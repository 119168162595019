import PropType from 'prop-types'
import { forwardRef, memo, useCallback, useRef } from 'react'

import AppmixerInsights from '../../../components/app-flows/AppmixerInsights'
import PanelDialog from '../../../components/dialog/PanelDialog'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['InsightsDialog'] })

const InsightsDialog = memo(forwardRef(({ appFlow }, ref) => {
  ref = useDefaultRef(ref)
  const appmixerInsightsRef = useRef()

  useLogger({ log, lifecycle: true, tags: [] })

  const handleCloseEditor = useCallback(() => {
    ref.current.close()
  }, [ref])

  const handleOnReady = useCallback(() => {
    appmixerInsightsRef.current.loadAppFlow(appFlow)
  }, [appFlow])

  return (
    <PanelDialog
      ref={ref}
      ariaLabel='App Flow Activity'
      dismissable={false}
      fullscreen
      keepInBackground
    >
      <PanelHeader
        start={<PanelHeaderButton icon='cancel' onClick={handleCloseEditor} />}
        subtitle={appFlow.name}
        title='App Flow Activity'
      />
      <PanelContent>
        <AppmixerInsights
          ref={appmixerInsightsRef}
          onReady={handleOnReady}
        />
      </PanelContent>
    </PanelDialog>
  )
}))

InsightsDialog.displayName = 'InsightsDialog'
InsightsDialog.propTypes = propTypes
InsightsDialog.defaultProps = defaultProps

export default InsightsDialog
