import { FileSharer as NativeFileShare } from '@byteowls/capacitor-filesharer'
import PropType from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import QRCodeStyling from 'styled-qr-code'

import { useApp } from '../../contexts/AppContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Downloads } from '../../svg/icons'
import MarkDark from '../../svg/sources/branding/Mark Dark.svg'
import Button from '../buttons/Button'

const propTypes = {
  url: PropType.string.isRequired,
  size: PropType.oneOf(['sm', 'lg'])
}

const defaultProps = {
  size: 'sm'
}

const classNamesBySize = {
  sm: 'flex items-center justify-center space-x-4',
  lg: 'flex flex-col items-center pt-4 space-y-6'
}

const buttonClassNamesBySize = {
  sm: 'flex flex-col space-y-4',
  lg: 'flex space-x-4'
}

const QRCodeSizes = { sm: 150, lg: 300 }

const log = logger({ enabled: false, tags: ['StyledQrCodeContent'] })

const qrCodeStyled = new QRCodeStyling({
  type: 'svg',
  image: MarkDark,
  imageOptions: {
    crossOrigin: 'anonymous',
    hideBackgroundDots: true,
    margin: 2
  },
  dotsOptions: {
    type: 'extra-rounded',
    color: '#000'
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
    color: '#000'
  }
})

const StyledQrCodeContent = ({ url, size }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { isNative } = useApp()
  const ref = useRef(null)

  useEffect(() => {
    qrCodeStyled.append(ref.current)
    qrCodeStyled.update({
      width: QRCodeSizes[size],
      height: QRCodeSizes[size]
    })
  }, [size])

  useEffect(() => {
    qrCodeStyled.update({
      data: url
    })
  }, [url])

  const handleNativeDownload = useCallback(async (extension) => {
    try {
      const dataUrl = await qrCodeStyled.toDataUrl(extension)
      const matches = dataUrl.match(/^data:(image\/(?:png|svg\+xml));base64,(.*)$/) || []
      if (!matches.length) { return }

      const contentType = matches[1]
      const base64Data = matches[2]
      const filename = `qr-code-${+new Date()}.${extension}`
      await NativeFileShare.share({ base64Data, contentType, filename })
    } catch (ex) {}
  }, [])

  const handleDownload = useCallback((extension) => {
    qrCodeStyled.download({
      extension
    })
  }, [])

  const handleDownloadClick = useCallback((extension) => {
    isNative ? handleNativeDownload(extension) : handleDownload(extension)
  }, [handleDownload, handleNativeDownload, isNative])

  return (
    <div className={classNamesBySize[size]}>
      <div ref={ref} />
      <div className={buttonClassNamesBySize[size]}>
        <Button
          size='md'
          theme='neutral'
          variant='outline'
          onClick={() => handleDownloadClick('png')}
        >
          <Downloads className='text-black w-5 mr-2' />
          <span className='text-black'>
            Download PNG
          </span>
        </Button>
        {!isNative && (
          <Button
            size='md'
            theme='neutral'
            variant='outline'
            onClick={() => handleDownloadClick('svg')}
          >
            <Downloads className='text-black w-5 mr-2' />
            <span className='text-black'>
              Download SVG
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

StyledQrCodeContent.displayName = 'StyledQrCodeContent'
StyledQrCodeContent.propTypes = propTypes
StyledQrCodeContent.defaultProps = defaultProps

export default StyledQrCodeContent
