import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './ImportAction.validations'

const propTypes = {
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['ImportAction'] })

const ImportAction = forwardRef((params, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const shareIdDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { shareIdDialogRef.current.open() }
  }), [])

  const handleImportInfo = useCallback(({ shareId }) => {
    shareIdDialogRef.current.close()
    navigate(`/bundles/import/${shareId}`, { replace: true })
  }, [navigate])

  return (
    <FormDialog
      ref={shareIdDialogRef}
      defaultValues={{ shareId: '' }}
      description='Enter the Share Id for the bundle you wish to import and click "Get Info" to proceed.'
      formControls={[{ name: 'shareId', placeholder: 'Share Id' }]}
      submitTitle='Get Info'
      title='Import Bundle'
      validationSuite={validationSuite}
      onSubmit={handleImportInfo}
    />
  )
})

ImportAction.displayName = 'ImportAction'
ImportAction.propTypes = propTypes
ImportAction.defaultProps = defaultProps

export default ImportAction
