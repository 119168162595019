import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Error } from '../../../svg/icons'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  error: PropType.string
}

const defaultProps = {
  error: null
}

const classNamesByDirection = {
  in: [
    'self-start'
  ],
  out: [
    'self-end'
  ]
}

const log = logger({ enabled: false, tags: ['MessageThreadItemContentError'] })

const MessageThreadItemContentError = ({ direction, error }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!error) { return null }

  const className = classNames(
    'flex flex-row items-center max-w-full mb-4',
    'whitespace-pre-wrap break-words text-error',
    classNamesByDirection[direction]
  )

  return (
    <div className={className}>
      {direction === 'in' ? <Error className='mr-2' height='18' width='18' /> : null}
      {error}
      {direction === 'out' ? <Error className='ml-2' height='18' width='18' /> : null}
    </div>
  )
}

MessageThreadItemContentError.displayName = 'MessageThreadItemContentError'
MessageThreadItemContentError.propTypes = propTypes
MessageThreadItemContentError.defaultProps = defaultProps

export default MessageThreadItemContentError
