import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/chats/actions/NewChatAction'
import { Chats as ChatsEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const EmptyListContent = () => {
  const createActionRef = useRef()

  const handleNewChat = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col mx-auto mt-12 mb-6 max-w-[375px]'>
        <ChatsEmptyStateSvg />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Start a Chat</h1>
        <p className='label-sm text-center'>
          You don't have any chats to show.
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleNewChat}>
          Start a Chat
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
