import * as React from "react";
import { memo } from "react";
const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.1 13.053h14.805l-4.318 3.8c-.467.401-.5 1.101-.1 1.568.4.45 1.1.5 1.567.1l6.569-5.785c.467-.4.5-1.1.1-1.567-.067-.084-.15-.134-.233-.2l-6.47-5.686A1.125 1.125 0 0 0 14.288 5c-.3 0-.617.133-.833.383-.4.467-.367 1.168.1 1.568l4.418 3.884H3.1c-.617 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1v.018Z"
    />
  </svg>
);
const Memo = memo(SvgArrowRight);
export default Memo;
