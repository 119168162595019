import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import appFlowTemplateShape from '../../prop-types/shapes/appFlowTemplate'
import { AppFlow } from '../../svg/types'

const propTypes = {
  item: PropType.shape(appFlowTemplateShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['AppFlowTemplateListItemContent'] })

const AppFlowTemplateListItemContent = ({ item: appFlowTemplate }) => {
  useLogger({ log, lifecycle: true, tags: [appFlowTemplate?._id] })

  return (
    <>
      <AppFlow className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black'>
          {appFlowTemplate.name}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {appFlowTemplate.description}
        </div>
      </div>
    </>
  )
}

AppFlowTemplateListItemContent.displayName = 'AppFlowTemplateListItemContent'
AppFlowTemplateListItemContent.propTypes = propTypes
AppFlowTemplateListItemContent.defaultProps = defaultProps

export default AppFlowTemplateListItemContent
