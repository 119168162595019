import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../hooks/useLogger'
import { mfaChannelVerb, mfaViaFormatted } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import PanelLoading from '../../panels/PanelLoading'

const propTypes = {
  dialogRef: PropType.shape({
    current: PropType.shape({
      close: PropType.func.isRequired
    })
  }).isRequired,
  onSubmit: PropType.func.isRequired,
  channel: PropType.oneOf(['call', 'sms']),
  to: PropType.string
}

const defaultProps = {
  channel: null,
  to: null
}

const log = logger({ enabled: false, tags: ['VerificationForm'] })

const VerificationForm = ({ channel, dialogRef, onSubmit, to }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formSettings = {
    id: 'mfaAddVerifyForm',
    formControls: [
      {
        label: 'Verification Code',
        name: 'verificationCode',
        type: 'text',
        required: true
      }
    ],
    defaultValues: { verificationCode: '' },
    onSubmit
  }

  const handleCancelClick = useCallback(() => {
    dialogRef.current.close()
  }, [dialogRef])

  if (!channel || !to) {
    return <PanelLoading noHeader />
  }

  return (
    <>
      <p className='mb-5'>
        Please enter the verification code sent to you via
        {' '}
        {mfaChannelVerb(channel).toLowerCase()}
        {' '}
        at
        {' '}
        {mfaViaFormatted({ channel, to })}
        .
      </p>
      <Form {...formSettings} />
      <Button
        className='w-full'
        form='mfaAddVerifyForm'
        size='md'
        type='submit'
      >
        Verify
      </Button>
      <Button
        className='mt-5 w-full'
        size='md'
        variant='outline'
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
    </>
  )
}

VerificationForm.displayName = 'VerificationForm'
VerificationForm.propTypes = propTypes
VerificationForm.defaultProps = defaultProps

export default VerificationForm
