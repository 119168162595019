import PropType from 'prop-types'

import trackableLinkClick from './trackableLinkClick'
import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  autoTag: PropType.bool,
  name: PropType.string,
  url: PropType.string,
  user: PropType.oneOfType([PropType.string, PropType.shape(user)]),
  slug: PropType.string,
  search: PropType.string,
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  updatedAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  trackableLinkClicks: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(trackableLinkClick))
  })
}
