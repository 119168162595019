import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import useImagePicker from '../../../hooks/useImagePicker'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import landingPageShape from '../../../prop-types/shapes/landingPage'

const propTypes = {
  landingPage: PropType.shape(landingPageShape).isRequired,
  removeService: PropType.func.isRequired,
  uploadService: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['SocialSharingImageDetail'] })

const SocialSharingImageDetail = ({ landingPage, uploadService, removeService, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [landingPage?._id] })

  const confirmRemoveDialogRef = useRef()

  const { show: showImagePicker } = useImagePicker()

  const handleReplyOk = useCallback((reply) => {
    success('Social Sharing Image updated')
    onChange?.(reply)
  }, [onChange])
  const { call: uploadImage } = useService(uploadService, { onReplyOk: handleReplyOk })
  const { call: removeImage } = useService(removeService, { onReplyOk: handleReplyOk })

  const handleEdit = useCallback(async () => {
    const image = await showImagePicker()
    if (image) {
      const data = new FormData()
      data.append('upload', image.uploadData)
      uploadImage(landingPage._id, data)
    }
  }, [landingPage._id, uploadImage, showImagePicker])

  const handleRemove = useCallback(() => {
    confirmRemoveDialogRef.current.open()
  }, [])

  const handleConfirmRemove = useCallback(() => {
    removeImage(landingPage._id, {
      socialSharingImage: null
    })
  }, [landingPage._id, removeImage])

  const hasSocialImage = !!landingPage?.socialSharingImage?.src

  return (
    <>
      <DetailSection
        description='This shows up when the link is shared. It is recommended that the image be 1200 x 630 (the minimum is 200 x 200).'
        initialEditButtonText='Add Social Sharing Image'
        showClearIcon={hasSocialImage}
        showEditIcon={hasSocialImage}
        showInitialEditButton={!hasSocialImage}
        title='Social Sharing Image'
        onClearClick={handleRemove}
        onEditClick={handleEdit}
        onInitialEditClick={handleEdit}
      >
        {hasSocialImage ? <img src={landingPage.socialSharingImage.src} /> : null}
      </DetailSection>
      <ConfirmDialog
        ref={confirmRemoveDialogRef}
        description='Are you sure you want to remove the social sharing image?'
        title='Remove Social Sharing Image'
        onConfirm={handleConfirmRemove}
      />
    </>
  )
}

SocialSharingImageDetail.displayName = 'SocialSharingImageDetail'
SocialSharingImageDetail.propTypes = propTypes
SocialSharingImageDetail.defaultProps = defaultProps

export default SocialSharingImageDetail
