import PropType from 'prop-types'

import { campaignTypeInfo } from '../../helpers/campaign'
import { contactSummary, scheduleSummary } from '../../helpers/campaignEvent'
import useLogger from '../../hooks/useLogger'
import useTimeZone from '../../hooks/useTimeZone'
import logger from '../../lib/logger'
import campaignEventShape from '../../prop-types/shapes/campaignEvent'
import MessageFragment from '../message/MessageFragment'

const propTypes = {
  item: PropType.shape(campaignEventShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['CampaignEventLIstItemContent'] })

const CampaignEventListItemContent = ({ item: event }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  log.debug(event)

  const { userTimeZone } = useTimeZone()

  const { typeSvg: CampaignTypeSvg } = campaignTypeInfo(event.campaignType)

  return (
    <>
      <CampaignTypeSvg className='shrink-0 grow-0 h-6 w-6' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {event.name}
        </div>
        <div className='label-sm text-neutral-500'>
          <MessageFragment message={event} />
        </div>
        <div className='label-sm text-neutral-500'>
          {scheduleSummary(event, userTimeZone)}
        </div>
        <div className='label-sm text-neutral-500'>
          {contactSummary(event)}
        </div>
      </div>
    </>
  )
}

CampaignEventListItemContent.displayName = 'CampaignEventListItemContent'
CampaignEventListItemContent.propTypes = propTypes
CampaignEventListItemContent.defaultProps = defaultProps

export default CampaignEventListItemContent
