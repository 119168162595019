import { useCallback } from 'react'

import CheckboxDetailSection from '../../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../../components/detail-sections/DetailSection'
import NameDetailSection from '../../../components/detail-sections/NameDetailSection'
import EmailEditor from '../../../components/emails/EmailEditor'
import LandingPageEditor from '../../../components/landing-pages/LandingPageEditor'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import UnlayerDesignDetailSection from '../../../components/unlayer/UnlayerDesignDetailSection'
import useCurrentUnlayerTemplate from '../../../hooks/useCurrentUnlayerTemplate'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { regeneratePreviewImageUrl, update } from '../../../services/admin/unlayer-template'

import DetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UnlayerTemplatesDetail'] })

const UnlayerTemplatesDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { unlayerTemplate, setUnlayerTemplate, loading } = useCurrentUnlayerTemplate()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const updatedUnlayerTemplate = updateReply.json
    setUnlayerTemplate(updatedUnlayerTemplate)
    bus.emit('unlayerTemplateUpdated', updatedUnlayerTemplate)
  }, [setUnlayerTemplate])

  const handleRegeneratePreviewImageUrlReplyOk = useCallback((reply) => { handleDetailsUpdate(reply) }, [handleDetailsUpdate])
  const { call: regeneratePreviewImageUrlCall } = useService(regeneratePreviewImageUrl, { onReplyOk: handleRegeneratePreviewImageUrlReplyOk })

  const handleEditPreviewImageUrlClick = useCallback(() => {
    regeneratePreviewImageUrlCall(unlayerTemplate._id)
  }, [regeneratePreviewImageUrlCall, unlayerTemplate])

  if (loading || !unlayerTemplate) { return <PanelLoading /> }

  const Editor = unlayerTemplate.type === 'landingPage' ? LandingPageEditor : EmailEditor

  return (
    <Panel>
      <PanelHeader
        end={<DetailHeaderMoreMenu unlayerTemplate={unlayerTemplate} />}
        start={<PanelHeaderBackButton />}
        subtitle={unlayerTemplate.type === 'landingPage' ? 'Landing Page' : 'Email'}
        title={unlayerTemplate.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          object={unlayerTemplate}
          objectName='unlayerTemplate'
          service={update}
          onChange={handleDetailsUpdate}
        />
        <CheckboxDetailSection
          checked={unlayerTemplate.enabled}
          description='When checked, this template is available for use.'
          name='enabled'
          objectId={unlayerTemplate._id}
          service={update}
          title='Enabled'
          onChange={handleDetailsUpdate}
        />
        <DetailSection
          description={`This generated preview image is used when selecting a template. ${unlayerTemplate.previewImageUrl ? 'Click reload icon to regenerate.' : ''} `}
          editIcon='reload'
          showEditIcon={!!unlayerTemplate.unlayerDesign}
          title='Preview Image'
          onEditClick={handleEditPreviewImageUrlClick}
        >
          {unlayerTemplate.previewImageUrl ? <img alt='Preview' src={unlayerTemplate.previewImageUrl} /> : 'No Preview Image'}
        </DetailSection>
        <UnlayerDesignDetailSection
          Editor={Editor}
          record={unlayerTemplate}
          service={update}
          which='unlayerTemplate'
          onChange={handleDetailsUpdate}
        />

      </PanelContent>
    </Panel>
  )
}

UnlayerTemplatesDetail.displayName = 'UnlayerTemplatesDetail'
UnlayerTemplatesDetail.propTypes = propTypes
UnlayerTemplatesDetail.defaultProps = defaultProps

export default UnlayerTemplatesDetail
