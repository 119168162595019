import PropType from 'prop-types'

import InputIcon from '../forms/InputIcon'

const propTypes = {
  start: PropType.node.isRequired,
  summaries: PropType.arrayOf(PropType.string).isRequired,
  onClear: PropType.func.isRequired,
  onClick: PropType.func.isRequired
}

const defaultProps = {}

const SummaryInput = ({ start, summaries, onClear, onClick }) => {
  const className = 'flex flex-row flex-no-wrap items-center overflow-hidden border border-neutral-300 rounded-md w-full bg-white'
  const buttonClassName = 'text-left w-full py-[5px] cursor-pointer'
  const summaryNodeClassName = 'inline-block mr-[4px] px-[8px] py-[1px] bg-neutral-50 text-primary rounded-md border border-neutral-200'

  const summaryNodes = summaries.map((summary) => <span key={summary} className={summaryNodeClassName}>{summary}</span>)

  return (
    <div className={className}>
      {start}
      <button aria-describedby='Open advanced search dialog' className={buttonClassName} onClick={onClick}>
        {summaryNodes}
      </button>
      <InputIcon icon='close' onClick={onClear} />
    </div>
  )
}

SummaryInput.displayName = 'SummaryInput'
SummaryInput.propTypes = propTypes
SummaryInput.defaultProps = defaultProps

export default SummaryInput
