import PropType from 'prop-types'

import CheckboxDetailSection from '../../../../components/detail-sections/CheckboxDetailSection'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { updateDisabled } from '../../../../services/multi-factor-authentication'

const propTypes = {
  mfaDisabled: PropType.bool.isRequired,
  onChange: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DisableDetail'] })

const DisableDetail = ({
  mfaDisabled,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()

  return (
    <CheckboxDetailSection
      checked={mfaDisabled}
      description='When checked, temporarily disable multi-factor authentication.'
      name='disabled'
      objectId={currentUser._id}
      service={updateDisabled}
      title='Disable MFA'
      onChange={onChange}
    />
  )
}

DisableDetail.displayName = 'DisableDetail'
DisableDetail.propTypes = propTypes
DisableDetail.defaultProps = defaultProps

export default DisableDetail
