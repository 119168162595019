import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'
import { del } from '../../../services/contact'
import { success } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ contact, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${contact.formattedName} was deleted`)
    bus.emit('contactDeleted', contact)
    onSuccess?.()
  }, [contact, onSuccess])
  const { call } = useService(del, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    call(contact._id, false)
  }, [call, contact._id])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText={`Delete ${contact.formattedName}`}
      confirmValue='DELETE'
      title={`Are you sure you want to delete ${contact.formattedName}?`}
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>Deleting this contact will remove them completely from the system as if they never existed. This includes being removed from all broadcasts, campaigns, chats, attachment downloads, trackable link clicks, landing page views, contests, etc.</p>
      <p className='mb-4'>If you do not wish to fully delete this contact, please archive them instead.</p>
    </DangerousConfirmDialog>
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
