import PropType from 'prop-types'
import { useMemo } from 'react'

import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contest from '../../../prop-types/shapes/contest'

const propTypes = {
  contest: PropType.shape(contest).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['WinnersDetail'] })

const WinnersDetail = ({ contest }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { count, value } = useMemo(() => ({
    count: contest.winners.length,
    value: contest.winnersSubsetNames
  }), [contest])

  return (
    <DetailSection
      count={count}
      description='The winners in your contest are listed below.'
      editHref='winners'
      editIcon='chevron'
      showEditIcon={count > 0}
      title='Winners'
    >
      {value}
    </DetailSection>
  )
}

WinnersDetail.displayName = 'WinnersDetail'
WinnersDetail.propTypes = propTypes
WinnersDetail.defaultProps = defaultProps

export default WinnersDetail
