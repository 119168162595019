import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/emails/actions/CreateAction'
import { Emails as TemplatesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const EmailEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddTemplate = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <TemplatesEmptyStateSvg className='my-6' />
        <Button className='my-6 place-content-center' size='md' onClick={onAddTemplate}>
          Add Email
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

EmailEmptyListContent.displayName = 'EmailEmptyListContent'
EmailEmptyListContent.propTypes = propTypes
EmailEmptyListContent.defaultProps = defaultProps

export default EmailEmptyListContent
