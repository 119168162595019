import classNames from 'classnames'
import PropType from 'prop-types'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import Pluralize from '../../../Pluralize'

const propTypes = {
  count: PropType.number.isRequired,
  max: PropType.number.isRequired,
  messageType: PropType.oneOf(messageTypes).isRequired,
  segmentsCount: PropType.number.isRequired,
  showSegmentsCount: PropType.bool.isRequired,
  className: PropType.string
}

const defaultProps = {
  className: null
}

const log = logger({ enabled: true, tags: ['CharacterCounter'] })

const CharacterCounter = ({
  className,
  count,
  max,
  messageType,
  segmentsCount,
  showSegmentsCount
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (messageType === 'rvm' || messageType === 'email') { return null }

  const overLimit = count > max
  const computedClassName = classNames(
    'label-xs',
    {
      'text-neutral-400': !overLimit,
      'text-error': overLimit
    },
    className
  )

  const segments = (
    <div className='inline-block pl-2'>
      (
      <Pluralize
        className='pl-2'
        count={segmentsCount}
        singular='segment'
        includeCount
      />
      )
    </div>
  )

  return (
    <div className={computedClassName}>
      {count}
      {' '}
      of
      {' '}
      {max}
      {showSegmentsCount ? segments : null}
    </div>
  )
}

CharacterCounter.displayName = 'CharacterCounter'
CharacterCounter.propTypes = propTypes
CharacterCounter.defaultProps = defaultProps

export default CharacterCounter
