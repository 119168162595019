import PropType from 'prop-types'

const propTypes = {
  id: PropType.string,
  text: PropType.string
}

const defaultProps = {
  id: null,
  text: null
}

const Label = ({ id, text }) => {
  if (!text) { return null }
  return <label className='block label-md-strong mb-1' htmlFor={id}>{text}</label>
}

Label.displayName = 'Label'
Label.propTypes = propTypes
Label.defaultProps = defaultProps

export default Label
