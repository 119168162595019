import PropType from 'prop-types'

import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  fileName: PropType.string,
  fileSize: PropType.string,
  contentType: PropType.string, // mime type like "image/png"
  ext: PropType.string, // "png"
  key: PropType.string,
  search: PropType.string,
  slug: PropType.string,
  size: PropType.number,
  publicUrl: PropType.string,
  user: PropType.oneOfType([PropType.string, PropType.shape(user)]),
  attachmentDownloads: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape({
      contact: PropType.shape({
        firstName: PropType.string,
        lastName: PropType.string
      })
    }))
  })
}
