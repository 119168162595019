import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import { formatAbbreviatedDate } from '../../../lib/formatters'
import logger from '../../../lib/logger'

const propTypes = {
  currentUser: PropType.shape({
    subscriptionDetails: PropType.shape({
      cancelsAt: PropType.string,
      currentPlanEndDate: PropType.string,
      currentPlanFrequency: PropType.string,
      currentPlanName: PropType.string,
      scheduledPlanFrequency: PropType.string,
      scheduledPlanName: PropType.string
    }),
    timezone: PropType.string
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SubscriptionDetails'] })

const SubscriptionDetails = ({ currentUser }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const showDetails = currentUser.subscriptionDetails?.currentPlanEndDate

  if (!showDetails) { return }

  return (
    <>
      <div className='my-2 border-b border-neutral-400' />
      <div className='label-xs-strong'>
        {currentUser.subscriptionDetails?.currentPlanName}
        {' '}
        {currentUser.subscriptionDetails?.currentPlanFrequency}
        {' '}
        Plan
      </div>
      <div className='label-xs'>
        {currentUser.subscriptionDetails?.cancelsAt
          ? (
            <>
              Cancels on
              {' '}
              {formatAbbreviatedDate(currentUser.subscriptionDetails.cancelsAt, currentUser.timezone)}
            </>
            )
          : (
            <>
              {currentUser.subscriptionDetails?.scheduledPlanName ? 'Changes' : 'Renews'}
              {' '}
              on
              {' '}
              <span className='whitespace-nowrap'>
                {formatAbbreviatedDate(currentUser.subscriptionDetails.currentPlanEndDate, currentUser.timezone)}
              </span>
            </>
            )}
        {currentUser.subscriptionDetails?.scheduledPlanName
          ? (
            <>
              {' '}
              to
              {' '}
              <div className='label-xs-strong'>
                {currentUser.subscriptionDetails.scheduledPlanName}
                {' '}
                {currentUser.subscriptionDetails.scheduledPlanFrequency}
                {' '}
                Plan
              </div>
            </>
            )
          : null}
      </div>
    </>
  )
}

SubscriptionDetails.displayName = 'SubscriptionDetails'
SubscriptionDetails.propTypes = propTypes
SubscriptionDetails.defaultProps = defaultProps

export default SubscriptionDetails
