import * as React from "react";
import { memo } from "react";
const SvgArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.9 10.74H6.095l4.318-3.802c.467-.4.5-1.1.1-1.567-.4-.45-1.1-.5-1.567-.1l-6.569 5.785c-.467.4-.5 1.1-.1 1.568.067.083.15.133.233.2l6.47 5.685c.216.183.466.283.733.283.3 0 .617-.133.833-.383.4-.467.367-1.167-.1-1.567l-4.418-3.885H20.9c.617 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1v-.017Z"
    />
  </svg>
);
const Memo = memo(SvgArrowLeft);
export default Memo;
