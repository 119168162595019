import * as React from "react";
import { memo } from "react";
const SvgContest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.707 2.293a1 1 0 0 0-1.414 1.414l2.156 2.157L2.293 8.02a1 1 0 0 0 1.414 1.414l2.157-2.156L8.02 9.434A1 1 0 0 0 9.434 8.02L7.278 5.864l2.156-2.157A1 1 0 0 0 8.02 2.293L5.864 4.449 3.707 2.293ZM11 11V3a1 1 0 1 1 2 0v8h8a1 1 0 1 1 0 2h-8v8a1 1 0 1 1-2 0v-8H3a1 1 0 1 1 0-2h8Zm4.98 3.566a1 1 0 0 0-1.414 1.414l2.156 2.156-2.156 2.157a1 1 0 0 0 1.414 1.414l2.156-2.156 2.157 2.156a1 1 0 0 0 1.414-1.414l-2.156-2.157 2.156-2.156a1 1 0 0 0-1.414-1.414l-2.157 2.156-2.156-2.156Zm.293-8.702a1.864 1.864 0 1 1 3.727 0 1.864 1.864 0 0 1-3.727 0ZM18.136 2a3.864 3.864 0 1 0 0 7.727 3.864 3.864 0 0 0 0-7.727ZM4 18.136a1.864 1.864 0 1 1 3.727 0 1.864 1.864 0 0 1-3.727 0Zm1.864-3.863a3.864 3.864 0 1 0 0 7.727 3.864 3.864 0 0 0 0-7.727Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgContest);
export default Memo;
