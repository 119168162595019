import { useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLayoutPanels from '../../components/panels/PanelLayoutPanels'
import { PanelProvider } from '../../contexts/PanelContext'
import { Placeholder } from '../../svg/icons'

const variations = [
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title Only',
    props: {
      title: 'Project Broadcast'
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title and Count Only',
    props: {
      title: 'Project Broadcast',
      count: 123
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title and Subtitle Only',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch'
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Count, and Subtitle Only',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      count: 12345
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Start, and End Buttons',
    props: {
      title: 'Project Broadcast',
      start: <PanelHeaderButton icon='back' />,
      end: <PanelHeaderButton icon='more' />
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Start, Count, and End Buttons',
    props: {
      title: 'Project Broadcast',
      start: <PanelHeaderButton icon='back' />,
      end: <PanelHeaderButton icon='more' />,
      count: 1234567
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, and End Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton icon='back' />,
      end: <PanelHeaderButton icon='more' />
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, Count, and End Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton icon='back' />,
      end: <PanelHeaderButton icon='more' />,
      count: 1234
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, and Multiple End Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton icon='back' />,
      end: (
        <>
          <PanelHeaderButton icon={<Placeholder />} />
          <PanelHeaderButton icon={<Placeholder />} />
          <PanelHeaderButton icon='more' />
        </>
      )
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, Count, and Multiple End Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton icon='back' />,
      end: (
        <>
          <PanelHeaderButton icon={<Placeholder />} />
          <PanelHeaderButton icon={<Placeholder />} />
          <PanelHeaderButton icon='more' />
        </>
      ),
      count: 123456789
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, and End Text Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton title='Cancel' />,
      end: <PanelHeaderButton title='Save' />
    }
  },
  {
    panels: ['first', 'second', 'dialog'],
    title: 'Title, Subtitle, Start, Count, and End Text Buttons',
    props: {
      title: 'Project Broadcast',
      subtitle: 'Business Messaging with a Personal Touch',
      start: <PanelHeaderButton title='Cancel' />,
      end: <PanelHeaderButton title='Save' />,
      count: 1234
    }
  }
]

function PanelHeaders () {
  const [native, setNative] = useState(false)

  const output = variations.map(({ panels, title, props }) => {
    return (
      <div key={title} className='my-6'>
        {panels.map((slot) => (
          <div key={`${slot}-${title}`} className='relative'>
            <PanelLayoutPanels
              isNative={native}
              panel1={(
                <PanelProvider isNative={native} slot={slot}>
                  <PanelHeader {...props} />
                </PanelProvider>
              )}
              panel2={slot === 'second'
                ? (
                  <PanelProvider isNative={native} slot={slot}>
                    <PanelHeader {...props} />
                  </PanelProvider>
                  )
                : null}
              roomForTwoPanels={slot === 'second'}
              includeHeaderBackground
            />
            <div className='label-xs mb-3 text-right'>
              {title}
              {' ('}
              {slot}
              {' '}
              panel)
            </div>
          </div>
        ))}
      </div>
    )
  })

  const nativeSwitch = (
    <label className='mr-2'>
      <span className='inline-block mr-2'>Native</span>
      <input
        className='switch'
        defaultChecked={native}
        type='checkbox'
        onClick={() => setNative(!native)}
      />
    </label>
  )
  return (
    <Panel>
      <PanelHeader end={nativeSwitch} title='Panel Headers' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default PanelHeaders
