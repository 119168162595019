import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useId, useImperativeHandle, useMemo, useReducer, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import useStore from '../../store'
import { saveSnapshot } from '../../store/actions/list'
import { Refresh } from '../../svg/icons'
import Button from '../buttons/Button'

import EmptyListContentContainer from './EmptyListContentContainer'
import DefaultEmptySearchResultsListContent from './EmptySearchResultsListContent'
import ListFooter from './ListFooter'
import ListItem from './ListItem'
import DefaultListSearchBar from './ListSearchBar'
import ListSelectedStatusBar from './ListSelectedStatusBar'

import './List.css'

const propTypes = {
  ListItemContent: PropType.elementType.isRequired,
  searchService: PropType.func.isRequired,
  actionConfirm: PropType.string,
  actionLabel: PropType.string,
  additionalContext: PropType.object, // eslint-disable-line react/forbid-prop-types
  bulkActions: PropType.elementType,
  // NOTE: defaultSearch only works for data that does not get transformed by the implemented search bar
  defaultSearch: PropType.object, // eslint-disable-line react/forbid-prop-types
  EmptyListContent: PropType.elementType,
  EmptySearchResultsListContent: PropType.elementType,
  itemSharedContext: PropType.object, // eslint-disable-line react/forbid-prop-types
  ListSearchBar: PropType.elementType,
  multiple: PropType.bool, // applies to type select, add, and remove only
  pageSize: PropType.number,
  pathPrefix: PropType.string,
  placeholder: PropType.string,
  queryOverrides: PropType.object, // eslint-disable-line react/forbid-prop-types
  resultsFields: PropType.arrayOf(PropType.string),
  resultsKey: PropType.string,
  savedStateKey: PropType.string,
  search: PropType.bool,
  selectedItems: PropType.array, // eslint-disable-line react/forbid-prop-types
  showFolderToggle: PropType.bool,
  sortable: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape)),
  stripPadding: PropType.bool,
  type: PropType.oneOf([
    'add',
    'bulk',
    'list',
    'move',
    'nav',
    'remove',
    'select'
  ]),
  visible: PropType.bool,
  onSubmitAllInQuery: PropType.func,
  onSubmitSelected: PropType.func,
  onTotalChange: PropType.func
}

const defaultProps = {
  actionConfirm: null,
  actionLabel: null,
  additionalContext: null,
  bulkActions: null,
  defaultSearch: {},
  EmptyListContent: null,
  EmptySearchResultsListContent: DefaultEmptySearchResultsListContent,
  itemSharedContext: {},
  ListSearchBar: DefaultListSearchBar,
  multiple: true,
  onSubmitAllInQuery: undefined,
  onSubmitSelected: undefined,
  onTotalChange: undefined,
  pageSize: 25,
  pathPrefix: null,
  placeholder: undefined,
  queryOverrides: {},
  resultsFields: null,
  resultsKey: '',
  savedStateKey: null,
  search: false,
  selectedItems: [],
  showFolderToggle: false,
  sortable: false,
  sortList: null,
  stripPadding: false,
  type: 'list',
  visible: true
}

const initialPagingState = {
  page: 0, // current page
  pages: 1, // unsure what this is for... comes back in the server payload
  total: 0 // total pages
}

const log = logger({ enabled: true, tags: ['List'] })

const List = forwardRef(({
  actionLabel,
  actionConfirm,
  additionalContext,
  bulkActions,
  defaultSearch,
  EmptyListContent,
  EmptySearchResultsListContent,
  itemSharedContext,
  ListItemContent,
  ListSearchBar,
  multiple,
  pageSize,
  pathPrefix,
  placeholder,
  queryOverrides,
  resultsFields,
  resultsKey,
  savedStateKey,
  search,
  searchService,
  selectedItems: initialSelectedItems,
  showFolderToggle,
  sortable,
  sortList,
  stripPadding,
  type,
  visible,
  onSubmitSelected,
  onSubmitAllInQuery,
  onTotalChange
}, ref) => {
  ref = useDefaultRef(ref)
  const virtuosoRef = useRef()
  const listRef = useRef()
  const id = useId()
  const onTotalChangeRef = useRef(onTotalChange)
  const savedStateKeyRef = useRef(savedStateKey)
  const loadedSnapshotRef = useRef(useStore.getState().lists[savedStateKeyRef.current])

  useLogger({ log, lifecycle: true, tags: [id, resultsKey] })

  const initialState = useMemo(() => {
    const defaultState = {
      data: [],
      listData: [],
      focused: false,
      focusedItemIndex: 0,
      paging: initialPagingState,
      query: defaultSearch || {},
      selectedItems: initialSelectedItems || [],
      showRefreshBanner: false,
      pageSize,
      queryOverrides,
      resultsFields,
      nextPaging: false // used to trigger paging while avoiding side-effects from monitoring other state items
    }
    const computedInitialState = { ...defaultState, ...loadedSnapshotRef.current?.component }
    return computedInitialState
    // we're purposely ignoreing the dependencies here because this is just used on mount
  }, [/* defaultSearch, initialSelectedItems, pageSize, queryOverrides, resultsFields */]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleReplyOk = useCallback((reply) => { dispatch({ type: 'reply', reply, resultsKey }) }, [resultsKey])
  // TODO: should we handleReplyNotOk as well and retry?
  const { call, loading } = useService(searchService, { onReplyOk: handleReplyOk })
  const [state, dispatch] = useReducer(reducer, initialState)

  const ariaRole = useMemo(() => type === 'select' ? 'listbox' : 'navigation', [type])
  const ariaLabel = '' // TODO: we should require passing in an aria label
  const ariaActivedescendant = useMemo(() => state.focusedItemIndex > -1 ? `${id}-${state.focusedItemIndex}` : null, [id, state.focusedItemIndex])

  useEffect(() => { onTotalChangeRef.current = onTotalChange }, [onTotalChange])
  useEffect(() => { onTotalChangeRef.current?.(state.paging.total) }, [state.paging.total])

  const publicApi = useMemo(() => ({
    focus () {
      dispatch({ type: 'focus' })
    },
    getItemById (id) {
      return state.data.find((item) => id === itemIdentifier(item))
    },
    updateItem (newItem) {
      dispatch({ type: 'updateItem', newItem })
    },
    updateItems (updatedItems) {
      dispatch({ type: 'updateItems', updatedItems })
    },
    newItems (items) {
      dispatch({ type: 'newItems', items })
    },
    removeItem (itemIdentifier) {
      dispatch({ type: 'removeItem', itemIdentifier })
    },
    removeItems (itemIdentifiers) {
      dispatch({ type: 'removeItems', itemIdentifiers })
    },
    indicateUpdatesDetected () {
      if (state.paging.page === 1) {
        dispatch({ type: 'reload' })
      } else {
        dispatch({ type: 'showRefreshBanner' })
      }
    },
    reload () { dispatch({ type: 'reload' }) },
    scrollToIndex (index) {
      virtuosoRef.current.scrollIntoView({
        index,
        behavior: 'auto',
        done () {
          if (index !== state.focusedItemIndex) {
            dispatch({ type: 'itemFocus', focusedItemIndex: index })
          }
        }
      })
    },
    search (query) {
      // handleSearchBarChange also dispatches this action directly
      dispatch({ type: 'newQuery', query })
    },
    query () {
      return state.query
    },
    submitSelected () { onSubmitSelected?.(state.selectedItems) },
    submitAllInQuery () { onSubmitAllInQuery?.(state.query, state.paging.total) }
  }), [state.data, state.paging.page, state.paging.total, state.focusedItemIndex, state.query, state.selectedItems, onSubmitSelected, onSubmitAllInQuery])

  // Expose the publicApi
  useImperativeHandle(ref, () => publicApi, [publicApi])

  useUpdateEffect(() => { dispatch({ type: 'newPageSize', pageSize }) }, [pageSize])
  useUpdateEffect(() => { dispatch({ type: 'newQueryOverrides', queryOverrides }) }, [queryOverrides])
  useUpdateEffect(() => { dispatch({ type: 'newResultsFields', resultsFields }) }, [resultsFields])

  useEffect(() => {
    if (!loadedSnapshotRef.current?.component?.data?.length) {
      // Initial mount, trigger loading the next page
      dispatch({ type: 'nextPage' })
    }
  }, [])

  useEffect(() => {
    // push a snapshot of the state to the global store if opted into that behavior
    const globalStoreKey = savedStateKeyRef.current
    const virtuoso = virtuosoRef.current
    const savedState = state
    return () => {
      if (!globalStoreKey) { return }
      virtuoso.getState((virtuosoSnapshot) => {
        const snapshot = {
          component: savedState,
          virtuoso: virtuosoSnapshot
        }
        saveSnapshot(globalStoreKey, snapshot)
      })
    }
  }, [state])

  useEffect(() => {
    // nextPaging changed... see if it should trigger loading the next page or not
    if (!state.nextPaging) { return }
    const { nextPage, nextPageSize, nextQueryOverrides, nextQuery, nextResultsFields } = state.nextPaging
    call({ select: nextResultsFields, paging: { page: nextPage, pageSize: nextPageSize }, ...nextQuery, ...nextQueryOverrides }, true)
  }, [call, state.nextPaging])

  const handleOnEndReached = useCallback(() => {
    dispatch({ type: 'nextPage' })
  }, [])
  // const handleOnEndReached = useCallback(() => {
  //   const { page: currentPage, pages: totalPages } = state.paging
  //   const nextPage = currentPage + 1
  //   if (nextPage > totalPages) { return log.info(`Paging: done paging... ${totalPages} pages total`) }
  //   log.info(`Paging: fetching page ${nextPage} of ${totalPages}`, state.query)
  //   call({ select: resultsFields, paging: { page: nextPage, pageSize }, ...state.query, ...queryOverrides }, true)
  // }, [state, resultsFields, pageSize, queryOverrides])

  const handleKeydown = useCallback((event) => {
    const handleScrollableEvent = (index) => {
      event.preventDefault()
      publicApi.scrollToIndex(index)
    }

    if (event.key === 'ArrowUp') {
      return handleScrollableEvent(Math.max(0, state.focusedItemIndex - 1))
    } else if (event.key === 'ArrowDown') {
      return handleScrollableEvent(Math.min((state.data.length - 1), state.focusedItemIndex + 1))
    } else if (event.key === 'Home') {
      return handleScrollableEvent(0)
    } else if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
      event.preventDefault()
      if (type === 'list') { return }
      dispatch({ type: 'newItemSelectionByIndex', index: state.focusedItemIndex, multiple })
      if (type === 'nav') {
        const a = document.getElementById(`${id}-${state.focusedItemIndex}`)
        a?.click()
      }
    }
  }, [publicApi, state.focusedItemIndex, state.data.length, type, multiple, id])

  const handleItemClick = useCallback((event) => {
    if (type === 'list' || type === 'nav') { return }
    const clickedItemIndex = itemIndexFromEvent(event)
    // click event is delegated and might have originated from outside an item (like the scrollbar)
    if (clickedItemIndex === -1) { return }
    dispatch({ type: 'newItemSelectionByIndex', index: clickedItemIndex, multiple })
  }, [type, multiple])

  const handleSelectAll = useCallback(() => {
    publicApi.submitAllInQuery()
  }, [publicApi])

  const handleSelectSelected = useCallback(() => {
    publicApi.submitSelected()
  }, [publicApi])

  const handleListFocus = useCallback((event) => publicApi.focus(), [publicApi])
  const handleListBlur = useCallback(() => dispatch({ type: 'blur' }), [])

  const scrollerRef = useCallback((element) => {
    if (element) {
      element.addEventListener('mousedown', handleItemClick)
      element.addEventListener('keydown', handleKeydown)
      element.addEventListener('focus', handleListFocus)
      element.addEventListener('blur', handleListBlur)
      listRef.current = element
    } else {
      listRef.current.removeEventListener('mousedown', handleItemClick)
      listRef.current.removeEventListener('keydown', handleKeydown)
      listRef.current.removeEventListener('focus', handleListFocus)
      listRef.current.removeEventListener('blur', handleListBlur)
    }
  }, [handleItemClick, handleKeydown, handleListFocus, handleListBlur])

  const handleSearchBarChange = useCallback((query) => {
    // publicApi.search also dispatches this action directly
    dispatch({ type: 'newQuery', query })
  }, [])

  const renderItem = useCallback((index, { item, next, prev }, context) => {
    const isSelected = state.selectedItems.length === 0 ? false : state.selectedItems.some((selectedItem) => areItemsEqual(item, selectedItem))
    const isFocused = context.focused && state.focusedItemIndex === index
    const itemId = `${id}-${index}`
    return (
      <ListItem
        id={itemId}
        index={index}
        isFocused={isFocused}
        isSelected={isSelected}
        item={item}
        itemSharedContext={itemSharedContext}
        ListItemContent={ListItemContent}
        multiple={multiple}
        next={next}
        pathPrefix={pathPrefix}
        prev={prev}
        stripPadding={stripPadding}
        type={context.type}
      />
    )
  }, [state.selectedItems, state.focusedItemIndex, id, itemSharedContext, ListItemContent, multiple, stripPadding, pathPrefix])

  const handleRefreshBannerClick = useCallback(() => {
    dispatch({ type: 'reload' })
  }, [])

  const refreshBannerComponent = useMemo(() => {
    if (!state.showRefreshBanner) { return null }
    return (
      <div aria-live='polite' className='flex flex-row flex-nowrap bg-sky-50 border-neutral-200 border-t border-b p-[8px]'>
        <Button
          className='!text-black'
          size='sm'
          start={<Refresh />}
          variant='text'
          onClick={handleRefreshBannerClick}
        >
          Updates detected. Please refresh.
        </Button>
      </div>
    )
  }, [handleRefreshBannerClick, state.showRefreshBanner])

  const showingSearchResults = search && Object.values(state.query).some((value) => value?.length)

  const listSelectedStatusBar = useMemo(() => {
    if (!(['add', 'bulk', 'move', 'remove'].includes(type) && multiple === true)) { return null }
    return (
      <ListSelectedStatusBar
        actionConfirm={actionConfirm}
        actionLabel={actionLabel}
        bulkActions={bulkActions}
        selectedCount={state.selectedItems.length}
        selectedItems={state.selectedItems}
        totalCount={state.paging.total}
        type={type}
        onSelectAll={handleSelectAll}
        onSelectSelected={handleSelectSelected}
      />
    )
  }, [actionConfirm, actionLabel, bulkActions, handleSelectAll, handleSelectSelected, multiple, state.paging.total, state.selectedItems, type])

  return (
    <>
      {visible && search
        ? <ListSearchBar
            placeholder={placeholder}
            search={state.query}
            showFolderToggle={showFolderToggle}
            sortList={sortable ? sortList : null}
            onChange={handleSearchBarChange}
          />
        : null}
      {visible ? refreshBannerComponent : null}
      <Virtuoso
        ref={virtuosoRef}
        aria-activedescendant={ariaActivedescendant}
        aria-label={ariaLabel}
        aria-multiselectable={multiple}
        className='list max-w-full'
        components={{
          EmptyPlaceholder: EmptyListContentContainer,
          Footer: ListFooter
        }}
        context={{
          EmptyListContent: showingSearchResults ? EmptySearchResultsListContent : EmptyListContent,
          loading,
          multiple,
          focused: state.focused,
          query: state.query,
          type,
          ...additionalContext
        }}
        data={state.listData}
        endReached={handleOnEndReached}
        id={id}
        itemContent={renderItem}
        restoreStateFrom={loadedSnapshotRef.current?.virtuoso}
        role={ariaRole}
        scrollerRef={scrollerRef}
        style={{ display: !visible ? 'none' : undefined }}
        tabIndex='0'
      />
      {visible ? listSelectedStatusBar : null}
    </>
  )
})

List.displayName = 'List'
List.propTypes = propTypes
List.defaultProps = defaultProps

function toListData (data) {
  const result = []
  for (let i = 0; i < data.length; i++) {
    result.push({
      item: data[i],
      next: i < data.length - 1 ? data[i + 1] : null,
      prev: i > 0 ? data[i - 1] : null
    })
  }
  return result
}

function reducer (state, action) {
  switch (action.type) {
    case 'nextPage': {
      if (state.nextPaging) { return state }
      const { page: currentPage, pages: totalPages } = state.paging
      const nextPage = currentPage + 1
      if (nextPage > totalPages) { return state }
      const nextPaging = { nextPage, nextPageSize: state.pageSize, nextQueryOverrides: state.queryOverrides, nextQuery: state.query, nextResultsFields: state.resultsFields }
      return { ...state, nextPaging }
    }
    case 'focus':
      return { ...state, focused: true }
    case 'blur':
      return { ...state, focused: false }
    case 'reload': {
      const nextPaging = { nextPage: 1, nextPageSize: state.pageSize, nextQueryOverrides: state.queryOverrides, nextQuery: state.query, nextResultsFields: state.resultsFields }
      return { ...state, data: [], listData: [], query: { ...state.query }, paging: { ...initialPagingState }, showRefreshBanner: false, nextPaging }
    }
    case 'newQuery': {
      const nextPaging = { nextPage: 1, nextPageSize: state.pageSize, nextQueryOverrides: state.queryOverrides, nextQuery: action.query, nextResultsFields: state.resultsFields }
      return { ...state, data: [], listData: [], focusedItemIndex: 0, query: action.query, paging: { ...initialPagingState, total: state.paging.total }, showRefreshBanner: false, nextPaging }
    }
    case 'reply': {
      const newData = action.reply?.ok && (action.reply?.models || action.reply?.json?.[action.resultsKey])
      if (!newData) { return state }
      const data = [...state.data, ...newData]
      const paging = action.reply.json.paging || { total: data.length, pageSize: data.length, page: 1, pages: 1 }
      return { ...state, data, listData: toListData(data), paging, nextPaging: false }
    }
    case 'newItemSelectionByIndex': {
      const { index, multiple } = action
      const item = state.data[index]
      let selectedItems = [...state.selectedItems]

      if (!multiple) {
        if (selectedItems.length >= 2) { throw new Error('This list is !multiple but has multiple selections') }
        selectedItems = areItemsEqual(state.selectedItems[0], item) ? [] : [item]
      } else {
        const selectedIndex = selectedItems.findIndex((selectedItem) => areItemsEqual(selectedItem, item))
        selectedIndex > -1 ? selectedItems.splice(selectedIndex, 1) : selectedItems.push(item)
      }

      return { ...state, selectedItems: [...selectedItems], focusedItemIndex: index }
    }
    case 'itemFocus':
      return { ...state, focusedItemIndex: action.focusedItemIndex }
    case 'updateItem': {
      const index = state.data.findIndex((item) => itemIdentifier(item) === itemIdentifier(action.newItem))
      if (index === -1) { return state }
      const data = [...state.data]
      data[index] = action.newItem
      return { ...state, data, listData: toListData(data) }
    }
    case 'updateItems': {
      if (!action.updatedItems || action.updatedItems.length === 0) {
        return state
      }
      const updatedData = state.data.map((item) => {
        const updatedItem = action.updatedItems.find((updatedItem) => itemIdentifier(updatedItem) === itemIdentifier(item))
        return updatedItem || item
      })
      return { ...state, data: updatedData, listData: toListData(updatedData) }
    }
    case 'newItems': {
      if (!action.items || action.items.length === 0) {
        return state
      }
      const updatedData = [...state.data, ...action.items]
      return { ...state, data: updatedData, listData: toListData(updatedData) }
    }
    case 'removeItem': {
      const index = state.data.findIndex((item) => itemIdentifier(item) === action.itemIdentifier)
      if (index === -1) { return state }
      const data = [...state.data]
      data.splice(index, 1)
      return { ...state, data, listData: toListData(data) }
    }
    case 'removeItems': {
      if (!action.itemIdentifiers || action.itemIdentifiers.length === 0) {
        return state
      }
      const newData = state.data.filter(
        (item) => !action.itemIdentifiers.includes(itemIdentifier(item))
      )
      return { ...state, data: newData, listData: toListData(newData) }
    }
    case 'showRefreshBanner': {
      return { ...state, showRefreshBanner: true }
    }
    case 'newPageSize': { return { ...state, pageSize: action.pageSize } }
    case 'newQueryOverrides': { return { ...state, queryOverrides: action.queryOverrides } }
    case 'newResultsFields': { return { ...state, resultsFields: action.resultsFields } }
    default:
      throw new Error(`Unknown action: ${action?.type}`)
  }
}

export const itemIdentifier = (item) => item?._id || item?.name
export const areItemsEqual = (item1, item2) => itemIdentifier(item1) === itemIdentifier(item2)
const itemIndexFromEvent = (event) => {
  const target = event.target.closest('[data-list-index]')
  const listIndex = target?.dataset?.listIndex
  return !listIndex ? -1 : parseInt(listIndex, 10)
}

export default List
