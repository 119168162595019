import * as React from "react";
import { memo } from "react";
const SvgInvertedAiva = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 29"
    {...props}
  >
    <path
      fill="#fff"
      d="M32.62 0h-2.09c-1.32 0-2.5.89-2.86 2.16l-.61 2.13c-2.45-.3-4.9-.46-7.31-.46-2.35 0-4.71.15-7.05.43l-.6-2.11A2.988 2.988 0 0 0 9.24-.01H7.15c-1.4 0-2.63 1-2.91 2.37-.13.65-.28 1.3-.42 1.95-.36 1.63-.73 3.32-.97 5.05l-.03.23c-.02.19-.06.47-.1.66-.92.42-2.29 1.19-2.53 3.15-.25 1.98-.25 3.96-.01 5.87a3.94 3.94 0 0 0 2.36 3.13 6.572 6.572 0 0 0 5.32 5.35c4.11.75 8.24 1.13 12.26 1.13s7.94-.37 11.81-1.1c2.7-.51 4.8-2.63 5.28-5.3a3.98 3.98 0 0 0 2.59-3.19c.28-2 .28-4.01 0-5.96-.16-1.12-.8-2.13-1.75-2.75-.33-.22-.66-.34-.95-.41-.3-1.9-.7-3.77-1.09-5.59-.16-.74-.32-1.48-.47-2.22a2.975 2.975 0 0 0-2.91-2.37l-.01.01Z"
    />
    <path
      fill="#C5C5C5"
      d="M22.51 19.49h-4.83c-.46 0-.78.46-.62.89a3.238 3.238 0 0 0 3.03 2.11c1.39 0 2.58-.88 3.03-2.11a.661.661 0 0 0-.62-.89h.01Z"
    />
    <path
      fill="#6366F1"
      d="M22.51 19.97h-4.83c-.46 0-.78.46-.62.89a3.238 3.238 0 0 0 3.03 2.11c1.39 0 2.58-.88 3.03-2.11a.661.661 0 0 0-.62-.89h.01Z"
    />
    <path
      fill="#C5C5C5"
      d="M29.13 12.54a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.52-1.07 1.07-.24 1.71-.25 3.41 0 5.11.08.56.52.99 1.08 1.07.49.06.97.06 1.45 0 .55-.07.99-.5 1.08-1.05.28-1.76.28-3.48 0-5.15-.09-.54-.52-.96-1.06-1.04l-.01-.01Z"
    />
    <path
      fill="#fff"
      d="M29.13 13.54a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.52-1.07 1.07-.24 1.71-.25 3.41 0 5.11.08.56.52.99 1.08 1.07.49.06.97.06 1.45 0 .55-.07.99-.5 1.08-1.05.28-1.76.28-3.48 0-5.15-.09-.54-.52-.96-1.06-1.04l-.01-.01Z"
    />
    <path
      fill="#6366F1"
      d="M29.13 13.18a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.52-1.07 1.07-.24 1.71-.25 3.41 0 5.11.08.56.52.99 1.08 1.07.49.06.97.06 1.45 0 .55-.07.99-.5 1.08-1.05.28-1.76.28-3.48 0-5.15-.09-.54-.52-.96-1.06-1.04l-.01-.01Z"
    />
    <path
      fill="#C5C5C5"
      d="M12.07 12.54a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.52-1.07 1.07-.24 1.71-.25 3.41 0 5.11.08.56.52.99 1.08 1.07.49.06.97.06 1.45 0 .55-.07.99-.5 1.08-1.05.28-1.76.28-3.48 0-5.15-.09-.54-.52-.96-1.06-1.04l-.01-.01Z"
    />
    <path
      fill="#fff"
      d="M12.07 13.54a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.53-1.07 1.09-.24 1.75-.25 3.49 0 5.22.08.57.52 1.02 1.08 1.09.49.06.97.06 1.45 0 .55-.07.99-.51 1.08-1.07.28-1.8.28-3.55 0-5.26-.09-.55-.52-.99-1.06-1.06l-.01-.01Z"
    />
    <path
      fill="#6366F1"
      d="M12.07 13.18a5.17 5.17 0 0 0-1.47 0c-.56.08-.99.52-1.07 1.07-.24 1.71-.25 3.41 0 5.11.08.56.52.99 1.08 1.07.49.06.97.06 1.45 0 .55-.07.99-.5 1.08-1.05.28-1.76.28-3.48 0-5.15-.09-.54-.52-.96-1.06-1.04l-.01-.01Z"
    />
    <path
      fill="#C5C5C5"
      d="M2.03 16.49c.02.68.07 1.35.15 2.03.12.93.89 1.64 1.82 1.7.11 0 .22 0 .33.01.06.43.12.87.19 1.31a4.57 4.57 0 0 0 3.71 3.75c7.82 1.43 15.6 1.49 23.34.03 1.92-.36 3.4-1.88 3.69-3.78.07-.44.13-.87.18-1.31h.55c.94-.05 1.7-.77 1.83-1.7.1-.68.16-1.36.18-2.03h-4.26c-.03 1.32-.15 2.64-.36 3.96a3.921 3.921 0 0 1-3.17 3.24c-6.84 1.24-13.72 1.19-20.63-.03a3.922 3.922 0 0 1-3.19-3.21c-.22-1.33-.35-2.65-.39-3.96H2.02l.01-.01Z"
    />
    <path
      fill="#6366F1"
      d="M37.81 13.63c-.08-.56-.4-1.06-.87-1.37-.48-.32-1.22-.11-1.55-.61-.13-.19-.16-.43-.2-.66-.4-2.77-1.06-5.47-1.62-8.21a.974.974 0 0 0-.95-.78h-2.09c-.43 0-.82.29-.93.71l-1.08 3.81c-5.86-.9-11.61-.9-17.26-.03l-1.07-3.78A.972.972 0 0 0 9.26 2H7.17c-.46 0-.86.32-.95.78-.47 2.29-1.05 4.55-1.36 6.87-.12.86-.19 1.96-1.02 2.32-.82.35-1.51.67-1.64 1.69-.22 1.8-.23 3.59-.01 5.37.12.93.89 1.64 1.82 1.7.11 0 .22 0 .33.01.06.43.12.87.19 1.31a4.57 4.57 0 0 0 3.71 3.75c7.82 1.43 15.6 1.49 23.34.03 1.92-.36 3.4-1.88 3.69-3.78.07-.44.13-.87.18-1.31H36c.94-.05 1.7-.77 1.83-1.7.26-1.84.25-3.64 0-5.4l-.02-.01Zm-4.44 7.33a3.921 3.921 0 0 1-3.17 3.24c-6.84 1.24-13.72 1.19-20.63-.03a3.922 3.922 0 0 1-3.19-3.21c-.53-3.17-.53-6.28 0-9.34.27-1.59 1.5-2.84 3.07-3.17 1.3-.27 2.61-.49 3.92-.65l1.85 2.82c.28.42.77.68 1.29.68h6.74c.52 0 1-.25 1.28-.67l1.89-2.81c1.27.16 2.55.37 3.83.63 1.61.32 2.85 1.6 3.11 3.22.49 3.09.49 6.18 0 9.28l.01.01Z"
    />
  </svg>
);
const Memo = memo(SvgInvertedAiva);
export default Memo;
