import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import AddAction from '../../../../components/admin/spammers/actions/AddAction'
import RemoveAction from '../../../../components/admin/spammers/actions/RemoveAction'
import PanelHeaderButton from '../../../../components/panels/panel-header/PanelHeaderButton'
import { Minus, Plus, Reload } from '../../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const ListHeaderMoreMenu = ({ listRef }) => {
  const createActionRef = useRef()
  const removeActionRef = useRef()

  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])
  const handleRemoveSelected = useCallback(() => removeActionRef.current.activate(), [])
  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon={<Plus />} title='Add Spammer' onClick={handleCreateSelected} />
      <PanelHeaderButton icon={<Minus />} title='Remove Spammer' onClick={handleRemoveSelected} />
      <AddAction ref={createActionRef} />
      <RemoveAction ref={removeActionRef} />
    </>
  )
}

ListHeaderMoreMenu.displayName = 'ListHeaderMoreMenu'
ListHeaderMoreMenu.propTypes = propTypes
ListHeaderMoreMenu.defaultProps = defaultProps

export default ListHeaderMoreMenu
