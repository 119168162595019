import { useElements, useStripe } from '@stripe/react-stripe-js'
import { pick } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import ReCaptcha from 'react-google-recaptcha'
import { useWizard } from 'react-use-wizard'

import { error as errorBanner } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import SubscriptionSummary from '../../../components/plans/SubscriptionSummary'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { trackPurchase } from '../../../lib/analytics'
import { getCookie } from '../../../lib/cookies'
import logger from '../../../lib/logger'
import { generate } from '../../../lib/shortid'
import { allow as allowIntegrationService } from '../../../services/integration'
import { signUp as signUpService } from '../../../services/subscription'
import { initiateCheckout as trackInitiateCheckoutService } from '../../../services/tracking'
import Header from '../shared/Header'

import AffiliatePromo from './AffiliatePromo'
import validationSuite from './ManagedNumberForm.validations'
import ReferralPromo from './ReferralPromo'
import SecureCheckout from './SecureCheckout'

const propTypes = {
  affiliate: PropType.shape({
    name: PropType.string.isRequired
  }),
  integration: PropType.shape({
    key: PropType.string.isRequired
  }),
  numbers: PropType.objectOf(
    PropType.arrayOf(
      PropType.shape({
        nationalNumber: PropType.string.isRequired,
        number: PropType.string.isRequired
      })
    ).isRequired
  ),
  referral: PropType.shape({
    frequency: PropType.string,
    percentOff: PropType.number,
    referralCredits: PropType.number,
    referrerName: PropType.string,
    refId: PropType.string
  }),
  selectedPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    value: PropType.number.isRequired
  }),
  onSuccess: PropType.func
}

const defaultProps = {
  affiliate: null,
  integration: null,
  numbers: null,
  onSuccess: null,
  referral: null,
  selectedPlan: null
}

const formSettings = {
  id: 'selectNumberForm',
  defaultValues: {},
  validationSuite
}

const log = logger({ enabled: false, tags: ['Sign-up', 'ManagedNumberForm'] })

const ManagedNumberForm = ({ numbers, selectedPlan, onSuccess, integration, affiliate, referral, ...formData }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const captchaValue = useRef('')
  const { goToStep } = useWizard()
  const stripe = useStripe()
  const elements = useElements()
  const optionsData = []
  const selectedNumber = useRef(null)
  const formRef = useRef()
  const recaptchaRef = useRef(null)
  const initiateCheckoutEventId = generate()
  const purchaseEventId = generate()

  const redirectToWelcomeScreen = useCallback(() => {
    const mergedData = { ...formData, phoneNumber: selectedNumber.current }
    onSuccess(mergedData)
    goToStep(4)
  }, [formData, goToStep, onSuccess])

  const handleAllowReplyOk = useCallback((reply) => {
    const redirect = reply.json?.redirect
    if (redirect) {
      // redirect back to integration site
      window.location.replace(redirect)
    } else {
      redirectToWelcomeScreen()
    }
  }, [redirectToWelcomeScreen])
  const { call: allowIntegration } = useService(allowIntegrationService, { onReplyOk: handleAllowReplyOk })

  const trackInitiateCheckout = useCallback(({ firstName, lastName, email, stateProvince }) => {
    const trackingData = {
      firstName,
      lastName,
      email,
      stateProvince,
      eventId: initiateCheckoutEventId,
      eventSourceUrl: window.location.href,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp')
    }
    window.fbq('track', 'InitiateCheckout', {}, { eventID: initiateCheckoutEventId })
    window.gtag('event', 'begin_checkout')
    trackInitiateCheckoutService(trackingData).call()
  }, [initiateCheckoutEventId])

  const handleSignUpReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const handleSignUpReplyOk = useCallback((data) => {
    trackPurchase({ selectedPlan, eventId: purchaseEventId })
    if (data?.json?.phoneNumberType === 'needs-provisioning') {
      const mergedData = { ...formData, numbers, phoneNumber: selectedNumber.current }
      onSuccess(mergedData)
      goToStep(3) // select another number
    } else if (integration) {
      allowIntegration(integration.key, window.location.search)
    } else {
      redirectToWelcomeScreen()
    }
  }, [allowIntegration, formData, goToStep, integration, numbers, onSuccess, purchaseEventId, redirectToWelcomeScreen, selectedPlan])
  const { call: signUp } = useService(signUpService, { onReplyOk: handleSignUpReplyOk, onReply: handleSignUpReply })

  if (numbers) {
    for (const key in numbers) {
      optionsData.push({
        label: key,
        value: numbers[key].map((info) => {
          return {
            label: info.nationalNumber,
            value: info.number
          }
        })
      })
    }
  }

  formSettings.onSubmit = useCallback(async ({ number }) => {
    selectedNumber.current = number
    const card = elements.getElement('card')
    const { error, token } = await stripe.createToken(card)

    if (error) {
      const isCustomerFriendlyStripeErrorType = error.type === 'card_error'
      const errorMessage = isCustomerFriendlyStripeErrorType
        ? error.message
        : 'Something went wrong. Please try again later.'
      errorBanner('Oh no!', errorMessage)
      return
    }

    const captcha = captchaValue.current
    const stripeResult = {
      id: token.id,
      card: pick(token.card, ['id', 'last4', 'brand', 'exp_month', 'exp_year'])
    }
    const data = {
      ...formData,
      eventId: initiateCheckoutEventId,
      eventSourceUrl: window?.location?.href,
      number,
      captcha,
      stripeResult
    }
    const facebookAdClickId = getCookie('_fbc')
    if (facebookAdClickId) {
      data.signupReferral = {
        source: 'facebook',
        fbc: facebookAdClickId,
        fbp: getCookie('_fbp')
      }
    }

    log.debug('call signUp with', data)

    trackInitiateCheckout(data)

    data.eventId = purchaseEventId
    signUp(data)
    recaptchaRef.current.reset()
  }, [elements, stripe, formData, initiateCheckoutEventId, trackInitiateCheckout, purchaseEventId, signUp])

  formSettings.formControls = [{
    autoFocus: true,
    name: 'number',
    type: 'select',
    label: 'Project Broadcast Phone Number',
    placeholder: 'Choose a phone number',
    hint: 'This will be the phone number all of your Project Broadcast messages originate from.',
    required: true,
    options: optionsData
  },
  {
    name: 'card',
    type: 'stripecardelement',
    label: 'Payment Info',
    hint: `Your card will be charged ${selectedPlan.frequency.toLowerCase()}.`
  }]

  const handleGoBack = useCallback(() => {
    goToStep(0)
  }, [goToStep])

  const handleReCaptchaChange = useCallback((value) => {
    log.debug('ReCaptcha Value:', value)
    captchaValue.current = value
  }, [])

  if (!numbers) { return }

  return (
    <>
      <Header
        goBack={handleGoBack}
        heading='Create Account'
        title='Select a Number'
      />
      <div className='mt-5'>
        {integration
          ? null
          : (
              affiliate
                ? <AffiliatePromo
                    plan={selectedPlan}
                    referrerName={affiliate.name}
                  />
                : (
                    referral
                      ? <ReferralPromo
                          credits={referral.referralCredits}
                          frequency={referral.frequency}
                          percentOff={referral.percentOff}
                          referrerName={referral.referrerName}
                        />
                      : null
                  )
            )}
        <Form {...formSettings} ref={formRef} preventMultipleSubmits />
        <div className='paragraph-sm mb-4'>
          <SubscriptionSummary plan={selectedPlan} />
        </div>
        <ReCaptcha
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleReCaptchaChange}
        />
        <Button
          className='my-4 w-full'
          form='selectNumberForm'
          size='md'
          type='submit'
        >
          Complete Sign-Up
        </Button>
        <SecureCheckout />
      </div>
    </>
  )
}

ManagedNumberForm.displayName = 'ManagedNumberForm'
ManagedNumberForm.propTypes = propTypes
ManagedNumberForm.defaultProps = defaultProps

export default ManagedNumberForm
