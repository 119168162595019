import { useCallback, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const DoubleOptIn = () => {
  const currentUser = useCurrentUser()
  const [automatedCheckboxDisabled, setAutomatedCheckboxDisabled] = useState(!currentUser.requireDoubleOptIn)

  const handleReplyOkay = useCallback(() => {
    notify('Double Opt In updated successfully.')
  }, [])
  const { call: updateDoubleOptIn } = useServiceAndAction(updateProfileService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='doubleOptInForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Double Opt In'
      />
      <PanelContent className='p-5'>
        <Form
          defaultValues={{
            requireDoubleOptIn: currentUser.requireDoubleOptIn,
            enableAutoDoubleOptInMessage: currentUser.enableAutoDoubleOptInMessage
          }}
          formControls={[
            {
              type: 'checkbox',
              name: 'requireDoubleOptIn',
              label: 'Ensure messages are only sent to contacts that have provided explicit consent via Project Broadcast.',
              onChange: function (e) {
                setAutomatedCheckboxDisabled(!e.target.checked)
              },
              title: 'Require Contact Double Opt In'
            },
            {
              type: 'checkbox',
              name: 'enableAutoDoubleOptInMessage',
              label: 'Send an automated opt-in message when an incoming message is received from a contact which has not opted in. Project Broadcast will only send an automated opt-in if the contact has not been invited to opt-in within the past 7 days.',
              title: 'Enable Automated Double Opt In Message',
              style: automatedCheckboxDisabled ? { visibility: 'hidden' } : {}
            }
          ]}
          id='doubleOptInForm'
          onSubmit={updateDoubleOptIn}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='doubleOptInForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

DoubleOptIn.displayName = 'DoubleOptIn'

export default DoubleOptIn
