import React from 'react'

import PanelNotFound from '../../components/panels/PanelNotFound'
import AddCredits from '../../screens/admin/users/AddCredits'
import AuditHistory from '../../screens/admin/users/AuditHistory'
import CampaignRegistration from '../../screens/admin/users/CampaignRegistration'
import Detail from '../../screens/admin/users/Detail'
import EditCampaign from '../../screens/admin/users/EditCampaign'
import ExportPaymentHistory from '../../screens/admin/users/ExportPaymentHistory'
import MultiFactorAuthentication from '../../screens/admin/users/MultiFactorAuthentication'
import ReferralCode from '../../screens/admin/users/ReferralCode'
import RestoreCancelledCredits from '../../screens/admin/users/RestoreCancelledCredits'
import SetPassword from '../../screens/admin/users/SetPassword'
import UnsentMessages from '../../screens/admin/users/UnsentMessages'
import UpdateNumber from '../../screens/admin/users/UpdateNumber'

const routes = [
  {
    path: ':userId',
    element: <Detail />
  },
  {
    path: ':userId/add-credits',
    element: <AddCredits />
  },
  {
    path: ':userId/apply-referral-code',
    element: <ReferralCode />
  },
  {
    path: ':userId/audit-history',
    element: <AuditHistory />
  },
  {
    path: ':userId/campaign-registration',
    element: <CampaignRegistration />
  },
  {
    path: ':userId/campaign-registration/edit',
    element: <EditCampaign />
  },
  {
    path: ':userId/export-payment-history',
    element: <ExportPaymentHistory />
  },
  {
    path: ':userId/multi-factor-authentication',
    element: <MultiFactorAuthentication />
  },
  {
    path: ':userId/restore-cancelled-credits',
    element: <RestoreCancelledCredits />
  },
  {
    path: ':userId/set-password',
    element: <SetPassword />
  },
  {
    path: ':userId/unsent-messages',
    element: <UnsentMessages />
  },
  {
    path: ':userId/update-number',
    element: <UpdateNumber />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
