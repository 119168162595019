import PropType from 'prop-types'
import { forwardRef } from 'react'

import { MoreHorizontal } from '../../svg/icons'
import Button from '../buttons/Button'

const propTypes = {
  ariaSubject: PropType.string.isRequired
}

const defaultProps = {}

const DetailSectionMoreButton = forwardRef(({ ariaSubject, ...rest }, ref) => {
  return (
    <Button
      ref={ref}
      className='pb-0'
      icon={<MoreHorizontal />}
      size='md'
      variant='text'
      {...rest}
    >
      {ariaSubject}
      {' '}
      Action Menu
    </Button>
  )
})

DetailSectionMoreButton.displayName = 'DetailSectionMoreButton'
DetailSectionMoreButton.propTypes = propTypes
DetailSectionMoreButton.defaultProps = defaultProps

export default DetailSectionMoreButton
