import { useCallback, useRef } from 'react'

import List from '../../components/list/List'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useBus from '../../hooks/useBus'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as integrationsSearch } from '../../services/integration'

import ConnectDialog from './integrations/ConnectDialog'
import IntegrationListEmptyContent from './integrations/ListEmptyContent'
import IntegrationListHeaderMoreMenu from './integrations/ListHeaderMoreMenu'
import IntegrationListItemContent from './integrations/ListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Integrations'] })

const Integrations = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()

  const reloadList = useCallback(() => {
    listRef.current.reload()
  }, [])
  useBus('integrationAllowedOrDenied', reloadList)
  useBus('integrationDisconnected', reloadList)

  return (
    <Panel>
      <PanelHeader
        end={<IntegrationListHeaderMoreMenu listRef={listRef} />}
        start={<PanelHeaderBackButton />}
        title='Integrations'
      />
      <PanelContent>
        <List
          ref={listRef}
          EmptyListContent={IntegrationListEmptyContent}
          ListItemContent={IntegrationListItemContent}
          searchService={integrationsSearch}
          type='list'
        />
      </PanelContent>
      <ConnectDialog />
    </Panel>
  )
}

Integrations.displayName = 'Integrations'
Integrations.propTypes = propTypes
Integrations.defaultProps = defaultProps

export default Integrations
