import classNames from 'classnames'
import PropType from 'prop-types'
import { memo, useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { hasUnreadMessages } from '../../helpers/dates'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import { itemIdentifier } from './List'

const propTypes = {
  id: PropType.string.isRequired,
  index: PropType.number.isRequired,
  isFocused: PropType.bool.isRequired,
  isSelected: PropType.bool.isRequired,
  item: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ListItemContent: PropType.elementType.isRequired,
  multiple: PropType.bool.isRequired,
  type: PropType.oneOf(['add', 'bulk', 'list', 'move', 'nav', 'remove', 'select']).isRequired,
  itemSharedContext: PropType.object, // eslint-disable-line react/forbid-prop-types
  next: PropType.object, // eslint-disable-line react/forbid-prop-types
  pathPrefix: PropType.string,
  prev: PropType.object, // eslint-disable-line react/forbid-prop-types
  stripPadding: PropType.bool
}

const defaultProps = {
  itemSharedContext: {},
  next: null,
  pathPrefix: null,
  prev: null,
  stripPadding: false
}

const log = logger({ enabled: false, tags: ['ListItem'] })

const selectionIndicatorWidth = '28px'
const ListItem = memo(({
  multiple,
  id,
  index,
  item,
  itemSharedContext,
  ListItemContent,
  next,
  pathPrefix,
  prev,
  stripPadding,
  type,
  isFocused,
  isSelected
}) => {
  const identifier = itemIdentifier(item)
  const [timestamp, setTimestamp] = useState(Date.now())

  useLogger({ log, lifecycle: true, tags: [id, identifier] })

  const unread = useMemo(() => {
    return hasUnreadMessages(item.lastMessageFrom?.time, item.lastMessageRead?.time)
  }, [item.lastMessageFrom?.time, item.lastMessageRead?.time])

  const className = classNames(
    'block flex flex-row flex-nowrap max-w-full overflow-auto',
    'border-b border-neutral-200',
    {
      'px-3.5 py-3': !stripPadding,
      'cursor-pointer': type !== 'list',
      'bg-neutral-50': isSelected && type !== 'list' && type !== 'nav',
      'bg-blue-50': (isFocused && type !== 'list') || unread,
      'active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50': type !== 'list'
    }
  )

  const computeNavLinkClassName = ({ isActive /* isPending */ }) => {
    return classNames(className, {
      'bg-neutral-50': isActive
    })
  }

  const content = (
    <ListItemContent
      item={item}
      itemSharedContext={itemSharedContext}
      next={next}
      prev={prev}
    />
  )
  const inputType = multiple ? 'checkbox' : 'radio'
  const selectionIndicator = useMemo(() => (['add', 'bulk', 'move', 'select', 'remove'].includes(type)
    ? (
      <div className='flex-end flex-initial self-center pl-2' style={{ width: selectionIndicatorWidth }}>
        <div data-checked={isSelected} data-type={inputType} />
      </div>
      )
    : null
  ), [type, isSelected, inputType])

  const updateTimestamp = useCallback(() => { setTimestamp(Date.now()) }, [])

  const listItemProps = {
    className,
    'data-list-index': index,
    id
  }
  if (['add', 'bulk', 'move', 'select', 'remove'].includes(type)) {
    listItemProps['aria-selected'] = isSelected
    listItemProps.role = 'option'
  }
  if (type === 'list') { listItemProps.role = 'listitem' }

  if (type === 'nav') {
    return (
      <NavLink
        state={{ invalidateCurrentInstance: timestamp }}
        tabIndex='-1'
        to={pathPrefix ? `${pathPrefix}/${identifier}` : identifier}
        onClick={updateTimestamp}
        {...listItemProps}
        className={computeNavLinkClassName}
      >
        {content}
      </NavLink>
    )
  } else {
    return (
      <div
        {...listItemProps}
      >
        <div className='flex flex-row flex-nowrap flex-grow' style={{ width: `calc(100% - ${selectionIndicatorWidth})` }}>
          {content}
        </div>
        {selectionIndicator}
      </div>
    )
  }
})

ListItem.displayName = 'ListItem'
ListItem.propTypes = propTypes
ListItem.defaultProps = defaultProps

export default ListItem
