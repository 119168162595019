import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import loadFromCacheOrService from '../lib/loadFromCacheOrService'
import logger from '../lib/logger'
import { localTimeZone, pbHqTimeZone } from '../lib/timeZones'
import { timeZones } from '../services/time-zone'
import useStore from '../store'

import useLogger from './useLogger'

const log = logger({ enabled: false, tags: ['useTimeZone'] })

const useTimeZone = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  useEffect(() => {
    loadFromCacheOrService('timeZones', timeZones)
  }, [])

  const { timezone: userTimeZone } = useStore(useShallow((state) => ({ timezone: state.currentUser?.timezone })))
  const { timeZones: dataForSelect } = useStore(useShallow((state) => ({ timeZones: state.remoteDataSources.timeZones })))

  return {
    loading: !dataForSelect,
    dataForSelect: dataForSelect || [{ label: 'Loading...', value: '' }],
    pbHqTimeZone,
    localTimeZone,
    userTimeZone: userTimeZone || localTimeZone || pbHqTimeZone
  }
}

export default useTimeZone
