import { forEach } from 'lodash'
import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateUserCustomFields as updateUserCustomFieldsService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const UserCustomFields = () => {
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('User Custom Fields updated successfully.')
  }, [])
  const { call: updateUserCustomFields } = useServiceAndAction(updateUserCustomFieldsService, updateUserAction, { onReplyOk: handleReplyOkay })

  const formControls = []
  const defaultValues = {}
  forEach(currentUser.userCustomFields, (field) => {
    formControls.push({
      type: 'text',
      name: field._id,
      label: field.name
    })
    defaultValues[field._id] = field.value
  })

  const formProps = {
    id: 'userCustomFieldsForm',
    defaultValues,
    formControls,
    onSubmit: updateUserCustomFields
  }

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='userCustomFieldsForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='User Custom Fields'
      />
      <PanelContent className='p-4'>
        <div className='pb-4'>To define the names of these custom fields please visit Custom Field Names under Settings.</div>
        <Form {...formProps} />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='userCustomFieldsForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

UserCustomFields.displayName = 'UserCustomFields'

export default UserCustomFields
