import * as React from "react";
import { memo } from "react";
const SvgCurrent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Current_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 .875c.621 0 1.125.504 1.125 1.125v3.636a1.125 1.125 0 0 1-2.25 0v-2.44A8.87 8.87 0 0 0 3.125 12 8.87 8.87 0 0 0 12 20.875 8.87 8.87 0 0 0 20.875 12a8.797 8.797 0 0 0-.967-4.032 1.125 1.125 0 0 1 2.002-1.027A11.048 11.048 0 0 1 23.125 12 11.12 11.12 0 0 1 12 23.125 11.12 11.12 0 0 1 .875 12 11.12 11.12 0 0 1 12 .875Zm2.84 6.693a1.125 1.125 0 0 1 1.592 1.591l-3.637 3.637a1.125 1.125 0 1 1-1.59-1.591l3.636-3.637Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Current_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgCurrent);
export default Memo;
