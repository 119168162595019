import PropType from 'prop-types'
import { NavLink } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ChevronRight } from '../../../svg/icons'

const propTypes = {
  label: PropType.string.isRequired,
  to: PropType.oneOfType([
    PropType.string,
    PropType.shape({ pathname: PropType.string }) // a location object
  ]).isRequired,
  arrow: PropType.bool
}

const defaultProps = {
  arrow: true
}

const log = logger({ enabled: false, tags: ['NavLinkItem'] })

const NavLinkItem = ({ arrow, label, to }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <li className='label-sm flex flex-row active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50'>
      <NavLink className='w-full flex flex-row items-center p-[10px]' to={to}>
        <span className='label-sm'>{label}</span>
        {arrow ? <ChevronRight className='relative top-[-2px] self-end ml-auto' height='14' width='14' /> : null}
      </NavLink>
    </li>
  )
}

NavLinkItem.displayName = 'NavLinkItem'
NavLinkItem.propTypes = propTypes
NavLinkItem.defaultProps = defaultProps

export default NavLinkItem
