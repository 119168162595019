import PropType from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  context: PropType.shape({
    EmptyListContent: PropType.elementType,
    loading: PropType.bool
  }).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['EmptyListContentContainer'] })

const EmptyListContentContainer = ({ context }) => {
  useLogger({ log, lifecycle: false })

  const delayTimer = useRef(null)
  const [showEmptyContent, setShowEmptyContent] = useState(false)

  useEffect(() => {
    if (!context.EmptyListContent) { return }

    clearTimeout(delayTimer.current)
    if (context.loading) {
      setShowEmptyContent(false)
    } else {
      delayTimer.current = setTimeout(() => setShowEmptyContent(true), 125)
    }
    return () => clearTimeout(delayTimer.current)
  }, [context])

  return (showEmptyContent && context.EmptyListContent ? <context.EmptyListContent context={context} /> : null)
}

EmptyListContentContainer.displayName = 'EmptyListContentContainer'
EmptyListContentContainer.propTypes = propTypes
EmptyListContentContainer.defaultProps = defaultProps

export default EmptyListContentContainer
