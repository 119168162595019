import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import BulkEditActions from '../../../components/spaces/actions/BulkEditActions'
import CreateAction from '../../../components/spaces/actions/CreateAction'
import SpaceList from '../../../components/spaces/SpaceList'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListHeaderMore'] })

const ListHeaderMore = ({ listRef }) => {
  const createActionRef = useRef()
  const bulkEditRef = useRef()

  useLogger({ log, lifecycle: false, tags: [] })

  const handleBulkEditClick = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])
  const handleReloadList = useCallback(() => listRef.current.reload(), [listRef])
  const handleNewSpace = useCallback(() => { createActionRef.current.activate() }, [])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadList} />
      <PanelHeaderButton icon={<Plus />} title='New Space' onClick={handleNewSpace} />
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuHeader label='Manage Spaces' />
        <ActionMenuDivider />
        <ActionMenuItem label='Edit Spaces' onClick={handleBulkEditClick} />
      </ActionMenu>
      <BulkEditDialog ref={bulkEditRef} actions={BulkEditActions} list={SpaceList} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

ListHeaderMore.displayName = 'ListHeaderMore'
ListHeaderMore.propTypes = propTypes
ListHeaderMore.defaultProps = defaultProps

export default ListHeaderMore
