import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import AttachmentList from '../../../components/attachments/AttachmentList'
import { success } from '../../../components/banners/Banner'
import CampaignList from '../../../components/campaigns/CampaignList'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import EmailList from '../../../components/emails/EmailList'
import KeywordList from '../../../components/keywords/KeywordList'
import LandingPageList from '../../../components/landing-pages/LandingPageList'
import MessageTemplateList from '../../../components/message-templates/MessageTemplateList'
import VoiceDropList from '../../../components/voice-drops/VoiceDropList'
import { bundleContentTypeLabel, bundleContentTypes } from '../../../helpers/bundle'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import bundleShape from '../../../prop-types/shapes/bundle'
import {
  addAllInQuery as addAllInQueryService,
  addSelected as addSelectedService
} from '../../../services/bundle-content '
import {
  Attach,
  Browser,
  Campaign,
  ChatText,
  Email,
  Key,
  Microphone
} from '../../../svg/icons'

const contentListMap = {
  attachments: AttachmentList,
  campaigns: CampaignList,
  emails: EmailList,
  keywords: KeywordList,
  landingPages: LandingPageList,
  messageTemplates: MessageTemplateList,
  voiceDrops: VoiceDropList
}

const contentIconMap = {
  attachments: (<Attach />),
  campaigns: (<Campaign />),
  emails: (<Email />),
  keywords: (<Key />),
  landingPages: (<Browser />),
  messageTemplates: (<ChatText />),
  voiceDrops: (<Microphone />)
}

const propTypes = {
  bundle: PropType.shape(bundleShape).isRequired,
  contentType: PropType.oneOf(bundleContentTypes).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: false, tags: ['ContentDetailSection'] })

const ContentDetailSection = ({ bundle, contentType, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [bundle?._id, contentType] })

  const listDialogRef = useRef()

  const { contentName, count, title, description, value, addContentTitle } = useMemo(() => {
    const contentName = bundleContentTypeLabel(contentType)
    const count = bundle?.[contentType]?.length || 0
    return {
      contentName,
      count,
      title: `${contentName}s`,
      description: `A bundle can have one or more ${contentName.toLowerCase()}s.`,
      value: bundle?.[`${contentType}Summary`],
      addContentTitle: `Add ${contentName}s`
    }
  }, [bundle, contentType])

  const handleInitialEdit = useCallback(() => { listDialogRef.current.open() }, [])

  const handleReplyOk = useCallback((reply) => {
    success(`${contentName}(s) added`)
    onChange?.(reply)
  }, [contentName, onChange])
  const { call: addSelected } = useService(addSelectedService, { onReplyOk: handleReplyOk })
  const { call: addAllInQuery } = useService(addAllInQueryService, { onReplyOk: handleReplyOk })

  const handleAddSelected = useCallback((selected) => {
    addSelected({
      bundleId: bundle._id,
      contentType,
      [`${contentType}`]: selected.map((x) => x._id)
    })
  }, [addSelected, bundle, contentType])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQuery({
      bundleId: bundle._id,
      contentType,
      ...query
    })
  }, [addAllInQuery, bundle, contentType])

  return (
    <>
      <DetailSection
        count={count}
        description={description}
        editHref={contentType}
        editIcon='chevron'
        initialEditButtonText={addContentTitle}
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title={title}
        titleIcon={contentIconMap[contentType]}
        onInitialEditClick={handleInitialEdit}
      >
        {value}
      </DetailSection>
      <ListDialog
        ref={listDialogRef}
        list={contentListMap[contentType]}
        subtitle={bundle?.formattedName}
        title={addContentTitle}
        type='add'
        multiple
        search
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </>
  )
}

ContentDetailSection.displayName = 'ContentDetailSection'
ContentDetailSection.propTypes = propTypes
ContentDetailSection.defaultProps = defaultProps

export default ContentDetailSection
