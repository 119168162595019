import * as React from "react";
import { memo } from "react";
const SvgReload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.94 9.344C4.31 5.596 7.712 3 11.63 3c3.428 0 6.456 1.988 8.085 5H15a1 1 0 1 0 0 2h7a1 1 0 0 0 1-1V2a1 1 0 1 0-2 0v4.25C18.968 3.094 15.547 1 11.63 1 6.787 1 2.69 4.204 1.061 8.656a1 1 0 1 0 1.878.688ZM1 15a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H4.286c1.628 3.012 4.657 5 8.084 5 3.918 0 7.32-2.596 8.691-6.344a1 1 0 0 1 1.879.688C21.31 19.796 17.213 23 12.37 23 8.454 23 5.032 20.904 3 17.75V22a1 1 0 1 1-2 0v-7Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgReload);
export default Memo;
