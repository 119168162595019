import { useCallback, useEffect, useId, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import {
  resetPasswordWithToken as resetPasswordWithTokenService,
  verifyPasswordResetToken as verifyPasswordResetTokenService
} from '../../../services/user'
import Header from '../shared/Header'
import PanelWrapper from '../shared/PanelWrapper'

import validationSuite from './Form.validations'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['ResetPasswordForm'] })

const ResetPasswordForm = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formRef = useRef()
  const formId = useId()

  const [tokenIsVerified, setTokenIsVerified] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()

  const handleResetPasswordOk = useCallback(() => {
    log.debug('password reset')
    notify('Password Reset', 'Your password has been reset. Please login with your new password.')
    navigate('/auth')
  }, [navigate])
  const { call: resetPasswordWithToken } = useService(resetPasswordWithTokenService, { onReplyOk: handleResetPasswordOk })

  const handleSubmit = useCallback(({ password }) => {
    log.debug('handleSubmit', { password, token })
    resetPasswordWithToken(token, password)
  }, [resetPasswordWithToken, token])

  const handleReplyOk = useCallback(() => {
    log.debug('token verified')
    setTokenIsVerified(true)
  }, [])
  const handleReplyNotOk = useCallback(() => {
    log.debug('token invalid')
    navigate('/auth')
  }, [navigate])
  const { call: verifyPasswordResetToken } = useService(verifyPasswordResetTokenService, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })

  useEffect(() => {
    log.debug('verifying token')
    verifyPasswordResetToken(token)
  }, [token, verifyPasswordResetToken])

  if (!tokenIsVerified) { return null }

  return (
    <PanelWrapper>
      <Header title='Reset Password' />
      <Form
        ref={formRef}
        className='mt-4'
        defaultValues={{ password: '' }}
        formControls={[
          {
            autoComplete: 'new-password',
            type: 'password',
            name: 'password',
            label: 'New Password',
            hint: 'Password must be 8 or more characters with at least 1 letter and 1 number.',
            placeholder: 'Password',
            required: true,
            autoFocus: true
          }
        ]}
        id={formId}
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <Button
        className='mt-5 w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Reset Password
      </Button>
    </PanelWrapper>
  )
}

ResetPasswordForm.displayName = 'ResetPasswordForm'
ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

export default ResetPasswordForm
