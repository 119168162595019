import { useEffect } from 'react'

import bus from '../lib/bus'

const useBus = (name, fn) => {
  useEffect(() => {
    bus.on(name, fn)
    return () => bus.off(name, fn)
  }, [name, fn])
}

export default useBus
