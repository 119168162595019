import * as React from "react";
import { memo } from "react";
const SvgMessageTemplate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width={24} height={24} fill="#E1E7FF" rx={4} />
    <path
      fill="#5046E5"
      fillRule="evenodd"
      d="M10.333 4a6.33 6.33 0 0 0-1.667 12.44v2.227a.667.667 0 0 0 1.037.554l3.832-2.554h.132A6.337 6.337 0 0 0 20 10.333 6.337 6.337 0 0 0 13.667 4h-3.334Zm-5 6.333c0-2.758 2.242-5 5-5h3.334c2.758 0 5 2.242 5 5 0 2.759-2.242 5-5 5h-.334a.667.667 0 0 0-.37.112L10 17.421v-1.514c0-.323-.232-.6-.55-.657a4.998 4.998 0 0 1-4.117-4.917Zm2.667-1c0-.368.298-.666.667-.666h6.666a.667.667 0 1 1 0 1.333H8.667A.667.667 0 0 1 8 9.333ZM10 12c0-.368.299-.667.667-.667h2.666a.667.667 0 0 1 0 1.334h-2.666A.667.667 0 0 1 10 12Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMessageTemplate);
export default Memo;
