import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import EmailsDetail from '../screens/emails/EmailsDetail'
import EmailsFolderDetail from '../screens/emails/EmailsFolderDetails'

const emailRoutes = [
  {
    path: ':emailId',
    element: <EmailsDetail />
  }
]

const routes = [
  ...emailRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<EmailsFolderDetail />} />,
    children: [...emailRoutes]
  }
]

export default routes
