import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Block } from '../../../svg/icons'

const propTypes = {
  doNotEmail: PropType.bool.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DoNotEmailBadge'] })

const DoNotEmailBadge = ({ doNotEmail }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (doNotEmail !== true) { return null }

  return (
    <div className='flex flex-row flex-nowrap items-center pt-1'>
      <Block className='text-neutral-500' height='16' width='16' />
      <span className='pl-1 label-xs text-neutral-500'>
        Do Not Email
      </span>
    </div>
  )
}

DoNotEmailBadge.displayName = 'DoNotEmailBadge'
DoNotEmailBadge.propTypes = propTypes
DoNotEmailBadge.defaultProps = defaultProps

export default DoNotEmailBadge
