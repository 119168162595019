import * as React from "react";
import { memo } from "react";
const SvgLandingPages = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 53 48"
    {...props}
  >
    <mask id="LandingPages_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
      clipRule="evenodd"
    />
    <path
      fill="#6B7380"
      d="m21.1 6.79-1.998 1.504.75.997H21.1v-2.5Zm-.6-.796-1.997 1.503L20.5 5.994ZM3.5 9A2.5 2.5 0 0 1 6 6.5v-5A7.5 7.5 0 0 0-1.5 9h5Zm0 2.79V9h-5v2.79h5Zm0 24.42V11.79h-5v24.42h5Zm0 2.79v-2.79h-5V39h5ZM6 41.5A2.5 2.5 0 0 1 3.5 39h-5A7.5 7.5 0 0 0 6 46.5v-5Zm36 0H6v5h36v-5Zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5v5a7.5 7.5 0 0 0 7.5-7.5h-5Zm0-27.21V39h5V11.79h-5ZM42 9.29a2.5 2.5 0 0 1 2.5 2.5h5a7.5 7.5 0 0 0-7.5-7.5v5Zm-20.9 0H42v-5H21.1v5Zm-2.597-1.793.6.797 3.995-3.007-.6-.797-3.995 3.007ZM16.505 6.5a2.5 2.5 0 0 1 1.998.997l3.995-3.007a7.5 7.5 0 0 0-5.993-2.99v5ZM6 6.5h10.505v-5H6v5Z"
      mask="url(#LandingPages_svg__a)"
    />
    <rect
      width={43.5}
      height={29.5}
      x={2.25}
      y={13.25}
      stroke="#6B7380"
      strokeWidth={2.5}
      rx={3.75}
    />
    <g clipPath="url(#LandingPages_svg__b)">
      <rect width={16} height={16} x={35} y={30} fill="#E1E7FF" rx={4} />
      <rect width={16} height={16} x={35} y={30} fill="#CFFBFE" rx={4} />
      <g clipPath="url(#LandingPages_svg__c)">
        <path
          fill="#0891B3"
          fillRule="evenodd"
          d="M39 33.556a.447.447 0 0 0-.444.444v2.222h8.888V34a.447.447 0 0 0-.444-.444h-8ZM37.667 34v8.889c0 .246.199.445.444.445h9.778a.444.444 0 0 0 .444-.445V34c0-.734-.599-1.333-1.333-1.333h-8c-.734 0-1.333.599-1.333 1.333Zm9.777 8.445V37.11h-8.889v5.334h8.89Zm-7.11-7.56a.444.444 0 1 0-.89 0v.004a.444.444 0 1 0 .89 0v-.004Zm1.333-.445c.245 0 .444.2.444.445v.004a.444.444 0 1 1-.889 0v-.004c0-.246.2-.445.445-.445Zm2.222.445a.444.444 0 1 0-.889 0v.004a.444.444 0 1 0 .889 0v-.004Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <rect
      width={18}
      height={18}
      x={34}
      y={29}
      stroke="#6B7380"
      strokeWidth={2}
      rx={5}
    />
    <defs>
      <clipPath id="LandingPages_svg__b">
        <rect width={16} height={16} x={35} y={30} fill="#fff" rx={4} />
      </clipPath>
      <clipPath id="LandingPages_svg__c">
        <path fill="#fff" d="M37.667 32.667h10.667v10.667H37.667z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgLandingPages);
export default Memo;
