import { get as getTagService } from '../services/tag'

import useCurrentInstance from './useCurrentInstance'

const useCurrentTag = () => useCurrentInstance({
  resourceName: 'tag',
  getService: getTagService,
  replyAttrPath: 'json',
  notFoundRedirect: '/tags',
  idPath: 'name',
  instanceIdAttr: 'tagName'
})

export default useCurrentTag
