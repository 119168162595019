import { useCallback, useRef, useState } from 'react'

import FolderEmptyListContent from '../../components/folders/FolderEmptyListContent'
import FolderList from '../../components/folders/FolderList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import TrackableLinkList from '../../components/trackable-links/TrackableLinkList'
import useCurrentListFolderMode from '../../hooks/useCurrentListFolderMode'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'

import TrackableLinkEmptyListContent from './list/EmptyListContent'
import TrackableLinkListHeaderMoreMenu from './list/ListHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TrackableLinksList'] })

const TrackableLinksList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()
  const folderListRef = useRef()

  const currentListFolderMode = useCurrentListFolderMode()
  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])
  const [folderTotal, setFolderTotal] = useState(0)
  const handleFolderTotalChange = useCallback((newTotal) => { setFolderTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={currentListFolderMode ? folderTotal : total}
        end={<TrackableLinkListHeaderMoreMenu currentListFolderMode={currentListFolderMode} listRef={listRef} />}
        title='Trackable Links'
      />
      <PanelContent>
        <FolderList
          ref={folderListRef}
          EmptyListContent={FolderEmptyListContent}
          queryOverrides={{ contentType: CONTENT_TYPE.trackableLink }}
          savedStateKey='trackableLinksFolders'
          type='nav'
          visible={currentListFolderMode}
          showFolderToggle
          onTotalChange={handleFolderTotalChange}
        />
        <TrackableLinkList
          ref={listRef}
          EmptyListContent={TrackableLinkEmptyListContent}
          savedStateKey='trackable-links'
          type='nav'
          visible={!currentListFolderMode}
          showFolderToggle
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

TrackableLinksList.displayName = 'TrackableLinksList'
TrackableLinksList.propTypes = propTypes
TrackableLinksList.defaultProps = defaultProps

export default TrackableLinksList
