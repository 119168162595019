import PropType from 'prop-types'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import dates from '../../../data-sources/dates'
import months from '../../../data-sources/months'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['AnniversaryDetail'] })

const AnniversaryDetail = ({ contact, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const value = contact?.formattedAnniversary
  const defaultValues = {
    anniversary: {
      date: contact?.anniversary?.date,
      month: contact?.anniversary?.month
    }
  }
  const formControls = [
    { label: 'Month', name: 'anniversary[month]', type: 'select', options: months },
    { label: 'Day', name: 'anniversary[date]', type: 'select', options: dates }
  ]

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Set the anniversary on your contact to enable them for Anniversary Campaigns'
      formControls={formControls}
      formDescription={`Updating anniversary for ${contact.formattedName}`}
      formTitle={contact.formattedName}
      initialEditButtonText='Set Anniversary'
      objectId={contact._id}
      service={service}
      showClearIcon={!!value}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Anniversary'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

AnniversaryDetail.displayName = 'AnniversaryDetail'
AnniversaryDetail.propTypes = propTypes
AnniversaryDetail.defaultProps = defaultProps

export default AnniversaryDetail
