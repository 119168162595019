import integrationFromJSON from '../models/integration'

import pb from './pb'

export function search (ignoredSearchParam, background = false) {
  return pb.setupGet('integrations/listForCurrentUser?display=true', {
    background,
    model: {
      conversion: integrationFromJSON,
      multiple: true
    }
  })
}

export function verifyPartnerRequest (key, search) {
  return pb.setupGet(`integrations/${key}/verifyRequest${search}`)
}

export function allow (key, search, isExistingUser = false) {
  return pb.setupPost(`integrations/${key}/allow${search}`, {
    body: { isExistingUser }
  })
}

export function deny (key, search) {
  return pb.setupPost(`integrations/${key}/deny${search}`)
}

export function disconnect (key) {
  return pb.setupPost(`integrations/${key}/remove`)
}
