import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import KeywordJoinCampaigns from '../screens/keywords/KeywordJoinCampaigns'
import KeywordJoinSpaces from '../screens/keywords/KeywordJoinSpaces'
import KeywordLeaveCampaigns from '../screens/keywords/KeywordLeaveCampaigns'
import KeywordsDetail from '../screens/keywords/KeywordsDetail'
import KeywordsFolderDetail from '../screens/keywords/KeywordsFolderDetail'

const keywordRoutes = [
  {
    path: ':keywordId',
    element: <KeywordsDetail />
  },
  {
    path: ':keywordId/join-campaigns',
    element: <KeywordJoinCampaigns />
  },
  {
    path: ':keywordId/leave-campaigns',
    element: <KeywordLeaveCampaigns />
  },
  {
    path: ':keywordId/join-spaces',
    element: <KeywordJoinSpaces />
  }
]

const routes = [
  ...keywordRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<KeywordsFolderDetail />} />,
    children: [...keywordRoutes]
  }
]

export default routes
