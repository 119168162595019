import { isEqual } from 'lodash'
import { useCallback, useEffect, useReducer, useRef } from 'react'

import logger from '../lib/logger'

import useUpdateEffect from './useUpdateEffect'

function noop () {}

const initialState = {
  loading: false,
  reply: null
}

const log = logger({ enabled: true, tags: ['useService'] })

// having `reply` in the state is deprecated
function reducer (state, action) {
  switch (action.type) {
    case 'loading':
      return { loading: true, reply: null }
    case 'loaded':
      return { loading: false, reply: action.reply }
    case 'aborted':
      return { loading: false, reply: null }
    default:
      throw new Error('Unknown action')
  }
}

const useService = (serviceMethod, options = {}) => {
  const { onReply, onReplyOk, onReplyNotOk } = options

  const replyHandlersRef = useRef({ onReply, onReplyOk, onReplyNotOk })
  useUpdateEffect(() => {
    // log.debug('Got new reply handlers')
    replyHandlersRef.current = { onReply, onReplyOk, onReplyNotOk }
  }, [onReply, onReplyOk, onReplyNotOk])

  const serviceMethodRef = useRef(serviceMethod)
  useUpdateEffect(() => {
    // log.warn('Got new serviceMethod...')
    serviceMethodRef.current = serviceMethod
  }, [serviceMethod])

  const [state, dispatch] = useReducer(reducer, initialState)
  const serviceRef = useRef({
    call: noop, // the method to fetch the data
    abort: noop, // the method to abort the fetch
    serviceArgs: undefined, // service args used for the current fetch
    awaiting: 0, // internal counter flag for outstanding requests
    aborted: false // internal flag for if we've aborted an in progress fetch
  })

  const abortCall = useCallback(async (updateState = true) => {
    if (serviceRef.current.awaiting > 0) {
      // don't want to update state if we've aborted due to an unmount
      if (updateState) { dispatch({ type: 'aborted' }) }
      serviceRef.current.aborted = true
      serviceRef.current.abort()
    }
  }, [])

  const makeCall = useCallback(async function (...serviceArgs) {
    // handle some cases where we might already be awaiting a response but initiating another call
    if ((serviceRef.current.awaiting > 0) && !serviceRef.current.aborted) {
      if (isEqual(serviceArgs, serviceRef.current.serviceArgs)) {
        log.warn('Request with same params made while previous request is still loading. Ignoring this call.')
        return
      }
      log.debug('Request made while still loading a previous call. Aborting previous call.')
      abortCall()
    }

    // setup new service call with new service args
    const { call, abort } = serviceMethodRef.current(...serviceArgs)

    // we're now moving into a 'loading' state
    dispatch({ type: 'loading' })

    // updating current ref with new details
    serviceRef.current = { call, abort, awaiting: serviceRef.current.awaiting + 1, aborted: false, serviceArgs }

    // make and wait for the service call
    const reply = await serviceRef.current.call()

    // update loading and aborted flags after finishing
    serviceRef.current.awaiting--
    serviceRef.current.aborted = false

    // update state unless the fetch was aborted
    if (!reply.aborted) {
      dispatch({ type: 'loaded', reply })

      replyHandlersRef.current.onReply?.(reply)
      if (reply.ok) { replyHandlersRef.current.onReplyOk?.(reply) }
      if (!reply.ok) { replyHandlersRef.current.onReplyNotOk?.(reply) }
    }

    // might as well return the reply?
    return reply
  }, [abortCall])

  useEffect(() => {
    return () => { abortCall(false) }
  }, [abortCall])

  return { call: makeCall, abort: abortCall, ...state }
}

export default useService
