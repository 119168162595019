import PropType from 'prop-types'
import { Link } from 'react-router-dom'

import { useApp } from '../../../contexts/AppContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  currentUser: PropType.shape({
    subscriptionDetails: PropType.shape({
      currentPlanCreditPrice: PropType.number
    })
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BuyCreditsLink'] })

const BuyCreditsLink = ({ currentUser }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isWeb } = useApp()

  if (!isWeb) { return }

  const price = currentUser.subscriptionDetails?.currentPlanCreditPrice || 0

  return (
    <div className='label-xs'>
      <Link className='link text-white underline' to='/subscription/buy-credits'>Buy More Credits</Link>
      {price > 0
        ? (
          <>
            {' @ '}
            {(price).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 3 })}
            &#162; / credit
          </>
          )
        : null}
    </div>
  )
}

BuyCreditsLink.displayName = 'BuyCreditsLink'
BuyCreditsLink.propTypes = propTypes
BuyCreditsLink.defaultProps = defaultProps

export default BuyCreditsLink
