import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import loadFromCacheOrService from '../../../../lib/loadFromCacheOrService'
import logger from '../../../../lib/logger'
import messageQualityShape from '../../../../prop-types/shapes/messageQuality'
import { messageRevisionPromptOptions } from '../../../../services/aiva'
import useStore from '../../../../store'
import { Aiva } from '../../../../svg/icons'
import RadioGroup from '../../../forms/controls/RadioGroup'
import PanelHeaderButton from '../../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../panels/PanelContent'
import PanelHeader from '../../../panels/PanelHeader'
import AivaLearnMoreButton from '../../AivaLearnMoreButton'

import TabSelector from './TabSelector'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  onClose: PropType.func.isRequired,
  onPromptSelected: PropType.func.isRequired,
  onSelectTab: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['RevisionSelectPanel'] })

const RevisionSelectPanel = ({ messageQuality, onClose, onPromptSelected, onSelectTab }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const messagePromptOptions = useStore((state) => state.remoteDataSources.aivaMessagePrompts)
  const [selectedPrompt, setSelectedPrompt] = useState(null)

  useEffect(() => {
    if (!messagePromptOptions) {
      loadFromCacheOrService('aivaMessagePrompts', messageRevisionPromptOptions)
    }
  }, [messagePromptOptions])

  const handleRadioSelect = useCallback((event) => {
    setSelectedPrompt(event.target.value)
  }, [])

  const handleNextClick = useCallback(() => {
    onPromptSelected(selectedPrompt)
  }, [onPromptSelected, selectedPrompt])

  return (
    <>
      <PanelHeader
        end={<PanelHeaderButton disabled={selectedPrompt === null} title='Next' onClick={handleNextClick} />}
        start={<PanelHeaderButton title='Cancel' onClick={onClose} />}
        title='AIVA'
      />
      <TabSelector currentTab='revise-message' messageQuality={messageQuality} onSelectTab={onSelectTab} />
      <PanelContent>
        <div className='flex flex-col items-center gap-4 py-4 px-5'>
          <div className='bg-indigo-50 p-3 rounded-lg text-center'>
            <Aiva className='text-primary w-12 h-12 inline' />
            <h1 className='label-md-strong text-black'>
              AIVA: Your AI Virtual Assistant
            </h1>
            <p className='label-sm-medium'>
              The options below will assist you in elevating the quality of your writing.
            </p>
            <AivaLearnMoreButton variant='text' />
          </div>
          {messagePromptOptions?.length > 0 && (
            <div className='text-left text-black w-full leading-snug'>
              <RadioGroup name='promptId' options={messagePromptOptions} onChange={handleRadioSelect} />
            </div>
          )}
        </div>
      </PanelContent>

    </>
  )
}

RevisionSelectPanel.displayName = 'RevisionSelectPanel'
RevisionSelectPanel.propTypes = propTypes
RevisionSelectPanel.defaultProps = defaultProps

export default RevisionSelectPanel
