import { FloatingFocusManager, FloatingPortal, useFloating } from '@floating-ui/react'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import useStore from '../store'

import LoadingIndicator from './LoadingIndicator'

const log = logger({ enabled: true, tags: ['ApiLoading'] })

const showDelay = 750 // delay showing the loading overlay by this many milliseconds
const hideDelay = 500 // once shown only hide after this many milliseconds

const propTypes = {}

const defaultProps = {}

const ApiLoading = function () {
  const timeoutRef = useRef()
  const shownAtRef = useRef()
  const { context, refs } = useFloating()
  const { activated, messageOverride } = useStore(useShallow((state) => ({
    activated: state.apiLoading.activated,
    messageOverride: state.apiLoading.messageOverride
  })))
  const [show, setShow] = useState(false)

  useLogger({ log, lifecycle: true, tags: [activated] })

  useEffect(() => {
    if (timeoutRef) { clearTimeout(timeoutRef.current) }
    if (activated) {
      timeoutRef.current = setTimeout(() => {
        shownAtRef.current = new Date()
        setShow(true)
      }, showDelay)
    } else if (shownAtRef.current) {
      const current = new Date()
      const visibleTime = current - shownAtRef.current
      if (visibleTime >= hideDelay) {
        setShow(false)
      } else {
        timeoutRef.current = setTimeout(() => { setShow(false) }, hideDelay)
      }
    }
    return () => { clearTimeout(timeoutRef.current) }
  }, [activated])

  const containerClassName = classNames(
    'fixed inset-0 flex justify-center items-center',
    {
      'bg-overlay': show
    }
  )
  const dialogClassName = classNames(
    'text-neutral-700 border border-neutral-300 rounded-lg',
    {
      hidden: !show
    }
  )

  if (!show || !activated) { return null }

  return (
    <FloatingFocusManager closeOnFocusOut={false} context={context} initialFocus={refs.floating}>
      <FloatingPortal id='api-loading-portal'>
        <div className={containerClassName} onClick={(event) => { event.stopPropagation() }}>
          <LoadingIndicator
            aria-modal='true'
            className={dialogClassName}
            // eslint-disable-next-line react/no-unknown-property
            inert=''
            message={messageOverride}
            role='dialog'
          />
        </div>
      </FloatingPortal>
    </FloatingFocusManager>
  )
}

ApiLoading.displayName = 'ApiLoading'
ApiLoading.propTypes = propTypes
ApiLoading.defaultProps = defaultProps

export default ApiLoading
