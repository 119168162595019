import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import DeleteAction from '../../../components/trackable-links/actions/DeleteAction'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import trackableLink from '../../../prop-types/shapes/trackableLink'

const propTypes = {
  trackableLink: PropType.shape(trackableLink).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TrackableLinkDetailHeaderMoreMenu'] })

const TrackableLinkDetailHeaderMoreMenu = ({ trackableLink }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/trackable-links', { replace: true }), [navigateInStack])

  return !!trackableLink && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Delete Trackable Link' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} trackableLink={trackableLink} onSuccess={onDeleteSuccess} />
    </>
  )
}

TrackableLinkDetailHeaderMoreMenu.displayName = 'TrackableLinkDetailHeaderMoreMenu'
TrackableLinkDetailHeaderMoreMenu.propTypes = propTypes
TrackableLinkDetailHeaderMoreMenu.defaultProps = defaultProps

export default TrackableLinkDetailHeaderMoreMenu
