import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import DeleteAction from '../../../components/spaces/actions/DeleteAction'
import EditAction from '../../../components/spaces/actions/EditAction'
import LeaveAction from '../../../components/spaces/actions/LeaveAction'
import NotificationSettingsAction from '../../../components/spaces/actions/NotificationSettingsAction'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import space from '../../../prop-types/shapes/space'

const propTypes = {
  space: PropType.shape(space).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ListHeaderMore'] })

const ListHeaderMore = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: ['SpaceThread', space._id] })

  const editActionRef = useRef()
  const activateEditAction = useCallback(() => editActionRef.current.activate(), [])

  const deleteActionRef = useRef()
  const activateDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])

  const leaveActionRef = useRef()
  const activateLeaveAction = useCallback(() => leaveActionRef.current.activate(), [])

  const notificationSettingsActionRef = useRef()
  const activeNotificationSettingsAction = useCallback(() => notificationSettingsActionRef.current.activate(), [])

  return (
    <>
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem href='members' label='Members' />
        {space.isCurrentUserOwner ? <ActionMenuItem label='Edit Name' onClick={activateEditAction} /> : null}
        {space.isCurrentUserOwner ? <ActionMenuItem label='Delete Space' onClick={activateDeleteAction} /> : null}
        {!space.isCurrentUserOwner ? <ActionMenuItem label='Leave Space' onClick={activateLeaveAction} /> : null}
        <ActionMenuItem label='Notification Settings' onClick={activeNotificationSettingsAction} />
        <ActionMenuItem href='https://www.projectbroadcast.com/spaces-coming-soon/' label='Coming Soon' />
      </ActionMenu>
      {space.isCurrentUserOwner ? <EditAction ref={editActionRef} space={space} /> : null}
      {space.isCurrentUserOwner ? <DeleteAction ref={deleteActionRef} space={space} /> : null}
      {!space.isCurrentUserOwner ? <LeaveAction ref={leaveActionRef} space={space} /> : null}
      <NotificationSettingsAction ref={notificationSettingsActionRef} space={space} />
    </>
  )
})

ListHeaderMore.displayName = 'ListHeaderMore'
ListHeaderMore.propTypes = propTypes
ListHeaderMore.defaultProps = defaultProps

export default ListHeaderMore
