import { first } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import CreateAction from '../../../components/campaigns/event-actions/CreateAction'
import DetailSection from '../../../components/detail-sections/DetailSection'
import { campaignTypeInfo } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EventsDetail'] })

const EventsDetail = ({ campaign }) => {
  useLogger({ log, lifecycle: false, tags: [campaign?._id] })

  const navigate = useNavigate()
  const createActionRef = useRef()

  const { label, description, supportsMultipleMessages } = campaignTypeInfo(campaign.type)

  const handleCreateFirst = useCallback(() => {
    if (supportsMultipleMessages) {
      navigate('events')
    } else {
      createActionRef.current.activate()
    }
  }, [navigate, supportsMultipleMessages])

  const handleCreateFirstSuccess = useCallback((newCampaignEvent) => {
    navigate(`events/${newCampaignEvent._id}`)
  }, [navigate])

  const count = campaign?.events.length

  const messageSubsetValues = campaign?.events.map((event) => event.name)
  const messageSummary = messageSubsetValues.join(', ')
  const firstEventId = first(campaign?.events)?._id

  return (
    <>
      <DetailSection
        count={supportsMultipleMessages ? count : null}
        description={description}
        editHref={supportsMultipleMessages ? 'events' : (firstEventId ? `events/${firstEventId}` : null)}
        editIcon='chevron'
        initialEditButtonText={supportsMultipleMessages ? 'Manage' : 'Set Message'}
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title={`${label} Message`}
        onInitialEditClick={handleCreateFirst}
      >
        <div className='line-clamp-1'>{messageSummary}</div>
      </DetailSection>
      <CreateAction ref={createActionRef} campaign={campaign} onSuccess={handleCreateFirstSuccess} />
    </>
  )
}

EventsDetail.displayName = 'EventsDetail'
EventsDetail.propTypes = propTypes
EventsDetail.defaultProps = defaultProps

export default EventsDetail
