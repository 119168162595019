import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import CreateFolderAction from '../../../components/folders/actions/CreateFolderAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import BulkEditActions from '../../../components/qr-codes/actions/BulkEditActions'
import CreateAction from '../../../components/qr-codes/actions/CreateAction'
import QrCodeList from '../../../components/qr-codes/QrCodeList'
import { CONTENT_TYPE } from '../../../lib/folder'
import { Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired,
  currentListFolderMode: PropType.bool
}

const defaultProps = {
  currentListFolderMode: false
}

const QrCodeListHeaderMoreMenu = ({ currentListFolderMode, listRef }) => {
  const bulkEditRef = useRef()
  const createActionRef = useRef()
  const createFolderActionRef = useRef()

  const handleBulkEditClick = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])
  const handleCreateFolderAction = useCallback(() => createFolderActionRef.current.activate(), [])
  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])
  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])

  const createHandler = currentListFolderMode ? handleCreateFolderAction : handleCreateSelected

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon='add' title='Create' onClick={createHandler} />
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuHeader label='Manage QR Codes' />
        <ActionMenuDivider />
        <ActionMenuItem label='Edit QR Codes' onClick={handleBulkEditClick} />
      </ActionMenu>
      <BulkEditDialog ref={bulkEditRef} actions={BulkEditActions} list={QrCodeList} />
      <CreateFolderAction ref={createFolderActionRef} contentType={CONTENT_TYPE.qrCode} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

QrCodeListHeaderMoreMenu.displayName = 'QrCodeListHeaderMoreMenu'
QrCodeListHeaderMoreMenu.propTypes = propTypes
QrCodeListHeaderMoreMenu.defaultProps = defaultProps

export default QrCodeListHeaderMoreMenu
