import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/tag-contact'
import ContactList from '../contacts/ContactList'

const propTypes = {
  tagName: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TagContactList'] })

const TagContactList = forwardRef(({ tagName, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [tagName] })

  return (
    <ContactList
      ref={ref}
      queryOverrides={{ tagName }}
      searchService={searchService}
      {...rest}
    />
  )
})

TagContactList.displayName = 'TagContactList'
TagContactList.propTypes = propTypes
TagContactList.defaultProps = defaultProps

export default TagContactList
