import PropType from 'prop-types'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { Close, Profile } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {
  messageType: PropType.oneOf(messageTypes).isRequired,
  mediaUrl: PropType.string,
  onRemove: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onRemove: undefined
}

const log = logger({ enabled: false, tags: ['MessageVcardAttachment'] })

const MessageVcardAttachment = ({ mediaUrl, messageType, onRemove }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (messageType !== 'mms' || !mediaUrl?.endsWith('.vcf')) { return null }

  return (
    <div className='flex items-center px-3 py-2 flex place-content-center'>
      <div className='bg-neutral-200 rounded-lg mr-3 p-3'>
        <div className='label-xs-strong text-black flex flex-row gap-2 items-center'>
          <Profile className='h-4' />
          {' '}
          Contact Card
        </div>
        <div className='label-xs-medium'>
          Your Project Broadcast contact information.
        </div>
      </div>
      <Button
        icon={<Close />}
        size='sm'
        theme='primary'
        variant='outline'
        circle
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  )
}

MessageVcardAttachment.displayName = 'MessageVcardAttachment'
MessageVcardAttachment.propTypes = propTypes
MessageVcardAttachment.defaultProps = defaultProps

export default MessageVcardAttachment
