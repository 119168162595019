import * as React from "react";
import { memo } from "react";
const SvgKey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.816 1.811a2.789 2.789 0 0 0-3.928-.005L8.175 5.52a2.788 2.788 0 0 0-.228 3.673l-6.654 6.655a1 1 0 0 0-.293.707v5.454a1 1 0 0 0 1 1h5.455a1 1 0 0 0 1-1v-2.636h2.636a1 1 0 0 0 1-1v-1.728h4.455a.999.999 0 0 0 .171-.014 2.752 2.752 0 0 0 1.763-.806l3.709-3.709a2.789 2.789 0 0 0 .005-3.928l-6.378-6.377Zm.73 12.825c-.046 0-.092.004-.136.01h-5.32a1 1 0 0 0-1 1v1.727H7.456a1 1 0 0 0-1 1v2.636H3v-4.04l7.053-7.053a1 1 0 0 0 0-1.414l-.461-.46a.789.789 0 0 1-.003-1.108l3.706-3.706a.789.789 0 0 1 1.107-.003l6.368 6.368.002.002c.307.314.3.81.003 1.107l-3.71 3.71a.745.745 0 0 1-.52.224Zm-1.139-7.843a1 1 0 1 0-1.414 1.414l1.818 1.818a1 1 0 0 0 1.415-1.414l-1.819-1.818Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgKey);
export default Memo;
