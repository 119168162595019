export default {
  date (props, propName, name) {
    const min = 1
    const max = 31
    const value = props?.[propName]
    if (typeof value !== 'number' || value < min || value > max) {
      return new Error(`${propName} paseed to ${name} should be a number between ${min} and ${max} but received "${value}"`)
    }
  },
  month (props, propName, name) {
    const min = 0
    const max = 11
    const value = props?.[propName]
    if (typeof value !== 'number' || value < min || value > max) {
      return new Error(`${propName} paseed to ${name} should be a number between ${min} and ${max} but received "${value}"`)
    }
  }
}
