import { useRef } from 'react'

import Button from '../../../components/buttons/Button'
import BlockingDialog from '../../../components/dialog/BlockingDialog'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import { usePanel } from '../../../contexts/PanelContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const log = logger({ enabled: true, tags: ['Style Guide', 'FormDialogs'] })

function FormDialogs () {
  useLogger({ log, lifecycle: false, tags: [] })

  const { inFirst } = usePanel()

  const blockingDialogRef1 = useRef()
  const start = inFirst ? <PanelHeaderBackButton /> : null

  return (
    <Panel>
      <PanelHeader start={start} title='Blocking Dialogs' />
      <PanelContent>
        <div className='p-4 flex flex-row gap-4'>
          <BlockingDialog
            ref={blockingDialogRef1}
            ariaDescription='Notification of existing account'
            buttonVerb='Move along'
            href='/style-guide'
            title='Act Now'
            trigger={<Button size='sm'>Primary Trigger</Button>}
          >
            <p className='paragraph-sm text-neutral-500 text-center mb-[20px]'>
              Move along now! Nothing to see here.
            </p>
          </BlockingDialog>
          <Button size='sm' onClick={() => blockingDialogRef1.current.open()}>Trigger By Ref</Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default FormDialogs
