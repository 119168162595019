import PropType from 'prop-types'
import { NavLink } from 'react-router-dom'

import useLatestStackPath from '../../../hooks/useLatestStackPath'

const propTypes = {
  label: PropType.string.isRequired,
  path: PropType.string.isRequired,
  stack: PropType.string
}

const defaultProps = {
  stack: null
}

const computeClassName = ({ isActive/*, isPending */ }) => {
  const baseClassName = 'block label-sm w-full py-[3px]'
  return isActive ? `${baseClassName} text-primary` : baseClassName
}

const SubMenuItem = ({ label, path, stack }) => {
  const stackPath = useLatestStackPath(stack, path)

  return (
    <li className='w-full pl-[64px] active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50 min-w-[40px] min-h-[40px] flex items-center'>
      <NavLink className={computeClassName} to={stackPath}>{label}</NavLink>
    </li>
  )
}

SubMenuItem.displayName = 'SubMenuItem'
SubMenuItem.propTypes = propTypes
SubMenuItem.defaultProps = defaultProps

export default SubMenuItem
