import * as React from "react";
import { memo } from "react";
const SvgNinja = (props) => (
  <svg
    viewBox="0 0 121 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.422 79.5H33.278c-2.754 0-4.988-2.373-4.988-5.3 0-2.927 2.234-5.3 4.988-5.3H5.488C2.733 68.9.5 66.527.5 63.6c0-2.927 2.233-5.3 4.988-5.3h28.503c2.755 0 4.988-2.373 4.988-5.3 0-2.927-2.233-5.3-4.988-5.3H16.177c-2.755 0-4.988-2.373-4.988-5.3 0-2.927 2.233-5.3 4.988-5.3H44.68c-2.755 0-4.988-2.373-4.988-5.3 0-2.927 2.233-5.3 4.988-5.3h69.832c2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3H86.009c2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3h15.677c2.754 0 4.988 2.373 4.988 5.3 0 2.927-2.234 5.3-4.988 5.3h-7.25c-3.474 0-6.29 2.373-6.29 5.3 0 2.927 4.276 5.3 4.276 5.3 2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3Z"
      fill="#EEF2FE"
    />
    <g clipPath="url(#Ninja_svg__a)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M20.278 34.294c-1.586 1.946-1.83 3.72-1.485 6.369.106.81.729 1.456 1.538 1.566 4.883.668 11.494-.118 14.31-.52a1.869 1.869 0 0 0 1.593-1.565c.124-.812.255-1.84.266-2.644a.54.54 0 0 0-.479-.539c-.095.58-.275 1.265-.446 1.848a1.849 1.849 0 0 1-1.787 1.324c-2.611.002-8.422-.126-12.657-1.205a1.78 1.78 0 0 1-1.34-1.734c.003-1.137.129-2.068.487-2.9Z"
        fill="#9CA2AE"
      />
      <path
        d="M19.753 33.366c.91-1.045 2.363-1.154 3.46-.615 4.173 2.049 7.28 2.744 12.87 3.212a1.54 1.54 0 0 1 1.417 1.55c-.012.879-.152 1.963-.278 2.783a2.869 2.869 0 0 1-2.44 2.403c-2.812.401-9.55 1.21-14.586.521a2.806 2.806 0 0 1-2.395-2.428c-.187-1.438-.221-2.721.068-3.947.294-1.245.903-2.354 1.884-3.479Zm2.578 1.18c-.436-.214-.86-.107-1.07.135-.811.93-1.24 1.754-1.445 2.623-.21.887-.205 1.896-.031 3.23.049.376.334.657.682.705 4.728.646 11.212-.117 14.032-.52a.869.869 0 0 0 .746-.727c.1-.654.2-1.418.239-2.073-5.49-.483-8.802-1.237-13.153-3.373Z"
        fill="#000"
      />
      <path
        d="M19.654 53.771c.396 1.97 1.483 3.373 3.332 4.998.614.539 1.507.62 2.194.177 4.692-3.024 9.83-8.708 11.851-11.047a1.871 1.871 0 0 0 .176-2.225 28.398 28.398 0 0 0-1.42-2.088c-.051.344-.2.676-.44.954-2.021 2.34-7.16 8.023-11.851 11.047a1.825 1.825 0 0 1-2.194-.177c-.64-.562-1.188-1.097-1.648-1.639Z"
        fill="#9CA2AE"
      />
      <path
        d="M18.483 51.684c.02-1.373 1.031-2.41 2.221-2.72 5.097-1.329 8.235-3.012 13.32-6.728a1.6 1.6 0 0 1 2.166.253c.658.78 1.367 1.858 1.865 2.655a2.871 2.871 0 0 1-.267 3.409c-2.016 2.333-7.238 8.122-12.066 11.233a2.825 2.825 0 0 1-3.396-.266c-1.256-1.104-2.248-2.174-2.909-3.436-.67-1.28-.96-2.685-.934-4.4Zm2.726-.785c-.462.12-.722.48-.727.814-.02 1.457.223 2.518.708 3.444.493.944 1.276 1.823 2.456 2.86.29.255.694.28.992.088 4.557-2.936 9.611-8.516 11.637-10.86a.872.872 0 0 0 .084-1.041c-.423-.677-.96-1.494-1.46-2.132-5.046 3.663-8.384 5.444-13.69 6.826Z"
        fill="#000"
      />
    </g>
    <path
      d="m66.7 49.5-.063.022c-.54.242-1.146.093-1.453-.404-.377-.613-.185-1.485.419-1.979 1.031-.83 2.247-1.338 3.436-1.585a8.293 8.293 0 0 1 3.501.043c1.104.273 2.141.764 2.969 1.53.807.766 1.444 1.773 1.618 2.952-.968-.487-1.818-.867-2.705-1.122-.872-.247-1.73-.443-2.597-.488a9.787 9.787 0 0 0-2.605.195c-.864.178-1.694.463-2.52.837V49.5ZM52.883 49.5l.063.022c.54.242 1.146.093 1.453-.404.377-.613.185-1.485-.42-1.979-1.03-.83-2.247-1.338-3.435-1.585a8.293 8.293 0 0 0-3.502.043c-1.103.273-2.14.764-2.968 1.53-.807.766-1.444 1.773-1.619 2.952.968-.487 1.819-.867 2.706-1.122.871-.247 1.729-.443 2.596-.488.87-.058 1.754.02 2.605.195.865.178 1.694.463 2.52.837l.001-.001Z"
      fill="#000"
    />
    <path
      d="M75.14 57.277c-2.668.764-5.32-.335-8.135-1.495-2.127-.881-4.325-1.788-6.514-1.782-2.19.006-4.382.925-6.504 1.818-2.808 1.176-5.459 2.29-8.126 1.54-1.942-.542-5.548-1.735-5.506-.72 1.646.748 3.567 1.519 5.51 2.062 2.667.749 5.319-.364 8.126-1.54 2.122-.893 4.314-1.812 6.504-1.818 2.19-.006 4.387.9 6.514 1.781 2.815 1.161 5.467 2.26 8.135 1.496 1.939-.554 3.861-1.336 5.497-2.092.037-1.015-3.562.197-5.5.75Z"
      fill="#9CA2AE"
    />
    <path
      d="M60.535 69.752c-14.012.039-20.895-6.76-24.035-11.655.182 1.016.508 1.992.963 2.889C39.958 65.862 46.276 74.04 60.548 74c14.272-.04 20.54-8.252 23.007-13.142.45-.9.77-1.876.946-2.894-3.11 4.912-9.954 11.75-23.965 11.788h-.001Z"
      fill="#9CA2AE"
    />
    <path
      d="M34.902 60.534c1.302 2.666 3.563 6.19 7.256 9.14 3.383 2.57 7.512 4.421 12.07 5.283 1.725.294 3.585.46 5.591.468l.058.073.179.002c.636 0 1.267-.02 1.892-.057l-.057-.069c.49-.028.968-.063 1.44-.108l.06.057a30.63 30.63 0 0 0 2.07-.29l-.026-.025c.96-.154 1.874-.35 2.752-.579a29.052 29.052 0 0 0 6.677-2.727l.053-.03a24.5 24.5 0 0 0 2.14-1.412l.002.064a26.396 26.396 0 0 0 2.323-1.817l-.166-.029c2.94-2.716 4.807-5.741 5.948-8.09.88-1.828 1.342-3.912 1.336-6.023l-.031-11.06a13.963 13.963 0 0 0-1.35-5.976C78.136 22.77 60.676 22.503 59.914 22.5c-.739.008-18.196.376-25.098 14.976a13.97 13.97 0 0 0-1.315 5.983l.031 11.06c.007 2.111.48 4.192 1.371 6.015Zm48.306-1.104c-2.48 5.137-8.785 13.766-23.139 13.808-14.354.041-20.707-8.551-23.218-13.674a11.342 11.342 0 0 1-.97-3.05c1.987 1.101 5.33 2.785 8.813 3.806 3.439 1.01 6.74-.442 9.651-1.717 2.015-.888 3.92-1.721 5.677-1.726 1.756-.005 3.667.817 5.687 1.694 2.226.958 4.681 2.02 7.242 2.012a8.486 8.486 0 0 0 2.42-.353c3.476-1.04 6.81-2.744 8.79-3.856a11.348 11.348 0 0 1-.953 3.056ZM40.267 42.486c4.174-2.15 10.877-4.614 19.701-4.64 8.809-.024 15.536 2.406 19.728 4.537.219 2.11.769 8.175.583 13.547-1.645.794-3.578 1.616-5.529 2.197-2.684.803-5.351-.35-8.182-1.57-2.139-.926-4.35-1.879-6.552-1.872-2.202.006-4.407.971-6.54 1.91-2.825 1.235-5.49 2.405-8.173 1.618-1.954-.57-3.887-1.38-5.542-2.165-.216-5.377.3-11.456.505-13.562Zm44.056 11.267c-.493.297-1.335.782-2.394 1.339.096-4.462-.265-9.213-.5-11.777a27.814 27.814 0 0 1 2.87 1.883l.024 8.556v-.001ZM36.78 38.423c6.307-13.359 22.993-13.73 23.14-13.73.168 0 16.855.274 23.239 13.596a11.594 11.594 0 0 1 1.1 4.19c-3.493-2.342-11.862-6.861-24.297-6.825-12.434.036-20.779 4.603-24.257 6.965.1-1.47.46-2.9 1.076-4.196Zm1.76 5.01c-.221 2.56-.556 7.319-.434 11.786a49.05 49.05 0 0 1-2.401-1.325l-.025-8.556c.488-.38 1.449-1.079 2.86-1.905Z"
      fill="#000"
    />
    <defs>
      <clipPath id="Ninja_svg__a">
        <path fill="#fff" transform="translate(17.5 32)" d="M0 0h18v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgNinja);
export default Memo;
