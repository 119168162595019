import React from 'react'

import TagsContacts from '../screens/tags/TagContacts'
import TagsDetail from '../screens/tags/TagsDetail'

const routes = [
  {
    path: ':tagName',
    element: <TagsDetail />
  },
  {
    path: ':tagName/contacts',
    element: <TagsContacts />
  }
]

export default routes
