import { capitalize, isString } from 'lodash'

import { firstMediaUrl } from '../helpers/messageMedia'
import { formatName } from '../lib/formatters'
import useStore from '../store'

import contactModelFromJSON from './contact'
import proxy from './proxy'

export default function messageFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get direction () {
      if (json.direction) { return json.direction }
      const currentUserId = useStore.getState().currentUser?._id
      const messageUserId = json?.user?._id || json?.user
      return currentUserId === messageUserId ? 'out' : 'in'
    },

    get fromFormattedName () {
      // NOTE: I think this is only applicable for space messages
      return formatName(json.user)
    },

    get isPotentialStop () {
      // TODO: get threshold from remote config? should match es.lib.stopDetect.STOP_SCORE_THRESHOLD
      return json.stopDetectScore > 0.95 && json.stopDetectScore < 1
    },

    get isScheduled () {
      return json.state === 'queued'
    },

    get keywordAppliedByOtherDisplayText () {
      if (!json.keyword) { return '' }
      if (!json.keywordAppliedByOther) { return 'Keyword' }
      return json.keywordAppliedByOther === 'user' ? 'You' : capitalize(json.keywordAppliedByOther)
    },

    get mediaUrl () {
      return firstMediaUrl(json)
    },

    get userId () {
      return json?.user?._id || json?.user
    },

    toString () {
      return this._id
    }
  }

  const model = proxy(json, overrides)
  if (!isString(json.contact)) { model.contact = contactModelFromJSON(json.contact) }
  return model
}
