import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { pluck } from 'underscore'

import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { exportContacts as exportContactsService } from '../../../services/contact'
import { isCurrentlyManaging as isCurrentlyManagingService } from '../../../services/user'
import { success } from '../../banners/Banner'
import FormDialog from '../../dialog/FormDialog'
import ListDialog from '../../dialog/ListDialog'
import ContactList from '../ContactList'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: true, tags: ['ExportContactsAction'] })

const ExportContactsAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const [isManaging, setIsManaging] = useState(false)
  const [managerInformation, setManagerInformation] = useState(null)
  const [sendToManager, setSendToManager] = useState(false)

  const handleIsCurrentlyManagingReplyOk = useCallback((reply) => {
    const { isCurrentlyManaging, managingUserUsername, managingUserFullName } = reply.json
    setIsManaging(isCurrentlyManaging)
    setSendToManager(isCurrentlyManaging)
    setManagerInformation({ username: managingUserUsername, name: managingUserFullName })
  }, [])
  const { call: checkIfManaging } = useService(isCurrentlyManagingService, { onReply: handleIsCurrentlyManagingReplyOk })

  useEffect(() => {
    checkIfManaging()
  }, [checkIfManaging])

  const formRef = useRef()
  const selectRef = useRef()

  const handleReplyOk = useCallback((result) => {
    const email = sendToManager ? managerInformation.username : currentUser.auth.username
    success(`Generating csv for ${result.json.contactCount} contacts`, `You'll receive an email in a few moments at ${email}.`)
    formRef.current.close()
    onSuccess?.()
  }, [currentUser, managerInformation, onSuccess, sendToManager])
  const { call: exportContacts } = useService(exportContactsService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      formRef.current.open()
    }
  }), [])

  const handleExportAllInQuery = useCallback((searchData) => {
    exportContacts({ search: searchData, sendToManager })
  }, [exportContacts, sendToManager])

  const handleExportSelected = useCallback((data) => {
    exportContacts({ contactIds: pluck(data, '_id'), sendToManager })
  }, [exportContacts, sendToManager])

  const handleSubmitSelected = useCallback(({ exportType }) => {
    if (exportType === 'selected') {
      return selectRef.current.open()
    }
    if (exportType === 'all') {
      return exportContacts({ search: { archived: [true, false] }, sendToManager })
    }
  }, [exportContacts, sendToManager])

  const handleSendToManagerChange = useCallback((event) => {
    setSendToManager(event.target.checked)
  }, [])

  const defaultValues = useMemo(() => {
    const values = {
      exportType: 'all'
    }
    if (isManaging) {
      values.sendToManager = 'true'
    }
    return values
  }, [isManaging])

  const formControls = useMemo(() => {
    const controls = []
    if (isManaging && managerInformation) {
      controls.push({
        type: 'checkbox',
        name: 'sendToManager',
        label: `👋 Hi ${managerInformation.name}, check to send to your email instead: ${managerInformation.username}.`,
        onChange: handleSendToManagerChange
      })
    }
    controls.push({
      type: 'radiogroup',
      name: 'exportType',
      options: [
        { label: 'Export All Contacts', value: 'all' },
        { label: 'Export Selected Contacts', value: 'selected' }
      ]
    })
    return controls
  }, [handleSendToManagerChange, isManaging, managerInformation])

  return (
    <>
      <FormDialog
        ref={formRef}
        defaultValues={defaultValues}
        description={`This tool is used to export your contacts to a csv file. The export will be generated and mailed to the email address on your account, ${currentUser.auth.username}.`}
        formControls={formControls}
        submitTitle='Export'
        title='Export Contacts'
        onSubmit={handleSubmitSelected}
      />
      <ListDialog
        ref={selectRef}
        actionConfirm='exporting'
        actionLabel='Export'
        list={ContactList}
        subtitle={currentUser.auth.username}
        title='Export Contact(s)'
        type='add'
        onSubmitAllInQuery={handleExportAllInQuery}
        onSubmitSelected={handleExportSelected}
      />
    </>
  )
})

ExportContactsAction.displayName = 'ExportContactsAction'
ExportContactsAction.propTypes = propTypes
ExportContactsAction.defaultProps = defaultProps

export default ExportContactsAction
