import * as React from "react";
import { memo } from "react";
const SvgArticleLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 6a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm1 5a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgArticleLeft);
export default Memo;
