import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import FormattedDate from '../../../components/FormattedDate'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone, dateAtStartOfDayInTimeZone } from '../../../lib/timeZones'
import campaignShape from '../../../prop-types/shapes/campaign'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['CountdownDateDetail'] })

const CountdownDateDetail = ({ campaign, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaign._id] })

  const { userTimeZone } = useTimeZone()

  const { confirm, formControls, defaultValues, transformData, value } = useMemo(() => ({
    confirm: {
      description: `Updating to an earlier date may reschedule some broadcasts originated by this campaign
                    into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledAt }) => {
        if (!campaign.scheduledAt) { return false }
        const prevValueDay = formatDateForInput(campaign.scheduledAt, userTimeZone)
        const prevValueDateStartOfDay = dateAtStartOfDayInTimeZone(prevValueDay, userTimeZone)
        const newValueDay = formatDateForInput(scheduledAt, userTimeZone)
        const newValueDateStartOfDay = dateAtStartOfDayInTimeZone(newValueDay, userTimeZone)
        return newValueDateStartOfDay < prevValueDateStartOfDay
      }
    },
    formControls: [
      { label: '', name: 'date', type: 'date' },
      { label: '', name: 'time', type: 'time' }
    ],
    defaultValues: campaign.scheduledAt && {
      date: formatDateForInput(campaign.scheduledAt, userTimeZone),
      time: formatTimeForInput(campaign.scheduledAt, userTimeZone)
    },
    transformData: ({ date, time }) => ({
      scheduledAt: dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    }),
    value: <FormattedDate date={campaign.scheduledAt} format='extendedDateAndTime' />
  }), [campaign, userTimeZone])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the end date and time.'
      formControls={formControls}
      formDescription='Update the end date and time for this campaign'
      formTitle='Select End Date and Time'
      initialEditButtonText='Set End Date and Time'
      objectId={campaign._id}
      service={service}
      showEditIcon={!!campaign.scheduledAt}
      showInitialEditButton={!campaign.scheduledAt}
      title='End Date and Time'
      transformData={transformData}
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

CountdownDateDetail.displayName = 'CountdownDateDetail'
CountdownDateDetail.propTypes = propTypes
CountdownDateDetail.defaultProps = defaultProps

export default CountdownDateDetail
