// Seems a bit hacky but there doesn't seem to be another way to navigate outside the context of a component
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export let externalNavigate

function ExternalNavigate () {
  const navigate = useNavigate()

  useEffect(() => {
    function setNavigate () {
      externalNavigate = navigate
    }
    setNavigate()
  }, [navigate])

  return null
}

export default ExternalNavigate
