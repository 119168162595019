import { useCallback, useRef, useState } from 'react'

import LandingPageViewList from '../../components/landing-pages/LandingPageViewList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentLandingPage from '../../hooks/useCurrentLandingPage'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['LandingPagesViews'] })

const LandingPagesViews = () => {
  const listRef = useRef()
  const { landingPageId, loading, landingPage } = useCurrentLandingPage()

  useLogger({ log, lifecycle: false, tags: [landingPageId] })

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !landingPage) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        start={<PanelHeaderBackButton />}
        subtitle={landingPage?.name}
        title='Landing Page Views'
      />
      <PanelContent>
        <LandingPageViewList
          ref={listRef}
          landingPageId={landingPage?._id}
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

LandingPagesViews.displayName = 'LandingPagesViews'
LandingPagesViews.propTypes = propTypes
LandingPagesViews.defaultProps = defaultProps

export default LandingPagesViews
