import contactFromJSON from '../models/contact'

import pb from './pb'

export function search (body, background = false) {
  const { trackableLinkId } = body
  delete body.trackableLinkId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'trackableLinkClicks',
      multiple: true
    }
  }
  return pb.setupPost(`trackableLinks/${trackableLinkId}/contacts`, options)
}
