import { useEffect, useRef } from 'react'

import { useApp } from '../../contexts/AppContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PbNativeUtil from '../../plugins/pb-native-util'
import { receiveSharedImage } from '../../store/actions/nativeSharedImage'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'SharedImage'] })

const SharedImage = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { isAndroid, isNative } = useApp()

  const sharedImageListenerRef = useRef()

  useEffect(() => {
    async function addSharedImageListener () {
      if (sharedImageListenerRef.current?.remove) {
        await sharedImageListenerRef.current.remove()
        log.debug('removed previous "appSharedImage" listener')
      }

      sharedImageListenerRef.current = PbNativeUtil.addListener('appSharedImage', ({ imageData, mimeType, size }) => {
        receiveSharedImage(imageData, mimeType, size)
      })
      log.debug('added new "appSharedImage" listener')
    }

    if (isAndroid && isNative) {
      addSharedImageListener()
    }

    return () => {
      sharedImageListenerRef.current?.remove()
    }
  }, [isAndroid, isNative])

  return null
}

SharedImage.displayName = 'SharedImage'
SharedImage.propTypes = propTypes
SharedImage.defaultProps = defaultProps

export default SharedImage
