import PropType from 'prop-types'

import userShape from './user'

export default {
  _id: PropType.string,
  name: PropType.string,
  description: PropType.string,
  state: PropType.oneOf(['stopped', 'running']),
  user: PropType.oneOfType([
    PropType.string, // id
    PropType.shape(userShape)
  ]),
  appmixerFlowId: PropType.string,
  appmixer: PropType.shape({
    thumbnail: PropType.string // thumbnail image data uri
  }),
  bundles: PropType.array, // TODO: array of what?
  search: PropType.string,
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  __v: PropType.number
}
