import { useCallback, useEffect, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import TextInputIcon from '../../components/forms/TextInputIcon'
import TermsLink from '../../components/links/TermsLink'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import { toCreditOptions } from '../../helpers/credits'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import {
  giftCredits as giftCreditsService,
  gift as giftService,
  validation as validPBNumberService
} from '../../services/credit'

import validationSuite from './GiftCredits.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['GiftCredits'] })

const GiftCredits = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formRef = useRef()
  const [validPBNumber, setValidPBNumber] = useState(true)
  const [giftedUser, setGiftedUser] = useState()
  const [credits, setCredits] = useState([])

  const handleCreditOk = useCallback((result) => {
    setCredits(toCreditOptions(result?.json))
  }, [])
  const handleGiftOk = useCallback(() => {
    formRef.current.reset()
    notify('Credits Gifted Successfully')
  }, [])
  const handleGiftReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])
  const handleValidateOk = useCallback((result) => {
    setValidPBNumber(!result?.ok)
    setGiftedUser(result?.json)
  }, [])

  const { call: fetchCredit } = useService(giftCreditsService, { onReplyOk: handleCreditOk })
  const { call: pbNumberValidation } = useService(validPBNumberService, { onReplyOk: handleValidateOk })
  const { call: createGiftCredit } = useService(giftService, { onReplyOk: handleGiftOk, onReply: handleGiftReply })

  const onSubmitHandler = useCallback((data) => {
    createGiftCredit({ _id: giftedUser?._id, amount: data?.credits })
  }, [createGiftCredit, giftedUser?._id])

  const handleSearchIcon = () => {
    const phoneNumber = formRef.current
      .getValues()
      .phoneNumber.replace(/[^\d]/g, '')
    pbNumberValidation({ phoneNumber })
  }
  useEffect(() => {
    fetchCredit()
  }, [fetchCredit])

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='giftCreditForm'
            title='Purchase'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        title='Gift Credits'
      />
      <PanelContent className='p-5'>
        <p className='mb-2'>
          Well look at you... Giving the gift of Project Broadcast! Helping
          others succeed in their business feels great and I'm sure the person
          you are gifting to will be super excited!
        </p>
        <p className='mb-2'>
          Enter the Project Broadcast phone number of the person you want to
          gift credits to below and tap the lookup button. We will verify the
          number to make sure you are gifting to the right person.
        </p>
        <Form
          ref={formRef}
          defaultValues={{}}
          formControls={[
            {
              type: 'text',
              name: 'phoneNumber',
              label: 'PB Numbers',
              end: <TextInputIcon icon='search' onClick={handleSearchIcon} />
            },
            {
              type: 'passthrough',
              name: 'passthrough',
              element:
                !validPBNumber && giftedUser?.name
                  ? (
                    <p>
                      You have selected
                      {' '}
                      <strong>{`${giftedUser?.name}`}</strong>
                      {' '}
                      to receive your gift. Please select the bundle below for
                      this gift.
                      {' '}
                    </p>
                    )
                  : <span />
            },
            {
              label: 'Available Credit Bundles',
              name: 'credits',
              type: 'select',
              options: [{ label: '', value: '' }].concat(credits),
              disabled: validPBNumber
            }
          ]}
          id='giftCreditForm'
          validationSuite={validationSuite}
          preventMultipleSubmits
          onSubmit={onSubmitHandler}
        />
        <p className='mb-2'>
          By continuing, you understand that Project Broadcast will charge your card on file for the above selection and the corresponding credit quantity will be applied to the Project Broadcast number above. The credits will be available immediately to the recipient. This does not impact your current subscription.
          {' '}
          <strong>Refunds are not provided.</strong>
          {' '}
          Complete terms can be found
          {' '}
          <TermsLink label='here' />
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='giftCreditForm'
            size='sm'
            type='submit'
          >
            Purchase
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

GiftCredits.displayName = 'GiftCredits'
GiftCredits.propTypes = propTypes
GiftCredits.defaultProps = defaultProps

export default GiftCredits
