import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { subscribe, unsubscribe, unsubscribeLanding } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Unsubscribe'] })

const Unsubscribe = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { contactSlug } = useParams()
  const [contact, setContact] = useState(null)
  const [error, setError] = useState(null)

  const handleReplyOk = useCallback((reply) => { setContact(reply.model) }, [])
  const handleReplyNotOk = useCallback((/* reply */) => {
    setError('Sorry, ran into an unexpected error. Please try again later.')
  }, [])
  const { call: callLanding, loading: landing } = useService(unsubscribeLanding, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })
  const { call: callUnsubscribe, loading: unsubscribing } = useService(unsubscribe, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })
  const { call: callSubscribe, loading: subscribing } = useService(subscribe, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })
  useEffect(() => { callLanding(contactSlug) }, [callLanding, contactSlug])

  const handleSubscribeClick = useCallback(() => { callSubscribe(contactSlug) }, [callSubscribe, contactSlug])
  const handleUnsubscribeClick = useCallback(() => { callUnsubscribe(contactSlug) }, [callUnsubscribe, contactSlug])

  const loading = (landing || unsubscribing || subscribing || !contact) && !error

  const message = contact?.doNotEmail ? 'You have been successfully unsubscribed of further email messages from' : 'You are currently subscribed to receive email messages from'
  const action = contact?.doNotEmail ? <Button className='mx-auto' size='md' onClick={handleSubscribeClick}>Subscribe</Button> : <Button className='mx-auto' size='md' onClick={handleUnsubscribeClick}>Unsubscribe</Button>

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <div className='text-center'>
        <p>
          {message}
          {' '}
          <strong>
            {contact.user.formattedName}
          </strong>
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <div className='mt-4'>
          {action}
        </div>
      </div>
    </ShortLinkContent>
  )
}

Unsubscribe.displayName = 'Unsubscribe'
Unsubscribe.propTypes = propTypes
Unsubscribe.defaultProps = defaultProps

export default Unsubscribe
