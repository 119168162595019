import * as React from "react";
import { memo } from "react";
const SvgGear = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.707.293a1 1 0 0 0-1.414 0L8.586 3H4a1 1 0 0 0-1 1v4.586L.293 11.293a1 1 0 0 0 0 1.414L3 15.414V20a1 1 0 0 0 1 1h4.586l2.707 2.707a1 1 0 0 0 1.414 0L15.414 21H20a1 1 0 0 0 1-1v-4.586l2.707-2.707A1 1 0 0 0 23.8 11.4L21 7.667V4a1 1 0 0 0-1-1h-4.586L12.707.293Zm-3 4.414L12 2.414l2.293 2.293A1 1 0 0 0 15 5h4v3a1 1 0 0 0 .2.6l2.48 3.306-2.387 2.387A1 1 0 0 0 19 15v4h-4a1 1 0 0 0-.707.293L12 21.586l-2.293-2.293A1 1 0 0 0 9 19H5v-4a1 1 0 0 0-.293-.707L2.414 12l2.293-2.293A1 1 0 0 0 5 9V5h4a1 1 0 0 0 .707-.293ZM9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGear);
export default Memo;
