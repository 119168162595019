import PropType from 'prop-types'

import VersionText from '../../../components/VersionText'

const propTypes = {
  children: PropType.node
}

const defaultProps = {
  children: null
}

const PanelWrapper = ({ children }) => {
  return (
    <div className='flex flex-col h-full pb-safe'>
      <div className='w-full sm:w-[360px] p-5 m-auto'>
        {children}
      </div>
      <span className='label-xs text-center pb-2'>
        <VersionText />
      </span>
    </div>
  )
}

PanelWrapper.displayName = 'PanelWrapper'
PanelWrapper.propTypes = propTypes
PanelWrapper.defaultProps = defaultProps

export default PanelWrapper
