import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export const initialState = {
  appIsActive: true,
  keyboardOnScreen: false,

  requestedLocation: null,

  apiLoading: {
    activated: false,
    callCount: 0,
    messageOverride: null
  },

  stacks: {},
  stackBackButtonLocation: null,
  backButtonHandlerOverride: null,

  currentUser: null,

  isBroadcastingPaused: false,

  unreadCounts: {
    chats: 0,
    spaces: 0,
    total: 0
  },

  current: { // used in conjunction with `useCurrentInstance` when :instanceId param is in the URL
    appFlow: null,
    attachment: null,
    bundle: null,
    campaign: null,
    campaignEvent: null,
    chat: null,
    configurableForm: null,
    contact: null,
    contest: null,
    email: null,
    folder: null,
    keyword: null,
    landingPage: null,
    messageTemplate: null,
    qrCode: null,
    scheduledBroadcast: null,
    space: null,
    tag: null,
    template: null, // TODO: remove
    trackableLink: null,
    user: null, // useSelectedUser
    voiceDrop: null
  },

  remoteDataSources: {
    aivaMessagePrompts: null,
    messageTagUrlInfo: null,
    timeZones: null
  },

  nativeSharedImage: {
    imageData: null,
    mimeType: null,
    sharedAt: null
  },

  lists: {},

  listFolderModes: {
    attachment: false,
    bundle: false,
    campaign: false,
    configurableForm: false,
    contest: false,
    email: false,
    keyword: false,
    landingPage: false,
    messageTemplate: false,
    qrCode: false,
    template: false, // TODO: remove
    trackableLink: false,
    voiceDrop: false
  }
}

export const reset = (label = 'reset', withInitialState = null) => {
  // eslint-disable-next-line no-unused-vars
  useStore.setState((state) => ({ ...initialState, ...withInitialState }), false, label)
}

const useStore = create(
  immer(
    // eslint-disable-next-line no-unused-vars
    devtools((set) => initialState)
  )
)

export default useStore
