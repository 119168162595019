import { useParams } from 'react-router-dom'

import ScheduledChatThread from '../../components/chats/ScheduledChatThread'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentChat from '../../hooks/useCurrentChat'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ScheduledChatsThread'] })

const ScheduledChatsThread = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { chatId } = useParams()
  const { chat, loading } = useCurrentChat()

  if (!chat || loading) { return <PanelLoading hideMenuToggleButton /> }

  return (
    <Panel hideMenuToggleButton>
      <PanelHeader start={<PanelHeaderBackButton />} subtitle={chat.formattedName} title='Scheduled Messages' />
      <PanelContent>
        <ScheduledChatThread contactId={chatId} />
      </PanelContent>
    </Panel>
  )
}

ScheduledChatsThread.displayName = 'ScheduledChatsThread'
ScheduledChatsThread.propTypes = propTypes
ScheduledChatsThread.defaultProps = defaultProps

export default ScheduledChatsThread
