import * as React from "react";
import { memo } from "react";
const SvgQrCodes = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 180" {...props}>
    <path fill="#EEF2FE" d="M0 0h375v180H0z" />
    <circle
      cx={189.5}
      cy={94}
      r={73.5}
      fill="#fff"
      stroke="#E1E7FF"
      strokeWidth={3}
    />
    <path
      fill="#6366F1"
      fillRule="evenodd"
      d="M178.75 67H163v16.875h4.5V71.5h11.25V67ZM163 121v-14.625h4.5V116.5h11.25v4.5H163Zm49.5-4.5v-10.125h4.5V121h-15.75v-4.5h11.25ZM217 67v16.875h-4.5V71.5h-11.25V67H217Zm-33.75 13.5h-6.75v6.75h6.75V80.5ZM172 76v15.75h15.75V76H172Zm31.5 4.5h-6.75v6.75h6.75V80.5ZM192.25 76v15.75H208V76h-15.75Zm-15.75 24.75h6.75v6.75h-6.75v-6.75ZM172 112V96.25h15.75V112H172Zm31.5-11.25h-6.75v6.75h6.75v-6.75Zm-11.25-4.5V112H208V96.25h-15.75Z"
      clipRule="evenodd"
    />
    <circle cx={95} cy={142.5} r={6.5} fill="#C7D2FF" />
    <circle cx={113} cy={40.5} r={4.5} fill="#C7D2FF" />
    <circle cx={269.5} cy={44} r={6} fill="#C7D2FF" />
    <circle cx={287.5} cy={14} r={4} fill="#C7D2FF" />
  </svg>
);
const Memo = memo(SvgQrCodes);
export default Memo;
