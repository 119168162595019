import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import useScript from '../../hooks/useScript'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { editorConfig as editorConfigService } from '../../services/appFlow'
import Banner from '../banners/Banner'
import LoadingIndicator from '../LoadingIndicator'

import './Appmixer.css'

const propTypes = {
  appmixerTargetId: PropType.string.isRequired,
  onInit: PropType.func.isRequired,
  loadingMessage: PropType.string
}

const defaultProps = {
  loadingMessage: 'Loading...'
}

const log = logger({ enabled: true, tags: ['AppmixerUiContainer'] })

const AppmixerUiContainer = ({ appmixerTargetId, onInit, loadingMessage }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const init = useCallback(async ({ baseUrl, accessToken }) => {
    const appmixer = new Appmixer() // eslint-disable-line no-undef
    appmixer.set('baseUrl', baseUrl)
    appmixer.set('accessToken', accessToken)

    appmixer.set('theme', {
      variables: {
        font: {
          family: process.env.REACT_APP_FONT_FAMILY_SANS.replace(/"/g, '\\"'),
          size: 16
        }
      }
    })

    await onInit(appmixer)
    setLoaded(true)
  }, [onInit])

  const {
    call: editorConfig
  } = useService(editorConfigService, {
    onReplyOk: (reply) => init(reply.json)
  })

  useScript({
    src: process.env.REACT_APP_APPMIXER_SCRIPT_SRC,
    onLoad: () => editorConfig(),
    onError: () => setError('Failed to load.')
  })

  return (
    <div className='flex flex-1 min-height[500px]'>
      {!loaded && !error
        ? <LoadingIndicator className='mx-auto mt-32' message={loadingMessage} />
        : null}
      {error
        ? (
          <div className='p-5'>
            <Banner resolutionAction='Reload Page' type='error'>
              The Appmixer component may be currently unavailable. Please try again momentarily.
              <div className='paragraph-sm text-neutral-400'>
                {error}
              </div>
            </Banner>
          </div>
          )
        : null}
      <div className={loaded ? 'opacity-100' : 'opacity-0'} id={appmixerTargetId} />
    </div>
  )
}

AppmixerUiContainer.displayName = 'AppmixerUiContainer'
AppmixerUiContainer.propTypes = propTypes
AppmixerUiContainer.defaultProps = defaultProps

export default AppmixerUiContainer
