import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useId } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import Button from '../buttons/Button'

import BaseDialog from './BaseDialog'

const propTypes = {
  description: PropType.oneOfType([PropType.string, PropType.element]).isRequired,
  title: PropType.string.isRequired,
  cancelTitle: PropType.string,
  confirmTitle: PropType.string,
  trigger: PropType.node,
  onCancel: PropType.func,
  onConfirm: PropType.func
}

const defaultProps = {
  cancelTitle: 'Cancel',
  confirmTitle: 'Confirm',
  onCancel: null,
  onConfirm: null,
  trigger: null
}

const log = logger({ enabled: false, tags: ['ConfirmDialog'] })

const ConfirmDialog = forwardRef(({ description, trigger, title, cancelTitle, confirmTitle, onCancel, onConfirm }, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [] })

  const titleId = useId()
  const descriptionId = useId()
  const smallScreen = useSmallScreen()

  const handleConfirm = useCallback(() => {
    ref.current.close()
    onConfirm?.()
  }, [onConfirm, ref])

  const handleCancel = useCallback(() => {
    ref.current.close()
    onCancel?.()
  }, [onCancel, ref])

  const className = classNames(
    'bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[375px] flex flex-col overflow-auto',
    {
      'small-screen-dialog': smallScreen && (description.length > 750)
    }
  )

  return (
    <BaseDialog
      ref={ref}
      ariaDescribedBy={descriptionId}
      ariaLabelledBy={titleId}
      trigger={trigger}
    >
      <div className={className}>
        <h1 className='label-lg-strong text-black text-center mb-[20px] break-words' id={titleId}>{title}</h1>
        <p className='paragraph-sm text-neutral-500 text-center mb-[20px]' id={descriptionId}>{description}</p>
        <div className='flex flex-row flex-nowrap gap-2 justify-center'>
          <Button size='md' onClick={handleConfirm}>{confirmTitle}</Button>
          <Button size='md' variant='outline' onClick={handleCancel}>{cancelTitle}</Button>
        </div>
      </div>
    </BaseDialog>
  )
})

ConfirmDialog.displayName = 'ConfirmDialog'
ConfirmDialog.propTypes = propTypes
ConfirmDialog.defaultProps = defaultProps

export default ConfirmDialog
