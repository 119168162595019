import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import Button from '../buttons/Button'
import ConfirmDialog from '../dialog/ConfirmDialog'
import Pill from '../pill/Pill'

const propTypes = {
  selectedCount: PropType.number.isRequired,
  selectedItems: PropType.array.isRequired, // eslint-disable-line react/forbid-prop-types
  totalCount: PropType.number.isRequired,
  type: PropType.oneOf(['add', 'bulk', 'move', 'remove']).isRequired,
  onSelectAll: PropType.func.isRequired,
  onSelectSelected: PropType.func.isRequired,
  actionConfirm: PropType.string,
  actionLabel: PropType.string,
  bulkActions: PropType.elementType
}

const defaultProps = {
  actionConfirm: null,
  actionLabel: null,
  bulkActions: null
}

const log = logger({ enabled: false, tags: ['ListSelectedStatusBar'] })

const ListSelectedStatusBar = ({ bulkActions: BulkActions, selectedCount, selectedItems, totalCount, type, actionLabel, actionConfirm, onSelectAll, onSelectSelected }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const wrappClassName = 'flex flex-row justify-between items-center px-2 py-2 border-t border-neutral-200'
  const buttonClassName = 'text-primary label-sm'
  const buttonProps = { className: buttonClassName, size: 'none', theme: 'none', variant: 'none' }

  const action = actionLabel || (type === 'remove' ? 'Remove' : (type === 'move' ? 'Move' : 'Add'))
  const confirmTitle = 'Are you sure?'
  const confirmAction = actionConfirm || (type === 'remove' ? 'removing' : (type === 'move' ? 'moving' : 'adding'))
  const confirmDescription = `You're ${confirmAction} ${totalCount}`

  if (type === 'bulk') {
    return (
      <div className={wrappClassName}>
        <span className='flex flex-nowrap justify-center items-center text-primary label-sm'>
          <Pill className='mr-1' value={selectedCount} />
          Selected
        </span>
        <BulkActions selectedItems={selectedItems} />
      </div>
    )
  }

  return (
    <div className={wrappClassName}>
      <ConfirmDialog
        description={confirmDescription}
        title={confirmTitle}
        trigger={(
          <Button {...buttonProps}>
            <Pill className='mr-1' value={totalCount} />
            {action}
            {' '}
            All
          </Button>
        )}
        onConfirm={onSelectAll}
      />
      <Button onClick={onSelectSelected} {...buttonProps}>
        <Pill className='mr-1' value={selectedCount} />
        {action}
      </Button>
    </div>
  )
}

ListSelectedStatusBar.displayName = 'ListSelectedStatusBar'
ListSelectedStatusBar.propTypes = propTypes
ListSelectedStatusBar.defaultProps = defaultProps

export default ListSelectedStatusBar
