import { useRef } from 'react'

import ActionMenu from '../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../components/action-menu/ActionMenuItem'
import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Placeholder } from '../../svg/icons'

const log = logger({ enabled: true, tags: ['Style Guide', 'ActionMenus'] })

function ActionMenus () {
  useLogger({ log, lifecycle: false, tags: [] })

  const actionMenuRef1 = useRef(null)

  return (
    <Panel>
      <PanelHeader title='Action Menus' />
      <PanelContent>
        <div className='p-4'>
          <ActionMenu placement='bottom-start' trigger={<Button icon={<Placeholder />} size='sm'>Action Menu</Button>}>
            <ActionMenuHeader label='Optional Header' />
            <ActionMenuDivider />
            <ActionMenuItem label='First Item' />
            <ActionMenuItem label='Second Item' onClick={() => log.info('Selected item 2')} />
            <ActionMenuItem label='Third Item' onClick={() => log.info('Selected item 3')} />
            <ActionMenuDivider />
            <ActionMenuItem className='text-danger' label='Dangerous Item' onClick={() => log.info('Selected the dangerous item')} />
          </ActionMenu>
        </div>
        <div className='p-4 flex flex-row gap-2'>
          <ActionMenu
            ref={actionMenuRef1}
            offset={10}
            placement='bottom-start'
            trigger={<Button icon={<Placeholder />} size='sm'>Action Menu</Button>}
          >
            <ActionMenuHeader label='Optional Header' />
            <ActionMenuDivider />
            <ActionMenuItem label='Item 1' onClick={() => log.info('Selected item 1')} />
            <ActionMenuItem label='Item 2' onClick={() => log.info('Selected item 2')} />
            <ActionMenuItem label='Item 3' onClick={() => log.info('Selected item 3')} />
            <ActionMenuDivider />
            <ActionMenuItem className='text-danger' label='Dangerous Item' onClick={() => log.info('Selected the dangerous item')} />
          </ActionMenu>
          <Button size='sm' onClick={() => actionMenuRef1.current.open()}>Open</Button>
        </div>
        <div className='p-4'>
          <ActionMenu placement='bottom-start' trigger={<Button icon={<Placeholder />} size='sm'>Action Menu</Button>}>
            <ActionMenuHeader label='Optional Header' />
            <ActionMenuDivider />
            <ActionMenuItem end={<Placeholder />} label='First Item' start={<Placeholder />} />
            <ActionMenuItem
              end={<Placeholder />}
              label='Second Item'
              start={<Placeholder />}
              onClick={() => log.info('Selected item 2')}
            />
            <ActionMenuItem
              end={<Placeholder />}
              label='Third Item'
              start={<Placeholder />}
              onClick={() => log.info('Selected item 3')}
            />
            <ActionMenuDivider />
            <ActionMenuItem
              className='text-danger'
              end={<Placeholder />}
              label='Dangerous Item'
              start={<Placeholder />}
              onClick={() => log.info('Selected the dangerous item')}
            />
          </ActionMenu>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default ActionMenus
