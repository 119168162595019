import PropType from 'prop-types'

import { messageDirections } from '../../helpers/message'
import messageTypes from '../../helpers/messageTypes'

import keyword from './keyword'
import user from './user'

export const baseMessageFields = {
  media: PropType.arrayOf(PropType.string), // urls
  text: PropType.string, // message body
  type: PropType.oneOf(messageTypes)
}

export default {
  ...baseMessageFields,
  __v: PropType.number,
  _id: PropType.string,
  archived: PropType.bool,
  billable: PropType.bool,
  contact: PropType.oneOfType([
    PropType.string, // contact id,
    PropType.shape({
      _id: PropType.string
      // rest of the contact shape... can't import due to circular imports
    })
  ]),
  contactUser: PropType.string, // user id
  createdAt: PropType.string,  // "2022-12-15T13:27:46.413Z"
  deliveryState: PropType.oneOf([
    'waiting',
    'delivered',
    'not-delivered'
  ]),
  direction: PropType.oneOf(messageDirections),
  emailMessage: PropType.shape({
    _id: PropType.string,
    subject: PropType.string
  }),
  error: PropType.string, // error message
  flags: PropType.shape({
    appliedDynamicField: PropType.bool
  }),
  from: PropType.string, // "6073260323"
  fromApi: PropType.bool,
  keyword: PropType.oneOfType([
    PropType.string, // keyword id
    PropType.shape(keyword)
  ]),
  keywordAppliedByOther: PropType.oneOf([
    'user',
    'form',
    'campaign'
  ]),
  needsMediaFix: PropType.bool,
  sendAsMMS: PropType.bool,
  space: PropType.string, // space id if this message belongs to a space
  state: PropType.oneOf([
    'received',
    'queuing',
    'queued',
    'scheduled', // this can occur when scheduled broadcast message
    'sending',
    'sent',
    'error',
    'skipped'
  ]),
  template: PropType.shape({
    _id: PropType.string,
    name: PropType.string
  }),
  time: PropType.string, // "2022-12-15T13:27:46.325Z"
  to: PropType.string, // "6073260323"
  updatedAt: PropType.string, // "2022-12-15T13:27:46.413Z"
  usedTags: PropType.shape({
    attachments: PropType.arrayOf(PropType.string),
    configurableForms: PropType.arrayOf(PropType.string),
    dynamicFields: PropType.arrayOf(PropType.string),
    landingPages: PropType.arrayOf(PropType.string),
    trackableLinks: PropType.arrayOf(PropType.string)
  }),
  user: PropType.oneOfType([
    PropType.string, // user id
    PropType.shape(user)
  ])
}
