import * as React from "react";
import { memo } from "react";
const SvgFolder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <mask id="Folder_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M2 1a2 2 0 0 0-2 2v17.869a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V5.456a2 2 0 0 0-2-2H11.24L9.99 1.797A2 2 0 0 0 8.393 1H2Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="currentColor"
      d="M11.24 3.456 9.64 4.658l.6.798h.998v-2ZM9.99 1.797 11.59.595 9.991 1.797ZM2 3v-4a4 4 0 0 0-4 4h4Zm0 2.456V3h-4v2.456h4Zm0 12.957V5.456h-4v12.957h4Zm0 2.456v-2.456h-4v2.456h4Zm0 0h-4a4 4 0 0 0 4 4v-4Zm20 0H2v4h20v-4Zm0 0v4a4 4 0 0 0 4-4h-4Zm0-15.413v15.413h4V5.456h-4Zm0 0h4a4 4 0 0 0-4-4v4Zm-10.76 0H22v-4H11.24v4ZM8.392 3l1.248 1.658 3.197-2.405L11.59.595 8.392 3Zm0 0L11.59.595A4 4 0 0 0 8.393-1v4ZM2 3h6.393v-4H2v4Z"
      mask="url(#Folder_svg__a)"
    />
    <rect
      width={22}
      height={14}
      x={1}
      y={8}
      stroke="currentColor"
      strokeWidth={2}
      rx={1}
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.393 3 1.848 2.456H22V7H2V3h6.393ZM2 9v11.87h20V9H2ZM0 9V3a2 2 0 0 1 2-2h6.393a2 2 0 0 1 1.598.797l1.248 1.659H22a2 2 0 0 1 2 2V21a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgFolder);
export default Memo;
