import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { baseMessageFields } from '../../../prop-types/shapes/message'
import { VoiceDrop } from '../../../svg/types'
import Button from '../../buttons/Button'

const propTypes = {
  message: PropType.shape(baseMessageFields).isRequired,
  mediaUrl: PropType.string,
  size: PropType.oneOf(['sm', 'md', 'lg']),
  onRefreshForVoice: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onRefreshForVoice: undefined,
  size: 'md'
}

const log = logger({ enabled: true, tags: ['VoiceMessageFragment'] })

const VoiceMessageFragment = ({ mediaUrl, message, size, onRefreshForVoice }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const audioOrRecording = useMemo(() => {
    if (mediaUrl) {
      return (
        <audio
          className='outline-0 w-full my-1 mr-3'
          preload='auto'
          src={mediaUrl}
          controls
        />
      )
    }
    return (
      <div className='bg-neutral-200 rounded-lg mr-3 p-3'>
        <div className='label-xs-strong'>
          Calling Phone to Record
        </div>
        <div className='label-xs-medium'>
          The system will call your Call Forwarding phone number and prompt you to record your voice drop.
          {onRefreshForVoice
            ? (
              <>
                Refresh when recording is complete.
                <Button
                  className='mt-1 self-end'
                  size='sm'
                  variant='outline'
                  onClick={onRefreshForVoice}
                >
                  Refresh
                </Button>
              </>
              )
            : null}
        </div>
      </div>
    )
  }, [mediaUrl, onRefreshForVoice])

  if (!mediaUrl && message.type !== 'rvm') { return }

  if (size !== 'lg' && !mediaUrl) {
    return (
      <div>
        (
        {!mediaUrl ? 'recording ' : ''}
        voice drop)
      </div>
    )
  }

  if (size !== 'lg' && mediaUrl) {
    return (
      <audio
        className='outline-0 h-6 w-full my-1'
        preload='auto'
        src={mediaUrl}
        controls
      />
    )
  }

  if (message.template) {
    return (
      <div className='flex items-center px-3 pt-3 flex w-full'>
        <VoiceDrop className='w-6 h-6 mr-2 shrink-0 self-start' />
        <div className='flex flex-col w-full'>
          <div>
            <strong>
              {message.template?.name || 'Voice Drop'}
            </strong>
          </div>
          {audioOrRecording}
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center px-3 pt-3 flex w-full'>
      {audioOrRecording}
    </div>
  )
}

VoiceMessageFragment.displayName = 'VoiceMessageFragment'
VoiceMessageFragment.propTypes = propTypes
VoiceMessageFragment.defaultProps = defaultProps

export default VoiceMessageFragment
