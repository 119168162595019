import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import { appmixerGet, appmixerPut } from '../../lib/appmixer'
import logger from '../../lib/logger'

import AppmixerUiContainer from './AppmixerUiContainer'

const propTypes = {
  onReady: PropType.func,
  onValidate: PropType.func
}

const defaultProps = {
  onReady: undefined,
  onValidate: undefined
}

const log = logger({ enabled: true, tags: ['AppmixerEditor'] })

const AppmixerEditor = forwardRef(({ onReady, onValidate }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })
  const designerRef = useRef()
  const hasLoadedRef = useRef(false)
  const savedEditCountRef = useRef(0)
  const [currentErrors, setCurrentErrors] = useState(null)

  useEffect(() => {
    onValidate?.(currentErrors)
  }, [currentErrors, onValidate])

  const handleInit = useCallback((appmixer) => {
    return new Promise((resolve) => {
      appmixer.set('theme', {
        ui: {
          shapes: {
            action: 'action-vertical',
            trigger: 'trigger-vertical',
            selection: 'selection-vertical'
          }
        }
      })

      const designer = appmixer.ui.Designer({
        el: '#appmixerDesigner',
        options: {
          showHeader: false,
          toolbar: [
            ['undo', 'redo'],
            ['zoom-to-fit', 'zoom-in', 'zoom-out'],
            ['logs']
          ]
        },
        api: {
          // Intercept getFlow request so we can control loading indicator
          //  since we don't get a loaded event from appmixer designer :-(
          async getFlow (flowId) {
            const flow = await appmixerGet(this, `/flows/${flowId}`)
            setTimeout(() => resolve(), 333)
            hasLoadedRef.current = true
            return flow
          },
          async updateFlow (flowId, update) {
            const flow = await appmixerPut(this, `/flows/${flowId}`, update)
            savedEditCountRef.current++
            return flow
          }
        }
      })

      designer.on('flow:validation', (errors) => {
        if (hasLoadedRef.current) {
          setCurrentErrors(errors || [])
        }
      })

      designerRef.current = designer
      onReady?.()
    })
  }, [onReady])

  useImperativeHandle(ref, () => ({
    loadAppFlow (appFlow) {
      designerRef.current.set('flowId', appFlow.appmixerFlowId)
      designerRef.current.open()
    },
    reload () {
      designerRef.current.reload()
    },
    hasSavedEdits () {
      return savedEditCountRef.current > 0
    }
  }), [])

  return (
    <AppmixerUiContainer
      appmixerTargetId='appmixerDesigner'
      loadingMessage='Loading Flow Designer...'
      onInit={handleInit}
    />
  )
})

AppmixerEditor.displayName = 'AppmixerEditor'
AppmixerEditor.propTypes = propTypes
AppmixerEditor.defaultProps = defaultProps

export default AppmixerEditor
