import * as React from "react";
import { memo } from "react";
const SvgDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.096 4.256.001-.001A.999.999 0 0 1 11.77 4H21c.548 0 1 .452 1 1v14c0 .548-.452 1-1 1h-9.23c-.246 0-.488-.09-.673-.255l-.001-.001L2.495 12l8.6-7.744ZM11.77 2c-.746 0-1.452.27-2.006.764L.331 11.257a1 1 0 0 0 0 1.486l9.43 8.49.003.003A3.017 3.017 0 0 0 11.77 22H21c1.652 0 3-1.348 3-3V5c0-1.652-1.348-3-3-3h-9.23Zm-.477 6.293a1 1 0 0 1 1.414 0L15 10.586l2.293-2.293a1 1 0 1 1 1.414 1.414L16.414 12l2.293 2.293a1 1 0 0 1-1.414 1.414L15 13.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L13.586 12l-2.293-2.293a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgDelete);
export default Memo;
