import React from 'react'

import PanelNotFound from '../components/panels/PanelNotFound'
import PanelUnderConstruction from '../components/panels/PanelUnderConstruction'
import AddOns from '../screens/subscription/AddOns'
import AutoRecharge from '../screens/subscription/AutoRecharge'
import BuyCredits from '../screens/subscription/BuyCredits'
import CancelSubscription from '../screens/subscription/CancelSubscription'
import ChangeSubscription from '../screens/subscription/ChangeSubscription'
import GiftCredits from '../screens/subscription/GiftCredits'
import PaymentHistory from '../screens/subscription/PaymentHistory'
import UpdatePayment from '../screens/subscription/UpdatePayment'

const routes = [
  {
    path: 'change-subscription',
    element: <ChangeSubscription />
  },
  {
    path: 'change-subscription/cancel-subscription',
    element: <CancelSubscription />
  },
  {
    path: 'payment-history',
    element: <PaymentHistory />
  },
  {
    path: 'update-payment',
    element: <UpdatePayment />
  },
  {
    path: 'add-ons',
    element: <AddOns />
  },
  {
    path: 'gift-credits',
    element: <GiftCredits />
  },
  {
    path: 'auto-recharge',
    element: <AutoRecharge />
  },
  {
    path: 'buy-credits',
    element: <BuyCredits />
  },
  {
    path: 'gift-credits',
    element: <PanelUnderConstruction title='Gift Credits' />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
