import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Calendar } from '../../../svg/icons'
import Pill from '../../pill/Pill'
import Pluralize from '../../Pluralize'

const propTypes = {
  count: PropType.number.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBadge'] })

const ScheduledBadge = ({ count }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!count) { return }

  return (
    <div className='flex flex-row flex-nowrap items-center'>
      <Calendar height='16' width='16' />
      <Pill className='mt-0.5 ml-1' value={count} />
      <span className='pt-1 pl-1 label-xs text-neutral-500'>
        <Pluralize count={count} singular='Scheduled Chat' />
      </span>
    </div>
  )
}

ScheduledBadge.displayName = 'ScheduledBadge'
ScheduledBadge.propTypes = propTypes
ScheduledBadge.defaultProps = defaultProps

export default ScheduledBadge
