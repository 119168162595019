import { useCallback } from 'react'

import { success } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import DetailSection from '../../../components/detail-sections/DetailSection'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import { needsTcrInfo, pendingSmsVerification, tcrAdminCanResubmit, tcrAdminEditable, tcrApplicable, tcrComplete, tcrFailed, tcrRegistering } from '../../../helpers/user-tcr'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { resubmitTwilioSolePropCampaign as resubmitTwilioSolePropCampaignService } from '../../../services/admin'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignRegistration'] })

const CampaignRegistration = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { loading, setUser, user } = useSelectedUser()

  const onReplyOk = useCallback((reply) => {
    setUser(reply.model)
    success('Campaign Resubmitted')
  }, [setUser])

  const { call: resubmitTwilioSolePropCampaign } = useService(resubmitTwilioSolePropCampaignService, { onReplyOk })

  const handleResubmit = useCallback(() => {
    resubmitTwilioSolePropCampaign(user?._id)
  }, [resubmitTwilioSolePropCampaign, user?._id])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  const prettyDebugInfo = JSON.stringify(user.tcr, null, 2)
  const tcrStatus = user.registration?.isTCR ? user.tcr?.status : user.twilio?.status
  const registrationFailed = tcrFailed(user)
  const showEditIcon = tcrAdminEditable(user)
  const showResubmitButton = tcrAdminCanResubmit(user)

  let friendlyStatus = 'unknown'
  let failedReason = 'unknown'

  if (needsTcrInfo(user)) {
    friendlyStatus = 'Registration Required'
  } else if (!tcrApplicable(user)) {
    friendlyStatus = 'No action necessary'
  } else if (tcrComplete(user)) {
    friendlyStatus = 'Congratulations!'
  } else if (pendingSmsVerification(user)) {
    friendlyStatus = 'SMS Verification Required'
  } else if (tcrRegistering(user)) {
    friendlyStatus = 'Registration Pending'
  } else if (registrationFailed) {
    friendlyStatus = 'Registration Failed'
    failedReason = user.registration?.isTCR ? user.tcr?.failedReason : user.twilio?.failedReason
  }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Campaign Registration'
      />
      <PanelContent className='p-4'>
        <DetailSection
          editHref='edit'
          showEditIcon={showEditIcon}
          title={`Status: ${tcrStatus}`}
        >
          <p>
            {friendlyStatus}
          </p>
          {registrationFailed
            ? (
              <>
                <p className='mt-4' title={prettyDebugInfo}>
                  {failedReason}
                </p>
                {showResubmitButton
                  ? (
                    <div className='flex flex-row gap-4 items-center mt-4'>
                      <Button
                        className='flex-grow md:flex-none'
                        size='sm'
                        onClick={handleResubmit}
                      >
                        Resubmit
                      </Button>
                    </div>
                    )
                  : null}
              </>
              )
            : null}
        </DetailSection>
      </PanelContent>
    </Panel>
  )
}

CampaignRegistration.displayName = 'CampaignRegistration'
CampaignRegistration.propTypes = propTypes
CampaignRegistration.defaultProps = defaultProps

export default CampaignRegistration
