import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { messageStrengthLabel, messageStrengthTextBgColor, messageStrengthTextFgColor } from '../../../helpers/messageQuality'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageQualityShape from '../../../prop-types/shapes/messageQuality'
import { AivaWithShadow } from '../../../svg/misc/aiva-panels'

import MessageQualityListItem from './MessageQualityListItem'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MessageQualityAivaVariant'] })

const MessageQualityAivaVariant = ({ messageQuality }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const strengthIndicator = useMemo(() => {
    const { strengthRating } = messageQuality
    const strengthClassName = classNames(
      'p-1 uppercase label-2xs-strong',
      messageStrengthTextBgColor(strengthRating),
      messageStrengthTextFgColor(strengthRating)
    )
    return (
      <span className={strengthClassName}>
        {messageStrengthLabel(strengthRating)}
        {' '}
        Message
      </span>
    )
  }, [messageQuality])

  return (
    <div className='flex flex-col gap-3'>
      <div className='bg-indigo-50 p-3 rounded-lg w-full flex flex-row items-center gap-3'>
        <div className='w-20 h-20 rounded-full bg-white flex-none flex justify-center items-center'>
          <AivaWithShadow className='text-primary w-12 h-12 inline' />
        </div>
        <div className='grow flex-col'>
          <h1 className='label-sm-strong flex flex-row flex-wrap gap-x-1 items-center'>
            AIVA Message Rating
            {strengthIndicator}
          </h1>
          <p className='label-xs'>
            AIVA, our AI virtual assistant, offers her best estimate to help you gauge your message's chances
            of reaching and being read. Though we can't guarantee delivery, AIVA provides helpful insights to
            increase your chances!
          </p>
        </div>
      </div>
      {messageQuality.cons?.length > 0
        ? (
          <div className='bg-blue-50 p-3 rounded-lg w-full flex flex-col items-center'>
            <h1 className='w-full label-sm-strong'>
              Opportunities
            </h1>
            <p className='w-full label-sm'>
              Below are some opportunities for improving your message.
            </p>
            <ul className='w-full mt-4'>
              {messageQuality.cons.map((con) => (
                <MessageQualityListItem key={con.note} message={con.note} type='con' />
              ))}
            </ul>
          </div>
          )
        : null}
      {messageQuality.pros?.length > 0
        ? (
          <div className='bg-neutral-50 p-3 rounded-lg w-full flex flex-col items-center'>
            <h1 className='w-full label-sm-strong'>
              Highlights
            </h1>
            <p className='w-full label-sm'>
              Your message has some great things going for it.
            </p>
            <ul className='w-full mt-4'>
              {messageQuality.pros.map((pro) => (
                <MessageQualityListItem key={pro} message={pro} type='pro' />
              ))}
            </ul>
          </div>
          )
        : null}
    </div>
  )
}

MessageQualityAivaVariant.displayName = 'MessageQualityAivaVariant'
MessageQualityAivaVariant.propTypes = propTypes
MessageQualityAivaVariant.defaultProps = defaultProps

export default MessageQualityAivaVariant
