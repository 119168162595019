import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import messageTypes from '../../../helpers/messageTypes'
import useLogger from '../../../hooks/useLogger'
import isOnlyEmoji from '../../../lib/isOnlyEmoji'
import logger from '../../../lib/logger'
import Autolinker from '../../Autolinker'
import Markdown from '../../Markdown'

import MessageThreadItemKeyword from './MessageThreadItemKeyword'
import MessageThreadItemPotentialStop from './MessageThreadItemPotentialStop'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  type: PropType.oneOf(messageTypes).isRequired,
  archived: PropType.bool,
  isPotentialStop: PropType.bool,
  keywordAppliedByOtherDisplayText: PropType.string,
  keywordId: PropType.string,
  renderTextAsMarkdown: PropType.bool,
  text: PropType.string
}

const defaultProps = {
  archived: false,
  isPotentialStop: false,
  keywordAppliedByOtherDisplayText: null,
  keywordId: null,
  renderTextAsMarkdown: false,
  text: null
}

const classNamesByDirection = {
  in: [
    'self-start',
    'bg-neutral-100',
    'text-neutral-900'
  ],
  out: [
    'self-end',
    'bg-indigo-100',
    'text-indigo-900'
  ]
}

const log = logger({ enabled: true, tags: ['MessageThreadItemContentText'] })

const MessageThreadItemContentText = ({
  archived,
  direction,
  isPotentialStop,
  keywordAppliedByOtherDisplayText,
  keywordId,
  renderTextAsMarkdown,
  text,
  type
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const emojiOnlyText = isOnlyEmoji(text)
  const className = classNames(
    'flex flex-col max-w-full w-full mb-4',
    'whitespace-pre-wrap break-words p-3 rounded-lg',
    {
      'headline-md': emojiOnlyText,
      'paragraph-md': !emojiOnlyText,
      'opacity-60': archived
    },
    classNamesByDirection[direction]
  )

  if (!text || type === 'rvm' || type === 'email') { return null }

  return (
    <div className={className}>
      {renderTextAsMarkdown
        ? <Markdown content={text} />
        : <Autolinker tagName='div' text={text} />}
      <MessageThreadItemKeyword direction={direction} keywordAppliedByOtherDisplayText={keywordAppliedByOtherDisplayText} keywordId={keywordId} />
      {isPotentialStop
        ? <MessageThreadItemPotentialStop />
        : null}
    </div>
  )
}

MessageThreadItemContentText.displayName = 'MessageThreadItemContentText'
MessageThreadItemContentText.propTypes = propTypes
MessageThreadItemContentText.defaultProps = defaultProps

export default MessageThreadItemContentText
