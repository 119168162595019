import * as React from "react";
import { memo } from "react";
const SvgBroadcast = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Broadcast_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.608 19.72a8.979 8.979 0 0 1-1.972-1.498 8.994 8.994 0 0 1 0-12.728 8.994 8.994 0 0 1 12.728 0 8.994 8.994 0 0 1 0 12.728 8.978 8.978 0 0 1-1.972 1.498 1 1 0 0 0 .974 1.748c.867-.484 1.68-1.1 2.412-1.832a10.994 10.994 0 0 0 0-15.556 10.994 10.994 0 0 0-15.556 0 10.994 10.994 0 0 0 0 15.556 10.977 10.977 0 0 0 2.412 1.831 1 1 0 1 0 .974-1.747Zm1.837-3.144a5.325 5.325 0 0 1-1.238-.925 5.359 5.359 0 0 1 0-7.586 5.359 5.359 0 0 1 7.586 0 5.359 5.359 0 0 1 0 7.586c-.382.382-.799.69-1.238.925a1 1 0 1 0 .946 1.762 7.282 7.282 0 0 0 1.706-1.273 7.36 7.36 0 0 0 0-10.414 7.359 7.359 0 0 0-10.414 0 7.359 7.359 0 0 0 0 10.414c.511.51 1.09.943 1.706 1.273a1 1 0 0 0 .946-1.762Zm1.334-3.497a1.727 1.727 0 1 0 2.442-2.443 1.727 1.727 0 0 0-2.442 2.443ZM11 15.45a3.727 3.727 0 1 1 2 0V23a1 1 0 1 1-2 0v-7.55Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Broadcast_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBroadcast);
export default Memo;
