import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import StatusDetail from './StatusDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Status'] })

const Status = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='The Campaign Registry&trade;'
      />
      <PanelContent className='p-5'>
        <p className='mb-5'>
          The mobile carriers have mandated that all senders must be registered with The Campaign Registry&trade; (TCR) which now acts as a registration and reputation authority for businesses sending messages across all mobile carriers.
        </p>
        <StatusDetail />
      </PanelContent>
    </Panel>
  )
}

Status.displayName = 'Status'
Status.propTypes = propTypes
Status.defaultProps = defaultProps

export default Status
