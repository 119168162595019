import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import List from '../../components/list/List'
import ListItemContent from '../../components/users/ListItemContent'
import UsersSearchBar from '../../components/users/UsersSearchBar'
import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as userSearch } from '../../services/admin'

const userResultsFields = [
  '_id',
  'firstName',
  'lastName',
  'phoneNumber',
  'auth.username',
  'messageCredits',
  'isSinch',
  'isTwilio',
  'isV2',
  'registration',
  'tcr',
  'twilio',
  'subscriptionPlan'
]

const propTypes = {
  ListItemContent: PropType.elementType,
  placeholder: PropType.string,
  resultsFields: PropType.arrayOf(PropType.string),
  resultsKey: PropType.string,
  searchService: PropType.func
}

const defaultProps = {
  ListItemContent,
  placeholder: 'Users',
  resultsFields: userResultsFields,
  resultsKey: 'users',
  searchService: userSearch
}

const log = logger({ enabled: false, tags: ['userList'] })

const UserList = forwardRef(({
  ListItemContent,
  placeholder,
  resultsFields,
  resultsKey,
  searchService,
  ...listProps
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [listProps.type] })
  ref = useDefaultRef(ref)

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('userUpdated', handleBusEvent)
  useBus('userRemoved', handleBusEvent)
  useBus('userAdded', handleBusEvent)

  return (
    <List
      ref={ref}
      ListItemContent={ListItemContent}
      ListSearchBar={UsersSearchBar}
      multiple={false}
      placeholder={placeholder}
      resultsFields={resultsFields}
      resultsKey={resultsKey}
      searchService={searchService}
      type='nav'
      search
      {...listProps}
    />
  )
})

UserList.displayName = 'UserList'
UserList.propTypes = propTypes
UserList.defaultProps = defaultProps

export default UserList
