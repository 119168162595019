import * as React from "react";
import { memo } from "react";
const SvgFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 1a1 1 0 0 0-2 0v21a1 1 0 1 0 2 0v-9.412l1.923-1.068a3 3 0 0 1 3.047.078l3.88 2.425a5 5 0 0 0 5.3 0l2.35-1.469V2.382l-3.44 1.72a3 3 0 0 1-2.932-.139L11.22 1.521a5 5 0 0 0-5.424.08L5 2.13V1Zm0 3.535V10.3l.952-.529a5 5 0 0 1 5.078.131l3.88 2.425a3 3 0 0 0 3.18 0l1.41-.881V5.618l-.546.273a5 5 0 0 1-4.886-.232L10.16 3.217a3 3 0 0 0-3.254.048L5 4.535Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgFlag);
export default Memo;
