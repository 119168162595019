import { useMemo } from 'react'

import DetailSection from '../../components/detail-sections/DetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import StyledQrCodeContent from '../../components/qr-codes/StyledQrCodeContent'
import useCurrentUser from '../../hooks/useCurrentUser'

import CopyOptInLink from './CopyOptInLink'

const OptInFormInfo = () => {
  const currentUser = useCurrentUser()
  const slug = currentUser?.slug

  const optInUrl = useMemo(() => new URL(`/u/${slug}/subscribe`, process.env.REACT_APP_SHORT_LINK_URL).toString(), [slug])

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='Opt In Form'
      />
      <PanelContent className='p-5'>
        <div className='pb-4'>Project Broadcast provides a page for you to allow new contacts to explicitly subscribe to your messages.</div>
        <CopyOptInLink url={optInUrl} />
        <DetailSection description='Revolutionize engagement via QR Codes!' title='QR Code'>
          <StyledQrCodeContent size='sm' url={optInUrl} />
        </DetailSection>
      </PanelContent>
    </Panel>
  )
}

OptInFormInfo.displayName = 'OptInFormInfo'

export default OptInFormInfo
