import { get as getUserService } from '../services/admin'

import useCurrentInstance from './useCurrentInstance'

const useSelectedUser = () => useCurrentInstance({
  resourceName: 'user',
  getService: getUserService,
  replyAttrPath: 'model'
})

export default useSelectedUser
