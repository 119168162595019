import { get as getFolderService } from '../services/folder'

import useCurrentInstance from './useCurrentInstance'

const useCurrentFolder = () => useCurrentInstance({
  resourceName: 'folder',
  getService: getFolderService,
  replyAttrPath: 'json',
  notFoundRedirect: ''
})

export default useCurrentFolder
