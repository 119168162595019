import * as React from "react";
import { memo } from "react";
const SvgEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width={24} height={24} fill="#CFFBFE" rx={4} />
    <path
      fill="#0891B3"
      fillRule="evenodd"
      d="M5.889 7.833c0-.337.274-.61.611-.61h11c.337 0 .611.273.611.61v1.39l-5.347 2.468a1.834 1.834 0 0 1-1.534 0L5.888 9.227V7.833ZM4.667 9.631v6.758c0 1.012.82 1.833 1.833 1.833h11c1.012 0 1.833-.82 1.833-1.833V7.833C19.333 6.821 18.513 6 17.5 6h-11c-1.012 0-1.833.82-1.833 1.833v1.77a.6.6 0 0 0 0 .028Zm1.222.942 4.828 2.227c.81.375 1.75.374 2.56 0l4.834-2.23v5.819a.611.611 0 0 1-.611.61h-11a.611.611 0 0 1-.611-.61v-5.816Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgEmail);
export default Memo;
