import PropType from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import { ImportContext } from './ImportContext'
import validationSuite from './MapFields.validations'

const propTypes = {
  preImportInfo: PropType.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fieldMapGuesses: PropType.object,
    fieldMapOptions: PropType.arrayOf(PropType.shape({
      label: PropType.string,
      value: PropType.string
    }))
  }).isRequired,
  onMappingsSelected: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MapFields'] })

const MapFields = ({ preImportInfo, onMappingsSelected }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const { fieldMapGuesses, fieldMapOptions } = preImportInfo
  const { title, subtitle, handleBackButtonClick } = useContext(ImportContext)

  const handleSubmit = useCallback((data) => {
    onMappingsSelected(data)
  }, [onMappingsSelected])

  const formControls = useMemo(() => {
    const controls = [
      {
        type: 'passthrough',
        name: 'passthrough1',
        element: (
          <p className='mb-3'>
            Before importing, setup the mapping between Project Broadcast contact fields and
            the csv file column names using the drop downs above.
          </p>
        )
      },
      {
        hint: 'Which of the following would you like to do?',
        hintTop: true,
        type: 'radiogroup',
        name: 'importType',
        defaultValue: 'newAndExistingUsers',
        options: [
          { label: 'Import New Contacts & Update Existing Contacts', value: 'newAndExistingUsers' },
          { label: 'Import New Contacts Only', value: 'newUsersOnly' }
        ]
      },
      {
        type: 'passthrough',
        name: 'passthrough2',
        element: (
          <div className='headline-2xs p-1 bg-neutral-100'>
            Required Mapping
          </div>
        )
      },
      {
        label: 'Phone Number',
        hint: `The Phone Number is the only required field when doing an import.
               Select the header title above that represents the Phone Number field in your import.`,
        name: 'phoneNumber',
        type: 'select',
        options: fieldMapOptions
      },
      {
        type: 'passthrough',
        name: 'passthrough3',
        element: (
          <div className='headline-2xs p-1 bg-neutral-100'>
            Optional Mapping
          </div>
        )
      },
      {
        label: 'Secondary Phone Number',
        hint: `The Secondary Phone Number is used as a fallback on importing in the case where a record
               does not have a Phone Number. This is useful if you have two phone numbers in your file
               such as "cell" and "home" and you would like to use "home" if "cell" is blank.`,
        name: 'secondaryPhoneNumber',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Email',
        hint: 'Emails should be unique.',
        name: 'email',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Tags',
        hint: `If your import file already contains a tags column, select the header title above
               that represents the Tags column.  This will cause every contact to be tagged with the
               value of this column. Tags may only contain letters, numbers, spaces, dashes, and underscores.
               If your tags column contains multiple tags, ensure they are separated by a comma in the column.`,
        name: 'tags',
        type: 'select',
        options: fieldMapOptions
      },
      {
        autoCapitalize: 'none',
        label: 'Additional Tags',
        hint: `Enter additional comma separated tags above that you want associated with all the
               contacts in your import. For example, if you type "VIP" (no quotes) above, all contacts
               in your import file will be tagged with VIP. If you would like to add two tags such
               as VIP and Customer you would type "VIP, Customer" (no quotes) above. Tags may only
               contain letters, numbers, spaces, dashes, and underscores.`,
        name: 'additionalTags',
        type: 'text'
      },
      {
        label: 'First Name',
        hint: `If your import file has the first and last name in the same column, select that header
              title for both First Name/Last Name sections and we will do our best to clean up the
              data on import. For example, if your file has a Name column and a contact's name is
              "DOE, JANE" select Name for the First/Last Name sections and we will do our best to fix
              that into first name Jane and last name Doe.`,
        name: 'firstName',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Last Name',
        hint: `If your import file has the first and last name in the same column, select that header
               title for both First Name/Last Name sections and we will do our best to clean up the
               data on import. For example, if your file has a Name column and a contact's name is
               "DOE, JANE" select Name for the First/Last Name sections and we will do our best to fix
               that into first name Jane and last name Doe.`,
        name: 'lastName',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Company',
        name: 'company',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Birthday',
        hint: `The value for birthday must contain a valid month and day.  If you include a year it will just be
               ignored.  We suggest using a format like m/d or m-d to ensure a contact's birthday is correctly imported.
               However the system will do its best to parse the value you provide.  Some valid examples are 12/8, 5-3,
               Dec 7, 8/21/1944, 12-31-1999, October 10 2010.`,
        name: 'birthday',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Birth Month (1-12)',
        hint: "A number between 1 and 12 representing the month of the contact's birthday.",
        name: 'birthdayMonth',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Birth Day (1-31)',
        hint: "A number between 1 and 31 representing the day of the contact's birthday.",
        name: 'birthdayDay',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Anniversary',
        hint: `The value for anniversary must contain a valid month and day.  If you include a year it will just be
               ignored.  We suggest using a format like m/d or m-d to ensure a contact's anniversary is correctly imported.
               However the system will do its best to parse the value you provide.  Some valid examples are 12/8, 5-3,
               Dec 7, 8/21/1944, 12-31-1999, October 10 2010.`,
        name: 'anniversary',
        type: 'select',
        options: fieldMapOptions
      },
      {
        label: 'Notes',
        hint: `If your import file contains notes for your contacts, select the header title above that corresponds to the
               Notes column.  When importing notes for existing contacts the imported notes column will be <b>appended</b>
               to any existing notes for that contact.`,
        name: 'notes',
        type: 'select',
        options: fieldMapOptions
      },
      {
        type: 'passthrough',
        name: 'passthrough4',
        element: (
          <>
            <div className='headline-2xs p-1 bg-neutral-100'>
              Custom Field Mapping
            </div>
            <p className='my-3'>
              Custom fields are an advanced feature of Project Broadcast. Custom fields are a way for
              you to store specific information for each contact.
            </p>
            <p className='my-3'>
              If you are already using custom fields in Project Broadcast, make sure to use the same
              header titles on each import.
            </p>
            <p className='my-3'>
              If your custom fields below still say "Custom 1", "Custom 2", etc you need to first edit
              the labels for the custom fields in the More tab of Project Broadcast.
            </p>
          </>
        )
      }
    ]

    currentUser.customFields.forEach((customField) => {
      controls.push({
        label: customField.name,
        name: customField.path,
        type: 'select',
        options: fieldMapOptions
      })
    })
    return controls
  }, [currentUser, fieldMapOptions])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='mappingForm' title='Import' type='submit' />}
        start={<PanelHeaderButton icon='back' onClick={handleBackButtonClick} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent className='p-4'>
        <Form
          defaultValues={fieldMapGuesses}
          formControls={formControls}
          id='mappingForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='mappingForm'
            size='sm'
            type='submit'
          >
            Import
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

MapFields.displayName = 'MapFields'
MapFields.propTypes = propTypes
MapFields.defaultProps = defaultProps

export default MapFields
