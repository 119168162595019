import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { pluralize } from '../../../lib/formatters'
import contact from '../../../prop-types/shapes/contact'
import { bulkUpdate as bulkUpdateService } from '../../../services/contact'
import { success, warning } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  contacts: PropType.arrayOf(PropType.shape(contact)).isRequired
}

const BulkMarkAsDoNotMessageAction = forwardRef(({ contacts }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    const pluralizedCount = pluralize({ count: contacts.length, singular: 'contact', includeCount: true })
    success(`${pluralizedCount} marked Do Not Message`)
    bus.emit('contactOptOutRelatedInfoUpdated')
    bus.emit('closeBulkEditDialog')
  }, [contacts])
  const { call: bulkUpdate } = useService(bulkUpdateService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!contacts || !contacts.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [contacts])

  const handleConfirm = useCallback(() => {
    bulkUpdate(contacts.map(({ _id }) => _id), { doNotMessage: true })
  }, [bulkUpdate, contacts])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description={
        <>
          Would you like to proceed with marking
          {' '}
          {contacts.length === 1
            ? 'this contact'
            : `these ${contacts.length} contacts`}
          {' '}
          as
          {' '}
          <span className='font-bold whitespace-nowrap'>Do Not Message</span>
          {/**/}
          ?
        </>
      }
      title='Are you sure?'
      onConfirm={handleConfirm}
    />
  )
})

BulkMarkAsDoNotMessageAction.displayName = 'BulkMarkAsDoNotMessageAction'
BulkMarkAsDoNotMessageAction.propTypes = propTypes

export default BulkMarkAsDoNotMessageAction
