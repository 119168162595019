import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'
import { clone as cloneService } from '../../../services/campaign'
import { success } from '../../banners/Banner'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CloneAction'] })

const CloneAction = forwardRef(({ campaign, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaign?._id] })

  const handleReplyOk = useCallback(() => {
    success(`${campaign.name} was cloned`)
    bus.emit('campaignCloned', campaign)
    onSuccess?.()
  }, [campaign, onSuccess])
  const { call: cloneCampaignCall } = useService(cloneService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { cloneCampaignCall(campaign._id) }
  }), [campaign, cloneCampaignCall])
})

CloneAction.displayName = 'CloneAction'
CloneAction.propTypes = propTypes
CloneAction.defaultProps = defaultProps

export default CloneAction
