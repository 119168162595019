import * as React from "react";
import { memo } from "react";
const SvgHelp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.671 4.257A9.959 9.959 0 0 1 12 2c2.401 0 4.605.846 6.329 2.257L15.44 7.146a5.946 5.946 0 0 0-6.88 0L5.67 4.256Zm-2.1-.798a1.01 1.01 0 0 0-.112.112A11.962 11.962 0 0 0 0 12c0 3.285 1.32 6.262 3.459 8.43a1 1 0 0 0 .112.11A11.961 11.961 0 0 0 12 24c3.286 0 6.264-1.32 8.43-3.46a.992.992 0 0 0 .11-.11A11.962 11.962 0 0 0 24 12c0-3.286-1.32-6.264-3.46-8.43a1.017 1.017 0 0 0-.11-.11A11.962 11.962 0 0 0 12 0C8.715 0 5.737 1.32 3.57 3.46Zm16.172 2.212L16.855 8.56a5.946 5.946 0 0 1 0 6.882l2.888 2.888A9.96 9.96 0 0 0 22 12a9.96 9.96 0 0 0-2.257-6.329Zm-1.414 14.072-2.887-2.887a5.946 5.946 0 0 1-6.884 0l-2.887 2.887A9.958 9.958 0 0 0 12 22a9.959 9.959 0 0 0 6.329-2.257ZM4.257 18.329l2.887-2.887a5.946 5.946 0 0 1 .001-6.882L4.257 5.67A9.959 9.959 0 0 0 2 12c0 2.401.846 4.605 2.257 6.329Zm6.231-9.977a3.95 3.95 0 1 0 3.023 7.298 3.95 3.95 0 0 0-3.023-7.298Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgHelp);
export default Memo;
