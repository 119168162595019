import PropType from 'prop-types'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import messageQualityShape from '../../../../prop-types/shapes/messageQuality'
import MessageQualityAivaVariant from '../../../message/message-quality/MessageQualityAivaVariant'
import PanelHeaderButton from '../../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../panels/PanelContent'
import PanelHeader from '../../../panels/PanelHeader'

import TabSelector from './TabSelector'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  onClose: PropType.func.isRequired,
  onSelectTab: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageStrengthTab'] })

export const MessageStrengthTab = ({ messageQuality, onClose, onSelectTab }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <PanelHeader
        start={<PanelHeaderButton title='Cancel' onClick={onClose} />}
        title='AIVA'
      />
      <TabSelector currentTab='message-strength' messageQuality={messageQuality} onSelectTab={onSelectTab} />
      <PanelContent className='p-5'>
        <MessageQualityAivaVariant messageQuality={messageQuality} />
      </PanelContent>
    </>
  )
}

MessageStrengthTab.displayName = 'MessageStrengthTab'
MessageStrengthTab.propTypes = propTypes
MessageStrengthTab.defaultProps = defaultProps

export default MessageStrengthTab
