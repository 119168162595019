import * as React from "react";
import { memo } from "react";
const SvgSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 10.25a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0ZM10.25 1a9.25 9.25 0 0 0 0 18.5 9.21 9.21 0 0 0 5.597-1.885l5.52 5.519a1.25 1.25 0 0 0 1.767-1.768l-5.519-5.519A9.21 9.21 0 0 0 19.5 10.25 9.25 9.25 0 0 0 10.25 1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSearch);
export default Memo;
