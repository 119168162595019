import React from 'react'

import SpaceMembers from '../screens/spaces/SpaceMembers'
import SpacesScheduledThread from '../screens/spaces/SpacesScheduledThread'
import SpaceThread from '../screens/spaces/SpacesThread'

const routes = [
  {
    path: ':spaceId',
    element: <SpaceThread />
  },
  {
    path: ':spaceId/members',
    element: <SpaceMembers />
  },
  {
    path: ':spaceId/scheduled',
    element: <SpacesScheduledThread />
  }
]

export default routes
