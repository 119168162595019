import { differenceInMinutes } from 'date-fns'
import { isDate } from 'lodash'

import logger from '../../../lib/logger'

const log = logger({ enabled: true, tags: ['ThreadList2.reducer'] })

export const initialPagingState = {
  page: 0, // current page
  pages: 1, // number of pages
  total: 0 // total pages
}

export default function reducer (state, action) {
  const handler = actions[action?.type]
  if (!handler) { throw new Error(`Unknown action: ${action?.type}`) }
  return handler(state, action)
}

const actions = {
  newMessages (state, action) {
    const newData = action.messages || []
    if (state.reverseIncomingData) { newData.reverse() }
    const data = [...newData, ...state.data]
    const dataWithDates = injectDatesIntoData(data)
    const scheduledMessagesCount = action.scheduledMessagesCount
    const lightBoxSlides = generateLightBoxSlides(dataWithDates)
    return { ...state, data, dataWithDates, scheduledMessagesCount, lastItem: data[0], lightBoxSlides }
  },

  newPageSize (state, action) {
    return { ...state, pageSize: action.pageSize }
  },

  newQueryOverrides (state, action) {
    return { ...state, queryOverrides: action.queryOverrides }
  },

  nextPage (state/*, action */) {
    if (state.nextPaging) { return state }
    const { page: currentPage, pages: totalPages } = state.paging
    const nextPage = currentPage + 1
    if (nextPage > totalPages) { return state }
    const nextPaging = { nextPage, nextPageSize: state.pageSize, nextQueryOverrides: state.queryOverrides }
    return { ...state, nextPaging }
  },

  reload (state/*, action */) {
    const nextPaging = { nextPage: 1, nextPageSize: state.pageSize, nextQueryOverrides: state.queryOverrides }
    return { ...state, data: [], paging: { ...initialPagingState }, scheduledMessagesCount: 0, lastItemId: null, nextPaging, hasMore: false }
  },

  removeMessage (state, action) {
    const index = state.data.findIndex((message) => message._id === action.message._id)
    const data = [...state.data]
    if (index > -1) { data.splice(index, 1) }
    const dataWithDates = injectDatesIntoData(data)
    const lightBoxSlides = generateLightBoxSlides(dataWithDates)
    return { ...state, data, dataWithDates, lastItem: data[0], lightBoxSlides }
  },

  reply (state, action) {
    if (!action.reply?.ok || !action.reply?.json?.[action.resultsKey]) { return state }
    const newData = (action.reply?.models || action.reply.json[action.resultsKey])
    if (state.reverseIncomingData) { newData.reverse() }
    const data = [...state.data, ...newData]
    const dataWithDates = injectDatesIntoData(data)
    const paging = action.reply.json.paging || { total: data.length, pageSize: data.length, page: 1, pages: 1 }
    const hasMore = paging.page < paging.pages
    const scheduledMessagesCount = action.reply.json.scheduledMessagesCount || 0
    const lightBoxSlides = generateLightBoxSlides(dataWithDates)
    return { ...state, data, dataWithDates, paging, scheduledMessagesCount, lastItem: data[0], nextPaging: false, lightBoxSlides, hasMore }
  },

  updateMessage (state, action) {
    const index = state.data.findIndex((message) => message._id === action.message._id)
    const data = [...state.data]
    if (index > -1) { data.splice(index, 1, action.message) }
    const dataWithDates = injectDatesIntoData(data)
    const lightBoxSlides = generateLightBoxSlides(dataWithDates)
    return { ...state, data, dataWithDates, lastItem: data[0], lightBoxSlides }
  }
}

function nextDateFromMessage (message) {
  return new Date(message.state === 'queued' ? message.runAt : message.time)
}

function injectDatesIntoData (messages) {
  const timeDisplayGapInMinutes = 1
  const start = Date.now()
  let prevDate = null
  let prevPrevDate = null
  let prevDateIndex = null

  const results = messages.reduce((result, message) => {
    const nextDate = nextDateFromMessage(message)

    if (!prevDate) { prevDate = nextDate }

    const minuteDifference = differenceInMinutes(prevDate, nextDate)

    if (minuteDifference >= timeDisplayGapInMinutes) {
      result.push(prevDate)
      prevPrevDate = prevDate
      prevDate = nextDate
      prevDateIndex = result.length - 1
    }

    result.push(message)

    return result
  }, [])

  // always end with a time label
  if (results.length && !isDate(results.at(-1))) {
    const nextDate = nextDateFromMessage(results.at(-1))
    if (prevDateIndex > -1) {
      const minuteDifference = differenceInMinutes(prevPrevDate, nextDate)
      if (minuteDifference <= timeDisplayGapInMinutes) {
        results.splice(prevDateIndex, 1)
      }
    }
    results.push(nextDate)
  }

  const end = Date.now()
  log.debug(`injected dates into data... took ${end - start}ms`)

  return results
}

function generateLightBoxSlides (dataWithDates) {
  return (dataWithDates || []).reduce((slides, message, index) => {
    if (message.type !== 'mms') { return slides }
    const src = message.mediaUrl
    if (!src) { return slides }
    slides.push({
      src,
      messageId: message._id,
      messageIndex: index
    })
    return slides
  }, []).reverse()
}
