import goalFromJSON from '../../models/goal'
import pb from '../pb'

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: goalFromJSON,
      key: 'goals',
      multiple: true
    }
  }
  return pb.setupPost('admin/goals', options)
}

export function get (id, background = false) {
  const options = {
    background,
    model: {
      conversion: goalFromJSON,
      key: 'goal'
    }
  }
  return pb.setupGet(`admin/goals/${id}`, options)
}

export function exportCsv (id, background = false) {
  return pb.setupPost(`admin/goals/${id}/export`, [background])
}
