import { useCallback } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { exportPaymentHistory } from '../../../services/admin'
import { Email } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ExportPaymentHistory'] })

const ExportPaymentHistory = () => {
  const { userId, loading, user } = useSelectedUser()
  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback(() => {
    notify('Queued an export of the payment history')
  }, [])
  const { call: exportPayment } = useService(exportPaymentHistory, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback(() => {
    exportPayment(user._id)
  }, [user, exportPayment])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            icon={<Email />}
            onClick={handleSubmit}
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Export Payment History'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            size='sm'
            onClick={handleSubmit}
          >
            SEND PAYMENT HISTORY TO THIS USER
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ExportPaymentHistory.displayName = 'ExportPaymentHistory'
ExportPaymentHistory.propTypes = propTypes
ExportPaymentHistory.defaultProps = defaultProps

export default ExportPaymentHistory
