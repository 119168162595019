import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { campaignTypeOptions } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createCampaignService } from '../../../services/campaign'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormPanelDialog from '../../dialog/FormPanelDialog'
import ExternalLink from '../../links/ExternalLink'

import validationSuite from './CreateAction.validations'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: null
}

const log = logger({ enabled: true, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()

  const handleReply = useCallback((reply) => {
    const newCampaign = reply.model
    setCurrentInstance('campaign', newCampaign)
    bus.emit('campaignCreated', newCampaign)
    if (!createdFromFolder) { navigate(`/campaigns/${newCampaign._id}`) }
    onSuccess?.(newCampaign)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createCampaign } = useService(createCampaignService, { onReplyOk: handleReply })

  const collectCampaignNameDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { collectCampaignNameDialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((formData) => {
    collectCampaignNameDialogRef.current.close()
    createCampaign(formData)
  }, [createCampaign])

  return (
    <FormPanelDialog
      ref={collectCampaignNameDialogRef}
      defaultValues={{ name: '' }}
      description='Please name your new campaign'
      formControls={[
        {
          autoComplete: 'off',
          type: 'text',
          name: 'name',
          placeholder: 'Campaign Name'
        },
        {
          type: 'radiogroup',
          name: 'type',
          label: 'Campaign Type',
          options: campaignTypeOptions.map(({ label, value, description }) => ({ label, value, description }))
        },
        {
          type: 'passthrough',
          name: 'passthrough',
          element: (
            <p className='label-xs neutral-400 -mt-3'>
              <ExternalLink
                className='link'
                href='https://training.projectbroadcast.com/courses/campaigns'
                label='Learn more'
              />
              {' '}
              about campaign types and how they are used.
            </p>
          )
        }
      ]}
      title='New Campaign'
      validationSuite={validationSuite}
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
