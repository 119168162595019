import classNames from 'classnames'
import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Tag } from '../../../svg/icons'
import Pill from '../../pill/Pill'

const propTypes = {
  tags: PropType.arrayOf(PropType.string).isRequired,
  className: PropType.string
}

const defaultProps = {
  className: null
}

const log = logger({ enabled: false, tags: ['TagSummaryLine'] })

const TagSummaryLine = ({ className, tags }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!tags?.length) { return null }

  className = classNames('flex flex-row flex-nowrap flex-initial items-center', className)

  return (
    <div className={className}>
      <Tag className='shrink-0' height='16' width='16' />
      <Pill className='mx-1' value={tags.length} />
      <span className='label-xs text-neutral-500 line-clamp-1'>{tags.join(', ')}</span>
    </div>
  )
}

TagSummaryLine.displayName = 'TagSummaryLine'
TagSummaryLine.propTypes = propTypes
TagSummaryLine.defaultProps = defaultProps

export default TagSummaryLine
