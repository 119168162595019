import { useCallback, useRef, useState } from 'react'

import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import UnlayerTemplateList from '../../../components/unlayer-templates/UnlayerTemplateList'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import ListHeaderMoreMenu from './list/ListHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UnlayerTemplatesList'] })

const UnlayerTemplatesList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<ListHeaderMoreMenu listRef={listRef} />}
        title='Unlayer Templates'
      />
      <PanelContent>
        <UnlayerTemplateList
          ref={listRef}
          savedStateKey='adminUnlayerTemplates'
          type='nav'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

UnlayerTemplatesList.displayName = 'UnlayerTemplatesList'
UnlayerTemplatesList.propTypes = propTypes
UnlayerTemplatesList.defaultProps = defaultProps

export default UnlayerTemplatesList
