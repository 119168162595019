import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import spaceMember from '../../prop-types/shapes/spaceMember'

const propTypes = {
  item: PropType.shape(spaceMember)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['SpaceMemberListItemContent'] })

const SpaceMemberListItemContent = ({ item: spaceMember }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col ml-[12px] py-[4px]'>
      <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
        {spaceMember.formattedName}
      </div>
      <div className='label-xs text-neutral-500 mt-[3px]'>{spaceMember.formattedPhoneNumber}</div>
    </div>
  )
}

SpaceMemberListItemContent.displayName = 'SpaceMemberListItemContent'
SpaceMemberListItemContent.propTypes = propTypes
SpaceMemberListItemContent.defaultProps = defaultProps

export default SpaceMemberListItemContent
