import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWizard } from 'react-use-wizard'

import { error } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import PanelLoading from '../../../components/panels/PanelLoading'
import SubscriptionSummary from '../../../components/plans/SubscriptionSummary'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { centsToDollars, formatPhone } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { defaultPlanId, optionsData, requiresVerifiedMobile } from '../../../lib/plans'
import { reactivatePreFlight as reactivatePreFlightService } from '../../../services/subscription'
import { plans as plansService } from '../../../services/user'
import Header from '../shared/Header'
import PartnerPromo from '../signup/PartnerPromo'
import SecureCheckout from '../signup/SecureCheckout'

import validationSuite from './SubscriptionForm.validations'

const propTypes = {
  onSuccess: PropType.func.isRequired,
  affiliate: PropType.shape({
    name: PropType.string.isRequired
  }),
  existingPhoneNumber: PropType.string,
  integration: PropType.shape({
    defaultPlan: PropType.string.isRequired,
    description: PropType.string.isRequired,
    key: PropType.string.isRequired,
    logo: PropType.string.isRequired,
    name: PropType.string.isRequired,
    newUserAccountBalanceCreditCents: PropType.number.isRequired,
    request: PropType.string.isRequired,
    terms: PropType.string.isRequired,
    url: PropType.string.isRequired
  }),
  signInUrl: PropType.string
}

const defaultProps = {
  affiliate: null,
  existingPhoneNumber: null,
  integration: null,
  signInUrl: '/auth'
}

const formSettings = {
  id: 'signUpForm',
  validationSuite
}

function creditMessage (integration) {
  if (integration?.newUserAccountBalanceCreditCents === 0) {
    return null
  }

  return (
    <p>
      As a bonus, new sign-ups connected to
      {' '}
      <strong>{integration.name}</strong>
      {' '}
      are receiving a
      {' '}
      <strong>
        $
        {centsToDollars(integration.newUserAccountBalanceCreditCents)}
      </strong>
      {' '}
      account credit!
    </p>
  )
}

const log = logger({ enabled: false, tags: ['ReactivateSubscriptionForm'] })

const SubscriptionForm = ({ onSuccess, integration, signInUrl, existingPhoneNumber, affiliate, ...data }) => {
  useLogger({ log, lifecycle: true, tags: [] })

  const navigate = useNavigate()
  const [formData, setFormData] = useState({})
  const [plans, setPlans] = useState([])
  const { goToStep } = useWizard()
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId)
  const partnerId = integration?.key

  const handlePreFlightReplyOk = useCallback((reply) => {
    if (reply.json.verificationCodeSent === true) {
      onSuccess(formData)
      goToStep(1)
    } else if (reply.json.previousPhoneNumberAvailable === true) {
      onSuccess({ ...formData, selectedPlan })
      goToStep(2)
    } else {
      const respNumbers = reply.json.numbers
      const numbers = {}
      let numbersAvailable = false

      Object.keys(respNumbers).forEach((key) => {
        if (respNumbers[key].length > 0) {
          numbers[key] = respNumbers[key]
          numbersAvailable = true
        }
      })

      if (numbersAvailable) {
        onSuccess({ ...formData, numbers, selectedPlan })
        goToStep(2)
      } else {
        error('Oh no!', 'It seems we have no numbers available in that state/province. Please select another.')
      }
    }
  }, [formData, goToStep, onSuccess, selectedPlan])
  const { call: reactivatePreFlight } = useService(reactivatePreFlightService, { onReplyOk: handlePreFlightReplyOk })

  const handlePlansDataReplyOk = useCallback((reply) => {
    const plans = reply.json?.plans
    const currentPlan = reply.json?.currentPlan
    const verifiedPlanId = (
      plans.find((plan) => plan.id === data.planId) ||
      plans.find((plan) => plan.id === currentPlan?.id) ||
      plans.find((plan) => plan.id === integration?.defaultPlan) ||
      plans.find((plan) => plan.id === defaultPlanId)
    )?.id
    setPlans(plans)
    setSelectedPlanId(verifiedPlanId)
  }, [data.planId, integration?.defaultPlan])
  const { call: plansData } = useService(plansService, { onReplyOk: handlePlansDataReplyOk })

  useEffect(() => {
    plansData({ partnerId }, { background: false })
  }, [partnerId, plansData])

  const handlePlanChange = useCallback((event) => {
    log.debug('handlePlanChange', event.target.value)
    setSelectedPlanId(event.target.value)
  }, [])

  formSettings.defaultValues = {
    ...data,
    planId: selectedPlanId
  }

  formSettings.onSubmit = (data) => {
    data.affiliate = affiliate
    reactivatePreFlight(data)
    setFormData(data)
  }

  const formControls = [{
    autoFocus: true,
    type: 'select',
    name: 'planId',
    label: 'Subscription Plan',
    required: true,
    onChange: handlePlanChange,
    options: optionsData(plans),
    placeholder: 'Choose a subscription plan'
  },
  partnerId && integration?.newUserAccountBalanceCreditCents > 0
    ? {
        type: 'passthrough',
        element: creditMessage(integration),
        name: 'creditMessage'
      }
    : null,
  existingPhoneNumber
    ? {
        type: 'hidden',
        name: 'existingPhoneNumber',
        value: existingPhoneNumber
      }
    : null,
  requiresVerifiedMobile(selectedPlan)
    ? {
        autoComplete: 'phone-number',
        type: 'tel',
        name: 'cellPhoneNumber',
        label: 'Phone Number',
        hint: 'A verification code will be sent via text message to this number. This code will be required to complete signup in the next step.',
        placeholder: 'Enter your mobile number',
        required: true
      }
    : existingPhoneNumber
      ? {
          type: 'passthrough',
          element: (
            <p>
              {'Your previous Project Broadcast phone number is available for reactivation: '}
              <strong className='whitespace-nowrap'>
                {formatPhone(existingPhoneNumber)}
              </strong>
            </p>
          ),
          name: 'existingPhoneNumberNotification'
        }
      : {
          type: 'stateprovince',
          name: 'stateProvince',
          label: 'State/Province',
          hint: 'The State/Province is used to locate you a Project Broadcast number. If the State/Province you provide does not return any available numbers, please use a different State/Province.',
          placeholder: 'Choose State/Province'
        },
  integration
    ? {
        type: 'checkbox',
        name: 'partnerTerms',
        labelAlignItems: 'items-start',
        label: integration.terms,
        labelAlignment: 'right',
        required: true,
        dangerouslySetInnerHTML: true
      }
    : null,
  integration
    ? {
        type: 'hidden',
        name: 'partnerId',
        value: partnerId
      }
    : null,
  {
    type: 'checkbox',
    name: 'euDisclaimer',
    label: 'Select here if you are a member of the European Union.',
    labelAlignment: 'right'
  }]
  formSettings.formControls = formControls.filter((o) => o)

  // form defaultValues are cached after first render,
  // so block Form rendering until we have the data we need.
  if (!selectedPlanId || !plans.length) {
    return <PanelLoading noHeader />
  }

  return (
    <>
      <Header
        goBack={partnerId ? null : () => navigate(signInUrl)}
        heading='Reactivate Account'
        title='Account Details'
      />
      {partnerId && integration ? <PartnerPromo integration={integration} signInUrl={signInUrl} /> : null}
      <Form {...formSettings} />
      <div className='paragraph-sm mb-4'>
        <SubscriptionSummary plan={selectedPlan} />
      </div>
      <Button
        className='mb-4 w-full'
        form='signUpForm'
        size='md'
        type='submit'
      >
        Continue
      </Button>
      <SecureCheckout />
    </>
  )
}

SubscriptionForm.displayName = 'ReactivateSubscriptionForm'
SubscriptionForm.propTypes = propTypes
SubscriptionForm.defaultProps = defaultProps

export default SubscriptionForm
