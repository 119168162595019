import pb from './pb'

export function history (background = false) {
  return pb.setupGet('payment', { background })
}

export function exportHistory (background = false) {
  return pb.setupPost('payment/export', { background })
}

export function update (data) {
  return pb.setupPut('payment', { body: data })
}
