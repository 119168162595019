import * as React from "react";
import { memo } from "react";
const SvgWorld = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.41 1.31s-.03-.01-.04-.02A11.85 11.85 0 0 0 12 0C5.38 0 0 5.38 0 12c0 4.67 2.69 8.71 6.59 10.7.01 0 .03.01.04.02 1.62.82 3.44 1.29 5.37 1.29 6.62 0 12-5.38 12-12 0-4.67-2.68-8.71-6.59-10.69v-.01ZM2 12c0-.34.02-.67.05-1H5c.55 0 1 .45 1 1v7.98C3.58 18.15 2 15.26 2 12Zm6 9.16V12c0-1.65-1.35-3-3-3H2.46C3.74 4.95 7.53 2 12 2c1.42 0 2.77.3 4 .84v3.54c0 .44-.5.75-.89.56l-1.32-.66c-.36-.18-.76-.28-1.17-.28C11.18 6 10 7.18 10 8.62v1.76c0 1.44 1.18 2.62 2.62 2.62h2.72c.92 0 1.67.75 1.67 1.67 0 .52-.25 1.02-.67 1.33-1.89 1.41-3.08 3.6-3.29 5.95-.35.04-.7.06-1.05.06-1.42 0-2.77-.3-4-.84v-.01Zm7.13.33c.29-1.54 1.14-2.95 2.41-3.89.92-.69 1.47-1.78 1.47-2.93 0-2.02-1.65-3.67-3.67-3.67h-2.72c-.34 0-.62-.28-.62-.62V8.62c0-.43.5-.75.89-.56l1.32.66c.36.18.76.28 1.17.28C16.82 9 18 7.82 18 6.38V4.02c2.42 1.83 4 4.72 4 7.98 0 4.42-2.89 8.17-6.87 9.49Z"
    />
  </svg>
);
const Memo = memo(SvgWorld);
export default Memo;
