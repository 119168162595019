import PropType from 'prop-types'
import { createContext, useCallback, useContext, useDebugValue, useMemo, useReducer } from 'react'

import { useMinBreakpoint } from '../hooks/useBreakpoint'
import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { setPreference } from '../lib/preferences'

import { useApp } from './AppContext'

export const SideMenuContext = createContext() // intentially left empty to trap nesting issues in useSideMenu
SideMenuContext.displayName = 'SideMenuContext'

SideMenuProvider.propTypes = { children: PropType.node.isRequired }

function reducer (state, action) {
  switch (action.type) {
    case 'expand':
      return { ...state, expanded: true }
    case 'lock':
      setPreference('sideMenuLocked', true)
      return { ...state, expanded: true, prefersLocked: true }
    case 'unlock':
      setPreference('sideMenuLocked', false)
      return { ...state, prefersLocked: false, expanded: false, userMenuVisible: false }
    case 'collapse':
      return { ...state, expanded: false, userMenuVisible: false }
    case 'showUserMenu':
      return { ...state, expanded: true, userMenuVisible: true }
    case 'hideUserMenu':
      return { ...state, userMenuVisible: false }
  }
}

const log = logger({ enabled: true, tags: ['SideMenuProvider'] })

export function SideMenuProvider ({ children }) {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isNative, prefersSideMenuLocked } = useApp()
  const minLgBreakpoint = useMinBreakpoint('lg')
  const [state, dispatch] = useReducer(reducer, {
    expanded: false,
    prefersLocked: prefersSideMenuLocked,
    userMenuVisible: false
  })

  const setUserMenuVisible = useCallback((visible) => dispatch({ type: visible ? 'showUserMenu' : 'hideUserMenu' }), [])
  const setExpanded = useCallback((expand) => dispatch({ type: expand ? 'expand' : 'collapse' }), [])
  const setLocked = useCallback((lock) => dispatch({ type: lock ? 'lock' : 'unlock' }), [])
  const toggleExpanded = useCallback(() => setExpanded(!state.expanded), [setExpanded, state.expanded])
  const toggleLocked = useCallback(() => setLocked(!state.prefersLocked), [setLocked, state.prefersLocked])

  const value = useMemo(() => ({
    expanded: (!isNative && minLgBreakpoint && state.prefersLocked) || state.expanded,
    locked: !isNative && minLgBreakpoint && state.prefersLocked,
    userMenuVisible: state.userMenuVisible,
    minLgBreakpoint,
    setExpanded,
    setLocked,
    setUserMenuVisible,
    toggleExpanded,
    toggleLocked
  }), [isNative, minLgBreakpoint, state.prefersLocked, state.expanded, state.userMenuVisible, setExpanded, setLocked, setUserMenuVisible, toggleExpanded, toggleLocked])

  return (
    <SideMenuContext.Provider value={value}>{children}</SideMenuContext.Provider>
  )
}

export function useSideMenu () {
  const context = useContext(SideMenuContext)
  if (context === undefined) {
    throw new Error('useSideMenu must be used within a SideMenuProvider')
  }
  useDebugValue(`expanded: ${context.expanded}`)
  useDebugValue(`locked: ${context.locked}`)
  return context
}
