import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import { useBreakpointValue } from '../../hooks/useBreakpoint'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import breakpoints from '../../tailwindcss/screens'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Breakpoints'] })

const Breakpoints = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentBreakpoint = useBreakpointValue()

  const breakpointEntries = Object.entries(breakpoints)
  breakpointEntries.unshift(['none', '0px'])
  log.debug(currentBreakpoint)
  const output = breakpointEntries.map(([breakpoint, pixels]) => {
    return (
      <div key={breakpoint} className='m-2'>
        {breakpoint}
        {': '}
        {pixels}
        {(breakpoint === currentBreakpoint) || (breakpoint === 'none' && currentBreakpoint === null) ? ' (active)' : null}
      </div>
    )
  })

  return (
    <Panel>
      <PanelHeader title='Breakpoints' />
      <PanelContent className='p-2'>
        {output}
      </PanelContent>
    </Panel>
  )
}

Breakpoints.displayName = 'Breakpoints'
Breakpoints.propTypes = propTypes
Breakpoints.defaultProps = defaultProps

export default Breakpoints
