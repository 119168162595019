import classNames from 'classnames'
import PropType from 'prop-types'
import { useLayoutEffect, useMemo } from 'react'

import { usePanel } from '../../contexts/PanelContext'
import AivaChatLauncherButton from '../aiva/AivaChatLauncherButton'
import SideMenuToggleButton from '../side-menu/SideMenuToggleButton'

const propTypes = {
  children: PropType.node.isRequired,
  hideAivaChatButton: PropType.bool,
  hideMenuToggleButton: PropType.bool
}

const defaultProps = {
  hideAivaChatButton: false,
  hideMenuToggleButton: false
}

const Panel = ({ children, hideAivaChatButton, hideMenuToggleButton }) => {
  const {
    inSecond, hasTwoPanels, roomForTwoPanels, setContainsAivaChatButton, setContainsSideMenuToggleButton
  } = usePanel()
  const className = classNames('flex flex-col h-full w-full overflow-hidden')

  const showAivaChatButton = useMemo(() => {
    if (roomForTwoPanels) {
      /*
        For wider browser / tablet-landscape screens
        Show if:
          * we were not explicitly asked to hide it
            -and-
          * we are
            a) in the second panel
               -or-
            b) in the first panel w/ no second panel
      */
      return !hideAivaChatButton && (inSecond || !hasTwoPanels)
    } else {
      /*
        For smaller mobile / tablet-portrait screens
        Show if:
          * we were not explicitly asked to hide it
      */
      return !hideAivaChatButton
    }
  }, [hasTwoPanels, hideAivaChatButton, inSecond, roomForTwoPanels])

  const showMenuToggleButton = useMemo(() => {
    return !hideMenuToggleButton
  }, [hideMenuToggleButton])

  useLayoutEffect(() => {
    setContainsAivaChatButton(showAivaChatButton)
    setContainsSideMenuToggleButton(showMenuToggleButton)
  }, [showAivaChatButton, showMenuToggleButton, setContainsAivaChatButton, setContainsSideMenuToggleButton])

  return (
    <>
      <div className={className}>
        {children}
      </div>
      {showMenuToggleButton ? <SideMenuToggleButton /> : null}
      {showAivaChatButton ? <AivaChatLauncherButton /> : null}
    </>
  )
}

Panel.displayName = 'Panel'
Panel.propTypes = propTypes
Panel.defaultProps = defaultProps

export default Panel
