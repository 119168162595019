import * as React from "react";
import { memo } from "react";
const SvgMicrophone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2a2 2 0 0 0-2 2v8a2 2 0 1 0 4 0V4a2 2 0 0 0-2-2ZM8 4a4 4 0 1 1 8 0v8a4 4 0 0 1-8 0V4ZM4 9a1 1 0 0 1 1 1v2c0 3.858 3.142 7 7 7s7-3.142 7-7v-2a1 1 0 1 1 2 0v2c0 4.624-3.506 8.446-8 8.945V23a1 1 0 1 1-2 0v-2.055c-4.494-.5-8-4.32-8-8.945v-2a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMicrophone);
export default Memo;
