import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { messageQualityLabel } from '../../../helpers/messageQuality'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import useStore from '../../../store'
import { More } from '../../../svg/icons'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import Button from '../../buttons/Button'
import MessageTemplateCreateAction from '../../message-templates/actions/CreateAction'
import CopyFromAction from '../actions/CopyFromAction'
import DeleteAction from '../actions/DeleteAction'
import EditScheduledMessageAction from '../actions/EditScheduledMessageAction'
import EditScheduledMessageScheduleAction from '../actions/EditScheduledMessageScheduleAction'
import MessageQualityAction from '../actions/MessageQuality'
import NewBroadcastAction from '../actions/NewBroadcast'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageThreadItemActionMenuDefault'] })

const MessageThreadItemActionMenuDefault = forwardRef(({ message }, ref) => {
  const actionMenuRef = useRef()
  const [showButton, setShowButton] = useState(false)
  const { aivaSettings: { messageHelper: aivaMessageHelperEnabled } } = useCurrentUser()

  const { currentUserId } = useStore(useShallow((state) => ({ currentUserId: state.currentUser?._id })))

  useLogger({ log, lifecycle: false, tags: [] })

  useImperativeHandle(ref, () => ({
    showButton () { setShowButton(true) },
    hideButton () { setShowButton(false) },
    toggleButton () { setShowButton(!showButton) },
    open () { actionMenuRef.current.open() },
    close () { actionMenuRef.current.close() }
  }), [showButton])

  const triggerClassName = classNames(
    'absolute top-[50%] mt-[-23px] select-none',
    message.direction === 'in' ? 'right-[-40px]' : 'left-[-40px]',
    !showButton ? 'hidden' : null
  )

  const triggerTrapClick = useCallback((event) => event.stopPropagation(), [])
  const trigger = (
    <Button
      className={triggerClassName}
      icon={<More />}
      size='sm'
      theme='none'
      variant='none'
      onClick={triggerTrapClick}
    >
      Manage Message
    </Button>
  )
  const placement = message.direction === 'in' ? 'right' : 'left'

  const copyFromActionRef = useRef()
  const handleCopyFromAction = useCallback(() => { copyFromActionRef.current.activate() }, [])

  const createTemplateActionRef = useRef()
  const handleCreateTemplateAction = useCallback(() => { createTemplateActionRef.current.activate() }, [])

  const deleteActionRef = useRef()
  const handleDeleteAction = useCallback(() => { deleteActionRef.current.activate() }, [])

  const editActionRef = useRef()
  const handleEditAction = useCallback(() => { editActionRef.current.activate() }, [])

  const editScheduleActionRef = useRef()
  const handleEditScheduleAction = useCallback(() => { editScheduleActionRef.current.activate() }, [])

  const scheduleBroadcastCreateActionRef = useRef()
  const handleScheduledBroadcastAction = useCallback(() => { scheduleBroadcastCreateActionRef.current.activate() }, [])

  const messageQualityActionRef = useRef()
  const handleMessageQuality = useCallback(() => { messageQualityActionRef.current.activate() }, [])

  const isSpaceMessage = message.space
  const isChatMessage = !isSpaceMessage
  const isScheduledMessage = message.isScheduled
  const isOwner = message.userId === currentUserId
  const isRVM = message.type === 'rvm'
  const isEmail = message.type === 'email'

  return (
    <>
      <ActionMenu ref={actionMenuRef} placement={placement} trigger={trigger}>
        <ActionMenuItem label='Copy From Here' onClick={handleCopyFromAction} />
        {isScheduledMessage ? <ActionMenuItem label='Edit Message' onClick={handleEditAction} /> : null}
        {isScheduledMessage ? <ActionMenuItem label='Edit Schedule' onClick={handleEditScheduleAction} /> : null}
        {!isRVM && !isEmail ? <ActionMenuItem label={messageQualityLabel(aivaMessageHelperEnabled)} onClick={handleMessageQuality} /> : null}
        {isChatMessage && !isRVM && !isEmail ? <ActionMenuItem label='New Message Template' onClick={handleCreateTemplateAction} /> : null}
        {isChatMessage && !isEmail ? <ActionMenuItem label='New Broadcast' onClick={handleScheduledBroadcastAction} /> : null}
        {isOwner ? <ActionMenuItem label='Delete Message' onClick={handleDeleteAction} /> : null}
      </ActionMenu>
      <MessageQualityAction ref={messageQualityActionRef} message={message} variant={aivaMessageHelperEnabled ? 'aiva' : 'insights'} />
      <CopyFromAction ref={copyFromActionRef} message={message} />
      <DeleteAction ref={deleteActionRef} message={message} />
      {!isRVM ? <MessageTemplateCreateAction ref={createTemplateActionRef} message={message} /> : null}
      <NewBroadcastAction ref={scheduleBroadcastCreateActionRef} message={message} />
      {isScheduledMessage ? <EditScheduledMessageAction ref={editActionRef} message={message} /> : null}
      {isScheduledMessage ? <EditScheduledMessageScheduleAction ref={editScheduleActionRef} message={message} /> : null}
    </>
  )
})

MessageThreadItemActionMenuDefault.displayName = 'MessageThreadItemActionMenuDefault'
MessageThreadItemActionMenuDefault.propTypes = propTypes
MessageThreadItemActionMenuDefault.defaultProps = defaultProps

export default MessageThreadItemActionMenuDefault
