import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import CreateAction from '../../../components/trackable-links/actions/CreateAction'
import { Default as DefaultEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const TrackableLinkEmptyListContent = () => {
  const createActionRef = useRef()

  const handleAddTrackableLink = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <DefaultEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong text-center'>Get Started with Trackable Links</h1>
        <p className='label-sm text-center'>
          Learn how to use
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/trackable-links'
            label='Trackable Links'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleAddTrackableLink}>
          Add Trackable Link
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

TrackableLinkEmptyListContent.displayName = 'TrackableLinkEmptyListContent'
TrackableLinkEmptyListContent.propTypes = propTypes
TrackableLinkEmptyListContent.defaultProps = defaultProps

export default TrackableLinkEmptyListContent
