import * as React from "react";
import { memo } from "react";
const SvgStats = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V3Zm1-3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H9Zm5.489 5.128a1 1 0 0 0-.987.004c-.907.52-1.956 1.384-2.8 2.491a.997.997 0 0 0-.087.104c-.842 1.142-.771 2.505-.147 3.536A3.635 3.635 0 0 0 13 12.95V14a1 1 0 1 0 2 0v-1.051a3.629 3.629 0 0 0 2.526-1.687c.623-1.033.69-2.394-.15-3.533-.856-1.168-1.945-2.073-2.887-2.601ZM14.369 11h-.749c-.663 0-1.18-.341-1.441-.773-.238-.393-.26-.838-.007-1.237.022-.024.042-.049.061-.075a7.994 7.994 0 0 1 1.77-1.73 7.77 7.77 0 0 1 1.76 1.726l.002.002c.31.42.3.898.049 1.315-.26.43-.775.772-1.444.772ZM4 5a1 1 0 0 0-2 0v14c0 2.762 2.238 5 5 5h10a1 1 0 1 0 0-2H7c-1.658 0-3-1.342-3-3V5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgStats);
export default Memo;
