import React from 'react'

import AppFlowsDetail from '../screens/app-flows/AppFlowsDetail'

const routes = [
  {
    path: ':appFlowId',
    element: <AppFlowsDetail />
  }
]

export default routes
