import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWizard } from 'react-use-wizard'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useService from '../../../hooks/useService'
import { trackPurchase } from '../../../lib/analytics'
import { formatPhone } from '../../../lib/formatters'
import { generate } from '../../../lib/shortid'
import {
  signUpPreFlight as signUpPreFlightService,
  signUp as signUpService
} from '../../../services/subscription'
import Header from '../shared/Header'

import validationSuite from './MobileVerificationForm.validations'

const propTypes = {
  data: PropType.shape({
    email: PropType.string.isRequired,
    firstName: PropType.string.isRequired,
    lastName: PropType.string.isRequired,
    password: PropType.string.isRequired,
    phoneNumber: PropType.string.isRequired
  })
}

const defaultProps = {
  data: null
}

const resendSeconds = 60

const formSettings = {
  id: 'mobileVerificationForm',
  defaultValues: {},
  formControls: [
    {
      type: 'text',
      name: 'verificationCode',
      label: 'Verification Code',
      placeholder: 'Enter your verification code',
      autoComplete: 'one-time-code',
      autoFocus: true,
      required: true
    }
  ],
  validationSuite
}

const MobileVerificationForm = (data) => {
  const formRef = useRef()
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState('')
  const [resendEnabled, setResendEnabled] = useState(true)
  const { goToStep } = useWizard()
  const purchaseEventId = generate()

  const { selectedPlan } = data

  const handlePreFlightReplyOk = useCallback((reply) => {
    if (reply.json?.verificationCodeSent === true) {
      notify('Check your text messages', 'Please enter the verification code we sent to complete your sign up.')
      formRef.current?.reset()
    }
  }, [])
  const { call: signUpPreFlight } = useService(signUpPreFlightService, { onReplyOk: handlePreFlightReplyOk })

  const handleSignUpReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const handleSignUpReplyOk = useCallback(() => {
    if (verificationCode) {
      trackPurchase({ selectedPlan, eventId: purchaseEventId })
      notify('Account Created', 'Welcome to Project Broadcast! You can now log in.')
      navigate('/auth')
    }
  }, [navigate, purchaseEventId, selectedPlan, verificationCode])
  const { call: signUp } = useService(signUpService, { onReplyOk: handleSignUpReplyOk, onReply: handleSignUpReply })

  formSettings.onSubmit = ({ verificationCode }) => {
    setVerificationCode(verificationCode)
    signUp({ ...data, verificationCode })
  }

  const disableResendButton = () => {
    const ms = resendSeconds * 1000
    setResendEnabled(false)
    setTimeout(() => {
      setResendEnabled(true)
    }, ms)
  }

  const resendVerificationCode = () => {
    signUpPreFlight({ ...data })
    disableResendButton()
  }

  const subtitle = (
    <>
      {'Enter the code we sent to '}
      <span className='whitespace-nowrap'>
        {formatPhone(data.phoneNumber)}
      </span>
    </>
  )

  const handleGoBack = useCallback(() => {
    goToStep(0)
  }, [goToStep])

  return (
    <>
      <Header
        goBack={handleGoBack}
        heading='Create Account'
        subtitle={subtitle}
        title='Verification Code'
      />
      <div className='mt-5'>
        <Form ref={formRef} {...formSettings} preventMultipleSubmits />
        <Button
          className='mt-5 w-full'
          form='mobileVerificationForm'
          size='md'
          type='submit'
        >
          Continue
        </Button>
      </div>
      <p className='text-center label-md my-5'>
        {resendEnabled
          ? (
            <Button
              className='label-md mx-auto'
              size='sm'
              theme='primary'
              variant='text'
              onClick={resendVerificationCode}
            >
              Resend Validation Code
            </Button>
            )
          : `Validation code resent. You can request another code after ${resendSeconds} seconds.`}
      </p>
    </>
  )
}

MobileVerificationForm.displayName = 'MobileVerificationForm'
MobileVerificationForm.propTypes = propTypes
MobileVerificationForm.defaultProps = defaultProps

export default MobileVerificationForm
