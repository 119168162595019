import * as React from 'react'
import { memo } from 'react'
const SvgAttachment = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#EAD6E6" rx={4} />
        <path
            fill="#973080"
            d="M12.292 18.708a4.378 4.378 0 0 0 4.375-4.375V8.865a3.277 3.277 0 0 0-3.282-3.282 3.277 3.277 0 0 0-3.28 3.282v5.468c0 1.21.976 2.188 2.187 2.188 1.21 0 2.187-.977 2.187-2.188V9.411a.542.542 0 0 0-.547-.546.546.546 0 0 0-.547.546v4.922a1.092 1.092 0 1 1-2.188 0V8.865c0-1.21.978-2.188 2.188-2.188 1.21 0 2.188.977 2.188 2.188v5.468a3.277 3.277 0 0 1-3.281 3.282 3.277 3.277 0 0 1-3.282-3.282v-4.192a.542.542 0 0 0-.546-.547.546.546 0 0 0-.547.547v4.192a4.378 4.378 0 0 0 4.375 4.375Z"
        />
    </svg>
)
const Memo = memo(SvgAttachment)
export default Memo
