import PropType from 'prop-types'

import messageFromJSON from '../models/message'

import { qualityPropTypes, sendPropTypes } from './message.prop-types'
import messageFieldsNormalizer from './messageFieldsNormalizer'
import pb from './pb'

export function archive (messageId, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON
    }
  }
  return pb.setupPut(`messages/${messageId}/archive`, options)
}

export function get (messageId, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON
    }
  }
  return pb.setupGet(`messages/${messageId}`, options)
}

export function del (messageId, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON
    }
  }
  return pb.setupDelete(`messages/${messageId}`, options)
}

// Handles sending/queuing both chat and space messages
export function send (body, background = false) {
  body = messageFieldsNormalizer(body)
  PropType.checkPropTypes(sendPropTypes, body, 'prop', 'message#send')
  const endPoint = body?.runAt ? 'messages/queue' : 'messages/send'
  return pb.setupPost(endPoint, { background, body })
}

export function messageTagUrlInfo () {
  return pb.setupGet('messages/dynamicUrlsInfo', { background: true })
}

export function quality (message, background = false) {
  const options = {
    body: { text: message?.text },
    background
  }
  PropType.checkPropTypes(qualityPropTypes, message, 'prop', 'message#quality')
  return pb.setupPost('message-quality', options)
}

export function searchUnsent (body, background = false) {
  const options = {
    background,
    body,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  return pb.setupPost('messages/unsent', options)
}

export function updateScheduledMessage (messageId, body, background = false) {
  body = messageFieldsNormalizer(body)
  const options = {
    background,
    body,
    model: {
      conversion: messageFromJSON
    }
  }
  return pb.setupPut(`messages/${messageId}/scheduled`, options)
}

export function importMedia ({ imageUrl }) {
  return pb.setupPost('messages/media/import', {
    body: { imageUrl }
  })
}
