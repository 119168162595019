import { useEffect, useState } from 'react'

import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { stats as statsService } from '../../../services/admin'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Stats'] })

const Stats = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [stats, setStats] = useState([])

  const loadStats = (data) => {
    if (data?.json) {
      setStats(data.json)
    }
  }
  const { call: getStats } = useService(statsService, { onReplyOk: loadStats })

  useEffect(() => {
    getStats()
  }, [getStats])

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='Admin Stats'
      />
      <PanelContent>
        <table>
          <tbody>
            {stats.map((stat) => (
              <tr key={stat.name} className='border-b border-neutral-200'>
                <td className='px-4 py-4'>
                  <div className='text-black'>
                    {stat.name}
                    {' '}
                    <span className='paragraph-sm text-neutral-400 whitespace-nowrap'>
                      (query took
                      {' '}
                      {stat.time}
                      ms)
                    </span>
                  </div>
                </td>
                <td className='bg-neutral-50 text-center text-black whitespace-nowrap px-4 py-4'>
                  {stat.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PanelContent>
    </Panel>
  )
}

Stats.displayName = 'Stats'
Stats.propTypes = propTypes
Stats.defaultProps = defaultProps

export default Stats
