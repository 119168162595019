import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import DeleteAction from '../../../components/contests/actions/DeleteAction'
import PickWinnerAction from '../../../components/contests/actions/PickWinner'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import contestShape from '../../../prop-types/shapes/contest'

const propTypes = {
  contest: PropType.shape(contestShape)
}

const defaultProps = {
  contest: null
}

const log = logger({ enabled: false, tags: ['ContestDetailHeaderMore'] })

const ContestDetailHeaderMore = ({ contest }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const pickWinnerActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/contests', { replace: true }), [navigateInStack])

  const onPickWinnerSelected = useCallback(() => pickWinnerActionRef.current.activate(), [])

  return !!contest && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Delete Contest' onClick={onDeleteSelected} />
        <ActionMenuItem label='Pick Winner' onClick={onPickWinnerSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} contest={contest} onSuccess={onDeleteSuccess} />
      <PickWinnerAction ref={pickWinnerActionRef} contest={contest} />
    </>
  )
}

ContestDetailHeaderMore.displayName = 'ContestDetailHeaderMore'
ContestDetailHeaderMore.propTypes = propTypes
ContestDetailHeaderMore.defaultProps = defaultProps

export default ContestDetailHeaderMore
