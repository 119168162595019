import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import CreateAction from '../../../components/app-flows/actions/CreateAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const ListHeaderMoreMenu = ({ listRef }) => {
  const createActionRef = useRef()

  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])
  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon={<Plus />} title='Create New App Flow' onClick={handleCreateSelected} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

ListHeaderMoreMenu.displayName = 'ListHeaderMoreMenu'
ListHeaderMoreMenu.propTypes = propTypes
ListHeaderMoreMenu.defaultProps = defaultProps

export default ListHeaderMoreMenu
