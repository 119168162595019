import * as React from "react";
import { memo } from "react";
const SvgAttachments = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 180"
    {...props}
  >
    <g clipPath="url(#Attachments_svg__a)">
      <path fill="#fff" d="M0 0h375v180H0z" />
      <g filter="url(#Attachments_svg__b)">
        <path
          fill="#fff"
          d="M255.286 0H114.714C108.245 0 103 5.245 103 11.714v140.572c0 6.469 5.245 11.714 11.714 11.714h140.572c6.469 0 11.714-5.245 11.714-11.714V11.714C267 5.244 261.755 0 255.286 0Z"
        />
        <path
          stroke="#E6E7EB"
          d="M114.714.5h140.572c6.193 0 11.214 5.02 11.214 11.214v140.572c0 6.193-5.021 11.214-11.214 11.214H114.714c-6.193 0-11.214-5.021-11.214-11.214V11.714C103.5 5.521 108.521.5 114.714.5Z"
        />
      </g>
      <path
        fill="#FEF4FF"
        d="M245 12H125c-5.523 0-10 4.477-10 10v120c0 5.523 4.477 10 10 10h120c5.523 0 10-4.477 10-10V22c0-5.523-4.477-10-10-10Z"
      />
      <g filter="url(#Attachments_svg__c)">
        <rect
          width={178}
          height={40}
          x={122}
          y={80.5}
          fill="#fff"
          rx={4}
          shapeRendering="crispEdges"
        />
        <rect width={28} height={28} x={130} y={86.5} fill="#FAE8FE" rx={4} />
        <path
          fill="#C026D4"
          d="M144.5 112.5c4.138 0 7.5-3.363 7.5-7.5v-9.375A5.618 5.618 0 0 0 146.375 90a5.618 5.618 0 0 0-5.625 5.625V105a3.745 3.745 0 0 0 3.75 3.75 3.745 3.745 0 0 0 3.75-3.75v-8.438a.928.928 0 0 0-.938-.937.936.936 0 0 0-.937.938V105a1.872 1.872 0 0 1-1.875 1.875 1.873 1.873 0 0 1-1.875-1.875v-9.375a3.745 3.745 0 0 1 3.75-3.75 3.745 3.745 0 0 1 3.75 3.75V105a5.617 5.617 0 0 1-5.625 5.625 5.618 5.618 0 0 1-5.625-5.625v-7.188a.928.928 0 0 0-.937-.937.936.936 0 0 0-.938.938V105c0 4.137 3.363 7.5 7.5 7.5Z"
        />
        <path
          fill="#FAE8FE"
          d="M255.397 91.471h-87.859c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.537-1.573 3.537-3.514 0-1.941-1.583-3.515-3.537-3.515ZM219.428 102.5h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h51.89c1.954 0 3.538-1.574 3.538-3.515s-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={177}
          height={39}
          x={122.5}
          y={81}
          stroke="#E6E7EB"
          rx={3.5}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#Attachments_svg__d)">
        <rect
          width={178}
          height={40}
          x={74.5}
          y={29}
          fill="#fff"
          rx={4}
          shapeRendering="crispEdges"
        />
        <rect width={28} height={28} x={82.5} y={35} fill="#FAE8FE" rx={4} />
        <path
          fill="#C026D4"
          d="M97.5 60.5c4.138 0 7.5-3.362 7.5-7.5v-9.375A5.618 5.618 0 0 0 99.375 38a5.617 5.617 0 0 0-5.625 5.625V53a3.745 3.745 0 0 0 3.75 3.75 3.745 3.745 0 0 0 3.75-3.75v-8.438a.928.928 0 0 0-.938-.937.936.936 0 0 0-.937.938V53a1.872 1.872 0 0 1-1.875 1.875A1.872 1.872 0 0 1 95.625 53v-9.375a3.745 3.745 0 0 1 3.75-3.75 3.745 3.745 0 0 1 3.75 3.75V53a5.617 5.617 0 0 1-5.625 5.625A5.617 5.617 0 0 1 91.875 53v-7.188a.928.928 0 0 0-.938-.937.936.936 0 0 0-.937.938V53c0 4.138 3.362 7.5 7.5 7.5Z"
        />
        <path
          fill="#FAE8FE"
          d="M207.897 39.971h-87.859c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.537-1.573 3.537-3.514 0-1.941-1.583-3.515-3.537-3.515ZM171.928 51h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h51.89c1.954 0 3.538-1.574 3.538-3.515 0-1.94-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={177}
          height={39}
          x={75}
          y={29.5}
          stroke="#E6E7EB"
          rx={3.5}
          shapeRendering="crispEdges"
        />
      </g>
    </g>
    <defs>
      <filter
        id="Attachments_svg__b"
        width={182}
        height={182}
        x={96}
        y={-5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1064_23826"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1064_23826"
          result="shape"
        />
      </filter>
      <filter
        id="Attachments_svg__c"
        width={196}
        height={58}
        x={115}
        y={75.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1064_23826"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1064_23826"
          result="shape"
        />
      </filter>
      <filter
        id="Attachments_svg__d"
        width={196}
        height={58}
        x={67.5}
        y={24}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1064_23826"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1064_23826"
          result="shape"
        />
      </filter>
      <clipPath id="Attachments_svg__a">
        <path fill="#fff" d="M0 0h375v180H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAttachments);
export default Memo;
