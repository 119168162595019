import PropType from 'prop-types'

import messageQualityProCon from './messageQualityProCon'

export default {
  cons: PropType.arrayOf(PropType.shape(messageQualityProCon)),
  strengthRating: PropType.oneOf(['strong', 'good', 'average', 'weak']),
  pros: PropType.arrayOf(PropType.string),
  rawDeliverability: PropType.number,
  scaledDeliverability: PropType.number // 0-100 (100 is bad)
}
