import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Wizard as ReactWizard } from 'react-use-wizard'

import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import useCurrentUser from '../../../hooks/useCurrentUser'
import usePartnerRequest from '../../../hooks/usePartnerRequest'
import useStore from '../../../store'
import MultiFactorAuthenticationAddMethod from '../multi-factor-auth/AddMethod'
import MultiFactorAuthenticationChoices from '../multi-factor-auth/Choices'
import MultiFactorAuthenticationForm from '../multi-factor-auth/Form'
import MultiFactorAuthenticationSetup from '../multi-factor-auth/Setup'
import PanelWrapper from '../shared/PanelWrapper'

import LoginForm from './Form'

const propTypes = {}
const defaultProps = {}

const LoginWizard = () => {
  const [mfaChallenge, setMfaChallenge] = useState(null)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const {
    forgotPasswordUrl,
    internalConnectUrl,
    partnerId,
    reactivateUrl,
    signUpUrl,
    verificationComplete,
    verified
  } = usePartnerRequest()
  const navigate = useNavigate()
  const requestedLocation = useStore((state) => state.requestedLocation)

  const currentUser = useCurrentUser()
  if (partnerId && currentUser) {
    return <Navigate to={internalConnectUrl} replace />
  }

  if (currentUser) {
    return <Navigate to={requestedLocation || '/chats'} replace />
  }

  const onAccountCancelled = () => {
    const path = partnerId ? reactivateUrl : '/reactivate'
    return navigate(path, { replace: true })
  }

  const onLoggedIn = () => {
    const path = partnerId
      ? internalConnectUrl
      : (requestedLocation || '/chats')
    return navigate(path, { replace: true })
  }

  const onMfaChallenge = (challenge) => {
    setMfaChallenge(challenge)
    if (challenge?.availableMethods.length === 1) {
      setSelectedMethod(challenge.availableMethods[0])
      setSelectedChannel(challenge.availableMethods[0].channel)
    }
  }

  const onMethodSelected = (method) => {
    setSelectedMethod(method)
  }

  const onChannelSelected = (channel) => {
    setSelectedChannel(channel)
  }

  // form defaultValues are cached after first render,
  // so block Form rendering until we have the data we need.
  if (partnerId && !verified) {
    if (verificationComplete) {
      return <PanelNotFound />
    } else {
      return <PanelLoading noHeader />
    }
  }

  return (
    <ReactWizard wrapper={<PanelWrapper />}>
      <LoginForm
        forgotPasswordUrl={forgotPasswordUrl}
        signUpUrl={signUpUrl}
        onAccountCancelled={onAccountCancelled}
        onLoggedIn={onLoggedIn}
        onMfaChallenge={onMfaChallenge}
      />
      <MultiFactorAuthenticationSetup
        canDeferSetup={mfaChallenge?.canDeferSetup}
        nonce={mfaChallenge?.nonce}
        supportedChannels={mfaChallenge?.supportedChannels}
        username={mfaChallenge?.username}
        onAccountCancelled={onAccountCancelled}
        onChannelSelected={onChannelSelected}
        onLoggedIn={onLoggedIn}
      />
      <MultiFactorAuthenticationAddMethod
        nonce={mfaChallenge?.nonce}
        selectedChannel={selectedChannel}
        username={mfaChallenge?.username}
        onMethodSelected={onMethodSelected}
      />
      <MultiFactorAuthenticationChoices
        availableMethods={mfaChallenge?.availableMethods}
        onMethodSelected={onMethodSelected}
      />
      <MultiFactorAuthenticationForm
        method={selectedMethod}
        nonce={mfaChallenge?.nonce}
        username={mfaChallenge?.username}
        onAccountCancelled={onAccountCancelled}
        onLoggedIn={onLoggedIn}
      />
    </ReactWizard>
  )
}

LoginWizard.displayName = 'LoginWizard'
LoginWizard.propTypes = propTypes
LoginWizard.defaultProps = defaultProps

export default LoginWizard
