import { forwardRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { ArrowUp } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SendButton'] })

const SendButton = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Button
      ref={ref}
      icon={<ArrowUp />}
      size='md'
      theme='primary'
      variant='filled'
      circle
      {...props}
    >
      Send
    </Button>
  )
})

SendButton.displayName = 'SendButton'
SendButton.propTypes = propTypes
SendButton.defaultProps = defaultProps

export default SendButton
