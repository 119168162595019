import { App } from '@capacitor/app'
import { useCallback, useEffect, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PbNativeUtil from '../../plugins/pb-native-util'
import { handleAndroidBackButton } from '../../store/actions/stacks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'BackButton'] })

const BackButton = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const backButtonListenerRef = useRef()

  const handleBackButton = useCallback(() => {
    if (!handleAndroidBackButton()) {
      PbNativeUtil.launchAndroidHomeScreen()
    }
  }, [])

  useEffect(() => {
    async function removeAndAdd () {
      if (backButtonListenerRef.current?.remove) {
        await backButtonListenerRef.current.remove()
        log.debug('removed previous "backButton" listener')
      }
      backButtonListenerRef.current = await App.addListener('backButton', handleBackButton)
      log.debug('added new "backButton" listener')
    }

    removeAndAdd()

    return () => { backButtonListenerRef.current?.remove() }
  }, [handleBackButton])

  return null
}

BackButton.displayName = 'BackButton'
BackButton.propTypes = propTypes
BackButton.defaultProps = defaultProps

export default BackButton
