import PropType from 'prop-types'

import { useApp } from '../../../contexts/AppContext'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLatestStackPath from '../../../hooks/useLatestStackPath'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import NavLinkItem from './NavLinkItem'

const propTypes = {
  currentUser: PropType.shape({
    superuser: PropType.bool
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Nav'] })

const Nav = ({ currentUser }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isWeb } = useApp()
  const { stripeId } = useCurrentUser()

  const accountStackPath = useLatestStackPath('account', '/account')
  const subscriptionStackPath = useLatestStackPath('subscription', '/subscription')
  const settingsStackPath = useLatestStackPath('settings', '/settings')

  return (
    <ul>
      <NavLinkItem label='Account' to={accountStackPath} />
      {isWeb && stripeId ? <NavLinkItem label='Subscription' to={subscriptionStackPath} /> : null}
      <NavLinkItem label='Settings' to={settingsStackPath} />
      {currentUser?.superuser ? <NavLinkItem label='Style Guide' to='/style-guide' /> : null}
      <NavLinkItem arrow={false} label='Sign Out' to='/logout' />
    </ul>
  )
}

Nav.displayName = 'Nav'
Nav.propTypes = propTypes
Nav.defaultProps = defaultProps

export default Nav
