import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Banner from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import PanelDialog from '../../../components/dialog/PanelDialog'
import FormControl from '../../../components/forms/FormControl'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import usePartnerRequest from '../../../hooks/usePartnerRequest'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import {
  allow as allowIntegrationService,
  deny as denyIntegrationService
} from '../../../services/integration'
import { isCurrentlyManaging as isCurrentlyManagingService } from '../../../services/user'
import { MarkColor } from '../../../svg/branding'
import { ChevronLeft, ChevronRight } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ConnectDialog'] })

const ConnectDialog = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { call: isCurrentlyManaging } = useService(isCurrentlyManagingService, { onReply: handleCurrentlyManagingReply })
  function handleCurrentlyManagingReply (data) {
    setManagerName(data?.json?.managingUserFullName)
  }

  const {
    connectUrl,
    integration,
    partnerId
  } = usePartnerRequest()
  const panelDialogRef = useRef()
  const [userAgrees, setUserAgrees] = useState(false)
  const [managerName, setManagerName] = useState()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  const onReplyOk = useCallback((reply) => {
    const redirect = reply.json?.redirect
    if (redirect) {
      // typically redirects back to integration site
      window.location.replace(redirect)
    } else {
      bus.emit('integrationAllowedOrDenied', integration)
      panelDialogRef.current.close()
      navigate('/account/integrations', { replace: true })
    }
  }, [integration, navigate])
  const { call: allowIntegration } = useService(allowIntegrationService, { onReplyOk })
  const { call: denyIntegration } = useService(denyIntegrationService, { onReplyOk })

  const logoutAndConnectUrl = `/logout?to=${encodeURIComponent(connectUrl)}`

  let integrationLogo = ''
  if (integration) {
    try {
      integrationLogo = require(`../../../assets/images/integrations/${integration.key}.png`)
    } catch (e) {}
  }

  useEffect(() => {
    isCurrentlyManaging()
  }, [isCurrentlyManaging])

  useEffect(() => {
    if (!currentUser) {
      navigate(connectUrl, { replace: true })
    }
  }, [connectUrl, currentUser, navigate])

  useEffect(() => {
    if (integration) {
      panelDialogRef.current.open()
    }
  }, [integration])

  const handleChange = useCallback((e) => {
    setUserAgrees(e.target.checked)
  }, [])

  const handleAllowClicked = useCallback(() => {
    const isExistingUser = true
    allowIntegration(partnerId, window.location.search, isExistingUser)
  }, [allowIntegration, partnerId])

  const handleDenyClicked = useCallback(() => {
    denyIntegration(partnerId, window.location.search)
  }, [denyIntegration, partnerId])

  const handleLogOut = useCallback(() => {
    navigate(logoutAndConnectUrl, { replace: true })
  }, [logoutAndConnectUrl, navigate])

  if (!integration) {
    return
  }

  return (
    <PanelDialog
      ref={panelDialogRef}
      ariaDescription={`Connect with ${integration.name} panel dialog.`}
      ariaLabel='Connect Dialog'
      dismissable={false}
    >
      <PanelHeader
        title={`Connect with ${integration.name}`}
      />
      <PanelContent className='p-4 flex'>
        <div className='flex flex-row gap-3'>
          <div className='flex basis-1/3 w-[150px] h-[150px]'>
            <MarkColor />
          </div>
          <ChevronLeft className='flex basis-1/6 w-[150px] h-[150px]' />
          <ChevronRight className='flex basis-1/6 w-[150px] h-[150px]' />
          <img alt={`${integration.name} logo`} className='flex basis-1/3 w-[150px] h-[150px]' src={integrationLogo} />
        </div>
        <p className='text-center paragraph-md my-5'>
          {integration.request}
        </p>
        {integration.terms
          ? (
            <FormControl
              label={integration.terms}
              labelAlignItems='items-start'
              labelAlignment='right'
              name='partnerTerms'
              type='checkbox'
              dangerouslySetInnerHTML
              onChange={handleChange}
            />
            )
          : null}
        {!!managerName && (
          <Banner type='warning'>
            You are currently managing an account for
            {' '}
            <strong className='whitespace-nowrap'>
              {currentUser?.firstName}
              {' '}
              {currentUser?.lastName}
            </strong>
            {', '}
            not
            {' '}
            <strong className='whitespace-nowrap'>{managerName}</strong>
            {'. '}
          </Banner>
        )}
        <Button
          className='mt-5 w-full'
          disabled={integration.terms ? !userAgrees : false}
          size='md'
          onClick={handleAllowClicked}
        >
          Allow
        </Button>
        <Button
          className='mt-5 w-full'
          href='/account/integrations'
          size='md'
          theme='danger'
          onClick={handleDenyClicked}
        >
          Deny
        </Button>
        <p className='text-center paragraph-sm text-neutral-400 mt-5'>
          You are currently logged in as
          {' '}
          <strong className='whitespace-nowrap'>
            {currentUser?.firstName}
            {' '}
            {currentUser?.lastName}
          </strong>
          {' '}
          <strong className='whitespace-nowrap'>{currentUser?.auth?.username}</strong>
          {'. '}
        </p>
        <Button
          className='mt-5 mx-auto'
          size='sm'
          theme='neutral'
          variant='outline'
          onClick={handleLogOut}
        >
          {managerName
            ? (
              <>
                Return to
                {' '}
                {managerName}
                {' '}
              </>
              )
            : 'Log Out'}
        </Button>
      </PanelContent>
    </PanelDialog>
  )
}

ConnectDialog.displayName = 'ConnectDialog'
ConnectDialog.propTypes = propTypes
ConnectDialog.defaultProps = defaultProps

export default ConnectDialog
