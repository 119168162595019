import { Provider as RollbarProvider } from '@rollbar/react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import GenericErrorBoundary from './components/error-boundaries/GenericErrorBoundary'
import PageViewTracker from './components/PageViewTracker'
import RollbarRouteContextProvider from './components/RollbarRouteContextProvider'
import { AppProvider } from './contexts/AppContext'
import useLogger from './hooks/useLogger'
import logger from './lib/logger'
import rollbar from './lib/rollbar'
import { ShortLinkRoutes } from './routes/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShortLinkApp'] })

const ShortLinkApp = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <RollbarProvider instance={rollbar}>
      <BrowserRouter>
        <AppProvider shortLinkApp>
          <GenericErrorBoundary boundaryName='ShortLinkApp'>
            <ShortLinkRoutes />
            <Toaster containerClassName='mt-safe' />
            <PageViewTracker />
            <RollbarRouteContextProvider />
          </GenericErrorBoundary>
        </AppProvider>
      </BrowserRouter>
    </RollbarProvider>
  )
}

ShortLinkApp.displayName = 'ShortLinkApp'
ShortLinkApp.propTypes = propTypes
ShortLinkApp.defaultProps = defaultProps

export default ShortLinkApp
