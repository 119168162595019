import { formatTag } from '../lib/formatters'

import proxy from './proxy'

export default function landingPageFromJSON (json) {
  if (!json) { return null }

  const overrides = {

    get tagName () {
      return formatTag(json.name).toLowerCase()
    }
  }

  return proxy(json, overrides)
}
