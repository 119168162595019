import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import CloneAction from '../../../components/configurable-forms/actions/CloneAction'
import DeleteAction from '../../../components/configurable-forms/actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import configurableFormShape from '../../../prop-types/shapes/configurableForm'

const propTypes = {
  configurableForm: PropType.shape(configurableFormShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DetailHeaderMoreMenu'] })

const DetailHeaderMoreMenu = ({ configurableForm }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const cloneActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/configurable-forms', { replace: true }), [navigateInStack])

  const onCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])

  return !!configurableForm && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Clone Form' onClick={onCloneSelected} />
        <ActionMenuItem label='Delete Configurable Form' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} configurableForm={configurableForm} onSuccess={onDeleteSuccess} />
      <CloneAction ref={cloneActionRef} configurableForm={configurableForm} />
    </>
  )
}

DetailHeaderMoreMenu.displayName = 'DetailHeaderMoreMenu'
DetailHeaderMoreMenu.propTypes = propTypes
DetailHeaderMoreMenu.defaultProps = defaultProps

export default DetailHeaderMoreMenu
