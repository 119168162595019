import { get as getGoalService } from '../services/admin/goal'

import useCurrentInstance from './useCurrentInstance'

const useCurrentGoal = () => useCurrentInstance({
  resourceName: 'goal',
  getService: getGoalService,
  replyAttrPath: 'model',
  notFoundRedirect: '/goals'
})

export default useCurrentGoal
