import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'

import ListDialog from './ListDialog'

// TODO: properly support multiple? ... would need to also support onSubmitAllInQuery

const propTypes = {
  list: PropType.elementType.isRequired,
  service: PropType.func.isRequired,
  onSelect: PropType.func
}

const defaultProps = {
  onSelect: undefined
}

const log = logger({ enabled: true, tags: ['ListSelectAction'] })

const ListSelectAction = forwardRef(({
  list,
  service,
  onSelect,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, enabled: true, lifecycle: false, tags: [list.displayName] })

  const handleReplyOk = useCallback((reply) => {
    listDialogRef.current.close()
    onSelect?.(reply.model || reply.json)
  }, [onSelect])
  const { call } = useService(service, { onReplyOk: handleReplyOk })

  const listDialogRef = useRef()
  useImperativeHandle(ref, () => ({
    activate () { listDialogRef.current.open() }
  }), [])

  const handleSubmitSelected = useCallback((selected) => {
    log.debug('selected', selected)
    call(selected?._id || selected) // TODO: ListDialog is getting updated to only emit the identifiers
  }, [call])

  return (
    <ListDialog
      ref={listDialogRef}
      list={list}
      multiple={false}
      type='select'
      onSubmitSelected={handleSubmitSelected}
      {...rest}
    />
  )
})

ListSelectAction.displayName = 'ListSelectAction'
ListSelectAction.propTypes = propTypes
ListSelectAction.defaultProps = defaultProps

export default ListSelectAction
