import { useEffect } from 'react'

import poller from '../lib/poller'

const usePoller = (name, fn) => {
  useEffect(() => {
    poller.on(name, fn)
    return () => poller.off(name, fn)
  }, [name, fn])
}

export default usePoller
