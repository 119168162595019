import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import rollbar from '../lib/rollbar'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['RollbarRouteContextProvider'] })

const RollbarRouteContextProvider = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const location = useLocation()

  useEffect(() => {
    rollbar.configure({ payload: { context: location.pathname } })
  }, [location])

  return null
}

RollbarRouteContextProvider.displayName = 'RollbarRouteContextProvider'
RollbarRouteContextProvider.propTypes = propTypes
RollbarRouteContextProvider.defaultProps = defaultProps

export default RollbarRouteContextProvider
