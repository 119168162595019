import PropType from 'prop-types'

import keyword from './keyword'
import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  fields: PropType.object,
  user: PropType.shape(user), // this is sometimes a pretty bare user object though
  search: PropType.string,
  slug: PropType.string,
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  updatedAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  description: PropType.string,
  redirectUrl: PropType.string,
  keyword: PropType.shape(keyword) // this is a pretty bare string object though
}
