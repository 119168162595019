import { throttle } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import logger from '../../../../lib/logger'
import messageShape from '../../../../prop-types/shapes/message'
import { quality as messageQualityService } from '../../../../services/message'
import { Aiva } from '../../../../svg/icons'
import MeetAivaAction from '../../../aiva/actions/MeetAivaAction'
import MessageHelperAction from '../../../aiva/actions/MessageHelperAction'
import Button from '../../../buttons/Button'
import MessageQualityBadge from '../../../message/message-quality/MessageQualityBadge'

const propTypes = {
  message: PropType.shape(messageShape),
  onInsertRevision: PropType.func
}

const defaultProps = {
  message: null,
  onInsertRevision: undefined
}

const log = logger({ enabled: true, tags: ['AivaMessageHelperButton'] })

const AivaMessageHelperButton = forwardRef(({ message, onInsertRevision }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [aivaActivated, setAivaActivated] = useState(false)
  const [messageQuality, setMessageQuality] = useState()
  const meetAivaActionRef = useRef()
  const messageHelperActionRef = useRef()

  const { abort: abortMessageQualityCall, call: callMessageQuality } = useService(messageQualityService, {
    onReplyOk: useCallback((reply) => {
      const results = reply.json
      setMessageQuality(results)
    }, [])
  })

  // NOTE: Disabling the "exhaustive-deps" rule in order to use throttle for this use-case.
  //       With this approach linter receives a "function whose dependencies are unknown" so
  //       it's on us to make sure they're included manually.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAivaMessageInsights = useCallback(throttle((message) => {
    const messageLength = message?.text?.trim().length

    if (messageLength >= 3) {
      setAivaActivated(true)
      callMessageQuality(message, true)
    } else {
      abortMessageQualityCall()
      setAivaActivated(false)
      setMessageQuality(null)
    }
  }, 1250), [abortMessageQualityCall, callMessageQuality])

  useEffect(() => {
    updateAivaMessageInsights(message)
  }, [message, updateAivaMessageInsights])

  const handleClick = useCallback(async () => {
    if (aivaActivated) {
      messageHelperActionRef.current.activate()
    } else {
      meetAivaActionRef.current.activate()
    }
  }, [aivaActivated])

  return (
    <>
      <div className='relative'>
        <Button
          ref={ref}
          className={`!p-0 !rounded-full w-12 h-10 ${aivaActivated ? '!text-indigo-500' : '!text-black'}`}
          size='md'
          theme='neutral'
          variant='filled'
          onClick={handleClick}
        >
          <Aiva className='w-6 h-6 mx-3 my-2' />
        </Button>
        {messageQuality
          ? <MessageQualityBadge className='absolute -top-0.5 -right-0.5' messageQuality={messageQuality} />
          : null}
      </div>
      <MeetAivaAction ref={meetAivaActionRef} />
      {messageQuality
        ? (<MessageHelperAction
            ref={messageHelperActionRef}
            messageQuality={messageQuality}
            messageText={message?.text}
            onInsertRevision={onInsertRevision}
           />)
        : null}
    </>
  )
})

AivaMessageHelperButton.displayName = 'AivaMessageHelperButton'
AivaMessageHelperButton.propTypes = propTypes
AivaMessageHelperButton.defaultProps = defaultProps

export default AivaMessageHelperButton
