import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'
import { archive } from '../../../services/contact'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['ArchiveAction'] })

// NOTE: this is also used in the Chat Thread and gets passed a `chat` as the contact
const ArchiveAction = forwardRef(({ contact, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${contact.formattedName} was archived`)
    bus.emit('contactArchived', contact)
    onSuccess?.()
  }, [contact, onSuccess])
  const { call } = useService(archive, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [confirmRef])

  const handleConfirm = useCallback(() => {
    call([contact._id], false)
  }, [call, contact._id])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='You can unarchive this contact by removing them from Archived Contacts.'
      title={`Are you sure you want to archive ${contact.formattedName}?`}
      onConfirm={handleConfirm}
    />
  )
})

ArchiveAction.displayName = 'ArchiveAction'
ArchiveAction.propTypes = propTypes
ArchiveAction.defaultProps = defaultProps

export default ArchiveAction
