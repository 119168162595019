import useStore from '..'
import { stateKeyFromStackKey } from '../../lib/folder'

export function saveSnapshot (key, snapshot) {
  useStore.setState((state) => {
    state.lists[key] = {
      time: Date.now(),
      ...snapshot
    }
  }, false, `list/saveSnapshot(${key})`)
}

export function toggleListFolderModeByStackKey (stackKey) {
  useStore.setState((state) => {
    const stateKey = stateKeyFromStackKey(stackKey)
    const currentValue = state.listFolderModes[stateKey]
    state.listFolderModes[stateKey] = currentValue !== true
  }, false, `list/toggleListFolderModeByStackKey(${stackKey})`)
}
