import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as qrCodeSearch } from '../../services/qrCode'
import List from '../list/List'

import QrCodeListItemContent from './QrCodeListItemContent'

const propTypes = {
  additionalHandlers: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {}
}

const log = logger({ enabled: false, tags: ['QrCodeList'] })

const qrCodeResultsFields = [
  '_id',
  'name',
  'url'
]

const QrCodeList = forwardRef(({ additionalHandlers, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('qrCodeCreated', handleBusEvent)
  useBus('qrCodeUpdated', handleBusEvent)
  useBus('qrCodeDeleted', handleBusEvent)
  useBus('qrCodesDeleted', handleBusEvent)

  additionalHandlers?.('qrCode', ref)

  return (
    <List
      ref={ref}
      ListItemContent={QrCodeListItemContent}
      placeholder='Search QR Codes'
      resultsFields={qrCodeResultsFields}
      resultsKey='qrCodes'
      searchService={qrCodeSearch}
      search
      {...rest}
    />
  )
})

QrCodeList.displayName = 'QrCodeList'
QrCodeList.propTypes = propTypes
QrCodeList.defaultProps = defaultProps

export default QrCodeList
