import { useCallback, useRef, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import TrackableLinkContactList from '../../components/trackable-links/TrackableLinkContactList'
import useCurrentTrackableLink from '../../hooks/useCurrentTrackableLink'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TrackableLinksContacts'] })

const TrackableLinksContacts = () => {
  const trackableLinkContactListRef = useRef()
  const { trackableLinkId, loading, trackableLink } = useCurrentTrackableLink()

  useLogger({ log, lifecycle: false, tags: [trackableLinkId] })

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !trackableLink) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        start={<PanelHeaderBackButton />}
        subtitle={trackableLink?.name}
        title='Trackable Link Clicks'
      />
      <PanelContent>
        <TrackableLinkContactList
          ref={trackableLinkContactListRef}
          placeholder='Search Trackable Link Clicks'
          trackableLinkId={trackableLink?._id}
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

TrackableLinksContacts.displayName = 'TrackableLinksContacts'
TrackableLinksContacts.propTypes = propTypes
TrackableLinksContacts.defaultProps = defaultProps

export default TrackableLinksContacts
