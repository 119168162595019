import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import DeleteAction from '../../../components/tags/actions/DeleteAction'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import tagShape from '../../../prop-types/shapes/tag'

const propTypes = {
  tag: PropType.shape(tagShape)
}

const defaultProps = {
  tag: null
}

const log = logger({ enabled: false, tags: ['TagDetailHeaderMore'] })

const TagDetailHeaderMore = ({ tag }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/tags', { replace: true }), [navigateInStack])

  return !!tag && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Delete Tag' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} tag={tag} onSuccess={onDeleteSuccess} />
    </>
  )
}

TagDetailHeaderMore.displayName = 'TagDetailHeaderMore'
TagDetailHeaderMore.propTypes = propTypes
TagDetailHeaderMore.defaultProps = defaultProps

export default TagDetailHeaderMore
