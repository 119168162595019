import { omit } from 'lodash'
import PropType from 'prop-types'

import { searchPropTypes as contactSearchPropTypes } from './contact.prop-types'

export const searchPropTypes = {
  scheduledBroadcastId: PropType.string.isRequired,
  ...contactSearchPropTypes
}

export const addRemoveSelectedPropTypes = {
  scheduledBroadcastId: PropType.string.isRequired,
  contactIds: PropType.arrayOf(PropType.string).isRequired
}

export const addRemoveAllInQueryPropTypes = {
  scheduledBroadcastId: PropType.string.isRequired,
  ...omit(contactSearchPropTypes, 'paging')
}

export const searchByStatePropTypes = {
  scheduledBroadcastId: PropType.string.isRequired,
  state: PropType.oneOf(['sending', 'sent', 'unsent']),
  ...contactSearchPropTypes
}

export const tagContactsPropTypes = {
  state: PropType.oneOf(['sendTo', 'sending', 'sent', 'unsent']),
  tag: PropType.string
}
