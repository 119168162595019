import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import spaceShape from '../../../prop-types/shapes/space'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import Button from '../../buttons/Button'

import BulkDeleteAction from './BulkDeleteAction'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(spaceShape)).isRequired
}

const defaultProps = {
}

const BulkEditActions = ({ selectedItems }) => {
  const deleteActionRef = useRef()

  const handleDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])

  const trigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={trigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Delete Spaces' onClick={handleDeleteAction} />
      </ActionMenu>
      <BulkDeleteAction ref={deleteActionRef} spaces={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
