import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function Radios () {
  return (
    <Panel>
      <PanelHeader title='Radios' />
      <PanelContent>
        <div className='p-4'>
          <label className='flex items-center m-2'>
            <input name='test' type='radio' />
            <span className='ml-2'>Default Unchecked</span>
          </label>
          <label className='flex items-center m-2'>
            <input name='test' type='radio' defaultChecked />
            <span className='ml-2'>Default Checked</span>
          </label>
          <label className='flex items-center m-2'>
            <input type='radio' disabled />
            <span className='ml-2'>Disabled Unchecked</span>
          </label>
          <label className='flex items-center m-2'>
            <input
              type='radio'
              defaultChecked
              disabled
            />
            <span className='ml-2'>Disabled Checked</span>
          </label>
        </div>
        <div className='p-4'>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-type='radio' />
            <span className='label-sm-medium'>Fake radio unchecked</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-checked='true' data-type='radio' />
            <span className='label-sm-medium'>Fake radio checked</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-disabled='true' data-type='radio' />
            <span className='label-sm-medium'>Fake radio unchecked disabled</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-checked='true' data-disabled='true' data-type='radio' />
            <span className='label-sm-medium'>Fake radio checked disabled</span>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Radios
