import managerFromJSON from '../models/manager'

import pb from './pb'

export function search (ignoredSearchParam, background = false) {
  return pb.setupGet('users/managers', {
    background,
    model: {
      conversion: managerFromJSON,
      multiple: true
    }
  })
}

export function add (phoneNumber) {
  return pb.setupPost('users/managers/add', { body: { phoneNumber } })
}

export function remove (managerId) {
  return pb.setupPost('users/managers/remove', { body: { managerId } })
}
