import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import PropType from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { generate } from '../../lib/shortid'

import GenericErrorBoundaryFallback from './GenericErrorBoundaryFallback'

const propTypes = {
  boundaryName: PropType.string.isRequired,
  children: PropType.node
}

const defaultProps = {
  children: null
}

const log = logger({ enabled: true, tags: ['GenericErrorBoundary'] })

const GenericErrorBoundary = ({ boundaryName, children }) => {
  const triggeredRef = useRef(false)
  const [key, setKey] = useState(generate())

  useLogger({ log, lifecycle: false, tags: [boundaryName, triggeredRef.current] })

  const location = useLocation()
  useEffect(() => {
    if (triggeredRef.current === true) {
      log.debug('resetting error boundary due to location change', location)
      triggeredRef.current = false
      setKey(generate())
    }
  }, [location])

  const handleCallback = useCallback(() => {
    log.debug('error boundary triggered')
    triggeredRef.current = true
  }, [])

  // eslint-disable-next-line n/handle-callback-err, no-unused-vars
  const handleExtra = useCallback((error, info) => {
    return { boundaryName, innerWidth: window.innerWidth, innerHeight: window.innerHeight }
  }, [boundaryName])

  return (
    <RollbarErrorBoundary
      key={key}
      callback={handleCallback}
      extra={handleExtra}
      fallbackUI={GenericErrorBoundaryFallback}
    >
      {children}
    </RollbarErrorBoundary>
  )
}

GenericErrorBoundary.displayName = 'GenericErrorBoundary'
GenericErrorBoundary.propTypes = propTypes
GenericErrorBoundary.defaultProps = defaultProps

export default GenericErrorBoundary
