import { omit, pick } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import LoadingIndicator from '../../components/LoadingIndicator'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import {
  updateVcardData as updateVcardDataService,
  vcardData as vcardDataService
} from '../../services/user'

import validationSuite from './ContactCard.validations'

const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'stateProvince', 'postal']

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ContactCard'] })

const ContactCard = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [initialVcardData, setInitialVcardData] = useState(null)
  const formRef = useRef()

  const { call: vcardData } = useService(vcardDataService, {
    onReplyOk: useCallback((reply) => setInitialVcardData(reply.json), [])
  })

  const { call: updateVcardData } = useService(updateVcardDataService, {
    onReplyOk: useCallback(() => notify('Contact Card updated successfully.'), [])
  })

  const formProps = useMemo(() => {
    if (!initialVcardData) { return null }

    return {
      id: 'contactCardForm',
      defaultValues: {
        ...omit(initialVcardData, 'businessAddress'),
        ...pick(initialVcardData.businessAddress, ADDRESS_FIELDS)
      },
      formControls: [
        {
          type: 'controlgroup',
          name: 'group1',
          containerClassName: 'flex-row',
          formControls: [
            {
              autoComplete: 'first-name',
              type: 'text',
              name: 'firstName',
              label: 'Your Name',
              placeholder: 'First Name',
              containerClassName: 'basis-1/2'
            },
            {
              autoComplete: 'last-name',
              type: 'text',
              name: 'lastName',
              label: '',
              placeholder: 'Last Name',
              containerClassName: 'basis-1/2 self-end'
            }
          ]
        },
        {
          autoComplete: 'email',
          type: 'email',
          name: 'email',
          label: 'Email Address'
        },
        {
          autoComplete: 'phone-number',
          type: 'tel',
          name: 'phoneNumber',
          label: 'Phone Number'
        },
        {
          type: 'text',
          name: 'businessName',
          label: 'Business Name'
        },
        {
          name: 'addressLine1',
          label: 'Business Address',
          placeholder: 'Street / P.O. Box',
          type: 'text'
        },
        {
          name: 'addressLine2',
          label: '',
          placeholder: 'Apt / Suite #',
          type: 'text'
        },
        {
          type: 'text',
          name: 'city',
          label: '',
          placeholder: 'City'
        },
        {
          type: 'controlgroup',
          name: 'group2',
          containerClassName: 'flex-wrap',
          formControls: [
            {
              type: 'text',
              name: 'stateProvince',
              label: '',
              placeholder: 'State/Province',
              containerClassName: 'flex-grow'
            },
            {
              type: 'text',
              name: 'postal',
              label: '',
              placeholder: 'Postal Code'
            }
          ]
        }

      ],
      onSubmit: function (data) {
        const updateData = {
          ...omit(data, ADDRESS_FIELDS),
          businessAddress: pick(data, ADDRESS_FIELDS)
        }
        updateVcardData(updateData)
      },
      validationSuite
    }
  }, [initialVcardData, updateVcardData])

  useEffect(() => {
    if (!initialVcardData) {
      vcardData()
    }
  }, [initialVcardData, vcardData])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton
          disabled={!formProps}
          form='contactCardForm'
          title='Save'
          type='submit'
             />}
        start={<PanelHeaderBackButton />}
        title='Your Contact Card'
      />
      <PanelContent className='p-4'>
        {formProps
          ? (
            <>
              <Form ref={formRef} {...formProps} />
              <div className='flex flex-row gap-4 items-center'>
                <Button
                  className='flex-grow md:flex-none'
                  form='contactCardForm'
                  size='sm'
                  type='submit'
                >
                  Save
                </Button>
              </div>
            </>
            )
          : <LoadingIndicator className='mx-auto' />}
      </PanelContent>
    </Panel>
  )
}

ContactCard.displayName = 'ContactCard'
ContactCard.propTypes = propTypes
ContactCard.defaultProps = defaultProps

export default ContactCard
