import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { add as addManagerService } from '../../../services/user-manager'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './AddAction.validations'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['AddAction'] })

const AddAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formDialogRef = useRef()

  const { call: addManager } = useService(addManagerService, {
    onReplyOk: useCallback(() => {
      bus.emit('managerAdded')
      onSuccess?.()
    }, [onSuccess])
  })

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleAddManager = useCallback(({ phoneNumber }) => {
    formDialogRef.current.close()
    addManager(phoneNumber)
  }, [addManager])

  return (
    <FormDialog
      ref={formDialogRef}
      defaultValues={{ phoneNumber: '' }}
      description='An Account Manager will have the ability to access your PB Account on your behalf and has full
                   access when managing your account.'
      formControls={[
        {
          name: 'phoneNumber',
          label: 'Phone Number',
          hint: 'Provide the Project Broadcast Phone Number of the person you would like to add.',
          type: 'tel'
        }
      ]}
      title='Add Account Manager'
      validationSuite={validationSuite}
      onSubmit={handleAddManager}
    />
  )
})

AddAction.displayName = 'AddAction'
AddAction.propTypes = propTypes
AddAction.defaultProps = defaultProps

export default AddAction
