import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import user from '../../../prop-types/shapes/user'

const propTypes = {
  user: PropType.shape(user).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SenderInfo'] })

const SenderInfo = ({ user }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='mb-10'>
      <p className='text-center'>
        <strong>
          {user.formattedName}
          {' - '}
          {user.formattedPhoneNumber}
        </strong>
        {' '}
        would like to send you text messages.
      </p>
    </div>
  )
}

SenderInfo.displayName = 'SenderInfo'
SenderInfo.propTypes = propTypes
SenderInfo.defaultProps = defaultProps

export default SenderInfo
