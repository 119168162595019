import { get as getAttachmentService } from '../services/attachment'

import useCurrentInstance from './useCurrentInstance'

const useCurrentAttachment = () => useCurrentInstance({
  resourceName: 'attachment',
  getService: getAttachmentService,
  replyAttrPath: 'model',
  notFoundRedirect: '/attachments'
})

export default useCurrentAttachment
