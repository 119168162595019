import { create, enforce, only, skipWhen, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  skipWhen(
    () => (data.existingPhoneNumber),
    () => {
      test('managedPhoneNumber', 'Phone Number is required', () => {
        enforce(data.managedPhoneNumber).isNotEmpty()
      })
    }
  )
})

export default validationSuite
