import PropType from 'prop-types'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import MessageQualityDialog from '../MessageQualityDialog'

const propTypes = {
  message: PropType.shape(messageShape).isRequired,
  variant: PropType.oneOf(['aiva', 'insights']).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['MessageQualityAction'] })

const MessageQualityAction = forwardRef(({ message, variant }, ref) => {
  const messageQualityDialogRef = useRef()

  useLogger({ log, lifecycle: false, tags: [message?._id] })

  useImperativeHandle(ref, () => ({
    activate () {
      messageQualityDialogRef.current.open(message)
    }
  }), [message])

  return (
    <MessageQualityDialog ref={messageQualityDialogRef} variant={variant} />
  )
})

MessageQualityAction.displayName = 'MessageQualityAction'
MessageQualityAction.propTypes = propTypes
MessageQualityAction.defaultProps = defaultProps

export default MessageQualityAction
