// import PropType from 'prop-types'

import { pick } from 'lodash'
import { useCallback } from 'react'

import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import StyledQrCodeContent from '../../components/qr-codes/StyledQrCodeContent'
import useCurrentQrCode from '../../hooks/useCurrentQrCode'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateQrCode } from '../../services/qrCode'

import QrCodeDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['QrCodesDetail'] })

const QrCodesDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { qrCode, loading, setQrCode } = useCurrentQrCode()

  const handleDetailsUpdate = useCallback((updateReply) => {
    setQrCode(updateReply.json)
    bus.emit('qrCodeUpdated', updateReply.json)
  }, [setQrCode])

  if (loading || !qrCode) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader end={<QrCodeDetailHeaderMoreMenu qrCode={qrCode} />} start={<PanelHeaderBackButton />} title={qrCode.name} />
      <PanelContent className='p-5'>
        <NameDetailSection
          description="Name the QR Code so that it's easy to find later."
          object={qrCode}
          objectName='QR Code'
          service={updateQrCode}
          onChange={handleDetailsUpdate}
        />
        <FormDetailSection
          defaultValues={pick(qrCode, ['url'])}
          description='This url will be used to generate the QR Code.'
          formControls={[{ label: 'URL', name: 'url', type: 'url', required: true }]}
          formDescription={`Updating QR Code link for ${qrCode.url}`}
          formTitle='URL'
          objectId={qrCode._id}
          service={updateQrCode}
          title='URL'
          showEditIcon
          onChange={handleDetailsUpdate}
        >
          {qrCode.url}
        </FormDetailSection>
        <StyledQrCodeContent size='lg' url={qrCode.url} />

      </PanelContent>
    </Panel>
  )
}

QrCodesDetail.displayName = 'QrCodesDetail'
QrCodesDetail.propTypes = propTypes
QrCodesDetail.defaultProps = defaultProps

export default QrCodesDetail
