const propTypes = {}

const defaultProps = {}

const OldBrowserApp = () => {
  return (
    <div className='w-full h-full overflow-auto bg-neutral-50 p-5 flex flex-col items-center'>
      <div className='bg-white drop-shadow border border-neutral-200 px-6 py-10 w-full max-w-[800px] rounded-lg my-auto'>
        <div className='flex flex-col w-full items-center p-4'>
          <h1 className='mb-6 headline-md'>Oh no!</h1>
          <p className='text-center'>It looks like you're using an older browser that doesn't have the features required to run Project Broadcast. Please update your browser to the latest version.</p>
        </div>
      </div>
    </div>
  )
}

OldBrowserApp.displayName = 'OldBrowserApp'
OldBrowserApp.propTypes = propTypes
OldBrowserApp.defaultProps = defaultProps

export default OldBrowserApp
