import * as React from 'react'
import { memo } from 'react'
const SvgContest = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#BAE7FE" rx={4} />
        <path
            fill="#0384C6"
            fillRule="evenodd"
            d="M11.455 11.455V7.09a.545.545 0 1 1 1.09 0v4.364h4.364a.545.545 0 1 1 0 1.09h-4.363v4.364a.545.545 0 1 1-1.091 0v-4.363H7.09a.545.545 0 0 1 0-1.091h4.364Zm-3.978-4.75a.545.545 0 0 0-.772.772l1.177 1.176-1.177 1.176a.545.545 0 1 0 .772.772l1.176-1.177 1.176 1.177a.545.545 0 0 0 .772-.772L9.424 8.653l1.177-1.176a.545.545 0 0 0-.772-.772L8.653 7.882 7.477 6.705ZM14.17 13.4a.545.545 0 1 0-.771.772l1.176 1.176-1.177 1.176a.545.545 0 0 0 .772.772l1.176-1.177 1.176 1.177a.545.545 0 0 0 .772-.772l-1.177-1.176 1.177-1.176a.545.545 0 0 0-.772-.771l-1.176 1.176-1.176-1.177Zm.16-4.746a1.017 1.017 0 1 1 2.033 0 1.017 1.017 0 0 1-2.033 0Zm1.016-2.108a2.107 2.107 0 1 0 0 4.215 2.107 2.107 0 0 0 0-4.215Zm-7.71 8.802a1.017 1.017 0 1 1 2.032 0 1.017 1.017 0 0 1-2.033 0Zm1.016-2.107a2.107 2.107 0 1 0 0 4.215 2.107 2.107 0 0 0 0-4.215Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgContest)
export default Memo
