import * as React from "react";
import { memo } from "react";
const SvgArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.74 4.1v14.805l-3.802-4.318c-.4-.467-1.1-.5-1.567-.1-.45.4-.5 1.1-.1 1.567l5.785 6.569c.4.467 1.1.5 1.568.1.083-.067.133-.15.2-.233l5.685-6.47c.183-.216.283-.466.283-.733 0-.3-.133-.617-.383-.833-.467-.4-1.167-.367-1.567.1l-3.885 4.418V4.1c0-.617-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1h-.017Z"
    />
  </svg>
);
const Memo = memo(SvgArrowDown);
export default Memo;
