import * as React from "react";
import { memo } from "react";
const SvgSymbols = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.944 1.848c.72-1.141 2.378-1.117 3.096-.009l.004.008 4.672 7.363c.775 1.23-.15 2.782-1.556 2.782h-9.33c-1.406 0-2.35-1.534-1.554-2.786l4.668-7.358Zm5.9 8.144-4.349-6.854-4.35 6.854h8.7ZM6 13.992a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm16 4v-6h6v6h-6Zm-.2-8a1.8 1.8 0 0 0-1.8 1.8v6.4a1.8 1.8 0 0 0 1.8 1.8h6.4a1.8 1.8 0 0 0 1.8-1.8v-6.4a1.8 1.8 0 0 0-1.8-1.8h-6.4Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSymbols);
export default Memo;
