import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import ConfirmDialog from '../../components/dialog/ConfirmDialog'
import Form from '../../components/forms/Form'
import TermsLink from '../../components/links/TermsLink'
import LoadingIndicator from '../../components/LoadingIndicator'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useService from '../../hooks/useService'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateAddOns as updateAddOnsService } from '../../services/subscription'
import { addOns as addOnsService } from '../../services/user'
import { update as updateCurrentUser } from '../../store/actions/currentUser'

export const AddOns = () => {
  const currentUser = useCurrentUser()
  const formId = useId()
  const confirmDialogRef = useRef()
  const [addOns, setAddOns] = useState(null)
  const [submitData, setSubmitData] = useState(null)

  const { call: getAddOns } = useService(addOnsService, {
    onReplyOk: useCallback((reply) => setAddOns(reply.json), [])
  })

  const { call: updateAddOns } = useServiceAndAction(updateAddOnsService, updateCurrentUser, {
    onReplyOk: useCallback(() => {
      notify('Your add-ons have been updated.')
    }, [])
  })

  useEffect(() => {
    if (!addOns) {
      getAddOns()
    }
  }, [addOns, getAddOns])

  const handleConfirmSave = useCallback(() => {
    updateAddOns(submitData)
  }, [submitData, updateAddOns])

  const handleSubmit = useCallback((data) => {
    setSubmitData(data)
    confirmDialogRef.current.open()
  }, [])

  const disableSubmit = useMemo(() => {
    const firstAvailableOption = addOns?.find(({ availableInCurrentPlan, includedInCurrentPlan }) => {
      return availableInCurrentPlan && !includedInCurrentPlan
    })
    return !firstAvailableOption
  }, [addOns])

  const defaultValues = useMemo(() => {
    const values = {}
    if (currentUser?.addOns) {
      for (const addOnStripeId of currentUser.addOns) {
        values[addOnStripeId] = true
      }
    }
    return values
  }, [currentUser?.addOns])

  const formControls = useMemo(() => {
    const controls = []
    if (addOns) {
      for (const addOn of addOns) {
        const {
          stripeId,
          name,
          price,
          desc,
          frequency,
          availableInCurrentPlan,
          includedInCurrentPlan
        } = addOn

        controls.push({
          type: 'checkbox',
          name: stripeId,
          label: desc,
          title: `${name} (${includedInCurrentPlan ? 'Included' : `${price} ${frequency}`})`,
          disabled: !availableInCurrentPlan || includedInCurrentPlan
        })
      }
    }
    return controls
  }, [addOns])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton
          disabled={disableSubmit}
          form={formId}
          title='Save'
          type='submit'
             />}
        start={<PanelHeaderBackButton />}
        title='Add Ons'
      />
      <PanelContent className='p-5'>
        <div className='mb-4'>
          <p>
            Add ons are a great way to enhance your Project Broadcast
            experience. The below add ons unlock additional functionality in
            Project Broadcast and are added to your subscription. You will be
            billed for the selected add ons below each month in addition to your
            current subscription. Your first month will be prorated and appear
            on your next renewal.
          </p>
        </div>
        {formControls?.length === 0
          ? <LoadingIndicator className='mx-auto' />
          : (
            <>
              <Form
                defaultValues={defaultValues}
                formControls={formControls}
                id={formId}
                onSubmit={handleSubmit}
              />
              <Button
                className='w-fit'
                disabled={disableSubmit}
                form={formId}
                size='sm'
                type='submit'
              >
                Save
              </Button>
              <div />
              <ConfirmDialog
                ref={confirmDialogRef}
                confirmTitle='Confirm Change'
                description={(
                  <p className='mb-4'>
                    By continuing, you understand that Project Broadcast will charge
                    your card on file for your Add-On selections each month in addition to
                    your current subscription level.
                    {' '}
                    <strong>Refunds are not provided</strong>
                    . Complete
                    terms can be found
                    {' '}
                    <TermsLink label='here' />
                    {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
                  </p>
                )}
                title='Change Add-On Selection?'
                onConfirm={handleConfirmSave}
              />
            </>
            )}
      </PanelContent>
    </Panel>
  )
}

AddOns.displayName = 'AddOns'

export default AddOns
