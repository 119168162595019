import timeZoneFromJSON from '../models/timeZone'

import pb from './pb'

export function timeZones () {
  return pb.setupGet('timezones', {
    background: true,
    model: {
      conversion: timeZoneFromJSON,
      multiple: true
    }
  })
}
