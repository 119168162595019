import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { stateKeyFromContentType } from '../../../lib/folder'
import logger from '../../../lib/logger'
import templateShape from '../../../prop-types/shapes/template'
import { del as deleteService } from '../../../services/template'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  template: PropType.shape(templateShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ template, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [template?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    const stateKey = stateKeyFromContentType(template.contentType)
    success(`${template.name} was deleted`)
    bus.emit(`${stateKey}Deleted`, template)
    if (template.folderId) {
      bus.emit(`${stateKey}RemovedFromFolder`, template)
      bus.emit('foldersUpdated')
    }
    onSuccess?.()
  }, [template, onSuccess])
  const { call: deleteTemplate } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteTemplate([template._id], false)
  }, [template._id, deleteTemplate])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to delete this template? This can not be undone.'
      title={`Delete ${template.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
