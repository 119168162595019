import PropType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageQualityShape from '../../../prop-types/shapes/messageQuality'

import MessageQualityListItem from './MessageQualityListItem'
import MessageQualityRatingBar from './MessageQualityRatingBar'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape)
}

const defaultProps = {
  messageQuality: null
}

const log = logger({ enabled: false, tags: ['MessageQualityInsightsVariant'] })

const MessageQualityInsightsVariant = ({ messageQuality }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [insights, setInsights] = useState(null)

  useEffect(() => {
    if (messageQuality) {
      const cons = (messageQuality.cons || []).map((con) => con?.note)
      setInsights({ cons, pros: messageQuality.pros, score: messageQuality.scaledDeliverability })
    } else {
      setInsights(null)
    }
  }, [messageQuality])

  const ratingBar = useMemo(() => {
    if (!insights) { return null }
    return (
      <div className='mb-4'>
        <MessageQualityRatingBar className='mb-4' score={insights?.score} />
        <p className='paragraph-sm text-neutral-500'>This is our best guess to help you get an idea if your message has a good shot of reaching its recipients and being read. While we can not guarantee your message will be delivered, below are our thoughts!</p>
      </div>
    )
  }, [insights])

  const opportunities = useMemo(() => {
    if (!insights?.cons?.length) { return null }
    return (
      <div className='mb-4 rounded-lg p-6 bg-indigo-50'>
        <h4 className='label-md-strong'>Opportunities</h4>
        <p className='text-neutral-500 label-sm mb-4'>Below are some opportunities for improving your message.</p>
        <ul>
          {insights.cons.map((con) => (<MessageQualityListItem key={con} message={con} type='con' />))}
        </ul>
      </div>
    )
  }, [insights])

  const highlights = useMemo(() => {
    if (!insights?.pros?.length) { return null }
    return (
      <div className='rounded-lg p-6 bg-neutrals-50'>
        <h4 className='label-md-strong'>Highlights</h4>
        <p className='text-neutral-500 label-sm mb-4'>Your message has some great things going for it.</p>
        <ul>
          {insights.pros.map((pro) => (<MessageQualityListItem key={pro} message={pro} type='pro' />))}
        </ul>
      </div>
    )
  }, [insights])

  return (
    <>
      <h3 className='label-md-strong mb-4'>Message Rating</h3>
      {ratingBar}
      {opportunities}
      {highlights}
    </>
  )
}

MessageQualityInsightsVariant.displayName = 'MessageQualityInsightsVariant'
MessageQualityInsightsVariant.propTypes = propTypes
MessageQualityInsightsVariant.defaultProps = defaultProps

export default MessageQualityInsightsVariant
