import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Indicator'] })

const Indicator = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='absolute top-[18px] left-[2px] shrink-0 w-[12px] h-[12px] rounded-full bg-blue-700 border-[2px] border-white' />
  )
}

Indicator.displayName = 'Indicator'
Indicator.propTypes = propTypes
Indicator.defaultProps = defaultProps

export default Indicator
