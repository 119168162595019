import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import DetailSectionMoreButton from '../../../components/detail-sections/DetailSectionMoreButton'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import ListDialog from '../../../components/dialog/ListDialog'
import TagCreateAction from '../../../components/tags/actions/CreateAction'
import TagList from '../../../components/tags/TagList'
import TagListEmptySearchContent from '../../../components/tags/TagListEmptySearchResultsContent'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { formatName } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import { tagContacts } from '../../../services/scheduledBroadcast-contact'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired,
  state: PropType.oneOf(['sending', 'sent', 'unsent']).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: true, tags: ['ContactsByStateDetailSection'] })

const ContactsByStateDetailSection = ({ scheduledBroadcast, state, ...rest }) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast._id, state] })

  const [selectedTag, setSelectedTag] = useState(null)
  const applyTagDialogRef = useRef()
  const confirmApplyTagDialogRef = useRef()
  const tagCreateActionRef = useRef()

  const handleApplyTagToContactsReplyOk = useCallback(() => { success('Tag applied') }, [])
  const { call: tagContactsCall } = useService(tagContacts, { onReplyOk: handleApplyTagToContactsReplyOk })

  const handleApplyExistingTag = useCallback(() => { applyTagDialogRef.current.open() }, [])
  const handleApplyNewTag = useCallback(() => { tagCreateActionRef.current.activate() }, [])

  const handleSetSelectedTag = useCallback((tag) => {
    setSelectedTag(tag)
    confirmApplyTagDialogRef.current.open()
  }, [])

  const handleApplySelectedTag = useCallback(() => {
    tagContactsCall(scheduledBroadcast._id, { state, tag: selectedTag?.name })
  }, [tagContactsCall, selectedTag?.name, scheduledBroadcast, state])

  const { allowTagging, contacts, title, description } = useMemo(() => {
    let description
    switch (state) {
      case 'sending':
        description = 'When we are working to send your messages to your recipient list. You can monitor it here.'
        break
      case 'sent':
        description = 'As we send messages out they will transition from Sending to Sent.'
        break
      case 'unsent':
        description = 'Unfortunately, some messages fail. You can view those here.'
        break
    }

    return {
      allowTagging: state !== 'sending' && scheduledBroadcast.contactsSending.length === 0,
      contacts: scheduledBroadcast[`contacts${upperFirst(state)}`],
      description
    }
  }, [state, scheduledBroadcast])

  const addMissingTag = useCallback((newTagName) => {
    handleSetSelectedTag({ name: newTagName })
  }, [handleSetSelectedTag])

  return (
    <>
      <DetailSection
        count={contacts.length}
        description={description}
        editHref={`contacts/${state}`}
        editIcon='chevron'
        title={upperFirst(state)}
        showEditIcon
        {...rest}
      >
        {contacts.subset.map(formatName).join(', ')}
        {!!allowTagging && (
          <div className='grid place-content-center'>
            <ActionMenu placement='bottom-start' trigger={<DetailSectionMoreButton ariaSubject='Broadcast Contact' />}>
              <ActionMenuItem label='Apply Existing Tag' onClick={handleApplyExistingTag} />
              <ActionMenuItem label='Apply New Tag' onClick={handleApplyNewTag} />
            </ActionMenu>
            <TagCreateAction ref={tagCreateActionRef} mode='tag-name-only' onSuccess={handleSetSelectedTag} />
          </div>
        )}
      </DetailSection>
      <ListDialog
        ref={applyTagDialogRef}
        additionalContext={{ addMissingTag, contactCount: contacts.length }}
        EmptySearchResultsListContent={TagListEmptySearchContent}
        list={TagList}
        multiple={false}
        subtitle={title}
        title='Apply Tag'
        type='add'
        search
        onSubmitSelected={handleSetSelectedTag}
      />
      <ConfirmDialog
        ref={confirmApplyTagDialogRef}
        description={`Are you sure you want to apply the tag ${selectedTag?.name} to ${contacts.length} contact(s)?`}
        title='Apply Tag?'
        onConfirm={handleApplySelectedTag}
      />
    </>
  )
}

ContactsByStateDetailSection.displayName = 'ContactsByStateDetailSection'
ContactsByStateDetailSection.propTypes = propTypes
ContactsByStateDetailSection.defaultProps = defaultProps

export default ContactsByStateDetailSection
