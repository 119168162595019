import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import campaignShape from '../../prop-types/shapes/campaign'
import EventDateDialog from '../../screens/campaigns/detail/EventDateDialog'
import ContactList from '../contacts/ContactList'
import ListDialog from '../dialog/ListDialog'

const propTypes = {
  onSubmitAllInQuery: PropType.func.isRequired,
  onSubmitSelected: PropType.func.isRequired,
  campaign: PropType.shape(campaignShape),
  subtitle: PropType.string
}

const defaultProps = {
  campaign: null,
  subtitle: null
}

const log = logger({ enabled: false, tags: ['AddContactsDialog'] })

const AddContactsDialog = forwardRef(({
  subtitle,
  campaign,
  onSubmitAllInQuery,
  onSubmitSelected
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [selected, setSelected] = useState(null)
  const [query, setQuery] = useState(null)

  const addContactsDialogRef = useRef()
  const addEventDateDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    open () { addContactsDialogRef.current.open() }
  }), [])

  const handleAddAllInQuery = useCallback((query) => {
    if (campaign?.type === 'event') {
      setQuery(query)
      addEventDateDialogRef.current.open()
    } else {
      onSubmitAllInQuery(query)
    }
  }, [campaign?.type, onSubmitAllInQuery])

  const handleAddSelected = useCallback((selected) => {
    if (campaign?.type === 'event') {
      setSelected(selected)
      addEventDateDialogRef.current.open()
    } else {
      onSubmitSelected(selected)
    }
  }, [campaign?.type, onSubmitSelected])

  const handleEventDateSubmit = useCallback((data) => {
    addEventDateDialogRef.current.close()
    if (query) { onSubmitAllInQuery(query, data) } else { onSubmitSelected(selected, data) }
  }, [onSubmitAllInQuery, onSubmitSelected, query, selected])

  return (
    <>
      <ListDialog
        ref={addContactsDialogRef}
        list={ContactList}
        subtitle={subtitle}
        title='Add contacts'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
      <EventDateDialog
        ref={addEventDateDialogRef}
        onSubmit={handleEventDateSubmit}
      />
    </>
  )
})

AddContactsDialog.displayName = 'AddContactsDialog'
AddContactsDialog.propTypes = propTypes
AddContactsDialog.defaultProps = defaultProps

export default AddContactsDialog
