import * as React from "react";
import { memo } from "react";
const SvgGlobal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2c-.151 0-.425.081-.799.516-.371.432-.75 1.116-1.09 2.048-.348.959-.634 2.125-.828 3.436h5.434c-.194-1.31-.48-2.477-.828-3.436-.34-.932-.719-1.616-1.09-2.048C12.425 2.081 12.15 2 12 2ZM2.832 8a10.03 10.03 0 0 1 5.993-5.485c-.219.42-.416.878-.593 1.365-.427 1.174-.758 2.575-.969 4.12h-4.43ZM2.2 10a10.046 10.046 0 0 0 0 4h4.864a31.085 31.085 0 0 1 0-4H2.2Zm.632 6a10.03 10.03 0 0 0 5.993 5.485c-.219-.42-.416-.878-.593-1.365-.427-1.174-.758-2.575-.969-4.12h-4.43Zm12.343 5.485c.219-.42.416-.878.593-1.365.427-1.174.758-2.575.969-4.12h4.43a10.03 10.03 0 0 1-5.992 5.485ZM21.8 14a10.052 10.052 0 0 0 0-4h-4.864a31.114 31.114 0 0 1 0 4H21.8Zm-.632-6a10.03 10.03 0 0 0-5.993-5.485c.219.42.416.878.593 1.365.427 1.174.758 2.575.969 4.12h4.43ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm-1.889 19.436c-.348-.959-.634-2.125-.828-3.436h5.434c-.194 1.31-.48 2.477-.828 3.436-.34.932-.719 1.616-1.09 2.048-.374.435-.648.516-.799.516-.151 0-.425-.081-.799-.516-.371-.432-.75-1.116-1.09-2.048ZM9 12c0 .686.024 1.355.068 2h5.864a28.984 28.984 0 0 0 0-4H9.068A29.004 29.004 0 0 0 9 12Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGlobal);
export default Memo;
