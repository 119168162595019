import classNames from 'classnames'
import PropType from 'prop-types'
import { useRef } from 'react'

import useCurrentUser from '../../../../hooks/useCurrentUser'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import messageShape from '../../../../prop-types/shapes/message'

import AivaMessageHelperButton from './AivaMessageHelperButton'
import AttachImageButton from './AttachImageButton'
import ComposerSettingsButton from './ComposerSettingsButton.js'
import InsertMessageTemplateButton from './InsertMessageTemplateButton'
import MessageBarActionMenu from './MessageBarActionMenu'
import MessageInsightsButton from './MessageInsightsButton'
import ScheduleButton from './ScheduleButton'
import SendButton from './SendButton'

const propTypes = {
  message: PropType.shape(messageShape).isRequired,
  contentOptions: PropType.shape({
    emails: PropType.bool,
    messageTemplates: PropType.bool,
    voiceDrops: PropType.bool
  }),
  theme: PropType.oneOf(['chat', 'input', 'space', 'aiva']),
  onAivaRevision: PropType.func,
  onAttachmentSelect: PropType.func,
  onConfigurableFormSelect: PropType.func,
  onDynamicFieldSelect: PropType.func,
  onImageSelect: PropType.func,
  onLandingPageSelect: PropType.func,
  onMessageInsightsClick: PropType.func,
  onScheduleClick: PropType.func,
  onSendClick: PropType.func,
  onTemplateSelect: PropType.func,
  onTrackableLinkSelect: PropType.func,
  onVcardSelect: PropType.func
}

const defaultProps = {
  contentOptions: {
    emails: true,
    messageTemplates: true,
    voiceDrops: true
  },
  onAivaRevision: undefined,
  onAttachmentSelect: undefined,
  onConfigurableFormSelect: undefined,
  onDynamicFieldSelect: undefined,
  onImageSelect: undefined,
  onLandingPageSelect: undefined,
  onMessageInsightsClick: undefined,
  onScheduleClick: undefined,
  onSendClick: undefined,
  onTemplateSelect: undefined,
  onTrackableLinkSelect: undefined,
  onVcardSelect: undefined,
  theme: 'chat'
}

const log = logger({ enabled: true, tags: ['MessageBar'] })

const MessageBar = ({
  message,
  contentOptions,
  theme,
  onAivaRevision,
  onAttachmentSelect,
  onConfigurableFormSelect,
  onDynamicFieldSelect,
  onImageSelect,
  onLandingPageSelect,
  onMessageInsightsClick,
  onScheduleClick,
  onSendClick,
  onTemplateSelect,
  onTrackableLinkSelect,
  onVcardSelect
}) => {
  const businessToolsMenuRef = useRef()
  useLogger({ log, lifecycle: false, tags: [] })
  const { aivaSettings: { messageHelper: aivaMessageHelperEnabled } } = useCurrentUser()
  const showAivaMessageHelper = aivaMessageHelperEnabled || (theme === 'aiva')

  const subActionClassName = classNames(
    'flex flex-row flex-nowrap items-center gap-1',
    {
      'pl-2': theme !== 'space'
    }
  )

  return (
    <div className='flex flex-row flex-nowrap items-center text-black p-2'>
      <div className='flex flex-row flex-nowrap items-center self-start mr-auto'>
        <MessageBarActionMenu
          ref={businessToolsMenuRef}
          contentOptions={contentOptions}
          theme={theme}
          onAttachmentSelect={onAttachmentSelect}
          onConfigurableFormSelect={onConfigurableFormSelect}
          onDynamicFieldSelect={onDynamicFieldSelect}
          onEmailSelect={onTemplateSelect}
          onLandingPageSelect={onLandingPageSelect}
          onTrackableLinkSelect={onTrackableLinkSelect}
          onVcardSelect={onVcardSelect}
          onVoiceDropSelect={onTemplateSelect}
        />
        <div className={subActionClassName}>
          {showAivaMessageHelper
            ? <AivaMessageHelperButton message={message} onInsertRevision={onAivaRevision} />
            : null}
          {(theme !== 'space') && contentOptions.messageTemplates
            ? <InsertMessageTemplateButton disabled={theme === 'aiva'} onTemplateSelect={onTemplateSelect} />
            : null}
          <AttachImageButton disabled={theme === 'aiva'} onImageSelect={onImageSelect} />
          {!showAivaMessageHelper
            ? <MessageInsightsButton disabled={theme === 'aiva'} onClick={onMessageInsightsClick} />
            : null}
          {theme !== 'input'
            ? <ScheduleButton disabled={theme === 'aiva'} onClick={onScheduleClick} />
            : null}
        </div>
      </div>
      <div className='flex flex-row flex-nowrap items-center self-items-end ml-auto gap-2'>
        {(theme !== 'aiva')
          ? (
            <ComposerSettingsButton composerThemeInput={theme === 'input'} />
            )
          : null}
        {theme !== 'input'
          ? (
            <SendButton onClick={onSendClick} />
            )
          : null}
      </div>
    </div>
  )
}

MessageBar.displayName = 'MessageBar'
MessageBar.propTypes = propTypes
MessageBar.defaultProps = defaultProps

export default MessageBar
