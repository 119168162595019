import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import keywordShape from '../../prop-types/shapes/keyword'
import { Campaign, Rocket } from '../../svg/icons'
import { Keyword } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(keywordShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['KeywordListItemContent'] })

const KeywordListItemContent = ({ item }) => {
  useLogger({ log, lifecycle: true, tags: [item?._id] })

  const campaignNames = item?.campaigns.map((campaign) => campaign?.name).join(', ')
  const spaceNames = item?.spaces.map((space) => space?.name).join(', ')

  return (
    <>
      <Keyword className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {item.word}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Campaign className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={item?.campaigns.length} />
          <div className='line-clamp-1 break-words'>{campaignNames}</div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Rocket className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={item?.spaces.length} />
          <div className='line-clamp-1 break-words'>{spaceNames}</div>
        </div>
      </div>
    </>
  )
}

KeywordListItemContent.displayName = 'KeywordListItemContent'
KeywordListItemContent.propTypes = propTypes
KeywordListItemContent.defaultProps = defaultProps

export default KeywordListItemContent
