import PropType from 'prop-types'

import attachment from './attachment'
import campaign from './campaign'
import keyword from './keyword'
import landingPage from './landingPage'
import template from './template'
import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  shareId: PropType.string,
  shareOptions: PropType.shape({
    overwriteKeywords: PropType.bool,
    overwriteTemplates: PropType.bool
  }),
  user: PropType.oneOfType([
    PropType.string,
    PropType.shape(user)
  ]),
  campaigns: PropType.arrayOf(PropType.shape(campaign)),
  campaignsSummary: PropType.string,
  keywords: PropType.arrayOf(PropType.shape(keyword)),
  keywordsSummary: PropType.string,
  landingPages: PropType.arrayOf(PropType.shape(landingPage)),
  landingPagesSummary: PropType.string,
  attachments: PropType.arrayOf(PropType.shape(attachment)),
  attachmentsSummary: PropType.string,
  emails: PropType.arrayOf(PropType.shape(template)),
  emailsSummary: PropType.string,
  messageTemplates: PropType.arrayOf(PropType.shape(template)),
  messageTemplatesSummary: PropType.string,
  voiceDrops: PropType.arrayOf(PropType.shape(template)),
  voiceDropsSummary: PropType.string
}
