import * as React from "react";
import { memo } from "react";
const SvgAiva = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 175"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v175H0z" />
    <path fill="#EEF2FE" d="M-113 0h600v175h-600z" />
    <circle
      cx={186}
      cy={88}
      r={73.5}
      fill="#fff"
      stroke="#E1E7FF"
      strokeWidth={3}
    />
    <path
      fill="#A6B4FD"
      fillRule="evenodd"
      d="M280 38a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM72 67a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Zm0 11a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Zm8-3a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1Zm-12 1a1 1 0 1 0 0-2h-5a1 1 0 1 0 0 2h5ZM280 146a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm0 11a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm8-3a1 1 0 0 1-1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1Zm-12 1a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2h5ZM98.5 136a1 1 0 0 0-2 0v6.5H90a1 1 0 0 0 0 2h6.5v6.5a1 1 0 0 0 2 0v-6.5h6.5a1 1 0 0 0 0-2h-6.5V136ZM303.031 88.425a1 1 0 1 0-1.414-1.415l-4.596 4.597-4.597-4.597a1 1 0 1 0-1.414 1.415l4.597 4.596-4.597 4.596a1 1 0 0 0 1.415 1.414l4.596-4.596 4.596 4.596a1 1 0 1 0 1.414-1.414l-4.596-4.596 4.596-4.596ZM105 17a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm0 15a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm-5-5a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1Zm14 1a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2h5Zm-13.1 2.1a1 1 0 0 1 0 1.415l-3.264 3.263a1 1 0 0 1-1.414-1.414l3.264-3.264a1 1 0 0 1 1.414 0Zm10.878-9.464a1 1 0 1 0-1.414-1.414l-3.264 3.263a1 1 0 0 0 1.414 1.415l3.264-3.264ZM100.9 23.9a1 1 0 0 1-1.415 0l-3.263-3.264a1 1 0 1 1 1.414-1.414l3.264 3.264a1 1 0 0 1 0 1.414Zm9.464 10.878a1 1 0 1 0 1.414-1.414l-3.263-3.264a1.001 1.001 0 0 0-1.415 1.414l3.264 3.264Z"
      clipRule="evenodd"
    />
    <path
      fill="#6366F1"
      fillRule="evenodd"
      d="M224.633 84.132a4.422 4.422 0 0 1 1.942 3.045 42.367 42.367 0 0 1-.004 12.003 4.377 4.377 0 0 1-4.071 3.768c-.408.022-.816.028-1.224.019a80.915 80.915 0 0 1-.395 2.916c-.65 4.228-3.939 7.601-8.205 8.404-17.194 3.236-34.486 3.092-51.865-.074-4.263-.777-7.561-4.124-8.248-8.34a79.255 79.255 0 0 1-.42-2.903 13.914 13.914 0 0 1-.736-.024 4.334 4.334 0 0 1-4.045-3.775c-.498-3.96-.475-7.94.023-11.937.282-2.26 1.808-2.966 3.635-3.75 1.701-.732 1.977-2.86 2.214-4.684l.062-.472c.503-3.67 1.295-7.274 2.088-10.881.321-1.46.642-2.922.944-4.39a2.16 2.16 0 0 1 2.116-1.724h4.647c.965 0 1.813.641 2.077 1.57l2.388 8.407c12.546-1.944 25.326-1.955 38.354.056l2.405-8.463a2.158 2.158 0 0 1 2.077-1.57h4.648a2.16 2.16 0 0 1 2.115 1.725c.34 1.658.696 3.312 1.051 4.964.947 4.406 1.893 8.805 2.547 13.28.074.513.153 1.044.436 1.477.42.642 1.149.759 1.895.88.546.087 1.101.176 1.549.473Zm-14.969 26.537a8.704 8.704 0 0 0 7.04-7.195h.001a65.22 65.22 0 0 0-.007-20.62c-.577-3.6-3.346-6.447-6.919-7.165a123.321 123.321 0 0 0-8.518-1.401l-4.194 6.236c-.621.922-1.695 1.48-2.85 1.48h-14.98c-1.168 0-2.254-.572-2.869-1.51l-4.109-6.261c-2.916.37-5.819.854-8.707 1.452a8.717 8.717 0 0 0-6.825 7.037c-1.179 6.792-1.18 13.709.002 20.75a8.715 8.715 0 0 0 7.086 7.137c15.363 2.704 30.649 2.826 45.849.06Zm-40.325-25.585a11.761 11.761 0 0 0-3.265.002 2.795 2.795 0 0 0-2.378 2.38c-.536 3.803-.56 7.587-.017 11.351a2.789 2.789 0 0 0 2.395 2.369 12.57 12.57 0 0 0 3.231 0 2.794 2.794 0 0 0 2.389-2.333c.628-3.911.616-7.728.002-11.454a2.792 2.792 0 0 0-2.358-2.314l.001-.001Zm34.654.002a11.761 11.761 0 0 1 3.265-.002l-.001.001a2.792 2.792 0 0 1 2.358 2.314c.614 3.725.626 7.543-.003 11.454a2.793 2.793 0 0 1-2.389 2.333 12.562 12.562 0 0 1-3.23 0 2.789 2.789 0 0 1-2.395-2.369c-.544-3.764-.52-7.548.017-11.351a2.794 2.794 0 0 1 2.378-2.38Zm-22.194 15.079h10.728a1.467 1.467 0 0 1 1.373 1.979 7.184 7.184 0 0 1-13.474 0 1.467 1.467 0 0 1 1.373-1.979Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAiva);
export default Memo;
