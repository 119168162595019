import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { popStackEntry } from '../store/actions/stacks'

const log = logger({ enabled: false, tags: ['useNavigateInStack'] })

const useNavigateInStack = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigate = useNavigate()

  const navigateInStack = useCallback((path, { replace, ...otherOpts } = {}) => {
    navigate(path, { replace, ...otherOpts })
    if (replace) {
      // Also need to popStackEntry here so that the replaced route (often times the path to
      //  a detail screen of a deleted resource) will not be stuck in the stack. If allowed to
      //  stay it causes weird problems esp when stack is multiple levels deep. The user gets
      //  effectively trapped at a screen in the stack preventing further PanelHeaderBackButton
      //  navigation and the clicks just pop up "<Resource> not Found" toasts.
      popStackEntry()
    }
  }, [navigate])

  return navigateInStack
}

export default useNavigateInStack
