const propTypes = {}

const defaultProps = {}

const ManagerListEmptyContent = () => {
  return (<p className='flex flex-col justify-center min-h-[60px] text-center'>(no managers)</p>)
}

ManagerListEmptyContent.displayName = 'ManagerListEmptyContent'
ManagerListEmptyContent.propTypes = propTypes
ManagerListEmptyContent.defaultProps = defaultProps

export default ManagerListEmptyContent
