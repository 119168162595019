import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('phoneNumber', 'Phone number is required', () => {
    enforce(data.phoneNumber).isNotEmpty()
  })

  test('phoneNumber', 'Phone number is not valid', () => {
    enforce(data.phoneNumber.replace(/[^\d]/g, '')).matches(/^\d{10}$/)
  })
})

export default validationSuite
