import PropType from 'prop-types'

const propTypes = {
  message: PropType.string
}

const defaultProps = {
  message: null
}

const InputHint = ({ message }) => {
  if (!message) { return null }
  return <p className='label-sm text-neutral-400'>{message}</p>
}

InputHint.displayName = 'InputHint'
InputHint.propTypes = propTypes
InputHint.defaultProps = defaultProps

export default InputHint
