import AutolinkerLib from 'autolinker'
import PropType from 'prop-types'
import { createElement, memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {
  className: PropType.string,
  tagName: PropType.string,
  text: PropType.string,
  textSelectable: PropType.bool
}

const defaultProps = {
  className: '',
  tagName: 'div',
  text: '',
  textSelectable: false
}

const log = logger({ enabled: false, tags: ['Autolinker'] })

const Autolinker = memo(({ className, tagName, text, textSelectable, ...options }) => {
  useLogger({ log, lifecycle: false })
  const navigate = useNavigate()

  const html = useMemo(() => {
    let textToAutoLink = text

    // TODO: Find a better fix for this:
    if (/!\S*https:\/\//.test(text)) {
      // Fixes runtime exception when autolinker is incorrectly parsing exclamation point
      //    followed by url. There may be other scenarios (where a different character has
      //    the same effect. Seems to work for others I tried though, eg period, comma, etc).
      //    ADDITIONAL NOTE: exclamation seems to be culprit followed by any number of
      //                     "not a whitespace" characters run into protocol
      //    Ex: the following causes an exception
      //          testing!https://win.co
      //    It ends up seeing link as an "protocol relative match" (ie //win.co) because parser
      //      lumps the ! and https: together.
      //    Fix for now is to put a space in between them. This fix only works for most common
      //      case of https
      textToAutoLink = text.replace(/https:\/\//g, ' https://')
    }

    return AutolinkerLib.link(textToAutoLink, {
      className: `link ${textSelectable ? 'select-text' : ''}`,
      replaceFn: (match) => {
        if (match.getType() !== 'url') { return true }

        // Capture internal target urls
        // NOTE: provide a dummy baseurl (w/ https scheme) in case we got a protocol relative link
        const targetUrl = new URL(match.getAnchorHref(), 'https://app.projectbroadcast.com')
        const isInternalUrl = [
          'https://pb-ui.test',
          'https://dev.projectbroadcast.com',
          'https://staging.projectbroadcast.com',
          'https://app.projectbroadcast.com',
          process.env.REACT_APP_URL
        ].includes(targetUrl.origin)

        if (isInternalUrl) {
          const tag = match.buildTag()
          tag.setAttr('data-internal-url', targetUrl.pathname)
          return tag
        } else {
          return true
        }
      },
      sanitizeHtml: true,
      ...options
    })
  }, [options, text, textSelectable])

  const handleContainerClick = useCallback((event) => {
    const internalUrl = event.target?.getAttribute('data-internal-url')
    if (internalUrl) {
      event.preventDefault()
      navigate(internalUrl)
    }
  }, [navigate])

  return createElement(tagName, {
    className: `${className} ${textSelectable ? 'select-text' : ''}`,
    dangerouslySetInnerHTML: {
      __html: html
    },
    onClick: handleContainerClick
  })
})

Autolinker.displayName = 'Autolinker'
Autolinker.propTypes = propTypes
Autolinker.defaultProps = defaultProps

export default Autolinker
