import PropType from 'prop-types'
import { forwardRef, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import Select from './Select'
import TextArea from './TextArea'

const propTypes = {
  name: PropType.string.isRequired,
  autoFocus: PropType.bool,
  defaultValue: PropType.string,
  hint: PropType.string,
  label: PropType.string,
  maxRows: PropType.number,
  minRows: PropType.number,
  options: PropType.arrayOf(PropType.shape({
    label: PropType.string.isRequired,
    value: PropType.oneOfType([
      PropType.string,
      PropType.arrayOf(PropType.shape({
        label: PropType.string.isRequired,
        value: PropType.string.isRequired,
        disabled: PropType.bool
      }))
    ]).isRequired,
    disabled: PropType.bool
  })),
  placeholder: PropType.string,
  required: PropType.bool,
  onChange: PropType.func
}
const defaultProps = {
  autoFocus: false,
  defaultValue: null,
  hint: null,
  label: null,
  maxRows: undefined,
  minRows: undefined,
  onChange: () => {},
  options: [],
  placeholder: null,
  required: false
}

const log = logger({ enabled: false, tags: ['TextSample'] })

const TextSample = forwardRef(({
  autoFocus,
  defaultValue,
  hint,
  label,
  maxRows,
  minRows,
  name,
  options,
  placeholder,
  required,
  onChange,
  ...rest
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [sampleText, setSampleText] = useState()
  const [disabled, setDisabled] = useState(true)
  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form

  const handleSelectChange = useCallback((e) => {
    setSampleText(e.target.value)
    setDisabled(false)
    const shouldValidate = useFormMethods?.formState?.isSubmitted
    useFormMethods?.setValue(name, e.target.value, { shouldValidate })
  }, [name, useFormMethods])

  const handleTextAreaChange = useCallback((e) => {
    setSampleText(e.target.value)
    onChange(e)
  }, [onChange])

  return (
    <>
      <Select
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        label={label}
        name={`select-${name}`}
        options={options}
        placeholder='Select a Template'
        onChange={handleSelectChange}
      />
      <TextArea
        ref={ref}
        className='mt-2'
        disabled={disabled}
        emojiPicker={false}
        hint={hint}
        maxRows={maxRows}
        minRows={minRows}
        name={name}
        placeholder={placeholder}
        required={required}
        value={sampleText}
        onChange={handleTextAreaChange}
        {...rest}
      />
    </>
  )
})

TextSample.displayName = 'TextSample'
TextSample.propTypes = propTypes
TextSample.defaultProps = defaultProps

export default TextSample
