import * as React from "react";
import { memo } from "react";
const SvgVoiceDrop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width={24} height={24} fill="#DBEAFF" rx={4} />
    <path
      fill="#2563EC"
      fillRule="evenodd"
      d="M12 5.333c-.736 0-1.333.597-1.333 1.334V12a1.333 1.333 0 1 0 2.666 0V6.667c0-.737-.597-1.334-1.333-1.334ZM9.333 6.667a2.667 2.667 0 1 1 5.334 0V12a2.667 2.667 0 0 1-5.334 0V6.667ZM6.667 10c.368 0 .666.299.666.667V12A4.674 4.674 0 0 0 12 16.667 4.674 4.674 0 0 0 16.667 12v-1.333a.667.667 0 1 1 1.333 0V12a6.01 6.01 0 0 1-5.333 5.963v1.37a.667.667 0 0 1-1.334 0v-1.37A6.009 6.009 0 0 1 6 12v-1.333c0-.368.298-.667.667-.667Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgVoiceDrop);
export default Memo;
