import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { isCurrentlyManaging as isCurrentlyManagingService } from '../../../services/user'
import { PicturePortrait } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ManagingAccount'] })

const ManagingAccount = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { call: isCurrentlyManaging } = useService(isCurrentlyManagingService, { onReply: handleReply })

  const [managerName, setManagerName] = useState()

  function handleReply (data) {
    setManagerName(data?.json?.managingUserFullName)
  }

  useEffect(() => {
    isCurrentlyManaging()
  }, [isCurrentlyManaging])

  if (!managerName) { return null }

  const returnText = `Return to ${managerName}`

  return (
    <Link className='block w-[278px]' title={returnText} to='/logout'>
      <div className='bg-indigo-50 flex flex-row flex-nowrap items-center py-2 px-[12px]'>
        <div className='bg-indigo-400 rounded-lg shrink-0 w-[40px] h-[40px] flex flex-col items-center justify-center mr-3'>
          <div className='bg-indigo-50 rounded-full w-[32px] h-[32px] flex flex-col items-center justify-center'>
            <PicturePortrait className='text-indigo-700' height='20' width='20' />
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='label-sm-strong text-indigo-700 shrink-0'>Managing an Account</span>
          <span className='label-xs-medium text-indigo-400 line-clamp-1 break-all'>{returnText}</span>
        </div>
      </div>
    </Link>
  )
}

ManagingAccount.displayName = 'ManagingAccount'
ManagingAccount.propTypes = propTypes
ManagingAccount.defaultProps = defaultProps

export default ManagingAccount
