import { useCallback } from 'react'

import ContactsWithAddDetailSection from '../../components/detail-sections/ContactsWithAddDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentContest from '../../hooks/useCurrentContest'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateContestService } from '../../services/contest'
import { addAllInQuery, addSelected } from '../../services/contest-contact'

import ContestDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import WinnersDetail from './detail/WinnersDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContestsDetail'] })

const ContestsDetail = () => {
  useLogger({ log, lifecycle: false, tags: ['ContestsDetail'] })

  const { contest, setContest, loading } = useCurrentContest()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const updateContest = updateReply.model
    setContest(updateContest)
    bus.emit('contestUpdated', updateContest)
  }, [setContest])

  if (loading || !contest) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<ContestDetailHeaderMoreMenu contest={contest} />}
        start={<PanelHeaderBackButton />}
        title={contest.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          object={contest}
          objectName='contest'
          service={updateContestService}
          onChange={handleDetailsUpdate}
        />
        {contest.winners.length > 0 && (
          <WinnersDetail contest={contest} />
        )}
        <ContactsWithAddDetailSection
          addAllInQueryService={addAllInQuery}
          addSelectedService={addSelected}
          description='The participants in your contest are listed below.'
          nomenclature='Participants'
          object={contest}
          objectName='contest'
          onChange={handleDetailsUpdate}
        />
      </PanelContent>
    </Panel>
  )
}

ContestsDetail.displayName = 'ContestsDetail'
ContestsDetail.propTypes = propTypes
ContestsDetail.defaultProps = defaultProps

export default ContestsDetail
