import * as React from "react";
import { memo } from "react";
const SvgTrackableLinks = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 53 48"
    {...props}
  >
    <mask id="TrackableLinks_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
      clipRule="evenodd"
    />
    <path
      fill="#6B7380"
      d="m21.1 6.79-1.998 1.504.75.997H21.1v-2.5Zm-.6-.796-1.997 1.503L20.5 5.994ZM3.5 9A2.5 2.5 0 0 1 6 6.5v-5A7.5 7.5 0 0 0-1.5 9h5Zm0 2.79V9h-5v2.79h5Zm0 24.42V11.79h-5v24.42h5Zm0 2.79v-2.79h-5V39h5ZM6 41.5A2.5 2.5 0 0 1 3.5 39h-5A7.5 7.5 0 0 0 6 46.5v-5Zm36 0H6v5h36v-5Zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5v5a7.5 7.5 0 0 0 7.5-7.5h-5Zm0-27.21V39h5V11.79h-5ZM42 9.29a2.5 2.5 0 0 1 2.5 2.5h5a7.5 7.5 0 0 0-7.5-7.5v5Zm-20.9 0H42v-5H21.1v5Zm-2.597-1.793.6.797 3.995-3.007-.6-.797-3.995 3.007ZM16.505 6.5a2.5 2.5 0 0 1 1.998.997l3.995-3.007a7.5 7.5 0 0 0-5.993-2.99v5ZM6 6.5h10.505v-5H6v5Z"
      mask="url(#TrackableLinks_svg__a)"
    />
    <rect
      width={43.5}
      height={29.5}
      x={2.25}
      y={13.25}
      stroke="#6B7380"
      strokeWidth={2.5}
      rx={3.75}
    />
    <g clipPath="url(#TrackableLinks_svg__b)">
      <rect width={16} height={16} x={35} y={30} fill="#E1E7FF" rx={4} />
      <rect width={16} height={16} x={35} y={30} fill="#E1E7FF" rx={4} />
      <path
        fill="#5046E5"
        d="M43.394 39.833a2.251 2.251 0 0 0 1.573-.655l1.827-1.828c.434-.433.65-1 .65-1.572a2.22 2.22 0 0 0-3.794-1.572l-.611.61c-.172.184-.172.429 0 .59.194.172.422.166.589 0l.61-.611c.273-.267.629-.406.984-.406a1.383 1.383 0 0 1 1.39 1.389c0 .355-.14.711-.407.983l-1.833 1.828a1.362 1.362 0 0 1-.978.406c-.594.01-.855-.317-1.177-.64a.42.42 0 0 0-.59 0 .422.422 0 0 0-.044.54c.456.588 1.034.933 1.811.938Zm-2.616 2.612c.572 0 1.139-.217 1.572-.65l.611-.612c.172-.183.172-.427 0-.588-.194-.173-.422-.167-.589 0l-.61.61a1.407 1.407 0 0 1-.984.406c-.356 0-.711-.139-.984-.405a1.407 1.407 0 0 1-.405-.984c0-.355.139-.71.405-.983l1.834-1.828c.266-.272.622-.405.977-.405.706 0 .917.377 1.178.639a.42.42 0 0 0 .59 0c.227-.228.083-.545-.195-.823-.434-.433-1-.65-1.572-.655a2.25 2.25 0 0 0-1.573.655l-1.828 1.828c-.433.433-.65 1-.65 1.572a2.222 2.222 0 0 0 2.223 2.223Z"
      />
    </g>
    <rect
      width={18}
      height={18}
      x={34}
      y={29}
      stroke="#6B7380"
      strokeWidth={2}
      rx={5}
    />
    <defs>
      <clipPath id="TrackableLinks_svg__b">
        <rect width={16} height={16} x={35} y={30} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTrackableLinks);
export default Memo;
