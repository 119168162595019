import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { backInStack, setStackBackButtonLocation } from '../store/actions/stacks'

function useStackBackButton () {
  const location = useLocation()

  useEffect(() => {
    setStackBackButtonLocation(location)
    return () => {
      setStackBackButtonLocation(null)
    }
  }, [location])

  function handleBackButtonClick () {
    backInStack(location)
  }

  return { handleBackButtonClick }
}

export default useStackBackButton
