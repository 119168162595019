import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import * as vendorBadges from '../../svg/vendor/badges'

function Vendor () {
  const elements = Object.entries(vendorBadges).map(([key, Value]) => {
    return (
      <div key={key} className='m-4 flex flex-col flex-wrap items-center'>
        <div className='w-[200px] h-[75px]'><Value /></div>
        <p className='label-xs mt-1'>{key}</p>
      </div>
    )
  })
  return (
    <Panel>
      <PanelHeader title='Vendor Badges' />
      <PanelContent>
        <div className='mx-5 flex flex-row justify-around'>
          {elements}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Vendor
