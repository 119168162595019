import { useCallback, useRef, useState } from 'react'

import List from '../../../components/list/List'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import logger from '../../../lib/logger'
import { auditHistory as auditHistorySearch } from '../../../services/admin'
import { Reload } from '../../../svg/icons'

import AuditHistoryItemContent from './list/AuditHistoryItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AuditHistory'] })

const AuditHistory = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()
  const { userId, loading, user } = useSelectedUser()

  const handleRefreshSelected = useCallback(() => {
    listRef.current.reload()
  }, [])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !user) { return <PanelLoading subtitle=' ' title='Audit History' /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<PanelHeaderButton icon={<Reload />} onClick={handleRefreshSelected} />}
        start={<PanelHeaderBackButton />}
        subtitle={`${user.formattedName}: ${user.carrierName} ${user.formattedPhoneNumber}`}
        title='Audit History'
      />
      <PanelContent>
        <List
          ref={listRef}
          ListItemContent={AuditHistoryItemContent}
          queryOverrides={{ userId }}
          resultsKey='audits'
          searchService={auditHistorySearch}
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

AuditHistory.displayName = 'AuditHistory'
AuditHistory.propTypes = propTypes
AuditHistory.defaultProps = defaultProps

export default AuditHistory
