import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import { search as landingPageSearch } from '../../services/landingPage'
import List from '../list/List'

import LandingPageListItemContent from './LandingPageListItemContent'

const landingPageResultsFields = [
  '_id',
  'slug', // utilized by the message composer
  'name',
  'description',
  'updatedAt'
]

const sortList = [
  { key: 'modified', label: 'Most Recently Updated', default: true },
  { key: 'newest', label: 'Most Recently Created' },
  { key: 'name', label: 'Name' }
]

const propTypes = {
  additionalHandlers: PropType.func,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func,
  sortable: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  additionalHandlers: () => {},
  queryOverrides: {},
  searchService: landingPageSearch,
  sortable: false,
  sortList
}

const log = logger({ enabled: false, tags: ['LandingPageList'] })

const LandingPageList = forwardRef(({
  additionalHandlers,
  queryOverrides,
  searchService,
  sortable,
  sortList,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('landingPageCreated', handleBusEvent)
  useBus('landingPageCloned', handleBusEvent)
  useBus('landingPageUpdated', handleBusEvent)
  useBus('landingPageDeleted', handleBusEvent)
  useBus('landingPagesDeleted', handleBusEvent)

  additionalHandlers?.('landingPage', ref)

  return (
    <List
      ref={ref}
      ListItemContent={LandingPageListItemContent}
      placeholder='Search Landing Pages'
      queryOverrides={queryOverrides}
      resultsFields={landingPageResultsFields}
      resultsKey='landingPages'
      searchService={searchService}
      sortable={sortable}
      sortList={sortList}
      search
      {...listProps}
    />
  )
})

LandingPageList.displayName = 'LandingPageList'
LandingPageList.propTypes = propTypes
LandingPageList.defaultProps = defaultProps

export default LandingPageList
