import PropType from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useWizard } from 'react-use-wizard'

import Banner from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { entityTypeDisplayName, entityTypes } from '../../../lib/tcr'

import validationSuite from './UseCaseDetails.validations'

const propTypes = {
  dataRef: PropType.shape({
    current: PropType.shape({
      companyName: PropType.string,
      displayName: PropType.string,
      entityType: PropType.oneOf(Object.keys(entityTypes)),
      firstName: PropType.string,
      lastName: PropType.string
    }).isRequired
  }).isRequired,
  dataSamplesRef: PropType.shape({
    current: PropType.shape({
      sampleBusinessDescriptions: PropType.arrayOf(PropType.shape({
        label: PropType.string.isRequired,
        value: PropType.string.isRequired
      })),
      sampleMessageFlows: PropType.arrayOf(PropType.shape({
        label: PropType.string.isRequired,
        value: PropType.string.isRequired
      })),
      sampleMessages: PropType.arrayOf(PropType.shape({
        label: PropType.string.isRequired,
        value: PropType.string.isRequired
      }))
    }).isRequired
  }).isRequired,
  formRef: PropType.shape({
    current: PropType.shape({
      getValues: PropType.func.isRequired
    }).isRequired
  }).isRequired,
  onBack: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UseCaseDetails'] })

function personalizeSamples (samples, identity) {
  return (samples || []).map(({ label, value }) => ({
    label,
    value: value.replace(/\${identity}/g, identity)
  }))
}

const UseCaseDetails = ({ dataRef, dataSamplesRef, formRef, onBack, onSubmit }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { entityType, displayName, companyName, firstName } = dataRef.current
  const { previousStep } = useWizard()
  dataRef.current.displayName = displayName || companyName || firstName
  const [messageDisplayName, setMessageDisplayName] = useState(dataRef.current.displayName)
  const { sampleBusinessDescriptions, sampleMessageFlows, sampleMessages } = dataSamplesRef.current

  const personalizedSampleMessages = useMemo(() => {
    return personalizeSamples(sampleMessages, messageDisplayName)
  }, [messageDisplayName, sampleMessages])

  const personalizedSampleMessageFlows = useMemo(() => {
    return personalizeSamples(sampleMessageFlows, messageDisplayName)
  }, [messageDisplayName, sampleMessageFlows])

  const personalizedSampleBusinessDescriptions = useMemo(() => {
    return personalizeSamples(sampleBusinessDescriptions, messageDisplayName)
  }, [messageDisplayName, sampleBusinessDescriptions])

  const handleBack = useCallback(() => {
    const values = formRef.current.getValues()
    onBack(values)
    previousStep()
  }, [formRef, onBack, previousStep])

  const formControls = [
    {
      name: 'displayName',
      label: 'Messaging Display Name',
      placeholder: 'Enter your messaging display name',
      hint: 'How you identify in messaging (ex: Jake with Project Broadcast)',
      type: 'text',
      required: true,
      onChange: (e) => {
        setMessageDisplayName(e.target.value)
      }
    },
    {
      name: 'description-hints',
      type: 'passthrough',
      element: (
        <>
          <p className='block label-md-strong mb-1'>Describe Business and Messaging</p>
          <p className='mb-5'>
            Please describe your business and who you will be messaging and why.
          </p>
        </>
      )
    },
    {
      type: 'textsample',
      name: 'description',
      label: 'Description',
      placeholder: 'Please start with one of our predefined templates above',
      minRows: 2,
      maxRows: 9,
      options: personalizedSampleBusinessDescriptions
    },
    {
      name: 'message-flow-hints',
      type: 'passthrough',
      element: (
        <>
          <p className='block label-md-strong mb-1'>Describe Contact Opt-in</p>
          <p className='mb-5'>
            Tell us how a recipient provides consent to receive messages from you. If opt-in is collected through a paper form, please provide a hosted link to an image of opt-in.
          </p>
          <Banner type='warning'>
            Opt-in Summary must include a SMS disclosure statement otherwise the major carriers will decline your registration.
            <br />
            <br />
            Example statement:
            {' '}
            <strong>During opt-in it is stated that they can opt-out at any time by replying Stop, they can reply Help for help and that message frequency may vary and message and data rates may apply.</strong>
          </Banner>
        </>
      )
    },
    {
      type: 'textsample',
      name: 'messageFlow',
      label: 'Opt-In Summary',
      placeholder: 'Please start with one of our predefined templates above',
      emojiPicker: false,
      minRows: 2,
      maxRows: 9,
      options: personalizedSampleMessageFlows
    },
    {
      name: 'sample-message-hints',
      type: 'passthrough',
      element: (
        <>
          <p className='block label-md-strong mb-1'>Messaging Samples</p>
          <p className='mb-5'>
            Please provide samples of messaging you plan to send.
          </p>
          <Banner type='warning'>
            Each sample message must include the identity of the sender
            {' '}
            <strong>
              {messageDisplayName ? `(e.g. ${messageDisplayName}) ` : ''}
            </strong>
            and opt-out language at the end
            {' '}
            <strong>(e.g. reply Stop to unsubscribe)</strong>
            {' '}
            otherwise the major carriers will decline your registration.
          </Banner>
        </>
      )
    },
    {
      type: 'textsample',
      name: 'sample1',
      label: 'Sample 1',
      placeholder: 'Please start with one of our predefined templates above',
      emojiPicker: false,
      minRows: 2,
      maxRows: 9,
      options: personalizedSampleMessages
    },
    {
      type: 'textsample',
      name: 'sample2',
      label: 'Sample 2',
      placeholder: 'Please start with one of our predefined templates above',
      emojiPicker: false,
      minRows: 2,
      maxRows: 9,
      options: personalizedSampleMessages
    },
    entityType !== 'SOLE_PROPRIETOR' && {
      name: 'online-presence',
      type: 'passthrough',
      element: (
        <>
          <p className='block label-md-strong mb-1'>Online Presence</p>
          <p className='mb-5'>
            Please provide either a link to your website or social media page (Facebook, Instagram, or LinkedIn) and a link to your privacy policy statement.
          </p>
          <Banner className='mb-5' type='warning'>
            To be approved by the major carriers your website or social media page must have a privacy policy statement that clearly states your contacts' personal info will not be shared or sold to third parties for the purpose of marketing.
            <br />
            <br />
            Example statement:
            {' '}
            <strong>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes.</strong>
            <br />
            <br />
            If you do not have a website, you can provide a link to a Facebook page, LinkedIn or Instagram. Your privacy policy statement can be in the "about" section on Facebook or LinkedIn. It can also be a pinned image on your Instagram page.
          </Banner>
        </>
      )
    },
    entityType !== 'SOLE_PROPRIETOR' && {
      name: 'socialMediaLink',
      label: 'Social Media Link',
      type: 'url',
      placeholder: 'Enter a link to your social media page'
    },
    entityType !== 'SOLE_PROPRIETOR' && {
      name: 'websiteLink',
      label: 'Website Link',
      type: 'url',
      placeholder: 'Enter a link to your website'
    },
    entityType !== 'SOLE_PROPRIETOR' && {
      name: 'privacyPolicyLink',
      label: 'Privacy Policy Link',
      type: 'url',
      placeholder: 'Enter a link to your privacy policy statement'
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'mobile-phone-hints',
      type: 'passthrough',
      element: (
        <>
          <p className='block label-md-strong mb-1'>Mobile Phone Verification</p>
          <p className='mb-5'>
            Please provide your 10-digit personal cell phone number to be used to verify your identity via SMS.
          </p>
        </>
      )
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'mobilePhone',
      label: 'Personal Cell Phone Number',
      placeholder: 'Enter your personal cell phone number',
      type: 'tel',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'uiWarning',
      type: 'passthrough',
      element: (
        <Banner className='mb-5' type='warning'>
          Please ensure you have access to this mobile phone before proceeding. A verification message will be sent from The Campaign Registry&trade; (TCR) upon submission of your information. Please reply YES to the message to complete the verification process.
        </Banner>
      )
    }
  ].filter((c) => c)

  if (!entityType) {
    return null
  }

  return (
    <>
      <PanelHeader
        start={<PanelHeaderButton icon='back' onClick={handleBack} />}
        subtitle={entityTypeDisplayName(entityType)}
        title='TCR Use-Case Details'
      />
      <PanelContent className='p-5'>
        <div className='w-full md:max-w-md mx-auto'>
          <Form
            ref={formRef}
            defaultValues={dataRef.current}
            formControls={formControls}
            id='tcr-BusinessDetails'
            validationSuite={validationSuite}
            preventMultipleSubmits
            onSubmit={onSubmit}
          />
          <div className='flex flex-row gap-4 items-center'>
            <Button form='tcr-BusinessDetails' size='sm' type='submit'>Submit</Button>
          </div>
        </div>
      </PanelContent>
    </>
  )
}

UseCaseDetails.displayName = 'UseCaseDetails'
UseCaseDetails.propTypes = propTypes
UseCaseDetails.defaultProps = defaultProps

export default UseCaseDetails
