import PropType from 'prop-types'
import { useCallback, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { needsTcrInfo } from '../helpers/user-tcr'
import useCurrentUser from '../hooks/useCurrentUser'
import useLogger from '../hooks/useLogger'
import usePartnerRequest from '../hooks/usePartnerRequest'
import useService from '../hooks/useService'
import logger from '../lib/logger'
import { getPreference, setPreference } from '../lib/preferences'
import { isCurrentlyManaging as isCurrentlyManagingService } from '../services/user'
import { setRequestedLocation } from '../store/actions/currentUser'

const propTypes = {
  children: PropType.node.isRequired,
  adminOnly: PropType.bool,
  allowCancelled: PropType.bool
}

const defaultProps = {
  adminOnly: false,
  allowCancelled: false
}

const getIgnoreComplianceUntilDate = async () => {
  const value = await getPreference('ignoreComplianceUntil')
  // preference value is returned as a string
  if (!value) { return 0 }
  return parseInt(value, 10)
}

const log = logger({ enabled: true, tags: ['RequireAuth'] })

const RequireAuth = ({ adminOnly, allowCancelled, children }) => {
  useLogger({ log, lifecycle: true })

  const {
    connectUrl,
    verificationComplete,
    verified
  } = usePartnerRequest()

  const currentUser = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()

  const handleIsCurrentlyManagingReplyOk = useCallback((reply) => {
    if (reply.json.mangingUserSuperuser === true) {
      log.debug('needs provisioning, but currently managing')
    } else {
      log.debug('needs provisioning')
      navigate('/select-phone-number')
    }
  }, [navigate])
  const { call: isCurrentlyManaging } = useService(isCurrentlyManagingService, { onReply: handleIsCurrentlyManagingReplyOk })

  useEffect(() => {
    if (currentUser?.phoneNumberType === 'needs-provisioning') {
      isCurrentlyManaging()
    }
  }, [currentUser?.phoneNumberType, isCurrentlyManaging])

  useEffect(() => {
    log.debug('currentUser', currentUser?._id)
    log.debug('location', location?.pathname)
    if (!currentUser) { setRequestedLocation(location) }
  }, [currentUser, location])

  useEffect(() => {
    if (!currentUser) { return }
    const checkCompliance = async () => {
      if (!currentUser.superuser && needsTcrInfo(currentUser) && location?.pathname === '/chats') {
        log.debug('needs TCR info')
        const date = await getIgnoreComplianceUntilDate()
        log.debug('compliance ignore date', date)
        if (date < Date.now()) {
          const ignorePeriod = 12 * 60 * 60 * 1000
          const nextComplianceCheck = Date.now() + ignorePeriod
          // preference values are stored as strings
          setPreference('ignoreComplianceUntil', nextComplianceCheck.toString())
          log.debug('navigating to TCR')
          navigate('/account/tcr')
        }
      }
    }
    checkCompliance()
  }, [currentUser, location?.pathname, navigate])

  if (currentUser) {
    if (adminOnly && !currentUser.superuser) {
      return <Navigate to='/' replace />
    }
    if (currentUser.cancelled && !allowCancelled) {
      return <Navigate to='/reactivate' replace />
    }

    return children
  } else if (verificationComplete) {
    const to = verified ? connectUrl : '/auth'
    return <Navigate to={to} replace />
  }
}

RequireAuth.displayName = 'RequireAuth'
RequireAuth.propTypes = propTypes
RequireAuth.defaultProps = defaultProps

export default RequireAuth
