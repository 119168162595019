import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import { eBrake as eBrakeService } from '../../../services/scheduledBroadcast'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['EBrakeAction'] })

const EBrakeAction = forwardRef(({ scheduledBroadcast, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${scheduledBroadcast.name} was E-Braked`)
    onSuccess?.()
  }, [scheduledBroadcast, onSuccess])
  const { call: eBrakeScheduledBroadcastCall } = useService(eBrakeService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    eBrakeScheduledBroadcastCall(scheduledBroadcast._id)
  }, [scheduledBroadcast._id, eBrakeScheduledBroadcastCall])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to apply the E-Brake to this scheduled broadcast? All queued messages will be skipped. This can not be undone.'
      title={`EBrake ${scheduledBroadcast.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

EBrakeAction.displayName = 'EBrakeAction'
EBrakeAction.propTypes = propTypes
EBrakeAction.defaultProps = defaultProps

export default EBrakeAction
