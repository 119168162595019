import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { LightBlub } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MessageInsightsButton'] })

const MessageInsightsButton = ({ ...props }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Button
      className='min-w-[32px]'
      icon={<LightBlub />}
      size='sm'
      theme='none'
      variant='none'
      {...props}
    >
      Message Insights
    </Button>
  )
}

MessageInsightsButton.displayName = 'MessageInsightsButton'
MessageInsightsButton.propTypes = propTypes
MessageInsightsButton.defaultProps = defaultProps

export default MessageInsightsButton
