import PropType from 'prop-types'

import messageTypes from '../helpers/messageTypes'

export const qualityPropTypes = {
  message: PropType.shape({
    text: PropType.string.isRequired
  })
}

export const sendPropTypes = {
  contactId: PropType.string,
  fromApi: PropType.bool,
  keyword: PropType.string, // keyword id
  mediaUrl: PropType.string, // added by `messageFieldsNormalizer` from message object
  runAt: PropType.string, // added by `messageFieldsNormalizer` from message object
  removeMedia: PropType.bool,
  spaceId: PropType.string,
  template: PropType.shape({ _id: PropType.string }),
  text: PropType.string, // added by `messageFieldsNormalizer` from message object
  type: PropType.oneOf(messageTypes), // added by `messageFieldsNormalizer` from message object
  upload: PropType.string  // added by `messageFieldsNormalizer` from message object
}
