import PropType from 'prop-types'

export default {
  __v: PropType.number,
  _id: PropType.string,
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  ip: PropType.string, // "127.0.0.1"
  userAgent: PropType.string, // "Mozilla/5.0 (Macintosh...",
  contact: PropType.shape({
    contactUser: PropType.string,
    firstName: PropType.string,
    lastName: PropType.string,
    phoneNumber: PropType.string
  })
}
