import { omit } from 'lodash'
import PropType from 'prop-types'

import campaignEventShape from '../prop-types/shapes/campaignEvent'

import { searchPropTypes as baseContactSearchPropTypes } from './contact.prop-types'

export const searchPropTypes = {
  campaignId: PropType.string.isRequired
}

export const createPropTypes = {
  ...omit(campaignEventShape, '_id')
}

export const updatePropTypes = {
  ...omit(campaignEventShape, '_id')
}

export const contactSearchPropTypes = {
  campaignId: PropType.string.isRequired,
  campaignEventId: PropType.string.isRequired,
  state: PropType.oneOf(['queued', 'processed']).isRequired,
  ...baseContactSearchPropTypes
}
