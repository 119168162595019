import * as React from "react";
import { memo } from "react";
const SvgImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-2.951 3l-8.26-10.614a1 1 0 0 0-1.578 0L3 17.09V6Zm.516 13.683A2.997 2.997 0 0 0 6 21h9.515L9 12.629l-5.485 7.054ZM17.551 23H6a5 5 0 0 1-5-5V6a5 5 0 0 1 5-5h12a5 5 0 0 1 5 5v12a5 5 0 0 1-5 5h-.449ZM16 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImage);
export default Memo;
