import { create, enforce, only, test } from 'vest'

import { checkNameUniqueness } from '../../../services/folder'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('name', 'Folder name is required', () => {
    enforce(data.name).isNotEmpty()
  })

  test('name', 'New name should be different than the current one', () => {
    enforce(data.name).notEquals(data.currentName)
  })

  test('name', 'A folder with this name already exists', async () => {
    const { json: existingFolderName } = await checkNameUniqueness(data.name, data.contentType).call()

    enforce(data.name).notMatches(existingFolderName)
  })
})

export default validationSuite
