import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chatShape from '../../../prop-types/shapes/chat'
import { bulkMarkAsUnread as bulkMarkAsUnreadService } from '../../../services/chat'
import { warning } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  chats: PropType.arrayOf(PropType.shape(chatShape)).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['BulkMarkAsUnread'] })

const BulkMarkAsUnread = forwardRef(({ chats }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    bus.emit('chatMarkedAsReadOrUnread')
    bus.emit('closeBulkEditDialog')
  }, [])
  const { call } = useService(bulkMarkAsUnreadService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!chats || !chats.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [chats])

  const handleConfirm = useCallback(() => {
    call(map(chats, '_id'))
  }, [call, chats])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to mark these as unread? This cannot be undone.'
      title='Mark as Unread?'
      onConfirm={handleConfirm}
    />
  )
})

BulkMarkAsUnread.displayName = 'BulkMarkAsUnread'
BulkMarkAsUnread.propTypes = propTypes
BulkMarkAsUnread.defaultProps = defaultProps

export default BulkMarkAsUnread
