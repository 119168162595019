import * as React from "react";
import { memo } from "react";
const SvgChats = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 313"
    {...props}
  >
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M187 255c-54.119 0-98-44.095-98-98.5S132.881 58 187 58s98 44.095 98 98.5-43.881 98.5-98 98.5Zm0 1c-54.676 0-99-44.548-99-99.5S132.324 57 187 57s99 44.548 99 99.5-44.324 99.5-99 99.5Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M187.5 285C116.531 285 59 227.469 59 156.5S116.531 28 187.5 28 316 85.531 316 156.5 258.469 285 187.5 285Zm0 1C115.979 286 58 228.021 58 156.5 58 84.98 115.979 27 187.5 27S317 84.98 317 156.5c0 71.521-57.979 129.5-129.5 129.5Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M187.5 312C101.62 312 32 242.38 32 156.5S101.62 1 187.5 1 343 70.62 343 156.5 273.38 312 187.5 312Zm0 1C101.067 313 31 242.933 31 156.5S101.067 0 187.5 0 344 70.067 344 156.5 273.933 313 187.5 313Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#F4DA92"
      d="M103.5 223C77.244 223 56 201.724 56 175.5c0-26.256 21.276-47.5 47.5-47.5 26.256 0 47.5 21.276 47.5 47.5.031 26.256-21.244 47.5-47.5 47.5Z"
    />
    <path
      fill="#FEE4BF"
      d="M118.478 192.251c-.959 4.704-7.169 7.49-17.627 3.288-12.832-5.206-17.764-19.865-17.764-19.865-.685-10.503-2.284-19.499-2.284-19.499 14.842-1.279 32.378 16.074 32.378 16.074l2.009 8.586c.183.73.548 1.37 1.096 1.826.959 1.644 2.832 6.576 2.192 9.59Z"
    />
    <path
      fill="#EFBDA5"
      d="M116.286 182.708c-8.722-.457-15.161-1.736-22.057-14.111 5.846 5.16 12.741 7.124 20.002 8.037l.959 4.201c.183.777.548 1.416 1.096 1.873Z"
    />
    <path
      fill="#FEE4BF"
      d="M134.325 127.679c.822 3.836 1.233 6.941-1.005 17.171-2.238 10.229 2.055 20.458-8.722 29.82-7.261 6.302-19.957 7.489-30.095.594-3.425-2.329-6.576-5.572-9.133-9.819a60.621 60.621 0 0 0-6.941-9.361c-3.608-3.928-7.946-11.828-5.8-25.802 3.47-22.742 25.984-25.345 39.273-22.833 13.289 2.511 20.185 10.092 22.423 20.23Z"
    />
    <path
      fill="#FEE4BF"
      d="M90.622 145.535s-1.416-6.85-8.083-6.028c-5.754.731-7.764 14.522 5.297 18.358"
    />
    <path
      fill="#423232"
      fillRule="evenodd"
      d="M85.23 144.193c.771.316 1.204.978 1.4 1.777.19.773.18 1.739.04 2.84-.064.668.198 1.205.513 1.604a3.212 3.212 0 0 0 .625.596l.002.001a.71.71 0 0 1-.767 1.195l.36-.563-.36.563-.003-.001-.003-.002-.007-.005-.022-.015a4.18 4.18 0 0 1-.294-.224 4.608 4.608 0 0 1-.645-.664c-.46-.582-.928-1.48-.812-2.635l.002-.019c.133-1.041.121-1.807-.008-2.333-.123-.501-.329-.707-.56-.802l-.006-.002c-.51-.213-.832-.192-1.032-.126-.207.069-.386.22-.54.452a2.452 2.452 0 0 0-.376 1.138.71.71 0 0 1-1.39.291 1.512 1.512 0 0 1-.022-.479c.013-.15.041-.324.085-.509a3.87 3.87 0 0 1 .52-1.227c.274-.413.686-.819 1.277-1.014.597-.198 1.278-.149 2.024.163Z"
      clipRule="evenodd"
    />
    <path
      fill="#EFBDA5"
      d="M122.269 156.038s1.735.091 3.379-1.873c.913-1.096 1.69-2.466 1.187-2.877-.502-.456-8.768.822-9.316 1.918s2.649 2.832 4.75 2.832Z"
    />
    <path
      fill="#423232"
      fillRule="evenodd"
      d="M124.349 140.678a.71.71 0 0 0-1.421.031l.711-.015-.711.015.001.002v.017l.002.045.008.16c.009.139.024.338.051.59a19.8 19.8 0 0 0 .349 2.097 23.365 23.365 0 0 0 2.561 6.606m-1.551-9.548v.006l.001.03.007.132c.007.117.021.295.045.525.05.46.145 1.128.324 1.945a21.96 21.96 0 0 0 2.405 6.202v.001c.787 1.369.538 3.079-.392 4.461-.94 1.397-2.606 2.528-4.824 2.852a.711.711 0 0 1-.206-1.406c1.846-.269 3.148-1.194 3.852-2.239.714-1.062.785-2.183.339-2.96"
      clipRule="evenodd"
    />
    <path
      fill="#F2A5B2"
      d="M98.887 158.367c2.598 0 4.704-1.738 4.704-3.882s-2.106-3.881-4.704-3.881c-2.597 0-4.703 1.737-4.703 3.881 0 2.144 2.106 3.882 4.703 3.882ZM132.589 150.695c-.137 2.558-.091 5.069-.274 7.581-2.009-.457-3.471-1.964-3.471-3.745 0-1.918 1.599-3.47 3.745-3.836Z"
      opacity={0.42}
    />
    <path
      fill="#423232"
      d="M107.077 145.28a1.887 1.887 0 0 1-1.827-2.055l.046-1.279a1.887 1.887 0 0 1 2.055-1.827 1.887 1.887 0 0 1 1.827 2.055l-.046 1.279c-.046 1.05-.959 1.872-2.055 1.827ZM126.303 145.965a1.836 1.836 0 0 1-1.736-1.918l.046-1.188a1.835 1.835 0 0 1 1.918-1.735 1.834 1.834 0 0 1 1.735 1.918l-.045 1.187a1.836 1.836 0 0 1-1.918 1.736Z"
    />
    <path
      fill="#423232"
      fillRule="evenodd"
      d="M108.376 139.921c1.194.186 2.12.766 2.735 1.28a6.471 6.471 0 0 1 .901.921 1.904 1.904 0 0 1 .068.09l.005.007.001.002.001.001.001.001-.548.385.548-.385a.71.71 0 0 1-1.161.819m-2.551-3.121c-.987-.16-2.008 0-2.829.335a4.014 4.014 0 0 0-1.105.657c-.306.264-.578.606-.696 1.021a.71.71 0 1 0 1.365.39c.019-.064.084-.185.259-.336.171-.147.413-.294.714-.417a3.973 3.973 0 0 1 2.067-.248l.004.001c.86.134 1.555.557 2.046.968a5.003 5.003 0 0 1 .69.703l.031.039a.038.038 0 0 0 .005.008v-.001M125.688 143.059a.71.71 0 0 1-1.42-.016h.71l-.71-.001v-.02l.001-.03a2.11 2.11 0 0 1 .395-1.095c.42-.583 1.18-1.026 2.415-1.026 1.108 0 1.895.516 2.386 1.007a3.944 3.944 0 0 1 .671.898l.037.071a.405.405 0 0 1 .015.032l.002.003v.001l.001.001-.633.29.633-.29a.711.711 0 0 1-1.29.595v-.001l-.002-.002a2.026 2.026 0 0 0-.094-.164 2.585 2.585 0 0 0-.344-.429c-.309-.309-.755-.591-1.382-.591-.865 0-1.156.288-1.261.434a.721.721 0 0 0-.129.326l-.001.007Z"
      clipRule="evenodd"
    />
    <path
      fill="#423232"
      d="M113.379 135.279c.959.319 1.918-.548 1.735-1.599-.091-.548-.319-1.096-.776-1.507-1.37-1.233-7.033-2.329-10.458-.73-3.014 1.461 5.115 2.374 9.499 3.836ZM127.033 136.009c-.776.274-1.598-.548-1.461-1.507.045-.502.228-1.004.593-1.37 1.096-1.096 4.841-1.963 7.216-.274 2.055 1.416-2.786 1.827-6.348 3.151Z"
    />
    <path
      fill="#423232"
      fillRule="evenodd"
      d="M108.897 157.939a.71.71 0 0 1 1 .096c1.013 1.228 2.641 2.272 4.342 2.811 1.718.544 3.351.528 4.452-.128a.71.71 0 0 1 .728 1.219c-1.597.953-3.705.866-5.609.263-1.921-.609-3.797-1.791-5.009-3.262a.709.709 0 0 1 .096-.999Z"
      clipRule="evenodd"
    />
    <path
      fill="#423232"
      d="M115.744 179.025c3.334-.867 6.302-2.374 8.722-4.475 10.778-9.316 6.485-19.591 8.723-29.82a91.458 91.458 0 0 0 1.415-7.901c-.365-.045-.776-.091-1.187-.182-1.644-.229-2.74-1.736-2.557-3.38.091-.685.137-1.37.182-2.1-.182-.046-.137-.046-.319-.046-.274.868-.594 1.781-.822 2.649-.411 1.37-1.736 2.237-3.106 2.146h-.045c-1.599-.137-2.786-1.461-2.786-3.06.046-1.141.046-2.283.091-3.425-.182-.045-.091 0-.274-.045a591.168 591.168 0 0 0-.685 3.607c-.274 1.507-1.598 2.558-3.105 2.466-3.927-.182-7.809-.228-11.325-.228-1.553 0-2.832-1.142-2.969-2.694-.228-2.01-.548-4.019-1.096-6.074-.593 1.827-.593 3.745-.502 5.708a2.994 2.994 0 0 1-2.923 3.151c-4.064.092-6.804.229-7.26.274.136 6.257-1.28 13.198-3.79 8.494-.184-.319-.32-.639-.503-1.05-1.05-1.872-3.151-4.11-7.124-3.608-5.572.731-7.627 13.655 4.064 17.947.046.959.274 1.918.685 2.923 1.781 4.338 2.557 7.946 2.557 10.914 0 17.217-28.176 10.047-36.122-5.571-3.06-6.074-3.105-13.38 2.694-21.052 14.203-17.399 9.956-10.778 13.107-21.098 5.16-16.897 16.942-22.286 32.56-22.149 0 0 25.984 0 32.88 6.302 6.896 6.302 4.521 25.985 3.927 27.766-.182.548-.639.867-1.141 1.096-.274 8.996.639 12.147 3.836 19.408 6.987 15.983-6.211 31.19-25.802 23.107Z"
    />
    <path
      fill="#FEE4BF"
      d="M90.125 144.136c-.183-.319-.32-.639-.502-1.05.182.365.365.731.502 1.05Z"
    />
    <path
      fill="#B7AEA9"
      d="M126.167 151.372s.548 3.105 4.155 4.658c.914.365 1.873-.228 1.873-1.233-.046 0-5.8-3.06-6.028-3.425ZM117.49 143.883s2.923-2.923 4.384-3.242l-3.882.913-.502 2.329ZM97.99 151.372s1.69 3.836 6.759 4.749c5.069.959 9.681-2.694 11.828-8.813 0 0-1.736 5.662-8.677 6.713-6.896 1.141-9.91-2.649-9.91-2.649Z"
      opacity={0.6}
    />
    <path
      fill="#DBC3ED"
      d="M106.165 130.867c-6.44 0-11.69 5.389-11.737 11.965 0 3.105 1.142 5.937 3.014 7.992l16.577-16.76a11.121 11.121 0 0 0-7.854-3.197Z"
      opacity={0.29}
    />
    <path
      fill="#C4ADD8"
      d="m114.019 134.064-16.577 16.76c2.101 2.329 5.069 3.745 8.357 3.745 6.348 0 11.554-5.252 11.737-11.782a11.736 11.736 0 0 0-3.517-8.723Z"
      opacity={0.23}
    />
    <path
      fill="#DBC3ED"
      d="M132.88 130.867c-4.887 0-8.997 5.389-9.179 11.965-.092 2.649.502 5.069 1.507 7.033l14.476-14.34c-1.507-2.831-3.973-4.658-6.804-4.658Z"
      opacity={0.29}
    />
    <path
      fill="#C4ADD8"
      d="m139.684 135.479-14.476 14.34c1.507 2.877 3.973 4.749 6.85 4.749 4.795 0 8.905-5.252 9.179-11.782.091-2.694-.503-5.252-1.553-7.307Z"
      opacity={0.23}
    />
    <path
      fill="#4EC2DD"
      fillRule="evenodd"
      d="M105.799 153.904c-5.933 0-10.705-4.91-10.706-11.069.002-.313.017-.622.043-.929a.703.703 0 0 0 .063-.56c.75-5.503 5.381-9.768 10.92-9.768 6.027 0 10.878 5.046 10.707 11.234l-.001.005c-.129 6.16-5.08 11.087-11.026 11.087Zm-12.127-11.077c.002-.188.007-.376.016-.563h-5.653a.71.71 0 1 1 0-1.42h5.803c.972-6.021 6.097-10.687 12.281-10.687 6.102 0 11.108 4.547 11.992 10.461l.116-.037a17.254 17.254 0 0 1 2.03-.514c.749-.136 1.587-.218 2.312-.1.264.042.535.117.788.236 1.077-5.953 5.244-10.3 9.936-10.017 5.2.315 9.075 6.197 8.655 13.14-.419 6.942-4.975 12.316-10.174 12.001-5.2-.314-9.075-6.197-8.655-13.139l.014-.207a.716.716 0 0 1-.108-.207c-.052-.161-.199-.327-.683-.405-.487-.079-1.136-.03-1.831.096-.682.124-1.352.312-1.856.471-.156.049-.295.095-.412.135.009.258.01.517.002.778-.146 6.9-5.697 12.475-12.446 12.475-6.762 0-12.127-5.591-12.127-12.492v-.005Zm46.753.407c-.193 3.182-1.331 5.945-2.943 7.846-1.611 1.901-3.614 2.864-5.617 2.743-2.004-.121-3.876-1.319-5.247-3.4-1.37-2.081-2.168-4.961-1.976-8.143.193-3.182 1.331-5.946 2.943-7.846 1.611-1.901 3.614-2.865 5.617-2.744 2.004.121 3.876 1.319 5.247 3.401 1.37 2.08 2.168 4.961 1.976 8.143Z"
      clipRule="evenodd"
    />
    <mask
      id="Chats_svg__a"
      width={94}
      height={94}
      x={56}
      y={130}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#F7C0B5"
        d="M103 224c-25.98 0-47-21.052-47-47 0-25.979 21.052-47 47-47 25.979 0 47 21.052 47 47 .031 25.979-21.021 47-47 47Z"
      />
    </mask>
    <g mask="url(#Chats_svg__a)">
      <path
        fill="#BEE3EB"
        d="M157.133 236.439H41.688s.685-21.189 7.626-36.305c5.252-11.416 17.628-17.764 26.852-21.052a103.079 103.079 0 0 1 6.805-2.101c0 .229.045.411.045.64 0 0 4.932 14.659 17.765 19.865 10.457 4.247 16.668 1.415 17.627-3.288.594-3.014-1.279-7.946-2.238-9.545-.548-.456-.913-1.141-1.096-1.826l-.365-1.507a22.64 22.64 0 0 0 2.512-.685c.274-.092.502-.183.776-.274 5.891 1.552 14.75 5.251 25.208 14.019 11.828 9.864 13.928 42.059 13.928 42.059Z"
      />
      <path
        fill="#fff"
        d="M96.808 202.236c-15.756-7.079-21.875-19.728-20.688-23.153 0 0 0-.046.046-.092.822-1.689 2.147-3.059 3.79-4.018.914-.548 1.918-1.051 2.74-1.325.046.64.229 2.649.275 3.334 0 .228.045.411.045.639 0 0 2.923 10.915 15.755 16.121 10.458 4.247 16.943 1.461 17.628-3.288.776-5.252-2.192-9.042-2.192-9.042.867-.183 2.237-.503 3.059-.777 2.01 2.421 4.065 4.841 4.841 11.234 1.096 9.179-8.996 17.719-25.3 10.367Z"
      />
    </g>
    <path
      fill="#B5E2DC"
      d="M273 224c27.638 0 50-22.395 50-50 0-27.638-22.395-50-50-50-27.638 0-50 22.395-50 50-.033 27.638 22.362 50 50 50Z"
    />
    <mask
      id="Chats_svg__b"
      width={100}
      height={100}
      x={223}
      y={124}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#B5E2DC"
        d="M273 224c27.638 0 50-22.395 50-50 0-27.638-22.395-50-50-50-27.638 0-50 22.395-50 50-.033 27.638 22.362 50 50 50Z"
      />
    </mask>
    <g mask="url(#Chats_svg__b)">
      <path
        fill="#AD827B"
        d="M234.829 213.258c.318.227.68.454.998.68.362.227.68.454 1.042.635.318.181.59.363.907.544.045.045.091.045.136.091a44.733 44.733 0 0 0 3.219 1.768c.317.181.68.362.997.498 0 0 .045.046.091.046l1.088.544c.725.362 1.496.68 2.221.997.363.181.771.317 1.133.453.363.136.771.318 1.134.454.362.136.77.317 1.133.453h.045c.363.136.771.272 1.134.408.816.272 1.677.544 2.493.816.272.091.589.181.861.272.862.272 1.723.499 2.584.68.318.091.635.136.998.227.816.181 1.677.362 2.493.544.181.045.317.045.499.09.272.046.589.091.861.136.408.046.771.136 1.179.182.181.045.362.045.544.09.226.046.498.091.77.091.726.091 1.496.181 2.222.272.226.045.498.045.725.091h.045c.318.045.635.045.952.09.318.046.635.046.952.046.182 0 .408.045.59.045.362 0 .68.045.997.045.453 0 .861.046 1.315.046h2.266c19.086-.272 36.312-8.387 48.552-21.262 0 0 0-.045.046-.045-1.406-2.765-6.619-7.752-12.059-11.877-6.891-5.214-16.637-8.432-16.637-8.432s-6.392-1.043-30.102 3.898c-18.088 3.808-25.794 19.267-28.424 26.384Z"
      />
      <path
        fill="#AD827B"
        d="M258.221 199.432c.952 4.669 7.117 7.435 17.499 3.264 12.738-5.168 17.634-19.72 17.634-19.72.68-10.427 2.267-19.357 2.267-19.357-14.733-1.27-32.141 15.957-32.141 15.957l-1.995 8.523c-.181.725-.544 1.36-1.088 1.813-.952 1.632-2.811 6.528-2.176 9.52Z"
      />
      <path
        fill="#8E5650"
        d="M260.397 189.957c8.659-.453 15.051-1.722 21.896-14.008-5.802 5.123-12.648 7.072-19.856 7.979l-.952 4.171c-.181.77-.544 1.405-1.088 1.858Z"
      />
      <path
        fill="#AD827B"
        d="M242.491 135.33c-.816 3.808-1.224 6.891.997 17.046 2.221 10.154-2.04 20.309 8.659 29.602 7.207 6.256 19.81 7.435 29.874.59 3.4-2.312 6.528-5.531 9.067-9.747a60.12 60.12 0 0 1 6.89-9.293c3.582-3.899 7.888-11.742 5.758-25.614-3.446-22.576-25.795-25.16-38.987-22.666-13.192 2.493-20.037 10.018-22.258 20.082Z"
      />
      <path
        fill="#AD827B"
        d="M285.875 153.056s1.405-6.8 8.024-5.984c5.712.725 7.706 14.416-5.259 18.224"
      />
      <path
        fill="#423232"
        fillRule="evenodd"
        d="M291.226 151.724c-.765.314-1.195.971-1.39 1.764-.188.768-.177 1.727-.038 2.82.063.662-.197 1.196-.51 1.591a3.164 3.164 0 0 1-.584.566l-.034.024-.005.003a.705.705 0 0 0 .762 1.187l-.371-.582c.371.582.372.582.372.581l.002-.001.002-.002.008-.004.022-.015.069-.049a4.57 4.57 0 0 0 .863-.833c.457-.578.921-1.469.806-2.616l-.002-.019c-.132-1.033-.12-1.793.008-2.315.122-.498.326-.702.557-.796l.005-.002c.505-.212.826-.191 1.024-.125.205.068.384.218.536.448a2.446 2.446 0 0 1 .374 1.13.705.705 0 0 0 1.379.289c.042-.168.033-.354.022-.475a3.877 3.877 0 0 0-.6-1.724c-.273-.411-.681-.813-1.268-1.007-.592-.196-1.269-.147-2.009.162Z"
        clipRule="evenodd"
      />
      <path
        fill="#8E605C"
        d="M254.459 163.482s-1.723.091-3.355-1.859c-.907-1.088-1.677-2.448-1.179-2.856.499-.453 8.704.816 9.248 1.904.544 1.088-2.629 2.811-4.714 2.811Z"
      />
      <path
        fill="#423232"
        fillRule="evenodd"
        d="M252.394 148.234a.704.704 0 1 1 1.409.031l-.705-.015.705.015v.019l-.002.044a14.023 14.023 0 0 1-.059.746 19.613 19.613 0 0 1-.346 2.081 23.186 23.186 0 0 1-2.542 6.558m1.54-9.479v.001l-.001.006-.001.03a12.388 12.388 0 0 1-.052.651c-.049.457-.143 1.12-.321 1.931a21.8 21.8 0 0 1-2.388 6.157v.001c-.78 1.359-.533 3.057.389 4.428.934 1.387 2.588 2.51 4.79 2.831a.704.704 0 1 0 .204-1.395c-1.833-.268-3.125-1.185-3.824-2.223-.709-1.054-.78-2.167-.336-2.938"
        clipRule="evenodd"
      />
      <path
        fill="#6366F1"
        d="M290.647 187.195s.907-5.621 2.448-5.304c7.163 1.632 28.061 7.707 35.36 23.483 6.891 15.005 7.525 36.04 7.525 36.04H221.332s2.086-31.96 13.782-41.752c6.936-5.848 13.192-9.43 18.314-11.606 6.8-2.901 13.646 3.174 15.595 3.038 3.491-.182 21.624-3.899 21.624-3.899Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M315.665 223.895a.705.705 0 0 0-.785.615l-2.04 16.819a.705.705 0 0 0 1.4.169l2.04-16.818a.705.705 0 0 0-.615-.785ZM234.12 218.231a.705.705 0 0 0-.384.92m0 0 .002.005a16.33 16.33 0 0 1 .593 1.955c.279 1.199.5 2.763.31 4.304-.057.462-.13.957-.208 1.49-.477 3.244-1.158 7.878.309 14.658a.706.706 0 0 0 1.378-.298c-1.41-6.518-.766-10.914-.293-14.145.079-.542.154-1.051.213-1.532.218-1.768-.037-3.513-.336-4.797a17.302 17.302 0 0 0-.598-2.007 5.203 5.203 0 0 0-.046-.121l-.014-.034-.004-.009-.001-.003v-.002l-.644.265.643-.265a.705.705 0 0 0-.92-.384"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M295.86 177.312s3.264 1.179 3.853 1.814c.59.634.862 1.042.862 1.042s1.632 1.814 2.538 6.211c.408 1.859-.544 4.896.182 7.299 2.085 6.664-7.798 16.909-24.118 22.938-12.376 4.579-26.021.408-27.925-8.205-1.813-8.296-.725-12.059.136-14.733 1.723-5.168 2.267-6.619 4.579-8.614 1.45-1.269 2.448 1.36 7.888 2.856 1.677.454 3.717.816 6.346.907 15.64.589 22.259-8.296 24.616-10.835.59-.498.862-.77 1.043-.68Z"
      />
      <path
        fill="#CCC"
        fillRule="evenodd"
        d="M295.433 177.43a.704.704 0 0 0-.996.037c-.18.193-.381.42-.606.672-1.286 1.447-3.34 3.756-6.581 5.801-3.79 2.393-9.215 4.425-16.886 4.136h-.002c-2.574-.088-4.561-.443-6.187-.882a.705.705 0 1 0-.368 1.361c1.728.467 3.822.838 6.505.931 7.969.3 13.672-1.816 17.691-4.353 3.424-2.162 5.616-4.63 6.896-6.072.216-.243.406-.457.571-.635a.704.704 0 0 0-.037-.996Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M254.683 184.255c-3.921 3.677-10.554 13.094-7.677 33.135 1.432 9.908-3.174 14.305-1.996 15.826.196.261.43.384.614.419.78.137 1.525.457 1.928 1.161.154.309.394.522.721.685.282.165.599.147.868.086.937-.281 2.009.202 2.644.983.245.304.529.515.942.582.277.075.546.014.724-.042.756-.27 1.629-.093 2.339.411.189.125.376.205.602.192.407-.023.714-.222.97-.51a2.382 2.382 0 0 1 2.415-.638c.093.04.138.038.229.032.639.054 1.108-.472 1.42-1.353.316-.835 1.155-1.247 2.065-1.208.181-.011.362-.021.586-.079 1.742-.509 3.864-3.083 3.738-6.073-.124-2.944-1.097-8.019-2.366-10.353-3.909-7.177-5.481-12.399-5.891-16.372-.78-7.265 3.461-14.048 3.001-14.158-4.671-.685-7.876-2.726-7.876-2.726Z"
      />
      <path
        fill="#CCC"
        fillRule="evenodd"
        d="M263.383 186.964a.704.704 0 0 0-.937.34l-.247.519c-1.132 2.371-3.525 7.382-3.264 12.778.187 4.111 1.492 9.508 5.008 16.951a.704.704 0 0 0 1.275-.602c-3.465-7.335-4.699-12.546-4.875-16.414v-.002c-.243-5.021 1.997-9.725 3.135-12.115l.245-.517a.705.705 0 0 0-.34-.938Z"
        clipRule="evenodd"
      />
      <path
        fill="#CCC"
        fillRule="evenodd"
        d="M259.607 199.864a.704.704 0 0 0-.672.736c.186 4.111 1.491 9.508 5.008 16.952.522 1.107.963 2.907 1.267 4.84.301 1.92.455 3.896.433 5.32a.706.706 0 0 0 1.41.022c.024-1.523-.14-3.581-.45-5.56-.309-1.966-.774-3.928-1.385-5.224-3.465-7.335-4.699-12.546-4.875-16.414a.704.704 0 0 0-.736-.672Z"
        clipRule="evenodd"
      />
      <path
        fill="#CCC"
        fillRule="evenodd"
        d="M285.724 205.991a.705.705 0 0 0-.987-.138c-4.388 3.313-9.955 4.663-14.479 5.172a45.197 45.197 0 0 1-5.64.277 36.532 36.532 0 0 1-1.652-.059c-.193-.012-.34-.023-.439-.031l-.111-.009a.273.273 0 0 1-.026-.003h-.008a.705.705 0 0 0-.138 1.403l.063-.637-.062.637h.003l.009.001a.662.662 0 0 0 .033.003l.124.011c.107.008.265.02.467.032.404.025.989.051 1.717.062a46.558 46.558 0 0 0 5.818-.286c4.634-.522 10.492-1.915 15.17-5.447a.705.705 0 0 0 .138-.988ZM258.994 187.429a.705.705 0 0 0-.98.184c-1.306 1.912-2.822 4.743-3.201 8.255a.705.705 0 0 0 1.401.152c.346-3.197 1.731-5.806 2.964-7.612a.704.704 0 0 0-.184-.979ZM251.664 202.96a.704.704 0 0 0-.831.55l.681.139-.681-.138-.001.002-.001.006a.061.061 0 0 1-.003.016l-.01.058a16.654 16.654 0 0 0-.135 1.038c-.07.724-.136 1.8-.125 3.23.023 2.86.358 7.135 1.592 12.848 1.14 5.256 1.135 9.066.855 11.541-.14 1.238-.349 2.144-.519 2.732a8.054 8.054 0 0 1-.271.793l-.013.031-.002.004a.706.706 0 0 0 1.276.6l-.633-.299c.633.299.633.299.634.298v-.001l.001-.002.003-.006.007-.017.025-.055c.02-.047.047-.114.08-.2.065-.172.152-.423.248-.755.192-.663.416-1.647.565-2.964.298-2.637.293-6.601-.878-11.998-1.214-5.621-1.537-9.801-1.56-12.562-.011-1.38.053-2.407.119-3.082.033-.338.066-.588.09-.75l.029-.178.008-.041.001-.007v-.001M303.512 188.011a.705.705 0 0 0-.942.327l-.002.003-.005.012a41.877 41.877 0 0 1-1.932 3.406c-1.209 1.911-2.834 4.117-4.573 5.491a.704.704 0 1 0 .874 1.106c1.933-1.527 3.662-3.9 4.89-5.843a43.295 43.295 0 0 0 1.872-3.272l.106-.21.028-.056.008-.015.002-.006-.634-.308.634.308a.705.705 0 0 0-.326-.943ZM275.347 201.628a.706.706 0 0 0-.913-.402c-2.932 1.138-6.515 1.575-9.402 1.721a51.492 51.492 0 0 1-3.576.054 36.807 36.807 0 0 1-1.316-.048l-.069-.004-.017-.001h-.005a.704.704 0 1 0-.096 1.406l.048-.703-.048.703.008.001.021.001.077.005.292.015a52.024 52.024 0 0 0 4.752-.021c2.938-.149 6.699-.595 9.841-1.815a.706.706 0 0 0 .403-.912Z"
        clipRule="evenodd"
      />
      <path
        fill="#423232"
        fillRule="evenodd"
        d="M265.444 148.754c.611-.51 1.53-1.086 2.716-1.271.979-.159 1.993 0 2.808.332.409.167.789.387 1.097.653.304.262.573.601.691 1.013a.705.705 0 0 1-1.356.388.647.647 0 0 0-.179-.262l.041 1.156a1.872 1.872 0 0 1-1.813 2.04c-1.088.046-1.995-.77-2.04-1.813l-.046-1.269a1.956 1.956 0 0 1 .032-.549 4.613 4.613 0 0 0-1.047.664 5.061 5.061 0 0 0-.542.526l-.179.219a.706.706 0 0 1-1.152-.813l.543.382-.543-.382v-.001l.001-.001.002-.002.005-.007.015-.021.052-.068a6.269 6.269 0 0 1 .894-.914Zm.361 1.609 2.355-2.88-2.354 2.879-.001.001Zm-.177.217.177-.217-.008.009c-.061.07-.106.126-.135.162l-.03.04-.004.006Zm0 0h-.001v.001l.001-.001ZM252.048 150.581l.335-.001v-.019l-.001-.03a1.937 1.937 0 0 0-.051-.34 2.113 2.113 0 0 0-.341-.746c-.416-.58-1.171-1.019-2.397-1.019-1.1 0-1.881.512-2.369 1a3.984 3.984 0 0 0-.666.891l-.037.071-.011.023-.004.008-.001.003-.001.001v.001l.628.288-.628-.288a.706.706 0 0 0 1.28.591l.001-.001.001-.002a2.064 2.064 0 0 1 .094-.163 2.54 2.54 0 0 1 .551-.612 1.878 1.878 0 0 0-.017.345l.046 1.178a1.82 1.82 0 0 0 1.904 1.723 1.82 1.82 0 0 0 1.722-1.904l-.016-.412a.702.702 0 0 0 .313-.586h-.335Z"
        clipRule="evenodd"
      />
      <path
        fill="#423232"
        d="M263.193 142.875c-.952.317-1.904-.544-1.723-1.587.091-.544.318-1.088.771-1.496 1.36-1.224 6.981-2.312 10.381-.725 2.992 1.45-5.077 2.357-9.429 3.808ZM249.638 143.601c.771.272 1.587-.544 1.451-1.496-.045-.499-.227-.998-.589-1.36-1.088-1.088-4.806-1.95-7.163-.272-2.04 1.405 2.765 1.813 6.301 3.128Z"
      />
      <path
        fill="#423232"
        fillRule="evenodd"
        d="M267.642 165.369a.704.704 0 0 0-.992.096c-1.006 1.219-2.622 2.255-4.311 2.79-1.705.541-3.326.525-4.42-.127a.704.704 0 1 0-.722 1.211c1.586.946 3.678.859 5.568.26 1.907-.604 3.769-1.778 4.973-3.237a.706.706 0 0 0-.096-.993Z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="#423232"
      d="M236.808 174.017s-6.392 4.352-2.629 9.792c4.488 6.528 12.013-.59 12.013-.59-1.36 4.398 3.536 11.651 10.2 5.939a18.302 18.302 0 0 0 3.037-3.309c-2.765-.907-5.304-2.267-7.389-4.08-5.984-5.168-7.299-10.654-7.661-16.23-.182-2.493-.136-4.986-.272-7.525-.091-1.723-.227-3.4-.544-5.123-2.448-1.768-7.072-1.858-8.296.816-2.902 6.392 2.493 9.022 2.81 9.158-.362-.091-8.885-1.859-8.93 5.077-.091 7.299 7.661 6.075 7.661 6.075Z"
    />
    <path
      fill="#423232"
      fillRule="evenodd"
      d="M274.072 107.377c-3.441-.473-6.107.461-7.958 1.575-.085-2.047-1.084-3.985-4.781-3.434-4.542.681-6.773 4.224-7.806 6.778-10.693-1.961-17.44 3.943-18.941 13.486-.414 2.645-.374 4.514-.04 5.807 1.095 7.903 6.628 8.774 9.742 4.257 2.538-3.682 6.316-5.898 7.8-6.671 3.768 4.806 14.424 15.723 31.913 13.093.102.871.194 1.736.284 2.575.397 3.707.738 6.896 1.528 7.821.363-1.269 2.131-6.437 7.933-5.712 5.486.68 7.571 13.374-3.762 17.726.028.779.273 1.766.654 2.786-2.38 1.357-6.956 4.546-5.46 8.321 1.723 4.397 5.576 1.859 5.576 1.859s-3.626 8.477 3.491 9.701c5.757.997 7.072-5.395 7.072-5.395s2.856 7.798 8.477 5.032c5.531-2.72 1.768-8.749 1.768-8.749s7.979 3.4 10.608-2.448c3.264-7.253-5.53-10.653-5.53-10.653 4.669.68 8.477-8.523 1.178-11.742-6.993-3.064-12.079-.817-13.312-.163.128-.441.249-.892.361-1.352 4.227-10.298 2.832-29.257-10.622-37.019-5.205-3.004-10.831-4.319-16.113-4.705-.203-1.246-1.233-2.385-4.06-2.774Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChats);
export default Memo;
