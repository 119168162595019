import PropType from 'prop-types'
import { Navigate } from 'react-router-dom'

import useCurrentUser from '../hooks/useCurrentUser'
import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {
  children: PropType.node.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['RequireStripeAccount'] })

const RequireStripeAccount = ({ children }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()

  if (currentUser?.stripeId) {
    return children
  } else {
    return <Navigate to='/' />
  }
}

RequireStripeAccount.displayName = 'RequireStripeAccount'
RequireStripeAccount.propTypes = propTypes
RequireStripeAccount.defaultProps = defaultProps

export default RequireStripeAccount
