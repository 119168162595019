import PropType from 'prop-types'

import bundleFromJSON from '../models/bundle'

import { createPropTypes, searchPropTypes, updatePropTypes } from './bundle.prop-types'
import pb from './pb'

export function search (body, background = false) {
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'bundle#search')
  return pb.setupPost('bundles/search', {
    body,
    background,
    model: {
      conversion: bundleFromJSON,
      key: 'bundles',
      multiple: true
    }
  })
}

export function get (bundleId, background = false) {
  return pb.setupGet(`bundles/${bundleId}`, {
    background,
    model: {
      conversion: bundleFromJSON
    }
  })
}

export function create (body) {
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'bundles#create')
  return pb.setupPost('bundles', {
    body,
    model: {
      conversion: bundleFromJSON
    }
  })
}

export function update (bundleId, body) {
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'bundle#update')
  return pb.setupPut(`bundles/${bundleId}`, {
    body,
    model: {
      conversion: bundleFromJSON
    }
  })
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('bundles/bulk/delete', { body, background })
}

export function del (bundleId) {
  return pb.setupDelete(`bundles/${bundleId}`)
}

export function importInfoByShareId ({ shareId }) {
  return pb.setupGet(`bundles/import/${shareId}`, {
    model: {
      conversion: bundleFromJSON
    }
  })
}

export function importByShareId ({ shareId }) {
  return pb.setupPost(`bundles/import/${shareId}`, {
    model: {
      conversion: bundleFromJSON
    }
  })
}
