import './polyfills'
import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import 'yet-another-react-lightbox/styles.css'
import App from './App'
import isOldBrowser from './lib/isOldBrowser'
import OldBrowserApp from './OldBrowserApp'
import { isShortLinkRoute } from './routes/shortLinks'
import ShortLinkApp from './ShortLinkApp'

const AppToMount = isOldBrowser()
  ? OldBrowserApp
  : isShortLinkRoute(window.location)
    ? ShortLinkApp
    : App

const root = createRoot(document.getElementById('root'))
root.render(
  <AppToMount />
)
