import { AppLauncher } from '@capacitor/app-launcher'
import { Capacitor } from '@capacitor/core'
import classNames from 'classnames'
import { throttle } from 'lodash'
import PropType from 'prop-types'
import { toast } from 'react-hot-toast'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Close } from '../../svg/icons'
import Button from '../buttons/Button'
import { externalNavigate } from '../ExternalNavigate'

const propTypes = {
  children: PropType.node,
  className: PropType.string,
  resolutionAction: PropType.string,
  resolutionUrl: PropType.string,
  title: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  toastInstance: PropType.object,
  type: PropType.oneOf(['primary', 'success', 'warning', 'error'])
}

const defaultProps = {
  children: null,
  className: null,
  resolutionAction: null,
  resolutionUrl: null,
  title: null,
  toastInstance: null,
  type: 'primary'
}

const log = logger({ enabled: false, tags: ['Banner'] })

const Banner = ({ children, className, title, resolutionAction, resolutionUrl, toastInstance, type }) => {
  useLogger({ log, lifecycle: true, tags: [type, toastInstance?.id] })

  const dismissable = !!toastInstance

  const borderColorByType = {
    primary: 'border-sky-500',
    success: 'border-green-500',
    warning: 'border-yellow-500',
    error: 'border-red-500'
  }
  const handleDismissClick = (event) => {
    event.stopPropagation()
    log.debug('dismissing toast')
    toast.dismiss(toastInstance?.id)
  }

  const handleResolutionClick = (event) => {
    event.stopPropagation()
    if (resolutionUrl) {
      if (Capacitor.isNativePlatform()) {
        AppLauncher.openUrl({ url: resolutionUrl })
      } else {
        externalNavigate(resolutionUrl)
      }
    } else {
      window.location.reload()
    }
  }

  const computedClassName = classNames(
    'flex flex-row flex-nowrap p-[16px] border border-neutral-200 bg-white drop-shadow',
    {
      'pr-0': dismissable,
      'max-w-[720px]': dismissable,
      'mx-[20px]': dismissable,
      'w-full': !dismissable,
      'animate-enter': toastInstance?.visible,
      'animate-leave': toastInstance ? !toastInstance.visible : false
    },
    className
  )

  const dismissElement = (
    <div className='self-center ml-auto'>
      <Button
        className='!text-black'
        icon={<Close />}
        size='md'
        variant='text'
        onClick={handleDismissClick}
      >
        Dismiss
      </Button>
    </div>
  )

  return (
    <div className={computedClassName} {...toastInstance?.ariaProps}>
      <div className={`border-l-4 pl-[16px] flex flex-col flex-wrap justify-center ${borderColorByType[type]}`}>
        {title ? <span className='label-md-strong'>{title}</span> : null}
        {children ? <span className='label-md flex-grow'>{children}</span> : null}
        {resolutionAction
          ? (
            <Button className='w-48 mt-2' size='sm' onClick={handleResolutionClick}>
              {resolutionAction}
            </Button>
            )
          : null}
      </div>
      {dismissable ? dismissElement : null}
    </div>
  )
}

Banner.displayName = 'Banner'
Banner.propTypes = propTypes
Banner.defaultProps = defaultProps

function customToast (type = 'primary', title = '', message, toastOptions = {}, bannerOptions = {}) {
  // Set id to prevent on-screen duplicates
  toastOptions.id = toastOptions.id || `${title}${message}`.replace(/\W+/g, '')

  return toast.custom((t) => (
    <Banner
      title={title}
      toastInstance={t}
      type={type}
      {...bannerOptions}
    >
      {message}
    </Banner>), { position: 'bottom-center', ...toastOptions })
}
export function notify (...args) { customToast('primary', ...args) }
export function success (...args) { customToast('success', ...args) }
export function warning (...args) { customToast('warning', ...args) }
export function error (...args) { customToast('error', ...args) }
export const critical = throttle((title, message, resolutionAction = 'Reload Now', resolutionUrl = null) => {
  customToast('error', title, message, { duration: Infinity }, { resolutionAction, resolutionUrl })
}, 5000, { leading: true, trailing: false })

export default Banner
