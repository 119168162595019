import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import BundlesContentList from '../screens/bundles/BundlesContentList'
import BundlesDetail from '../screens/bundles/BundlesDetail'
import BundlesFolderDetail from '../screens/bundles/BundlesFolderDetail'
import ImportBundle from '../screens/bundles/ImportBundle'

const bundleRoutes = [
  {
    path: 'import/:shareId',
    element: <ImportBundle />
  },
  {
    path: ':bundleId',
    element: <BundlesDetail />
  },
  {
    path: ':bundleId/attachments',
    element: <BundlesContentList contentType='attachments' />
  },
  {
    path: ':bundleId/campaigns',
    element: <BundlesContentList contentType='campaigns' />
  },
  {
    path: ':bundleId/emails',
    element: <BundlesContentList contentType='emails' />
  },
  {
    path: ':bundleId/keywords',
    element: <BundlesContentList contentType='keywords' />
  },
  {
    path: ':bundleId/landingPages',
    element: <BundlesContentList contentType='landingPages' />
  },
  {
    path: ':bundleId/messageTemplates',
    element: <BundlesContentList contentType='messageTemplates' />
  },
  {
    path: ':bundleId/voiceDrops',
    element: <BundlesContentList contentType='voiceDrops' />
  }
]

const routes = [
  ...bundleRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<BundlesFolderDetail />} />,
    children: [...bundleRoutes]
  }
]

export default routes
