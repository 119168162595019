import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import AttachmentDownloads from '../screens/attachments/AttachmentDownloads'
import AttachmentsDetail from '../screens/attachments/AttachmentsDetail'
import AttachmentsFolderDetail from '../screens/attachments/AttachmentsFolderDetail'

const attachmentRoutes = [
  {
    path: ':attachmentId',
    element: <AttachmentsDetail />
  },
  {
    path: ':attachmentId/downloads',
    element: <AttachmentDownloads />
  }
]

const routes = [
  ...attachmentRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<AttachmentsFolderDetail />} />,
    children: [...attachmentRoutes]
  }
]

export default routes
