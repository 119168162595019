import { useCallback, useEffect, useMemo, useState } from 'react'

import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { isManager as isManagerService } from '../../services/user-managedAccount'
import { Admin, Aiva, Audiance, Automation, GroupChat, Manage, ManageAccounts, Rocket } from '../../svg/icons'
import SideMenu from '../side-menu/SideMenu'

const propTypes = {}

const defaultProps = {}

const baseUserNavConfig = [
  {
    icon: <GroupChat />,
    label: 'Chat',
    path: '/chats',
    indicator: 'unreadCounts.chats',
    stack: 'chats'
  },
  {
    icon: <Rocket />,
    label: 'Spaces',
    path: '/spaces',
    indicator: 'unreadCounts.spaces',
    stack: 'spaces'
  },
  {
    icon: <Audiance />,
    label: 'Audience',
    subConfig: [
      {
        label: 'Contacts',
        path: '/contacts',
        stack: 'contacts'
      },
      {
        label: 'Configurable Forms',
        path: '/configurable-forms',
        stack: 'configurable-forms'
      },
      {
        label: 'Tags',
        path: '/tags',
        stack: 'tags'
      }
    ]
  },
  {
    icon: <Automation />,
    label: 'Automation',
    subConfig: [
      {
        label: 'App Flows',
        path: '/app-flows',
        stack: 'app-flows'
      },
      {
        label: 'Campaigns',
        path: '/campaigns',
        stack: 'campaigns'
      },
      {
        label: 'Keywords',
        path: '/keywords',
        stack: 'keywords'
      },
      {
        label: 'Schedule',
        path: '/scheduled-broadcasts',
        stack: 'schedule'
      },
      {
        label: 'Unsent Messages',
        path: '/unsent-messages'
      }
    ]
  },
  {
    icon: <Manage />,
    label: 'Content',
    subConfig: [
      {
        label: 'Attachments',
        path: '/attachments',
        stack: 'attachments'
      },
      {
        label: 'Bundles',
        path: '/bundles',
        stack: 'bundles'
      },
      {
        label: 'Contests',
        path: '/contests',
        stack: 'contests'
      },
      {
        label: 'Emails',
        path: '/emails',
        stack: 'emails'
      },
      {
        label: 'Landing Pages',
        path: '/landing-pages',
        stack: 'landing-pages'
      },
      {
        label: 'Message Templates',
        path: '/message-templates',
        stack: 'message-templates'
      },
      {
        label: 'QR Codes',
        path: '/qr-codes',
        stack: 'qr-codes'
      },
      {
        label: 'Trackable Links',
        path: '/trackable-links',
        stack: 'trackable-links'
      },
      {
        label: 'Voice Drops',
        path: '/voice-drops',
        stack: 'voice-drops'
      }
    ]
  },
  {
    icon: <Aiva />,
    label: 'AIVA',
    subConfig: [
      {
        label: 'Introduction',
        path: '/aiva/what-is-aiva'
      },
      {
        label: 'Instant Assistant',
        path: '/aiva/chat'
      },
      {
        label: 'Playground',
        path: '/aiva/try-aiva'
      },
      {
        label: 'Settings',
        path: '/aiva/settings'
      }
    ]
  },
  {
    icon: <ManageAccounts />,
    label: 'Manage Accounts',
    path: '/managed-accounts',
    hide: ({ isManager }) => !isManager
  },
  {
    icon: <Admin />,
    label: 'Admin',
    hide: ({ currentUser }) => !currentUser.superuser,
    subConfig: [
      {
        label: 'Billing Accounts',
        path: '/admin/billing-accounts'
      },
      {
        label: 'Tools',
        path: '/admin/tools'
      },
      {
        label: 'Unlayer Templates',
        path: '/admin/unlayer-templates'
      },
      {
        label: 'Users',
        path: '/admin/users'
      }
    ]
  }
]

const log = logger({ enabled: true, tags: ['Menu'] })

const Menu = () => {
  useLogger({ log, lifecycle: false })

  const currentUser = useCurrentUser()
  const [isManager, setIsManager] = useState(false)

  // called each time the currentUser changes (like when managing a user)
  const { call: isManagerCall } = useService(isManagerService, {
    onReplyOk: useCallback((reply) => {
      setIsManager(!!reply.json.isManager)
    }, [])
  })

  useEffect(() => {
    if (currentUser) {
      isManagerCall()
    }
  }, [currentUser, isManagerCall])

  const navConfig = useMemo(() => {
    // Remove top-level nav items w/ hide func returning true
    let visibleUserNavConfig = baseUserNavConfig.filter(({ hide: hideFunc }) => {
      return !hideFunc?.({ currentUser, isManager })
    })

    // Remove nested subConfig nav items w/ hide func returning true
    visibleUserNavConfig = visibleUserNavConfig.map((topLevelNavItem) => ({
      ...topLevelNavItem,
      subConfig: topLevelNavItem.subConfig?.filter(({ hide: hideFunc }) => {
        return !hideFunc?.({ currentUser, isManager })
      })
    }))

    return visibleUserNavConfig
  }, [currentUser, isManager])

  return currentUser ? <SideMenu navConfig={navConfig} /> : null
}

Menu.displayName = 'Menu'
Menu.propTypes = propTypes
Menu.defaultProps = defaultProps

export default Menu
