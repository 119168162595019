import * as React from "react";
import { memo } from "react";
const SvgChevronDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.931 15.921 6.68-6.678c.519-.507.519-1.347 0-1.853a1.296 1.296 0 0 0-1.854 0l-5.759 5.759-5.758-5.76a1.296 1.296 0 0 0-1.853 0c-.254.254-.387.6-.387.934 0 .333.133.68.387.933l6.678 6.679c.52.52 1.347.52 1.853 0l.014-.014Z"
    />
  </svg>
);
const Memo = memo(SvgChevronDown);
export default Memo;
