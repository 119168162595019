import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CampaignEventList from '../../components/campaigns/CampaignEventList'
import CreateAction from '../../components/campaigns/event-actions/CreateAction'
import FormattedDate from '../../components/FormattedDate'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { campaignTypeInfo, campaignTypeLabel } from '../../helpers/campaign'
import useCurrentCampaign from '../../hooks/useCurrentCampaign'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignEventsList'] })

const CampaignEventsList = () => {
  const navigate = useNavigate()
  const createCampaignEventRef = useRef()
  const { campaignId, loading, campaign } = useCurrentCampaign()

  useLogger({ log, lifecycle: false, tags: [campaignId] })

  useEffect(() => {
    if (!campaign) { return }
    const { supportsMultipleMessages } = campaignTypeInfo(campaign.type)
    if (!supportsMultipleMessages) {
      navigate(`/campaigns/${campaignId}`)
    }
  }, [campaign, campaignId, navigate])

  const handleAddButtonClick = useCallback(() => {
    createCampaignEventRef.current.activate()
  }, [])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !campaign) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<PanelHeaderButton icon='add' onClick={handleAddButtonClick} />}
        start={<PanelHeaderBackButton />}
        subtitle={campaign.name}
        title={`${campaignTypeLabel(campaign.type)} Messages`}
      />
      <PanelContent>
        {campaign.type === 'countdown' && (
          <h1 className='bg-neutral-50 label-sm text-black text-center p-3 border-b border-neutral-200'>
            <FormattedDate date={campaign.scheduledAt} format='extendedDateAndTime' />
          </h1>
        )}
        <CampaignEventList
          campaignId={campaignId}
          type='nav'
          onTotalChange={handleTotalChange}
        />
        <CreateAction ref={createCampaignEventRef} campaign={campaign} />
      </PanelContent>
    </Panel>
  )
}

CampaignEventsList.displayName = 'CampaignEventsList'
CampaignEventsList.propTypes = propTypes
CampaignEventsList.defaultProps = defaultProps

export default CampaignEventsList
