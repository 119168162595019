import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('refId', 'Referral Code must be at least 3 characters', () => {
    enforce(data.refId).longerThanOrEquals(3)
  })

  test('refId', 'Referral Code must contain only letters and numbers.', () => {
    enforce(data.refId).matches(/^[a-zA-Z0-9]*$/)
  })
})

export default validationSuite
