import * as React from "react";
import { memo } from "react";
const SvgTimeLapsed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Time_Lapsed_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.78.375a1 1 0 0 1-.155 1.406l-5 4a1 1 0 0 1-1.25-1.562l5-4a1 1 0 0 1 1.406.156ZM3.5 13.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0ZM12 3C6.201 3 1.5 7.701 1.5 13.5S6.201 24 12 24s10.5-4.701 10.5-10.5S17.799 3 12 3Zm1 6a1 1 0 1 0-2 0v4H8a1 1 0 1 0 0 2h4a1 1 0 0 0 1-1V9ZM18.625.22a1 1 0 1 0-1.25 1.56l5 4a1 1 0 0 0 1.25-1.56l-5-4Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Time_Lapsed_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTimeLapsed);
export default Memo;
