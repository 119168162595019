import { pluralize } from '../lib/formatters'

export function durationSummary (estimatedBroadcastTimeMins) {
  let estimationStr = ''
  if (estimatedBroadcastTimeMins === 0) {
    estimationStr = 'a few seconds'
  } else if (estimatedBroadcastTimeMins > 90) {
    const hours = Math.ceil(estimatedBroadcastTimeMins / 60)
    estimationStr = pluralize({ count: hours, singular: 'hour', includeCount: true })
  } else {
    estimationStr = pluralize({ count: estimatedBroadcastTimeMins, singular: 'minute', includeCount: true })
  }
  return `Estimated processing time: ${estimationStr}`
}

export function canEditBroadcast (scheduledBroadcast) {
  return !scheduledBroadcast.campaign || ['drip', 'delay', 'schedule', 'countdown', 'event'].includes(scheduledBroadcast.campaignType)
}
