import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import contact from '../../../prop-types/shapes/contact'
import { archive } from '../../../services/contact'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  contacts: PropType.arrayOf(PropType.shape(contact)).isRequired
}

const BulkArchiveAction = forwardRef(({ contacts }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success('Archived Contacts updated')
    bus.emit('contactArchived', contacts)
    bus.emit('closeBulkEditDialog')
  }, [contacts])
  const { call: addSelectedCall } = useService(archive, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!contacts || !contacts.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [contacts])

  const handleConfirm = useCallback(() => {
    addSelectedCall(map(contacts, '_id'))
  }, [addSelectedCall, contacts])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, archive contacts'
      confirmValue='ARCHIVE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        Archived contacts will be moved out of your main contact list and stored in the archived contacts section.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkArchiveAction.displayName = 'BulkArchiveAction'
BulkArchiveAction.propTypes = propTypes

export default BulkArchiveAction
