import * as React from "react";
import { memo } from "react";
const SvgEye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4C6.706 4 2.215 7.428.62 12.182a1 1 0 0 0 0 .636C2.216 17.572 6.707 21 12 21c5.294 0 9.785-3.428 11.38-8.182a1 1 0 0 0 0-.636C21.785 7.428 17.294 4 12 4Zm0 15c-4.29 0-7.952-2.702-9.37-6.5a10.004 10.004 0 0 1 18.74 0A10.004 10.004 0 0 1 12 19Zm-2-6.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgEye);
export default Memo;
