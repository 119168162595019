import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import CreateAction from '../../../components/scheduled-broadcast/actions/CreateAction'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const ScheduledBroadcastListHeaderMoreMenu = ({ listRef }) => {
  const createActionRef = useRef()

  const handleCreateClick = useCallback(() => createActionRef.current.activate(), [])
  const handleReloadClick = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadClick} />
      <PanelHeaderButton icon={<Plus />} title='Create New Broadcast' onClick={handleCreateClick} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

ScheduledBroadcastListHeaderMoreMenu.displayName = 'ScheduledBroadcastListHeaderMoreMenu'
ScheduledBroadcastListHeaderMoreMenu.propTypes = propTypes
ScheduledBroadcastListHeaderMoreMenu.defaultProps = defaultProps

export default ScheduledBroadcastListHeaderMoreMenu
