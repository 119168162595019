import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useMemo } from 'react'

import { usePanel } from '../../../contexts/PanelContext'
import { Check, ChevronLeft, Close, Downloads, Dynamic, Gear, Minus, More, Past, PhoneOutboundCall, Plus, Profile, Search, Sort, Tag } from '../../../svg/icons'
import Button from '../../buttons/Button'

const propTypes = { // will also pass through any other props to Button
  className: PropType.string,
  form: PropType.string,
  href: PropType.string,
  icon: PropType.oneOfType([
    PropType.oneOf([
      'add',
      'call',
      'cancel',
      'back',
      'close',
      'downloads',
      'dynamic',
      'more',
      'past',
      'profile',
      'remove',
      'save',
      'search',
      'settings',
      'sort',
      'tags'
    ]),
    PropType.element
  ]),
  title: PropType.string,
  type: PropType.string,
  onClick: PropType.func
}

const defaultProps = {
  className: '',
  form: null,
  href: null,
  icon: null,
  onClick: null,
  title: null,
  type: 'button'
}

const PanelHeaderButton = forwardRef(({
  className,
  form,
  href,
  icon,
  title,
  type,
  onClick,
  ...rest
}, ref) => {
  const { isNative, inDialog } = usePanel()

  const classNameStateOverrides = useMemo(() => (
    isNative && !inDialog
      ? {
          hover: 'bg-blue-600',
          active: 'bg-blue-600'
        }
      : null
  ), [inDialog, isNative])

  if (!icon && !title) { return null }

  if (typeof icon === 'string') {
    ({ icon, title } = iconAndTitleMappings[icon])
  }

  const computedClassName = classNames('flex-initial p-[6px] cursor-pointer', className)

  return (
    <Button
      ref={ref}
      className={computedClassName}
      classNameStateOverrides={classNameStateOverrides}
      form={form}
      href={href}
      size='none'
      theme='none'
      type={type}
      variant='none'
      onClick={onClick}
      {...rest}
    >
      {icon
        ? (
          <span className='inline-block h-[20px] w-[20px]'>
            {icon}
            <span className='sr-only'>{title}</span>
          </span>
          )
        : <span className='inline-block h-[20px] label-md-strong'>{title}</span>}
    </Button>
  )
})

PanelHeaderButton.displayName = 'PanelHeaderButton'
PanelHeaderButton.propTypes = propTypes
PanelHeaderButton.defaultProps = defaultProps

const iconAndTitleMappings = {
  add: { icon: <Plus />, title: 'add' },
  back: { icon: <ChevronLeft />, title: 'back' },
  call: { icon: <PhoneOutboundCall />, title: 'call' },
  cancel: { icon: <Close />, title: 'cancel' },
  close: { icon: <Close />, title: 'close' },
  downloads: { icon: <Downloads />, title: 'downloads' },
  dynamic: { icon: <Dynamic />, title: 'downloads' },
  more: { icon: <More />, title: 'more' },
  past: { icon: <Past />, title: 'past' },
  profile: { icon: <Profile />, title: 'profile' },
  remove: { icon: <Minus />, title: 'remove' },
  save: { icon: <Check />, title: 'save' },
  search: { icon: <Search />, title: 'search' },
  settings: { icon: <Gear />, title: 'settings' },
  sort: { icon: <Sort />, title: 'sort' },
  tags: { icon: <Tag />, title: 'tags' }
}

export default PanelHeaderButton
