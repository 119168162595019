import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import PanelHeaderButton from '../../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../panels/PanelContent'
import PanelHeader from '../../../panels/PanelHeader'

const propTypes = {
  suggestionText: PropType.string.isRequired,
  onBack: PropType.func.isRequired,
  onInsertSelected: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['RevisionResultsPanel'] })

const RevisionResultsPanel = ({ suggestionText, onBack, onInsertSelected }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleInsertClick = useCallback(() => {
    onInsertSelected(suggestionText)
  }, [onInsertSelected, suggestionText])

  return (
    <>
      <PanelHeader
        end={<PanelHeaderButton title='Insert' onClick={handleInsertClick} />}
        start={<PanelHeaderButton icon='back' onClick={onBack} />}
        title='Revise Message'
      />
      <PanelContent className='min-h-[500px] p-5'>
        <h2 className='headline-2xs mb-3'>Message Suggestion:</h2>
        <p className='paragraph-md text-neutral-600 whitespace-pre-line'>
          {suggestionText}
        </p>
      </PanelContent>
    </>
  )
}

RevisionResultsPanel.displayName = 'RevisionResultsPanel'
RevisionResultsPanel.propTypes = propTypes
RevisionResultsPanel.defaultProps = defaultProps

export default RevisionResultsPanel
