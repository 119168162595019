import UnlayerTemplatesDetail from '../../screens/admin/unlayer-templates/UnlayerTemplatesDetail'

const routes = [
  {
    path: ':unlayerTemplateId',
    element: <UnlayerTemplatesDetail />
  }
]

export default routes
