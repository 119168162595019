import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DetailSection from '../../../../components/detail-sections/DetailSection'
import PanelLoading from '../../../../components/panels/PanelLoading'
import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import { formatPhone, mfaChannelVerb } from '../../../../lib/formatters'
import logger from '../../../../lib/logger'
import {
  search as mfaSearchService
} from '../../../../services/multi-factor-authentication'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MfaDetail'] })

const MfaDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const [mfaMethods, setMfaMethods] = useState([])

  const { call: mfaSearch, loading: loadingMethods } = useService(mfaSearchService, {
    onReplyOk: useCallback((reply) => setMfaMethods(reply.json || []), [])
  })

  useEffect(() => {
    mfaSearch()
  }, [mfaSearch])

  const handleEdit = useCallback(async () => {
    navigate('list')
  }, [navigate])

  const count = mfaMethods?.length

  if (loadingMethods) { return <PanelLoading /> }

  return (
    <DetailSection
      description='Multi-Factor Authentication (MFA) is used to help keep your account secure.'
      editHref='list'
      editIcon='chevron'
      initialEditButtonText='Add Multi-Factor Authentication Method'
      showEditIcon={(count > 0)}
      showInitialEditButton={(count === 0)}
      title={`MFA Methods (${count})`}
      onInitialEditClick={handleEdit}
    >
      <ul>
        {mfaMethods.map((mfaMethod) => (
          <li key={mfaMethod._id}>
            {mfaChannelVerb(mfaMethod.channel)}
            {' '}
            {formatPhone(mfaMethod.to)}
          </li>
        ))}
      </ul>
    </DetailSection>
  )
}

MfaDetail.displayName = 'MfaDetail'
MfaDetail.propTypes = propTypes
MfaDetail.defaultProps = defaultProps

export default MfaDetail
