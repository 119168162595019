import PropType from 'prop-types'
import { useCallback } from 'react'

import { notify } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import { copyToClipboard } from '../../../lib/copyToClipboard'
import logger from '../../../lib/logger'

const propTypes = {
  signupUrl: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CopyReferralLink'] })

const CopyReferralLink = ({ signupUrl }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleCopyClick = useCallback(() => {
    window.gtag('event', 'share', { event_category: 'engagement', event_label: 'Referral Code Copy' })
    copyToClipboard(signupUrl)
    notify('Referral link copied to clipboard')
  }, [signupUrl])

  return (
    <DetailSection
      title='Copy and share your link'
      showCopyIcon
      onCopyClick={handleCopyClick}
    >
      <div className='select-text'>{signupUrl}</div>
    </DetailSection>
  )
}

CopyReferralLink.displayName = 'CopyReferralLink'
CopyReferralLink.propTypes = propTypes
CopyReferralLink.defaultProps = defaultProps

export default CopyReferralLink
