import { filter, includes, omit } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { campaignTypeLabel, repeatFrequencyOptions } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useTimeZone from '../../../hooks/useTimeZone'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { dateAtStartOfDayInTimeZone, dateAtTimeOfDayInTimeZone } from '../../../lib/timeZones'
import campaign from '../../../prop-types/shapes/campaign'
import { create as createCampaignEventService } from '../../../services/campaign-event'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import Banner from '../../banners/Banner'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuiteByCampaignType from './CreateAction.validations'

const propTypes = {
  campaign: PropType.shape(campaign).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: true, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ campaign, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    const newCampaignEvent = reply.model
    setCurrentInstance('campaignEvent', newCampaignEvent)
    bus.emit('campaignEventCreated', newCampaignEvent)
    navigate(`/campaigns/${campaign._id}/events/${newCampaignEvent._id}`)
    onSuccess?.(newCampaignEvent)
  }, [campaign._id, navigate, onSuccess])
  const { call: createCampaignEventCall } = useService(createCampaignEventService, { onReplyOk: handleReplyOk })

  const { userTimeZone } = useTimeZone()

  const createDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { createDialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((formData) => {
    createDialogRef.current.close()
    createCampaignEventCall(campaign._id, formData)
  }, [campaign._id, createCampaignEventCall])

  const formControls = useMemo(() => filter([
    {
      type: 'passthrough',
      name: 'passthrough',
      element: (
        <Banner className='mb-5' type='warning'>
          When adding a new message, broadcasts will automatically be scheduled for existing
          contacts in this campaign only if they do not end up in the past.
        </Banner>
      )
    },
    {
      autoComplete: 'off',
      label: 'Name',
      type: 'text',
      name: 'name',
      hint: 'Give your message a name so you can stay organized when looking at your campaign.'
    },
    {
      label: 'Message',
      type: 'message',
      name: 'message',
      hint: 'You have something to say? Make it personal. Make it awesome!'
    },
    includes(['drip'], campaign.type) && {
      label: 'Day Delay',
      name: 'scheduledDelayDays',
      type: 'number',
      min: 0,
      hint: `Specify the number of days to wait before sending this message. Setting this to 0
             will send immediately if a contact joins after the time set on this message.`
    },
    includes(['countdown', 'event'], campaign.type) && {
      label: 'Days Before',
      name: 'scheduledBeforeDays',
      type: 'number',
      min: 0,
      hint: `Specify the number of days before the ${campaign.type === 'event' ? 'event' : 'end'} date to send this message.`
    },
    includes(['schedule', 'repeat'], campaign.type) && {
      label: 'Date',
      name: 'scheduledAtDay',
      hint: 'Specify the date to send this message.',
      type: 'date'
    },
    includes(['drip', 'birthday', 'anniversary', 'countdown', 'event', 'schedule', 'repeat'], campaign.type) && {
      label: 'Time',
      name: 'scheduledAtTime',
      hint: 'Specify the time of day to send this message.',
      type: 'time-message-send'
    },
    includes(['delay'], campaign.type) && {
      label: 'Hour Delay',
      name: 'scheduledDelayHours',
      type: 'number',
      min: 0,
      hint: 'Specify the number of hours to wait before sending this message.'
    },
    includes(['delay'], campaign.type) && {
      label: 'Minute Delay',
      name: 'scheduledDelayMinutes',
      type: 'number',
      min: 0,
      hint: 'Specify the number of minutes to wait before sending this message.'
    },
    includes(['repeat'], campaign.type) && {
      label: 'Repeat',
      name: 'scheduledRepeat',
      type: 'select',
      options: [{ label: '', value: '' }].concat(repeatFrequencyOptions),
      hint: 'Specify how often to resend this message.'
    },
    {
      label: 'Apply Keyword to Contact After Message is Sent',
      name: 'keyword',
      hint: `The keyword below will be applied to each contact after this campaign message is sent
             (processed). Note this means the keyword will be applied whether or not this message
             is ultimately sent and received successfully.`,
      type: 'modelselect',
      model: 'keywords',
      multiple: false
    }
  ]), [campaign.type])

  const transformData = useCallback((data) => {
    const result = omit(data, 'keyword', 'scheduledAtDay', 'scheduledAtTime')
    const { keyword, scheduledAtDay, scheduledAtTime } = data

    if (keyword) {
      result.keyword = keyword._id
    }

    if (scheduledAtTime) {
      const dateTime = scheduledAtDay ? dateAtStartOfDayInTimeZone(scheduledAtDay, userTimeZone) : new Date()
      result.scheduledAt = dateAtTimeOfDayInTimeZone(dateTime, scheduledAtTime, userTimeZone)?.toISOString()
    }

    return result
  }, [userTimeZone])

  return (
    <FormPanelDialog
      ref={createDialogRef}
      defaultValues={{ name: '' }}
      description='Create a new campaign message'
      formControls={formControls}
      subtitle={campaign.name}
      title={`New ${campaignTypeLabel(campaign.type)} Message`}
      transformData={transformData}
      validationSuite={validationSuiteByCampaignType[campaign.type]}
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
