import PropType from 'prop-types'

export const updatePropTypes = {
  intro: PropType.shape({
    message: PropType.string
  }),
  nudge: PropType.shape({
    message: PropType.string
  }),
  reward: PropType.shape({
    credits: PropType.number,
    message: PropType.string
  })
}
