import { extend, some } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Checkbox from '../../components/forms/controls/Checkbox'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateAivaSettings as updateAivaSettingsService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const AivaSettingsScreen = () => {
  const { aivaSettings } = useCurrentUser()
  const [allSettingsDisabled, setAllSettingsDisabled] = useState(!some([
    aivaSettings.assistantChat,
    aivaSettings.messageHelper,
    aivaSettings.stopDetect !== 'off'
  ]))
  const formRef = useRef()

  const { call: updateAivaSettings } = useServiceAndAction(updateAivaSettingsService, updateUserAction, {
    onReplyOk: () => notify('AIVA Settings updated successfully.')
  })

  const handleToggleAll = useCallback(() => {
    setAllSettingsDisabled(!allSettingsDisabled)
  }, [allSettingsDisabled])

  const handleSave = useCallback(() => {
    if (allSettingsDisabled) {
      updateAivaSettings({
        stopDetect: 'off',
        assistantChat: false,
        messageHelper: false
      })
    } else {
      const formValues = formRef.current.getValues()
      updateAivaSettings(extend(formValues, {
        stopDetect: formValues.stopDetect ? 'flag-only' : 'off'
      }))
    }
  }, [allSettingsDisabled, updateAivaSettings])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton title='Save' onClick={handleSave} />}
        title='AIVA Settings'
      />
      <PanelContent className='p-5'>
        <div className='p-6 bg-indigo-50'>
          <Checkbox
            defaultChecked={!allSettingsDisabled}
            label='AIVA assistance for Message Composition, Instant Assistant, Smart Opt-out and prevent Message Strength Insights.'
            name='allSettingsEnabled'
            title='Enable AIVA'
            onChange={handleToggleAll}
          />
        </div>
        {allSettingsDisabled
          ? null
          : (
            <Form
              ref={formRef}
              className='px-6 py-5'
              defaultValues={{
                stopDetect: aivaSettings.stopDetect === 'flag-only',
                assistantChat: aivaSettings.assistantChat,
                messageHelper: aivaSettings.messageHelper
              }}
              formControls={[
                {
                  type: 'checkbox',
                  name: 'stopDetect',
                  label: 'Marks a contact both in your active chats list and within the chat conversation itself as potential “Do Not Message”',
                  title: 'Enable Smart Opt-out Detection'
                },
                {
                  type: 'checkbox',
                  name: 'assistantChat',
                  label: 'AI-powered support providing instant assistance and learning.',
                  title: 'Enable Instant Assistant'
                },
                {
                  type: 'checkbox',
                  name: 'messageHelper',
                  label: "Helps improve your message's reach and readability, aiming to enhance delivery chances.",
                  title: 'Enable Message Strength'
                }
              ]}
            />
            )}
      </PanelContent>
    </Panel>
  )
}

AivaSettingsScreen.displayName = 'AivaSettingsScreen'

export default AivaSettingsScreen
