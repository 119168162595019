import { formatShortDateAndTime } from '../lib/formatters'

import { campaignTypeLabel } from './campaign'

// converts advanced search form data to a server-ready object for the search api
export function generateSearchFromAdvancedSearchFormData (advancedSearchFormData) {
  const search = {}

  if (!advancedSearchFormData) { return search }

  const fieldsToCopyAsIs = ['term', 'campaignType', 'scheduledAtType']
  fieldsToCopyAsIs.forEach((field) => { search[field] = advancedSearchFormData[field] })

  if (advancedSearchFormData.scheduledAtType === 'range') {
    search.scheduledAtFrom = advancedSearchFormData.scheduledAtFrom.toISOString()
    search.scheduledAtTo = advancedSearchFormData.scheduledAtTo.toISOString()
  }

  if (advancedSearchFormData.noCampaigns) {
    search.noCampaigns = true
  }

  if (advancedSearchFormData.contact) {
    search.contactId = advancedSearchFormData.contact._id
  }

  if (advancedSearchFormData.campaign) {
    search.campaignId = advancedSearchFormData.campaign._id
  }

  if (advancedSearchFormData.event) {
    search.eventId = advancedSearchFormData.event._id
  }

  return search
}

export function generateSummaryFromAdvancedSearchFormData (advancedSearchFormData, userTimeZone) {
  let filterCount = 0
  const summaries = []

  if (!advancedSearchFormData) { return summaries }

  if (advancedSearchFormData.term) {
    filterCount++
    summaries.push(advancedSearchFormData.term)
  }

  if (advancedSearchFormData.scheduledAtType === 'past') {
    filterCount++
    summaries.push('Past')
  }

  if (advancedSearchFormData.scheduledAtType === 'range') {
    filterCount++
    const from = formatShortDateAndTime(advancedSearchFormData.scheduledAtFrom, userTimeZone)
    const to = formatShortDateAndTime(advancedSearchFormData.scheduledAtTo, userTimeZone)
    summaries.push(`${from} - ${to}`)
  }

  if (advancedSearchFormData.contact) {
    filterCount++
    summaries.push(advancedSearchFormData.contact.firstName || advancedSearchFormData.contact.phoneNumber)
  }

  if (advancedSearchFormData.noCampaigns) {
    filterCount++
    summaries.push('No Campaigns')
  }

  if (advancedSearchFormData.campaignType) {
    filterCount++
    summaries.push(campaignTypeLabel(advancedSearchFormData.campaignType))
  }

  if (advancedSearchFormData.campaign) {
    filterCount++
    summaries.push(advancedSearchFormData.campaign.name)
  }

  if (advancedSearchFormData.event) {
    filterCount++
    summaries.push(advancedSearchFormData.event.name)
  }

  if (filterCount) { summaries.unshift(`${filterCount}`) }
  return summaries
}
