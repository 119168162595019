import { Preferences } from '@capacitor/preferences'

export async function getPreference (key) {
  const result = await Preferences.get({ key })
  return result?.value
}

export async function setPreference (key, value) {
  await Preferences.set({ key, value })
}

export async function removePreference (key) {
  await Preferences.remove({ key })
}
