import { get as getLandingPageService } from '../services/landingPage'

import useCurrentInstance from './useCurrentInstance'

const useCurrentLandingPage = () => useCurrentInstance({
  resourceName: 'landingPage',
  getService: getLandingPageService,
  replyAttrPath: 'model',
  notFoundRedirect: '/landing-pages'
})

export default useCurrentLandingPage
