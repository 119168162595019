import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import { CONTENT_TYPE } from '../../../lib/folder'
import logger from '../../../lib/logger'
import templateShape from '../../../prop-types/shapes/template'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import Button from '../../buttons/Button'
import AddToExistingFolderAction from '../../folders/actions/AddToExistingFolderAction'
import CreateFolderAction from '../../folders/actions/CreateFolderAction'

import BulkDeleteAction from './BulkDeleteAction'

const propTypes = {
  deleteActionLabel: PropType.string.isRequired,
  folderContentType: PropType.oneOf([
    CONTENT_TYPE.email,
    CONTENT_TYPE.messageTemplate,
    CONTENT_TYPE.voiceDrop
  ]).isRequired,
  selectedItems: PropType.arrayOf(PropType.shape(templateShape)).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['BulkEditActions'] })

const BulkEditActions = ({ deleteActionLabel, folderContentType, selectedItems }) => {
  useLogger({ log, lifecycle: false, tags: [folderContentType] })

  const deleteActionRef = useRef()
  const addToFolderActionRef = useRef()
  const createFolderActionRef = useRef()

  const handleDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])
  const handleAddToFolderAction = useCallback(() => addToFolderActionRef.current.activate(), [])
  const handleCreateFolderAction = useCallback(() => createFolderActionRef.current.activate(), [])

  const trigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={trigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <ActionMenuItem label='Create new folder' onClick={handleCreateFolderAction} />
        <ActionMenuItem label='Move to existing folder' onClick={handleAddToFolderAction} />
        <ActionMenuDivider />
        <DangerousActionMenuItem label={deleteActionLabel} onClick={handleDeleteAction} />
      </ActionMenu>
      <AddToExistingFolderAction ref={addToFolderActionRef} contentType={folderContentType} selectedItems={selectedItems} />
      <CreateFolderAction ref={createFolderActionRef} contentType={folderContentType} selectedItems={selectedItems} />
      <BulkDeleteAction ref={deleteActionRef} folderContentType={folderContentType} templates={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
