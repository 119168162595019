import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import { create as createTemplateService } from '../../../services/template'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuite from './CreateAction.validations'

const propTypes = {
  createdFromFolder: PropType.bool,
  message: PropType.shape(message),
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  message: null,
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, message, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const dialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    const template = reply.json
    setCurrentInstance('email', template)
    bus.emit('emailCreated', template)
    if (!createdFromFolder) { navigate(`/emails/${template._id}`) }
    onSuccess?.(template)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createTemplate } = useService(createTemplateService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const createTemplateWithName = useCallback((data) => {
    dialogRef.current.close()
    createTemplate(data)
  }, [createTemplate])

  return (
    <FormPanelDialog
      ref={dialogRef}
      defaultValues={{ type: 'email', contentType: 'Email', name: '', message }}
      formControls={[
        {
          type: 'hidden',
          name: 'type'
        },
        {
          type: 'hidden',
          name: 'contentType'
        },
        {
          autoComplete: 'off',
          name: 'name',
          placeholder: 'Email Name',
          hint: 'Please name your new email'
        },
        {
          autoComplete: 'off',
          name: 'subject',
          placeholder: 'Email Subject',
          hint: 'Please enter your email subject'
        }
      ]}
      title='New Email'
      validationSuite={validationSuite}
      onSubmit={createTemplateWithName}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
