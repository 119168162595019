import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/keyword-leave-campaign'
import CampaignList from '../campaigns/CampaignList'

const propTypes = {
  keywordId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['KeywordLeaveCampaignList'] })

const KeywordLeaveCampaignList = forwardRef(({ keywordId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [keywordId] })

  return (
    <CampaignList
      ref={ref}
      itemSharedContext={{ keywordId }}
      queryOverrides={{ keywordId }}
      searchService={searchService}
      {...rest}
    />
  )
})

KeywordLeaveCampaignList.displayName = 'KeywordLeaveCampaignList'
KeywordLeaveCampaignList.propTypes = propTypes
KeywordLeaveCampaignList.defaultProps = defaultProps

export default KeywordLeaveCampaignList
