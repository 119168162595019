import { assignIn, omit } from 'lodash'
import PropType from 'prop-types'

import { bundleContentTypes } from '../helpers/bundle'

import { searchPropTypes as attachmentSearchPropTypes } from './attachment.prop-types'
import { searchPropTypes as campaignSearchPropTypes } from './campaign.prop-types'
import { searchPropTypes as keywordSearchPropTypes } from './keyword.prop-types'
import { searchPropTypes as landingPageSearchPropTypes } from './landingPage.prop-types'
import { searchPropTypes as templateSearchPropTypes } from './template.prop-types'

export function searchPropTypesForContent ({ contentType }) {
  const propTypes = {
    bundleId: PropType.string.isRequired,
    contentType: PropType.oneOf(bundleContentTypes)
  }

  switch (contentType) {
    case 'attachments':
      assignIn(propTypes, attachmentSearchPropTypes)
      break
    case 'campaigns':
      assignIn(propTypes, campaignSearchPropTypes)
      break
    case 'emails':
      assignIn(propTypes, omit(templateSearchPropTypes, ['contentType']))
      break
    case 'keywords':
      assignIn(propTypes, keywordSearchPropTypes)
      break
    case 'landingPages':
      assignIn(propTypes, landingPageSearchPropTypes)
      break
    case 'messageTemplates':
      assignIn(propTypes, omit(templateSearchPropTypes, ['contentType']))
      break
    case 'voiceDrops':
      assignIn(propTypes, omit(templateSearchPropTypes, ['contentType']))
      break
  }

  return propTypes
}

export function addRemoveSelectedPropTypesForContent ({ contentType }) {
  return {
    bundleId: PropType.string.isRequired,
    contentType: PropType.oneOf(bundleContentTypes),
    [`${contentType}`]: PropType.arrayOf(PropType.string).isRequired
  }
}

export function addRemoveAllInQueryPropTypesForContent ({ contentType }) {
  const propTypes = {
    bundleId: PropType.string.isRequired,
    contentType: PropType.oneOf(bundleContentTypes)
  }

  switch (contentType) {
    case 'attachments':
      assignIn(propTypes, omit(attachmentSearchPropTypes, 'paging'))
      break
    case 'campaigns':
      assignIn(propTypes, omit(campaignSearchPropTypes, 'paging'))
      break
    case 'emails':
      assignIn(propTypes, omit(templateSearchPropTypes, ['paging', 'contentType']))
      break
    case 'keywords':
      assignIn(propTypes, omit(keywordSearchPropTypes, 'paging'))
      break
    case 'landingPages':
      assignIn(propTypes, omit(landingPageSearchPropTypes, 'paging'))
      break
    case 'messageTemplates':
      assignIn(propTypes, omit(templateSearchPropTypes, ['paging', 'contentType']))
      break
    case 'voiceDrops':
      assignIn(propTypes, omit(templateSearchPropTypes, ['paging', 'contentType']))
      break
  }

  return propTypes
}
