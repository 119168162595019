import * as React from 'react'
import { memo } from 'react'
const SvgTags = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#D0FAE4" rx={4} />
        <path
            fill="#05976A"
            fillRule="evenodd"
            d="M12.988 6c-.41 0-.793.163-1.08.442L6.453 11.82l-.003.002c-.6.6-.6 1.575 0 2.175l3.554 3.554c.6.6 1.575.6 2.175 0l5.374-5.453.001-.002c.286-.286.446-.678.446-1.083V7.537A1.54 1.54 0 0 0 16.463 6h-3.475ZM7.22 12.594l5.45-5.371a.454.454 0 0 1 .318-.132h3.475c.244 0 .446.202.446.446v3.475c0 .12-.048.234-.128.313l-5.374 5.454v.001a.448.448 0 0 1-.631-.001L7.22 13.224a.448.448 0 0 1 0-.63Zm8.301-3.57a.545.545 0 0 0-1.09 0v.006a.545.545 0 1 0 1.09 0v-.006Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgTags)
export default Memo
