import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  fromFormattedName: PropType.string,
  space: PropType.bool
}

const defaultProps = {
  fromFormattedName: null,
  space: false
}

const log = logger({ enabled: false, tags: ['MessageThreadItemContentName'] })

const MessageThreadItemContentName = ({ direction, fromFormattedName, space }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!space || direction !== 'in' || !fromFormattedName) { return null }

  return (
    <div className='flex flex-col mb-1 label-md-strong'>
      {fromFormattedName}
    </div>
  )
}

MessageThreadItemContentName.displayName = 'MessageThreadItemContentName'
MessageThreadItemContentName.propTypes = propTypes
MessageThreadItemContentName.defaultProps = defaultProps

export default MessageThreadItemContentName
