import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import ListDialog from '../../../components/dialog/ListDialog'
import KeywordList from '../../../components/keywords/KeywordList'
import { campaignTypeLabel } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: true, contacts: ['EventKeywordDetail'] })

const EventKeywordDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, contacts: [campaignEvent?._id] })

  const setKeywordDialogRef = useRef()
  const confirmRemoveKeywordDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success(`Keyword ${reply.model.keyword ? 'updated' : 'removed'}`)
    onChange?.(reply)
  }, [onChange])
  const { call: updateCall } = useService(service, { onReplyOk: handleReplyOk })

  const handlePromptForSetKeyword = useCallback(() => { setKeywordDialogRef.current.open() }, [])
  const handlePromptForRemoveKeyword = useCallback(() => { confirmRemoveKeywordDialogRef.current.open() }, [])

  const handleSetKeyword = useCallback((keyword) => {
    updateCall(campaignEvent._id, { keyword: keyword._id })
  }, [campaignEvent._id, updateCall])

  const handleRemoveKeyword = useCallback(() => {
    updateCall(campaignEvent._id, { keyword: null })
  }, [campaignEvent._id, updateCall])

  return (
    <>
      <DetailSection
        description='The keyword below will be applied to each contact after this campaign message is sent (processed).
          Note this means the keyword will be applied whether or not this message is ultimately sent and received successfully.'
        initialEditButtonText='Set Keyword'
        showClearIcon={!!campaignEvent.keyword}
        showEditIcon={!!campaignEvent.keyword}
        showInitialEditButton={!campaignEvent.keyword}
        title='Apply Keyword to Contact After Message is Sent'
        onClearClick={handlePromptForRemoveKeyword}
        onEditClick={handlePromptForSetKeyword}
        onInitialEditClick={handlePromptForSetKeyword}
      >
        {campaignEvent.keyword?.word}
      </DetailSection>
      <ListDialog
        ref={setKeywordDialogRef}
        list={KeywordList}
        multiple={false}
        subtitle={`${campaignTypeLabel(campaignEvent.campaignType)} Message`}
        title='Set Keyword'
        type='select'
        search
        onSubmitSelected={handleSetKeyword}
      />
      <ConfirmDialog
        ref={confirmRemoveKeywordDialogRef}
        description='Are you sure you want to remove the keyword from this campaign message?'
        title='Remove Keyword?'
        onConfirm={handleRemoveKeyword}
      />
    </>
  )
}

EventKeywordDetail.displayName = 'EventKeywordDetail'
EventKeywordDetail.propTypes = propTypes
EventKeywordDetail.defaultProps = defaultProps

export default EventKeywordDetail
