import { useCallback, useRef } from 'react'

import CreateAction from '../../../components/bundles/actions/CreateAction'
import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import { Bundles as BundlesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const BundleEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddBundle = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <BundlesEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong'>Create New Bundle</h1>
        <p className='label-sm text-center'>
          Learn how to use
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/bundles'
            label='Bundles'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddBundle}>
          Add Bundle
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

BundleEmptyListContent.displayName = 'BundleEmptyListContent'
BundleEmptyListContent.propTypes = propTypes
BundleEmptyListContent.defaultProps = defaultProps

export default BundleEmptyListContent
