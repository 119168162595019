import * as React from "react";
import { memo } from "react";
const SvgAutomation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Automation_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm-.687 6.373A2.17 2.17 0 0 0 9.189 6.2C8.556 6.49 8 7.127 8 8v8c0 .873.556 1.511 1.19 1.8a2.17 2.17 0 0 0 2.123-.173l5.824-4.003a1.953 1.953 0 0 0 0-3.247l-5.82-4.001-.004-.003ZM10 15.971V8.03a.17.17 0 0 1 .186-.004L15.97 12l-5.782 3.973v.001A.17.17 0 0 1 10 15.97Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Automation_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAutomation);
export default Memo;
