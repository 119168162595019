import * as React from "react";
import { memo } from "react";
const SvgQuestion = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm8-2c0-2.212 1.788-4 4-4h1c2.212 0 4 1.788 4 4v.15a3.999 3.999 0 0 1-2.213 3.574l-2.34 1.17a1 1 0 1 1-.894-1.788l2.34-1.17A2 2 0 0 0 15 10.15V10c0-1.108-.892-2-2-2h-1c-1.108 0-2 .892-2 2a1 1 0 1 1-2 0Zm5 8a1 1 0 1 0-2 0v.01a1 1 0 1 0 2 0V18Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgQuestion);
export default Memo;
