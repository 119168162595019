import * as React from "react";
import { memo } from "react";

const SvgMarkDark = (props) => (
  <svg
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.194 16.641c-.56-1.314-1.354-2.44-2.413-3.468a11.432 11.432 0 0 0-3.569-2.313 11.271 11.271 0 0 0-4.364-.86h-10.61c-1.55 0-3.07.296-4.354.86a10.95 10.95 0 0 0-5.982 5.762 10.342 10.342 0 0 0-.902 4.26v10.14c0 1.511.314 2.994.892 4.239.56 1.314 1.354 2.441 2.413 3.469 1.01.969 2.206 1.75 3.57 2.313 1.372.573 2.843.86 4.363.86h21.809V20.9c.04-1.384-.255-2.856-.853-4.26Zm-4.07 4.24V37.07H21.238c-3.481 0-6.315-2.718-6.315-6.049v-10.14c0-3.34 2.834-6.048 6.315-6.048H31.76c3.51 0 6.364 2.718 6.364 6.048Z"
      fill="#000"
    />
    <path
      d="M45.989 22.68v22.197H22.925c1.235 2.51 3.755 4.23 6.648 4.23h19.534v-20.23a7.758 7.758 0 0 0-3.118-6.207v.01Z"
      fill="#000"
    />
  </svg>
);

const Memo = memo(SvgMarkDark);
export default Memo;
