import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { generateAdvancedSearchFromSearchData, generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/unlayerTemplateListSearchBar'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import ActionMenu from '../action-menu/ActionMenu'
import ActionMenuItem from '../action-menu/ActionMenuItem'
import AdvancedSearchDialog from '../search-bar/AdvancedSearchDialog'
import SearchBar from '../search-bar/SearchBar'
import SearchBarButton from '../search-bar/SearchBarButton'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  search: PropType.object, // eslint-disable-line react/forbid-prop-types
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  placeholder: undefined,
  search: null,
  sortList: undefined
}

const unlayerTemplateTypeOptionsForSearch = [
  { label: 'Email', value: 'email' },
  { label: 'Landing Page', value: 'landingPage' }
]

const log = logger({ enabled: false, tags: ['UnlayerTemplateListSearchBar'] })

const UnlayerTemplateListSearchBar = ({
  search: initialSearch,
  placeholder,
  sortList,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()

  const [advancedSearchData, setAdvancedSearchData] = useState(generateAdvancedSearchFromSearchData(initialSearch))

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])
  const handleSearchBarReset = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData)
  }, [advancedSearchData])

  const formControls = [
    { label: 'Term', name: 'term' },
    {
      type: 'radiogroup',
      name: 'type',
      label: 'Template Type',
      options: [...unlayerTemplateTypeOptionsForSearch]
    }
  ]

  const handleTypeFilterClick = useCallback((type) => {
    const newAdvancedSearchData = { ...advancedSearchData, type }
    setAdvancedSearchData(newAdvancedSearchData)
    const search = generateSearchFromAdvancedSearchFormData(newAdvancedSearchData)
    searchBarRef.current.update(search)
  }, [advancedSearchData])

  const actionMenuItems = unlayerTemplateTypeOptionsForSearch.map(({ label, value }) => {
    return <ActionMenuItem key={value} label={label} onClick={() => handleTypeFilterClick(value)} />
  })
  const end = (
    <ActionMenu placement='bottom-start' trigger={<SearchBarButton icon='filter' />}>
      {actionMenuItems}
    </ActionMenu>
  )

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        end={end}
        generateSummary={generateSummary}
        placeholder={placeholder}
        search={advancedSearchData}
        sortList={sortList}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onReset={handleSearchBarReset}
        onTermChange={handleSearchBarTermChange}
      />
      <AdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        formControls={formControls}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>
  )
}

UnlayerTemplateListSearchBar.displayName = 'UnlayerTemplateListSearchBar'
UnlayerTemplateListSearchBar.propTypes = propTypes
UnlayerTemplateListSearchBar.defaultProps = defaultProps

export default UnlayerTemplateListSearchBar
