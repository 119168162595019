import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { warning } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import { useMinBreakpoint } from '../../../hooks/useBreakpoint'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'

import InsightsDialog from './InsightsDialog'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['InsightsDetail'] })

const InsightsDetail = ({ appFlow }) => {
  const insightsDialogRef = useRef()

  const roomForShowInsights = useMinBreakpoint('roomToEditDesign')

  const handleShowInsights = useCallback(() => {
    if (roomForShowInsights) {
      insightsDialogRef.current.open()
    } else {
      warning('Detailed insight views are only available on larger screens.')
    }
  }, [roomForShowInsights])

  return (
    <>
      <DetailSection
        description={`View activity insights and logs for this flow.${roomForShowInsights ? '' : ' Only available on larger screens.'}`}
        editIcon='chevron'
        showEditIcon={roomForShowInsights}
        title='Activity'
        hideContent
        onEditClick={handleShowInsights}
      />
      <InsightsDialog
        ref={insightsDialogRef}
        appFlow={appFlow}
      />
    </>
  )
}

InsightsDetail.displayName = 'InsightsDetail'
InsightsDetail.propTypes = propTypes
InsightsDetail.defaultProps = defaultProps

export default InsightsDetail
