import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Profile } from '../../../svg/icons'

const propTypes = {
  mediaUrl: PropType.string,
  size: PropType.oneOf(['sm', 'md', 'lg'])
}

const defaultProps = {
  mediaUrl: null,
  size: 'md'
}

const log = logger({ enabled: false, tags: ['VcardMessageFragment'] })

const VcardMessageFragment = ({ mediaUrl, size }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!mediaUrl) { return }

  if (size === 'sm') {
    return (<div>(contact card)</div>)
  }

  return (
    <div className='flex items-center'>
      <div className={`bg-neutral-200 ${size === 'lg' ? 'rounded-lg p-3' : 'rounded-md p-2'}`}>
        <div className='label-xs-strong text-black flex flex-row gap-2 items-center'>
          <Profile className='h-4' />
          {' '}
          Contact Card
        </div>
        {size === 'lg' && (
          <div className='label-xs-medium'>
            Your Project Broadcast contact information.
          </div>
        )}
      </div>
    </div>
  )
}

VcardMessageFragment.displayName = 'VcardMessageFragment'
VcardMessageFragment.propTypes = propTypes
VcardMessageFragment.defaultProps = defaultProps

export default VcardMessageFragment
