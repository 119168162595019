import { useRef } from 'react'

import Banner from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import DangerousConfirmDialog from '../../../components/dialog/DangerousConfirmDialog'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const log = logger({ enabled: true, tags: ['Style Guide', 'ConfirmDialogs'] })

function ConfirmDialogs () {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmDialogRef1 = useRef(null)

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Confirm Dialogs' />
      <PanelContent>
        <div className='p-4 flex flex-row gap-4'>
          <ConfirmDialog
            ref={confirmDialogRef1}
            description='Clicking "Confirm" will result in positive changes.'
            title='Are you sure?'
            trigger={<Button size='sm'>Primary Trigger</Button>}
            onConfirm={() => { log.info('Confirmed!') }}
          />
          <Button size='sm' onClick={() => confirmDialogRef1.current.open()}>Trigger By Ref</Button>
        </div>
        <div className='p-4'>
          <DangerousConfirmDialog
            confirmButtonText='I understand, do it!'
            confirmValue='DO IT'
            title='Are you absolutely sure?'
            trigger={<Button size='sm' theme='danger'>Delete</Button>}
            onConfirm={() => { log.info('Delete it!') }}
          >
            <Banner className='mb-4' type='warning'>Please read the following!</Banner>
            <p className='mb-2'>Some descriptive text about what is going to happen after confirming.</p>
            <p>
              This action
              {' '}
              <strong>cannot</strong>
              {' '}
              be undone!
            </p>
          </DangerousConfirmDialog>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default ConfirmDialogs
