import PropType from 'prop-types'

import { campaignTypeLabel } from '../../helpers/campaign'
import useLogger from '../../hooks/useLogger'
import { formatName } from '../../lib/formatters'
import logger from '../../lib/logger'
import campaignShape from '../../prop-types/shapes/campaign'
import { Dialog, PeopleGroup } from '../../svg/icons'
import { Campaign } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(campaignShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['CampaignListItemContent'] })

const CampaignListItemContent = ({ item }) => {
  useLogger({ log, lifecycle: true, tags: [item?._id] })

  const contactNames = item?.contacts.subset.map(formatName).join(', ')
  const eventMessages = item?.events.map((event) => event.name).join(', ')

  return (
    <>
      <Campaign className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {item.name}
        </div>
        <div className='label-sm text-neutral-500'>
          {campaignTypeLabel(item.type)}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Dialog className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={item?.events.length} />
          <div className='line-clamp-1 break-words'>{eventMessages}</div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <PeopleGroup className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={item?.contacts.length} />
          <div className='line-clamp-1 break-words'>{contactNames}</div>
        </div>
      </div>
    </>
  )
}

CampaignListItemContent.displayName = 'CampaignListItemContent'
CampaignListItemContent.propTypes = propTypes
CampaignListItemContent.defaultProps = defaultProps

export default CampaignListItemContent
