import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import BlockingDialog from '../../../components/dialog/BlockingDialog'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { pluralize } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'
import { toggleState as toggleStateService } from '../../../services/appFlow'
import { Dynamic, Warning } from '../../../svg/icons'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired,
  onChange: PropType.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  appFlowErrors: PropType.arrayOf(PropType.object)
}

const defaultProps = {
  appFlowErrors: null
}

const log = logger({ enabled: false, tags: ['EditorHeaderFlowControl'] })

const EditorHeaderFlowControl = forwardRef(({ appFlow, appFlowErrors, onChange }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const blockingDialogRef = useRef()

  const { call: toggleState } = useService(toggleStateService, { onReplyOk: onChange })
  const onToggleState = useCallback(() => {
    toggleState(appFlow._id)
  }, [appFlow._id, toggleState])

  const errorDescription = useMemo(() => {
    return `Your Flow has ${pluralize({ count: appFlowErrors?.length || 0, singular: 'Error', includeCount: true })}`
  }, [appFlowErrors])

  const handleFlowErrorsOpen = useCallback(() => {
    blockingDialogRef.current.open()
  }, [])

  const handleFlowErrorsClose = useCallback(() => {
    blockingDialogRef.current.close()
  }, [])

  useImperativeHandle(ref, () => ({
    hasErrors () {
      return appFlowErrors?.length > 0
    },
    async startAppFlow () {
      if (appFlow.state !== 'running') {
        return toggleState(appFlow._id)
      }
    }
  }), [appFlow._id, appFlow.state, appFlowErrors?.length, toggleState])

  return appFlow.state === 'running'
    ? <PanelHeaderButton
        size='sm'
        theme='danger'
        title='Stop Flow'
        variant='filled'
        onClick={onToggleState}
      />
    : (
      <>
        {appFlowErrors?.length > 0
          ? (
            <div className='flex flex-row items-center gap-2 mr-2 text-danger'>
              <Button
                icon={<Warning />}
                size='sm'
                theme='danger'
                variant='none'
                onClick={handleFlowErrorsOpen}
              >
                Flow Errors
              </Button>
              <BlockingDialog
                ref={blockingDialogRef}
                ariaDescription={errorDescription}
                title={errorDescription}
                onClick={handleFlowErrorsClose}
              >
                <p className='paragraph-sm text-neutral-500 text-center mb-[20px]'>
                  Your App Flow currently has errors that need to be resolved
                  before it can be started. Expand the component panel on the right
                  to view and resolve.
                </p>
              </BlockingDialog>
            </div>
            )
          : null}
        <PanelHeaderButton
          disabled={!appFlowErrors || appFlowErrors.length > 0}
          size='sm'
          start={<Dynamic />}
          theme='primary'
          title='Start Flow'
          variant='filled'
          onClick={onToggleState}
        />
      </>)
})

EditorHeaderFlowControl.displayName = 'EditorHeaderFlowControl'
EditorHeaderFlowControl.propTypes = propTypes
EditorHeaderFlowControl.defaultProps = defaultProps

export default EditorHeaderFlowControl
