import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import LandingPagesDetail from '../screens/landing-pages/LandingPagesDetail'
import LandingPagesFolderDetail from '../screens/landing-pages/LandingPagesFolderDetail'
import LandingPagesViews from '../screens/landing-pages/LandingPagesViews'

const landingPageRoutes = [
  {
    path: ':landingPageId',
    element: <LandingPagesDetail />
  },
  {
    path: ':landingPageId/views',
    element: <LandingPagesViews />
  }
]

const routes = [
  ...landingPageRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<LandingPagesFolderDetail />} />,
    children: [...landingPageRoutes]
  }
]

export default routes
