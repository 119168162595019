import { useCallback, useMemo } from 'react'

import { success } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import WarningDetailSection from '../../components/detail-sections/WarningDetailSection'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import logger from '../../lib/logger'
import { updateCanSpamCompliance } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

import validationSuite from './CanSpamCompliance.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CanSpamCompliance'] })

const formControls = [
  {
    name: 'physicalMailingAddress.addressLine1',
    label: 'Physical Mailing Address',
    placeholder: 'Street / P.O. Box',
    type: 'text'
  },
  {
    name: 'physicalMailingAddress.addressLine2',
    label: '',
    placeholder: 'Apt / Suite #',
    type: 'text'
  },
  {
    type: 'text',
    name: 'physicalMailingAddress.city',
    label: '',
    placeholder: 'City'
  },
  {
    type: 'controlgroup',
    name: 'group2',
    containerClassName: 'flex-wrap',
    formControls: [
      {
        type: 'text',
        name: 'physicalMailingAddress.stateProvince',
        label: '',
        placeholder: 'State/Province',
        containerClassName: 'flex-grow'
      },
      {
        type: 'text',
        name: 'physicalMailingAddress.postal',
        label: '',
        placeholder: 'Postal Code'
      }
    ]
  },
  {
    type: 'checkbox',
    name: 'agreesToCanSpamAct',
    label: 'I have read, understand, and agree to the CAN-SPAM Act.',
    labelAlignment: 'right'
  }
]

const CanSpamCompliance = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()

  const handleReplyOk = useCallback(() => {
    success('Your changes have been saved.')
  }, [])
  const { call } = useServiceAndAction(updateCanSpamCompliance, updateAction, { onReplyOk: handleReplyOk })

  const defaultValues = useMemo(() => {
    return {
      'physicalMailingAddress.addressLine1': currentUser?.canSpamCompliance?.physicalMailingAddress.addressLine1,
      'physicalMailingAddress.addressLine2': currentUser?.canSpamCompliance?.physicalMailingAddress.addressLine2,
      'physicalMailingAddress.city': currentUser?.canSpamCompliance?.physicalMailingAddress.city,
      'physicalMailingAddress.stateProvince': currentUser?.canSpamCompliance?.physicalMailingAddress.stateProvince,
      'physicalMailingAddress.postal': currentUser?.canSpamCompliance?.physicalMailingAddress.postal,
      agreesToCanSpamAct: currentUser?.canSpamCompliance?.agreesToCanSpamAct
    }
  }, [currentUser])

  const handleSubmit = useCallback((data) => { call(data) }, [call])

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='CAN-SPAM Compliance'
      />
      <PanelContent className='p-4'>
        <WarningDetailSection text='CAN-SPAM compliance is a legal requirement.  It requires that you provide a valid physical address where you can receive mail.' />
        <p className='mb-4'>
          Please review the CAN-SPAM Act at
          {' '}
          <a
            className='link'
            href='https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business'
            rel='noreferrer'
            target='_blank'
          >
            https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business
          </a>
        </p>
        <p className='mb-4'>Your physical mailing address will be added to the footer of every email along with an unsubscribe link.</p>
        <Form
          defaultValues={defaultValues}
          formControls={formControls}
          id='canSpamComplianceForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div>
          <Button
            className='flex-grow md:flex-none'
            form='canSpamComplianceForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

CanSpamCompliance.displayName = 'CanSpamCompliance'
CanSpamCompliance.propTypes = propTypes
CanSpamCompliance.defaultProps = defaultProps

export default CanSpamCompliance
