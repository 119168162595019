import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { provisionNewNumber as provisionNewNumberService } from '../../../services/subscription'

const propTypes = {
  numbers: PropType.objectOf(
    PropType.arrayOf(
      PropType.shape({
        nationalNumber: PropType.string.isRequired,
        number: PropType.string.isRequired
      })
    )
  )
}

const defaultProps = {
  numbers: {}
}

const log = logger({ enabled: true, tags: ['SelectNumberForm'] })

const SelectNumberForm = ({ numbers }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [selectedNumber, setSelectedNumber] = useState()
  const [disabledNumbers, setDisabledNumbers] = useState([])
  const optionsData = []
  const formRef = useRef()

  const onReplyNotOk = useCallback(() => {
    setDisabledNumbers((prev) => [...prev, selectedNumber])
  }, [selectedNumber])

  const onReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const onReplyOk = useCallback(() => {
    window.location.href = '/'
  }, [])

  const { call: provisionNewNumber } = useService(provisionNewNumberService, { onReplyOk, onReplyNotOk, onReply })

  const onSubmit = useCallback(({ number }) => {
    log.debug('selected number:', number)
    setSelectedNumber(number)
    provisionNewNumber({ number })
  }, [provisionNewNumber])

  const formSettings = {
    id: 'selectNumberForm',
    ref: formRef,
    defaultValues: {},
    formControls: [{
      autoFocus: true,
      name: 'number',
      type: 'select',
      label: 'Project Broadcast Phone Number',
      placeholder: 'Choose a phone number',
      hint: 'This will be the phone number all of your Project Broadcast messages originate from.',
      required: true,
      options: optionsData
    }],
    onSubmit
  }

  if (numbers) {
    for (const key in numbers) {
      optionsData.push({
        label: key,
        value: numbers[key].map((info) => {
          return {
            label: info.nationalNumber,
            value: info.number,
            disabled: disabledNumbers.includes(info.number)
          }
        })
      })
    }
  }

  return (
    <>
      <Form
        {...formSettings}
        className='text-left'
        preventMultipleSubmits
      />
      <div className='flex flex-row gap-4 items-center'>
        <Button
          className='flex-grow md:flex-none'
          form={formSettings.id}
          size='sm'
          type='submit'
        >
          Save
        </Button>
      </div>
    </>
  )
}

SelectNumberForm.displayName = 'SelectNumberForm'
SelectNumberForm.propTypes = propTypes
SelectNumberForm.defaultProps = defaultProps

export default SelectNumberForm
