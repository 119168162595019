import { entries } from 'lodash'
import PropType from 'prop-types'
import { useCallback } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: false, tags: ['CustomFieldsDetail'] })

const CustomFieldsDetail = ({ contact, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const transformData = useCallback((data) => {
    // incoming data looks like:
    // {
    //   [id]: [value],
    //   ...
    // }
    // need to ensure the data looks like:
    // {
    //   customFields: [
    //     { _id: '...', value: '...' },
    //     ...
    //   ]
    // }
    const customFields = entries(data).map(([id, value]) => ({ _id: id, value }))
    return { customFields }
  }, [])

  // contacts have a hardcoded set of custom fields even if they're all empty
  const defaultValues = contact?.customFields.reduce((obj, { _id: id, value }) => {
    obj[id] = value
    return obj
  }, {})
  const formControls = contact?.customFields.map((customField) => {
    return {
      name: customField._id,
      label: customField.name,
      hint: `To use this in a message you will use the dynamic field ${customField.code}.`
    }
  })

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Here you can add custom data to your contact, which can be used as dynamic fields in messages.'
      formControls={formControls}
      formDescription={`Updating custom fields for ${contact.formattedName}`}
      formTitle={contact.formattedName}
      objectId={contact._id}
      service={service}
      title='Custom Fields'
      transformData={transformData}
      isPanelDialog
      showEditIcon
      onChange={onChange}
    >
      {contact?.formattedCustomFields}
    </FormDetailSection>
  )
}

CustomFieldsDetail.displayName = 'CustomFieldsDetail'
CustomFieldsDetail.propTypes = propTypes
CustomFieldsDetail.defaultProps = defaultProps

export default CustomFieldsDetail
