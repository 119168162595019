import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as attachmentSearch } from '../../services/attachment'
import List from '../list/List'

import AttachmentListItemContent from './AttachmentListItemContent'

const propTypes = {
  additionalHandlers: PropType.func,
  queryOverrides: PropType.object, // eslint-disable-line react/forbid-prop-types
  searchService: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {},
  queryOverrides: {},
  searchService: attachmentSearch
}

const attachmentResultsFields = [
  '_id',
  'slug', // utilized by the message composer
  'key',
  'name',
  'fileName',
  'fileSize'
]

const log = logger({ enabled: true, tags: ['AttachmentList'] })

const AttachmentList = forwardRef(({ additionalHandlers, queryOverrides, searchService, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tag: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('attachmentCreated', handleBusEvent)
  useBus('attachmentUpdated', handleBusEvent)
  useBus('attachmentDeleted', handleBusEvent)
  useBus('attachmentsDeleted', handleBusEvent)

  additionalHandlers?.('attachment', ref)

  return (
    <List
      ref={ref}
      ListItemContent={AttachmentListItemContent}
      placeholder='Search Attachments'
      queryOverrides={queryOverrides}
      resultsFields={attachmentResultsFields}
      resultsKey='attachments'
      searchService={searchService}
      search
      {...listProps}
    />
  )
})

AttachmentList.displayName = 'AttachmentList'
AttachmentList.propTypes = propTypes
AttachmentList.defaultProps = defaultProps

export default AttachmentList
