import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Aiva as AivaEmptyState } from '../../../svg/empty-states'
import PanelDialog from '../../dialog/PanelDialog'
import PanelHeaderButton from '../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../panels/PanelContent'
import AivaLearnMoreButton from '../AivaLearnMoreButton'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MeetAivaAction'] })

const MeetAivaAction = forwardRef((params, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const panelDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () {
      panelDialogRef.current.open()
    }
  }), [])

  const handleCloseClick = useCallback(() => {
    panelDialogRef.current.close()
  }, [])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel='Meet AIVA'>
      <PanelContent className='relative'>
        <PanelHeaderButton className='absolute top-2 right-2' icon='close' onClick={handleCloseClick} />
        <div className='flex flex-col items-center gap-6 my-12 w-full'>
          <div className='w-full bg-indigo-50'>
            <AivaEmptyState className='max-w-[600px] mx-auto' />
          </div>
          <div>
            <h1 className='label-lg-strong text-center'>Meet AIVA</h1>
            <p className='label-medium text-center max-w-[240px]'>
              Once you start typing out your message, AIVA will activate to assist you in improving your message.
            </p>
          </div>
          <AivaLearnMoreButton />
        </div>
      </PanelContent>
    </PanelDialog>
  )
})

MeetAivaAction.displayName = 'MeetAivaAction'
MeetAivaAction.propTypes = propTypes
MeetAivaAction.defaultProps = defaultProps

export default MeetAivaAction
