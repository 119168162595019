import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Pill from '../../components/pill/Pill'
import { Placeholder } from '../../svg/icons'

function Pills () {
  return (
    <Panel>
      <PanelHeader title='Pills' />
      <PanelContent>
        <div className='p-4 flex flex-col gap-2'>
          <div className='flex flex-row gap-2'>
            <Pill value={1} />
            <Pill theme='danger' value={1} />
          </div>
          <div className='flex flex-row gap-2'>
            <Pill start={<Placeholder />} value={1} />
            <Pill start={<Placeholder />} theme='danger' value={1} />
          </div>
          <div className='flex flex-row gap-2'>
            <Pill end={<Placeholder />} value={1} />
            <Pill end={<Placeholder />} theme='danger' value={1} />
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Pills
