import * as React from "react";
import { memo } from "react";
const SvgClipboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.286 3.429c0-.234.195-.429.429-.429h5.3l5.128 5.128v8.157a.433.433 0 0 1-.428.429h-10a.433.433 0 0 1-.429-.428V3.428ZM8.715 1a2.433 2.433 0 0 0-2.429 2.429v1.857H4.43A2.433 2.433 0 0 0 2 7.715v12.857A2.433 2.433 0 0 0 4.429 23h10a2.433 2.433 0 0 0 2.428-2.428v-1.858h1.858a2.433 2.433 0 0 0 2.428-2.428V7.713a1 1 0 0 0-.293-.707l-5.714-5.714A1 1 0 0 0 14.429 1H8.715Zm6.142 17.714H8.715a2.433 2.433 0 0 1-2.429-2.428v-9H4.43A.433.433 0 0 0 4 7.715v12.857c0 .233.195.428.429.428h10a.433.433 0 0 0 .428-.428v-1.858Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgClipboard);
export default Memo;
