import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import ExternalLink from './ExternalLink'

const propTypes = {
  label: PropType.string.isRequired,
  className: PropType.string
}

const defaultProps = {
  className: 'link'
}

const log = logger({ enabled: false, tags: ['TermsLink'] })

const TermsLink = ({ className, label, ...rest }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <ExternalLink
      className={className}
      href={process.env.REACT_APP_TERMS_URL}
      label={label}
      {...rest}
    />
  )
}

TermsLink.displayName = 'TermsLink'
TermsLink.propTypes = propTypes
TermsLink.defaultProps = defaultProps

export default TermsLink
