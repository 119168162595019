import { formatName } from '../lib/formatters'
import useStore from '../store'

import messageFromJSON from './message'
import proxy from './proxy'

export default function spaceFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get isCurrentUserOwner () {
      const currentUserId = useStore.getState().currentUser?._id
      return currentUserId === json.user
    },

    get userCount () {
      return json.users?.length || 0
    },

    get userSubsetFormattedNames () {
      return json.users?.subset?.map((user) => formatName(user)).join(', ')
    }
  }

  const model = proxy(json, overrides)
  model.lastMessage = messageFromJSON(json.lastMessage)
  return model
}
