import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('amount', 'Must be a number greater than 0.', () => {
    enforce(data.amount).isNotEmpty()
  })
})

export default validationSuite
