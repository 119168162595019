import PropType from 'prop-types'

import Button from '../../../components/buttons/Button'
import { ChevronLeft } from '../../../svg/icons'

const propTypes = {
  children: PropType.node.isRequired,
  goBack: PropType.func
}

const defaultProps = {
  goBack: null
}

const Wrapper = ({ goBack, children }) => {
  if (goBack) {
    return (
      <div className='grid grid-cols-3 items-center'>
        <Button
          className='justify-self-start text-neutral-900'
          size='sm'
          start={<ChevronLeft />}
          theme='primary'
          variant='text'
          onClick={goBack}
        >
          Back
        </Button>
        {children}
      </div>
    )
  } else {
    return children
  }
}

Wrapper.displayName = 'Wrapper'
Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
