import { useCallback, useRef } from 'react'

import List from '../../../components/list/List'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useBus from '../../../hooks/useBus'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { search as mfaSearch } from '../../../services/multi-factor-authentication'

import EmptyListContent from './EmptyListContent'
import ListHeaderMoreMenu from './ListHeaderMoreMenu'
import ListItemContent from './ListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MultiFactorAuthenticationList'] })

const MultiFactorAuthenticationList = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()

  const reloadList = useCallback(() => {
    listRef.current.reload()
  }, [])
  useBus('mfaMethodAdded', reloadList)
  useBus('mfaMethodRemoved', reloadList)

  return (
    <Panel>
      <PanelHeader
        end={<ListHeaderMoreMenu listRef={listRef} />}
        start={<PanelHeaderBackButton />}
        title='Multi-Factor Authentication'
      />
      <PanelContent>
        <List
          ref={listRef}
          EmptyListContent={EmptyListContent}
          ListItemContent={ListItemContent}
          resultsKey='methods'
          searchService={mfaSearch}
          type='list'
        />
      </PanelContent>
    </Panel>
  )
}

MultiFactorAuthenticationList.displayName = 'MultiFactorAuthenticationList'
MultiFactorAuthenticationList.propTypes = propTypes
MultiFactorAuthenticationList.defaultProps = defaultProps

export default MultiFactorAuthenticationList
