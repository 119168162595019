import { useEffect, useRef } from 'react'

import useFirstRender from './useFirstRender'

function useUpdateEffect (effect, deps) {
  const isFirstRender = useFirstRender()
  const unmountedRef = useRef(false)

  // if we pass an empty array, treat it as not passing anything
  if (deps?.length === 0) { deps = undefined }

  useEffect(() => {
    // we'll skip the update effect for unmount updates too
    return () => { unmountedRef.current = true }
  }, [])

  useEffect(() => {
    if (!isFirstRender && !unmountedRef.current) {
      return effect()
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useUpdateEffect
