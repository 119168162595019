import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluck } from 'underscore'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { CONTENT_TYPE } from '../../../lib/folder'
import logger from '../../../lib/logger'
import templateShape from '../../../prop-types/shapes/template'
import { bulkDelete as bulkDeleteService } from '../../../services/template'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const lookups = {
  [CONTENT_TYPE.email]: { path: '/emails', label: 'Emails', event: 'emailsDeleted', idParam: 'emailId' },
  [CONTENT_TYPE.messageTemplate]: { path: '/message-templates', label: 'Message Templates', event: 'messageTemplatesDeleted', idParam: 'messageTemplateId' },
  [CONTENT_TYPE.voiceDrop]: { path: '/voice-drops', label: 'Voice Drops', event: 'voiceDropsDeleted', idParam: 'voiceDropId' }
}

const propTypes = {
  folderContentType: PropType.oneOf([
    CONTENT_TYPE.email,
    CONTENT_TYPE.messageTemplate,
    CONTENT_TYPE.voiceDrop
  ]).isRequired,
  templates: PropType.arrayOf(PropType.shape(templateShape)).isRequired
}

const log = logger({ enabled: true, tags: ['BulkDeleteAction'] })

const BulkDeleteAction = forwardRef(({ folderContentType, templates }, ref) => {
  useLogger({ log, lifecycle: false, tags: [folderContentType] })

  const confirmRef = useRef()
  const navigate = useNavigate()
  const params = useParams()

  const handleReplyOk = useCallback(() => {
    const { event, label, path, idParam } = lookups[folderContentType]
    success(`${label} have been deleted`)
    bus.emit(event, templates)
    bus.emit('closeBulkEditDialog')
    const templateIds = new Set(pluck(templates, '_id'))
    if (templateIds.has(params[idParam])) { navigate(path) }
  }, [folderContentType, templates, params, navigate])
  const { call: bulkDelete } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!templates || !templates.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [templates])

  const handleConfirm = useCallback(() => {
    bulkDelete({ templateIds: pluck(templates, '_id') })
  }, [bulkDelete, templates])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
