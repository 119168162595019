import { useEffect } from 'react'

import logger from '../lib/logger'
import { current } from '../services/user'
import useStore from '../store'
import { update as updateUserAction } from '../store/actions/currentUser'

import useServiceAndAction from './useServiceAndAction'

// TODO: need plan name and plan renewal date

const log = logger({ enabled: true, tags: ['useCurrentUser'] })

const useCurrentUser = ({ alsoReload = false } = {}) => {
  const currentUser = useStore((state) => state.currentUser)
  const { call: reloadCurrentUser } = useServiceAndAction(current, updateUserAction)
  useEffect(() => {
    if (alsoReload) { reloadCurrentUser() }
  }, [alsoReload, reloadCurrentUser])
  return currentUser
}

export default useCurrentUser
