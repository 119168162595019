import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { CONTENT_TYPE, stateKeyFromStackKey } from '../../../lib/folder'
import logger from '../../../lib/logger'
import { contentType as contentTypeShape } from '../../../prop-types/shapes/folder'
import { removeAllInQuery as removeAllInQueryService, removeSelected as removeSelectedService } from '../../../services/folder'
import AttachmentList from '../../attachments/AttachmentList'
import { notify } from '../../banners/Banner'
import BundleList from '../../bundles/BundleList'
import CampaignList from '../../campaigns/CampaignList'
import ConfigurableFormList from '../../configurable-forms/ConfigurableFormList'
import ContactList from '../../contacts/ContactList'
import ContestList from '../../contests/ContestList'
import ListDialog from '../../dialog/ListDialog'
import EmailList from '../../emails/EmailList'
import KeywordList from '../../keywords/KeywordList'
import LandingPageList from '../../landing-pages/LandingPageList'
import MessageTemplateList from '../../message-templates/MessageTemplateList'
import QrCodeList from '../../qr-codes/QrCodeList'
import SpaceList from '../../spaces/SpaceList'
import TemplateList from '../../templates/TemplateList'
import TrackableLinkList from '../../trackable-links/TrackableLinkList'
import VoiceDropList from '../../voice-drops/VoiceDropList'

const listMap = {
  [CONTENT_TYPE.attachment]: AttachmentList,
  [CONTENT_TYPE.bundle]: BundleList,
  [CONTENT_TYPE.campaign]: CampaignList,
  [CONTENT_TYPE.configurableForm]: ConfigurableFormList,
  [CONTENT_TYPE.contact]: ContactList,
  [CONTENT_TYPE.contest]: ContestList,
  [CONTENT_TYPE.email]: EmailList,
  [CONTENT_TYPE.keyword]: KeywordList,
  [CONTENT_TYPE.landingPage]: LandingPageList,
  [CONTENT_TYPE.messageTemplate]: MessageTemplateList,
  [CONTENT_TYPE.qrCode]: QrCodeList,
  [CONTENT_TYPE.space]: SpaceList,
  [CONTENT_TYPE.template]: TemplateList, // TODO: remove
  [CONTENT_TYPE.trackableLink]: TrackableLinkList,
  [CONTENT_TYPE.voiceDrop]: VoiceDropList
}

const propTypes = {
  contentType: PropType.oneOf(contentTypeShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['RemoveItemsAction'] })

const RemoveFromFolderAction = forwardRef(({ contentType }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const params = useParams()
  const { folderId } = params
  const listDialogRef = useRef()
  const selectedItemsRef = useRef([])
  const stackKey = useCurrentStackKey()

  const handleReplyOk = useCallback((reply) => {
    const updatedFolder = reply.json
    notify('Item(s) Removed!', 'The items you selected have been removed from this folder.')
    const stateKey = stateKeyFromStackKey(stackKey)
    bus.emit('folderUpdated', updatedFolder)
    bus.emit(`${stateKey}RemovedFromFolder`, selectedItemsRef.current)
  }, [stackKey])

  const { call: removeSelected } = useService(removeSelectedService, { onReplyOk: handleReplyOk })
  const { call: removeAllInQuery } = useService(removeAllInQueryService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      listDialogRef.current.open()
    }
  }), [])

  const handleRemoveAllInQuery = useCallback(({ term }) => {
    removeAllInQuery({ folderId, contentType, term })
  }, [removeAllInQuery, folderId, contentType])

  const handleRemoveSelected = useCallback((selectedItems) => {
    const contentIds = selectedItems.map((item) => item._id)
    selectedItemsRef.current = selectedItems
    removeSelected({ folderId, contentType, contentIds })
  }, [folderId, removeSelected, contentType, selectedItemsRef])

  return (
    <ListDialog
      ref={listDialogRef}
      list={listMap[contentType]}
      queryOverrides={{ folderId }}
      title='Remove Items'
      type='remove'
      multiple
      onSubmitAllInQuery={handleRemoveAllInQuery}
      onSubmitSelected={handleRemoveSelected}
    />
  )
})

RemoveFromFolderAction.displayName = 'RemoveFromFolderAction'
RemoveFromFolderAction.propTypes = propTypes
RemoveFromFolderAction.defaultProps = defaultProps

export default RemoveFromFolderAction
