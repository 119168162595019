import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('description', 'Business Description is required', () => {
    enforce(data.description).isNotEmpty()
  })
  test('description', 'Business Description should contain more details', () => {
    enforce(data.description).longerThan(100)
  })
  test('description', 'Business Description cannot be longer than 4096 characters', () => {
    enforce(data.description).shorterThan(4096)
  })
  test('description', 'Please fill in placeholder name.', () => {
    enforce(data.description).notMatches(/\[.+name\]/i)
  })

  test('messageFlow', 'Opt-in Summary is required', () => {
    enforce(data.messageFlow).isNotEmpty()
  })
  test('messageFlow', 'Opt-in Summary should contain more details', () => {
    enforce(data.messageFlow).longerThan(100)
  })
  test('messageFlow', 'Opt-in Summary cannot be longer than 2048 characters', () => {
    enforce(data.messageFlow).shorterThan(2048)
  })
  test('messageFlow', 'Please fill in placeholder links.', () => {
    enforce(data.messageFlow).notMatches(/\[.+link.+\]/i)
  })

  test('sample1', 'Sample Message 1 is required', () => {
    enforce(data.sample1).isNotEmpty()
  })
  test('sample1', 'Sample Message 1 should contain more details', () => {
    enforce(data.sample1).longerThan(20)
  })
  test('sample1', 'Sample Message 1 cannot be longer than 1024 characters', () => {
    enforce(data.sample1).shorterThan(1024)
  })

  test('sample2', 'Sample Message 2 is required', () => {
    enforce(data.sample2).isNotEmpty()
  })
  test('sample2', 'Sample Message 2 should contain more details', () => {
    enforce(data.sample2).longerThan(20)
  })
  test('sample2', 'Sample Message 2 cannot be longer than 1024 characters', () => {
    enforce(data.sample2).shorterThan(1024)
  })
})

export default validationSuite
