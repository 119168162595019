import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import DangerousConfirmDialog from '../../components/dialog/DangerousConfirmDialog'
import SubscriptionSummary from '../../components/plans/SubscriptionSummary'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { renewImmediately as renewImmediatelyService } from '../../services/subscription'

const propTypes = {
  currentPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ConfirmRenewSubscriptionDialog'] })

const ConfirmRenewSubscriptionDialog = forwardRef(({ currentPlan }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const onReplyOk = useCallback(() => {
    notify('Your renewal request has been submitted.')
  }, [])
  const { call: renewImmediately } = useService(renewImmediatelyService, { onReplyOk })

  const onConfirm = () => {
    renewImmediately()
  }

  return (
    <DangerousConfirmDialog
      ref={ref}
      confirmButtonText='I understand, renew it!'
      confirmValue='RENEW'
      title='Are you sure?'
      onConfirm={onConfirm}
    >
      <SubscriptionSummary plan={currentPlan} reanchorImmediately />
    </DangerousConfirmDialog>
  )
})

ConfirmRenewSubscriptionDialog.displayName = 'ConfirmRenewSubscriptionDialog'
ConfirmRenewSubscriptionDialog.propTypes = propTypes
ConfirmRenewSubscriptionDialog.defaultProps = defaultProps

export default ConfirmRenewSubscriptionDialog
