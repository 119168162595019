import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'
import { MarkColor } from '../../svg/branding'
import { Clock } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape(contactShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['TrackableLinkContactListItemContent'] })

const TrackableLinkContactListItemContent = ({ item: contact }) => {
  useLogger({ log, lifecycle: true, tags: [contact._id] })

  return (
    <>
      <Avatar contactOrUser={contact} size='sm' />
      <div className='flex flex-col ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {contact.formattedName}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{contact.formattedPhoneNumber}</div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1'>
            <FormattedDate date={contact.createdAt} format='extendedDateAndTime' />
          </div>
        </div>
      </div>
    </>
  )
}

TrackableLinkContactListItemContent.displayName = 'TrackableLinkContactListItemContent'
TrackableLinkContactListItemContent.propTypes = propTypes
TrackableLinkContactListItemContent.defaultProps = defaultProps

export default TrackableLinkContactListItemContent
