import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useBus from '../../hooks/useBus'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import {
  chatMessagesAfter,
  pollChatMessage,
  sendChatMessage
} from '../../services/aiva'
import Button from '../buttons/Button'
import AivaMessage from '../forms/controls/AivaMessage'

import AivaSupportAction from './actions/AivaSupportAction'
import AivaChatThread from './AivaChatThread'

const propTypes = {
  draftKey: PropType.string
}

const defaultProps = {
  draftKey: null
}

const log = logger({ enabled: true, tags: ['AivaChat'] })

const AivaChat = forwardRef(({ draftKey }, ref) => {
  useLogger({ log, lifecycle: false, tags: [draftKey || 'popover'] })
  const [generating, setGenerating] = useState(false)
  const [lastMessageId, setLastMessageId] = useState(null)

  const aivaSupportActionRef = useRef()
  const chatThreadRef = useRef()
  const messageControlRef = useRef()

  useBus('aivaChatMessageResent', (sentMessage) => {
    chatThreadRef.current.newMessages([sentMessage])
    pollForSentMessageResponse(sentMessage)
  }, [])

  const handleSyncReplyOk = useCallback((reply) => {
    if (reply.models?.length > 0) {
      chatThreadRef.current.newMessages(reply.models)
    }
  }, [])
  const { call: syncNewMessagesCall } = useService(chatMessagesAfter, { onReplyOk: handleSyncReplyOk })

  useImperativeHandle(ref, () => ({
    syncNewMessages () {
      if (lastMessageId) {
        log.info('Syncing new messages', lastMessageId)
        syncNewMessagesCall({ messageId: lastMessageId }, true)
      }
    }
  }), [lastMessageId, syncNewMessagesCall])

  const { call: pollChatMessageCall } = useService(pollChatMessage)

  const pollForSentMessageResponse = useCallback((sentMessage) => {
    setGenerating(true)
    const POLL_PERIOD = 1500
    const pollFunc = async () => {
      try {
        const pollResult = await pollChatMessageCall(sentMessage._id, true)
        sentMessage = pollResult.model

        if (sentMessage.state !== 'sending') {
          setGenerating(false)
          chatThreadRef.current.updateMessage(sentMessage)
          syncNewMessagesCall({ messageId: sentMessage._id }, true)
        } else {
          setTimeout(pollFunc, POLL_PERIOD)
        }
      } catch (ex) {
        setGenerating(false)
        log.error('Exception while polling', ex)
      }
    }
    setTimeout(pollFunc, POLL_PERIOD)
  }, [pollChatMessageCall, syncNewMessagesCall])

  const handleSendReplyOk = useCallback((reply) => {
    messageControlRef.current.reset()
    const sentMessage = reply.model
    chatThreadRef.current.newMessages([sentMessage])
    pollForSentMessageResponse(sentMessage)
  }, [pollForSentMessageResponse])
  const { call: sendChatMessageCall } = useService(sendChatMessage, { onReplyOk: handleSendReplyOk })

  const handleNeedSupportClick = useCallback(() => {
    aivaSupportActionRef.current.activate()
  }, [])

  const handleSend = useCallback(({ message }) => {
    sendChatMessageCall(message, true)
  }, [sendChatMessageCall])

  const handleLastItemChange = useCallback((message) => {
    if (message?._id) {
      setLastMessageId(message._id)
    }
  }, [])

  return (
    <>
      <AivaChatThread
        ref={chatThreadRef}
        recipientTyping={generating}
        onLastItemChange={handleLastItemChange}
      />
      <div className='border-t border-neutral-200'>
        <h4 className='px-3 label-xs-strong text-center'>
          Responses generated by AIVA
          {/* intentionally omitting space before span here
          */}
          <span className='ml-2'>-</span>
          <div className='inline-block'>
            <Button
              className='!label-xs-strong'
              size='sm'
              variant='text'
              onClick={handleNeedSupportClick}
            >
              Need Support?
            </Button>
            <AivaSupportAction ref={aivaSupportActionRef} />
          </div>
        </h4>
      </div>
      <div className='border-t border-neutral-200'>
        <AivaMessage
          ref={messageControlRef}
          disabled={generating}
          draftKey={draftKey}
          name='message'
          placeholder='What is your question...'
          onSend={handleSend}
        />
      </div>
    </>
  )
})

AivaChat.displayName = 'AivaChat'
AivaChat.propTypes = propTypes
AivaChat.defaultProps = defaultProps

export default AivaChat
