import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import ActionMenuItem from './ActionMenuItem'

const propTypes = {
  label: PropType.node.isRequired,
  className: PropType.string,
  end: PropType.element,
  start: PropType.element
}

const defaultProps = {
  className: null,
  end: null,
  start: null
}

const log = logger({ enabled: false, tags: ['ActionMenuItem'] })

const DangerousActionMenuItem = forwardRef(({ className, label, ...rest }, ref) => {
  useLogger({ log, lifecycle: true, tags: [label] })

  const computedClassName = classNames('text-danger focus:bg-red-50 hover:bg-red-50', className)

  return (
    <ActionMenuItem
      ref={ref}
      className={computedClassName}
      label={label}
      {...rest}
    />
  )
})

DangerousActionMenuItem.displayName = 'ActionMenuItem'
DangerousActionMenuItem.propTypes = propTypes
DangerousActionMenuItem.defaultProps = defaultProps

export default DangerousActionMenuItem
