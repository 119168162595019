import { useCallback } from 'react'
import { create, enforce, only, test } from 'vest'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import { masks } from '../../components/forms/controls/TextInput'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateCallForwarding as updateCallForwardingService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const CallForwarding = () => {
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('Call Forwarding updated successfully.')
  }, [])
  const { call: updateCallForwarding } = useServiceAndAction(updateCallForwardingService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='callForwardingForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Call Forwarding'
      />
      <PanelContent className='p-5'>
        <Form
          defaultValues={{
            callForwardEnabled: currentUser.callForwardEnabled,
            callForwardNumber: masks.tel(currentUser.callForwardNumber),
            callFromAnyoneEnabled: currentUser.callFromAnyoneEnabled
          }}
          formControls={[
            {
              type: 'tel',
              name: 'callForwardNumber',
              label: 'Phone Number'
            },
            {
              type: 'checkbox',
              name: 'callForwardEnabled',
              label: 'Enabling will forward calls made to your Project Broadcast phone number to your Call Forwarding phone number.',
              title: 'Enabled'
            },
            {
              type: 'checkbox',
              name: 'callFromAnyoneEnabled',
              label: 'By default, only calls originating from your Project Broadcast contacts are forwarded to help reduce potential spam. By turning this on you are allowing all calls to be forwarded.',
              title: 'Allow All'
            }
          ]}
          id='callForwardingForm'
          validationSuite={create((data = {}, currentField) => {
            only(currentField)

            test('callForwardNumber', 'Phone Number is required', () => {
              enforce(data.callForwardNumber).isNotEmpty()
            })
          })}
          onSubmit={updateCallForwarding}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='callForwardingForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

CallForwarding.displayName = 'CallForwarding'

export default CallForwarding
