import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Error } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MessageThreadItemPotentialStop'] })

const MessageThreadItemPotentialStop = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { aivaSettings } = useCurrentUser()

  return aivaSettings.stopDetect !== 'off'
    ? (
      <div className='flex flex-row items-center italic label-sm mt-1 gap-1'>
        <Error height='14' width='14' />
        <span>Potential opt-out</span>
      </div>
      )
    : null
}

MessageThreadItemPotentialStop.displayName = 'MessageThreadItemPotentialStop'
MessageThreadItemPotentialStop.propTypes = propTypes
MessageThreadItemPotentialStop.defaultProps = defaultProps

export default MessageThreadItemPotentialStop
