import React from 'react'
import { useRoutes } from 'react-router-dom'

import Layout from '../components/Layout'
import Menu from '../components/menu/Menu'
import PanelLayout from '../components/panels/PanelLayout'
import PanelNotFound from '../components/panels/PanelNotFound'
import RequireAuth from '../components/RequireAuth'
import RequireStripeAccount from '../components/RequireStripeAccount'
import RequireWeb from '../components/RequireWeb'
import AccountActionsList from '../screens/account/AccountActionsList'
import BillingAccountsList from '../screens/admin/billing-accounts/BillingAccountsList'
import ToolsActionsList from '../screens/admin/tools/ToolsActionsList'
import UnlayerTemplatesList from '../screens/admin/unlayer-templates/UnlayerTemplatesList'
import UpdateNumber from '../screens/admin/users/UpdateNumber'
import UsersList from '../screens/admin/users/UsersList'
import AivaChatScreen from '../screens/aiva/AivaChatScreen'
import AivaSettingsScreen from '../screens/aiva/AivaSettingsScreen'
import TryAivaScreen from '../screens/aiva/TryAivaScreen'
import WhatIsAivaScreen from '../screens/aiva/WhatIsAivaScreen'
import AppFlowsList from '../screens/app-flows/AppFlowsList'
import AttachmentsList from '../screens/attachments/AttachmentsList'
import ForgotPasswordForm from '../screens/auth/forgot-password/Form'
import LoginGettingStarted from '../screens/auth/login/GettingStarted'
import LoginWizard from '../screens/auth/login/Wizard'
import Logout from '../screens/auth/Logout'
import ReactivateWizard from '../screens/auth/reactivate/Wizard'
import ResetPasswordForm from '../screens/auth/reset-password/Form'
import SignUpWizard from '../screens/auth/signup/Wizard'
import BundlesList from '../screens/bundles/BundlesList'
import CampaignsList from '../screens/campaigns/CampaignsList'
import ChatsList from '../screens/chats/ChatsList'
import ConfigurableFormsList from '../screens/configurable-forms/ConfigurableFormsList'
import ContactsList from '../screens/contacts/ContactsList'
import ContestsList from '../screens/contests/ContestsList'
import EmailsList from '../screens/emails/EmailsList'
import GettingStarted from '../screens/GettingStarted'
import KeywordsList from '../screens/keywords/KeywordsList'
import LandingPagesList from '../screens/landing-pages/LandingPagesList'
import ManagedAccountsList from '../screens/managed-accounts/ManagedAccountsList'
import MessageTemplatesList from '../screens/message-templates/MessageTemplatesList'
import QrCodesList from '../screens/qr-codes/QrCodesList'
import ScheduledBroadcastsList from '../screens/scheduled-broadcasts/ScheduledBroadcastsList'
import SettingsActionsList from '../screens/settings/SettingsActionsList'
import SpacesList from '../screens/spaces/SpacesList'
import StyleGuideLayout from '../screens/style-guide/StyleGuideLayout'
import SelectPhoneNumber from '../screens/subscription/SelectPhoneNumber'
import SubscriptionActionsList from '../screens/subscription/SubscriptionActionsList'
import TagsList from '../screens/tags/TagsList'
import TemplateRedirect from '../screens/templates/TemplateRedirect'
import TemplatesRedirect from '../screens/templates/TemplatesRedirect'
import TrackableLinksList from '../screens/trackable-links/TrackableLinksList'
import UnsentMessagesList from '../screens/unsent-messages/UnsentMessagesList'
import VoiceDropsList from '../screens/voice-drops/VoiceDropsList'

import accountRoutes from './account'
import AdminBillingAccountRoutes from './admin/billing-accounts'
import AdminToolRoutes from './admin/tools'
import AdminUnlayerTemplateRoutes from './admin/unlayer-templates'
import AdminUserRoutes from './admin/users'
import appFlowsRoutes from './app-flows'
import attachmentsRoutes from './attachments'
import bundlesRoutes from './bundles'
import campaignsRoutes from './campaigns'
import chatsRoutes from './chats'
import configurableFormsRoutes from './configurable-forms'
import contactsRoutes from './contacts'
import contestsRoutes from './contests'
import emailRoutes from './emails'
import integrationsRoutes from './integrations'
import keywordsRoutes from './keywords'
import landingPagesRoutes from './landing-pages'
import legacyRoutes from './legacy'
import messageTemplatesRoutes from './message-templates'
import qrCodeRoutes from './qr-codes'
import scheduledBroadcastsRoutes from './scheduledBroadcasts'
import settingsRoutes from './settings'
import spacesRoutes from './spaces'
import styleGuideRoutes from './style-guide'
import subscriptionRoutes from './subscription'
import tagsRoutes from './tags'
import trackableLinksRoutes from './trackable-links'
import voiceDropsRoutes from './voice-drops'

const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'auth',
        element: <PanelLayout includeHeaderBackground={false} panel1={<LoginWizard />} panel2={<LoginGettingStarted />} />
      },
      {
        path: 'forgot-password',
        element: <PanelLayout includeHeaderBackground={false} panel1={<ForgotPasswordForm />} panel2={<LoginGettingStarted />} />
      },
      {
        path: 'reset-password',
        element: <PanelLayout includeHeaderBackground={false} panel1={<ResetPasswordForm />} panel2={<LoginGettingStarted />} />
      },
      {
        path: 'reactivate',
        element: <PanelLayout includeHeaderBackground={false} panel1={<ReactivateWizard />} />
      },
      {
        path: 'sign-up',
        element: <PanelLayout includeHeaderBackground={false} panel1={<SignUpWizard />} />
      },
      {
        path: 'logout',
        element: <Logout />
      },
      {
        path: 'account',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<AccountActionsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...accountRoutes]
      },
      {
        path: 'admin/billing-accounts',
        element: (
          <RequireAuth adminOnly>
            <PanelLayout
              menu={<Menu />}
              panel1={<BillingAccountsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...AdminBillingAccountRoutes]
      },
      {
        path: 'admin/unlayer-templates',
        element: (
          <RequireAuth adminOnly>
            <PanelLayout
              menu={<Menu />}
              panel1={<UnlayerTemplatesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...AdminUnlayerTemplateRoutes]
      },
      {
        path: 'admin/users',
        element: (
          <RequireAuth adminOnly>
            <PanelLayout
              menu={<Menu />}
              panel1={<UsersList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...AdminUserRoutes]
      },
      {
        path: 'admin/tools',
        element: (
          <RequireAuth adminOnly>
            <PanelLayout
              menu={<Menu />}
              panel1={<ToolsActionsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...AdminToolRoutes]
      },
      {
        path: 'admin/update-number',
        element: (
          <RequireAuth adminOnly>
            <PanelLayout
              menu={<Menu />}
              panel1={<UsersList title='Update Number' />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [{
          path: ':userId',
          element: <UpdateNumber />
        }]
      },
      {
        path: 'settings',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<SettingsActionsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...settingsRoutes]
      },
      {
        path: 'subscription',
        element: (
          <RequireAuth>
            <RequireWeb>
              <RequireStripeAccount>
                <PanelLayout
                  menu={<Menu />}
                  panel1={<SubscriptionActionsList />}
                  panel2={<GettingStarted />}
                />
              </RequireStripeAccount>
            </RequireWeb>
          </RequireAuth>
        ),
        children: [...subscriptionRoutes]
      },
      {
        path: 'select-phone-number',
        element: (
          <RequireAuth>
            <PanelLayout
              panel1={<SelectPhoneNumber />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'aiva/what-is-aiva',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<WhatIsAivaScreen />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'aiva/try-aiva',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<TryAivaScreen />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'aiva/chat',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<AivaChatScreen />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'aiva/settings',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<AivaSettingsScreen />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'app-flows',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<AppFlowsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...appFlowsRoutes]
      },
      {
        path: 'attachments',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<AttachmentsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...attachmentsRoutes]
      },
      {
        path: 'bundles',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<BundlesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...bundlesRoutes]
      },
      {
        path: 'configurable-forms',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ConfigurableFormsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...configurableFormsRoutes]
      },
      {
        path: 'unsent-messages',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<UnsentMessagesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'campaigns',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<CampaignsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...campaignsRoutes]
      },
      {
        path: 'chats',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ChatsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...chatsRoutes]
      },
      {
        path: 'contacts',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ContactsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...contactsRoutes]
      },
      {
        path: 'contests',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ContestsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...contestsRoutes]
      },
      {
        path: 'emails',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<EmailsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...emailRoutes]
      },
      {
        path: 'landing-pages',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<LandingPagesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...landingPagesRoutes]
      },
      {
        path: 'keywords',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<KeywordsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...keywordsRoutes]
      },

      {
        path: 'managed-accounts',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ManagedAccountsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        )
      },
      {
        path: 'qr-codes',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<QrCodesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...qrCodeRoutes]
      },
      {
        path: 'scheduled-broadcasts',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<ScheduledBroadcastsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...scheduledBroadcastsRoutes]
      },
      {
        path: 'spaces',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<SpacesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...spacesRoutes]
      },
      {
        path: 'tags',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<TagsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...tagsRoutes]
      },
      {
        path: 'message-templates',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<MessageTemplatesList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...messageTemplatesRoutes]
      },
      {
        path: 'voice-drops',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<VoiceDropsList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...voiceDropsRoutes]
      },
      {
        path: 'templates',
        element: (<TemplatesRedirect />)
      },
      {
        path: 'templates/:id/*',
        element: (<TemplateRedirect />)
      },
      {
        path: 'trackable-links',
        element: (
          <RequireAuth>
            <PanelLayout
              menu={<Menu />}
              panel1={<TrackableLinksList />}
              panel2={<GettingStarted />}
            />
          </RequireAuth>
        ),
        children: [...trackableLinksRoutes]
      },
      {
        path: 'style-guide',
        element: <StyleGuideLayout />,
        children: [...styleGuideRoutes]
      },
      ...integrationsRoutes,
      ...legacyRoutes,
      {
        path: '*',
        element: (
          <RequireWeb>
            <PanelLayout menu={<Menu />} panel1={<PanelNotFound />} panel2={<GettingStarted />} />
          </RequireWeb>
        )
      }
    ]
  }
]

export function Routes () {
  const routesAsElements = useRoutes(routes)
  return routesAsElements
}

export default routes
