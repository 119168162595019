import ListActionLink from '../../components/links/ListActionLink'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function SubscriptionActionsList () {
  return (
    <Panel>
      <PanelHeader title='Subscription' />
      <PanelContent className='text-black'>
        <div className='border-neutral-200'>
          <ListActionLink title='Change Subscription' to='change-subscription' />
          <ListActionLink title='Update Payment' to='update-payment' />
          <ListActionLink title='Payment History' to='payment-history' />
          {/* <ListActionLink title='Add Ons' to='add-ons' /> */}
          <ListActionLink title='Auto-Recharge' to='auto-recharge' />
          <ListActionLink title='Buy Credits' to='buy-credits' />
          <ListActionLink title='Gift Credits' to='gift-credits' />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default SubscriptionActionsList
