import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useId, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import {
  add as mfaAddService,
  settings as mfaSettingsService,
  verifyAdd as mfaVerifyAddService
} from '../../../services/multi-factor-authentication'
import BaseDialog from '../../dialog/BaseDialog'

import ChooseMethod from './ChooseMethod'
import MethodForm from './MethodForm'
import VerificationForm from './VerificationForm'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['AddAction'] })

const AddAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const dialogRef = useRef()
  const titleId = useId()
  const [channels, setChannels] = useState([])
  const [channel, setChannel] = useState(null)
  const [to, setTo] = useState(null)

  const handleMfaSettingsReplyOk = useCallback((data) => {
    setChannels(data?.json?.supportedChannels || [])
  }, [])

  const handleMfaAddReplyNotOk = useCallback(() => {
    setTo(null)
  }, [])

  const clearForm = useCallback(() => {
    setChannel(null)
    setTo(null)
  }, [])

  const handleMfaAddVerifyReply = useCallback(() => {
    dialogRef.current.close()
    clearForm()
    bus.emit('mfaMethodAdded')
    onSuccess?.()
  }, [clearForm, onSuccess])

  const { call: mfaSettings } = useService(mfaSettingsService, { onReplyOk: handleMfaSettingsReplyOk })
  const { call: mfaAdd } = useService(mfaAddService, { onReplyNotOk: handleMfaAddReplyNotOk })
  const { call: mfaVerifyAdd } = useService(mfaVerifyAddService, { onReplyOk: handleMfaAddVerifyReply })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  useEffect(() => {
    mfaSettings()
  }, [mfaSettings])

  const handleVerificationFormSubmit = useCallback(({ verificationCode }) => {
    mfaVerifyAdd({ channel, to, verificationCode })
  }, [channel, mfaVerifyAdd, to])

  const handleChannelSelection = useCallback(({ channel }) => {
    setChannel(channel)
  }, [])

  const handleToFormSubmit = useCallback(({ to }) => {
    setTo(to)
    mfaAdd({ channel, to })
  }, [channel, mfaAdd])

  return (
    <BaseDialog
      ref={dialogRef}
      ariaLabelledBy={titleId}
      onClose={clearForm}
    >
      <div className='bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[600px] flex flex-col'>
        <h1 className='label-lg-strong text-black text-center mb-[20px]' id={titleId}>Add New MFA Method</h1>
        <div className='flex flex-col flex-nowrap gap-2 justify-center'>
          {!channel ? <ChooseMethod channels={channels} dialogRef={dialogRef} onSelection={handleChannelSelection} /> : null}
          {channel && !to
            ? <MethodForm
                channel={channel}
                dialogRef={dialogRef}
                i
                onSubmit={handleToFormSubmit}
              />
            : null}
          {channel && to
            ? <VerificationForm
                channel={channel}
                dialogRef={dialogRef}
                to={to}
                onSubmit={handleVerificationFormSubmit}
              />
            : null}
        </div>
      </div>
    </BaseDialog>
  )
})

AddAction.displayName = 'AddAction'
AddAction.propTypes = propTypes
AddAction.defaultProps = defaultProps

export default AddAction
