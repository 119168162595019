import { map } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import AddContactsDialog from '../../components/campaigns/AddContactsDialog'
import CampaignContactList from '../../components/campaigns/CampaignContactList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentCampaign from '../../hooks/useCurrentCampaign'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/campaign-contact'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['CampaignsContactsList'] })

const CampaignsContactsList = () => {
  const { campaignId, loading, campaign, setCampaign } = useCurrentCampaign()

  useLogger({ log, lifecycle: false, tags: [campaignId] })

  const campaignContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Contacts updated')
    campaignContactListRef.current.reload()
    const updatedCampaign = reply.model
    setCampaign(updatedCampaign)
    bus.emit('campaignUpdated', updatedCampaign)
  }, [setCampaign])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query, data) => {
    addAllInQueryCall({ ...data, campaignId, ...query })
  }, [addAllInQueryCall, campaignId])

  const handleAddSelected = useCallback((selected, data) => {
    addSelectedCall({ ...data, campaignId, contactIds: map(selected, '_id') })
  }, [addSelectedCall, campaignId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ campaignId, ...query })
  }, [campaignId, removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ campaignId, contactIds: map(selected, '_id') })
  }, [campaignId, removeSelectedCall])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !campaign) { return <PanelLoading /> }

  const end = (
    <>
      {campaign.contacts.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={campaign.name}
        title='Contacts'
      />
      <PanelContent>
        <CampaignContactList
          ref={campaignContactListRef}
          campaignId={campaign._id}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        campaignId={campaign._id}
        list={CampaignContactList}
        subtitle={campaign.name}
        title='Remove Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <AddContactsDialog
        ref={addListDialogRef}
        campaign={campaign}
        subtitle={campaign.name}
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

CampaignsContactsList.displayName = 'CampaignsContactsList'
CampaignsContactsList.propTypes = propTypes
CampaignsContactsList.defaultProps = defaultProps

export default CampaignsContactsList
