import { formatName, formatPhone } from '../lib/formatters'

import proxy from './proxy'

export default function spaceMemberFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get formattedName () {
      return formatName(json)
    },

    get formattedPhoneNumber () {
      return formatPhone(json.phoneNumber)
    }
  }

  const model = proxy(json, overrides)
  return model
}
