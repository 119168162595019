import { keys } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { success } from '../banners/Banner'
import FormDialog from '../dialog/FormDialog'
import FormPanelDialog from '../dialog/FormPanelDialog'

import DetailSection from './DetailSection'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropType.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formControls: PropType.array.isRequired,
  formDescription: PropType.string.isRequired,
  formTitle: PropType.string.isRequired,
  objectId: PropType.string.isRequired,
  service: PropType.func.isRequired,
  children: PropType.node,
  className: PropType.string,
  confirm: PropType.shape({
    description: PropType.string,
    title: PropType.string,
    when: PropType.func
  }),
  description: PropType.string,
  editIcon: PropType.oneOf(['edit', 'chevron']),
  initialEditButtonText: PropType.string,
  isPanelDialog: PropType.bool,
  showClearIcon: PropType.bool,
  showEditIcon: PropType.bool,
  showInitialEditButton: PropType.bool,
  title: PropType.string,
  transformData: PropType.func,
  validationSuite: PropType.func,
  onChange: PropType.func
}

const defaultProps = {
  children: null,
  className: null,
  confirm: null,
  description: null,
  editIcon: 'edit',
  initialEditButtonText: 'Edit',
  isPanelDialog: false,
  onChange: null,
  showClearIcon: false,
  showEditIcon: false,
  showInitialEditButton: false,
  title: null,
  transformData: undefined,
  validationSuite: null
}

const log = logger({ enabled: true, tags: ['FormDetailSection'] })

const FormDetailSection = ({
  children,
  className,
  confirm,
  description,
  defaultValues,
  editIcon,
  formControls,
  formDescription,
  formTitle,
  isPanelDialog,
  initialEditButtonText,
  showClearIcon,
  showEditIcon,
  showInitialEditButton,
  title,
  transformData,
  objectId,
  service,
  validationSuite,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [objectId] })

  const formDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    success(`${title} updated`)
    onChange?.(reply)
  }, [onChange, title])
  const { call } = useService(service, { onReplyOk: handleReplyOk })

  const handleClear = useCallback(() => {
    const emptyData = {}
    keys(defaultValues).map((key) => (emptyData[key] = null))
    call(objectId, emptyData)
  }, [call, defaultValues, objectId])

  const handleEdit = useCallback(() => { formDialogRef.current.open() }, [])

  const handleSubmit = useCallback((data) => { call(objectId, data) }, [call, objectId])

  const formDialogProps = useMemo(() => ({
    confirm,
    defaultValues,
    description: formDescription,
    formControls,
    title: formTitle,
    transformData,
    validationSuite,
    onSubmit: handleSubmit
  }), [confirm, defaultValues, formControls, formDescription, formTitle, handleSubmit, transformData, validationSuite])
  const formDialog = useMemo(() => {
    return isPanelDialog ? <FormPanelDialog ref={formDialogRef} {...formDialogProps} /> : <FormDialog ref={formDialogRef} {...formDialogProps} />
  }, [isPanelDialog, formDialogProps])

  return (
    <>
      <DetailSection
        className={className}
        description={description}
        editIcon={editIcon}
        initialEditButtonText={initialEditButtonText}
        showClearIcon={showClearIcon}
        showEditIcon={showEditIcon}
        showInitialEditButton={showInitialEditButton}
        title={title}
        onClearClick={handleClear}
        onEditClick={handleEdit}
        onInitialEditClick={handleEdit}
      >
        {children}
      </DetailSection>
      {formDialog}
    </>
  )
}

FormDetailSection.displayName = 'FormDetailSection'
FormDetailSection.propTypes = propTypes
FormDetailSection.defaultProps = defaultProps

export default FormDetailSection
