import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { listAvailableNumbers as listAvailableNumbersService } from '../../../services/subscription'

const propTypes = {
  onSubmit: PropType.func.isRequired
}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['SelectStateForm'] })

const SelectStateForm = ({ onSubmit: handleNumbers }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const onReplyOk = useCallback((data) => {
    const numbers = data?.json?.numbers
    log.debug('data', numbers)
    handleNumbers({ numbers })
  }, [handleNumbers])
  const { call: listAvailableNumbers } = useService(listAvailableNumbersService, { onReplyOk })

  const onSubmit = useCallback(({ stateProvince }) => {
    log.debug('selected State/Province:', stateProvince)
    listAvailableNumbers({ stateProvince })
  }, [listAvailableNumbers])

  const stateFormSettings = useMemo(() => ({
    id: 'signUpForm',
    defaultValues: {},
    formControls: [
      {
        type: 'stateprovince',
        name: 'stateProvince',
        label: 'State/Province',
        hint: 'The State/Province is used to locate you a Project Broadcast number. If the State/Province you provide does not return any available numbers, please use a different State/Province.',
        placeholder: 'Choose State/Province',
        required: true
      }
    ],
    onSubmit
  }), [onSubmit])

  return (
    <>
      <Form {...stateFormSettings} className='text-left' />
      <div className='flex flex-row gap-4 items-center'>
        <Button
          className='flex-grow md:flex-none'
          form={stateFormSettings.id}
          size='sm'
          type='submit'
        >
          Continue
        </Button>
      </div>
    </>
  )
}

SelectStateForm.displayName = 'SelectStateForm'
SelectStateForm.propTypes = propTypes
SelectStateForm.defaultProps = defaultProps

export default SelectStateForm
