import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useId, useImperativeHandle, useRef, useState } from 'react'

import { campaignTypeOptionsForSearch } from '../../helpers/campaign'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelDialog from '../dialog/PanelDialog'
import Checkbox from '../forms/controls/Checkbox'
import DateTimeSelect from '../forms/controls/DateTimeSelect'
import ModelSelect from '../forms/controls/ModelSelect'
import RadioGroup from '../forms/controls/RadioGroup'
import TextInput from '../forms/controls/TextInput'
import Form from '../forms/Form'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import validationSuite from './ScheduledBroadcastListAdvancedSearchDialog.validations'

const propTypes = {
  defaultValues: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropType.func
}

const defaultProps = {
  onSubmit: undefined
}

const log = logger({ enabled: true, tags: ['ScheduledBroadcastListAdvancedSearchDialog'] })

const ScheduledBroadcastListAdvancedSearchDialog = forwardRef(({
  defaultValues,
  onSubmit
}, ref) => {
  ref = useDefaultRef(ref)
  const panelDialogRef = useRef()
  const formRef = useRef()
  const formId = useId()

  useLogger({ log, lifecycle: false, tags: [formId] })

  const [showBetweenInputs, setShowBetweenInputs] = useState(defaultValues?.scheduledAtType === 'range')
  const [hideCampaigns, setHideCampaigns] = useState(defaultValues?.noCampaigns === true)
  const [campaignType, setCampaignType] = useState(defaultValues?.campaignType)
  const [campaignId, setCampaignId] = useState(defaultValues?.campaign?._id)

  useEffect(() => {
    setShowBetweenInputs(defaultValues?.scheduledAtType === 'range')
    setHideCampaigns(defaultValues?.noCampaigns === true)
    setCampaignType(defaultValues?.campaignType)
    setCampaignId(defaultValues?.campaign?._id)
  }, [defaultValues])

  useImperativeHandle(ref, () => ({
    open () {
      panelDialogRef.current.open()
    }
  }))

  const handleCancel = useCallback(() => panelDialogRef.current.close(), [])

  const handleScheduledAtTypeChange = useCallback(() => {
    const isRange = formRef.current.getValues('scheduledAtType') === 'range'
    setShowBetweenInputs(isRange)
  }, [])

  const handleCampaignTypeChange = useCallback(() => {
    setCampaignType(formRef.current.getValues('campaignType'))
  }, [])

  const handleCampaignSelect = useCallback((campaign) => {
    setCampaignId(campaign?._id)
  }, [])

  const handleNoCampaignsChange = useCallback(() => {
    const noCampaigns = formRef.current.getValues('noCampaigns')
    setHideCampaigns(noCampaigns)
    if (noCampaigns) {
      formRef.current.setValue('campaignType', null)
      formRef.current.setValue('campaign', null)
      formRef.current.setValue('event', null)
    }
  }, [])

  const handleSubmit = useCallback((data) => {
    panelDialogRef.current.close()
    onSubmit(data)
  }, [onSubmit])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel='Advanced Search'>
      <PanelHeader
        end={<PanelHeaderButton form={formId} title='Search' type='submit' />}
        start={<PanelHeaderButton title='Cancel' onClick={handleCancel} />}
        title='Advanced Search'
      />
      <PanelContent className='p-4'>
        <Form
          ref={formRef}
          defaultValues={defaultValues}
          id={formId}
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        >
          <div className='mb-5'>
            <TextInput label='Term' name='term' />
          </div>
          <div className='mb-5'>
            <RadioGroup
              label='Scheduled'
              name='scheduledAtType'
              options={[
                { label: 'Current', value: 'future' },
                { label: 'Past', value: 'past' },
                { label: 'Between', value: 'range' }
              ]}
              onChange={handleScheduledAtTypeChange}
            />
          </div>
          <div className={`px-5 ${!showBetweenInputs ? 'hidden' : ''}`}>
            <div className='mb-5'>
              <DateTimeSelect label='From' name='scheduledAtFrom' />
            </div>
            <div className='mb-5'>
              <DateTimeSelect label='To' name='scheduledAtTo' />
            </div>
          </div>
          <div className='mb-5'>
            <ModelSelect
              label='Contact'
              model='contacts'
              multiple={false}
              name='contact'
            />
          </div>
          <div className='mb-5'>
            <Checkbox
              label='No Campaigns'
              name='noCampaigns'
              onChange={handleNoCampaignsChange}
            />
          </div>
          <div className={`${hideCampaigns ? 'hidden' : ''}`}>
            <div className='mb-5'>
              <RadioGroup
                label='Campaign Type'
                name='campaignType'
                options={[...campaignTypeOptionsForSearch]}
                onChange={handleCampaignTypeChange}
              />
            </div>
            <div className='mb-5'>
              <ModelSelect
                label='Campaign'
                listProps={{ defaultSearch: { type: campaignType } }}
                model='campaigns'
                multiple={false}
                name='campaign'
                onChange={handleCampaignSelect}
              />
            </div>
            <div className='mb-5'>
              {campaignId
                ? <ModelSelect
                    disabled={!campaignId}
                    label='Message'
                    listProps={{ campaignId, search: false }}
                    model='campaignEvents'
                    multiple={false}
                    name='event'
                  />
                : <TextInput
                    label='Message'
                    name='campaignEventDisabled'
                    placeholder='Select a campaign first'
                    disabled
                    readOnly
                  />}
            </div>
          </div>
        </Form>
      </PanelContent>
    </PanelDialog>
  )
})

ScheduledBroadcastListAdvancedSearchDialog.displayName = 'ScheduledBroadcastListAdvancedSearchDialog'
ScheduledBroadcastListAdvancedSearchDialog.propTypes = propTypes
ScheduledBroadcastListAdvancedSearchDialog.defaultProps = defaultProps

export default ScheduledBroadcastListAdvancedSearchDialog
