import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('physicalMailingAddress.addressLine1', 'Address Line 1 is required', () => {
    enforce(data.physicalMailingAddress.addressLine1).isNotEmpty()
  })

  test('physicalMailingAddress.city', 'City is required', () => {
    enforce(data.physicalMailingAddress.city).isNotEmpty()
  })

  test('physicalMailingAddress.stateProvince', 'State/Province is required', () => {
    enforce(data.physicalMailingAddress.stateProvince).isNotEmpty()
  })

  test('physicalMailingAddress.postal', 'Postal is required', () => {
    enforce(data.physicalMailingAddress.postal).isNotEmpty()
  })

  test('agreesToCanSpamAct', 'You must agree to the CAN-SPAM Act', () => {
    enforce(data.agreesToCanSpamAct).equals(true)
  })
})

export default validationSuite
