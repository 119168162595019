import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { scheduled } from '../../services/chat'
import ThreadList from '../list/thread/ThreadList'
import MessageThreadItemContent from '../message/MessageThreadItemContent'

const propTypes = {
  contactId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ScheduledChatThread'] })

const ScheduledChatThread = forwardRef(({ contactId, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const handleMessageDeleted = useCallback((message) => {
    ref.current.removeMessage(message)
  }, [ref])
  useBus('messageDeleted', handleMessageDeleted)

  const handleMessageUpdated = useCallback((message) => {
    ref.current.updateMessage(message)
  }, [ref])
  useBus('messageUpdated', handleMessageUpdated)

  return (
    <ThreadList
      ref={ref}
      queryOverrides={{ contactId }}
      service={scheduled}
      ThreadItemContent={MessageThreadItemContent}
      reverseIncomingData
      {...rest}
    />
  )
})

ScheduledChatThread.displayName = 'ScheduledChatThread'
ScheduledChatThread.propTypes = propTypes
ScheduledChatThread.defaultProps = defaultProps

export default ScheduledChatThread
