import * as React from "react";
import { memo } from "react";
const SvgManageAccounts = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Manage_Accounts_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3.001 3.001 0 0 1-2.014 2.834.692.692 0 0 0-.006-.032C19.423 15.04 16.88 13 14 13c-2.88 0-5.423 2.04-5.98 4.802l-.006.032A3.001 3.001 0 0 1 6 15V5Zm3 15a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H9Zm8.974-2h-7.948c.443-1.702 2.087-3 3.974-3s3.531 1.298 3.974 3ZM12.5 8.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM14 5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM2 7a1 1 0 0 0-2 0v10c0 3.862 3.138 7 7 7h10a1 1 0 1 0 0-2H7c-2.758 0-5-2.242-5-5V7Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Manage_Accounts_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgManageAccounts);
export default Memo;
