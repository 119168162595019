import pb from './pb'

export function credit () {
  return pb.setupGet('credit', {})
}
export function giftCredits () {
  return pb.setupGet('giftCredits', {})
}
export function create ({ credits: amount }) {
  return pb.setupPost('credit', { body: { amount } })
}
export function validation (data) {
  return pb.setupPost('credit/findRecipient', { body: data })
}
export function gift (data) {
  return pb.setupPost('credit/gift', { body: data })
}
