import { map } from 'lodash'
import { useCallback, useRef } from 'react'

import { success } from '../../components/banners/Banner'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import SpaceContactList from '../../components/spaces/SpaceContactList'
import SpaceMemberList from '../../components/spaces/SpaceMemberList'
import useCurrentSpace from '../../hooks/useCurrentSpace'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/space-member'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpaceMembers'] })

const SpaceMembers = () => {
  const { spaceId, loading, space, setSpace } = useCurrentSpace()

  useLogger({ log, lifecycle: false, tags: [spaceId] })

  const spaceMemberListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Members updated')
    spaceMemberListRef.current.reload()
    const updatedSpace = reply.model
    setSpace(updatedSpace)
    bus.emit('spaceUpdated', updatedSpace)
  }, [setSpace])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectecdCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ spaceId, ...query })
  }, [addAllInQueryCall, spaceId])

  const handleAddSelected = useCallback((selected) => {
    addSelectecdCall({ spaceId, contactIds: map(selected, '_id') })
  }, [addSelectecdCall, spaceId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ spaceId, ...query })
  }, [removeAllInQueryCall, spaceId])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ spaceId, userIds: map(selected, '_id') })
  }, [removeSelectedCall, spaceId])

  if (loading || !space) { return <PanelLoading /> }

  const end = (
    <>
      {space.isCurrentUserOwner && space.users?.length > 1 ? <PanelHeaderButton icon='remove' title='Remove Members' onClick={handleRemoveButtonClick} /> : null}
      {space.isCurrentUserOwner ? <PanelHeaderButton icon='add' title='Add Members' onClick={handleAddButtonClick} /> : null}
    </>
  )

  return (
    <Panel>
      <PanelHeader
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={space.name}
        title={`Members (${space.users?.length})`}
      />
      <PanelContent>
        <SpaceMemberList
          ref={spaceMemberListRef}
          spaceId={space._id}
          type='list'
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={SpaceMemberList}
        spaceId={space._id}
        subtitle={space.name}
        title='Remove Member(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        list={SpaceContactList}
        spaceId={space._id}
        subtitle={space.name}
        title='Add Member(s)'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

SpaceMembers.displayName = 'SpaceMembers'
SpaceMembers.propTypes = propTypes
SpaceMembers.defaultProps = defaultProps

export default SpaceMembers
