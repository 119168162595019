import * as React from "react";
import { memo } from "react";
const SvgCountdown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 0a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H8ZM4 14a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 4 2 8.477 2 14s4.477 10 10 10 10-4.477 10-10a9.955 9.955 0 0 0-1.825-5.76l1.532-1.533a1 1 0 0 0-1.414-1.414L18.86 6.725A9.965 9.965 0 0 0 12 4Zm1 5a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V9Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCountdown);
export default Memo;
