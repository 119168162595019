import pb from '../pb'

export function create (body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupPost('admin/unlayerTemplates', options)
}

export function search (body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupPost('admin/unlayerTemplates/search', options)
}

export function get (unlayerTemplateId, background = false) {
  const options = {
    background
  }
  return pb.setupGet(`admin/unlayerTemplates/${unlayerTemplateId}`, options)
}

export function update (unlayerTemplateId, body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupPut(`admin/unlayerTemplates/${unlayerTemplateId}`, options)
}

export function del (unlayerTemplateId, background = false) {
  const options = {
    background
  }
  return pb.setupDelete(`admin/unlayerTemplates/${unlayerTemplateId}`, options)
}

export function clone (unlayerTemplateId, background = false) {
  const options = {
    background
  }
  return pb.setupPost(`admin/unlayerTemplates/${unlayerTemplateId}/clone`, options)
}

export function cloneAsOppositeType (unlayerTemplateId, background = false) {
  const options = {
    background
  }
  return pb.setupPost(`admin/unlayerTemplates/${unlayerTemplateId}/cloneAsOppositeType`, options)
}

export function regeneratePreviewImageUrl (unlayerTemplateId, background = false) {
  const options = {
    background
  }
  return pb.setupPost(`admin/unlayerTemplates/${unlayerTemplateId}/regeneratePreviewImageUrl`, options)
}
