import PropType from 'prop-types'

import Button from '../../../components/buttons/Button'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contact from '../../../prop-types/shapes/contact'

import SenderInfo from './SenderInfo'

const propTypes = {
  contact: PropType.shape(contact).isRequired,
  onDoubleOptInClick: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['StepOne'] })

const StepOne = ({ contact, onDoubleOptInClick }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div>
      <SenderInfo user={contact.user} />
      <p className='text-center mb-6'>
        ** By clicking "Accept" you agree to receive text messages from
        {' '}
        <strong>
          {contact.user.formattedName}
          {' - '}
          {contact.user.formattedPhoneNumber}
        </strong>
        {`${'. '}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        Message frequency will vary. Messages may be automated and promotional in nature. Message and data rates may apply. At any time you can reply STOP to stop receiving messages. **
      </p>
      <Button className='w-full' onClick={onDoubleOptInClick}>Accept</Button>
    </div>
  )
}

StepOne.displayName = 'StepOne'
StepOne.propTypes = propTypes
StepOne.defaultProps = defaultProps

export default StepOne
