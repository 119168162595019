import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createKeywordService } from '../../../services/keyword'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import FormDialog from '../../dialog/FormDialog'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: undefined
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    dialogRef.current.close()
    const keyword = reply.json
    setCurrentInstance('keyword', keyword)
    success(`${keyword.word} created`)
    bus.emit('keywordCreated', keyword)
    if (!createdFromFolder) { navigate(`/keywords/${keyword._id}`) }
    onSuccess?.(keyword)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createKeyword } = useService(createKeywordService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((createData) => { createKeyword(createData) }, [createKeyword])

  return (
    <FormDialog
      ref={dialogRef}
      defaultValues={{ name: '' }}
      description='Keywords act as mini inboxes. You can have Project Broadcast perform tasks on your behalf when this keyword is sent to your Project Broadcast Number. Because Keywords offer tagging, they can only include letters, numbers, dashes, spaces, and underscores.'
      formControls={[
        {
          label: 'Name',
          name: 'word',
          type: 'text',
          required: true
        }
      ]}
      title='New Keyword'
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
