import PropType from 'prop-types'
import { NavLink } from 'react-router-dom'

import useLatestStackPath from '../../../hooks/useLatestStackPath'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import IconLabelChevron from './IconLabelChevron'

const propTypes = {
  icon: PropType.element.isRequired,
  label: PropType.string.isRequired,
  path: PropType.string.isRequired,
  stack: PropType.string
}

const defaultProps = {
  stack: null
}

const log = logger({ enabled: true, tags: ['MenuItemWithoutSub'] })

const computeClassName = ({ isActive/*, isPending */ }) => {
  return isActive ? 'w-full text-primary' : 'w-full text-black'
}

const MenuItemWithoutSub = ({ icon, label, path, stack }) => {
  useLogger({ log, lifecycle: false })

  const stackPath = useLatestStackPath(stack, path)

  return (
    <li className='w-[278px] active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50 select-none'>
      <NavLink className={computeClassName} to={stackPath} aria-current>
        <IconLabelChevron
          icon={icon}
          label={label}
        />
      </NavLink>
    </li>
  )
}

MenuItemWithoutSub.displayName = 'MenuItemWithoutSub'
MenuItemWithoutSub.propTypes = propTypes
MenuItemWithoutSub.defaultProps = defaultProps

export default MenuItemWithoutSub
