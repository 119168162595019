import { useEffect, useState } from 'react'

import logger from '../lib/logger'
import useStore from '../store'

import useLogger from './useLogger'

const log = logger({ enabled: false, tags: ['useLatestStackPath'] })

const useLatestStackPath = (stack, defaultPath) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const stackHistory = useStore((state) => state.stacks)
  const [stackPath, setStackPath] = useState(defaultPath)
  useEffect(() => {
    if (!stack || !stackHistory?.[stack]?.length) {
      setStackPath(defaultPath)
    } else {
      setStackPath(stackHistory[stack].at(-1))
    }
  }, [stackHistory, stack, defaultPath])

  return stackPath
}

export default useLatestStackPath
