import * as React from "react";
import { memo } from "react";
const SvgAlbum = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 0a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H5ZM2 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm19 5H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1ZM3 8a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V11a3 3 0 0 0-3-3H3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAlbum);
export default Memo;
