import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import { search as contactSearch } from '../../services/contact'
import List from '../list/List'

import ContactListItemContent from './ContactListItemContent'
import ContactListSearchBar from './ContactListSearchBar'

const contactResultsFields = [
  '_id',
  'slug',
  'firstName',
  'lastName',
  'phoneNumber',
  'company',
  'contactUser',
  'tags',
  'doNotMessage',
  'doNotEmail',
  'potentialStopMessage'
]

const sortList = [
  { key: 'default', label: 'Default', default: true },
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'newest', label: 'Date Created (newest first)' },
  { key: 'oldest', label: 'Date Created (oldest first)' }
]

const propTypes = {
  bulkActions: PropType.elementType,
  ListItemContent: PropType.elementType,
  placeholder: PropType.string,
  resultsFields: PropType.arrayOf(PropType.string),
  resultsKey: PropType.string,
  searchService: PropType.func,
  sortable: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  bulkActions: null,
  ListItemContent: ContactListItemContent,
  placeholder: 'Search Contacts',
  resultsFields: contactResultsFields,
  resultsKey: 'contacts',
  searchService: contactSearch,
  sortable: false,
  sortList
}

const log = logger({ enabled: true, tags: ['ContactList'] })

const ContactList = forwardRef(({
  ListItemContent,
  placeholder,
  resultsFields,
  resultsKey,
  searchService,
  sortList,
  sortable,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('contactArchived', handleBusEvent)
  useBus('contactCreated', handleBusEvent)
  useBus('contactDeleted', handleBusEvent)
  useBus('contactUpdated', handleBusEvent)
  useBus('contactOptOutRelatedInfoUpdated', handleBusEvent)
  useBus('contactsUpdated', handleBusEvent)
  useBus('archivedContactsUpdated', handleBusEvent)

  return (
    <List
      ref={ref}
      ListItemContent={ListItemContent}
      ListSearchBar={ContactListSearchBar}
      placeholder={placeholder}
      resultsFields={resultsFields}
      resultsKey={resultsKey}
      searchService={searchService}
      sortable={sortable}
      sortList={sortList}
      search
      {...listProps}
    />
  )
})

ContactList.displayName = 'ContactList'
ContactList.propTypes = propTypes
ContactList.defaultProps = defaultProps

export default ContactList
