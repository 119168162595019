import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import logger from '../../../../lib/logger'
import scheduledBroadcastShape from '../../../../prop-types/shapes/scheduledBroadcast'
import { addAllInQuery, addSelected } from '../../../../services/scheduledBroadcast-contact'
import ContactList from '../../../contacts/ContactList'
import DetailSection from '../../../detail-sections/DetailSection'
import ListDialog from '../../../dialog/ListDialog'
import InputError from '../../../forms/InputError'
import ScheduledBroadcastSendToInfo from '../../ScheduledBroadcastSendToInfo'

import ScheduledBroadcastContactsDialog from './ScheduledBroadcastContactsDialog'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape),
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined,
  scheduledBroadcast: null
}

const log = logger({ enabled: true, tags: ['DraftContactsDetailSection'] })

const DraftContactsDetailSection = forwardRef(({ scheduledBroadcast, onChange }, ref) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast._id] })

  const addContactsDialogRef = useRef()
  const editContactsDialogRef = useRef()
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(ref, () => ({
    setError (message) {
      setErrorMessage(message)
    }
  }), [])

  const handleAddReplyOk = useCallback((reply) => {
    const updatedScheduledBroadcast = reply.model
    onChange?.(updatedScheduledBroadcast)
    setErrorMessage('')
  }, [onChange])
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleAddReplyOk })
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleAddReplyOk })

  const handleAddFirst = useCallback(() => {
    addContactsDialogRef.current.open()
  }, [])

  const handleEdit = useCallback(() => {
    editContactsDialogRef.current.open()
  }, [])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ scheduledBroadcastId: scheduledBroadcast._id, contactIds: map(selected, '_id') })
  }, [addSelectedCall, scheduledBroadcast._id])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ scheduledBroadcastId: scheduledBroadcast._id, ...query })
  }, [addAllInQueryCall, scheduledBroadcast._id])

  const count = scheduledBroadcast.contacts.length

  return (
    <>
      <DetailSection
        count={count}
        description='A great message is only great once it is seen! The contacts you select below will get your broadcast.'
        editIcon='chevron'
        initialEditButtonText='Add Contacts'
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title='Send To'
        onEditClick={handleEdit}
        onInitialEditClick={handleAddFirst}
      >
        {scheduledBroadcast.contactsSubsetNames}
      </DetailSection>
      <InputError message={errorMessage} />
      <ScheduledBroadcastSendToInfo scheduledBroadcast={scheduledBroadcast} />
      <ListDialog
        ref={addContactsDialogRef}
        list={ContactList}
        subtitle={scheduledBroadcast.name}
        title='Add contacts'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
      <ScheduledBroadcastContactsDialog ref={editContactsDialogRef} scheduledBroadcast={scheduledBroadcast} onChange={onChange} />
    </>
  )
})

DraftContactsDetailSection.displayName = 'DraftContactsDetailSection'
DraftContactsDetailSection.propTypes = propTypes
DraftContactsDetailSection.defaultProps = defaultProps

export default DraftContactsDetailSection
