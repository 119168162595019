import PropType from 'prop-types'
import { useMemo } from 'react'

import FormattedDate from '../../../components/FormattedDate'
import MessageFragment from '../../../components/message/MessageFragment'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatExtendedDate2 } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import { Broadcast, Campaign, ChatText, Email, Microphone, PeopleGroup, Person, Warning } from '../../../svg/icons'

const propTypes = {
  item: PropType.shape(scheduledBroadcastShape).isRequired,
  prev: PropType.shape(scheduledBroadcastShape)
}

const defaultProps = {
  prev: null
}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastListItemContent'] })

const ScheduledBroadcastListItemContent = ({ item: scheduledBroadcast, prev: prevItem }) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast?.key] })
  const { userTimeZone } = useTimeZone()

  const currentScheduledDateDisplay = useMemo(() => {
    const currentDisplay = formatExtendedDate2(scheduledBroadcast.scheduledAt, userTimeZone)
    const prevDisplay = prevItem?.scheduledAt && formatExtendedDate2(prevItem.scheduledAt, userTimeZone)
    if (currentDisplay !== prevDisplay) {
      return currentDisplay
    }
  }, [scheduledBroadcast.scheduledAt, prevItem?.scheduledAt, userTimeZone])

  const stateColor = useMemo(() => {
    const stateSummary = scheduledBroadcast.stateSummary
    if (stateSummary.includes('SENDING')) {
      return 'text-indigo-600'
    } else if (stateSummary.includes('UNSENT')) {
      return 'text-red-700'
    } else if (stateSummary.includes('SENT')) {
      return 'text-green-700'
    } else {
      return 'text-sky-700'
    }
  }, [scheduledBroadcast.stateSummary])

  const MessageTypeIcon = useMemo(() => {
    if (scheduledBroadcast.type === 'email') { return Email }
    if (scheduledBroadcast.type === 'rvm') { return Microphone }
    return ChatText
  }, [scheduledBroadcast])

  return (
    <div className='flex flex-grow flex-col gap-1 max-w-full overflow-auto'>
      {!!currentScheduledDateDisplay && (
        <div className='bg-neutral-50 text-center h-10 border-b border-neutral-200'>
          <div className='border-t border-neutral-200 w-full h-0 relative top-1/2' />
          <div className='inline-block bg-white text-neutral-700 py-1 px-2 mx-auto my-1.5
                      relative z-10 rounded-full label-xs-strong border border-neutral-200'
          >
            {currentScheduledDateDisplay}
          </div>
        </div>
      )}
      <div className='flex flex-col gap-1 px-3.5 py-3'>
        <div className={`label-xs-strong ${stateColor}`}>
          {scheduledBroadcast.stateSummary}
        </div>
        <div className='flex flex-row place-items-center gap-2'>
          <div className='w-4 h-4 shrink-0 grow-0'>
            {scheduledBroadcast.campaign ? <Campaign /> : <Broadcast />}
          </div>
          <div className='grow text-black max-w-full overflow-auto'>
            <div className='label-xs-strong break-words'>
              {scheduledBroadcast.name}
            </div>
            <div className='label-xs'>
              <FormattedDate date={scheduledBroadcast.scheduledAt} format='timeOfDay' />
            </div>
          </div>
        </div>
        <div className='flex flex-row place-items-center gap-2'>
          <div className='w-4 h-4 shrink-0 grow-0'>
            <MessageTypeIcon />
          </div>
          <div className='grow paragraph-xs text-neutral-600 max-w-full overflow-auto'>
            <MessageFragment message={scheduledBroadcast} size='sm' />
          </div>
        </div>
        <div className='flex flex-row place-items-center gap-2'>
          <div className='w-4 h-4 shrink-0 grow-0'>
            {scheduledBroadcast.contacts.length > 1 ? <PeopleGroup /> : <Person />}
          </div>
          <div className='grow flex flex-row place-items-center'>
            {scheduledBroadcast.contacts.length > 1 && (
              <div className='bg-neutral-100 rounded-md py-1 px-2 label-2xs-strong text-neutral-900 mr-1'>
                {scheduledBroadcast.contacts.length}
              </div>
            )}
            <div className='paragraph-xs text-black line-clamp-1'>
              {scheduledBroadcast.contactsSubsetNames}
            </div>
          </div>
        </div>
        {!!scheduledBroadcast.error && (
          <div className='flex flex-row place-items-center gap-2 text-red-700'>
            <div className='w-4 h-4 shrink-0 grow-0'>
              <Warning />
            </div>
            <div className='grow label-xs-medium'>
              Errors Detected
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ScheduledBroadcastListItemContent.displayName = 'ScheduledBroadcastListItemContent'
ScheduledBroadcastListItemContent.propTypes = propTypes
ScheduledBroadcastListItemContent.defaultProps = defaultProps

export default ScheduledBroadcastListItemContent
