import * as React from 'react'
import { memo } from 'react'
const SvgConfigurableForm = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#FEF9C3" rx={4} />
        <path
            fill="#CA8A04"
            fillRule="evenodd"
            d="m14.29 8.14 3.678 3.678.008.008a.713.713 0 0 1 0 1.02l-3.678 3.677a.735.735 0 0 1-.514.213.735.735 0 0 1-.513-.213.713.713 0 0 1 0-1.02l3.17-3.171-3.17-3.171a.713.713 0 0 1 0-1.02.713.713 0 0 1 1.02 0Zm-5.044 8.382L5.57 12.844l-.007-.007a.713.713 0 0 1 0-1.02l3.677-3.678c.14-.14.33-.213.514-.213.183 0 .374.073.514.213a.713.713 0 0 1 0 1.02L7.096 12.33l3.17 3.171a.713.713 0 0 1 0 1.02.713.713 0 0 1-1.02 0Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgConfigurableForm)
export default Memo
