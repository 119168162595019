import { useCallback } from 'react'

import ContactsWithAddDetailSection from '../../components/detail-sections/ContactsWithAddDetailSection'
import MessageDetailSection from '../../components/detail-sections/MessageDetailSection'
import WarningDetailSection from '../../components/detail-sections/WarningDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import ScheduledBroadcastSendingInfo from '../../components/scheduled-broadcast/ScheduledBroadcastSendingInfo'
import ScheduledBroadcastSendToInfo from '../../components/scheduled-broadcast/ScheduledBroadcastSendToInfo'
import { canEditBroadcast } from '../../helpers/scheduledBroadcast'
import useCurrentScheduledBroadcast from '../../hooks/useCurrentScheduledBroadcast'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateScheduledBroadcastService } from '../../services/scheduledBroadcast'
import { addAllInQuery, addSelected, tagContacts } from '../../services/scheduledBroadcast-contact'

import ContactsByStateDetailSection from './detail/ContactsByStateDetailSection'
import ScheduledBroadcastDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import ScheduledAtDetail from './detail/ScheduledAtDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastsDetail'] })

const ScheduledBroadcastsDetail = () => {
  const { scheduledBroadcastId, scheduledBroadcast, setScheduledBroadcast, loading } = useCurrentScheduledBroadcast()
  useLogger({ log, lifecycle: true, tags: [scheduledBroadcastId] })

  const handleScheduledBroadcastUpdated = useCallback((updateReply) => {
    const updatedScheduledBroadcast = updateReply.model
    setScheduledBroadcast(updatedScheduledBroadcast)
    bus.emit('scheduledBroadcastUpdated', updatedScheduledBroadcast)
  }, [setScheduledBroadcast])

  const tagAll = useCallback(({ tagName }) => {
    return tagContacts(scheduledBroadcastId, { tag: tagName, state: 'sendTo' })
  }, [scheduledBroadcastId])

  const title = 'Scheduled Broadcast'

  const doNotPhrase = scheduledBroadcast?.type === 'email' ? 'Do Not Email' : 'Do Not Message'
  const contactsWithAddDetailSectionHint = `You may have ${doNotPhrase} contacts in the Send To list. Project Broadcast will ignore them at the time of sending if they are still marked as ${doNotPhrase}.`

  if (loading || !scheduledBroadcast) { return <PanelLoading title={title} /> }

  return (
    <Panel>
      <PanelHeader
        end={<ScheduledBroadcastDetailHeaderMoreMenu scheduledBroadcast={scheduledBroadcast} />}
        start={<PanelHeaderBackButton />}
        subtitle={scheduledBroadcast?.campaign?.name}
        title={title}
      />
      <PanelContent className='p-5'>
        {scheduledBroadcast.campaign && canEditBroadcast(scheduledBroadcast)
          ? <WarningDetailSection text='Please note that changes to this broadcast may be overridden if you update the campaign message.' />
          : null}
        {scheduledBroadcast.campaign && !canEditBroadcast(scheduledBroadcast)
          ? <WarningDetailSection text='Changes to this broadcast can only be made by updating the campaign.' />
          : null}
        <MessageDetailSection
          description='You have something to say? Make it personal. Make it awesome!'
          message={scheduledBroadcast}
          readOnly={scheduledBroadcast.state !== 'scheduled' || !canEditBroadcast(scheduledBroadcast)}
          service={updateScheduledBroadcastService}
          onChange={handleScheduledBroadcastUpdated}
        />
        {scheduledBroadcast.state !== 'queued' && (
          <>
            <ContactsWithAddDetailSection
              addAllInQueryService={addAllInQuery}
              addSelectedService={addSelected}
              description='A great message is only great once it is seen! The below contacts will get your broadcast.'
              hint={contactsWithAddDetailSectionHint}
              nomenclature='Send To'
              object={scheduledBroadcast}
              objectName='scheduledBroadcast'
              tagAllService={tagAll}
              onChange={handleScheduledBroadcastUpdated}
            />
            <ScheduledBroadcastSendToInfo scheduledBroadcast={scheduledBroadcast} />
          </>
        )}
        {scheduledBroadcast.state === 'queued' && (
          <>
            {scheduledBroadcast.contactsSending.length > 0 && (
              <>
                <ContactsByStateDetailSection
                  scheduledBroadcast={scheduledBroadcast}
                  state='sending'
                />
                <ScheduledBroadcastSendingInfo scheduledBroadcast={scheduledBroadcast} />
              </>
            )}
            {scheduledBroadcast.contactsSent.length > 0 && (
              <ContactsByStateDetailSection
                scheduledBroadcast={scheduledBroadcast}
                state='sent'
              />
            )}
            {scheduledBroadcast.contactsUnsent.length > 0 && (
              <ContactsByStateDetailSection
                scheduledBroadcast={scheduledBroadcast}
                state='unsent'
              />
            )}
          </>
        )}
        <ScheduledAtDetail
          scheduledBroadcast={scheduledBroadcast}
          service={updateScheduledBroadcastService}
          onChange={handleScheduledBroadcastUpdated}
        />
      </PanelContent>
    </Panel>
  )
}

ScheduledBroadcastsDetail.displayName = 'ScheduledBroadcastsDetail'
ScheduledBroadcastsDetail.propTypes = propTypes
ScheduledBroadcastsDetail.defaultProps = defaultProps

export default ScheduledBroadcastsDetail
