import { useCallback, useRef, useState } from 'react'

import List from '../../components/list/List'
import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { searchUnsent as searchUnsentService } from '../../services/message'
import { Reload } from '../../svg/icons'

import EmptyListContent from './list/EmptyListContent'
import UnsentMessageItemContent from './list/UnsentMessageItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UnsentMessagesList'] })

const UnsentMessagesList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()

  const handleRefreshSelected = useCallback(() => {
    listRef.current.reload()
  }, [])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<PanelHeaderButton icon={<Reload />} onClick={handleRefreshSelected} />}
        title='Unsent Messages'
      />
      <PanelContent>
        <List
          ref={listRef}
          EmptyListContent={EmptyListContent}
          ListItemContent={UnsentMessageItemContent}
          placeholder='Search Unsent Messages'
          resultsKey='messages'
          savedStateKey='unsent-messages'
          searchService={searchUnsentService}
          type='list'
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

UnsentMessagesList.displayName = 'UnsentMessagesList'
UnsentMessagesList.propTypes = propTypes
UnsentMessagesList.defaultProps = defaultProps

export default UnsentMessagesList
