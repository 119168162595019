import PropType from 'prop-types'

const propTypes = {
  element: PropType.node.isRequired
}

const defaultProps = {}

const Passthrough = ({ element }) => element

Passthrough.displayName = 'Passthrough'
Passthrough.propTypes = propTypes
Passthrough.defaultProps = defaultProps

export default Passthrough
