import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import { updateScheduledMessage } from '../../../services/message'
import FormDialog from '../../dialog/FormDialog'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['EditScheduledMessageAction'] })

const EditScheduledMessageAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    bus.emit('messageUpdated', reply.model)
  }, [])
  const { call } = useService(updateScheduledMessage, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleSubmit = useCallback((data) => {
    call(message._id, data)
  }, [call, message._id])

  const defaultValues = { message }
  const formControls = [
    {
      label: 'Scheduled Message',
      type: 'message',
      name: 'message'
    }
  ]

  return (
    <FormDialog
      ref={formDialogRef}
      defaultValues={defaultValues}
      description='Update this scheduled message'
      formControls={formControls}
      title='Edit Scheduled Message'
      onSubmit={handleSubmit}
    />
  )
})

EditScheduledMessageAction.displayName = 'EditScheduledMessageAction'
EditScheduledMessageAction.propTypes = propTypes
EditScheduledMessageAction.defaultProps = defaultProps

export default EditScheduledMessageAction
