import PropType from 'prop-types'
import { Outlet } from 'react-router-dom'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import ShortLinkFooter from './ShortLinkFooter'

const propTypes = {
  excludeFooter: PropType.bool
}

const defaultProps = {
  excludeFooter: false
}

const log = logger({ enabled: false, tags: ['ShortLinkLayout'] })

const ShortLinkLayout = ({ excludeFooter }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='w-full h-full overflow-auto bg-neutral-50 p-5 flex flex-col items-center'>
      <Outlet />
      {!excludeFooter && <ShortLinkFooter />}
    </div>
  )
}

ShortLinkLayout.displayName = 'ShortLinkLayout'
ShortLinkLayout.propTypes = propTypes
ShortLinkLayout.defaultProps = defaultProps

export default ShortLinkLayout
