import FormattedDate from '../../components/FormattedDate'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['FormattedDates'] })

const FormattedDates = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const exampleDateMonth = { month: 1, date: 2 }
  const exampleStringDate = '2021-02-26T13:54:32.897Z'
  const exampleStringDateAsDate = new Date(exampleStringDate)
  const exampleNowDate = new Date()
  const formattedDateConfigs = [
    {
      title: 'format: dateMonth',
      props: {
        date: exampleDateMonth,
        format: 'dateMonth',
        useLocalTimeZone: true
      }
    },
    {
      title: 'format: extendedDateAndTime',
      props: {
        date: exampleStringDateAsDate,
        format: 'extendedDateAndTime',
        useLocalTimeZone: true
      }
    },
    {
      title: 'format: extendedDate',
      props: {
        date: exampleStringDate,
        format: 'extendedDate',
        useLocalTimeZone: true
      }
    },
    {
      title: 'format: timeOfDay',
      props: {
        date: exampleStringDateAsDate,
        format: 'timeOfDay',
        useLocalTimeZone: true
      }
    },
    {
      title: 'format: timeDistance',
      props: {
        date: exampleNowDate,
        format: 'timeDistance',
        useLocalTimeZone: true
      }
    }
  ]

  const output = formattedDateConfigs.map(({ title, props }) => {
    return (
      <div key={title} className='m-2 mb-6'>
        <h2 className='headline-xs mb-2'>{title}</h2>
        <FormattedDate {...props} />
      </div>
    )
  })

  return (
    <Panel>
      <PanelHeader title='Formatted Dates' />
      <PanelContent>
        <div className='p-2'>{output}</div>
      </PanelContent>
    </Panel>
  )
}

FormattedDates.displayName = 'FormattedDates'
FormattedDates.propTypes = propTypes
FormattedDates.defaultProps = defaultProps

export default FormattedDates
