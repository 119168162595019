import classNames from 'classnames'
import PropType from 'prop-types'

import SubMenuItem from './SubMenuItem'

const propTypes = {
  open: PropType.bool.isRequired,
  subConfig: PropType.arrayOf(PropType.shape({
    label: PropType.string,
    path: PropType.string,
    stack: PropType.string
  })).isRequired
}

const defaultProps = { }

const SubMenu = ({ subConfig, open }) => {
  const items = subConfig.map((config) => <SubMenuItem key={config.path} {...config} />)
  const className = classNames('overflow-hidden', {
    hidden: !open
  })
  return (
    <ul className={className}>
      {items}
    </ul>
  )
}

SubMenu.displayName = 'SubMenu'
SubMenu.propTypes = propTypes
SubMenu.defaultProps = defaultProps

export default SubMenu
