import PropType from 'prop-types'

import campaignFromJSON from '../models/campaign'

import { createPropTypes, searchPropTypes, updatePropTypes } from './campaign.prop-types'
import pb from './pb'

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: campaignFromJSON,
      key: 'campaigns',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'campaign#search')
  return pb.setupPost('campaigns/search', options)
}

export function get (campaignId, background = false) {
  const options = {
    background,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  }
  return pb.setupGet(`campaigns/${campaignId}`, options)
}

export function create (body) {
  const options = {
    body,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  }
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'campaign#create')
  return pb.setupPost('campaigns', options)
}

export function clone (campaignId) {
  const options = {
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  }
  return pb.setupPost(`campaigns/${campaignId}/clone`, options)
}

export function fastForward (campaignId, contactId) {
  const options = {
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  }
  return pb.setupPost(`campaigns/${campaignId}/fastFwd/${contactId}`, options)
}

export function update (campaignId, updateData) {
  const options = {
    body: updateData,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  }
  PropType.checkPropTypes(updatePropTypes, updateData, 'prop', 'campaign#update')
  return pb.setupPut(`campaigns/${campaignId}`, options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('campaigns/bulk/delete', { body, background })
}

export function del (campaignId) {
  return pb.setupDelete(`campaigns/${campaignId}`)
}
