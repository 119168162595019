import PropType from 'prop-types'

import { usePanel } from '../../contexts/PanelContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import Panel from './Panel'
import PanelHeaderBackButton from './panel-header/PanelHeaderBackButton'
import PanelContent from './PanelContent'
import PanelHeader from './PanelHeader'

const propTypes = {
  start: PropType.element,
  title: PropType.string
}

const defaultProps = {
  start: null,
  title: 'Not Found'
}

const log = logger({ enabled: false, tags: ['PanelNotFound'] })

const PanelNotFound = ({ start, title }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { inSecond } = usePanel()

  start = start || (inSecond ? <PanelHeaderBackButton /> : null)

  return (
    <Panel>
      <PanelHeader start={start} title={title} />
      <PanelContent className='p-5'>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          <h1 className='label-xl text-neutral-400'>Not able to locate this content</h1>
        </div>
      </PanelContent>
    </Panel>
  )
}

PanelNotFound.displayName = 'PanelNotFound'
PanelNotFound.propTypes = propTypes
PanelNotFound.defaultProps = defaultProps

export default PanelNotFound
