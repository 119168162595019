import * as React from "react";
import { memo } from "react";
const SvgPlay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.07 10.29 7.635 4.03c-1.19-.714-1.785-1.07-2.274-1.025a1.5 1.5 0 0 0-1.07.606C4 4.006 4 4.7 4 6.087v12.522c0 1.388 0 2.081.29 2.478a1.5 1.5 0 0 0 1.07.605c.49.046 1.085-.31 2.275-1.025l10.435-6.26c1.131-.68 1.697-1.02 1.889-1.458a1.5 1.5 0 0 0 0-1.202c-.192-.439-.758-.778-1.889-1.457Z"
    />
  </svg>
);
const Memo = memo(SvgPlay);
export default Memo;
