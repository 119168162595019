import { isNumber, pick } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import Pluralize from '../../../components/Pluralize'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventHourDelay'] })

const EventHourDelayDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent?._id] })

  const { confirm, value, defaultValues, formControls } = useMemo(() => ({
    confirm: {
      description: `Updating to an earlier hour delay may reschedule some broadcasts originated by this
                    campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledDelayHours }) => (scheduledDelayHours < campaignEvent.scheduledDelayHours)
    },
    value: isNumber(campaignEvent.scheduledDelayHours)
      ? <Pluralize count={campaignEvent.scheduledDelayHours} singular='Hour' includeCount />
      : null,
    defaultValues: pick(campaignEvent, 'scheduledDelayHours'),
    formControls: [{ label: '', name: 'scheduledDelayHours', type: 'number', min: 0 }]
  }), [campaignEvent])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the number of hours to wait before sending this message.'
      formControls={formControls}
      formDescription='Updating Hour Delay for this message'
      formTitle='Hour Delay'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Hour Delay'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventHourDelayDetail.displayName = 'EventHourDelayDetail'
EventHourDelayDetail.propTypes = propTypes
EventHourDelayDetail.defaultProps = defaultProps

export default EventHourDelayDetail
