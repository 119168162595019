import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import { createDraftForUnsent as createDraftForUnsentService } from '../../../services/scheduledBroadcast'
import ConfirmDialog from '../../dialog/ConfirmDialog'

import CreateAction from './CreateAction'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['RescheduleAction'] })

const RescheduleAction = forwardRef(({ scheduledBroadcast }, ref) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast?._id] })

  const confirmRef = useRef()
  const createRef = useRef()
  const [draft, setDraft] = useState()

  const { call: createDraftForUnsentCall } = useService(createDraftForUnsentService, {
    onReplyOk: useCallback((reply) => {
      setDraft(reply.model)
    }, [])
  })

  useLayoutEffect(() => {
    if (draft) {
      createRef.current.activate()
    }
  }, [draft])

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    createDraftForUnsentCall(scheduledBroadcast._id)
  }, [scheduledBroadcast, createDraftForUnsentCall])

  return (
    <>
      <ConfirmDialog
        ref={confirmRef}
        description='Before rescheduling these unsent messages please make sure there are no messages still sending for this broadcast. Are you sure you want to reschedule now?'
        title='Reschedule Unsent?'
        onConfirm={handleConfirm}
      />
      {draft ? <CreateAction ref={createRef} draft={draft} /> : null}
    </>
  )
})

RescheduleAction.displayName = 'RescheduleAction'
RescheduleAction.propTypes = propTypes
RescheduleAction.defaultProps = defaultProps

export default RescheduleAction
