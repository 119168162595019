import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import RemoveAction from '../../../components/multi-factor-authentication/actions/RemoveAction'
import useLogger from '../../../hooks/useLogger'
import { formatPhone, mfaChannelVerb } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import mfaMethod from '../../../prop-types/shapes/mfaMethod'

const propTypes = {
  item: PropType.shape(mfaMethod).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListItemContent'] })

const ListItemContent = ({ item: mfaMethod }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const removeActionRef = useRef()

  const handleRemoveClick = useCallback(() => removeActionRef.current.activate(), [])

  return (
    <>
      <div className='flex-grow flex flex-col ml-[12px] py-[4px]'>
        <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
          {mfaChannelVerb(mfaMethod.channel)}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {formatPhone(mfaMethod.to)}
        </div>
      </div>
      <div className='self-center'>
        <Button
          size='md'
          theme='danger'
          variant='text'
          onClick={handleRemoveClick}
        >
          Remove
        </Button>
      </div>
      <RemoveAction ref={removeActionRef} mfaMethod={mfaMethod} />
    </>
  )
}

ListItemContent.displayName = 'ListItemContent'
ListItemContent.propTypes = propTypes
ListItemContent.defaultProps = defaultProps

export default ListItemContent
