import { isNumber, pick } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import Pluralize from '../../../components/Pluralize'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventMinuteDelay'] })

const EventMinuteDelayDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent?._id] })

  const { confirm, value, defaultValues, formControls } = useMemo(() => ({
    confirm: {
      description: `Updating to an earlier minute delay may reschedule some broadcasts originated by this
                    campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledDelayMinutes }) => (scheduledDelayMinutes < campaignEvent.scheduledDelayMinutes)
    },
    value: isNumber(campaignEvent.scheduledDelayMinutes)
      ? <Pluralize count={campaignEvent.scheduledDelayMinutes} singular='Minute' includeCount />
      : null,
    defaultValues: pick(campaignEvent, 'scheduledDelayMinutes'),
    formControls: [{ label: '', name: 'scheduledDelayMinutes', type: 'number', min: 0 }]
  }), [campaignEvent])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the number of minutes to wait before sending this message.'
      formControls={formControls}
      formDescription='Updating Minute Delay for this message'
      formTitle='Minute Delay'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Minute Delay'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventMinuteDelayDetail.displayName = 'EventMinuteDelayDetail'
EventMinuteDelayDetail.propTypes = propTypes
EventMinuteDelayDetail.defaultProps = defaultProps

export default EventMinuteDelayDetail
