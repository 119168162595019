import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import AddAction from '../../../components/managers/actions/AddAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const ManagerListHeaderMoreMenu = ({ listRef }) => {
  const addActionRef = useRef()

  const handleAddClick = useCallback(() => addActionRef.current.activate(), [])
  const handleReloadClick = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadClick} />
      <PanelHeaderButton icon={<Plus />} title='Add New Manager' onClick={handleAddClick} />
      <AddAction ref={addActionRef} />
    </>
  )
}

ManagerListHeaderMoreMenu.displayName = 'ManagerListHeaderMoreMenu'
ManagerListHeaderMoreMenu.propTypes = propTypes
ManagerListHeaderMoreMenu.defaultProps = defaultProps

export default ManagerListHeaderMoreMenu
