import { get as getChatService } from '../services/chat'

import useCurrentInstance from './useCurrentInstance'

const useCurrentChat = () => useCurrentInstance({
  resourceName: 'chat',
  getService: getChatService,
  replyAttrPath: 'model',
  notFoundRedirect: '/chats'
})

export default useCurrentChat
