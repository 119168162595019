import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentTag from '../../hooks/useCurrentTag'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateTagService } from '../../services/tag'

import ContactsDetail from './detail/ContactsDetail'
import TagDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import NameDetail from './detail/NameDetail'
const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TagsDetail'] })

const TagsDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const { tag, setTag, loading } = useCurrentTag()

  const handleNameUpdated = useCallback((updateReply) => {
    const updatedTag = updateReply.json
    setTag(updatedTag)
    bus.emit('tagUpdated', updatedTag)
    // navigate to the new path as the tag name is the unique tag identifier
    navigate(`/tags/${encodeURI(updatedTag.name)}`)
  }, [navigate, setTag])

  const handleContactsUpdated = useCallback((updateReply) => {
    const updatedTag = updateReply.json
    bus.emit('tagUpdated', updatedTag)
    setTag(updatedTag)
  }, [setTag])

  if (loading || !tag) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<TagDetailHeaderMoreMenu tag={tag} />}
        start={<PanelHeaderBackButton />}
        title={tag.name}
      />
      <PanelContent className='p-5'>
        <NameDetail service={updateTagService} tag={tag} onChange={handleNameUpdated} />
        <ContactsDetail tag={tag} onChange={handleContactsUpdated} />
      </PanelContent>
    </Panel>
  )
}

TagsDetail.displayName = 'TagsDetail'
TagsDetail.propTypes = propTypes
TagsDetail.defaultProps = defaultProps

export default TagsDetail
