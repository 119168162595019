import * as React from "react";
import { memo } from "react";
const SvgStarFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.668 22.834-5.648-2.89h-.054l-5.638 2.89c-1.101.56-2.388-.35-2.18-1.54l1.079-6.12s0-.04-.01-.05l-4.81-4.56c-.796-.75-.36-2.06.742-2.21l6.64-.94s.033-.01.044-.03l2.824-5.57c.545-1.08 2.137-1.09 2.694 0l2.824 5.56s.021.03.043.03l6.64.94c1.091.15 1.527 1.46.742 2.21l-4.808 4.56s-.022.03-.011.05l1.08 6.12c.206 1.19-1.08 2.1-2.17 1.54l-.023.01Z"
    />
  </svg>
);
const Memo = memo(SvgStarFilled);
export default Memo;
