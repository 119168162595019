import * as React from "react";
import { memo } from "react";
const SvgMoreHorizontal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.077 9.462a2.538 2.538 0 1 1 0 5.076 2.538 2.538 0 0 1 0-5.076Zm8.462 0a2.538 2.538 0 1 1 0 5.076 2.538 2.538 0 0 1 0-5.076Zm11 2.538a2.538 2.538 0 1 0-5.077 0 2.538 2.538 0 0 0 5.077 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMoreHorizontal);
export default Memo;
