import * as React from "react";
import { memo } from "react";
const SvgQrCode = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <g clipPath="url(#QR_Code_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.04 0h-7v7.5h2V2h5V0Zm-7 24v-6.5h2V22h5v2h-7Zm22-2v-4.5h2V24h-7v-2h5Zm2-22v7.5h-2V2h-5V0h7Zm-15 6h-3v3h3V6Zm-5-2v7h7V4h-7Zm14 2h-3v3h3V6Zm-5-2v7h7V4h-7Zm-7 11h3v3h-3v-3Zm-2 5v-7h7v7h-7Zm14-5h-3v3h3v-3Zm-5-2v7h7v-7h-7Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="QR_Code_svg__a">
        <path fill="#fff" d="M.04 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgQrCode);
export default Memo;
