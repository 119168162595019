import PropType from 'prop-types'

import contestFromJSON from '../models/contest'

import { createPropTypes, searchPropTypes, updatePropTypes } from './contest.prop-types'
import pb from './pb'

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: contestFromJSON,
      key: 'contests',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'contests#search')
  return pb.setupPost('contests/search', options)
}

export function get (contestId, background = false) {
  return pb.setupGet(`contests/${contestId}`, {
    background,
    model: {
      conversion: contestFromJSON,
      key: 'contest'
    }
  })
}

export function create (body) {
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'contests#create')
  return pb.setupPost('contests', {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'
    }
  })
}

export function update (contestId, body) {
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'campaign#update')
  return pb.setupPut(`contests/${contestId}`, {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'
    }
  })
}

export function pickWinner (contestId) {
  return pb.setupPut(`contests/${contestId}/winner`, {
    model: {
      conversion: contestFromJSON,
      key: 'contest'
    }
  })
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('contests/bulk/delete', { body, background })
}

export function del (contestId) {
  return pb.setupDelete(`contests/${contestId}`)
}
