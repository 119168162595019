import { isNumber } from 'lodash'

import useStore from '..'

export function updateUnreadChats (unreadCount) {
  if (!isNumber(unreadCount)) { return }

  useStore.setState((state) => {
    state.unreadCounts.chats = unreadCount
    state.unreadCounts.total = state.unreadCounts.chats + state.unreadCounts.spaces
  }, false, 'unread/updateUnreadChats')
}

export function updateUnreadSpaces (unreadCount) {
  if (!isNumber(unreadCount)) { return }

  useStore.setState((state) => {
    state.unreadCounts.spaces = unreadCount
    state.unreadCounts.total = state.unreadCounts.chats + state.unreadCounts.spaces
  }, false, 'unread/updateUnreadSpaces')
}
