import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { messageQualityLabel } from '../../helpers/messageQuality'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { quality as messageQualityService } from '../../services/message'
import PanelDialog from '../dialog/PanelDialog'
import LoadingIndicator from '../LoadingIndicator'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import MessageQualityAivaVariant from './message-quality/MessageQualityAivaVariant'
import MessageQualityInsightsVariant from './message-quality/MessageQualityInsightsVariant'

const propTypes = {
  variant: PropType.oneOf(['aiva', 'insights']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageQualityDialog'] })

const MessageQualityDialog = forwardRef(({ variant }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [messageQuality, setMessageQuality] = useState(null)

  ref = useDefaultRef(ref)
  const panelDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    setMessageQuality(reply.json)
  }, [])
  const { call } = useService(messageQualityService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    open (message) {
      setMessageQuality(null)
      call(message)
      panelDialogRef.current.open()
    }
  }))

  const handleCloseClick = useCallback(() => {
    panelDialogRef.current.close()
    setMessageQuality(null)
  }, [])

  const title = useMemo(() => messageQualityLabel(variant === 'aiva'), [variant])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel={title}>
      <PanelHeader start={<PanelHeaderButton icon='close' onClick={handleCloseClick} />} title={title} />
      <PanelContent className='p-6'>
        {messageQuality
          ? variant === 'aiva'
            ? <MessageQualityAivaVariant messageQuality={messageQuality} />
            : <MessageQualityInsightsVariant messageQuality={messageQuality} />
          : <LoadingIndicator className='mx-auto' />}
      </PanelContent>
    </PanelDialog>
  )
})

MessageQualityDialog.displayName = 'MessageQualityDialog'
MessageQualityDialog.propTypes = propTypes
MessageQualityDialog.defaultProps = defaultProps

export default MessageQualityDialog
