import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { hasUnreadMessages } from '../../../helpers/dates'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  className: PropType.string,
  lastMessageFromISOTime: PropType.string,
  lastMessageReadISOTime: PropType.string
}

const defaultProps = {
  className: null,
  lastMessageFromISOTime: null,
  lastMessageReadISOTime: null
}

const log = logger({ enabled: true, tags: ['UnreadIndicator'] })

const UnreadIndicator = ({ className, lastMessageFromISOTime, lastMessageReadISOTime }) => {
  useLogger({ log, lifecycle: false, tags: [lastMessageFromISOTime, lastMessageReadISOTime] })

  const unread = useMemo(() => {
    return hasUnreadMessages(lastMessageFromISOTime, lastMessageReadISOTime)
  }, [lastMessageFromISOTime, lastMessageReadISOTime])

  const computedClassName = classNames(
    'shrink-0 w-[12px] h-[12px] rounded-full border-[2px]',
    {
      'bg-blue-700': unread,
      'bg-transparent': !unread,
      'border-blue-50': unread,
      'border-transparent': !unread
    }, className
  )
  const screenReaderNotice = unread ? (<span className='sr-only'>Unread</span>) : null

  return (<div className={computedClassName}>{screenReaderNotice}</div>)
}

UnreadIndicator.displayName = 'UnreadIndicator'
UnreadIndicator.propTypes = propTypes
UnreadIndicator.defaultProps = defaultProps

export default UnreadIndicator
