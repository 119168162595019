import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useId, useMemo, useReducer, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'

import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import { categories, getDynamicFieldsByCategory } from '../../lib/dynamicFields'
import logger from '../../lib/logger'
import { notify } from '../banners/Banner'
import PanelDialog from '../dialog/PanelDialog'
import ListItem from '../list/ListItem'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import CategoryButton from './CategoryButton'
import DynamicFieldListItemContent from './DynamicFieldListItemContent'

const propTypes = {
  excludeContactPaths: PropType.arrayOf(PropType.string), // an array of customField.path strings
  excludeOtherPaths: PropType.arrayOf(PropType.string), // an array of customField.path strings
  excludeUserPaths: PropType.arrayOf(PropType.string), // an array of customField.path strings
  onSelect: PropType.func
}

const defaultProps = {
  excludeContactPaths: [],
  excludeOtherPaths: [],
  excludeUserPaths: [],
  onSelect: undefined
}

const log = logger({ enabled: true, tags: ['DynamicFieldsDialog'] })

const DynamicFieldsDialog = forwardRef(({
  excludeContactPaths,
  excludeOtherPaths,
  excludeUserPaths,
  onSelect,
  ...dialogProps
}, ref) => {
  const virtuosoRef = useRef()
  const listRef = useRef()
  const id = useId()

  useLogger({ log, lifecycle: false, tags: [id] })

  const currentUser = useCurrentUser()
  const initialState = {
    dataByCategory: {},
    currentCategory: categories[0],
    currentData: [],
    selectedItemIndex: -1,
    selectedItemIndexCategory: categories[0],
    focused: false,
    focusedItemIndex: 0
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const scrollToIndex = useCallback((index) => {
    virtuosoRef.current.scrollIntoView({
      index,
      behavior: 'auto',
      done () {
        if (index !== state.focusedItemIndex) {
          dispatch({ type: 'itemFocus', focusedItemIndex: index })
        }
      }
    })
  }, [state.focusedItemIndex])

  const focus = useCallback(() => {
    dispatch({ type: 'focus' })
    scrollToIndex(state.focusedItemIndex)
  }, [scrollToIndex, state.focusedItemIndex])

  // if current user updates, update our data
  useEffect(() => {
    const dataByCategory = getDynamicFieldsByCategory(currentUser, excludeContactPaths, excludeOtherPaths, excludeUserPaths)
    dispatch({ type: 'updateData', dataByCategory })
  }, [currentUser, excludeContactPaths, excludeOtherPaths, excludeUserPaths])

  const triggerListSubmit = useCallback(() => {
    const selected = state.dataByCategory?.[state.selectedItemIndexCategory]?.[state.selectedItemIndex]
    if (!selected) {
      return notify('Please select a dynamic field to insert first.')
    }
    dispatch({ type: 'resetSelection' })
    ref.current.close()
    onSelect?.(selected)
  }, [onSelect, ref, state.dataByCategory, state.selectedItemIndex, state.selectedItemIndexCategory])

  const handleCancel = useCallback(() => {
    dispatch({ type: 'resetSelection' })
    ref.current.close()
  }, [ref])

  const handleCategoryClick = useCallback((event) => {
    dispatch({ type: 'newCategory', category: categoryFromEvent(event) })
    setTimeout(() => listRef.current.focus(), 1)
  }, [])

  const tabs = useMemo(() => {
    return categories.map((category) => (
      <CategoryButton
        key={category}
        category={category}
        selected={category === state.currentCategory}
        onClick={handleCategoryClick}
      />
    ))
  }, [state.currentCategory, handleCategoryClick])

  const renderItem = useCallback((index, item, context) => {
    const selected = state.selectedItemIndexCategory === state.currentCategory && index === state.selectedItemIndex
    const isFocused = context.focused && state.focusedItemIndex === index
    const itemId = `${id}-${state.currentCategory}-${index}`
    return (
      <ListItem
        id={itemId}
        index={index}
        isFocused={isFocused}
        isSelected={selected}
        item={item}
        ListItemContent={DynamicFieldListItemContent}
        multiple={false}
        type='select'
      />
    )
  }, [state.selectedItemIndexCategory, state.currentCategory, state.selectedItemIndex, state.focusedItemIndex, id])

  const handleKeydown = useCallback((event) => {
    const handleScrollableEvent = (index) => {
      event.preventDefault()
      scrollToIndex(index)
    }

    if (event.key === 'ArrowUp') {
      return handleScrollableEvent(Math.max(0, state.focusedItemIndex - 1))
    } else if (event.key === 'ArrowDown') {
      const currentData = state.dataByCategory?.[state.currentCategory]
      return handleScrollableEvent(Math.min((currentData.length - 1), state.focusedItemIndex + 1))
    } else if (event.key === 'Home') {
      return handleScrollableEvent(0)
    } else if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
      event.preventDefault()
      dispatch({ type: 'newSelectedItem', index: state.focusedItemIndex })
    }
  }, [scrollToIndex, state.focusedItemIndex, state.dataByCategory, state.currentCategory])

  const handleItemClick = useCallback((event) => {
    const clickedItemIndex = itemIndexFromEvent(event)
    if (clickedItemIndex < 0) { return }
    dispatch({ type: 'newSelectedItem', index: clickedItemIndex })
  }, [])
  const handleListFocus = useCallback(() => focus(), [focus])
  const handleListBlur = useCallback(() => dispatch({ type: 'blur' }), [])

  const scrollerRef = useCallback((element) => {
    if (element) {
      element.addEventListener('mousedown', handleItemClick)
      element.addEventListener('keydown', handleKeydown)
      element.addEventListener('focus', handleListFocus)
      element.addEventListener('blur', handleListBlur)
      listRef.current = element
    } else {
      listRef.current.removeEventListener('mousedown', handleItemClick)
      listRef.current.removeEventListener('keydown', handleKeydown)
      listRef.current.removeEventListener('focus', handleListFocus)
      listRef.current.removeEventListener('blur', handleListBlur)
    }
  }, [handleItemClick, handleKeydown, handleListFocus, handleListBlur])

  const ariaActivedescendant = useMemo(() => state.focusedItemIndex > -1 ? `${id}-${state.currentCategory}-${state.focusedItemIndex}` : null, [id, state.focusedItemIndex, state.currentCategory])

  return (
    <PanelDialog ref={ref} ariaLabel='Select Dynamic Field' {...dialogProps}>
      <PanelHeader
        end={<PanelHeaderButton icon='save' onClick={triggerListSubmit} />}
        start={<PanelHeaderButton icon='cancel' onClick={handleCancel} />}
        title='Select Dynamic Field'
      />
      <PanelContent>
        <div className='w-full flex flex-row items-stretch justify-items-stretch border-neutral-200 border-b'>
          {tabs}
        </div>
        <Virtuoso
          ref={virtuosoRef}
          aria-activedescendant={ariaActivedescendant}
          aria-multiselectable={false}
          className='list'
          context={{
            focused: state.focused
          }}
          data={state.dataByCategory?.[state.currentCategory]}
          id={id}
          itemContent={renderItem}
          role='listbox'
          scrollerRef={scrollerRef}
          tabIndex='0'
        />
      </PanelContent>
    </PanelDialog>
  )
})

DynamicFieldsDialog.displayName = 'DynamicFieldsDialog'
DynamicFieldsDialog.propTypes = propTypes
DynamicFieldsDialog.defaultProps = defaultProps

const itemIndexFromEvent = (event) => {
  const target = event.target.closest('[data-list-index]')
  const listIndex = target?.dataset?.listIndex
  return !listIndex ? -1 : parseInt(listIndex, 10)
}
const categoryFromEvent = (event) => {
  const target = event.target.closest('[data-category]')
  return target.dataset.category
}

function reducer (state, action) {
  switch (action.type) {
    case 'newCategory':
      return { ...state, currentCategory: action.category, focusedItemIndex: 0 }
    case 'focus':
      return { ...state, focused: true }
    case 'blur':
      return { ...state, focused: false }
    case 'newSelectedItem':
      return { ...state, selectedItemIndex: action.index, focusedItemIndex: action.index, selectedItemIndexCategory: state.currentCategory }
    case 'resetSelection':
      return { ...state, currentCategory: categories[0], selectedItemIndex: -1, selectedItemIndexCategory: categories[0] }
    case 'itemFocus':
      return { ...state, focusedItemIndex: action.focusedItemIndex }
    case 'updateData':
      // TODO: should we check to ensure our selected/focused items are still in bounds?
      return { ...state, dataByCategory: { ...action.dataByCategory } }
    default:
      throw new Error(`Unknown action: ${action?.type}`)
  }
}

export default DynamicFieldsDialog
