import PropType from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useWizard } from 'react-use-wizard'

import Banner from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import SupportLink from '../../../components/links/SupportLink'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import caProvinceAndTerritoryData from '../../../data-sources/states/ca'
import usStateData from '../../../data-sources/states/us'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { entityTypeDisplayName, entityTypes } from '../../../lib/tcr'

import validationSuite from './BusinessDetails.validations'

const propTypes = {
  dataRef: PropType.shape({
    current: PropType.shape({
      country: PropType.oneOf(['US', 'CA']),
      entityType: PropType.oneOf(Object.keys(entityTypes)),
      isLargeBusiness: PropType.oneOf(['yes', 'no'])
    }).isRequired
  }).isRequired,
  panelDialogRef: PropType.shape({
    current: PropType.shape({
      close: PropType.func.isRequired
    })
  }).isRequired,
  onBack: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BusinessDetails'] })

const BusinessDetails = ({ dataRef, panelDialogRef, onBack, onSubmit }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { entityType } = dataRef.current
  const [country, setCountry] = useState(dataRef.current.country)
  const [isLargeBusiness, setIsLargeBusiness] = useState(entityType === 'SOLE_PROPRIETOR' ? 'no' : dataRef.current.isLargeBusiness)
  const formRef = useRef()
  const { nextStep, previousStep, isFirstStep } = useWizard()

  useEffect(() => {
    formRef.current.setValue('state', '')
  }, [country])

  const handleBack = useCallback(() => {
    const values = formRef.current.getValues()
    onBack(values)
    previousStep()
  }, [onBack, previousStep])

  const handleCloseClick = useCallback(() => panelDialogRef.current.close(), [panelDialogRef])
  const headerCloseButton = <PanelHeaderButton icon='close' onClick={handleCloseClick} />
  const headerBackButton = <PanelHeaderButton icon='back' onClick={handleBack} />

  const handleSubmit = useCallback(() => {
    if (isLargeBusiness === 'yes') { return }
    const values = formRef.current.getValues()
    onSubmit(values)
    nextStep()
  }, [isLargeBusiness, nextStep, onSubmit])

  const formControls = [
    entityType === 'PRIVATE_PROFIT' && {
      name: 'isLargeBusiness',
      label: 'Does your business have more than one Project Broadcast account?',
      type: 'select',
      required: true,
      options: [
        { label: 'Select an option', value: '' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      onChange: (e) => {
        setIsLargeBusiness(e.target.value)
      }
    },
    {
      name: 'country',
      label: 'Country of Registration',
      type: 'countryselect',
      hint: entityType === 'SOLE_PROPRIETOR' ? 'Your country of residence' : 'The country where your business is registered',
      required: true,
      onChange: (e) => {
        setCountry(e.target.value)
      }
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'companyName',
      label: 'Company Name',
      placeholder: 'Enter the legal name of the business',
      hint: 'This must exactly match with government registration documents',
      type: 'text',
      required: true
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'ein',
      label: country === 'US' ? 'EIN (Tax ID)' : 'Business Number (Tax ID)',
      placeholder: country === 'US' ? 'XX-XXXXXXX' : 'Business Number (Tax ID)',
      hint: `Your 9-digit ${country === 'US' ? 'EIN' : 'business number'}. This can be found on your registration documents`,
      type: 'text',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'firstName',
      label: 'First Name',
      placeholder: 'Enter your first name',
      type: 'text',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Enter your last name',
      type: 'text',
      required: true
    },
    {
      name: 'email',
      label: 'Contact Email',
      placeholder: 'Enter your contact email',
      type: 'email',
      required: true
    },
    {
      name: 'street',
      label: 'Street Name and Number',
      placeholder: 'Enter an address',
      type: 'text',
      required: true
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      placeholder: 'Enter a city',
      required: true
    },
    {
      type: 'controlgroup',
      name: 'group1',
      containerClassName: 'flex-wrap',
      formControls: [
        {
          type: 'select',
          name: 'state',
          label: country === 'US' ? 'State' : 'Province/Territory',
          placeholder: country === 'US' ? 'Select a state' : 'Select a province/territory',
          options: country === 'US' ? usStateData : caProvinceAndTerritoryData,
          required: true,
          containerClassName: 'flex-grow'
        },
        {
          type: 'text',
          name: 'postalCode',
          label: 'Postal Code',
          placeholder: 'Postal code'
        }
      ]
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'phone',
      label: 'Business Phone',
      placeholder: 'The 10-digit support contact telephone number',
      type: 'tel',
      required: true
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'uiWarning',
      type: 'passthrough',
      element: (
        <Banner className='mb-5' type='warning'>
          Please ensure the business information and address you provided is the same as the address on your government registration documents. This will be used as part of the business verification process. If these do not match, your registration will not be approved.
        </Banner>
      )
    }
  ].filter((c) => c)

  if (!entityType) {
    return null
  }

  const adjustedFormControls = formControls

  if (isLargeBusiness === 'yes') {
    adjustedFormControls.length = 1
    adjustedFormControls.push({
      name: 'uiWarning',
      type: 'passthrough',
      element: (
        <Banner className='mb-5' type='warning'>
          If you are a private company with multiple Project Broadcast accounts, you must email
          {' '}
          <SupportLink />
          {' '}
          for registration assistance.
        </Banner>
      )
    })
  }

  return (
    <>
      <PanelHeader
        start={isFirstStep ? headerCloseButton : headerBackButton}
        subtitle={entityTypeDisplayName(entityType)}
        title='TCR Business Details'
      />
      <PanelContent className='p-5'>
        <div className='w-full md:max-w-md mx-auto'>
          <Form
            ref={formRef}
            defaultValues={dataRef.current}
            formControls={adjustedFormControls}
            id='tcr-BusinessDetails'
            validationSuite={validationSuite}
            onSubmit={handleSubmit}
          />
          {isLargeBusiness === 'yes'
            ? null
            : (
              <div className='flex flex-row gap-4 items-center'>
                <Button form='tcr-BusinessDetails' size='sm' type='submit'>Continue</Button>
              </div>
              )}
        </div>
      </PanelContent>
    </>
  )
}

BusinessDetails.displayName = 'BusinessDetails'
BusinessDetails.propTypes = propTypes
BusinessDetails.defaultProps = defaultProps

export default BusinessDetails
