import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet'
import { AppLauncher } from '@capacitor/app-launcher'
import { Capacitor } from '@capacitor/core'
import { Share as NativeShare } from '@capacitor/share'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Download from 'yet-another-react-lightbox/plugins/download'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { NewWindow as NewWindowIcon, Share as ShareIcon } from '../svg/icons'

import './LightBox.css'

const propTypes = {
  slides: PropType.arrayOf(PropType.shape({
    src: PropType.string
  })).isRequired,
  onView: PropType.func
}

const defaultProps = {
  onView: undefined
}

const log = logger({ enabled: false, tags: ['LightBox'] })

const LightBox = forwardRef(({ slides, onView, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const lightboxRef = useRef()
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const [lightboxOpen, setLightboxOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open (initialIndex = 0) {
      setLightboxIndex(initialIndex)
      setLightboxOpen(true)
    },
    close () {
      setLightboxOpen(false)
    }
  }), [])

  const handleLightboxClose = useCallback(() => setLightboxOpen(false), [])

  const handleNativeDownload = useCallback(async ({ slide }) => {
    const { index: selectedSource } = await ActionSheet.showActions({
      options: [
        { title: 'Share Image' },
        { title: 'Open In Browser' },
        {
          title: 'Cancel',
          style: ActionSheetButtonStyle.Cancel
        }
      ]
    })
    switch (selectedSource) {
      case 0:
        try { await NativeShare.share({ dialogTitle: 'Sharing Image', url: slide.src }) } catch (ex) {}
        break
      case 1:
        AppLauncher.openUrl({ url: slide.src })
        break
    }
  }, [])

  return (
    <Lightbox
      carousel={{ finite: true }}
      close={handleLightboxClose}
      controller={{ ref: lightboxRef }}
      download={Capacitor.isNativePlatform() ? { download: handleNativeDownload } : null}
      index={lightboxIndex}
      on={{ view: onView }}
      open={lightboxOpen}
      plugins={[Download, Zoom]}
      render={{
        iconDownload: () => (
          <div className='w-[26px] h-[26px]'>
            {Capacitor.isNativePlatform() ? <ShareIcon /> : <NewWindowIcon />}
          </div>
        )
      }}
      slides={slides}
      styles={{
        container: {
          marginTop: 'env(safe-area-inset-top)',
          marginRight: 'env(safe-area-inset-right)',
          marginBottom: 'env(safe-area-inset-bottom)',
          marginLeft: 'env(safe-area-inset-left)'
        },
        root: {
          backgroundColor: '#000'
        },
        button: {
          paddingLeft: '0',
          paddingRight: '0'
        }
      }}
      {...rest}
    />
  )
})

LightBox.displayName = 'LightBox'
LightBox.propTypes = propTypes
LightBox.defaultProps = defaultProps

export default LightBox
