import { get as getConfigurableFormService } from '../services/configurableForm'

import useCurrentInstance from './useCurrentInstance'

const useCurrentConfigurableForm = () => useCurrentInstance({
  resourceName: 'configurableForm',
  getService: getConfigurableFormService,
  replyAttrPath: 'model',
  notFoundRedirect: '/configurable-forms'
})

export default useCurrentConfigurableForm
