import * as React from "react";
import { memo } from "react";
const SvgFilter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm1-1.83V1a1 1 0 1 0-2 0v5.17a3.001 3.001 0 0 0 0 5.66V23a1 1 0 1 0 2 0V11.83a3.001 3.001 0 0 0 0-5.66ZM15 16a3.001 3.001 0 0 1-2 2.83V23a1 1 0 1 1-2 0v-4.17a3.001 3.001 0 0 1 0-5.66V1a1 1 0 1 1 2 0v12.17c1.165.412 2 1.524 2 2.83Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-9-6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1-3.83V1a1 1 0 0 0-2 0v5.17a3.001 3.001 0 0 0 0 5.66V23a1 1 0 1 0 2 0V11.83a3.001 3.001 0 0 0 0-5.66Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgFilter);
export default Memo;
