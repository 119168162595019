import auditFromJSON from '../models/audit'
import messageFromJSON from '../models/message'
import userFromJSON from '../models/user'

import pb from './pb'

export function get (id, background = false) {
  const options = {
    background,
    model: {
      conversion: userFromJSON,
      key: 'user'
    }
  }
  return pb.setupGet(`admin/users/${id}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: userFromJSON,
      key: 'users',
      multiple: true
    }
  }
  return pb.setupPost('admin/users', options)
}

export function giveCredits (body, id) {
  const options = {
    body,
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/giveCredits`, options)
}

export function restoreCancelledCredits (body, id) {
  const options = {
    body,
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/restoreCancelledCredits`, options)
}

export function getMfaSettings (id) {
  return pb.setupGet(`admin/users/${id}/mfaSettings`)
}

export function updateMfaSettings (body, id) {
  return pb.setupPut(`admin/users/${id}/mfaSettings`, { body })
}

export function setPassword (body, id) {
  const options = {
    body,
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/setPassword`, options)
}

export function referralCode (body, id) {
  const options = {
    body
  }
  return pb.setupPost(`admin/users/${id}/applyReferralCode`, options)
}

export function exportPaymentHistory (id) {
  return pb.setupPost(`admin/users/${id}/exportPaymentHistory`)
}

export function stats () {
  return pb.setupGet('admin/stats')
}

export function unsentMessages (body) {
  const options = {
    body,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  return pb.setupPost(`admin/users/${body.userId}/unsentMessages`, options)
}

export function auditHistory (body) {
  const options = {
    body,
    model: {
      conversion: auditFromJSON,
      key: 'audits',
      multiple: true
    }
  }
  return pb.setupPost(`admin/users/${body.userId}/auditHistory`, options)
}

export function updateNumber (body, id) {
  const options = {
    body,
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/updateNumber`, options)
}

export function validateBrand (body) {
  return pb.setupPost('admin/users/validateBrand', { body })
}

export function resubmitTwilioSolePropCampaign (id) {
  const options = {
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/resubmitTwilioSolePropCampaign`, options)
}

export function updateTwilioSolePropCampaign (body, id) {
  const options = {
    body,
    model: {
      conversion: userFromJSON
    }
  }
  return pb.setupPost(`admin/users/${id}/updateTwilioSolePropCampaign`, options)
}

export function spammers (body) {
  const options = {
    body,
    model: {
      conversion: userFromJSON,
      key: 'users',
      multiple: true
    }
  }
  return pb.setupPost('admin/spammers', options)
}

export function markAsSpammer (id) {
  return pb.setupPost(`admin/users/${id}/markAsSpammer`)
}

export function markAsEnabled (id) {
  return pb.setupPost(`admin/users/${id}/markAsEnabled`)
}
