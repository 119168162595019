import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import TermsLink from '../../components/links/TermsLink'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { bundleForCreditsAmount, toCreditOptions } from '../../helpers/credits'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import logger from '../../lib/logger'
import { credit as creditService } from '../../services/credit'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

import validationSuite from './AutoRecharge.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AutoRecharge'] })

const AutoRecharge = () => {
  const formRef = useRef()
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const [creditBundles, setCreditBundles] = useState([])
  const [currentBundle, setCurrentBundle] = useState(null)
  const [lowCreditThreshold, setLowCreditThreshold] = useState(currentUser.autoRecharge.lowCreditThreshold)
  const [autoRechargeEnabled, setAutoRechargeEnabled] = useState(currentUser.autoRecharge.enabled)

  const handleCreditBundlesReplyOk = useCallback((result) => {
    const creditBundles = result.json
    const currentRenewalCreditAmount = currentUser.autoRecharge.renewalCreditAmount
    const currentBundle = bundleForCreditsAmount(creditBundles, currentRenewalCreditAmount)
    setCreditBundles(creditBundles)
    setCurrentBundle(currentBundle)
  }, [currentUser])
  const { call: fetchCredit } = useService(creditService, { onReplyOk: handleCreditBundlesReplyOk })
  useEffect(() => { fetchCredit() }, [fetchCredit])

  const handleProfileUpdateOk = useCallback(() => { notify('Auto-Recharge updated.') }, [])
  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateAction, { onReplyOk: handleProfileUpdateOk })

  const handleEnabledChange = useCallback((event) => setAutoRechargeEnabled(event.target.checked), [])
  const handleLowCreditThresholdInputChange = useCallback((event) => setLowCreditThreshold(event.target.value), [])
  const handleCreditBundleChange = useCallback((event) => setCurrentBundle(bundleForCreditsAmount(creditBundles, event.target.value)), [creditBundles])
  const handleSubmit = useCallback((data) => updateProfile({ autoRecharge: data }), [updateProfile])

  const creditBundleOptions = useMemo(() => { return toCreditOptions(creditBundles) }, [creditBundles])

  const formControls = useMemo(() => [
    {
      type: 'checkbox',
      name: 'enabled',
      label: 'When enabled, Project Broadcast will charge your card according to the specifications you select below.',
      title: 'Auto-Recharge',
      onChange: handleEnabledChange,
      disabled: false
    },
    {
      type: 'number',
      name: 'lowCreditThreshold',
      label: 'Each time your credits fall below:',
      min: 25,
      step: 25,
      onChange: handleLowCreditThresholdInputChange,
      disabled: !autoRechargeEnabled
    },
    {
      autoFocus: true,
      label: 'Add the following credits:',
      name: 'renewalCreditAmount',
      placeholder: 'Select a credit bundle',
      type: 'select',
      onChange: handleCreditBundleChange,
      options: creditBundleOptions,
      disabled: !autoRechargeEnabled
    }
  ], [autoRechargeEnabled, creditBundleOptions, handleCreditBundleChange, handleEnabledChange, handleLowCreditThresholdInputChange])

  const defaultValues = useMemo(() => {
    return {
      lowCreditThreshold: currentUser.autoRecharge.lowCreditThreshold,
      renewalCreditAmount: currentUser.autoRecharge.renewalCreditAmount,
      enabled: currentUser.autoRecharge.enabled
    }
  }, [currentUser])

  if (!creditBundles.length || !currentBundle) { return <PanelLoading start={<PanelHeaderBackButton />} title='Auto Recharge' /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='autoRechargeForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        title='Auto Recharge'
      />
      <PanelContent className='p-5'>
        <p className='mb-2'>
          Ever run out of credits and had to reschedule your failed messages
          after purchasing credits? Yup, we know its a pain! Auto-Recharge
          allows Project Broadcast to charge your card automatically to add
          credits to your account each time your credits fall below a certain
          amount. This will prevent your credits from reaching 0 and causing the
          dreaded Out Of Credits errors on your scheduled messages.
        </p>
        <Form
          ref={formRef}
          defaultValues={defaultValues}
          formControls={formControls}
          id='autoRechargeForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <p className='mb-2'>
          By continuing, you understand that Project Broadcast will charge your
          card on file
          {' '}
          {currentBundle.priceLabel}
          {' '}
          to add
          {' '}
          {currentBundle.credits}
          {' '}
          credits each time
          your credits fall below
          {' '}
          {`${lowCreditThreshold}`}
          {' '}
          in addition to your
          current subscription level and add ons.
          {' '}
          <strong>Refunds are not provided.</strong>
          {' '}
          Complete terms can be found
          {' '}
          <TermsLink label='here' />
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='autoRechargeForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

AutoRecharge.displayName = 'AutoRecharge'
AutoRecharge.propTypes = propTypes
AutoRecharge.defaultProps = defaultProps

export default AutoRecharge
