import { pick } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import useBus from '../../hooks/useBus'
import useCurrentChat from '../../hooks/useCurrentChat'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import usePoller from '../../hooks/usePoller'
import logger from '../../lib/logger'
import chatFromJSON from '../../models/chat'
import ChatListEmptyContent from '../../screens/chats/list/EmptyListContent'
import { search as chatSearch } from '../../services/chat'
import List from '../list/List'

import ChatListItemContent from './ChatListItemContent'
import ChatListSearchBar from './ChatListSearchBar'

const propTypes = {
  ListItemContent: PropType.elementType
}

const defaultProps = {
  ListItemContent: ChatListItemContent
}

const log = logger({ enabled: true, tags: ['ChatList'] })

const updateObjWithLastMessage = (obj, lastMessage) => {
  const lastMessageToOrFromKey = obj.lastMessage.direction === 'in' ? 'lastMessageFrom' : 'lastMessageTo'
  obj.lastMessageRead = pick(lastMessage, ['_id', 'time'])
  obj[lastMessageToOrFromKey] = pick(lastMessage, ['_id', 'time'])
  return obj
}

const ChatList = forwardRef(({ ListItemContent, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  ref = useDefaultRef(ref)
  const lastMessageSeenIdRef = useRef(undefined)
  const ignoreNextPollerUpdate = useRef(true)

  const { chat, setChat } = useCurrentChat()

  const updateChatWithLastMessage = useCallback((lastMessage) => {
    const newChat = chatFromJSON({ ...chat, lastMessage })
    updateObjWithLastMessage(newChat, lastMessage)
    setChat(newChat)
  }, [chat, setChat])

  const updateListItemWithLastMessage = useCallback((lastMessage) => {
    const currentItem = ref.current.getItemById(chat._id)
    if (!currentItem) { return }
    const newListItem = chatFromJSON({ ...currentItem, lastMessage })
    updateObjWithLastMessage(newListItem, lastMessage)
    ref.current.updateItem(newListItem)
  }, [chat?._id, ref])

  // Attempt to update the current chat and list with the last message details
  const handleNewLastMessage = useCallback((lastMessage) => {
    log.debug('handleNewLastMessage', lastMessage._id)
    lastMessageSeenIdRef.current = lastMessage._id
    ignoreNextPollerUpdate.current = true
    updateChatWithLastMessage(lastMessage)
    updateListItemWithLastMessage(lastMessage)
  }, [updateChatWithLastMessage, updateListItemWithLastMessage])
  useBus('newLastChatMessage', handleNewLastMessage)

  const handlePollerPaused = useCallback(() => {
    // Invalidate any outstanding ignore so we pick up any changes on resume
    ignoreNextPollerUpdate.current = false
  }, [])
  usePoller('paused', handlePollerPaused)

  const handleChatsUpdate = useCallback((updates) => {
    log.debug('Got chatsUpdate event from poller', updates)
    const newLastMessageUnreadId = updates?.lastMessageUnread?._id

    log.debug(ignoreNextPollerUpdate.current ? 'ignoring' : 'processing', lastMessageSeenIdRef, newLastMessageUnreadId)

    const justMarkedLastAsRead = !newLastMessageUnreadId && updates.unreadCount === 0

    if (ignoreNextPollerUpdate.current !== true && !justMarkedLastAsRead) {
      if (lastMessageSeenIdRef.current !== newLastMessageUnreadId) {
        ref.current.indicateUpdatesDetected()
      }
    }

    lastMessageSeenIdRef.current = newLastMessageUnreadId
    ignoreNextPollerUpdate.current = false
  }, [ref])
  usePoller('chatsUpdate', handleChatsUpdate)

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('contactOptOutRelatedInfoUpdated', handleBusEvent)
  useBus('contactArchived', handleBusEvent)
  useBus('contactUpdated', handleBusEvent)
  useBus('chatHidden', handleBusEvent)
  useBus('chatMarkedAsReadOrUnread', handleBusEvent)

  return (
    <List
      ref={ref}
      EmptyListContent={ChatListEmptyContent}
      ListItemContent={ListItemContent}
      ListSearchBar={ChatListSearchBar}
      placeholder='Inbox'
      resultsKey='contacts'
      searchService={chatSearch}
      search
      {...rest}
    />
  )
})

ChatList.displayName = 'ChatList'
ChatList.propTypes = propTypes
ChatList.defaultProps = defaultProps

export default ChatList
