import * as React from 'react'
import { memo } from 'react'
const SvgKeyword = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#D0FAE4" rx={4} />
        <g clipPath="url(#Keyword_svg__a)">
            <path
                fill="#05976A"
                fillRule="evenodd"
                d="M14.082 6.442a1.521 1.521 0 0 0-2.143-.002L9.914 8.466a1.52 1.52 0 0 0-.125 2.003L6.16 14.1a.545.545 0 0 0-.159.385v2.976c0 .3.244.545.545.545h2.976a.545.545 0 0 0 .545-.545v-1.438h1.438a.545.545 0 0 0 .546-.546v-.942h2.43a.555.555 0 0 0 .093-.008c.357-.023.7-.178.962-.44l2.023-2.023c.59-.59.587-1.55.002-2.142l-3.478-3.479Zm.397 6.996a.563.563 0 0 0-.073.005h-2.902a.546.546 0 0 0-.545.546v.942H9.52a.545.545 0 0 0-.546.545v1.438H7.091V14.71l3.847-3.847a.545.545 0 0 0 0-.771l-.252-.251a.43.43 0 0 1-.001-.604l2.021-2.022a.43.43 0 0 1 .604-.001l3.474 3.473v.001a.43.43 0 0 1 .002.604l-2.023 2.023a.407.407 0 0 1-.284.123Zm-.62-4.278a.545.545 0 0 0-.772.771l.992.992a.545.545 0 0 0 .771-.771l-.991-.992Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="Keyword_svg__a">
                <path fill="#fff" d="M5.455 5.455h13.09v13.09H5.456z" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgKeyword)
export default Memo
