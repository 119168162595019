import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import ApplyKeyword from '../../../components/contacts/actions/ApplyKeywordAction'
import ArchiveAction from '../../../components/contacts/actions/ArchiveAction'
import CallAction from '../../../components/contacts/actions/CallAction'
import DeleteAction from '../../../components/contacts/actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape)
}
const defaultProps = {
  contact: null
}

const log = logger({ enabled: true, tags: ['ContactDetailHeaderMoreMenu'] })

const ContactDetailHeaderMoreMenu = forwardRef(({ contact }, ref) => {
  useLogger({ log, lifecycle: true, tags: [contact?._id] })

  const navigateInStack = useNavigateInStack()
  const currentStackKey = useCurrentStackKey()

  const archiveActionRef = useRef()
  const activateArchiveAction = useCallback(() => archiveActionRef.current.activate(), [])
  const handleArchiveActionSuccess = useCallback(() => {
    navigateInStack(currentStackKey === 'chats' ? '/chats' : '/contacts', { replace: true })
  }, [currentStackKey, navigateInStack])

  const applyKeywordActionRef = useRef()
  const activateApplyKeywordAction = useCallback(() => applyKeywordActionRef.current.activate(), [])

  const callActionRef = useRef()
  const activateCallAction = useCallback(() => callActionRef.current.activate(), [])

  const deleteActionRef = useRef()
  const activateDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])
  const handleDeleteActionSuccess = useCallback(() => navigateInStack('/contacts', { replace: true }), [navigateInStack])

  if (!contact) { return null }

  return (
    <>
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        {currentStackKey === 'contacts' ? <ActionMenuItem href={`/contacts/${contact._id}/chat`} label='Open Chat' /> : null}
        <ActionMenuItem label='Apply Keyword' onClick={activateApplyKeywordAction} />
        <ActionMenuItem href={`tel:${contact.phoneNumber}`} label='Call Using This Device' />
        <ActionMenuItem label='Call Using PB Number' onClick={activateCallAction} />
        <ActionMenuItem label='Archive Contact' onClick={activateArchiveAction} />
        <ActionMenuItem label='Delete Contact' onClick={activateDeleteAction} />
      </ActionMenu>
      <ArchiveAction ref={archiveActionRef} contact={contact} onSuccess={handleArchiveActionSuccess} />
      <ApplyKeyword ref={applyKeywordActionRef} contact={contact} />
      <CallAction ref={callActionRef} contact={contact} />
      <DeleteAction ref={deleteActionRef} contact={contact} onSuccess={handleDeleteActionSuccess} />
    </>
  )
})

ContactDetailHeaderMoreMenu.displayName = 'ContactDetailHeaderMoreMenu'
ContactDetailHeaderMoreMenu.propTypes = propTypes
ContactDetailHeaderMoreMenu.defaultProps = defaultProps

export default ContactDetailHeaderMoreMenu
