import PropType from 'prop-types'

import messageTypes from '../helpers/messageTypes'
import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}

export const updatePropTypes = {
  media: PropType.arrayOf(PropType.string),
  text: PropType.string,
  type: PropType.oneOf(messageTypes),
  removeMedia: PropType.bool,
  upload: PropType.string,
  scheduledAt: PropType.string
}
