import PropType from 'prop-types'

import Button from '../../../components/buttons/Button'
import { useApp } from '../../../contexts/AppContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  onFromDeviceSelected: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SelectFromDevice'] })

const SelectFromDevice = ({ onFromDeviceSelected }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isNative } = useApp()

  if (!isNative) { return null }

  return (
    <div className='mb-6'>
      <h2 className='headline-2xs mb-4 text-black'>One-Time Import From Your Device</h2>
      <p className='mb-4'>This tool can be used to perform a one-time import of your contacts stored on your device.</p>
      <Button className='w-auto mx-auto mb-3' size='md' onClick={onFromDeviceSelected}>Import Device Contacts</Button>
    </div>
  )
}

SelectFromDevice.displayName = 'SelectFromDevice'
SelectFromDevice.propTypes = propTypes
SelectFromDevice.defaultProps = defaultProps

export default SelectFromDevice
