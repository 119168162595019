import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuite from './NewChatAction.validationSuite'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['NewChatAction'] })

const NewChatAction = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formDialogRef = useRef()
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const defaultValues = {
    phoneNumber: '',
    firstName: '',
    lastName: ''
  }

  const formControls = [
    { label: 'Phone Number', name: 'phoneNumber', type: 'tel' },
    { label: 'First Name', name: 'firstName', type: 'text' },
    { label: 'Last Name', name: 'lastName', type: 'text' }
  ]

  const handleSubmit = useCallback((data) => {
    formDialogRef.current.close()
    const newUrlSearchParams = new URLSearchParams()
    newUrlSearchParams.set('firstName', data.firstName)
    newUrlSearchParams.set('lastName', data.lastName)
    navigate(`/chats/new/${data.phoneNumber}?${newUrlSearchParams.toString()}`)
  }, [navigate])

  return (
    <FormPanelDialog
      ref={formDialogRef}
      defaultValues={defaultValues}
      description='This will find an existing chat by phone number or create a new contact and chat.'
      formControls={formControls}
      title='New Chat'
      validationSuite={validationSuite}
      onSubmit={handleSubmit}
    />
  )
})

NewChatAction.displayName = 'NewChatAction'
NewChatAction.propTypes = propTypes
NewChatAction.defaultProps = defaultProps

export default NewChatAction
