import { isNumber, pick } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import Pluralize from '../../../components/Pluralize'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventDayDelay'] })

const EventDayDelayDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent?._id] })

  const { confirm, value, defaultValues, formControls } = useMemo(() => ({
    confirm: {
      description: `Updating to an earlier day delay may reschedule some broadcasts originated by this
                    campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledDelayDays }) => (scheduledDelayDays < campaignEvent.scheduledDelayDays)
    },
    value: isNumber(campaignEvent.scheduledDelayDays)
      ? <Pluralize count={campaignEvent.scheduledDelayDays} singular='Day' includeCount />
      : null,
    defaultValues: pick(campaignEvent, 'scheduledDelayDays'),
    formControls: [{ label: '', name: 'scheduledDelayDays', type: 'number', min: 0 }]
  }), [campaignEvent])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the number of days to wait before sending this message. Setting this to 0
                   will send immediately if a contact joins after the time set on this message.'
      formControls={formControls}
      formDescription='Updating Day Delay for this message'
      formTitle='Day Delay'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Day Delay'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventDayDelayDetail.displayName = 'EventDayDelayDetail'
EventDayDelayDetail.propTypes = propTypes
EventDayDelayDetail.defaultProps = defaultProps

export default EventDayDelayDetail
