import PropType from 'prop-types'

import ExternalLink from './ExternalLink'

MailLink.propTypes = {
  to: PropType.string.isRequired,
  bcc: PropType.string,
  body: PropType.string,
  cc: PropType.string,
  className: PropType.string,
  label: PropType.string,
  subject: PropType.string
}

MailLink.defaultProps = {
  bcc: null,
  body: null,
  cc: null,
  className: null,
  label: null,
  subject: null
}

function MailLink ({ className, to, cc, bcc, label, subject, body }) {
  let href = `mailto:${to}`

  const params = {
    cc,
    bcc,
    subject,
    body
  }
  const paramsStr = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')

  if (paramsStr) {
    href += `?${paramsStr}`
  }

  return (
    <ExternalLink
      className={className}
      href={href}
      label={label || to}
    />
  )
}

export default MailLink
