import { useCallback, useRef } from 'react'

import List from '../../components/list/List'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useBus from '../../hooks/useBus'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as managersSearch } from '../../services/user-manager'

import ManagerListEmptyContent from './managers/ListEmptyContent'
import ManagerListHeaderMoreMenu from './managers/ListHeaderMoreMenu'
import ManagerListItemContent from './managers/ListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Managers'] })

const ManagersList = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()

  const reloadList = useCallback(() => {
    listRef.current.reload()
  }, [])
  useBus('managerAdded', reloadList)
  useBus('managerRemoved', reloadList)

  return (
    <Panel>
      <PanelHeader
        end={<ManagerListHeaderMoreMenu listRef={listRef} />}
        start={<PanelHeaderBackButton />}
        title='Account Managers'
      />
      <PanelContent>
        <List
          ref={listRef}
          EmptyListContent={ManagerListEmptyContent}
          ListItemContent={ManagerListItemContent}
          searchService={managersSearch}
          type='list'
        />
      </PanelContent>
    </Panel>
  )
}

ManagersList.displayName = 'Managers'
ManagersList.propTypes = propTypes
ManagersList.defaultProps = defaultProps

export default ManagersList
