import * as React from "react";
import { memo } from "react";
const SvgSearchNoResults = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 180"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v180H0z" />
    <circle
      cx={187}
      cy={95}
      r={73.5}
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
    />
    <path
      fill="#A6B4FD"
      fillRule="evenodd"
      d="M281 40a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM73 69a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Zm0 11a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Zm8-3a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1Zm-12 1a1 1 0 1 0 0-2h-5a1 1 0 1 0 0 2h5ZM281 148a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm0 11a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm8-3a1 1 0 0 1-1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1Zm-12 1a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2h5ZM99.5 138a1 1 0 0 0-2 0v6.5H91a1 1 0 0 0 0 2h6.5v6.5a1 1 0 0 0 2 0v-6.5h6.5a1 1 0 0 0 0-2h-6.5V138ZM304.031 90.425a1 1 0 1 0-1.414-1.415l-4.596 4.597-4.597-4.597a1 1 0 1 0-1.414 1.415l4.597 4.596-4.597 4.596a1 1 0 0 0 1.415 1.414l4.596-4.596 4.596 4.596a.999.999 0 1 0 1.414-1.414l-4.596-4.596 4.596-4.596ZM106 19a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm0 15a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Zm-5-5a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1Zm14 1a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2h5Zm-13.1 2.1a1 1 0 0 1 0 1.414l-3.264 3.264a1 1 0 1 1-1.414-1.414l3.264-3.264a1 1 0 0 1 1.414 0Zm10.878-9.464a1 1 0 1 0-1.414-1.414l-3.264 3.263a1 1 0 0 0 1.414 1.415l3.264-3.264ZM101.9 25.9a1 1 0 0 1-1.414 0l-3.264-3.264a1 1 0 1 1 1.414-1.414l3.264 3.264a1 1 0 0 1 0 1.414Zm9.464 10.878a1 1 0 1 0 1.414-1.414l-3.263-3.264a1.001 1.001 0 0 0-1.415 1.414l3.264 3.264Z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={4}
      d="M188 42h-81M188 58h-81M188 74h-81M188 90h-81M188 106h-81M188 122h-81M188 138h-81M188 154h-81"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M217.581 135.86a2 2 0 0 0 2 2h1.163a2 2 0 0 1 2 2v24.675a9.465 9.465 0 0 1-18.93 0V139.86a2 2 0 0 1 2-2h1.163a2 2 0 0 0 2-2v-3.744a2 2 0 0 1 2-2h4.604a2 2 0 0 1 2 2v3.744Z"
      clipRule="evenodd"
    />
    <path
      fill="#6366F1"
      fillRule="evenodd"
      d="M210.977 131.616a.5.5 0 0 0-.5.5v3.745a3.5 3.5 0 0 1-3.5 3.5h-1.163a.5.5 0 0 0-.5.5v24.674a7.965 7.965 0 1 0 15.93 0v-24.674a.5.5 0 0 0-.5-.5h-1.163a3.5 3.5 0 0 1-3.5-3.5v-3.745a.5.5 0 0 0-.5-.5h-4.604Zm-3.5.5a3.5 3.5 0 0 1 3.5-3.5h4.604a3.5 3.5 0 0 1 3.5 3.5v3.745a.5.5 0 0 0 .5.5h1.163a3.5 3.5 0 0 1 3.5 3.5v24.674c0 6.056-4.909 10.965-10.965 10.965s-10.965-4.909-10.965-10.965v-24.674a3.5 3.5 0 0 1 3.5-3.5h1.163a.5.5 0 0 0 .5-.5v-3.745Z"
      clipRule="evenodd"
    />
    <path
      fill="#6366F1"
      fillRule="evenodd"
      d="M213.709 124.419c14.199 0 25.71-11.511 25.71-25.71 0-14.199-11.511-25.709-25.71-25.709C199.51 73 188 84.51 188 98.71c0 14.198 11.51 25.709 25.709 25.709Zm0 10c19.722 0 35.71-15.988 35.71-35.71 0-19.721-15.988-35.709-35.71-35.709C193.988 63 178 78.988 178 98.71c0 19.721 15.988 35.709 35.709 35.709Z"
      clipRule="evenodd"
    />
    <path fill="#fff" d="M219 89.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z" />
    <path
      fill="#A6B4FD"
      fillRule="evenodd"
      d="M220 89.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Zm-7.5 6.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M220 101.5c0 1.381-1.343 2.5-3 2.5s-3-1.119-3-2.5 1.343-2.5 3-2.5 3 1.119 3 2.5Z"
    />
    <path
      fill="#A6B4FD"
      fillRule="evenodd"
      d="M221 101.5c0 2.096-1.969 3.5-4 3.5-2.031 0-4-1.404-4-3.5s1.969-3.5 4-3.5c2.031 0 4 1.404 4 3.5Zm-4 2.5c1.657 0 3-1.119 3-2.5s-1.343-2.5-3-2.5-3 1.119-3 2.5 1.343 2.5 3 2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSearchNoResults);
export default Memo;
