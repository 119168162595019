import PropType from 'prop-types'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import goalShape from '../../../../prop-types/shapes/goal'
import Banner from '../../../banners/Banner'
import BlockingDialog from '../../../dialog/BlockingDialog'

const propTypes = {
  goal: PropType.shape(goalShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ImportGoalAction'] })

const ImportGoalAction = forwardRef(({ goal }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const blockingDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () {
      blockingDialogRef.current.open()
    }
  }), [])

  return (
    <BlockingDialog
      ref={blockingDialogRef}
      buttonVerb='Dismiss'
      title={`Import ${goal.name}`}
      onClick={() => blockingDialogRef.current.close()}
    >
      <Banner title='Currently Not Implemented' type='warning' />
      <p className='paragraph-sm text-neutral-500 text-left my-4'>
        The goal can be imported manually for now.
      </p>
      <pre className='paragraph-xs bg-neutral-200 p-3'>
        node scripts/_ImportGoal.js
      </pre>

    </BlockingDialog>
  )
})

ImportGoalAction.displayName = 'ImportGoalAction'
ImportGoalAction.propTypes = propTypes
ImportGoalAction.defaultProps = defaultProps

export default ImportGoalAction
