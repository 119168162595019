import { useMemo, useRef } from 'react'
import { create, enforce, only, test } from 'vest'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import { usePanel } from '../../../contexts/PanelContext'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import logger from '../../../lib/logger'
import { localTimeZone } from '../../../lib/timeZones'

const log = logger({ enabled: true, tags: ['Style Guide', 'FormDialogs'] })

function Forms () {
  useLogger({ log, lifecycle: false, tags: [] })

  const ref1 = useRef()
  const {
    loading: timeZoneInfoLoading,
    dataForSelect
  } = useTimeZone()
  log.debug('timezone', { localTimeZone, dataForSelect })

  const form1 = useMemo(() => ({
    id: 'testForm1',
    defaultValues: {
      test1: 'default for 1',
      test: {
        test2: 'default for 2',
        test3: 'default for 3'
      },
      password: '',
      timezone: localTimeZone,
      select: '2',
      textarea1: 'testing',
      message1: {
        text: 'hello 🫡',
        media: ['https://picsum.photos/seed/picsum.s3.amazonaws.com/370/260'],
        type: 'mms'
      },
      filter: 'starred'
    },
    formControls: [
      {
        type: 'text',
        name: 'test1',
        label: 'Input 1'
      },
      {
        type: 'controlgroup',
        name: 'group1',
        formControls: [
          {
            type: 'text',
            name: 'test[test2]',
            label: 'Input 2',
            containerClassName: 'flex-grow'
          },
          {
            type: 'text',
            name: 'test[test3]',
            label: 'Input 3'
          }
        ]
      },
      {
        type: 'password',
        name: 'password',
        label: 'Password'
      },
      {
        type: 'select',
        name: 'timezone',
        label: 'Time Zone',
        options: dataForSelect,
        placeholder: 'Choose Time Zone'
      },
      {
        type: 'modelselect',
        name: 'attachments',
        label: 'Attachments',
        model: 'attachments',
        placeholder: 'Select Attachments'
      },
      {
        type: 'modelselect',
        name: 'campaigns',
        label: 'Campaigns',
        model: 'campaigns'
      },
      {
        type: 'controlgroup',
        name: 'group2',
        formControls: [
          {
            containerClassName: 'basis-4/6',
            type: 'modelselect',
            name: 'contacts',
            label: 'Contacts',
            model: 'contacts'
          },
          {
            containerClassName: 'basis-2/6',
            type: 'modelselect',
            name: 'contact',
            label: 'Single Contact',
            model: 'contacts',
            multiple: false
          }
        ]
      },
      {
        type: 'modelselect',
        name: 'tags',
        label: 'Tags',
        model: 'tags'
      },
      {
        type: 'select',
        name: 'select',
        label: 'Select',
        options: [
          { label: 'One', value: '1' },
          { label: 'Two', value: '2' },
          { label: 'Three', value: '3' }
        ]
      },
      {
        type: 'textarea',
        name: 'textarea1',
        label: 'Text Area',
        emojiPicker: true,
        className: 'h-[100px]'
      },
      {
        type: 'message',
        name: 'message1',
        label: 'Message'
      },
      {
        type: 'datetimeselect',
        name: 'datetimeselect1',
        label: 'Date & Time Select',
        placeholder: 'Select a date and time'
      },
      {
        type: 'radiogroup',
        name: 'filter',
        label: 'Filter',
        options: [
          { label: 'Sent', value: 'sent' },
          { label: 'Unread', value: 'unread' },
          { label: 'Starred', value: 'starred' },
          { label: 'Scheduled', value: 'scheduled' }
        ]
      }
    ],
    onSubmit: function (data) {
      log.info(data)
    },
    validationSuite: create((data = {}, currentField) => {
      only(currentField)
      test('test1', 'Input 1 is required', () => {
        enforce(data.test1).isNotEmpty()
      })
      test('test2', 'Input 2 is required', () => {
        enforce(data.test.test2).isNotEmpty()
      })
      test('test3', 'Input 3 is required', () => {
        enforce(data.test.test3).isNotEmpty()
      })
    })
  }), [dataForSelect])

  const { inFirst } = usePanel()
  const start = inFirst ? <PanelHeaderBackButton /> : null

  return (
    <Panel>
      <PanelHeader start={start} title='Forms' />
      <PanelContent>
        <div className='p-4'>
          {!timeZoneInfoLoading && <Form ref={ref1} {...form1} />}
          <div className='flex flex-row gap-4 items-center'>
            <Button form='testForm1' size='sm' type='submit'>Submit</Button>
            <Button size='sm' variant='outline' onClick={() => ref1.current.reset('', { keepDefaultValues: true })}>Reset</Button>
            <Button size='sm' variant='outline' onClick={() => ref1.current.setValue('test1', 'testing')}>Set Input 1 to 'testing'</Button>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Forms
