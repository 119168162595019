import { Filesystem } from '@capacitor/filesystem'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import { useCallback } from 'react'

import { warning } from '../components/banners/Banner'
import { useApp } from '../contexts/AppContext'
import logger from '../lib/logger'

import useLogger from './useLogger'

const log = logger({ enabled: true, tags: ['useFilePicker'] })

const useFilePicker = ({ typeFilter, limitMB }) => {
  useLogger({ log, lifecycle: false })
  const { isIOS, isNative, isWeb } = useApp()

  const show = useCallback(async () => {
    let types
    switch (typeFilter) {
      case '*/*':
        types = (isIOS && isNative) ? [] : ['*/*']
        break
      case 'audio/wav':
        types = ['wav', 'audio/wav']
        break
      case 'text/csv':
        types = ['text/csv', 'text/comma-separated-values', 'application/csv']
        break
      case 'image/*':
        types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
        break
      default:
        types = [typeFilter]
        break
    }

    let pickFileResult
    try {
      pickFileResult = await FilePicker.pickFiles({
        types,
        multiple: false,
        readData: isWeb
      })
    } catch (err) {
      log.debug('Had an error from FilePicker.pickFiles... probably just user canceled though', err)
      return null
    }
    const file = pickFileResult.files?.length > 0 && pickFileResult.files[0]

    if (file.size > limitMB * 1024 * 1024) {
      warning('Invalid Selection', `File must be less than ${limitMB}MB`)
      return null
    }

    let fileDataBase64 = file.data
    if (!fileDataBase64) {
      const { data } = await Filesystem.readFile({ path: file.path })
      fileDataBase64 = data
    }

    file.uploadData = `PB-CAP-FILE;${encodeURIComponent(file.name)};${encodeURIComponent(file.mimeType)};${fileDataBase64}`
    file.webPath = `data:${file.mimeType};base64,${fileDataBase64}`

    return file
  }, [isIOS, isNative, isWeb, limitMB, typeFilter])

  return { show }
}

export default useFilePicker
