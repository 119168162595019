import PropType from 'prop-types'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {
  to: PropType.string.isRequired,
  paramName: PropType.string
}

const defaultProps = {
  paramName: null
}

const log = logger({ enabled: false, tags: ['LegacyAppRedirect'] })

const LegacyAppRedirect = ({ paramName, to }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const params = useParams()
  const [searchParams] = useSearchParams()

  const queryString = searchParams.toString()
  const interpolatedTo = paramName ? to.replace(`:${paramName}`, params[paramName]) : to
  const resolvedTo = `/${interpolatedTo}${queryString ? `?${queryString}` : ''}`

  return (<Navigate to={resolvedTo} replace />)
}

LegacyAppRedirect.displayName = 'LegacyAppRedirect'
LegacyAppRedirect.propTypes = propTypes
LegacyAppRedirect.defaultProps = defaultProps

export default LegacyAppRedirect
