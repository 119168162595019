import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import DeleteAction from '../../../components/campaigns/event-actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { campaignTypeInfo } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import campaignEvent from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEvent)
}

const defaultProps = {
  campaignEvent: null
}

const log = logger({ enabled: false, tags: ['EventDetailHeaderMoreMenu'] })

const EventDetailHeaderMoreMenu = ({ campaignEvent }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => {
    const { supportsMultipleMessages } = campaignTypeInfo(campaignEvent.campaignType)
    if (supportsMultipleMessages) {
      navigateInStack(`/campaigns/${campaignEvent.campaignId}/events`, { replace: true })
    } else {
      navigateInStack(`/campaigns/${campaignEvent.campaignId}`, { replace: true })
    }
  }, [campaignEvent, navigateInStack])

  return !!campaignEvent && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Delete Campaign Message' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} campaignEvent={campaignEvent} onSuccess={onDeleteSuccess} />
    </>
  )
}

EventDetailHeaderMoreMenu.displayName = 'EventDetailHeaderMoreMenu'
EventDetailHeaderMoreMenu.propTypes = propTypes
EventDetailHeaderMoreMenu.defaultProps = defaultProps

export default EventDetailHeaderMoreMenu
