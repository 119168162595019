import classNames from 'classnames'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import { Close, Collapse, Expand } from '../../svg/icons'
import Button from '../buttons/Button'

import AivaChat from './AivaChat'

import './AivaChatPopover.css'

export const propTypes = {}

export const defaultProps = {}

const log = logger({ enabled: false, tags: ['AivaChatPopover'] })

const AivaChatPopover = forwardRef((params, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const smallScreen = useSmallScreen()
  const aivaChatRef = useRef()

  useImperativeHandle(ref, () => ({
    open () {
      setOpen(true)
    }
  }), [])

  useEffect(() => {
    if (open) {
      aivaChatRef.current.syncNewMessages()
    }
  }, [open])

  const handleToggleExpand = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const handleMinimize = useCallback(() => {
    setOpen(false)
  }, [])

  const className = classNames(
    'fixed flex flex-col bg-white overflow-hidden z-[999]',
    'h-full w-full inset-0',
    'sm:h-[600px] sm:w-[375px] sm:top-auto sm:left-auto sm:bottom-5 sm:right-5',
    'sm:border sm:border-neutral-300 sm:rounded-lg',
    {
      active: open,
      'md:w-[725px] xl:w-[975px] expanded': expanded,
      'p-safe': smallScreen
    }
  )

  return (
    <div className={className} id='aivaChatPopover'>
      <div className='flex flex-row gap-1 items-center p-2 border-b border-neutral-300'>
        <h1 className='grow label-lg-strong px-1'>Ask AIVA</h1>
        <Button
          className='invisible sm:visible'
          icon={expanded ? <Collapse /> : <Expand />}
          size='md'
          variant='none'
          onClick={handleToggleExpand}
        >
          Minimize
        </Button>
        <Button
          icon={<Close />}
          size='md'
          variant='none'
          onClick={handleMinimize}
        >
          Minimize
        </Button>
      </div>
      {open ? <AivaChat ref={aivaChatRef} /> : null}
    </div>
  )
})

AivaChatPopover.displayName = 'AivaChatPopover'
AivaChatPopover.propTypes = propTypes
AivaChatPopover.defaultProps = defaultProps

export default AivaChatPopover
