import { pick } from 'lodash'
import { useCallback } from 'react'

import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../components/detail-sections/DetailSection'
import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import StyledQrCodeContent from '../../components/qr-codes/StyledQrCodeContent'
import useCurrentTrackableLink from '../../hooks/useCurrentTrackableLink'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateTrackableLinkService } from '../../services/trackableLink'

import TrackableLinkDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const log = logger({ enabled: true, tags: ['TrackableLinksDetail'] })

const TrackableLinksDetail = () => {
  const { trackableLinkId, loading, trackableLink, setTrackableLink } = useCurrentTrackableLink()

  useLogger({ log, lifecycle: false, tags: [trackableLinkId] })

  const handleDetailsUpdate = useCallback((updateReply) => {
    setTrackableLink(updateReply.json)
    bus.emit('trackableLinkUpdated', updateReply.json)
  }, [setTrackableLink])

  if (loading || !trackableLink) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<TrackableLinkDetailHeaderMoreMenu trackableLink={trackableLink} />}
        start={<PanelHeaderBackButton />}
        title={trackableLink?.name}
      />
      <PanelContent className='p-5'>
        <div>
          <FormDetailSection
            defaultValues={pick(trackableLink, ['name'])}
            description='Use Project Broadcast Trackable Links to send short versions of your urls that are trackable.'
            formControls={[{ autoComplete: 'off', label: 'Name', name: 'name', type: 'text', required: true }]}
            formDescription={`Updating trackable link for ${trackableLink.name}`}
            formTitle={trackableLink.name}
            objectId={trackableLink._id}
            service={updateTrackableLinkService}
            title='Name'
            showEditIcon
            onChange={handleDetailsUpdate}
          >
            {trackableLink.name}
          </FormDetailSection>
          <FormDetailSection
            defaultValues={pick(trackableLink, ['url'])}
            description='Your URL will be shortened for your messages and its clicks are tracked.'
            formControls={[{ label: 'URL', name: 'url', type: 'url', required: true }]}
            formDescription={`Updating trackable link for ${trackableLink.url}`}
            formTitle={trackableLink.url}
            objectId={trackableLink._id}
            service={updateTrackableLinkService}
            title='URL'
            showEditIcon
            onChange={handleDetailsUpdate}
          >
            {trackableLink.url}
          </FormDetailSection>
          <DetailSection description='QR Codes are not trackable and will go directly to the url.' title='QR Code'>
            <StyledQrCodeContent size='sm' url={trackableLink.url} />
          </DetailSection>
          <CheckboxDetailSection
            checked={trackableLink.autoTag}
            description='When enabled, each contact that clicks this link will be tagged with the name of the link.'
            name='autoTag'
            objectId={trackableLink._id}
            service={updateTrackableLinkService}
            title='Auto-Tag'
            onChange={handleDetailsUpdate}
          />
          <DetailSection
            description='Unique ID for your link.'
            title='Link ID'
          >
            {trackableLink.slug}
          </DetailSection>
          <DetailSection
            count={trackableLink.clickCount}
            description='The contacts below are the recent tracked clicks for your link.'
            editHref='contacts'
            editIcon='chevron'
            title='Contacts'
            showEditIcon
          >
            {trackableLink.clickSubsetNames}
          </DetailSection>
        </div>
      </PanelContent>
    </Panel>
  )
}

TrackableLinksDetail.displayName = 'TrackableLinksDetail'

export default TrackableLinksDetail
