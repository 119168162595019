import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useId, useState } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import { warning } from '../banners/Banner'
import Button from '../buttons/Button'
import TextInput from '../forms/controls/TextInput'

import BaseDialog from './BaseDialog'

const propTypes = {
  children: PropType.node.isRequired,
  confirmValue: PropType.string.isRequired,
  title: PropType.string.isRequired,
  confirmButtonText: PropType.string,
  trigger: PropType.node,
  onConfirm: PropType.func
}

const defaultProps = {
  confirmButtonText: 'Confirm',
  onConfirm: null,
  trigger: null
}

const log = logger({ enabled: false, tags: ['DangerousConfirmDialog'] })

const DangerousConfirmDialog = forwardRef(({ children, confirmButtonText, confirmValue, title, trigger, onConfirm }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const inputId = useId()
  const titleId = useId()
  const smallScreen = useSmallScreen()

  const [value, setValue] = useState('')

  const handleConfirm = useCallback(() => {
    log.debug(value, confirmValue)
    if (value !== confirmValue) {
      warning(`Please type "${confirmValue}" to confirm`)
    } else {
      ref.current.close()
      onConfirm?.()
    }
  }, [value, confirmValue, ref, onConfirm])

  const handleClose = useCallback(() => {
    setValue('')
  }, [setValue])

  const handleCancel = useCallback(() => {
    ref.current.close()
  }, [ref])

  const handleInputChange = useCallback((event) => {
    setValue(event.target.value)
  }, [setValue])

  const className = classNames(
    'bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[525px] flex flex-col overflow-auto',
    {
      'small-screen-dialog': smallScreen
    }
  )

  return (
    <BaseDialog
      ref={ref}
      ariaLabelledBy={titleId}
      trigger={trigger}
      onClose={handleClose}
    >
      <div className={className}>
        <h1 className='label-lg-strong text-black text-center mb-[20px]' id={titleId}>{title}</h1>
        <div className='mb-4'>{children}</div>
        <div className='flex flex-col flex-nowrap gap-2 justify-center'>
          <label className='label-md-strong' htmlFor={inputId}>
            Please type
            {' '}
            <strong>
              "
              {confirmValue}
              "
            </strong>
            {' '}
            to confirm
          </label>
          <TextInput
            id={inputId}
            name='confirm'
            value={value}
            onChange={handleInputChange}
          />
          <Button
            className='w-full'
            disabled={value !== confirmValue}
            size='md'
            theme='danger'
            onClick={handleConfirm}
          >
            {confirmButtonText}
          </Button>
          <Button
            className='w-full'
            size='md'
            variant='outline'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseDialog>
  )
})

DangerousConfirmDialog.displayName = 'DangerousConfirmDialog'
DangerousConfirmDialog.propTypes = propTypes
DangerousConfirmDialog.defaultProps = defaultProps

export default DangerousConfirmDialog
