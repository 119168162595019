import PropType from 'prop-types'
import { forwardRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'

const propTypes = {
  messageType: PropType.oneOf(messageTypes).isRequired,
  maxRows: PropType.number,
  minRows: PropType.number,
  onHeightChange: PropType.func
}

const defaultProps = {
  maxRows: 6,
  minRows: 3,
  onHeightChange: undefined
}

const log = logger({ enabled: false, tags: ['MessageTextInput'] })

const MessageTextInput = forwardRef(({
  messageType,
  maxRows,
  minRows,
  onHeightChange,
  ...textareaProps
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <TextareaAutosize
      ref={ref}
      maxRows={maxRows}
      minRows={minRows}
      style={{
        display: messageType === 'rvm' || messageType === 'email' ? 'none' : ''
      }}
      onHeightChange={onHeightChange}
      {...textareaProps}
    />
  )
})

MessageTextInput.displayName = 'MessageTextInput'
MessageTextInput.propTypes = propTypes
MessageTextInput.defaultProps = defaultProps

export default MessageTextInput
