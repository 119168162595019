import * as React from "react";
import { memo } from "react";
const SvgTiktok = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.483 2c.33 2.838 1.914 4.53 4.668 4.71v3.192c-1.596.156-2.994-.366-4.62-1.35v5.97c0 7.585-8.268 9.955-11.593 4.519-2.136-3.499-.828-9.637 6.025-9.883v3.366a9.904 9.904 0 0 0-1.59.39c-1.524.516-2.388 1.482-2.148 3.187.462 3.264 6.45 4.23 5.952-2.149V2.006h3.306V2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTiktok);
export default Memo;
