import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import logger from '../../../lib/logger'
import { manage as manageAccountService } from '../../../services/user-managedAccount'
import { manageUser as manageAccountAction } from '../../../store/actions/currentUser'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  userId: PropType.string.isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['ManageAccountAction'] })

const ManageAccountAction = forwardRef(({ userId }, ref) => {
  useLogger({ log, lifecycle: false, tags: [userId] })
  const confirmRef = useRef()
  const navigate = useNavigate()
  const { call: manageAccountCall } = useServiceAndAction(manageAccountService, manageAccountAction, {
    onReplyOk: useCallback(() => {
      navigate('/', { replace: true })
    }, [navigate])
  })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    manageAccountCall(userId)
  }, [manageAccountCall, userId])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to manage this account? When you are done, tap Sign Out from the side menu to return to your own account.'
      title='Manage Account?'
      onConfirm={handleConfirm}
    />
  )
})

ManageAccountAction.displayName = 'ManageAccountAction'
ManageAccountAction.propTypes = propTypes
ManageAccountAction.defaultProps = defaultProps

export default ManageAccountAction
