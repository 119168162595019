import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useId, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import Button from '../buttons/Button'
import Form from '../forms/Form'

import BaseDialog from './BaseDialog'
import './FormDialog.css'
import ConfirmDialog from './ConfirmDialog'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropType.object.isRequired,
  description: PropType.string.isRequired,
  formControls: PropType.arrayOf(PropType.shape({ // TODO: this should mirror/use the FormControl props
    name: PropType.string.isRequired,
    autoComplete: PropType.string,
    autoFocus: PropType.bool,
    className: PropType.string,
    label: PropType.string,
    placeholder: PropType.string,
    type: PropType.string
  })).isRequired,
  title: PropType.string.isRequired,
  confirm: PropType.shape({
    description: PropType.string,
    title: PropType.string,
    when: PropType.func
  }),
  confirmCancel: PropType.string,
  open: PropType.bool,
  submitTitle: PropType.string,
  transformData: PropType.func,
  trigger: PropType.node,
  validationSuite: PropType.func,
  onCancel: PropType.func,
  onClose: PropType.func,
  onSubmit: PropType.func
}

const defaultProps = {
  confirm: null,
  confirmCancel: null,
  onCancel: null,
  onClose: null,
  onSubmit: null,
  open: false,
  submitTitle: 'Save',
  transformData: undefined,
  trigger: null,
  validationSuite: null
}

const log = logger({ enabled: false, tags: ['FormDialog'] })

const FormDialog = forwardRef(({
  defaultValues,
  description,
  confirm,
  confirmCancel,
  formControls,
  title,
  trigger,
  transformData,
  validationSuite,
  open,
  onClose,
  onCancel,
  onSubmit,
  submitTitle
}, ref) => {
  ref = useDefaultRef(ref)

  const formId = useId()
  const smallScreen = useSmallScreen()
  const confirmCancelRef = useRef()

  useLogger({ log, lifecycle: false, tags: [formId] })

  const handleSubmit = useCallback((data, event) => {
    onSubmit?.(data, event, ref.current.close)
  }, [onSubmit, ref])
  const handleCancel = useCallback(() => {
    ref.current.close()
    onCancel?.()
  }, [onCancel, ref])
  const handleCancelWithConfirm = useCallback(() => {
    confirmCancelRef.current.open()
  }, [])

  const className = classNames(
    'form-dialog bg-white rounded-lg px-[20px] py-[20px] flex flex-col overflow-auto',
    {
      'small-screen-dialog': smallScreen
    }
  )

  return (
    <BaseDialog
      ref={ref}
      ariaDescription={description}
      ariaLabel={title}
      dismissable={!confirmCancel}
      open={open}
      trigger={trigger}
      onClose={onClose}
    >
      <div className={className}>
        <h1 className='label-md-strong text-black mb-[4px] break-words'>{title}</h1>
        <p className='paragraph-sm text-neutral-500 mb-[16px] break-words'>{description}</p>
        <Form
          confirm={confirm}
          defaultValues={defaultValues}
          formControls={formControls}
          id={formId}
          transformData={transformData}
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row flex-nowrap gap-2 justify-end'>
          <Button size='md' variant='outline' onClick={confirmCancel ? handleCancelWithConfirm : handleCancel}>Cancel</Button>
          <Button form={formId} size='md' type='submit'>{submitTitle}</Button>
        </div>
      </div>
      {!!confirmCancel && (
        <ConfirmDialog
          ref={confirmCancelRef}
          description={confirmCancel}
          title='Please Confirm'
          onConfirm={handleCancel}
        />
      )}
    </BaseDialog>

  )
})

FormDialog.displayName = 'FormDialog'
FormDialog.propTypes = propTypes
FormDialog.defaultProps = defaultProps

export default FormDialog
