import * as React from "react";
import { memo } from "react";
const SvgArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.053 20.9V6.095l3.8 4.318c.401.467 1.101.5 1.568.1.45-.4.5-1.1.1-1.567l-5.785-6.569c-.4-.467-1.1-.5-1.567-.1-.084.067-.134.15-.2.233L5.283 8.98A1.125 1.125 0 0 0 5 9.712c0 .3.133.617.383.833.467.4 1.168.367 1.568-.1l3.884-4.418V20.9c0 .617.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1h.018Z"
    />
  </svg>
);
const Memo = memo(SvgArrowUp);
export default Memo;
