import proxy from './proxy'

export default function timeZoneFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get label () {
      return json.display
    }
  }
  return proxy(json, overrides)
}
