import classNames from 'classnames'
import PropType from 'prop-types'
import { useCallback } from 'react'

import { useSideMenu } from '../../contexts/SideMenuContext'
import useHover from '../../hooks/useHover'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import SideMenuContent from './SideMenuContent'
import SideMenuFooter from './SideMenuFooter'
import SideMenuHeader from './SideMenuHeader'

const propTypes = {
  navConfig: PropType.arrayOf(PropType.shape({
    icon: PropType.element.isRequired,
    label: PropType.string.isRequired,
    indicator: PropType.string, // global store prop path to check in order to display indicator
    path: PropType.string,
    subConfig: PropType.arrayOf(PropType.shape({
      label: PropType.string.isRequired,
      path: PropType.string.isRequired
    }))
  })).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SideMenu'] })

const SideMenu = ({ navConfig }) => {
  useLogger({ log, lifecycle: true, tags: [] })

  const { expanded, locked, minLgBreakpoint, toggleExpanded, setExpanded } = useSideMenu()

  const handleHoverIn = useCallback(() => {
    if (minLgBreakpoint && !locked) { setExpanded(true) }
  }, [locked, minLgBreakpoint, setExpanded])
  const handleHoverOut = useCallback(() => {
    if (minLgBreakpoint && !locked) { setExpanded(false) }
  }, [locked, minLgBreakpoint, setExpanded])
  const { ref } = useHover({ onHoverIn: handleHoverIn, onHoverOut: handleHoverOut })

  const className = classNames(
    'z-30 absolute min-h-full h-full max-h-full overflow-hidden',
    'w-[279px] not-native:lg:left-[0px]',
    'transition-sideMenu',
    'flex flex-col grow-0 shrink-0',
    'pb-safe bg-white border-r border-neutral-200',
    {
      'not-native:lg:w-[65px]': !expanded,
      'not-native:lg:w-[279px]': expanded,
      'left-[-280px]': !expanded,
      'left-[0px]': expanded
    }
  )
  const gapClassName = classNames('transition-[width] z-0 relative h-full w-0 grow-0 shrink-0', {
    'not-native:lg:w-[279px]': locked,
    'not-native:lg:w-[65px]': !locked
  })
  const overlayClassName = classNames(
    'transition z-[29] absolute',
    {
      'bg-transparent': !expanded,
      'bg-overlay backdrop-blur-sm not-native:lg:bg-transparent not-native:lg:backdrop-blur-none top-0 right-0 bottom-0 left-0': expanded && !locked
    }
  )

  const handleNavCaptureClick = useCallback((event) => {
    if (locked) { return }
    if (!expanded) {
      event.stopPropagation()
      event.preventDefault()
      toggleExpanded()
    }
  }, [expanded, locked, toggleExpanded])

  const handleNavClick = useCallback((event) => {
    if (locked) { return }
    if (expanded) {
      toggleExpanded()
    }
  }, [expanded, locked, toggleExpanded])

  const handleOverlayClick = useCallback((event) => {
    event.stopPropagation()
    toggleExpanded()
  }, [toggleExpanded])

  return (
    <>
      <nav
        ref={ref}
        className={className}
        onClick={handleNavClick}
        onClickCapture={handleNavCaptureClick}
      >
        <div className='max-h-full h-full w-full overflow-hidden flex flex-col'>
          <SideMenuHeader />
          <SideMenuContent navConfig={navConfig} />
          <SideMenuFooter />
        </div>
      </nav>
      <div className={gapClassName} />
      <div className={overlayClassName} onClickCapture={handleOverlayClick} />
    </>
  )
}

SideMenu.displayName = 'SideMenu'
SideMenu.propTypes = propTypes
SideMenu.defaultProps = defaultProps

export default SideMenu
