import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { update as updateFolderService } from '../../../services/folder'
import { notify } from '../../banners/Banner'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuite from './UpdateFolderNameAction.validations'

const propTypes = {
  contentType: PropType.string,
  currentName: PropType.string,
  onSuccess: PropType.func
}

const defaultProps = {
  contentType: null,
  currentName: null,
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['UpdateFolderNameAction'] })

const UpdateFolderNameAction = forwardRef(({ contentType, currentName, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const params = useParams()
  const dialogRef = useRef()

  const { folderId } = params

  const handleReplyOk = useCallback((updatedReply) => {
    const updatedFolder = updatedReply.json
    notify('Success!', 'Your folder has been renamed.')
    bus.emit('closeBulkEditDialog')
    bus.emit('folderUpdated', updatedFolder)
    onSuccess?.(updatedFolder)
  }, [onSuccess])
  const { call: updateFolder } = useService(updateFolderService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      dialogRef.current.open()
    }
  }), [])

  const updateFolderWithName = useCallback(({ name }) => {
    dialogRef.current.close()
    updateFolder({ name, folderId })
  }, [updateFolder, folderId])

  return (
    <FormPanelDialog
      ref={dialogRef}
      defaultValues={{ name: currentName }}
      formControls={[
        {
          autoComplete: 'off',
          autoFocus: true,
          name: 'name',
          label: 'Folder Name',
          type: 'text',
          hint: 'Please give your new name.'
        },
        {
          name: 'currentName',
          value: currentName,
          type: 'hidden'
        },
        {
          name: 'contentType',
          value: contentType,
          type: 'hidden'
        }
      ]}
      title='Edit Name'
      validationSuite={validationSuite}
      onSubmit={updateFolderWithName}
    />
  )
})

UpdateFolderNameAction.displayName = 'UpdateFolderNameAction'
UpdateFolderNameAction.propTypes = propTypes
UpdateFolderNameAction.defaultProps = defaultProps

export default UpdateFolderNameAction
