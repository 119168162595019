import PropType from 'prop-types'

import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}

export const createPropTypes = {
  name: PropType.string
}

export const updatePropTypes = {
  name: PropType.string
}
