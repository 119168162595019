import * as React from "react";
import { memo } from "react";
const SvgStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.007 2c-.113 0-.244.056-.326.234L9.09 7.805a1.057 1.057 0 0 1-.597.545l-.02.007-.007.003-.004.001-.002.001H8.46l-.317-.948.317.948a1.007 1.007 0 0 1-.164.04l-6.096.94c-.068.011-.144.057-.183.185-.04.13-.01.255.073.34l.002.002 4.41 4.56c.183.189.24.398.251.44v.004a1.11 1.11 0 0 1 .04.281V15.173h-1 1c0 .053-.004.108-.012.16l-.99 6.118a.488.488 0 0 0 .173.477c.114.088.232.098.354.032l5.167-2.89a1 1 0 0 1 .488-.126h.05c.17 0 .339.043.488.127l5.023 2.802c.061.02.12.046.178.078.113.064.227.056.341-.032a.489.489 0 0 0 .173-.477l-.982-6.07c-.066-.3.025-.54.044-.59v-.002a1.087 1.087 0 0 1 .092-.188l.012-.018.005-.008.002-.004.001-.002h.001l.832.554-.831-.555a.993.993 0 0 1 .113-.14l4.407-4.558a.342.342 0 0 0 .07-.344c-.04-.133-.117-.175-.177-.184h-.004l-6.07-.938a1.033 1.033 0 0 1-.605-.302 1.12 1.12 0 0 1-.118-.14l-.01-.016-.005-.007L15 7.927 15 7.926l-.001-.002.835-.55-.835.55a1.003 1.003 0 0 1-.072-.128l-2.59-5.558c-.085-.184-.218-.238-.33-.238Zm4.52 4.493L14.15 1.39V1.39a2.344 2.344 0 0 0-4.284.003L7.49 6.503l-5.587.862C.01 7.65-.583 9.985.655 11.26l.002.001 4.075 4.214-.916 5.661c-.3 1.877 1.615 3.602 3.472 2.572l.003-.001L12 21.075l4.717 2.632c.07.04.145.07.222.09.83.369 1.711.189 2.338-.296a2.487 2.487 0 0 0 .925-2.375l-.916-5.661 4.077-4.216.003-.003c1.219-1.27.637-3.606-1.251-3.89l-.002-.001-5.586-.862ZM5.076 15.83l-.012-.012.011.012Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgStar);
export default Memo;
