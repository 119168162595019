// A chat is technically just a contact with extra message related props
import chatFromJSON from '../models/chat'
import messageFromJSON from '../models/message'

import pb from './pb'

export function after (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { contactId, messageId } = body
  // TODO: prop type check body
  return pb.setupGet(`contacts/${contactId}/messages/after/${messageId}`, options)
}

export function get (contactId, background = false) {
  const options = {
    background,
    model: {
      conversion: chatFromJSON,
      key: 'contact'
    }
  }
  return pb.setupGet(`contacts/${contactId}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: chatFromJSON,
      key: 'contacts',
      multiple: true
    }
  }
  // TODO: prop type check body
  return pb.setupPost('messages/search', options)
}

export function markAllAsRead (background = false) {
  return pb.setupPut('messages/markAsRead', { background })
}

export function bulkMarkAsRead (contactIds, background = false) {
  const body = { contactIds }
  const options = { body, background }
  return pb.setupPut('messages/bulkMarkAsRead', options)
}

export function bulkMarkAsUnread (contactIds, background = false) {
  const body = { contactIds }
  const options = { body, background }
  return pb.setupPut('messages/bulkMarkAsUnread', options)
}

export function markAsRead (body, background = false) {
  const options = { background }
  const { contactId, messageId } = body
  // TODO: prop type check body
  return pb.setupPut(`contacts/${contactId}/messages/${messageId}/read`, options)
}

export function markAsUnread (chatId, background = false) {
  const body = { contactId: chatId }
  const options = { body, background }
  return pb.setupPut('messages/markAsUnread', options)
}

export function messages (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { contactId, paging: { page, pageSize } } = body
  // TODO: prop type check body
  return pb.setupGet(`contacts/${contactId}/messages?page=${page}&pageSize=${pageSize}`, options)
}

export function scheduled (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { contactId } = body
  // TODO: prop type check body
  return pb.setupGet(`contacts/${contactId}/messages/scheduled`, options)
}
