import * as React from "react";
import { memo } from "react";
const SvgAiva = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Aiva_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.29 10.84c.314.207.529.54.582.913a12.71 12.71 0 0 1 0 3.601 1.313 1.313 0 0 1-1.222 1.13 4.685 4.685 0 0 1-.367.006c-.035.292-.074.583-.119.875a3.05 3.05 0 0 1-2.461 2.521c-5.159.971-10.346.928-15.56-.022a3.053 3.053 0 0 1-2.474-2.502c-.048-.29-.09-.581-.126-.87-.074-.002-.147-.004-.22-.008A1.3 1.3 0 0 1 .108 15.35a14.364 14.364 0 0 1 .007-3.58c.084-.679.542-.89 1.09-1.126.51-.22.593-.858.664-1.405l.019-.142c.15-1.1.388-2.182.626-3.264.096-.438.193-.877.284-1.317A.648.648 0 0 1 3.433 4h1.394c.29 0 .544.192.624.47l.716 2.523a37.42 37.42 0 0 1 11.506.017l.722-2.54a.647.647 0 0 1 .623-.47h1.394c.307 0 .573.216.635.517.102.498.208.994.315 1.49.284 1.321.568 2.641.764 3.984.022.154.046.313.13.443.127.192.345.227.57.263.163.027.33.054.464.143ZM18.8 18.8a2.611 2.611 0 0 0 2.111-2.158 19.57 19.57 0 0 0-.002-6.186 2.62 2.62 0 0 0-2.075-2.15 36.989 36.989 0 0 0-2.556-.42L15.02 9.757a1.028 1.028 0 0 1-.855.444H9.671c-.35 0-.676-.171-.86-.453L7.577 7.87a34.15 34.15 0 0 0-2.612.435 2.615 2.615 0 0 0-2.048 2.112 18.373 18.373 0 0 0 0 6.225 2.615 2.615 0 0 0 2.126 2.14c4.61.812 9.195.849 13.755.019ZM6.701 11.126a3.528 3.528 0 0 0-.98 0 .838.838 0 0 0-.713.715 11.993 11.993 0 0 0-.005 3.405c.053.371.347.663.718.71.324.043.647.043.97 0a.838.838 0 0 0 .716-.699 10.623 10.623 0 0 0 0-3.436.838.838 0 0 0-.707-.694Zm10.396 0c.33-.045.657-.045.98 0h-.001a.838.838 0 0 1 .707.695 10.625 10.625 0 0 1 0 3.436.838.838 0 0 1-.717.7 3.76 3.76 0 0 1-.97 0 .836.836 0 0 1-.718-.71 11.988 11.988 0 0 1 .005-3.406.838.838 0 0 1 .714-.714ZM10.44 15.65h3.218a.44.44 0 0 1 .412.594 2.155 2.155 0 0 1-4.042 0 .44.44 0 0 1 .412-.593Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Aiva_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAiva);
export default Memo;
