import PropType from 'prop-types'

import { campaignTypes } from '../../helpers/campaign'

export default {
  __v: PropType.number,
  _id: PropType.string,
  antiKeywords: PropType.arrayOf(PropType.string), // keyword ids
  archived: PropType.bool,
  boost: PropType.bool,
  bundles: PropType.array, // TODO: array of what?
  contacts: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape({
      _id: PropType.string,
      firstName: PropType.string,
      lastName: PropType.string,
      phoneNumber: PropType.string
    }))
  }),
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  events: PropType.arrayOf(PropType.shape({ // TODO: should be prop-types/event.js?
    _id: PropType.string,
    campaignType: PropType.oneOf(campaignTypes),
    name: PropType.string,
    scheduledAt: PropType.string // "2021-06-04T12:28:43.881Z"
  })),
  keywords: PropType.arrayOf(PropType.string), // keyword ids
  name: PropType.string,
  removeOnReply: PropType.bool,
  scheduledAt: PropType.string, // "2021-06-04T12:28:43.881Z" (applicable to countdown type)
  search: PropType.string,
  type: PropType.oneOf(campaignTypes),
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  user: PropType.string
}
