import { omit } from 'lodash'
import PropType from 'prop-types'

import contactFromJSON from '../models/contact'
import contestFromJSON from '../models/contest'

import { addRemoveAllInQueryPropTypes, addRemoveSelectedPropTypes, searchPropTypes } from './contest-contact.prop-types'
import pb from './pb'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'contest-contact#search')

  const { contestId } = data
  const body = omit(data, 'contestId')
  const model = {
    conversion: contactFromJSON,
    key: 'contacts',
    multiple: true
  }
  return pb.setupPost(`contests/${contestId}/contacts/search`, { body, background, model })
}

export function addSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'contest-contact#addSelected')
  const { contestId } = data
  const body = omit(data, 'contestId')
  return pb.setupPut(`contests/${contestId}/contacts/add`, {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'

    }
  })
}

export function addAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'contest-contact#addAllInQuery')
  const { contestId } = data
  const body = omit(data, 'contestId')
  return pb.setupPut(`contests/${contestId}/contacts/add/all`, {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'

    }
  })
}

export function removeSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'contest-contact#removeSelected')
  const { contestId } = data
  const body = omit(data, 'contestId')
  return pb.setupPut(`contests/${contestId}/contacts/remove`, {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'

    }
  })
}

export function removeAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'contest-contact#removeAllInQuery')
  const { contestId } = data
  const body = omit(data, 'contestId')
  return pb.setupPut(`contests/${contestId}/contacts/remove/all`, {
    body,
    model: {
      conversion: contestFromJSON,
      key: 'contest'

    }
  })
}
