import PropType from 'prop-types'

import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Error } from '../../../svg/icons'

const propTypes = {
  className: PropType.string
}

const defaultProps = {
  className: ''
}

const log = logger({ enabled: true, tags: ['PotentialStopMessageBadge'] })

const PotentialStopMessageBadge = ({ className }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { aivaSettings } = useCurrentUser()

  return aivaSettings.stopDetect !== 'off'
    ? (
      <div className={`flex flex-row flex-nowrap items-center ${className}`}>
        <Error className='text-error' height='16' width='16' />
        <span className='pt-1 pl-1 label-xs text-error'>
          Potential opt-out detected
        </span>
      </div>
      )
    : null
}

PotentialStopMessageBadge.displayName = 'PotentialStopMessageBadge'
PotentialStopMessageBadge.propTypes = propTypes
PotentialStopMessageBadge.defaultProps = defaultProps

export default PotentialStopMessageBadge
