import PropType from 'prop-types'

import useLogger from '../hooks/useLogger'
import { pluralize } from '../lib/formatters'
import logger from '../lib/logger'

const propTypes = {
  count: PropType.number.isRequired,
  singular: PropType.string.isRequired,
  includeCount: PropType.bool,
  plural: PropType.string,
  zero: PropType.string
}

const defaultProps = {
  includeCount: false,
  plural: null,
  zero: null
}

const log = logger({ enabled: true, tags: ['Pluralize'] })

const Pluralize = ({ count, singular, plural, zero, includeCount }) => {
  useLogger({ log, lifecycle: false })
  return pluralize({ count, singular, includeCount, plural, zero })
}

Pluralize.displayName = 'Pluralize'
Pluralize.propTypes = propTypes
Pluralize.defaultProps = defaultProps

export default Pluralize
