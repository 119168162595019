import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import DetailSection from '../../components/detail-sections/DetailSection'
import FormDialog from '../../components/dialog/FormDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { centsToDollars } from '../../lib/formatters'
import logger from '../../lib/logger'
import { currentProgram } from '../../services/referral'
import { updateProfile } from '../../services/user'
import { update } from '../../store/actions/currentUser'

import CopyReferralLink from './referral-program/CopyReferralLink'
import ShareReferralLink from './referral-program/ShareReferralLink'
import validationSuite from './ReferralProgram.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ReferralProgram'] })

const ReferralProgram = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formDialogRef = useRef()

  const [program, setProgram] = useState(null)

  const handleCurrentProgramReplyOk = useCallback((reply) => { setProgram(reply.json) }, [])
  const { loading: loadingCurrentProgram, call: loadCurrentProgram } = useService(currentProgram, { onReplyOk: handleCurrentProgramReplyOk })
  useEffect(() => { loadCurrentProgram() }, [loadCurrentProgram])

  const { refId } = useCurrentUser()
  const signupUrl = useMemo(() => new URL(`/sign-up?referral=${refId}`, process.env.REACT_APP_URL).toString(), [refId])

  const handleReplyOk = useCallback(() => { formDialogRef.current.close() }, [])
  const { call } = useServiceAndAction(updateProfile, update, { onReplyOk: handleReplyOk })

  const handleEditClick = useCallback(() => { formDialogRef.current.open() }, [])
  const handleSubmit = useCallback((data) => { call(data) }, [call])

  const title = 'Referral Program'

  if (loadingCurrentProgram || !program) { return <PanelLoading title={title} /> }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent className='p-5'>
        <DetailSection
          description={`Signups using your code get ${program.percentOff}% off their first month of a monthly subscription or ${program.percentOffYear}% off their first year of a yearly subscription at signup. You get a $${centsToDollars(program.refererAccountCreditInCents)} account credit as our thank you! It's that easy. Set your referral and share!`}
          initialEditButtonText='Set Referral Code'
          showEditIcon={!!refId}
          showInitialEditButton={!refId}
          title='Referral Code'
          onEditClick={handleEditClick}
          onInitialEditClick={handleEditClick}
        >
          {refId}
        </DetailSection>
        <FormDialog
          ref={formDialogRef}
          defaultValues={{ refId }}
          description='Your referral code must contain only letters and numbers and be at least 3 characters.'
          formControls={[{ type: 'text', label: 'Referral Code', name: 'refId' }]}
          title='Update Referral Code'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        {refId ? <CopyReferralLink signupUrl={signupUrl} /> : null}
        {refId ? <ShareReferralLink program={program} signupUrl={signupUrl} /> : null}
      </PanelContent>
    </Panel>
  )
}

ReferralProgram.displayName = 'ReferralProgram'
ReferralProgram.propTypes = propTypes
ReferralProgram.defaultProps = defaultProps

export default ReferralProgram
