import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ContactList from '../contacts/ContactList'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpaceContactList'] })

const SpaceContactList = forwardRef(({ ...listProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <ContactList
      ref={ref}
      queryOverrides={{ hasContactUser: true }}
      {...listProps}
    />
  )
})

SpaceContactList.displayName = 'SpaceContactList'
SpaceContactList.propTypes = propTypes
SpaceContactList.defaultProps = defaultProps

export default SpaceContactList
