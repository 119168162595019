import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import DeleteAction from '../../../components/scheduled-broadcast/actions/DeleteAction'
import EBrakeAction from '../../../components/scheduled-broadcast/actions/EBrakeAction'
import { canEditBroadcast } from '../../../helpers/scheduledBroadcast'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape)
}

const defaultProps = {
  scheduledBroadcast: null
}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastDetailHeaderMoreMenu'] })

const ScheduledBroadcastDetailHeaderMoreMenu = ({ scheduledBroadcast }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const eBrakeActionRef = useRef()

  const onDeleteSuccess = useCallback(() => navigateInStack('/scheduled-broadcasts', { replace: true }), [navigateInStack])

  const canDelete = scheduledBroadcast?.state === 'scheduled' && canEditBroadcast(scheduledBroadcast)
  const canEBrake = scheduledBroadcast?.state === 'queued'

  return !!scheduledBroadcast && (canDelete || canEBrake) && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        {canDelete ? <ActionMenuItem label='Delete Broadcast' onClick={() => deleteActionRef.current.activate()} /> : null}
        {canEBrake ? <ActionMenuItem label='Apply E-Brake (Stop All Messages)' onClick={() => eBrakeActionRef.current.activate()} /> : null}
      </ActionMenu>
      {canDelete ? <DeleteAction ref={deleteActionRef} scheduledBroadcast={scheduledBroadcast} onSuccess={onDeleteSuccess} /> : null}
      {canEBrake ? <EBrakeAction ref={eBrakeActionRef} scheduledBroadcast={scheduledBroadcast} /> : null}
    </>
  )
}

ScheduledBroadcastDetailHeaderMoreMenu.displayName = 'ScheduledBroadcastDetailHeaderMoreMenu'
ScheduledBroadcastDetailHeaderMoreMenu.propTypes = propTypes
ScheduledBroadcastDetailHeaderMoreMenu.defaultProps = defaultProps

export default ScheduledBroadcastDetailHeaderMoreMenu
