import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useImagePicker from '../../../../hooks/useImagePicker'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { Picture } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {
  onImageSelect: PropType.func
}

const defaultProps = {
  onImageSelect: undefined
}

const log = logger({ enabled: false, tags: ['AttachImageButton'] })

const AttachImageButton = forwardRef(({ onImageSelect, ...buttonProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { show: showImagePicker } = useImagePicker()

  const handleClick = useCallback(async (event) => {
    event.stopPropagation()
    const image = await showImagePicker()
    if (image) { onImageSelect?.(image) }
  }, [onImageSelect, showImagePicker])

  return (
    <Button
      ref={ref}
      className='min-w-[32px]'
      icon={<Picture />}
      size='sm'
      theme='none'
      variant='none'
      onClick={handleClick}
      {...buttonProps}
    >
      Attach Image
    </Button>
  )
})

AttachImageButton.displayName = 'AttachImageButton'
AttachImageButton.propTypes = propTypes
AttachImageButton.defaultProps = defaultProps

export default AttachImageButton
