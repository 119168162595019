import { useCallback } from 'react'

import Banner, { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import SupportLink from '../../../components/links/SupportLink'
import { needsTcrInfo, pendingSmsVerification, tcrApplicable, tcrComplete, tcrFailed, tcrRegistering } from '../../../helpers/user-tcr'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import logger from '../../../lib/logger'
import { current as currentUserService } from '../../../services/user'
import { update as updateUserAction } from '../../../store/actions/currentUser'

import RegistrationDialog from './RegistrationDialog'
import SendOtp from './SendOtp'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['StatusDetail'] })

const StatusDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()

  const handleCurrentUserOk = useCallback(() => {
    notify('Checked for updates.')
  }, [])

  const { call: userData } = useServiceAndAction(currentUserService, updateUserAction, { onReplyOk: handleCurrentUserOk })

  const handleCheckForUpdates = useCallback(() => {
    userData()
  }, [userData])

  if (!currentUser) {
    return null
  }

  if (currentUser.billingAccount && !tcrComplete(currentUser)) {
    return (
      <Banner className='mb-5' title='No action necessary'>
        Registration with The Campaign Registry&trade; is managed by your billing account manager.
      </Banner>
    )
  }

  if (needsTcrInfo(currentUser)) {
    return (
      <>
        <Banner className='mb-5' title='Registration Required' type='warning'>
          Please begin the registration process with The Campaign Registry&trade; by clicking the button below.
        </Banner>
        <RegistrationDialog />
      </>
    )
  }

  if (!tcrApplicable(currentUser)) {
    return (
      <Banner className='mb-5' title='No action necessary'>
        Registration with The Campaign Registry&trade; does not apply to Social Plan users.
      </Banner>
    )
  }

  if (tcrComplete(currentUser)) {
    return (
      <Banner className='mb-5' title='Congratulations!' type='success'>
        Your registration with The Campaign Registry&trade; is approved.
      </Banner>
    )
  }

  if (pendingSmsVerification(currentUser)) {
    return (
      <>
        <div className='flex flex-row'>
          <Button className='mb-5' size='sm' onClick={handleCheckForUpdates}>
            Check for Updates
          </Button>
        </div>
        <Banner className='mb-5' title='SMS Verification Required' type='warning'>
          Your registration with The Campaign Registry&trade; is incomplete. Please complete the SMS verification process on your mobile device.
        </Banner>
        <SendOtp />
      </>
    )
  }

  if (tcrRegistering(currentUser)) {
    return (
      <>
        <div className='flex flex-row'>
          <Button className='mb-5' size='sm' onClick={handleCheckForUpdates}>
            Check for Updates
          </Button>
        </div>
        <Banner className='mb-5' title='Registration Pending'>
          Your registration with The Campaign Registry&trade; is pending. No further action is required.
        </Banner>
      </>
    )
  }

  if (tcrFailed(currentUser)) {
    return (
      <Banner className='mb-5' title='Registration Failed'>
        Your registration with The Campaign Registry&trade; has failed. You must email
        {' '}
        <SupportLink />
        {' '}
        to resolve the issue.
      </Banner>
    )
  }

  return null
}

StatusDetail.displayName = 'StatusDetail'
StatusDetail.propTypes = propTypes
StatusDetail.defaultProps = defaultProps

export default StatusDetail
