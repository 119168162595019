import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useTimeZone from '../../../hooks/useTimeZone'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'
import message from '../../../prop-types/shapes/message'
import { updateScheduledMessage } from '../../../services/message'
import ScheduleMessageDialog from '../../forms/controls/message/ScheduleMessageDialog'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EditScheduledMessageScheduleAction'] })

const EditScheduledMessageScheduleAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { userTimeZone } = useTimeZone()

  const scheduleMessageDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    scheduleMessageDialogRef.current.close()
    bus.emit('messageUpdated', reply.model)
  }, [])
  const { call } = useService(updateScheduledMessage, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { scheduleMessageDialogRef.current.open() }
  }), [])

  const currentDateTime = new Date(message.runAt)

  const handleSubmit = useCallback(({ date, time }) => {
    const runAt = dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    const data = { message: { ...message, runAt } }
    call(message._id, data)
  }, [call, message, userTimeZone])

  return (
    <ScheduleMessageDialog ref={scheduleMessageDialogRef} currentDateTime={currentDateTime} onSubmit={handleSubmit} />
  )
})

EditScheduledMessageScheduleAction.displayName = 'EditScheduledMessageScheduleAction'
EditScheduledMessageScheduleAction.propTypes = propTypes
EditScheduledMessageScheduleAction.defaultProps = defaultProps

export default EditScheduledMessageScheduleAction
