import { useCallback, useRef, useState } from 'react'

import Panel from '../../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../../components/panels/PanelContent'
import PanelHeader from '../../../../components/panels/PanelHeader'
import UserList from '../../../../components/users/UserList'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { spammers as searchService } from '../../../../services/admin'

import EmptyListContent from './EmptyListContent'
import ListHeaderMoreMenu from './ListHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['List'] })

const SpammerList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<ListHeaderMoreMenu listRef={listRef} />}
        start={<PanelHeaderBackButton />}
        title='Spammers'
      />
      <PanelContent>
        <UserList
          ref={listRef}
          EmptyListContent={EmptyListContent}
          resultsKey='users'
          searchService={searchService}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

SpammerList.displayName = 'SpammerList'
SpammerList.propTypes = propTypes
SpammerList.defaultProps = defaultProps

export default SpammerList
