import PropType from 'prop-types'
import { forwardRef, useCallback, useId, useImperativeHandle, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelDialog from '../dialog/PanelDialog'
import Form from '../forms/Form'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

const propTypes = {
  defaultValues: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  formControls: PropType.arrayOf(PropType.shape({
    name: PropType.string.isRequired,
    autoComplete: PropType.string,
    autoFocus: PropType.bool,
    label: PropType.string,
    placeholder: PropType.string,
    type: PropType.string
  })).isRequired,
  onSubmit: PropType.func
}

const defaultProps = {
  onSubmit: undefined
}

const log = logger({ enabled: true, tags: ['AdvancedSearchDialog'] })

const AdvancedSearchDialog = forwardRef(({ defaultValues, formControls, onSubmit }, ref) => {
  ref = useDefaultRef(ref)
  const panelDialogRef = useRef()
  const formRef = useRef()
  const formId = useId()

  useLogger({ log, lifecycle: true, tags: [formId] })

  useImperativeHandle(ref, () => ({
    open () {
      panelDialogRef.current.open()
    }
  }), [])

  const close = useCallback(() => panelDialogRef.current.close(), [])
  const handleCancel = useCallback(() => close(), [close])
  const handleSubmit = useCallback((data) => {
    close()
    onSubmit?.(data)
  }, [close, onSubmit])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel='Advanced Search'>
      <PanelHeader
        end={<PanelHeaderButton form={formId} title='Search' type='submit' />}
        start={<PanelHeaderButton title='Cancel' onClick={handleCancel} />}
        title='Advanced Search'
      />
      <PanelContent className='p-4'>
        <Form
          ref={formRef}
          defaultValues={defaultValues}
          formControls={formControls}
          id={formId}
          onSubmit={handleSubmit}
        />
      </PanelContent>
    </PanelDialog>
  )
})

AdvancedSearchDialog.displayName = 'AdvancedSearchDialog'
AdvancedSearchDialog.propTypes = propTypes
AdvancedSearchDialog.defaultProps = defaultProps

export default AdvancedSearchDialog
