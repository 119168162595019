import * as React from "react";
import { memo } from "react";
const SvgChatText = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 0C4.258 0 0 4.258 0 9.5c0 4.38 2.966 8.061 7 9.16V22a1 1 0 0 0 1.555.832L14.303 19h.197c5.242 0 9.5-4.258 9.5-9.5S19.742 0 14.5 0h-5ZM2 9.5C2 5.362 5.362 2 9.5 2h5C18.638 2 22 5.362 22 9.5S18.638 17 14.5 17H14a1 1 0 0 0-.555.168L9 20.13V17.86a1 1 0 0 0-.824-.985C4.663 16.25 2 13.185 2 9.5ZM6 8a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm3 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChatText);
export default Memo;
