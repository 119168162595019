import PropType from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { usePanel } from '../../contexts/PanelContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import LoadingIndicator from '../LoadingIndicator'

import Panel from './Panel'
import PanelHeaderBackButton from './panel-header/PanelHeaderBackButton'
import PanelContent from './PanelContent'
import PanelHeader from './PanelHeader'

const propTypes = {
  hideAivaChatButton: PropType.bool,
  hideMenuToggleButton: PropType.bool,
  noHeader: PropType.bool,
  start: PropType.element,
  subtitle: PropType.string,
  title: PropType.string
}

const defaultProps = {
  hideAivaChatButton: false,
  hideMenuToggleButton: false,
  noHeader: false,
  start: null,
  subtitle: null,
  title: ''
}

const log = logger({ enabled: false, tags: ['PanelLoading'] })

const showDelay = 750

const PanelLoading = ({ hideAivaChatButton, hideMenuToggleButton, noHeader, start, subtitle, title }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const timeoutRef = useRef()
  const [show, setShow] = useState(false)

  const { inSecond } = usePanel()

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, showDelay)
    return () => { clearTimeout(timeoutRef.current) }
  }, [])

  start = start || (inSecond ? <PanelHeaderBackButton /> : null)

  return (
    <Panel hideAivaChatButton={hideAivaChatButton} hideMenuToggleButton={hideMenuToggleButton}>
      {noHeader ? null : <PanelHeader start={start} subtitle={subtitle} title={title} />}
      <PanelContent className='p-5'>
        <div className='flex flex-col w-full items-center p-4'>
          {show ? <LoadingIndicator className='text-neutral-400' /> : null}
        </div>
      </PanelContent>
    </Panel>
  )
}

PanelLoading.displayName = 'PanelLoading'
PanelLoading.propTypes = propTypes
PanelLoading.defaultProps = defaultProps

export default PanelLoading
