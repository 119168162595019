import { useCallback } from 'react'

import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentBundle from '../../hooks/useCurrentBundle'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateBundleService } from '../../services/bundle'

import ContentDetailSection from './detail/ContentDetail'
import BundleDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import ShareIdDetail from './detail/ShareIdDetail'
import ShareOptionsDetail from './detail/ShareOptionsDetail'
import UsageDetailSection from './detail/UsageDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BundlesDetail'] })

const BundlesDetail = () => {
  useLogger({ log, lifecycle: false, tags: ['BundlesDetail'] })

  const { bundle, setBundle, loading } = useCurrentBundle()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const updateBundle = updateReply.model
    setBundle(updateBundle)
    bus.emit('bundleUpdated', updateBundle)
  }, [setBundle])

  if (loading || !bundle) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<BundleDetailHeaderMoreMenu bundle={bundle} />}
        start={<PanelHeaderBackButton />}
        title={bundle.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          description='Bundles are used to share items like campaigns, templates, and more. Name the bundle so it is easy to find later.'
          object={bundle}
          objectName='bundle'
          service={updateBundleService}
          onChange={handleDetailsUpdate}
        />
        <UsageDetailSection
          bundle={bundle}
          service={updateBundleService}
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='attachments'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='campaigns'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='emails'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='keywords'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='landingPages'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='messageTemplates'
          onChange={handleDetailsUpdate}
        />
        <ContentDetailSection
          bundle={bundle}
          contentType='voiceDrops'
          onChange={handleDetailsUpdate}
        />
        <ShareIdDetail
          bundle={bundle}
        />
        <ShareOptionsDetail
          bundle={bundle}
          service={updateBundleService}
          onChange={handleDetailsUpdate}
        />
      </PanelContent>
    </Panel>
  )
}

BundlesDetail.displayName = 'BundlesDetail'
BundlesDetail.propTypes = propTypes
BundlesDetail.defaultProps = defaultProps

export default BundlesDetail
