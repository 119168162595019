import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'
import { del as deleteService } from '../../../services/campaign'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ campaign, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaign?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${campaign.name} was deleted`)
    bus.emit('campaignDeleted', campaign)
    if (campaign.folderId) {
      bus.emit('campaignRemovedFromFolder', campaign)
      bus.emit('foldersUpdated')
    }
    onSuccess?.()
  }, [campaign, onSuccess])
  const { call: deleteCampaignCall } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteCampaignCall(campaign._id)
  }, [campaign._id, deleteCampaignCall])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Deleting will remove all currently scheduled broadcasts originated by this campaign that have not been sent yet. Are you sure?'
      title={`Delete ${campaign.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
