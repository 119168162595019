import classNames from 'classnames'
import PropType from 'prop-types'

import { usePanel } from '../../contexts/PanelContext'
import GenericErrorBoundary from '../error-boundaries/GenericErrorBoundary'

import PanelBottomSafeSpacer from './PanelBottomSafeSpacer'

const propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
  disableAutoSafeSpacer: PropType.bool
}
const defaultProps = {
  className: null,
  disableAutoSafeSpacer: false
}

const PanelContent = ({ children, className, disableAutoSafeSpacer }) => {
  const { containsList } = usePanel()

  const computedClassName = classNames(
    'relative z-20 flex flex-col grow self-stretch overflow-auto',
    className
  )
  return (
    <GenericErrorBoundary boundaryName='PanelContent'>
      <div className={computedClassName}>
        {children}
        {!containsList && !disableAutoSafeSpacer && <PanelBottomSafeSpacer />}
      </div>
    </GenericErrorBoundary>
  )
}

PanelContent.displayName = 'PanelContent'
PanelContent.propTypes = propTypes
PanelContent.defaultProps = defaultProps

export default PanelContent
