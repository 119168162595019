import * as React from "react";
import { memo } from "react";
const SvgGallery = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 1a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM3 4a1 1 0 0 0 0 2h18a1 1 0 1 0 0-2H3Zm14 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm-6.99-2a1 1 0 0 1 .78.386L14.49 22H21a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v7.575l4.223-5.205A1 1 0 0 1 7.01 13Zm-4.85 8.544A1 1 0 0 0 3 22h8.955l-4.972-6.393-4.756 5.863c-.021.026-.043.05-.066.074ZM13.992 24H3a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-6.99a.62.62 0 0 1-.019 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGallery);
export default Memo;
