import { formatName } from '../lib/formatters'

import proxy from './proxy'

const stateMap = {
  scheduled: 'scheduled',
  queuing: 'sending',
  queued: 'sending',
  error: 'unsent'
}

const emptyContactsResult = { contactUsersCount: 0, length: 0, subset: [] }

export default function scheduledBroadcastFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get name () {
      if (json.campaign && json.event) {
        return [json.campaign.name, json.event.name].join(', ')
      } else {
        return 'Broadcast'
      }
    },

    get stateSummary () {
      return (json.messageStates?.join(' | ') || stateMap[json.state]).toUpperCase()
    },

    get contacts () {
      return json.contacts || emptyContactsResult
    },
    get contactsSending () {
      return json.contactsSending || emptyContactsResult
    },
    get contactsSent () {
      return json.contactsSent || emptyContactsResult
    },
    get contactsUnsent () {
      return json.contactsUnsent || emptyContactsResult
    },

    get contactsSubsetNames () {
      return json.contacts?.subset?.map(formatName)?.join(', ')
    }

  }

  return proxy(json, overrides)
}
