import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import AddToFolderAction from '../../../components/folders/actions/AddToFolderAction'
// import CreateFromFolderAction from '../../../components/folders/actions/CreateFromFolderAction'
import { CONTENT_TYPE } from '../../../lib/folder'
import { Folders as FoldersEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const FolderEmptyDetailContent = () => {
  const addToFolderActionRef = useRef()
  // const createContentActionRef = useRef()

  const handleAddToFolder = useCallback(() => addToFolderActionRef.current.activate(), [])
  // const handleCreateFromFolder = useCallback(() => createContentActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col mx-auto my-6 max-w-[475px]'>
        <FoldersEmptyStateSvg className='my-6' />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Empty Folder</h1>
        <p className='label-sm text-center'>
          Begin by adding content below to populate your folder.
        </p>
        <Button className='mt-6 place-content-center' size='md' onClick={handleAddToFolder}>
          Move to Folder
        </Button>
        {/* <Button
          className='mt-3 place-content-center'
          size='md'
          variant='outline'
          onClick={handleCreateFromFolder}
        >
          Create New Attachment
        </Button> */}
      </div>
      <AddToFolderAction ref={addToFolderActionRef} contentType={CONTENT_TYPE.attachment} />
      {/* <CreateFromFolderAction ref={createContentActionRef} contentType={CONTENT_TYPE.attachment} /> */}
    </>
  )
}

FolderEmptyDetailContent.displayName = 'FolderEmptyDetailContent'
FolderEmptyDetailContent.propTypes = propTypes
FolderEmptyDetailContent.defaultProps = defaultProps

export default FolderEmptyDetailContent
