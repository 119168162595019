import * as React from "react";
import { memo } from "react";

const SvgOpen = (props) => (
  <svg
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#0056FF" d="M19 19h7v14h-7z" />
    <path stroke="#000" strokeWidth={2} d="M18 18h16v16H18z" />
  </svg>
);

const Memo = memo(SvgOpen);
export default Memo;
