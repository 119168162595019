import { pick } from 'lodash'
import PropType from 'prop-types'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, keyword: ['ContactLinkRedirectUrlDetail'] })

const ContactLinkRedirectUrlDetail = ({ keyword, service, onChange }) => {
  useLogger({ log, lifecycle: false, keyword: [keyword?.word] })

  return (
    <FormDetailSection
      defaultValues={pick(keyword, ['contactLinkRedirectUrl'])}
      description='If you are using the Contact Link dynamic field in your reply message, you can tell us where to send the person after they fill out the contact info form.'
      formControls={[{ label: 'Contact Link Redirect Url', name: 'contactLinkRedirectUrl', type: 'url' }]}
      formDescription={`Updating keyword for ${keyword.word}`}
      formTitle={keyword.word}
      objectId={keyword._id}
      service={service}
      title='Contact Link Redirect Url'
      showEditIcon
      onChange={onChange}
    >
      {keyword.contactLinkRedirectUrl}
    </FormDetailSection>
  )
}

ContactLinkRedirectUrlDetail.displayName = 'ContactLinkRedirectUrlDetail'
ContactLinkRedirectUrlDetail.propTypes = propTypes
ContactLinkRedirectUrlDetail.defaultProps = defaultProps

export default ContactLinkRedirectUrlDetail
