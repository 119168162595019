import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTrackVisibility } from 'react-intersection-observer-hook'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { previewEmail, previewLandingPage, previewUnlayerTemplate } from '../../services/unlayer'
import { Close } from '../../svg/icons'
import Button from '../buttons/Button'
import ContactList from '../contacts/ContactList'
import ListDialog from '../dialog/ListDialog'
import LoadingIndicator from '../LoadingIndicator'

import UnlayerDesignPreviewIframe from './UnlayerDesignPreviewIframe'

const propTypes = {
  which: PropType.oneOf(['landingPage', 'email', 'unlayerTemplate']).isRequired,
  recordId: PropType.string,
  showPreview: PropType.bool
}

const defaultProps = {
  recordId: null,
  showPreview: false
}

const services = {
  email: previewEmail,
  landingPage: previewLandingPage,
  unlayerTemplate: previewUnlayerTemplate
}

const log = logger({ enabled: true, tags: ['UnlayerDesignPreview'] })

const UnlayerDesignPreview = ({
  showPreview,
  recordId,
  which
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [loading, setLoading] = useState(true)

  const [visibilityRef, { wasEverVisible }] = useTrackVisibility()

  const iframeRef = useRef()

  const [contact, setContact] = useState(null)

  const handleReplyOk = useCallback((reply) => {
    iframeRef.current.update(reply.json.body)
    setLoading(false)
  }, [])
  const { call } = useService(services[which], { onReplyOk: handleReplyOk })

  const handleClear = useCallback(() => {
    setContact(null)
    call(recordId)
  }, [call, recordId])

  const handleContactSelect = useCallback((selection) => {
    setContact(selection)
    call(recordId, selection._id)
  }, [call, recordId])

  const display = useMemo(() => {
    if (contact) { return contact.formattedName }
    return contact?.displayName || 'No Contact'
  }, [contact])

  const handleIframeLoad = useCallback(() => {
    call(recordId, contact?._id)
  }, [call, contact?._id, recordId])

  const iframeLoadingOverlay = useMemo(() => {
    if (!loading) { return null }
    return (
      <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center h-[150px] w-full'>
        <div className='absolute top-0 left-0 right-0 bottom-0 bg-white opacity-50 blur' />
        <LoadingIndicator className='relative' />
      </div>
    )
  }, [loading])

  const iframe = useMemo(() => {
    if (!wasEverVisible) { return }
    return (
      <UnlayerDesignPreviewIframe ref={iframeRef} onLoad={handleIframeLoad} />
    )
  }, [handleIframeLoad, wasEverVisible])

  if (!showPreview || !recordId) { return null }

  const trigger = (
    <Button
      size='sm'
      theme='neutral'
      variant='outline'
    >
      {display}
    </Button>
  )

  return (
    <>
      <div className='flex flex-row items-center mb-3'>
        <span className='label-sm-strong pr-2'>Preview As:</span>
        <ListDialog
          list={ContactList}
          multiple={false}
          trigger={trigger}
          type='select'
          onSubmitSelected={handleContactSelect}
        />
        {contact
          ? (
            <Button
              icon={<Close />}
              size='sm'
              theme='neutral'
              variant='none'
              onClick={handleClear}
            >
              Clear
            </Button>
            )
          : null}
      </div>
      <div ref={visibilityRef} className='relative'>
        {iframeLoadingOverlay}
        {iframe}
      </div>
    </>
  )
}

UnlayerDesignPreview.displayName = 'UnlayerDesignPreview'
UnlayerDesignPreview.propTypes = propTypes
UnlayerDesignPreview.defaultProps = defaultProps

export default UnlayerDesignPreview
