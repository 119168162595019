import PropType from 'prop-types'
import { forwardRef } from 'react'

import countryData from '../../../data-sources/countries.json'

import Select from './Select'

const propTypes = {
  label: PropType.string,
  placeholder: PropType.string
}
const defaultProps = {
  label: 'Country',
  placeholder: 'Choose Country'
}

const CountrySelect = forwardRef(({ ...formControlArgs }, ref) => {
  delete formControlArgs.type

  return (
    <Select
      ref={ref}
      options={countryData}
      type='select'
      {...formControlArgs}
    />
  )
})

CountrySelect.displayName = 'CountrySelect'
CountrySelect.propTypes = propTypes
CountrySelect.defaultProps = defaultProps

export default CountrySelect
