import * as React from "react";
import { memo } from "react";
const SvgQuote = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.765 17.55c0 1.146-.465 2.148-1.396 3.007a4.58 4.58 0 0 1-3.248 1.289c-1.969 0-3.481-.654-4.537-1.96C1.528 18.579 1 16.772 1 14.463c0-2.148.904-4.313 2.711-6.497C5.537 5.783 7.73 4.128 10.29 3l1.18 1.906c-2.022.984-3.605 2.13-4.75 3.436-1.146 1.307-1.808 2.89-1.987 4.752h1.503c1.11 0 2.013.125 2.711.376.698.25 1.262.6 1.692 1.047.411.43.698.904.859 1.423.179.519.268 1.056.268 1.61Zm12.235 0c0 1.146-.465 2.148-1.396 3.007a4.58 4.58 0 0 1-3.248 1.289c-1.969 0-3.481-.654-4.537-1.96-1.056-1.307-1.584-3.114-1.584-5.423 0-2.148.904-4.313 2.711-6.497C17.772 5.783 19.964 4.128 22.523 3l1.182 1.906c-2.023.984-3.607 2.13-4.752 3.436-1.145 1.307-1.808 2.89-1.986 4.752h1.503c1.11 0 2.013.125 2.711.376.698.25 1.262.6 1.691 1.047.412.43.698.904.86 1.423.178.519.268 1.056.268 1.61Z"
    />
  </svg>
);
const Memo = memo(SvgQuote);
export default Memo;
