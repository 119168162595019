import * as React from "react";
import { memo } from "react";
const SvgHeart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.613 7.108c-.564-3.243-3.676-4.943-6.503-3.694l-.01.005c-.355.152-1.299.6-2.182 1.643l-1.525 1.83-1.56-1.804-.003-.003C9.8 3.883 8.251 3.16 6.741 3.105c-1.484-.055-2.888.53-3.785 1.906l-.002.003c-1.08 1.647-1.235 3.382-.505 5.302l.001.003c.572 1.52 1.546 2.866 2.784 4.27 2.09 2.342 4.492 4.368 7.116 6.199 1.192-.794 2.301-1.589 3.299-2.463 2.556-2.263 4.778-4.37 6.2-7.025.746-1.41 1.001-2.75.764-4.192Zm-7.306-5.525c4.104-1.81 8.51.759 9.277 5.188l.002.007c.319 1.93-.045 3.714-.97 5.46l-.002.004c-1.61 3.008-4.092 5.326-6.642 7.582l-.003.003c-1.18 1.035-2.478 1.946-3.806 2.82-.1.068-.154.105-.19.134l-.577.46-.61-.414c-2.944-2-5.673-4.25-8.047-6.909l-.003-.004c-1.3-1.474-2.46-3.036-3.157-4.889-.94-2.474-.755-4.886.702-7.107 1.331-2.043 3.447-2.89 5.534-2.812 2.06.076 4.134 1.047 5.532 2.676l.001.001.017.019.024-.03c1.155-1.364 2.403-1.968 2.918-2.19Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgHeart);
export default Memo;
