import { Link } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import useStore from '../../../store'
import { BroadcastPaused, ChevronRight } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BroadcastingPaused'] })

const BroadcastingPaused = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const isBroadcastingPaused = useStore((state) => state.isBroadcastingPaused)

  if (!isBroadcastingPaused) { return null }

  return (
    <Link className='block w-[278px]' to='/settings/broadcast-hours'>
      <div className='bg-indigo-50 flex flex-row flex-nowrap items-center py-2 px-[12px]'>
        <div className='bg-indigo-400 rounded-lg shrink-0 w-[40px] h-[40px] flex flex-col items-center justify-center mr-3'>
          <div className='bg-indigo-50 rounded-full w-[32px] h-[32px] flex flex-col items-center justify-center'>
            <BroadcastPaused className='text-indigo-700' height='20' width='20' />
          </div>
        </div>
        <span className='label-sm-strong text-indigo-700 shrink-0'>Broadcasting Paused</span>
        <ChevronRight className='ml-auto text-indigo-700' height='16' width='16' />
      </div>
    </Link>
  )
}

BroadcastingPaused.displayName = 'BroadcastingPaused'
BroadcastingPaused.propTypes = propTypes
BroadcastingPaused.defaultProps = defaultProps

export default BroadcastingPaused
