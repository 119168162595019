import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import ContactList from '../../components/contacts/ContactList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { campaignTypeLabel } from '../../helpers/campaign'
import useCurrentCampaign from '../../hooks/useCurrentCampaign'
import useCurrentCampaignEvent from '../../hooks/useCurrentCampaignEvent'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { searchContacts as searchEventContacts } from '../../services/campaign-event'

const propTypes = {
  type: PropType.oneOf(['queued', 'processed']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignEventContactsList'] })

const CampaignEventContactsList = ({ type }) => {
  const { campaignId, campaign, loading: campaignLoading } = useCurrentCampaign()
  const { campaignEventId, campaignEvent, loading: campaignEventLoading } = useCurrentCampaignEvent()
  const loaded = !campaignLoading && !campaignEventLoading && !!campaign && !!campaignEvent

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  useLogger({ log, lifecycle: false, tags: [campaignId, campaignEventId] })

  if (!loaded) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        start={<PanelHeaderBackButton />}
        subtitle={`${campaignTypeLabel(campaign?.type)} Message`}
        title={`${upperFirst(type)} Contacts`}
      />
      <PanelContent>
        <ContactList
          placeholder={`Search ${upperFirst(type)} Contacts`}
          queryOverrides={{ campaignId, campaignEventId, state: type === 'queued' ? 'scheduled' : 'sent' }}
          searchService={searchEventContacts}
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

CampaignEventContactsList.displayName = 'CampaignEventContactsList'
CampaignEventContactsList.propTypes = propTypes
CampaignEventContactsList.defaultProps = defaultProps

export default CampaignEventContactsList
