import * as React from "react";
import { memo } from "react";
const SvgHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.791 2.033a1.25 1.25 0 0 0-1.582 0l-9 7.363a1.25 1.25 0 0 0-.459.968v9a2.891 2.891 0 0 0 2.886 2.886h4.07a1.522 1.522 0 0 0 .043 0h6.503a1.527 1.527 0 0 0 .042 0h4.07a2.891 2.891 0 0 0 2.886-2.886v-9c0-.375-.168-.73-.459-.968l-9-7.363Zm3.732 17.717h2.84c.21 0 .387-.177.387-.386v-8.408L12 4.616l-7.75 6.34v8.408c0 .21.177.386.386.386h2.842v-3.659A4.522 4.522 0 0 1 12 11.568a4.522 4.522 0 0 1 4.523 4.523v3.659Zm-6.545 0h4.045v-3.659a2.022 2.022 0 1 0-4.045 0v3.659Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgHome);
export default Memo;
