import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import HideChatAction from '../../../components/chats/actions/HideChatAction'
import MarkAsDoNotMessage from '../../../components/chats/actions/MarkAsDoNotMessage'
import MarkAsUnreadAction from '../../../components/chats/actions/MarkAsUnreadAction'
import TurnOffDoNotMessage from '../../../components/chats/actions/TurnOffDoNotMessage'
import ApplyKeyword from '../../../components/contacts/actions/ApplyKeywordAction'
import ArchiveAction from '../../../components/contacts/actions/ArchiveAction'
import CallAction from '../../../components/contacts/actions/CallAction'
import ManageAccountAction from '../../../components/managed-accounts/actions/ManageAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import chat from '../../../prop-types/shapes/chat'
import { isManagerFor } from '../../../services/user'

const propTypes = {
  chat: PropType.shape(chat).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ListHeaderMore'] })

const ListHeaderMore = forwardRef(({ chat }, ref) => {
  useLogger({ log, lifecycle: false, tags: ['ChatList', chat._id] })

  const [isManagerForUser, setIsManagerForUser] = useState(false)

  const handleReplyOk = useCallback((reply) => {
    setIsManagerForUser(!!reply?.json?.isManager)
  }, [])
  const { call } = useService(isManagerFor, { onReplyOk: handleReplyOk })

  const manageUserId = chat?.contactUser?._id || chat?.contactUser
  useEffect(() => {
    if (manageUserId) { call(manageUserId, true) }
  }, [call, manageUserId])

  const navigate = useNavigate()
  const currentStackKey = useCurrentStackKey()

  const applyKeywordActionRef = useRef()
  const activateKeywordAction = useCallback(() => applyKeywordActionRef.current.activate(), [])

  const archiveActionRef = useRef()
  const activateArchiveAction = useCallback(() => archiveActionRef.current.activate(), [])
  const handleArchiveActionSuccess = useCallback(() => {
    navigate(currentStackKey === 'chats' ? '/chats' : '/contacts')
  }, [currentStackKey, navigate])

  const callContactActionRef = useRef()
  const activateCallContactAction = useCallback(() => callContactActionRef.current.activate(), [])

  const hideChatActionRef = useRef()
  const activateHideChatAction = useCallback(() => hideChatActionRef.current.activate(), [])

  const manageAccountActionRef = useRef()
  const activateManageAccountAction = useCallback(() => manageAccountActionRef.current.activate(), [])

  const markAsUnreadActionRef = useRef()
  const activateMarkAsUnreadAction = useCallback(() => markAsUnreadActionRef.current.activate(), [])

  const markAsDoNotMessageRef = useRef()
  const activateMarkAsDoNotMessage = useCallback(() => markAsDoNotMessageRef.current.activate(), [])

  const turnOffDoNotMessageRef = useRef()
  const activateTurnOffDoNotMessage = useCallback(() => turnOffDoNotMessageRef.current.activate(), [])

  const DoNotMessageActionMenu = !chat.doNotMessage ? <ActionMenuItem label='Mark as Do Not Message' onClick={activateMarkAsDoNotMessage} /> : <ActionMenuItem label='Turn Off Do Not Message' onClick={activateTurnOffDoNotMessage} />

  return (
    <>
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Apply Keyword' onClick={activateKeywordAction} />
        <ActionMenuItem href={currentStackKey === 'chats' ? 'contact/tags' : `/contacts/${chat._id}/tags`} label='Manage Tags' />
        <ActionMenuItem href={currentStackKey === 'chats' ? 'contact/campaigns' : `/contacts/${chat._id}/campaigns`} label='Manage Campaigns' />
        <ActionMenuItem href={currentStackKey === 'chats' ? 'contact' : `/contacts/${chat._id}`} label='Edit Contact' />
        <ActionMenuItem href={`tel:${chat.phoneNumber}`} label='Call Using This Device' />
        <ActionMenuItem label='Call Using PB Number' onClick={activateCallContactAction} />
        {DoNotMessageActionMenu}
        <ActionMenuItem label='Mark as Unread' onClick={activateMarkAsUnreadAction} />
        <ActionMenuItem label='Hide Chat' onClick={activateHideChatAction} />
        <ActionMenuItem label='Archive Contact' onClick={activateArchiveAction} />
        {isManagerForUser ? <ActionMenuItem label='Manage Account' onClick={activateManageAccountAction} /> : null}
      </ActionMenu>
      <ArchiveAction ref={archiveActionRef} contact={chat} onSuccess={handleArchiveActionSuccess} />
      <ApplyKeyword ref={applyKeywordActionRef} contact={chat} />
      <CallAction ref={callContactActionRef} contact={chat} />
      <HideChatAction ref={hideChatActionRef} chat={chat} />
      <MarkAsUnreadAction ref={markAsUnreadActionRef} chat={chat} />
      <MarkAsDoNotMessage ref={markAsDoNotMessageRef} chat={chat} />
      <TurnOffDoNotMessage ref={turnOffDoNotMessageRef} chat={chat} />
      {isManagerForUser ? <ManageAccountAction ref={manageAccountActionRef} userId={manageUserId} /> : null}
    </>
  )
})

ListHeaderMore.displayName = 'ListHeaderMore'
ListHeaderMore.propTypes = propTypes
ListHeaderMore.defaultProps = defaultProps

export default ListHeaderMore
