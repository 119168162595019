import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import { bulkFullDelete as bulkFullDeleteService } from '../../../services/contact'
import { success } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'
import ListDialog from '../../dialog/ListDialog'
import ContactList from '../ContactList'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const DeleteContactsAction = forwardRef(({ onSuccess }, ref) => {
  const confirmRef = useRef()
  const selectRef = useRef()

  const handleReplyOk = useCallback(() => {
    success('Contacts queued for deletion.')
    onSuccess?.()
  }, [onSuccess])
  const { call: bulkFullDelete } = useService(bulkFullDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    selectRef.current.open()
  }, [])

  const handleDeleteAllInQuery = useCallback((data) => {
    bulkFullDelete({ search: data })
  }, [bulkFullDelete])

  const handleDeleteSelected = useCallback((data) => {
    bulkFullDelete({ contactIds: pluck(data, '_id') })
  }, [bulkFullDelete])

  return (
    <>
      <DangerousConfirmDialog
        ref={confirmRef}
        confirmButtonText='CONTINUE'
        confirmValue='CONTINUE'
        title='Delete Contacts'
        onConfirm={handleConfirm}
      >
        <p className='mb-4'>
          <strong>WARNING:</strong>
          {' '}
          Deleting contacts will remove them completely from the system as if they never existed. This includes being removed from all broadcasts, campaigns, chats, attachment downloads, trackable link clicks, landing page views, contests, etc. If you do not wish to fully delete the contacts you select, please archive them instead using the "Archived Contacts" feature.
        </p>
        <p className='mb-4' />
        <p className='mb-4'>
          <strong>IMPORTANT:</strong>
          {' '}
          Have you backed up your contacts? If you choose to continue with deleting contacts rather than archiving them, we highly recommend exporting your contacts first using the "Export Contacts" feature.
        </p>
      </DangerousConfirmDialog>
      <ListDialog
        ref={selectRef}
        actionConfirm='deleting'
        actionLabel='Delete'
        list={ContactList}
        subtitle='This cannot be undone.'
        title='Delete Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleDeleteAllInQuery}
        onSubmitSelected={handleDeleteSelected}
      />
    </>
  )
})

DeleteContactsAction.displayName = 'DeleteContactsAction'
DeleteContactsAction.propTypes = propTypes
DeleteContactsAction.defaultProps = defaultProps

export default DeleteContactsAction
