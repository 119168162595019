module.exports = {
  inherit: 'inherit',
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  black: '#000000',
  primary: '#0056FF',
  action: '#0056FF',
  overlay: 'rgba(32, 41, 56, 0.5)',
  danger: '#DC2625',
  error: '#DC2625',
  neutral: {
    50: '#F9FAFC',
    100: '#F3F4F6',
    200: '#E6E7EB',
    300: '#D2D5DC',
    400: '#9CA2AE',
    500: '#6B7380',
    600: '#4C5564',
    700: '#384152',
    800: '#202938',
    900: '#111828'
  },
  red: {
    50: '#FEF2F2',
    100: '#FEE2E1',
    200: '#FECBCA',
    300: '#FDA5A4',
    400: '#F87070',
    500: '#F04444',
    600: '#DC2625',
    700: '#BA1C1D',
    800: '#991B1C',
    900: '#7F1D1E'
  },
  orange: {
    50: '#FFFAF5',
    100: '#FFF6ED',
    200: '#FFEAD5',
    300: '#FDDCAB',
    400: '#FEB273',
    500: '#FD853A',
    600: '#FB6514',
    700: '#EC4A0A',
    800: '#9C2A10',
    900: '#7E2410'
  },
  yellow: {
    50: '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#E9B308',
    600: '#CA8A04',
    700: '#A26208',
    800: '#854E0E',
    900: '#723F12'
  },
  lime: {
    50: '#F6FEE7',
    100: '#ECFCCB',
    200: '#DAF99C',
    300: '#BEF164',
    400: '#A3E636',
    500: '#83CC16',
    600: '#64A30E',
    700: '#5A8720',
    800: '#3F6212',
    900: '#365313'
  },
  green: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D1',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#23C55E',
    600: '#17A34A',
    700: '#157F3D',
    800: '#176535',
    900: '#15532E'
  },
  emerald: {
    50: '#ECFDF5',
    100: '#D0FAE4',
    200: '#A7F3CF',
    300: '#6DE7B6',
    400: '#34D399',
    500: '#10B982',
    600: '#05976A',
    700: '#057857',
    800: '#075F47',
    900: '#064D3B'
  },
  cyan: {
    50: '#ECFEFF',
    100: '#CFFBFE',
    200: '#A5F3FD',
    300: '#66E8F8',
    400: '#21D3ED',
    500: '#07B6D5',
    600: '#0891B3',
    700: '#0F7490',
    800: '#165E76',
    900: '#164F63'
  },
  sky: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#BAE7FE',
    300: '#7ED3FC',
    400: '#38BDF8',
    500: '#0EA5EA',
    600: '#0384C6',
    700: '#0469A1',
    800: '#075A86',
    900: '#0C4A6F'
  },
  blue: {
    50: '#EFF6FF',
    100: '#DBEAFF',
    200: '#BFDCFE',
    300: '#93C4FC',
    400: '#61A5FA',
    500: '#3B82F6',
    600: '#2563EC',
    700: '#1D4ED7',
    800: '#1F41AF',
    900: '#1F3A8B'
  },
  indigo: {
    50: '#EEF2FE',
    100: '#E1E7FF',
    200: '#C7D2FF',
    300: '#A6B4FD',
    400: '#818CF8',
    500: '#6366F1',
    600: '#5046E5',
    700: '#4438CA',
    800: '#3730A2',
    900: '#312D81'
  },
  violet: {
    50: '#F5F3FF',
    100: '#EDEAFF',
    200: '#DDD6FF',
    300: '#C5B5FE',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7D3AEC',
    700: '#6D28D9',
    800: '#5B20B6',
    900: '#4D1D95'
  },
  purple: {
    50: '#FBF5FF',
    100: '#F4E8FE',
    200: '#EAD5FE',
    300: '#D8B4FE',
    400: '#C085FD',
    500: '#A755F7',
    600: '#9334EA',
    700: '#7E22CF',
    800: '#6B22A8',
    900: '#571C86'
  },
  fuchsia: {
    50: '#FEF4FF',
    100: '#FAE8FE',
    200: '#F5CFFE',
    300: '#F0ABFC',
    400: '#E879F9',
    500: '#D946EF',
    600: '#C026D4',
    700: '#A21CAF',
    800: '#861990',
    900: '#701A75'
  },
  rose: {
    50: '#FFF1F1',
    100: '#FEE4E7',
    200: '#FECDD3',
    300: '#FEA4B0',
    400: '#FB7286',
    500: '#F43F5E',
    600: '#E21D48',
    700: '#BE113C',
    800: '#9F133A',
    900: '#891336'
  }
}
