import * as React from "react";
import { memo } from "react";
const SvgCampaign = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 3a1 1 0 0 0-1.287-.958L3 7.656V7.49a1 1 0 0 0-2 0V14.49a1 1 0 1 0 2 0v-.08l2.587.905-.44 1.253A3.009 3.009 0 0 0 7.1 20.364l4.769 1.5a3.009 3.009 0 0 0 3.763-1.96l.313-.964 5.724 2.004A1 1 0 0 0 23 20V3Zm-8.943 15.28-6.582-2.304-.435 1.237c-.156.52.14 1.08.66 1.243l4.77 1.5a1.009 1.009 0 0 0 1.256-.656l.003-.009.328-1.011ZM3 12.29l18 6.3V4.344l-18 5.4v2.546Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCampaign);
export default Memo;
