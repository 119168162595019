import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Image } from '../../svg/icons'

const propTypes = {
  className: PropType.string
}

const defaultProps = {
  className: ''
}

const log = logger({ enabled: false, tags: ['ImageDropZoneIndicator'] })

const ImageDropZoneIndicator = ({ className }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className={`absolute w-full h-full border-2 border-neutral-100 bg-neutral-50 p-2 pointer-events-none ${className}`}>
      <div className='flex flex-col justify-center items-center border border-dotted border-neutral-300 rounded-md p-4 h-full w-full'>
        <Image className='text-neutral-300 w-12 h-12' />
        <p className='text-neutral-400 label-sm'>Drop Image</p>
      </div>
    </div>
  )
}

ImageDropZoneIndicator.displayName = 'ImageDropZoneIndicator'
ImageDropZoneIndicator.propTypes = propTypes
ImageDropZoneIndicator.defaultProps = defaultProps

export default ImageDropZoneIndicator
