import PropType from 'prop-types'

export default {
  __v: PropType.number,
  _id: PropType.string,
  contact: PropType.shape({
    _id: PropType.string,
    company: PropType.string,
    contactUser: PropType.string,
    firstName: PropType.string,
    lastName: PropType.string,
    phoneNumber: PropType.string
  }),
  createdAt: PropType.string // "2021-02-12T15:47:33.143Z"
}
