import { get as getTemplateService } from '../services/template'

import useCurrentInstance from './useCurrentInstance'

const useCurrentVoiceDrop = () => useCurrentInstance({
  resourceName: 'voiceDrop',
  getService: getTemplateService,
  replyAttrPath: 'json',
  notFoundRedirect: '/voice-drops'
})

export default useCurrentVoiceDrop
