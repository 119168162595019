import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShortLinkStopMessage'] })

const ShortLinkStopMessage = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (<p>** At any time you can reply STOP to the sender to stop receiving messages **</p>)
}

ShortLinkStopMessage.displayName = 'ShortLinkStopMessage'
ShortLinkStopMessage.propTypes = propTypes
ShortLinkStopMessage.defaultProps = defaultProps

export default ShortLinkStopMessage
