import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('scheduledAtDay', 'Date required', () => {
    enforce(data.scheduledAtDay).isNotEmpty()
  })

  test('scheduledAtTime', 'Time required', () => {
    enforce(data.scheduledAtTime).isNotEmpty()
  })
})

export default validationSuite
