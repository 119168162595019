import { groupBy } from 'lodash'

export const pricePerCredit = 4.4
export const herbalifePricePerCredit = 3.6
export const defaultPlanId = 'starter_4'
export const socialPlanId = 'free'
export const defaultPaygPlanId = 'pay_as_you_go_4'
export const defaultOnHoldPlanId = 'on_hold_4'

export const onHoldPlanIds = ['onhold', 'on_hold_4']

export function isOnHoldPlan (plan) {
  return onHoldPlanIds.includes(plan?.id)
}

export function isSocialPlan (plan) {
  return plan?.id === socialPlanId
}

export function planCanBuyCredits (plan) {
  return !(isOnHoldPlan(plan) || isSocialPlan(plan))
}

export function isPayingPlan (plan) {
  return plan?.priceInCents > 0
}

export function includesTrial (plan) {
  return plan?.newUserTrialInDays > 0
}

export function requiresVerifiedMobile (plan) {
  return plan?.phoneNumberType === 'verified-cell'
}

export function isPaygPlan (plan) {
  const paygPlans = [
    'pay_as_you_go',
    'pay_as_you_go_3',
    'pay_as_you_go_4',
    'pay_as_you_go_annual_3',
    'pay_as_you_go_annual_4',
    'herbalife_pay_as_you_go',
    'herbalife_pay_as_you_go_annual'
  ]
  return paygPlans.includes(plan?.id)
}

export function movingToPayAsYouGo (oldPlan, newPlan) {
  return !isPaygPlan(oldPlan) && isPaygPlan(newPlan)
}

export function movingToVerifiedCell (oldPlan, newPlan) {
  return oldPlan?.phoneNumberType !== 'verified-cell' && newPlan?.phoneNumberType === 'verified-cell'
}

export function movingToManagedVoip (oldPlan, newPlan) {
  return oldPlan?.phoneNumberType !== 'managed-voip' && newPlan?.phoneNumberType === 'managed-voip'
}

export function movingToNonRollover (oldPlan, newPlan) {
  return oldPlan?.rolloverCredits === true && newPlan?.rolloverCredits === false
}

export function movingFromOnHold (oldPlan, newPlan) {
  const onHoldPlanIds = ['onhold', 'on_hold_4']
  return onHoldPlanIds.includes(oldPlan?.id) && !onHoldPlanIds.includes(newPlan?.id)
}

export function planMustChangeImmediately (oldPlan, newPlan) {
  return movingToManagedVoip(oldPlan, newPlan) ||
    movingToVerifiedCell(oldPlan, newPlan) ||
    movingFromOnHold(oldPlan, newPlan) ||
    isSamePlan(oldPlan, newPlan)
}

function isSameFrequency (oldPlan, newPlan) {
  return oldPlan?.frequency === newPlan?.frequency
}

function isSamePlan (oldPlan, newPlan) {
  return oldPlan?.id === newPlan?.id
}

function isSamePlanOrUpgrade (oldPlan, newPlan) {
  return isSamePlan(oldPlan, newPlan) || isUpgrade(oldPlan, newPlan)
}

function isUpgrade (oldPlan, newPlan) {
  return movingToYearly(oldPlan, newPlan) ||
    (isSameFrequency(oldPlan, newPlan) && movingToPlanWithHigherPrice(oldPlan, newPlan))
}

function movingToPlanWithHigherPrice (oldPlan, newPlan) {
  return newPlan?.priceInCents > oldPlan?.priceInCents
}

function movingToYearly (oldPlan, newPlan) {
  return oldPlan?.frequency === 'Monthly' && newPlan?.frequency === 'Yearly'
}

export function userCanChoosePlanChangeDate (oldPlan, newPlan) {
  return !planMustChangeImmediately(oldPlan, newPlan) && isSamePlanOrUpgrade(oldPlan, newPlan)
}

function planDescription (plan, newSignupForm) {
  if (plan.priceInCents === 0) {
    return plan.desc
  }

  const perCredit = plan.id.startsWith('herbalife_')
    ? herbalifePricePerCredit
    : pricePerCredit

  const creditsAmount = `${plan.credits.toLocaleString()}${plan.additionalPartnerCredits ? ` + ${plan.additionalPartnerCredits.toLocaleString()}` : ''}`
  const creditsType = `${plan.rolloverCredits ? '' : 'Non-Rollover '}`
  const creditsDesc = plan.credits === 0
    ? `Buy Credits @ ${perCredit.toFixed(2)}${String.fromCharCode(162)} / credit`
    : `${creditsAmount} ${creditsType}Credits @ ${plan.actualCostPerCreditInCents.toPrecision(3)}${String.fromCharCode(162)} / credit`
  const trial = (newSignupForm && plan.newUserTrialInDays) ? `${plan.newUserTrialInDays} Day Trial, ` : ''
  const frequencyAbbrev = plan.frequency === 'Yearly' ? 'yr' : 'mo'
  const onboarding = (newSignupForm && plan.includesOnboarding) ? ' + Expert Onboarding Included' : ''

  return [
    trial,
    plan.price,
    `/${frequencyAbbrev} - `,
    plan.name,
    ` (${creditsDesc})`,
    onboarding
  ].filter((line) => line.toString().length > 0).join('')
}

function subscriptionOptionFormat (plan, newSignupForm) {
  return {
    label: planDescription(plan, newSignupForm),
    value: plan.id
  }
}

export function optionsData (plans, newSignupForm = false) {
  const activePlans = plans.filter((plan) => !plan.hidden)
  const groupedPlans = groupBy(activePlans, 'frequency')

  return Object.keys(groupedPlans).map((frequency) => {
    return {
      label: frequency,
      value: groupedPlans[frequency].map((plan) => subscriptionOptionFormat(plan, newSignupForm))
    }
  })
}
