import classNames from 'classnames'
import PropType from 'prop-types'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import LoadingIndicatorSvg from '../svg/raw-components/LoadingIndicator'

import './LoadingIndicator.css'

const propTypes = {
  className: PropType.string,
  message: PropType.string
}

const defaultProps = {
  className: null,
  message: null
}

const log = logger({ enabled: false, tags: ['LoadingIndicator'] })

const LoadingIndicator = ({ className, message, ...props }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = classNames(
    'flex flex-col gap-3 p-4 w-fit min-w-[200px] bg-white text-center',
    className
  )

  return (
    <div
      aria-label='Loading'
      className={computedClassName}
      id='loadingIndicator'
      {...props}
    >
      <LoadingIndicatorSvg className='h-20' />
      <div className='font-medium tracking-tight'>{message || 'Loading...'}</div>
    </div>
  )
}

LoadingIndicator.displayName = 'LoadingIndicator'
LoadingIndicator.propTypes = propTypes
LoadingIndicator.defaultProps = defaultProps

export default LoadingIndicator
