import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelDialog from '../dialog/PanelDialog'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import UnlayerTemplates from './UnlayerTemplates'

const propTypes = {
  subtitle: PropType.string.isRequired,
  which: PropType.oneOf(['landingPage', 'email']).isRequired,
  onTemplateSelected: PropType.func.isRequired,
  title: PropType.string
}

const defaultProps = {
  title: 'Design Templates'
}

const log = logger({ enabled: false, tags: ['UnlayerTemplateDialog'] })

const UnlayerTemplateDialog = forwardRef(({
  subtitle,
  title,
  which,
  onTemplateSelected
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const handleCancelTemplates = useCallback(() => { ref.current.close() }, [ref])
  const handleSelectedTemplate = useCallback((template) => {
    onTemplateSelected(template)
  }, [onTemplateSelected])

  return (
    <PanelDialog
      ref={ref}
      ariaLabel={title}
      fullscreen
    >
      <PanelHeader
        start={<PanelHeaderButton icon='cancel' onClick={handleCancelTemplates} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent className='p-5'>
        <UnlayerTemplates which={which} onSelected={handleSelectedTemplate} />
      </PanelContent>
    </PanelDialog>
  )
})

UnlayerTemplateDialog.displayName = 'UnlayerTemplateDialog'
UnlayerTemplateDialog.propTypes = propTypes
UnlayerTemplateDialog.defaultProps = defaultProps

export default UnlayerTemplateDialog
