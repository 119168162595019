import * as React from "react";
import { memo } from "react";
const SvgCloseMini = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.005 17.232 2.227-2.227-2.864-2.864 2.864-2.864-2.227-2.227-2.864 2.864L9.277 7.05 7.05 9.277l2.864 2.864-2.864 2.864 2.227 2.227 2.864-2.863 2.864 2.863Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCloseMini);
export default Memo;
