import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import spaceShape from '../../../prop-types/shapes/space'
import { addAllInQuery, addSelected } from '../../../services/space-member'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import ListDialog from '../../dialog/ListDialog'
import SpaceContactList from '../SpaceContactList'

const propTypes = {
  space: PropType.shape(spaceShape)
}

const defaultProps = {
  space: null
}

const log = logger({ enabled: false, tags: ['AddMembersAction'] })

const AddMembersAction = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const addListDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { addListDialogRef.current.open() }
  }), [addListDialogRef])

  const handleReplyOk = useCallback((reply) => {
    success('Members added')
    const updatedSpace = reply.model
    setCurrentInstance('space', updatedSpace)
    bus.emit('spaceUpdated', updatedSpace)
  }, [])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ spaceId: space._id, ...query })
  }, [addAllInQueryCall, space])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ spaceId: space._id, contactIds: map(selected, '_id') })
  }, [addSelectedCall, space])

  return (
    <ListDialog
      ref={addListDialogRef}
      list={SpaceContactList}
      spaceId={space?._id}
      subtitle={space?.name}
      title='Add Member(s)'
      type='add'
      onSubmitAllInQuery={handleAddAllInQuery}
      onSubmitSelected={handleAddSelected}
    />
  )
})

AddMembersAction.displayName = 'AddMembersAction'
AddMembersAction.propTypes = propTypes
AddMembersAction.defaultProps = defaultProps

export default AddMembersAction
