import { omit } from 'lodash'
import PropType from 'prop-types'

import spaceFromJSON from '../models/space'
import spaceMemberFromJSON from '../models/spaceMember'

import pb from './pb'
import { addRemoveAllInQueryPropTypes, addSelectedPropTypes, removeSelectedPropTypes, searchPropTypes } from './space-member.prop-types'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'space-contact#search')

  const { spaceId } = data
  const body = omit(data, 'spaceId')
  const model = {
    conversion: spaceMemberFromJSON,
    key: 'members',
    multiple: true
  }
  return pb.setupPost(`spaces/${spaceId}/members/search`, { body, background, model })
}

export function addSelected (data) {
  PropType.checkPropTypes(addSelectedPropTypes, data, 'prop', 'space-contact#addSelected')

  const { spaceId } = data
  const body = omit(data, 'spaceId')
  const model = {
    conversion: spaceFromJSON,
    key: 'space'
  }
  return pb.setupPut(`spaces/${spaceId}/members/add`, { body, model })
}

export function addAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'space-contact#addAllInQuery')

  const { spaceId } = data
  const body = omit(data, 'spaceId')
  const model = {
    conversion: spaceFromJSON,
    key: 'space'
  }
  return pb.setupPut(`spaces/${spaceId}/members/add/all`, { body, model })
}

export function removeSelected (data) {
  PropType.checkPropTypes(removeSelectedPropTypes, data, 'prop', 'space-contact#removeSelected')

  const { spaceId } = data
  const body = omit(data, 'spaceId')
  const model = {
    conversion: spaceFromJSON,
    key: 'space'
  }
  return pb.setupPut(`spaces/${spaceId}/members/remove`, { body, model })
}

export function removeAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'space-contact#removeAllInQuery')

  const { spaceId } = data
  const body = omit(data, 'spaceId')
  const model = {
    conversion: spaceFromJSON,
    key: 'space'
  }
  return pb.setupPut(`spaces/${spaceId}/members/remove/all`, { body, model })
}
