import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { ChevronRight, Edit, Reload, Trash } from '../../svg/icons'
import Clipboard from '../../svg/icons/Clipboard'
import Button from '../buttons/Button'
import TitleCountPill from '../pill/TitleCountPill'

import WarningDetailSection from './WarningDetailSection'

const propTypes = {
  children: PropType.node,
  className: PropType.string,
  count: PropType.number,
  description: PropType.string,
  editHref: PropType.string,
  editIcon: PropType.oneOf(['edit', 'chevron', 'reload']),
  hideContent: PropType.bool,
  initialEditButtonText: PropType.string,
  showClearIcon: PropType.bool,
  showCopyIcon: PropType.bool,
  showEditIcon: PropType.bool,
  showInitialEditButton: PropType.bool,
  title: PropType.string,
  titleIcon: PropType.element,
  warning: PropType.string,
  onClearClick: PropType.func,
  onCopyClick: PropType.func,
  onEditClick: PropType.func,
  onInitialEditClick: PropType.func
}

const defaultProps = {
  children: null,
  className: null,
  count: null,
  description: null,
  editHref: null,
  editIcon: 'edit',
  hideContent: false,
  initialEditButtonText: 'Edit',
  onClearClick: undefined,
  onCopyClick: undefined,
  onEditClick: undefined,
  onInitialEditClick: undefined,
  showClearIcon: false,
  showCopyIcon: false,
  showEditIcon: false,
  showInitialEditButton: false,
  title: null,
  titleIcon: null,
  warning: ''
}

const iconMap = {
  chevron: ChevronRight,
  edit: Edit,
  reload: Reload
}

const log = logger({ enabled: false, tags: ['DetailSection'] })

const DetailSection = ({
  children,
  className,
  count,
  description,
  editHref,
  editIcon,
  hideContent,
  initialEditButtonText,
  showClearIcon,
  showCopyIcon,
  showEditIcon,
  showInitialEditButton,
  title,
  titleIcon,
  warning,
  onClearClick,
  onCopyClick,
  onEditClick,
  onInitialEditClick
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const editButton = useMemo(() => {
    const DelegateIcon = iconMap[editIcon]
    const iconElement = (<DelegateIcon height='20' width='20' />)
    return (
      <Button
        className='p-1 h-[32px]'
        href={editHref}
        icon={iconElement}
        size='none'
        theme='none'
        variant='none'
        onClick={onEditClick}
      >
        Edit
        {' '}
        {title}
      </Button>
    )
  }, [editHref, editIcon, onEditClick, title])
  const clearButton = useMemo(() => {
    return (
      <Button
        className='p-1 h-[32px]'
        icon={<Trash height='20' width='20' />}
        size='none'
        theme='none'
        variant='none'
        onClick={onClearClick}
      >
        Clear
        {' '}
        {title}
      </Button>
    )
  }, [onClearClick, title])
  const copyButton = useMemo(() => {
    return (
      <Button
        className='p-1 h-[32px]'
        icon={<Clipboard height='20' width='20' />}
        size='none'
        theme='none'
        variant='none'
        onClick={onCopyClick}
      >
        Copy
        {' '}
        {title}
      </Button>
    )
  }, [onCopyClick, title])
  const initialEditButton = useMemo(() => {
    return (
      <div className='flex flex-row justify-center w-full'>
        <Button
          className='text-neutral-900 h-auto py-0'
          size='sm'
          theme='primary'
          variant='text'
          onClick={onInitialEditClick}
        >
          {initialEditButtonText}
        </Button>
      </div>
    )
  }, [initialEditButtonText, onInitialEditClick])

  const computedClassName = classNames('mb-4', className)

  return (
    <div className={computedClassName}>
      <div className='flex flex-row flex-nowrap mb-3'>
        <div className='mr-auto'>
          <div className='flex flex-row items-center mb-1'>
            {titleIcon
              ? (<span className='shrink-0 grow-0 h-[1rem] w-[1rem] mr-2'>{titleIcon}</span>)
              : null}
            <div className='headline-2xs text-black'>{title}</div>
            <TitleCountPill value={count} />
          </div>
          {warning ? <WarningDetailSection text={warning} /> : null}
          <div className='paragraph-sm'>{description}</div>
        </div>
        <div className='flex flex-row flex-nowrap gap-1 ml-1'>
          {showCopyIcon ? copyButton : null}
          {showClearIcon ? clearButton : null}
          {showEditIcon ? editButton : null}
        </div>
      </div>
      {hideContent
        ? null
        : (
          <div className='border rounded-lg border-neutral-300 p-3 label-medium text-neutral-900 select-text max-w-full break-words overflow-auto'>
            {showInitialEditButton ? initialEditButton : children}
          </div>
          )}
    </div>
  )
}

DetailSection.displayName = 'DetailSection'
DetailSection.propTypes = propTypes
DetailSection.defaultProps = defaultProps

export default DetailSection
