import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { viewsSearch } from '../../services/landingPage'
import List from '../list/List'

import LandingPageViewListItemContent from './LandingPageViewListItemContent'

const propTypes = {
  landingPageId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['LandingPageViewList'] })

const LandingPageViewList = forwardRef(({ landingPageId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [landingPageId] })

  const searchService = (body, background) => {
    return viewsSearch(landingPageId, body, background)
  }

  return (
    <List
      ref={ref}
      ListItemContent={LandingPageViewListItemContent}
      resultsKey='landingPageViews'
      searchService={searchService}
      type='list'
      {...rest}
    />
  )
})

LandingPageViewList.displayName = 'LandingPageViewList'
LandingPageViewList.propTypes = propTypes
LandingPageViewList.defaultProps = defaultProps

export default LandingPageViewList
