import { CapacitorCookies } from '@capacitor/core'
import { Http } from '@capacitor-community/http'
import { chain } from 'lodash'

import { getPreference, removePreference, setPreference } from '../lib/preferences'

const pbAppSessionCookieJar = {
  async capture (baseUrl) {
    const { cookies } = await Http.getCookies({ url: baseUrl }) || {}
    const previousAppSessionId = await getPreference('pbSessionId')

    const { value: newAppSessionId } = chain(cookies)
      .filter({ key: 'connect.sid' })
      .map(({ value }) => ({ value: decodeURIComponent(value) }))
      .reject({ value: previousAppSessionId })
      .first()
      .value() || {}

    if (newAppSessionId) {
      if (previousAppSessionId && (previousAppSessionId !== newAppSessionId)) {
        await CapacitorCookies.clearCookies({ url: baseUrl })
        await CapacitorCookies.setCookie({ url: baseUrl, key: 'connect.sid', value: newAppSessionId })
      }
      await setPreference('pbSessionId', newAppSessionId)
    }
  },
  async restore (baseUrl) {
    const appSessionId = await getPreference('pbSessionId')
    if (appSessionId) {
      await CapacitorCookies.setCookie({ url: baseUrl, key: 'connect.sid', value: appSessionId })
    }
  },
  async reset (baseUrl) {
    await removePreference('pbSessionId')
    await CapacitorCookies.deleteCookie({ url: baseUrl, key: 'connect.sid' })
  }
}

export default pbAppSessionCookieJar
