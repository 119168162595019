import * as React from "react";
import { memo } from "react";
const SvgPast = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Past_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 .875c-.621 0-1.125.504-1.125 1.125v3.636a1.125 1.125 0 0 0 2.25 0v-2.44A8.87 8.87 0 0 1 20.875 12 8.87 8.87 0 0 1 12 20.875 8.87 8.87 0 0 1 3.125 12c0-1.455.347-2.825.967-4.032A1.125 1.125 0 0 0 2.09 6.941 11.048 11.048 0 0 0 .875 12 11.12 11.12 0 0 0 12 23.125 11.12 11.12 0 0 0 23.125 12 11.12 11.12 0 0 0 12 .875ZM9.16 7.568A1.125 1.125 0 1 0 7.567 9.16l3.637 3.637a1.125 1.125 0 1 0 1.59-1.591L9.16 7.568Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Past_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgPast);
export default Memo;
