import * as React from "react";
import { memo } from "react";
const SvgSocial = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM9 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM5 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm11 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM5 21c-1.108 0-2 .892-2 2a1 1 0 1 1-2 0c0-2.212 1.788-4 4-4h2c2.212 0 4 1.788 4 4a1 1 0 1 1-2 0c0-1.108-.892-2-2-2H5Zm10 2c0-1.108.892-2 2-2h2c1.108 0 2 .892 2 2a1 1 0 1 0 2 0c0-2.212-1.788-4-4-4h-2c-2.212 0-4 1.788-4 4a1 1 0 1 0 2 0ZM9.502 9.67C9.873 9.259 10.408 9 11 9h2c.592 0 1.127.259 1.497.67a1 1 0 0 0 1.486-1.34A4.013 4.013 0 0 0 13 7h-2a4.013 4.013 0 0 0-2.983 1.33 1 1 0 1 0 1.485 1.34Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSocial);
export default Memo;
