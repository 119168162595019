import * as React from 'react'
import { memo } from 'react'
const SvgDynamicField = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#D0FAE4" rx={4} />
        <path
            fill="#05976A"
            d="M9.197 20.105c.356 0 .78-.143 1.227-.603l6.81-7.111c.5-.527.766-1.054.766-1.498 0-.728-.629-1.246-1.735-1.246h-1.598l2.704-4.602h-6.848l-3.235 7.062a2.037 2.037 0 0 0-.197.861c0 .929.667 1.698 1.636 1.698h1.205l-1.576 3.205c-.174.351-.204.703-.204.987 0 .778.446 1.247 1.045 1.247Zm.182-1.347c-.106 0-.06-.21.023-.394l2.234-4.952H8.727c-.31 0-.492-.184-.492-.444 0-.092.015-.192.068-.301L11.227 6.3h4.03l-2.59 4.602h3.598c.258 0 .386.092.386.226 0 .092-.06.2-.181.334l-6.803 7.111c-.122.134-.228.185-.288.185Z"
        />
    </svg>
)
const Memo = memo(SvgDynamicField)
export default Memo
