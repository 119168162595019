import { useCallback, useRef, useState } from 'react'

import AttachmentList from '../../components/attachments/AttachmentList'
import FolderDetailHeaderMoreMenu from '../../components/folders/detail/FolderDetailHeaderMoreMenu'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { useApp } from '../../contexts/AppContext'
import { useAdditionalContentHandlers } from '../../hooks/useAdditionalContentHandlers'
import useCurrentFolder from '../../hooks/useCurrentFolder'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import FolderEmptyDetailContent from './detail/FolderEmptyDetailContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AttachmentsFolderDetail'] })

const AttachmentsFolderDetail = () => {
  const { folderId, loading, folder, setFolder } = useCurrentFolder()

  useLogger({ log, lifecycle: false, tags: [folderId] })

  const listRef = useRef()

  const { isRoomForTwoPanels } = useApp()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  const handleFolderUpdate = useCallback((updateReply) => {
    setFolder(updateReply)
  }, [setFolder])

  if (loading || !folder) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<FolderDetailHeaderMoreMenu folder={folder} listRef={listRef} onUpdate={handleFolderUpdate} />}
        start={<PanelHeaderBackButton />}
        subtitle={isRoomForTwoPanels ? null : folder?.name}
        title={isRoomForTwoPanels ? folder?.name : 'Attachments'}
      />
      <PanelContent>
        <AttachmentList
          ref={listRef}
          additionalHandlers={useAdditionalContentHandlers}
          EmptyListContent={FolderEmptyDetailContent}
          queryOverrides={{ folderId }}
          type='nav'
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

AttachmentsFolderDetail.displayName = 'AttachmentsFolderDetail'
AttachmentsFolderDetail.propTypes = propTypes
AttachmentsFolderDetail.defaultProps = defaultProps

export default AttachmentsFolderDetail
