import { useMemo } from 'react'

import FormControl from '../../../components/forms/FormControl'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const log = logger({ enabled: true, tags: ['Style Guide', 'TextSamples'] })

function TextSamples () {
  useLogger({ log, lifecycle: false, tags: [] })

  const controls = useMemo(() => ([
    {
      type: 'textsample',
      name: 'textsample1',
      label: 'Text Sample',
      placeholder: 'Choose a preset or provide your own text',
      hint: 'This is a hint',
      defaultValue: null,
      options: [
        {
          label: 'Provide your own text',
          value: ''
        },
        {
          label: 'Sample 1',
          value: 'Sample 1 lorem ipsum dolor sit amet'
        },
        {
          label: 'Sample 2',
          value: 'Sample 2 lorem ipsum dolor sit amet'
        },
        {
          label: 'Sample 3',
          value: 'Sample 3 lorem ipsum dolor sit amet'
        }
      ]
    }
  ]), [])

  const output = controls.map((options) => {
    return <FormControl key={options.name} {...options} />
  })
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Text Samples' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default TextSamples
