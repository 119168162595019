import * as React from 'react'
import { memo } from 'react'
const SvgCampaign = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#CFFBFE" rx={4} />
        <path
            fill="#0891B3"
            fillRule="evenodd"
            d="M20 5.455a.727.727 0 0 0-.936-.697L5.454 8.84V8.72A.727.727 0 0 0 4 8.72V13.811a.727.727 0 1 0 1.455 0v-.059l1.881.659-.32.911a2.188 2.188 0 0 0 1.42 2.76l3.47 1.092a2.188 2.188 0 0 0 2.736-1.425l.228-.701 4.162 1.457a.727.727 0 0 0 .968-.687V5.455Zm-6.504 11.112L8.71 14.89l-.317.9a.734.734 0 0 0 .48.904l3.47 1.091c.38.12.794-.096.913-.477l.002-.006.24-.736ZM5.455 12.21l13.09 4.582V6.432l-13.09 3.927v1.852Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgCampaign)
export default Memo
