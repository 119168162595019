import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  mediaUrl: PropType.string
}

const defaultProps = {
  mediaUrl: null
}

const classNamesByDirection = {
  in: [
    'self-start',
    'bg-neutral-100',
    'text-neutral-900'
  ],
  out: [
    'self-end',
    'bg-indigo-100',
    'text-indigo-900'
  ]
}

const log = logger({ enabled: false, tags: ['MessageThreadItemContentMediaLink'] })

const MessageThreadItemContentMediaLink = ({ direction, mediaUrl }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const mediaName = useMemo(() => (mediaUrl || '').split('/')?.at(-1), [mediaUrl])

  if (!mediaUrl) { return }

  const wrapperClassName = classNames(
    'flex flex-col max-w-full mb-4',
    'whitespace-pre-wrap break-words p-3 rounded-lg',
    classNamesByDirection[direction]
  )

  return (
    <div className={wrapperClassName}>
      <div className='label-sm-strong'>Media</div>
      <a
        className='link'
        href={mediaUrl}
        rel='noreferrer'
        target='_blank'
      >
        {mediaName}
      </a>
    </div>
  )
}

MessageThreadItemContentMediaLink.displayName = 'MessageThreadItemContentMediaLink'
MessageThreadItemContentMediaLink.propTypes = propTypes
MessageThreadItemContentMediaLink.defaultProps = defaultProps

export default MessageThreadItemContentMediaLink
