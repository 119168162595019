import * as React from "react";
import { memo } from "react";
const SvgCheckMini = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.582 9.727 16.355 7.5l-5.763 5.763-2.89-2.89L5.475 12.6l5.114 5.114.035-.035.003.003 7.955-7.955Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCheckMini);
export default Memo;
