import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import CampaignContactsList from '../screens/campaigns/CampaignContactsList'
import CampaignEventContactsList from '../screens/campaigns/CampaignEventContactsList'
import CampaignEventsDetail from '../screens/campaigns/CampaignEventsDetail'
import CampaignEventsList from '../screens/campaigns/CampaignEventsList'
import CampaignsDetail from '../screens/campaigns/CampaignsDetail'
import CampaignsFolderDetail from '../screens/campaigns/CampaignsFolderDetail'

const campaignRoutes = [
  {
    path: ':campaignId',
    element: <CampaignsDetail />
  },
  {
    path: ':campaignId/contacts',
    element: <CampaignContactsList />
  },
  {
    path: ':campaignId/events',
    element: <CampaignEventsList />
  },
  {
    path: ':campaignId/events/:campaignEventId',
    element: <CampaignEventsDetail />
  },
  {
    path: ':campaignId/events/:campaignEventId/queued',
    element: <CampaignEventContactsList type='queued' />
  },
  {
    path: ':campaignId/events/:campaignEventId/processed',
    element: <CampaignEventContactsList type='processed' />
  }
]

const routes = [
  ...campaignRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<CampaignsFolderDetail />} />,
    children: [...campaignRoutes]
  }
]

export default routes
