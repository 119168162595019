import React from 'react'

import PanelNotFound from '../../components/panels/PanelNotFound'
import PanelUnderConstruction from '../../components/panels/PanelUnderConstruction'

const routes = [
  {
    path: ':billingAccountId',
    element: <PanelUnderConstruction title='Billing Account Detail' />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
