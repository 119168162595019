import React from 'react'

import NewScheduledBroadcast from '../screens/scheduled-broadcasts/NewScheduledBroadcast'
import ScheduledBroadcastContactsByStateList from '../screens/scheduled-broadcasts/ScheduledBroadcastContactsByStateList'
import ScheduledBroadcastContactsList from '../screens/scheduled-broadcasts/ScheduledBroadcastContactsList'
import ScheduledBroadcastsDetail from '../screens/scheduled-broadcasts/ScheduledBroadcastsDetail'

const routes = [
  {
    path: 'new',
    element: <NewScheduledBroadcast />
  },
  {
    path: ':scheduledBroadcastId',
    element: <ScheduledBroadcastsDetail />
  },
  {
    path: ':scheduledBroadcastId/contacts',
    element: <ScheduledBroadcastContactsList />
  },
  {
    path: ':scheduledBroadcastId/contacts/sending',
    element: <ScheduledBroadcastContactsByStateList state='sending' />
  },
  {
    path: ':scheduledBroadcastId/contacts/sent',
    element: <ScheduledBroadcastContactsByStateList state='sent' />
  },
  {
    path: ':scheduledBroadcastId/contacts/unsent',
    element: <ScheduledBroadcastContactsByStateList state='unsent' />
  }

]

export default routes
