import { flip as flipMiddlware, FloatingFocusManager, FloatingNode, FloatingPortal, offset as offsetMiddleware, shift as shiftMiddleware, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions, useListNavigation, useRole, useTypeahead } from '@floating-ui/react'
import PropType from 'prop-types'
import { Children, cloneElement, forwardRef, isValidElement, useImperativeHandle, useMemo, useRef, useState } from 'react'

import useDismissOnResize from '../../hooks/useDismissOnResize'
import useKeyboardOnScreen from '../../hooks/useKeyboardOnScreen'
import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import Overlay from '../dialog/Overlay'

const propTypes = {
  children: PropType.node.isRequired,
  // https://floating-ui.com/docs/computePosition#placement
  placement: PropType.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ]).isRequired,
  trigger: PropType.node.isRequired, // trigger must be able to take a ref
  // https://floating-ui.com/docs/offset
  offset: PropType.oneOfType([
    PropType.number,
    PropType.func,
    PropType.shape({
      alignmentAxis: PropType.number,
      crossAxis: PropType.number,
      mainAxis: PropType.number
    })
  ])
}

const defaultProps = {
  offset: 0
}

const log = logger({ enabled: false, tags: ['ActionMenu'] })

const ActionMenu = forwardRef(({ children, placement, offset, trigger }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const itemsRef = useRef([])
  const itemsContentRef = useRef([])
  const keyboardOnScreen = useKeyboardOnScreen()
  const smallScreen = useSmallScreen()

  itemsContentRef.current = useMemo(() => Children.map(children, (child) => {
    return child?.type.displayName === 'ActionMenuItem' ? child.props.label : ''
  }), [children])

  const nodeId = useFloatingNodeId()

  const middleware = smallScreen
    ? []
    : [
        offsetMiddleware(offset), // https://floating-ui.com/docs/offset
        shiftMiddleware(), // https://floating-ui.com/docs/shift
        flipMiddlware() // https://floating-ui.com/docs/flip
      ]

  const { reference, floating, context, strategy, x, y } = useFloating({
    nodeId,
    placement,
    strategy: 'absolute',
    open: (open && !keyboardOnScreen),
    onOpenChange: setOpen,
    middleware
  })

  // Public facing API via ref.current
  useImperativeHandle(ref, () => ({
    close () { setOpen(false) },
    open () {
      // using setTimeout because we use `outsidePressEvent: 'click'`
      // opening by using the ref.current.open() from a 'click'
      // would result in immediately closing the dialog
      setTimeout(() => setOpen(true), 1)
    }
  }))

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismissOnResize(context, { enabled: true }),
    useDismiss(context, {
      referencePress: false,
      outsidePress: true,
      ancestorScroll: false,
      outsidePressEvent: smallScreen ? 'click' : 'pointerdown'
    }),
    useListNavigation(context, {
      listRef: itemsRef,
      activeIndex,
      onNavigate: setActiveIndex
    }),
    useTypeahead(context, {
      listRef: itemsContentRef,
      onMatch: (open && !keyboardOnScreen) ? setActiveIndex : undefined,
      activeIndex
    })
  ])

  const style = !smallScreen
    ? {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0
      }
    : {}

  const floatingClassNameBase = 'flex flex-col bg-white text-neutral-600 min-w-[200px] max-w-[375px] select-none'
  const floatingClassName = smallScreen
    ? `${floatingClassNameBase} min-w-[200px] w-full max-w-[375px] px-[10px] pt-[12px] pb-[40px] rounded-t-2xl`
    : `${floatingClassNameBase} border border-neutral-300 rounded-md px-[10px] py-[8px] drop-shadow z-50`

  const floatingProps = getFloatingProps({
    ref: floating,
    className: floatingClassName,
    style
  })

  const items = useMemo(() => Children.map(children, (child, index) => {
    if (!isValidElement(child)) { return }
    if (child.type.displayName === 'ActionMenuItem') {
      return cloneElement(child, getItemProps({
        ...child.props,
        tabIndex: -1,
        role: 'menuitem',
        ref (node) { itemsRef.current[index] = node },
        onClick (event) {
          setOpen(false)
          child.props?.onClick?.(event)
        }
      }))
    }
    return cloneElement(child)
  }), [children, getItemProps])

  const menu = (
    <div {...floatingProps}>
      {/* Need a way for screen reader users to dismiss this modal */}
      {smallScreen ? <button className='sr-only' onClick={() => setOpen(false)}>dismiss</button> : null}
      {items}
    </div>
  )

  const floatingMenu = smallScreen
    ? (
      <FloatingNode id={nodeId}>
        <FloatingPortal id='action-menu-portal'>
          <Overlay>
            <FloatingFocusManager context={context} modal>
              <div className='w-full h-full px-1 grid grid-cols-1 place-items-center place-content-end'>
                {menu}
              </div>
            </FloatingFocusManager>
          </Overlay>
        </FloatingPortal>
      </FloatingNode>
      )
    : (
      <FloatingNode id={nodeId}>
        <FloatingPortal id='action-menu-portal'>
          <FloatingFocusManager context={context} modal>
            {menu}
          </FloatingFocusManager>
        </FloatingPortal>
      </FloatingNode>
      )

  return (
    <>
      {cloneElement(trigger, getReferenceProps({ ref: reference, ...trigger.props }))}
      {(open && !keyboardOnScreen) ? floatingMenu : null}
    </>
  )
})

ActionMenu.displayName = 'ActionMenu'
ActionMenu.propTypes = propTypes
ActionMenu.defaultProps = defaultProps

export default ActionMenu
