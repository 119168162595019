import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useMemo, useState } from 'react'

import { useApp } from '../../contexts/AppContext'
import useLogger from '../../hooks/useLogger'
import isHrefExternal from '../../lib/isHrefExternal'
import logger from '../../lib/logger'

import ButtonIcon from './ButtonIcon'
import ButtonWrapper from './ButtonWrapper'

// will also pass through any other props to ButtonWrapper
// be careful not to override the built-in event handlers though
const propTypes = {
  children: PropType.node.isRequired,
  circle: PropType.bool,
  className: PropType.string,
  classNameStateOverrides: PropType.shape({
    active: PropType.string,
    default: PropType.string,
    disabled: PropType.string,
    hover: PropType.string
  }),
  disabled: PropType.bool,
  end: PropType.element,
  form: PropType.string,
  href: PropType.string,
  icon: PropType.element,
  size: PropType.oneOf(['lg', 'md', 'sm', 'none']),
  start: PropType.element,
  theme: PropType.oneOf(['primary', 'danger', 'neutral', 'none']),
  type: PropType.oneOf(['submit', 'reset', 'button']),
  variant: PropType.oneOf(['filled', 'outline', 'text', 'none']),
  onClick: PropType.func
}

const defaultProps = {
  circle: false,
  className: '',
  classNameStateOverrides: null,
  disabled: false,
  end: null,
  form: null,
  href: null,
  icon: null,
  onClick: null,
  size: 'lg',
  start: null,
  theme: 'primary',
  type: 'button',
  variant: 'filled'
}

const classNamesBySize = {
  lg: 'label-xl-strong py-[16px] px-[19.5px] rounded-md min-h-[60px]',
  md: 'label-md-strong py-[10px] px-[12px] rounded-md min-h-[40px]',
  sm: 'label-sm-strong py-[6px] px-[8px] rounded-md min-h-[32px]',
  smOnePanel: 'label-sm-strong py-[12px] px-[12px] rounded-md min-h-[32px]',
  none: ''
}

const disabledStyles = 'opacity-50 cursor-not-allowed'

const classNamesByVariantThemeState = {
  filled: {
    primary: {
      default: 'bg-action text-white',
      hover: 'bg-blue-700',
      active: 'bg-blue-500',
      disabled: disabledStyles
    },
    danger: {
      default: 'bg-red-600 text-white',
      hover: 'bg-red-700',
      active: 'bg-red-400',
      disabled: disabledStyles
    },
    neutral: {
      default: 'bg-neutral-100 text-primary',
      hover: 'bg-neutral-200',
      active: 'bg-neutral-50',
      disabled: disabledStyles
    }
  },
  outline: {
    primary: {
      default: 'border border-1 border-action background-white text-primary',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    },
    danger: {
      default: 'border border-1 border-danger background-white text-danger',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    },
    neutral: {
      default: 'border border-1 border-neutral-400 background-white text-neutral-400',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    }
  },
  text: {
    primary: {
      default: 'background-white text-primary',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    },
    danger: {
      default: 'background-white text-danger',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    },
    none: {
      default: '',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    }
  },
  none: {
    none: {
      default: '',
      hover: 'bg-blue-50',
      active: 'bg-blue-50',
      disabled: disabledStyles
    }
  }
}

const circleClassNamesBySize = {
  lg: '!rounded-3xl max-w-[60px] max-h-[60px]',
  md: '!rounded-2xl !px-[11px] max-w-[40px] max-h-[40px]',
  sm: '!rounded-xl max-w-[32px] max-h-[32px]'
}

const log = logger({ enabled: false, tags: ['Button'] })

const Button = forwardRef(({
  children,
  circle,
  className,
  classNameStateOverrides,
  disabled,
  end,
  form,
  href,
  icon,
  size,
  start,
  theme,
  type,
  variant,
  onClick,
  ...rest
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [size, variant, theme] })

  const [state, setState] = useState('default')
  const { isRoomForTwoPanels } = useApp()

  type = href ? null : type
  const external = href ? isHrefExternal(href) : false
  const target = external ? '_blank' : null
  const rel = external ? 'noopener' : null

  const handleHoverAndFocusIn = (event) => {
    if (state !== 'active') {
      setState('hover')
    }
  }
  const handleHoverAndFocusOut = (event) => setState('default')
  const handleClickDown = (event) => setState('active')
  const handleClickUp = (event) => setState('default')

  const computedClassNames = useMemo(() => {
    const mergedClassNamesByState = { ...classNamesByVariantThemeState[variant][theme], ...classNameStateOverrides }
    const baseClasses = 'flex flex-nowrap justify-center items-center'
    const sizeClasses = classNamesBySize[size === 'sm' && !isRoomForTwoPanels ? 'smOnePanel' : size]
    const themeClasses = mergedClassNamesByState.default
    const stateClasses = state !== 'default' && !disabled ? mergedClassNamesByState[state] : ''
    const disabledClasses = disabled ? mergedClassNamesByState.disabled : ''
    const circleClasses = circle ? circleClassNamesBySize[size] : ''

    return classNames(baseClasses, sizeClasses, themeClasses, stateClasses, circleClasses, disabledClasses, className)
  }, [circle, className, classNameStateOverrides, disabled, isRoomForTwoPanels, size, state, theme, variant])

  return (
    <ButtonWrapper
      ref={ref}
      className={computedClassNames}
      disabled={disabled}
      external={external}
      form={form}
      href={href}
      rel={rel}
      target={target}
      type={type}
      onBlur={handleHoverAndFocusOut}
      onClick={!disabled ? onClick : null}
      onFocus={handleHoverAndFocusIn}
      onMouseDown={handleClickDown}
      onMouseEnter={handleHoverAndFocusIn}
      onMouseLeave={handleHoverAndFocusOut}
      onMouseUp={handleClickUp}
      onTouchEnd={handleClickUp}
      onTouchStart={handleClickDown}
      {...rest}
    >
      {start ? <ButtonIcon icon={start} position='start' size={size} /> : null}
      {icon ? <ButtonIcon icon={icon} size={size} /> : null}
      {icon ? <span className='sr-only'>{children}</span> : children}
      {end ? <ButtonIcon icon={end} position='end' size={size} /> : null}
    </ButtonWrapper>
  )
})

Button.displayName = 'Button'
Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
