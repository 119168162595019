import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { importResultItem } from '../../../prop-types/shapes/contactImportResults'
import { CircleCheck, Error, Info } from '../../../svg/icons'

const propTypes = {
  resultItem: PropType.shape(importResultItem).isRequired,
  resultType: PropType.oneOf([
    'created',
    'exists',
    'failed'
  ]).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ImportResultItem'] })

const ImportResultItem = ({ resultItem, resultType }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <tr className='first:border-t border-b border-neutral-200 odd:bg-neutral-100 label-xs'>
      <td className='py-2'>{`${resultItem.firstName || ''} ${resultItem.lastName || ''}`}</td>
      <td className='p-2'>{resultItem.phoneNumber}</td>
      <td className='py-2 flex flex-row gap-1'>
        {resultType === 'created' ? <CircleCheck className='w-4' /> : null}
        {resultType === 'exists' ? <Info className='w-4' /> : null}
        {resultType === 'failed' ? <Error className='w-4' /> : null}
        {resultItem.error || 'Contact added.'}
      </td>
    </tr>
  )
}

ImportResultItem.displayName = 'ImportResultItem'
ImportResultItem.propTypes = propTypes
ImportResultItem.defaultProps = defaultProps

export default ImportResultItem
