import { map } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { pluck } from 'underscore'

import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import SpaceList from '../../../components/spaces/SpaceList'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'
import { addAllInQuery, addSelected } from '../../../services/keyword-join-space'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: false, tags: ['JoinSpacesDetail'] })

const JoinSpacesDetail = ({ keyword, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [keyword?._id] })

  const addJoinSpacesDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Spaces added')
    onChange?.(reply)
  }, [onChange])
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })

  const handleAddFirst = useCallback(() => { addJoinSpacesDialogRef.current.open() }, [])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ keywordId: keyword._id, spaces: map(selected, '_id') })
  }, [addSelectedCall, keyword._id])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ keywordId: keyword._id, ...query })
  }, [addAllInQueryCall, keyword._id])

  const names = pluck(keyword.spaces, 'name').join(', ')
  const count = keyword.spaces.length
  return (
    <>
      <DetailSection
        count={count}
        description='If the sender is a Project Broadcast user, the system will auto add the sender to the following spaces. If not, the system will send a text message with instructions on how to sign up for free. After their account is created the system will add them to the following spaces.'
        editHref='join-spaces'
        editIcon='chevron'
        initialEditButtonText='Add Spaces to Join'
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title='Join Spaces'
        onInitialEditClick={handleAddFirst}
      >
        {names}
      </DetailSection>
      <ListDialog
        ref={addJoinSpacesDialogRef}
        list={SpaceList}
        queryOverrides={{ owner: true }}
        subtitle={keyword?.word}
        title='Add Spaces To Join'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </>
  )
}

JoinSpacesDetail.displayName = 'JoinSpacesDetail'
JoinSpacesDetail.propTypes = propTypes
JoinSpacesDetail.defaultProps = defaultProps

export default JoinSpacesDetail
