import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import bus from '../../../../lib/bus'
import logger from '../../../../lib/logger'
import { markAsEnabled as removeService } from '../../../../services/admin'
import { success } from '../../../banners/Banner'
import ConfirmDialog from '../../../dialog/ConfirmDialog'
import ListDialog from '../../../dialog/ListDialog'
import SpammersList from '../SpammersList'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['RemoveAction'] })

const RemoveAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()
  const confirmRef = useRef()
  const [selectedSpammer, setSelectedSpammer] = useState()
  const { call: removeCall } = useService(removeService, {
    onReplyOk: useCallback(() => {
      success(`${selectedSpammer.formattedName} was removed from spammer list.`)
      bus.emit('userRemoved', selectedSpammer)
      onSuccess?.()
    }, [selectedSpammer, onSuccess])
  })

  useImperativeHandle(ref, () => ({
    activate () { listRef.current.open() }
  }), [])

  const handleUserSelected = useCallback((data) => {
    setSelectedSpammer(data)
    confirmRef.current.open()
  }, [])

  const handleConfirm = useCallback(() => {
    removeCall(selectedSpammer?._id)
  }, [removeCall, selectedSpammer?._id])

  return (
    <>
      <ListDialog
        ref={listRef}
        list={SpammersList}
        multiple={false}
        title='Remove Spammer'
        type='select'
        onSubmitSelected={handleUserSelected}
      />
      <ConfirmDialog
        ref={confirmRef}
        description={`Remove ${selectedSpammer?.formattedName} from spammer list. Are you sure?`}
        title='Re-enable user?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

RemoveAction.displayName = 'RemoveAction'
RemoveAction.propTypes = propTypes
RemoveAction.defaultProps = defaultProps

export default RemoveAction
