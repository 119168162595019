import * as React from "react";
import { memo } from "react";
const SvgBlock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Block_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2c2.401 0 4.605.846 6.329 2.257L4.257 18.33A9.958 9.958 0 0 1 2 12Zm3.671 7.743A9.96 9.96 0 0 0 12 22c5.523 0 10-4.477 10-10a9.96 9.96 0 0 0-2.257-6.329L5.671 19.743ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Block_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBlock);
export default Memo;
