import * as React from "react";
import { memo } from "react";
const SvgMy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.469 8.727h2.457l1.69 4.12h.086l1.69-4.12h2.457V16h-1.931v-4.204h-.057l-1.62 4.147H8.077l-1.62-4.176h-.056V16H4.469V8.727ZM13.487 8.727h2.202l1.42 2.955h.057l1.42-2.955h2.202l-2.67 4.986V16h-1.96v-2.287l-2.671-4.986Z"
    />
  </svg>
);
const Memo = memo(SvgMy);
export default Memo;
