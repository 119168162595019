import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { messageStrengthBadgeColor } from '../../../helpers/messageQuality'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageQualityShape from '../../../prop-types/shapes/messageQuality'
import { CheckMini, CloseMini, MinusMini } from '../../../svg/icons'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  className: PropType.string
}

const defaultProps = {
  className: null
}

const log = logger({ enabled: false, tags: ['MessageQualityBadge'] })

const MessageQualityBadge = ({ messageQuality, className }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = useMemo(() => classNames(
    'w-3 h-3 box-content rounded-full',
    'border-2 border-white text-white',
    messageStrengthBadgeColor(messageQuality.strengthRating),
    className
  ), [className, messageQuality])

  const iconIndicator = useMemo(() => {
    switch (messageQuality.strengthRating) {
      case 'weak':
        return <CloseMini />
      case 'good':
      case 'average':
        return <MinusMini />
      case 'strong':
        return <CheckMini />
    }
  }, [messageQuality])

  return (
    <div className={computedClassName}>
      {iconIndicator}
    </div>
  )
}

MessageQualityBadge.displayName = 'MessageQualityBadge'
MessageQualityBadge.propTypes = propTypes
MessageQualityBadge.defaultProps = defaultProps

export default MessageQualityBadge
