import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import bus from '../../../lib/bus'
import { stackKeyFromContentType } from '../../../lib/folder'
import logger from '../../../lib/logger'
import folderShape from '../../../prop-types/shapes/folder'
import { Plus, Reload } from '../../../svg/icons'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import PanelHeaderButton from '../../panels/panel-header/PanelHeaderButton'
import AddToFolderAction from '../actions/AddToFolderAction'
import CreateFromFolderAction from '../actions/CreateFromFolderAction'
import DeleteAction from '../actions/DeleteAction'
import RemoveFromFolderAction from '../actions/RemoveFromFolderAction'
import UpdateFolderNameAction from '../actions/UpdateFolderNameAction'

const propTypes = {
  folder: PropType.shape(folderShape).isRequired,
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired,
  onUpdate: PropType.func
}

const defaultProps = {
  onUpdate: null
}

const log = logger({ enabled: false, tags: ['FolderDetailHeaderMoreMenu'] })

const FolderDetailHeaderMoreMenu = ({
  folder,
  listRef,
  onUpdate
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const addToFolderActionRef = useRef()
  const removeItemsActionRef = useRef()
  const updateNameActionRef = useRef()
  const createContentActionRef = useRef()
  const deleteActionRef = useRef()

  const handleAddToFolderAction = useCallback(() => addToFolderActionRef.current.activate(), [addToFolderActionRef])
  const handleRemoveItemsAction = useCallback(() => removeItemsActionRef.current.activate(), [removeItemsActionRef])
  const handleUpdateNameAction = useCallback(() => updateNameActionRef.current.activate(), [updateNameActionRef])
  const handleCreateContentAction = useCallback(() => createContentActionRef.current.activate(), [])
  const handleDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])
  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])

  const handleDeleteSuccess = useCallback(() => {
    const stackKey = stackKeyFromContentType(folder.contentType)
    bus.emit('folderDeleted', folder)
    navigateInStack(`/${stackKey}`, { replace: true })
  }, [folder, navigateInStack])

  return !!folder && (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon={<Plus />} title='Create new content' onClick={handleCreateContentAction} />
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Edit Name' onClick={handleUpdateNameAction} />
        <ActionMenuItem label='Move to Folder' onClick={handleAddToFolderAction} />
        <ActionMenuItem label='Remove from Folder' onClick={handleRemoveItemsAction} />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Delete Folder' onClick={handleDeleteAction} />
      </ActionMenu>
      <CreateFromFolderAction ref={createContentActionRef} contentType={folder.contentType} />
      <AddToFolderAction ref={addToFolderActionRef} contentType={folder.contentType} />
      <DeleteAction ref={deleteActionRef} folder={folder} onSuccess={handleDeleteSuccess} />
      <RemoveFromFolderAction ref={removeItemsActionRef} contentType={folder.contentType} />
      <UpdateFolderNameAction
        ref={updateNameActionRef}
        contentType={folder.contentType}
        currentName={folder.name}
        onSuccess={onUpdate}
      />
    </>
  )
}

FolderDetailHeaderMoreMenu.displayName = 'FolderDetailHeaderMoreMenu'
FolderDetailHeaderMoreMenu.propTypes = propTypes
FolderDetailHeaderMoreMenu.defaultProps = defaultProps

export default FolderDetailHeaderMoreMenu
