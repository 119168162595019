import mitt from 'mitt'

/*
  Known global events:
    - contactArchived
    - contactDeleted
    - contactUpdated

    - nativeUnsupportedAppUrl
*/

const bus = mitt()

export default bus
