import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Pluralize from '../../components/Pluralize'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Pluralization'] })

const Pluralization = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const variations = [
    {
      title: 'basic singular',
      props: {
        count: 1,
        singular: 'cup',
        includeCount: true
      }
    },
    {
      title: 'basic plural',
      props: {
        count: 2,
        singular: 'cup',
        includeCount: true
      }
    },
    {
      title: 'specify plural',
      props: {
        count: 2,
        singular: 'goose',
        plural: 'geese',
        includeCount: true
      }
    },
    {
      title: 'specify zero and no count output',
      props: {
        count: 0,
        singular: 'cup',
        zero: 'None'
      }
    }
  ]

  const output = variations.map(({ title, props }) => {
    return (
      <div key={title} className='m-2 mb-6'>
        <h2 className='headline-xs mb-2'>{title}</h2>
        <Pluralize {...props} />
      </div>
    )
  })

  return (
    <Panel>
      <PanelHeader title='Pluralize' />
      <PanelContent>
        <div className='p-2'>{output}</div>
      </PanelContent>
    </Panel>
  )
}

Pluralization.displayName = 'Pluralization'
Pluralization.propTypes = propTypes
Pluralization.defaultProps = defaultProps

export default Pluralization
