import { useCallback, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { referralCode as setUserReferralCode } from '../../../services/admin'

import validationSuite from './ReferralCode.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ReferralCode'] })

const ReferralCode = () => {
  const formRef = useRef()
  const { userId, loading, user } = useSelectedUser()
  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback(() => {
    notify('Referral code is applied')
  }, [])
  const { call: referralCode } = useService(setUserReferralCode, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    referralCode({ referralCode: data.referralCode.toLowerCase() }, user._id)
    formRef.current.reset()
  }, [user, referralCode])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='referralCodeForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Apply Referral Code'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <Form
          ref={formRef}
          defaultValues={{
            referralCode: ''
          }}
          formControls={[
            {
              type: 'text',
              name: 'referralCode',
              label: 'Referral Code'
            }
          ]}
          id='referralCodeForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='referralCodeForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ReferralCode.displayName = 'ReferralCode'
ReferralCode.propTypes = propTypes
ReferralCode.defaultProps = defaultProps

export default ReferralCode
