import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import Button from './Button'

const propTypes = {
  selected: PropType.bool
}

const defaultProps = {
  selected: false
}

const TabButton = forwardRef(({ selected, ...rest }, ref) => {
  const className = classNames('grow flex-1 pt-2 pb-1 border-b-4', {
    'border-primary': selected,
    'border-transparent': !selected
  })

  return (
    <Button
      ref={ref}
      className={className}
      size='none'
      theme='none'
      variant='none'
      {...rest}
    />
  )
})

TabButton.displayName = 'TabButton'
TabButton.propTypes = propTypes
TabButton.defaultProps = defaultProps

export default TabButton
