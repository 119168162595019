import * as React from "react";
import { memo } from "react";
const SvgBookmark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 4c0-1.652 1.348-3 3-3h12c1.652 0 3 1.348 3 3v18a1 1 0 0 1-1.447.894L12 19.118l-7.553 3.776A1 1 0 0 1 3 22V4Zm3-1c-.548 0-1 .452-1 1v16.382l6.553-3.276a1 1 0 0 1 .894 0L19 20.382V4c0-.548-.452-1-1-1H6Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBookmark);
export default Memo;
