import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import DetailSectionMoreButton from '../../../components/detail-sections/DetailSectionMoreButton'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import ListDialog from '../../../components/dialog/ListDialog'
import TagCreateAction from '../../../components/tags/actions/CreateAction'
import TagList from '../../../components/tags/TagList'
import TagListEmptySearchContent from '../../../components/tags/TagListEmptySearchResultsContent'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { formatName } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import campaignEvent from '../../../prop-types/shapes/campaignEvent'
import { applyTagToContacts } from '../../../services/tag'

const propTypes = {
  campaignEvent: PropType.shape(campaignEvent).isRequired,
  type: PropType.oneOf(['queued', 'processed']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EventContactsDetail'] })

const EventContactsDetail = ({ campaignEvent, type }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [selectedTag, setSelectedTag] = useState(null)

  const applyTagDialogRef = useRef()
  const tagCreateActionRef = useRef()
  const confirmApplyTagDialogRef = useRef()

  const { count, value } = useMemo(() => {
    const contacts = campaignEvent[type === 'queued' ? 'contactsScheduled' : 'contactsSent']
    return {
      count: contacts.length,
      value: contacts.subset.map(formatName).join(', ')
    }
  }, [campaignEvent, type])

  const handleApplyTagToContactsReplyOk = useCallback(() => { success('Tag applied') }, [])
  const { call: applyTagToContactsCall } = useService(applyTagToContacts, { onReplyOk: handleApplyTagToContactsReplyOk })

  const handleApplyExistingTag = useCallback(() => { applyTagDialogRef.current.open() }, [])
  const handleApplyNewTag = useCallback(() => { tagCreateActionRef.current.activate() }, [])

  const handleSetSelectedTag = useCallback((tag) => {
    setSelectedTag(tag)
    confirmApplyTagDialogRef.current.open()
  }, [])

  const handleApplySelectedTag = useCallback(() => {
    applyTagToContactsCall({ tagName: selectedTag?.name, campaignId: campaignEvent.campaign })
  }, [applyTagToContactsCall, selectedTag?.name, campaignEvent])

  const addMissingTag = useCallback((newTagName) => {
    handleSetSelectedTag({ name: newTagName })
  }, [handleSetSelectedTag])

  return (
    <>
      <DetailSection
        count={count}
        description={type === 'queued'
          ? 'Contacts who are waiting to be processed for this campaign message.'
          : 'Contacts who have been processed for this campaign message.'}
        editHref={type}
        editIcon='chevron'
        showEditIcon={count > 0}
        title={upperFirst(type)}
      >
        {value}
        <div className='grid place-content-center'>
          <ActionMenu placement='bottom-start' trigger={<DetailSectionMoreButton />}>
            <ActionMenuItem label='Apply Existing Tag' onClick={handleApplyExistingTag} />
            <ActionMenuItem label='Apply New Tag' onClick={handleApplyNewTag} />
          </ActionMenu>
          <TagCreateAction ref={tagCreateActionRef} mode='tag-name-only' onSuccess={handleSetSelectedTag} />
        </div>
      </DetailSection>
      <ListDialog
        ref={applyTagDialogRef}
        additionalContext={{ addMissingTag, contactCount: count }}
        EmptySearchResultsListContent={TagListEmptySearchContent}
        list={TagList}
        multiple={false}
        subtitle={campaignEvent?.name}
        title='Apply Tag'
        type='add'
        search
        onSubmitSelected={handleSetSelectedTag}
      />
      <ConfirmDialog
        ref={confirmApplyTagDialogRef}
        description={`Are you sure you want to apply the tag ${selectedTag?.name} to ${count} contact(s)?`}
        title='Apply Tag?'
        onConfirm={handleApplySelectedTag}
      />
    </>
  )
}

EventContactsDetail.displayName = 'EventContactsDetail'
EventContactsDetail.propTypes = propTypes
EventContactsDetail.defaultProps = defaultProps

export default EventContactsDetail
