import { Lock } from '../../../svg/icons'

function SecureCheckout () {
  return (
    <p className='paragraph-sm mb-4 flex flex-row items-center gap-1 justify-center'>
      <Lock className='h-3' />
      Secure Checkout
    </p>
  )
}

export default SecureCheckout
