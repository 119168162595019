import { useCallback, useState } from 'react'

import List from '../../components/list/List'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentAttachment from '../../hooks/useCurrentAttachment'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/attachment-download'

import DownloadListItemContent from './downloads/DownloadListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AttachmentDownloads'] })

const AttachmentDownloads = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { attachmentId, attachment, loading } = useCurrentAttachment()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !attachment) { return <PanelLoading subtitle=' ' title='Downloads' /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        start={<PanelHeaderBackButton />}
        subtitle={attachment.name}
        title='Downloads'
      />
      <PanelContent>
        <List
          ListItemContent={DownloadListItemContent}
          queryOverrides={{ attachmentId }}
          resultsKey='attachmentDownloads'
          searchService={searchService}
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

AttachmentDownloads.displayName = 'AttachmentDownloads'
AttachmentDownloads.propTypes = propTypes
AttachmentDownloads.defaultProps = defaultProps

export default AttachmentDownloads
