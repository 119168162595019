import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import TrackableLinksContacts from '../screens/trackable-links/TrackableLinksContacts'
import TrackableLinksDetail from '../screens/trackable-links/TrackableLinksDetail'
import TrackableLinksFolderDetail from '../screens/trackable-links/TrackableLinksFolderDetail'

const trackableLinkRoutes = [
  {
    path: ':trackableLinkId',
    element: <TrackableLinksDetail />
  },
  {
    path: ':trackableLinkId/contacts',
    element: <TrackableLinksContacts />
  }
]

const routes = [
  ...trackableLinkRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<TrackableLinksFolderDetail />} />,
    children: [...trackableLinkRoutes]
  }
]

export default routes
