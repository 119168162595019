import { useCallback, useId, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import usePartnerRequest from '../../../hooks/usePartnerRequest'
import useService from '../../../hooks/useService'
import { forgotPassword as forgotPasswordService } from '../../../services/user'
import Header from '../shared/Header'
import PanelWrapper from '../shared/PanelWrapper'

import validationSuite from './Form.validations'

const propTypes = {}
const defaultProps = {}

const ForgotPasswordForm = () => {
  const formRef = useRef()
  const formId = useId()
  const navigate = useNavigate()
  const {
    connectUrl
  } = usePartnerRequest()
  const authRoute = connectUrl || '/auth'

  const handleReplyOk = useCallback(() => {
    notify('Password Reset Requested', `If an account exists, an email will be sent to ${formRef.current.getValues('email')} with further instructions.`)
    formRef.current.reset()
  }, [])
  const { call: forgotPassword } = useService(forgotPasswordService, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback(({ email }) => forgotPassword(email), [forgotPassword])
  const handleGoBack = useCallback(() => navigate(authRoute), [authRoute, navigate])

  return (
    <PanelWrapper>
      <Header goBack={handleGoBack} subtitle="No worries, we'll help you figure things out." title='Forgot Password?' />
      <Form
        ref={formRef}
        className='mt-5'
        defaultValues={{ email: '' }}
        formControls={[
          {
            autoComplete: 'email',
            name: 'email',
            placeholder: 'Email Address',
            type: 'email',
            autoFocus: true
          }
        ]}
        id={formId}
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <Button
        className='mt-5 w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Reset Password
      </Button>
      <p className='text-center label-md my-5'>
        <Link className='text-primary label-md' to={authRoute}>
          Back to sign in
        </Link>
      </p>
    </PanelWrapper>
  )
}

ForgotPasswordForm.displayName = 'ForgotPasswordForm'
ForgotPasswordForm.propTypes = propTypes
ForgotPasswordForm.defaultProps = defaultProps

export default ForgotPasswordForm
