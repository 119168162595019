import { get } from '../services/admin/unlayer-template'

import useCurrentInstance from './useCurrentInstance'

const useCurrentUnlayerTemplate = () => useCurrentInstance({
  resourceName: 'unlayerTemplate',
  getService: get,
  replyAttrPath: 'json',
  notFoundRedirect: '/admin/unlayer-templates'
})

export default useCurrentUnlayerTemplate
