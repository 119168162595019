import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['NonBreakingSpace'] })

const NonBreakingSpace = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (<>&nbsp;</>)
}

NonBreakingSpace.displayName = 'NonBreakingSpace'
NonBreakingSpace.propTypes = propTypes
NonBreakingSpace.defaultProps = defaultProps

export default NonBreakingSpace
