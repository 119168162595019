import ListActionLink from '../../../components/links/ListActionLink'
import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'

function FormsList () {
  return (
    <Panel>
      <PanelHeader title='Form Controls' />
      <PanelContent>
        <ListActionLink title='Forms' to='forms' />
        <ListActionLink title='Checkbox Inputs' to='checkboxes' />
        <ListActionLink title='Messages' to='messages' />
        <ListActionLink title='Password Inputs' to='passwords' />
        <ListActionLink title='Radio Inputs' to='radios' />
        <ListActionLink title='Select Inputs' to='selects' />
        <ListActionLink title='Text Inputs' to='text-inputs' />
        <ListActionLink title='Text Samples' to='text-samples' />
      </PanelContent>
    </Panel>
  )
}

export default FormsList
