import { useCallback, useEffect, useId, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { success } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import dates from '../../data-sources/dates.json'
import months from '../../data-sources/months.json'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import contactFromJSON from '../../models/contact'
import { contactLinkLanding, contactLinkUpdate } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Contact'] })

const Contact = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formId = useId()
  const formRef = useRef()

  const { contactSlugOrId, keywordSlugOrId } = useParams()

  const [contact, setContact] = useState(null)
  const [keyword, setKeyword] = useState(null)
  const [error, setError] = useState(null)

  const handleGetReplyOk = useCallback((reply) => {
    setContact(contactFromJSON(reply.json.contact))
    if (reply.json.keyword) { setKeyword(reply.json.keyword) }
  }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/paste the link from your message to your browser.')
  }, [])
  const { call: getContact, loading: loadingContact } = useService(contactLinkLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  useEffect(() => {
    getContact(contactSlugOrId, keywordSlugOrId, true)
  }, [contactSlugOrId, getContact, keywordSlugOrId])

  const handleUpdateReplyOk = useCallback((/* reply */) => {
    window.scrollTo(0, 0)
    formRef.current.reset()
    success('Your contact info has been updated!')
    if (keyword?.contactLinkRedirectUrl) {
      setTimeout(() => { window.location.replace(keyword.contactLinkRedirectUrl) }, 2000)
    }
  }, [keyword])
  const { call: contactLinkUpdateCall } = useService(contactLinkUpdate, { onReplyOk: handleUpdateReplyOk })

  const handleSubmit = useCallback((data) => {
    contactLinkUpdateCall(contactSlugOrId, keywordSlugOrId, data)
  }, [contactLinkUpdateCall, contactSlugOrId, keywordSlugOrId])

  const loading = (loadingContact || !contact) && !error

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <p className='mb-10 text-center'>
        You have clicked on a form sent from
        {' '}
        <strong>
          {contact.user.formattedName}
          {' - '}
          {contact.user.formattedPhoneNumber}
        </strong>
        {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
      </p>
      <Form
        ref={formRef}
        defaultValues={{
          firstName: '',
          lastName: '',
          company: '',
          email: ''
        }}
        formControls={[
          { autoFocus: true, type: 'text', name: 'firstName', label: 'First Name' },
          { type: 'text', name: 'lastName', label: 'Last Name' },
          { type: 'text', name: 'company', label: 'Company' },
          { type: 'email', name: 'email', label: 'Email' },
          {
            type: 'controlgroup',
            name: 'birthday',
            formControls: [
              { containerClassName: 'w-full', label: 'Birthday Month', name: 'birthday[month]', type: 'select', options: [{ label: 'Select Month', value: '' }, ...months] },
              { containerClassName: 'w-full', label: 'Birthday Day', name: 'birthday[date]', type: 'select', options: [{ label: 'Select Day', value: '' }, ...dates] }
            ]
          }
        ]}
        id={formId}
        onSubmit={handleSubmit}
      />
      <p className='mt-4 mb-8 text-center'>
        ** By submitting this form you agree to receive text messages from the person who sent you this link. Message and data rates may apply. At any time you can reply STOP to the sender to stop receiving messages **
      </p>
      <Button
        className='w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Submit
      </Button>
    </ShortLinkContent>
  )
}

Contact.displayName = 'Contact'
Contact.propTypes = propTypes
Contact.defaultProps = defaultProps

export default Contact
