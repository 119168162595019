import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import spaceShape from '../../prop-types/shapes/space'
import { MarkColor } from '../../svg/branding'
import MessagePreview from '../message/list-item-helpers/MessagePreview'
import RelativeTime from '../message/list-item-helpers/RelativeTime'
import UnreadIndicator from '../message/list-item-helpers/UnreadIndicator'

const propTypes = {
  item: PropType.shape(spaceShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['SpaceListItemContent'] })

const SpaceListItemContent = ({ item: space }) => {
  useLogger({ log, lifecycle: true, tags: [space?._id] })

  log.debug(space)

  if (!space) { return null }

  return (
    <>
      <UnreadIndicator
        className='mt-[4px] mr-[4px]'
        lastMessageFromISOTime={space.lastMessage?.time}
        lastMessageReadISOTime={space.lastMessageRead?.time}
      />
      <div className='flex flex-col flex-nowrap gap-y-1 max-w-full overflow-auto'>
        <div className='flex flex-row flex-nowrap items-center text-black'>
          <div className='label-sm-strong max-w-full overflow-auto break-words'>
            {space.name}
          </div>
          <RelativeTime date={space.lastMessage?.time} />
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px]'>
          <MarkColor className='shrink-0 grow-0' height='16' width='16' />
          <div className='line-clamp-1 break-words'>
            <span className='text-neutral-600'>
              (
              {space.userCount}
              )
            </span>
            {' '}
            {space.userSubsetFormattedNames}
          </div>
        </div>
        <MessagePreview
          direction={space.lastMessage?.direction}
          fromFormattedName={space.lastMessage?.fromFormattedName}
          mediaUrl={space.lastMessage?.mediaUrl}
          text={space.lastMessage?.text}
          type={space.lastMessage?.type}
          space
        />
      </div>
    </>
  )
}

SpaceListItemContent.displayName = 'SpaceListItemContent'
SpaceListItemContent.propTypes = propTypes
SpaceListItemContent.defaultProps = defaultProps

export default SpaceListItemContent
