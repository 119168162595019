import { useCallback, useEffect, useRef, useState } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import caProvinceAndTerritoryData from '../../../data-sources/states/ca'
import usStateData from '../../../data-sources/states/us'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { entityTypeOptions, whitelistedRegistrationData } from '../../../lib/tcr'
import { validateBrand as validateBrandService } from '../../../services/admin'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BrandValidator'] })

const BrandValidator = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formRef = useRef()
  const registrationDataRef = useRef({})
  const [entityType, setEntityType] = useState('SOLE_PROPRIETOR')
  const [country, setCountry] = useState('US')

  useEffect(() => {
    formRef.current.setValue('state', '')
  }, [country])

  const onReplyOk = useCallback((reply) => {
    log.debug('handleReplyOk', { reply })
    notify('Brand validation successful')
  }, [])

  const { call: validateBrand } = useService(validateBrandService, { onReplyOk })

  const handleSubmit = useCallback(() => {
    const data = formRef.current.getValues()
    registrationDataRef.current = whitelistedRegistrationData(data)
    validateBrand(registrationDataRef.current)
  }, [validateBrand])

  const formControls = [
    {
      name: 'entityType',
      label: 'Select Business Type',
      type: 'select',
      options: entityTypeOptions,
      required: true,
      onChange: (e) => {
        setEntityType(e.target.value)
      }
    },
    {
      name: 'country',
      label: 'Country of Registration',
      type: 'countryselect',
      required: true,
      onChange: (e) => {
        setCountry(e.target.value)
      }
    },
    {
      name: 'displayName',
      label: 'Messaging Display Name',
      placeholder: 'Enter your messaging display name',
      type: 'text',
      required: true
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'companyName',
      label: 'Company Name',
      placeholder: 'Enter the legal name of the business',
      type: 'text',
      required: true
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'ein',
      label: country === 'US' ? 'EIN (Tax ID)' : 'Business Number (Tax ID)',
      placeholder: country === 'US' ? 'XX-XXXXXXX' : 'Business Number (Tax ID)',
      type: 'text',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'firstName',
      label: 'First Name',
      placeholder: 'Enter your first name',
      type: 'text',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Enter your last name',
      type: 'text',
      required: true
    },
    {
      name: 'email',
      label: 'Contact Email',
      placeholder: 'Enter your contact email',
      type: 'email',
      required: true
    },
    {
      name: 'street',
      label: 'Street Name and Number',
      placeholder: 'Enter an address',
      type: 'text',
      required: true
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      placeholder: 'Enter a city',
      required: true
    },
    {
      type: 'controlgroup',
      name: 'group1',
      containerClassName: 'flex-wrap',
      formControls: [
        {
          type: 'select',
          name: 'state',
          label: country === 'US' ? 'State' : 'Province/Territory',
          placeholder: country === 'US' ? 'Select a state' : 'Select a province/territory',
          options: country === 'US' ? usStateData : caProvinceAndTerritoryData,
          required: true,
          containerClassName: 'flex-grow'
        },
        {
          type: 'text',
          name: 'postalCode',
          label: 'Postal Code',
          placeholder: 'Postal code',
          required: true
        }
      ]
    },
    entityType === 'PRIVATE_PROFIT' && {
      name: 'phone',
      label: 'Business Phone',
      placeholder: 'The 10-digit support contact telephone number',
      type: 'tel',
      required: true
    },
    entityType === 'SOLE_PROPRIETOR' && {
      name: 'mobilePhone',
      label: 'Personal Cell Phone Number',
      placeholder: 'Enter your personal cell phone number',
      type: 'tel',
      required: true
    }
  ].filter((c) => c)

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='Brand Validator'
      />
      <PanelContent className='p-4'>
        <Form
          ref={formRef}
          defaultValues={{
            entityType,
            country
          }}
          formControls={formControls}
          id='brand-validator'
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            form='brand-validator'
            size='md'
            type='submit'
          >
            Test
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

BrandValidator.displayName = 'BrandValidator'
BrandValidator.propTypes = propTypes
BrandValidator.defaultProps = defaultProps

export default BrandValidator
