import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'

import { SearchNoResults } from '../../svg/empty-states'
import Button from '../buttons/Button'

const propTypes = {
  context: PropType.shape({
    addMissingTag: PropType.func.isRequired,
    query: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
    contactCount: PropType.number
  }).isRequired
}

const defaultProps = {}

const TagListEmptySearchContent = ({ context: { query, addMissingTag, contactCount } }) => {
  const missingTagName = useMemo(() => query.term.toLowerCase().trim(), [query])

  const onAddTag = useCallback(() => {
    addMissingTag(missingTagName)
  }, [addMissingTag, missingTagName])

  return (
    <>
      <div className='flex flex-col mx-auto mt-12 mb-6 max-w-[375px]'>
        <SearchNoResults />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Tag not found</h1>
        <p className='paragraph-sm-strong text-center'>
          Would you like to create and apply it to the selected contact
          {contactCount > 1 ? 's' : ''}
          ?
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddTag}>
          Add "
          {missingTagName}
          " Tag
        </Button>
      </div>
    </>
  )
}

TagListEmptySearchContent.displayName = 'TagListEmptySearchContent'
TagListEmptySearchContent.propTypes = propTypes
TagListEmptySearchContent.defaultProps = defaultProps

export default TagListEmptySearchContent
