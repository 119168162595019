import * as React from "react";
import { memo } from "react";
const SvgApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.568 15.586a10.856 10.856 0 0 1-1.08 1.933c-.568.807-1.033 1.365-1.391 1.674-.556.509-1.151.77-1.789.784-.457 0-1.009-.13-1.651-.392-.645-.262-1.237-.392-1.779-.392-.568 0-1.177.13-1.829.392-.652.263-1.178.4-1.58.413-.611.026-1.22-.242-1.828-.805-.389-.337-.874-.914-1.456-1.733-.624-.874-1.137-1.888-1.539-3.043C4.216 13.169 4 11.96 4 10.79c0-1.34.29-2.496.874-3.465a5.113 5.113 0 0 1 1.83-1.842 4.94 4.94 0 0 1 2.473-.695c.486 0 1.123.15 1.914.443.789.295 1.295.444 1.518.444.166 0 .728-.175 1.682-.523.903-.323 1.664-.457 2.288-.404 1.69.135 2.96.799 3.805 1.994-1.512.912-2.26 2.189-2.245 3.827.014 1.276.479 2.338 1.393 3.181.414.392.876.694 1.391.909-.111.322-.23.63-.355.927ZM16.692.4c0 1-.367 1.934-1.1 2.799-.882 1.027-1.95 1.621-3.108 1.527a3.111 3.111 0 0 1-.024-.379c0-.96.42-1.987 1.166-2.828.372-.425.846-.779 1.42-1.061C15.619.18 16.161.026 16.671 0c.015.134.02.267.02.4Z"
    />
  </svg>
);
const Memo = memo(SvgApple);
export default Memo;
