import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import FormattedDate from '../../FormattedDate'

const propTypes = {
  date: PropType.string
}

const defaultProps = {
  date: null
}

const log = logger({ enabled: false, tags: ['RelativeTime'] })

const RelativeTime = ({ date }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!date) { return null }

  return (
    <span className='ml-2 label-sm text-neutral-500 whitespace-nowrap'>
      <FormattedDate date={date} format='timeDistance' />
      {' '}
      ago
    </span>
  )
}

RelativeTime.displayName = 'RelativeTime'
RelativeTime.propTypes = propTypes
RelativeTime.defaultProps = defaultProps

export default RelativeTime
