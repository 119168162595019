import PropType from 'prop-types'

import Banner from '../banners/Banner'

const propTypes = {
  text: PropType.string.isRequired
}

const defaultProps = {}

const WarningDetailSection = ({ text }) => {
  return (
    <Banner className='mb-5' type='warning'>
      {text}
    </Banner>
  )
}

WarningDetailSection.displayName = 'WarningDetailSection'
WarningDetailSection.propTypes = propTypes
WarningDetailSection.defaultProps = defaultProps

export default WarningDetailSection
