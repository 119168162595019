import { App } from '@capacitor/app'
import { AppLauncher } from '@capacitor/app-launcher'
import { useCallback, useEffect, useRef } from 'react'

import useBus from '../../hooks/useBus'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import resolveLaunchUrl from '../../lib/resolveLaunchUrl'
import { externalNavigate } from '../ExternalNavigate'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'AppUrlOpen'] })

const AppUrlOpen = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const appUrlOpenListenerRef = useRef()

  const handleAppUrlOpen = useCallback((event) => {
    const launchUri = resolveLaunchUrl(event.url)
    log.info('ON:appUrlOpen', 'Dispatching Launch Uri:', launchUri)
    externalNavigate(launchUri)
  }, [])

  useBus('nativeUnsupportedAppUrl', (url) => {
    AppLauncher.openUrl({ url })
  })

  useEffect(() => {
    async function removeAndAdd () {
      if (appUrlOpenListenerRef.current?.remove) {
        await appUrlOpenListenerRef.current.remove()
        log.debug('removed previous "appUrlOpen" listener')
      }
      appUrlOpenListenerRef.current = await App.addListener('appUrlOpen', handleAppUrlOpen)
      log.debug('added new "appUrlOpen" listener')
    }

    removeAndAdd()

    return () => { appUrlOpenListenerRef.current?.remove() }
  }, [handleAppUrlOpen])

  return null
}

AppUrlOpen.displayName = 'AppUrlOpen'
AppUrlOpen.propTypes = propTypes
AppUrlOpen.defaultProps = defaultProps

export default AppUrlOpen
