import { useCallback, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { giveCredits as giveCreditsToUser } from '../../../services/admin'

import validationSuite from './AddCredits.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AddCredits'] })

const AddCredits = () => {
  const formRef = useRef()
  const { userId, loading, user, setUser } = useSelectedUser()

  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback((updateReply) => {
    setUser(updateReply.model)
    bus.emit('userUpdated', updateReply.model)
    notify('Credits Added')
  }, [setUser])
  const { call: giveCredits } = useService(giveCreditsToUser, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    giveCredits({ amount: data.amount }, user._id)
    formRef.current.reset()
  }, [user, giveCredits])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='userListForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Add Credits'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <Form
          ref={formRef}
          confirm={{
            description: `Are you sure you want to add credits to ${user.formattedName} at ${user.formattedPhoneNumber}?`,
            title: 'Confirmation'
          }}
          defaultValues={{

          }}
          formControls={[
            {
              type: 'number',
              name: 'amount',
              label: 'Number of Credits',
              step: 1
            }
          ]}
          id='userListForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='userListForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

AddCredits.displayName = 'AddCredits'
AddCredits.propTypes = propTypes
AddCredits.defaultProps = defaultProps

export default AddCredits
