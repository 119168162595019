import { formatName, formatNumber, formatPhone } from '../lib/formatters'

import proxy from './proxy'

export default function userFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get carrierName () {
      return (json.isSinch && 'Sinch') || (json.isTwilio && 'Twilio') || (json.isV2 && 'Bandwidth')
    },

    get email () {
      return json?.auth?.username
    },

    get formattedMessageCredits () {
      return formatNumber(json.messageCredits, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    },

    get formattedName () {
      return formatName(json)
    },

    get formattedPhoneNumber () {
      return formatPhone(json.phoneNumber)
    },

    get formattedCanSpamPhysicalMailingAddress () {
      const streetLine1 = json.canSpamCompliance?.physicalMailingAddress?.addressLine1 || ''
      const streetLine2 = json.canSpamCompliance?.physicalMailingAddress?.addressLine2 || ''
      const city = json.canSpamCompliance?.physicalMailingAddress?.city || ''
      const state = json.canSpamCompliance?.physicalMailingAddress?.stateProvince || ''
      const postal = json.canSpamCompliance?.physicalMailingAddress?.postal || ''

      return [streetLine1, streetLine2, city, state, postal].filter((x) => x).join(', ')
    }
  }

  return proxy(json, overrides)
}
