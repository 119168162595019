import * as React from "react";
import { memo } from "react";
const SvgExpand = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.13 10.826a.87.87 0 0 1-.87-.87V5.97l-6.34 6.341a.87.87 0 1 1-1.23-1.23l6.341-6.34h-3.987a.87.87 0 1 1 0-1.74h6.087c.48 0 .869.39.869.87v6.087c0 .48-.39.87-.87.87Zm-9.565 2.609c.34.34.34.89 0 1.23L5.97 21.26h3.988a.87.87 0 1 1 0 1.739H3.87a.87.87 0 0 1-.87-.87v-6.086a.87.87 0 1 1 1.74 0v3.987l6.595-6.596a.87.87 0 0 1 1.23 0Z"
    />
  </svg>
);
const Memo = memo(SvgExpand);
export default Memo;
