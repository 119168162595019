import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import ShortLinkError from './ShortLinkError'
import ShortLinkLoading from './ShortLinkLoading'

const propTypes = {
  children: PropType.node,
  error: PropType.string,
  loading: PropType.bool
}

const defaultProps = {
  children: null,
  error: null,
  loading: false
}

const log = logger({ enabled: false, tags: ['ShortLinkContent'] })

const ShortLinkContent = ({ children, error, loading }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='bg-white drop-shadow border border-neutral-200 px-6 py-10 w-full max-w-[800px] rounded-lg my-auto'>
      {loading ? <ShortLinkLoading /> : error ? <ShortLinkError error={error} /> : children}
    </div>
  )
}

ShortLinkContent.displayName = 'ShortLinkContent'
ShortLinkContent.propTypes = propTypes
ShortLinkContent.defaultProps = defaultProps

export default ShortLinkContent
