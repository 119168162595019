import { useCallback, useRef, useState } from 'react'

import ManagedAccountList from '../../components/managed-accounts/ManagedAccountList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

import ListHeaderMoreMenu from './list/ListHeaderMoreMenu'

function ManagedAccountsList () {
  const listRef = useRef()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<ListHeaderMoreMenu listRef={listRef} />}
        title='Managed Accounts'
      />
      <PanelContent>
        <ManagedAccountList
          ref={listRef}
          placeholder='Search Managed Accounts'
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

export default ManagedAccountsList
