import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { textKeywordLinkLanding } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['TextKeywordLink'] })

const TextKeywordLink = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [keyword, setKeyword] = useState(null)
  const [url, setUrl] = useState(null)
  const [error, setError] = useState(null)

  const { keywordSlug } = useParams()

  const handleGetReplyOk = useCallback((reply) => {
    setKeyword(reply.model)
    const phoneNumber = reply.model.user?.phoneNumber
    const body = encodeURIComponent(reply.model.word)
    // https://stackoverflow.com/questions/6480462/how-to-pre-populate-the-sms-body-text-via-an-html-link
    setUrl(`sms:${phoneNumber};?&body=${body}`)
  }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/pate the link.')
  }, [])
  const { call, loading: keywordLoading } = useService(textKeywordLinkLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  useEffect(() => {
    call(keywordSlug, true)
  }, [call, keywordSlug])

  useEffect(() => {
    if (url) {
      window.location.href = url
    }
  }, [url])

  const loading = (keywordLoading || !keyword) && !error

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <div className='text-center'>
        <p>
          You have clicked on a link from
          {' '}
          <strong>
            {keyword.user.formattedName}
            {' - '}
            {keyword.user.formattedPhoneNumber}
          </strong>
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <p className='my-10'>
          <a
            className='link'
            href={url}
            rel='nofollow noreferrer'
            target='_blank'
          >
            Send the keyword
            {' '}
            {`"${keyword.word}"`}
            {' '}
            to
            {' '}
            {keyword.user.formattedPhoneNumber}
          </a>
        </p>
      </div>
    </ShortLinkContent>
  )
}

TextKeywordLink.displayName = 'TextKeywordLink'
TextKeywordLink.propTypes = propTypes
TextKeywordLink.defaultProps = defaultProps

export default TextKeywordLink
