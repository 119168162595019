import PropType from 'prop-types'
import { forwardRef, memo, useCallback, useRef, useState } from 'react'

import AppmixerEditor from '../../../components/app-flows/AppmixerEditor'
import { warning } from '../../../components/banners/Banner'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import PanelDialog from '../../../components/dialog/PanelDialog'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'

import EditorHeaderFlowControl from './EditorHeaderFlowControl'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired,
  onChange: PropType.func.isRequired,
  onClose: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['EditorDialog'] })

const EditorDialog = memo(forwardRef(({ appFlow, onChange, onClose }, ref) => {
  ref = useDefaultRef(ref)
  const appmixerEditorRef = useRef()
  const [appFlowErrors, setAppFlowErrors] = useState(null)
  const appFlowRunningOnOpenRef = useRef(false)
  const confirmDialogRef = useRef()
  const headerFlowControlRef = useRef()

  useLogger({ log, lifecycle: false, tags: [] })

  const closeNow = useCallback(() => {
    ref.current.close()
    onClose({ hasSavedEdits: appmixerEditorRef.current.hasSavedEdits() })
  }, [onClose, ref])

  const handleOnOpen = useCallback(() => {
    appFlowRunningOnOpenRef.current = appFlow.state === 'running'
  }, [appFlow])

  const handleCloseEditor = useCallback(() => {
    if (appFlow.state !== 'running' && appFlowRunningOnOpenRef.current) {
      return confirmDialogRef.current.open()
    } else {
      closeNow()
    }
  }, [appFlow.state, closeNow])

  const handleOnChange = useCallback((reply) => {
    appmixerEditorRef.current.reload()
    onChange(reply)
  }, [onChange])

  const handleOnReady = useCallback(() => {
    appmixerEditorRef.current.loadAppFlow(appFlow)
  }, [appFlow])

  const handleOnValidate = useCallback((errors) => {
    setAppFlowErrors(errors)
  }, [])

  const handleRestartAndClose = useCallback(async () => {
    if (headerFlowControlRef.current.hasErrors()) {
      warning('App Flow has errors. Please fix them before restarting.')
    } else {
      await headerFlowControlRef.current.startAppFlow()
      closeNow()
    }
  }, [closeNow])

  return (
    <PanelDialog
      ref={ref}
      ariaLabel='Edit App Flow'
      dismissable={false}
      fullscreen
      keepInBackground
      onOpen={handleOnOpen}
    >
      <PanelHeader
        end={(
          <EditorHeaderFlowControl
            ref={headerFlowControlRef}
            appFlow={appFlow}
            appFlowErrors={appFlowErrors}
            onChange={handleOnChange}
          />)}
        start={<PanelHeaderButton icon='cancel' onClick={handleCloseEditor} />}
        subtitle={appFlow.name}
        title='Edit App Flow'
      />
      <PanelContent>
        <AppmixerEditor
          ref={appmixerEditorRef}
          onReady={handleOnReady}
          onValidate={handleOnValidate}
        />
        <ConfirmDialog
          ref={confirmDialogRef}
          cancelTitle='Close'
          confirmTitle='Restart & Close'
          description='The App Flow was initially running when opened for edit. Would you like to restart it?'
          title='Re-Start App Flow?'
          onCancel={closeNow}
          onConfirm={handleRestartAndClose}
        />
      </PanelContent>
    </PanelDialog>
  )
}))

EditorDialog.displayName = 'EditorDialog'
EditorDialog.propTypes = propTypes
EditorDialog.defaultProps = defaultProps

export default EditorDialog
