import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import ShortLinkStopMessage from '../../components/short-links/ShortLinkStopMessage'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { trackableLinkClick, trackableLinkLanding } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['TrackableLink'] })

const TrackableLink = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [trackableLink, setTrackableLink] = useState(null)
  const [error, setError] = useState(null)

  const { trackableLinkSlug, contactSlug } = useParams()

  const handleGetReplyOk = useCallback((reply) => { setTrackableLink(reply.model) }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/paste the link from your message to your browser.')
  }, [])
  const { call: getTrackableLinkCall, loading: trackableLinkLoading } = useService(trackableLinkLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  const handleTrackLinkClickReply = useCallback((/* reply */) => {
    window.location.href = trackableLink.url
  }, [trackableLink?.url])
  const { call: trackLinkClick } = useService(trackableLinkClick, { onReply: handleTrackLinkClickReply })

  useEffect(() => {
    getTrackableLinkCall(trackableLinkSlug, contactSlug, true)
  }, [contactSlug, getTrackableLinkCall, trackableLinkSlug])

  const handleLinkClick = useCallback((event) => {
    trackLinkClick(trackableLinkSlug, contactSlug)
  }, [contactSlug, trackLinkClick, trackableLinkSlug])

  const loading = (trackableLinkLoading || !trackableLink) && !error

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <div className='text-center'>
        <p>
          You have clicked on a link sent from
          {' '}
          <strong>
            {trackableLink.user.formattedName}
            {' - '}
            {trackableLink.user.formattedPhoneNumber}
          </strong>
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <p>To continue please click the link below.</p>
        <p className='my-10'>
          <span className='link select-none' onClick={handleLinkClick} onContextMenu={(e) => e.preventDefault()}>{trackableLink.url}</span>
        </p>
        <ShortLinkStopMessage />
      </div>
    </ShortLinkContent>
  )
}

TrackableLink.displayName = 'TrackableLink'
TrackableLink.propTypes = propTypes
TrackableLink.defaultProps = defaultProps

export default TrackableLink
