import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Well from '../../components/wells/Well'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { formatNumber } from '../../lib/formatters'
import logger from '../../lib/logger'
import { planCanBuyCredits } from '../../lib/plans'
import { plans as plansService } from '../../services/user'

import ChangeSubscriptionForm from './ChangeSubscriptionForm'
import ChangeSubscriptionScheduled from './ChangeSubscriptionScheduled'
import RenewSubscriptionImmediately from './RenewSubscriptionImmediately'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['ChangeSubscription'] })

const ChangeSubscription = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [currentPlan, setCurrentPlan] = useState(null)
  const [plans, setPlans] = useState([])

  const loadPlans = useCallback((data) => {
    setCurrentPlan(data?.json?.currentPlan)
    setPlans(data?.json?.plans)
  }, [])
  const { call: getPlans } = useService(plansService, { onReplyOk: loadPlans })

  useEffect(() => {
    getPlans()
  }, [getPlans])

  const currentUser = useCurrentUser({ alsoReload: true })
  const subscriptionDetails = currentUser.subscriptionDetails
  const planCancelling = !!subscriptionDetails?.cancelsAt
  const planChanging = subscriptionDetails?.scheduledPlanId && subscriptionDetails.scheduledPlanId !== subscriptionDetails.currentPlanId

  const title = 'Change Subscription'

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent className='p-5'>
        {!planCancelling && planCanBuyCredits({ id: currentUser.subscriptionPlan }) && (
          <Well className='mb-5'>
            <p className='mb-2'>
              <strong className='block'>Need More Credits?</strong>
              {' '}
              You can easily add more credits to your current subscription, making sure your business has what it needs right away.
            </p>
            <Link className='text-primary' to='/subscription/buy-credits'>
              Buy Credits Now
            </Link>
          </Well>
        )}
        {planChanging ? null : <RenewSubscriptionImmediately currentPlan={currentPlan} subscriptionDetails={subscriptionDetails} />}
        <p className='mb-5'>
          Project Broadcast allows you to change your subscription level to suit your needs. Plan changes take effect at the end of your current billing cycle.
        </p>
        {subscriptionDetails?.currentPlanName && subscriptionDetails?.currentPlanFrequency
          ? (
            <p className='mb-5'>
              You are currently subscribed to the
              {' '}
              <strong>
                {subscriptionDetails.currentPlanName}
                {' '}
                {subscriptionDetails.currentPlanFrequency}
              </strong>
              {' '}
              plan and have
              {' '}
              <strong>
                {formatNumber(currentUser.messageCredits)}
                {' '}
                available credits
              </strong>
              {' '}
              on your account.
            </p>
            )
          : null}
        {planCancelling || planChanging
          ? <ChangeSubscriptionScheduled subscriptionDetails={subscriptionDetails} />
          : <ChangeSubscriptionForm currentPlan={currentPlan} plans={plans} subscriptionDetails={subscriptionDetails} />}
      </PanelContent>
    </Panel>
  )
}

ChangeSubscription.displayName = 'ChangeSubscription'
ChangeSubscription.propTypes = propTypes
ChangeSubscription.defaultProps = defaultProps

export default ChangeSubscription
