import AivaChat from '../../components/aiva/AivaChat'
import Panel from '../../components/panels/Panel'
import PanelBottomSafeSpacer from '../../components/panels/PanelBottomSafeSpacer'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AivaChatScreen'] })

const AivaChatScreen = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Panel hideAivaChatButton>
      <PanelHeader
        title='Ask AIVA'
      />
      <PanelContent disableAutoSafeSpacer>
        <AivaChat draftKey='aiva-chat' />
        <PanelBottomSafeSpacer className='bg-neutral-50' />
      </PanelContent>
    </Panel>
  )
}

AivaChatScreen.displayName = 'AivaChatScreen'
AivaChatScreen.propTypes = propTypes
AivaChatScreen.defaultProps = defaultProps

export default AivaChatScreen
