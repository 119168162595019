import PropType from 'prop-types'
import { useCallback } from 'react'

import { useApp } from '../../../../contexts/AppContext'
import useFilePicker from '../../../../hooks/useFilePicker'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import Banner from '../../../banners/Banner'
import Button from '../../../buttons/Button'

const propTypes = {
  onUpload: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['UploadTab'] })

const UploadTab = ({ onUpload }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isNative } = useApp()
  const supportsUpload = !isNative

  const { show: showWavFilePicker } = useFilePicker({ typeFilter: 'audio/wav', limitMB: 7.5 })

  const handleUploadFile = useCallback(async () => {
    const audio = await showWavFilePicker()
    onUpload(audio)
  }, [onUpload, showWavFilePicker])

  if (!supportsUpload) {
    return (<Banner title='Unsupported' type='warning'>Please use a web browser to upload audio.</Banner>)
  }

  return (
    <div className='flex flex-col items-center'>
      <Button
        size='sm'
        theme='primary'
        type='button'
        variant='outline'
        onClick={handleUploadFile}
      >
        Browse Files
      </Button>
      <p className='mt-3 paragraph-sm'>
        Supported file types:
        {' '}
        <strong>WAV</strong>
      </p>
    </div>
  )
}

UploadTab.displayName = 'UploadTab'
UploadTab.propTypes = propTypes
UploadTab.defaultProps = defaultProps

export default UploadTab
