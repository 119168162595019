export function calculatePanelOneAndTwoClassNames (hasPanel2, panelPriority, roomForTwoPanels) {
  let one = 'w-full'
  let two = 'w-full'

  if (roomForTwoPanels && hasPanel2) {
    one = 'md:min-w-[375px] w-2/5 md:max-w-[478px]'
  } else if (!roomForTwoPanels && hasPanel2) {
    if (panelPriority === 2) {
      one = 'hidden'
    } else {
      two = 'hidden'
    }
  }

  return [one, two]
}
