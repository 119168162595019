import { useCallback, useEffect, useState } from 'react'

import useLogger from '../hooks/useLogger'
import { getCookie } from '../lib/cookies'
import logger from '../lib/logger'
import { meta as metaService } from '../services/affiliate'

import useService from './useService'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['useAffiliate'] })

const useAffiliate = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [affiliate, setAffiliate] = useState()
  const trackingCode = getCookie('LD_T') // LeadDyno script on www sets LD_T
  const [affiliateLookupComplete, setAffiliateLookupComplete] = useState(!trackingCode)

  const onReply = useCallback((reply) => {
    setAffiliateLookupComplete(true)
    if (reply?.ok) {
      const { id, name } = reply?.json || {}
      if (id && name) {
        setAffiliate({ id, name, trackingCode })
      }
    }
  }, [trackingCode])
  const { call: meta } = useService(metaService, { onReply })

  useEffect(() => {
    if (trackingCode) {
      log.debug('trackingCode', trackingCode)
      meta({ trackingCode })
    }
  }, [meta, trackingCode])

  log.debug('affiliate', affiliate)
  return {
    affiliate, // { id, name, trackingCode }
    affiliateLookupComplete
  }
}

useAffiliate.displayName = 'useAffiliate'
useAffiliate.propTypes = propTypes
useAffiliate.defaultProps = defaultProps

export default useAffiliate
