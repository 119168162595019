import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Microphone } from '../../../svg/icons'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  mediaUrl: PropType.string,
  template: PropType.shape({
    _id: PropType.string,
    name: PropType.string
  })
}

const defaultProps = {
  mediaUrl: null,
  template: null
}

const classNamesByDirection = {
  in: [
    'self-start',
    'bg-neutral-100',
    'text-neutral-900'
  ],
  out: [
    'self-end',
    'bg-indigo-100',
    'text-indigo-900'
  ]
}

const log = logger({ enabled: true, tags: ['MessageThreadItemContentAudio'] })

const MessageThreadItemContentAudio = ({ direction, mediaUrl, template }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!mediaUrl) { return }

  const wrapperClassName = classNames(
    'flex flex-col max-w-full mb-4',
    'whitespace-pre-wrap break-words p-3 rounded-lg',
    classNamesByDirection[direction]
  )

  return (
    <div className={wrapperClassName}>
      <div className='label-sm-strong flex flex-row items-center mb-1'>
        <Microphone className='w-4 h-4 mr-2' />
        Voice Drop
      </div>
      {direction === 'out' && template?.name
        ? (
          <div className='label-sm'>
            {template?.name}
          </div>)
        : null}
      <audio
        className='outline-0 h-6 w-72 my-1'
        preload='none'
        src={mediaUrl}
        controls
      />
    </div>
  )
}

MessageThreadItemContentAudio.displayName = 'MessageThreadItemContentAudio'
MessageThreadItemContentAudio.propTypes = propTypes
MessageThreadItemContentAudio.defaultProps = defaultProps

export default MessageThreadItemContentAudio
