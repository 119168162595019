import { useEffect, useRef } from 'react'

import useUpdateEffect from './useUpdateEffect'

const useLogger = ({ log, lifecycle, tags }) => {
  const mounted = useRef(false)
  const renders = useRef(0)
  const lifecycleRef = useRef(lifecycle || false)

  useEffect(() => { log.updateAdditionalTags(tags) }, [tags]) // eslint-disable-line react-hooks/exhaustive-deps
  useUpdateEffect(() => { lifecycleRef.current = lifecycle || false }, [lifecycle])

  renders.current++

  if (lifecycleRef.current && mounted.current) { log.debug('lifecycle:updated', `renders:${renders.current}`) }

  useEffect(() => {
    mounted.current = true
    if (lifecycleRef.current) { log.debug('lifecycle:mounted', `renders:${renders.current}`) }
    return () => {
      if (lifecycleRef.current) { log.debug('lifecycle:unmounted', `renders:${renders.current}`) }
      renders.current = 1
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useLogger
