import PropType from 'prop-types'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import templateShape from '../../../../prop-types/shapes/template'
import { Close } from '../../../../svg/icons'
import { VoiceDrop } from '../../../../svg/types'
import Button from '../../../buttons/Button'

const propTypes = {
  messageType: PropType.oneOf(messageTypes).isRequired,
  mediaUrl: PropType.string,
  template: PropType.shape(templateShape),
  onRemove: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onRemove: undefined,
  template: undefined
}

const log = logger({ enabled: false, tags: ['MessageVoiceAttachment'] })

const MessageVoiceAttachment = ({ mediaUrl, messageType, template, onRemove }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (messageType !== 'rvm') { return null }

  return (
    <div className='flex items-center px-3 pt-3 flex w-full'>
      <VoiceDrop className='w-6 h-6 mr-2 shrink-0 self-start' />
      <div className='flex flex-col w-full'>
        <div className='flex flex-col'>
          <div>
            <strong>
              {template?.name}
            </strong>
          </div>
        </div>
        {mediaUrl
          ? (
            <audio
              className='outline-0 w-full my-1 mr-3'
              preload='auto'
              src={mediaUrl}
              controls
            />
            )
          : (
            <div className='bg-neutral-200 rounded-lg mr-3 p-3'>
              <div className='label-xs-strong'>
                Calling Phone to Record
              </div>
              <div className='label-xs-medium'>
                The system will call your Call Forwarding phone number and prompt you to record your voice drop.
              </div>
            </div>
            )}
      </div>
      <Button
        className='ml-3'
        icon={<Close />}
        size='sm'
        theme='primary'
        variant='outline'
        circle
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  )
}

MessageVoiceAttachment.displayName = 'MessageVoiceAttachment'
MessageVoiceAttachment.propTypes = propTypes
MessageVoiceAttachment.defaultProps = defaultProps

export default MessageVoiceAttachment
