import useStore from '../'

export function incrementApiLoading (customLoadingMessage) {
  useStore.setState((state) => {
    state.apiLoading.callCount += 1
    state.apiLoading.activated = true
    state.apiLoading.messageOverride = customLoadingMessage
  }, false, 'apiLoading/incrementApiLoading')
}

export function decrementApiLoading () {
  useStore.setState((state) => {
    state.apiLoading.callCount = Math.max(0, state.apiLoading.callCount - 1)
    state.apiLoading.activated = state.apiLoading.callCount !== 0
    if (!state.apiLoading.activated) {
      state.apiLoading.messageOverride = null
    }
  }, false, 'apiLoading/decrementApiLoading')
}
