import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useId, useImperativeHandle, useLayoutEffect, useMemo, useReducer, useRef } from 'react'

import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import logger from '../../../lib/logger'
import Button from '../../buttons/Button'
import LightBox from '../../LightBox'

import ScheduledChats from './ScheduledChats'
import ThreadItem from './ThreadItem'
import reducer, { initialPagingState } from './ThreadList.reducer'
import ThreadRecipientTyping from './ThreadRecipientTyping'

const propTypes = {
  service: PropType.func.isRequired,
  ThreadItemContent: PropType.elementType.isRequired,
  EmptyThreadContent: PropType.elementType,
  pageSize: PropType.number,
  queryOverrides: PropType.object, // eslint-disable-line react/forbid-prop-types
  recipientTyping: PropType.bool,
  resultsKey: PropType.string,
  reverseIncomingData: PropType.bool,
  onLastItemChange: PropType.func
}

const defaultProps = {
  EmptyThreadContent: null,
  onLastItemChange: undefined,
  pageSize: 50,
  queryOverrides: {},
  recipientTyping: false,
  resultsKey: 'messages',
  reverseIncomingData: false
}

const log = logger({ enabled: true, tags: ['ThreadList'] })

const ThreadList = forwardRef(({
  EmptyThreadContent,
  pageSize,
  queryOverrides,
  recipientTyping,
  resultsKey,
  reverseIncomingData,
  service,
  ThreadItemContent,
  onLastItemChange
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const initialState = {
    data: [],
    scheduledMessagesCount: 0,
    paging: initialPagingState,
    pageSize,
    hasMore: false,
    queryOverrides,
    lastItemId: null,
    nextPaging: false, // used to trigger paging while avoiding side-effects from monitoring other state items
    reverseIncomingData, // not kept up-to-date
    lightBoxSlides: []
  }

  ref = useDefaultRef(ref)
  const lightBoxRef = useRef()
  const scrollContainerRef = useRef()
  const id = useId()

  useLogger({ log, lifecycle: false, tags: [id] })

  // Handle new received data
  const handleReplyOk = useCallback((reply) => { dispatch({ type: 'reply', reply, resultsKey }) }, [resultsKey])
  // TODO: should we handleReplyNotOk as well and retry?
  const { call/*, loading */ } = useService(service, { onReplyOk: handleReplyOk })
  const [state, dispatch] = useReducer(reducer, initialState)

  const publicApi = useMemo(() => ({
    isEmpty () {
      return !state.lastItem
    },
    newMessages (messages, scheduledMessagesCount) {
      dispatch({ type: 'newMessages', messages, scheduledMessagesCount })
    },
    reload () { dispatch({ type: 'reload' }) },
    updateMessage (message) { // expects a message model
      dispatch({ type: 'updateMessage', message })
    },
    removeMessage (message) {
      dispatch({ type: 'removeMessage', message })
    },
    scrollToMessageId (messageId) {
      const domId = `${id}-${messageId}`
      const messageDiv = document.getElementById(domId)
      const messageDivRect = messageDiv.getBoundingClientRect()
      const scrollContainerRect = scrollContainerRef.current.getBoundingClientRect()
      const currentScrollTop = scrollContainerRef.current.scrollTop
      const scrolled = Math.abs(currentScrollTop)
      const scrollHeight = scrollContainerRef.current.scrollHeight
      const unscrolled = scrollHeight - scrolled
      const bottom = unscrolled + messageDivRect.bottom - scrollContainerRect.height - scrollContainerRect.top
      let newScrollTop = (scrollHeight - bottom) * -1

      if ((messageDivRect.height + 16) > scrollContainerRect.height) {
        // If message is taller than the container height, adjust to show message top
        //    NOTE: The 16px accounts for a message's mb-4
        newScrollTop -= (messageDivRect.height + 16) - scrollContainerRect.height
      }

      // NOTE: if the scrollTops are equal then ios will not adjust the scroll position... so we offset it by one which doesn't seem noticeable in the ui
      if (currentScrollTop === newScrollTop) { newScrollTop -= 1 }
      scrollContainerRef.current.scrollTo(0, newScrollTop)
    },
    showLightBoxForMessageId (id) {
      const index = state.lightBoxSlides.findIndex((slide) => slide.messageId === id)
      if (index > -1) {
        lightBoxRef.current.open(index)
      }
    }
  }), [id, state.lastItem, state.lightBoxSlides])

  useImperativeHandle(ref, () => publicApi, [publicApi])

  const loadingEarlierRef = useRef(false)
  const currentScrollTopRef = useRef(0)
  const handleLoadEarlierClick = useCallback(() => {
    if (loadingEarlierRef.current) { return }
    scrollContainerRef.current.style = 'overflow: hidden' // prevent scrolling during next page loading
    loadingEarlierRef.current = true
    currentScrollTopRef.current = scrollContainerRef.current.scrollTop
    dispatch({ type: 'nextPage' })
  }, [])
  const handlePostLoadEarlierClick = useCallback(() => {
    loadingEarlierRef.current = false
    scrollContainerRef.current.scrollTop = currentScrollTopRef.current // safari ios needs us to set reset scrollTop
    scrollContainerRef.current.style = '' // re-enable scrolling
  }, [])

  useEffect(() => {
    // nextPaging changed... see if it should trigger loading the next page or not
    if (!state.nextPaging) {
      // assuming next paging was loaded
      handlePostLoadEarlierClick()
      return
    }
    const { nextPage, nextPageSize, nextQueryOverrides } = state.nextPaging
    call({ paging: { page: nextPage, pageSize: nextPageSize }, ...nextQueryOverrides }, true)
  }, [call, handlePostLoadEarlierClick, state.nextPaging])

  // Initial mount, trigger loading the next page
  useEffect(() => { dispatch({ type: 'nextPage' }) }, [])

  useUpdateEffect(() => { dispatch({ type: 'newPageSize', pageSize }) }, [pageSize])
  useUpdateEffect(() => { dispatch({ type: 'newQueryOverrides', queryOverrides }) }, [queryOverrides])

  const onLastItemChangeRef = useRef(onLastItemChange)
  useUpdateEffect(() => { onLastItemChangeRef.current = onLastItemChange }, [onLastItemChange])
  useEffect(() => { onLastItemChangeRef.current?.(state.lastItem) }, [state.lastItem])
  useLayoutEffect(() => {
    if (!state.lastItem) { return }
    publicApi.scrollToMessageId(state.lastItem._id)
  }, [publicApi, state.lastItem])

  useLayoutEffect(() => {
    if (recipientTyping) {
      const { scrollHeight } = scrollContainerRef.current
      scrollContainerRef.current.scrollTo(0, scrollHeight)
    }
  }, [recipientTyping])

  const handleLightBoxView = useCallback(({ index: slideIndex }) => {
    const slide = state.lightBoxSlides[slideIndex]
    const { messageId } = slide
    publicApi.scrollToMessageId(messageId)
  }, [publicApi, state.lightBoxSlides])

  const threadItems = useMemo(() => {
    if (state?.dataWithDates?.length === 0) {
      return EmptyThreadContent ? <EmptyThreadContent /> : null
    }

    return state?.dataWithDates?.map((item, index) => {
      const itemId = `${id}-${item._id || item.getTime()}`
      return (
        <ThreadItem
          key={itemId}
          id={itemId}
          index={index}
          item={item}
          listRef={ref}
          ThreadItemContent={ThreadItemContent}
        />
      )
    })
  }, [EmptyThreadContent, ThreadItemContent, id, ref, state?.dataWithDates])

  const loadEarlierButton = useMemo(() => {
    if (!state.hasMore) { return null }
    return (
      <div className='flex justify-center my-4'>
        <Button size='md' variant='text' onClick={handleLoadEarlierClick}>Load Earlier</Button>
      </div>
    )
  }, [handleLoadEarlierClick, state.hasMore])

  return (
    <div className='relative h-full w-full overflow-hidden select-none'>
      <ScheduledChats scheduledMessagesCount={state.scheduledMessagesCount} />
      <div ref={scrollContainerRef} className='relative max-h-full w-full overflow-auto flex flex-col-reverse z-10'>
        {recipientTyping ? <ThreadRecipientTyping /> : null}
        {threadItems}
        {loadEarlierButton}
      </div>
      <LightBox
        ref={lightBoxRef}
        slides={state.lightBoxSlides}
        onView={handleLightBoxView}
      />
    </div>
  )
})

ThreadList.displayName = 'ThreadList'
ThreadList.propTypes = propTypes
ThreadList.defaultProps = defaultProps

export default ThreadList
