import { map } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { pluck } from 'underscore'

import { success } from '../../../components/banners/Banner'
import CampaignList from '../../../components/campaigns/CampaignList'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'
import { addAllInQuery, addSelected } from '../../../services/keyword-join-campaign'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: false, tags: ['JoinCampaignsDetail'] })

const JoinCampaignsDetail = ({ keyword, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [keyword?._id] })

  const addJoinCampaignsDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Campaigns added')
    onChange?.(reply)
  }, [onChange])
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })

  const handleAddFirst = useCallback(() => { addJoinCampaignsDialogRef.current.open() }, [])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ keywordId: keyword._id, campaigns: map(selected, '_id') })
  }, [addSelectedCall, keyword._id])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ keywordId: keyword._id, ...query })
  }, [addAllInQueryCall, keyword._id])

  const names = pluck(keyword.campaigns, 'name').join(', ')
  const count = keyword.campaigns.length

  return (
    <>
      <DetailSection
        count={count}
        description='Project Broadcast will auto add the sender to the following campaigns.'
        editHref='join-campaigns'
        editIcon='chevron'
        initialEditButtonText='Add Campaigns to Join'
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title='Join Campaigns'
        onInitialEditClick={handleAddFirst}
      >
        {names}
      </DetailSection>
      <ListDialog
        ref={addJoinCampaignsDialogRef}
        list={CampaignList}
        subtitle={keyword?.word}
        title='Add Campaigns To Join'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </>
  )
}

JoinCampaignsDetail.displayName = 'JoinCampaignsDetail'
JoinCampaignsDetail.propTypes = propTypes
JoinCampaignsDetail.defaultProps = defaultProps

export default JoinCampaignsDetail
