import { useRef, useState } from 'react'

import ActionMenu from '../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../components/action-menu/ActionMenuItem'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import AdvancedSearchDialog from '../../components/search-bar/AdvancedSearchDialog'
import SearchBar from '../../components/search-bar/SearchBar'
import SearchBarButton from '../../components/search-bar/SearchBarButton'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const log = logger({ enabled: true, tags: ['Style Guide', 'SearchBars'] })

function SearchBars () {
  useLogger({ log, lifecycle: false, tags: [] })

  const advancedSearchBarRef = useRef()
  const advancedSearchDialogRef = useRef()
  const advancedSearchBarSortList = {
    sortUp: { label: 'Sort Up' },
    sortDown: { label: 'Sort Down', selected: true },
    sortLeft: { label: 'Sort Left' }
  }
  const advancedSearchBarDefaults = { sort: 'sortDown', term: 'testing', areaCode: '444' }
  const [advancedSearchDialogDefaults, setAdvancedSearchDialogDefaults] = useState(advancedSearchBarDefaults)
  const advancedSearchBarActionMenu = (
    <ActionMenu offset={8} placement='bottom-end' trigger={<SearchBarButton icon='filter' />}>
      <ActionMenuItem label='Set Area Code to 555' onClick={() => { advancedSearchBarRef.current.update({ areaCode: '555' }) }} />
      <ActionMenuItem label='Set Area Code to 666' onClick={() => { advancedSearchBarRef.current.update({ areaCode: '666' }) }} />
    </ActionMenu>
  )

  return (
    <Panel>
      <PanelHeader title='Search Bars' />
      <PanelContent>
        <div className='p-4'>
          <SearchBar onChange={(data) => { log.info('SearchBar0 onChange', data) }} />
        </div>
        <div className='p-4'>
          <SearchBar search={{ term: 'prefilled term' }} onChange={(data) => { log.info('SearchBar1 onChange', data) }} />
        </div>
        <div className='p-4'>
          <SearchBar
            ref={advancedSearchBarRef}
            end={advancedSearchBarActionMenu}
            search={advancedSearchBarDefaults}
            sortList={advancedSearchBarSortList}
            onAdvancedSearchClick={() => advancedSearchDialogRef.current.open()}
            onChange={(data) => {
              setAdvancedSearchDialogDefaults(data)
              log.info('SearchBar2 onChange', data)
            }}
          />
          <AdvancedSearchDialog
            ref={advancedSearchDialogRef}
            defaultValues={advancedSearchDialogDefaults}
            formControls={[
              {
                label: 'Term',
                name: 'term'
              },
              {
                label: 'Area Code',
                name: 'areaCode'
              }
            ]}
            onSubmit={(data) => { advancedSearchBarRef.current.update(data) }}
          />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default SearchBars
