import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import { formatName, formatPhone } from '../../lib/formatters'
import logger from '../../lib/logger'
import landingPageViewShape from '../../prop-types/shapes/landingPageView'
import { MarkColor } from '../../svg/branding'
import { Clock } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape(landingPageViewShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['LandingPageViewListItemContent'] })

const LandingPageViewListItemContent = ({ item: landingPageView }) => {
  useLogger({ log, lifecycle: true, tags: [landingPageView._id] })

  const contact = landingPageView.contact || { firstName: 'Guest', phoneNumber: '' }
  return (
    <>
      <Avatar contactOrUser={contact} size='sm' />
      <div className='flex flex-col ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {contact.contactUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {formatName(contact)}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{formatPhone(contact.phoneNumber)}</div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1'>
            <FormattedDate date={landingPageView.createdAt} format='extendedDateAndTime' />
          </div>
        </div>
      </div>
    </>
  )
}

LandingPageViewListItemContent.displayName = 'LandingPageViewListItemContent'
LandingPageViewListItemContent.propTypes = propTypes
LandingPageViewListItemContent.defaultProps = defaultProps

export default LandingPageViewListItemContent
