import useStore from '../store'

// For use outside of a component/hook context but ties into the store
export default async function loadFromCacheOrService (dataSourceKey, service, ...serviceArgs) {
  const { getState, setState } = useStore
  const cached = getState().remoteDataSources[dataSourceKey] // not a live connection
  if (cached) { return cached }
  const { call } = service(...serviceArgs)
  let reply
  try {
    reply = await call()
  } catch (e) {
    return null
  }
  if (!reply || !reply.ok) { return null }

  const data = reply.models || reply.model || reply.json
  setState((state) => {
    state.remoteDataSources[dataSourceKey] = data
  })
  return data
}
