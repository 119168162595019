import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import BulkEditActions from '../../../components/configurable-forms/actions/BulkEditActions'
import CreateAction from '../../../components/configurable-forms/actions/CreateAction'
import ConfigurableFormList from '../../../components/configurable-forms/ConfigurableFormList'
import CreateFolderAction from '../../../components/folders/actions/CreateFolderAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { CONTENT_TYPE } from '../../../lib/folder'
import { Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired,
  currentListFolderMode: PropType.bool
}

const defaultProps = {
  currentListFolderMode: false
}

const ListHeaderMoreMenu = ({ currentListFolderMode, listRef }) => {
  const createActionRef = useRef()
  const bulkEditRef = useRef()
  const createFolderActionRef = useRef()

  const handleBulkEditClick = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])
  const handleCreateFolderAction = useCallback(() => createFolderActionRef.current.activate(), [])
  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])
  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])

  const createHandler = currentListFolderMode ? handleCreateFolderAction : handleCreateSelected

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon='add' title='Create' onClick={createHandler} />
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuHeader label='Manage Configurable Forms' />
        <ActionMenuDivider />
        <ActionMenuItem label='Edit Configurable Forms' onClick={handleBulkEditClick} />
      </ActionMenu>
      <BulkEditDialog ref={bulkEditRef} actions={BulkEditActions} list={ConfigurableFormList} />
      <CreateFolderAction ref={createFolderActionRef} contentType={CONTENT_TYPE.configurableForm} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

ListHeaderMoreMenu.displayName = 'ListHeaderMoreMenu'
ListHeaderMoreMenu.propTypes = propTypes
ListHeaderMoreMenu.defaultProps = defaultProps

export default ListHeaderMoreMenu
