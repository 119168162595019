import PropType from 'prop-types'
import { forwardRef, useCallback, useId, useMemo, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import Form from '../forms/Form'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import ConfirmDialog from './ConfirmDialog'
import PanelDialog from './PanelDialog'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropType.object.isRequired,
  formControls: PropType.arrayOf(PropType.shape({ // TODO: this should mirror/use the FormControl props
    name: PropType.string.isRequired,
    autoComplete: PropType.string,
    autoFocus: PropType.bool,
    className: PropType.string,
    label: PropType.string,
    placeholder: PropType.string,
    type: PropType.string
  })).isRequired,
  title: PropType.string.isRequired,
  confirm: PropType.shape({
    description: PropType.string,
    title: PropType.string,
    when: PropType.func
  }),
  confirmCancel: PropType.string,
  description: PropType.string,
  submitTitle: PropType.string,
  transformData: PropType.func,
  trigger: PropType.node,
  validationSuite: PropType.func,
  onCancel: PropType.func,
  onClose: PropType.func,
  onSubmit: PropType.func
}

const defaultProps = {
  confirm: null,
  confirmCancel: null,
  description: null,
  onCancel: null,
  onClose: null,
  onSubmit: null,
  submitTitle: 'Save',
  transformData: undefined,
  trigger: null,
  validationSuite: null
}

const log = logger({ enabled: false, tags: ['FormPanelDialog'] })

const FormPanelDialog = forwardRef(({
  defaultValues,
  description,
  confirm,
  confirmCancel,
  formControls,
  title,
  trigger,
  transformData,
  validationSuite,
  onClose,
  onCancel,
  onSubmit,
  submitTitle
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const formId = useId()
  const confirmCancelRef = useRef()

  const handleSubmit = useCallback((data, event) => {
    onSubmit?.(data, event, ref.current.close)
  }, [onSubmit, ref])
  const handleCancel = useCallback(() => {
    ref.current.close()
    onCancel?.()
  }, [onCancel, ref])
  const handleCancelWithConfirm = useCallback(() => {
    confirmCancelRef.current.open()
  }, [])

  const cancleButton = useMemo(() => {
    return (
      <PanelHeaderButton icon='cancel' onClick={confirmCancel ? handleCancelWithConfirm : handleCancel} />
    )
  }, [confirmCancel, handleCancel, handleCancelWithConfirm])

  const submitButton = useMemo(() => {
    return (
      <PanelHeaderButton
        form={formId}
        icon='save'
        title={submitTitle}
        type='submit'
      />
    )
  }, [formId, submitTitle])

  return (
    <PanelDialog
      ref={ref}
      ariaDescription={description}
      ariaLabel={title}
      trigger={trigger}
      onClose={onClose}
    >
      <PanelHeader
        end={submitButton}
        start={cancleButton}
        title={title}
      />
      <PanelContent className='p-4'>
        {description ? <p className='mb-4'>{description}</p> : null}
        <Form
          confirm={confirm}
          defaultValues={defaultValues}
          formControls={formControls}
          id={formId}
          transformData={transformData}
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        {!!confirmCancel && (
          <ConfirmDialog
            ref={confirmCancelRef}
            description={confirmCancel}
            title='Please Confirm'
            onConfirm={handleCancel}
          />
        )}
      </PanelContent>
    </PanelDialog>
  )
})

FormPanelDialog.displayName = 'FormPanelDialog'
FormPanelDialog.propTypes = propTypes
FormPanelDialog.defaultProps = defaultProps

export default FormPanelDialog
