import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../components/detail-sections/DetailSection'
import MessageDetail from '../../components/detail-sections/MessageDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import StyledQrCodeContent from '../../components/qr-codes/StyledQrCodeContent'
import useCurrentKeyword from '../../hooks/useCurrentKeyword'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import { copyToClipboard } from '../../lib/copyToClipboard'
import { smsToUri } from '../../lib/formatters'
import logger from '../../lib/logger'
import { update as updateKeywordService } from '../../services/keyword'

import ContactLinkRedirectUrlDetail from './details/ContactLinkRedirectUrlDetail'
import DetailHeaderMoreMenu from './details/DetailHeaderMoreMenu'
import JoinCampaignsDetail from './details/JoinCampaignsDetail'
import JoinSpacesDetail from './details/JoinSpacesDetail'
import LeaveCampaignsDetail from './details/LeaveCampaignsDetail'
import NameDetail from './details/NameDetail'
import ReplyDelayDetail from './details/ReplyDelayDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['KeywordsDetail'] })

const KeywordsDetail = () => {
  const { keywordId, loading, keyword, setKeyword } = useCurrentKeyword()

  useLogger({ log, lifecycle: false, tags: [keywordId] })
  const currentUser = useCurrentUser()

  const handleDetailsUpdated = useCallback((updateReply) => {
    setKeyword(updateReply.json)
    bus.emit('keywordUpdated', updateReply.json)
  }, [setKeyword])

  const handleCopyClick = useCallback(() => {
    copyToClipboard(keyword.publicTextKeywordLink)
    notify('SMS URL copied to clipboard.')
  }, [keyword])

  if (loading || !keyword) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<DetailHeaderMoreMenu keyword={keyword} />}
        start={<PanelHeaderBackButton />}
        title={keyword?.word}
      />
      <PanelContent className='p-5'>
        <div>
          <NameDetail keyword={keyword} service={updateKeywordService} onChange={handleDetailsUpdated} />
          <JoinCampaignsDetail keyword={keyword} onChange={handleDetailsUpdated} />
          <LeaveCampaignsDetail keyword={keyword} onChange={handleDetailsUpdated} />
          <JoinSpacesDetail keyword={keyword} onChange={handleDetailsUpdated} />
          <DetailSection
            description='This is a link you can share that will attempt to open the text messaging app prefilled with your number and keyword.'
            title='SMS URL'
            showCopyIcon
            onCopyClick={handleCopyClick}
          >
            <div className='select-text'>{keyword.publicTextKeywordLink}</div>
          </DetailSection>
          <DetailSection description='Revolutionize Your SMS Engagement with Instant Keyword Insertion via QR Codes!' title='QR Code'>
            <StyledQrCodeContent size='sm' url={smsToUri(currentUser.phoneNumber, keyword?.word)} />
          </DetailSection>
          <ContactLinkRedirectUrlDetail keyword={keyword} service={updateKeywordService} onChange={handleDetailsUpdated} />
          <MessageDetail
            contentOptions={{ email: false, messageTemplates: true, voiceDrops: true }}
            description='Project Broadcast will reply to a sender with this reply message.'
            message={keyword}
            required={false}
            service={updateKeywordService}
            title='Reply Message'
            onChange={handleDetailsUpdated}
          />
          <ReplyDelayDetail keyword={keyword} service={updateKeywordService} onChange={handleDetailsUpdated} />
          <CheckboxDetailSection
            checked={keyword.enabled}
            description='Disabling this keyword will prevent replying, auto-tagging, and adding contacts to campaigns.'
            name='enabled'
            objectId={keyword._id}
            service={updateKeywordService}
            title='Enabled'
            onChange={handleDetailsUpdated}
          />
          <CheckboxDetailSection
            checked={keyword.autoTag}
            description='When enabled, each contact that sends this keyword will be tagged with the keyword.'
            name='autoTag'
            objectId={keyword._id}
            service={updateKeywordService}
            title='Auto-Tag'
            onChange={handleDetailsUpdated}
          />
          <CheckboxDetailSection
            checked={keyword.doNotMessage}
            description='When enabled, each contact that sends this keyword will be marked as Do Not Message.'
            name='doNotMessage'
            objectId={keyword._id}
            service={updateKeywordService}
            title='Do Not Message'
            onChange={handleDetailsUpdated}
          />
          <CheckboxDetailSection
            checked={keyword.doNotEmail}
            description='When enabled, each contact that sends this keyword will be marked as Do Not Email.'
            name='doNotEmail'
            objectId={keyword._id}
            service={updateKeywordService}
            title='Do Not Email'
            onChange={handleDetailsUpdated}
          />
          <CheckboxDetailSection
            checked={keyword.optIn}
            description='When enabled, each contact that sends this will be marked as Opted In. You should always display your terms along with the keyword.'
            name='optIn'
            objectId={keyword._id}
            service={updateKeywordService}
            title='Opt In'
            onChange={handleDetailsUpdated}
          />
        </div>
      </PanelContent>
    </Panel>
  )
}

KeywordsDetail.displayName = 'KeywordsDetail'
KeywordsDetail.propTypes = propTypes
KeywordsDetail.defaultProps = defaultProps

export default KeywordsDetail
