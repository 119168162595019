import * as React from "react";
import { memo } from "react";
const SvgInfo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm-1.934 9.796h2.957a.484.484 0 0 1 .365.12c.1.09.163.217.172.354.048.248 0 .483-.049.718-.011.058-.023.115-.033.173a684.32 684.32 0 0 0-.572 3.212c-.02.116-.042.232-.064.349-.05.276-.101.553-.14.832-.019.16-.014.322.014.481a.49.49 0 0 0 .193.355.458.458 0 0 0 .387.078c.166-.039.328-.096.482-.17.056-.025.11-.057.163-.089.073-.043.146-.086.224-.113a.29.29 0 0 1 .26.03c.063.09.088.203.068.313a.79.79 0 0 1-.206.33 2.713 2.713 0 0 1-2.003.897 6.804 6.804 0 0 1-1.059-.07c-.592-.102-1.337-.878-1.207-1.706.044-.273.084-.546.125-.819.05-.342.101-.684.157-1.025.053-.327.11-.652.166-.978.101-.583.202-1.166.285-1.752.069-.488-.116-.696-.574-.718a1.46 1.46 0 0 1-.503-.14.307.307 0 0 1-.165-.352.313.313 0 0 1 .24-.264c.104-.03.21-.045.317-.046Zm1.287-3.385c.293-.28.682-.428 1.08-.41v.004c.393.014.763.188 1.03.484.269.295.413.689.402 1.094a1.574 1.574 0 0 1-.465 1.09 1.47 1.47 0 0 1-1.075.425 1.476 1.476 0 0 1-1.05-.49 1.58 1.58 0 0 1-.403-1.115c.015-.413.188-.802.481-1.082Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgInfo);
export default Memo;
