import PropType from 'prop-types'
import { forwardRef, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import { sendChatMessage } from '../../../services/aiva'

const propTypes = {
  message: PropType.shape(messageShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ResendChatMessageAction'] })

const ResendChatMessageAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { call: sendChatMessageCall } = useService(sendChatMessage, {
    onReplyOk: (reply) => bus.emit('aivaChatMessageResent', reply.model)
  })

  useImperativeHandle(ref, () => ({
    activate () {
      sendChatMessageCall(message, true)
    }
  }), [message, sendChatMessageCall])

  return null
})

ResendChatMessageAction.displayName = 'ResendChatMessageAction'
ResendChatMessageAction.propTypes = propTypes
ResendChatMessageAction.defaultProps = defaultProps

export default ResendChatMessageAction
