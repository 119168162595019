import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as bundleSearch } from '../../services/bundle'
import List from '../list/List'

import BundleListItemContent from './BundleListItemContent'

const propTypes = {
  type: PropType.oneOf(['bulk', 'move', 'nav', 'remove', 'select']).isRequired,
  additionalHandlers: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {}
}

const bundleResultsFields = [
  '_id',
  'name',
  'campaignsSummary',
  'templatesSummary',
  'keywordsSummary',
  'landingPagesSummary',
  'attachmentsSummary'
]

const log = logger({ enabled: true, tags: ['BundleList'] })

const BundleList = forwardRef(({ additionalHandlers, type, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tag: [] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('bundleCreated', handleBusEvent)
  useBus('bundleUpdated', handleBusEvent)
  useBus('bundleDeleted', handleBusEvent)
  useBus('bundlesDeleted', handleBusEvent)

  additionalHandlers?.('bundle', ref)

  return (
    <List
      ref={ref}
      ListItemContent={BundleListItemContent}
      placeholder='Search Bundles'
      resultsFields={bundleResultsFields}
      resultsKey='bundles'
      searchService={bundleSearch}
      type={type}
      search
      {...rest}
    />
  )
})

BundleList.displayName = 'BundleList'
BundleList.propTypes = propTypes
BundleList.defaultProps = defaultProps

export default BundleList
