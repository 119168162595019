import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import CreateAction from '../../../components/scheduled-broadcast/actions/CreateAction'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Facebook, Instagram, Linkedin, ProjectBroadcast, Twitter } from '../../../svg/icons'

const propTypes = {
  program: PropType.shape({
    percentOff: PropType.number,
    percentOffYear: PropType.number
  }).isRequired,
  signupUrl: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['share'] })

const openSocialWindow = function (url) {
  const left = (screen.width - 570) / 2
  const top = (screen.height - 750) / 2
  const params = `menubar=no,toolbar=no,status=no,width=570,height=750,top=${top},left=${left}`
  window.open(url, 'ShareWindow', params)
}

const trackShareEngagement = function (label) {
  window.gtag('event', 'share', { event_category: 'engagement', event_label: label })
}

const ShareReferralLink = ({ program, signupUrl }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const newBroadcastActionRef = useRef()

  const pbShareMessage = useMemo(() => ({
    type: 'sms',
    text: `I'm loving Project Broadcast! You can use my link below to get ${program?.percentOff}% off your first month of a monthly subscription or ${program?.percentOffYear}% off your first year on a yearly subscription!\n\n{{myReferralLink}}`
  }), [program])
  const handleShareOnPBClick = useCallback(() => {
    trackShareEngagement('PB In Referral Share')
    newBroadcastActionRef.current.activate()
  }, [])

  const handleShareOnFacebookClick = useCallback(() => {
    trackShareEngagement('FB Referral Share')
    const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(signupUrl)}`
    openSocialWindow(url)
  }, [signupUrl])

  const handleShareOnInstagramClick = useCallback(() => {
    notify("Instagram doesn't provide a shortcut for sharing.", 'We have copied your link to your clipboard so you can share directly via Instagram.')
  }, [])

  const handleShareOnLinkedInClick = useCallback(() => {
    trackShareEngagement('Linked In Referral Share')
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(signupUrl)}`
    openSocialWindow(url)
  }, [signupUrl])

  const handleShareOnTwitterClick = useCallback(() => {
    trackShareEngagement('Twitter In Referral Share')
    const message = `Loving Project Broadcast!! Use my link below to get ${program?.percentOff}% off your first month of a monthly subscription or ${program?.percentOffYear}% off your first year on a yearly subscription!`
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(signupUrl)}&text=${encodeURIComponent(message)}`
    openSocialWindow(url)
  }, [program, signupUrl])

  return (
    <div>
      <div className='mb-3'>
        <div className='headline-2xs text-black mb-1'>Multiple Ways to Share!</div>
        <div className='paragraph-sm'>You have options! Easily share your love of Project Broadcast on multiple platforms.</div>
      </div>
      <div className='flex flex-row gap-3 justify-center'>
        <div className='bg-indigo-50 rounded-full p-3 w-[50px] h-[50px] cursor-pointer' onClick={handleShareOnPBClick}>
          <ProjectBroadcast />
        </div>
        <div className='bg-indigo-50 rounded-full p-3 w-[50px] h-[50px] cursor-pointer' onClick={handleShareOnFacebookClick}>
          <Facebook />
        </div>
        <div className='bg-indigo-50 rounded-full p-3 w-[50px] h-[50px] cursor-pointer' onClick={handleShareOnInstagramClick}>
          <Instagram />
        </div>
        <div className='bg-indigo-50 rounded-full p-3 w-[50px] h-[50px] cursor-pointer' onClick={handleShareOnLinkedInClick}>
          <Linkedin />
        </div>
        <div className='bg-indigo-50 rounded-full p-3 w-[50px] h-[50px] cursor-pointer' onClick={handleShareOnTwitterClick}>
          <Twitter />
        </div>
      </div>
      <CreateAction ref={newBroadcastActionRef} message={pbShareMessage} />
    </div>
  )
}

ShareReferralLink.displayName = 'ShareReferralLink'
ShareReferralLink.propTypes = propTypes
ShareReferralLink.defaultProps = defaultProps

export default ShareReferralLink
