export default function proxy (json, overrides) {
  const overrideKeys = Object.keys(overrides)

  return new Proxy(json, {
    // eslint-disable-next-line no-unused-vars
    get (target, prop, receiver) {
      if (overrideKeys.includes(prop)) {
        return Reflect.get(overrides, prop)
      }
      return Reflect.get(...arguments)
    }
  })
}
