import { forEach } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import useService from '../../hooks/useService'
import { emailTemplatesForPicker, landingPageTemplatesForPicker } from '../../services/unlayer-template'

const propTypes = {
  which: PropType.oneOf(['landingPage', 'email']).isRequired,
  onSelected: PropType.func
}

const defaultProps = {
  onSelected: undefined
}

const service = {
  email: emailTemplatesForPicker,
  landingPage: landingPageTemplatesForPicker
}

const Templates = ({
  which,
  onSelected
}) => {
  const [templates, setTemplates] = useState()

  const handleReplyOk = useCallback((reply) => {
    setTemplates(reply.json)
  }, [])
  const { call: fetchTemplates } = useService(service[which], { onReplyOk: handleReplyOk })

  useEffect(() => {
    if (!templates) {
      fetchTemplates({ background: true })
    }
  }, [templates, fetchTemplates])

  const handleOnClick = (template) => {
    onSelected && onSelected(template)
  }

  if (!templates) { return null }

  const items = []
  forEach(templates, (template) => {
    items.push(
      <div key={template._id} style={{ cursor: 'pointer' }} onClick={() => handleOnClick(template)}>
        <div style={{
          height: '200px',
          overflow: 'hidden'
        }}
        >
          <img
            height='200'
            src={template.previewImageUrl}
          />
        </div>
        <p className='p-2'>{template.name}</p>
      </div>
    )
  })

  return (
    <div className='container grid md:grid-cols-3 lg:grid-cols-5 gap-5 text-center'>
      {items}
    </div>
  )
}

Templates.displayName = 'Templates'
Templates.propTypes = propTypes
Templates.defaultProps = defaultProps

export default Templates
