import * as React from "react";
import { memo } from "react";
const SvgBundle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Bundle_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m12.5 3.622 4.155 6.38h-8.31l4.155-6.38Zm1.048-2.055 5.494 8.435H23a1 1 0 0 1 .953 1.304l-3.5 11a1 1 0 0 1-.953.696h-14a1 1 0 0 1-.953-.696l-3.5-11A1 1 0 0 1 2 10.002h3.958l5.5-8.444a1.252 1.252 0 0 1 2.084 0l.006.009Zm4.965 10.435H3.367l2.864 9H18.77l2.863-9h-3.12Zm-5.013 3a1 1 0 0 0-2 0v3a1 1 0 1 0 2 0v-3Zm-5-1a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm9 1a1 1 0 0 0-2 0v3a1 1 0 1 0 2 0v-3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Bundle_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBundle);
export default Memo;
