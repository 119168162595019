import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('lowCreditThreshold', 'Credit is required', () => {
    enforce(data.lowCreditThreshold).isNotEmpty()
  })
  test('renewalCreditAmount', 'Credit is required', () => {
    enforce(data.renewalCreditAmount).isNotEmpty()
  })
})

export default validationSuite
