import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Tooltip from '../../components/tooltip/Tooltip'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Tooltips'] })

const Tooltips = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Panel>
      <PanelHeader title='Tooltips' />
      <PanelContent>
        <div className='p-4'>
          <Tooltip content='Test tooltip' trigger={<Button className='mb-4'>Test</Button>} />
          <Tooltip content='Test tooltip' placement='right' trigger={<Button>Test Right</Button>} />
        </div>
      </PanelContent>
    </Panel>
  )
}

Tooltips.displayName = 'Tooltips'
Tooltips.propTypes = propTypes
Tooltips.defaultProps = defaultProps

export default Tooltips
