import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import VoiceDropsDetail from '../screens/voice-drops/VoiceDropsDetail'
import VoiceDropsFolderDetail from '../screens/voice-drops/VoiceDropsFolderDetails'

const voiceDropRoutes = [
  {
    path: ':voiceDropId',
    element: <VoiceDropsDetail />
  }
]

const routes = [
  ...voiceDropRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<VoiceDropsFolderDetail />} />,
    children: [...voiceDropRoutes]
  }
]

export default routes
