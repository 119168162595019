import * as React from 'react'
import { memo } from 'react'
const SvgQrCode = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#E1E7FF" rx={4} />
        <g clipPath="url(#QR_Code_svg__a)">
            <path
                fill="#5046E5"
                fillRule="evenodd"
                d="M9.273 5.455H5.455v4.09h1.09v-3h2.728v-1.09Zm-3.818 13.09V15h1.09v2.455h2.728v1.09H5.455Zm12-1.09V15h1.09v3.546h-3.818v-1.091h2.728Zm1.09-12v4.09h-1.09v-3h-2.728v-1.09h3.819Zm-8.181 3.272H8.727v1.637h1.637V8.727Zm-2.728-1.09v3.818h3.819V7.636H7.636Zm7.637 1.09h-1.637v1.637h1.637V8.727Zm-2.727-1.09v3.818h3.818V7.636h-3.819Zm-3.819 6h1.637v1.636H8.727v-1.637Zm-1.09 2.727v-3.819h3.818v3.819H7.636Zm7.636-2.728h-1.637v1.637h1.637v-1.637Zm-2.727-1.09v3.818h3.818v-3.819h-3.819Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="QR_Code_svg__a">
                <path fill="#fff" d="M5.455 5.455h13.09v13.09H5.456z" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgQrCode)
export default Memo
