import { useCallback, useEffect, useRef, useState } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import FormDialog from '../../../components/dialog/FormDialog'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import { formatPhone } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { sendTcrOtp } from '../../../services/user'
import { update as updateAction } from '../../../store/actions/currentUser'

const propTypes = {}

const defaultProps = {}

const defaultValues = {
  mobilePhone: ''
}

const log = logger({ enabled: false, tags: ['SendOtp'] })

const SendOtp = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formDialogRef = useRef()
  const [sent, setSent] = useState(false)
  const [formattedPhone, setFormattedPhone] = useState()
  const currentUser = useCurrentUser()

  const handleSendOtpSuccess = useCallback(() => {
    notify('Request sent', `An SMS has been sent to ${formattedPhone}.`)
  }, [formattedPhone])

  const { call: sendOtp } = useServiceAndAction(sendTcrOtp, updateAction, { onReplyOk: handleSendOtpSuccess })

  const handleClick = useCallback(() => {
    formDialogRef.current.open()
  }, [])

  const handleSubmit = useCallback((data) => {
    const { mobilePhone } = data
    if (mobilePhone) {
      setFormattedPhone(formatPhone(mobilePhone))
    }
    sendOtp(data)
    setSent(true)
  }, [sendOtp])

  useEffect(() => {
    setFormattedPhone(formatPhone(currentUser?.registration?.brand?.mobilePhone))
  }, [currentUser])

  const formControls = [
    currentUser?.registration?.isTwilio
      ? {
          name: 'display-phone-number',
          type: 'passthrough',
          element: (
            <p className='mb-5'>
              An SMS will be sent to
              {' '}
              <span className='whitespace-nowrap'>
                {formattedPhone}
                .
              </span>
              {' '}
              If this is not the correct number, please contact support.
            </p>
          )
        }
      : {
          label: 'Mobile Phone',
          name: 'mobilePhone',
          placeholder: 'Enter your mobile phone number',
          hint: 'A 10-digit mobile phone number used to verify your identity via SMS.',
          type: 'tel',
          required: true
        }
  ].filter((c) => c)

  defaultValues.mobilePhone = formattedPhone

  if (sent || !currentUser) {
    return null
  }

  return (
    <>
      <p className='mb-5'>
        If you did not receive an SMS, click the button below to request another verification SMS be sent.
      </p>
      <div>
        <Button
          size='sm'
          theme='primary'
          type='button'
          onClick={handleClick}
        >
          Resend SMS Verification
        </Button>
      </div>
      <FormDialog
        ref={formDialogRef}
        defaultValues={defaultValues}
        description='Request another validation SMS to be sent to your mobile phone.'
        formControls={formControls}
        submitTitle='Send'
        title='Send validation SMS'
        onSubmit={handleSubmit}
      />
    </>
  )
}

SendOtp.displayName = 'SendOtp'
SendOtp.propTypes = propTypes
SendOtp.defaultProps = defaultProps

export default SendOtp
