import { pick } from 'lodash'
import { useCallback, useMemo } from 'react'

import { notify } from '../../components/banners/Banner'
import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../components/detail-sections/DetailSection'
import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import LandingPageEditor from '../../components/landing-pages/LandingPageEditor'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import StyledQrCodeContent from '../../components/qr-codes/StyledQrCodeContent'
import UnlayerDesignDetailSection from '../../components/unlayer/UnlayerDesignDetailSection'
import useCurrentLandingPage from '../../hooks/useCurrentLandingPage'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import { copyToClipboard } from '../../lib/copyToClipboard'
import logger from '../../lib/logger'
import { update as updateLandingPageService, updateSocialSharingImage as updateSocialSharingImageService } from '../../services/landingPage'
import { Tag } from '../../svg/icons'

import DetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import SocialSharingImageDetail from './detail/SocialSharingImageDetail'

const log = logger({ enabled: true, tags: ['LandingPagesDetail'] })

const LandingPagesDetail = () => {
  const { landingPageId, loading, landingPage, setLandingPage } = useCurrentLandingPage()

  useLogger({ log, lifecycle: false, tags: [landingPageId] })

  const handleDetailsUpdated = useCallback((updateReply) => {
    setLandingPage(updateReply.model)
    bus.emit('landingPageUpdated', updateReply.model)
  }, [setLandingPage])

  const handleCopyClick = useCallback(() => {
    copyToClipboard(landingPage.publicUrl)
    notify('URL copied to clipboard.')
  }, [landingPage?.publicUrl])

  const urlWarning = useMemo(() => {
    if (!landingPage?.unlayerDesign || !landingPage?.usesContactDynamicFields) { return '' }
    return 'This landing page uses contact dynamic fields. The contact dynamic fields will only work when inserting this landing page into a message or if properly wrapped in a display condition.'
  }, [landingPage])

  if (loading || !landingPage) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<DetailHeaderMoreMenu landingPage={landingPage} />}
        start={<PanelHeaderBackButton />}
        title={landingPage.name}
      />
      <PanelContent className='p-5'>
        <FormDetailSection
          defaultValues={pick(landingPage, ['name'])}
          description='Name the landing page so it is easy to find later.'
          formControls={[{ autoComplete: 'off', label: 'Name', name: 'name', type: 'text', required: true }]}
          formDescription={`Updating landing page for ${landingPage.name}`}
          formTitle={landingPage.name}
          objectId={landingPage._id}
          service={updateLandingPageService}
          title='Name'
          showEditIcon
          onChange={handleDetailsUpdated}
        >
          {landingPage.name}
        </FormDetailSection>
        <FormDetailSection
          defaultValues={pick(landingPage, ['title'])}
          description='This is what shows up as the title in the browser tab and when the link is shared.'
          formControls={[{ label: 'Title', name: 'title', type: 'text', required: true }]}
          formDescription={`Updating landing page for ${landingPage.name}`}
          formTitle={landingPage.title}
          objectId={landingPage._id}
          service={updateLandingPageService}
          title='Title'
          showEditIcon
          onChange={handleDetailsUpdated}
        >
          {landingPage.title}
        </FormDetailSection>
        <FormDetailSection
          defaultValues={pick(landingPage, ['description'])}
          description='This shows up when the link is shared. About 155 characters is best.'
          formControls={[{ label: 'Description', name: 'description', type: 'text', required: true }]}
          formDescription={`Updating landing page for ${landingPage.name}`}
          formTitle={landingPage.description}
          objectId={landingPage._id}
          service={updateLandingPageService}
          title='Description'
          showEditIcon
          onChange={handleDetailsUpdated}
        >
          {landingPage.description}
        </FormDetailSection>
        <SocialSharingImageDetail
          landingPage={landingPage}
          removeService={updateLandingPageService}
          uploadService={updateSocialSharingImageService}
          onChange={handleDetailsUpdated}
        />
        {
          landingPage.unlayerDesign
            ? (
              <DetailSection
                description='This is a direct link to your landing page, however it is recommended to insert this landing page in a message to get better analytics.'
                title='URL'
                warning={urlWarning}
                showCopyIcon
                onCopyClick={handleCopyClick}
              >
                <div className='select-text'>{landingPage.publicUrl}</div>
              </DetailSection>
              )
            : null
        }
        {
          landingPage.unlayerDesign
            ? (
              <DetailSection description='Revolutionize engagement via QR Codes!' title='QR Code'>
                <StyledQrCodeContent size='sm' url={landingPage.publicUrl} />
              </DetailSection>
              )
            : null
        }
        <CheckboxDetailSection
          checked={landingPage.autoTag}
          description='When enabled, each contact that clicks this link will be tagged with the name of the Landing Page formatted as a tag.'
          name='autoTag'
          objectId={landingPage._id}
          service={updateLandingPageService}
          title='Auto-Tag'
          onChange={handleDetailsUpdated}
        />
        <div className='flex flex-row items-center label-xs text-neutral-500 gap-1 -mt-6 mb-6'>
          <span>Tag with:</span>
          <Tag className='text-black shrink-0 grow-0 h-[1rem] w-[1rem] ml-1' />
          <div className='line-clamp-1 break-words'>{landingPage.tagName}</div>
        </div>
        <DetailSection
          count={landingPage?.landingPageViews?.length || 0}
          description='Who has viewed your page and when. We do our best to filter out bots.'
          editHref='views'
          editIcon='chevron'
          title='Views'
          hideContent
          showEditIcon
        />
        <UnlayerDesignDetailSection
          Editor={LandingPageEditor}
          record={landingPage}
          service={updateLandingPageService}
          which='landingPage'
          onChange={handleDetailsUpdated}
        />
      </PanelContent>
    </Panel>
  )
}

LandingPagesDetail.displayName = 'LandingPagesDetail'

export default LandingPagesDetail
