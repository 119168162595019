import { get, isNumber } from 'lodash'
import PropType from 'prop-types'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import useLatestStackPath from '../../../hooks/useLatestStackPath'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import useStore from '../../../store'

import IconLabelChevron from './IconLabelChevron'
import Indicator from './Indicator'

const propTypes = {
  icon: PropType.element.isRequired,
  indicator: PropType.string.isRequired, // global store prop path to check in order to display indicator
  label: PropType.string.isRequired,
  path: PropType.string.isRequired,
  stack: PropType.string
}

const defaultProps = {
  stack: null
}

const log = logger({ enabled: true, tags: ['MenuItemWithIndicator'] })

const computeClassName = ({ isActive/*, isPending */ }) => {
  const base = 'w-full'
  return isActive ? `${base} text-primary` : `${base} text-black`
}

const MenuItemWithIndicator = ({ icon, indicator, label, path, stack }) => {
  useLogger({ log, lifecycle: false })

  const navIndicatorValue = useStore((state) => get(state, indicator))
  const [indicatorVisible, setIndicatorVisible] = useState(!!navIndicatorValue)
  useEffect(() => { setIndicatorVisible(!!navIndicatorValue) }, [navIndicatorValue])

  const stackPath = useLatestStackPath(stack, path)

  return (
    <li className='relative w-[278px] active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50 select-none'>
      {indicatorVisible ? <Indicator /> : null}
      <NavLink className={computeClassName} to={stackPath} aria-current>
        <IconLabelChevron
          count={isNumber(navIndicatorValue) ? navIndicatorValue : undefined}
          icon={icon}
          label={label}
        />
      </NavLink>
    </li>
  )
}

MenuItemWithIndicator.displayName = 'MenuItemWithIndicator'
MenuItemWithIndicator.propTypes = propTypes
MenuItemWithIndicator.defaultProps = defaultProps

export default MenuItemWithIndicator
