import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { mergeRefs } from 'react-merge-refs'

import useDefaultId from '../../../hooks/useDefaultId'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

const propTypes = {
  name: PropType.string.isRequired,
  autoFocus: PropType.bool,
  defaultValue: PropType.string,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  options: PropType.arrayOf(PropType.shape({
    label: PropType.string.isRequired,
    value: PropType.oneOfType([
      PropType.string,
      PropType.arrayOf(PropType.shape({
        label: PropType.string.isRequired,
        value: PropType.string.isRequired,
        disabled: PropType.bool
      }))
    ]).isRequired,
    disabled: PropType.bool
  })),
  placeholder: PropType.string,
  onChange: PropType.func
}

const defaultProps = {
  autoFocus: false,
  defaultValue: null,
  error: null,
  hint: null,
  id: null,
  label: null,
  onChange: null,
  options: [],
  placeholder: null
}

const log = logger({ enabled: false, tags: ['Select'] })

const Select = forwardRef(({
  autoFocus,
  defaultValue,
  error,
  hint,
  id,
  label,
  name,
  options,
  placeholder,
  onChange,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  id = useDefaultId(id)

  // force default value to empty string if falsey
  defaultValue = defaultValue || ''

  useLogger({ log, lifecycle: true, tags: [name, id] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  const optionElements = useMemo(() => {
    const elements = generateOptions(options)
    if (placeholder) {
      elements.unshift(
        <option
          key={`placeholder-${placeholder}`}
          value=''
          disabled
        >
          {placeholder}
        </option>
      )
    }
    return elements
  }, [options, placeholder])

  const registerProps = useFormMethods?.register
    ? useFormMethods.register(name, { onChange })
    : { onChange }

  const selectClassNames = classNames('border border-neutral-300 rounded-md w-full bg-white')

  const mergedRef = mergeRefs([ref, registerProps.ref])
  delete registerProps.ref

  return (
    <>
      <InputLabel id={id} text={label} />
      <select
        ref={mergedRef}
        autoFocus={autoFocus}
        className={selectClassNames}
        defaultValue={defaultValue}
        id={id}
        {...rest}
        {...registerProps}
      >
        {optionElements}
      </select>
      <InputError message={error?.message || error} />
      <InputHint message={hint} />
    </>
  )
})

Select.displayName = 'Select'
Select.propTypes = propTypes
Select.defaultProps = defaultProps

function generateOptions (options) {
  return options.map(({ label, value, disabled }) => {
    if (Array.isArray(value)) {
      return <optgroup key={`optgroup-${label}`} disabled={disabled} label={label}>{generateOptions(value)}</optgroup>
    } else {
      return <option key={`${label}-${value}`} disabled={disabled} value={value}>{label}</option>
    }
  })
}

export default Select
