import { includes } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import FormattedDate from '../../../components/FormattedDate'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatTimeForInput } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { dateAtTimeOfDayInTimeZone } from '../../../lib/timeZones'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventTimeOfDayDetail'] })

const EventTimeOfDayDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent?._id] })

  const { userTimeZone, loading: timeZoneInfoLoading } = useTimeZone()

  const { confirm, formControls, defaultValues, transformData, value } = useMemo(() => {
    return {
      confirm: {
        description: `Updating to an earlier time may reschedule some broadcasts originated by this
                      campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
        when: ({ scheduledAt }) => {
          if (includes(['repeat', 'schedule'], campaignEvent.campaignType)) {
            return new Date(scheduledAt) < new Date()
          } else {
            return new Date(scheduledAt) < new Date(campaignEvent.scheduledAt)
          }
        }
      },
      formControls: [
        { label: '', name: 'timeOfDay', type: 'time-message-send' }
      ],
      defaultValues: {
        timeOfDay: formatTimeForInput(campaignEvent.scheduledAt, userTimeZone)
      },
      transformData: ({ timeOfDay }) => ({
        scheduledAt: dateAtTimeOfDayInTimeZone(campaignEvent.scheduledAt, timeOfDay, userTimeZone)?.toISOString()
      }),
      value: <FormattedDate date={campaignEvent.scheduledAt} format='timeOfDay' />
    }
  }, [campaignEvent, userTimeZone])

  return (!timeZoneInfoLoading &&
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the time of day to send this message.'
      formControls={formControls}
      formDescription='Updating send time for this message'
      formTitle='Time'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Time'
      transformData={transformData}
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventTimeOfDayDetail.displayName = 'EventTimeOfDayDetail'
EventTimeOfDayDetail.propTypes = propTypes
EventTimeOfDayDetail.defaultProps = defaultProps

export default EventTimeOfDayDetail
