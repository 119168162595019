import aivaMessageFromJSON from '../models/aiva-message'

import pb from './pb'

export function messageRevisionPromptOptions () {
  return pb.setupGet('aiva/messages/prompts')
}

export function applyMessageRevision (promptId, message) {
  return pb.setupPost(`aiva/messages/prompts/${promptId}`, {
    apiLoadingMessageOverride: 'Generating...',
    body: { message }
  })
}

export function chatMessages (body, background = false) {
  const options = {
    background,
    model: {
      conversion: aivaMessageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  // TODO: prop type check body
  const { paging: { page, pageSize } } = body
  return pb.setupGet(`aiva/chat/messages?page=${page}&pageSize=${pageSize}`, options)
}

export function pollChatMessage (messageId, background = false) {
  const options = {
    background,
    model: {
      conversion: aivaMessageFromJSON
    }
  }
  return pb.setupGet(`aiva/chat/messages/${messageId}/poll`, options)
}

export function rateChatMessage (messageId, rating, background = false) {
  const options = {
    background,
    body: { rating },
    model: {
      conversion: aivaMessageFromJSON
    }
  }
  return pb.setupPut(`aiva/chat/messages/${messageId}/rate`, options)
}

export function sendChatMessage (body, background = false) {
  // TODO: prop type check body
  const options = {
    background,
    body,
    model: {
      conversion: aivaMessageFromJSON
    }
  }
  return pb.setupPost('aiva/chat/messages', options)
}

export function chatMessagesAfter (body, background = false) {
  const options = {
    background,
    model: {
      conversion: aivaMessageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  // TODO: prop type check body
  const { messageId } = body
  return pb.setupGet(`aiva/chat/messages/after/${messageId}`, options)
}
