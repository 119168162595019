import * as React from "react";
import { memo } from "react";
const SvgPhoneInboundCall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.619 3.795 7.709.885a3.01 3.01 0 0 0-4.265 0l-1.38 1.38C.382 3.948-.31 6.346.13 8.66l.001.005c1.498 7.658 7.54 13.712 15.2 15.2a7.107 7.107 0 0 0 6.399-1.934l1.38-1.38a3.01 3.01 0 0 0 0-4.265l-2.91-2.91a3.01 3.01 0 0 0-4.265 0l-1.92 1.921a11.571 11.571 0 0 1-5.317-5.315L10.62 8.06a3.01 3.01 0 0 0 0-4.265ZM4.859 2.3c.4-.4 1.036-.4 1.435 0l2.91 2.91c.4.399.4 1.036 0 1.435l-2.41 2.41a1 1 0 0 0-.215 1.093 13.565 13.565 0 0 0 7.266 7.267 1 1 0 0 0 1.094-.215l2.41-2.41c.4-.4 1.036-.4 1.435 0l2.91 2.91c.4.399.4 1.036 0 1.435l-1.38 1.38a5.109 5.109 0 0 1-4.602 1.386C8.854 20.569 3.436 15.144 2.093 8.283c-.32-1.686.19-3.407 1.386-4.603l1.38-1.38Zm11.434 5.407A.997.997 0 0 1 16 7.01V2a1 1 0 1 1 2 0v2.586L22.293.293a1 1 0 1 1 1.414 1.414L19.414 6H22a1 1 0 1 1 0 2h-5l-.048-.001a.996.996 0 0 1-.66-.292Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPhoneInboundCall);
export default Memo;
