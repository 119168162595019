import { omit } from 'lodash'
import PropType from 'prop-types'

import campaignFromJSON from '../models/campaign'
import contactFromJSON from '../models/contact'

import { addRemoveAllInQueryPropTypes, addRemoveSelectedPropTypes, searchPropTypes } from './campaign-contact.prop-types'
import pb from './pb'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'campaign-contact#search')

  const { campaignId } = data
  const body = omit(data, 'campaignId')
  const model = {
    conversion: contactFromJSON,
    key: 'contacts',
    multiple: true
  }
  return pb.setupPost(`campaigns/${campaignId}/contacts/search`, { body, background, model })
}

export function addSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'campaign-contact#addSelected')
  const { campaignId } = data
  const body = omit(data, 'campaignId')
  return pb.setupPut(`campaigns/${campaignId}/contacts/add`, {
    body,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  })
}

export function addAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'campaign-contact#addAllInQuery')
  const { campaignId } = data
  const body = omit(data, 'campaignId')
  return pb.setupPut(`campaigns/${campaignId}/contacts/add/all`, {
    body,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  })
}

export function removeSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'campaign-contact#removeSelected')
  const { campaignId } = data
  const body = omit(data, 'campaignId')
  return pb.setupPut(`campaigns/${campaignId}/contacts/remove`, {
    body,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  })
}

export function removeAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'campaign-contact#removeAllInQuery')
  const { campaignId } = data
  const body = omit(data, 'campaignId')
  return pb.setupPut(`campaigns/${campaignId}/contacts/remove/all`, {
    body,
    model: {
      conversion: campaignFromJSON,
      key: 'campaign'
    }
  })
}
