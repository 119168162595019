import classNames from 'classnames'
import { isString } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useId, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { success } from '../banners/Banner'
import ConfirmDialog from '../dialog/ConfirmDialog'

const propTypes = {
  name: PropType.string.isRequired,
  objectId: PropType.string.isRequired,
  service: PropType.func.isRequired,
  checked: PropType.bool,
  className: PropType.string,
  confirmOnSet: PropType.oneOfType([PropType.string, PropType.bool]),
  description: PropType.string,
  title: PropType.string,
  transformData: PropType.func,
  onChange: PropType.func
}

const defaultProps = {
  checked: false,
  className: null,
  confirmOnSet: false,
  description: null,
  onChange: null,
  title: null,
  transformData: null
}

const log = logger({ enabled: true, tags: ['CheckboxDetailSection'] })

const CheckboxDetailSection = ({ checked, className, confirmOnSet, description, name, service, objectId, title, transformData, onChange }) => {
  useLogger({ log, lifecycle: true, tags: [objectId, name] })

  const ref = useRef()
  const confirmRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    // TODO: perhaps just let the parent component do the success message?
    success(`${title} updated`)
    onChange?.(reply)
  }, [onChange, title])
  const { call } = useService(service, { onReplyOk: handleReplyOk })

  const labelId = useId()
  const descriptionId = useId()

  const computedClassName = classNames('mb-4', className)

  const handleChange = useCallback(() => {
    if (ref.current.checked && confirmOnSet) {
      confirmRef.current.open()
    } else {
      const data = { [name]: ref.current.checked }
      call(objectId, transformData ? transformData(data) : data)
    }
  }, [call, confirmOnSet, name, objectId, transformData])

  const handleConfirmOnSet = useCallback(() => {
    call(objectId, { [name]: true })
  }, [call, name, objectId])

  return (
    <>
      <div className={computedClassName}>
        <div className='flex flex-row flex-nowrap mb-3'>
          <div className='mr-auto'>
            <div className='headline-2xs text-black mb-1' id={labelId}>{title}</div>
            <div className='paragraph-sm' id={descriptionId}>{description}</div>
          </div>
          <div className='flex items-center ml-1'>
            <input
              ref={ref}
              aria-describedby={descriptionId}
              aria-labelledby={labelId}
              checked={checked}
              name={name}
              type='checkbox'
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {(!!confirmOnSet) && (
        <ConfirmDialog
          ref={confirmRef}
          description={isString(confirmOnSet) ? confirmOnSet : `${description} Are you sure?`}
          title='Confirm Change'
          onConfirm={handleConfirmOnSet}
        />
      )}
    </>
  )
}

CheckboxDetailSection.displayName = 'CheckboxDetailSection'
CheckboxDetailSection.propTypes = propTypes
CheckboxDetailSection.defaultProps = defaultProps

export default CheckboxDetailSection
