import FormControl from '../../../components/forms/FormControl'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'

const controls = [
  {
    type: 'checkbox',
    name: 'checkboxNoLabel1'
  },
  {
    type: 'checkbox',
    name: 'checkboxNoLabel2',
    defaultChecked: true
  },
  {
    type: 'checkbox',
    name: 'checkboxNoLabel3',
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'checkboxNoLabel4',
    defaultChecked: true,
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'checkbox1',
    label: 'Checkbox with title and default label alignment',
    title: 'Title'
  },
  {
    type: 'checkbox',
    name: 'checkbox1',
    label: 'Checkbox with default label alignment'
  },
  {
    type: 'checkbox',
    name: 'checkbox2',
    label: 'Checkbox with left label alignment',
    labelAlignment: 'left'
  },
  {
    type: 'checkbox',
    name: 'checkboxHint2',
    label: 'Checkbox with left label alignment and hint',
    hint: 'This is a hint.',
    labelAlignment: 'left'
  },
  {
    type: 'checkbox',
    name: 'checkboxHintError2',
    label: 'Checkbox with left label alignment, hint, and error',
    hint: 'This is a hint.',
    error: 'This is an error',
    labelAlignment: 'left'
  },
  {
    type: 'checkbox',
    name: 'checkboxError2',
    label: 'Checkbox with default label alignment and error',
    error: 'This is an error'
  },
  {
    type: 'checkbox',
    name: 'checkbox3',
    label: 'Checkbox with right label alignment',
    labelAlignment: 'right'
  },
  {
    type: 'checkbox',
    name: 'checkboxHint1',
    label: 'Checkbox with right label alignment and hint',
    hint: 'This is a hint.',
    labelAlignment: 'right'
  },
  {
    type: 'checkbox',
    name: 'checkboxHintError1',
    label: 'Checkbox with right label alignment, hint, and error',
    hint: 'This is a hint.',
    error: 'This is an error',
    labelAlignment: 'right'
  },
  {
    type: 'checkbox',
    name: 'checkboxError1',
    label: 'Checkbox with right label alignment and error',
    error: 'This is an error',
    labelAlignment: 'right'
  },
  {
    type: 'checkbox',
    name: 'checkbox4',
    label: 'Disabled Unchecked',
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'checkbox5',
    label: 'Disabled Checked',
    disabled: true,
    defaultChecked: true
  }
]

function Checkboxes () {
  const output = controls.map((options) => {
    return <FormControl key={options.name} {...options} />
  })
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Checkbox Inputs' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
        <div className='p-4'>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-type='checkbox' />
            <span className='label-sm-medium'>Fake checkbox unchecked</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-checked='true' data-type='checkbox' />
            <span className='label-sm-medium'>Fake checkbox checked</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-disabled='true' data-type='checkbox' />
            <span className='label-sm-medium'>Fake checkbox disabled</span>
          </div>
          <div className='flex flex-row items-center gap-1 mb-4'>
            <div data-checked='true' data-disabled='true' data-type='checkbox' />
            <span className='label-sm-medium'>Fake checkbox checked disabled</span>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Checkboxes
