import { useCallback, useMemo, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import ChatThread from '../../components/chats/ChatThread'
import Message from '../../components/forms/controls/Message'
import PotentialStopMessageBanner from '../../components/message/potential-stop/PotentialStopMessageBanner'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentChat from '../../hooks/useCurrentChat'
import useCurrentStackKey from '../../hooks/useCurrentStackKey'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import poller from '../../lib/poller'
import { markAsRead } from '../../services/chat'
import { send as sendMesssageService } from '../../services/message'

import ListHeaderMore from './thread/ListHeaderMore'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ChatsThread'] })

const ChatsThread = () => {
  const chatThreadRef = useRef()
  const messageControlRef = useRef()
  const [searchParams] = useSearchParams()

  const stackKey = useCurrentStackKey()

  useLogger({ log, lifecycle: false, tags: [stackKey] })

  const { chatId } = useParams()
  const { chat, loading, refresh } = useCurrentChat()

  const draftMessageOverride = useMemo(() => {
    const text = searchParams.get('text')
    const image = searchParams.get('image')
    if (text || image) {
      return {
        text: text || '',
        media: image ? [image] : [],
        type: image ? 'mms' : 'sms'
      }
    }
  }, [searchParams])

  const handleMarkAsReadReplyOk = useCallback(() => { poller.runImmediately() }, [])
  const { call: markAsReadCall } = useService(markAsRead, { onReplyOk: handleMarkAsReadReplyOk })

  const handleSendReplyOk = useCallback((reply) => {
    messageControlRef.current.reset()
    // maybe a bit hacky... we're going to immediately mark the message as read to avoid the chats unread count indicator from flashing
    // but might have been a queue call...
    const message = reply.json?.messages?.[0]
    if (message) {
      markAsReadCall({ contactId: chatId, messageId: message._id }, true)
      if (chatThreadRef.current.isEmpty()) {
        // Force reload the list after send if new/empty chat. Otherwise since there
        //  is no "last message" the poller will not pick up the newly sent message.
        chatThreadRef.current.reload()
      }
    } else {
      poller.runImmediately()
    }
  }, [chatId, markAsReadCall])
  const { call: sendMessage } = useService(sendMesssageService, { onReplyOk: handleSendReplyOk })

  const handleSend = useCallback((message) => {
    sendMessage({ contactId: chatId, ...message }, true)
  }, [chatId, sendMessage])

  if (loading || !chat) { return <PanelLoading hideAivaChatButton hideMenuToggleButton /> }

  const draftKey = `chat-thread-${chatId}`

  return (
    <Panel hideAivaChatButton hideMenuToggleButton>
      <PanelHeader
        end={<ListHeaderMore chat={chat} />}
        href={stackKey === 'chats' ? 'contact' : undefined}
        start={<PanelHeaderBackButton />}
        title={chat.formattedName}
      />
      <PanelContent>
        {chat.potentialStopMessage
          ? <PotentialStopMessageBanner messageId={chat.potentialStopMessage} onResolved={refresh} />
          : null}
        <ChatThread ref={chatThreadRef} contactId={chatId} />
        <div className='border-t border-neutral-200'>
          <Message
            ref={messageControlRef}
            draftKey={draftKey}
            draftMessageOverride={draftMessageOverride}
            name='message'
            theme='chat'
            onSchedule={handleSend}
            onSend={handleSend}
          />
        </div>
      </PanelContent>
    </Panel>
  )
}

ChatsThread.displayName = 'ChatsThread'
ChatsThread.propTypes = propTypes
ChatsThread.defaultProps = defaultProps

export default ChatsThread
