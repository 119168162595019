import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import bundleShape from '../../../prop-types/shapes/bundle'

import validationSuite from './UsageDetail.validations'

const propTypes = {
  bundle: PropType.shape(bundleShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['UsageDetailSection'] })

const UsageDetailSection = ({ bundle, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [bundle?._id] })

  const { defaultValues, formControls } = useMemo(() => {
    return {
      defaultValues: {
        usage: bundle?.usage
      },
      formControls: [
        { label: 'Usage', name: 'usage', type: 'textarea', minRows: 3, maxRows: 6 }
      ]
    }
  }, [bundle])

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Help people get the most out of your bundle! Take time and describe its contents and how it should be used.'
      formControls={formControls}
      formDescription={`Updating usage for ${bundle?.name}`}
      formTitle='Bundle Usage'
      objectId={bundle._id}
      service={service}
      title='Usage'
      validationSuite={validationSuite}
      showEditIcon
      onChange={onChange}
    >
      {bundle.usage}
    </FormDetailSection>
  )
}

UsageDetailSection.displayUsage = 'UsageDetailSection'
UsageDetailSection.propTypes = propTypes
UsageDetailSection.defaultProps = defaultProps

export default UsageDetailSection
