import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'
import { addAllInQuery, addSelected } from '../../../services/keyword-contact'
import { success } from '../../banners/Banner'
import ContactList from '../../contacts/ContactList'
import ListDialog from '../../dialog/ListDialog'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['ApplyKeyword'] })

const ApplyKeyword = forwardRef(({ keyword, onSuccess }, ref) => {
  ref = useDefaultRef(ref)
  const dialogRef = useRef()

  useLogger({ log, lifecycle: false, tags: [keyword?._id] })

  const handleReplyOk = useCallback((reply) => {
    success('Keyword applied')
    onSuccess?.(reply)
  }, [onSuccess])
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ keywordId: keyword._id, contactIds: map(selected, '_id') })
  }, [addSelectedCall, keyword._id])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ keywordId: keyword._id, ...query })
  }, [addAllInQueryCall, keyword._id])

  return (
    <ListDialog
      ref={dialogRef}
      list={ContactList}
      subtitle={keyword?.word}
      title='Apply Keyword'
      type='add'
      onSubmitAllInQuery={handleAddAllInQuery}
      onSubmitSelected={handleAddSelected}
    />
  )
})

ApplyKeyword.displayName = 'ApplyKeyword'
ApplyKeyword.propTypes = propTypes
ApplyKeyword.defaultProps = defaultProps

export default ApplyKeyword
