import { forEach } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import ConfirmDialog from '../../components/dialog/ConfirmDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentUser from '../../hooks/useCurrentUser'
import useService from '../../hooks/useService'
import { exportHistory as exportHistoryService, history as historyService } from '../../services/payment'
import { Email, NewWindow } from '../../svg/icons'

const PaymentHistory = () => {
  const [history, setHistory] = useState()
  const confirmExportDialogRef = useRef()
  const currentUser = useCurrentUser()

  const handleReplyOk = useCallback((result) => {
    setHistory(result.json)
  }, [])
  const { call: fetchHistory } = useService(historyService, { onReplyOk: handleReplyOk })

  const handleExportReplyOk = useCallback(() => {
    notify('Payment History exported successfully.')
  }, [])
  const { call: exportHistory } = useService(exportHistoryService, { onReplyOk: handleExportReplyOk })

  useEffect(() => {
    fetchHistory(true)
  }, [fetchHistory])

  const handleConfirmExport = () => {
    confirmExportDialogRef.current.open()
  }

  const handleExportHistory = () => {
    exportHistory()
  }

  const title = 'Payment History'

  if (!history) { return <PanelLoading title={title} /> }

  const rows = []
  let key = 0
  forEach(history, (item) => {
    rows.push(
      <tr key={key++} className='border-b border-neutral-200'>
        <td className='px-4 py-4'>{item.date}</td>
        <td className='px-4 py-4'>{item.description}</td>
        <td className='px-4 py-4'>{item.amount}</td>
        <td>
          <Button
            href={item.link}
            icon={<NewWindow height='20' width='20' />}
            variant='text'
          >
            Export
          </Button>
        </td>
      </tr>
    )
  })

  return (
    <>
      <Panel>
        <PanelHeader
          end={<PanelHeaderButton icon={<Email height='20' width='20' />} onClick={handleConfirmExport} />}
          start={<PanelHeaderBackButton />}
          title={title}
        />
        <PanelContent>
          <table>
            <tbody>
              {rows}
            </tbody>
          </table>
        </PanelContent>
      </Panel>
      <ConfirmDialog
        ref={confirmExportDialogRef}
        description={`The payment export will be generated and mailed to the email address associated with your account, ${currentUser.auth.username}`}
        title='Export Payment History'
        onConfirm={handleExportHistory}
      />
    </>
  )
}

PaymentHistory.displayName = 'PaymentHistory'
export default PaymentHistory
