import * as React from "react";
import { memo } from "react";
const SvgPeopleGroup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 6.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM12 1a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Zm0 13c-2.132 0-4.1.664-5.557 1.783C4.986 16.903 4 18.52 4 20.38V22a1 1 0 1 0 2 0v-1.62c0-1.11.584-2.183 1.662-3.01C8.74 16.54 10.272 16 12 16c1.727 0 3.259.543 4.338 1.373 1.078.83 1.662 1.903 1.662 3.007V22a1 1 0 1 0 2 0v-1.62c0-1.856-.986-3.473-2.443-4.593C16.101 14.667 14.133 14 12 14Zm-9.21 2.224a1 1 0 0 0-1.58-1.228C.466 15.955 0 17.106 0 18.38V21a1 1 0 1 0 2 0v-2.62c0-.766.275-1.495.79-2.156Zm18.596-1.403a1 1 0 0 1 1.403.175c.746.959 1.211 2.11 1.211 3.384V21a1 1 0 1 1-2 0v-2.62c0-.766-.275-1.495-.79-2.156a1 1 0 0 1 .176-1.403Zm-1.843-9.932a1 1 0 0 1 1.358-.396C22.125 5.165 23 6.466 23 8a3.986 3.986 0 0 1-3.72 3.977 1 1 0 1 1-.14-1.995A1.986 1.986 0 0 0 21 8c0-.746-.425-1.404-1.06-1.753a1 1 0 0 1-.397-1.358ZM4.061 6.247a1 1 0 1 0-.962-1.754C1.875 5.165 1 6.466 1 8a3.986 3.986 0 0 0 3.72 3.977 1 1 0 1 0 .14-1.995A1.986 1.986 0 0 1 3 8c0-.746.425-1.404 1.06-1.753Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPeopleGroup);
export default Memo;
