import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useId } from 'react'

import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import Button from '../buttons/Button'

import BaseDialog from './BaseDialog'

const propTypes = {
  ariaDescription: PropType.string.isRequired,
  children: PropType.node.isRequired,
  title: PropType.string.isRequired,
  buttonVerb: PropType.string,
  href: PropType.string,
  onClick: PropType.func
}

const defaultProps = {
  buttonVerb: 'Continue',
  href: '#',
  onClick: () => {}
}

const log = logger({ enabled: false, tags: ['BlockingDialog'] })

const BlockingDialog = forwardRef(({ children, onClick, buttonVerb, href, title, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const titleId = useId()
  const smallScreen = useSmallScreen()

  const className = classNames(
    'bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[375px] flex flex-col overflow-auto',
    {
      'small-screen-dialog': smallScreen
    }
  )

  return (
    <BaseDialog
      ref={ref}
      ariaLabelledBy={titleId}
      dismissable={false}
      {...rest}
    >
      <div className={className}>
        <h1 className='label-lg-strong text-black text-center mb-[20px]' id={titleId}>
          {title}
        </h1>
        {children}
        <Button
          className='mt-5'
          href={href}
          size='sm'
          onClick={onClick}
        >
          {buttonVerb}
        </Button>
      </div>
    </BaseDialog>
  )
})

BlockingDialog.displayName = 'BlockingDialog'
BlockingDialog.propTypes = propTypes
BlockingDialog.defaultProps = defaultProps

export default BlockingDialog
