import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { stackKeyFromContentType, stateKeyFromContentType } from '../../../lib/folder'
import logger from '../../../lib/logger'
import { contentType as contentTypeShape } from '../../../prop-types/shapes/folder'
import { addSelected as addSelectedService } from '../../../services/folder'
import { notify } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'
import ListDialog from '../../dialog/ListDialog'
import FolderList from '../FolderList'

const propTypes = {
  contentType: PropType.oneOf(contentTypeShape).isRequired,
  selectedItems: PropType.array // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  selectedItems: []
}

const log = logger({ enabled: false, tags: ['AddToExistingFolderAction'] })

const AddToExistingFolderAction = forwardRef(({ contentType, selectedItems }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const listDialogRef = useRef()
  const folderIdRef = useRef()
  const confirmRef = useRef()
  const contentIds = useMemo(() => selectedItems.map((item) => item._id), [selectedItems])

  const handleReplyOk = useCallback((response) => {
    if (response.json.showConfirmation) {
      return confirmRef.current.open()
    }
    notify('Success!', 'Your item(s) have been moved to this folder.')
    const stateKey = stateKeyFromContentType(contentType)
    const stackKey = stackKeyFromContentType(contentType)
    bus.emit('closeBulkEditDialog')
    bus.emit('folderUpdated')
    bus.emit(`${stateKey}Created`)
    navigate(`/${stackKey}/folder/${folderIdRef.current}`)
  }, [contentType, navigate])
  const { call: addToExistingFolder } = useService(addSelectedService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      listDialogRef.current.open()
    }
  }), [])

  const handleSubmitSelected = useCallback((folder) => {
    folderIdRef.current = folder._id
    addToExistingFolder({ folderId: folder._id, contentType, contentIds })
  }, [addToExistingFolder, contentType, contentIds])

  const handleConfirm = useCallback(() => {
    addToExistingFolder({ folderId: folderIdRef.current, contentType, contentIds, movingItemsConfirmed: true })
  }, [addToExistingFolder, contentType, contentIds])

  return (
    <>
      <ListDialog
        ref={listDialogRef}
        list={FolderList}
        multiple={false}
        queryOverrides={{ contentType }}
        title='Move to Folder'
        type='select'
        onSubmitSelected={handleSubmitSelected}
      />
      <ConfirmDialog
        ref={confirmRef}
        description='Selected items already exist in other folders. Would you like to move them to a new folder?'
        title='Move Items?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

AddToExistingFolderAction.displayName = 'AddToExistingFolderAction'
AddToExistingFolderAction.propTypes = propTypes
AddToExistingFolderAction.defaultProps = defaultProps

export default AddToExistingFolderAction
