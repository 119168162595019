import classNames from 'classnames'
import PropType from 'prop-types'
import { Link } from 'react-router-dom'

import { usePanel } from '../../../contexts/PanelContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import NonBreakingSpace from '../../NonBreakingSpace'
import TitleCountPill from '../../pill/TitleCountPill'

const propTypes = {
  title: PropType.string.isRequired,
  onClick: PropType.func.isRequired,
  count: PropType.number,
  href: PropType.string,
  subtitle: PropType.string
}

const defaultProps = {
  count: null,
  href: null,
  subtitle: null
}

const log = logger({ enabled: true, tags: ['PanelHeaderTitle'] })

const PanelHeaderTitle = ({ count, href, subtitle, title, onClick }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isNative, inDialog } = usePanel()

  const containerClassName = classNames(
    'relative z-10 mx-auto px-[6px] flex flex-col flex-wrap max-w-full overflow-auto',
    {
      'items-center': inDialog
    }
  )

  const subtitleClassName = classNames(
    'label-sm-medium line-clamp-1 break-words w-full',
    {
      'text-neutral-400': inDialog || !isNative,
      'text-neutral-200': isNative && !inDialog
    }
  )

  if (title === ' ' || title === '') { title = <NonBreakingSpace /> }
  if (subtitle === ' ' || subtitle === '') { subtitle = <NonBreakingSpace /> }

  return (
    <div className={containerClassName} onClick={onClick}>
      <div className='flex flex-row items-center w-full'>
        <div className='label-lg-strong line-clamp-1 break-words max-w-min'>
          {href ? <Link className={`link text-${isNative ? 'white' : 'black'}`} to={href}>{title}</Link> : title}
        </div>
        <TitleCountPill value={count} />
      </div>
      {subtitle ? <div className={subtitleClassName}>{subtitle}</div> : null}
    </div>
  )
}

PanelHeaderTitle.displayName = 'PanelHeaderTitle'
PanelHeaderTitle.propTypes = propTypes
PanelHeaderTitle.defaultProps = defaultProps

export default PanelHeaderTitle
