import { useCallback, useRef } from 'react'

import CreateAction from '../../../components/app-flows/actions/CreateAction'
import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import { Default as DefaultEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const EmptyListContent = () => {
  const createActionRef = useRef()

  const onAddAppFlow = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <DefaultEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong text-center'>Get Started with App Flows</h1>
        <p className='label-sm text-center'>
          Learn how to use
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/app-flows'
            label='App Flows'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddAppFlow}>
          Add App Flow
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
