import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import logger from '../../../../lib/logger'
import goalShape from '../../../../prop-types/shapes/goal'
import { exportCsv } from '../../../../services/admin/goal'
import { success } from '../../../banners/Banner'
import ConfirmDialog from '../../../dialog/ConfirmDialog'

const propTypes = {
  goal: PropType.shape(goalShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ExportGoalAction'] })

const ExportGoalAction = forwardRef(({ goal }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const confirmDialogRef = useRef()

  const { call: exportGoalCall } = useService(exportCsv, {
    onReplyOk: useCallback((reply) => {
      confirmDialogRef.current.close()
      success(`Export of ${goal.name} sent to ${reply.json.sentTo}`)
    }, [goal.name])
  })

  useImperativeHandle(ref, () => ({
    activate () {
      confirmDialogRef.current.open()
    }
  }), [])

  const handleExport = useCallback(() => {
    exportGoalCall(goal._id)
  }, [exportGoalCall, goal._id])

  return (
    <ConfirmDialog
      ref={confirmDialogRef}
      description={`Export ${goal.name} and send to your email?`}
      title={`Export ${goal.name}`}
      onConfirm={handleExport}
    />
  )
})

ExportGoalAction.displayName = 'ExportGoalAction'
ExportGoalAction.propTypes = propTypes
ExportGoalAction.defaultProps = defaultProps

export default ExportGoalAction
