import * as React from "react";
import { memo } from "react";
const SvgSettings = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.261 0c-1.129 0-2.116.815-2.296 1.95L8.67 3.697a8.87 8.87 0 0 0-1.99 1.154l-1.657-.618h-.002A2.335 2.335 0 0 0 2.19 5.242l-.873 1.515c-.58.991-.343 2.249.539 2.975l1.368 1.126a9.178 9.178 0 0 0 0 2.304l-1.367 1.125a2.348 2.348 0 0 0-.547 2.963l.875 1.52.005.008a2.335 2.335 0 0 0 2.832 1.009l.002-.001 1.66-.619c.605.462 1.27.853 1.986 1.154l.296 1.755v.003a2.33 2.33 0 0 0 2.296 1.941h1.74c1.129 0 2.116-.815 2.296-1.95l.295-1.748a8.872 8.872 0 0 0 1.99-1.154l1.656.618h.002a2.335 2.335 0 0 0 2.832-1.008l.873-1.515a2.329 2.329 0 0 0-.539-2.975l-1.367-1.126a9.19 9.19 0 0 0 0-2.305l1.366-1.124a2.348 2.348 0 0 0 .548-2.963l-.876-1.52-.005-.008a2.335 2.335 0 0 0-2.832-1.008h-.002l-1.66.619a9.045 9.045 0 0 0-1.986-1.154l-.295-1.755v-.003A2.33 2.33 0 0 0 13.001 0h-1.74Zm-.321 2.264A.323.323 0 0 1 11.262 2h1.74a.33.33 0 0 1 .324.278v.001l.39 2.317a1 1 0 0 0 .664.78c.87.296 1.667.768 2.354 1.368a1 1 0 0 0 1.007.183l2.2-.82.003-.001a.335.335 0 0 1 .404.149l.863 1.498a.348.348 0 0 1-.074.434l-.001.001-1.81 1.49a1 1 0 0 0-.345.968c.086.431.13.895.13 1.364 0 .467-.043.922-.13 1.368a1 1 0 0 0 .345.964l1.81 1.49c.137.113.16.292.084.42l-.872 1.513a.335.335 0 0 1-.404.15l-.003-.002-2.2-.82a1 1 0 0 0-1.012.188 6.879 6.879 0 0 1-2.35 1.362 1 1 0 0 0-.663.781l-.39 2.32-.003.012a.323.323 0 0 1-.322.264h-1.74a.33.33 0 0 1-.323-.278v-.001l-.39-2.317a1 1 0 0 0-.665-.78 7.043 7.043 0 0 1-2.354-1.368 1 1 0 0 0-1.007-.183l-2.2.82-.003.001a.335.335 0 0 1-.404-.149l-.863-1.498a.348.348 0 0 1 .074-.434l1.811-1.49a1 1 0 0 0 .345-.97 7.014 7.014 0 0 1-.13-1.363c0-.467.044-.922.13-1.368a1 1 0 0 0-.345-.964l-1.81-1.49a.329.329 0 0 1-.084-.42l.872-1.513a.335.335 0 0 1 .404-.15l.003.002 2.2.82a1 1 0 0 0 1.012-.188 6.879 6.879 0 0 1 2.35-1.362 1 1 0 0 0 .664-.781l.39-2.32.002-.012Zm-.818 9.746a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSettings);
export default Memo;
