import React from 'react'

import PanelLayout from '../components/panels/PanelLayout'
import ActionMenus from '../screens/style-guide/ActionMenus'
import Avatars from '../screens/style-guide/Avatars'
import Banners from '../screens/style-guide/Banners'
import Branding from '../screens/style-guide/Branding'
import Breakpoints from '../screens/style-guide/Breakpoints'
import Buttons from '../screens/style-guide/Buttons'
import Colors from '../screens/style-guide/Colors'
import DetailSections from '../screens/style-guide/DetailSections'
import BlockingDialogs from '../screens/style-guide/dialogs/BlockingDialogs'
import ConfirmDialogs from '../screens/style-guide/dialogs/ConfirmDialogs'
import DialogsList from '../screens/style-guide/dialogs/DialogsList'
import FormDialogs from '../screens/style-guide/dialogs/FormDialogs'
import PanelDialogs from '../screens/style-guide/dialogs/PanelDialogs'
import FormattedDates from '../screens/style-guide/FormattedDates'
import Checkboxes from '../screens/style-guide/forms/Checkboxes'
import Forms from '../screens/style-guide/forms/Forms'
import FormsList from '../screens/style-guide/forms/FormsList'
import Messages from '../screens/style-guide/forms/Messages'
import Passwords from '../screens/style-guide/forms/Passwords'
import Selects from '../screens/style-guide/forms/Selects'
import TextInputs from '../screens/style-guide/forms/TextInputs'
import TextSamples from '../screens/style-guide/forms/TextSamples'
import Iconography from '../screens/style-guide/Iconography'
import PanelHeaders from '../screens/style-guide/PanelHeaders'
import Pills from '../screens/style-guide/Pills'
import Pluralization from '../screens/style-guide/Pluralization'
import Radios from '../screens/style-guide/Radios'
import SearchBars from '../screens/style-guide/SearchBars'
import Switches from '../screens/style-guide/Switches'
import Tooltips from '../screens/style-guide/Tooltips'
import Typography from '../screens/style-guide/Typography'
import Vendor from '../screens/style-guide/Vendor'
import Wells from '../screens/style-guide/Wells'

const routes = [
  {
    path: 'branding',
    element: <Branding />
  },
  {
    path: 'colors',
    element: <Colors />
  },
  {
    path: 'iconography',
    element: <Iconography />
  },
  {
    path: 'typography',
    element: <Typography />
  },
  {
    path: 'breakpoints',
    element: <Breakpoints />
  },
  {
    path: 'components/action-menus',
    element: <ActionMenus />
  },
  {
    path: 'components/avatars',
    element: <Avatars />
  },
  {
    path: 'components/banners',
    element: <Banners />
  },
  {
    path: 'components/buttons',
    element: <Buttons />
  },
  {
    path: 'components/detail-sections',
    element: <DetailSections />
  },
  {
    path: 'components/detail-sections/contacts',
    element: <DetailSections popup='Would load contacts list page' />
  },
  {
    path: 'components/dialogs',
    element: (
      <PanelLayout
        panel1={<DialogsList />}
        panel2={<BlockingDialogs />}
      />
    ),
    children: [
      {
        path: 'blocking',
        element: <BlockingDialogs />
      },
      {
        path: 'confirm',
        element: <ConfirmDialogs />
      },
      {
        path: 'form',
        element: <FormDialogs />
      },
      {
        path: 'panel',
        element: <PanelDialogs />
      }
    ]
  },
  {
    path: 'components/formatted-dates',
    element: <FormattedDates />
  },
  {
    path: 'components/forms',
    element: (
      <PanelLayout
        panel1={<FormsList />}
        panel2={<Forms />}
      />
    ),
    children: [
      {
        path: 'checkboxes',
        element: <Checkboxes />
      },
      {
        path: 'forms',
        element: <Forms />
      },
      {
        path: 'messages',
        element: <Messages />
      },
      {
        path: 'passwords',
        element: <Passwords />
      },
      {
        path: 'radios',
        element: <Radios />
      },
      {
        path: 'selects',
        element: <Selects />
      },
      {
        path: 'text-inputs',
        element: <TextInputs />
      },
      {
        path: 'text-samples',
        element: <TextSamples />
      }
    ]
  },
  {
    path: 'components/panel-headers',
    element: <PanelHeaders />
  },
  {
    path: 'components/pills',
    element: <Pills />
  },
  {
    path: 'components/pluralization',
    element: <Pluralization />
  },
  {
    path: 'components/search-bars',
    element: <SearchBars />
  },
  {
    path: 'components/switches',
    element: <Switches />
  },
  {
    path: 'components/tooltips',
    element: <Tooltips />
  },
  {
    path: 'components/wells',
    element: <Wells />
  },
  {
    path: 'vendor',
    element: <Vendor />
  }
]

export default routes
