import { forwardRef } from 'react'

import ContactList from './ContactList'

const ArchivedContactList = forwardRef(({ ...rest }, ref) => {
  return (
    <ContactList
      ref={ref}
      placeholder='Search Archived Contacts'
      queryOverrides={{ archived: true }}
      {...rest}
    />
  )
})

ArchivedContactList.displayName = 'ArchivedContactList'

export default ArchivedContactList
