import { useCallback } from 'react'

import logger from '../lib/logger'

import useBus from './useBus'
import useLogger from './useLogger'

const log = logger({ enabled: true, tags: ['useAdditionalContentHandlers'] })

export function useAdditionalContentHandlers (stateKey, listRef) {
  useLogger({ log, lifecycle: false, tag: [stateKey] })

  const handleContentAdded = useCallback(() => {
    log.debug('handleContentAdded')
    listRef.current.indicateUpdatesDetected()
  }, [listRef])

  const handleContentRemoved = useCallback(() => {
    log.debug('handleContentRemoved')
    listRef.current.indicateUpdatesDetected()
  }, [listRef])

  useBus(`${stateKey}AddedToFolder`, handleContentAdded)
  useBus(`${stateKey}RemovedFromFolder`, handleContentRemoved)
}
