import * as React from "react";
import { memo } from "react";
const SvgMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm1 5a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMenu);
export default Memo;
