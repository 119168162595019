import { forwardRef, useCallback, useId, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import BaseDialog from '../../components/dialog/BaseDialog'
import TextInput from '../../components/forms/controls/TextInput'
import TermsLink from '../../components/links/TermsLink'
import useLogger from '../../hooks/useLogger'
import useNavigateInStack from '../../hooks/useNavigateInStack'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import logger from '../../lib/logger'
import { defaultOnHoldPlanId } from '../../lib/plans'
import { changePlan as changePlanService } from '../../services/subscription'
import { update } from '../../store/actions/currentUser'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ConfirmOnHoldDialog'] })

const ConfirmOnHoldDialog = forwardRef((_props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const [value, setValue] = useState('')
  const inputId = useId()
  const titleId = useId()

  const handlePlanChangeOk = useCallback(() => {
    notify('Your plan change request has been submitted.')
    navigateInStack('/subscription', { replace: true })
  }, [navigateInStack])
  const { call: changePlan } = useServiceAndAction(changePlanService, update, { onReplyOk: handlePlanChangeOk })

  const title = 'Hold Subscription'
  const confirmValue = 'HOLD'
  const confirmButtonText = 'Confirm'

  const handleInputChange = useCallback((event) => {
    setValue(event.target.value)
  }, [setValue])

  const handleConfirm = useCallback(() => {
    changePlan({ planId: defaultOnHoldPlanId })
  }, [changePlan])

  const handleCancel = useCallback(() => {
    ref.current.close()
  }, [ref])

  const handleClose = useCallback(() => {
    setValue('')
  }, [setValue])

  return (
    <BaseDialog
      ref={ref}
      ariaLabelledBy={titleId}
      onClose={handleClose}
    >
      <div className='bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[600px] flex flex-col'>
        <h1 className='label-lg-strong text-black text-center mb-[20px]' id={titleId}>{title}</h1>
        <div className='flex flex-col flex-nowrap gap-2 justify-center'>
          <p className='mb-5'>
            Putting your account on hold allows you to continue to use Project Broadcast with the features of our Social Plan while maintaining your accumulated credits and your Project Broadcast number for a low monthly fee of
            {' '}
            <strong>$6.00</strong>
            {'. '}
            You will be able to send messages to Project Broadcast users, but you will not be able to send messages outside Project Broadcast.
          </p>
          <label className='label-md-strong' htmlFor={inputId}>
            Please type
            {' '}
            <strong>
              "
              {confirmValue}
              "
            </strong>
            {' '}
            to confirm
          </label>
          <TextInput
            className='mb-5'
            id={inputId}
            name='confirm'
            value={value}
            onChange={handleInputChange}
          />
          <p className='mb-5'>
            By continuing, you understand that Project Broadcast will charge your card on file
            {' '}
            <strong>$6.00</strong>
            {' '}
            a month to maintain your accumulated credits and Project Broadcast number.
            {' '}
            <strong>Refunds are not provided.</strong>
            {' '}
            Complete terms can be found
            {' '}
            <TermsLink label='here' />
            {'. '}
          </p>
          <Button
            className='w-full'
            disabled={value !== confirmValue}
            size='md'
            theme='danger'
            onClick={handleConfirm}
          >
            {confirmButtonText}
          </Button>
          <Button
            className='w-full'
            size='md'
            variant='outline'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseDialog>
  )
})

ConfirmOnHoldDialog.displayName = 'ConfirmOnHoldDialog'
ConfirmOnHoldDialog.propTypes = propTypes
ConfirmOnHoldDialog.defaultProps = defaultProps

export default ConfirmOnHoldDialog
