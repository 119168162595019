import * as React from "react";
import { memo } from "react";
const SvgDiscussion = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 0C1.348 0 0 1.348 0 3v7c0 1.652 1.348 3 3 3h2v2a1 1 0 0 0 1.447.894L12.237 13H15c1.652 0 3-1.348 3-3V3c0-1.652-1.348-3-3-3H3ZM2 3c0-.548.452-1 1-1h12c.548 0 1 .452 1 1v7c0 .548-.452 1-1 1h-3a.999.999 0 0 0-.447.106L7 13.382V12a1 1 0 0 0-1-1H3c-.548 0-1-.452-1-1V3Zm19 4a1 1 0 1 0 0 2c.548 0 1 .452 1 1v7c0 .548-.452 1-1 1h-3a1 1 0 0 0-1 1v1.382l-4.553-2.276A.999.999 0 0 0 12 18H9a1 1 0 1 0 0 2h2.764l5.789 2.894A1 1 0 0 0 19 22v-2h2c1.652 0 3-1.348 3-3v-7c0-1.652-1.348-3-3-3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgDiscussion);
export default Memo;
