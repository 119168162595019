import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useState } from 'react'
import { Wizard as ReactWizard } from 'react-use-wizard'

import PanelNotFound from '../../../components/panels/PanelNotFound'
import useAffiliate from '../../../hooks/useAffiliate'
import useLogger from '../../../hooks/useLogger'
import usePartnerRequest from '../../../hooks/usePartnerRequest'
import logger from '../../../lib/logger'

import ManagedNumberForm from './ManagedNumberForm'
import MobileVerificationForm from './MobileVerificationForm'
import NumberUnavailableForm from './NumberUnavailableForm'
import SubscriptionForm from './SubscriptionForm'
import Welcome from './Welcome'
import WizardWrapper from './WizardWrapper'

const propTypes = {}
const defaultProps = {}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
const cssSrc = process.env.REACT_APP_CSS_FONT_URL
const elementsOptions = { fonts: [{ cssSrc }] }

const log = logger({ enabled: false, tags: ['SignUpWizard'] })

const Wizard = () => {
  useLogger({ log, lifecycle: true, tags: [] })

  const [data, setData] = useState({})
  const {
    connectUrl,
    integration,
    partnerId,
    verificationComplete,
    verified
  } = usePartnerRequest()
  const {
    affiliate,
    affiliateLookupComplete
  } = useAffiliate()

  useEffect(() => {
    log.debug('integration', integration)
    setData((prev) => ({ ...prev, integration }))
  }, [integration])

  useEffect(() => {
    log.debug('affiliate', affiliate)
    setData((prev) => ({ ...prev, affiliate }))
  }, [affiliate])

  const handleFormSubmit = useCallback((formData) => {
    log.debug('formData', formData)
    setData((prev) => ({ ...prev, ...formData }))
  }, [])

  // form defaultValues are cached after first render,
  // so block Form rendering until we have the data we need.
  if (partnerId && !verified) {
    if (verificationComplete) {
      return <PanelNotFound />
    } else {
      return null
    }
  }

  if (!affiliateLookupComplete) {
    return null
  }

  return (
    <Elements options={elementsOptions} stripe={stripePromise}>
      <ReactWizard wrapper={<WizardWrapper />}>
        <SubscriptionForm {...data} signInUrl={connectUrl} onSuccess={handleFormSubmit} />
        <MobileVerificationForm {...data} />
        <ManagedNumberForm {...data} onSuccess={handleFormSubmit} />
        <NumberUnavailableForm {...data} onSuccess={handleFormSubmit} />
        <Welcome {...data} />
      </ReactWizard>
    </Elements>
  )
}

Wizard.displayName = 'SignUpWizard'
Wizard.propTypes = propTypes
Wizard.defaultProps = defaultProps

export default Wizard
