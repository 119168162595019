import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateDynamicFieldsSettings as updateDynamicFieldsSettingsService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const DynamicFields = () => {
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('Dynamic Fields updated successfully.')
  }, [])
  const { call: updateDynamicFields } = useServiceAndAction(updateDynamicFieldsSettingsService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='dynamicFieldsForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Dynamic Fields'
      />
      <PanelContent className='p-5'>
        <Form
          defaultValues={{
            relaxedDynamicFieldValidation: currentUser.relaxedDynamicFieldValidation
          }}
          formControls={[
            {
              type: 'checkbox',
              name: 'relaxedDynamicFieldValidation',
              label: 'When enabled and a contact does not have values for dynamic fields in a message, instead of failing validation, the system will insert blanks and send the message.',
              title: 'Enable Relaxed Validation'
            }
          ]}
          id='dynamicFieldsForm'
          onSubmit={updateDynamicFields}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='dynamicFieldsForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

DynamicFields.displayName = 'DynamicFields'

export default DynamicFields
