import PropType from 'prop-types'

import milestoneFromJSON from '../../models/goalMilestone'
import pb from '../pb'

import { updatePropTypes } from './goal-milestone.prop-types'

export function get (goalId, milestoneId, background = false) {
  return pb.setupGet(`admin/goals/${goalId}/milestones/${milestoneId}`, {
    background,
    model: {
      conversion: milestoneFromJSON,
      key: 'milestone'
    }
  })
}

export function update (goalId, milestoneId, body) {
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'goalMilestone#update')
  return pb.setupPut(`admin/goals/${goalId}/milestones/${milestoneId}`, {
    body,
    model: {
      conversion: milestoneFromJSON,
      key: 'milestone'
    }
  })
}
