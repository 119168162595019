import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

import Button from '../../components/buttons/Button'
import TermsLink from '../../components/links/TermsLink'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { cardLastFour } from '../../lib/formatters'
import logger from '../../lib/logger'
import { current as currentUserService } from '../../services/user'

import UpdatePaymentForm from './update-payment/UpdatePaymentForm'

const log = logger({ enabled: false, tags: ['UpdatePayment'] })

export const UpdatePayment = () => {
  const [updatedCardValues, setUpdatedCardValues] = useState({ brand: null, expMonth: null, expYear: null, last4: null })

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
  const cssSrc = process.env.REACT_APP_CSS_FONT_URL
  const elementsOptions = { fonts: [{ cssSrc }] }

  const handleCurrentUserOk = (result) => {
    if (!result?.json?.card) { return }
    const {
      card: { brand, exp_month: expMonth, exp_year: expYear, last4 }
    } = result.json
    setUpdatedCardValues({
      brand, expMonth, expYear, last4
    })
  }

  const { loading, call: userData } = useService(currentUserService, { onReplyOk: handleCurrentUserOk })

  useLogger({ log, lifecycle: false, tags: ['UpdatePayment'] })

  useEffect(() => {
    userData()
  }, [userData])

  if (loading) { return <PanelLoading title='Update Payment' /> }
  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='updatePaymentForm'
            title='Update'
            type='submit'
          />
      }
        start={<PanelHeaderBackButton />}
        title='Update Payment'
      />
      <PanelContent className='p-5'>
        {updatedCardValues?.brand
          ? (
            <div className='mb-4'>
              <p>
                Your current card on file is a
                {' '}
                <strong>{`${updatedCardValues.brand} `}</strong>
                ending in
                {' '}
                <strong>{`${cardLastFour(updatedCardValues.last4)} `}</strong>
                and expiring
                {' '}
                <strong>{`${updatedCardValues.expMonth}/${updatedCardValues.expYear}`}</strong>
                {'. '}
              </p>
            </div>
            )
          : null}
        <div className='mb-4'>
          <p>
            Please provide updated payment information below. If you have had a
            failed payment for your subscription, the next attempt will use the
            updated information. After 3 failed attempts your subscription is
            auto cancelled.
          </p>
        </div>
        <Elements options={elementsOptions} stripe={stripePromise}>
          <UpdatePaymentForm />
        </Elements>
        <div>
          <p>
            By continuing, you understand that Project Broadcast is a
            subscription based service. Your future charges (renewals, etc) will
            utilize the new payment info above.
            {' '}
            <strong>Refunds are not provided</strong>
            . Complete terms can be
            found
            {' '}
            <TermsLink label='here' />
            {'. '}
          </p>
        </div>
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='updatePaymentForm'
            size='sm'
            type='submit'
          >
            Update
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

UpdatePayment.displayName = 'UpdatePayment'

export default UpdatePayment
