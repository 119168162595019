export const bundleForCreditsAmount = (bundles, creditAmount) => {
  return bundles.find((creditBundle) => `${creditBundle.credits}` === `${creditAmount}`)
}

export const toCreditOptions = (creditsArray = []) => {
  return creditsArray.map(({ credits, name, price, priceLabel }) => ({
    label: `${name} - ${priceLabel}`,
    priceLabel,
    price,
    value: `${credits}`
  }))
}
