import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import ShortLinkStopMessage from '../../components/short-links/ShortLinkStopMessage'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { baseUrl } from '../../services/pb'
import { attachmentLanding } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Attachment'] })

const Attachment = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [attachment, setAttachment] = useState(null)
  const [error, setError] = useState(null)

  const { attachmentSlug, contactSlug } = useParams()

  const handleGetReplyOk = useCallback((reply) => { setAttachment(reply.model) }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/paste the link from your message to your browser.')
  }, [])
  const { call: getAttachmentCall, loading: loadingAttachment } = useService(attachmentLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  useEffect(() => {
    getAttachmentCall(attachmentSlug, contactSlug, true)
  }, [attachmentSlug, contactSlug, getAttachmentCall])

  const loading = (loadingAttachment || !attachment) && !error

  const downloadUrl = useMemo(() => {
    return `${baseUrl}/a/${attachmentSlug}/${contactSlug}/download`
  }, [attachmentSlug, contactSlug])

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <div className='text-center'>
        <p>
          You have clicked a download link sent from
          {' '}
          <strong>
            {attachment.user.formattedName}
            {' - '}
            {attachment.user.formattedPhoneNumber}
          </strong>
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <p className='mb-6'>To continue please click the download button below.</p>
        <p>
          <strong>
            {attachment.fileName}
            {' '}
            (
            {attachment.fileSize}
            )
          </strong>
        </p>
        <p className='my-10'>
          <Button download={attachment.fileName} href={downloadUrl} size='md'>Download</Button>
        </p>
        <ShortLinkStopMessage />
      </div>
    </ShortLinkContent>
  )
}

Attachment.displayName = 'Attachment'
Attachment.propTypes = propTypes
Attachment.defaultProps = defaultProps

export default Attachment
