import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import folderShape from '../../../prop-types/shapes/folder'
import { del as deleteFolderService } from '../../../services/folder'
import { warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  folder: PropType.shape(folderShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ folder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [folder?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    warning('Folder Deleted', 'The folder you have selected has been removed.')
    bus.emit('folderDeleted', folder)
    onSuccess?.()
  }, [folder, onSuccess])
  const { call: deleteFolder } = useService(deleteFolderService, {
    onReplyOk: handleReplyOk
  })

  useImperativeHandle(
    ref,
    () => ({
      activate () {
        confirmRef.current.open()
      }
    }),
    []
  )

  const handleConfirm = useCallback(() => {
    deleteFolder([folder._id], false)
  }, [deleteFolder, folder._id])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText={`Delete ${folder.name}`}
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        <strong>Please note:</strong>
        {' '}
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. Deleting this folder is irreversible.
        {' '}
        {folder.count > 0
          ? "Deleting the folder will not delete its contents. To delete items within, select them from the main list and use the 'Remove Items' option from the menu."
          : null}
      </p>
    </DangerousConfirmDialog>
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
