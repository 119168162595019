import PropType from 'prop-types'
import { useCallback, useId, useRef } from 'react'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useService from '../../../hooks/useService'
import { mfaInitiate as mfaInitiateService } from '../../../services/user'
import Header from '../shared/Header'

import validationSuite from './AddMethod.validations'

const propTypes = {
  onMethodSelected: PropType.func.isRequired,
  backStepIndex: PropType.number,
  nonce: PropType.string,
  selectedChannel: PropType.string,
  username: PropType.string
}

const defaultProps = {
  backStepIndex: 0,
  nonce: null,
  selectedChannel: null,
  username: null
}

const AddMethod = ({ backStepIndex, nonce, selectedChannel, username, onMethodSelected }) => {
  const formId = useId()
  const { goToStep } = useWizard()
  const preferredMethodRef = useRef(null)

  const handleReplyOk = useCallback(() => {
    onMethodSelected(preferredMethodRef.current)
    goToStep(4)
  }, [onMethodSelected, goToStep])
  const { call: mfaInitiate } = useService(mfaInitiateService, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback(({ phone: to }) => {
    const method = { channel: selectedChannel, to }
    preferredMethodRef.current = method
    mfaInitiate(username, nonce, method)
  }, [mfaInitiate, nonce, selectedChannel, username])
  const handleGoBack = useCallback(() => goToStep(backStepIndex), [goToStep, backStepIndex])

  const formSettings = {
    className: 'mt-5',
    defaultValues: { phone: '' },
    formControls: [{
      name: 'phone',
      placeholder: 'Phone',
      type: 'tel'
    }],
    id: formId,
    validationSuite,
    onSubmit: handleSubmit
  }

  return (
    <>
      <Header
        goBack={handleGoBack}
        heading='Sign In'
        subtitle='Step 1 of 2'
        title='Multi-Factor Authentication Setup'
      />
      <Form {...formSettings} />
      <Button
        className='mt-5 w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Continue
      </Button>
    </>
  )
}

AddMethod.displayName = 'MultiFactorAuthenticationAddMethod'
AddMethod.propTypes = propTypes
AddMethod.defaultProps = defaultProps

export default AddMethod
