import classNames from 'classnames'
import PropType from 'prop-types'

import { PanelProvider } from '../../contexts/PanelContext'
import GenericErrorBoundary from '../error-boundaries/GenericErrorBoundary'

const propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
  hasTwoPanels: PropType.bool,
  roomForTwoPanels: PropType.bool
}

const defaultProps = {
  className: null,
  hasTwoPanels: false,
  roomForTwoPanels: false
}

const PanelOne = ({ children, className, hasTwoPanels, roomForTwoPanels }) => {
  const computedClassName = classNames('relative z-10 max-h-full max-w-full overflow-auto', className)
  return (
    <PanelProvider hasTwoPanels={hasTwoPanels} roomForTwoPanels={roomForTwoPanels} slot='first'>
      <section className={computedClassName}>
        <GenericErrorBoundary boundaryName='PanelOne'>
          {children}
        </GenericErrorBoundary>
      </section>
    </PanelProvider>
  )
}

PanelOne.displayName = 'PanelOne'
PanelOne.propTypes = propTypes
PanelOne.defaultProps = defaultProps

export default PanelOne
