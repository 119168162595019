import PropType from 'prop-types'
import { useCallback, useId } from 'react'
import { Link } from 'react-router-dom'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import { login as loginService } from '../../../services/user'
import { login as loginAction } from '../../../store/actions/currentUser'
import Header from '../shared/Header'

import validationSuite from './Form.validations'
import SignUpLink from './SignUpLink'

const propTypes = {
  onAccountCancelled: PropType.func.isRequired,
  onLoggedIn: PropType.func.isRequired,
  onMfaChallenge: PropType.func.isRequired,
  forgotPasswordUrl: PropType.string,
  signUpUrl: PropType.string
}

const defaultProps = {
  forgotPasswordUrl: '/forgot-password',
  signUpUrl: '/sign-up'
}

const LoginForm = ({ forgotPasswordUrl, signUpUrl, onAccountCancelled, onLoggedIn, onMfaChallenge }) => {
  const formId = useId()
  const { goToStep } = useWizard()

  const goToNextStep = useCallback((mfaChallenge) => {
    if (mfaChallenge.availableMethods.length === 0) {
      goToStep(1)
    } else if (mfaChallenge.availableMethods.length === 1) {
      goToStep(4)
    } else {
      goToStep(3)
    }
  }, [goToStep])

  const handleReply = useCallback((reply) => {
    if (reply.ok) {
      onLoggedIn()
    } else {
      if (reply?.status === 403) {
        return onAccountCancelled()
      }

      if (reply?.status === 428) {
        const mfaChallenge = reply.json.data
        onMfaChallenge(mfaChallenge)
        goToNextStep(mfaChallenge)
      }
    }
  }, [goToNextStep, onAccountCancelled, onLoggedIn, onMfaChallenge])
  const { call: login } = useServiceAndAction(loginService, loginAction, { onReply: handleReply })

  const handleSubmit = useCallback(({ email, password }) => login(email, password), [login])

  const defaultValues = {
    email: process.env.REACT_APP_DEV_USERNAME,
    password: process.env.REACT_APP_DEV_PASSWORD
  }
  const formControls = [
    {
      autoComplete: 'username',
      name: 'email',
      placeholder: 'Email Address',
      type: 'email',
      autoFocus: true
    },
    {
      autoComplete: 'current-password',
      name: 'password',
      placeholder: 'Password',
      type: 'password'
    }
  ]

  return (
    <>
      <Header heading='Welcome to' subtitle='Helping you build relations through business communications.' title='Project Broadcast!' />
      <Form
        className='mt-5'
        defaultValues={defaultValues}
        formControls={formControls}
        id={formId}
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <Link className='text-primary label-sm-medium' to={forgotPasswordUrl}>
        Forgot password?
      </Link>
      <Button
        className='mt-5 w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Sign In
      </Button>
      <SignUpLink signUpUrl={signUpUrl} />
    </>
  )
}

LoginForm.displayName = 'LoginForm'
LoginForm.propTypes = propTypes
LoginForm.defaultProps = defaultProps

export default LoginForm
