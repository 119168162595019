import ListActionLink from '../../../components/links/ListActionLink'
import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'

function ToolsActionsList () {
  return (
    <Panel>
      <PanelHeader title='Admin Tools' />
      <PanelContent className='text-black'>
        <div className='border-neutral-200'>
          <ListActionLink title='Brand Validator' to='brand-validator' />
          <ListActionLink title='Goals' to='goals' />
          <ListActionLink title='Spammers' to='spammers' />
          <ListActionLink title='Stats' to='stats' />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default ToolsActionsList
