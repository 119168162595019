import * as React from "react";
import { memo } from "react";
const SvgPictureFrame = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 3a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v13h-3.04c-.2-1.231-1.124-2.2-2.219-2.838C15.476 12.425 13.796 12 12 12c-1.796 0-3.476.425-4.741 1.162C6.164 13.8 5.24 14.77 5.04 16H2V3ZM0 17V3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4Zm22 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3h20ZM10.5 7.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM12 4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm3.734 10.89c.624.364.979.754 1.146 1.11H7.12c.167-.356.522-.746 1.146-1.11.91-.53 2.23-.89 3.734-.89s2.824.36 3.734.89Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPictureFrame);
export default Memo;
