import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import useTimeZone from '../../hooks/useTimeZone'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

const SetTimeZone = () => {
  const handleReplyOk = useCallback(() => {
    notify('Time Zone updated successfully.')
  }, [])
  const { dataForSelect, loading: timeZoneInfoLoading, userTimeZone } = useTimeZone()
  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateAction, { onReplyOk: handleReplyOk })

  const title = 'Set Time Zone'

  if (timeZoneInfoLoading || !dataForSelect) { return (<PanelLoading title={title} />) }

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='setTimeZoneForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent className='p-5'>
        <div className='pb-4'>All messages originating from your Project Broadcast number will be based on the Time Zone setting.</div>
        <Form
          defaultValues={{
            timeZone: userTimeZone
          }}
          formControls={[
            {
              type: 'select',
              name: 'timeZone',
              label: 'Time Zone',
              required: true,
              onChange: null,
              options: dataForSelect,
              placeholder: 'Choose Time Zone'
            }
          ]}
          id='setTimeZoneForm'
          onSubmit={({ timeZone }) => {
            updateProfile({ timezone: timeZone })
          }}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='setTimeZoneForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

SetTimeZone.displayName = 'SetTimeZone'

export default SetTimeZone
