import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import logger from '../lib/logger'
import {
  verifyPartnerRequest as verifyPartnerRequestService
} from '../services/integration'

import useLogger from './useLogger'
import useService from './useService'

const log = logger({ enabled: false, tags: ['usePartnerRequest'] })

const usePartnerRequest = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [integration, setIntegration] = useState(null)
  const [verified, setVerified] = useState(false)
  const [verificationComplete, setVerificationComplete] = useState(false)

  const { partnerId } = useParams()

  const handleReplyOk = useCallback((reply) => {
    log.debug('reply ok', reply)
    setIntegration(reply.json.meta.integration)
    setVerified(true)
    setVerificationComplete(true)
  }, [])
  const handleReplyNotOk = useCallback((reply) => {
    log.debug('reply not ok', reply)
    setVerificationComplete(true)
  }, [])
  const { call: verifyPartnerRequest } = useService(verifyPartnerRequestService, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })

  const search = window.location.search
  const connectUrl = partnerId ? `/integrations/${partnerId}/connect${search}` : undefined
  const internalAllowUrl = partnerId ? `/account/integrations/${partnerId}/allow${search}` : undefined
  const internalConnectUrl = partnerId ? `/account/integrations/${partnerId}/connect${search}` : undefined
  const forgotPasswordUrl = partnerId ? `/integrations/${partnerId}/forgot-password${search}` : undefined
  const reactivateUrl = partnerId ? `/integrations/${partnerId}/reactivate${search}` : undefined
  const signUpUrl = partnerId ? `/integrations/${partnerId}/signup${search}` : undefined

  useEffect(() => {
    if (!verificationComplete) {
      if (partnerId) {
        log.debug('get config for partner', partnerId)
        verifyPartnerRequest(partnerId, search)
      } else {
        log.debug('no partnerId')
        setVerificationComplete(true)
      }
    }
  }, [partnerId, search, verificationComplete, verifyPartnerRequest])

  return {
    connectUrl,
    forgotPasswordUrl,
    integration,
    internalAllowUrl,
    internalConnectUrl,
    partnerId,
    reactivateUrl,
    signUpUrl,
    verificationComplete,
    verified
  }
}

export default usePartnerRequest
