import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Warning } from '../../../svg/icons'

const propTypes = {
  hasError: PropType.bool.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ErrorBadge'] })

const ErrorBadge = ({ hasError }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (hasError !== true) { return null }

  return (
    <div className='flex flex-row flex-nowrap items-center'>
      <Warning className='text-error' height='16' width='16' />
      <span className='pt-1 pl-1 label-xs text-error'>
        View message to see errors
      </span>
    </div>
  )
}

ErrorBadge.displayName = 'ErrorBadge'
ErrorBadge.propTypes = propTypes
ErrorBadge.defaultProps = defaultProps

export default ErrorBadge
