import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createTrackableLinkService } from '../../../services/trackableLink'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import FormDialog from '../../dialog/FormDialog'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: undefined
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const dialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    dialogRef.current.close()
    const trackableLink = reply.json
    setCurrentInstance('trackableLink', trackableLink)
    success(`${trackableLink.name} created`)
    bus.emit('trackableLinkCreated', trackableLink)
    if (!createdFromFolder) { navigate(`/trackable-links/${trackableLink._id}`) }
    onSuccess?.(trackableLink)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createTrackableLink } = useService(createTrackableLinkService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((createData) => {
    createTrackableLink(createData)
  }, [createTrackableLink])

  return (
    <FormDialog
      ref={dialogRef}
      defaultValues={{ name: '', url: '' }}
      description='Trackable Links allow you to have a named link sent through Project Broadcast that is tracked when clicked by your contacts. Because Trackable Links offer tagging, the name can only include letters, numbers, dashes, spaces, and underscores'
      formControls={[
        {
          autoComplete: 'off',
          label: 'Name',
          name: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'URL',
          name: 'url',
          type: 'url',
          required: true
        }
      ]}
      title='New Trackable Link'
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
