import * as React from "react";
import { memo } from "react";
const SvgTraining = (props) => (
  <svg
    viewBox="0 0 121 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.422 79.5H33.278c-2.754 0-4.988-2.373-4.988-5.3 0-2.927 2.234-5.3 4.988-5.3H5.488C2.733 68.9.5 66.527.5 63.6c0-2.927 2.233-5.3 4.988-5.3h28.503c2.755 0 4.988-2.373 4.988-5.3 0-2.927-2.233-5.3-4.988-5.3H16.177c-2.755 0-4.988-2.373-4.988-5.3 0-2.927 2.233-5.3 4.988-5.3H44.68c-2.755 0-4.988-2.373-4.988-5.3 0-2.927 2.233-5.3 4.988-5.3h69.832c2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3H86.009c2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3h15.677c2.754 0 4.988 2.373 4.988 5.3 0 2.927-2.234 5.3-4.988 5.3h-7.25c-3.474 0-6.29 2.373-6.29 5.3 0 2.927 4.276 5.3 4.276 5.3 2.755 0 4.988 2.373 4.988 5.3 0 2.927-2.233 5.3-4.988 5.3Z"
      fill="#EEF2FE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.025 76.256H85.23c.566 0 1.025-.459 1.025-1.025V25.025c0-.566-.459-1.025-1.025-1.025H35.025c-.566 0-1.025.459-1.025 1.025V75.23c0 .566.459 1.025 1.025 1.025Zm1.024-10.247V34.246h48.158v31.763H36.049Zm0 2.05h48.158v6.148H36.049v-6.149Zm0-35.862v-6.148h48.158v6.148H36.049Zm3.075 31.763h26.64c.566 0 1.025-.459 1.025-1.025V37.32c0-.566-.46-1.025-1.025-1.025h-26.64c-.566 0-1.025.459-1.025 1.025v25.615c0 .566.46 1.025 1.025 1.025Zm25.616-2.05H40.149V38.344h24.59V61.91Zm16.392 10.246H39.123a1.025 1.025 0 0 1 0-2.049h42.009a1.025 1.025 0 0 1 0 2.05Zm-11.27-22.54h11.27c.567 0 1.025-.46 1.025-1.025v-11.27c0-.566-.458-1.025-1.024-1.025h-11.27c-.566 0-1.025.459-1.025 1.025v11.27c0 .566.459 1.025 1.024 1.025Zm10.247-2.05h-9.222v-9.221h9.222v9.221Zm1.024 16.394h-11.27a1.025 1.025 0 0 1 0-2.05h11.27a1.025 1.025 0 0 1 0 2.05Zm-11.27-10.246h11.27a1.025 1.025 0 0 0 0-2.05h-11.27a1.025 1.025 0 0 0 0 2.05Zm11.27 5.123h-11.27a1.025 1.025 0 0 1 0-2.05h11.27a1.025 1.025 0 0 1 0 2.05Zm-27.665-28.69H39.123a1.025 1.025 0 0 1 0-2.049h14.344a1.025 1.025 0 0 1 0 2.05Zm-7.696 27.521c-.31-.185-.5-.519-.5-.88v-13.32a1.024 1.024 0 0 1 1.512-.9l12.296 6.66a1.025 1.025 0 0 1 0 1.8l-12.295 6.66a1.024 1.024 0 0 1-1.013-.02Zm1.55-2.6 9.119-4.94-9.12-4.94v9.88Zm28.988-26.67c-.4.4-.4 1.05 0 1.45.402.4 1.259.19 1.45 0a1.025 1.025 0 0 0-1.45-1.45Zm-4.098 1.45a1.025 1.025 0 0 1 1.449-1.45 1.025 1.025 0 0 1 0 1.45c-.19.19-1.048.4-1.449 0Zm8.197-1.45a1.025 1.025 0 0 0 0 1.45c.4.4 1.258.19 1.448 0a1.025 1.025 0 0 0-1.448-1.45Z"
      fill="#000"
    />
  </svg>
);
const Memo = memo(SvgTraining);
export default Memo;
