import * as React from "react";
import { memo } from "react";
const SvgPlaceholder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.532 20.812a2.463 2.463 0 0 0 .95.188h5.742a2.483 2.483 0 0 0-2.483-2.483H5.483V15.26A2.483 2.483 0 0 0 3 12.776v5.741a2.507 2.507 0 0 0 .188.95 2.482 2.482 0 0 0 1.344 1.345Zm.95-12.07A2.483 2.483 0 0 1 3 11.223V5.483a2.507 2.507 0 0 1 .188-.95A2.482 2.482 0 0 1 5.483 3h5.741a2.483 2.483 0 0 1-2.483 2.483H5.483V8.74Zm9.777-3.26A2.483 2.483 0 0 1 12.776 3h5.741a2.507 2.507 0 0 1 .95.188A2.482 2.482 0 0 1 21 5.483v5.741a2.483 2.483 0 0 1-2.483-2.483V5.483H15.26Zm3.258 9.777A2.483 2.483 0 0 1 21 12.776v5.741a2.495 2.495 0 0 1-.188.95 2.48 2.48 0 0 1-1.344 1.345 2.462 2.462 0 0 1-.95.188h-5.742a2.483 2.483 0 0 1 2.483-2.483h3.258V15.26ZM12 14.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPlaceholder);
export default Memo;
