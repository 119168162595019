import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useServiceAndAction from '../../hooks/useServiceAndAction'
import { logout as logoutService } from '../../services/user'
import { logout as logoutAction } from '../../store/actions/currentUser'

function Logout () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { call: logoutCall } = useServiceAndAction(logoutService, logoutAction, {
    onReplyOk: useCallback((logoutReply) => {
    // NOTE: The current user is returned when "manage user end" occurs via logout
      const manageUserEnded = !!logoutReply.model?._id
      const redirectTo = searchParams.get('to')
      navigate(redirectTo || (manageUserEnded ? '/managed-accounts' : '/'), { replace: true })
    }, [navigate, searchParams])
  })

  useEffect(() => {
    logoutCall()
  }, [logoutCall])
}

export default Logout
