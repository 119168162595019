import { FloatingOverlay } from '@floating-ui/react'
import classNames from 'classnames'
import PropType from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
  enabled: PropType.bool,
  hasBackground: PropType.bool,
  lockScroll: PropType.bool
}

const defaultProps = {
  className: null,
  enabled: true,
  hasBackground: true,
  lockScroll: true
}

const log = logger({ enabled: true, tags: ['Overlay'] })

const Overlay = ({ children, className, enabled, hasBackground, lockScroll }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const timerRef = useRef(null)

  useEffect(() => { timerRef.current = new Date() }, [])

  const computedClassName = classNames(
    '!overflow-hidden', // Prevents over scrolling caused by `overflow: auto` style that floating-ui adds here
    hasBackground ? 'bg-overlay backdrop-blur-sm' : '',
    className
  )

  // This is really just trying to prevent long press events
  // that trigger a dialog from also triggering a contexxt
  // menu on some touch devices. So we prevent the context
  // menu from showing within a given time frame of rendering.
  const handleContextMenu = useCallback((event) => {
    const now = new Date()
    const diff = now - timerRef.current
    if (diff < 250) {
      event.preventDefault()
    }
  }, [])

  return (
    enabled ? (<FloatingOverlay className={computedClassName} lockScroll={lockScroll} onContextMenu={handleContextMenu}>{children}</FloatingOverlay>) : children
  )
}

Overlay.displayName = 'Overlay'
Overlay.propTypes = propTypes
Overlay.defaultProps = defaultProps

export default Overlay
