import PropType from 'prop-types'
import { forwardRef, useMemo } from 'react'

import FormDialog from '../../../components/dialog/FormDialog'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'

const propTypes = {
  onSubmit: PropType.func
}

const defaultProps = {
  onSubmit: null
}

const log = logger({ enabled: false, tags: ['EventDateDialog'] })

const EventDateDialog = forwardRef(({ onSubmit }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { userTimeZone } = useTimeZone()

  const { formControls, transformData } = useMemo(() => ({
    formControls: [
      { label: '', name: 'date', type: 'date', required: true },
      { label: '', name: 'time', type: 'time', required: true }
    ],
    transformData: ({ date, time }) => ({
      eventDate: dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    })
  }), [userTimeZone])

  return (
    <FormDialog
      ref={ref}
      defaultValues={{}}
      description='The final step in adding contacts to this campaign is to set their event date and time.'
      formControls={formControls}
      submitTitle='Save'
      title='Set Event Date and Time'
      transformData={transformData}
      onSubmit={onSubmit}
    />
  )
})

EventDateDialog.displayName = 'EventDateDialog'
EventDateDialog.propTypes = propTypes
EventDateDialog.defaultProps = defaultProps

export default EventDateDialog
