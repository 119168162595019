import * as React from "react";
import { memo } from "react";
const SvgTravel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Travel_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.9 1.474A1 1 0 0 1 6.75 1h3a1 1 0 0 1 .753.341L17.203 9h3.547c1.652 0 3 1.348 3 3s-1.348 3-3 3h-3.556l-6.701 7.659A1 1 0 0 1 9.74 23H6.75a1 1 0 0 1-.895-1.446L9.124 15H5.851l-2.226 1.78A1 1 0 0 1 3 17H1a1 1 0 0 1-.97-1.242L.97 12 .03 8.243A1 1 0 0 1 1 7h2a1 1 0 0 1 .625.22L5.85 9h3.281L5.856 2.447a1 1 0 0 1 .043-.973ZM10.73 11a.97.97 0 0 0 .042 0h9.978c.548 0 1 .452 1 1s-.452 1-1 1h-4a.51.51 0 0 0-.018 0H5.5a1 1 0 0 0-.625.22L2.65 15h-.368l.69-2.758a1 1 0 0 0 0-.485L2.28 9h.368l2.226 1.78A1 1 0 0 0 5.5 11h5.23Zm.629 4-2.993 6h.92l5.25-6H11.36Zm3.187-6h-3.178l-3-6h.928l5.25 6Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Travel_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTravel);
export default Memo;
