import PropType from 'prop-types'

import { campaignTypes } from '../helpers/campaign'
import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  contactId: PropType.string.isRequired,
  paging: PropType.shape(paging).isRequired,
  term: PropType.string,
  type: PropType.oneOf(campaignTypes)
}

export const addOnePropTypes = {
  contactId: PropType.string.isRequired,
  campaignId: PropType.string.isRequired
}

export const removeOnePropTypes = {
  contactId: PropType.string.isRequired,
  campaignId: PropType.string.isRequired
}
