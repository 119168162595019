import { debounce } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import TextInput from '../forms//controls/TextInput'
import InputIcon from '../forms/InputIcon'

const propTypes = {
  start: PropType.node.isRequired,
  onChange: PropType.func.isRequired,
  onClear: PropType.func.isRequired,
  placeholder: PropType.string,
  term: PropType.string
}
const defaultProps = {
  placeholder: 'Search',
  term: ''
}

const log = logger({ enabled: true, tags: ['TermInput'] })

const TermInput = forwardRef(({ start, term: initialTerm, placeholder, onChange, onClear }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })
  const inputRef = useRef(null)

  const [value, setValue] = useState(initialTerm)

  const debouncedOnChange = useMemo(() => debounce(onChange, 225), [onChange])

  useImperativeHandle(ref, () => ({
    setTerm (term) { setValue(term) }
  }))

  const useFormResults = useForm({
    shouldUseNativeValidation: false,
    defaultValues: { term: initialTerm }
  })

  const handleOnChange = useCallback((event) => {
    const value = event.target.value
    debouncedOnChange(value)
    setValue(value)
  }, [debouncedOnChange])

  const handleKeyDown = useCallback((event) => {
    // blur the input when pressing enter/return
    // this dismisses virtual keyboards
    if (event.key === 'Enter') {
      inputRef.current.blur()
    }
  }, [])

  return (
    <FormProvider {...useFormResults}>
      <TextInput
        ref={inputRef}
        end={value ? <InputIcon icon='close' onClick={onClear} /> : null}
        name='term'
        placeholder={placeholder}
        start={start}
        type='text'
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
    </FormProvider>
  )
})

TermInput.displayName = 'TermInput'
TermInput.propTypes = propTypes
TermInput.defaultProps = defaultProps

export default TermInput
