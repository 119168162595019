import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/scheduledBroadcast-contact'
import ContactList from '../contacts/ContactList'

const propTypes = {
  scheduledBroadcastId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastContactList'] })

const ScheduledBroadcastContactList = forwardRef(({ scheduledBroadcastId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcastId] })

  return (
    <ContactList
      ref={ref}
      itemSharedContext={{ scheduledBroadcastId }}
      queryOverrides={{ scheduledBroadcastId }}
      searchService={searchService}
      {...rest}
    />
  )
})

ScheduledBroadcastContactList.displayName = 'ScheduledBroadcastContactList'
ScheduledBroadcastContactList.propTypes = propTypes
ScheduledBroadcastContactList.defaultProps = defaultProps

export default ScheduledBroadcastContactList
