import PropType from 'prop-types'

import { campaignTypes, repeatFrequencies } from '../../helpers/campaign'
import messageTypes from '../../helpers/messageTypes'

import campaign from './campaign'
import keyword from './keyword'
import user from './user'

const eventContact = {
  _id: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string,
  phoneNumber: PropType.string
}

export default {
  __v: PropType.number,
  _id: PropType.string,
  user: PropType.oneOfType([
    PropType.string,
    PropType.shape(user)
  ]),
  campaign: PropType.oneOfType([
    PropType.string,
    PropType.shape(campaign)
  ]),
  campaignId: PropType.string,
  campaignType: PropType.oneOf(campaignTypes),
  name: PropType.string, // "Intro Message",
  type: PropType.oneOf(messageTypes),
  text: PropType.string, // "Good morning from High Bar BBQ!",
  media: PropType.arrayOf(PropType.string), // ["https://projectbroadcast.s3.amazonaws.com/media/600aceb4710b5f001759caa2/1611321012005/srG5Lu6et.wav"]
  scheduledAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  scheduledDelayDays: PropType.number, // applicable when campaignType === 'drip'
  scheduledBeforeDays: PropType.number, // applicable when campaignType === 'countdown' or 'event'
  scheduledDelayHours: PropType.number, // applicable when campaignType === 'delay'
  scheduledDelayMinutes: PropType.number, // applicable when campaignType === 'delay'
  scheduledRepeat: PropType.oneOf(repeatFrequencies), // applicable when campaignType === 'repeat'
  keyword: PropType.oneOfType([
    PropType.string,
    PropType.shape(keyword)
  ]),
  contactsScheduled: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(eventContact))
  }),
  contactsSent: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(eventContact))
  }),
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  updatedAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  archived: PropType.bool
}
