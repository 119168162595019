import * as React from 'react'
import { memo } from 'react'
const SvgCountdown = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#DBEAFF" rx={4} />
        <path
            fill="#2563EC"
            fillRule="evenodd"
            d="M9.818 5.455a.545.545 0 1 0 0 1.09h4.364a.545.545 0 1 0 0-1.09H9.818ZM7.636 13.09a4.364 4.364 0 1 1 8.728 0 4.364 4.364 0 0 1-8.728 0ZM12 7.636a5.455 5.455 0 1 0 4.46 2.313l.835-.836a.545.545 0 0 0-.772-.771l-.78.78A5.436 5.436 0 0 0 12 7.637Zm.546 2.728a.545.545 0 0 0-1.091 0v2.727a.545.545 0 1 0 1.09 0v-2.727Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgCountdown)
export default Memo
