import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { includesTrial, isPayingPlan } from '../../lib/plans'

const propTypes = {
  plan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }).isRequired,
  reanchorImmediately: PropType.bool
}

const defaultProps = {
  reanchorImmediately: false
}

const log = logger({ enabled: false, tags: ['SummaryTextFragment'] })

const SummaryTextFragment = ({ plan, reanchorImmediately }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!plan) {
    return ''
  }

  return (
    <>
      {'Project Broadcast is a subscription based service. You have chosen the '}
      <strong>{plan.name}</strong>
      {isPayingPlan(plan)
        ? (
          <>
            {' plan and will be billed '}
            <strong>
              {plan.price}
              {' '}
              {plan.frequency}
            </strong>
            {includesTrial(plan) ? ` starting at the end of your ${plan.newUserTrialInDays} day trial` : null}
            .
            {plan.credits > 0
              ? (
                <>
                  {' You will receive '}
                  <strong>
                    {(plan.actualCredits || plan.credits).toLocaleString()}
                  </strong>
                  {' credits upon each successful renewal of your subscription.'}
                </>
                )
              : null}
          </>
          )
        : ' plan.'}
      {' '}
      <strong>
        {reanchorImmediately ? 'This change will take effect immediately. ' : null}
        Refunds are not provided.
      </strong>
    </>
  )
}

SummaryTextFragment.displayName = 'SummaryTextFragment'
SummaryTextFragment.propTypes = propTypes
SummaryTextFragment.defaultProps = defaultProps

export default SummaryTextFragment
