import { useCallback, useRef } from 'react'

import { notify, error as notifyError, success } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Message from '../../../components/forms/controls/Message'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageFieldsNormalizer from '../../../services/messageFieldsNormalizer'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Messages'] })

const Messages = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const messageControlRef = useRef()

  const handleSchedule = useCallback((data) => {
    log.info('raw data', data)
    log.info('using messageFieldNormalizer', messageFieldsNormalizer(data))
    notify('Pretend we scheduled that...')
  }, [])
  const handleSend = useCallback((data) => {
    log.info('raw data', data)
    log.info('using messageFieldNormalizer', messageFieldsNormalizer(data))
    notify('Pretend we sent that...')
  }, [])

  const handleValidateMessageClick = useCallback(async (event) => {
    log.info(messageControlRef.current.message)
    const error = await messageControlRef.current.hasError()
    if (error) {
      return notifyError(error)
    } else {
      return success('Message is valid.')
    }
  }, [])

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Messages' />
      <PanelContent>
        <div className='p-4'>
          <div className='mb-6'>
            <h2 className='headline-xs'>Theme: chat</h2>
            <Message
              name='testing-as-chat'
              placeholder='Type message here...'
              theme='chat'
              onSchedule={handleSchedule}
              onSend={handleSend}
            />
          </div>
          <div className='mb-6'>
            <h2 className='headline-xs'>Theme: space</h2>
            <Message
              name='testing-as-space'
              placeholder='Type message here...'
              theme='space'
              onSchedule={handleSchedule}
              onSend={handleSend}
            />
          </div>
          <div className='mb-6'>
            <h2 className='headline-xs'>Theme: input</h2>
            <Message
              ref={messageControlRef}
              error='This is the error...'
              hint='This is the hint...'
              label='Message Input'
              name='testing-as-input'
              placeholder='Type message here...'
              onSchedule={handleSchedule}
              onSend={handleSend}
            />
            <Button size='sm' onClick={handleValidateMessageClick}>Validate Message</Button>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

Messages.displayName = 'Messages'
Messages.propTypes = propTypes
Messages.defaultProps = defaultProps

export default Messages
