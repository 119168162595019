import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import keywordShape from '../../../prop-types/shapes/keyword'
import { clone as cloneService } from '../../../services/keyword'
import { success } from '../../banners/Banner'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const CloneAction = forwardRef(({ keyword, onSuccess }, ref) => {
  const handleReplyOk = useCallback(() => {
    success(`${keyword.word} was cloned`)
    bus.emit('keywordCloned', keyword)
    onSuccess?.()
  }, [keyword, onSuccess])
  const { call: cloneKeywordCall } = useService(cloneService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { cloneKeywordCall(keyword._id) }
  }), [keyword, cloneKeywordCall])
})

CloneAction.displayName = 'CloneAction'
CloneAction.propTypes = propTypes
CloneAction.defaultProps = defaultProps

export default CloneAction
