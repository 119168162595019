import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function Switch () {
  return (
    <Panel>
      <PanelHeader title='Switches' />
      <PanelContent>
        <div className='p-4'>
          <label className='flex items-center m-2'>
            <input className='switch' type='checkbox' />
            <span className='ml-2'>Default Unchecked</span>
          </label>
          <label className='flex items-center m-2'>
            <input className='switch' type='checkbox' defaultChecked />
            <span className='ml-2'>Default Checked</span>
          </label>
          <label className='flex items-center m-2'>
            <input className='switch' type='checkbox' disabled />
            <span className='ml-2'>Disabled Unchecked</span>
          </label>
          <label className='flex items-center m-2'>
            <input
              className='switch'
              type='checkbox'
              defaultChecked
              disabled
            />
            <span className='ml-2'>Disabled Checked</span>
          </label>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Switch
