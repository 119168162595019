import { pick } from 'lodash'
import { useCallback } from 'react'

import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelErrorBanner from '../../components/panels/PanelErrorBanner'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { useApp } from '../../contexts/AppContext'
import useCurrentAppFlow from '../../hooks/useCurrentAppFlow'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateAppFlowService } from '../../services/appFlow'

import DesignDetail from './detail/DesignDetail'
import DetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import InsightsDetail from './detail/InsightsDetail'

const log = logger({ enabled: true, tags: ['AppFlowsDetail'] })

const AppFlowsDetail = () => {
  const { appFlowId, appFlow, setAppFlow, refresh } = useCurrentAppFlow()
  const currentUser = useCurrentUser()
  const { isWeb } = useApp()

  useLogger({ log, lifecycle: false, tags: [appFlowId] })

  const handleOnChange = useCallback((updateReply) => {
    if (updateReply) {
      setAppFlow(updateReply.json)
      bus.emit('appFlowUpdated', updateReply.json)
    } else {
      refresh()
    }
  }, [refresh, setAppFlow])

  if (!appFlow) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<DetailHeaderMoreMenu appFlow={appFlow} />}
        start={<PanelHeaderBackButton />}
        title={appFlow.name}
      />
      <PanelContent>
        {currentUser.messageCredits <= 0
          ? <PanelErrorBanner
              message='App Flows Stopped - Insufficient Credit Balance'
              resolutionHref='/subscription/buy-credits'
              resolutionTitle='Buy Credits'
              showResolution={isWeb}
            />
          : null}
        <div className='p-5'>
          <FormDetailSection
            defaultValues={pick(appFlow, ['name'])}
            description='Name the app flow so it is easy to find later.'
            formControls={[{ autoComplete: 'off', label: 'Name', name: 'name', type: 'text', required: true }]}
            formDescription='Updating name for app flow'
            formTitle={appFlow.name}
            objectId={appFlow._id}
            service={updateAppFlowService}
            title='Name'
            showEditIcon
            onChange={handleOnChange}
          >
            {appFlow.name}
          </FormDetailSection>
          <FormDetailSection
            defaultValues={pick(appFlow, ['description'])}
            description='Provide a short description of this flow if desired.'
            formControls={[{ label: 'Description', name: 'description', type: 'textarea', required: true }]}
            formDescription='Updating description for app flow'
            formTitle={appFlow.name}
            objectId={appFlow._id}
            service={updateAppFlowService}
            title='Description'
            showEditIcon
            onChange={handleOnChange}
          >
            {appFlow.description || <br />}
          </FormDetailSection>
          <DesignDetail
            appFlow={appFlow}
            onChange={handleOnChange}
          />
          {appFlow.hasDesign ? <InsightsDetail appFlow={appFlow} /> : null}
        </div>
      </PanelContent>
    </Panel>
  )
}

AppFlowsDetail.displayName = 'AppFlowsDetail'

export default AppFlowsDetail
