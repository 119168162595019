import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)
  test('phoneNumber', 'Phone number is required', () => {
    enforce(data.phoneNumber).isNotEmpty()
  })
  test('credits', 'Credit is required', () => {
    enforce(data.credits).isNotEmpty()
  })
})

export default validationSuite
