import PropType from 'prop-types'

import paging from '../prop-types/shapes/paging'

export const addPropTypes = {
  folderId: PropType.string,
  contentType: PropType.string,
  contentIds: PropType.arrayOf(PropType.string),
  movingItemsConfirmed: PropType.bool
}

export const createPropTypes = {
  name: PropType.string,
  contentType: PropType.string,
  contentIds: PropType.arrayOf(PropType.string),
  movingItemsConfirmed: PropType.bool
}

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  contentType: PropType.string,
  term: PropType.string
}
