import { get as getContestService } from '../services/contest'

import useCurrentInstance from './useCurrentInstance'

const useCurrentContest = () => useCurrentInstance({
  resourceName: 'contest',
  getService: getContestService,
  replyAttrPath: 'model',
  notFoundRedirect: '/contests'
})

export default useCurrentContest
