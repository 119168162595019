import * as React from "react";
import { memo } from "react";
const SvgGift = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.038 1.569a4.064 4.064 0 0 0-5.214.93.986.986 0 0 0-.055.074L13 5.213l-1.769-2.64a1.015 1.015 0 0 0-.054-.073 4.064 4.064 0 0 0-5.214-.931A3.97 3.97 0 0 0 4.13 6H3a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V8h1a1 1 0 1 0 0-2h-1.13a3.97 3.97 0 0 0-1.832-4.431ZM19.719 6a1.966 1.966 0 0 0-.696-2.708l-.003-.002a2.064 2.064 0 0 0-2.617.439L14.88 6h4.838Zm-6.862 2c.095.014.19.013.284 0H20v12h-4v-9a1 1 0 0 0-1-1H6V8h6.857ZM11.12 6 9.597 3.729A2.064 2.064 0 0 0 6.98 3.29l-.003.002A1.965 1.965 0 0 0 6.28 6h4.838ZM2 12h5v3H2v-3Zm7 3v-3h5v3H9Zm0 2h5v3H9v-3Zm-2 0v3H2v-3h5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGift);
export default Memo;
