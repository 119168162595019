import PropType from 'prop-types'

export default {
  __v: PropType.number,
  _id: PropType.string,
  enabled: PropType.bool,
  name: PropType.string,
  search: PropType.string,
  unlayerDesign: PropType.object,
  unlayerHtmlChunks: PropType.object,
  previewImageUrl: PropType.string,
  type: PropType.oneOf(['email', 'landingPage'])
}
