import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import manager from '../../../prop-types/shapes/manager'
import { remove as removeService } from '../../../services/user-manager'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  manager: PropType.shape(manager).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['RemoveAction'] })

const RemoveAction = forwardRef(({ manager }, ref) => {
  useLogger({ log, lifecycle: false, tags: [manager._id] })
  const confirmRef = useRef()
  const { call: removeCall } = useService(removeService, {
    onReplyOk: useCallback(() => {
      bus.emit('managerRemoved', manager)
    }, [manager])
  })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    removeCall(manager._id)
  }, [removeCall, manager])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description={`Are you sure you want to remove ${manager.formattedName} as a manager for your account?  They will no longer have the ability to manage your account unless you re-add them later.`}
      title='Remove Manager'
      onConfirm={handleConfirm}
    />
  )
})

RemoveAction.displayName = 'RemoveAction'
RemoveAction.propTypes = propTypes
RemoveAction.defaultProps = defaultProps

export default RemoveAction
