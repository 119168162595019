import React from 'react'

import PanelNotFound from '../../components/panels/PanelNotFound'
import GoalDetail from '../../screens/admin/goals/GoalDetail'
import GoalMilestoneDetail from '../../screens/admin/goals/GoalMilestoneDetail'
import GoalsList from '../../screens/admin/goals/GoalsList'
import BrandValidator from '../../screens/admin/tools/BrandValidator'
import SpammerList from '../../screens/admin/tools/spammers/SpammerList'
import Stats from '../../screens/admin/tools/Stats'

const routes = [
  {
    path: 'stats',
    element: <Stats />
  },
  {
    path: 'brand-validator',
    element: <BrandValidator />
  },
  {
    path: 'goals',
    element: <GoalsList />
  },
  {
    path: 'goals/:goalId',
    element: <GoalDetail />
  },
  {
    path: 'goals/:goalId/:goalMilestoneId',
    element: <GoalMilestoneDetail />
  },
  {
    path: 'spammers',
    element: <SpammerList />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
