import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import BillingAccountList from '../../../components/billing-accounts/BillingAccountList'
import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  title: PropType.string
}

const defaultProps = {
  title: 'Billing Accounts'
}

const log = logger({ enabled: false, tags: ['BillingAccountsList'] })

const BillingAccountsList = ({ title }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [total, setTotal] = useState(0)

  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader count={total} title={title} />
      <PanelContent>
        <BillingAccountList savedStateKey='admin-billing-accounts' onTotalChange={handleTotalChange} />
      </PanelContent>
    </Panel>
  )
}

BillingAccountsList.displayName = 'BillingAccountsList'
BillingAccountsList.propTypes = propTypes
BillingAccountsList.defaultProps = defaultProps

export default BillingAccountsList
