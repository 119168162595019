import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import Checkbox from './controls/Checkbox'
import CountrySelect from './controls/CountrySelect'
import DateTimeSelect from './controls/DateTimeSelect'
import HiddenInput from './controls/HiddenInput'
import Message from './controls/Message'
import ModelSelect from './controls/ModelSelect'
import Passthrough from './controls/Passthrough'
import PasswordInput from './controls/PasswordInput'
import RadioGroup from './controls/RadioGroup'
import Select from './controls/Select'
import StateProvinceSelect from './controls/StateProvinceSelect'
import StripeCardElement from './controls/StripeCardElement'
import TextArea from './controls/TextArea'
import TextInput from './controls/TextInput'
import TextSample from './controls/TextSample'
import VoiceDrop from './controls/VoiceDrop'

const propTypes = {
  containerClassName: PropType.string,
  type: PropType.oneOf([
    'checkbox',
    'countryselect',
    'date',
    'datetimeselect',
    'email',
    'hidden',
    'message',
    'modelselect',
    'number',
    'passthrough',
    'password',
    'radiogroup',
    'select',
    'stateprovince',
    'stripecardelement',
    'tel',
    'text',
    'textarea',
    'textsample',
    'time',
    'time-message-send',
    'url',
    'voicedrop'
  ])
}

const defaultProps = {
  containerClassName: '',
  type: 'text'
}

function isVisibleFormControl (type) {
  return type !== 'hidden'
}

const log = logger({ enabled: false, tags: ['FormControl'] })

const FormControl = forwardRef(({ type, containerClassName, ...rest }, ref) => {
  useLogger({ log, lifecycle: true, tags: [type] })

  const DelegatedControl = useMemo(() => {
    switch (type) {
      case 'message':
        return Message
      case 'modelselect':
        return ModelSelect
      case 'countryselect':
        return CountrySelect
      case 'stateprovince':
        return StateProvinceSelect
      case 'stripecardelement':
        return StripeCardElement
      case 'password':
        return PasswordInput
      case 'datetimeselect':
        return DateTimeSelect
      case 'date':
      case 'email':
      case 'number':
      case 'tel':
      case 'text':
      case 'time':
      case 'time-message-send':
      case 'url':
        return TextInput
      case 'hidden':
        return HiddenInput
      case 'checkbox':
        return Checkbox
      case 'radiogroup':
        return RadioGroup
      case 'select':
        return Select
      case 'textarea':
        return TextArea
      case 'textsample':
        return TextSample
      case 'passthrough':
        return Passthrough
      case 'voicedrop':
        return VoiceDrop
      default:
        throw new Error(`Unknown Form Control type: ${type}`)
    }
  }, [type])

  return (
    <div className={isVisibleFormControl(type) ? classNames('mb-5', containerClassName) : 'hidden'}>
      <DelegatedControl ref={ref} type={type} {...rest} />
    </div>
  )
})

FormControl.displayName = 'FormControl'
FormControl.propTypes = propTypes
FormControl.defaultProps = defaultProps

export default FormControl
