import PropType from 'prop-types'

export default {
  __v: PropType.number,
  _id: PropType.string,
  createdAt: PropType.string, // 2023-01-20T23:26:11.694Z
  updatedAt: PropType.string, // 2023-01-20T23:26:11.694Z
  goal: PropType.string,
  name: PropType.string,
  category: PropType.string,
  trigger: PropType.string,
  enabled: PropType.bool,
  priority: PropType.number,
  intro: PropType.shape({
    delayHours: PropType.number,
    message: PropType.string
  }),
  nudge: PropType.shape({
    delayDays: PropType.number,
    maxRepeat: PropType.number,
    message: PropType.string,
    repeatBackOff: PropType.oneOf(['constant', 'exponential'])
  }),
  reward: PropType.shape({
    credits: PropType.number,
    message: PropType.string
  })
}
