import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { CONTENT_TYPE, stateKeyFromStackKey } from '../../../lib/folder'
import logger from '../../../lib/logger'
import { contentType as contentTypeShape } from '../../../prop-types/shapes/folder'
import { addAllInQuery as addAllInQueryService, addSelected as addSelectedService } from '../../../services/folder'
import AttachmentList from '../../attachments/AttachmentList'
import { notify } from '../../banners/Banner'
import BundleList from '../../bundles/BundleList'
import CampaignList from '../../campaigns/CampaignList'
import ConfigurableFormList from '../../configurable-forms/ConfigurableFormList'
import ContactList from '../../contacts/ContactList'
import ContestList from '../../contests/ContestList'
import ConfirmDialog from '../../dialog/ConfirmDialog'
import ListDialog from '../../dialog/ListDialog'
import EmailList from '../../emails/EmailList'
import KeywordList from '../../keywords/KeywordList'
import LandingPageList from '../../landing-pages/LandingPageList'
import MessageTemplateList from '../../message-templates/MessageTemplateList'
import QrCodeList from '../../qr-codes/QrCodeList'
import SpaceList from '../../spaces/SpaceList'
import TemplateList from '../../templates/TemplateList'
import TrackableLinkList from '../../trackable-links/TrackableLinkList'
import VoiceDropList from '../../voice-drops/VoiceDropList'

const listMap = {
  [CONTENT_TYPE.attachment]: AttachmentList,
  [CONTENT_TYPE.bundle]: BundleList,
  [CONTENT_TYPE.campaign]: CampaignList,
  [CONTENT_TYPE.configurableForm]: ConfigurableFormList,
  [CONTENT_TYPE.contact]: ContactList,
  [CONTENT_TYPE.contest]: ContestList,
  [CONTENT_TYPE.email]: EmailList,
  [CONTENT_TYPE.keyword]: KeywordList,
  [CONTENT_TYPE.landingPage]: LandingPageList,
  [CONTENT_TYPE.messageTemplate]: MessageTemplateList,
  [CONTENT_TYPE.qrCode]: QrCodeList,
  [CONTENT_TYPE.space]: SpaceList,
  [CONTENT_TYPE.template]: TemplateList, // TODO: remove
  [CONTENT_TYPE.trackableLink]: TrackableLinkList,
  [CONTENT_TYPE.voiceDrop]: VoiceDropList
}

const propTypes = {
  contentType: PropType.oneOf(contentTypeShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['AddToFolderAction'] })

const AddToFolderAction = forwardRef(({ contentType }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmRef = useRef()
  const listDialogRef = useRef()
  const contentIdsRef = useRef()
  const { folderId } = useParams()
  const selectedItemsRef = useRef([])
  const stackKey = useCurrentStackKey()

  const handleReplyOk = useCallback((response) => {
    if (response.json.showConfirmation) {
      return confirmRef.current.open()
    }
    const updatedFolder = response.json
    notify('Success!', 'Your item(s) have been moved to this folder.')
    const stateKey = stateKeyFromStackKey(stackKey)
    bus.emit('foldersUpdated', updatedFolder)
    bus.emit(`${stateKey}AddedToFolder`, selectedItemsRef.current)
  }, [stackKey])
  const { call: addSelected } = useService(addSelectedService, { onReplyOk: handleReplyOk })
  const { call: addAllInQuery } = useService(addAllInQueryService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      listDialogRef.current.open()
    }
  }), [])

  const handleSubmitAllInQuery = useCallback(({ term }) => {
    addAllInQuery({ folderId, contentType, term })
  }, [addAllInQuery, contentType, folderId])

  const handleSubmitSelected = useCallback((selectedItems) => {
    contentIdsRef.current = selectedItems.map((item) => item._id)
    selectedItemsRef.current = selectedItems
    addSelected({ folderId, contentType, contentIds: contentIdsRef.current })
  }, [addSelected, contentType, folderId])

  const handleConfirm = useCallback(() => {
    addSelected({ folderId, contentType, contentIds: contentIdsRef.current, movingItemsConfirmed: true })
  }, [contentType, addSelected, folderId])

  return (
    <>
      <ListDialog
        ref={listDialogRef}
        list={listMap[contentType]}
        queryOverrides={{ folderId: { $ne: folderId } }}
        title='Move to Folder'
        type='move'
        multiple
        onSubmitAllInQuery={handleSubmitAllInQuery}
        onSubmitSelected={handleSubmitSelected}
      />
      <ConfirmDialog
        ref={confirmRef}
        description='Selected items already exist in other folders. Would you like to move them to a new folder?'
        title='Move Items?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

AddToFolderAction.displayName = 'AddToFolderAction'
AddToFolderAction.propTypes = propTypes
AddToFolderAction.defaultProps = defaultProps

export default AddToFolderAction
