import { Navigate } from 'react-router-dom'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['TemplatesRedirect'] })

const TemplatesRedirect = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  return (<Navigate to='/message-templates' replace />)
}

TemplatesRedirect.displayName = 'TemplatesRedirect'
TemplatesRedirect.propTypes = propTypes
TemplatesRedirect.defaultProps = defaultProps

export default TemplatesRedirect
