import PropType from 'prop-types'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { firstMediaUrl } from '../../../helpers/messageMedia'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import LightBox from '../../LightBox'

const propTypes = {
  message: PropType.shape(messageShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['ViewImageAction'] })

const ViewImageAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [message?._id] })
  const lightBoxRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { lightBoxRef.current.open() }
  }), [])

  return (!!message &&
    <LightBox
      ref={lightBoxRef}
      slides={[{ src: firstMediaUrl(message) }]}
    />
  )
})

ViewImageAction.displayName = 'ViewImageAction'
ViewImageAction.propTypes = propTypes
ViewImageAction.defaultProps = defaultProps

export default ViewImageAction
