import * as React from "react";
import { memo } from "react";
const SvgAddFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 4.818A2.824 2.824 0 0 1 3.818 2h5.455a1 1 0 0 1 .707.293l2.434 2.434h7.768A2.824 2.824 0 0 1 23 7.545v10.91a2.824 2.824 0 0 1-2.818 2.818H3.818A2.824 2.824 0 0 1 1 18.454V4.819ZM3.818 4A.824.824 0 0 0 3 4.818v13.636c0 .448.37.819.818.819h16.364c.448 0 .818-.37.818-.819V7.545a.824.824 0 0 0-.818-.818H12a1 1 0 0 1-.707-.293L8.859 4h-5.04ZM12 8.364a1 1 0 0 1 1 1V12h2.636a1 1 0 0 1 0 2H13v2.636a1 1 0 0 1-2 0V14H8.364a1 1 0 1 1 0-2H11V9.364a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAddFile);
export default Memo;
