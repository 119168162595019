import mfaMethodFromJSON from '../models/mfaMethod'

import pb from './pb'

export function settings () {
  return pb.setupGet('users/auth/mfa')
}

export function updateDisabled (userId, body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupPut('users/auth/mfa', options)
}

export function search (ignoredSearchParam, background = false) {
  const options = {
    background,
    model: {
      conversion: mfaMethodFromJSON,
      multiple: true
    }
  }
  return pb.setupGet('users/auth/mfa/methods', options)
}

export function add ({ channel, to }, background = false) {
  const options = {
    background,
    body: {
      method: {
        channel,
        to
      }
    }
  }
  return pb.setupPost('users/auth/mfa/methods', options)
}

export function verifyAdd ({ channel, to, verificationCode }, background = false) {
  const options = {
    body: {
      background,
      method: {
        channel,
        to
      },
      verificationCode
    }
  }
  return pb.setupPost('users/auth/mfa/methods', options)
}

export function remove (methodId) {
  return pb.setupDelete(`users/auth/mfa/methods/${methodId}`)
}
