import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useEffect, useState } from 'react'

import useLogger from '../hooks/useLogger'
import useTimeZone from '../hooks/useTimeZone'
import * as formatters from '../lib/formatters'
import logger from '../lib/logger'
import { localTimeZone } from '../lib/timeZones'
import dateMonth from '../prop-types/shapes/dateMonth'

const propTypes = {
  date: PropType.oneOfType([
    PropType.string,
    PropType.instanceOf(Date),
    PropType.shape(dateMonth) // only used for `dateMonth` format
  ]).isRequired,
  format: PropType.oneOf([
    'dateMonth', // 'January 1st' (this is expecting the input to be dateMonth shape)
    'extendedDateAndTime', // 'Sunday, January 1, 2023, 3:23 AM'
    'extendedDate', // 'Sunday, January 1, 2023'
    'shortDateAndTime', // 1/1/2023 3:23 AM
    'timeOfDay', // 3:23 AM'
    'extendedDate2', // 'Sat, Feb 29, 2020'
    'timeDistance' // 2 minutes ago (this is live)
  ]).isRequired,
  useLocalTimeZone: PropType.bool
}

const defaultProps = {
  useLocalTimeZone: false
}

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

const log = logger({ enabled: true, tags: ['FormattedDate'] })

const FormattedDate = ({ date, format, useLocalTimeZone }) => {
  useLogger({ log, lifecycle: false, tags: [format, date] })

  const [timeNow, setTimeNow] = useState(Date.now()) // used for 'timeDistance' formate

  const { userTimeZone } = useTimeZone()
  const formatFunction = formatters[`format${upperFirst(format)}`]
  const timeZone = useLocalTimeZone ? localTimeZone : userTimeZone

  // Setup live updates for 'timeDistance' format
  useEffect(() => {
    if (format !== 'timeDistance') { return }

    const makeTimeout = () => {
      const dateObj = new Date(date)
      const distanceInSeconds = Math.round(Math.abs(timeNow - dateObj) / 1000)
      const interval = distanceInSeconds < MINUTE
        ? 1000
        : distanceInSeconds < HOUR
          ? 1000 * MINUTE
          : distanceInSeconds < DAY
            ? 1000 * HOUR
            : false

      // TODO: might be reasonable to also listen to window focus/blur to start/stop/force update current relative timers
      //       or appIsActive flag
      if (interval) {
        return setTimeout(() => {
          setTimeNow(Date.now())
        }, interval)
      }
    }

    const timeout = makeTimeout()
    return () => {
      if (timeout) { clearTimeout(timeout) }
    }
  }, [date, format, timeNow])

  return (
    <>
      {formatFunction(date, timeZone)}
    </>
  )
}

FormattedDate.displayName = 'FormattedDate'
FormattedDate.propTypes = propTypes
FormattedDate.defaultProps = defaultProps

export default FormattedDate
