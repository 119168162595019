import * as React from "react";
import { memo } from "react";
const SvgBirthday = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C6.968 0 3 4.33 3 9.53c0 3.384 1.632 5.436 3.496 6.892.811.634 1.684 1.17 2.461 1.646l.269.165c.71.437 1.307.82 1.774 1.21V23a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1v-3.562c.478-.416 1.066-.833 1.745-1.308l.095-.066c.78-.545 1.654-1.156 2.46-1.868 1.712-1.511 3.2-3.55 3.2-6.666C21 4.33 17.032 0 12 0Zm-1.726 16.53c.672.413 1.37.852 1.972 1.349.572-.49 1.224-.948 1.853-1.388l.064-.045c.803-.561 1.594-1.114 2.314-1.75C17.923 13.42 19 11.885 19 9.53 19 5.31 15.808 2 12 2S5 5.31 5 9.53c0 2.586 1.183 4.11 2.727 5.316.716.56 1.49 1.035 2.28 1.519l.267.165ZM12 4C9.238 4 7 6.238 7 9a1 1 0 0 0 2 0c0-1.658 1.342-3 3-3a1 1 0 1 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBirthday);
export default Memo;
