import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import Button from '../../components/buttons/Button'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import ConfirmRenewSubscriptionDialog from './ConfirmRenewSubscriptionDialog'

const propTypes = {
  currentPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  })
}

const defaultProps = {
  currentPlan: null
}

const log = logger({ enabled: false, tags: ['RenewSubscriptionImmediately'] })

const RenewSubscriptionImmediately = ({ currentPlan }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmDialogRef = useRef()

  const handleButtonClick = useCallback(() => {
    confirmDialogRef.current.open()
  }, [])

  if (!currentPlan) {
    return null
  }

  return (
    <>
      <p className='mb-5'>
        <strong className='block'>Want to renew now?</strong>
        If you would like to renew your subscription to
        {' '}
        {currentPlan.name}
        {' '}
        {currentPlan.frequency}
        {' '}
        immediately, you can now skip the wait. You will be charged in full, and receive your credits now.
      </p>
      <div className='flex flex-row flex-wrap gap-4 items-center mb-5'>
        <Button
          className='grow md:flex-none'
          form='RenewSubscriptionImmediately'
          size='sm'
          onClick={handleButtonClick}
        >
          Renew my subscription now
        </Button>
      </div>
      <ConfirmRenewSubscriptionDialog ref={confirmDialogRef} currentPlan={currentPlan} />
    </>
  )
}

RenewSubscriptionImmediately.displayName = 'RenewSubscriptionImmediately'
RenewSubscriptionImmediately.propTypes = propTypes
RenewSubscriptionImmediately.defaultProps = defaultProps

export default RenewSubscriptionImmediately
