import * as React from "react";
import { memo } from "react";
const SvgRead = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 4.7C4 3.758 4.758 3 5.7 3H20v1.6H7.5a1 1 0 1 0 0 2H20v14.2H5.7c-.942 0-1.7-.758-1.7-1.7V4.7Zm18 .9v16.2a1 1 0 0 1-1 1H5.7A3.696 3.696 0 0 1 2 19.1V4.7C2 2.654 3.654 1 5.7 1H21a1 1 0 0 1 1 1v3.6Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgRead);
export default Memo;
