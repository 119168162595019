import { has } from 'lodash'

import userFromJSON from '../models/user'

import pb from './pb'

export function forgotPassword (username) {
  const body = { username }
  return pb.setupPost('forgot-password', { body })
}

export function verifyPasswordResetToken (token) {
  const body = { token }
  return pb.setupPost('password-reset/verify', { body })
}

export function resetPasswordWithToken (token, password) {
  const body = { token, password }
  return pb.setupPut('password-reset', { body })
}

export function generateApiKey () {
  const options = {
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/apiKey', options)
}

// isManager can be found in ./user-managedAccount.js

export function isManagerFor (userId, background = false) {
  const options = { background }
  return pb.setupGet(`users/isManagerfor/${userId}`, options)
}

export function isCurrentlyManaging (background = true) {
  return pb.setupGet('users/isCurrentlyManaging', { background })
}

export function loadForReactivation () {
  const options = {
    background: true,
    model: { conversion: userFromJSON }
  }
  return pb.setupGet('users/load', options)
}

export function current () {
  const options = {
    background: true,
    model: { conversion: userFromJSON }
  }
  return pb.setupGet('users/current', options)
}

export function login (username, password) {
  const body = { username, password }
  const options = {
    appSessionCapture: true,
    body,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/session', options)
}

export function logout () {
  return pb.setupDelete('users/session', {
    appSessionReset: (json) => {
      // Reset session unless the current user is returned upon "manage user end" via logout
      return !has(json, '_id')
    },
    model: {
      conversion: (json) => {
        // This returns the current user when "manage user end" occurs via logout
        return json?._id ? userFromJSON(json) : undefined
      }
    }
  })
}

export function logoutAll () {
  return pb.setupPost('users/force-login')
}

export function mfaDeferLogin (username, nonce) {
  const body = { username, nonce }
  const options = {
    appSessionCapture: true,
    body,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/session/mfa/defer', options)
}

export function mfaInitiate (username, nonce, method) {
  const body = { username, nonce, method }
  return pb.setupPost('users/session/mfa/step1', { body })
}

export function mfaLogin (username, nonce, method, verificationCode) {
  const body = { username, nonce, method, verificationCode }
  const options = {
    appSessionCapture: true,
    body,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/session/mfa/step2', options)
}

export function poller (data) {
  const options = {
    body: data,
    background: true
  }
  return pb.setupPost('users/poller', options)
}

export function resumeSession () {
  const options = {
    appSessionCapture: true,
    background: true,
    model: { conversion: userFromJSON }
  }
  return pb.setupGet('users/session', options)
}

export function stats (background = false) {
  return pb.setupGet('stats', { background })
}

export function updateProfile (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/profile', options)
}

export function updateCustomFields (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/customFields', options)
}
export function updateUserCustomFields (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/updateUserCustomFieldsAnswers', options)
}

export function vcardData () {
  return pb.setupGet('users/vcard', {})
}

export function updateVcardData (body) {
  return pb.setupPut('users/vcard', { body })
}

export function allowMessagesFromShortCodes (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/allowMessagesFromShortCodes', options)
}

export function updateAivaSettings (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/aivaSettings', options)
}

export function updateCallForwarding (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/callForwarding', options)
}

export function updateIncomingMessageEmailNotifications (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/incomingMessageEmailNotifications', options)
}

export function updateDynamicFieldsSettings (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('users/relaxedDynamicFieldValidation', options)
}

export function changePassword (data) {
  const options = {
    body: data
  }
  return pb.setupPut('users/password', options)
}

export function addOns () {
  return pb.setupGet('users/addOns')
}

export function plans (data) {
  const options = {
    body: data
  }
  return pb.setupPost('users/plans', options)
}

export function submitTcrRegistration (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/submitTcrRegistration', options)
}

export function upgradeToSoleProp2 (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/submitTcrRegistration', options)
}

export function sendTcrOtp (data) {
  const options = {
    body: data,
    model: { conversion: userFromJSON }
  }
  return pb.setupPost('users/sendTcrOtp', options)
}

export function updateCanSpamCompliance (data, background = false) {
  const options = {
    body: data,
    model: { conversion: userFromJSON },
    background
  }
  return pb.setupPut('users/canSpamCompliance', options)
}
