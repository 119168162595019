import * as React from "react";
import { memo } from "react";
const SvgLightBlub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.836 20.036C6.532 18.393 5.82 16.8 4.72 15.28a9.014 9.014 0 0 1-1.61-6.72c.62-3.91 3.93-7.07 7.87-7.51 2.56-.28 5.12.54 7.02 2.24 1.9 1.71 3 4.15 3 6.7 0 1.94-.62 3.79-1.78 5.36-1.076 1.441-1.792 3.078-2.08 4.736a.995.995 0 0 1-.01.084 9.78 9.78 0 0 0-.14 1.63v.2c0 1.65-1.35 3-3 3H10c-1.65 0-3-1.35-3-3v-.2c0-.52-.05-1.05-.15-1.63a1.002 1.002 0 0 1-.014-.134Zm8.487-1.026a13.395 13.395 0 0 1 2.287-4.86 6.863 6.863 0 0 0 1.38-4.16c0-1.99-.85-3.89-2.33-5.21a6.915 6.915 0 0 0-5.47-1.74c-3.06.34-5.63 2.79-6.11 5.83-.29 1.86.15 3.72 1.24 5.23 1.13 1.56 1.91 3.21 2.327 4.91h6.676ZM9 21.8c0-.26-.01-.53-.03-.8h6.06c-.03.27-.03.53-.03.8v.2c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1v-.2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgLightBlub);
export default Memo;
