import PropType from 'prop-types'

import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import SummaryTextFragment from '../../../components/plans/SummaryTextFragment'
import useLogger from '../../../hooks/useLogger'
import { formatPhone } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { HorizontalColor } from '../../../svg/branding'
import { DownloadAppStore, DownloadGooglePlay } from '../../../svg/vendor/badges'

const propTypes = {
  isReactivation: PropType.bool,
  phoneNumber: PropType.string,
  selectedPlan: PropType.shape({
    frequency: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired
  })
}

const defaultProps = {
  isReactivation: false,
  phoneNumber: null,
  selectedPlan: null
}

const log = logger({ enabled: false, tags: ['Welcome'] })

const Welcome = ({ isReactivation, phoneNumber, selectedPlan }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!selectedPlan) {
    return
  }

  return (
    <div className='text-center'>
      <div className='sm:w-96 m-auto'>
        <HorizontalColor />
      </div>
      <h1 className='m-4 headline-md text-center'>
        {isReactivation ? 'Welcome Back!' : 'Welcome!'}
      </h1>
      <p className='mb-4'>
        Get your first broadcast sending in no time at all!
      </p>
      <div className='inline-block mb-4'>
        <Button
          href='https://training.projectbroadcast.com/courses/project-broadcast-quick-start'
          size='md'
        >
          Start Learning
        </Button>
      </div>
      <p className='mb-4'>
        {`You are ${isReactivation ? 'once again' : 'now'} a member of Project Broadcast! `}
        <SummaryTextFragment plan={selectedPlan} />
      </p>
      <p className='my-4'>
        Your Project Broadcast phone number is
        {' '}
        <strong>{formatPhone(phoneNumber)}</strong>
        {'. '}
        The number will be visible in your Project Broadcast app and you should also receive a welcome email with the number as well. You should now search the Apple App Store or Google Play Store for "Project Broadcast" (or click the buttons below) and start broadcasting!
      </p>
      <div className='inline-block mb-4'>
        <Button href='/' size='md'>Get Started on the Web</Button>
      </div>
      <div className='sm:w-1/2 mx-auto'>
        <div className='flex items-center gap-5 mt-5'>
          <ExternalLink
            className='w-[200px]'
            href='https://apps.apple.com/us/app/project-broadcast/id1289777524'
            label={<DownloadAppStore />}
          />
          <ExternalLink
            className='w-[226px]'
            href='https://play.google.com/store/apps/details?id=com.starterstep.broadcast'
            label={<DownloadGooglePlay />}
          />
        </div>
      </div>
      {process.env.REACT_APP_ENABLE_CONVERSION_PIXEL === 'true' && (
        <img
          height='1'
          src='https://ct.capterra.com/capterra_tracker.gif?vid=2304167&vkey=74ee1d35f6748bc4298a52c74fa897bf'
          width='1'
        />
      )}
    </div>
  )
}

Welcome.displayName = 'Welcome'
Welcome.propTypes = propTypes
Welcome.defaultProps = defaultProps

export default Welcome
