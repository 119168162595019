import * as React from "react";
import { memo } from "react";
const SvgTraining = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 15.5v-7a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0ZM3.5 8c.185 0 .365.02.539.058A2.5 2.5 0 0 1 9 8.5V11h6V8.5a2.5 2.5 0 0 1 4.961-.442A2.5 2.5 0 0 1 23 10.5v3a2.5 2.5 0 0 1-3.039 2.442A2.5 2.5 0 0 1 15 15.5V13H9v2.5a2.5 2.5 0 0 1-4.961.442A2.5 2.5 0 0 1 1 13.5v-3A2.5 2.5 0 0 1 3.5 8Zm.5 5.5v-3a.5.5 0 0 0-1 0v3a.5.5 0 0 0 1 0Zm13-5v7a.5.5 0 0 0 1 0v-7a.5.5 0 0 0-1 0Zm3.5 5.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTraining);
export default Memo;
