import { capitalize, get } from 'lodash'

import useStore from '..'

export function setCurrentInstanceFromReply (model, replyAttrPath, serviceReply) {
  useStore.setState((state) => {
    const currentInstance = serviceReply?.ok && get(serviceReply, replyAttrPath)
    state.current[model] = currentInstance || null
  }, false, `current${capitalize(model)}/setCurrent${capitalize(model)}FromReply`)
}

export function setCurrentInstance (model, instance) {
  useStore.setState((state) => {
    state.current[model] = instance
  }, false, `current${capitalize(model)}/setCurrent${capitalize(model)}`)
}
