import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import { formatName } from '../../lib/formatters'
import logger from '../../lib/logger'
import tagSearchResultShape from '../../prop-types/shapes/tagSearchResult'
import { PeopleGroup } from '../../svg/icons'
import { Tags } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(tagSearchResultShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['TagListItemContent'] })

const TagListItemContent = ({ item }) => {
  useLogger({ log, lifecycle: true, tags: [item?.name] })

  const names = item?.contacts.subset?.map((contact) => formatName(contact)).join(', ')

  return (
    <>
      <Tags className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {item.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <PeopleGroup className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={item?.contacts?.length} />
          <div className='line-clamp-1 break-words'>{names}</div>
        </div>
      </div>
    </>
  )
}

TagListItemContent.displayName = 'TagListItem'
TagListItemContent.propTypes = propTypes
TagListItemContent.defaultProps = defaultProps

export default TagListItemContent
