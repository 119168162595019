import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback } from 'react'

import { error, notify } from '../../../components/banners/Banner'
import Form from '../../../components/forms/Form'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { update as updatePaymentDetails } from '../../../services/payment'

const log = logger({ enabled: false, tags: ['UpdatePaymentForm'] })
const UpdatePaymentForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handlePaymentUpdateOk = () => {
    const cardElement = elements.getElement('card')
    cardElement.clear()
    notify('Payment updated.', 'It may take a bit before the update is shown here.')
  }

  const { call: updatePayment } = useService(
    updatePaymentDetails,
    {
      onReplyOk: handlePaymentUpdateOk
    }
  )
  const handleSubmit = useCallback(async () => {
    const card = elements.getElement('card')
    const { error: stripeError, token } = await stripe.createToken(card)

    if (stripeError) {
      error(
        stripeError?.message
      )
      return
    }

    const data = {
      stripeResult: token
    }
    updatePayment(data)
  }, [updatePayment, elements, stripe])

  useLogger({ log, lifecycle: false, tags: ['UpdatePaymentForm'] })

  return (
    <Form
      defaultValues={{}}
      formControls={[
        {
          name: 'card',
          type: 'stripecardelement'
        }
      ]}
      id='updatePaymentForm'
      onSubmit={handleSubmit}
    />
  )
}

export default UpdatePaymentForm
