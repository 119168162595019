import { create, enforce, only, skipWhen, test } from 'vest'

import countryData from '../../../data-sources/countries.json'
const validCountries = countryData.map((country) => country.value)

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  const isSoleProp = data.entityType === 'SOLE_PROPRIETOR'

  test('city', 'City is required', () => {
    enforce(data.city).isNotEmpty()
  })

  test('country', 'Country is invalid', () => {
    enforce(data.country).inside(validCountries)
  })

  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty()
  })

  test('postalCode', 'Postal Code is required', () => {
    enforce(data.postalCode).isNotEmpty()
  })

  test('state', 'Field is required', () => {
    enforce(data.state).isNotEmpty()
  })

  test('street', 'Street is required', () => {
    enforce(data.street).isNotEmpty()
  })

  skipWhen(isSoleProp, () => {
    test('companyName', 'Company Name is required', () => {
      enforce(data.companyName).isNotEmpty()
    })

    test('ein', 'Field is required', () => {
      enforce(data.companyName).isNotEmpty()
    })

    test('isLargeBusiness', 'Field is required', () => {
      enforce(data.isLargeBusiness).equals('no')
    })

    test('phone', 'Phone is required', () => {
      enforce(data.phone).isNotEmpty()
    })
  })

  skipWhen(!isSoleProp, () => {
    test('firstName', 'First Name is required', () => {
      enforce(data.firstName).isNotEmpty()
    })

    test('lastName', 'Last Name is required', () => {
      enforce(data.lastName).isNotEmpty()
    })
  })
})

export default validationSuite
