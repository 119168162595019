import * as React from "react";
import { memo } from "react";
const SvgProfile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C5.368 1 0 6.368 0 13c0 2.303.656 4.444 1.779 6.265a1 1 0 1 0 1.702-1.05A9.887 9.887 0 0 1 2 13C2 7.472 6.472 3 12 3s10 4.472 10 10a9.913 9.913 0 0 1-1.481 5.215 1 1 0 1 0 1.702 1.05A11.913 11.913 0 0 0 24 13c0-6.632-5.368-12-12-12Zm0 6.99a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm.494 7.867c1.292-.552 2.85-.867 4.506-.867 1.657 0 3.215.315 4.507.867 1.277.545 2.373 1.358 2.987 2.409a1 1 0 0 1-1.727 1.008c-.326-.559-1.01-1.136-2.046-1.578-1.02-.436-2.308-.706-3.72-.706-1.414 0-2.701.27-3.722.706-1.035.442-1.719 1.02-2.045 1.578a1 1 0 1 1-1.727-1.008c.614-1.051 1.71-1.864 2.987-2.41Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgProfile);
export default Memo;
