import { isNumber } from 'lodash'
import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import { formatNumber } from '../../lib/formatters'
import logger from '../../lib/logger'

const propTypes = {
  value: PropType.number
}

const defaultProps = {
  value: null
}

const log = logger({ enabled: false, tags: ['TitleCountPill'] })

const TitleCountPill = ({ value }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!isNumber(value)) { return null }

  return (
    <div className='ml-2 label-2xs-strong text-black rounded-full bg-neutral-200 py-[2px] px-[8px]'>
      {formatNumber(value)}
    </div>
  )
}

TitleCountPill.displayName = 'TitleCountPill'
TitleCountPill.propTypes = propTypes
TitleCountPill.defaultProps = defaultProps

export default TitleCountPill
