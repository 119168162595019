import classNames from 'classnames'
import { useCallback } from 'react'

import { useSideMenu } from '../../contexts/SideMenuContext'
import useCurrentUser from '../../hooks/useCurrentUser'
import { copyToClipboard } from '../../lib/copyToClipboard'
import { ChevronDown, ChevronUp, Clipboard } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import { notify } from '../banners/Banner'

import BroadcastingPaused from './side-menu-footer/BroadcastingPaused'
import ManagingAccount from './side-menu-footer/ManagingAccount'
import MissingTcrRegistration from './side-menu-footer/MissingTcrRegistration'
import Raised from './side-menu-footer/Raised'

const propTypes = {}

const defaultProps = {}

const SideMenuFooter = () => {
  const { expanded, userMenuVisible, setUserMenuVisible } = useSideMenu()
  const currentUser = useCurrentUser()

  const handleOpenEvent = useCallback((event) => {
    if (event.type === 'click' || (event.type === 'keyup' && event.key === 'Enter')) {
      event.stopPropagation()
      setUserMenuVisible(!userMenuVisible)
    }
  }, [userMenuVisible, setUserMenuVisible])

  const handleCopyToClipboard = useCallback((event) => {
    event.stopPropagation()
    copyToClipboard(currentUser.formattedPhoneNumber)
    notify('Phone Number copied to clipboard')
  }, [currentUser])

  const footerClassNames = classNames(
    'self-end mt-auto grow-0 w-full max-h-full overflow-hidden',
    {
      'h-full mt-[-1px]': (expanded && userMenuVisible)
    }
  )
  const userMenuClassNames = classNames(
    'flex flex-col shrink-0 grow-0 w-full max-h-full border-neutral-200',
    {
      'border-t': !userMenuVisible
    }
  )

  if (!currentUser) { return null }

  const avatar = (<div onClick={handleOpenEvent}><Avatar contactOrUser={currentUser} size='xs' /></div>)

  return (
    <footer className={footerClassNames}>
      {!userMenuVisible
        ? (
          <div className='grid grid-y divide-y divide-neutral-200'>
            <MissingTcrRegistration />
            <BroadcastingPaused />
            <ManagingAccount />
          </div>
          )
        : null}
      <div className={userMenuClassNames}>
        <div className='flex flex-row w-[278px] p-[12px]'>
          {avatar}
          <div className='flex flex-col ml-[12px] w-full overflow-hidden'>
            <div className='label-sm-strong text-black mr-[6px] line-clamp-1'>{currentUser.formattedName}</div>
            <div className='flex flex-row items-center cursor-pointer' onClick={handleCopyToClipboard}>
              <span className='label-xs-medium'>{currentUser.formattedPhoneNumber}</span>
              <Clipboard className='ml-1' height='12' width='12' />
            </div>
          </div>
          <div
            className='flex shrink-0 grow-0 justify-center items-center w-[40px] h-[40px] ml-auto self-center border border-neutral-200 rounded-2xl cursor-pointer hover:bg-blue-50'
            tabIndex='0'
            onClick={handleOpenEvent}
            onKeyUp={handleOpenEvent}
          >
            {userMenuVisible && expanded ? <ChevronDown height='24' width='24' /> : <ChevronUp height='24' width='24' />}
          </div>
        </div>
        {userMenuVisible && expanded ? <Raised currentUser={currentUser} /> : null}
      </div>
    </footer>
  )
}

SideMenuFooter.displayName = 'SideMenuFooter'
SideMenuFooter.propTypes = propTypes
SideMenuFooter.defaultProps = defaultProps

export default SideMenuFooter
