import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApp } from '../../../../contexts/AppContext'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { More, NewWindow } from '../../../../svg/icons'
import ActionMenu from '../../../action-menu/ActionMenu'
import ActionMenuDivider from '../../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../action-menu/ActionMenuItem'
import Button from '../../../buttons/Button'
import ConfirmDialog from '../../../dialog/ConfirmDialog'

const propTypes = {
  composerThemeInput: PropType.bool
}

const defaultProps = {
  composerThemeInput: false
}

const log = logger({ enabled: true, tags: ['ComposerSettingsButton'] })

const ComposerSettingsButton = ({ composerThemeInput }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { isNative } = useApp()
  const navigate = useNavigate()
  const confirmRef = useRef()

  const triggerButton = useMemo(() => (
    <Button
      className='min-w-[32px]'
      icon={<More />}
      size='sm'
      theme='none'
      variant='none'
    >
      Composer Settings
    </Button>
  ), [])

  const handleConfirmOpenAivaSettings = useCallback(() => {
    confirmRef.current.open()
  }, [])

  const handleOpenAivaSettings = useCallback(() => {
    navigate('/aiva/settings')
  }, [navigate])

  return (
    <>
      <ActionMenu placement='bottom-end' trigger={triggerButton}>
        <ActionMenuHeader label='Editor Settings' />
        <ActionMenuDivider />
        {isNative && composerThemeInput
          ? (
            <ActionMenuItem
              end={<NewWindow />}
              label='AIVA Settings'
              onClick={handleConfirmOpenAivaSettings}
            />
            )
          : (
            <ActionMenuItem
              end={<NewWindow />}
              href='/aiva/settings'
              label='AIVA Settings'
              target={composerThemeInput ? '_blank' : null}
            />
            )}
      </ActionMenu>
      <ConfirmDialog
        ref={confirmRef}
        description='Opening AIVA Settings will discard any unsaved changes. Are you sure you want to proceed?'
        title='Abandon Changes?'
        onConfirm={handleOpenAivaSettings}
      />
    </>
  )
}

ComposerSettingsButton.displayName = 'ComposerSettingsButton'
ComposerSettingsButton.propTypes = propTypes
ComposerSettingsButton.defaultProps = defaultProps

export default ComposerSettingsButton
