import * as React from "react";
import { memo } from "react";
const SvgChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 23C5.936 23 1 18.064 1 12S5.936 1 12 1s11 4.936 11 11c0 1.974-.522 3.892-1.509 5.57l1.128 3.822c.113.395 0 .818-.282 1.114a1.1 1.1 0 0 1-1.1.297l-4.02-1.114A10.976 10.976 0 0 1 12 23Zm0-19.744c-4.823 0-8.744 3.92-8.744 8.744 0 4.823 3.92 8.744 8.744 8.744 1.58 0 3.13-.424 4.485-1.241.268-.156.578-.198.874-.113l2.51.69-.69-2.354a1.082 1.082 0 0 1 .14-.931A8.758 8.758 0 0 0 20.744 12c0-4.823-3.92-8.744-8.744-8.744Z"
    />
  </svg>
);
const Memo = memo(SvgChat);
export default Memo;
