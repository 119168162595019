import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { error } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import SubscriptionSummary from '../../components/plans/SubscriptionSummary'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { formatExtendedDate } from '../../lib/formatters'
import logger from '../../lib/logger'
import { movingToManagedVoip, movingToVerifiedCell, optionsData, planMustChangeImmediately, userCanChoosePlanChangeDate } from '../../lib/plans'
import {
  changePlanPreFlight as changePlanPreFlightService
} from '../../services/subscription'

import ChangeSubscriptionWarnings from './ChangeSubscriptionWarnings'
import ChooseNumber from './ChooseNumber'
import ConfirmChangeSubscriptionDialog from './ConfirmChangeSubscriptionDialog'
import VerifyMobileNumber from './VerifyMobileNumber'

const propTypes = {
  currentPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }).isRequired,
  plans: PropType.arrayOf(
    PropType.shape({
      credits: PropType.number.isRequired,
      frequency: PropType.string.isRequired,
      id: PropType.string.isRequired,
      name: PropType.string.isRequired,
      price: PropType.string.isRequired,
      priceInCents: PropType.number.isRequired,
      actualCredits: PropType.number,
      newUserTrialInDays: PropType.number
    })
  ).isRequired,
  subscriptionDetails: PropType.shape({
    currentPlanEndDate: PropType.string.isRequired,
    currentPlanId: PropType.string.isRequired
  })
}

const defaultProps = {
  subscriptionDetails: null
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
const cssSrc = process.env.REACT_APP_CSS_FONT_URL
const elementsOptions = { fonts: [{ cssSrc }] }

const log = logger({ enabled: false, tags: ['ChangeSubscriptionForm'] })

const ChangeSubscriptionForm = ({ subscriptionDetails, currentPlan, plans }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [cellPhoneNumber, setCellPhoneNumber] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [numbers, setNumbers] = useState()
  const currentUser = useCurrentUser()

  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [reanchorImmediately, setReanchorImmediately] = useState(false)

  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId)
  const chooseNumberDialogRef = useRef()
  const confirmDialogRef = useRef()
  const verifyMobileDialogRef = useRef()
  const formRef = useRef()

  const immediateUpgradeRequired = planMustChangeImmediately(currentPlan, selectedPlan)
  const upgradeAtEndOfCycleRequired = !immediateUpgradeRequired && !userCanChoosePlanChangeDate(currentPlan, selectedPlan)

  const handlePreFlightOk = useCallback((data) => {
    log.debug('handlePreFlightOk', data)
    if (data.json.verificationCodeSent === true) {
      log.debug('verificationCodeSent')
      verifyMobileDialogRef.current.open()
    } else if (data.json.previousPhoneNumberAvailable === true) {
      log.debug('previousPhoneNumberAvailable')
      confirmDialogRef.current.open()
    } else if (data.json.numbers) {
      const respNumbers = data.json.numbers
      const allAvailableNumbers = {}
      let numbersAvailable = false

      Object.keys(respNumbers).forEach((key) => {
        if (respNumbers[key].length > 0) {
          allAvailableNumbers[key] = respNumbers[key]
          numbersAvailable = true
        }
      })

      if (numbersAvailable) {
        log.debug('show ChooseNumber')
        setNumbers(allAvailableNumbers)
        chooseNumberDialogRef.current.open()
      } else {
        error('Oh no!', 'It seems we have no numbers available in that state/province. Please select another.')
      }
    } else {
      log.debug('confirm plan change')
      confirmDialogRef.current.open()
    }
  }, [])
  const { call: changePlanPreFlight } = useService(changePlanPreFlightService, { onReplyOk: handlePreFlightOk })

  const handleFormSubmit = useCallback(({ cellPhoneNumber, phoneNumber, stateProvince }) => {
    log.debug('handleFormSubmit', selectedPlanId, cellPhoneNumber, phoneNumber)
    setCellPhoneNumber(cellPhoneNumber)
    setPhoneNumber(phoneNumber)
    changePlanPreFlight({ planId: selectedPlanId, reanchorImmediately, cellPhoneNumber, phoneNumber, stateProvince })
  }, [changePlanPreFlight, reanchorImmediately, selectedPlanId])

  const handlePlanChange = useCallback((event) => {
    const val = event.target.value
    log.debug('handlePlanChange', val)
    setSelectedPlanId(val)
    const newPlan = plans.find((plan) => plan.id === val)
    const mustChangeNow = planMustChangeImmediately(currentPlan, newPlan)
    const reanchor = mustChangeNow // force user to activate it each plan change to prevent accidentals
    setReanchorImmediately(reanchor)
    formRef.current.setValue('reanchorImmediately', reanchor ? '1' : '0')
  }, [currentPlan, plans])

  const handleReanchorImmediately = useCallback((event) => {
    const val = event.target.value === '1'
    log.debug('handleReanchorImmediately', val)
    setReanchorImmediately(val)
  }, [])

  const formControls = [{
    type: 'select',
    name: 'planId',
    label: 'Subscription Plan',
    onChange: handlePlanChange,
    options: optionsData(plans),
    placeholder: 'Choose a subscription plan'
  },
  selectedPlan && {
    type: 'radiogroup',
    name: 'reanchorImmediately',
    label: 'Effective Date',
    onChange: handleReanchorImmediately,
    options: [{
      label: immediateUpgradeRequired
        ? <span className='cursor-not-allowed'>Schedule my plan change.</span>
        : `Schedule my plan change for ${formatExtendedDate(subscriptionDetails.currentPlanEndDate, currentUser.timezone)}. ${selectedPlan.priceInCents > 0 ? 'I will not be charged today.' : ''}`,
      disabled: immediateUpgradeRequired,
      value: '0'
    }, {
      label: upgradeAtEndOfCycleRequired
        ? <span className='cursor-not-allowed'>Change my plan immediately.</span>
        : `Change my plan immediately. ${selectedPlan.priceInCents > 0 ? `I will be charged ${selectedPlan?.price} today and ${selectedPlan?.price} at each ${selectedPlan?.frequency.toLowerCase()} renewal.` : ''}`,
      disabled: upgradeAtEndOfCycleRequired,
      value: '1'
    }]
  },
  {
    type: 'passthrough',
    name: 'warnings',
    element: <ChangeSubscriptionWarnings
      currentPlan={currentPlan}
      reanchorImmediately={reanchorImmediately}
      selectedPlan={selectedPlan}
      user={currentUser}
             />
  },
  movingToManagedVoip(currentPlan, selectedPlan)
    ? {
        type: 'passthrough',
        name: 'phone-number-text',
        element: (
          <>
            <strong>Project Broadcast Phone Number</strong>
            <p>This plan includes a Project Broadcast phone number where all your Project Broadcast messages will originate from. It is your own personal business phone number where you can send/receive text messages and utilize voice features in addition to interacting with other Project Broadcast users.</p>
          </>
        )
      }
    : null,
  movingToManagedVoip(currentPlan, selectedPlan)
    ? (currentUser?.phoneNumberPendingCancellation?.length > 0
        ? {
            type: 'hidden',
            name: 'phoneNumber',
            value: currentUser.phoneNumberPendingCancellation
          }
        : {
            type: 'stateprovince',
            name: 'stateProvince',
            label: 'State/Province',
            hint: 'The State/Province is used to locate you a Project Broadcast number. If the State/Province you provide does not return any available numbers, please use a different State/Province.',
            placeholder: 'Choose State/Province'
          })
    : null,
  movingToVerifiedCell(currentPlan, selectedPlan)
    ? {
        autoComplete: 'phone-number',
        type: 'tel',
        name: 'cellPhoneNumber',
        label: 'Mobile Phone Number',
        hint: 'A verification code will be sent via text message to this number. This code will be required to complete your subscription change in the next step.',
        placeholder: 'Enter your mobile number'
      }
    : null
  ].filter((c) => c)

  if (plans.length === 0) {
    return null
  }

  return (
    <>
      {subscriptionDetails
        ? (
          <p className='mb-5'>
            Your subscription renews
            {' '}
            <strong>{formatExtendedDate(subscriptionDetails.currentPlanEndDate, currentUser.timezone)}</strong>
            {'. '}
            If you'd like to switch plans, choose a new subscription plan below.
          </p>
          )
        : null}
      <Form
        ref={formRef}
        defaultValues={{
          planId: selectedPlanId
        }}
        formControls={formControls}
        id='changeSubscriptionForm'
        onSubmit={handleFormSubmit}
      />
      <div className='mb-4'>
        <SubscriptionSummary plan={selectedPlan} reanchorImmediately={reanchorImmediately} />
      </div>
      <div className='flex flex-row flex-wrap gap-4 items-center'>
        <Button
          className='grow md:flex-none'
          form='changeSubscriptionForm'
          size='sm'
          type='submit'
        >
          Next Step
        </Button>
        <div className='grow text-primary label-sm-medium text-right'>
          <Link to='cancel-subscription'>
            Cancel Subscription
          </Link>
        </div>
      </div>
      {movingToVerifiedCell(currentPlan, selectedPlan)
        ? <VerifyMobileNumber ref={verifyMobileDialogRef} cellPhoneNumber={cellPhoneNumber} plan={selectedPlan} />
        : null}
      {movingToManagedVoip(currentPlan, selectedPlan)
        ? (
          <Elements options={elementsOptions} stripe={stripePromise}>
            <ChooseNumber
              ref={chooseNumberDialogRef}
              numbers={numbers}
              plan={selectedPlan}
            />
          </Elements>
          )
        : null}
      <ConfirmChangeSubscriptionDialog
        ref={confirmDialogRef}
        phoneNumber={phoneNumber}
        plan={selectedPlan}
        reanchorImmediately={reanchorImmediately}
      />
    </>
  )
}

ChangeSubscriptionForm.displayName = 'ChangeSubscriptionForm'
ChangeSubscriptionForm.propTypes = propTypes
ChangeSubscriptionForm.defaultProps = defaultProps

export default ChangeSubscriptionForm
