import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import DangerousConfirmDialog from '../../components/dialog/DangerousConfirmDialog'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useStackBackButton from '../../hooks/useStackBackButton'
import { formatExtendedDate } from '../../lib/formatters'
import logger from '../../lib/logger'
import { cancelScheduledChanges as cancelScheduledChangesService } from '../../services/subscription'

const propTypes = {
  subscriptionDetails: PropType.shape({
    currentPlanEndDate: PropType.string.isRequired,
    currentPlanFrequency: PropType.string.isRequired,
    currentPlanName: PropType.string.isRequired
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CancelChangeSubscriptionDialog'] })

const CancelChangeSubscriptionDialog = forwardRef(({ subscriptionDetails }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()
  const { handleBackButtonClick } = useStackBackButton()

  const onReplyOk = useCallback(() => {
    notify('Your request has been submitted.')
    handleBackButtonClick()
  }, [handleBackButtonClick])
  const { call: cancelScheduledChanges } = useService(cancelScheduledChangesService, { onReplyOk })

  const onConfirm = useCallback(() => {
    cancelScheduledChanges()
  }, [cancelScheduledChanges])

  return (
    <DangerousConfirmDialog
      ref={ref}
      confirmButtonText='Stay on my current plan!'
      confirmValue='STAY'
      title='Are you sure?'
      onConfirm={onConfirm}
    >
      By continuing, you understand you will remain on the
      {' '}
      <strong className='whitespace-nowrap'>
        {subscriptionDetails.currentPlanName}
        {' '}
        {subscriptionDetails.currentPlanFrequency}
      </strong>
      {' '}
      plan. You can schedule changes to your plan at any time before your next renewal on
      {' '}
      {formatExtendedDate(subscriptionDetails.currentPlanEndDate, currentUser.timezone)}
      .
    </DangerousConfirmDialog>
  )
})

CancelChangeSubscriptionDialog.displayName = 'CancelChangeSubscriptionDialog'
CancelChangeSubscriptionDialog.propTypes = propTypes
CancelChangeSubscriptionDialog.defaultProps = defaultProps

export default CancelChangeSubscriptionDialog
