import { useCallback, useEffect, useRef } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import PanelDialog from '../../../components/dialog/PanelDialog'
import { needsSolePropUpgradeInfo, needsTcrInfo } from '../../../helpers/user-tcr'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import logger from '../../../lib/logger'
import { whitelistedRegistrationData } from '../../../lib/tcr'
import { registrationUseCaseSamples as registrationUseCaseSamplesService } from '../../../services/tcr'
import { submitTcrRegistration } from '../../../services/user'
import { update as updateAction } from '../../../store/actions/currentUser'

import BusinessDetails from './BusinessDetails'
import BusinessType from './BusinessType'
import UseCaseDetails from './UseCaseDetails'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['RegistrationDialog'] })

const RegistrationDialog = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const isSolePropUpgrade = needsSolePropUpgradeInfo(currentUser)
  const registrationDefaults = {
    country: 'US',
    entityType: 'SOLE_PROPRIETOR',
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.auth.username
  }
  const registrationDataRef = useRef(registrationDefaults)
  const panelDialogRef = useRef()
  const confirmDialogRef = useRef()
  const dataSamplesRef = useRef({})
  const detailsFormRef = useRef()

  const handleUseCaseSamplesReplyOk = useCallback((data) => {
    dataSamplesRef.current = data.json
  }, [])

  const handleTcrRegistrationSuccess = useCallback((reply) => {
    log.debug('handleTcrRegistrationSuccess', reply)
    navigate('/account/tcr')
  }, [navigate])

  const handleRegistrationReply = useCallback(() => {
    detailsFormRef.current.updateSubmitting(false)
  }, [])

  const { call: registrationUseCaseSamples } = useService(registrationUseCaseSamplesService, { onReplyOk: handleUseCaseSamplesReplyOk })
  const { call: submitRegistration } = useServiceAndAction(submitTcrRegistration, updateAction, { onReplyOk: handleTcrRegistrationSuccess, onReply: handleRegistrationReply })

  useEffect(() => {
    registrationUseCaseSamples()
  }, [registrationUseCaseSamples])

  function mergeAndStoreData (data) {
    const registrationData = {
      ...registrationDataRef.current,
      ...data
    }
    log.debug('store data', registrationData)
    registrationDataRef.current = registrationData
  }

  const handleBusinessTypeSubmit = useCallback((data) => {
    const { entityType } = data

    const registrationData = {
      ...registrationDataRef.current,
      entityType
    }
    log.debug('store data', registrationData)
    registrationDataRef.current = registrationData
  }, [registrationDataRef])

  const handleBusinessDetailsSubmit = useCallback((data) => {
    mergeAndStoreData(data)
  }, [])

  const handleUseCaseDetailsSubmit = useCallback((data) => {
    mergeAndStoreData(data)
    log.debug('show confirm dialog', registrationDataRef.current)
    confirmDialogRef.current.open()
  }, [])

  const handleSubmitCancel = useCallback(() => {
    detailsFormRef.current.updateSubmitting(false)
  }, [])

  const handleSubmitConfirm = useCallback(() => {
    const data = whitelistedRegistrationData(registrationDataRef.current)
    log.debug('submitting registration', data)
    submitRegistration(data)
  }, [submitRegistration])

  const handleBack = useCallback((data) => {
    log.debug('handleBack', data)
    mergeAndStoreData(data)
  }, [])

  const handleBeginRegistration = useCallback(() => {
    panelDialogRef.current.open()
  }, [])

  useEffect(() => {
    if (needsTcrInfo(currentUser)) {
      handleBeginRegistration()
    }
  }, [currentUser, handleBeginRegistration])

  if (!currentUser) {
    return null
  }

  if (!needsTcrInfo(currentUser)) {
    return <Navigate to='/account/tcr' replace />
  }

  return (
    <>
      <PanelDialog
        ref={panelDialogRef}
        ariaDescription='TCR registration form'
        ariaLabel='Registration Dialog'
        dismissable={false}
        fullscreen
      >
        <Wizard>
          {!isSolePropUpgrade && <BusinessType panelDialogRef={panelDialogRef} onSubmit={handleBusinessTypeSubmit} />}
          <BusinessDetails
            dataRef={registrationDataRef}
            panelDialogRef={panelDialogRef}
            onBack={handleBack}
            onSubmit={handleBusinessDetailsSubmit}
          />
          <UseCaseDetails
            dataRef={registrationDataRef}
            dataSamplesRef={dataSamplesRef}
            formRef={detailsFormRef}
            onBack={handleBack}
            onSubmit={handleUseCaseDetailsSubmit}
          />
        </Wizard>
        <ConfirmDialog
          ref={confirmDialogRef}
          confirmTitle='Submit Registration'
          description={registrationDataRef.current.entityType === 'SOLE_PROPRIETOR' ? 'Please ensure you have provided complete and accurate information. Incorrect or incomplete data will cause your registration to not be approved.' : 'Please ensure the business information and address you provided is the same as the address on your government registration documents. This will be used as part of the business verification process. If these do not match, your registration will not be approved.'}
          title='Are you sure?'
          onCancel={handleSubmitCancel}
          onConfirm={handleSubmitConfirm}
        />
      </PanelDialog>
      <div className='flex flex-row'>
        <Button className='mb-5' size='sm' onClick={handleBeginRegistration}>
          Begin Registration
        </Button>
      </div>
    </>
  )
}

RegistrationDialog.displayName = 'RegistrationDialog'
RegistrationDialog.propTypes = propTypes
RegistrationDialog.defaultProps = defaultProps

export default RegistrationDialog
