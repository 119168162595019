import { pick } from 'lodash'
import { forwardRef, useCallback, useRef } from 'react'

import useBus from '../../hooks/useBus'
import useCurrentSpace from '../../hooks/useCurrentSpace'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import usePoller from '../../hooks/usePoller'
import logger from '../../lib/logger'
import spaceFromJSON from '../../models/space'
import SpaceListEmptyContent from '../../screens/spaces/list/EmptyListContent'
import { search as spaceSearch } from '../../services/space'
import List from '../list/List'

import SpaceListItemContent from './SpaceListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['SpaceList'] })

const updateObjWithLastMessage = (obj, lastMessage) => {
  const lastMessageToOrFromKey = obj.lastMessage.direction === 'in' ? 'lastMessageFrom' : 'lastMessageTo'
  obj.lastMessageRead = pick(lastMessage, ['_id', 'time'])
  obj[lastMessageToOrFromKey] = pick(lastMessage, ['_id', 'time'])
  return obj
}

const SpaceList = forwardRef(({ ...rest }, ref) => {
  useLogger({ log, lifecycle: true, tags: [] })
  ref = useDefaultRef(ref)
  const lastMessageSeenIdRef = useRef(undefined)
  const ignoreNextPollerUpdate = useRef(true)

  const { space, setSpace } = useCurrentSpace()

  const updateSpaceWithLastMessage = useCallback((lastMessage) => {
    const newSpace = spaceFromJSON({ ...space, lastMessage })
    updateObjWithLastMessage(newSpace, lastMessage)
    setSpace(space)
  }, [setSpace, space])

  const updateListItemWithLastMessage = useCallback((lastMessage) => {
    const currentItem = ref.current.getItemById(space._id)
    if (!currentItem) { return }
    const newListItem = spaceFromJSON({ ...currentItem, lastMessage })
    updateObjWithLastMessage(newListItem, lastMessage)
    ref.current.updateItem(newListItem)
  }, [space?._id, ref])

  // Attempt to update the current space and list with the last message details
  const handleNewLastMessage = useCallback((lastMessage) => {
    log.debug('handleNewLastMessage', lastMessage._id)
    lastMessageSeenIdRef.current = lastMessage._id
    ignoreNextPollerUpdate.current = true
    updateSpaceWithLastMessage(lastMessage)
    updateListItemWithLastMessage(lastMessage)
  }, [updateSpaceWithLastMessage, updateListItemWithLastMessage])
  useBus('newLastChatMessage', handleNewLastMessage)

  const handlePollerPaused = useCallback(() => {
    // Invalidate any outstanding ignore so we pick up any changes on resume
    ignoreNextPollerUpdate.current = false
  }, [])
  usePoller('paused', handlePollerPaused)

  const handleSpacesUpdate = useCallback((updates) => {
    log.debug('Got spacesUpdate event from poller', updates)
    const newLastMessageUnreadId = updates?.lastMessageUnread?._id

    log.debug(ignoreNextPollerUpdate.current ? 'ignoring' : 'processing', lastMessageSeenIdRef, newLastMessageUnreadId)

    const justMarkedLastAsRead = !newLastMessageUnreadId && updates.unreadCount === 0

    if (ignoreNextPollerUpdate.current !== true && !justMarkedLastAsRead) {
      if (lastMessageSeenIdRef.current !== newLastMessageUnreadId) {
        ref.current.indicateUpdatesDetected()
      }
    }

    lastMessageSeenIdRef.current = newLastMessageUnreadId
    ignoreNextPollerUpdate.current = false
  }, [ref])
  usePoller('spacesUpdate', handleSpacesUpdate)

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('spaceCreated', handleBusEvent)
  useBus('spaceUpdated', handleBusEvent)
  useBus('spaceDeleted', handleBusEvent)
  useBus('spacesDeleted', handleBusEvent)
  useBus('spaceLeft', handleBusEvent)

  return (
    <List
      ref={ref}
      EmptyListContent={SpaceListEmptyContent}
      ListItemContent={SpaceListItemContent}
      placeholder='Search Spaces'
      resultsKey='spaces'
      searchService={spaceSearch}
      search
      {...rest}
    />
  )
})

SpaceList.displayName = 'SpaceList'
SpaceList.propTypes = propTypes
SpaceList.defaultProps = defaultProps

export default SpaceList
