import { times } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import PanelDialog from '../../../components/dialog/PanelDialog'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useDefaultRef from '../../../hooks/useDefaultRef'

const TestPanel = forwardRef(({ forceOverlay, fullscreen, isNested, keepInBackground, nestedLevel }, ref) => {
  ref = useDefaultRef(ref)

  const handleCloseClick = useCallback(() => ref.current.close(), [ref])
  const fillerAmount = Math.random() * (50 - 1) + 1
  const fillerContent = times(fillerAmount, (index) => (<p key={index} className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>))
  const nestedHeaderStartButton = <PanelHeaderButton icon='back' onClick={handleCloseClick} />
  const headerCloseButton = <PanelHeaderButton icon='close' onClick={handleCloseClick} />
  const headerEndButton = (
    <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
      <ActionMenuHeader label={`Action Menu ${nestedLevel}`} />
      <ActionMenuDivider />
      <ActionMenuItem label='Neat Action' />
      <ActionMenuItem label='A Longer Action Name That Might Wrap' />
      <ActionMenuItem label='Ok Action' />
    </ActionMenu>
  )
  return (
    <PanelDialog
      ref={ref}
      ariaDescription='An example and/or test panel dialog.'
      ariaLabel='Testing'
      forceOverlay={forceOverlay}
      fullscreen={fullscreen}
      keepInBackground={keepInBackground}
      trigger={
        <Button size='sm'>
          Open
          {' '}
          {fullscreen ? 'fullscreen' : null}
          {' '}
          {keepInBackground ? 'keepInBackground' : null}
        </Button>
      }
    >
      <PanelHeader
        end={headerEndButton}
        start={isNested ? nestedHeaderStartButton : headerCloseButton}
        title={`Testing ${nestedLevel}`}
      />
      <PanelContent className='p-4'>
        <div className='flex flex-row gap-4 mb-4'>
          <TestPanel
            forceOverlay={keepInBackground}
            fullscreen={keepInBackground ? false : fullscreen}
            nestedLevel={nestedLevel + 1}
            parentWasKeptInBackground={keepInBackground}
            isNested
          />
          <ConfirmDialog description='Testing confirm dialog within panel dialogs' title={`Confirm Dialog ${nestedLevel}`} trigger={<Button size='sm'>Confirm</Button>} />
          <Button size='sm' onClick={() => notify('Consider youself notified...')}>Notify</Button>
          <Button size='sm' onClick={() => ref.current.closeTree()}>Close All</Button>
        </div>
        {fillerContent}
      </PanelContent>
    </PanelDialog>
  )
})
TestPanel.displayName = 'TestPanel'
TestPanel.propTypes = {
  forceOverlay: PropType.bool,
  fullscreen: PropType.bool,
  isNested: PropType.bool,
  keepInBackground: PropType.bool,
  nestedLevel: PropType.number
}
TestPanel.defaultProps = {
  forceOverlay: false,
  fullscreen: false,
  isNested: false,
  keepInBackground: false,
  nestedLevel: 0
}

function PanelDialogs () {
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Panel Dialogs' />
      <PanelContent>
        <div className='p-4 flex flex-row gap-4'>
          <TestPanel fullscreen />
          <TestPanel />
          <TestPanel fullscreen keepInBackground />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default PanelDialogs
