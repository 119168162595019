import { pick } from 'lodash'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import Banner from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../components/detail-sections/DetailSection'
import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import EmailEditor from '../../components/emails/EmailEditor'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import UnlayerDesignDetailSection from '../../components/unlayer/UnlayerDesignDetailSection'
import useCurrentEmail from '../../hooks/useCurrentEmail'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import { CONTENT_TYPE, stateKeyFromContentType } from '../../lib/folder'
import logger from '../../lib/logger'
import { update as updateTemplateService } from '../../services/template'

import TemplateDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmailsDetail'] })

const EmailsDetail = () => {
  useLogger({ log, lifecycle: false, tags: ['EmailsDetail'] })

  const user = useCurrentUser()

  const { email, setEmail, /* refresh, */loading } = useCurrentEmail()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const stateKey = stateKeyFromContentType(CONTENT_TYPE.email)
    const updateTemplate = updateReply.json
    setEmail(updateTemplate)
    bus.emit(`${stateKey}Updated`, updateTemplate)
  }, [setEmail])

  if (loading || !email) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<TemplateDetailHeaderMoreMenu template={email} />}
        start={<PanelHeaderBackButton />}
        title={email.name}
      />
      <PanelContent className='p-5'>
        {!user.isCanSpamCompliant
          ? (
            <Banner className='mb-5' type='warning'>
              <div className='flex flex-col gap-2'>
                In order to send emails you must provide a physical mailing address and agree to the CAN-SPAM Act.
                <div className='flex'>
                  <Button
                    className='link button max-w-fit'
                    href='/account/can-spam-compliance'
                    size='sm'
                    theme='primary'
                    variant='outline'
                  >
                    Go to CAN-SPAM Compliance
                  </Button>
                </div>
              </div>
            </Banner>
            )
          : null}
        <FormDetailSection
          defaultValues={pick(email, ['name'])}
          description='Name the email so it is easy to find later.'
          formControls={[{ autoComplete: 'off', label: 'Name', name: 'name', type: 'text', required: true }]}
          formDescription='Updating email name'
          formTitle={email.name}
          objectId={email._id}
          service={updateTemplateService}
          title='Name'
          showEditIcon
          onChange={handleDetailsUpdate}
        >
          {email.name}
        </FormDetailSection>
        {user.previewAccess?.transactionalEmail
          ? (
            <CheckboxDetailSection
              checked={email.transactional}
              description='When checked, this email will ignore "Do Not Email" settings and will not offer an unsubscribe link.'
              name='transactional'
              objectId={email._id}
              service={updateTemplateService}
              title='Transactional Email'
              onChange={handleDetailsUpdate}
            />
            )
          : null}
        <FormDetailSection
          defaultValues={pick(email, ['subject'])}
          description='This is what shows up as the subject of the email.'
          formControls={[{ autoComplete: 'off', label: 'Subject', name: 'subject', type: 'text', required: true }]}
          formDescription='Updating email subject'
          formTitle={email.name}
          objectId={email._id}
          service={updateTemplateService}
          title='Subject'
          showEditIcon
          onChange={handleDetailsUpdate}
        >
          {email.subject}
        </FormDetailSection>
        <FormDetailSection
          defaultValues={pick(email, ['preheader'])}
          description='This is what shows up as a preview of the email next to the subject in many mail clients. It is recommended to be about 85 to 100 characters in length.'
          formControls={[{ autoComplete: 'off', label: 'Preview Text', name: 'preheader', type: 'text', required: true }]}
          formDescription='Updating email preview text'
          formTitle={email.name}
          initialEditButtonText='Set your email preview text'
          objectId={email._id}
          service={updateTemplateService}
          showEditIcon={!!email.preheader}
          showInitialEditButton={!email.preheader}
          title='Preview Text'
          onChange={handleDetailsUpdate}
        >
          {email.preheader}
        </FormDetailSection>
        <UnlayerDesignDetailSection
          Editor={EmailEditor}
          record={email}
          service={updateTemplateService}
          which='email'
          onChange={handleDetailsUpdate}
        />
        {!email.transactional
          ? (
            <DetailSection>
              <p className='mb-4'>
                At the bottom of every email will be the physical mailing address you provided in the
                {' '}
                <Link className='link' to='/account/can-spam-compliance'>CAN-SPAM Compliance section</Link>
                {' '}
                and an unsubscribe link.
              </p>
              <p>
                {user.formattedCanSpamPhysicalMailingAddress}
              </p>
            </DetailSection>
            )
          : null}
      </PanelContent>
    </Panel>
  )
}

EmailsDetail.displayName = 'EmailsDetail'
EmailsDetail.propTypes = propTypes
EmailsDetail.defaultProps = defaultProps

export default EmailsDetail
