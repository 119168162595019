import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('number', 'Phone Number is required', () => {
    enforce(data.number).isNotEmpty()
  })
})

export default validationSuite
