import PropType from 'prop-types'

import { searchPropTypes, searchTemplatePropTypes, updatePropTypes } from './appFlow.prop-types'
import pb from './pb'

export function editorConfig () {
  return pb.setupGet('appFlows/editorConfig')
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      key: 'appFlows',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'appFlows#search')
  return pb.setupPost('appFlows/search', options)
}

export function create (body) {
  return pb.setupPost('appFlows', { body })
}

export function createDesign (appFlowId, appFlowTemplateId) {
  return pb.setupPost(`appFlows/${appFlowId}/design`, { body: { appFlowTemplateId } })
}

export function clone (appFlowId) {
  return pb.setupPost(`appFlows/${appFlowId}/clone`)
}

export function get (appFlowId, { omitAppmixerInfo = false }, background = false) {
  return pb.setupGet(`appFlows/${appFlowId}?omitAppmixerInfo=${omitAppmixerInfo.toString()}`, { background })
}

export function update (appFlowId, body, background = false) {
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'appFlows#update')
  return pb.setupPut(`appFlows/${appFlowId}`, { body, background })
}

export function toggleState (appFlowId, background = false) {
  return pb.setupPost(`appFlows/${appFlowId}/toggleState`, { background })
}

export function del (appFlowId) {
  return pb.setupDelete(`appFlows/${appFlowId}`)
}

export function searchTemplates (body, background = false) {
  const options = {
    body,
    background
  }
  PropType.checkPropTypes(searchTemplatePropTypes, body, 'prop', 'appFlows#searchTemplates')
  return pb.setupPost('appFlows/templates', options)
}
