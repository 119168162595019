import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'
import { forwardRef } from 'react'

import BaseDialogNode, { defaultProps, propTypes } from './BaseDialogNode'

import './BaseDialog.css'

const BaseDialog = forwardRef((props, ref) => {
  const parentId = useFloatingParentNodeId()
  const baseDialogNode = <BaseDialogNode ref={ref} {...props} />

  return parentId ? baseDialogNode : (<FloatingTree>{baseDialogNode}</FloatingTree>)
})

BaseDialog.displayName = 'BaseDialog'
BaseDialog.propTypes = propTypes
BaseDialog.defaultProps = defaultProps

export default BaseDialog
