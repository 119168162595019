import * as React from "react";
import { memo } from "react";
const SvgTags = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 244 183"
    {...props}
  >
    <g filter="url(#Tags_svg__a)">
      <path
        fill="#fff"
        d="M187.786 5.143H47.214c-6.47 0-11.714 5.244-11.714 11.714v140.571c0 6.47 5.245 11.715 11.714 11.715h140.572c6.469 0 11.714-5.245 11.714-11.715V16.857c0-6.47-5.245-11.714-11.714-11.714Z"
      />
      <path
        stroke="#E6E7EB"
        d="M47.214 5.643h140.572c6.193 0 11.214 5.02 11.214 11.214v140.571c0 6.194-5.021 11.215-11.214 11.215H47.214c-6.193 0-11.214-5.021-11.214-11.215V16.857c0-6.194 5.02-11.214 11.214-11.214Z"
      />
    </g>
    <path
      fill="#ECFDF5"
      d="M177.5 17.143h-120c-5.523 0-10 4.477-10 10v120c0 5.522 4.477 10 10 10h120c5.523 0 10-4.478 10-10v-120c0-5.523-4.477-10-10-10Z"
    />
    <g filter="url(#Tags_svg__b)">
      <rect
        width={178}
        height={40}
        x={54.5}
        y={85.643}
        fill="#fff"
        rx={4}
        shapeRendering="crispEdges"
      />
      <rect width={28} height={28} x={62.5} y={91.643} fill="#D0FAE4" rx={4} />
      <g clipPath="url(#Tags_svg__c)">
        <path
          fill="#05976A"
          fillRule="evenodd"
          d="M77.652 98.643c-.478 0-.924.19-1.259.516l-6.365 6.272-.003.003c-.7.7-.7 1.837 0 2.537l4.147 4.147c.7.7 1.837.7 2.537 0l6.27-6.362v-.002c.335-.335.521-.791.521-1.264v-4.054c0-.988-.805-1.793-1.793-1.793h-4.055Zm-6.729 7.693 6.359-6.266a.528.528 0 0 1 .37-.154h4.055c.285 0 .52.235.52.52v4.054a.52.52 0 0 1-.149.366l-6.27 6.362v.001a.522.522 0 0 1-.736-.001l-4.147-4.147a.522.522 0 0 1-.002-.735Zm9.685-4.165a.636.636 0 0 0-1.273 0v.006a.637.637 0 1 0 1.273 0v-.006Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#D0FAE4"
        d="M187.897 96.614h-87.859c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h87.859c1.954 0 3.537-1.574 3.537-3.515 0-1.94-1.583-3.514-3.537-3.514ZM151.928 107.643h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
      />
      <rect
        width={177}
        height={39}
        x={55}
        y={86.143}
        stroke="#E6E7EB"
        rx={3.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#Tags_svg__d)">
      <rect
        width={178}
        height={40}
        x={7}
        y={34.143}
        fill="#fff"
        rx={4}
        shapeRendering="crispEdges"
      />
      <rect width={28} height={28} x={15} y={40.143} fill="#D0FAE4" rx={4} />
      <g clipPath="url(#Tags_svg__e)">
        <path
          fill="#05976A"
          fillRule="evenodd"
          d="M30.152 47.143c-.478 0-.925.19-1.259.516l-6.365 6.272-.003.003c-.7.7-.7 1.838 0 2.537l4.147 4.147c.7.7 1.837.7 2.537 0l6.27-6.362v-.002c.335-.334.521-.791.521-1.264v-4.054c0-.988-.806-1.793-1.793-1.793h-4.055Zm-6.729 7.693 6.359-6.267a.53.53 0 0 1 .37-.154h4.055c.285 0 .52.236.52.521v4.054a.52.52 0 0 1-.149.366l-6.27 6.362v.001a.523.523 0 0 1-.736 0l-4.147-4.148a.523.523 0 0 1-.002-.735Zm9.685-4.165a.636.636 0 1 0-1.273 0v.007a.636.636 0 0 0 1.273 0v-.007Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#D0FAE4"
        d="M140.397 45.114h-87.86c-1.953 0-3.537 1.573-3.537 3.514 0 1.941 1.584 3.515 3.538 3.515h87.859c1.954 0 3.537-1.574 3.537-3.515 0-1.94-1.583-3.514-3.537-3.514ZM104.428 56.143h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.94 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
      />
      <rect
        width={177}
        height={39}
        x={7.5}
        y={34.643}
        stroke="#E6E7EB"
        rx={3.5}
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="Tags_svg__a"
        width={182}
        height={182}
        x={28.5}
        y={0.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_882_88409"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_882_88409"
          result="shape"
        />
      </filter>
      <filter
        id="Tags_svg__b"
        width={196}
        height={58}
        x={47.5}
        y={80.643}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_882_88409"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_882_88409"
          result="shape"
        />
      </filter>
      <filter
        id="Tags_svg__d"
        width={196}
        height={58}
        x={0}
        y={29.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_882_88409"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_882_88409"
          result="shape"
        />
      </filter>
      <clipPath id="Tags_svg__c">
        <path fill="#fff" d="M68.863 98.006h15.273v15.273H68.863z" />
      </clipPath>
      <clipPath id="Tags_svg__e">
        <path fill="#fff" d="M21.363 46.506h15.273V61.78H21.363z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTags);
export default Memo;
