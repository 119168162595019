import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import stackKeyFromLocation from '../lib/stackKeyFromLocation'

function useCurrentStackKey () {
  const location = useLocation()
  const [stackKey, setStackKey] = useState(stackKeyFromLocation(location))
  useEffect(() => { setStackKey(stackKeyFromLocation(location)) }, [location])
  return stackKey
}

export default useCurrentStackKey
