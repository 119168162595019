import PropType from 'prop-types'
import { useId } from 'react'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import dates from '../../../data-sources/dates'
import months from '../../../data-sources/months'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contact from '../../../prop-types/shapes/contact'

import SenderInfo from './SenderInfo'

const propTypes = {
  contact: PropType.shape(contact).isRequired,
  onUpdateSubmit: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['StepTwo'] })

const StepTwo = ({ contact, onUpdateSubmit }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formId = useId()

  return (
    <div>
      <SenderInfo user={contact.user} />
      <p className='mb-6 headline-sm text-center'>Thank you for opting in.</p>
      <p className='mb-6 text-center'>Optionally, fill out the form below to make sure we've got the correct contact information for you.</p>
      <Form
        defaultValues={{
          firstName: '',
          lastName: '',
          company: '',
          email: ''
        }}
        formControls={[
          { autoFocus: true, type: 'text', name: 'firstName', label: 'First Name' },
          { type: 'text', name: 'lastName', label: 'Last Name' },
          { type: 'text', name: 'company', label: 'Company' },
          { type: 'email', name: 'email', label: 'Email' },
          {
            type: 'controlgroup',
            name: 'birthday',
            formControls: [
              { containerClassName: 'w-full', label: 'Birthday Month', name: 'birthday[month]', type: 'select', options: [{ label: 'Select Month', value: '' }, ...months] },
              { containerClassName: 'w-full', label: 'Birthday Day', name: 'birthday[date]', type: 'select', options: [{ label: 'Select Day', value: '' }, ...dates] }
            ]
          }
        ]}
        id={formId}
        onSubmit={onUpdateSubmit}
      />
      <Button
        className='w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Save
      </Button>
    </div>
  )
}

StepTwo.displayName = 'StepTwo'
StepTwo.propTypes = propTypes
StepTwo.defaultProps = defaultProps

export default StepTwo
