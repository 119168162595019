import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chatShape from '../../../prop-types/shapes/chat'
import { bulkMarkAsRead as bulkMarkAsReadService } from '../../../services/chat'
import { warning } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  chats: PropType.arrayOf(PropType.shape(chatShape)).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['BulkMarkAsRead'] })

const BulkMarkAsRead = forwardRef(({ chats }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    bus.emit('chatMarkedAsReadOrUnread')
    bus.emit('closeBulkEditDialog')
  }, [])
  const { call } = useService(bulkMarkAsReadService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!chats || !chats.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [chats])

  const handleConfirm = useCallback(() => {
    call(map(chats, '_id'))
  }, [call, chats])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to mark these as read? This cannot be undone.'
      title='Mark as Read?'
      onConfirm={handleConfirm}
    />
  )
})

BulkMarkAsRead.displayName = 'BulkMarkAsRead'
BulkMarkAsRead.propTypes = propTypes
BulkMarkAsRead.defaultProps = defaultProps

export default BulkMarkAsRead
