import { includes } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import FormattedDate from '../../../components/FormattedDate'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventScheduledDateDetail'] })

const EventScheduledDateDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent._id] })

  const { userTimeZone } = useTimeZone()

  const { confirm, formControls, defaultValues, transformData, value } = useMemo(() => ({
    confirm: {
      description: `Updating to an earlier date may reschedule some broadcasts originated by this
                    campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledAt }) => {
        if (includes(['repeat', 'schedule'], campaignEvent.campaignType)) {
          return new Date(scheduledAt) < new Date()
        } else {
          return new Date(scheduledAt) < new Date(campaignEvent.scheduledAt)
        }
      }
    },
    formControls: [
      { label: '', name: 'date', type: 'date' },
      { label: '', name: 'time', type: 'time-message-send' }
    ],
    defaultValues: campaignEvent.scheduledAt && {
      date: formatDateForInput(campaignEvent.scheduledAt, userTimeZone),
      time: formatTimeForInput(campaignEvent.scheduledAt, userTimeZone)
    },
    transformData: ({ date, time }) => ({
      scheduledAt: dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    }),
    value: <FormattedDate date={campaignEvent.scheduledAt} format='extendedDateAndTime' />
  }), [campaignEvent, userTimeZone])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='Specify the date and time to send this message.'
      formControls={formControls}
      formDescription='Update the date and time for sending this message'
      formTitle='Select Date and Time'
      initialEditButtonText='Set Date and Time'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!campaignEvent.scheduledAt}
      showInitialEditButton={!campaignEvent.scheduledAt}
      title='Send At'
      transformData={transformData}
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventScheduledDateDetail.displayName = 'EventScheduledDateDetail'
EventScheduledDateDetail.propTypes = propTypes
EventScheduledDateDetail.defaultProps = defaultProps

export default EventScheduledDateDetail
