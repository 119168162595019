import PropType from 'prop-types'
import { useCallback } from 'react'

import { notify } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import { copyToClipboard } from '../../../lib/copyToClipboard'
import logger from '../../../lib/logger'
import bundleShape from '../../../prop-types/shapes/bundle'

const propTypes = {
  bundle: PropType.shape(bundleShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShareIdDetail'] })

const ShareIdDetail = ({ bundle }) => {
  useLogger({ log, lifecycle: false, tags: [bundle._id] })

  const handleCopyClick = useCallback(() => {
    copyToClipboard(bundle.shareId)
    notify('Share Id copied to clipboard.')
  }, [bundle])

  return (
    <DetailSection
      description='Share this id with other Project Broadcast users and they can use it to import a copy of your bundle. Just tap the icon to copy the share id to the clipboard.'
      title='Share Id'
      showCopyIcon
      onCopyClick={handleCopyClick}
    >
      <div className='select-text'>
        {bundle.shareId}
      </div>
    </DetailSection>
  )
}

ShareIdDetail.displayName = 'ShareIdDetail'
ShareIdDetail.propTypes = propTypes
ShareIdDetail.defaultProps = defaultProps

export default ShareIdDetail
