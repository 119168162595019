import classNames from 'classnames'
import PropType from 'prop-types'
import { NavLink } from 'react-router-dom'

import { ChevronRight } from '../../svg/icons'

const propTypes = {
  title: PropType.string.isRequired,
  to: PropType.string.isRequired
}

const defaultProps = {}

const ListActionLink = ({ title, to }) => {
  const computeClassName = ({ isActive/* isPending */ }) => {
    return classNames(
      'block p-3 flex items-center border-b border-neutral-200 active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50',
      {
        'text-primary': isActive,
        'bg-neutral-50': isActive
      }
    )
  }
  return (
    <NavLink className={computeClassName} to={to}>
      <span className='flex-grow'>{title}</span>
      <ChevronRight className='flex-none w-4 h-4' />
    </NavLink>
  )
}

ListActionLink.displayName = 'ListActionLink'
ListActionLink.propTypes = propTypes
ListActionLink.defaultProps = defaultProps

export default ListActionLink
