import PropType from 'prop-types'

import customField from './customField'
import dateMonth from './dateMonth'
import message from './message'
import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  anniversary: PropType.shape(dateMonth),
  archived: PropType.bool,
  birthday: PropType.shape(dateMonth),
  campaignJoinDates: PropType.arrayOf(PropType.shape({
    campaign: PropType.string, // "6038fd5a3e69e626c02acf4c"
    joinDate: PropType.string // "2021-02-26T13:54:32.897Z"
  })),
  campaigns: PropType.oneOfType([
    PropType.arrayOf(PropType.string), // ids
    PropType.shape({
      length: PropType.number,
      subset: PropType.arrayOf(PropType.shape({
        _id: PropType.string,
        name: PropType.string
      }))
    })
  ]),
  company: PropType.string,
  contactUser: PropType.oneOfType([
    PropType.string, // maybe should be string or user object?
    PropType.shape(user)
  ]),
  contests: PropType.arrayOf(PropType.shape({
    contest: PropType.string,
    winner: PropType.bool
  })),
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  customFields: PropType.arrayOf(PropType.shape(customField)),
  potentialStopMessage: PropType.string, // "6449a157a61a28000255914c"
  doNotMessage: PropType.bool,
  doNotEmail: PropType.bool,
  doNotMessageMe: PropType.bool,
  doubleOptedInAt: PropType.string,
  doubleOptInMessages: PropType.array, // array of what?
  email: PropType.string,
  firstName: PropType.string,
  hideChat: PropType.bool,
  id: PropType.string,
  importLock: PropType.bool,
  isDoubleOptedIn: PropType.bool,
  lastMessage: PropType.shape(message),
  lastMessageFrom: PropType.shape({
    time: PropType.string // "2021-06-04T12:28:43.580Z"
  }),
  lastMessageRead: PropType.shape({
    time: PropType.string // "2021-06-04T12:28:43.580Z"
  }),
  lastMessageTo: PropType.shape({
    time: PropType.string // "2022-02-14T16:22:55.064Z"
  }),
  lastName: PropType.string,
  notes: PropType.string,
  outboundJobs: PropType.array, // maybe should be array of strings or outbound job objects?
  phoneNumber: PropType.string,
  scheduledChatCount: PropType.number,
  search: PropType.string,
  slug: PropType.string,
  sortText: PropType.string,
  starred: PropType.bool,
  tags: PropType.arrayOf(PropType.string),
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  user: PropType.oneOfType([
    PropType.string,
    PropType.shape(user)
  ])
}
