import * as React from "react";
import { memo } from "react";
const SvgSchool = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#School_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.553.106a1 1 0 0 1 .894 0l3.986 1.992.027.014 1.987.994a1 1 0 1 1-.894 1.788L17 4.618v3.764l4.433 2.216.027.014 1.987.994a1 1 0 1 1-.894 1.788L22 13.118V23a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9.882l-.553.276a1 1 0 1 1-.894-1.788l1.978-.99a.928.928 0 0 1 .045-.022L7 8.382V4.618l-.553.276a1 1 0 1 1-.894-1.788l1.978-.99a.996.996 0 0 1 .045-.022L11.553.106ZM4 12.118V22h3v-3c0-2.762 2.238-5 5-5s5 2.238 5 5v3h3v-9.882l-4.424-2.212a1.014 1.014 0 0 1-.045-.023l-3.53-1.765-3.54 1.77-.028.014L4 12.118ZM15 22v-3c0-1.658-1.342-3-3-3s-3 1.342-3 3v3h6ZM12 2.118l3 1.5v3.764l-2.553-1.276a1 1 0 0 0-.894 0L9 7.382V3.618l3-1.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="School_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgSchool);
export default Memo;
