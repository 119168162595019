import PropType from 'prop-types'
import { Link } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ChevronRight } from '../../../svg/icons'

const propTypes = {
  scheduledMessagesCount: PropType.number
}

const defaultProps = {
  scheduledMessagesCount: 0
}

const log = logger({ enabled: false, tags: ['ScheduledChats'] })

const ScheduledChats = ({ scheduledMessagesCount }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!scheduledMessagesCount) { return null }

  return (
    <div className='absolute bottom-2 left-5 right-5 flex flex-col items-center z-20 select-none'>
      <Link className='block py-1 pl-3 pr-1 label-xs-medium border bg-blue-50 border-blue-300 rounded-full text-black flex flex-row flex-nowrap items-center' to='scheduled'>
        Scheduled Chats
        (
        {scheduledMessagesCount}
        )
        <ChevronRight className='ml-1' height='16' width='16' />
      </Link>
    </div>
  )
}

ScheduledChats.displayName = 'ScheduledChats'
ScheduledChats.propTypes = propTypes
ScheduledChats.defaultProps = defaultProps

export default ScheduledChats
