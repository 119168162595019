import { forwardRef, useCallback, useId, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import BaseDialog from '../../components/dialog/BaseDialog'
import TextInput from '../../components/forms/controls/TextInput'
import Form from '../../components/forms/Form'
import useLogger from '../../hooks/useLogger'
import useNavigateInStack from '../../hooks/useNavigateInStack'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { unsubscribe as unsubscribeService } from '../../services/subscription'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ConfirmCancelSubscriptionDialog'] })

const formSettings = {
  id: 'ConfirmCancelSubscriptionDialogForm',
  defaultValues: {},
  formControls: [{
    name: 'reasonCode',
    type: 'select',
    required: true,
    label: 'Reason',
    options: [
      { label: 'I have stopped my business', value: 'stopped-business' },
      { label: 'I am moving to a different platform', value: 'switching-to-competitor' },
      { label: 'I find Project Broadcast difficult to use', value: 'hard-to-use' },
      { label: 'I did not use PB enough to justify the cost', value: 'too-expensive' },
      { label: 'Other', value: 'other' }
    ],
    placeholder: 'Please select a reason'
  }, {
    name: 'reasonExtra',
    type: 'passthrough',
    element: (
      <>
        We understand that Project Broadcast may not be a fit for everyone. We are always striving to make Project Broadcast better and would love to get your thoughts on what is driving you to cancel. Thanks so much for giving Project Broadcast a chance.
      </>
    )
  }, {
    name: 'reason',
    className: 'mb-0',
    type: 'textarea',
    label: 'Additional Comments'
  }]
}

const ConfirmCancelSubscriptionDialog = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmButtonText = 'Cancel Subscription'
  const confirmValue = 'CANCEL'
  const title = 'Are you sure you want to cancel your subscription?'

  const inputId = useId()
  const titleId = useId()
  const formRef = useRef()
  const [value, setValue] = useState('')
  const navigateInStack = useNavigateInStack()

  const handleUnsubscribeOk = useCallback(() => {
    notify('Your subscription cancellation request has been submitted.')
    navigateInStack('/subscription', { replace: true })
    ref.current.close()
  }, [navigateInStack, ref])

  const { call: cancelSubscription } = useService(unsubscribeService, { onReplyOk: handleUnsubscribeOk })

  const handleInputChange = useCallback((event) => {
    setValue(event.target.value)
  }, [setValue])

  const handleSubmit = useCallback(({ reason, reasonCode }) => {
    cancelSubscription({ reason, reasonCode })
  }, [cancelSubscription])
  formSettings.onSubmit = handleSubmit

  const handleCancel = useCallback(() => {
    ref.current.close()
  }, [ref])

  const handleClose = useCallback(() => {
    setValue('')
  }, [setValue])

  return (
    <BaseDialog
      ref={ref}
      ariaLabelledBy={titleId}
      onClose={handleClose}
    >
      <div className='bg-white drop-shadow rounded-lg px-[20px] py-[28px] max-w-[375px] flex flex-col'>
        <h1 className='label-lg-strong text-black text-center mb-[20px]' id={titleId}>{title}</h1>
        <Form
          ref={formRef}
          {...formSettings}
        />
        <div className='flex flex-col flex-nowrap gap-2 justify-center'>
          <label className='label-md-strong' htmlFor={inputId}>
            Please type
            {' '}
            <strong>
              "
              {confirmValue}
              "
            </strong>
            {' '}
            to confirm
          </label>
          <TextInput
            id={inputId}
            name='confirm'
            value={value}
            onChange={handleInputChange}
          />
          <Button
            className='w-full'
            disabled={value !== confirmValue}
            form={formSettings.id}
            size='md'
            theme='danger'
            type='submit'
          >
            {confirmButtonText}
          </Button>
          <Button
            className='w-full'
            size='md'
            variant='outline'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseDialog>
  )
})

ConfirmCancelSubscriptionDialog.displayName = 'ConfirmCancelSubscriptionDialog'
ConfirmCancelSubscriptionDialog.propTypes = propTypes
ConfirmCancelSubscriptionDialog.defaultProps = defaultProps

export default ConfirmCancelSubscriptionDialog
