import { useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'

import Message from '../../components/forms/controls/Message'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import SpaceThread from '../../components/spaces/SpaceThread'
import useCurrentSpace from '../../hooks/useCurrentSpace'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { pluralize } from '../../lib/formatters'
import logger from '../../lib/logger'
import poller from '../../lib/poller'
import { send as sendMesssageService } from '../../services/message'
import { markAsRead } from '../../services/space'

import ListHeaderMore from './thread/ListHeaderMore'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['SpacesThread'] })

const SpacesThread = () => {
  const spaceThreadRef = useRef()
  const messageControlRef = useRef()

  useLogger({ log, lifecycle: false, tags: [] })

  const { spaceId } = useParams()
  const { space, loading } = useCurrentSpace()

  const handleMarkAsReadReplyOk = useCallback(() => { poller.runImmediately() }, [])
  const { call: markAsReadCall } = useService(markAsRead, { onReplyOk: handleMarkAsReadReplyOk })

  const handleSendReplyOk = useCallback((reply) => {
    messageControlRef.current.reset()
    // maybe a bit hacky... we're going to immediately mark the message as read to avoid the spaces unread count indicator from flashing
    // but might have been a queue call...
    const message = reply.json?.messages?.[0]
    if (message) {
      markAsReadCall({ spaceId, messageId: message._id }, true)
      if (spaceThreadRef.current.isEmpty()) {
        // Force reload the list after send if new/empty space. Otherwise since there
        //  is no "last message" the poller will not pick up the newly sent message.
        spaceThreadRef.current.reload()
      }
    } else {
      poller.runImmediately()
    }
  }, [markAsReadCall, spaceId])
  const { call: sendMessage } = useService(sendMesssageService, { onReplyOk: handleSendReplyOk })

  const handleSend = useCallback((message) => {
    sendMessage({ spaceId, ...message }, true)
  }, [sendMessage, spaceId])

  const drafKey = `space-thread-${spaceId}`

  if (loading || !space) { return <PanelLoading hideAivaChatButton hideMenuToggleButton /> }

  return (
    <Panel hideAivaChatButton hideMenuToggleButton>
      <PanelHeader
        end={<ListHeaderMore space={space} />}
        start={<PanelHeaderBackButton />}
        subtitle={`${space.users.length} ${pluralize({ count: space.users.length, singular: 'Member' })}`}
        title={space.name}
      />
      <PanelContent>
        <SpaceThread ref={spaceThreadRef} spaceId={space._id} />
        <div className='border-t border-neutral-200'>
          <Message
            ref={messageControlRef}
            contentOptions={{
              emails: true,
              messageTemplates: true,
              voiceDrops: true
            }}
            draftKey={drafKey}
            name='message'
            theme='space'
            onSchedule={handleSend}
            onSend={handleSend}
          />
        </div>
      </PanelContent>
    </Panel>
  )
}

SpacesThread.displayName = 'SpacesThread'
SpacesThread.propTypes = propTypes
SpacesThread.defaultProps = defaultProps

export default SpacesThread
