import PropType from 'prop-types'

export default {
  contacts: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape({
      _id: PropType.string,
      company: PropType.string,
      firstName: PropType.string,
      lastName: PropType.string,
      phoneNumber: PropType.string,
      tags: PropType.string // just the tag.name repeated
    }))
  }),
  name: PropType.string
}
