import PropType from 'prop-types'

import contactFromJSON from '../models/contact'

import { bulkUpdatePropTypes, createPropTypes, searchPropTypes, updatePropTypes } from './contact.prop-types'
import pb from './pb'

export function applyKeyword (contactId, keywordId, background = false) {
  const options = { background }
  return pb.setupPost(`contacts/${contactId}/applyKeyword/${keywordId}`, options)
}

export function archive (contactIds, background = false) {
  const options = {
    background,
    body: { contactIds }
  }
  return pb.setupPut('contacts/archive', options)
}

export function create (body, background = false) {
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'contact#create')
  const options = {
    background,
    body,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPost('contacts', options)
}

export function del (contactId, background = false) {
  const options = { background }
  return pb.setupDelete(`contacts/${contactId}`, options)
}

export function get (contactId, background = false) {
  const options = {
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupGet(`contacts/${contactId}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contacts',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'contact#search')
  return pb.setupPost('contacts/search', options)
}

export function update (contactId, body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'contact#update')
  return pb.setupPut(`contacts/${contactId}`, options)
}

export function bulkUpdate (contactIds, updateData, background = false) {
  const options = {
    body: {
      contactIds,
      updateData
    },
    background
  }
  PropType.checkPropTypes(bulkUpdatePropTypes, updateData, 'prop', 'contact#bulkUpdate')
  return pb.setupPut('contacts', options)
}

export function bulkFullDelete (body) {
  const options = {
    body
  }
  return pb.setupPost('contacts/remove', options)
}

export function archiveAll (body) {
  const options = {
    body
  }
  return pb.setupPut('contacts/archiveAll', options)
}

export function unarchive (contactIds) {
  const options = {
    body: { contactIds }
  }
  return pb.setupPut('contacts/unarchive', options)
}

export function unarchiveAll (body) {
  const options = {
    body
  }
  return pb.setupPut('contacts/unarchiveAll', options)
}

export function hideChats (contactIds, background = false) {
  const options = {
    background,
    body: { contactIds }
  }
  return pb.setupPut('contacts/hideChats', options)
}

export function importContacts (body) {
  const options = {
    body
  }
  return pb.setupPost('contacts/import', options)
}

export function exportContacts (body) {
  const options = {
    body
  }
  return pb.setupPost('contacts/export', options)
}
