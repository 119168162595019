import { SplashScreen } from '@capacitor/splash-screen'

import logger from './lib/logger'
import { getPreference, removePreference } from './lib/preferences'
import pb from './services/pb'
import { resumeSession as resumeSessionService, updateAivaSettings as updateAivaSettingsService } from './services/user'
import { resumeSession as resumeSessionAction } from './store/actions/currentUser'

const log = logger({ enabled: true, tags: ['App', 'boot'] })

async function getPreferences () {
  log.debug('loading preferences...')
  const emojiSkinTone = await getPreference('emojiSkinTone') || 'neutral'
  const sideMenuLocked = await getPreference('sideMenuLocked') !== 'false'
  return { emojiSkinTone, sideMenuLocked }
}

async function attemptToResumeSession () {
  log.debug('attempting to resume session...')
  await pb.init()
  const { call } = resumeSessionService()
  return await call()
}

async function migrateSettings () {
  // TODO: remove this after some time (2H24?)
  try {
    const aivaMessageHelperEnabled = await getPreference('aivaMessageHelperEnabled')
    if (aivaMessageHelperEnabled === 'false') {
      const { call } = updateAivaSettingsService({ messageHelper: false })
      await call()
      removePreference('aivaMessageHelperEnabled')
    }
  } catch (ex) {}
}

async function boot () {
  SplashScreen.show({ autoHide: false })
  log.debug('starting up...')
  const [currentPreferencesPromise, resumeSessionReplyPromise] = await Promise.allSettled([getPreferences(), attemptToResumeSession()])
  log.debug('currentPreferences: ', currentPreferencesPromise.value)
  log.debug('resumeSession:', resumeSessionReplyPromise.value.ok ? 'resumed' : 'not resumed')
  resumeSessionAction(resumeSessionReplyPromise.value)
  if (resumeSessionReplyPromise.value.ok) {
    await migrateSettings()
  }
  return currentPreferencesPromise.value
}

export default boot
