import PropType from 'prop-types'

import messageTypes from '../../helpers/messageTypes'

import campaignShape from './campaign'
import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  antiCampaigns: PropType.arrayOf(PropType.shape(campaignShape)),
  autoTag: PropType.bool,
  bundles: PropType.arrayOf(PropType.string), // bundle ids
  campaigns: PropType.arrayOf(PropType.shape(campaignShape)),
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  delay: PropType.number,
  doNotMessage: PropType.bool,
  doNotEmail: PropType.bool,
  optIn: PropType.bool,
  enabled: PropType.bool,
  media: PropType.arrayOf(PropType.string),
  search: PropType.string,
  slug: PropType.string,
  spaces: PropType.array, // TODO; array of what?
  text: PropType.string,
  type: PropType.oneOf(messageTypes),
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  user: PropType.oneOfType([
    PropType.string,
    PropType.shape(user)
  ]),
  word: PropType.string
}
