import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'

export async function copyToClipboard (text) {
  if (Capacitor.isNativePlatform()) {
    return await Clipboard.write({
      string: text
    })
  } else {
    return new Promise((resolve, reject) => {
      try {
        navigator.clipboard.writeText(text)
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  }
}

// promiseFn should return text to copy
// safari doesn't like async clipboard writes and firefox doesn't support the safari workaround
export async function copyToClipboardViaPromise (promiseFn) {
  if (!Capacitor.isNativePlatform() && typeof ClipboardItem && navigator.clipboard.write) { // safari/chrome
    const text = new ClipboardItem({
      'text/plain': promiseFn().then((text) => new Blob([text], { type: 'text/plain' }))
    })
    return await navigator.clipboard.write([text])
  } else { // others like firefox and capacitor
    const text = await promiseFn()
    return copyToClipboard(text)
  }
}
