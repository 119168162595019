import { get as getKeywordService } from '../services/keyword'

import useCurrentInstance from './useCurrentInstance'

const useCurrentKeyword = () => useCurrentInstance({
  resourceName: 'keyword',
  getService: getKeywordService,
  replyAttrPath: 'json',
  notFoundRedirect: '/keywords'
})

export default useCurrentKeyword
