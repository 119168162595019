import { upperFirst } from 'lodash'
import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import appFlow from '../../prop-types/shapes/appFlow'
import { Clock, Dynamic } from '../../svg/icons'
import { AppFlow } from '../../svg/types'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape(appFlow)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['AppFlowListItemContent'] })

const AppFlowListItemContent = ({ item: appFlow }) => {
  useLogger({ log, lifecycle: false, tags: [appFlow?._id] })

  return (
    <>
      <AppFlow className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {appFlow.name}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px] gap-1 line-clamp-1 break-words'>
          {appFlow.description}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px] gap-1 line-clamp-1 break-words'>
          <div className='flex flex-row gap-1'>
            {appFlow.state === 'running' ? <Dynamic className='w-4 h-4' /> : null}
            {upperFirst(appFlow.state)}
          </div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            modified
            {' '}
            <FormattedDate date={appFlow.updatedAt} format='extendedDate2' />
          </div>
        </div>
      </div>
    </>
  )
}

AppFlowListItemContent.displayName = 'AppFlowListItemContent'
AppFlowListItemContent.propTypes = propTypes
AppFlowListItemContent.defaultProps = defaultProps

export default AppFlowListItemContent
