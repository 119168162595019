import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import ConfirmDialog from '../../../dialog/ConfirmDialog'

const propTypes = {
  messageType: PropType.oneOf(messageTypes).isRequired,
  mediaUrl: PropType.string,
  onRemove: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onRemove: undefined
}

const log = logger({ enabled: false, tags: ['MessageImageAttachment'] })

const MessageImageAttachment = ({ mediaUrl, messageType, onRemove }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmRef = useRef()

  const handleImageClick = useCallback(() => { confirmRef.current.open() }, [])

  if (!mediaUrl || messageType === 'rvm') { return null }

  return (
    <div className='px-3 py-2 flex place-content-center'>
      <img className='h-36 cursor-pointer' src={mediaUrl} onClick={handleImageClick} />
      <ConfirmDialog
        ref={confirmRef}
        description='This will remove the image.'
        title='Are you sure?'
        onConfirm={onRemove}
      />
    </div>
  )
}

MessageImageAttachment.displayName = 'MessageImageAttachment'
MessageImageAttachment.propTypes = propTypes
MessageImageAttachment.defaultProps = defaultProps

export default MessageImageAttachment
