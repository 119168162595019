import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chatShape from '../../../prop-types/shapes/chat'
import { hideChats } from '../../../services/contact'
import { warning } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  chats: PropType.arrayOf(PropType.shape(chatShape)).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BulkHideChatAction'] })

const BulkHideChatAction = forwardRef(({ chats }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    bus.emit('chatHidden', chats)
    bus.emit('closeBulkEditDialog')
  }, [chats])
  const { call } = useService(hideChats, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!chats || !chats.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [chats])

  const handleConfirm = useCallback(() => {
    call(chats.map((chat) => chat._id))
  }, [call, chats])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to hide these chats?'
      title='Hide Chats?'
      onConfirm={handleConfirm}
    />
  )
})

BulkHideChatAction.displayName = 'BulkHideChatAction'
BulkHideChatAction.propTypes = propTypes
BulkHideChatAction.defaultProps = defaultProps

export default BulkHideChatAction
