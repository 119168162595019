import { forEach } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useService from '../../hooks/useService'
import { stats as statsService } from '../../services/user'

const Stats = () => {
  const [stats, setStats] = useState()

  const handleReplyOk = useCallback((result) => {
    setStats(result.json)
  }, [])
  const { call: fetchStats } = useService(statsService, { onReplyOk: handleReplyOk })

  useEffect(() => {
    fetchStats(true)
  }, [fetchStats])

  const title = 'Stats'

  if (!stats) { return <PanelLoading title={title} /> }

  const sections = []
  let key = 0
  forEach(stats, (section, title) => {
    sections.push(<tr key={key++} className='bg-neutral-100 border-b border-neutral-200'><th className='text-left px-4 py-4' colSpan={2}>{title}</th></tr>)
    forEach(section.stats, function (stat) {
      sections.push(
        <tr key={key++} className='border-b border-neutral-200'>
          <td className='px-4 py-4'>
            <div className='headline-2xs text-black mb-1'>{stat.name}</div>
            <div className='paragraph-sm'>{stat.desc}</div>
          </td>
          <td className='bg-neutral-50 headline-2xs text-center text-black px-4 py-4'>
            {stat.value}
          </td>
        </tr>
      )
    })
  })

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent>
        <table>
          <tbody>
            {sections}
          </tbody>
        </table>
      </PanelContent>
    </Panel>
  )
}

Stats.displayName = 'Stats'
export default Stats
