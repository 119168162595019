import { isNumber } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import Pluralize from '../../../components/Pluralize'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEventShape from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEventShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventDaysBeforeDetail'] })

const EventDaysBeforeDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent?._id] })

  const { confirm, defaultValues, formControls, value } = useMemo(() => ({
    confirm: {
      description: `Updating days before to be greater may reschedule some broadcasts originated by this
                    campaign message into the past, causing them to send IMMEDIATELY. Are you sure?`,
      when: ({ scheduledBeforeDays }) => (scheduledBeforeDays > campaignEvent.scheduledBeforeDays)
    },
    defaultValues: {
      scheduledBeforeDays: campaignEvent.scheduledBeforeDays
    },
    formControls: [
      { label: '', name: 'scheduledBeforeDays', type: 'number', min: 0 }
    ],
    value: isNumber(campaignEvent.scheduledBeforeDays) ? <Pluralize count={campaignEvent.scheduledBeforeDays} singular='Day' includeCount /> : null
  }), [campaignEvent])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description={`Specify the number of days before the ${campaignEvent.campaignType === 'event' ? 'event' : 'end'} date to send this message.`}
      formControls={formControls}
      formDescription='Updating Days Before for this message'
      formTitle='Days Before'
      objectId={campaignEvent._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Days Before'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventDaysBeforeDetail.displayName = 'EventDaysBeforeDetail'
EventDaysBeforeDetail.propTypes = propTypes
EventDaysBeforeDetail.defaultProps = defaultProps

export default EventDaysBeforeDetail
