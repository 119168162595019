import * as React from "react";
import { memo } from "react";
const SvgEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.667 6.5c0-.46.373-.833.833-.833h15c.46 0 .833.373.833.833v1.896l-7.291 3.364a2.502 2.502 0 0 1-2.093 0L3.667 8.4V6.5ZM2 8.953v9.213a2.5 2.5 0 0 0 2.5 2.5h15a2.5 2.5 0 0 0 2.5-2.5V6.5A2.5 2.5 0 0 0 19.5 4h-15A2.5 2.5 0 0 0 2 6.5v2.453Zm1.667 1.283 6.584 3.037c1.105.51 2.386.51 3.49 0l6.592-3.041v7.934c0 .46-.373.834-.833.834h-15a.833.833 0 0 1-.833-.834v-7.93Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgEmail);
export default Memo;
