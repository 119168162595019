import PropType from 'prop-types'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import CancelChangeSubscriptionDialog from './CancelChangeSubscriptionDialog'
import ChangeSubscriptionScheduledBanner from './ChangeSubscriptionScheduledBanner'

const propTypes = {
  subscriptionDetails: PropType.shape({
    currentPlanFrequency: PropType.string.isRequired,
    currentPlanName: PropType.string.isRequired,
    cancelsAt: PropType.string
  })
}

const defaultProps = {
  subscriptionDetails: {
    cancelsAt: null,
    scheduledPlanFrequency: null,
    scheduledPlanName: null
  }
}

const log = logger({ enabled: false, tags: ['ChangeSubscriptionScheduled'] })

const ChangeSubscriptionScheduled = ({ subscriptionDetails }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const cancelDialogRef = useRef()
  const cancelButtonText = subscriptionDetails?.cancelsAt ? 'Keep My Subscription' : 'Cancel Subscription Change'

  return (
    <>
      <ChangeSubscriptionScheduledBanner subscriptionDetails={subscriptionDetails} timezone={currentUser.timezone} />
      <p className='mb-5'>
        If you prefer to stay on the
        {' '}
        <strong className='whitespace-nowrap'>
          {subscriptionDetails.currentPlanName}
          {' '}
          {subscriptionDetails.currentPlanFrequency}
        </strong>
        {' '}
        plan or want to choose a different subscription plan, click the
        {' '}
        {cancelButtonText}
        {' '}
        button.
      </p>
      <div className='flex flex-row flex-wrap gap-4 items-center'>
        <Button
          className='grow md:flex-none'
          size='sm'
          type='submit'
          onClick={() => cancelDialogRef.current.open()}
        >
          {cancelButtonText}
        </Button>
        <div className='grow text-primary label-sm-medium text-right'>
          <Link to='cancel-subscription'>
            Cancel Subscription
          </Link>
        </div>
      </div>
      <CancelChangeSubscriptionDialog ref={cancelDialogRef} subscriptionDetails={subscriptionDetails} />
    </>
  )
}

ChangeSubscriptionScheduled.displayName = 'ChangeSubscriptionScheduled'
ChangeSubscriptionScheduled.propTypes = propTypes
ChangeSubscriptionScheduled.defaultProps = defaultProps

export default ChangeSubscriptionScheduled
