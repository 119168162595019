import PropType from 'prop-types'

import Banner from '../../components/banners/Banner'
import useLogger from '../../hooks/useLogger'
import { formatNumber, formatPhone } from '../../lib/formatters'
import logger from '../../lib/logger'
import { movingToManagedVoip, movingToNonRollover, movingToPayAsYouGo, movingToVerifiedCell } from '../../lib/plans'

const propTypes = {
  currentPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    phoneNumberType: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    rolloverCredits: PropType.bool.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  reanchorImmediately: PropType.bool,
  selectedPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    phoneNumberType: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    rolloverCredits: PropType.bool.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  user: PropType.shape({
    messageCredits: PropType.number.isRequired,
    phoneNumber: PropType.string
  })
}

const defaultProps = {
  currentPlan: null,
  reanchorImmediately: false,
  selectedPlan: null,
  user: null
}

const log = logger({ enabled: false, tags: ['ChangeSubscriptionWarnings'] })

const ChangeSubscriptionWarnings = ({ currentPlan, selectedPlan, user, reanchorImmediately }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      {!movingToManagedVoip(currentPlan, selectedPlan) && movingToPayAsYouGo(currentPlan, selectedPlan) && user.messageCredits > currentPlan.credits
        ? (
          <Banner className='mb-4' title='Warning' type='warning'>
            You have selected to change to the Pay As You Go plan. You currently have
            {' '}
            {formatNumber(user.messageCredits)}
            {' '}
            credits. When changing to this plan your accumulated credits will be lost. You will be allowed to keep
            {' '}
            {formatNumber(currentPlan.credits)}
            .
            {' '}
            <strong>
              This plan change will take effect
              {' '}
              {reanchorImmediately ? 'immediately' : 'at the end of your current billing cycle'}
              .
            </strong>
          </Banner>
          )
        : null}
      {!movingToVerifiedCell(currentPlan, selectedPlan) && movingToNonRollover(currentPlan, selectedPlan)
        ? (
          <Banner className='mb-4' title='Warning' type='warning'>
            You have selected to downgrade to a plan with
            {' '}
            <strong>non-rollover</strong>
            {' '}
            credits. You will lose
            {' '}
            <strong>all</strong>
            {' '}
            remaining message credits
            {' '}
            {reanchorImmediately
              ? 'immediately'
              : 'when your new plan takes effect at renewal'}
            .
          </Banner>
          )
        : null}
      {movingToVerifiedCell(currentPlan, selectedPlan)
        ? (
          <>
            <Banner className='mb-4' title='Warning' type='warning'>
              You have selected to downgrade to a plan that does not utilize message credits. This plan change will take effect
              {' '}
              <strong>immediately</strong>
              . You will lose your remaining
              {' '}
              <strong>
                {formatNumber(user.messageCredits)}
              </strong>
              {' '}
              unused message credits.
            </Banner>
            <Banner className='mb-4' title='Warning' type='warning'>
              You have selected a plan that does not include a personal Project Broadcast phone number.
              You will
              {' '}
              <strong>lose</strong>
              {' '}
              your Project Broadcast phone number
              {' '}
              <strong>
                <span id='pbNumber'>
                  {formatPhone(user.phoneNumber)}
                </span>
              </strong>
              {' '}
              and will no longer be
              able to send/receive text messages and utilize voice features.
            </Banner>
          </>
          )
        : null}
    </>
  )
}

ChangeSubscriptionWarnings.displayName = 'ChangeSubscriptionWarnings'
ChangeSubscriptionWarnings.propTypes = propTypes
ChangeSubscriptionWarnings.defaultProps = defaultProps

export default ChangeSubscriptionWarnings
