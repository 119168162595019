import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useParams } from 'react-router-dom'

import { firstImageUrl } from '../../../helpers/messageMedia'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useTimeZone from '../../../hooks/useTimeZone'
import { copyToClipboardViaPromise } from '../../../lib/copyToClipboard'
import { formatExtendedDateAndTime } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import { after as chatMessagesAfterService } from '../../../services/chat'
import { after as spaceMessagesAfterService } from '../../../services/space'
import useStore from '../../../store'
import { error, notify } from '../../banners/Banner'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['CopyFromAction'] })

const CopyFromAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [message._id] })

  const { userTimeZone } = useTimeZone()
  const params = useParams() // should hold either `spaceId` or `chatId`
  const chat = useStore((state) => state.current.chat)
  const space = useStore((state) => state.current.space)
  const isSpace = !!message.space

  const { call: chatMessagesAfterCall } = useService(chatMessagesAfterService)
  const { call: spaceMessagesAfterCall } = useService(spaceMessagesAfterService)

  const formatReplyForClipboard = useCallback((reply) => {
    if (!reply.ok) { throw new Error('Failed to fetch thread for partial copy') }

    let clipboard = `Partial conversation from ${isSpace ? `space ${space.name}` : `${chat.formattedName} (${chat.phoneNumber})`}`
    clipboard += '\n\n'

    const messages = reply.models
    messages.push(message)
    messages.reverse()
    messages.forEach((message) => {
      let sentAt = formatExtendedDateAndTime(message.state === 'queued' ? message.runAt : message.time, userTimeZone)
      if (message.state === 'queued') { sentAt += ' (scheduled)' }
      const sentBy = message.direction === 'out' ? 'You' : (isSpace ? message.fromFormattedName : chat.formattedName)
      clipboard += `[${sentAt}] ${sentBy}:\n`
      if (message.type === 'email') {
        clipboard += '[sent an email]\n\n'
      } else if (message.type === 'rvm') {
        clipboard += '[sent a voice drop]\n\n'
      } else {
        const imageUrl = firstImageUrl(message)
        if (message.type === 'mms' && imageUrl) {
          clipboard += '[sent an image]\n\n'
        }
        if (message.text) {
          clipboard += `${message.text}\n\n`
        }
      }
    })

    return clipboard
  }, [chat, isSpace, message, space, userTimeZone])

  const copyToClipboardPromiseFn = useCallback(() => {
    return new Promise((resolve, reject) => {
      const serviceCall = message.space ? spaceMessagesAfterCall : chatMessagesAfterCall
      const callOptions = { messageId: message._id }
      if (message.space) {
        callOptions.spaceId = params.spaceId
      } else {
        callOptions.contactId = params.chatId
      }
      serviceCall(callOptions).then(formatReplyForClipboard).then((text) => resolve(text)).catch(reject)
    })
  }, [chatMessagesAfterCall, formatReplyForClipboard, message._id, message.space, params.chatId, params.spaceId, spaceMessagesAfterCall])

  useImperativeHandle(ref, () => ({
    activate () {
      copyToClipboardViaPromise(copyToClipboardPromiseFn).then(() => {
        notify('Partial thread copied to your clipboard')
      }, () => {
        error('Unable to copy to your clipboard')
      })
    }
  }), [copyToClipboardPromiseFn])

  return null
})

CopyFromAction.displayName = 'CopyFromAction'
CopyFromAction.propTypes = propTypes
CopyFromAction.defaultProps = defaultProps

export default CopyFromAction
