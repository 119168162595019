import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import BulkEditActions from '../../../components/tags/actions/BulkEditActions'
import CreateAction from '../../../components/tags/actions/CreateAction'
import TagList from '../../../components/tags/TagList'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const TagListHeaderMoreMenu = forwardRef(({ listRef }, ref) => {
  const bulkEditRef = useRef()
  const createActionRef = useRef()

  const handleOpenBulkEdit = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])
  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])
  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleRefreshSelected} />
      <PanelHeaderButton icon={<Plus />} title='Create New Tag' onClick={handleCreateSelected} />
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuHeader label='Manage Tags' />
        <ActionMenuDivider />
        <ActionMenuItem label='Edit Tags' onClick={handleOpenBulkEdit} />
      </ActionMenu>
      <BulkEditDialog
        ref={bulkEditRef}
        actions={BulkEditActions}
        list={TagList}
        search
      />
      <CreateAction ref={createActionRef} />
    </>
  )
})

TagListHeaderMoreMenu.displayName = 'TagListHeaderMoreMenu'
TagListHeaderMoreMenu.propTypes = propTypes
TagListHeaderMoreMenu.defaultProps = defaultProps

export default TagListHeaderMoreMenu
