import * as React from "react";
import { memo } from "react";
const SvgCamera = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 22H3c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2h3l2-3h8l2 3h3c1.1 0 2 .9 2 2v13c0 1.1-.9 2-2 2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 18a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM19 8.99V9"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.168 1.445A1 1 0 0 1 8 1h8a1 1 0 0 1 .832.445L18.535 4H21c1.652 0 3 1.348 3 3v13c0 1.652-1.348 3-3 3H3c-1.652 0-3-1.348-3-3V7c0-1.652 1.348-3 3-3h2.465l1.703-2.555ZM8.535 3 6.832 5.555A1 1 0 0 1 6 6H3c-.548 0-1 .452-1 1v13c0 .548.452 1 1 1h18c.548 0 1-.452 1-1V7c0-.548-.452-1-1-1h-3a1 1 0 0 1-.832-.445L15.465 3h-6.93ZM12 10a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-5.5 3.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0ZM20 8.99a1 1 0 1 0-2 0V9a1 1 0 1 0 2 0v-.01Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCamera);
export default Memo;
