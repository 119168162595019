import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import imageCompression from 'browser-image-compression'
import { pick } from 'lodash'
import { useCallback } from 'react'

import { warning } from '../components/banners/Banner'
import { useApp } from '../contexts/AppContext'
import logger from '../lib/logger'
import useStore from '../store'

import useFilePicker from './useFilePicker'

const log = logger({ enabled: true, tags: ['useImagePicker'] })

const useImagePicker = ({ limitMB } = { limitMB: 0.9 }) => {
  const { show: showImageFilePicker } = useFilePicker({ typeFilter: 'image/*', limitMB: 50 })
  const nativeSharedImage = useStore((state) => state.nativeSharedImage)
  const { isNative } = useApp()

  const prepareImage = useCallback(async (imageFileIn, mimeTypeIn, sizeIn) => {
    if (mimeTypeIn === 'image/gif' && sizeIn > limitMB * 1024 * 1024) {
      warning('Invalid Selection', `GIF images must be less than ${limitMB}MB`)
      return null
    }

    try {
      const { name } = imageFileIn
      let imageFile, mimeType, size

      if (mimeTypeIn === 'image/gif') {
        imageFile = imageFileIn
        mimeType = mimeTypeIn
        size = sizeIn
      } else {
        imageFile = await imageCompression(imageFileIn, {
          maxSizeMB: limitMB,
          maxWidthOrHeight: 2000,
          fileType: 'image/jpeg' // always output jpeg w/ default quality (png compression can take longer w/ little value added)
        })
        mimeType = 'image/jpeg'
        size = imageFile.size
      }

      const imageDataUrl = await imageCompression.getDataUrlFromFile(imageFile)
      const base64 = imageDataUrl.split(';base64,')[1]
      const uploadData = `PB-CAP-FILE;${encodeURIComponent(name)};${encodeURIComponent(mimeType)};${base64}`

      return { name, type: mimeType, size, uploadData, webPath: imageDataUrl }
    } catch (ex) {
      warning('Selection Failed', 'Selected image may be too large. Please try again.')
      return null
    }
  }, [limitMB])

  const showUsingCamera = useCallback(async (source) => {
    try {
      const image = await Camera.getPhoto({
        width: 2000,
        height: 2000,
        resultType: CameraResultType.DataUrl,
        source,
        webUseInput: true // don't try to use the PWA Elements on web
      })

      const imageFile = await imageCompression.getFilefromDataUrl(image.dataUrl, `image${new Date().getTime()}.${image.format}`)

      return prepareImage(imageFile, `image/${image.format}`, imageFile.size)
    } catch (ex) {
      return null
    }
  }, [prepareImage])

  const showUsingFiles = useCallback(async () => {
    try {
      const { mimeType, name, size, webPath: dataUrl } = await showImageFilePicker()

      const imageFile = await imageCompression.getFilefromDataUrl(dataUrl, name)

      return prepareImage(imageFile, mimeType, size)
    } catch (ex) {
      return null
    }
  }, [prepareImage, showImageFilePicker])

  const showUsingNativeSharedImage = useCallback(async () => {
    try {
      const { imageData, mimeType, size } = nativeSharedImage
      const dataUrl = `data:${mimeType};base64,${imageData}`

      const imageFile = await imageCompression.getFilefromDataUrl(dataUrl, `image${new Date().getTime()}`)

      return prepareImage(imageFile, mimeType, size)
    } catch (ex) {
      return null
    }
  }, [nativeSharedImage, prepareImage])

  const show = useCallback(async () => {
    if (isNative) {
      const sourceOptions = [
        {
          title: 'From Files',
          action: async () => showUsingFiles()
        },
        {
          title: 'From Photos',
          action: async () => showUsingCamera(CameraSource.Photos)
        },
        {
          title: 'Take Picture',
          action: async () => showUsingCamera(CameraSource.Camera)
        },
        {
          title: 'Cancel',
          action: async () => null,
          style: ActionSheetButtonStyle.Cancel
        }
      ]

      const hasRecentNativeSharedImage = nativeSharedImage && (new Date() - nativeSharedImage.sharedAt) < 90 * 1000
      if (hasRecentNativeSharedImage) {
        sourceOptions.unshift({
          title: 'From Recently Shared Image',
          action: async () => showUsingNativeSharedImage()
        })
      }

      const { index: selectedSourceIdx } = await ActionSheet.showActions({
        title: 'Select Image',
        options: sourceOptions.map((option) => pick(option, 'title', 'style'))
      })
      return sourceOptions[selectedSourceIdx]?.action()
    } else {
      return showUsingCamera(CameraSource.Prompt)
    }
  }, [isNative, nativeSharedImage, showUsingCamera, showUsingFiles, showUsingNativeSharedImage])

  const fromFile = useCallback(async (file) => {
    if (!file) { return null }

    if (!file?.type.startsWith('image/')) {
      warning('File must be an image')
      return null
    }

    return prepareImage(file, file.type, file.size)
  }, [prepareImage])

  return { show, fromFile }
}

export default useImagePicker
