import { isDate } from 'lodash'
import PropType from 'prop-types'
import { memo } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import ThreadItemDate from './ThreadItemDate'

const propTypes = {
  id: PropType.string.isRequired,
  index: PropType.number.isRequired,
  item: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  listRef: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ThreadItemContent: PropType.elementType.isRequired // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ThreadItem'] })

const ThreadItem = memo(({
  id,
  index,
  item,
  listRef,
  ThreadItemContent
}) => {
  useLogger({ log, lifecycle: true, tags: [id] })

  const isItemDate = isDate(item)

  const threadItemProps = {
    className: 'flex last:mt-4',
    'data-list-index': index,
    id
  }

  return (
    <div {...threadItemProps}>
      {isItemDate ? <ThreadItemDate date={item} /> : <ThreadItemContent item={item} listRef={listRef} />}
    </div>
  )
})

ThreadItem.displayName = 'ThreadItem'
ThreadItem.propTypes = propTypes
ThreadItem.defaultProps = defaultProps

export default ThreadItem
