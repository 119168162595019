import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { useWizard } from 'react-use-wizard'

import Banner from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import ExternalLink from '../../../components/links/ExternalLink'
import SupportLink from '../../../components/links/SupportLink'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { entityTypeOptions } from '../../../lib/tcr'

const propTypes = {
  panelDialogRef: PropType.shape({
    current: PropType.shape({
      close: PropType.func.isRequired
    })
  }).isRequired,
  onSubmit: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BusinessType'] })

const BusinessType = ({ panelDialogRef, onSubmit }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formRef = useRef()
  const { nextStep } = useWizard()

  const handleSubmit = useCallback(() => {
    const values = formRef.current.getValues()
    onSubmit(values)
    nextStep()
  }, [nextStep, onSubmit])

  const formControls = [
    {
      name: 'entityType',
      label: 'Select Business Type',
      type: 'select',
      options: entityTypeOptions,
      required: true
    }
  ]

  const handleCloseClick = useCallback(() => panelDialogRef.current.close(), [panelDialogRef])
  const headerCloseButton = <PanelHeaderButton icon='close' onClick={handleCloseClick} />

  return (
    <>
      <PanelHeader
        start={headerCloseButton}
        title='TCR Registration'
      />
      <PanelContent className='p-5'>
        <div className='w-full md:max-w-md mx-auto'>
          <Banner className='mb-5' title='Before You Begin: See our Campaign Registry Frequently Asked Questions' type='primary'>
            <ExternalLink className='text-action' href='https://pb-site.com/p/GGprrn6CNKH' label='Video Walk-Throughs and Resources' />
          </Banner>
          <p className='mb-5'>
            The mobile carriers have mandated that all senders must be registered with The Campaign Registry&trade; (TCR) which now acts as a registration and reputation authority for businesses sending messages across all mobile carriers.
          </p>
          <Banner className='mb-5' type='error'>
            Your registration must be submitted to The Campaign Registry&trade; and then approved by the major carriers before you can start sending text messages.
          </Banner>
          <p className='mb-5'>
            You have the two options below when registering your traffic.
          </p>
          <p className='mb-5'>
            <strong className='block'>Sole Proprietor:</strong>
            {' '}
            This option is available to individuals and small business owners without an EIN (US) or Business Number (Canada) sending commercial messages.
          </p>
          <p className='mb-5'>
            <strong className='block'>Private Company:</strong>
            {' '}
            Your organization has an EIN (US) or Business Number (Canada). Please be aware that registering as a Private Company carries rigorous and detailed scrutiny, but provides higher throughput.
          </p>
          <Banner className='mb-5' type='warning'>
            <strong>Political Registrations</strong>
            <br />
            If you are a part of an organized effort to influence decision making of specific group, please email
            {' '}
            <SupportLink />
            {' '}
            for registration assistance.  Most political registrations will require external vetting by
            {' '}
            <ExternalLink href='https://www.campaignverify.org' label='Campaign Verify' />
            {' '}
            before you can begin the registration process.
          </Banner>
          <Form
            ref={formRef}
            defaultValues={{}}
            formControls={formControls}
            id='tcr-BusinessType'
            onSubmit={handleSubmit}
          />
          <div className='flex flex-row gap-4 items-center'>
            <Button form='tcr-BusinessType' size='sm' type='submit'>Continue</Button>
          </div>
        </div>
      </PanelContent>
    </>
  )
}

BusinessType.displayName = 'BusinessType'
BusinessType.propTypes = propTypes
BusinessType.defaultProps = defaultProps

export default BusinessType
