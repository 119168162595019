import PropType from 'prop-types'
import { useCallback, useContext } from 'react'

import Panel from '../../../components/panels/Panel'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactImportResultsShape from '../../../prop-types/shapes/contactImportResults'

import { ImportContext } from './ImportContext'
import ImportResultItem from './ImportResultItem'

const propTypes = {
  importResults: PropType.shape(contactImportResultsShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ImportResults'] })

const ImportResults = ({ importResults }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { title, subtitle, handleBackButtonClick } = useContext(ImportContext)

  const renderResults = useCallback((resultType) => {
    return importResults[resultType].map((resultItem) => (
      <ImportResultItem key={resultItem.phoneNumber} resultItem={resultItem} resultType={resultType} />
    ))
  }, [importResults])

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderButton icon='close' onClick={handleBackButtonClick} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent className='p-4'>
        <div>
          Number of contacts created:
          {'  '}
          <strong>{importResults.created.length}</strong>
        </div>
        <div>
          Number of contacts updated:
          {'  '}
          <strong>{importResults.exists.length}</strong>
        </div>
        <div>
          Existing contacts skipped:
          {'  '}
          <strong>{importResults.skipped.length}</strong>
        </div>
        <div>
          Number of errors:
          {'  '}
          <strong>{importResults.failed.length}</strong>
        </div>
        <div className='mt-4 headline-2xs'>Results Detail</div>
        <div className='paragraph-sm mb-3'>
          Below are the detailed results for your import. Project Broadcast will import your records and
          either update existing contacts or create new ones. An error indicates the record was ignored
          and will need to either be fixed in your file or manually created/updated.
        </div>
        <table>
          <tbody>
            {renderResults('failed')}
            {renderResults('exists')}
            {renderResults('created')}
          </tbody>
        </table>
      </PanelContent>
    </Panel>
  )
}

ImportResults.displayName = 'ImportResults'
ImportResults.propTypes = propTypes
ImportResults.defaultProps = defaultProps

export default ImportResults
