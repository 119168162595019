import PropType from 'prop-types'

import managedAccountFromJSON from '../models/managedAccount'
import userFromJSON from '../models/user'

import pb from './pb'
import { searchPropTypes } from './user-managedAccount.prop-types'

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: managedAccountFromJSON,
      key: 'users',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'managedAccount#search')
  return pb.setupPost('users/managedAccounts', options)
}

export function isManager (background = true) {
  return pb.setupGet('users/isManager', { background })
}

export function manage (userId) {
  return pb.setupPost(`users/${userId}/pose`, {
    model: { conversion: userFromJSON }
  })
}

export function remove (userId) {
  return pb.setupPost('users/managedAccounts/remove', { body: { userId } })
}
