import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelDialog from '../dialog/PanelDialog'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

const propTypes = {
  actions: PropType.elementType.isRequired,
  list: PropType.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropType.array,
  subtitle: PropType.string,
  title: PropType.string,
  trigger: PropType.element,
  type: PropType.string
}

const defaultProps = {
  selectedItems: [],
  subtitle: null,
  title: 'Bulk Edit',
  trigger: null,
  type: 'bulk'
}

const log = logger({ enabled: false, tags: ['BulkEditDialog'] })

const BulkEditDialog = forwardRef(({
  actions,
  list: PassedInList,
  selectedItems: initialSelectedItems,
  title,
  trigger,
  type,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  const panelDialogRef = useRef()
  const listRef = useRef()

  useLogger({ log, lifecycle: false, tags: [] })

  const [defaultSearch, setDefaultSearch] = useState()

  useImperativeHandle(ref, () => ({
    open (defaultSearch) {
      panelDialogRef.current.open()
      if (defaultSearch) {
        setDefaultSearch(defaultSearch)
      }
    },

    close () {
      panelDialogRef.current.close()
    }
  }))

  const handleClose = useCallback(() => ref.current.close(), [ref])

  useBus('closeBulkEditDialog', handleClose)

  return (
    <PanelDialog
      ref={panelDialogRef}
      ariaLabel={title}
      trigger={trigger}
    >
      <PanelHeader
        start={<PanelHeaderButton icon='cancel' onClick={handleClose} />}
        title={title}
      />
      <PanelContent>
        <PassedInList
          ref={listRef}
          bulkActions={actions}
          defaultSearch={defaultSearch}
          selectedItems={initialSelectedItems}
          type={type}
          multiple
          {...rest}
        />
      </PanelContent>
    </PanelDialog>
  )
})

BulkEditDialog.displayName = 'BulkEditDialog'
BulkEditDialog.propTypes = propTypes
BulkEditDialog.defaultProps = defaultProps

export default BulkEditDialog
