import PropType from 'prop-types'
// import { useEffect } from 'react'
import { /* useLocation, useNavigate, */ useOutlet } from 'react-router-dom'

import { useApp } from '../../contexts/AppContext'

// import useStore from '../../store'

import PanelLayoutPanels from './PanelLayoutPanels'

const propTypes = {
  panel1: PropType.node.isRequired,
  includeHeaderBackground: PropType.bool,
  menu: PropType.node,
  panel2: PropType.node
}
const defaultProps = {
  includeHeaderBackground: true,
  menu: null,
  panel2: null
}

const PanelLayout = ({ menu, panel1, panel2, includeHeaderBackground }) => {
  const { isNative, isRoomForTwoPanels } = useApp()

  // kind of a hacky "stack" navigation
  // if the current path ('/chats') matches a top-level stack ('chats')
  // then we'll check to see if we have a history within that stack to
  // actually navigate to instead (via replacing the current history entry)
  // this means to get back to the chat list (/chats) on mobile/small screen
  // we'd have to pop off items from this stack as we "go back"
  // const location = useLocation()
  // const stacks = useStore((state) => state.stacks)
  // const navigate = useNavigate()
  // useEffect(() => {
  //   const stackKey = location.pathname.split('/')[1]
  //   const stack = stacks[stackKey]
  //   if (location.pathname === `/${stackKey}` && stack?.length) {
  //     const prev = stack?.at(-1)
  //     if (prev.pathname !== location.pathname) {
  //       const historyItem = stack.at(-1)
  //       navigate(historyItem.pathname, { replace: true })
  //     }
  //   }
  // }, [location, stacks, navigate])
  const outlet = useOutlet()

  // panel 2 becomes the primary when there is a child route
  const panelPriority = outlet ? 2 : 1

  return (
    <>
      {menu || null}
      <PanelLayoutPanels
        includeHeaderBackground={includeHeaderBackground}
        isNative={isNative}
        panel1={panel1}
        panel2={(outlet || panel2)}
        panelPriority={panelPriority}
        roomForTwoPanels={isRoomForTwoPanels}
      />
    </>
  )
}

PanelLayout.displayName = 'PanelLayout'
PanelLayout.propTypes = propTypes
PanelLayout.defaultProps = defaultProps

export default PanelLayout
