import { chain, forEach, omit, pick, startCase } from 'lodash'
import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import DetailSection from '../../components/detail-sections/DetailSection'
import FormDetailSection from '../../components/detail-sections/FormDetailSection'
import Checkbox from '../../components/forms/controls/Checkbox'
import InputLabel from '../../components/forms/InputLabel'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { getFieldDisplaySortPosition } from '../../helpers/configurableForm'
import useCurrentConfigurableForm from '../../hooks/useCurrentConfigurableForm'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateConfigurableFormService } from '../../services/configurableForm'

import DetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import KeywordDetail from './detail/KeywordDetail'

const log = logger({ enabled: true, tags: ['TrackableLinksDetail'] })

const ConfigurableFormsDetail = () => {
  const { configurableFormId, loading, configurableForm, setConfigurableForm } = useCurrentConfigurableForm()

  useLogger({ log, lifecycle: false, tags: [configurableFormId] })

  const handleReplyOkay = useCallback((updateReply) => {
    notify('Configurable Form updated successfully.')
    setConfigurableForm(updateReply.model)
    bus.emit('configurableFormUpdated', updateReply.model)
  }, [setConfigurableForm])
  const { call: updateConfigurableForm } = useService(updateConfigurableFormService, { onReplyOk: handleReplyOkay })

  const handleDetailsUpdate = useCallback((updateReply) => {
    setConfigurableForm(updateReply.model)
    bus.emit('configurableFormUpdated', updateReply.model)
  }, [setConfigurableForm])

  const handleFieldChange = useCallback((e) => {
    const field = {}
    if (e.target.name.split('Enabled').length > 1) {
      field[e.target.name.split('Enabled')[0]] = { enabled: e.target.checked }
    } else {
      field[e.target.name.split('Required')[0]] = { required: e.target.checked }
    }
    updateConfigurableForm(configurableFormId, {
      field
    })
  }, [updateConfigurableForm, configurableFormId])

  if (loading || !configurableForm) { return <PanelLoading /> }

  const controls = []
  const customFields = configurableForm.fields.customFields
  const otherFields = chain(omit(configurableForm.fields, 'customFields'))
    .map((v, k) => ({ field: v, name: k }))
    .sortBy(({ name }) => getFieldDisplaySortPosition(name))
    .value()

  forEach(otherFields, ({ field, name }) => {
    controls.push(
      <div key={name}>
        <InputLabel text={(name === 'birthDay') ? 'Birthday' : startCase(name)} />
        <div className='grid grid-cols-2 bg-neutral-100 p-4'>
          <Checkbox
            checked={field.enabled}
            label='Enabled'
            labelAlignment='right'
            name={`${name}Enabled`}
            onChange={handleFieldChange}
          />
          <Checkbox
            checked={field.required}
            label='Required'
            labelAlignment='right'
            name={`${name}Required`}
            onChange={handleFieldChange}
          />
        </div>
      </div>
    )
  })

  forEach(customFields, (field, i) => {
    controls.push(
      <div key={field._id}>
        <InputLabel text={`Custom ${i + 1}: ${field.name}`} />
        <div className='grid grid-cols-2 bg-neutral-100 p-4'>
          <Checkbox
            checked={field.enabled}
            label='Enabled'
            labelAlignment='right'
            name={`${field._id}Enabled`}
            onChange={handleFieldChange}
          />
          <Checkbox
            checked={field.required}
            label='Required'
            labelAlignment='right'
            name={`${field._id}Required`}
            onChange={handleFieldChange}
          />
        </div>
      </div>
    )
  })

  return (
    <Panel>
      <PanelHeader
        end={<DetailHeaderMoreMenu configurableForm={configurableForm} />}
        start={<PanelHeaderBackButton />}
        title={configurableForm?.name}
      />
      <PanelContent className='p-5'>
        <div>
          <FormDetailSection
            defaultValues={pick(configurableForm, ['name'])}
            description='Configurable Forms allow you to decide which Project Broadcast fields to show to your contacts and whether or not a keyword should be applied once they have filled out your form.'
            formControls={[{ autoComplete: 'off', label: 'Name', name: 'name', type: 'text', required: true }]}
            formDescription='Updating configurable form name'
            formTitle='Configurable form name'
            objectId={configurableForm._id}
            service={updateConfigurableFormService}
            title='Name'
            showEditIcon
            onChange={handleDetailsUpdate}
          >
            {configurableForm.name}
          </FormDetailSection>
          <FormDetailSection
            defaultValues={pick(configurableForm, ['description'])}
            formControls={[{ label: 'Description', name: 'description', type: 'textarea' }]}
            formDescription='Updating configurable form description'
            formTitle='Configurable form description'
            initialEditButtonText='Set Description'
            objectId={configurableForm._id}
            service={updateConfigurableFormService}
            showClearIcon={!!configurableForm.description}
            showEditIcon={!!configurableForm.description}
            showInitialEditButton={!configurableForm.description}
            title='Description'
            onChange={handleDetailsUpdate}
          >
            {configurableForm.description}
          </FormDetailSection>
          <KeywordDetail configurableForm={configurableForm} service={updateConfigurableFormService} onChange={handleDetailsUpdate} />
          <FormDetailSection
            defaultValues={pick(configurableForm, ['redirectUrl'])}
            description='The redirect url allows you to direct a contact to an external website after submitting this configurable form.'
            formControls={[{ label: 'Redirect URL', name: 'redirectUrl', type: 'url' }]}
            formDescription='Updating configurable form redirect url'
            formTitle='Configurable form redirect url'
            initialEditButtonText='Set Redirect URL'
            objectId={configurableForm._id}
            service={updateConfigurableFormService}
            showClearIcon={!!configurableForm.redirectUrl}
            showEditIcon={!!configurableForm.redirectUrl}
            showInitialEditButton={!configurableForm.redirectUrl}
            title='Redirect URL'
            onChange={handleDetailsUpdate}
          >
            {configurableForm.redirectUrl}
          </FormDetailSection>
          <DetailSection
            description='Unique ID for your configurable form.'
            title='Configurable Form ID'
          >
            {configurableForm.slug}
          </DetailSection>
          <div className='pt-3 pb-3'>
            Below are the fields you can enable for your configurable form. In addition to enabling a field, you can also mark the field as required.
          </div>
          <form className='grid grid-cols-1 gap-y-6'>
            {controls}
          </form>
        </div>
      </PanelContent>
    </Panel>
  )
}

ConfigurableFormsDetail.displayName = 'ConfigurableFormsDetail'

export default ConfigurableFormsDetail
