import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/contact-campaign'
import CampaignList from '../campaigns/CampaignList'

const propTypes = {
  contactId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContactCampaignList'] })

const ContactCampaignList = forwardRef(({ contactId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contactId] })

  return (
    <CampaignList
      ref={ref}
      queryOverrides={{ contactId }}
      searchService={searchService}
      {...rest}
    />
  )
})

ContactCampaignList.displayName = 'ContactCampaignList'
ContactCampaignList.propTypes = propTypes
ContactCampaignList.defaultProps = defaultProps

export default ContactCampaignList
