import * as React from 'react'
import { memo } from 'react'
const SvgLandingPage = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#CFFBFE" rx={4} />
        <g clipPath="url(#Landing_Page_svg__a)">
            <path
                fill="#0891B3"
                fillRule="evenodd"
                d="M5.455 4.727a.732.732 0 0 0-.728.728V9.09h14.546V5.455a.732.732 0 0 0-.728-.728H5.455Zm-2.182.728V20c0 .402.325.727.727.727h16a.727.727 0 0 0 .727-.727V5.455c0-1.202-.98-2.182-2.182-2.182H5.455c-1.202 0-2.182.98-2.182 2.182Zm16 13.818v-8.727H4.727v8.727h14.546ZM7.636 6.902a.727.727 0 0 0-1.454 0v.007a.727.727 0 0 0 1.454 0v-.007Zm2.182-.727c.402 0 .727.325.727.727v.007a.727.727 0 1 1-1.454 0v-.007c0-.402.325-.727.727-.727Zm3.636.727a.727.727 0 1 0-1.454 0v.007a.727.727 0 0 0 1.454 0v-.007Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="Landing_Page_svg__a">
                <path fill="#fff" d="M3.273 3.273h17.454v17.454H3.273z" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgLandingPage)
export default Memo
