import { omit } from 'lodash'
import PropType from 'prop-types'

import campaignEventFromJSON from '../models/campaignEvent'
import contactFromJSON from '../models/contact'

import { contactSearchPropTypes, createPropTypes, searchPropTypes, updatePropTypes } from './campaign-event.prop-types'
import messageFieldsNormalizer from './messageFieldsNormalizer'
import pb from './pb'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'campaign-event#search')
  const { campaignId } = data
  return pb.setupGet(`campaigns/${campaignId}/events`, {
    background,
    model: {
      conversion: campaignEventFromJSON,
      key: 'events',
      multiple: true
    }
  })
}

export function get (campaignId, campaignEventId, background = false) {
  return pb.setupGet(`campaigns/${campaignId}/events/${campaignEventId}`, {
    background,
    model: {
      conversion: campaignEventFromJSON,
      key: 'event'
    }
  })
}

export function create (campaignId, data) {
  const body = messageFieldsNormalizer(data)
  PropType.checkPropTypes(createPropTypes, data, 'prop', 'campaign-event#create')
  return pb.setupPost(`campaigns/${campaignId}/events`, {
    body,
    model: {
      conversion: campaignEventFromJSON,
      key: 'event'
    }
  })
}

export function update (campaignId, campaignEventId, data) {
  data = messageFieldsNormalizer(data)
  PropType.checkPropTypes(updatePropTypes, data, 'prop', 'campaign-event#update')
  const body = omit(data, 'campaignId', 'campaignEventId')
  return pb.setupPut(`campaigns/${campaignId}/events/${campaignEventId}`, {
    body,
    model: {
      conversion: campaignEventFromJSON,
      key: 'event'
    }
  })
}

export function del (campaignId, campaignEventId) {
  return pb.setupDelete(`campaigns/${campaignId}/events/${campaignEventId}`)
}

export function searchContacts (data, background = false) {
  PropType.checkPropTypes(contactSearchPropTypes, data, 'prop', 'campaign-event#searchContacts')
  const { campaignId, campaignEventId } = data
  const body = omit(data, 'campaignId', 'campaignEventId')
  return pb.setupPost(`campaigns/${campaignId}/events/${campaignEventId}/contacts/search`, {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contacts',
      multiple: true
    }
  })
}
