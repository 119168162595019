import * as React from "react";
import { memo } from "react";
const SvgWarning = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4.636A2.641 2.641 0 0 1 4.636 2h14.728A2.641 2.641 0 0 1 22 4.636v10.637a2.641 2.641 0 0 1-2.636 2.636h-3.61l-2.973 3.716a1 1 0 0 1-1.562 0l-2.972-3.716h-3.61A2.641 2.641 0 0 1 2 15.273V4.636ZM4.636 4A.641.641 0 0 0 4 4.636v10.637c0 .347.289.636.636.636h4.091a1 1 0 0 1 .781.375L12 19.4l2.492-3.115a1 1 0 0 1 .78-.375h4.092a.641.641 0 0 0 .636-.636V4.636A.641.641 0 0 0 19.364 4H4.636ZM12 6.09a1 1 0 0 1 1 1v3.274a1 1 0 1 1-2 0V7.09a1 1 0 0 1 1-1Zm1 6.72a1 1 0 1 0-2 0v.01a1 1 0 1 0 2 0v-.01Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgWarning);
export default Memo;
