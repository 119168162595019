import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import DisconnectAction from '../../../components/integrations/actions/DisconnectAction'
import ExternalLink from '../../../components/links/ExternalLink'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import integrationShape from '../../../prop-types/shapes/integration'

const propTypes = {
  item: PropType.shape(integrationShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['IntegrationListItemContent'] })

const IntegrationListItemContent = ({ item: integration }) => {
  useLogger({ log, lifecycle: false, tags: [integration?.key] })
  const disconnectActionRef = useRef()

  const handleDisconnectClick = useCallback(() => { disconnectActionRef.current.activate() }, [])
  const handleConnectClick = useCallback(() => { window.location = integration.externalConnectUrl }, [integration.externalConnectUrl])

  return (
    <>
      <img className='flex-none h-20 w-20' src={integration.icon} />
      <div className='flex-auto flex flex-col gap-1'>
        <div className='label-md-strong text-black'>
          {integration.name}
        </div>
        <div className='label-sm text-neutral-500'>
          {integration.description}
        </div>
        <ExternalLink
          className='label-xs-strong text-primary'
          href={integration.url}
          label='Learn More'
          showIcon
        />
      </div>
      <div className='flex-none self-center'>
        {(!!integration.showConnect &&
          <Button
            size='md'
            variant='text'
            onClick={handleConnectClick}
          >
            Connect
          </Button>
          )}
        {(!!integration.enabled &&
          <Button
            size='md'
            theme='danger'
            variant='text'
            onClick={handleDisconnectClick}
          >
            Disconnect
          </Button>
          )}
      </div>
      <DisconnectAction ref={disconnectActionRef} integration={integration} />
    </>
  )
}

IntegrationListItemContent.displayName = 'IntegrationListItemContent'
IntegrationListItemContent.propTypes = propTypes
IntegrationListItemContent.defaultProps = defaultProps

export default IntegrationListItemContent
