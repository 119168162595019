import proxy from './proxy'
import userFromJSON from './user'

export default function keywordFromJSON (json) {
  if (!json) { return null }

  const overrides = { }

  const model = proxy(json, overrides)
  if (json.user?._id) {
    model.user = userFromJSON(json.user)
  }
  return model
}
