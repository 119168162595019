import * as React from "react";
import { memo } from "react";
const SvgDialog = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 5.5C1 3.573 2.573 2 4.5 2h15C21.427 2 23 3.573 23 5.5v8.75c0 1.927-1.573 3.5-3.5 3.5h-3.514l-7.289 3.644A1 1 0 0 1 7.25 20.5v-2.75H4.5a3.507 3.507 0 0 1-3.5-3.5V5.5ZM4.5 4C3.677 4 3 4.677 3 5.5v8.75c0 .823.677 1.5 1.5 1.5h3.75a1 1 0 0 1 1 1v2.132l6.053-3.026a.999.999 0 0 1 .447-.106h3.75c.823 0 1.5-.677 1.5-1.5V5.5c0-.823-.677-1.5-1.5-1.5h-15Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgDialog);
export default Memo;
