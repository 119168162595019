import contactFromJSON from './contact'
import proxy from './proxy'

export default function attachmentDownloadFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get contact () {
      return contactFromJSON(json.contact)
    }
  }

  return proxy(json, overrides)
}
