import PropType from 'prop-types'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import CreateAction from '../../scheduled-broadcast/actions/CreateAction'

const propTypes = {
  message: PropType.shape(messageShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['NewBroadcastAction'] })

const NewBroadcastAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [message?._id] })

  const createActionRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () {
      createActionRef.current.activate()
    }
  }), [])

  return <CreateAction ref={createActionRef} message={message} />
})

NewBroadcastAction.displayName = 'NewBroadcastAction'
NewBroadcastAction.propTypes = propTypes
NewBroadcastAction.defaultProps = defaultProps

export default NewBroadcastAction
