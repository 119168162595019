import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'

import FormDetailSection from '../../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import milestoneShape from '../../../../prop-types/shapes/goalMilestone'

const propTypes = {
  milestone: PropType.shape(milestoneShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['RewardCreditsDetail'] })

const RewardCreditsDetail = ({ milestone, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [milestone?._id] })

  const { defaultValues, formControls, value } = useMemo(() => {
    return {
      defaultValues: {
        credits: milestone.reward.credits
      },
      formControls: [
        { label: '', name: 'credits', type: 'number', min: 0 }
      ],
      value: milestone.reward.credits
    }
  }, [milestone])

  const transformData = useCallback(({ credits }) => ({
    reward: { credits }
  }), [])

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Specify the number of reward credits for this milestone.'
      formControls={formControls}
      formDescription='Updating reward credits for this milestone'
      formTitle='Reward Credits'
      objectId={milestone._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Reward Credits'
      transformData={transformData}
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

RewardCreditsDetail.displayName = 'RewardCreditsDetail'
RewardCreditsDetail.propTypes = propTypes
RewardCreditsDetail.defaultProps = defaultProps

export default RewardCreditsDetail
