import { useCallback, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { setPassword as setUserPassword } from '../../../services/admin'

import validationSuite from './SetPassword.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SetPassword'] })

const SetPassword = () => {
  const formRef = useRef()
  const { userId, loading, user, setUser } = useSelectedUser()
  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback((updateReply) => {
    setUser(updateReply.model)
    notify('Password Updated')
  }, [setUser])
  const { call: setPassword } = useService(setUserPassword, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    setPassword({ newPassword: data.newPassword }, user._id)
    formRef.current.reset()
  }, [user, setPassword])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='setPasswordForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Set Password'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <Form
          ref={formRef}
          defaultValues={{
            newPassword: ''
          }}
          formControls={[
            {
              type: 'text',
              name: 'newPassword',
              label: 'New Password',
              hint: 'Password must be 8 or more characters with at least 1 letter and 1 number.',
              required: true
            }
          ]}
          id='setPasswordForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='setPasswordForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

SetPassword.displayName = 'SetPassword'
SetPassword.propTypes = propTypes
SetPassword.defaultProps = defaultProps

export default SetPassword
