import PropType from 'prop-types'
import { forwardRef, useCallback, useMemo, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ListDialog from '../dialog/ListDialog'
import PanelDialog from '../dialog/PanelDialog'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'
import TagList from '../tags/TagList'

import DisplayConditionItem from './DisplayConditionItem'

const propTypes = {
  onSelect: PropType.func
}

const defaultProps = {
  onSelect: undefined
}

const conditions = {
  withContact: {
    type: 'Contact',
    label: 'With a Contact',
    description: 'This will show the content when a contact is associated with the Landing Page view. This happens when you insert the Landing Page in a message.',
    before: '{{#if hasContact}}',
    after: '{{/if}}'
  },
  withoutContact: {
    type: 'Contact',
    label: 'Without a Contact',
    description: 'This will show the content when there is no associated contact with the Landing Page view. This happens when you share the public url directly.',
    before: '{{#unless hasContact}}',
    after: '{{/unless}}'
  },
  isTagged: {
    type: 'Contact',
    label: 'Contact is Tagged With',
    description: "This will show the content when there is a contact associated with the Landing Page view and that contact is tagged with the selected tag. You'll be able to pick the tag after selecting this option.",
    before: '{{#if hasContact}}{{#if (isTagged "VALUE")}}',
    after: '{{/if}}{{/if}}'
  },
  isNotTagged: {
    type: 'Contact',
    label: 'Contact is Not Tagged With',
    description: "This will show the content when there is a contact associated with the Landing Page view and that contact is tagged with the selected tag. You'll be able to pick the tag after selecting this option.",
    before: '{{#if hasContact}}{{#unless (isTagged "VALUE")}}',
    after: '{{/unless}}{{/if}}'
  }
}

const log = logger({ enabled: true, tags: ['DisplayConditionsDialog'] })

const DisplayConditionsDialog = forwardRef(({ onSelect, ...dialogProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const conditionIdRef = useRef()
  const tagListDialogRef = useRef()

  const handleCancel = useCallback(() => { ref.current.close() }, [ref])
  const handleSelection = useCallback((id) => {
    if (['isTagged', 'isNotTagged'].includes(id)) {
      conditionIdRef.current = id
      tagListDialogRef.current.open()
    } else {
      onSelect?.(conditions[id])
    }
  }, [onSelect])

  const handleTagListSubmitSelected = useCallback((selection) => {
    const condition = { ...conditions[conditionIdRef.current] }
    condition.label += ` "${selection.name}"`
    condition.after = condition.after.replace(/VALUE/, selection.name)
    condition.before = condition.before.replace(/VALUE/, selection.name)
    onSelect?.(condition)
  }, [onSelect])

  const items = useMemo(() => {
    return Object.keys(conditions).map((id) => {
      log.debug(id, conditions[id])
      const { label, description } = conditions[id]
      return (
        <DisplayConditionItem
          key={id}
          description={description}
          id={id}
          name={label}
          onSelect={handleSelection}
        />
      )
    })
  }, [handleSelection])

  return (
    <PanelDialog ref={ref} ariaLabel='Select Display Condition' {...dialogProps}>
      <PanelHeader
        start={<PanelHeaderButton icon='cancel' onClick={handleCancel} />}
        title='Select Display Condition'
      />
      <PanelContent>
        {items}
      </PanelContent>
      <ListDialog
        ref={tagListDialogRef}
        list={TagList}
        multiple={false}
        type='select'
        search
        onSubmitSelected={handleTagListSubmitSelected}
      />
    </PanelDialog>
  )
})

DisplayConditionsDialog.displayName = 'DisplayConditionsDialog'
DisplayConditionsDialog.propTypes = propTypes
DisplayConditionsDialog.defaultProps = defaultProps

export default DisplayConditionsDialog
