import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contact from '../../../prop-types/shapes/contact'

import SenderInfo from './SenderInfo'

const propTypes = {
  contact: PropType.shape(contact).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['StepThree'] })

const StepThree = ({ contact }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div>
      <SenderInfo user={contact.user} />
      <p className='mb-6 headline-sm text-center'>Thank you for updating your information.</p>
    </div>
  )
}

StepThree.displayName = 'StepThree'
StepThree.propTypes = propTypes
StepThree.defaultProps = defaultProps

export default StepThree
