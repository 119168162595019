import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Error } from '../../svg/icons'
import Button from '../buttons/Button'

const propTypes = {
  message: PropType.string.isRequired,
  resolutionTitle: PropType.string.isRequired,
  resolutionHref: PropType.string,
  resolutionOnClick: PropType.func,
  showResolution: PropType.bool
}

const defaultProps = {
  resolutionHref: null,
  resolutionOnClick: null,
  showResolution: true
}

const log = logger({ enabled: false, tags: ['PanelErrorBanner'] })

const PanelErrorBanner = ({ message, resolutionTitle, resolutionHref, resolutionOnClick, showResolution }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='bg-red-50 py-1.5 px-3 flex flex-row gap-2 flex-nowrap items-center'>
      <Error className='text-error' height='16' width='16' />
      <span className='grow label-md-strong text-error'>
        {message}
      </span>
      <div className='justify-end'>
        {showResolution
          ? (
            <Button
              className='text-right'
              href={resolutionHref}
              size='sm'
              theme='danger'
              variant='text'
              onClick={resolutionOnClick}
            >
              {resolutionTitle}
            </Button>
            )
          : null}
      </div>
    </div>
  )
}

PanelErrorBanner.displayName = 'PanelErrorBanner'
PanelErrorBanner.propTypes = propTypes
PanelErrorBanner.defaultProps = defaultProps

export default PanelErrorBanner
