import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chat from '../../../prop-types/shapes/chat'
import { markAsUnread } from '../../../services/chat'

const propTypes = {
  chat: PropType.shape(chat).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MarkAsUnreadAction'] })

const MarkAsUnreadAction = forwardRef(({ chat }, ref) => {
  useLogger({ log, lifecycle: false, tags: [chat._id] })

  const navigate = useNavigate()

  const handleReplyOk = useCallback(() => {
    bus.emit('chatMarkedAsReadOrUnread')
    navigate('/chats')
  }, [navigate])
  const { call } = useService(markAsUnread, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { call(chat._id) }
  }), [call, chat._id])

  return null
})

MarkAsUnreadAction.displayName = 'MarkAsUnread'
MarkAsUnreadAction.propTypes = propTypes
MarkAsUnreadAction.defaultProps = defaultProps

export default MarkAsUnreadAction
