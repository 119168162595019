import * as React from "react";
import { memo } from "react";
const SvgConfigurableForm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.525 7.068 5.057 5.057.01.01a.981.981 0 0 1 0 1.403l-5.057 5.057a1.01 1.01 0 0 1-.706.292c-.253 0-.515-.1-.707-.292a.981.981 0 0 1 0-1.403l4.36-4.36-4.36-4.36a.981.981 0 0 1 0-1.404.981.981 0 0 1 1.403 0ZM8.59 18.592l-5.057-5.056-.01-.01a.98.98 0 0 1 0-1.403L8.58 7.066a1.01 1.01 0 0 1 .706-.293c.253 0 .515.101.707.293a.981.981 0 0 1 0 1.403l-4.36 4.36 4.36 4.36a.981.981 0 0 1 0 1.403.98.98 0 0 1-1.403 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgConfigurableForm);
export default Memo;
