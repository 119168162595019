import PropType from 'prop-types'

import customField from '../prop-types/shapes/customField'
import dateMonth from '../prop-types/shapes/dateMonth'
import paging from '../prop-types/shapes/paging'
import shouldBeType from '../prop-types/shouldBeType'
import shouldNotCollideWith from '../prop-types/shouldNotCollideWith'

export const createPropTypes = {
  phoneNumber: PropType.string.isRequired,
  company: PropType.string,
  email: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string
}

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  anniversary: PropType.shape(dateMonth),
  archived: PropType.bool,
  areaCode: PropType.oneOfType([PropType.string, PropType.number]), // matches against `phoneNumber`
  birthday: PropType.shape(dateMonth),
  campaignId (props, propName, name) {
    const collidesWithPropName = 'campaigns'
    const value = props?.[propName]
    const shouldBeTypeError = shouldBeType(value, 'string', propName, name)
    const shouldNotCollideWithError = shouldNotCollideWith(props, propName, collidesWithPropName, name)
    return shouldBeTypeError || shouldNotCollideWithError || null
  }, // collides with `campaigns`
  campaigns: PropType.arrayOf(PropType.string), // collides with `campaignId`
  contestId (props, propName, name) {
    const collidesWithPropName = 'wonContestId'
    const value = props?.[propName]
    const shouldBeTypeError = shouldBeType(value, 'string', propName, name)
    const shouldNotCollideWithError = shouldNotCollideWith(props, propName, collidesWithPropName, name)
    return shouldBeTypeError || shouldNotCollideWithError || null
  }, // collides with `wonContestId`
  customFields: PropType.arrayOf(PropType.shape(customField)),
  deletable: PropType.bool,
  doNotMessage: PropType.bool,
  doNotEmail: PropType.bool,
  doNotMessageMe: PropType.bool,
  doubleOptedIn: PropType.bool,
  hasContactUser: PropType.bool,
  hideChat: PropType.bool,
  importLock: PropType.bool,
  lastReply: PropType.string, // date
  noName: PropType.bool,
  outboundJobId (props, propName, name) {
    const collidesWithPropName = 'outboundJobs'
    const value = props?.[propName]
    const shouldBeTypeError = shouldBeType(value, 'string', propName, name)
    const shouldNotCollideWithError = shouldNotCollideWith(props, propName, collidesWithPropName, name)
    return shouldBeTypeError || shouldNotCollideWithError || null
  }, // collides with `outboundJobs`
  outboundJobs: PropType.arrayOf(PropType.string), // collides with `outboundJobId`
  tag (props, propName, name) {
    const collidesWithPropName = 'tags'
    const value = props?.[propName]
    const shouldBeTypeError = shouldBeType(value, 'string', propName, name)
    const shouldNotCollideWithError = shouldNotCollideWith(props, propName, collidesWithPropName, name)
    return shouldBeTypeError || shouldNotCollideWithError || null
  }, // collides with `tags`
  tags: PropType.arrayOf(PropType.string), // collides with `tag`
  term: PropType.string,
  sort: PropType.string,
  wonContestId: PropType.string // collides with `contestId`
}

export const updatePropTypes = {
  anniversary: PropType.shape(dateMonth),
  birthday: PropType.shape(dateMonth),
  company: PropType.string,
  potentialStopMessage: PropType.oneOf([null]),
  doNotMessage: PropType.bool,
  doNotEmail: PropType.bool,
  doNotMessageMe: PropType.bool,
  firstName: PropType.string,
  hideChat: PropType.bool,
  importLock: PropType.bool,
  lastName: PropType.string,
  notes: PropType.string,
  phoneNumber: PropType.string,
  starred: PropType.bool
}

export const bulkUpdatePropTypes = {
  potentialStopMessage: PropType.oneOf([null]),
  doNotMessage: PropType.bool
}
