import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import qrCode from '../../prop-types/shapes/qrCode'
import { QrCode } from '../../svg/types'

const propTypes = {
  item: PropType.shape(qrCode).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['QrCodeListItemContent'] })

const QrCodeListItemContent = ({ item: qrCode }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <QrCode className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {qrCode.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <div className='line-clamp-1 break-all'>{qrCode.url}</div>
        </div>
      </div>
    </>
  )
}

QrCodeListItemContent.displayName = 'QrCodeListItemContent'
QrCodeListItemContent.propTypes = propTypes
QrCodeListItemContent.defaultProps = defaultProps

export default QrCodeListItemContent
