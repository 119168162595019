import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { singleOptIn, singleOptInLanding } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SingleOptIn'] })

const SingleOptIn = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formRef = useRef()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const { slug } = useParams()

  const onReplyOk = useCallback((reply) => {
    log.debug('onReplyOk', reply)
    setData(reply.json)
  }, [])

  const onReplyNotOk = useCallback(() => {
    setError('This link is invalid or expired.')
  }, [])

  const { call: getUser, loading: loadingUser } = useService(singleOptInLanding, { onReplyOk, onReplyNotOk })

  const onOptInReplyOk = useCallback(() => {
    formRef.current?.reset()
    notify('You have been subscribed.')
  }, [])

  const onOptInReplyNotOk = useCallback(() => {
    setError('Unable to subscribe. Please try again later.')
  }, [])

  const { call: optIn } = useService(singleOptIn, { onReplyOk: onOptInReplyOk, onReplyNotOk: onOptInReplyNotOk })

  useEffect(() => {
    log.debug('SingleOptIn')
    getUser(slug, true)
  }, [getUser, slug])

  const onSubmit = useCallback((formData) => {
    log.debug('onSubmit', formData)
    optIn(slug, formData)
  }, [optIn, slug])

  const loading = (loadingUser || !data) && !error
  const formId = 'single-opt-in'

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <div className='mb-10'>
        <p className='text-center'>
          <strong>
            {data.info}
          </strong>
          {' '}
          would like to send you text messages.
        </p>
      </div>
      <Form
        ref={formRef}
        defaultValues={{
          firstName: '',
          lastName: '',
          phoneNumber: ''
        }}
        formControls={[
          { autoFocus: true, type: 'text', name: 'firstName', label: 'First Name', required: true },
          { type: 'text', name: 'lastName', label: 'Last Name', required: true },
          { name: 'phoneNumber', label: 'Phone Number', type: 'tel', required: true }
        ]}
        id={formId}
        onSubmit={onSubmit}
      />
      <p className='text-center mb-6'>
        ** By subscribing you agree to receive text messages from
        {' '}
        <strong>
          {data.info}
        </strong>
        {'. '}
        Message frequency will vary. Messages may be automated and promotional in nature. Message and data rates may apply. Reply HELP for help. At any time you can reply STOP to stop receiving messages. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. **
      </p>
      <Button
        className='w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Subscribe
      </Button>
    </ShortLinkContent>
  )
}

SingleOptIn.displayName = 'SingleOptIn'
SingleOptIn.propTypes = propTypes
SingleOptIn.defaultProps = defaultProps

export default SingleOptIn
