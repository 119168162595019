import { vestResolver } from '@hookform/resolvers/vest'
import { some } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import useDefaultId from '../../hooks/useDefaultId'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ConfirmDialog from '../dialog/ConfirmDialog'

import FormControl from './FormControl'
import FormControlGroup from './FormControlGroup'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropType.object.isRequired,
  children: PropType.node,
  className: PropType.string,
  confirm: PropType.shape({
    description: PropType.string,
    title: PropType.string,
    when: PropType.func
  }),
  formControls: PropType.arrayOf(PropType.shape({
    name: PropType.string.isRequired,
    autoComplete: PropType.string,
    autoFocus: PropType.bool,
    label: PropType.string,
    placeholder: PropType.string,
    type: PropType.string,
    onChange: PropType.func
  })),
  id: PropType.string,
  // NOTE: preventMultipleSubmits should only be ncessary in situations where the form does not exclusively use our service layer (external apis) and is not executed in the "background"... otherwise making a service call blocks the entire ui
  preventMultipleSubmits: PropType.bool,
  transformData: PropType.func,
  validationSuite: PropType.func,
  onError: PropType.func,
  onSubmit: PropType.func
}

const defaultProps = {
  children: null,
  className: null,
  confirm: null,
  formControls: [],
  id: null,
  onError: null,
  onSubmit () {},
  preventMultipleSubmits: false,
  transformData: undefined,
  validationSuite: null
}

const log = logger({ enabled: true, tags: ['Form'] })

const Form = forwardRef(({
  children,
  className,
  confirm,
  defaultValues,
  formControls,
  id,
  preventMultipleSubmits,
  transformData,
  validationSuite,
  onError,
  onSubmit
}, ref) => {
  ref = useDefaultRef(ref)
  id = useDefaultId(id)

  const confirmRef = useRef()
  const submitParams = useRef()
  const submittingRef = useRef(false)

  useLogger({ log, lifecycle: true, tags: [id] })

  const customErrors = useRef({})

  const useFormResults = useForm({
    resolver: validationSuite ? vestResolver(validationSuite) : undefined,
    shouldUseNativeValidation: false,
    defaultValues
  })

  const customContextMethods = useMemo(() => {
    return {
      setCustomError (name, { message } = {}) {
        customErrors.current[name] = message
      }
    }
  }, [])

  useImperativeHandle(ref, () => ({
    updateSubmitting (isSubmitting) {
      submittingRef.current = isSubmitting === true
    },
    ...useFormResults
  }), [useFormResults])

  const onSubmitSuccess = async function (data, event) {
    if (some(customErrors.current)) {
      // Manually flag form control custom errors & abort submit
      for (const [fieldName, errorMessage] of Object.entries(customErrors.current)) {
        useFormResults.setError(fieldName, { message: errorMessage })
      }
      if (preventMultipleSubmits) {
        submittingRef.current = false
      }
      return
    }

    data = transformData ? transformData(data) : data
    if (confirm && (!confirm.when || confirm.when(data))) {
      submitParams.current = [data, event]
      confirmRef.current.open()
    } else {
      onSubmit?.(data, event)
    }
  }
  const onSubmitError = async function (errors, event) {
    if (preventMultipleSubmits) {
      submittingRef.current = false
    }
    onError?.(errors, event)
  }
  const formHooksHandleSubmit = useFormResults.handleSubmit(onSubmitSuccess, onSubmitError)
  const handleSubmit = (event) => {
    event.stopPropagation()

    // Forms using `preventMultipleSubmits` must call `updateSubmitting(false)` to allow another submit
    if (submittingRef.current === true) {
      event.preventDefault()
      return
    }
    if (preventMultipleSubmits) { submittingRef.current = true }

    // Clear any previous custom errors before submit
    for (const fieldName of Object.keys(customErrors.current)) {
      useFormResults.clearErrors(fieldName)
    }

    formHooksHandleSubmit(event)
  }

  const onConfirm = useCallback(() => { onSubmit?.(...submitParams.current) }, [onSubmit])

  const controls = !formControls?.length
    ? false
    : formControls.map((controlProps) => {
      const DelegatedComponent = controlProps.type === 'controlgroup' ? FormControlGroup : FormControl
      return <DelegatedComponent key={controlProps.name} {...controlProps} />
    })

  return (
    <>
      <FormProvider {...useFormResults} {...customContextMethods}>
        <form className={className} id={id} onSubmit={handleSubmit}>
          {controls || children}
        </form>
      </FormProvider>
      {!!confirm && (
        <ConfirmDialog
          ref={confirmRef}
          description={confirm.description || 'Are you sure?'}
          title={confirm.title || 'Please Confirm'}
          onConfirm={onConfirm}
        />
      )}
    </>
  )
})

Form.displayName = 'Form'
Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
