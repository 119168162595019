import PropType from 'prop-types'

import MenuItemWithIndicator from './MenuItemWithIndicator'
import MenuItemWithoutSub from './MenuItemWithoutSub'
import MenuItemWithSub from './MenuItemWithSub'

const propTypes = {
  icon: PropType.element.isRequired,
  label: PropType.string.isRequired,
  indicator: PropType.string, // global store prop path to check in order to display indicator
  path: PropType.string,
  stack: PropType.string,
  subConfig: PropType.arrayOf(PropType.shape({
    label: PropType.string.isRequired,
    path: PropType.string.isRequired,
    stack: PropType.string
  }))
}

const defaultProps = {
  indicator: null,
  path: null,
  stack: null,
  subConfig: null
}

const MenuItem = (props) => {
  const DelegatedMenuItem = props.indicator ? MenuItemWithIndicator : props.subConfig ? MenuItemWithSub : MenuItemWithoutSub
  return (<DelegatedMenuItem {...props} />)
}

MenuItem.displayName = 'MenuItem'
MenuItem.propTypes = propTypes
MenuItem.defaultProps = defaultProps

export default MenuItem
