import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/campaign-contact'
import ContactList from '../contacts/ContactList'

import CampaignContactListItemContent from './CampaignContactListItemContent'

const propTypes = {
  campaignId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignContactList'] })

const campaignContactResultsFields = [
  '_id',
  'slug',
  'firstName',
  'lastName',
  'phoneNumber',
  'company',
  'contactUser',
  'tags',
  'doNotMessage',
  'doNotEmail',
  'campaignJoinDates'
]

const CampaignContactList = forwardRef(({ campaignId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaignId] })

  return (
    <ContactList
      ref={ref}
      itemSharedContext={{ campaignId }}
      ListItemContent={CampaignContactListItemContent}
      queryOverrides={{ campaignId }}
      resultsFields={campaignContactResultsFields}
      searchService={searchService}
      {...rest}
    />
  )
})

CampaignContactList.displayName = 'CampaignContactList'
CampaignContactList.propTypes = propTypes
CampaignContactList.defaultProps = defaultProps

export default CampaignContactList
