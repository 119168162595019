import { forwardRef } from 'react'

import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { spammers as searchService } from '../../../services/admin'
import List from '../../list/List'
import ListItemContent from '../../users/ListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpammersList'] })

const SpammersList = forwardRef(({ ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  ref = useDefaultRef(ref)

  return (
    <List
      ref={ref}
      ListItemContent={ListItemContent}
      multiple={false}
      placeholder='Search Spammers'
      resultsKey='users'
      searchService={searchService}
      type='select'
      search
      {...rest}
    />
  )
})

SpammersList.displayName = 'SpammersList'
SpammersList.propTypes = propTypes
SpammersList.defaultProps = defaultProps

export default SpammersList
