import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import sortListItemShape from '../../../prop-types/shapes/sortListItem'
import { Check } from '../../../svg/icons'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import SearchBarButton from '../SearchBarButton'

const propTypes = {
  currentSortKey: PropType.string.isRequired,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape)).isRequired,
  onChange: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SortButton'] })

const SortButton = ({ currentSortKey, sortList, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleMenuItemClick = useCallback((sortKey) => { onChange(sortKey) }, [onChange])

  const actionMenuItems = useMemo(() => {
    return sortList.map((sortListItem) => {
      const isSortItemSelected = sortListItem.key === currentSortKey
      const end = isSortItemSelected ? <Check /> : null
      const className = isSortItemSelected ? 'text-primary' : null
      const proxyMenuItemClick = isSortItemSelected ? null : () => handleMenuItemClick(sortListItem.key)

      return (
        <ActionMenuItem
          key={sortListItem.key}
          className={className}
          end={end}
          label={sortListItem.label}
          onClick={proxyMenuItemClick}
        />
      )
    })
  }, [currentSortKey, handleMenuItemClick, sortList])

  return (
    <ActionMenu offset={8} placement='bottom-end' trigger={<SearchBarButton icon='sort' />}>
      {actionMenuItems}
    </ActionMenu>
  )
}

SortButton.displayName = 'SortButton'
SortButton.propTypes = propTypes
SortButton.defaultProps = defaultProps

export default SortButton
