import { useEffect } from 'react'

// For use with float-ui useInteractions

function useDismissOnResize ({ open, onOpenChange }, { enabled }) {
  useEffect(() => {
    if (!open || !enabled) { return }

    function onResize () {
      onOpenChange(false)
    }

    window.addEventListener('resize', onResize, { passive: true })

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [open, enabled, onOpenChange])

  return {}
}

export default useDismissOnResize
