import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import MessageTemplatesDetail from '../screens/message-templates/MessageTemplatesDetail'
import MessageTemplatesFolderDetail from '../screens/message-templates/MessageTemplatesFolderDetails'

const messageTemplateRoutes = [
  {
    path: ':messageTemplateId',
    element: <MessageTemplatesDetail />
  }
]

const routes = [
  ...messageTemplateRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<MessageTemplatesFolderDetail />} />,
    children: [...messageTemplateRoutes]
  }
]

export default routes
