import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['NotesDetail'] })

const NotesDetail = ({ contact, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const value = useMemo(() => {
    if (!contact.notes) { return null }
    // eslint-disable-next-line react/no-array-index-key
    return contact.notes.split(/\n/).map((line, index) => <p key={index}>{line || <br />}</p>)
  }, [contact])

  const defaultValues = {
    notes: contact.notes
  }
  const formControls = [
    { label: 'Notes', name: 'notes', type: 'textarea', className: 'h-[100px]' }
  ]

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description="You can't remember everything! Jot down notes about your contact."
      formControls={formControls}
      formDescription={`Updating notes for ${contact.formattedName}`}
      formTitle={contact.formattedName}
      objectId={contact._id}
      service={service}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Notes'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

NotesDetail.displayName = 'NotesDetail'
NotesDetail.propTypes = propTypes
NotesDetail.defaultProps = defaultProps

export default NotesDetail
