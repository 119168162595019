import { uniq } from 'lodash'
import PropType from 'prop-types'

import { campaignTypes } from '../../helpers/campaign'
import messageTypes from '../../helpers/messageTypes'

import campaign from './campaign'
import campaignEvent from './campaignEvent'

const contact = {
  _id: PropType.string,
  company: PropType.string,
  contactUser: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string,
  phoneNumber: PropType.string
}

const jobStates = [
  'draft',
  'building',
  'scheduled',
  'queuing',
  'queued',
  'error',
  'unsent'
]

const messageStates = uniq([
  'received',
  'queuing',
  'queued',
  'sending',
  'sent',
  'error',
  'skipped'
].concat(jobStates))

export default {
  _id: PropType.string,
  __v: PropType.number,
  ebrake: PropType.bool,
  fastFwd: PropType.bool,
  state: PropType.oneOf(jobStates),
  messageStates: PropType.arrayOf(
    PropType.oneOf(messageStates)
  ),
  media: PropType.arrayOf(PropType.string),
  text: PropType.string,
  type: PropType.oneOf(messageTypes),
  scheduledAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  campaign: PropType.shape(campaign),
  campaignType: PropType.oneOf(campaignTypes),
  event: PropType.shape(campaignEvent),
  contacts: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contact))
  }),
  contactsSending: PropType.shape({
    contactUsersCount: PropType.number,
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contact))
  }),
  contactsSent: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contact))
  }),
  contactsUnsent: PropType.shape({
    contactUsersCount: PropType.number,
    estimatedBroadcastTimeMins: PropType.number,
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contact))
  }),
  error: PropType.string,
  result: PropType.shape({
    contactCount: PropType.number,
    messageCount: PropType.number
  })
}
