import dynamicFieldsContact from '../data-sources/dynamic-fields-contact.json'
import dynamicFieldsOther from '../data-sources/dynamic-fields-other.json'
import dynamicFieldsUser from '../data-sources/dynamic-fields-user.json'

export const categories = ['Contact', 'User', 'Other']

export function getDynamicFieldsByCategory (currentUser, excludeContactPaths = [], excludeOtherPaths = [], excludedUserPaths = []) {
  const userCustomFields = currentUser?.userCustomFields || []
  const contactCustomFields = currentUser?.customFields || []

  const contactDynamicFields = [...dynamicFieldsContact, ...contactCustomFields].filter(({ path }) => !excludeContactPaths.includes(path))
  const userDynamicFields = [...dynamicFieldsUser, ...userCustomFields].filter(({ path }) => !excludedUserPaths.includes(path))
  const otherDynamicFields = [...dynamicFieldsOther].filter(({ path }) => !excludeOtherPaths.includes(path))

  const dynamicFields = {
    Contact: contactDynamicFields,
    User: userDynamicFields,
    Other: otherDynamicFields
  }

  return dynamicFields
}
