import * as React from "react";
import { memo } from "react";
const SvgNature = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.995 2.095a.993.993 0 0 0-.289-.803.997.997 0 0 0-.802-.288l-4.105.319h-.003c-.731.052-2.885.248-5.24.807-2.312.548-5.028 1.492-6.693 3.163-3.485 3.484-3.808 8.479-.831 12.165l.02.024a127.17 127.17 0 0 0-1.901 2.99 1 1 0 0 0 1.698 1.056c.616-.99 1.148-1.838 1.646-2.6.015.014.03.027.047.04 3.686 2.977 8.68 2.653 12.165-.831 1.665-1.666 2.61-4.384 3.16-6.696.56-2.356.758-4.51.81-5.236v-.003l.318-4.107ZM6.641 17.247l.197.197c2.85 2.27 6.672 2.062 9.455-.721 1.244-1.245 2.09-3.481 2.628-5.744.526-2.216.714-4.254.762-4.92v-.006l.23-2.966-2.972.23c-.672.049-2.71.233-4.924.759-2.261.536-4.495 1.381-5.74 2.63v.001c-2.653 2.653-2.966 6.25-1.024 9.048.246-.333.496-.66.755-.985 1.31-1.639 2.854-3.225 5.312-5.503a1 1 0 1 1 1.36 1.466c-2.43 2.252-3.89 3.76-5.11 5.286-.32.402-.626.806-.93 1.228Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgNature);
export default Memo;
