import PropType from 'prop-types'

export const importResultItem = {
  error: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string,
  phoneNumber: PropType.string
}

export default {
  created: PropType.arrayOf(PropType.shape(importResultItem)),
  exists: PropType.arrayOf(PropType.shape(importResultItem)),
  failed: PropType.arrayOf(PropType.shape(importResultItem))
}
