import PropType from 'prop-types'
import { useCallback } from 'react'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import useServiceAndAction from '../../../hooks/useServiceAndAction'
import { mfaChannelVerb } from '../../../lib/formatters'
import { mfaDeferLogin as mfaDeferLoginService } from '../../../services/user'
import { login as loginAction } from '../../../store/actions/currentUser'
import Header from '../shared/Header'

import DeferSetupButton from './DeferSetupButton'

const propTypes = {
  onAccountCancelled: PropType.func.isRequired,
  onLoggedIn: PropType.func.isRequired,
  backStepIndex: PropType.number,
  canDeferSetup: PropType.bool,
  nonce: PropType.string,
  supportedChannels: PropType.arrayOf(PropType.string),
  username: PropType.string,
  onChannelSelected: PropType.func
}

const defaultProps = {
  backStepIndex: 0,
  canDeferSetup: false,
  nonce: null,
  onChannelSelected: function noop () {},
  supportedChannels: [],
  username: null
}

const Setup = ({ backStepIndex, canDeferSetup, nonce, supportedChannels, username, onAccountCancelled, onChannelSelected, onLoggedIn }) => {
  const { goToStep } = useWizard()

  const handleReply = useCallback((reply) => {
    if (reply.ok) {
      onLoggedIn()
    } else {
      onAccountCancelled()
    }
  }, [onAccountCancelled, onLoggedIn])
  const { call: deferMfaLogin } = useServiceAndAction(mfaDeferLoginService, loginAction, { onReply: handleReply })

  const handleChannelSelected = (channel) => {
    onChannelSelected(channel)
    goToStep(2)
  }

  return (
    <>
      <Header
        goBack={() => goToStep(backStepIndex)}
        heading='Sign In'
        subtitle='Enhanced security for your account'
        title='Setup Multi-Factor Authentication'
      />
      <p className='my-5 text-center'>
        Multi-Factor Authentication (MFA) provides an extra layer of security for your account and is highly recommended for all users. MFA helps secure your account by requiring an additional method for verifying your identity upon login.
      </p>
      <p className='my-5 text-center'>
        Select the method that you would like to setup.
      </p>
      {supportedChannels.map((methodChannel) => (
        <Button
          key={methodChannel}
          className='mt-5 w-full'
          size='md'
          onClick={() => handleChannelSelected(methodChannel)}
        >
          {mfaChannelVerb(methodChannel)}
        </Button>
      ))}
      <DeferSetupButton
        canDeferSetup={canDeferSetup}
        onClick={() => deferMfaLogin(username, nonce)}
      />
    </>
  )
}

Setup.displayName = 'MultiFactorAuthenticationSetup'
Setup.propTypes = propTypes
Setup.defaultProps = defaultProps

export default Setup
