import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import ActionMenu from '../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../components/action-menu/ActionMenuItem'
import ContactList from '../../components/contacts/ContactList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import RescheduleAction from '../../components/scheduled-broadcast/actions/RescheduleAction'
import ScheduledBroadcastContactListItemContent from '../../components/scheduled-broadcast/ScheduledBroadcastContactListItemContent'
import useCurrentScheduledBroadcast from '../../hooks/useCurrentScheduledBroadcast'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { searchByState } from '../../services/scheduledBroadcast-contact'

const propTypes = {
  state: PropType.oneOf(['sending', 'sent', 'unsent']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastContactsByStateList'] })

const ScheduledBroadcastContactsByStateList = ({ state }) => {
  const { scheduledBroadcastId, loading, scheduledBroadcast } = useCurrentScheduledBroadcast()

  useLogger({ log, lifecycle: false, tags: [scheduledBroadcastId, state] })

  const rescheduleRef = useRef()

  const loaded = !loading && !!scheduledBroadcast

  const { contacts, title } = useMemo(() => {
    return {
      contacts: scheduledBroadcast?.[`contacts${upperFirst(state)}`],
      title: scheduledBroadcast?.campaign?.name || 'Broadcast'
    }
  }, [state, scheduledBroadcast])

  const handleRescheduleClick = useCallback(() => {
    rescheduleRef.current.activate()
  }, [])

  const end = useMemo(() => {
    return !!scheduledBroadcast && state === 'unsent'
      ? (
        <>
          <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
            <ActionMenuItem label='Reschedule Unsent' onClick={handleRescheduleClick} />
          </ActionMenu>
          <RescheduleAction ref={rescheduleRef} scheduledBroadcast={scheduledBroadcast} />
        </>
        )
      : null
  }, [state, scheduledBroadcast, handleRescheduleClick])

  return (
    <Panel>
      <PanelHeader
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={`${upperFirst(state)} Contacts (${contacts?.length || 0})`}
        title={title}
      />
      <PanelContent>
        {!!loaded && (
          <ContactList
            itemSharedContext={{ listState: state }}
            ListItemContent={ScheduledBroadcastContactListItemContent}
            queryOverrides={{ scheduledBroadcastId, state }}
            searchService={searchByState}
          />
        )}
      </PanelContent>
    </Panel>
  )
}

ScheduledBroadcastContactsByStateList.displayName = 'ScheduledBroadcastContactsByStateList'
ScheduledBroadcastContactsByStateList.propTypes = propTypes
ScheduledBroadcastContactsByStateList.defaultProps = defaultProps

export default ScheduledBroadcastContactsByStateList
