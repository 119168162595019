import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { ChevronRight } from '../../svg/icons'

const propTypes = {
  description: PropType.string.isRequired,
  id: PropType.string.isRequired,
  name: PropType.string.isRequired,
  onSelect: PropType.func
}

const defaultProps = {
  onSelect: undefined
}

const log = logger({ enabled: false, tags: ['DisplayConditionItem'] })

const DisplayConditionItem = ({
  description,
  id,
  name,
  onSelect
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleClick = useCallback(() => {
    onSelect?.(id)
  }, [onSelect, id])

  return (
    <div className='block flex flex-row flex-nowrap max-w-full border-b border-neutral-200 cursor-pointer' onClick={handleClick}>
      <div className='flex flex-col px-6 py-6 mr-auto'>
        <div className='label-md-strong pb-2'>{name}</div>
        <div className='label-sm'>{description}</div>
      </div>
      <div className='flex-end flex-initial self-center pl-3 pr-6'>
        <ChevronRight height='20' width='20' />
      </div>
    </div>
  )
}

DisplayConditionItem.displayName = 'DisplayConditionItem'
DisplayConditionItem.propTypes = propTypes
DisplayConditionItem.defaultProps = defaultProps

export default DisplayConditionItem
