import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Clock } from '../../svg/icons'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape({
    createdAt: PropType.string.isRequired,
    name: PropType.string.isRequired
  })
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['ListItemContent'] })

const ListItemContent = ({ item: billingAccount }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col flex-grow py-[4px] max-w-full overflow-auto'>
      <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
        {billingAccount.name}
      </div>
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Clock className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <div className='line-clamp-1'>
          Created
          {' '}
          <FormattedDate date={billingAccount.createdAt} format='extendedDate' />
        </div>
      </div>
    </div>
  )
}

ListItemContent.displayName = 'ListItemContent'
ListItemContent.propTypes = propTypes
ListItemContent.defaultProps = defaultProps

export default ListItemContent
