import PropType from 'prop-types'

import goalMilestoneShape from './goalMilestone'

export default {
  __v: PropType.number,
  _id: PropType.string,
  createdAt: PropType.string, // 2023-01-20T23:26:11.694Z
  updatedAt: PropType.string, // 2023-01-20T23:26:11.694Z
  name: PropType.string,
  displayName: PropType.string,
  enabled: PropType.bool,
  openEnded: PropType.bool,
  milestones: PropType.shape(goalMilestoneShape)
}
