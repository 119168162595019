import * as React from "react";
import { memo } from "react";
const SvgEmoji = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.242 14.563a5.29 5.29 0 0 1-1.977 2.793A5.387 5.387 0 0 1 12 18.416a5.388 5.388 0 0 1-3.265-1.06 5.29 5.29 0 0 1-1.977-2.793.882.882 0 0 1 .057-.694.873.873 0 0 1 .545-.452.881.881 0 0 1 .694.057.873.873 0 0 1 .452.545c.239.764.68 1.382 1.325 1.855a3.58 3.58 0 0 0 2.17.71c.801 0 1.525-.237 2.17-.71a3.558 3.558 0 0 0 1.325-1.855.872.872 0 0 1 .458-.545.896.896 0 0 1 .701-.057.875.875 0 0 1 .53.452.881.881 0 0 1 .058.694h-.001Zm-7.076-6.23c0 .506-.179.939-.537 1.297-.358.358-.79.537-1.296.537s-.938-.179-1.296-.537a1.766 1.766 0 0 1-.538-1.296c0-.506.18-.938.538-1.297.358-.358.79-.537 1.296-.537s.938.18 1.296.537c.358.359.537.79.537 1.297Zm7.333 0c0 .506-.179.939-.537 1.297-.358.358-.79.537-1.296.537s-.938-.179-1.297-.537a1.766 1.766 0 0 1-.537-1.296c0-.506.18-.938.537-1.297.359-.358.79-.537 1.297-.537.506 0 .938.18 1.296.537.358.359.537.79.537 1.297ZM21.166 12a8.913 8.913 0 0 0-.73-3.56 9.25 9.25 0 0 0-1.956-2.922 9.28 9.28 0 0 0-2.922-1.955 8.89 8.89 0 0 0-3.56-.731 8.927 8.927 0 0 0-3.56.73 9.243 9.243 0 0 0-2.921 1.956A9.273 9.273 0 0 0 3.56 8.44a8.905 8.905 0 0 0-.73 3.56c0 1.242.243 2.429.73 3.56a9.273 9.273 0 0 0 1.956 2.922 9.244 9.244 0 0 0 2.922 1.955 8.927 8.927 0 0 0 3.56.731 8.89 8.89 0 0 0 3.56-.73 9.28 9.28 0 0 0 2.921-1.956 9.25 9.25 0 0 0 1.956-2.922 8.913 8.913 0 0 0 .73-3.56ZM23 12c0 1.996-.492 3.836-1.475 5.522a10.946 10.946 0 0 1-4.003 4.003C15.836 22.508 13.996 23 12 23c-1.996 0-3.836-.492-5.522-1.475a10.946 10.946 0 0 1-4.003-4.003C1.492 15.836 1 13.996 1 12c0-1.996.492-3.836 1.475-5.522a10.947 10.947 0 0 1 4.003-4.003C8.164 1.492 10.004 1 12 1c1.996 0 3.836.492 5.522 1.475a10.946 10.946 0 0 1 4.003 4.003C22.508 8.164 23 10.004 23 12Z"
    />
  </svg>
);
const Memo = memo(SvgEmoji);
export default Memo;
