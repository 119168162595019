import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import landingPage from '../../prop-types/shapes/landingPage'
import { Clock } from '../../svg/icons'
import { LandingPage } from '../../svg/types'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape(landingPage)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['LandingPageListItemContent'] })

const LandingPageListItemContent = ({ item: landingPage }) => {
  useLogger({ log, lifecycle: false, tags: [landingPage?._id] })

  return (
    <>
      <LandingPage className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {landingPage.name}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px] gap-1 line-clamp-1 break-words'>
          {landingPage.description}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-neutral-500 shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            modified
            {' '}
            <FormattedDate date={landingPage.updatedAt} format='extendedDate2' />
          </div>
        </div>
      </div>
    </>
  )
}

LandingPageListItemContent.displayName = 'LandingPageListItemContent'
LandingPageListItemContent.propTypes = propTypes
LandingPageListItemContent.defaultProps = defaultProps

export default LandingPageListItemContent
