import * as React from "react";
import { memo } from "react";
const SvgList = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 3.333a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0Zm.833-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm4.167 2.5c0-.46.373-.833.833-.833h10.834a.833.833 0 1 1 0 1.666H8.333a.833.833 0 0 1-.833-.833Zm.833 5.833a.833.833 0 0 0 0 1.667h10.834a.833.833 0 1 0 0-1.667H8.333Zm0 6.667a.833.833 0 0 0 0 1.667h10.834a.833.833 0 1 0 0-1.667H8.333Zm-5.833.833a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0Zm.833-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0-5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667ZM.833 10a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgList);
export default Memo;
