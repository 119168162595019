import { useEffect } from 'react'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { generateApiKey as generateApiKeyService } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

const ApiKey = () => {
  const { call: generateApiKey } = useServiceAndAction(generateApiKeyService, updateAction)

  const currentUser = useCurrentUser()

  useEffect(() => {
    if (!currentUser.apiKey) {
      generateApiKey()
    }
  }, [currentUser.apiKey, generateApiKey])

  if (!currentUser.apiKey) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='API Key'
      />
      <PanelContent>
        <div className='p-5 flex flex-col gap-3 text-center'>
          <code className='label-xl-strong select-text'>{currentUser.apiKey}</code>
          <div>Your API Key allows you to make requests to our service without the use of a UI. This is intended to be used by developers on your behalf to integrate with Project Broadcast. An example would be because you want to put a widget on your website to allow your website visitors to join a campaign you have in Project Broadcast.</div>
        </div>
      </PanelContent>
    </Panel>
  )
}

ApiKey.displayName = 'ApiKey'
export default ApiKey
