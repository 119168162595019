import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'

import ChatListItemContent from './ChatListItemContent'

const propTypes = {
  item: PropType.shape(contactShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['ChatListItemContentWithoutFavorite'] })

const ChatListItemContentWithoutFavorite = ({ item }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (<ChatListItemContent item={item} hideFavoriteStar />)
}

ChatListItemContentWithoutFavorite.displayName = 'ChatListItemContentWithoutFavorite'
ChatListItemContentWithoutFavorite.propTypes = propTypes
ChatListItemContentWithoutFavorite.defaultProps = defaultProps

export default ChatListItemContentWithoutFavorite
