import { useCallback, useRef } from 'react'

import CreateAction from '../../../components/attachments/actions/CreateAction'
import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import { Attachments as AttachmentsEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const AttachmentEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddAttachment = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <AttachmentsEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong'>Create New Attachment</h1>
        <p className='label-sm text-center'>
          Learn how to use
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/attachments'
            label='Attachments'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddAttachment}>
          Add Attachment
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

AttachmentEmptyListContent.displayName = 'AttachmentEmptyListContent'
AttachmentEmptyListContent.propTypes = propTypes
AttachmentEmptyListContent.defaultProps = defaultProps

export default AttachmentEmptyListContent
