import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Well from '../../components/wells/Well'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Wells'] })

const Wells = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Panel>
      <PanelHeader title='Wells' />
      <PanelContent className='m-5'>
        <Well className='mb-4'>
          I'm in a well.
        </Well>
      </PanelContent>
    </Panel>
  )
}

Wells.displayName = 'Wells'
Wells.propTypes = propTypes
Wells.defaultProps = defaultProps

export default Wells
