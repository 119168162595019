import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmptyListContent'] })

const EmptyListContent = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <h1 className='label-md text-center p-10 text-neutral-400'>
      (No Spammers)
    </h1>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
