import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import { formatDateMonthDayYear } from '../../lib/formatters'
import logger from '../../lib/logger'
import folderShape from '../../prop-types/shapes/folder'
import {
  Attachments as AttachmentsFolder,
  Bundles as BundlesFolder,
  Campaigns as CampaignsFolder,
  ConfigurableForms as ConfigurableFormsFolder,
  Contests as ContestsFolder,
  Emails as EmailsFolder,
  Keywords as KeywordsFolder,
  LandingPages as LandingPagesFolder,
  MessageTemplates as MessageTemplatesFolder,
  QrCodes as QrCodesFolder,
  Templates as TemplatesFolder,
  TrackableLinks as TrackableLinksFolder,
  VoiceDrops as VoiceDropsFolder
} from '../../svg/folders'
import {
  Attach,
  Browser,
  Bundle,
  Campaign,
  ChatText,
  ConfigurableForm,
  Contest,
  Email,
  Folder,
  Key,
  Link,
  Microphone,
  QrCode,
  Template
} from '../../svg/icons'
import Pill from '../pill/Pill'

const folderIconMap = {
  [CONTENT_TYPE.attachment]: AttachmentsFolder,
  [CONTENT_TYPE.bundle]: BundlesFolder,
  [CONTENT_TYPE.campaign]: CampaignsFolder,
  [CONTENT_TYPE.configurableForm]: ConfigurableFormsFolder,
  [CONTENT_TYPE.contest]: ContestsFolder,
  [CONTENT_TYPE.email]: EmailsFolder,
  [CONTENT_TYPE.keyword]: KeywordsFolder,
  [CONTENT_TYPE.landingPage]: LandingPagesFolder,
  [CONTENT_TYPE.messageTemplate]: MessageTemplatesFolder,
  [CONTENT_TYPE.qrCode]: QrCodesFolder,
  [CONTENT_TYPE.template]: TemplatesFolder, // TODO: remove
  [CONTENT_TYPE.trackableLink]: TrackableLinksFolder,
  [CONTENT_TYPE.voiceDrop]: VoiceDropsFolder
}

const iconCountMap = {
  [CONTENT_TYPE.attachment]: Attach,
  [CONTENT_TYPE.bundle]: Bundle,
  [CONTENT_TYPE.campaign]: Campaign,
  [CONTENT_TYPE.configurableForm]: ConfigurableForm,
  [CONTENT_TYPE.contest]: Contest,
  [CONTENT_TYPE.email]: Email,
  [CONTENT_TYPE.keyword]: Key,
  [CONTENT_TYPE.landingPage]: Browser,
  [CONTENT_TYPE.messageTemplate]: ChatText,
  [CONTENT_TYPE.qrCode]: QrCode,
  [CONTENT_TYPE.template]: Template, // TODO: remove
  [CONTENT_TYPE.trackableLink]: Link,
  [CONTENT_TYPE.voiceDrop]: Microphone
}

const propTypes = {
  item: PropType.shape(folderShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['FolderListItemContent'] })

const FolderListItemContent = ({ item: folder }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const fomattedCreatedAt = useMemo(() => {
    return formatDateMonthDayYear(folder.createdAt)
  }, [folder.createdAt])

  const FolderIcon = folderIconMap[folder.contentType] || Folder
  const IconCount = iconCountMap[folder.contentType] || null

  return (
    <>
      <FolderIcon className='shrink-0 grow-0 h-12 w-12' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <p className='label-sm-strong text-black break-words text-xl'>
          {folder.name}
        </p>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <IconCount className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill value={folder?.count} />
          •
          {' '}
          {fomattedCreatedAt}
        </div>
      </div>
    </>
  )
}

FolderListItemContent.displayName = 'FolderListItemContent'
FolderListItemContent.propTypes = propTypes
FolderListItemContent.defaultProps = defaultProps

export default FolderListItemContent
