import { App } from '@capacitor/app'
import { useEffect, useState } from 'react'

import { useApp } from '../contexts/AppContext'
import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {}

const defaultProps = {}

const commit = process.env.REACT_APP_PB_COMMIT

const log = logger({ enabled: false, tags: ['VersionText'] })

const VersionText = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [version, setVersion] = useState(process.env.REACT_APP_PB_VERSION)
  const { isNative } = useApp()

  useEffect(() => {
    async function renderAppStoreVersion () {
      const appInfo = await App.getInfo()
      setVersion(appInfo.version)
    }

    if (isNative) {
      renderAppStoreVersion()
    }
  }, [isNative])

  return (
    <>
      Version
      {' '}
      {version}
      {commit ? ` (${commit})` : null}
    </>
  )
}

VersionText.displayName = 'VersionText'
VersionText.propTypes = propTypes
VersionText.defaultProps = defaultProps

export default VersionText
