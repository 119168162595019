import Button from '../../components/buttons/Button'
import { useSideMenu } from '../../contexts/SideMenuContext'
import { Menu } from '../../svg/icons'

const propTypes = {}

const defaultProps = {}

const SideMenuToggleButton = () => {
  const { toggleExpanded } = useSideMenu()
  const className = 'absolute not-native:lg:hidden z-50 left-5 bottom-5'
  return (
    <Button
      className={className}
      icon={<Menu />}
      circle
      onClick={toggleExpanded}
    >
      Toggle
    </Button>
  )
}

SideMenuToggleButton.displayName = 'SideMenuToggleButton'
SideMenuToggleButton.propTypes = propTypes
SideMenuToggleButton.defaultProps = defaultProps

export default SideMenuToggleButton
