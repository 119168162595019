import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as contestSearch } from '../../services/contest'
import List from '../list/List'

import ContestListItemContent from './ContestListItemContent'

const propTypes = {
  additionalHandlers: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {}
}

const contestResultsFields = [
  '_id',
  'name',
  'contacts',
  'winners'
]

const log = logger({ enabled: true, tags: ['ContestList'] })

const ContestList = forwardRef(({ additionalHandlers, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('contestCreated', handleBusEvent)
  useBus('contestUpdated', handleBusEvent)
  useBus('contestDeleted', handleBusEvent)
  useBus('contestsDeleted', handleBusEvent)

  additionalHandlers?.('contest', ref)

  return (
    <List
      ref={ref}
      ListItemContent={ContestListItemContent}
      placeholder='Search Contests'
      resultsFields={contestResultsFields}
      resultsKey='contests'
      searchService={contestSearch}
      search
      {...rest}
    />
  )
})

ContestList.displayName = 'ContestList'
ContestList.propTypes = propTypes
ContestList.defaultProps = defaultProps

export default ContestList
