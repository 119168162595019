import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import CloneAction from '../../../components/app-flows/actions/CloneAction'
import DeleteAction from '../../../components/app-flows/actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'

const propTypes = {
  appFlow: PropType.shape(appFlowShape)
}

const defaultProps = {
  appFlow: null
}

const log = logger({ enabled: false, tags: ['AppFlowDetailHeaderMoreMenu'] })

const DetailHeaderMoreMenu = ({ appFlow }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const cloneActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/app-flows', { replace: true }), [navigateInStack])

  const onCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])

  return (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Clone App Flow' onClick={onCloneSelected} />
        <ActionMenuItem label='Delete App Flow' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} appFlow={appFlow} onSuccess={onDeleteSuccess} />
      <CloneAction ref={cloneActionRef} appFlow={appFlow} />
    </>
  )
}

DetailHeaderMoreMenu.displayName = 'DetailHeaderMoreMenu'
DetailHeaderMoreMenu.propTypes = propTypes
DetailHeaderMoreMenu.defaultProps = defaultProps

export default DetailHeaderMoreMenu
