import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('contact', 'Contact is required', () => {
    enforce(data.contact).isNotEmpty()
  })
})

export default validationSuite
