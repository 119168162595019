import * as React from "react";
import { memo } from "react";
const SvgCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.058 18.701a1.246 1.246 0 0 1-1.81.052l-4.883-4.882a1.246 1.246 0 0 1 1.762-1.762l4 4.002L18.874 5.365a1.246 1.246 0 1 1 1.762 1.762L9.11 18.652a1.247 1.247 0 0 1-.051.05Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCheck);
export default Memo;
