import { pick } from 'lodash'
import PropType from 'prop-types'
import { create, enforce, only, test } from 'vest'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEvent from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEvent).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventNameDetail'] })

const EventNameDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent.name] })

  const defaultValues = pick(campaignEvent, 'name')
  const formControls = [
    { autoComplete: 'off', label: 'Name', name: 'name', type: 'text' }
  ]

  const validationSuite = create((data = {}, currentField) => {
    only(currentField)

    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty()
    })
  })

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Give your message a name so you can stay organized when looking at your campaign.'
      formControls={formControls}
      formDescription='Updating campaign message name'
      formTitle='Message Name'
      objectId={campaignEvent._id}
      service={service}
      title='Name'
      validationSuite={validationSuite}
      showEditIcon
      onChange={onChange}
    >
      {campaignEvent.name}
    </FormDetailSection>
  )
}

EventNameDetail.displayName = 'EventNameDetail'
EventNameDetail.propTypes = propTypes
EventNameDetail.defaultProps = defaultProps

export default EventNameDetail
