import PropType from 'prop-types'

import message from './message'

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  user: PropType.oneOfType([
    PropType.string, // user id
    PropType.shape({
      _id: PropType.string
    })
  ]),
  search: PropType.string,
  createdAt: PropType.string,
  updatedAt: PropType.string,
  keywords: PropType.arrayOf(PropType.string), // array of keyword ids
  pendingPhoneNumbers: PropType.arrayOf(PropType.string), // array of phone numbers: "1999694709"
  scheduledChatCount: PropType.number,
  lastMessage: PropType.shape(message),
  lastMessageRead: PropType.shape({
    time: PropType.string // "2022-06-29T21:15:56.080Z"
  }),
  users: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape({
      _id: PropType.string,
      firstName: PropType.string,
      lastName: PropType.string,
      phoneNumber: PropType.string
    }))
  })
}
