import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import bundleShape from '../../../prop-types/shapes/bundle'
import { bulkDelete as bulkDeleteService } from '../../../services/bundle'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  bundles: PropType.arrayOf(PropType.shape(bundleShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ bundles }, ref) => {
  const confirmRef = useRef()
  const navigate = useNavigate()
  const params = useParams()

  const handleReplyOk = useCallback(() => {
    success('Bundles have been deleted')
    bus.emit('bundlesDeleted', bundles)
    bus.emit('closeBulkEditDialog')
    const bundleIds = new Set(pluck(bundles, '_id'))
    if (bundleIds.has(params.bundleId)) { navigate('/bundles') }
  }, [navigate, bundles, params.bundleId])
  const { call: bulkDeleteBundles } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!bundles || !bundles.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [bundles])

  const handleConfirm = useCallback(() => {
    bulkDeleteBundles({ bundleIds: pluck(bundles, '_id') })
  }, [bulkDeleteBundles, bundles])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
