import PropType from 'prop-types'

const propTypes = {
  label: PropType.string.isRequired
}

const ActionMenuHeader = ({ label }) => {
  return <div className='block px-[6px] py-[3px] my-[3px] label-xs-medium text-neutral-400' role='separator'>{label}</div>
}

ActionMenuHeader.displayName = 'ActionMenuHeader'
ActionMenuHeader.propTypes = propTypes

export default ActionMenuHeader
