import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import ContestContactsList from '../screens/contests/ContestContactsList'
import ContestsDetail from '../screens/contests/ContestsDetail'
import ContestsFolderDetail from '../screens/contests/ContestsFolderDetail'
import ContestWinnersList from '../screens/contests/ContestWinnersList'

const contestRoutes = [
  {
    path: ':contestId',
    element: <ContestsDetail />
  },
  {
    path: ':contestId/contacts',
    element: <ContestContactsList />
  },
  {
    path: ':contestId/winners',
    element: <ContestWinnersList />
  }
]

const routes = [
  ...contestRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<ContestsFolderDetail />} />,
    children: [...contestRoutes]
  }
]

export default routes
