import { map } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import ContactList from '../../components/contacts/ContactList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import TagContactList from '../../components/tags/TagContactList'
import useCurrentTag from '../../hooks/useCurrentTag'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/tag-contact'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TagsContacts'] })

const TagsContacts = () => {
  const { tagName, loading, tag, setTag } = useCurrentTag()

  useLogger({ log, lifecycle: false, tags: [tagName] })

  const tagContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Contacts updated')
    tagContactListRef.current.reload()
    const updatedTag = reply.json
    setTag(updatedTag)
    bus.emit('tagUpdated', updatedTag)
  }, [setTag])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ tagName, ...query })
  }, [addAllInQueryCall, tagName])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ tagName, contactIds: map(selected, '_id') })
  }, [addSelectedCall, tagName])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ tagName, ...query })
  }, [removeAllInQueryCall, tagName])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ tagName, contactIds: map(selected, '_id') })
  }, [removeSelectedCall, tagName])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !tag) { return <PanelLoading /> }

  const end = (
    <>
      {tag.contacts.length ? <PanelHeaderButton icon='remove' title='Remove Contacts' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' title='Add Contacts' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={tag.name}
        title='Contacts'
      />
      <PanelContent>
        <TagContactList
          ref={tagContactListRef}
          tagName={tag.name}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={TagContactList}
        subtitle={tag.name}
        tagName={tag.name}
        title='Remove Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        list={ContactList}
        subtitle={tag.name}
        tagName={tag.name}
        title='Add Contact(s)'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

TagsContacts.displayName = 'TagsContacts'
TagsContacts.propTypes = propTypes
TagsContacts.defaultProps = defaultProps

export default TagsContacts
