import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { CONTENT_TYPE, stateKeyFromStackKey } from '../../../lib/folder'
import logger from '../../../lib/logger'
import { contentType as contentTypeShape } from '../../../prop-types/shapes/folder'
import { addSelected as addSelectedService } from '../../../services/folder'
import CreateAttachmentAction from '../../attachments/actions/CreateAction'
import CreateBundleAction from '../../bundles/actions/CreateAction'
import CreateCampaignAction from '../../campaigns/actions/CreateAction'
import CreateConfigFormAction from '../../configurable-forms/actions/CreateAction'
import CreateContestAction from '../../contests/actions/CreateAction'
import CreateEmailAction from '../../emails/actions/CreateAction'
import CreateKeywordAction from '../../keywords/actions/CreateAction'
import CreateLandingPageAction from '../../landing-pages/actions/CreateAction'
import CreateMessageTemplateAction from '../../message-templates/actions/CreateAction'
import CreateQRCodeAction from '../../qr-codes/actions/CreateAction'
import CreateTrackableLinkAction from '../../trackable-links/actions/CreateAction'
import CreateVoiceDropAction from '../../voice-drops/actions/CreateAction'

const propTypes = {
  contentType: PropType.oneOf(contentTypeShape).isRequired
}

const defaultProps = {}

const componentMapping = {
  [CONTENT_TYPE.attachment]: CreateAttachmentAction,
  [CONTENT_TYPE.bundle]: CreateBundleAction,
  [CONTENT_TYPE.campaign]: CreateCampaignAction,
  [CONTENT_TYPE.configurableForm]: CreateConfigFormAction,
  [CONTENT_TYPE.contest]: CreateContestAction,
  [CONTENT_TYPE.email]: CreateEmailAction,
  [CONTENT_TYPE.keyword]: CreateKeywordAction,
  [CONTENT_TYPE.landingPage]: CreateLandingPageAction,
  [CONTENT_TYPE.messageTemplate]: CreateMessageTemplateAction,
  [CONTENT_TYPE.qrCode]: CreateQRCodeAction,
  [CONTENT_TYPE.trackableLink]: CreateTrackableLinkAction,
  [CONTENT_TYPE.voiceDrop]: CreateVoiceDropAction
}

const log = logger({ enabled: false, tags: ['CreateFromFolderAction'] })

const CreateFromFolderAction = forwardRef(({ contentType }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const CreateAction = componentMapping[contentType]

  const navigate = useNavigate()
  const contentRef = useRef()
  const dialogRef = useRef()
  const { folderId } = useParams()
  const stackKey = useCurrentStackKey()

  const handleReplyOk = useCallback(() => {
    const stateKey = stateKeyFromStackKey(stackKey)
    bus.emit('foldersUpdated')
    bus.emit(`${stateKey}AddedToFolder`)
    navigate(`/${stackKey}/folder/${folderId}/${contentRef.current._id}`)
  }, [folderId, navigate, stackKey])
  const { call: addContent } = useService(addSelectedService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      dialogRef.current && dialogRef.current.activate()
    }
  }), [])

  const handleOnSuccess = useCallback((content) => {
    contentRef.current = content
    addContent({ folderId, contentIds: [content._id], contentType })
  }, [addContent, folderId, contentType])

  return (
    <CreateAction ref={dialogRef} createdFromFolder onSuccess={handleOnSuccess} />
  )
})

CreateFromFolderAction.displayName = 'CreateFromFolderAction'
CreateFromFolderAction.propTypes = propTypes
CreateFromFolderAction.defaultProps = defaultProps

export default CreateFromFolderAction
