import proxy from './proxy'

export default function aivaMessageFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get type () {
      return 'sms'
    },

    get media () {
      return []
    },

    get isAivaChatMessage () {
      return true
    },

    toString () {
      return this._id
    }
  }

  const model = proxy(json, overrides)
  return model
}
