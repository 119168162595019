import PropType from 'prop-types'

import userShape from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  autoTag: PropType.bool,
  bundles: PropType.array, // TODO: array of what?
  name: PropType.string,
  title: PropType.string,
  description: PropType.string,
  user: PropType.oneOfType([
    PropType.string, // id
    PropType.shape(userShape)
  ]),
  search: PropType.string,
  slug: PropType.string,
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  unlayerDesign: PropType.object,
  usesContactDynamicFields: PropType.bool,
  previewImageUrl: PropType.string,
  publicUrl: PropType.string,
  landingPageViews: PropType.shape({
    length: PropType.number
  })
}
