import { entries, filter, has, values } from 'lodash'

import dates from '../data-sources/dates'
import months from '../data-sources/months'
import { formatShortDateAndTime } from '../lib/formatters'
import logger from '../lib/logger'

const log = logger({ enabled: true, tags: ['contactListSearchBar'] })

export function generateFormControls (currentUser) {
  const formControls = filter([
    { label: 'Term', name: 'term' },
    { label: 'Tags', name: 'tags', model: 'tags', type: 'modelselect' },
    { label: 'Area Code', max: '999', name: 'areaCode', type: 'number' },
    { label: 'No Reply Since', name: 'lastReply', type: 'datetimeselect' },
    { label: 'Has Replied Since', name: 'hasRepliedSince', type: 'datetimeselect' },
    { label: 'No Outgoing Message Since', name: 'lastSent', type: 'datetimeselect' },
    {
      type: 'controlgroup',
      name: 'birthday',
      formControls: [
        {
          label: 'Birthday Month',
          name: 'birthday[month]',
          type: 'select',
          options: [{ label: 'Select Month', value: '' }, ...months]
        },
        {
          label: 'Birthday Day',
          name: 'birthday[date]',
          type: 'select',
          options: [{ label: 'Select Day', value: '' }, ...dates],
          placeholder: 'Date'
        }
      ]
    },
    {
      type: 'controlgroup',
      name: 'anniversary',
      formControls: [
        {
          label: 'Anniversary Month',
          name: 'anniversary[month]',
          type: 'select',
          options: [{ label: 'Select Month', value: '' }, ...months]
        },
        {
          label: 'Anniversary Day',
          name: 'anniversary[date]',
          type: 'select',
          options: [{ label: 'Select Day', value: '' }, ...dates],
          placeholder: 'Date'
        }
      ]
    },
    { label: 'No Name', name: 'noName', type: 'checkbox' },
    currentUser.aivaSettings.stopDetect !== 'off' ? { label: 'Potential Opt-out', name: 'potentialStopMessage', type: 'checkbox' } : null,
    { label: 'Do Not Message', name: 'doNotMessage', type: 'checkbox' },
    { label: 'Do Not Email', name: 'doNotEmail', type: 'checkbox' },
    { label: 'Do Not Message Me', name: 'doNotMessageMe', type: 'checkbox' },
    { label: 'Import Lock', name: 'importLock', type: 'checkbox' },
    { label: 'Hide Chat', name: 'hideChat', type: 'checkbox' },
    { label: 'Double Opt In Status', name: 'doubleOptInStatus', type: 'select', options: [{ label: 'Any', value: '' }, { label: 'Not double opted in', value: '0' }, { label: 'Double opted In', value: '1' }] },
    { label: 'Email', name: 'hasEmail', type: 'select', options: [{ label: 'Any', value: '' }, { label: 'No Email', value: '0' }, { label: 'Has Email', value: '1' }] }
  ])

  // append user custom fields to advanced search inputs
  currentUser.customFields.forEach((customField) => {
    formControls.push({ label: customField.name, name: `customFields[${customField._id}]`, type: 'text' })
  })

  return formControls
}

// converts server-ready object back to an advanced search object :(
export function generateAdvancedSearchFromSearchData (searchData) {
  const search = {}

  if (!searchData) { return search }

  const fieldsToCopyAsIs = ['term', 'noName', 'potentialStopMessage', 'doNotMessage', 'doNotEmail', 'doNotMessageMe', 'importLock', 'hideChat']
  fieldsToCopyAsIs.forEach((field) => { search[field] = searchData[field] })

  if (searchData.areaCode) {
    search.areaCode = searchData.areaCode
  }

  search.tags = (searchData.tags || []).map((name) => ({ name }))
  search.sortKey = searchData.sortKey

  if (searchData.lastReply) {
    search.lastReply = new Date(searchData.lastReply)
  }

  if (searchData.hasRepliedSince) {
    search.hasRepliedSince = new Date(searchData.hasRepliedSince)
  }

  if (searchData.lastSent) {
    search.lastSent = new Date(searchData.lastSent)
  }

  const occasions = ['birthday', 'anniversary']
  occasions.forEach((occasion) => {
    if (has(searchData[occasion], 'month') || has(searchData[occasion], 'date')) {
      search[occasion] = {}
      const fields = ['month', 'date']
      fields.forEach((field) => {
        if (has(searchData[occasion], field)) {
          search[occasion][field] = `${searchData[occasion][field]}`
        }
      })
    }
  })

  if (has(searchData, 'doubleOptedIn')) {
    if (searchData.doubleOptedIn === true) {
      search.doubleOptInStatus = '1'
    } else if (searchData.doubleOptedIn === false) {
      search.doubleOptInStatus = '0'
    }
  }

  if (has(searchData, 'hasEmail')) {
    if (searchData.hasEmail === true) {
      search.hasEmail = '1'
    } else if (searchData.hasEmail === false) {
      search.hasEmail = '0'
    }
  }

  // handle custom fields
  // input: [{ _id, value }]
  // output: { _id: value }
  if (searchData.customFields?.length) {
    search.customFields = searchData.customFields.reduce((results, { _id, value }) => {
      results[_id] = value
      return results
    }, {})
  }

  return search
}

// converts advanced search form data to a server-ready object for the search api
export function generateSearchFromAdvancedSearchFormData (advancedSearchFormData) {
  const search = {}

  if (!advancedSearchFormData) { return search }

  const fieldsToCopyAsIs = ['term']
  fieldsToCopyAsIs.forEach((field) => { search[field] = advancedSearchFormData[field] })

  if (advancedSearchFormData.areaCode) {
    search.areaCode = advancedSearchFormData.areaCode
  }

  // some fields are expected to be excluded when not checked (instead of passing `false`)
  const fieldsToIncludeIfTrue = ['noName', 'potentialStopMessage', 'doNotMessage', 'doNotEmail', 'doNotMessageMe', 'importLock', 'hideChat']
  fieldsToIncludeIfTrue.forEach((name) => {
    if (advancedSearchFormData[name] === true) {
      search[name] = true
    }
  })

  search.tags = (advancedSearchFormData.tags || []).map(({ name }) => name)
  search.sortKey = advancedSearchFormData.sortKey || 'default'

  search.lastReply = advancedSearchFormData.lastReply?.toISOString()
  search.hasRepliedSince = advancedSearchFormData.hasRepliedSince?.toISOString()
  search.lastSent = advancedSearchFormData.lastSent?.toISOString()

  const occasions = ['birthday', 'anniversary']
  occasions.forEach((occasion) => {
    if (advancedSearchFormData[occasion]?.month || advancedSearchFormData[occasion]?.date) {
      search[occasion] = {}
      const fields = ['month', 'date']
      fields.forEach((field) => {
        if (advancedSearchFormData[occasion]?.[field]) {
          search[occasion][field] = parseInt(advancedSearchFormData[occasion][field], 10)
        }
      })
    }
  })

  if (advancedSearchFormData.doubleOptInStatus) {
    if (advancedSearchFormData.doubleOptInStatus === '1') {
      search.doubleOptedIn = true
    } else if (advancedSearchFormData.doubleOptInStatus === '0') {
      search.doubleOptedIn = false
    }
  }

  if (advancedSearchFormData.hasEmail) {
    if (advancedSearchFormData.hasEmail === '1') {
      search.hasEmail = true
    } else if (advancedSearchFormData.hasEmail === '0') {
      search.hasEmail = false
    }
  }

  // handle custom fields
  // input: { _id: value }
  // output: [{ _id, value }]
  search.customFields = entries(advancedSearchFormData.customFields || {}).reduce((results, [id, value]) => {
    if (!value) { return results }
    results.push({ _id: id, value })
    return results
  }, [])

  return search
}

export function generateSummaryFromAdvancedSearchFormData (advancedSearchFormData, userTimeZone, excludeTerm = false) {
  let filterCount = 0
  const summaries = []

  if (!advancedSearchFormData) { return summaries }

  if (advancedSearchFormData.term && !excludeTerm) {
    filterCount++
    summaries.push(advancedSearchFormData.term)
  }

  if (advancedSearchFormData.tags?.length) {
    filterCount++
    summaries.push(advancedSearchFormData.tags.filter((tag) => !!tag?.name).map(({ name }) => name).join(' or '))
  }

  const checkboxFields = [
    ['potentialStopMessage', 'Potential Opt-out'],
    ['doNotMessage', 'Do Not Message'],
    ['doNotEmail', 'Do Not Email'],
    ['importLock', 'Import Lock'],
    ['doNotMessageMe', 'Do Not Message Me'],
    ['hideChat', 'Hide Chat'],
    ['noName', 'No Name']
  ]
  checkboxFields.forEach(([field, summary]) => {
    if (advancedSearchFormData[field] === true) {
      filterCount++
      summaries.push(summary)
    }
  })

  if (advancedSearchFormData.areaCode) {
    filterCount++
    summaries.push(`Area Code: ${advancedSearchFormData.areaCode}`)
  }

  if (has(advancedSearchFormData, 'doubleOptInStatus')) {
    if (advancedSearchFormData.doubleOptInStatus === '1') {
      filterCount++
      summaries.push('Double Opted In')
    } else if (advancedSearchFormData.doubleOptInStatus === '0') {
      filterCount++
      summaries.push('Not Double Opted In')
    }
  }

  if (has(advancedSearchFormData, 'hasEmail')) {
    if (advancedSearchFormData.hasEmail === '1') {
      filterCount++
      summaries.push('Has Email')
    } else if (advancedSearchFormData.hasEmail === '0') {
      filterCount++
      summaries.push('No Email')
    }
  }

  const customFieldValues = values(advancedSearchFormData.customFields || {}).reduce((results, customFieldValue) => {
    if (customFieldValue) { results.push(customFieldValue) }
    return results
  }, [])
  if (customFieldValues.length) {
    filterCount++
    summaries.push(`Custom Fields ${customFieldValues.length}`)
  }

  const occasions = ['birthday', 'anniversary']
  occasions.forEach((occasion) => {
    if (advancedSearchFormData[occasion]?.month || advancedSearchFormData[occasion]?.date) {
      filterCount++
      const month = advancedSearchFormData[occasion]?.month ? months[advancedSearchFormData[occasion].month].label : '*'
      const date = advancedSearchFormData[occasion]?.date || '*'
      const label = occasion === 'birthday' ? 'BDay' : 'Anniv'
      summaries.push(`${label} ${month}/${date}`)
    }
  })

  if (advancedSearchFormData.lastReply) {
    filterCount++
    summaries.push(`No Reply Since ${formatShortDateAndTime(advancedSearchFormData.lastReply, userTimeZone)}`)
  }

  if (advancedSearchFormData.hasRepliedSince) {
    filterCount++
    summaries.push(`Has Replied Since ${formatShortDateAndTime(advancedSearchFormData.hasRepliedSince, userTimeZone)}`)
  }

  if (advancedSearchFormData.lastSent) {
    filterCount++
    summaries.push(`No Outgoing Since ${formatShortDateAndTime(advancedSearchFormData.lastSent, userTimeZone)}`)
  }

  if (filterCount) { summaries.unshift(`${filterCount}`) }
  return summaries
}
