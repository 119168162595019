import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import appFlowShape from '../../../prop-types/shapes/appFlow'
import { del as deleteService } from '../../../services/appFlow'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const DeleteAction = forwardRef(({ appFlow, onSuccess }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${appFlow.name} was deleted`)
    bus.emit('appFlowDeleted', appFlow)
    onSuccess?.()
  }, [appFlow, onSuccess])
  const { call: deleteAppFlow } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteAppFlow([appFlow._id], false)
  }, [deleteAppFlow, appFlow._id])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to delete this app flow? This can not be undone.'
      title={`Delete ${appFlow.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
