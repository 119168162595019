import React from 'react'

import PanelNotFound from '../components/panels/PanelNotFound'
import BroadcastHours from '../screens/settings/BroadcastHours'
import CallForwarding from '../screens/settings/CallForwarding'
import ChangeAssistantPassword from '../screens/settings/ChangeAssistantPassword'
import CustomFieldNames from '../screens/settings/CustomFieldNames'
import DoubleOptIn from '../screens/settings/DoubleOptIn'
import DynamicFields from '../screens/settings/DynamicFields'
import IncomingMessageEmailNotifications from '../screens/settings/IncomingMessageEmailNotifications'
import OptInFormInfo from '../screens/settings/OptInFormInfo'
import OptOutMessage from '../screens/settings/OptOutMessage'
import SetTimeZone from '../screens/settings/SetTimeZone'
import ShortCodeMessages from '../screens/settings/ShortCodeMessages'

const routes = [
  {
    path: 'short-code-messages',
    element: <ShortCodeMessages />
  },
  {
    path: 'call-forwarding',
    element: <CallForwarding />
  },
  {
    path: 'incoming-message-email-notifications',
    element: <IncomingMessageEmailNotifications />
  },
  {
    path: 'dynamic-fields',
    element: <DynamicFields />
  },
  {
    path: 'double-opt-in',
    element: <DoubleOptIn />
  },
  {
    path: 'opt-out-message',
    element: <OptOutMessage />
  },
  {
    path: 'change-assistant-password',
    element: <ChangeAssistantPassword />
  },
  {
    path: 'set-time-zone',
    element: <SetTimeZone />
  },
  {
    path: 'broadcast-hours',
    element: <BroadcastHours />
  },
  {
    path: 'custom-field-names',
    element: <CustomFieldNames />
  },
  {
    path: 'opt-in-form',
    element: <OptInFormInfo />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
