import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../hooks/useLogger'
import { mfaChannelVerb } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import Button from '../../buttons/Button'
import PanelLoading from '../../panels/PanelLoading'

const propTypes = {
  channels: PropType.arrayOf(PropType.string).isRequired,
  dialogRef: PropType.shape({
    current: PropType.shape({
      close: PropType.func.isRequired
    })
  }).isRequired,
  onSelection: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ChooseMethod'] })

const ChooseMethod = ({ channels, dialogRef, onSelection }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleCancelClick = useCallback(() => {
    dialogRef.current.close()
  }, [dialogRef])

  if (!channels.length) {
    return <PanelLoading noHeader />
  }

  return (
    <>
      <p className='mb-5'>
        Select the method you would like to add:
      </p>
      {channels.map((channel) => (
        <Button
          key={channel}
          className='w-full'
          size='md'
          onClick={() => { onSelection({ channel }) }}
        >
          {mfaChannelVerb(channel)}
        </Button>
      ))}
      <Button
        className='mt-5 w-full'
        size='md'
        variant='outline'
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
    </>
  )
}

ChooseMethod.displayName = 'ChooseMethod'
ChooseMethod.propTypes = propTypes
ChooseMethod.defaultProps = defaultProps

export default ChooseMethod
