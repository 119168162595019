import { upperFirst } from 'lodash'
import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import FormDetailSection from './FormDetailSection'
import validationSuite from './NameDetailSection.validations'

const propTypes = {
  object: PropType.shape({
    _id: PropType.string,
    name: PropType.string
  }).isRequired,
  objectName: PropType.string.isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['NameDetailSection'] })

const NameDetailSection = ({ object, objectName, service, onChange, ...rest }) => {
  useLogger({ log, lifecycle: false, tags: [object?._id] })

  const defaultValues = { name: object?.name }
  const formControls = [
    { autoComplete: 'off', label: 'Name', name: 'name', type: 'text' }
  ]

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description={`Name the ${objectName} so it is easy to find later.`}
      formControls={formControls}
      formDescription={`Updating name for ${objectName}`}
      formTitle={`${upperFirst(objectName)} Name`}
      objectId={object._id}
      service={service}
      title='Name'
      validationSuite={validationSuite}
      showEditIcon
      onChange={onChange}
      {...rest}
    >
      {object.name}
    </FormDetailSection>
  )
}

NameDetailSection.displayName = 'NameDetailSection'
NameDetailSection.propTypes = propTypes
NameDetailSection.defaultProps = defaultProps

export default NameDetailSection
