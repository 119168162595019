import PropType from 'prop-types'

export default {
  name: PropType.string,
  contacts: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape({
      _id: PropType.string,
      company: PropType.string,
      firstName: PropType.string,
      lastName: PropType.string,
      phoneNumber: PropType.string
    }))
  })
}
