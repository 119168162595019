import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import FormattedDate from '../../FormattedDate'

const propTypes = {
  date: PropType.instanceOf(Date).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ThreadItemDate'] })

const ThreadItemDate = ({ date }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='text-center label-sm text-neutral-500 mx-4 mb-4 w-full'>
      <FormattedDate date={date} format='shortDateAndTime' />
    </div>
  )
}

ThreadItemDate.displayName = 'ThreadItemDate'
ThreadItemDate.propTypes = propTypes
ThreadItemDate.defaultProps = defaultProps

export default ThreadItemDate
