import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as keywordSearch } from '../../services/keyword'
import List from '../list/List'

import KeywordListItemContent from './KeywordListItemContent'

const propTypes = {
  additionalHandlers: PropType.func,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {},
  queryOverrides: {},
  searchService: keywordSearch
}

const log = logger({ enabled: true, tags: ['KeywordList'] })

const KeywordList = forwardRef(({ additionalHandlers, queryOverrides, searchService, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('keywordCreated', handleBusEvent)
  useBus('keywordCloned', handleBusEvent)
  useBus('keywordUpdated', handleBusEvent)
  useBus('keywordDeleted', handleBusEvent)
  useBus('keywordsDeleted', handleBusEvent)

  additionalHandlers?.('keyword', ref)

  return (
    <List
      ref={ref}
      ListItemContent={KeywordListItemContent}
      placeholder='Search Keywords'
      queryOverrides={queryOverrides}
      resultsKey='keywords'
      searchService={searchService}
      search
      {...listProps}
    />
  )
})

KeywordList.displayName = 'KeywordList'
KeywordList.propTypes = propTypes
KeywordList.defaultProps = defaultProps

export default KeywordList
