import { useEffect, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListLoading'] })

const showDelay = 750

const ListLoading = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const timeoutRef = useRef()
  const [show, setShow] = useState(false)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, showDelay)
    return () => { clearTimeout(timeoutRef.current) }
  }, [])

  if (!show) { return null }
  return (
    <div className='flex flex-col w-full items-center p-4'>
      <span className='animate-pulse'>Loading...</span>
    </div>
  )
}

ListLoading.displayName = 'ListLoading'
ListLoading.propTypes = propTypes
ListLoading.defaultProps = defaultProps

export default ListLoading
