import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import space from '../../../prop-types/shapes/space'
import { update } from '../../../services/space'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './EditAction.validations'

const propTypes = {
  space: PropType.shape(space).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EditAction'] })

const EditAction = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    setCurrentInstance('space', reply.model)
    bus.emit('spaceUpdated', reply.model)
  }, [])
  const { call } = useService(update, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    call(space._id, data)
  }, [call, space._id])

  const defaultValues = { name: space.name }

  const formControls = [
    { autoComplete: 'off', label: 'Name', name: 'name', type: 'text' }
  ]

  return (
    <FormDialog
      ref={formDialogRef}
      defaultValues={defaultValues}
      description='Edit the name of your space'
      formControls={formControls}
      title='Edit Space'
      validateSuite={validationSuite}
      onSubmit={handleSubmit}
    />
  )
})

EditAction.displayName = 'EditAction'
EditAction.propTypes = propTypes
EditAction.defaultProps = defaultProps

export default EditAction
