import * as React from "react";
import { memo } from "react";
const SvgBlogPromoBackground = (props) => (
  <svg
    viewBox="0 0 801 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M383.086-68.091c-17.541-.581-35.378-.552-52.306 2.95-16.927 3.5-33.04 10.858-42.074 22.15-20.975 26.224 2.546 61.373-11.529 90.074-10.002 20.393-37.379 33.388-65.254 39.314-27.875 5.926-57.261 6.266-85.828 9.896-28.567 3.629-57.972 11.391-76.139 28.319-17.295 16.116-21.57 37.668-29.402 57.503-7.833 19.834-22.812 40.808-49.346 46.462-25.944 5.53-53.138-5.876-72.323-20.092-60.595-44.9-70.976-119.776-36.026-178.035C-102.189-27.81-28.554-69.675 52.081-90.273c95.379-24.364 201.698-21.349 296.584 4.078 19.279 5.166 38.134 11.225 56.42 18.133 11.49 4.338 23.57 10.53 36.842 8.932-16.808-7.148-39.803-8.327-58.841-8.957v-.004Z"
      fill="#FEF2F2"
    />
    <path
      d="m552.41 60 7.899 5.923L568.206 60l7.898 5.923L584.001 60l7.899 5.923L599.801 60l7.899 5.923L615.599 60l7.899 5.923L631.399 60"
      stroke="#6366F1"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M219.936 107.973c-.929.288-1.37 1.087-.986 1.783.385.697 1.45 1.028 2.379.739.929-.288 1.37-1.087.985-1.784-.385-.696-1.449-1.027-2.378-.738ZM201.382 108.932c-.231.734.375 1.469 1.353 1.643.979.173 1.959-.282 2.19-1.016.231-.733-.375-1.469-1.353-1.642-.979-.173-1.959.281-2.19 1.015ZM184.942 107.974c-.929.288-1.37 1.087-.986 1.783.385.697 1.45 1.028 2.379.739.929-.288 1.37-1.087.985-1.784-.385-.696-1.449-1.027-2.378-.738ZM166.854 108.269c-.711.534-.71 1.398 0 1.931.711.533 1.864.533 2.575 0 .711-.533.711-1.397 0-1.931-.711-.533-1.864-.533-2.575 0ZM149.945 107.973c-.928.289-1.37 1.088-.985 1.784.385.697 1.45 1.028 2.379.739.929-.289 1.37-1.087.985-1.784-.385-.696-1.45-1.027-2.379-.739ZM131.86 108.269c-.711.533-.711 1.397 0 1.931.71.533 1.863.533 2.574 0 .711-.534.711-1.398 0-1.931-.711-.533-1.864-.533-2.574 0ZM114.954 107.973c-.929.288-1.37 1.087-.985 1.783.384.697 1.449 1.028 2.378.739.929-.288 1.37-1.087.986-1.784-.385-.696-1.45-1.027-2.379-.738ZM220.631 94.421c-1.005 0-1.82.611-1.82 1.365s.815 1.365 1.82 1.365c1.006 0 1.821-.61 1.821-1.365 0-.754-.815-1.365-1.821-1.365ZM203.136 94.421c-1.005 0-1.82.611-1.82 1.365s.815 1.365 1.82 1.365c1.006 0 1.821-.61 1.821-1.365 0-.754-.815-1.365-1.821-1.365ZM185.638 94.421c-1.005 0-1.82.611-1.82 1.365s.815 1.365 1.82 1.365c1.006 0 1.821-.61 1.821-1.365 0-.754-.815-1.365-1.821-1.365ZM168.14 94.421c-1.005 0-1.82.611-1.82 1.365s.815 1.365 1.82 1.365c1.006 0 1.821-.61 1.821-1.365 0-.754-.815-1.365-1.821-1.365ZM150.643 94.421c-1.006 0-1.821.611-1.821 1.365s.815 1.365 1.821 1.365c1.005 0 1.82-.61 1.82-1.365 0-.754-.815-1.365-1.82-1.365ZM133.147 94.421c-1.005 0-1.82.611-1.82 1.365s.815 1.365 1.82 1.365c1.006 0 1.821-.61 1.821-1.365 0-.754-.815-1.365-1.821-1.365ZM115.65 94.421c-1.006 0-1.821.611-1.821 1.365s.815 1.365 1.821 1.365c1.005 0 1.82-.61 1.82-1.365 0-.754-.815-1.365-1.82-1.365Z"
      fill="#A6B4FD"
    />
    <g opacity={0.4} fill="#FEA4B0">
      <path d="M572.163 1.428a76.493 76.493 0 0 0 7.991-8.73v-73.511a76.332 76.332 0 0 0-7.991-8.731V1.428ZM530.211 24.277a115.733 115.733 0 0 0 7.991-2.554V-109.84a115.303 115.303 0 0 0-7.991-2.555V24.277ZM544.195 19.367a106.427 106.427 0 0 0 7.991-3.811V-103.67a106.466 106.466 0 0 0-7.991-3.811V19.367ZM558.179 12.112c2.8-1.742 5.469-3.6 7.991-5.564V-94.664a93.402 93.402 0 0 0-7.991-5.564V12.112ZM496.124 28.884h.125v-145.882h-.125c-2.647 0-5.272.081-7.866.236V28.65c2.594.155 5.219.236 7.866.236v-.002ZM446.305 18.6a111.021 111.021 0 0 0 7.991 3.212v-131.741c-2.737.98-5.404 2.051-7.991 3.212V18.601ZM432.321 10.998a98.597 98.597 0 0 0 7.991 4.687v-119.488a98.744 98.744 0 0 0-7.991 4.688V10.998ZM418.336-.263a84.209 84.209 0 0 0 7.991 7.004V-94.861a84.472 84.472 0 0 0-7.991 7.004V-.263ZM502.242 28.741c2.694-.125 5.359-.333 7.991-.62v-144.357a130.285 130.285 0 0 0-7.991-.62v145.6-.003ZM460.289 23.773c2.61.776 5.277 1.47 7.991 2.077v-139.818a120.414 120.414 0 0 0-7.991 2.077V23.773ZM516.226 27.322a123.64 123.64 0 0 0 7.991-1.528V-113.91a122.23 122.23 0 0 0-7.991-1.528V27.324v-.002ZM474.274 27.035c2.624.452 5.289.825 7.991 1.113v-144.412c-2.702.289-5.367.661-7.991 1.113V27.035Z" />
    </g>
    <ellipse cx={712.811} cy={198.5} rx={135.109} ry={101.5} fill="#F5F3FF" />
    <path
      d="M738.684 97.365V85.25M730.612 91.307h16.143"
      stroke="#8B5CF6"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M348.748 108c-6.616 0-11.98 4.029-11.98 9s5.364 9 11.98 9c6.617 0 11.981-4.029 11.981-9s-5.364-9-11.981-9Z"
      stroke="#FEA4B0"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
const Memo = memo(SvgBlogPromoBackground);
export default Memo;
