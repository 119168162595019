import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/qr-codes/actions/CreateAction'
import { QrCodes as QrCodesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const QrCodeEmptyListContent = () => {
  const createActionRef = useRef()

  const handleAddQrCode = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col mx-auto mb-6 max-w-[475px]'>
        <QrCodesEmptyStateSvg />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Create New QR Code</h1>
        <p className='label-sm text-center'>
          Supercharge your business with a custom QR Code!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleAddQrCode}>
          Create Now
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

QrCodeEmptyListContent.displayName = 'QrCodeEmptyListContent'
QrCodeEmptyListContent.propTypes = propTypes
QrCodeEmptyListContent.defaultProps = defaultProps

export default QrCodeEmptyListContent
