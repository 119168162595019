import { omit } from 'lodash'
import PropType from 'prop-types'

import contactFromJSON from '../models/contact'

import pb from './pb'
import { addRemoveAllInQueryPropTypes, addRemoveSelectedPropTypes, searchPropTypes } from './tag-contact.prop-types'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'tag-contact#search')

  const { tagName } = data
  const body = omit(data, 'tagName')
  const model = {
    conversion: contactFromJSON,
    key: 'contacts',
    multiple: true
  }
  return pb.setupPost(`tags/${encodeURI(tagName)}/contacts/search`, { body, background, model })
}

export function addSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'tag-contact#addSelected')

  const { tagName } = data
  const body = omit(data, 'tagName')
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/add`, { body })
}

export function addAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'tag-contact#addAllInQuery')

  const { tagName } = data
  const body = omit(data, 'tagName')
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/add/all`, { body })
}

export function removeSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'tag-contact#removeSelected')

  const { tagName } = data
  const body = omit(data, 'tagName')
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/remove`, { body })
}

export function removeAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'tag-contact#removeAllInQuery')

  const { tagName } = data
  const body = omit(data, 'tagName')
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/remove/all`, { body })
}
