import ActionMenuItem from '../../components/action-menu/ActionMenuItem'
import Button from '../../components/buttons/Button'
import TextInputIcon from '../../components/forms/TextInputIcon'
import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import { Placeholder } from '../../svg/icons'

const variations = [
  {
    title: 'filled, primary',
    props: {}
  },
  {
    title: 'filled, danger',
    props: {
      theme: 'danger'
    }
  },
  {
    title: 'filled, neutral',
    props: {
      theme: 'neutral'
    }
  },
  {
    title: 'outline, primary',
    props: {
      variant: 'outline'
    }
  },
  {
    title: 'outline, danger',
    props: {
      variant: 'outline',
      theme: 'danger'
    }
  },
  {
    title: 'outline, neutral',
    props: {
      variant: 'outline',
      theme: 'neutral'
    }
  },
  {
    title: 'text, primary',
    props: {
      variant: 'text'
    }
  },
  {
    title: 'text, danger',
    props: {
      variant: 'text',
      theme: 'danger'
    }
  },
  {
    title: 'filled, neutral, internal link',
    props: {
      theme: 'neutral',
      href: '/'
    }
  },
  {
    title: 'filled, neutral, external link',
    props: {
      theme: 'neutral',
      href: 'https://www.projectbroadcast.com'
    }
  }
]

function Buttons () {
  const output = variations.map(({ title, props }) => {
    return (
      <div key={title} className='my-6'>
        <h2 className='headline-xs mb-2'>{title}</h2>
        <div className='flex flex-row w-full mb-2'>
          <Button className='mr-2' {...props}>Label</Button>
          <Button className='mr-2' start={<Placeholder />} {...props}>Label</Button>
          <Button className='mr-2' end={<Placeholder />} {...props}>Label</Button>
          <Button className='mr-2' icon={<Placeholder />} {...props}>Accessible Label</Button>
          <Button
            className='mr-2'
            icon={<Placeholder />}
            circle
            {...props}
          >
            Accessible Label
          </Button>
          <Button className='mr-2 w-full' {...props}>Label</Button>
        </div>
        <div className='flex flex-row w-full mb-2'>
          <Button className='mr-2' size='md' {...props}>Label</Button>
          <Button
            className='mr-2'
            size='md'
            start={<Placeholder />}
            {...props}
          >
            Label
          </Button>
          <Button
            className='mr-2'
            end={<Placeholder />}
            size='md'
            {...props}
          >
            Label
          </Button>
          <Button
            className='mr-2'
            icon={<Placeholder />}
            size='md'
            {...props}
          >
            Accessible Label
          </Button>
          <Button
            className='mr-2'
            icon={<Placeholder />}
            size='md'
            circle
            {...props}
          >
            Accessible Label
          </Button>
          <Button className='mr-2 w-full' size='md' {...props}>Label</Button>
        </div>
        <div className='flex flex-row w-full mb-2'>
          <Button className='mr-2' size='sm' {...props}>Label</Button>
          <Button
            className='mr-2'
            size='sm'
            start={<Placeholder />}
            {...props}
          >
            Label
          </Button>
          <Button
            className='mr-2'
            end={<Placeholder />}
            size='sm'
            {...props}
          >
            Label
          </Button>
          <Button
            className='mr-2'
            icon={<Placeholder />}
            size='sm'
            {...props}
          >
            Accessible Label
          </Button>
          <Button
            className='mr-2'
            icon={<Placeholder />}
            size='sm'
            circle
            {...props}
          >
            Accessible Label
          </Button>
          <Button className='mr-2 w-full' size='sm' {...props}>Label</Button>
        </div>
      </div>
    )
  })
  return (
    <Panel>
      <PanelHeader title='Buttons' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
        <div className='p-4'>
          <h2 className='headline-xs mb-2'>Special Cases and Other Components Relying on Button</h2>
          <div className='flex flex-row w-full mb-2'>
            <Button size='none' theme='none' variant='none'>Minimally Styled Button</Button>
            <PanelHeaderButton icon='sort' />
            <TextInputIcon icon='search' />
            <ActionMenuItem end={<Placeholder />} label='Action Menu Item' start={<Placeholder />} />
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Buttons
