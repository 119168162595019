import { useCallback, useEffect } from 'react'

import { get as getAppFlowService } from '../services/appFlow'

import useCurrentInstance from './useCurrentInstance'
import useService from './useService'

const useCurrentAppFlow = () => {
  const instanceAccessObj = useCurrentInstance({
    resourceName: 'appFlow',
    getService: (appFlowId) => getAppFlowService(appFlowId, { omitAppmixerInfo: true }),
    replyAttrPath: 'json',
    notFoundRedirect: '/app-flows'
  })

  const {
    call: getAppFlow
  } = useService(getAppFlowService, {
    onReplyOk: useCallback((reply) => {
      instanceAccessObj.setAppFlow(reply.json)
    }, [instanceAccessObj])
  })

  useEffect(() => {
    const { appFlow } = instanceAccessObj
    if (appFlow && appFlow.hasDesign && !appFlow.appmixer) {
      getAppFlow(appFlow._id, { omitAppmixerInfo: false }, true)
    }
  }, [getAppFlow, instanceAccessObj])

  return instanceAccessObj
}

export default useCurrentAppFlow
