const isOnlyEmoji = (str) => {
  if (!str || !str.length) { return false }
  // remove spaces
  let strippedStr = str.replace(/\s/g, '')
  if (!strippedStr.length) { return false }
  // remove emojis and spaces
  strippedStr = strippedStr.replace(/\p{Extended_Pictographic}/gu, '')
  return strippedStr.length === 0
}

export default isOnlyEmoji
