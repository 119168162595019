import { pick } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['ViewScheduleAction'] })

const ViewScheduleAction = forwardRef(({ campaign }, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaign?._id] })
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    activate () {
      navigate('/scheduled-broadcasts', {
        state: {
          search: {
            campaignId: campaign._id,
            campaign: pick(campaign, '_id', 'name')
          }
        }
      })
    }
  }), [campaign, navigate])
})

ViewScheduleAction.displayName = 'ViewScheduleAction'
ViewScheduleAction.propTypes = propTypes
ViewScheduleAction.defaultProps = defaultProps

export default ViewScheduleAction
