import * as React from "react";
import { memo } from "react";
const SvgChevronLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.403 12.931 6.679 6.68c.506.519 1.346.519 1.853 0 .52-.52.52-1.347 0-1.854l-5.759-5.759 5.759-5.758c.52-.52.52-1.347 0-1.853A1.334 1.334 0 0 0 15.002 4c-.334 0-.68.133-.934.387L7.39 11.065c-.52.52-.52 1.347 0 1.853l.013.014Z"
    />
  </svg>
);
const Memo = memo(SvgChevronLeft);
export default Memo;
