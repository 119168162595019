import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create } from '../../../services/admin/unlayer-template'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './CreateAction.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    const unlayerTemplate = reply.json
    setCurrentInstance('unlayerTemplate', unlayerTemplate)
    bus.emit('unlayerTemplateCreated', unlayerTemplate)
    navigate(`/admin/unlayer-templates/${unlayerTemplate._id}`)
  }, [navigate])
  const { call } = useService(create, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const createUnlayerTemplateWithName = useCallback((data) => {
    dialogRef.current.close()
    call(data)
  }, [call])

  return (
    <FormDialog
      ref={dialogRef}
      defaultValues={{ name: '', type: 'email' }}
      description='Create a new Unlayer Template'
      formControls={[
        {
          name: 'name',
          placeholder: 'Public Display Name',
          label: 'Name'
        },
        {
          type: 'radiogroup',
          name: 'type',
          label: 'Template Type',
          hint: 'Template Type cannot be changed later',
          options: [
            {
              value: 'email',
              label: 'Email'
            },
            {
              value: 'landingPage',
              label: 'Landing Page'
            }
          ]
        }
      ]}
      title='New Unlayer Template'
      validationSuite={validationSuite}
      onSubmit={createUnlayerTemplateWithName}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
