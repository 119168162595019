import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/spaces/actions/CreateAction'
import { Spaces as SpacesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const EmptyListContent = () => {
  const createActionRef = useRef()

  const handleAddSpace = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col mx-auto mt-12 mb-6 max-w-[375px]'>
        <SpacesEmptyStateSvg />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Create a Space</h1>
        <p className='label-sm text-center'>
          You don't have any discussions yet. Create a space to invite your friends.
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleAddSpace}>
          Create a Space
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
