import { useCallback, useEffect, useMemo, useRef } from 'react'

import logger from '../lib/logger'
import poller from '../lib/poller'
import rollbar from '../lib/rollbar'

import useLogger from './useLogger'
import usePoller from './usePoller'

export function useChatThreadPolling (contactId, onUpdates) {
  return useThreadPolling('chat', contactId, (updates) => {
    if (updates.contactId === contactId) {
      onUpdates(updates)
    } else {
      rollbar.warn('ChatThreadPolling update received for different contactId', {
        actual: updates.contactId,
        expected: contactId
      })
    }
  })
}

export function useSpaceThreadPolling (spaceId, onUpdates) {
  return useThreadPolling('space', spaceId, (updates) => {
    if (updates.spaceId === spaceId) {
      onUpdates(updates)
    } else {
      rollbar.warn('SpaceThreadPolling update received for different spaceId', {
        actual: updates.spaceId,
        expected: spaceId
      })
    }
  })
}

const log = logger({ enabled: true, tags: ['useThreadPolling'] })

const eventNameByType = {
  chat: 'newMessagesInChatThread',
  space: 'newMessagesInSpaceThread'
}
const startMonitoringNameByType = {
  chat: 'startMonitoringChatThread',
  space: 'startMonitoringSpaceThread'
}
const stopMonitoringNameByType = {
  chat: 'stopMonitoringChatThread',
  space: 'stopMonitoringSpaceThread'
}

function useThreadPolling (type, parentId, onUpdates) {
  useLogger({ log, lifecycle: true, tags: [type, parentId] })

  const onUpdatesRef = useRef(onUpdates)

  const eventName = useMemo(() => eventNameByType[type], [type])
  const startMonitoring = useMemo(() => poller[startMonitoringNameByType[type]], [type])
  const stopMonitoring = useMemo(() => poller[stopMonitoringNameByType[type]], [type])

  useEffect(() => {
    log.debug('onUpdates handler changed')
    onUpdatesRef.current = onUpdates
  }, [onUpdates])

  const handleUpdates = useCallback((updates) => {
    log.debug('got updates from poller', updates)
    onUpdatesRef.current?.(updates)
  }, [])

  usePoller(eventName, handleUpdates)

  const handleLastItemChange = useCallback((lastItem) => {
    log.debug('lastItemId changed', lastItem?._id)
    if (!parentId || !lastItem?._id) {
      stopMonitoring()
    } else {
      startMonitoring(parentId, lastItem?._id)
    }
  }, [parentId, startMonitoring, stopMonitoring])

  useEffect(() => {
    return () => {
      log.debug('unmounted or stopMonitoring method changed... stopping monitoring')
      stopMonitoring()
    }
  }, [stopMonitoring])

  return { handleLastItemChange }
}
