import PropType from 'prop-types'

import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  spaceId: PropType.string.isRequired,
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}

export const addSelectedPropTypes = {
  spaceId: PropType.string.isRequired,
  contactIds: PropType.arrayOf(PropType.string).isRequired
}

export const addRemoveAllInQueryPropTypes = {
  spaceId: PropType.string.isRequired,
  term: PropType.string
}

export const removeSelectedPropTypes = {
  spaceId: PropType.string.isRequired,
  userIds: PropType.arrayOf(PropType.string).isRequired
}
