import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import TagList from '../../../components/tags/TagList'
import TagListEmptySearchContent from '../../../components/tags/TagListEmptySearchResultsContent'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'
import { addAll as addAllTagsService, add as addTagsService } from '../../../services/contact-tag'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: true, tags: ['TagsDetail'] })

const TagsDetail = ({ contact, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const listDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Tag(s) added')
    onChange?.(reply)
  }, [onChange])
  const { call: putTags } = useService(addTagsService, { onReplyOk: handleReplyOk })
  const { call: putAllTags } = useService(addAllTagsService, { onReplyOk: handleReplyOk })

  const handleInitialEdit = useCallback(() => { listDialogRef.current.open() }, [])

  const handleAddSelected = useCallback((selected) => {
    const tags = selected.map((tagSearchResult) => tagSearchResult.name)
    putTags({ contactId: contact._id, tags })
  }, [contact, putTags])

  const handleAddAll = useCallback((query) => {
    putAllTags({ contactId: contact._id, ...query })
  }, [contact, putAllTags])

  const addMissingTag = useCallback((newTagName) => {
    putTags({ contactId: contact._id, tags: [newTagName] })
    listDialogRef.current.close()
  }, [contact, putTags])

  const value = contact?.tags.join(', ')
  const count = contact?.tags.length

  return (
    <>
      <DetailSection
        count={count}
        description='Associate tags to contacts to stay organized.'
        editHref='tags'
        editIcon='chevron'
        initialEditButtonText='Add Tags'
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title='Tags'
        onInitialEditClick={handleInitialEdit}
      >
        {value}
      </DetailSection>
      <ListDialog
        ref={listDialogRef}
        additionalContext={{ addMissingTag }}
        EmptySearchResultsListContent={TagListEmptySearchContent}
        list={TagList}
        subtitle={contact?.formattedName}
        title='Add tags'
        type='add'
        search
        onSubmitAllInQuery={handleAddAll}
        onSubmitSelected={handleAddSelected}
      />
    </>
  )
}

TagsDetail.displayName = 'TagsDetail'
TagsDetail.propTypes = propTypes
TagsDetail.defaultProps = defaultProps

export default TagsDetail
