import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import CloneAction from '../../../components/templates/actions/CloneAction'
import DeleteAction from '../../../components/templates/actions/DeleteAction'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import templateShape from '../../../prop-types/shapes/template'

const propTypes = {
  template: PropType.shape(templateShape)
}

const defaultProps = {
  template: null
}

const log = logger({ enabled: false, tags: ['VoiceDropsDetailHeaderMore'] })

const VoiceDropsDetailHeaderMore = ({ template }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const cloneActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/voice-drops', { replace: true }), [navigateInStack])

  const onCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])

  return !!template && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Clone Voice Drop' onClick={onCloneSelected} />
        <ActionMenuItem label='Delete Voice Drop' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} template={template} onSuccess={onDeleteSuccess} />
      <CloneAction ref={cloneActionRef} template={template} />
    </>
  )
}

VoiceDropsDetailHeaderMore.displayName = 'VoiceDropsDetailHeaderMore'
VoiceDropsDetailHeaderMore.propTypes = propTypes
VoiceDropsDetailHeaderMore.defaultProps = defaultProps

export default VoiceDropsDetailHeaderMore
