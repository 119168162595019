import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { allow as allowIntegrationService } from '../../../services/integration'
import { provisionNewNumber as provisionNewNumberService } from '../../../services/subscription'
import Header from '../shared/Header'

const propTypes = {
  integration: PropType.shape({
    key: PropType.string.isRequired
  }),
  isReactivation: PropType.bool,
  numbers: PropType.objectOf(
    PropType.arrayOf(
      PropType.shape({
        nationalNumber: PropType.string.isRequired,
        number: PropType.string.isRequired
      })
    )
  ),
  phoneNumber: PropType.string,
  referral: PropType.shape({
    refId: PropType.string
  }),
  onSuccess: PropType.func
}

const defaultProps = {
  integration: null,
  isReactivation: false,
  numbers: null,
  onSuccess: null,
  phoneNumber: null,
  referral: null
}

const log = logger({ enabled: false, tags: ['NumberUnavailableForm'] })

const NumberUnavailableForm = ({ numbers, phoneNumber, integration, onSuccess, referral, isReactivation }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formRef = useRef()
  const { goToStep } = useWizard()
  const [selectedNumber, setSelectedNumber] = useState()
  const [disabledNumbers, setDisabledNumbers] = useState([phoneNumber])
  const optionsData = []

  const onReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const redirectToWelcomeScreen = useCallback(() => {
    goToStep(4)
  }, [goToStep])

  const handleAllowIntegrationReplyOk = useCallback((reply) => {
    const redirect = reply.json?.redirect
    if (redirect) {
      window.location.replace(redirect)
    } else {
      redirectToWelcomeScreen()
    }
  }, [redirectToWelcomeScreen])
  const { call: allowIntegration } = useService(allowIntegrationService, { onReplyOk: handleAllowIntegrationReplyOk })

  const onReplyOk = useCallback(() => {
    onSuccess({ phoneNumber: selectedNumber })
    if (integration) {
      allowIntegration(integration.key, window.location.search)
    } else {
      redirectToWelcomeScreen()
    }
  }, [allowIntegration, integration, onSuccess, redirectToWelcomeScreen, selectedNumber])

  const onReplyNotOk = useCallback(() => {
    setDisabledNumbers((prev) => [...prev, selectedNumber])
  }, [selectedNumber])

  const { call: provisionNewNumber } = useService(provisionNewNumberService, { onReplyOk, onReplyNotOk, onReply })

  const onSubmit = useCallback(({ number }) => {
    log.debug('submit', number)
    setSelectedNumber(number)
    provisionNewNumber({ number, referral })
  }, [provisionNewNumber, referral])

  if (!numbers) {
    return ''
  }

  if (numbers) {
    for (const key in numbers) {
      optionsData.push({
        label: key,
        value: numbers[key].map((info) => {
          return {
            label: info.nationalNumber,
            value: info.number,
            disabled: disabledNumbers.includes(info.number)
          }
        })
      })
    }
  }

  const formControls = [{
    autoFocus: true,
    name: 'number',
    type: 'select',
    label: 'Project Broadcast Phone Number',
    placeholder: 'Choose another phone number',
    hint: 'This will be the phone number all of your Project Broadcast messages originate from.',
    required: true,
    options: optionsData
  }]

  return (
    <>
      <Header
        heading={`${isReactivation ? 'Reactivate' : 'Create'} Account`}
        title='Select a Number'
      />
      <div className='mt-5'>
        <p className='mb-5'>
          Your account was successfully
          {' '}
          {isReactivation ? 'reactivated' : 'created'}
          ! However, we were unable to register your number. Please select a new Project Broadcast phone number.
        </p>
        <Form
          ref={formRef}
          defaultValues={{}}
          formControls={formControls}
          id='selectNumberForm'
          preventMultipleSubmits
          onSubmit={onSubmit}
        />
        <Button
          className='my-4 w-full'
          form='selectNumberForm'
          size='md'
          type='submit'
        >
          Complete
          {' '}
          {isReactivation ? 'Reactivation' : 'Sign-Up'}
        </Button>
      </div>
    </>
  )
}

NumberUnavailableForm.displayName = 'NumberUnavailableForm'
NumberUnavailableForm.propTypes = propTypes
NumberUnavailableForm.defaultProps = defaultProps

export default NumberUnavailableForm
