import { useCallback } from 'react'

import Banner, { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { allowMessagesFromShortCodes as allowMessagesFromShortCodesService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const ShortCodeMessages = () => {
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('Short Code Messages updated successfully.')
  }, [])
  const { call: updateAllowShortCodeMessages } = useServiceAndAction(allowMessagesFromShortCodesService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='shortCodeMessagesForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Short Code Messages'
      />
      <PanelContent className='p-5'>
        <Form
          defaultValues={{
            allowMessagesFromShortCodes: currentUser.allowMessagesFromShortCodes
          }}
          formControls={[{
            type: 'checkbox',
            name: 'allowMessagesFromShortCodes',
            label: 'When checked, this will deliver inbound messages from short codes via the Project Broadcast contact.',
            title: 'Allow Short Code Messages'
          }]}
          id='shortCodeMessagesForm'
          onSubmit={updateAllowShortCodeMessages}
        />
        <Banner className='mb-5' type='warning'>
          Please make sure the Project Broadcast contact is not currently archived or marked as Do Not Message Me.
        </Banner>
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='shortCodeMessagesForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ShortCodeMessages.displayName = 'ShortCodeMessages'

export default ShortCodeMessages
