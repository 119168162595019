import * as React from "react";
import { memo } from "react";
const SvgAirplane = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.54.295C2.685-.706.324 1.054 1.164 3.191a.988.988 0 0 0 .011.026l3.743 8.778-3.743 8.778a1 1 0 0 0-.01.026c-.84 2.137 1.52 3.897 3.375 2.896l.002-.001 19.94-10.82a1 1 0 0 0-.022-1.77L4.542.296H4.54Zm15.525 10.7L3.59 2.055a.398.398 0 0 0-.464.044.355.355 0 0 0-.116.168.28.28 0 0 0 .012.183l3.644 8.545h13.4Zm-13.4 2L3.023 21.54a.279.279 0 0 0-.012.182.355.355 0 0 0 .116.168.398.398 0 0 0 .463.044h.001l16.476-8.94h-13.4Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAirplane);
export default Memo;
