import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  referrerName: PropType.string.isRequired,
  credits: PropType.number,
  frequency: PropType.oneOf(['Monthly', 'Yearly']),
  percentOffMonth: PropType.number,
  percentOffYear: PropType.number
}

const defaultProps = {
  credits: 0,
  frequency: null,
  percentOffMonth: 0,
  percentOffYear: 0
}

const log = logger({ enabled: false, tags: ['ReferralPromoTextFragment'] })

const ReferralPromoTextFragment = ({ credits, frequency, percentOffMonth, percentOffYear, referrerName }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!referrerName || !(percentOffMonth || percentOffYear || credits > 0)) {
    return null
  }

  const discountSnippet = percentOffMonth > 0 || percentOffYear > 0
    ? (
        frequency
          ? (
              frequency.toLowerCase() === 'yearly'
                ? `${percentOffYear}% off your first year of Project Broadcast`
                : `${percentOffMonth}% off your first month of Project Broadcast`
            )
          : `${percentOffMonth}% off your first month of a monthly subscription or ${percentOffYear}% off your first year on a yearly subscription of Project Broadcast`
      )
    : null

  const creditsSnippet = credits > 0
    ? `${credits.toLocaleString()} bonus credits added to your account after signup`
    : null

  return (
    <p className='my-5'>
      <strong>Congrats!</strong>
      {' '}
      You have been referred to Project Broadcast by
      {' '}
      <strong>{referrerName}</strong>
      ! Make sure to thank them for the referral because you
      are gonna get an additional
      {' '}
      {discountSnippet && creditsSnippet
        ? (
          <>
            <strong>
              {discountSnippet}
              !
            </strong>
            {' '}
            You are also gonna get an additional
            {' '}
            <strong>
              {creditsSnippet}
              !
            </strong>
          </>
          )
        : (
          <strong>
            {discountSnippet || creditsSnippet}
            !
          </strong>
          )}
      {' '}
      Pretty sweet huh!?!
    </p>
  )
}

ReferralPromoTextFragment.displayName = 'ReferralPromoTextFragment'
ReferralPromoTextFragment.propTypes = propTypes
ReferralPromoTextFragment.defaultProps = defaultProps

export default ReferralPromoTextFragment
