import { mapKeys, omit } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { warning } from '../../components/banners/Banner'
import { getPreImportInfo } from '../../helpers/contactCsv'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useStackBackButton from '../../hooks/useStackBackButton'
import logger from '../../lib/logger'
import { importContacts as importService } from '../../services/contact'

import FromDevice from './import/FromDevice'
import { ImportContext } from './import/ImportContext'
import ImportResults from './import/ImportResults'
import MapFields from './import/MapFields'
import SelectFile from './import/SelectFile'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ImportContacts'] })

const ImportContacts = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const { handleBackButtonClick: handleStackBackButtonClick } = useStackBackButton()
  const [currentStep, setCurrentStep] = useState('select-file')
  const [csvFile, setCsvFile] = useState(null)
  const [preImportInfo, setPreImportInfo] = useState(null)
  const [importResults, setImportResults] = useState(null)

  const { call: importCall } = useService(importService, {
    onReplyOk: useCallback((reply) => {
      setImportResults(reply.json)
      setCurrentStep('import-results')
    }, [])
  })

  const handleBackButtonClick = useCallback(() => {
    if (['from-device', 'map-fields', 'import-results'].includes(currentStep)) {
      setCurrentStep('select-file')
      setCsvFile(null)
    } else {
      handleStackBackButtonClick()
    }
  }, [currentStep, handleStackBackButtonClick])

  const handleFileSelected = useCallback(async (selectedCsvFile) => {
    try {
      const csvFileInfo = await getPreImportInfo(selectedCsvFile, currentUser.customFields)
      setCsvFile(selectedCsvFile)
      setPreImportInfo(csvFileInfo)
      setCurrentStep('map-fields')
    } catch (ex) {
      warning(ex.message)
    }
  }, [currentUser])

  const handleMappingsSelected = useCallback((mappings) => {
    const formData = {
      mappings: mapKeys(omit(mappings, 'additionalTags'), (v, k) => k.toLowerCase()),
      tags: mappings.additionalTags,
      upload: csvFile.uploadData
    }
    importCall(formData)
  }, [csvFile, importCall])

  const handleFromDevice = useCallback(() => {
    setCurrentStep('from-device')
  }, [])

  const handleFromDeviceImportAllContacts = useCallback((csvFileLikeObj, mappings) => {
    log.debug(csvFileLikeObj, mappings)
    const formData = {
      mappings: mapKeys(omit(mappings, 'additionalTags'), (v, k) => k.toLowerCase()),
      tags: mappings.additionalTags,
      upload: csvFileLikeObj.uploadData
    }
    importCall(formData)
  }, [importCall])

  const importContext = useMemo(() => ({
    handleBackButtonClick,
    title: 'Import Contacts',
    subtitle: csvFile ? csvFile.name : null
  }), [csvFile, handleBackButtonClick])

  return (
    <ImportContext.Provider value={importContext}>
      {currentStep === 'select-file' && (
        <SelectFile onFileSelected={handleFileSelected} onFromDeviceSelected={handleFromDevice} />
      )}
      {currentStep === 'from-device' && (
        <FromDevice onImportAllContacts={handleFromDeviceImportAllContacts} />
      )}
      {currentStep === 'map-fields' && (
        <MapFields preImportInfo={preImportInfo} onMappingsSelected={handleMappingsSelected} />
      )}
      {currentStep === 'import-results' && (
        <ImportResults importResults={importResults} />
      )}
    </ImportContext.Provider>
  )
}

ImportContacts.displayName = 'ImportContacts'
ImportContacts.propTypes = propTypes
ImportContacts.defaultProps = defaultProps

export default ImportContacts
