import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import pb from '../../services/pb'
import { reset } from '../../store'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'Unauthorized'] })

const Unauthorized = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    function handlePbUnauthorized () {
      if (currentUser) {
        navigate('/logout')
      } else {
        reset('pb/401')
      }
    }

    pb.events.on('unauthorized', handlePbUnauthorized)

    return () => {
      pb.events.off('unauthorized', handlePbUnauthorized)
    }
  }, [currentUser, navigate])

  return null
}

Unauthorized.displayName = 'Unauthorized'
Unauthorized.propTypes = propTypes
Unauthorized.defaultProps = defaultProps

export default Unauthorized
