import 'react-app-polyfill/stable'

// Unfortunately react-app-polyfill does not indicate the version of core-js correctly and this limits what stable polyfills are available.
// This means we have to manually includes some polyfills :(
// See:
// * https://github.com/facebook/create-react-app/pull/9021
// * https://github.com/facebook/create-react-app/issues/8875
import * as at from 'array.prototype.at'

at.shim()
