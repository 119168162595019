import classNames from 'classnames'
import PropType from 'prop-types'

import { useSideMenu } from '../../contexts/SideMenuContext'

import MenuItem from './side-menu-content/MenuItem'

const propTypes = {
  navConfig: PropType.arrayOf(PropType.shape({
    icon: PropType.element.isRequired,
    label: PropType.string.isRequired,
    indicator: PropType.string, // global store prop path to check in order to display indicator
    path: PropType.string,
    stack: PropType.string,
    subConfig: PropType.arrayOf(PropType.shape({
      label: PropType.string.isRequired,
      path: PropType.string.isRequired,
      stack: PropType.string
    }))
  })).isRequired
}

const defaultProps = {}

const SideMenuContent = ({ navConfig }) => {
  const { userMenuVisible } = useSideMenu()

  const navItems = navConfig.map((navItemConfig) => <MenuItem key={navItemConfig.label} {...navItemConfig} />)

  const computedClassName = classNames(
    'max-h-full overflow-y-auto overflow-x-hidden',
    {
      hidden: userMenuVisible
    }
  )

  return (
    <ul className={computedClassName}>
      {navItems}
    </ul>
  )
}

SideMenuContent.displayName = 'SideMenuContent'
SideMenuContent.propTypes = propTypes
SideMenuContent.defaultProps = defaultProps

export default SideMenuContent
