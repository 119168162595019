import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { addStackEntryFromLocation } from '../store/actions/stacks'

function StackHistory () {
  const location = useLocation()
  useEffect(() => {
    addStackEntryFromLocation(location)
  }, [location])
  return undefined
}

export default StackHistory
