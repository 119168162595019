import * as React from "react";
import { memo } from "react";
const SvgMinusMini = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="M17.55 10.8v3.15H6.3V10.8z" />
  </svg>
);
const Memo = memo(SvgMinusMini);
export default Memo;
