import classNames from 'classnames'
import PropType from 'prop-types'
import { useEffect } from 'react'

import { usePanel } from '../../contexts/PanelContext'

import PanelHeaderTitle from './panel-header/PanelHeaderTitle'

const propTypes = {
  count: PropType.number,
  end: PropType.element,
  href: PropType.string,
  start: PropType.element,
  subtitle: PropType.string,
  title: PropType.string,
  onTitleClick: PropType.func
}

const defaultProps = {
  count: null,
  end: null,
  href: null,
  onTitleClick () {},
  start: null,
  subtitle: null,
  title: 'Project Broadcast'
}

const PanelHeader = ({ count, end, href, start, subtitle, title, onTitleClick }) => {
  const { isNative, inDialog, inFirst } = usePanel()

  useEffect(() => {
    if (inFirst) {
      document.title = `Project Broadcast - ${title}`
    }
  }, [inFirst, title])

  const baseClassNames = classNames(
    'relative flex shrink-0 items-center justify-between w-full z-40 h-[52px] px-[8px]',
    {
      'text-center': inDialog
    }
  )

  const baseWrapperClassNames = 'relative'
  const nonNativeWrapperClassNames = classNames(
    baseWrapperClassNames,
    {
      'pt-safe px-safe': !inDialog
    },
    'text-black bg-white border-b border-neutral-200'
  )
  const nativeWrapperClassNames = classNames(
    baseWrapperClassNames,
    {
      'pt-safe px-safe': !inDialog
    },
    'text-white'
  )

  const startEndClassNames = 'relative flex basis-0 flex-row flex-nowrap items-center z-10'
  const startClassNames = classNames(startEndClassNames, 'justify-self-start', { 'flex-grow': inDialog })
  const endClassNames = classNames(startEndClassNames, 'justify-self-end flex-grow justify-end')

  return (
    <div className={isNative && !inDialog ? nativeWrapperClassNames : nonNativeWrapperClassNames}>
      <header className={baseClassNames}>
        <div className={startClassNames}>
          {start}
        </div>
        <PanelHeaderTitle
          count={count}
          href={href}
          subtitle={subtitle}
          title={title}
          onClick={onTitleClick}
        />
        <div className={endClassNames}>
          {end}
        </div>
      </header>
    </div>
  )
}

PanelHeader.displayName = 'PanelHeader'
PanelHeader.propTypes = propTypes
PanelHeader.defaultProps = defaultProps

export default PanelHeader
