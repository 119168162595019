import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import chatShape from '../../../prop-types/shapes/chat'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import Button from '../../buttons/Button'
import BulkArchiveAction from '../../contacts/actions/BulkArchiveAction'
import BulkMarkAsDoNotMessageAction from '../../contacts/actions/BulkMarkAsDoNotMessageAction'
import BulkMarkAsNotAnOptOutAction from '../../contacts/actions/BulkMarkAsNotAnOptOutAction'

import BulkHideChatAction from './BulkHideChatAction'
import BulkMarkAsRead from './BulkMarkAsRead'
import BulkMarkAsUnread from './BulkMarkAsUnread'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(chatShape)).isRequired
}

const defaultProps = {
}

const BulkEditActions = ({ selectedItems }) => {
  const archiveActionRef = useRef()
  const hideChatActionRef = useRef()
  const markAsReadActionRef = useRef()
  const markAsUnreadActionRef = useRef()
  const markAsDoNotMessageActionRef = useRef()
  const markAsNotAnOptOutActionRef = useRef()

  const handleArchiveAction = useCallback(() => archiveActionRef.current.activate(), [])
  const handleHideChatAction = useCallback(() => hideChatActionRef.current.activate(), [])
  const handleMarkAsReadAction = useCallback(() => markAsReadActionRef.current.activate(), [])
  const handleMarkAsUnreadAction = useCallback(() => markAsUnreadActionRef.current.activate(), [])
  const handleMarkAsDoNotMessageAction = useCallback(() => markAsDoNotMessageActionRef.current.activate(), [])
  const handleMarkAsNotAnOptOutAction = useCallback(() => markAsNotAnOptOutActionRef.current.activate(), [])

  const actionsTrigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={actionsTrigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <ActionMenuItem label='Hide Chats' onClick={handleHideChatAction} />
        <ActionMenuItem label='Mark as Read' onClick={handleMarkAsReadAction} />
        <ActionMenuItem label='Mark as Unread' onClick={handleMarkAsUnreadAction} />
        <ActionMenuItem label='Not an Opt-out' onClick={handleMarkAsNotAnOptOutAction} />
        <ActionMenuItem label='Mark as Do Not Message' onClick={handleMarkAsDoNotMessageAction} />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Archive Contacts' onClick={handleArchiveAction} />
      </ActionMenu>
      <BulkArchiveAction ref={archiveActionRef} contacts={selectedItems} />
      <BulkHideChatAction ref={hideChatActionRef} chats={selectedItems} />
      <BulkMarkAsRead ref={markAsReadActionRef} chats={selectedItems} />
      <BulkMarkAsUnread ref={markAsUnreadActionRef} chats={selectedItems} />
      <BulkMarkAsDoNotMessageAction ref={markAsDoNotMessageActionRef} contacts={selectedItems} />
      <BulkMarkAsNotAnOptOutAction ref={markAsNotAnOptOutActionRef} contacts={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
