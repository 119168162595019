import { useCallback, useRef } from 'react'

import useCurrentStackKey from '../../hooks/useCurrentStackKey'
import { contentTypeFromStackKey } from '../../lib/folder'
import { Folders as FoldersEmptyStateSvg } from '../../svg/empty-states'
import Button from '../buttons/Button'

import CreateFolderAction from './actions/CreateFolderAction'

const propTypes = {}

const defaultProps = {}

const FolderEmptyListContent = () => {
  const createActionRef = useRef()

  const stackKey = useCurrentStackKey()
  const contentType = contentTypeFromStackKey(stackKey)

  const handleCreateFolder = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col mx-auto my-6 max-w-[475px]'>
        <FoldersEmptyStateSvg className='my-6' />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>Create Folder</h1>
        <p className='label-sm text-center'>
          Begin by Creating Your First Folder to Organize Your Content
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleCreateFolder}>
          Create Folder
        </Button>
      </div>
      <CreateFolderAction ref={createActionRef} contentType={contentType} />
    </>
  )
}

FolderEmptyListContent.displayName = 'FolderEmptyListContent'
FolderEmptyListContent.propTypes = propTypes
FolderEmptyListContent.defaultProps = defaultProps

export default FolderEmptyListContent
