import Avatar from '../../components/avatar/Avatar'
import ListActionLink from '../../components/links/ListActionLink'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import { useApp } from '../../contexts/AppContext'
import useCurrentUser from '../../hooks/useCurrentUser'

function AccountActionsList () {
  const currentUser = useCurrentUser()
  const { isWeb } = useApp()

  return (
    <Panel>
      <PanelHeader title='Account' />
      <PanelContent className='text-black'>
        <div className='p-5 flex flex-col items-center justify-items-center gap-3'>
          <Avatar contactOrUser={currentUser} size='md' />
          <span className='m-1 label-md-strong'>{currentUser.formattedName}</span>
        </div>
        <div className='border-t border-neutral-200'>
          <ListActionLink title='Profile' to='profile' />
          <ListActionLink title='The Campaign Registry&trade;' to='tcr' />
          <ListActionLink title='Account Managers' to='managers' />
          {isWeb ? <ListActionLink title='Affiliate Program' to='affiliate-program' /> : null}
          {isWeb ? <ListActionLink title='API Key' to='api-key' /> : null}
          <ListActionLink title='Change Password' to='change-password' />
          {isWeb ? <ListActionLink title='Integrations' to='integrations' /> : null}
          <ListActionLink title='Multi-Factor Authentication' to='multi-factor-authentication' />
          {currentUser.stripeId ? <ListActionLink title='Referral Program' to='referral-program' /> : null}
          <ListActionLink title='Credit Usage' to='credit-usage' />
          <ListActionLink title='Stats' to='stats' />
          <ListActionLink title='Sign Out From All Devices' to='sign-out-all' />
          <ListActionLink title='User Custom Fields' to='user-custom-fields' />
          <ListActionLink title='Your Contact Card' to='contact-card' />
          <ListActionLink title='CAN-SPAM Compliance' to='can-spam-compliance' />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default AccountActionsList
