import { includes } from 'lodash'
import { useCallback } from 'react'

import MessageDetail from '../../components/detail-sections/MessageDetailSection'
import WarningDetailSection from '../../components/detail-sections/WarningDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { campaignTypeLabel } from '../../helpers/campaign'
import useCurrentCampaign from '../../hooks/useCurrentCampaign'
import useCurrentCampaignEvent from '../../hooks/useCurrentCampaignEvent'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateCampaignEventService } from '../../services/campaign-event'

import EventContactsDetail from './events-detail/EventContactsDetail'
import EventDayDelayDetail from './events-detail/EventDayDelayDetail'
import EventDaysBeforeDetail from './events-detail/EventDaysBeforeDetail'
import EventDetailHeaderMoreMenu from './events-detail/EventDetailHeaderMoreMenu'
import EventHourDelayDetail from './events-detail/EventHourDelayDetail'
import EventKeywordDetail from './events-detail/EventKeywordDetail'
import EventMinuteDelayDetail from './events-detail/EventMinuteDelayDetail'
import EventNameDetail from './events-detail/EventNameDetail'
import EventRepeatDetail from './events-detail/EventRepeatDetail'
import EventScheduleDateDetail from './events-detail/EventScheduleDateDetail'
import EventTimeOfDayDetail from './events-detail/EventTimeOfDayDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignEventsDetail'] })

const CampaignEventsDetail = () => {
  const { campaignId, campaign, loading: campaignLoading } = useCurrentCampaign()
  const { campaignEventId, campaignEvent, loading: campaignEventLoading, setCampaignEvent } = useCurrentCampaignEvent()
  const loaded = !campaignLoading && !campaignEventLoading && !!campaign && !!campaignEvent

  useLogger({ log, lifecycle: false, tags: [campaignId, campaignEventId] })

  const updateCampaignEventServiceWrapper = (_ignored, updateData) => {
    return updateCampaignEventService(campaignId, campaignEventId, updateData)
  }

  const handleCampaignEventUpdated = useCallback((updateReply) => {
    const updatedCampaignEvent = updateReply.model
    setCampaignEvent(updatedCampaignEvent)
    bus.emit('campaignEventUpdated', updatedCampaignEvent)
  }, [setCampaignEvent])

  if (!loaded) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<EventDetailHeaderMoreMenu campaignEvent={campaignEvent} />}
        start={<PanelHeaderBackButton />}
        subtitle={campaign.name}
        title={`${campaignTypeLabel(campaign.type)} Message`}
      />
      <PanelContent className='p-5'>
        <WarningDetailSection text='Please note that changes made here will propagate to all scheduled broadcasts originated by this campaign message.' />
        <EventNameDetail
          campaignEvent={campaignEvent}
          service={updateCampaignEventServiceWrapper}
          onChange={handleCampaignEventUpdated}
        />
        <MessageDetail
          description='You have something to say? Make it personal. Make it awesome!'
          message={campaignEvent}
          service={updateCampaignEventServiceWrapper}
          onChange={handleCampaignEventUpdated}
        />
        {includes(['drip'], campaign.type) && (
          <EventDayDelayDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['countdown', 'event'], campaign.type) && (
          <EventDaysBeforeDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['schedule', 'repeat'], campaign.type) && (
          <EventScheduleDateDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['drip', 'birthday', 'anniversary', 'countdown', 'event'], campaign.type) && (
          <EventTimeOfDayDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['delay'], campaign.type) && (
          <EventHourDelayDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['delay'], campaign.type) && (
          <EventMinuteDelayDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        {includes(['repeat'], campaign.type) && (
          <EventRepeatDetail
            campaignEvent={campaignEvent}
            service={updateCampaignEventServiceWrapper}
            onChange={handleCampaignEventUpdated}
          />
        )}
        <EventKeywordDetail
          campaignEvent={campaignEvent}
          service={updateCampaignEventServiceWrapper}
          onChange={handleCampaignEventUpdated}
        />
        <EventContactsDetail
          campaignEvent={campaignEvent}
          type='queued'
        />
        <EventContactsDetail
          campaignEvent={campaignEvent}
          type='processed'
        />
      </PanelContent>
    </Panel>
  )
}

CampaignEventsDetail.displayName = 'CampaignEventsDetail'
CampaignEventsDetail.propTypes = propTypes
CampaignEventsDetail.defaultProps = defaultProps

export default CampaignEventsDetail
