import { useCallback, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelNotFound from '../../components/panels/PanelNotFound'
import VersionText from '../../components/VersionText'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import { formatPhone } from '../../lib/formatters'
import logger from '../../lib/logger'
import { HorizontalColor } from '../../svg/branding'

import SelectNumberForm from './select-phone-number/SelectNumberForm'
import SelectStateForm from './select-phone-number/SelectStateForm'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['SelectPhoneNumber'] })

const SelectPhoneNumber = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const [phoneNumbers, setPhoneNumbers] = useState(null)

  const handleNumbers = useCallback((data) => {
    const numbers = data?.numbers
    log.debug('handleStateFormSubmit', numbers)
    setPhoneNumbers(numbers)
  }, [])

  if (currentUser?.phoneNumberType !== 'needs-provisioning') {
    return <PanelNotFound />
  }

  return (
    <Panel hideAivaChatButton hideMenuToggleButton>
      <PanelContent className='flex flex-col gap-y-6 py-8 px-4 items-center'>
        <div className='flex flex-col w-full h-full pb-safe overflow-auto'>
          <div className='w-full sm:w-[600px] p-5 m-auto'>
            <div className='sm:w-96 m-auto'>
              <HorizontalColor />
            </div>
            <h1 className='m-4 headline-md text-center'>
              Oh no!
            </h1>
            <p className='mb-4'>
              We were unable to register the number
              {' '}
              <span className='whitespace-nowrap'>
                {formatPhone(currentUser.phoneNumber)}
              </span>
              {'. '}
            </p>
            {phoneNumbers
              ? <SelectNumberForm numbers={phoneNumbers} />
              : <SelectStateForm onSubmit={handleNumbers} />}
          </div>
        </div>
        <span className='label-xs text-center pb-2'>
          <VersionText />
        </span>
      </PanelContent>
    </Panel>
  )
}

SelectPhoneNumber.displayName = 'SelectPhoneNumber'
SelectPhoneNumber.propTypes = propTypes
SelectPhoneNumber.defaultProps = defaultProps

export default SelectPhoneNumber
