import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import configurableFormShape from '../../../prop-types/shapes/configurableForm'
import { bulkDelete as bulkDeleteService } from '../../../services/configurableForm'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  configurableForms: PropType.arrayOf(PropType.shape(configurableFormShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ configurableForms }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success('Configurable Forms have been deleted')
    bus.emit('configurableFormsDeleted', configurableForms)
    bus.emit('closeBulkEditDialog')
  }, [configurableForms])
  const { call: bulkDeleteConfigurableForms } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!configurableForms || !configurableForms.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [configurableForms])

  const handleConfirm = useCallback(() => {
    bulkDeleteConfigurableForms({ configurableFormsIds: pluck(configurableForms, '_id') })
  }, [bulkDeleteConfigurableForms, configurableForms])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
