import { useCallback, useRef } from 'react'

import useCurrentUser from '../../hooks/useCurrentUser'
import { InvertedAiva } from '../../svg/icons'

import AivaChatPopover from './AivaChatPopover'

const propTypes = {}

const defaultProps = {}

const AivaChatLauncherButton = () => {
  const popoverRef = useRef()
  const currentUser = useCurrentUser()

  const handleClick = useCallback(() => {
    popoverRef.current.open()
  }, [])

  return (currentUser?.aivaSettings.assistantChat
    ? (
      <>
        <button
          className='absolute z-50 w-[60px] h-[60px] right-5 bottom-5
                   p-2.5 rounded-full bg-primary hover:bg-blue-700'
          onClick={handleClick}
        >
          <InvertedAiva />
          <span className='sr-only'>Ask AIVA</span>
        </button>
        <AivaChatPopover ref={popoverRef} />
      </>
      )
    : null
  )
}

AivaChatLauncherButton.displayName = 'AivaChatLauncherButton'
AivaChatLauncherButton.propTypes = propTypes
AivaChatLauncherButton.defaultProps = defaultProps

export default AivaChatLauncherButton
