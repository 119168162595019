import { useCallback } from 'react'

import MessageDetailSection from '../../components/detail-sections/MessageDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentVoiceDrop from '../../hooks/useCurrentVoiceDrop'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import { CONTENT_TYPE, stateKeyFromContentType } from '../../lib/folder'
import logger from '../../lib/logger'
import { update as updateTemplateService } from '../../services/template'

import TemplateDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['VoiceDropsDetail'] })

const VoiceDropsDetail = () => {
  useLogger({ log, lifecycle: false, tags: ['VoiceDropsDetail'] })

  const { voiceDrop, setVoiceDrop, refresh, loading } = useCurrentVoiceDrop()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const stateKey = stateKeyFromContentType(CONTENT_TYPE.voiceDrop)
    const updateTemplate = updateReply.json
    setVoiceDrop(updateTemplate)
    bus.emit(`${stateKey}Updated`, updateTemplate)
  }, [setVoiceDrop])

  if (loading || !voiceDrop) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<TemplateDetailHeaderMoreMenu template={voiceDrop} />}
        start={<PanelHeaderBackButton />}
        title={voiceDrop.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          object={voiceDrop}
          objectName='template'
          service={updateTemplateService}
          onChange={handleDetailsUpdate}
        />
        <MessageDetailSection
          contentOptions={{
            emails: false,
            messageTemplates: false,
            voiceDrops: true
          }}
          description=''
          message={voiceDrop}
          service={updateTemplateService}
          onChange={handleDetailsUpdate}
          onRefreshForVoice={refresh}
        />
      </PanelContent>
    </Panel>
  )
}

VoiceDropsDetail.displayName = 'VoiceDropsDetail'
VoiceDropsDetail.propTypes = propTypes
VoiceDropsDetail.defaultProps = defaultProps

export default VoiceDropsDetail
