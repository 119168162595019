import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import space from '../../../prop-types/shapes/space'
import {
  notificationSettings as getNotificationSettingsService,
  updateNotificationSettings as updateNotificationSettingsService
} from '../../../services/space'
import FormDialog from '../../dialog/FormDialog'

const propTypes = {
  space: PropType.shape(space).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['NotificationSettingsAction'] })

const NotificationSettingsAction = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formDialogRef = useRef()
  const [notificationSettings, setNotificationSettings] = useState()

  const handleGetReplyOk = useCallback((reply) => {
    setNotificationSettings(reply.json)
    formDialogRef.current.open()
  }, [])
  const { loading: loadingNotificationSettings, call: getNotificationSettingsCall } = useService(getNotificationSettingsService, { onReplyOk: handleGetReplyOk })

  const handleUpdateReplyOk = useCallback(() => {
    formDialogRef.current.close()
  }, [])
  const { call: updateNotificationSettingsCall } = useService(updateNotificationSettingsService, { onReplyOk: handleUpdateReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      getNotificationSettingsCall(space._id)
    }
  }), [getNotificationSettingsCall, space._id])

  const handleSubmit = useCallback((data) => {
    log.debug(data)
    updateNotificationSettingsCall(space._id, { disabled: !data.enabled })
  }, [space._id, updateNotificationSettingsCall])

  const defaultValues = {
    enabled: !notificationSettings?.disabled
  }

  const formControls = [
    {
      type: 'checkbox',
      name: 'enabled',
      label: 'Enable push notifications for all new messages',
      title: 'Enabled',
      disabled: loadingNotificationSettings || !notificationSettings
    }
  ]

  return (
    <FormDialog
      ref={formDialogRef}
      defaultValues={defaultValues}
      description='When enabled you will receive a push notification for all new messages sent to this space.'
      formControls={formControls}
      subtitle={space.name}
      title='Notification Settings'
      onSubmit={handleSubmit}
    />
  )
})

NotificationSettingsAction.displayName = 'NotificationSettingsAction'
NotificationSettingsAction.propTypes = propTypes
NotificationSettingsAction.defaultProps = defaultProps

export default NotificationSettingsAction
