import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'
import { update as updateService } from '../../services/contact'
import { MarkColor } from '../../svg/branding'
import { error } from '../banners/Banner'
import MessagePreview from '../message/list-item-helpers/MessagePreview'
import RelativeTime from '../message/list-item-helpers/RelativeTime'
import UnreadIndicator from '../message/list-item-helpers/UnreadIndicator'

import DoNotEmailBadge from './chat-list-item-content/DoNotEmailBadge'
import DoNotMessageBadge from './chat-list-item-content/DoNotMessageBadge'
import ErrorBadge from './chat-list-item-content/ErrorBadge'
import KeywordBadge from './chat-list-item-content/KeywordBadge'
import PotentialStopMessageBadge from './chat-list-item-content/PotentialStopMessageBadge'
import ScheduledBadge from './chat-list-item-content/ScheduledBadge'
import StarContact from './chat-list-item-content/StarContact'
import TagSummaryLine from './chat-list-item-content/TagSummaryLine'

const propTypes = {
  hideFavoriteStar: PropType.bool,
  item: PropType.shape(contactShape)
}

const defaultProps = {
  hideFavoriteStar: false,
  item: null
}

const log = logger({ enabled: true, tags: ['ChatListItemContent'] })

const ChatListItemContent = ({ hideFavoriteStar, item: contact }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const [starred, setStarred] = useState(contact.starred)

  const handleReplyOk = useCallback((reply) => {
    // Just making sure we've got the latest starred value on a successful reply... maybe not necessary?
    const updatedContact = reply.model
    setStarred(updatedContact.starred)
  }, [])
  const handleReplyNotOk = useCallback(() => {
    // Something went wrong, let the user know and toggle the star value back
    error(`Could not update ${contact.formattedName}`)
    setStarred(!starred)
  }, [contact.formattedName, starred])
  const { loading, call } = useService(updateService, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })

  const handleStarClick = useCallback(() => {
    if (loading) {
      log.info('Still loading a previous star click call... ignoring this click')
      return
    }
    // Go ahead and just set starred because we're expecting a successful response
    const updatedValue = !starred
    setStarred(updatedValue)
    call(contact._id, { starred: updatedValue }, true)
    // TODO: would be nice to focus current list item
  }, [loading, starred, call, contact._id])

  if (!contact) { return null }

  return (
    <>
      <UnreadIndicator
        className='mt-[4px] mr-[4px]'
        lastMessageFromISOTime={contact.lastMessageFrom?.time}
        lastMessageReadISOTime={contact.lastMessageRead?.time}
      />
      <div className='flex flex-col flex-nowrap w-full max-w-full gap-y-1 overflow-hidden'>
        <div className='flex flex-row flex-nowrap text-black max-w-full overflow-auto'>
          {contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {contact.formattedName}
          </div>
          <RelativeTime date={contact.lastMessage?.time} />
        </div>
        <MessagePreview
          direction={contact.lastMessage?.direction}
          mediaUrl={contact.lastMessage?.mediaUrl}
          text={contact.lastMessage?.text}
          type={contact.lastMessage?.type}
        />
        <KeywordBadge
          hasKeyword={!!contact.lastMessage?.keyword}
          keywordAppliedByOther={contact.lastMessage?.keywordAppliedByOther}
        />
        <ErrorBadge hasError={!!contact.lastMessage?.error} />
        <DoNotMessageBadge doNotMessage={!!contact.doNotMessage} />
        <DoNotEmailBadge doNotEmail={!!contact.doNotEmail} />
        <ScheduledBadge count={contact.scheduledChatCount || 0} />
        <TagSummaryLine tags={contact.tags} />
        {contact.potentialStopMessage
          ? <PotentialStopMessageBadge />
          : null}
      </div>
      {!hideFavoriteStar
        ? (
          <div className='ml-auto self-center'>
            <StarContact starred={starred} onClick={handleStarClick} />
          </div>
          )
        : null}
    </>
  )
}

ChatListItemContent.displayName = 'ChatListItemContent'
ChatListItemContent.propTypes = propTypes
ChatListItemContent.defaultProps = defaultProps

export default ChatListItemContent
