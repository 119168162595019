import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  mediaUrl: PropType.string,
  onClick: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onClick: undefined
}

const classNamesByDirection = {
  in: [
    'self-start'
  ],
  out: [
    'self-end'
  ]
}

const log = logger({ enabled: true, tags: ['MessageThreadItemContentImage'] })

const MessageThreadItemContentImage = ({ direction, mediaUrl, onClick }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = classNames(
    'flex flex-col mb-4 items-center justify-center h-[200px]',
    onClick ? 'cursor-pointer' : '',
    classNamesByDirection[direction]
  )

  if (!mediaUrl) { return }

  return (
    <div className={computedClassName}>
      <img
        className='object-fit max-w-full max-h-full'
        src={mediaUrl}
        onClickCapture={onClick}
      />
    </div>
  )
}

MessageThreadItemContentImage.displayName = 'MessageThreadItemContentImage'
MessageThreadItemContentImage.propTypes = propTypes
MessageThreadItemContentImage.defaultProps = defaultProps

export default MessageThreadItemContentImage
