import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { SideMenuProvider } from '../contexts/SideMenuContext'

const propTypes = {}

const defaultProps = {}

const Layout = () => {
  const location = useLocation()

  if (location.pathname === '/') {
    return <Navigate to={process.env.REACT_APP_DEV_INITIAL_ROUTE || 'chats'} replace />
  }

  return (
    <SideMenuProvider>
      <Outlet />
    </SideMenuProvider>
  )
}

Layout.displayName = 'Layout'
Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
