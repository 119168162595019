import { useCallback, useRef, useState } from 'react'

import FolderEmptyListContent from '../../components/folders/FolderEmptyListContent'
import FolderList from '../../components/folders/FolderList'
import LandingPageList from '../../components/landing-pages/LandingPageList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentListFolderMode from '../../hooks/useCurrentListFolderMode'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'

import LandingPagesEmptyListContent from './list/EmptyListContent'
import ListHeaderMoreMenu from './list/ListHeaderMoreMenu'

const log = logger({ enabled: false, tags: ['LandingPagesList'] })

const LandingPagesList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()
  const folderListRef = useRef()

  const currentListFolderMode = useCurrentListFolderMode()
  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])
  const [folderTotal, setFolderTotal] = useState(0)
  const handleFolderTotalChange = useCallback((newTotal) => { setFolderTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={currentListFolderMode ? folderTotal : total}
        end={<ListHeaderMoreMenu currentListFolderMode={currentListFolderMode} listRef={listRef} />}
        title='Landing Pages'
      />
      <PanelContent>
        <FolderList
          ref={folderListRef}
          EmptyListContent={FolderEmptyListContent}
          queryOverrides={{ contentType: CONTENT_TYPE.landingPage }}
          savedStateKey='landingPagesFolders'
          type='nav'
          visible={currentListFolderMode}
          showFolderToggle
          onTotalChange={handleFolderTotalChange}
        />
        <LandingPageList
          ref={listRef}
          EmptyListContent={LandingPagesEmptyListContent}
          savedStateKey='landing-pages'
          type='nav'
          visible={!currentListFolderMode}
          showFolderToggle
          sortable
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

LandingPagesList.displayName = 'LandingPagesList'

export default LandingPagesList
