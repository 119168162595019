import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import AppmixerUiContainer from './AppmixerUiContainer'

const propTypes = {
  onReady: PropType.func
}

const defaultProps = {
  onReady: undefined
}

const log = logger({ enabled: true, tags: ['AppmixerInsights'] })

const AppmixerInsights = forwardRef(({ onReady }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })
  const insightsRef = useRef()

  const handleInit = useCallback((appmixer) => {
    return new Promise((resolve) => {
      const insightsDashboard = appmixer.ui.InsightsLogs({
        el: '#appmixerInsights'
      })
      insightsRef.current = insightsDashboard

      setTimeout(() => {
        resolve()
        onReady?.()
      }, 333)
    })
  }, [onReady])

  useImperativeHandle(ref, () => ({
    loadAppFlow (appFlow) {
      insightsRef.current.set('flowId', appFlow.appmixerFlowId)
      insightsRef.current.open()
    }
  }), [])

  return (
    <AppmixerUiContainer
      appmixerTargetId='appmixerInsights'
      loadingMessage='Loading Flow Activity...'
      onInit={handleInit}
    />
  )
})

AppmixerInsights.displayName = 'AppmixerInsights'
AppmixerInsights.propTypes = propTypes
AppmixerInsights.defaultProps = defaultProps

export default AppmixerInsights
