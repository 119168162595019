import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../hooks/useLogger'
import { mfaChannelVerb } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import PanelLoading from '../../panels/PanelLoading'

const propTypes = {
  dialogRef: PropType.shape({
    current: PropType.shape({
      close: PropType.func.isRequired
    })
  }).isRequired,
  onSubmit: PropType.func.isRequired,
  channel: PropType.oneOf(['call', 'sms'])
}

const defaultProps = {
  channel: null
}

const log = logger({ enabled: false, tags: ['MethodForm'] })

const MethodForm = ({ channel, dialogRef, onSubmit }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const formSettings = {
    id: 'mfaToForm',
    formControls: [
      {
        label: 'Phone Number',
        name: 'to',
        type: 'tel',
        required: true
      }
    ],
    defaultValues: { to: '' },
    onSubmit
  }

  const handleCancelClick = useCallback(() => {
    dialogRef.current.close()
  }, [dialogRef])

  if (!channel) {
    return <PanelLoading noHeader />
  }

  return (
    <>
      <p className='mb-5'>
        Enter the phone number you would like to use for this method.  A verification code will be provided via
        {' '}
        {mfaChannelVerb(channel).toLowerCase()}
        {' '}
        to the number you provide below.
      </p>
      <Form {...formSettings} />
      <Button
        className='w-full'
        form='mfaToForm'
        size='md'
        type='submit'
      >
        Next
      </Button>
      <Button
        className='mt-5 w-full'
        size='md'
        variant='outline'
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
    </>
  )
}

MethodForm.displayName = 'MethodForm'
MethodForm.propTypes = propTypes
MethodForm.defaultProps = defaultProps

export default MethodForm
