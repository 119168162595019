import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'
import { fastForward as fastForwardService } from '../../../services/campaign'
import { success } from '../../banners/Banner'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './FastForwardAction.validations'

const propTypes = {
  campaign: PropType.shape(campaignShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: true, tags: ['FastForwardAction'] })

const FastForwardAction = forwardRef(({ campaign, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaign?._id] })

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    success('Fast forward started.')
    onSuccess?.(reply)
  }, [onSuccess])
  const { call: fastForwardCall } = useService(fastForwardService, { onReplyOk: handleReplyOk })
  const formDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleSubmit = useCallback(({ contact }) => {
    fastForwardCall(campaign._id, contact._id)
  }, [campaign._id, fastForwardCall])

  return (
    <FormDialog
      ref={formDialogRef}
      defaultValues={{}}
      description='Fast Forwarding a campaign is a great way to test your campaign or even send all the
                   campaign messages quickly to a contact that may have missed your campaign.'
      formControls={[
        {
          type: 'passthrough',
          name: 'passthrough',
          element: (
            <p className='paragraph-sm text-neutral-500 mb-4'>
              When you select a contact and tap save, Project Broadcast will immediately schedule all
              the messages in your campaign 1 minute apart and will begin sending to the selected contact.
            </p>
          )
        },
        {
          type: 'modelselect',
          name: 'contact',
          label: 'Send To',
          model: 'contacts',
          multiple: false,
          hint: 'Select the contact that will get the fast forwarded campaign messages.'
        }
      ]}
      title='Fast Forward Campaign'
      validationSuite={validationSuite}
      onSubmit={handleSubmit}
    />
  )
})

FastForwardAction.displayName = 'FastForwardAction'
FastForwardAction.propTypes = propTypes
FastForwardAction.defaultProps = defaultProps

export default FastForwardAction
