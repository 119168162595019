import PropType from 'prop-types'

import VersionText from '../../../components/VersionText'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  children: PropType.node
}

const defaultProps = {
  children: null
}

const log = logger({ enabled: false, tags: ['WizardWrapper'] })

const WizardWrapper = ({ children }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col w-full h-full pb-safe overflow-auto'>
      <div className='w-full md:w-[720px] p-5 m-auto'>
        {children}
      </div>
      <span className='label-xs text-center pb-2'>
        <VersionText />
      </span>
    </div>
  )
}

WizardWrapper.displayName = 'WizardWrapper'
WizardWrapper.propTypes = propTypes
WizardWrapper.defaultProps = defaultProps

export default WizardWrapper
