import PropType from 'prop-types'
import { useCallback, useContext } from 'react'

import Button from '../../../components/buttons/Button'
import Panel from '../../../components/panels/Panel'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useFilePicker from '../../../hooks/useFilePicker'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import { ImportContext } from './ImportContext'
import SelectFromDevice from './SelectFromDevice'

const propTypes = {
  onFileSelected: PropType.func.isRequired,
  onFromDeviceSelected: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SelectFile'] })

const SelectFile = ({
  onFileSelected,
  onFromDeviceSelected
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { title, subtitle, handleBackButtonClick } = useContext(ImportContext)
  const { show: showCsvFilePicker } = useFilePicker({ typeFilter: 'text/csv', limitMB: 7.5 })

  const handleSelectCsv = useCallback(async () => {
    const csvFile = await showCsvFilePicker()
    if (csvFile) {
      onFileSelected(csvFile)
    }
  }, [onFileSelected, showCsvFilePicker])

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderButton icon='back' onClick={handleBackButtonClick} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent className='p-4'>
        <SelectFromDevice onFromDeviceSelected={onFromDeviceSelected} />
        <h2 className='headline-2xs mb-4 text-black'>Import From a CSV File</h2>
        <p className='mb-3'>
          This tool is used to import your csv file.  The first row in your csv file must be a header row.
          That means that each item in the header row should represent the title for the values in that column.
        </p>
        <p className='mb-3'>
          After selecting your file, you will need to match your titles with the appropriate Project Broadcast fields.
          We will attempt to auto select the correct titles so make sure to verify before finalizing.
        </p>
        <p className='mb-3'>
          If your import file is large, this may take time to process.
        </p>
        <p className='mb-3 text-center'>
          <strong>Your file must be a CSV file.</strong>
        </p>
        <Button
          className='w-auto mx-auto mb-3'
          size='md'
          onClick={handleSelectCsv}
        >
          Select Import File
        </Button>
      </PanelContent>
    </Panel>
  )
}

SelectFile.displayName = 'SelectFile'
SelectFile.propTypes = propTypes
SelectFile.defaultProps = defaultProps

export default SelectFile
