import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createContestService } from '../../../services/contest'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './CreateAction.validations'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const dialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    const contest = reply.model
    setCurrentInstance('contest', contest)
    bus.emit('contestCreated', contest)
    if (!createdFromFolder) { navigate(`/contests/${contest._id}`) }
    onSuccess?.(contest)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createContest } = useService(createContestService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const createContestWithName = useCallback((data) => {
    dialogRef.current.close()
    createContest(data)
  }, [createContest])

  return (
    <FormDialog
      ref={dialogRef}
      defaultValues={{ name: '' }}
      description='Please name your new contest'
      formControls={[
        {
          autoComplete: 'off',
          name: 'name',
          placeholder: 'Contest Name'
        }
      ]}
      title='New Contest'
      validationSuite={validationSuite}
      onSubmit={createContestWithName}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
