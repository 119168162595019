import Avatar from '../../components/avatar/Avatar'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function Avatars () {
  const testUser = {
    firstName: 'Bob',
    lastName: 'Belcher'
  }
  return (
    <Panel>
      <PanelHeader title='Avatars' />
      <PanelContent>
        <div className='p-4 flex flex-row flex-wrap gap-4 items-end'>
          <Avatar contactOrUser={testUser} size='lg' />
          <Avatar contactOrUser={testUser} size='md' />
          <Avatar contactOrUser={testUser} size='sm' />
          <Avatar contactOrUser={testUser} size='xs' />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Avatars
