import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  error: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShortLinkError'] })

const ShortLinkError = ({ error }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col w-full items-center p-4'>
      <span className='text-error'>{error}</span>
    </div>
  )
}

ShortLinkError.displayName = 'ShortLinkError'
ShortLinkError.propTypes = propTypes
ShortLinkError.defaultProps = defaultProps

export default ShortLinkError
