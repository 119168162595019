import PropType from 'prop-types'
import { forwardRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as billingAccountSearch } from '../../services/admin/billing-account'
import List from '../list/List'

import ListItemContent from './ListItemContent'

const billingAccountResultsFields = [
  '_id',
  'name',
  'createdAt'
]

const propTypes = {
  ListItemContent: PropType.elementType,
  placeholder: PropType.string,
  resultsFields: PropType.arrayOf(PropType.string),
  resultsKey: PropType.string,
  searchService: PropType.func
}

const defaultProps = {
  ListItemContent,
  placeholder: 'Billing Accounts',
  resultsFields: billingAccountResultsFields,
  resultsKey: 'billingAccounts',
  searchService: billingAccountSearch
}

const log = logger({ enabled: false, tags: ['BillingAccountList'] })

const BillingAccountList = forwardRef(({
  ListItemContent,
  placeholder,
  resultsFields,
  resultsKey,
  searchService,
  ...listProps
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [listProps.type] })
  ref = useDefaultRef(ref)

  return (
    <List
      ref={ref}
      ListItemContent={ListItemContent}
      multiple={false}
      placeholder={placeholder}
      resultsFields={resultsFields}
      resultsKey={resultsKey}
      searchService={searchService}
      type='nav'
      {...listProps}
    />
  )
})

BillingAccountList.displayName = 'BillingAccountList'
BillingAccountList.propTypes = propTypes
BillingAccountList.defaultProps = defaultProps

export default BillingAccountList
