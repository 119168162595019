import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuDivider from '../../../components/action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../components/action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import BulkEditActions from '../../../components/contacts/actions/BulkEditActions'
import CreateAction from '../../../components/contacts/actions/CreateAction'
import DeleteContactsAction from '../../../components/contacts/actions/DeleteContactsAction'
import ExportContactsAction from '../../../components/contacts/actions/ExportContactsAction'
import ContactList from '../../../components/contacts/ContactList'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}
const defaultProps = {}

const ContactListHeaderMoreMenu = forwardRef(({ listRef }, ref) => {
  const createActionRef = useRef()
  const deleteActionRef = useRef()
  const bulkEditRef = useRef()

  const exportActionRef = useRef()

  const handleBulkEditClick = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])
  const handleCreateNewContact = useCallback(() => createActionRef.current.activate(), [])
  const handleDeleteContacts = useCallback(() => deleteActionRef.current.activate(), [])
  const handleExportContacts = useCallback(() => exportActionRef.current.activate(), [])
  const handleReloadList = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadList} />
      <PanelHeaderButton icon={<Plus />} title='Create New Contact' onClick={handleCreateNewContact} />
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuHeader label='Manage Contacts' />
        <ActionMenuDivider />
        <ActionMenuItem label='Edit Contacts' onClick={handleBulkEditClick} />
        <ActionMenuItem href='import' label='Import Contacts' />
        <ActionMenuItem label='Export Contacts' onClick={handleExportContacts} />
        <ActionMenuItem label='Delete Contacts' onClick={handleDeleteContacts} />
        <ActionMenuDivider />
        <ActionMenuItem href='archived' label='Archived Contacts' />
      </ActionMenu>
      <BulkEditDialog
        ref={bulkEditRef}
        actions={BulkEditActions}
        list={ContactList}
        sortable
      />
      <CreateAction ref={createActionRef} />
      <DeleteContactsAction ref={deleteActionRef} />
      <ExportContactsAction ref={exportActionRef} />
    </>
  )
})

ContactListHeaderMoreMenu.displayName = 'ContactListHeaderMoreMenu'
ContactListHeaderMoreMenu.propTypes = propTypes
ContactListHeaderMoreMenu.defaultProps = defaultProps

export default ContactListHeaderMoreMenu
