import * as React from "react";
import { memo } from "react";
const SvgClock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm1 7a1 1 0 1 0-2 0v5H8a1 1 0 1 0 0 2h4a1 1 0 0 0 1-1V7Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgClock);
export default Memo;
