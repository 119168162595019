import { pick } from 'lodash'
import PropType from 'prop-types'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, keyword: ['ReplyDelayDetail'] })

const ReplyDelayDetail = ({ keyword, service, onChange }) => {
  useLogger({ log, lifecycle: false, keyword: [keyword?.word] })

  return (
    <FormDetailSection
      defaultValues={pick(keyword, ['delay'])}
      description='How many minutes should we wait before replying?'
      formControls={[{ label: 'Reply Delay', name: 'delay', type: 'number', required: true }]}
      formDescription={`Updating keyword for ${keyword.word}`}
      formTitle={keyword.word}
      objectId={keyword._id}
      service={service}
      title='Reply Delay'
      showEditIcon
      onChange={onChange}
    >
      {keyword.delay}
      {' '}
      minute delay
    </FormDetailSection>
  )
}

ReplyDelayDetail.displayName = 'ReplyDelayDetail'
ReplyDelayDetail.propTypes = propTypes
ReplyDelayDetail.defaultProps = defaultProps

export default ReplyDelayDetail
