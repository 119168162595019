import classNames from 'classnames'
import PropType from 'prop-types'

import { PanelProvider } from '../../contexts/PanelContext'
import GenericErrorBoundary from '../error-boundaries/GenericErrorBoundary'

const propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
  roomForTwoPanels: PropType.bool
}

const defaultProps = {
  className: null,
  roomForTwoPanels: false
}

const PanelTwo = ({ children, className, roomForTwoPanels }) => {
  const computedClassName = classNames(
    'realtive z-20 max-h-full w-full max-w-full overflow-auto grow',
    {
      'border-l border-neutral-200': roomForTwoPanels
    },
    className
  )
  return (
    <PanelProvider roomForTwoPanels={roomForTwoPanels} slot='second' hasTwoPanels>
      <section className={computedClassName}>
        <GenericErrorBoundary boundaryName='PanelTwo'>
          {children}
        </GenericErrorBoundary>
      </section>
    </PanelProvider>
  )
}

PanelTwo.displayName = 'PanelTwo'
PanelTwo.propTypes = propTypes
PanelTwo.defaultProps = defaultProps

export default PanelTwo
