import classNames from 'classnames'
import PropType from 'prop-types'

import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import * as Logos from '../../svg/branding'

BrandingChip.propTypes = {
  element: PropType.element.isRequired,
  name: PropType.string.isRequired,
  light: PropType.bool
}
BrandingChip.defaultProps = {
  light: false
}

function BrandingChip ({ element, name, light }) {
  const className = classNames('p-5 w-60', {
    'bg-black': light,
    'text-white': light
  })
  return (
    <div className={className}>
      {element}
      <span className='label-xs'>{name}</span>
    </div>
  )
}

function Branding () {
  return (
    <Panel>
      <PanelHeader title='Branding' />
      <PanelContent>
        <div className='mx-5 flex flex-row justify-around'>
          <BrandingChip element={<Logos.CompanyColor />} name='CompanyColor' />
          <BrandingChip element={<Logos.CompanyDark />} name='CompanyDark' />
          <BrandingChip element={<Logos.CompanyLight />} name='CompanyLight' light />
        </div>
        <div className='mx-5 flex flex-row justify-around'>
          <BrandingChip element={<Logos.HorizontalColor />} name='HorizontalColor' />
          <BrandingChip element={<Logos.HorizontalDark />} name='HorizontalDark' />
          <BrandingChip element={<Logos.HorizontalLight />} name='HorizontalLight' light />
        </div>
        <div className='mx-5 flex flex-row justify-around'>
          <BrandingChip element={<Logos.MarkColor />} name='MarkColor' />
          <BrandingChip element={<Logos.MarkDark />} name='MarkDark' />
          <BrandingChip element={<Logos.MarkLight />} name='MarkLight' light />
        </div>
        <div className='mx-5 flex flex-row justify-around'>
          <BrandingChip element={<Logos.VerticalColor />} name='VerticalColor' />
          <BrandingChip element={<Logos.VerticalDark />} name='VerticalDark' />
          <BrandingChip element={<Logos.VerticalLight />} name='VerticalLight' light />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Branding
