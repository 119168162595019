import * as React from "react";
import { memo } from "react";
const SvgLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.887 16.125a5.065 5.065 0 0 0 3.538-1.475l4.113-4.113A4.977 4.977 0 0 0 22 7a4.998 4.998 0 0 0-1.462-3.538A4.998 4.998 0 0 0 17 2a4.977 4.977 0 0 0-3.537 1.462l-1.375 1.376c-.388.412-.388.962 0 1.324.437.388.95.375 1.325 0l1.374-1.375A3.166 3.166 0 0 1 17 3.875c.8-.013 1.6.313 2.212.912.6.613.925 1.413.913 2.213 0 .8-.313 1.6-.913 2.213l-4.124 4.112c-.6.613-1.4.913-2.2.913-1.338.024-1.925-.713-2.65-1.438a.943.943 0 0 0-1.325 0 .949.949 0 0 0-.1 1.212c1.024 1.325 2.324 2.1 4.074 2.113ZM7 22a4.977 4.977 0 0 0 3.537-1.462l1.375-1.375c.388-.413.388-.963 0-1.326-.437-.387-.95-.375-1.325 0l-1.374 1.375c-.613.6-1.413.913-2.213.913s-1.6-.313-2.213-.913A3.166 3.166 0 0 1 3.875 17c0-.8.313-1.6.912-2.213l4.125-4.112c.6-.613 1.4-.913 2.2-.913 1.588 0 2.063.85 2.65 1.438a.943.943 0 0 0 1.325 0c.513-.512.188-1.225-.437-1.85a5.043 5.043 0 0 0-3.537-1.475A5.065 5.065 0 0 0 7.575 9.35l-4.113 4.113A4.977 4.977 0 0 0 2 17c0 1.275.487 2.563 1.462 3.538A4.998 4.998 0 0 0 7 22Z"
    />
  </svg>
);
const Memo = memo(SvgLink);
export default Memo;
