export function generateFormControls () {
  const formControls = [
    { label: 'Term', name: 'term' },
    { label: 'Cancelled', name: 'cancelled', type: 'checkbox' }
  ]
  return formControls
}

// converts server-ready object back to an advanced search object :(
export function generateAdvancedSearchFromSearchData (searchData) {
  const search = {}

  if (!searchData) { return search }

  search.term = searchData.term
  search.cancelled = !!searchData.cancelled

  return search
}

// converts advanced search form data to a server-ready object for the search api
export function generateSearchFromAdvancedSearchFormData (advancedSearchFormData) {
  const search = {}

  if (!advancedSearchFormData) { return search }

  search.term = advancedSearchFormData.term
  // the field expected to be excluded when not checked (instead of passing `false`)
  if (advancedSearchFormData.cancelled === true) {
    search.cancelled = true
  }
  return search
}

export function generateSummaryFromAdvancedSearchFormData (advancedSearchFormData) {
  let filterCount = 0
  const summaries = []

  if (!advancedSearchFormData) { return summaries }

  if (advancedSearchFormData.term) {
    filterCount++
    summaries.push(advancedSearchFormData.term)
  }

  if (advancedSearchFormData.cancelled) {
    filterCount++
    summaries.push('Cancelled')
  }

  if (filterCount) { summaries.unshift(`${filterCount}`) }
  return summaries
}
