const commonDataKeys = [
  'city',
  'country',
  'description',
  'displayName',
  'email',
  'entityType',
  'messageFlow',
  'postalCode',
  'privacyPolicyLink',
  'sample1',
  'sample2',
  'state',
  'street',
  'socialMediaLink',
  'websiteLink'
]

export const solePropDataKeys = [
  'firstName',
  'lastName',
  'mobilePhone',
  ...commonDataKeys
]

export const businessDataKeys = [
  'companyName',
  'ein',
  'phone',
  ...commonDataKeys
]

export const entityTypes = {
  SOLE_PROPRIETOR: 'Sole Proprietor',
  PRIVATE_PROFIT: 'Private Company'
}

export function entityTypeDisplayName (entityType) {
  return entityTypes[entityType]
}

export const entityTypeOptions = Object.keys(entityTypes).map((key) => ({ label: entityTypes[key], value: key }))

export function whitelistedRegistrationData (registrationData) {
  const isSoleProp = registrationData.entityType === 'SOLE_PROPRIETOR'
  const dataKeys = isSoleProp ? solePropDataKeys : businessDataKeys

  return dataKeys.reduce((data, field) => {
    data[field] = registrationData[field]
    return data
  }, {})
}
