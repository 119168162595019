import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import contactShape from '../../../prop-types/shapes/contact'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import Button from '../../buttons/Button'
import BulkHideChatAction from '../../chats/actions/BulkHideChatAction'

import BulkArchiveAction from './BulkArchiveAction'
import BulkDeleteAction from './BulkDeleteAction'
import BulkMarkAsDoNotMessageAction from './BulkMarkAsDoNotMessageAction'
import BulkMarkAsNotAnOptOutAction from './BulkMarkAsNotAnOptOutAction'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(contactShape)).isRequired
}

const defaultProps = {
}

const BulkEditActions = ({ selectedItems }) => {
  const archiveActionRef = useRef()
  const hideChatActionRef = useRef()
  const deleteActionRef = useRef()
  const markAsDoNotMessageActionRef = useRef()
  const markAsNotAnOptOutActionRef = useRef()

  const handleArchiveAction = useCallback(() => archiveActionRef.current.activate(), [])
  const handleHideChatAction = useCallback(() => hideChatActionRef.current.activate(), [])
  const handleDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])
  const handleMarkAsDoNotMessageAction = useCallback(() => markAsDoNotMessageActionRef.current.activate(), [])
  const handleMarkAsNotAnOptOutAction = useCallback(() => markAsNotAnOptOutActionRef.current.activate(), [])

  const trigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={trigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <ActionMenuItem label='Hide Chats' onClick={handleHideChatAction} />
        <ActionMenuItem label='Not an Opt-out' onClick={handleMarkAsNotAnOptOutAction} />
        <ActionMenuItem label='Archive Contacts' onClick={handleArchiveAction} />
        <ActionMenuItem label='Mark as Do Not Message' onClick={handleMarkAsDoNotMessageAction} />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Delete Contacts' onClick={handleDeleteAction} />
      </ActionMenu>
      <BulkArchiveAction ref={archiveActionRef} contacts={selectedItems} />
      <BulkHideChatAction ref={hideChatActionRef} chats={selectedItems} />
      <BulkDeleteAction ref={deleteActionRef} contacts={selectedItems} />
      <BulkMarkAsDoNotMessageAction ref={markAsDoNotMessageActionRef} contacts={selectedItems} />
      <BulkMarkAsNotAnOptOutAction ref={markAsNotAnOptOutActionRef} contacts={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
