import { flip as flipMiddleware, FloatingFocusManager, FloatingNode, FloatingPortal, shift as shiftMiddleware, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions } from '@floating-ui/react'
import EmojiPickerReact from 'emoji-picker-react'
import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import { useApp } from '../../contexts/AppContext'
import useDismissOnResize from '../../hooks/useDismissOnResize'
import useLogger from '../../hooks/useLogger'
import useSmallScreen from '../../hooks/useSmallScreen'
import logger from '../../lib/logger'
import { EmojiAdd } from '../../svg/icons'
import Button from '../buttons/Button'

import './EmojiPicker.css'

const propTypes = {
  className: PropType.string, // applies to the trigger
  onEmojiClick: PropType.func
}

const defaultProps = {
  className: null,
  onEmojiClick: null
}

const log = logger({ enabled: true, tags: ['EmojiPicker'] })

const EmojiPicker = ({ className, onEmojiClick }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const nodeId = useFloatingNodeId()
  const { isAndroid, isIOS } = useApp()

  const { emojiSkinTone, saveEmojiSkinTone } = useApp()
  const [open, setOpen] = useState(false)
  const smallScreen = useSmallScreen()

  const middleware = [
    flipMiddleware(), // https://floating-ui.com/docs/flip
    shiftMiddleware() // https://floating-ui.com/docs/shift
  ]

  const { reference, floating, context, strategy, x, y } = useFloating({
    nodeId,
    placement: 'top-start',
    strategy: 'absolute',
    open,
    onOpenChange: setOpen,
    middleware
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismissOnResize(context, { enabled: true }),
    useDismiss(context, {
      referencePress: false,
      outsidePress: true,
      ancestorScroll: true,
      outsidePressEvent: smallScreen ? 'click' : 'pointerdown'
    })
  ])
  const style = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0
  }

  const floatingClassName = 'drop-shadow z-50'

  const floatingProps = getFloatingProps({
    ref: floating,
    style,
    className: floatingClassName
  })
  const triggerProps = getReferenceProps({
    ref: reference
  })

  const handleEmojiClick = useCallback((emojiData, event) => {
    log.debug(event)
    // emojiData: {
    //   activeSkinTone: SkinTones;
    //   unified: string;
    //   unifiedWithoutSkinTone: string;
    //   emoji: string;
    //   names: string[];
    //   getImageUrl: (emojiStyle: EmojiStyle) => string;
    // }
    log.info(emojiData)
    if (emojiData.activeSkinTone !== emojiSkinTone) {
      saveEmojiSkinTone(emojiData.activeSkinTone)
    }
    onEmojiClick?.(emojiData)
  }, [emojiSkinTone, onEmojiClick, saveEmojiSkinTone])

  // Emoji picker has issues w/ on-screen keyboard on android & iOS devices (native & web)
  if (isAndroid || isIOS) { return null }

  const emojiPicker = (
    <div {...floatingProps}>
      <EmojiPickerReact
        autoFocusSearch={false}
        defaultSkinTone={emojiSkinTone}
        emojiStyle='native'
        emojiVersion='13.1'
        height={270}
        previewConfig={{ showPreview: false }}
        suggestedEmojisMode='recent'
        theme='light'
        onEmojiClick={handleEmojiClick}
      />
    </div>
  )

  const floatingEmojiPicker = (
    <FloatingNode id={nodeId}>
      <FloatingPortal id='emoji-picker-portal'>
        <FloatingFocusManager context={context} modal>
          {emojiPicker}
        </FloatingFocusManager>
      </FloatingPortal>
    </FloatingNode>
  )

  const trigger = (
    <Button
      className={className}
      icon={<EmojiAdd />}
      size='sm'
      theme='none'
      variant='none'
      {...triggerProps}
    >
      Add Emoji
    </Button>
  )

  return (
    <>
      {trigger}
      {open ? floatingEmojiPicker : null}
    </>
  )
}

EmojiPicker.displayName = 'EmojiPicker'
EmojiPicker.propTypes = propTypes
EmojiPicker.defaultProps = defaultProps

export default EmojiPicker
