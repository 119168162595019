import PropType from 'prop-types'

import messageTypes from '../helpers/messageTypes'
import { CONTENT_TYPE } from '../lib/folder'
import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  contentType: PropType.arrayOf(PropType.oneOf([
    CONTENT_TYPE.email,
    CONTENT_TYPE.messageTemplate,
    CONTENT_TYPE.voiceDrop
  ])),
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}

export const updatePropTypes = {
  contentType: PropType.arrayOf(PropType.oneOf([
    CONTENT_TYPE.email,
    CONTENT_TYPE.messageTemplate,
    CONTENT_TYPE.voiceDrop
  ])),
  name: PropType.string,
  media: PropType.arrayOf(PropType.string),
  text: PropType.string,
  type: PropType.oneOf(messageTypes)
}
