import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import * as icons from '../../svg/icons'

function Iconography () {
  const iconElements = Object.entries(icons).map(([key, Value]) => {
    return (
      <div key={key} className='m-4 flex flex-col flex-wrap items-center'>
        <div className='w-[25px] h-[25px]'><Value /></div>
        <p className='label-xs mt-1'>{key}</p>
      </div>
    )
  })
  return (
    <Panel>
      <PanelHeader title='Iconography' />
      <PanelContent>
        <div className='p-5 max-w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 xl:grid-cols-12'>
          {iconElements}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Iconography
