import * as React from "react";
import { memo } from "react";
const SvgTopEdgeBackground = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 999 570"
    {...props}
  >
    <path
      fill="#93C4FC"
      d="M411.013-2.514c-16.129 17.335-35.551 31.75-56.158 39.704-45.604 17.596-83.006-1.89-114.764 28.692-23.916 23.029-11.681 42.717-38.257 76.517-19.547 24.866-58.979 55.943-95.636 50.214-46.462-7.26-40.35-66.122-95.64-105.209-55.44-39.195-115.493-18.13-160.49-21.93V-2.514h560.945Z"
    />
    <path
      fill="#C7D2FF"
      d="M1148.55-2.514v125.131c-10.03.021-21.1 1.419-33.37 5.436-70.94 23.21-81.2 100.763-117.161 98.036-40.409-3.062-28.309-101.059-93.247-148.25-88.129-64.047-232.465 27.747-263.002-19.13-10.642-16.334-2.204-41.43 7.782-61.223h498.998Z"
    />
    <path
      stroke="#CA8A04"
      d="M197.882 156.313c-39.948 23.353-78.714 8.838-124.794-8.415l-2.106-.789C24.026 129.538-30.415 109.79-100.84 125.172c-29.922 6.535-55.943 17.933-78.593 31.036V-2.014h448.435c2.264 31.142-3.708 63.767-16.116 92.066-12.47 28.441-31.425 52.477-55.005 66.261ZM215.444 631.738c20.723 27.525 35.741 61.976 40.266 97.535h-435.142V477.851c19.172-5.773 39.228-8.183 57.231-6.804 18.122 1.388 34.116 6.611 45.05 16.055 16.336 14.109 17.71 34.457 19.048 54.669l.008.121c1.329 20.079 2.65 40.044 19.22 52.828 10.602 8.18 22.685 9.75 35.909 7.865 13.203-1.881 27.604-7.215 42.883-12.893l.258-.095c30.547-11.352 64.669-24.033 100.441-13.922 27.42 7.752 53.992 28.388 74.828 56.063ZM1118.05-2.014V290.35c-30.64-10.069-60.82-27.666-82.77-45.519-40.353-32.817-55.659-68.723-68.454-99.412l-.521-1.25c-12.549-30.108-23.003-55.191-53.097-66.796-37.92-14.622-75.831 4.17-110.626 22.487l-3.893 2.053c-16.025 8.456-31.359 16.547-45.765 20.996-15.554 4.804-29.935 5.332-42.869-2.457-15.014-9.042-25.175-27.539-30.146-49.942-4.941-22.269-4.733-48.311.88-72.524h437.261Z"
    />
  </svg>
);
const Memo = memo(SvgTopEdgeBackground);
export default Memo;
