import React from 'react'

import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import ConfigurableFormsDetail from '../screens/configurable-forms/ConfigurableFormsDetail'
import ConfigurableFormsFolderDetail from '../screens/configurable-forms/ConfigurableFormsFolderDetail'

const configurableFormRoutes = [
  {
    path: ':configurableFormId',
    element: <ConfigurableFormsDetail />
  }
]

const routes = [
  ...configurableFormRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<ConfigurableFormsFolderDetail />} />,
    children: [...configurableFormRoutes]
  }
]

export default routes
