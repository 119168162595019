import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import qrCodeShape from '../../../prop-types/shapes/qrCode'
import { bulkDelete as bulkDeleteService } from '../../../services/qrCode'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  qrCodes: PropType.arrayOf(PropType.shape(qrCodeShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ qrCodes }, ref) => {
  const confirmRef = useRef()
  const navigate = useNavigate()
  const params = useParams()

  const handleReplyOk = useCallback(() => {
    success('QR Codes have been deleted')
    bus.emit('qrCodesDeleted', qrCodes)
    bus.emit('closeBulkEditDialog')
    const qrCodeIds = new Set(pluck(qrCodes, '_id'))
    if (qrCodeIds.has(params.qrCodeId)) { navigate('/qr-codes') }
  }, [navigate, qrCodes, params.qrCodeId])
  const { call: bulkDelete } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!qrCodes || !qrCodes.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [qrCodes])

  const handleConfirm = useCallback(() => {
    bulkDelete({ qrCodeIds: pluck(qrCodes, '_id') })
  }, [bulkDelete, qrCodes])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
