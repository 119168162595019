import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import { del as deleteService } from '../../../services/scheduledBroadcast'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ scheduledBroadcast, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast?._id] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${scheduledBroadcast.name} was deleted`)
    bus.emit('scheduledBroadcastDeleted', scheduledBroadcast)
    onSuccess?.()
  }, [scheduledBroadcast, onSuccess])
  const { call: deleteScheduledBroadcastCall } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteScheduledBroadcastCall(scheduledBroadcast._id)
  }, [scheduledBroadcast._id, deleteScheduledBroadcastCall])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to delete this scheduled broadcast?'
      title={`Delete ${scheduledBroadcast.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
