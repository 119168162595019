import * as React from "react";
import { memo } from "react";
const SvgSort = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 5a1 1 0 0 1 1-1h22a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Zm3 7a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm5 6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSort);
export default Memo;
