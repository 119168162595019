import * as React from "react";
import { memo } from "react";
const SvgObjects = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.447 1.106a1 1 0 0 0-.894 0l-9 4.5A1 1 0 0 0 2 6.5v11a1 1 0 0 0 .553.894l9 4.5a1 1 0 0 0 .894 0l9-4.5A1 1 0 0 0 22 17.5v-11a1 1 0 0 0-.553-.894l-9-4.5ZM4 16.882V7.118l8-4 8 4v9.764l-8 4-8-4ZM9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgObjects);
export default Memo;
