import { create, each, enforce, test } from 'vest'

const generateValidationSuiteForUser = (user) => create((data = {}) => {
  each(user.customFields, (field) => {
    test(field.path, 'Name is required', () => {
      enforce(data[field.path]).isNotEmpty()
    })
  })

  each(user.userCustomFields, (field) => {
    test(field.path, 'Name is required', () => {
      enforce(data[field.path]).isNotEmpty()
    })
  })
})

export default generateValidationSuiteForUser
