import { useCallback, useRef } from 'react'

import { success } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import useStackBackButton from '../../../hooks/useStackBackButton'
import logger from '../../../lib/logger'
import { entityTypeDisplayName } from '../../../lib/tcr'
import { updateTwilioSolePropCampaign as updateTwilioSolePropCampaignService } from '../../../services/admin'

import validationSuite from './EditCampaign.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EditCampaign'] })

const EditCampaign = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { loading, user } = useSelectedUser()
  const { handleBackButtonClick } = useStackBackButton()

  const onReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const onReplyOk = useCallback(() => {
    success('Campaign updated')
    handleBackButtonClick()
  }, [handleBackButtonClick])

  const { call: updateTwilioSolePropCampaign } = useService(updateTwilioSolePropCampaignService, { onReply, onReplyOk })

  const formRef = useRef()

  const formControls = [
    {
      type: 'textarea',
      name: 'description',
      label: 'Description',
      minRows: 2,
      maxRows: 9
    },
    {
      type: 'textarea',
      name: 'messageFlow',
      label: 'Opt-in Summary',
      minRows: 2,
      maxRows: 9
    },
    {
      type: 'textarea',
      name: 'sample1',
      label: 'Sample 1',
      minRows: 2,
      maxRows: 9
    },
    {
      type: 'textarea',
      name: 'sample2',
      label: 'Sample 2',
      minRows: 2,
      maxRows: 9
    }
  ]

  const onSubmit = useCallback((data) => {
    log.debug('onSubmit', data)
    updateTwilioSolePropCampaign(data, user?._id)
  }, [updateTwilioSolePropCampaign, user?._id])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title={`Edit ${entityTypeDisplayName(user.registration.campaign.usecase)} Campaign`}
      />
      <PanelContent className='p-5'>
        <div className='w-full md:max-w-md mx-auto'>
          <Form
            ref={formRef}
            defaultValues={user.registration.campaign}
            formControls={formControls}
            id='edit-campaign'
            validationSuite={validationSuite}
            preventMultipleSubmits
            onSubmit={onSubmit}
          />
          <div className='flex flex-row gap-4 items-center'>
            <Button form='edit-campaign' size='sm' type='submit'>Save</Button>
          </div>
        </div>
      </PanelContent>
    </>
  )
}

EditCampaign.displayName = 'EditCampaign'
EditCampaign.propTypes = propTypes
EditCampaign.defaultProps = defaultProps

export default EditCampaign
