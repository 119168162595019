import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import messageTypes from '../../../helpers/messageTypes'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Email } from '../../../svg/icons'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  type: PropType.oneOf(messageTypes).isRequired,
  subject: PropType.string
}

const defaultProps = {
  subject: null
}

const log = logger({ enabled: true, tags: ['MessageThreadItemContentEmail'] })

const classNamesByDirection = {
  in: [
    'self-start',
    'bg-neutral-100',
    'text-neutral-900'
  ],
  out: [
    'self-end',
    'bg-indigo-100',
    'text-indigo-900'
  ]
}

const MessageThreadItemContentEmail = ({ direction, subject, type }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const wrapperClassName = classNames(
    'flex flex-col max-w-full mb-4',
    'whitespace-pre-wrap break-words p-3 rounded-lg',
    classNamesByDirection[direction]
  )

  if (type !== 'email') { return null }

  return (
    <div className={wrapperClassName}>
      <div className='label-sm-strong flex flex-row items-center mb-1'>
        <Email className='w-4 h-4 mr-2' />
        Email
      </div>
      <div>{subject}</div>
    </div>
  )
}

MessageThreadItemContentEmail.displayName = 'MessageThreadItemContentEmail'
MessageThreadItemContentEmail.propTypes = propTypes
MessageThreadItemContentEmail.defaultProps = defaultProps

export default MessageThreadItemContentEmail
