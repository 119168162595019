import PropType from 'prop-types'

import trackableLinkFromJSON from '../models/trackableLink'

import pb from './pb'
import { searchPropTypes } from './trackableLink.prop-types'

export function get (trackableLinkId, background = false) {
  const options = {
    background,
    model: { conversion: trackableLinkFromJSON }
  }
  return pb.setupGet(`trackableLinks/${trackableLinkId}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: trackableLinkFromJSON,
      key: 'trackableLinks',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'trackableLinks#search')
  return pb.setupPost('trackableLinks/search', options)
}

export function create (body, background = false) {
  const options = {
    body,
    background,
    model: { conversion: trackableLinkFromJSON }
  }
  return pb.setupPost('trackableLinks', options)
}

export function update (trackableLinkId, body, background = false) {
  const options = {
    body,
    background,
    model: { conversion: trackableLinkFromJSON }
  }
  return pb.setupPut(`trackableLinks/${trackableLinkId}`, options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('trackableLinks/bulk/delete', { body, background })
}

export function del (trackableLinkId) {
  return pb.setupDelete(`trackableLinks/${trackableLinkId}`)
}
