import * as React from "react";
import { memo } from "react";
const SvgBroadcastPaused = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Broadcast_Paused_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.037 8.136a4 4 0 0 0-4.636 5.612L10.01 11.8a2.002 2.002 0 0 1 1.419-1.717l1.608-1.947ZM6.57 15.965C5.954 14.98 5.5 13.797 5.5 12.5c0-2.728 2.028-4.806 3.052-5.485a1 1 0 0 0-1.104-1.667C6.138 6.215 3.5 8.828 3.5 12.5c0 1.846.672 3.45 1.474 4.683.087.134.176.264.267.39l1.329-1.608ZM4.269 18.75C3.159 17.28 2 15.028 2 12.38c0-3.84 2.437-6.686 3.61-7.587a1 1 0 1 0-1.22-1.586C2.898 4.356 0 7.731 0 12.38c0 3.405 1.555 6.211 2.966 7.947l1.303-1.577Zm.097 3.023a.808.808 0 0 0 .02.017c-.078.33.062.723.385.99.426.352 1.008.35 1.301-.005l2.383-2.884a.996.996 0 0 0 .506-.613L11 16.81V23a1 1 0 1 0 2 0v-7.126a4.002 4.002 0 0 0 2.856-4.942l1.548-1.874c.613.934 1.096 2.11 1.096 3.442 0 1.354-.494 2.583-1.15 3.592-.66 1.014-1.448 1.749-1.931 2.094a1 1 0 1 0 1.162 1.628c.683-.488 1.646-1.404 2.445-2.631.802-1.233 1.474-2.837 1.474-4.683 0-2.05-.822-3.77-1.776-5.04l1.04-1.26C20.86 7.593 22 9.754 22 12.38c0 3.848-2.447 6.862-3.638 7.85a1 1 0 1 0 1.276 1.54C21.113 20.547 24 17.019 24 12.38c0-3.44-1.585-6.182-3.015-7.841.037-.31-.105-.659-.4-.902a1.092 1.092 0 0 0-.764-.26 6.73 6.73 0 0 0-.155-.126l-15.3 18.522Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Broadcast_Paused_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBroadcastPaused);
export default Memo;
