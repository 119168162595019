import ListActionLink from '../../components/links/ListActionLink'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function SettingsActionsList () {
  return (
    <Panel>
      <PanelHeader title='Settings' />
      <PanelContent className='text-black'>
        <div className='border-neutral-200'>
          <ListActionLink title='Broadcast Hours' to='broadcast-hours' />
          <ListActionLink title='Call Forwarding' to='call-forwarding' />
          <ListActionLink title='Change Assistant Password' to='change-assistant-password' />
          <ListActionLink title='Custom Field Names' to='custom-field-names' />
          <ListActionLink title='Dynamic Fields' to='dynamic-fields' />
          <ListActionLink title='Incoming Message Email Notifications' to='incoming-message-email-notifications' />
          <ListActionLink title='Opt Out Message' to='opt-out-message' />
          <ListActionLink title='Opt In Form' to='opt-in-form' />
          <ListActionLink title='Double Opt In' to='double-opt-in' />
          <ListActionLink title='Set Time Zone' to='set-time-zone' />
          <ListActionLink title='Short Code Messages' to='short-code-messages' />
        </div>
      </PanelContent>
    </Panel>
  )
}

export default SettingsActionsList
