import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { scheduled } from '../../services/space'
import ThreadList from '../list/thread/ThreadList'
import MessageThreadItemContent from '../message/MessageThreadItemContent'

const propTypes = {
  spaceId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpaceScheduledThread'] })

const SpaceScheduledThread = forwardRef(({ spaceId, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const handleMessageDeleted = useCallback((message) => {
    ref.current.removeMessage(message)
  }, [ref])
  useBus('messageDeleted', handleMessageDeleted)

  const handleMessageUpdated = useCallback((message) => {
    ref.current.updateMessage(message)
  }, [ref])
  useBus('messageUpdated', handleMessageUpdated)

  return (
    <ThreadList
      ref={ref}
      queryOverrides={{ spaceId }}
      service={scheduled}
      ThreadItemContent={MessageThreadItemContent}
      reverseIncomingData
      {...rest}
    />
  )
})

SpaceScheduledThread.displayName = 'SpaceScheduledThread'
SpaceScheduledThread.propTypes = propTypes
SpaceScheduledThread.defaultProps = defaultProps

export default SpaceScheduledThread
