import PropType from 'prop-types'
import { useCallback } from 'react'

import DetailSection from '../../../components/detail-sections/DetailSection'
import useFilePicker from '../../../hooks/useFilePicker'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import attachmentShape from '../../../prop-types/shapes/attachment'

const propTypes = {
  attachment: PropType.shape(attachmentShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['FileDetail'] })

const FileDetail = ({ attachment, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [attachment?._id] })

  const { show: showFilePicker } = useFilePicker({ typeFilter: '*/*', limitMB: 7.5 })

  const handleReplyOk = useCallback((reply) => { onChange?.(reply) }, [onChange])
  const { call } = useService(service, { onReplyOk: handleReplyOk })

  const handleEdit = useCallback(async () => {
    const file = await showFilePicker()
    if (file) {
      const data = new FormData()
      data.append('upload', file.uploadData)
      call(attachment._id, data)
    }
  }, [attachment._id, call, showFilePicker])

  return (
    <DetailSection
      description='The file to be downloaded. Limited to 7.5MB.'
      title='File'
      showEditIcon
      onEditClick={handleEdit}
    >
      {attachment?.fileNameAndSize}
    </DetailSection>
  )
}

FileDetail.displayName = 'FileDetail'
FileDetail.propTypes = propTypes
FileDetail.defaultProps = defaultProps

export default FileDetail
