import attachmentFromJSON from '../models/attachment'
import configurableFormFromJSON from '../models/configurableForm'
import contactFromJSON from '../models/contact'
import keywordFromJSON from '../models/keyword'
import trackableLinkFromJSON from '../models/trackableLink'

import pb from './pb'

export function attachmentLanding (attachmentSlug, contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: attachmentFromJSON }
  }
  return pb.setupGet(`a/${attachmentSlug}/${contactSlug}`, options)
}

export function configurableFormLanding (configurableFormSlug, contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: configurableFormFromJSON }
  }
  return pb.setupGet(`f/${configurableFormSlug}/${contactSlug}`, options)
}

export function configurableFormUpdate (configurableFormSlug, contactSlug, body, background = false) {
  const options = {
    background,
    body,
    model: { conversion: configurableFormFromJSON }
  }
  return pb.setupPost(`f/${configurableFormSlug}/${contactSlug}`, options)
}

export function contactLinkLanding (contactSlugOrId, keywordSlugOrId, background = false) {
  const options = {
    background
  }
  let path = `c/${contactSlugOrId}`
  if (keywordSlugOrId) { path = `${path}/k/${keywordSlugOrId}` }
  return pb.setupGet(path, options)
}

export function contactLinkUpdate (contactSlugOrId, keywordSlugOrId, body, background = false) {
  const options = {
    background,
    body
  }
  let path = `c/${contactSlugOrId}`
  if (keywordSlugOrId) { path = `${path}/k/${keywordSlugOrId}` }
  return pb.setupPost(path, options)
}

export function singleOptInLanding (slug, background = false) {
  const options = {
    background
  }
  return pb.setupGet(`u/${slug}/subscribe`, options)
}

export function singleOptIn (slug, body, background = false) {
  const options = {
    background,
    body
  }
  return pb.setupPost(`u/${slug}/subscribe`, options)
}

export function doubleOptInLanding (contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON }
  }
  return pb.setupGet(`d/${contactSlug}`, options)
}

export function doubleOptIn (contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON }
  }
  return pb.setupPost(`d/${contactSlug}`, options)
}

export function doubleOptInUpdate (contactSlug, body, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON },
    body
  }
  return pb.setupPut(`d/${contactSlug}`, options)
}

export function textKeywordLinkLanding (keywordSlug, background = false) {
  const options = {
    background,
    model: { conversion: keywordFromJSON }
  }
  return pb.setupGet(`t/${keywordSlug}`, options)
}

export function trackableLinkLanding (trackableLinkSlug, contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: trackableLinkFromJSON }
  }
  return pb.setupGet(`l/${trackableLinkSlug}/${contactSlug}`, options)
}

export function trackableLinkClick (trackableLinkSlug, contactSlug, background = false) {
  const options = { background }
  return pb.setupPost(`l/${trackableLinkSlug}/${contactSlug}`, options)
}

export function unsubscribeLanding (contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON }
  }
  return pb.setupGet(`unsubscribe/${contactSlug}`, options)
}

export function unsubscribe (contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON }
  }
  return pb.setupPost(`unsubscribe/${contactSlug}`, options)
}

export function subscribe (contactSlug, background = false) {
  const options = {
    background,
    model: { conversion: contactFromJSON }
  }
  return pb.setupPost(`subscribe/${contactSlug}`, options)
}
