import * as React from "react";
import { memo } from "react";
const SvgMessageTemplates = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 374 175"
    {...props}
  >
    <path fill="#fff" d="M-1 0h375v175H-1z" />
    <path
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
      d="M271.5 87.014c0 45.04-37.568 81.625-84 81.625s-84-36.585-84-81.625c0-45.041 37.568-81.625 84-81.625s84 36.584 84 81.625Z"
    />
    <circle cx={71.5} cy={142.5} r={6.5} fill="#E1E7FF" />
    <circle cx={89.5} cy={40.5} r={4.5} fill="#E1E7FF" />
    <circle cx={286} cy={44} r={6} fill="#E1E7FF" />
    <circle cx={304} cy={14} r={4} fill="#E1E7FF" />
    <rect width={100} height={100} x={137} y={41} fill="#fff" rx={4} />
    <rect
      width={100}
      height={100}
      x={137}
      y={41}
      stroke="#D2D5DC"
      strokeWidth={2}
      rx={4}
    />
    <rect width={88.054} height={33.64} x={143} y={87} fill="#fff" rx={4} />
    <rect
      width={88.054}
      height={33.64}
      x={143}
      y={87}
      stroke="#D2D5DC"
      strokeDasharray="3 3"
      strokeWidth={2}
      rx={4}
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M177 99.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5h-16.5a.75.75 0 0 1-.75-.75Zm5 5.25a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Zm6.75 4.5a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6Z"
      clipRule="evenodd"
    />
    <rect width={88.054} height={33.64} x={143} y={47} fill="#fff" rx={4} />
    <rect
      width={88.054}
      height={33.64}
      x={143}
      y={47}
      stroke="#D2D5DC"
      strokeDasharray="3 3"
      strokeWidth={2}
      rx={4}
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M177 59.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5h-16.5a.75.75 0 0 1-.75-.75Zm0 5.25a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 177 65Zm.75 4.5a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMessageTemplates);
export default Memo;
