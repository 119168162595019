import { useCallback, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import PanelLoading from '../../components/panels/PanelLoading'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { create, search } from '../../services/contact'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['NewChatsThreadByPhoneNumber'] })

const NewChatsThreadByPhoneNumber = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { phoneNumber } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleReplyNotOk = useCallback(() => { navigate('/chats', { replace: true }) }, [navigate])

  const navigateToChat = useCallback((contact) => {
    if (!contact?._id) {
      navigate('/chats', { replace: true })
    } else {
      const text = searchParams.get('text')
      const image = searchParams.get('image') // TODO: we should probably verify this is an image url we care about
      const newSearchParams = new URLSearchParams()
      if (text) { newSearchParams.set('text', searchParams.get('text')) }
      if (image) { newSearchParams.set('image', searchParams.get('image')) }
      navigate(`/chats/${contact._id}?${newSearchParams.toString()}`, { replace: true })
    }
  }, [navigate, searchParams])

  const handleCreateReplyOk = useCallback((reply) => {
    navigateToChat(reply?.json?.contact)
  }, [navigateToChat])
  const { call: createContactCall } = useService(create, { onReplyOk: handleCreateReplyOk, onReplyNotOk: handleReplyNotOk })

  const handleSearchReplyOk = useCallback((reply) => {
    const matchingContact = reply?.json?.contacts?.[0]
    if (matchingContact) {
      navigateToChat(matchingContact)
    } else {
      const firstName = searchParams.get('firstName')
      const lastName = searchParams.get('lastName')
      createContactCall({ phoneNumber, firstName, lastName })
    }
  }, [createContactCall, navigateToChat, phoneNumber, searchParams])
  const { call: searchContactsCall } = useService(search, { onReplyOk: handleSearchReplyOk, onReplyNotOk: handleReplyNotOk })

  useEffect(() => {
    // TODO: should we verify `phoneNumber` is something we care to search for?
    searchContactsCall({ term: phoneNumber, paging: { page: 1, pageSize: 1 } })
  }, [phoneNumber, searchContactsCall])

  return (<PanelLoading />)
}

NewChatsThreadByPhoneNumber.displayName = 'NewChatsThreadByPhoneNumber'
NewChatsThreadByPhoneNumber.propTypes = propTypes
NewChatsThreadByPhoneNumber.defaultProps = defaultProps

export default NewChatsThreadByPhoneNumber
