import PropType from 'prop-types'
import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelDialog from '../dialog/PanelDialog'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

const propTypes = {
  ariaLabel: PropType.string.isRequired,
  Editor: PropType.elementType.isRequired,
  subtitle: PropType.string.isRequired,
  title: PropType.string.isRequired,
  onSave: PropType.func.isRequired,
  onSaveAndClose: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['UnlayerEditorDialog'] })

const UnlayerEditorDialog = memo(forwardRef(({
  ariaLabel,
  Editor,
  subtitle,
  title,
  onSave,
  onSaveAndClose
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  ref = useDefaultRef(ref)
  const dialogRef = useRef()
  const editorRef = useRef()
  const currentDesignRef = useRef()

  useImperativeHandle(ref, () => ({
    close () {
      dialogRef.current.close()
    },
    open (design) {
      if (!design) { throw new Error('A design is required') }
      currentDesignRef.current = design
      dialogRef.current.open()
    }
  }), [])

  const handleCancelEditor = useCallback(() => { dialogRef.current.close() }, [])

  const handleSave = useCallback(() => {
    editorRef.current.exportHtml((details) => { onSave(details) }, { minify: true })
  }, [onSave])

  const handleSaveAndClose = useCallback(() => {
    editorRef.current.exportHtml((details) => { onSaveAndClose(details) }, { minify: true })
  }, [onSaveAndClose])

  const handleOnReady = useCallback(() => {
    editorRef.current.loadDesign(currentDesignRef.current)
  }, [])

  return (
    <PanelDialog
      ref={dialogRef}
      ariaLabel={ariaLabel}
      fullscreen
      keepInBackground
    >
      <PanelHeader
        end={
          <>
            <PanelHeaderButton className='mr-3' title='Save' onClick={handleSave} />
            <PanelHeaderButton icon='save' title='Save and Close' onClick={handleSaveAndClose} />
          </>
        }
        start={<PanelHeaderButton icon='cancel' onClick={handleCancelEditor} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent>
        <Editor
          ref={editorRef}
          onReady={handleOnReady}
        />
      </PanelContent>
    </PanelDialog>
  )
}))

UnlayerEditorDialog.displayName = 'UnlayerEditorDialog'
UnlayerEditorDialog.propTypes = propTypes
UnlayerEditorDialog.defaultProps = defaultProps

export default UnlayerEditorDialog
