import * as React from 'react'
import { memo } from 'react'
const SvgTemplate = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#DBEAFF" rx={4} />
        <g clipPath="url(#Template_svg__a)">
            <path
                fill="#2563EC"
                fillRule="evenodd"
                d="M10.364 5.455A.545.545 0 0 0 9.818 6v2.727H6a.545.545 0 0 0-.545.546V18c0 .301.244.546.545.546h7.09a.546.546 0 0 0 .546-.546v-2.727H18a.545.545 0 0 0 .545-.546V6A.545.545 0 0 0 18 5.455h-7.636Zm2.181 9.818v2.181h-6V9.819h3.273v1.636H8.182a.546.546 0 0 0 0 1.091h1.636v1.637H8.182a.545.545 0 0 0 0 1.09H12.545ZM10.91 12v2.182H17.455V6.545h-6.546V12ZM12 8.727c0-.301.244-.545.545-.545h3.273a.545.545 0 0 1 0 1.09h-3.273A.545.545 0 0 1 12 8.728Zm.545 2.182a.545.545 0 0 0 0 1.091h3.273a.545.545 0 0 0 0-1.09h-3.273Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="Template_svg__a">
                <path fill="#fff" d="M5.455 5.455h13.09v13.09H5.456z" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgTemplate)
export default Memo
