import { useOutlet } from 'react-router-dom'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import Status from './tcr/Status'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['TheCampaignRegistry'] })

const TheCampaignRegistry = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const outlet = useOutlet()

  return (outlet || <Status />)
}

TheCampaignRegistry.displayName = 'TheCampaignRegistry'
TheCampaignRegistry.propTypes = propTypes
TheCampaignRegistry.defaultProps = defaultProps

export default TheCampaignRegistry
