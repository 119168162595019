import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import ExternalLink from '../../../components/links/ExternalLink'
import CreateAction from '../../../components/scheduled-broadcast/actions/CreateAction'
import { ScheduleBroadcast as ScheduledBroadcastsEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const ScheduledBroadcastEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddScheduledBroadcast = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <ScheduledBroadcastsEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong'>Schedule New Broadcast</h1>
        <p className='label-sm text-center'>
          Learn how to
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/broadcast-messages'
            label='schedule a message'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddScheduledBroadcast}>
          Schedule New Broadcast
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

ScheduledBroadcastEmptyListContent.displayName = 'ScheduledBroadcastEmptyListContent'
ScheduledBroadcastEmptyListContent.propTypes = propTypes
ScheduledBroadcastEmptyListContent.defaultProps = defaultProps

export default ScheduledBroadcastEmptyListContent
