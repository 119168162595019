import { defaults } from 'lodash'
import { useCallback, useDebugValue, useEffect, useRef, useState } from 'react'

const useHover = (options) => {
  const [isHovering, setIsHovering] = useState(false)
  const ref = useRef(null)

  const timeoutRef = useRef(null)
  const newDelay = useCallback((fn, ms) => {
    if (timeoutRef.current) { clearTimeout(timeoutRef.current) }
    timeoutRef.current = setTimeout(fn, ms)
  }, [])

  useDebugValue(`isHovering: ${isHovering}`)

  options = defaults({}, options, {
    inDelay: 250,
    outDelay: 250,
    onHoverIn: null,
    onHoverOut: null
  })

  const handleEnter = useCallback((event) => {
    newDelay(() => {
      setIsHovering(true)
      options.onHoverIn?.()
    }, options.inDelay)
  }, [newDelay, options])
  const handleLeave = useCallback((event) => {
    newDelay(() => {
      setIsHovering(false)
      options.onHoverOut?.()
    }, options.outDelay)
  }, [newDelay, options])

  useEffect(() => {
    const target = ref.current

    if (target) {
      target.addEventListener('mouseenter', handleEnter)
      target.addEventListener('mouseleave', handleLeave)
    }

    return () => {
      if (timeoutRef.current) { clearTimeout(timeoutRef.current) }
      if (target) {
        target.removeEventListener('mouseenter', handleEnter)
        target.removeEventListener('mouseleave', handleLeave)
      }
    }
  }, [ref, handleEnter, handleLeave])

  return { isHovering, ref }
}

export default useHover
