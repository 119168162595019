import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../../components/panels/panel-header/PanelHeaderButton'
import CloneAction from '../../../../components/unlayer-templates/actions/CloneAction'
import CloneAsOppositeTypeAction from '../../../../components/unlayer-templates/actions/CloneAsOppositeTypeAction'
import DeleteAction from '../../../../components/unlayer-templates/actions/DeleteAction'
import useLogger from '../../../../hooks/useLogger'
import useNavigateInStack from '../../../../hooks/useNavigateInStack'
import logger from '../../../../lib/logger'
import unlayerTemplate from '../../../../prop-types/shapes/unlayerTemplate'

const propTypes = {
  unlayerTemplate: PropType.shape(unlayerTemplate)
}

const defaultProps = {
  unlayerTemplate: null
}

const log = logger({ enabled: false, tags: ['DetailHeaderMoreMenu'] })

const DetailHeaderMoreMenu = ({ unlayerTemplate }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const cloneActionRef = useRef()
  const cloneAsOppositeTypeActionRef = useRef()
  const deleteActionRef = useRef()

  const handleDelete = useCallback(() => deleteActionRef.current.activate(), [])
  const handleDeleteSuccess = useCallback(() => navigateInStack('/admin/unlayer-templates', { replace: true }), [navigateInStack])

  const handleCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])
  const handleCloneAsOppositeTypeSelected = useCallback(() => cloneAsOppositeTypeActionRef.current.activate(), [])

  const typeLabel = unlayerTemplate?.type === 'email' ? 'Email' : 'Landing Page'
  const oppositeTypeLabel = unlayerTemplate?.type === 'email' ? 'Landing Page' : 'Email'

  return !!unlayerTemplate && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label={`Clone as ${typeLabel}`} onClick={handleCloneSelected} />
        <ActionMenuItem label={`Clone as ${oppositeTypeLabel}`} onClick={handleCloneAsOppositeTypeSelected} />
        <ActionMenuItem label='Delete Unlayer Template' onClick={handleDelete} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} unlayerTemplate={unlayerTemplate} onSuccess={handleDeleteSuccess} />
      <CloneAction ref={cloneActionRef} unlayerTemplate={unlayerTemplate} />
      <CloneAsOppositeTypeAction ref={cloneAsOppositeTypeActionRef} unlayerTemplate={unlayerTemplate} />
    </>
  )
}

DetailHeaderMoreMenu.displayName = 'DetailHeaderMoreMenu'
DetailHeaderMoreMenu.propTypes = propTypes
DetailHeaderMoreMenu.defaultProps = defaultProps

export default DetailHeaderMoreMenu
