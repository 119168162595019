import * as React from "react";
import { memo } from "react";
const SvgUpload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.677 9.583A7.36 7.36 0 0 1 12 3a7.36 7.36 0 0 1 7.323 6.583A5.07 5.07 0 0 1 23 14.454a5.085 5.085 0 0 1-5.09 5.091h-1.364a1 1 0 0 1 0-2h1.363c1.711 0 3.091-1.38 3.091-3.09a3.077 3.077 0 0 0-2.747-3.052 1 1 0 0 1-.89-.994v-.045A5.36 5.36 0 0 0 12 5a5.359 5.359 0 0 0-5.364 5.364v.045a1 1 0 0 1-.89.994A3.07 3.07 0 0 0 3 14.454a3.085 3.085 0 0 0 3.09 3.091h1.365a1 1 0 1 1 0 2H6.09A5.085 5.085 0 0 1 1 14.456a5.062 5.062 0 0 1 3.677-4.872Zm8.046.09a.997.997 0 0 0-1.43-.017l-3.637 3.637a1 1 0 1 0 1.414 1.414l1.93-1.93v6.677a1 1 0 1 0 2 0v-6.676l1.929 1.929a1 1 0 0 0 1.414-1.414l-3.62-3.62Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgUpload);
export default Memo;
