import * as React from "react";
import { memo } from "react";
const SvgRefresh = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 0a1 1 0 0 0 0 2h1.61C1.574 4.25 0 8.034 0 12c0 6.632 5.368 12 12 12s12-5.368 12-12C24 5.738 19.21.6 13.09.044a1 1 0 1 0-.18 1.992C18.01 2.5 22 6.782 22 12c0 5.528-4.472 10-10 10S2 17.528 2 12c0-3.566 1.444-6.75 4-8.535V5a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1H3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgRefresh);
export default Memo;
