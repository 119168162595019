import LegacyAppRedirect from '../components/LegacyAppRedirect'

const routes = [
  {
    path: 'app/templates/:templateId',
    element: <LegacyAppRedirect paramName='templateId' to='templates/:templateId' />
  },
  {
    path: 'app/chat/contacts/:contactId',
    element: <LegacyAppRedirect paramName='contactId' to='chats/:contactId' />
  },
  {
    path: 'app/chat/:phoneNumber',
    element: <LegacyAppRedirect paramName='phoneNumber' to='chats/new/:phoneNumber' />
  },
  {
    path: 'app/*',
    element: <LegacyAppRedirect to='chats' />
  }
]

export default routes
