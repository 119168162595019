import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DisableDetailDisabled'] })

const DisableDetailDisabled = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='mb-4'>
      <div className='flex flex-row flex-nowrap mb-3'>
        <div className='mr-auto'>
          <div className='headline-2xs text-black mb-1'>Disable MFA</div>
          <div className='paragraph-sm'>Disabling MFA is not available when you are a manager for one or more users.</div>
        </div>
      </div>
    </div>
  )
}

DisableDetailDisabled.displayName = 'DisableDetailDisabled'
DisableDetailDisabled.propTypes = propTypes
DisableDetailDisabled.defaultProps = defaultProps

export default DisableDetailDisabled
