import { MarkDark } from '../../svg/branding'
import { Filter, Image, Panels, Symbols, Text, Trash } from '../../svg/icons'
import SideMenu from '../side-menu/SideMenu'

const navConfig = [
  {
    icon: <MarkDark />,
    label: 'Branding',
    path: '/style-guide/branding'
  },
  {
    icon: <Filter />,
    label: 'Colors',
    path: '/style-guide/colors'
  },
  {
    icon: <Symbols />,
    label: 'Icons',
    path: '/style-guide/iconography'
  },
  {
    icon: <Text />,
    label: 'Typography',
    path: '/style-guide/typography'
  },
  {
    icon: <Panels />,
    label: 'Breakpoints',
    path: '/style-guide/breakpoints'
  },
  {
    icon: <Image />,
    label: 'Components',
    subConfig: [
      {
        label: 'Action Menus',
        path: '/style-guide/components/action-menus'
      },
      {
        label: 'Avatars',
        path: '/style-guide/components/avatars'
      },
      {
        label: 'Banners',
        path: '/style-guide/components/banners'
      },
      {
        label: 'Buttons',
        path: '/style-guide/components/buttons'
      },
      {
        label: 'Detail Sections',
        path: '/style-guide/components/detail-sections'
      },
      {
        label: 'Dialogs',
        path: '/style-guide/components/dialogs'
      },
      {
        label: 'Formatted Dates',
        path: '/style-guide/components/formatted-dates'
      },
      {
        label: 'Forms',
        path: '/style-guide/components/forms'
      },
      {
        label: 'Panel Headers',
        path: '/style-guide/components/panel-headers'
      },
      {
        label: 'Pills',
        path: '/style-guide/components/pills'
      },
      {
        label: 'Pluralization',
        path: '/style-guide/components/pluralization'
      },
      {
        label: 'Search Bars',
        path: '/style-guide/components/search-bars'
      },
      {
        label: 'Switches',
        path: '/style-guide/components/switches'
      },
      {
        label: 'Tooltips',
        path: '/style-guide/components/tooltips'
      },
      {
        label: 'Wells',
        path: '/style-guide/components/wells'
      }
    ]
  },
  {
    icon: <Trash />,
    label: 'Vendor',
    path: '/style-guide/vendor'
  }
]

function StyleGuideMenu () {
  return (
    <SideMenu navConfig={navConfig} />
  )
}

export default StyleGuideMenu
