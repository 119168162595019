import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import RemoveAction from '../../../components/managed-accounts/actions/RemoveAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { Minus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ reload: PropType.func.isRequired })
  }).isRequired
}
const defaultProps = {}

const ManagedAccountListHeaderMoreMenu = ({ listRef }) => {
  const removeActionRef = useRef()
  const handleRemoveManagedAccount = useCallback(() => removeActionRef.current.activate(), [])
  const handleReloadList = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadList} />
      <PanelHeaderButton icon={<Minus />} title='Remove Managed Account' onClick={handleRemoveManagedAccount} />
      <RemoveAction ref={removeActionRef} />
    </>
  )
}

ManagedAccountListHeaderMoreMenu.displayName = 'ManagedAccountListHeaderMoreMenu'
ManagedAccountListHeaderMoreMenu.propTypes = propTypes
ManagedAccountListHeaderMoreMenu.defaultProps = defaultProps

export default ManagedAccountListHeaderMoreMenu
