import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { Provider as RollbarProvider } from '@rollbar/react'
import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import './App.css'
import boot from './boot'
import ApiLoading from './components/ApiLoading'
import AppEvents from './components/AppEvents'
import Badge from './components/Badge'
import GenericErrorBoundary from './components/error-boundaries/GenericErrorBoundary'
import ExternalNavigate from './components/ExternalNavigate'
import PageViewTracker from './components/PageViewTracker'
import Poller from './components/Poller'
import PushNotifications from './components/PushNotifications'
import RollbarRouteContextProvider from './components/RollbarRouteContextProvider'
import StackHistory from './components/StackHistory'
import { AppProvider } from './contexts/AppContext'
import useLogger from './hooks/useLogger'
import logger from './lib/logger'
import rollbar from './lib/rollbar'
import { Routes } from './routes'

const log = logger({ enabled: true, tags: ['App'] })

function App () {
  useLogger({ log, lifecycle: true })

  const [booting, setBooting] = useState(true)
  const [preferences, setPreferences] = useState(null)

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      document.documentElement.classList.add('capacitor-native-platform')
    }

    async function awaitBoot () {
      const loadedPreferences = await boot()
      setPreferences({ ...loadedPreferences })
      setBooting(false)
      setTimeout(SplashScreen.hide, 150)
    }
    awaitBoot()
  }, [])

  // maybe mirror splash screen which does not display for browser
  if (booting) { return null }

  return (
    <RollbarProvider instance={rollbar}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <AppProvider preferences={preferences}>
          <GenericErrorBoundary boundaryName='App'>
            <Routes />
            <StackHistory />
            <ApiLoading />
            <Toaster containerClassName='mt-safe' />
            <AppEvents />
            {Capacitor.isNativePlatform() ? <Badge /> : null}
            <PushNotifications />
            <ExternalNavigate />
            <Poller />
            <PageViewTracker />
            <RollbarRouteContextProvider />
          </GenericErrorBoundary>
        </AppProvider>
      </BrowserRouter>
    </RollbarProvider>
  )
}

export default App
