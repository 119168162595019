import PropType from 'prop-types'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { Close } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {
  mediaUrl: PropType.string,
  onRemove: PropType.func
}

const defaultProps = {
  mediaUrl: null,
  onRemove: undefined
}

const log = logger({ enabled: false, tags: ['MessageVoiceAttachment'] })

const MessageVoiceAttachment = ({ mediaUrl, onRemove }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!mediaUrl) { return null }

  return (
    <div className='flex items-center px-3 py-2 flex place-content-center'>
      <audio
        className='outline-0 w-full my-1 mr-3'
        preload='auto'
        src={mediaUrl}
        controls
      />
      <Button
        icon={<Close />}
        size='sm'
        theme='primary'
        variant='outline'
        circle
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  )
}

MessageVoiceAttachment.displayName = 'MessageVoiceAttachment'
MessageVoiceAttachment.propTypes = propTypes
MessageVoiceAttachment.defaultProps = defaultProps

export default MessageVoiceAttachment
