import PropType from 'prop-types'
import { useCallback } from 'react'

import { useSideMenu } from '../../contexts/SideMenuContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Closed, Open } from '../../svg/components/menu-toggle'
import Tooltip from '../tooltip/Tooltip'

const propTypes = {
  height: PropType.oneOfType([PropType.number, PropType.string]).isRequired,
  width: PropType.oneOfType([PropType.number, PropType.string]).isRequired,
  className: PropType.string
}
const defaultProps = {
  className: ''
}

const log = logger({ enabled: true, tags: ['SideMenuLockToggle'] })

const SideMenuLockToggle = ({ className, height, width }) => {
  useLogger({ log, lifecycle: false })

  const { expanded, locked, toggleLocked } = useSideMenu()
  const handleLockClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    toggleLocked()
  }, [toggleLocked])
  if (!expanded) { return null }
  const iconProps = { height, width }
  const icon = locked ? <Open {...iconProps} /> : <Closed {...iconProps} />
  const triggerProps = { className, onClick: handleLockClick }
  const trigger = <div {...triggerProps}>{icon}</div>
  return (
    <Tooltip
      content={locked ? 'Collapse' : 'Keep open'}
      offset={-8}
      placement='right'
      trigger={trigger}
    />
  )
}

SideMenuLockToggle.displayName = 'SideMenuLockToggle'
SideMenuLockToggle.propTypes = propTypes
SideMenuLockToggle.defaultProps = defaultProps

export default SideMenuLockToggle
