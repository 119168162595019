import * as React from "react";
import { memo } from "react";
const SvgFolders = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 175"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v175H0z" />
    <path
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
      d="M272.5 87.014c0 45.041-37.568 81.625-84 81.625s-84-36.584-84-81.625c0-45.04 37.568-81.625 84-81.625s84 36.584 84 81.625Z"
    />
    <path
      fill="#DBEAFF"
      d="M128 50.555v55.417c0 2.148 1.791 3.889 4 3.889h113c2.209 0 4-1.741 4-3.889V59.373c0-2.148-1.791-3.889-4-3.889h-89.258a4.04 4.04 0 0 1-3.193-1.547l-4.441-5.723a4.038 4.038 0 0 0-3.193-1.547H132c-2.209 0-4 1.74-4 3.888Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M132 48.125c-1.381 0-2.5 1.088-2.5 2.43v55.417c0 1.343 1.119 2.431 2.5 2.431h113c1.381 0 2.5-1.089 2.5-2.431V59.373c0-1.342-1.119-2.43-2.5-2.43h-89.258a5.556 5.556 0 0 1-4.391-2.127l-4.44-5.724a2.527 2.527 0 0 0-1.996-.967H132Zm-5.5 2.43c0-2.953 2.462-5.347 5.5-5.347h12.915c1.726 0 3.352.788 4.391 2.128l4.44 5.723a2.527 2.527 0 0 0 1.996.967H245c3.038 0 5.5 2.394 5.5 5.347v46.599c0 2.953-2.462 5.347-5.5 5.347H132c-3.038 0-5.5-2.394-5.5-5.347V50.556Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M211.522 38.944a9.998 9.998 0 0 0-6.97-2.83h-43.099c-5.523 0-10 4.477-10 10v53.85c0 5.522 4.477 9.999 10 9.999h55.382c5.522 0 10-4.477 10-10V58.055a10 10 0 0 0-3.03-7.17l-12.283-11.941Z"
      clipRule="evenodd"
    />
    <path
      fill="#DBEAFF"
      fillRule="evenodd"
      d="M208.905 35v15.546c0 1.036.863 1.875 1.928 1.875h15.991L208.905 35Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M209.279 35.364h-51.271c-4.152 0-7.519 3.273-7.519 7.31v65.04c0 4.037 3.367 7.31 7.519 7.31h61.886c4.153 0 7.519-3.273 7.519-7.31v-54.72l-1.928-1.875v56.595c0 3.002-2.503 5.436-5.591 5.436h-61.886c-3.088 0-5.591-2.434-5.591-5.436v-65.04c0-3.002 2.503-5.436 5.591-5.436h53.199l-1.928-1.874Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M207.941 35.375v13.297c0 3.106 2.59 5.623 5.784 5.623h13.677l-1.928-1.874h-11.749c-2.13 0-3.856-1.679-3.856-3.749V37.249l-1.928-1.874Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m209.408 35.291 18.005 17.505-1.5 1.458-18.004-17.505 1.499-1.458Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M127 71.014c0-2.17 1.007-3.93 2.249-3.93h118.502c1.242 0 2.249 1.76 2.249 3.93v66.028c0 2.171-1.007 3.93-2.249 3.93H129.249c-1.242 0-2.249-1.759-2.249-3.93V71.014Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M129.51 68.276v70.434h118.431V68.276H129.51Zm-.225-2.412c-1.246 0-2.256.982-2.256 2.193v70.873c0 1.211 1.01 2.193 2.256 2.193h118.881c1.246 0 2.256-.982 2.256-2.193V68.057c0-1.211-1.01-2.193-2.256-2.193H129.285Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M116.539 139.822c0-.666.556-1.207 1.241-1.207h141.44c.685 0 1.241.541 1.241 1.207s-.556 1.206-1.241 1.206H117.78c-.685 0-1.241-.54-1.241-1.206Z"
      clipRule="evenodd"
    />
    <circle cx={72.5} cy={142.5} r={6.5} fill="#E1E7FF" />
    <circle cx={90.5} cy={40.5} r={4.5} fill="#E1E7FF" />
    <circle cx={287} cy={44} r={6} fill="#E1E7FF" />
    <circle cx={305} cy={14} r={4} fill="#E1E7FF" />
  </svg>
);
const Memo = memo(SvgFolders);
export default Memo;
