import PropType from 'prop-types'

import { addPropTypes, createPropTypes, searchPropTypes } from './folder.prop-types'
import pb from './pb'

export function addSelected (data, background = false) {
  const { folderId, ...body } = data
  PropType.checkPropTypes(addPropTypes, body, 'prop', 'folders#add')
  return pb.setupPut(`folders/${folderId}/add`, {
    body, background
  })
}

export function addAllInQuery (data, background = false) {
  const { folderId, ...body } = data
  return pb.setupPut(`folders/${folderId}/add/all`, {
    body, background
  })
}

export function checkNameUniqueness (name, contentType, background = true) {
  return pb.setupGet(`folders/checkNameUniqueness/${name}/${contentType}`, { background })
}

export function create (body, background = false) {
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'folders#create')
  return pb.setupPost('folders', {
    body, background
  })
}

export function del (folderId) {
  return pb.setupDelete(`folders/${folderId}`)
}

export function get (folderId, background = false) {
  return pb.setupGet(`folders/${folderId}`, { background })
}

export function removeSelected (data, background = false) {
  const { folderId, ...body } = data
  const options = { body, background }
  return pb.setupPut(`folders/${folderId}/remove`, options)
}

export function removeAllInQuery (data, background = false) {
  const { folderId, ...body } = data
  const options = { body, background }
  return pb.setupPut(`folders/${folderId}/remove/all`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'folders#search')
  return pb.setupPost('folders/search', options)
}

export function update (data, background = false) {
  const { folderId, ...body } = data
  const options = { body, background }
  return pb.setupPut(`folders/${folderId}`, options)
}
