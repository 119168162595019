import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/voice-drops/actions/CreateAction'
import { VoiceDrops as TemplatesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const VoiceDropEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddTemplate = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[240px]'>
        <TemplatesEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong mb-3'>Create Voice Drop</h1>
        <p className='label-sm text-center'>
          Our system will call your forwarding number to record your voice drop.
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddTemplate}>
          Add Voice Drop
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

VoiceDropEmptyListContent.displayName = 'VoiceDropEmptyListContent'
VoiceDropEmptyListContent.propTypes = propTypes
VoiceDropEmptyListContent.defaultProps = defaultProps

export default VoiceDropEmptyListContent
