import { omit } from 'lodash'
import PropType from 'prop-types'

import attachmentFromJSON from '../models/attachment'
import bundleFromJSON from '../models/bundle'

import {
  addRemoveAllInQueryPropTypesForContent,
  addRemoveSelectedPropTypesForContent,
  searchPropTypesForContent
} from './bundle-content.prop-types'
import pb from './pb'

export function search (data, background = false) {
  const searchPropTypes = searchPropTypesForContent(data)
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'bundle-content#search')

  const { bundleId, contentType } = data
  const body = omit(data, 'bundleId', 'contentType')
  let model
  switch (contentType) {
    case 'attachments':
      model = {
        conversion: attachmentFromJSON,
        key: 'attachments',
        multiple: true
      }
      break
    case 'campaigns':
    case 'emails':
    case 'keywords':
    case 'landingPages':
    case 'messageTemplates':
    case 'voiceDrops':
      break
  }

  return pb.setupPost(`bundles/${bundleId}/${contentType}/search`, { body, background, model })
}

export function addSelected (data) {
  const addRemoveSelectedPropTypes = addRemoveSelectedPropTypesForContent(data)
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'bundle-content#addSelected')
  const { bundleId, contentType } = data
  const body = omit(data, 'bundleId', 'contentType')
  return pb.setupPut(`bundles/${bundleId}/${contentType}/add`, {
    body,
    model: { conversion: bundleFromJSON }
  })
}

export function addAllInQuery (data) {
  const addRemoveAllInQueryPropTypes = addRemoveAllInQueryPropTypesForContent(data)
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'bundle-content#addAllInQuery')
  const { bundleId, contentType } = data
  const body = omit(data, 'bundleId', 'contentType')
  return pb.setupPut(`bundles/${bundleId}/${contentType}/add/all`, {
    body,
    model: { conversion: bundleFromJSON }
  })
}

export function removeSelected (data) {
  const addRemoveSelectedPropTypes = addRemoveSelectedPropTypesForContent(data)
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'bundle-content#removeSelected')
  const { bundleId, contentType } = data
  const body = omit(data, 'bundleId', 'contentType')
  return pb.setupPut(`bundles/${bundleId}/${contentType}/remove`, {
    body,
    model: { conversion: bundleFromJSON }
  })
}

export function removeAllInQuery (data) {
  const addRemoveAllInQueryPropTypes = addRemoveAllInQueryPropTypesForContent(data)
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'bundle-content#removeAllInQuery')
  const { bundleId, contentType } = data
  const body = omit(data, 'bundleId', 'contentType')
  return pb.setupPut(`bundles/${bundleId}/${contentType}/remove/all`, {
    body,
    model: { conversion: bundleFromJSON }
  })
}
