import { chain, first, keys, map } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import TabButton from '../../components/buttons/TabButton'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateCustomFields as updateCustomFieldsService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

import CustomFieldNamesTab from './custom-field-names/CustomFieldNamesTab'
import generateValidationSuiteForUser from './CustomFieldNames.validations'

const CustomFieldNames = () => {
  const currentUser = useCurrentUser()
  const contactFieldNamesRef = useRef()
  const userFieldNamesRef = useRef()
  const [currentTab, setCurrentTab] = useState('contact')

  const { call: updateCustomFieldsCall } = useServiceAndAction(updateCustomFieldsService, updateUserAction, {
    onReplyOk: useCallback(() => {
      notify('Custom Field Names updated successfully.')
    }, [])
  })

  const handleContactFieldsClick = useCallback(() => setCurrentTab('contact'), [])
  const handleUserFieldsClick = useCallback(() => setCurrentTab('user'), [])

  useEffect(() => {
    contactFieldNamesRef.current.setVisible(currentTab === 'contact')
    userFieldNamesRef.current.setVisible(currentTab === 'user')
  }, [currentTab])

  const { defaultValues, formControls, transformData, validationSuite } = useMemo(() => {
    const allCustomFields = currentUser.customFields.concat(currentUser.userCustomFields)
    return {
      defaultValues: chain(allCustomFields).keyBy('path').mapValues('name').value(),
      formControls: [
        {
          type: 'passthrough',
          name: 'passthrough',
          element: (
            <>
              <CustomFieldNamesTab
                ref={contactFieldNamesRef}
                customFields={currentUser.customFields}
                description='Give the custom fields on your contacts a more relevant name, making it
                             easier to manage their custom data and select dynamic fields in messages.'
                title='Contact Custom Field Names'
              />
              <CustomFieldNamesTab
                ref={userFieldNamesRef}
                customFields={currentUser.userCustomFields}
                description='Give your custom fields a more relevant name, making it easier to manage
                             your custom data and select dynamic fields in messages.'
                title='User Custom Field Names'
              />
            </>
          )
        }
      ],
      transformData: (data) => ({
        customFields: map(currentUser.customFields, (field) => ({
          _id: field._id,
          name: data[field.path]
        })),
        userCustomFields: map(currentUser.userCustomFields, (field) => ({
          _id: field._id,
          name: data[field.path]
        }))
      }),
      validationSuite: generateValidationSuiteForUser(currentUser)
    }
  }, [currentUser])

  const onFormError = useCallback((data) => {
    const firstErrorField = first(keys(data))
    setCurrentTab(/^user/.test(firstErrorField) ? 'user' : 'contact')
  }, [])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='customFieldNamesForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Custom Field Names'
      />
      <div className='flex flex-row'>
        <TabButton selected={currentTab === 'contact'} onClick={handleContactFieldsClick}>Contact Fields</TabButton>
        <TabButton selected={currentTab === 'user'} onClick={handleUserFieldsClick}>User Fields</TabButton>
      </div>
      <PanelContent className='p-5'>
        <Form
          defaultValues={defaultValues}
          formControls={formControls}
          id='customFieldNamesForm'
          transformData={transformData}
          validationSuite={validationSuite}
          onError={onFormError}
          onSubmit={updateCustomFieldsCall}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='customFieldNamesForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

CustomFieldNames.displayName = 'CustomFieldNames'

export default CustomFieldNames
