import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as trackableLinkSearch } from '../../services/trackableLink'
import List from '../list/List'

import TrackableLinkListItemContent from './TrackableLinkListItemContent'

const propTypes = {
  additionalHandlers: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {}
}

const trackableLinkResultsFields = [
  '_id',
  'slug', // utilized by the message composer
  'name',
  'url',
  'trackableLinkClicks'
]

const log = logger({ enabled: true, tags: ['TrackableLinkList'] })

const TrackableLinkList = forwardRef(({ additionalHandlers, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('trackableLinkCreated', handleBusEvent)
  useBus('trackableLinkUpdated', handleBusEvent)
  useBus('trackableLinkDeleted', handleBusEvent)
  useBus('trackableLinksDeleted', handleBusEvent)

  additionalHandlers?.('trackableLink', ref)

  return (
    <List
      ref={ref}
      ListItemContent={TrackableLinkListItemContent}
      placeholder='Trackable Links'
      resultsFields={trackableLinkResultsFields}
      resultsKey='trackableLinks'
      searchService={trackableLinkSearch}
      search
      {...rest}
    />
  )
})

TrackableLinkList.displayName = 'TrackableLinkList'
TrackableLinkList.propTypes = propTypes
TrackableLinkList.defaultProps = defaultProps

export default TrackableLinkList
