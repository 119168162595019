import * as React from 'react'
import { memo } from 'react'
const SvgCalendar = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#EAD6E6" rx={4} />
        <path
            fill="#973080"
            fillRule="evenodd"
            d="M8.5 5c.322 0 .583.261.583.583v.584h5.834v-.584a.583.583 0 1 1 1.166 0v.584h1.167c.966 0 1.75.783 1.75 1.75v9.333A1.75 1.75 0 0 1 17.25 19H6.75A1.75 1.75 0 0 1 5 17.25V7.917c0-.967.784-1.75 1.75-1.75h1.167v-.584c0-.322.26-.583.583-.583Zm6.417 2.333V8.5a.583.583 0 1 0 1.166 0V7.333h1.167c.322 0 .583.261.583.584v2.333H6.167V7.917c0-.322.26-.584.583-.584h1.167V8.5a.583.583 0 1 0 1.166 0V7.333h5.834Zm2.916 4.084v5.833a.583.583 0 0 1-.583.583H6.75a.583.583 0 0 1-.583-.583v-5.833h11.666Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgCalendar)
export default Memo
