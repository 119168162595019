import { create, enforce, only, skipWhen, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  const isSoleProp = data.entityType === 'SOLE_PROPRIETOR'

  test('description', 'Business Description is required', () => {
    enforce(data.description).isNotEmpty()
  })
  test('description', 'Business Description should contain more details', () => {
    enforce(data.description).longerThan(100)
  })
  test('description', 'Business Description cannot be longer than 4096 characters', () => {
    enforce(data.description).shorterThan(4096)
  })
  test('description', 'Please fill in placeholder name.', () => {
    enforce(data.description).notMatches(/\[.+name\]/i)
  })

  test('messageFlow', 'Opt-in Summary is required', () => {
    enforce(data.messageFlow).isNotEmpty()
  })
  test('messageFlow', 'Opt-in Summary should contain more details', () => {
    enforce(data.messageFlow).longerThan(100)
  })
  test('messageFlow', 'Opt-in Summary cannot be longer than 2048 characters', () => {
    enforce(data.messageFlow).shorterThan(2048)
  })
  test('messageFlow', 'Please fill in placeholder links.', () => {
    enforce(data.messageFlow).notMatches(/\[.+link.+\]/i)
  })

  test('sample1', 'Sample Message 1 is required', () => {
    enforce(data.sample1).isNotEmpty()
  })
  test('sample1', 'Sample Message 1 should contain more details', () => {
    enforce(data.sample1).longerThan(20)
  })
  test('sample1', 'Sample Message 1 cannot be longer than 1024 characters', () => {
    enforce(data.sample1).shorterThan(1024)
  })

  test('sample2', 'Sample Message 2 is required', () => {
    enforce(data.sample2).isNotEmpty()
  })
  test('sample2', 'Sample Message 2 should contain more details', () => {
    enforce(data.sample2).longerThan(20)
  })
  test('sample2', 'Sample Message 2 cannot be longer than 1024 characters', () => {
    enforce(data.sample2).shorterThan(1024)
  })

  skipWhen(isSoleProp, () => {
    test('privacyPolicyLink', 'Link cannot be longer than 100 characters', () => {
      enforce(data.privacyPolicyLink).shorterThan(100)
    })
  })

  skipWhen(isSoleProp, () => {
    test('socialMediaLink', 'Link cannot be longer than 100 characters', () => {
      enforce(data.socialMediaLink).shorterThan(100)
    })
  })

  skipWhen(isSoleProp, () => {
    test('websiteLink', 'Link cannot be longer than 100 characters', () => {
      enforce(data.websiteLink).shorterThan(100)
    })
  })

  test('displayName', 'Display Name is required', () => {
    enforce(data.displayName).isNotEmpty()
  })

  skipWhen(!isSoleProp, () => {
    test('mobilePhone', 'Mobile Phone is required', () => {
      enforce(data.mobilePhone).isNotEmpty()
    })
  })
})

export default validationSuite
