import { create, enforce, only, skipWhen, test } from 'vest'

import stateProvinceData from '../../../data-sources/state-province'
import { socialPlanId } from '../../../lib/plans'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('planId', 'Plan is required', () => {
    enforce(data.planId).isNotEmpty()
  })

  test('firstName', 'First name is required', () => {
    enforce(data.firstName).isNotEmpty()
  })

  test('lastName', 'Last name is required', () => {
    enforce(data.lastName).isNotEmpty()
  })

  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty()
  })

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty()
  })
  test('password', 'Password is too short', () => {
    enforce(data.password).longerThan(7)
  })
  test('password', 'Password must have at least one number', () => {
    enforce(data.password).matches(/[0-9]/)
  })
  test('password', 'Password must have at least one lowercase letter', () => {
    enforce(data.password).matches(/[a-z]/)
  })

  skipWhen(
    () => (data.planId !== socialPlanId),
    () => {
      test('phoneNumber', 'Phone Number is rquired', () => {
        enforce(data.phoneNumber).isNotEmpty()
      })
      test('phoneNumber', 'Phone Number is too short', () => {
        enforce(data.phoneNumber).longerThan(9)
      })
    }
  )

  skipWhen(
    () => (data.planId === socialPlanId),
    () => {
      test('stateProvince', 'State/Province is required', () => {
        enforce(data.stateProvince).isNotEmpty()
      })
      test('stateProvince', 'State/Province is not supported', () => {
        const isValid = stateProvinceData.some((country) => {
          return country.value.some((stateProvince) => {
            return stateProvince.value === data.stateProvince
          })
        })
        enforce(isValid).isTruthy()
      })
    }
  )

  test('timezone', 'Time Zone is required', () => {
    enforce(data.timezone).isNotEmpty()
  })

  skipWhen(
    () => (!data.partnerId),
    () => {
      test('partnerTerms', 'You must accept the terms', () => {
        enforce(data.partnerTerms).isTruthy()
      })
    }
  )

  test('euDisclaimer', 'Project Broadcast is not able to support members of the European Union at this time.', () => {
    enforce(data.euDisclaimer).isFalsy()
  })
})

export default validationSuite
