import { indexOf, padStart } from 'lodash'

const FIELD_DISPLAY_SORT_ORDER = [
  'firstName',
  'lastName',
  'company',
  'email',
  'birthDay',
  'anniversary'
]

export function getFieldDisplaySortPosition (fieldName) {
  const sortPos = indexOf(FIELD_DISPLAY_SORT_ORDER, fieldName)
  return `${padStart(sortPos === -1 ? 99 : sortPos, 2, '0')}${fieldName}`
}
