import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as folderSearch } from '../../services/folder'
import List from '../list/List'

import FolderListItemContent from './FolderListItemContent'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func
}

const defaultProps = {
  queryOverrides: { },
  searchService: folderSearch
}

const log = logger({ enabled: true, tags: ['FolderList'] })

const FolderList = forwardRef(({ queryOverrides, searchService, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('folderCreated', handleBusEvent)
  useBus('folderUpdated', handleBusEvent)
  useBus('folderDeleted', handleBusEvent)
  useBus('foldersUpdated', handleBusEvent)

  return (
    <List
      ref={ref}
      ListItemContent={FolderListItemContent}
      pathPrefix='folder'
      placeholder='Search Folders'
      queryOverrides={queryOverrides}
      resultsKey='folders'
      searchService={searchService}
      search
      {...listProps}
    />
  )
})

FolderList.displayName = 'FolderList'
FolderList.propTypes = propTypes
FolderList.defaultProps = defaultProps

export default FolderList
