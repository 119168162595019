import FormControl from '../../../components/forms/FormControl'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'

const controls = [
  {
    type: 'select',
    name: 'select1',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select2',
    placeholder: 'With Placeholder',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select3',
    placeholder: 'With Placeholder but Default Value',
    defaultValue: '2',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select4',
    label: 'With Label',
    placeholder: ' ',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select5',
    label: 'With Label and Error',
    error: 'Please fix this',
    defaultValue: '2',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select6',
    label: 'With Label and Hint',
    hint: 'Choose the right option.',
    defaultValue: '2',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select7',
    label: 'With Label, Hint, and Error',
    hint: 'Consider all the choices before you decide.',
    error: 'Please fix this',
    defaultValue: '2',
    options: [
      {
        label: 'Option 1',
        value: '1'
      },
      {
        label: 'Option 2',
        value: '2'
      }
    ]
  },
  {
    type: 'select',
    name: 'select8',
    label: 'With groupings',
    placeholder: 'Please Select',
    options: [
      {
        label: 'Group 1',
        value: [
          {
            label: 'Option 1',
            value: '1'
          },
          {
            label: 'Option 2',
            value: '2'
          }
        ]
      },
      {
        label: 'Group 2',
        value: [
          {
            label: 'Option 3',
            value: '3'
          },
          {
            label: 'Option 4',
            value: '4'
          }
        ]
      }
    ]
  },
  {
    type: 'countryselect',
    label: 'Non-default country label',
    name: 'countrySelect1'
  },
  {
    type: 'countryselect',
    name: 'countrySelect2',
    placeholder: 'Non-default placeholder',
    defaultValue: 'US'
  },
  {
    type: 'stateprovince',
    label: 'Non-default label',
    name: 'statePropvince1'
  },
  {
    type: 'stateprovince',
    name: 'stateProvince2',
    placeholder: 'Non-default placeholder',
    defaultValue: 'us-ny'
  }
]

function Selects () {
  const output = controls.map((options) => {
    return <FormControl key={options.name} {...options} />
  })
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Select Inputs' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Selects
