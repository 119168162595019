import classNames from 'classnames'
import { isFinite } from 'lodash'
import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import { formatNumber } from '../../lib/formatters'
import logger from '../../lib/logger'

const propTypes = {
  value: PropType.oneOfType([PropType.number, PropType.string]).isRequired,
  className: PropType.string,
  end: PropType.element,
  start: PropType.element,
  theme: PropType.oneOf(['primary', 'danger'])
}

const defaultProps = {
  className: null,
  end: null,
  start: null,
  theme: 'primary'
}

const log = logger({ enabled: false, tags: ['Pill'] })

const Pill = ({ className, end, start, value, theme }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = classNames(
    'grow-0 w-fit flex flex-row flex-nowrap items-center label-2xs-strong py-[2px] px-[6px] rounded-sm tabular-nums',
    {
      'bg-sky-100 text-primary': theme === 'primary',
      'bg-red-100 text-danger': theme === 'danger'
    },
    className
  )
  return (
    <div className={computedClassName}>
      {start ? <span className='mr-[2px] block h-[10px] w-[10px]'>{start}</span> : null}
      <span className='block'>{isFinite(value) ? formatNumber(value) : value}</span>
      {end ? <span className='ml-[2px] block h-[10px] w-[10px]'>{end}</span> : null}
    </div>
  )
}

Pill.displayName = 'Pill'
Pill.propTypes = propTypes
Pill.defaultProps = defaultProps

export default Pill
