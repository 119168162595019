import { omit } from 'lodash'
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import ConfirmDialog from '../../components/dialog/ConfirmDialog'
import Form from '../../components/forms/Form'
import LoadingIndicator from '../../components/LoadingIndicator'
import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import DraftContactsDetailSection from '../../components/scheduled-broadcast/actions/create/DraftContactsDetailSection'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import useTimeZone from '../../hooks/useTimeZone'
import bus from '../../lib/bus'
import { formatDateForInput, formatTimeForInput } from '../../lib/formatters'
import logger from '../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../lib/timeZones'
import {
  createDraft as createDraftService,
  del as deleteDraftService,
  scheduleDraft as scheduleDraftService,
  update as updateDraftService
} from '../../services/scheduledBroadcast'
import { setCurrentInstance } from '../../store/actions/currentInstance'

import validationSuite from './NewScheduledBroadcast.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['NewScheduledBroadcast'] })

const NewScheduledBroadcast = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [draft, setDraft] = useState(null)
  const navigate = useNavigate()
  const confirmCancelRef = useRef()
  const contactsDetailRef = useRef()
  const formId = useId()
  const { userTimeZone } = useTimeZone()

  const { call: scheduleDraftCall } = useService(scheduleDraftService, {
    onReplyOk: useCallback((reply) => {
      const scheduledBroadcast = reply.model
      setCurrentInstance('scheduledBroadcast', scheduledBroadcast)
      bus.emit('scheduledBroadcastCreated', scheduledBroadcast)
      navigate(`/scheduled-broadcasts/${scheduledBroadcast._id}`)
    }, [navigate])
  })

  const { call: createDraftCall } = useService(createDraftService, {
    onReplyOk: useCallback((reply) => setDraft(reply.model), [])
  })

  const { call: updateDraftCall } = useService(updateDraftService, {
    onReplyOk: useCallback(() => {
      scheduleDraftCall(draft?._id)
    }, [draft?._id, scheduleDraftCall])
  })

  const { call: deleteDraftCall } = useService(deleteDraftService, {
    onReplyOk: useCallback(() => {
      navigate('/scheduled-broadcasts')
    }, [navigate])
  })

  useEffect(() => {
    if (!draft) {
      createDraftCall()
    }
  }, [createDraftCall, draft, navigate])

  const handleCloseConfirm = useCallback(() => {
    deleteDraftCall(draft?._id)
  }, [deleteDraftCall, draft?._id])

  const handleClose = useCallback(() => {
    confirmCancelRef.current.open()
  }, [])

  const handleSubmit = useCallback((data) => {
    if (draft.contacts.length > 0) {
      updateDraftCall(draft._id, data)
    } else {
      contactsDetailRef.current.setError('Send To is required.')
    }
  }, [updateDraftCall, draft])

  const { confirm, defaultValues, formControls, transformData } = useMemo(() => {
    return {
      confirm: {
        description: `Are you sure you want to schedule this broadcast to send immediately?
                      Clicking OK will cause your broadcast to begin sending to your recipients now.`,
        when: ({ scheduledAt }) => !!scheduledAt && (new Date(scheduledAt) < new Date())
      },
      defaultValues: {
        message: { text: '', type: 'sms', media: [] },
        scheduledAtDay: formatDateForInput(new Date(), userTimeZone),
        scheduledAtTime: formatTimeForInput(new Date(), userTimeZone)
      },
      formControls: [
        {
          label: 'Message',
          type: 'message',
          name: 'message'
        },
        {
          type: 'passthrough',
          name: 'passthrough1',
          element: <DraftContactsDetailSection ref={contactsDetailRef} scheduledBroadcast={draft} onChange={setDraft} />
        },
        {
          label: 'Date',
          name: 'scheduledAtDay',
          hint: 'Specify the date to send this broadcast.',
          type: 'date'
        },
        {
          label: 'Time',
          name: 'scheduledAtTime',
          hint: 'Specify the time of day to start sending this broadcast.',
          type: 'time-message-send'
        }
      ],
      transformData: (data) => {
        const { scheduledAtDay, scheduledAtTime } = data
        data.scheduledAt = dateAtDayAndTimeInTimeZone(scheduledAtDay, scheduledAtTime, userTimeZone)?.toISOString()
        return omit(data, 'scheduledAtDay', 'scheduledAtTime')
      }
    }
  }, [draft, userTimeZone])

  return (
    <Panel>
      <PanelHeader
        end={(<PanelHeaderButton form={formId} title='Save' type='submit' />)}
        start={(<PanelHeaderButton icon='close' onClick={handleClose} />)}
        title='Schedule Broadcast'
      />
      <PanelContent className='p-5'>
        {!draft
          ? <LoadingIndicator className='mx-auto' message='Creating Draft...' />
          : (
            <>
              <p className='mb-4'>
                You have something to say? Awesome! This message can be text with
                an image or a voicemail! Make it personal. Make it awesome!
              </p>
              <Form
                confirm={confirm}
                defaultValues={defaultValues}
                formControls={formControls}
                id={formId}
                transformData={transformData}
                validationSuite={validationSuite}
                onSubmit={handleSubmit}
              />
              <Button
                className='w-fit'
                form={formId}
                size='md'
                type='submit'
              >
                Save
              </Button>
              <ConfirmDialog
                ref={confirmCancelRef}
                description='Are you sure you want to close? Closing will result in the loss of your work.'
                title='Please Confirm'
                onConfirm={handleCloseConfirm}
              />
            </>
            )}
      </PanelContent>
    </Panel>
  )
}

NewScheduledBroadcast.displayName = 'NewScheduledBroadcast'
NewScheduledBroadcast.propTypes = propTypes
NewScheduledBroadcast.defaultProps = defaultProps

export default NewScheduledBroadcast
