import PropType from 'prop-types'

import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import scheduledBroadcastShape from '../../prop-types/shapes/scheduledBroadcast'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape)
}

const defaultProps = {
  scheduledBroadcast: null
}

const log = logger({ enabled: true, tags: ['ScheduledBroadcastSendingInfo'] })

const ScheduledBroadcastSendingInfo = ({ scheduledBroadcast }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser({ alsoReload: true })

  const creditsRequired = scheduledBroadcast?.contactsSending.length - scheduledBroadcast?.contactsSending.contactUsersCount
  const showCreditWarning = creditsRequired > currentUser.messageCredits

  return scheduledBroadcast?.contactsSending.length > 0 && (
    <div className='-mt-1'>
      {!!showCreditWarning && (
        <div className='mb-2 p-2 bg-danger text-white'>
          {
            currentUser.phoneNumberType === 'managed-voip'
              ? 'You will need more credits to send this broadcast to the remaining contacts.'
              : 'You will need to upgrade your account to send this broadcast to the remaining contacts.'
          }
        </div>
      )}
    </div>
  )
}

ScheduledBroadcastSendingInfo.displayName = 'ScheduledBroadcastSendingInfo'
ScheduledBroadcastSendingInfo.propTypes = propTypes
ScheduledBroadcastSendingInfo.defaultProps = defaultProps

export default ScheduledBroadcastSendingInfo
