import PropType from 'prop-types'

export default {
  key: PropType.string,
  logo: PropType.string,
  name: PropType.string,
  description: PropType.string,
  request: PropType.string,
  url: PropType.string,
  enabled: PropType.bool,
  display: PropType.bool
}
