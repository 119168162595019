import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { mergeRefs } from 'react-merge-refs'

import useDefaultId from '../../../hooks/useDefaultId'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

const propTypes = { // passes along extra props to the input
  name: PropType.string.isRequired,
  dangerouslySetInnerHTML: PropType.bool,
  defaultChecked: PropType.bool,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  labelAlignItems: PropType.oneOf(['items-start', 'items-end', 'items-center', 'items-baseline', 'items-stretch']),
  labelAlignment: PropType.oneOf(['left', 'right']),
  title: PropType.string,
  onChange: PropType.func
}

const defaultProps = {
  dangerouslySetInnerHTML: false,
  defaultChecked: undefined,
  error: null,
  hint: null,
  id: null,
  label: null,
  labelAlignItems: 'items-center',
  labelAlignment: 'left',
  onChange: null,
  title: null
}

const log = logger({ enabled: false, tags: ['Checkbox'] })

const Checkbox = forwardRef(({
  dangerouslySetInnerHTML,
  defaultChecked,
  error,
  hint,
  id,
  label,
  labelAlignItems,
  labelAlignment,
  name,
  onChange,
  title,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  id = useDefaultId(id)

  useLogger({ log, lifecycle: true, tags: [name, id] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  const labelClassName = classNames('label-sm-medium', {
    'mr-2': labelAlignment === 'left',
    'flex-grow': labelAlignment === 'left',
    'ml-2': labelAlignment === 'right'
  })

  const labelElement = useMemo(() => {
    if (!label) { return null }
    if (dangerouslySetInnerHTML) {
      // eslint-disable-next-line react/no-danger
      return <span className={labelClassName} dangerouslySetInnerHTML={{ __html: label }} />
    }
    return <span className={labelClassName}>{label}</span>
  }, [dangerouslySetInnerHTML, label, labelClassName])

  const titleElement = !title
    ? null
    : (
      <InputLabel text={title} />
      )

  const registerProps = useFormMethods?.register
    ? useFormMethods.register(name, { onChange })
    : { onChange }

  const mergedRef = mergeRefs([ref, registerProps.ref])
  delete registerProps.ref

  return (
    <>
      {titleElement}
      <label className={`flex ${labelAlignItems}`}>
        {labelAlignment === 'left' ? labelElement : null}
        <input
          ref={mergedRef}
          defaultChecked={defaultChecked}
          id={id}
          name={name}
          type='checkbox'
          {...rest}
          {...registerProps}
        />
        {labelAlignment === 'right' ? labelElement : null}
      </label>
      <InputError message={error?.message || error} />
      <InputHint message={hint} />
    </>
  )
})

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
