import PropType from 'prop-types'

import billingAccountFromJSON from '../../models/billing-account'
import pb from '../pb'

import { searchPropTypes } from './billing-account.prop-types'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'billing-account#search')
  return pb.setupPost('admin/billingAccounts', {
    background,
    model: {
      conversion: billingAccountFromJSON,
      multiple: true
    }
  })
}

export function get (billingAccountId, background = false) {
  return pb.setupGet(`admin/billingAccounts/${billingAccountId}`, {
    background,
    model: {
      conversion: billingAccountFromJSON
    }
  })
}
