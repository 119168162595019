import PropType from 'prop-types'

import { searchPropTypes as contactSearchPropTypes } from './contact.prop-types'
import pb from './pb'
import { applyTagToContactsPropTypes, searchPropTypes, updatePropTypes } from './tag.prop-types'

export function search (body, background = false) {
  const options = { body, background }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'tag#search')
  return pb.setupPost('tags/search', options)
}

export function addSelectedContacts (tagName, contactIds) {
  const body = { contactIds }
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/add`, { body })
}

export function addAllContactsMatchingSearch (tagName, contactSearch) {
  PropType.checkPropTypes(contactSearchPropTypes, contactSearch, 'prop', 'tag#addAllContactsMatchingSearch')
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/add`, {
    body: contactSearch
  })
}

export function get (tagName, background = false) {
  return pb.setupGet(`tags/${encodeURI(tagName)}`, { background })
}

export function update (tagName, updateData) {
  const options = { body: updateData }
  PropType.checkPropTypes(updatePropTypes, updateData, 'prop', 'tag#update')
  return pb.setupPut(`tags/${encodeURI(tagName)}`, options)
}

export function bulkDelete (body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupDelete('tags/bulk/delete', options)
}

export function del (tagName) {
  return pb.setupDelete(`tags/${encodeURI(tagName)}`)
}

export function applyTagToContacts (data) {
  PropType.checkPropTypes(applyTagToContactsPropTypes, data, 'prop', 'tag#applyTagToContacts')
  const { tagName, ...body } = data
  return pb.setupPut(`tags/${encodeURI(tagName)}/contacts/add/all`, { body })
}

export function removeAllTags () {
  return pb.setupPost('tags/remove-all')
}
