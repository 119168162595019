import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import { PanelProvider } from '../../contexts/PanelContext'
import useSmallScreen from '../../hooks/useSmallScreen'

import BaseDialog from './BaseDialog'

import './PanelDialog.css'

const propTypes = {
  ariaLabel: PropType.string.isRequired,
  children: PropType.node.isRequired,
  ariaDescription: PropType.string,
  dismissable: PropType.bool,
  fullscreen: PropType.bool,
  maxHeight: PropType.bool,
  open: PropType.bool,
  trigger: PropType.element,
  onClose: PropType.func,
  onOpen: PropType.func
}

const defaultProps = {
  ariaDescription: null,
  dismissable: true,
  fullscreen: false,
  maxHeight: false,
  onClose: undefined,
  onOpen: undefined,
  open: false,
  trigger: null
}

const PanelDialog = forwardRef(({
  ariaDescription,
  ariaLabel,
  dismissable,
  children,
  fullscreen,
  maxHeight,
  open: initialOpen,
  trigger,
  onClose,
  onOpen,
  ...dialogProps
}, ref) => {
  const smallScreen = useSmallScreen()
  fullscreen = fullscreen || smallScreen

  const className = classNames(
    'panel-dialog bg-white rounded-lg overflow-hidden flex flex-col',
    {
      fullscreen,
      'max-height': maxHeight
    }
  )

  return (
    <BaseDialog
      ref={ref}
      ariaDescription={ariaDescription}
      ariaLabel={ariaLabel}
      dismissable={dismissable}
      open={initialOpen}
      trigger={trigger}
      onClose={onClose}
      onOpen={onOpen}
      {...dialogProps}
    >
      <PanelProvider slot='dialog'>
        <div className={className}>
          {children}
        </div>
      </PanelProvider>
    </BaseDialog>
  )
})

PanelDialog.displayName = 'PanelDialog'
PanelDialog.propTypes = propTypes
PanelDialog.defaultProps = defaultProps

export default PanelDialog
