import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import { generateAdvancedSearchFromSearchData, generateFormControls, generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/contactListSearchBar'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useTimeZone from '../../hooks/useTimeZone'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import ListDialog from '../dialog/ListDialog'
import AdvancedSearchDialog from '../search-bar/AdvancedSearchDialog'
import SearchBar from '../search-bar/SearchBar'
import SearchBarButton from '../search-bar/SearchBarButton'
import TagList from '../tags/TagList'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  // NOTE: search only works for data that does not get transformed
  search: PropType.object, // eslint-disable-line react/forbid-prop-types
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  placeholder: undefined,
  search: null,
  sortList: null
}

const log = logger({ enabled: true, tags: ['ContactListSearchBar'] })

const ContactListSearchBar = ({
  search: initialSearch,
  sortList,
  placeholder,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()
  const tagListDialogRef = useRef()

  const currentUser = useCurrentUser()
  const { userTimeZone } = useTimeZone()

  const [advancedSearchData, setAdvancedSearchData] = useState(generateAdvancedSearchFromSearchData(initialSearch))

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const handleTagListSubmitSelected = useCallback((selectedItems) => {
    const newAdvancedSearchData = { ...advancedSearchData, tags: selectedItems }
    setAdvancedSearchData(newAdvancedSearchData)
    const search = generateSearchFromAdvancedSearchFormData(newAdvancedSearchData)
    searchBarRef.current.update(search)
  }, [advancedSearchData])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData, userTimeZone)
  }, [advancedSearchData, userTimeZone])

  const hasAdvancedSearchDataCheck = useCallback(() => {
    const summariesWithoutTerm = generateSummaryFromAdvancedSearchFormData(advancedSearchData, userTimeZone, true)
    log.debug('summariesWithoutTerm', summariesWithoutTerm, summariesWithoutTerm.length)
    return summariesWithoutTerm.length !== 0
  }, [advancedSearchData, userTimeZone])

  const formControls = useMemo(() => { return generateFormControls(currentUser) }, [currentUser])

  const end = (
    <ListDialog
      ref={tagListDialogRef}
      list={TagList}
      selectedItems={[...(advancedSearchData.tags || [])]}
      trigger={<SearchBarButton icon='tags' />}
      type='select'
      multiple
      search
      onSubmitSelected={handleTagListSubmitSelected}
    />
  )

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        end={end}
        generateSummary={generateSummary}
        hasAdvancedSearchDataCheck={hasAdvancedSearchDataCheck}
        placeholder={placeholder}
        search={advancedSearchData}
        sortList={sortList}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onTermChange={handleSearchBarTermChange}
      />
      <AdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        formControls={formControls}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>
  )
}

ContactListSearchBar.displayName = 'ContactListSearchBar'
ContactListSearchBar.propTypes = propTypes
ContactListSearchBar.defaultProps = defaultProps

export default ContactListSearchBar
