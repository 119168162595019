import PropType from 'prop-types'
import { useCallback } from 'react'

import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import { Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const IntegrationListHeaderMoreMenu = ({ listRef }) => {
  const handleReloadClick = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadClick} />
  )
}

IntegrationListHeaderMoreMenu.displayName = 'IntegrationListHeaderMoreMenu'
IntegrationListHeaderMoreMenu.propTypes = propTypes
IntegrationListHeaderMoreMenu.defaultProps = defaultProps

export default IntegrationListHeaderMoreMenu
