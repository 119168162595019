import * as React from "react";
import { memo } from "react";
const SvgBundles = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 180"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v180H0z" />
    <circle
      cx={188.5}
      cy={89.5}
      r={84}
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
    />
    <circle cx={72.5} cy={142.5} r={6.5} fill="#E1E7FF" />
    <circle cx={90.5} cy={40.5} r={4.5} fill="#E1E7FF" />
    <circle cx={287} cy={44} r={6} fill="#E1E7FF" />
    <circle cx={305} cy={14} r={4} fill="#E1E7FF" />
    <path
      fill="#fff"
      d="M244 121.75v-64.5L188 25l-56 32.25v64.5L188 154l56-32.25Z"
    />
    <path
      fill="#D2D5DC"
      d="m188 27.41 53.914 31.28v62.578L188 152.547l-53.914-31.258V58.711L188 27.41Zm0-2.41-56 32.495v64.989l56 32.495 56-32.495v-64.99L188 25Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m132.314 59.01.999-1.731L188 88.835l54.332-31.388 1 1.731L188 91.144l-55.686-32.133Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M187 153.344V89.99h2v63.354h-2ZM224.626 48.17l-54.749 31.804-1.004-1.73 54.748-31.803 1.005 1.73ZM202.56 35.76l-54.749 31.803-1.005-1.73 54.749-31.802 1.005 1.729Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m146.309 64.816 24.525 14.22v21.244l-24.525-14.243V64.816Zm2 3.471v16.599l20.525 11.92V80.188l-20.525-11.9ZM237.95 97.248v21.221l-24.525 14.243v-21.244l24.525-14.22Zm-22.525 15.372v16.618l20.525-11.921v-16.598l-20.525 11.901ZM146.225 109.342l24.525 14.244v21.243l-24.525-14.243v-21.244Zm2 3.475v16.618l20.525 11.92v-16.618l-20.525-11.92Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBundles);
export default Memo;
