import classNames from 'classnames'
import { clamp } from 'lodash'
import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  className: PropType.string,
  score: PropType.number
}

const defaultProps = {
  className: undefined,
  score: undefined
}

const log = logger({ enabled: true, tags: [] })

const MessageQualityRatingBar = ({ className, score }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const clampedScore = clamp(score, 0, 100)
  const style = {
    top: '-2px'
  }
  if (clampedScore >= 95) { style.left = `${100 - clampedScore}px` }
  if (clampedScore < 95) { style.right = `${clampedScore}%` }

  const computedClassName = classNames('relative h-[20px]', className)

  return (
    <div className={computedClassName}>
      <div className='absolute right-0 border-[3px] border-white h-[20px] w-full bg-red-500 rounded-full' />
      <div className='absolute right-0 border-[3px] border-white h-[20px] w-9/12 bg-orange-500 rounded-full' />
      <div className='absolute right-0 border-[3px] border-white h-[20px] w-6/12 bg-yellow-300 rounded-full' />
      <div className='absolute right-0 border-[3px] border-white h-[20px] w-3/12 bg-emerald-300 rounded-full' />
      <div className='absolute border-[3px] border-white rounded-full h-[24px] w-[22px] drop-shadow-extreme' style={style} />
    </div>
  )
}

MessageQualityRatingBar.displayName = 'MessageInsightsRatingBar'
MessageQualityRatingBar.propTypes = propTypes
MessageQualityRatingBar.defaultProps = defaultProps

export default MessageQualityRatingBar
