import { useEffect, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShortLinkLoading'] })

const showDelay = 750

const ShortLinkLoading = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const timeoutRef = useRef()
  const [show, setShow] = useState(false)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, showDelay)
    return () => { clearTimeout(timeoutRef.current) }
  }, [])

  return (
    <div className='flex flex-col w-full items-center p-4'>
      {show ? <span className='animate-pulse'>Loading...</span> : null}
    </div>
  )
}

ShortLinkLoading.displayName = 'ShortLinkLoading'
ShortLinkLoading.propTypes = propTypes
ShortLinkLoading.defaultProps = defaultProps

export default ShortLinkLoading
