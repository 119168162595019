import { useCallback, useEffect, useRef } from 'react'

import { firstMediaUrl } from '../helpers/messageMedia'

function useMessageDraft ({ draftKey, draftMessageOverride, onRestoreDraft }) {
  const saveDraftTimeoutRef = useRef(null)

  useEffect(() => {
    if (!draftKey) { return }
    if (draftMessageOverride) {
      onRestoreDraft(draftMessageOverride)
      return
    }

    const messageFromLocalStorage = localStorage.getItem(draftKey)
    if (messageFromLocalStorage) {
      onRestoreDraft(JSON.parse(messageFromLocalStorage))
    }
  }, [draftKey, draftMessageOverride, onRestoreDraft])

  const saveDraft = useCallback((messageToSave) => {
    if (saveDraftTimeoutRef.current) { clearTimeout(saveDraftTimeoutRef.current) }

    function save () {
      const text = (messageToSave.text || '').trim()
      const { upload, call } = messageToSave
      const mediaUrl = firstMediaUrl(messageToSave)

      const isEmpty = !text && !upload && !call && !mediaUrl

      try {
        if (isEmpty) {
          localStorage.removeItem(draftKey)
        } else {
          const messageForLocalStorage = JSON.stringify(messageToSave)
          localStorage.setItem(draftKey, messageForLocalStorage)
        }
      } catch (e) {}
    }

    saveDraftTimeoutRef.current = setTimeout(save, 250)
  }, [draftKey])

  return { saveDraft }
}

export default useMessageDraft
