import { find, forEach, startCase } from 'lodash'
import { useCallback } from 'react'
import { create, enforce, only, test } from 'vest'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import WarningDetailSection from '../../components/detail-sections/WarningDetailSection'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { formatTimeForInput } from '../../lib/formatters'
import logger from '../../lib/logger'
import poller from '../../lib/poller'
import { localTimeZone } from '../../lib/timeZones'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

const days = [{
  day: 'monday',
  dayOfWeek: 1
}, {
  day: 'tuesday',
  dayOfWeek: 2
}, {
  day: 'wednesday',
  dayOfWeek: 3
}, {
  day: 'thursday',
  dayOfWeek: 4
}, {
  day: 'friday',
  dayOfWeek: 5
}, {
  day: 'saturday',
  dayOfWeek: 6
}, {
  day: 'sunday',
  dayOfWeek: 0
}]

const log = logger({ enabled: true, tags: ['BroadcastHours'] })

const BroadcastHours = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    poller.runImmediately()
    notify('Broadcast Hours updated.')
  }, [])

  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateAction, { onReplyOk: handleReplyOkay })

  const handleSubmit = useCallback((data) => {
    const officeHours = []
    forEach(days, ({ day, dayOfWeek }) => {
      if (data[`${day}From`] && data[`${day}To`]) {
        const [startHour, startMinute] = data[`${day}From`].split(':')
        const [endHour, endMinute] = data[`${day}To`].split(':')
        officeHours.push({
          dayOfWeek,
          startHour,
          startMinute,
          endHour,
          endMinute
        })
      }
    })
    updateProfile({ officeHours, outOfOffice: data.outOfOffice })
  }, [updateProfile])

  const formControls = [{
    type: 'checkbox',
    name: 'outOfOffice',
    label: 'Enabling this will override all settings below and pause broadcasting immediately until turned off.',
    title: 'Pause Broadcasting'
  }]
  const defaultValues = {
    outOfOffice: currentUser.outOfOffice
  }
  forEach(days, ({ day, dayOfWeek }) => {
    formControls.push({
      type: 'passthrough',
      name: day,
      element: (
        <div className='label-xl'>
          {startCase(day)}
        </div>
      )
    })
    formControls.push({
      type: 'controlgroup',
      name: `${day}ControlGroup`,
      className: 'flex-wrap',
      formControls: [
        {
          type: 'time',
          name: `${day}From`,
          label: 'From',
          containerClassName: 'flex-grow -mt-4',
          clearable: true
        },
        {
          type: 'time',
          name: `${day}To`,
          label: 'To',
          containerClassName: 'flex-grow -mt-4',
          clearable: true
        }
      ]
    })
    const officeHour = find(currentUser.officeHours, (oh) => oh.dayOfWeek === dayOfWeek)
    if (officeHour) {
      const startDate = new Date()
      startDate.setHours(officeHour.startHour)
      startDate.setMinutes(officeHour.startMinute)

      const endDate = new Date()
      endDate.setHours(officeHour.endHour)
      endDate.setMinutes(officeHour.endMinute)

      defaultValues[`${day}From`] = formatTimeForInput(startDate, localTimeZone)
      defaultValues[`${day}To`] = formatTimeForInput(endDate, localTimeZone)
    }
  })

  const formProps = {
    id: 'broadcastHoursForm',
    defaultValues,
    formControls,
    onSubmit: handleSubmit,
    validationSuite: create((data = {}, currentField) => {
      only(currentField)

      forEach(days, ({ day }) => {
        test(`${day}From`, 'Required.', () => {
          if (data[`${day}To`]) {
            enforce(data[`${day}From`]).isNotEmpty()
          }
        })
        test(`${day}To`, 'Required.', () => {
          if (data[`${day}From`]) {
            enforce(data[`${day}To`]).isNotEmpty()
          }
        })
        test(`${day}To`, 'Must be after From.', () => {
          if (data[`${day}From`]) {
            const [fromHour, fromMin] = data[`${day}From`].split(':')
            const [toHour, toMin] = data[`${day}To`].split(':')
            enforce(!((toHour < fromHour) || ((toHour === fromHour) && (toMin < fromMin)))).isTruthy()
          }
        })
      })
    })
  }

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='broadcastHoursForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Broadcast Hours'
      />
      <PanelContent className='p-4'>
        <div className='pb-4'>Define the times you would like Project Broadcast to broadcast messages on your behalf. This ensures your Broadcasts and Campaign Messages are only sent at times you are available to reply. Chat messages do not adhere to these settings.</div>
        <div className='pb-4'>Provide the hours for each day you would like Project Broadcast to broadcast messages on your behalf. if ALL days are blank it is assumed Project Broadcast can broadcast for you at any time.</div>
        <WarningDetailSection text='Broadcasts scheduled for delivery during non broadcast hours will be paused until your next availability and will send IMMEDIATELY.' />
        <Form {...formProps} />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='broadcastHoursForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

BroadcastHours.displayName = 'BroadcastHours'

export default BroadcastHours
