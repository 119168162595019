import classNames from 'classnames'
import PropType from 'prop-types'

import { messageDirections } from '../../../helpers/message'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Key } from '../../../svg/icons'

const propTypes = {
  direction: PropType.oneOf(messageDirections).isRequired,
  keywordAppliedByOtherDisplayText: PropType.string,
  keywordId: PropType.string
}

const defaultProps = {
  keywordAppliedByOtherDisplayText: null,
  keywordId: null
}

const log = logger({ enabled: false, tags: ['MessageThreadItemKeyword'] })

const MessageThreadItemKeyword = ({ direction, keywordId, keywordAppliedByOtherDisplayText }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!keywordId || direction !== 'in') { return null }

  const className = classNames('flex flex-row items-center italic label-sm mt-1')

  return (
    <div className={className}>
      <Key height='14' width='14' />
      {keywordAppliedByOtherDisplayText ? <span className='ml-1'>{keywordAppliedByOtherDisplayText}</span> : null}
    </div>
  )
}

MessageThreadItemKeyword.displayName = 'MessageThreadItemKeyword'
MessageThreadItemKeyword.propTypes = propTypes
MessageThreadItemKeyword.defaultProps = defaultProps

export default MessageThreadItemKeyword
