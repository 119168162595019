import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import messageQualityShape from '../../../../prop-types/shapes/messageQuality'
import TabButton from '../../../buttons/TabButton'
import MessageQualityBadge from '../../../message/message-quality/MessageQualityBadge'

const propTypes = {
  currentTab: PropType.oneOf(['revise-message', 'message-strength']).isRequired,
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  onSelectTab: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TabSelector'] })

const TabSelector = ({ currentTab, messageQuality, onSelectTab }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleReviseMessageTabClick = useCallback(() => {
    onSelectTab('revise-message')
  }, [onSelectTab])

  const handleMessageStrengthTabClick = useCallback(() => {
    onSelectTab('message-strength')
  }, [onSelectTab])

  return (
    <div className='flex flex-row border-b border-neutral-200'>
      <TabButton selected={currentTab === 'revise-message'} onClick={handleReviseMessageTabClick}>Revise Message</TabButton>
      <TabButton selected={currentTab === 'message-strength'} onClick={handleMessageStrengthTabClick}>
        Message Strength
        {' '}
        <MessageQualityBadge className='ml-1' messageQuality={messageQuality} />
      </TabButton>
    </div>
  )
}

TabSelector.displayName = 'TabSelector'
TabSelector.propTypes = propTypes
TabSelector.defaultProps = defaultProps

export default TabSelector
