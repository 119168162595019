import { useCallback } from 'react'

import MessageDetailSection from '../../components/detail-sections/MessageDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentMessageTemplate from '../../hooks/useCurrentMessageTemplate'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import { CONTENT_TYPE, stateKeyFromContentType } from '../../lib/folder'
import logger from '../../lib/logger'
import { update as updateTemplateService } from '../../services/template'

import TemplateDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MessageTemplatesDetail'] })

const MessageTemplatesDetail = () => {
  useLogger({ log, lifecycle: false, tags: ['MessageTemplatesDetail'] })

  const { messageTemplate, setMessageTemplate, refresh, loading } = useCurrentMessageTemplate()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const stateKey = stateKeyFromContentType(CONTENT_TYPE.messageTemplate)
    const updateTemplate = updateReply.json
    setMessageTemplate(updateTemplate)
    bus.emit(`${stateKey}Updated`, updateTemplate)
  }, [setMessageTemplate])

  if (loading || !messageTemplate) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<TemplateDetailHeaderMoreMenu template={messageTemplate} />}
        start={<PanelHeaderBackButton />}
        title={messageTemplate.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          description='Message Templates are time savers and are perfect for reusing messages throughout Project Broadcast.'
          object={messageTemplate}
          objectName='template'
          service={updateTemplateService}
          onChange={handleDetailsUpdate}
        />
        <MessageDetailSection
          contentOptions={{
            emails: false,
            messageTemplates: false,
            voiceDrops: false
          }}
          description='Text messages can be text with or without an image.'
          message={messageTemplate}
          service={updateTemplateService}
          onChange={handleDetailsUpdate}
          onRefreshForVoice={refresh}
        />
      </PanelContent>
    </Panel>
  )
}

MessageTemplatesDetail.displayName = 'MessageTemplatesDetail'
MessageTemplatesDetail.propTypes = propTypes
MessageTemplatesDetail.defaultProps = defaultProps

export default MessageTemplatesDetail
