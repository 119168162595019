import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import Avatar from '../../../components/avatar/Avatar'
import Button from '../../../components/buttons/Button'
import RemoveAction from '../../../components/managers/actions/RemoveAction'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import managerShape from '../../../prop-types/shapes/manager'

const propTypes = {
  item: PropType.shape(managerShape).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['ManagerListItemContent'] })

const ManagerListItemContent = ({ item: manager }) => {
  useLogger({ log, lifecycle: false, tags: [manager?.key] })
  const removeActionRef = useRef()

  const handleRemoveClick = useCallback(() => { removeActionRef.current.activate() }, [])

  return (
    <>
      <Avatar contactOrUser={manager} size='sm' />
      <div className='flex-grow flex flex-col ml-[12px] py-[4px]'>
        <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
          {manager.formattedName}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {manager.formattedPhoneNumber}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {manager.email}
        </div>
      </div>
      <div className='self-center'>
        <Button
          size='md'
          theme='danger'
          variant='text'
          onClick={handleRemoveClick}
        >
          Remove
        </Button>
      </div>
      <RemoveAction ref={removeActionRef} manager={manager} />
    </>
  )
}

ManagerListItemContent.displayName = 'ManagerListItemContent'
ManagerListItemContent.propTypes = propTypes
ManagerListItemContent.defaultProps = defaultProps

export default ManagerListItemContent
