import { get as getQrCodeService } from '../services/qrCode'

import useCurrentInstance from './useCurrentInstance'

const useCurrentQrCode = () => useCurrentInstance({
  resourceName: 'qrCode',
  getService: getQrCodeService,
  replyAttrPath: 'json',
  notFoundRedirect: '/qr-codes'
})

export default useCurrentQrCode
