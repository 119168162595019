import { Link } from 'react-router-dom'

import useCurrentUser from '../../../../hooks/useCurrentUser'
import useLogger from '../../../../hooks/useLogger'
import { formatPhone } from '../../../../lib/formatters'
import logger from '../../../../lib/logger'
import Banner from '../../../banners/Banner'
import Well from '../../../wells/Well'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CallTab'] })

const CallTab = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()
  const unsupportedPlan = currentUser.phoneNumberType !== 'managed-voip'
  const missingCallForwardingNumber = !currentUser.callForwardNumber
  const callForwardingNotEnabled = !currentUser.callForwardEnabled

  if (unsupportedPlan) {
    return (<Banner title='Unsupported' type='warning'>Your current plan does not support the Call to Record feature.</Banner>)
  }

  if (missingCallForwardingNumber) {
    return (
      <Banner title='Unsupported' type='warning'>
        Please add a
        {' '}
        <Link className='link' to='/settings/call-forwarding'>call forwarding number</Link>
        .
      </Banner>
    )
  }

  if (callForwardingNotEnabled) {
    return (
      <Banner title='Unsupported' type='warning'>
        Please enable
        {' '}
        <Link className='link' to='/settings/call-forwarding'>call forwarding</Link>
        .
      </Banner>
    )
  }

  return (
    <Well>
      <p className='headline-2xs'>Record from your phone</p>
      After clicking save, the system will call your
      {' '}
      <Link className='link' to='/settings/call-forwarding'>Call Forwarding phone number</Link>
      {' ('}
      {formatPhone(currentUser.callForwardNumber)}
      {') '}
      and prompt you to record your voice drop.
    </Well>
  )
}

CallTab.displayName = 'CallTab'
CallTab.propTypes = propTypes
CallTab.defaultProps = defaultProps

export default CallTab
