import { get as getScheduledBroadcastService } from '../services/scheduledBroadcast'

import useCurrentInstance from './useCurrentInstance'

const useCurrentScheduledBroadcast = () => useCurrentInstance({
  resourceName: 'scheduledBroadcast',
  getService: getScheduledBroadcastService,
  replyAttrPath: 'model',
  notFoundRedirect: '/scheduled-broadcasts'
})

export default useCurrentScheduledBroadcast
