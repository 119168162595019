import PropType from 'prop-types'

const propTypes = {
  message: PropType.string
}

const defaultProps = {
  message: null
}

const InputError = ({ message }) => {
  if (!message) { return null }
  return <p className='label-sm text-red-600 pt-1'>{message}</p>
}

InputError.displayName = 'InputError'
InputError.propTypes = propTypes
InputError.defaultProps = defaultProps

export default InputError
