import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import DangerousConfirmDialog from '../../components/dialog/DangerousConfirmDialog'
import SubscriptionSummary from '../../components/plans/SubscriptionSummary'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import useStackBackButton from '../../hooks/useStackBackButton'
import logger from '../../lib/logger'
import { changePlan as changePlanService } from '../../services/subscription'
import { update } from '../../store/actions/currentUser'

const propTypes = {
  cellPhoneNumber: PropType.string,
  phoneNumber: PropType.string,
  plan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  reanchorImmediately: PropType.bool
}

const defaultProps = {
  cellPhoneNumber: null,
  phoneNumber: null,
  plan: null,
  reanchorImmediately: false
}

const log = logger({ enabled: false, tags: ['ConfirmChangeSubscriptionDialog'] })

const ConfirmChangeSubscriptionDialog = forwardRef(({ plan, reanchorImmediately, cellPhoneNumber, phoneNumber }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { handleBackButtonClick } = useStackBackButton()

  const handlePlanChangeOk = useCallback(() => {
    notify('Your plan change request has been submitted.')
    handleBackButtonClick()
  }, [handleBackButtonClick])
  const { call: changePlan } = useServiceAndAction(changePlanService, update, { onReplyOk: handlePlanChangeOk })

  return (
    <DangerousConfirmDialog
      ref={ref}
      confirmButtonText='I understand, change it!'
      confirmValue='CHANGE'
      title='Are you absolutely sure?'
      onConfirm={() => {
        const planId = plan?.id
        changePlan({ planId, reanchorImmediately, cellPhoneNumber, phone: phoneNumber })
      }}
    >
      <SubscriptionSummary plan={plan} reanchorImmediately={reanchorImmediately} />
    </DangerousConfirmDialog>
  )
})

ConfirmChangeSubscriptionDialog.displayName = 'ConfirmChangeSubscriptionDialog'
ConfirmChangeSubscriptionDialog.propTypes = propTypes
ConfirmChangeSubscriptionDialog.defaultProps = defaultProps

export default ConfirmChangeSubscriptionDialog
