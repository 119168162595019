import { startCase } from 'lodash'

import goalMilestoneFromJSON from './goalMilestone'
import proxy from './proxy'

export default function goalFromJSON (json) {
  if (!json) { return null }

  const milestones = json.milestones
  delete json.milestones

  const overrides = {

    get displayName () {
      return startCase(json.name)
    },

    get milestones () {
      return milestones.map((milestone) => {
        const { _id, name, nudge, nudgeBehaviorDescription } = goalMilestoneFromJSON(milestone)
        return { _id, name, nudge, nudgeBehaviorDescription }
      })
    }
  }

  return proxy(json, overrides)
}
