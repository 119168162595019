import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ExternalLink from '../links/ExternalLink'
import PrivacyLink from '../links/PrivacyLink'
import SupportLink from '../links/SupportLink'
import TermsLink from '../links/TermsLink'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ShortLinkFooter'] })

const ShortLinkFooter = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <div className='mt-10 mb-1 text-center'>
        <p className='label-sm'>This content is neither created nor endorsed by Project Broadcast.</p>
      </div>
      <div className='mb-5 text-center'>
        <SupportLink className='link label-xs' label='Report Abuse' />
        {' - '}
        <TermsLink className='link label-xs' label='Terms of Service' />
        {' - '}
        <PrivacyLink className='link label-xs' label='Privacy Policy' />
      </div>
      <div className='text-center'>
        <ExternalLink className='link label-sm' href='https://www.projectbroadcast.com/' label='Project Broadcast' />
      </div>
    </>
  )
}

ShortLinkFooter.displayName = 'ShortLinkFooter'
ShortLinkFooter.propTypes = propTypes
ShortLinkFooter.defaultProps = defaultProps

export default ShortLinkFooter
