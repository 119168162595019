import PropType from 'prop-types'

import user from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  url: PropType.string,
  user: PropType.oneOfType([PropType.string, PropType.shape(user)]),
  search: PropType.string,
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  updatedAt: PropType.string // "2021-02-12T15:47:33.143Z"
}
