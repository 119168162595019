import Graphemer from 'graphemer'
import { has, pick } from 'lodash'
import { useCallback, useState } from 'react'
import { SegmentedMessage } from 'sms-segments-calculator'

import logger from '../lib/logger'

import useLogger from './useLogger'

// SEE: https://github.com/TwilioDevEd/message-segment-calculator
/*
  Props available on the `segmentedMessage` from the SegmentedMessage library
    encodingName
      Returns the name of the calculated encoding for the message: GSM-7 or UCS-2

    totalSize
      Total size of the message in bits (including User Data Header if present)

    messageSize
      Total size of the message in bits (excluding User Data Header if present)

    segmentsCount
      Number of segment(s)

  We also append:
    characterCount
      The "character count" by counting graphemes
*/

const splitter = new Graphemer()

const log = logger({ enabled: false, tags: ['useSegmentedCounter'] })

// Accepts a `message` object with a `text` prop or just the text of a message
const useSegmentedMessage = (initialMessage) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const getSegmentedMessage = (message) => {
    const text = (has(message, 'text') ? message.text : message) || ''
    const newSegmentedMessage = new SegmentedMessage(text)
    const result = pick(newSegmentedMessage, 'encodingName', 'totalSize', 'messageSize', 'segmentsCount')
    result.characterCount = splitter.countGraphemes(text)
    return result
  }

  const [result, setResult] = useState(getSegmentedMessage(initialMessage))

  const updateSegmentedMessage = useCallback((message) => {
    setResult(getSegmentedMessage(message))
  }, [])

  return { updateSegmentedMessage, ...result }
}

export default useSegmentedMessage
