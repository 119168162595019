import { get as getBundleService } from '../services/bundle'

import useCurrentInstance from './useCurrentInstance'

const useCurrentBundle = () => useCurrentInstance({
  resourceName: 'bundle',
  getService: getBundleService,
  replyAttrPath: 'model',
  notFoundRedirect: '/bundles'
})

export default useCurrentBundle
