import React from 'react'

import ChatsThread from '../screens/chats/ChatsThread'
import NewChatsThreadByPhoneNumber from '../screens/chats/NewChatsThreadByPhoneNumber'
import ScheduledChatsThread from '../screens/chats/ScheduledChatsThread'
import ContactsCampaigns from '../screens/contacts/ContactsCampaigns'
import ContactsDetail from '../screens/contacts/ContactsDetail'
import ContactsTags from '../screens/contacts/ContactsTags'

const routes = [
  // NOTE: this screen is also used in /contacts/:chatId/chat
  {
    path: ':chatId',
    element: <ChatsThread />
  },
  {
    path: ':chatId/scheduled',
    element: <ScheduledChatsThread />
  },
  {
    path: 'new/:phoneNumber',
    element: <NewChatsThreadByPhoneNumber />
  },
  // NOTE: using :contactId instead of :chatId because we're using a contacts based screen and that is necesaary for useCurrentContact to work properly
  {
    path: ':contactId/contact',
    element: <ContactsDetail />
  },
  {
    path: ':contactId/contact/campaigns',
    element: <ContactsCampaigns />
  },
  {
    path: ':contactId/contact/tags',
    element: <ContactsTags />
  }
]

export default routes
