import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'
import { applyKeyword } from '../../../services/contact'
import { success } from '../../banners/Banner'
import ListDialog from '../../dialog/ListDialog'
import KeywordList from '../../keywords/KeywordList'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['ApplyKeyword'] })

// NOTE: this is also used in the Chat Thread and gets passed a `chat` as the contact
const ApplyKeyword = forwardRef(({ contact, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const keywordRef = useRef()
  const dialogRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${keywordRef.current.word} was applied to ${contact.formattedName}`)
    onSuccess?.()
  }, [contact.formattedName, onSuccess])
  const { call } = useService(applyKeyword, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [dialogRef])

  const handleSubmit = useCallback((keyword) => {
    keywordRef.current = keyword
    call(contact._id, keyword._id, false)
  }, [call, contact._id])

  return (
    <ListDialog
      ref={dialogRef}
      list={KeywordList}
      multiple={false}
      title='Apply Keyword'
      type='select'
      search
      onSubmitSelected={handleSubmit}
    />
  )
})

ApplyKeyword.displayName = 'ApplyKeyword'
ApplyKeyword.propTypes = propTypes
ApplyKeyword.defaultProps = defaultProps

export default ApplyKeyword
