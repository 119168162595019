import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { markAllAsRead } from '../../../services/chat'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: undefined
}

const log = logger({ enabled: true, tags: ['MarkAllAsReadAction'] })

const MarkAllAsReadAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { confirmDialogRef.current.open() }
  }), [])

  const handleReplyOk = useCallback(() => onSuccess?.(), [onSuccess])
  const { call } = useService(markAllAsRead, { onReplyOk: handleReplyOk })

  const handleConfirm = useCallback(() => {
    call()
  }, [call])

  return (
    <ConfirmDialog
      ref={confirmDialogRef}
      description='This cannot be undone.'
      title='Are you sure you want to mark all messages as read?'
      onConfirm={handleConfirm}
    />
  )
})

MarkAllAsReadAction.displayName = 'MarkAllAsReadAction'
MarkAllAsReadAction.propTypes = propTypes
MarkAllAsReadAction.defaultProps = defaultProps

export default MarkAllAsReadAction
