import { useElements, useStripe } from '@stripe/react-stripe-js'
import { pick } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { useWizard } from 'react-use-wizard'

import { error as errorBanner } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import SubscriptionSummary from '../../../components/plans/SubscriptionSummary'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { formatPhone } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { allow as allowIntegrationService } from '../../../services/integration'
import { reactivate as reactivateService } from '../../../services/subscription'
import Header from '../shared/Header'
import AffiliatePromo from '../signup/AffiliatePromo'
import SecureCheckout from '../signup/SecureCheckout'

import validationSuite from './ManagedNumberForm.validations'

const propTypes = {
  affiliate: PropType.shape({
    name: PropType.string.isRequired
  }),
  existingPhoneNumber: PropType.string,
  integration: PropType.shape({
    key: PropType.string.isRequired
  }),
  numbers: PropType.objectOf(
    PropType.arrayOf(
      PropType.shape({
        nationalNumber: PropType.string.isRequired,
        number: PropType.string.isRequired
      })
    ).isRequired
  ),
  selectedPlan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired
  }),
  onSuccess: PropType.func
}

const defaultProps = {
  affiliate: null,
  existingPhoneNumber: null,
  integration: null,
  numbers: null,
  onSuccess: null,
  selectedPlan: null
}

const formSettings = {
  id: 'selectNumberForm',
  defaultValues: {},
  validationSuite
}

const log = logger({ enabled: false, tags: ['Sign-up', 'ManagedNumberForm'] })

const ManagedNumberForm = ({ numbers, selectedPlan, onSuccess, integration, existingPhoneNumber, affiliate, ...formData }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { goToStep } = useWizard()
  const stripe = useStripe()
  const elements = useElements()
  const optionsData = []
  const selectedNumber = useRef(null)
  const formRef = useRef()

  const redirectToWelcomeScreen = useCallback(() => {
    onSuccess({ ...formData, phoneNumber: existingPhoneNumber || selectedNumber.current })
    goToStep(4)
  }, [existingPhoneNumber, formData, goToStep, onSuccess])

  const handleAllowReplyOk = useCallback((reply) => {
    const redirect = reply.json?.redirect
    if (redirect) {
      // redirect back to integration site
      window.location.replace(redirect)
    } else {
      redirectToWelcomeScreen()
    }
  }, [redirectToWelcomeScreen])
  const { call: allowIntegration } = useService(allowIntegrationService, { onReplyOk: handleAllowReplyOk })

  const handleReactivateReplyOk = useCallback((data) => {
    if (data?.json?.phoneNumberType === 'needs-provisioning') {
      const mergedData = { ...formData, numbers, phoneNumber: selectedNumber.current }
      onSuccess(mergedData)
      goToStep(3) // select another number
    } else if (integration) {
      allowIntegration(integration.key, window.location.search)
    } else {
      redirectToWelcomeScreen()
    }
  }, [allowIntegration, formData, goToStep, integration, numbers, onSuccess, redirectToWelcomeScreen])

  const handleReactivateReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])

  const { call: reactivate } = useService(reactivateService, { onReplyOk: handleReactivateReplyOk, onReply: handleReactivateReply })

  if (existingPhoneNumber) {
    optionsData.push({
      label: formatPhone(existingPhoneNumber),
      value: existingPhoneNumber
    })
  } else if (numbers) {
    for (const key in numbers) {
      optionsData.push({
        label: key,
        value: numbers[key].map((info) => {
          return {
            label: info.nationalNumber,
            value: info.number
          }
        })
      })
    }
  }

  formSettings.onSubmit = useCallback(async ({ managedPhoneNumber }) => {
    selectedNumber.current = managedPhoneNumber
    const card = elements.getElement('card')
    const { error, token } = await stripe.createToken(card)

    if (error) {
      const isCustomerFriendlyStripeErrorType = error.type === 'card_error'
      const errorMessage = isCustomerFriendlyStripeErrorType
        ? error.message
        : 'Something went wrong. Please try again later.'
      errorBanner('Oh no!', errorMessage)
      return
    }

    const stripeResult = {
      id: token.id,
      card: pick(token.card, ['id', 'last4', 'brand', 'exp_month', 'exp_year'])
    }
    const data = {
      ...formData,
      managedPhoneNumber,
      stripeResult
    }

    log.debug('call reactivate with', data)
    reactivate(data)
  }, [elements, formData, reactivate, stripe])

  const formControls = [
    existingPhoneNumber
      ? {
          type: 'hidden',
          name: 'existingPhoneNumber',
          value: existingPhoneNumber
        }
      : null,
    existingPhoneNumber
      ? {
          type: 'passthrough',
          element: (
            <p>
              {'Your previous Project Broadcast phone number is available for reactivation: '}
              <strong className='whitespace-nowrap'>
                {formatPhone(existingPhoneNumber)}
              </strong>
            </p>
          ),
          name: 'existingPhoneNumber2'
        }
      : {
          autoFocus: true,
          name: 'managedPhoneNumber',
          type: 'select',
          label: 'Project Broadcast Phone Number',
          placeholder: 'Choose a phone number',
          hint: 'This will be the phone number all of your Project Broadcast messages originate from.',
          required: true,
          options: optionsData
        },
    {
      name: 'card',
      type: 'stripecardelement',
      label: 'Payment Info',
      hint: `Your card will be charged ${selectedPlan.frequency.toLowerCase()}.`
    }
  ]
  formSettings.formControls = formControls.filter((o) => o)

  const handleGoBack = useCallback(() => {
    goToStep(0)
  }, [goToStep])

  if (!(numbers || existingPhoneNumber)) {
    return
  }

  return (
    <>
      <Header
        goBack={handleGoBack}
        heading='Reactivate Account'
        title='Select a Number'
      />
      <div className='mt-5'>
        {integration
          ? null
          : (
              affiliate
                ? <AffiliatePromo
                    plan={selectedPlan}
                    referrerName={affiliate.name}
                  />
                : null
            )}
        <Form ref={formRef} {...formSettings} preventMultipleSubmits />
        <div className='paragraph-sm mb-4'>
          <SubscriptionSummary plan={selectedPlan} />
        </div>
        <Button
          className='my-4 w-full'
          form='selectNumberForm'
          size='md'
          type='submit'
        >
          Complete Reactivation
        </Button>
        <SecureCheckout />
      </div>
    </>
  )
}

ManagedNumberForm.displayName = 'ManagedNumberForm'
ManagedNumberForm.propTypes = propTypes
ManagedNumberForm.defaultProps = defaultProps

export default ManagedNumberForm
