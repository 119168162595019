import Banner, { error, notify, success, warning } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function Banners () {
  return (
    <Panel>
      <PanelHeader title='Banners' />
      <PanelContent>
        <div className='my-2 flex flex-row items-center'>
          <Banner title='Title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Banner>
          <Button size='md' variant='outline' onClick={() => notify('Title', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}>Trigger</Button>
        </div>
        <div className='my-2 flex flex-row items-center'>
          <Banner title='Title' type='success'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Banner>
          <Button size='md' variant='outline' onClick={() => success('Title', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}>Trigger</Button>
        </div>
        <div className='my-2 flex flex-row items-center'>
          <Banner title='Title' type='warning'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Banner>
          <Button size='md' variant='outline' onClick={() => warning('Title', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}>Trigger</Button>
        </div>
        <div className='my-2 flex flex-row items-center'>
          <Banner title='Title' type='error'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Banner>
          <Button size='md' variant='outline' onClick={() => error('Title', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}>Trigger</Button>
        </div>
        <div className='my-2 flex flex-row items-center'>
          <Banner title='No Message' />
          <Button size='md' variant='outline' onClick={() => notify('No Message')}>Trigger</Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Banners
