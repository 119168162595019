import { useMemo } from 'react'

import FormControl from '../../../components/forms/FormControl'
import TextInputIcon from '../../../components/forms/TextInputIcon'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Birthday, Placeholder } from '../../../svg/icons'

const log = logger({ enabled: true, tags: ['Style Guide', 'TextInputs'] })

function TextInputs () {
  useLogger({ log, lifecycle: false, tags: [] })

  const controls = useMemo(() => ([
    {
      type: 'email',
      name: 'email1',
      placeholder: 'email'
    },
    {
      type: 'number',
      name: 'number1',
      placeholder: 'number'
    },
    {
      type: 'tel',
      name: 'tel1',
      placeholder: 'tel'
    },
    {
      type: 'text',
      name: 'text1',
      placeholder: 'text'
    },
    {
      type: 'url',
      name: 'url1',
      placeholder: 'url'
    },
    {
      type: 'text',
      name: 'text2',
      label: 'Input with Label'
    },
    {
      type: 'text',
      name: 'text3',
      label: 'Input with Label and Error',
      error: 'Please fix this.'
    },
    {
      type: 'text',
      name: 'text4',
      label: 'Input with Label and Hint',
      hint: 'Make sure the value is happy.'
    },
    {
      type: 'text',
      name: 'text5',
      label: 'Input with Label, Hint, and Error',
      hint: 'Perhaps a joke would be good here.',
      error: 'Please fix this.'
    },
    {
      type: 'text',
      name: 'text6',
      label: 'Start Icon',
      start: <TextInputIcon icon={<Placeholder />} title='Placeholder' onClick={() => log.info('start placeholder icon clicked')} />
    },
    {
      type: 'text',
      name: 'text7',
      label: 'End Icon',
      end: <TextInputIcon icon={<Placeholder />} title='Placeholder' onClick={() => log.info('end placeholder icon clicked')} />
    },
    {
      type: 'text',
      name: 'text8',
      label: 'Start and End Icons',
      start: <TextInputIcon icon='search' onClick={() => log.info('search icon clicked')} />,
      end: <TextInputIcon icon='close' onClick={() => log.info('close icon clicked')} />
    },
    {
      type: 'date',
      name: 'date1',
      label: 'Native Date'
    },
    {
      type: 'date',
      name: 'date2',
      label: 'Native Date with Start Icon',
      start: <TextInputIcon icon={<Birthday />} disabled />
    },
    {
      type: 'time',
      name: 'time1',
      label: 'Native Time'
    },
    {
      type: 'time',
      name: 'time2',
      label: 'Native Time with Start Icon',
      start: <TextInputIcon icon={<Placeholder />} disabled />
    }
  ]), [])

  const output = controls.map((options) => {
    return <FormControl key={options.name} {...options} />
  })
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Text Inputs' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default TextInputs
