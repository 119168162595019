import PropType from 'prop-types'
import { Link } from 'react-router-dom'

import { useApp } from '../../../contexts/AppContext'

const propTypes = {
  signUpUrl: PropType.string.isRequired
}
const defaultProps = {}

const SignUpLink = ({ signUpUrl }) => {
  const { isNative } = useApp()

  if (isNative) {
    return null
  }

  return (
    <p className='text-center label-md my-5'>
      Don't have an account?
      <br />
      <Link className='text-primary label-md-strong' to={signUpUrl}>
        Sign up today
      </Link>
    </p>
  )
}

SignUpLink.displayName = 'SignUpLink'
SignUpLink.propTypes = propTypes
SignUpLink.defaultProps = defaultProps

export default SignUpLink
