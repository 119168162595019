import * as React from "react";
import { memo } from "react";
const SvgError = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Error_svg__a)">
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M14.598 3.5c-1.155-2-4.041-2-5.196 0L1.608 17c-1.155 2 .288 4.5 2.598 4.5h15.588c2.31 0 3.753-2.5 2.598-4.5L14.598 3.5Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m13.082 12.942.536-4.882c.097-.858-.779-1.643-1.606-1.643-.827 0-1.727.785-1.63 1.643l.535 4.882c.074.613.487 1.104 1.095 1.104.584 0 .997-.49 1.07-1.104Zm-1.07 2.723c-.9 0-1.63.736-1.63 1.644 0 .907.73 1.643 1.63 1.643.876 0 1.606-.736 1.606-1.643 0-.908-.73-1.644-1.606-1.644Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m23.258 16.167-7.505-13c-1.668-2.89-5.838-2.89-7.506 0l-7.505 13c-1.668 2.889.417 6.5 3.752 6.5h15.012c3.335 0 5.42-3.611 3.752-6.5ZM10.124 4.25c.834-1.444 2.918-1.444 3.752 0l7.506 13c.834 1.444-.209 3.25-1.876 3.25H4.494c-1.667 0-2.71-1.806-1.876-3.25l7.506-13Zm3.494 3.81-.536 4.882c-.073.613-.486 1.104-1.07 1.104-.608 0-1.021-.49-1.095-1.104l-.535-4.882c-.097-.858.803-1.643 1.63-1.643.827 0 1.703.785 1.606 1.643Zm-3.236 9.249c0-.908.73-1.644 1.63-1.644.876 0 1.606.736 1.606 1.644 0 .907-.73 1.643-1.606 1.643-.9 0-1.63-.736-1.63-1.643Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Error_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgError);
export default Memo;
