import { get as getTemplateService } from '../services/template'

import useCurrentInstance from './useCurrentInstance'

const useCurrentEmail = () => useCurrentInstance({
  resourceName: 'email',
  getService: getTemplateService,
  replyAttrPath: 'json',
  notFoundRedirect: '/emails'
})

export default useCurrentEmail
