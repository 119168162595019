import PropType from 'prop-types'

import paging from '../prop-types/shapes/paging'

export const addAllPropTypes = {
  contactId: PropType.string.isRequired,
  term: PropType.string
}

export const addPropTypes = {
  contactId: PropType.string.isRequired,
  tags: PropType.arrayOf(PropType.string).isRequired // tag names
}

export const removePropTypes = {
  contactId: PropType.string.isRequired,
  tags: PropType.arrayOf(PropType.string).isRequired // tag names
}

export const removeAllPropTypes = {
  contactId: PropType.string.isRequired,
  term: PropType.string
}

export const searchPropTypes = {
  contactId: PropType.string.isRequired,
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}
