import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import CloneAction from '../../../components/landing-pages/actions/CloneAction'
import DeleteAction from '../../../components/landing-pages/actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import landingPageShape from '../../../prop-types/shapes/landingPage'

const propTypes = {
  landingPage: PropType.shape(landingPageShape)
}

const defaultProps = {
  landingPage: null
}

const log = logger({ enabled: false, tags: ['LandingPageDetailHeaderMoreMenu'] })

const DetailHeaderMoreMenu = ({ landingPage }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const cloneActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/landing-pages', { replace: true }), [navigateInStack])

  const onCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])

  return !!landingPage && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Clone Landing Page' onClick={onCloneSelected} />
        <ActionMenuItem label='Delete Landing Page' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} landingPage={landingPage} onSuccess={onDeleteSuccess} />
      <CloneAction ref={cloneActionRef} landingPage={landingPage} />
    </>
  )
}

DetailHeaderMoreMenu.displayName = 'DetailHeaderMoreMenu'
DetailHeaderMoreMenu.propTypes = propTypes
DetailHeaderMoreMenu.defaultProps = defaultProps

export default DetailHeaderMoreMenu
