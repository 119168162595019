import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const ChangeAssistantPassword = () => {
  const handleReplyOkay = useCallback(() => {
    notify('Assistant Password updated successfully.')
  }, [])
  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='assistantPasswordForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Change Assistant Password'
      />
      <PanelContent className='p-5'>
        <div className='pb-4'>This feature allows you to give your email login and this assistant password to another person. When logged in, they have full access to your Project Broadcast account. If you need to you can change this password at any time.</div>
        <Form
          defaultValues={{}}
          formControls={[
            {
              type: 'password',
              name: 'assistantPassword',
              label: 'Assistant Password'
            }
          ]}
          id='assistantPasswordForm'
          onSubmit={updateProfile}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='assistantPasswordForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ChangeAssistantPassword.displayName = 'ChangeAssistantPassword'

export default ChangeAssistantPassword
