import PropType from 'prop-types'

import Avatar from '../../../components/avatar/Avatar'
import FormattedDate from '../../../components/FormattedDate'
import attachmentDownload from '../../../prop-types/shapes/attachmentDownload'
import { MarkColor } from '../../../svg/branding'
import { Clock } from '../../../svg/icons'

const propTypes = {
  item: PropType.shape(attachmentDownload).isRequired
}

const defaultProps = {}

const DownloadListItemContent = ({ item: download }) => {
  return (
    <>
      <Avatar contactOrUser={download.contact} size='sm' />
      <div className='flex flex-col ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {download.contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {download.contact.formattedName}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{download.contact.formattedPhoneNumber}</div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-black shrink-0 grow-0 h-3 w-3' />
          <div className='line-clamp-1'>
            <FormattedDate date={download.createdAt} format='extendedDateAndTime' />
          </div>
        </div>
      </div>
    </>
  )
}

DownloadListItemContent.displayName = 'DownloadListItemContent'
DownloadListItemContent.propTypes = propTypes
DownloadListItemContent.defaultProps = defaultProps

export default DownloadListItemContent
