import PropType from 'prop-types'

export default {
  _id: PropType.string.isRequired,
  value: PropType.string.isRequired,
  code: PropType.string, // {{custom1}}
  description: PropType.string,
  name: PropType.string, // user's alias/label for the field
  order: PropType.number,
  path: PropType.string // 'custom1'
}
