import { omit } from 'lodash'

import { firstMediaUrl } from '../helpers/messageMedia'

export default function messageFieldsNormalizer (data) {
  if (data.message) {
    const { text, type, runAt, upload, call, template } = data.message
    const newData = { ...omit(data, 'message'), text, type, runAt }
    if (template?._id) {
      if (template.template) { // this might be a templateSnapshot when editing a message
        newData.templateId = template.template?._id || template.template
      } else {
        newData.templateId = template._id
      }
    }
    // NOTE: we're assuming the type is already set for any given media/upload
    if (upload) {
      newData.upload = upload.uploadData
    } else if (call) {
      newData.call = true
    } else {
      const newMediaUrl = firstMediaUrl(data.message)
      if (newMediaUrl) {
        newData.mediaUrl = newMediaUrl
      } else {
        newData.removeMedia = true
      }
    }
    return newData
  }
  return data
}
