import FormControl from '../../../components/forms/FormControl'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'

const controls = [
  {
    type: 'password',
    name: 'password1',
    label: 'Default password input',
    placeholder: 'Password'
  },
  {
    type: 'password',
    name: 'password5',
    label: 'Default password input, hint, and error',
    hint: 'Password should be relatively strong.',
    error: 'Please do better',
    placeholder: 'Password'
  },
  {
    type: 'password',
    name: 'password6',
    label: 'Default password input and error',
    error: 'Please do better',
    placeholder: 'Password'
  },
  {
    type: 'password',
    name: 'password2',
    autoComplete: 'new-password',
    showPassword: true,
    label: 'With show password initially set',
    placeholder: 'Password'
  },
  {
    type: 'password',
    name: 'password3',
    showPasswordToggle: false,
    label: 'Without show password toggle',
    placeholder: 'Password'
  },
  {
    type: 'password',
    name: 'password4',
    label: 'Default password input with hint',
    hint: 'Password should be relatively strong.',
    placeholder: 'Password'
  }
]

function Passwords () {
  const output = controls.map((options) => {
    return <FormControl key={options.name} {...options} />
  })
  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Passwords' />
      <PanelContent>
        <div className='p-4'>
          {output}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Passwords
