import { Contacts } from '@capacitor-community/contacts'
import escapeStringRegexp from 'escape-string-regexp'
import { find, forEach, some } from 'lodash'
import * as Papa from 'papaparse'

import logger from '../lib/logger'

const log = logger({ enabled: true, tags: ['contactCsv'] })

const BOM = /^(\u00ef\u00bb\u00bf|\ufeff|\ufffe)/ // For UTF-8, UTF-16BE, & UTF-16LE encodings

export async function getPreImportInfo (file, customFields) {
  // eslint-disable-next-line no-unused-vars
  const [_ignored, name, type, base64] = file.uploadData.split(';')
  return new Promise((resolve, reject) => {
    let fileContents
    try {
      fileContents = window.atob(base64 || undefined)

      if (BOM.test(fileContents)) {
        // Papa Parse doesn't use BOM to infer encoding or byte-order when present. Instead,
        //  it becomes a part of the first header or column value so it's up to us to remove.
        // More Info:
        //  * https://github.com/mholt/PapaParse/issues/372
        //  * https://learn.microsoft.com/en-us/globalization/encoding/byte-order-mark
        fileContents = fileContents.replace(BOM, '')
      }

      if (!fileContents) { throw new Error('File Contents Empty') }
    } catch (ex) {
      return reject(new Error('Empty or invalid file.'))
    }

    Papa.parse(fileContents, {
      header: true,
      transformHeader: (val) => val.trim().replace(/\s+/g, ' '), // clean outer & inner whitespace
      error: () => reject(new Error('Unable to parse your import file.')),
      complete: function (results) {
        const csvFieldNames = results.meta.fields
        if (csvFieldNames && csvFieldNames.length) {
          resolve({
            fieldMapGuesses: guessFieldMapping(csvFieldNames, customFields),
            fieldMapOptions: [{ label: '', value: '' }].concat(csvFieldNames.map((fieldName) => ({
              label: fieldName,
              value: fieldName
            })))
          })
        } else {
          reject(new Error('Your file seems to be empty.'))
        }
      }
    })
  })
}

function guessFieldMapping (csvFieldNames, customFields) {
  const mappingRegExTests = {
    phoneNumber: [/cell/i, /mobile/i, /phone/i],
    email: [/e-?mail/i],
    firstName: [/first[\s_-]*name/i, /member[\s_-]*name/i, /full[\s_-]*name/i],
    lastName: [/last[\s_-]*name/i, /member[\s_-]*name/i, /full[\s_-]*name/i],
    tags: [/tags/i],
    company: [/company/i],
    birthday: [/birthday/i],
    birthdayMonth: [/birth[\s_-]+mo/i],
    birthdayDay: [/birth[\s_-]+da/i],
    anniversary: [/anniversary/i],
    notes: [/notes?/i]
  }

  const mapping = {}
  forEach(mappingRegExTests, (regExTests, mappingField) => {
    const fieldNameGuess = find(csvFieldNames, (csvFieldName) => {
      const f = csvFieldName.toLowerCase().trim()
      return some(regExTests, (regExTest) => regExTest.test(f))
    })
    mapping[mappingField] = fieldNameGuess || ''
  })

  customFields.forEach((customField) => {
    const normalizedCustomFieldName = escapeStringRegexp(customField.name.toLowerCase().trim().replace(' ', ''))
    const regExTest = new RegExp(`^${normalizedCustomFieldName}$`)
    const fieldNameGuess = find(csvFieldNames, (csvFieldName) => {
      const normalizedCsvFieldName = csvFieldName.toLowerCase().trim().replace(' ', '')
      return regExTest.test(normalizedCsvFieldName)
    })

    mapping[customField.path] = fieldNameGuess
  })

  return mapping
}

const getBestEmailFromDeviceEmails = (emails) => {
  if (!emails?.length) { return null }

  // email types: https://capacitor-community.github.io/contacts/#/api?id=emailtype
  // emails is an array of { type, address }
  const priorityLabelList = [
    'mobile',
    'home'
  ]

  for (let i = 0; i < priorityLabelList.length; i++) {
    const label = priorityLabelList[i]
    const email = emails.find(({ type }) => type === label)
    if (email) { return email.address }
  }

  return emails[0].address
}

const getBestPhoneFromDevicePhones = (phones) => {
  // phone types: https://capacitor-community.github.io/contacts/#/api?id=phonetype
  // phones is an array of { type, number }
  const priorityLabelList = [
    'mobile',
    'mms',
    'main'
  ]

  for (let i = 0; i < priorityLabelList.length; i++) {
    const label = priorityLabelList[i]
    const phone = phones.find(({ type }) => type === label)
    if (phone) { return phone.number }
  }

  return phones[0].number
}

function ensureByteFormattedString (str) {
  // 1 - Encode the input string as a UTF-8 byte sequence
  const utf8Bytes = new TextEncoder().encode(str)
  // 2 -  Convert each byte back into a byte-formatted string
  return String.fromCharCode(...utf8Bytes)
}

export async function getContactsAsCSVFromDevice () {
  const projection = {
    name: true, // .given (firstName) and .family (lastName)
    organization: true, // .company
    phones: true, // array ... .type and .number
    birthday: true, // .month and .day (format as .month/.day)
    emails: true // array ... .address and .type (do we want to focus on a particular type if multiple?)
  }

  const deviceContacts = await Contacts.getContacts({
    projection
  })

  if (deviceContacts?.contacts?.length === 0) { return null }

  // Build csv rows
  const headerRow = ['first', 'last', 'company', 'phone', 'email', 'birthday']
  const rows = []
  deviceContacts.contacts.forEach(({ name, organization, phones, birthday, emails }) => {
    if (!phones?.length) { return }

    const phoneNumber = getBestPhoneFromDevicePhones(phones)
    const email = getBestEmailFromDeviceEmails(emails)
    const formattedBirthday = birthday ? `${birthday.month}/${birthday.day}` : null
    const companyName = organization?.company
    const firstName = name?.given
    const lastName = name?.family
    rows.push([firstName, lastName, companyName, phoneNumber, email, formattedBirthday])
  })

  if (rows.length === 0) { return null }

  rows.unshift(headerRow)

  // Convert rows to csv string
  const csvString = Papa.unparse(rows)

  // Base64 encode the csvString for upload (btoa requires a byte-formatted string)
  const csvByteFormattedString = ensureByteFormattedString(csvString)
  const fileDataBase64 = window.btoa(csvByteFormattedString)

  const fileName = 'DeviceContactsImport.csv'
  const mimeType = 'text/csv'
  const csvFileLikeObj = {
    uploadData: `PB-CAP-FILE;${encodeURIComponent(fileName)};${encodeURIComponent(mimeType)};${fileDataBase64}`,
    webPath: `data:${mimeType};base64,${fileDataBase64}`
  }

  return csvFileLikeObj
}
