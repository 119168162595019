import { Keyboard as KeyboardPlugin } from '@capacitor/keyboard'
import { useCallback, useEffect, useRef } from 'react'

import { useApp } from '../../contexts/AppContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { setKeyboardOnScreen } from '../../store/actions/keyboardOnScreen'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'Keyboard'] })

const Keyboard = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { isNative } = useApp()

  const keyboardWillShowListenerRef = useRef()
  const keyboardDidShowListenerRef = useRef()
  const keyboardDidHideListenerRef = useRef()

  const handleKeyboardWillShow = useCallback(() => {
    setKeyboardOnScreen(true)
  }, [])
  const handleKeyboardDidShow = useCallback(() => {
    if (document.activeElement) {
      // Ensure focused element is in view after keyboard is shown
      document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])
  const handleKeyboardDidHide = useCallback(() => {
    setKeyboardOnScreen(false)
  }, [])

  useEffect(() => {
    async function removeAndAdd () {
      await keyboardWillShowListenerRef.current?.remove()
      keyboardWillShowListenerRef.current = await KeyboardPlugin.addListener('keyboardWillShow', handleKeyboardWillShow)

      await keyboardDidShowListenerRef.current?.remove()
      keyboardDidShowListenerRef.current = await KeyboardPlugin.addListener('keyboardDidShow', handleKeyboardDidShow)

      await keyboardDidHideListenerRef.current?.remove()
      keyboardDidHideListenerRef.current = await KeyboardPlugin.addListener('keyboardDidHide', handleKeyboardDidHide)
    }

    // Keyboard plugin only supported on native
    if (isNative) {
      removeAndAdd()
    }

    return () => {
      keyboardWillShowListenerRef.current?.remove()
      keyboardDidShowListenerRef.current?.remove()
      keyboardDidHideListenerRef.current?.remove()
    }
  }, [handleKeyboardDidShow, handleKeyboardWillShow, handleKeyboardDidHide, isNative])

  return null
}

Keyboard.displayName = 'Keyboard'
Keyboard.propTypes = propTypes
Keyboard.defaultProps = defaultProps

export default Keyboard
