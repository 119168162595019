import { useEffect, useRef } from 'react'

import logger from '../lib/logger'

const log = logger({ enabled: true, tags: ['useScript'] })

const scriptCache = {
  // src: 'loading', 'loaded', 'errored'
}

const useScript = ({ src, onLoad, onError }) => {
  const onLoadRef = useRef(onLoad)
  const onErrorRef = useRef(onError)

  useEffect(() => {
    const cachedState = scriptCache[src]
    if (cachedState) { log.debug('Already added this script:', cachedState) }
    if (cachedState === 'loading') { return }
    if (cachedState === 'loaded') {
      onLoadRef.current?.()
      return
    }
    if (cachedState === 'errored') {
      onErrorRef.current?.()
      return
    }

    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)

    const handleScriptEvent = (event) => {
      const readyOrError = event.type === 'load' ? 'loaded' : 'errored'
      scriptCache[src] = readyOrError
      log.debug('Script loaded or errored:', readyOrError)
      if (readyOrError === 'loaded') { onLoadRef.current?.() }
      if (readyOrError === 'errored') { onErrorRef.current?.() }
    }

    script.addEventListener('load', handleScriptEvent)
    script.addEventListener('error', handleScriptEvent)
    return () => {
      script.removeEventListener('load', handleScriptEvent)
      script.removeEventListener('error', handleScriptEvent)
    }
  }, [src])

  return null
}

export default useScript
