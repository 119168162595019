import * as React from "react";
import { memo } from "react";
const SvgCircleCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Circle_Check_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm2 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-13.328 4.169a.817.817 0 0 0 .033-.035.874.874 0 0 0 .035-.032l7.683-7.684a.83.83 0 0 0-1.174-1.175l-7.164 7.164-2.667-2.667a.83.83 0 0 0-1.175 1.174l3.255 3.255a.83.83 0 0 0 1.174 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Circle_Check_svg__a">
        <rect width={24} height={24} fill="#fff" rx={12} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgCircleCheck);
export default Memo;
