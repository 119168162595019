import PropType from 'prop-types'

import userFromJSON from '../models/user'

import pb from './pb'
import {
  signUpPreFlightPropTypes,
  signUpPropTypes
} from './subscription.prop-types'

export function signUpPreFlight ({
  affiliate,
  email,
  eventId,
  eventSourceUrl,
  firstName,
  lastName,
  partnerId,
  partnerTerms,
  password,
  phoneNumber,
  planId,
  referralCode,
  stateProvince,
  timezone
}) {
  const body = {
    affiliate,
    cellPhoneNumber: phoneNumber,
    email,
    eventId,
    eventSourceUrl,
    firstName,
    lastName,
    partnerId,
    partnerTermsCheckbox: partnerTerms,
    password,
    plan: planId,
    refId: referralCode,
    stateProvince,
    timezone
  }
  PropType.checkPropTypes(signUpPreFlightPropTypes, body, 'prop', 'subscription#preCheck')
  return pb.setupPost('subscriptions/preCheck', { body })
}

export function signUp ({
  affiliate,
  captcha,
  email,
  eventId,
  eventSourceUrl,
  firstName,
  lastName,
  number,
  partnerId,
  partnerTerms,
  password,
  phoneNumber,
  planId,
  referralCode,
  stateProvince,
  timezone,
  stripeResult,
  signupReferral,
  verificationCode
}) {
  const body = {
    affiliate,
    captcha,
    cellPhoneNumber: phoneNumber,
    email,
    eventId,
    eventSourceUrl,
    firstName,
    lastName,
    partnerId,
    partnerTermsCheckbox: partnerTerms,
    password,
    phone: number,
    plan: planId,
    refId: referralCode,
    signupReferral,
    stateProvince,
    timezone,
    stripeResult,
    verificationCode
  }

  PropType.checkPropTypes(signUpPropTypes, body, 'prop', 'subscription#signUp')
  const options = {
    appSessionCapture: true,
    body
  }
  return pb.setupPost('subscriptions', options)
}

export function plansForNewSubscriber (body) {
  return pb.setupPost('subscriptions/plansForNewSubscriber', { body })
}

export function reactivatePreFlight ({
  // General
  affiliate,
  planId,
  partnerId,
  // Applicable for managed-voip
  stateProvince,
  // Applicable for verified-cell
  cellPhoneNumber
}) {
  const body = {
    affiliate,
    plan: planId,
    partnerId,
    cellPhoneNumber,
    stateProvince
  }
  // TODO: check prop types
  return pb.setupPost('subscriptions/preCheckReactivation', { body })
}

export function reactivate ({
  // General
  affiliate,
  planId,
  partnerId,
  // Applicable for managed-voip
  managedPhoneNumber,
  stripeResult,
  // Applicable for verified-cell
  cellPhoneNumber,
  verificationCode
}) {
  const body = {
    affiliate,
    plan: planId,
    partnerId,
    phone: managedPhoneNumber,
    stripeResult,
    cellPhoneNumber,
    verificationCode
  }
  // TODO: check prop types
  return pb.setupPost('subscriptions/reactivate', { body })
}

export function changePlanPreFlight ({ planId, reanchorImmediately, cellPhoneNumber, stateProvince }) {
  const body = {
    plan: planId,
    reanchorImmediately,
    cellPhoneNumber,
    stateProvince
  }

  return pb.setupPost('subscriptions/preCheckChangeSubscription', { body })
}

export function changePlan ({ planId, reanchorImmediately, cellPhoneNumber, phone, verificationCode, stripeResult }) {
  const body = {
    plan: planId,
    reanchorImmediately,
    cellPhoneNumber,
    phone,
    stripeResult,
    verificationCode
  }
  const options = {
    body,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('subscriptions', options)
}

export function renewImmediately () {
  return pb.setupPost('subscriptions/renewImmediately')
}

export function listAvailableNumbers ({ stateProvince }) {
  const body = {
    stateProvince
  }
  return pb.setupPost('subscriptions/listAvailableNumbers', { body })
}

export function updateAddOns (addOns) {
  const body = { addOns }
  const options = {
    body,
    model: { conversion: userFromJSON }
  }
  return pb.setupPut('subscriptions', options)
}

export function unsubscribe ({ reasonCode, reason }) {
  const body = {
    reasonCode,
    reason
  }

  return pb.setupPost('subscriptions/unsubscribe', { body })
}

export function cancelScheduledChanges () {
  return pb.setupPost('subscriptions/cancelScheduledChanges')
}

export function provisionNewNumber ({ number, referral }) {
  const body = {
    number,
    referral
  }
  return pb.setupPut('subscriptions/provisionNewNumber', { body })
}
