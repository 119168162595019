import PropType from 'prop-types'

import { searchPropTypes } from './keyword.prop-types'
import messageFieldsNormalizer from './messageFieldsNormalizer'
import pb from './pb'

export function allWordsWithPublicLinkForUser (background = false) {
  const options = { background }
  return pb.setupGet('keywords/allWordsWithPublicLinkForUser', options)
}

export function get (keywordId, background = false) {
  const options = { background }
  return pb.setupGet(`keywords/${keywordId}`, options)
}

export function search (body, background = false) {
  const options = { body, background }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'keyword#search')
  return pb.setupPost('keywords/search', options)
}

export function create (body, background = false) {
  body = messageFieldsNormalizer(body)
  const options = { body, background }
  return pb.setupPost('keywords', options)
}

export function update (keywordId, body, background = false) {
  body = messageFieldsNormalizer(body)
  const options = { body, background }
  return pb.setupPut(`keywords/${keywordId}`, options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('keywords/bulk/delete', { body, background })
}

export function del (keywordId) {
  return pb.setupDelete(`keywords/${keywordId}`)
}

export function clone (keywordId, background = false) {
  const options = { background }
  return pb.setupPost(`keywords/${keywordId}/clone`, options)
}
