import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wizard as ReactWizard } from 'react-use-wizard'

import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import useAffiliate from '../../../hooks/useAffiliate'
import useLogger from '../../../hooks/useLogger'
import usePartnerRequest from '../../../hooks/usePartnerRequest'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { loadForReactivation as loadUserService } from '../../../services/user'
import NumberUnavailableForm from '../signup/NumberUnavailableForm'
import Welcome from '../signup/Welcome'
import WizardWrapper from '../signup/WizardWrapper'

import ManagedNumberForm from './ManagedNumberForm'
import MobileVerificationForm from './MobileVerificationForm'
import SubscriptionForm from './SubscriptionForm'
// import Welcome from './Welcome'

const propTypes = {}
const defaultProps = {}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
const cssSrc = process.env.REACT_APP_CSS_FONT_URL
const elementsOptions = { fonts: [{ cssSrc }] }

const log = logger({ enabled: false, tags: ['ReactivateWizard'] })

const Wizard = () => {
  useLogger({ log, lifecycle: true, tags: [] })

  const [data, setData] = useState({})
  const [existingPhoneNumber, setExistingPhoneNumber] = useState(null)
  const navigate = useNavigate()
  const {
    connectUrl,
    integration,
    partnerId,
    verificationComplete,
    verified
  } = usePartnerRequest()
  const {
    affiliate,
    affiliateLookupComplete
  } = useAffiliate()

  const handleDataReply = useCallback((reply) => {
    if (reply?.ok) {
      const { isActive, phoneNumberPendingCancellation: previousPhoneNumber } = reply.json

      if (isActive) {
        navigate('/auth', { replace: true })
      } else {
        setExistingPhoneNumber(previousPhoneNumber)
      }
    } else {
      navigate('/auth', { replace: true })
    }
  }, [navigate])
  const { call: userData } = useService(loadUserService, { onReply: handleDataReply })

  useEffect(() => {
    log.debug('integration', integration)
    setData((prev) => ({ ...prev, integration }))
  }, [integration])

  useEffect(() => {
    log.debug('affiliate', affiliate)
    setData((prev) => ({ ...prev, affiliate }))
  }, [affiliate])

  useEffect(() => { userData() }, [userData])

  useEffect(() => {
    setData((prev) => ({ ...prev, existingPhoneNumber }))
  }, [existingPhoneNumber])

  const handleSubscriptionFormSuccess = useCallback((formData) => {
    setData({ ...data, ...formData })
  }, [data])

  const handleManagedNumberSelected = useCallback((formData) => {
    setData({ ...data, ...formData })
  }, [data])

  // form defaultValues are cached after first render,
  // so block Form rendering until we have the data we need.
  if (partnerId && !verified) {
    if (verificationComplete) {
      return <PanelNotFound />
    } else {
      return <PanelLoading noHeader />
    }
  }

  if (!affiliateLookupComplete) {
    return null
  }

  return (
    <Elements options={elementsOptions} stripe={stripePromise}>
      <ReactWizard wrapper={<WizardWrapper />}>
        <SubscriptionForm
          {...data}
          existingPhoneNumber={existingPhoneNumber}
          signInUrl={connectUrl}
          onSuccess={handleSubscriptionFormSuccess}
        />
        <MobileVerificationForm {...data} />
        <ManagedNumberForm {...data} existingPhoneNumber={existingPhoneNumber} onSuccess={handleManagedNumberSelected} />
        <NumberUnavailableForm {...data} isReactivation onSuccess={handleManagedNumberSelected} />
        <Welcome {...data} isReactivation />
      </ReactWizard>
    </Elements>
  )
}

Wizard.displayName = 'ReactivateWizard'
Wizard.propTypes = propTypes
Wizard.defaultProps = defaultProps

export default Wizard
