import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import CampaignList from '../../components/campaigns/CampaignList'
import ContactCampaignList from '../../components/contacts/ContactCampaignList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentContact from '../../hooks/useCurrentContact'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addOne as addCampaignService, removeOne as removeCampaignService } from '../../services/contact-campaign'
import EventDateDialog from '../campaigns/detail/EventDateDialog'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContactsCampaigns'] })

const ContactsCampaigns = () => {
  const { contactId, loading, contact, setContact } = useCurrentContact()
  const [selectedCampaign, setSelectedCampaign] = useState()

  useLogger({ log, lifecycle: false, tags: [contactId] })

  const contactCampaignListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()
  const eventDateDialogRef = useRef()

  const handleReplyOk = useCallback((currentReply) => {
    success('Campaigns updated')
    contactCampaignListRef.current.reload()
    setContact(currentReply.model)
    bus.emit('contactUpdated', currentReply.model)
  }, [setContact])
  const { call: addCampaignCall } = useService(addCampaignService, { onReplyOk: handleReplyOk })
  const { call: removeCampaignCall } = useService(removeCampaignService, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAdd = useCallback((selected) => {
    if (selected.type === 'event') {
      setSelectedCampaign(selected)
      eventDateDialogRef.current.open()
    } else {
      const campaignId = selected._id
      addCampaignCall({ contactId, campaignId })
    }
  }, [addCampaignCall, contactId, setSelectedCampaign])

  const handleRemove = useCallback((selected) => {
    const campaignId = selected._id
    removeCampaignCall({ contactId, campaignId })
  }, [contactId, removeCampaignCall])

  const handleContinueAdd = useCallback((data) => {
    const campaignId = selectedCampaign._id
    const eventDate = data.eventDate
    eventDateDialogRef.current.close()
    addCampaignCall({ contactId, campaignId, eventDate })
  }, [contactId, addCampaignCall, selectedCampaign])

  const end = (
    <>
      {contact?.campaigns?.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  if (loading || !contact) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={contact?.formattedName}
        title={`Campaigns (${contact?.campaigns?.length})`}
      />
      <PanelContent>
        <ContactCampaignList
          ref={contactCampaignListRef}
          contactId={contact?._id}
          type='list'
          search
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        contactId={contact?._id}
        list={ContactCampaignList}
        multiple={false}
        subtitle={contact?.formattedName}
        title='Remove Campaign'
        type='remove'
        search
        onSubmitSelected={handleRemove}
      />
      <ListDialog
        ref={addListDialogRef}
        contactId={contact?._id}
        list={CampaignList}
        multiple={false}
        subtitle={contact?.formattedName}
        title='Add Campaign'
        type='add'
        search
        onSubmitSelected={handleAdd}
      />
      <EventDateDialog ref={eventDateDialogRef} onSubmit={handleContinueAdd} />
    </Panel>
  )
}

ContactsCampaigns.displayName = 'ContactsCampaigns'
ContactsCampaigns.propTypes = propTypes
ContactsCampaigns.defaultProps = defaultProps

export default ContactsCampaigns
