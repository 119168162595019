import PropType from 'prop-types'

const contestContact = {
  _id: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string,
  phoneNumber: PropType.string
}

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  contacts: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contestContact))
  }),
  winners: PropType.shape({
    length: PropType.number,
    subset: PropType.arrayOf(PropType.shape(contestContact))
  })
}
