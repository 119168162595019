import PropType from 'prop-types'

import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  allowArchived: PropType.bool,
  term: PropType.string
}

export const updatePropTypes = {
  name: PropType.string
}

export const applyTagToContactsPropTypes = PropType.oneOfType([
  PropType.shape({
    campaignId: PropType.string.isRequired,
    tagName: PropType.string.isRequired
  }),
  PropType.shape({
    contestId: PropType.string.isRequired,
    tagName: PropType.string.isRequired
  })
]).isRequired
