import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import { generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/scheduledBroadcastListSearchBar'
import useLogger from '../../hooks/useLogger'
import useTimeZone from '../../hooks/useTimeZone'
import logger from '../../lib/logger'
import SearchBar from '../search-bar/SearchBar'
import SearchBarButton from '../search-bar/SearchBarButton'

import ScheduledBroadcastListAdvancedSearchDialog from './ScheduledBroadcastListAdvancedSearchDialog'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  // NOTE: search only works for data that does not get transformed
  search: PropType.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  placeholder: undefined,
  search: null
}

const log = logger({ enabled: true, tags: ['ScheduledBroadcastListSearchBar'] })

const ScheduledBroadcastListSearchBar = ({
  search: initialSearch,
  placeholder,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()

  const { userTimeZone } = useTimeZone()

  const [advancedSearchData, setAdvancedSearchData] = useState(initialSearch || {})

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])
  const handleSearchBarReset = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData, userTimeZone)
  }, [advancedSearchData, userTimeZone])

  const handleFilterClick = useCallback((scheduledAtType) => {
    const newAdvancedSearchData = { ...advancedSearchData, scheduledAtType }
    setAdvancedSearchData(newAdvancedSearchData)
    const search = generateSearchFromAdvancedSearchFormData(newAdvancedSearchData)
    searchBarRef.current.update(search)
  }, [advancedSearchData])

  const handleCurrentFilterClick = useCallback(() => handleFilterClick(null), [handleFilterClick])
  const handlePastFilterClick = useCallback(() => handleFilterClick('past'), [handleFilterClick])

  const end = useMemo(() => {
    if (advancedSearchData?.scheduledAtType === 'past') {
      return <SearchBarButton icon='current' onClick={handleCurrentFilterClick} />
    } else {
      return <SearchBarButton icon='past' onClick={handlePastFilterClick} />
    }
  }, [advancedSearchData?.scheduledAtType, handleCurrentFilterClick, handlePastFilterClick])

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        end={end}
        generateSummary={generateSummary}
        placeholder={placeholder}
        search={initialSearch}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onReset={handleSearchBarReset}
        onTermChange={handleSearchBarTermChange}
      />
      <ScheduledBroadcastListAdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>
  )
}

ScheduledBroadcastListSearchBar.displayName = 'ScheduledBroadcastListSearchBar'
ScheduledBroadcastListSearchBar.propTypes = propTypes
ScheduledBroadcastListSearchBar.defaultProps = defaultProps

export default ScheduledBroadcastListSearchBar
