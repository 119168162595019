import { arrow, autoUpdate, flip, FloatingArrow, FloatingPortal, offset as offsetMiddleware, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStyles } from '@floating-ui/react'
import PropType from 'prop-types'
import { cloneElement, forwardRef, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  content: PropType.node.isRequired,
  trigger: PropType.node.isRequired,
  delay: PropType.number, // milliseconds
  // https://floating-ui.com/docs/offset
  offset: PropType.oneOfType([
    PropType.number,
    PropType.func,
    PropType.shape({
      alignmentAxis: PropType.number,
      crossAxis: PropType.number,
      mainAxis: PropType.number
    })
  ]),
  // https://floating-ui.com/docs/computePosition#placement
  placement: PropType.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ])
}

const defaultProps = {
  delay: 275,
  offset: 10,
  placement: 'top'
}

const log = logger({ enabled: true, tags: ['Tooltip'] })

const Tooltip = forwardRef(({
  content,
  delay,
  placement,
  offset,
  trigger
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const arrowRef = useRef()

  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    close () { setOpen(false) },
    open () { setTimeout(() => setOpen(true), 1) }
  }), [])

  const { reference, floating, context, strategy, x, y } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offsetMiddleware(offset), flip(), shift(), arrow({ element: arrowRef })]
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { move: false, delay }),
    useFocus(context),
    useDismiss(context),
    useRole(content, { role: 'tooltip' })
  ])

  const floatingClassName = 'label-xs-strong bg-white text-black p-2 drop-shadow'

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: 175,
    initial: {
      opacity: 0
    }
  })

  const style = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    visibility: x == null ? 'hidden' : 'visible',
    ...styles
  }

  const floatingProps = getFloatingProps({
    ref: floating,
    className: floatingClassName,
    style
  })

  const triggerClone = cloneElement(trigger, getReferenceProps({ ref: reference, ...trigger.props }))

  return (
    <>
      {triggerClone}
      <FloatingPortal id='tooltip-portal'>
        {open && isMounted
          ? (
            <div {...floatingProps}>
              {content}
              <FloatingArrow ref={arrowRef} context={context} fill='white' />
            </div>
            )
          : null}
      </FloatingPortal>
    </>
  )
})

Tooltip.displayName = 'Tooltip'
Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
