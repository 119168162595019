import classNames from 'classnames'
import { omit, pick } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useApp } from '../../../contexts/AppContext'
import { hasHardcodedDynamicLinks, messageTags } from '../../../helpers/message'
import { firstImageUrl, firstMediaUrl, firstNonS3ImageUrl, firstVcardUrl, firstVoiceUrl } from '../../../helpers/messageMedia'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useImagePicker from '../../../hooks/useImagePicker'
import useLogger from '../../../hooks/useLogger'
import useMessageDraft from '../../../hooks/useMessageDraft'
import useSegmentedMessage from '../../../hooks/useSegmentedMessage'
import useService from '../../../hooks/useService'
import useTimeZone from '../../../hooks/useTimeZone'
import addToTextArea from '../../../lib/addToTextArea'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'
import { baseMessageFields } from '../../../prop-types/shapes/message'
import { importMedia as importMediaService } from '../../../services/message'
import { error as notifyError, warning as notifyWarning } from '../../banners/Banner'
import EmojiPicker from '../../emoji-picker/EmojiPicker'
import MessageQualityAction from '../../message/actions/MessageQuality'
import ImageDropZoneIndicator from '../ImageDropZoneIndicator'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

import CharacterCounter from './message/CharacterCounter'
import MessageBar from './message/MessageBar'
import MessageEmailAttachment from './message/MessageEmailAttachment'
import MessageImageAttachment from './message/MessageImageAttachment'
import MessageTextInput from './message/MessageTextInput'
import MessageVcardAttachment from './message/MessageVcardAttachment'
import MessageVoiceAttachment from './message/MessageVoiceAttachment'
import ScheduleMessageDialog from './message/ScheduleMessageDialog'

/*
  NOTE: This takes a default value as a "message doc" and outputs a "message doc"
        but the server does not want a message doc. This requires the use of
        the `messageFieldsNormalizer` prior to submitting to the server.

        Except the message doc may contain an `upload` prop which has additional
        information like base64 `uploadData`

        message doc input: { media: [], text: '', type: 'sms' }
        message doc output: { media: [], text: '', type: 'mms', upload: { uploadData } }
*/

const propTypes = {
  name: PropType.string.isRequired,
  className: PropType.string,
  contentOptions: PropType.shape({
    emails: PropType.bool,
    messageTemplates: PropType.bool,
    voiceDrops: PropType.bool
  }),
  draftKey: PropType.string,
  draftMessageOverride: PropType.shape(baseMessageFields),
  error: PropType.string, // only outputs when theme === 'input
  hint: PropType.string, // only outputs when theme === 'input'
  label: PropType.string, // only outputs when theme === 'input'
  maxCharacters: PropType.number, // grapheme clusters
  placeholder: PropType.string,
  required: PropType.bool, // for standlone usage (like chat threads)
  theme: PropType.oneOf(['chat', 'input', 'space', 'aiva']),
  onChange: PropType.func,
  onSchedule: PropType.func, // for standalone usage (like chat threads)
  onSend: PropType.func // for standalone usage (like chat threads)
}

const defaultProps = {
  className: null,
  contentOptions: {
    emails: true,
    messageTemplates: true,
    voiceDrops: true
  },
  draftKey: null,
  draftMessageOverride: null,
  error: null,
  hint: null,
  label: null,
  maxCharacters: 1024,
  onChange: undefined,
  onSchedule: undefined,
  onSend: undefined,
  placeholder: null,
  required: true,
  theme: 'input'
}

const classNamesByTheme = {
  input: 'border border-neutral-300',
  chat: '',
  space: ''
}

const log = logger({ enabled: true, tags: ['Message'] })

const Message = forwardRef(({
  draftKey,
  name,
  className,
  draftMessageOverride,
  error,
  hint,
  contentOptions,
  label,
  maxCharacters,
  placeholder,
  required,
  theme,
  onChange,
  onSchedule,
  onSend
}, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()
  const { fromFile: imageFromFile } = useImagePicker()
  const { userTimeZone } = useTimeZone()
  const textareaRef = useRef()
  const scheduleMessageDialogRef = useRef()
  const messageInsightsRef = useRef()
  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  const initialMessageValue = pick(useFormMethods?.getValues(name), 'text', 'type', 'media', 'template')
  const [message, setMessage] = useState({ text: '', type: 'sms', media: [], upload: null, ...initialMessageValue })
  const { characterCount, segmentsCount, updateSegmentedMessage } = useSegmentedMessage(message)
  const { saveDraft } = useMessageDraft({ draftKey, draftMessageOverride, onRestoreDraft: setMessage })
  const [isCurrentDropTarget, setIsCurrentDropTarget] = useState(false)
  const { isWeb } = useApp()

  error = error || useFormMethods?.formState?.errors?.[name]?.message

  useFormMethods?.register(name)

  const setMessageAndSavePolitely = useCallback((message) => {
    setMessage(message)
    saveDraft(message)
  }, [saveDraft])

  const validateMessage = useCallback(async ({ characterCount, message }) => {
    let errorMessage = null
    if (required) {
      const text = (message.text || '').trim()
      const { upload, call } = message
      const mediaUrl = firstMediaUrl(message)
      if (!message.template && !text && !upload && !call && !mediaUrl) {
        errorMessage = 'Message is required.'
      }
    }
    if (maxCharacters && characterCount > maxCharacters) {
      errorMessage = `Message text cannot exceed ${maxCharacters} characters.`
    }
    const hardcodedLinksResponse = await hasHardcodedDynamicLinks(message)
    if (hardcodedLinksResponse) {
      errorMessage = hardcodedLinksResponse
    }

    useFormMethods?.setCustomError(name, { message: errorMessage })

    return errorMessage
  }, [maxCharacters, name, required, useFormMethods])

  useImperativeHandle(ref, () => ({
    get message () { return message },

    async hasError () {
      return await validateMessage({ characterCount, message })
    },

    reset (messageOverrideValue = null) {
      const resetMessage = {
        text: '',
        type: 'sms',
        media: [],
        upload: null,
        call: null,
        ...initialMessageValue,
        ...messageOverrideValue
      }
      updateSegmentedMessage(resetMessage)
      setMessageAndSavePolitely(resetMessage)
    }
  }), [characterCount, initialMessageValue, message, setMessageAndSavePolitely, updateSegmentedMessage, validateMessage])

  useEffect(() => {
    useFormMethods?.setValue(name, message)
    onChange?.(message)
    validateMessage({ characterCount, message })
  }, [characterCount, message, name, onChange, useFormMethods, validateMessage])

  const handleTextareaChange = useCallback(() => {
    const updatedMessage = { ...message, text: textareaRef.current.value }
    updateSegmentedMessage(updatedMessage)
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely, updateSegmentedMessage])

  const handleEmojiClick = useCallback((emojiData) => {
    addToTextArea(emojiData.emoji, textareaRef.current, false)
    handleTextareaChange()
  }, [handleTextareaChange])

  const handleTagInjection = useCallback((type, slug) => {
    const updatedMessage = { ...omit(message, 'call', 'template') }
    if (updatedMessage.type !== 'sms' || updatedMessage.type !== 'mms') {
      updatedMessage.type = 'sms'
    }
    const tag = `${messageTags[type].start}${slug}${messageTags[type].end}`
    addToTextArea(tag, textareaRef.current)
    updatedMessage.text = textareaRef.current.value
    updateSegmentedMessage(updatedMessage)
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely, updateSegmentedMessage])

  const handleAttachmentSelect = useCallback((selected) => { handleTagInjection('attachment', selected.slug) }, [handleTagInjection])
  const handleConfigurableFormSelect = useCallback((selected) => { handleTagInjection('configurableForm', selected.slug) }, [handleTagInjection])
  const handleDynamicFieldSelect = useCallback((selected) => { handleTagInjection('dynamicField', selected.path) }, [handleTagInjection])
  const handleLandingPageFieldSelect = useCallback((selected) => { handleTagInjection('landingPage', selected.slug) }, [handleTagInjection])
  const handleTrackableLinkSelect = useCallback((selected) => { handleTagInjection('trackableLink', selected.slug) }, [handleTagInjection])

  const handleAivaRevision = useCallback((suggestion) => {
    const updatedMessage = { ...message, text: suggestion }
    updateSegmentedMessage(updatedMessage)
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely, updateSegmentedMessage])

  const handleTemplateSelect = useCallback((selected) => {
    const templateMessage = { text: '', type: 'sms', media: [], upload: null, ...pick(selected, 'text', 'type', 'media'), template: selected }
    setMessageAndSavePolitely(templateMessage)
    textareaRef.current.value = templateMessage.text || ''
    updateSegmentedMessage(templateMessage)
  }, [setMessageAndSavePolitely, updateSegmentedMessage])

  const handleScheduleClick = useCallback(async () => {
    const errors = await ref.current.hasError()
    if (errors) { return notifyError(errors) }
    scheduleMessageDialogRef.current.open()
  }, [ref])

  const handleScheduleSubmit = useCallback(({ date, time }) => {
    scheduleMessageDialogRef.current.close()
    const runAt = dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    const data = { message: { ...message, runAt } }
    onSchedule?.(data)
  }, [message, onSchedule, userTimeZone])

  const handleSendClick = useCallback(async () => {
    if (!onSend) { return }
    const errors = await ref.current.hasError()
    if (errors) { return notifyError(errors) }
    const data = { message: { ...message } }
    onSend?.(data)
  }, [message, onSend, ref])

  const handleMessageInsightsClick = useCallback(async () => {
    const errors = await ref.current.hasError()
    if (errors) { return notifyError(errors) }
    messageInsightsRef.current.activate()
  }, [ref])

  const handleImageSelect = useCallback((upload) => {
    log.debug('Image selected', upload)
    const updatedMessage = { ...omit(message, 'call', 'upload') }
    updatedMessage.media = []
    updatedMessage.upload = upload
    updatedMessage.type = 'mms'
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely])

  const handleVcardSelect = useCallback(() => {
    const updatedMessage = { ...omit(message, 'call', 'upload', 'template') }
    updatedMessage.media = [currentUser.publicVcardMediaUrl]
    updatedMessage.type = 'mms'
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely, currentUser])

  const clearMedia = useCallback(() => {
    const updatedMessage = { ...omit(message, 'call', 'upload', 'template') }
    updatedMessage.media = []
    updatedMessage.type = 'sms'
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely])

  const uploadWebPathOrImageUrl = useMemo(() => { return message.upload?.webPath || firstImageUrl(message) }, [message])
  const uploadWebPathOrVoiceUrl = useMemo(() => { return message.upload?.webPath || firstVoiceUrl(message) }, [message])
  const vcardUrl = useMemo(() => firstVcardUrl(message), [message])

  const computedClassName = classNames(
    'relative w-full rounded-md dropzone',
    classNamesByTheme[theme],
    className
  )

  const characterCounterClassName = classNames(
    'absolute',
    {
      'bottom-5 right-12': theme === 'input',
      'bottom-[4rem] right-4': theme !== 'input'
    }
  )

  const imageDragDropHandlers = useMemo(() => {
    return isWeb
      ? {
          onDragEnter (event) {
            event.preventDefault()
            if (!isCurrentDropTarget) {
              log.info('++DragEnter++')
              setIsCurrentDropTarget(true)
            }
          },
          onDragLeave (event) {
            event.preventDefault()
            if (event.target.classList.contains('dropzone')) {
              log.info('--DragLeave--')
              setIsCurrentDropTarget(false)
            }
          },
          onDragOver (event) {
            event.preventDefault()
          },
          async onDrop (event) {
            event.preventDefault()
            log.info('**DragDrop**', event)
            setIsCurrentDropTarget(false)
            const image = await imageFromFile(event?.dataTransfer?.files?.[0])
            log.debug('dropped imageFromFile', image)
            if (image) { handleImageSelect(image) }
          }
        }
      : {}
  }, [imageFromFile, handleImageSelect, isWeb, isCurrentDropTarget])

  const handleImagePaste = useCallback(async (event) => {
    if (event?.clipboardData.files?.[0]) { event.preventDefault() }
    const image = await imageFromFile(event?.clipboardData.files?.[0])
    log.debug('pasted imageFromFile', image)
    if (image) { handleImageSelect(image) }
  }, [imageFromFile, handleImageSelect])

  const { call: importMedia } = useService(importMediaService, {
    onReplyOk: (reply) => {
      const updatedMessage = { ...omit(message, 'call', 'upload') }
      updatedMessage.media = [reply.json.s3ImageUrl]
      updatedMessage.type = 'mms'
      setMessageAndSavePolitely(updatedMessage)
    },
    onReplyNotOk: () => notifyWarning('Failed to Import Image')
  })

  useEffect(() => {
    const nonS3ImageUrl = firstNonS3ImageUrl({ type: message.type, media: message.media })
    if (nonS3ImageUrl) {
      importMedia({ imageUrl: nonS3ImageUrl })
    }
  }, [message.media, message.type, importMedia])

  return (
    <>
      {theme === 'input' ? <InputLabel text={label} /> : null}
      <div className={computedClassName} {...imageDragDropHandlers}>
        <ImageDropZoneIndicator className={isCurrentDropTarget ? 'visible' : 'invisible'} />
        <div className={isCurrentDropTarget ? 'invisible pointer-events-none' : 'visible'}>
          <MessageVoiceAttachment
            mediaUrl={uploadWebPathOrVoiceUrl}
            messageType={message.type}
            template={message.template}
            onRemove={clearMedia}
          />
          <MessageImageAttachment mediaUrl={uploadWebPathOrImageUrl} messageType={message.type} onRemove={clearMedia} />
          <MessageVcardAttachment mediaUrl={vcardUrl} messageType={message.type} onRemove={clearMedia} />
          <MessageEmailAttachment messageType={message.type} template={message.template} onRemove={clearMedia} />
          <div className='relative'>
            {message.type !== 'rvm' && message.type !== 'email' && <EmojiPicker className='absolute top-0 right-0' onEmojiClick={handleEmojiClick} />}
            <MessageTextInput
              ref={textareaRef}
              className='border border-none w-full h-full bg-white pr-6 h-36 min-w-72 resize-none'
              maxRows={8}
              messageType={message.type}
              minRows={3}
              placeholder={placeholder}
              value={message.text}
              onChange={handleTextareaChange}
              onPaste={handleImagePaste}
            />
          </div>
          <CharacterCounter
            className={characterCounterClassName}
            count={characterCount}
            max={maxCharacters}
            messageType={message.type}
            segmentsCount={segmentsCount}
            showSegmentsCount={!!currentUser?.smsOnly}
          />
          <MessageBar
            contentOptions={contentOptions}
            message={message}
            theme={theme}
            onAivaRevision={handleAivaRevision}
            onAttachmentSelect={handleAttachmentSelect}
            onConfigurableFormSelect={handleConfigurableFormSelect}
            onDynamicFieldSelect={handleDynamicFieldSelect}
            onEmojiClick={handleEmojiClick}
            onImageSelect={handleImageSelect}
            onLandingPageSelect={handleLandingPageFieldSelect}
            onMessageInsightsClick={handleMessageInsightsClick}
            onScheduleClick={handleScheduleClick}
            onSendClick={handleSendClick}
            onTemplateSelect={handleTemplateSelect}
            onTrackableLinkSelect={handleTrackableLinkSelect}
            onVcardSelect={handleVcardSelect}
          />
        </div>
      </div>
      {theme === 'input' ? <InputError message={error} /> : null}
      {theme === 'input' ? <InputHint message={hint} /> : null}
      <ScheduleMessageDialog ref={scheduleMessageDialogRef} onSubmit={handleScheduleSubmit} />
      <MessageQualityAction ref={messageInsightsRef} message={message} variant='insights' />
    </>
  )
})

Message.displayName = 'Message'
Message.propTypes = propTypes
Message.defaultProps = defaultProps

export default Message
