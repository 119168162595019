import { map } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import CampaignList from '../../components/campaigns/CampaignList'
import ListDialog from '../../components/dialog/ListDialog'
import KeywordLeaveCampaignList from '../../components/keywords/KeywordLeaveCampaignList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentKeyword from '../../hooks/useCurrentKeyword'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/keyword-leave-campaign'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['KeywordLeaveCampaigns'] })

const KeywordLeaveCampaigns = () => {
  const { keywordId, loading, keyword, setKeyword } = useCurrentKeyword()

  useLogger({ log, lifecycle: false, tags: [keywordId] })

  const keywordLeaveCampaignListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Campaigns updated')
    keywordLeaveCampaignListRef.current.reload()
    const updatedKeyword = reply.json
    setKeyword(updatedKeyword)
    bus.emit('keywordUpdated', updatedKeyword)
  }, [setKeyword])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ keywordId, ...query })
  }, [addAllInQueryCall, keywordId])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ keywordId, campaigns: map(selected, '_id') })
  }, [addSelectedCall, keywordId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ keywordId, ...query })
  }, [keywordId, removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ keywordId, campaigns: map(selected, '_id') })
  }, [keywordId, removeSelectedCall])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !keyword) { return <PanelLoading /> }

  const end = (
    <>
      {keyword.antiCampaigns.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={keyword.word}
        title='Leave Campaigns'
      />
      <PanelContent>
        <KeywordLeaveCampaignList
          ref={keywordLeaveCampaignListRef}
          keywordId={keyword._id}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        keywordId={keyword._id}
        list={KeywordLeaveCampaignList}
        subtitle={keyword.word}
        title='Remove Campaigns to Leave'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        keywordId={keyword._id}
        list={CampaignList}
        subtitle={keyword.word}
        title='Add Campaigns to Leave'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

KeywordLeaveCampaigns.displayName = 'KeywordLeaveCampaigns'
KeywordLeaveCampaigns.propTypes = propTypes
KeywordLeaveCampaigns.defaultProps = defaultProps

export default KeywordLeaveCampaigns
