import PropType from 'prop-types'

import messageFromJSON from '../models/message'
import spaceFromJSON from '../models/space'

import pb from './pb'
import { createPropTypes, updateNotificationSettingsPropTypes, updatePropTypes } from './space.prop-types'

export function after (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { spaceId, messageId } = body
  // TODO: prop type check body
  return pb.setupGet(`spaces/${spaceId}/messages/after/${messageId}`, options)
}

export function create (body, background = false) {
  const options = {
    background,
    model: {
      conversion: spaceFromJSON,
      key: 'space'
    },
    body
  }
  PropType.checkPropTypes(createPropTypes, body, 'prop', 'space#create')
  return pb.setupPost('spaces', options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('spaces/bulk/delete', { body, background })
}

export function del (spaceId, background = false) {
  return pb.setupDelete(`spaces/${spaceId}`, { background })
}

export function get (spaceId, background = false) {
  const options = {
    background,
    model: {
      conversion: spaceFromJSON,
      key: 'space'
    }
  }
  return pb.setupGet(`spaces/${spaceId}`, options)
}

export function leave (spaceId, background = false) {
  return pb.setupPut(`spaces/${spaceId}/leave`, { background })
}

export function search (body, background = false) {
  const options = {
    background,
    body,
    model: {
      conversion: spaceFromJSON,
      key: 'spaces',
      multiple: true
    }
  }
  return pb.setupPost('spaces/search', options)
}

export function messages (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { spaceId, paging: { page, pageSize } } = body
  // TODO: prop type check body
  return pb.setupGet(`spaces/${spaceId}/messages?page=${page}&pageSize=${pageSize}`, options)
}

export function markAsRead (body, background = false) {
  const options = { background }
  const { spaceId, messageId } = body
  // TODO: prop type check body
  return pb.setupPut(`spaces/${spaceId}/messages/${messageId}/read`, options)
}

export function notificationSettings (spaceId, background = false) {
  return pb.setupGet(`spaces/${spaceId}/notificationSettings`, { background })
}

export function scheduled (body, background = false) {
  const options = {
    background,
    model: {
      conversion: messageFromJSON,
      key: 'messages',
      multiple: true
    }
  }
  const { spaceId } = body
  // TODO: prop type check body
  return pb.setupGet(`spaces/${spaceId}/messages/scheduled`, options)
}

export function updateNotificationSettings (spaceId, body, background = false) {
  const options = { background, body }
  PropType.checkPropTypes(updateNotificationSettingsPropTypes, body, 'prop', 'space#updateNotificationSettings')
  return pb.setupPut(`spaces/${spaceId}/notificationSettings`, options)
}

export function update (spaceId, body, background = false) {
  const options = {
    background,
    model: {
      conversion: spaceFromJSON,
      key: 'space'
    },
    body
  }
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'space#update')
  return pb.setupPut(`spaces/${spaceId}`, options)
}
