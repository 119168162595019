import { first } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { messageDirections } from '../../../helpers/message'
import { getMediaInfo } from '../../../helpers/messageMedia'
import messageTypes from '../../../helpers/messageTypes'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  direction: PropType.oneOf(messageDirections), // check message model direction getter for how space messages directions are handled
  fromFormattedName: PropType.string, // required for inbound space message
  mediaUrl: PropType.string,
  space: PropType.bool,
  text: PropType.string,
  type: PropType.oneOf(messageTypes)
}

const defaultProps = {
  direction: null,
  fromFormattedName: null,
  mediaUrl: null,
  space: false,
  text: null,
  type: null
}

const log = logger({ enabled: true, tags: ['MessagePreview'] })

const MessagePreview = ({
  direction,
  fromFormattedName,
  mediaUrl,
  space,
  text,
  type
}) => {
  useLogger({ log, lifecycle: false, tags: [space ? 'Spaces' : 'Chats'] })

  const output = useMemo(() => {
    let result = direction === 'out' ? 'You: ' : (space && fromFormattedName ? `${fromFormattedName}: ` : '')
    const firstMediaInfo = mediaUrl ? first(getMediaInfo({ type, media: [mediaUrl] })) : null

    if (type === 'email') {
      result += 'Sent Email'
    } else if (text) {
      result += text
    } else if (firstMediaInfo?.isImage) {
      result += 'Sent Image'
    } else if (firstMediaInfo?.isVcard) {
      result += 'Sent Contact Card'
    } else if (firstMediaInfo?.isVoice) {
      result += 'Sent Voice Drop'
    }

    return result
  }, [direction, fromFormattedName, mediaUrl, space, text, type])

  if (!type) { return '' }

  return (
    <div className='line-clamp-1 break-words label-sm text-neutral-700'>
      {output}
    </div>
  )
}

MessagePreview.displayName = 'MessagePreview'
MessagePreview.propTypes = propTypes
MessagePreview.defaultProps = defaultProps

export default MessagePreview
