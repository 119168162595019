import { Link } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ChevronRight, Gift } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ReferralProgramBanner'] })

const ReferralProgramBanner = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Link className='flex items-center p-3 bg-indigo-100 rounded mt-1 mb-3' to='/account/referral-program'>
      <div className='rounded-full bg-indigo-50 text-indigo-600 p-2'>
        <Gift height='20' width='20' />
      </div>
      <div className='mx-2'>
        <p className='label-xs-strong text-black'>Share Project Broadcast</p>
        <p className='label-2xs text-neutral-600'>Earn account credit by sharing the tool you love!</p>
      </div>
      <ChevronRight height='20' width='20' />
    </Link>
  )
}

ReferralProgramBanner.displayName = 'ReferralProgramBanner'
ReferralProgramBanner.propTypes = propTypes
ReferralProgramBanner.defaultProps = defaultProps

export default ReferralProgramBanner
