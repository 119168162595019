import { App } from '@capacitor/app'
import { useCallback, useEffect, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { pauseAppActiveState, resumeAppActiveState } from '../../store/actions/appActiveState'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'AppStateChange'] })

const AppStateChange = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const appStateChangeListenerRef = useRef()

  const handleAppStateChange = useCallback((event) => {
    log.info('ON:appStateChange', event.isActive ? 'Resumed' : 'Paused')
    event.isActive ? resumeAppActiveState() : pauseAppActiveState()
  }, [])

  useEffect(() => {
    async function removeAndAdd () {
      if (appStateChangeListenerRef.current?.remove) {
        await appStateChangeListenerRef.current.remove()
        log.debug('removed previous "appStateChange" listener')
      }
      appStateChangeListenerRef.current = await App.addListener('appStateChange', handleAppStateChange)
      log.debug('added new "appStateChange" listener')
    }

    removeAndAdd()

    return () => { appStateChangeListenerRef.current?.remove() }
  }, [handleAppStateChange])

  return null
}

AppStateChange.displayName = 'AppStateChange'
AppStateChange.propTypes = propTypes
AppStateChange.defaultProps = defaultProps

export default AppStateChange
