import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as managedAccountSearch } from '../../services/user-managedAccount'
import List from '../list/List'

import ManagedAccountListItemContent from './ManagedAccountListItemContent'

const propTypes = {
  type: PropType.oneOf(['list', 'select']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ManagedAccountList'] })

const ManagedAccountList = forwardRef(({ type, ...rest }, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [] })

  const handleManagedAccountRemoved = useCallback((removedManagedAccount) => {
    ref.current.removeItem(removedManagedAccount._id)
    ref.current.indicateUpdatesDetected()
  }, [ref])

  useBus('managedAccountRemoved', handleManagedAccountRemoved)

  return (
    <List
      ref={ref}
      itemSharedContext={{ hideActions: type !== 'list' }}
      ListItemContent={ManagedAccountListItemContent}
      multiple={false}
      placeholder='Search Managed Accounts'
      resultsKey='users'
      searchService={managedAccountSearch}
      type={type}
      search
      {...rest}
    />
  )
})

ManagedAccountList.displayName = 'ManagedAccountList'
ManagedAccountList.propTypes = propTypes
ManagedAccountList.defaultProps = defaultProps

export default ManagedAccountList
