import * as React from "react";
import { memo } from "react";
const SvgAivaHello = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 126 95"
    {...props}
  >
    <rect width={94} height={95} x={0.5} fill="#fff" rx={47} />
    <ellipse cx={46.5} cy={70.5} fill="#E6E7EB" rx={19.5} ry={3} />
    <g clipPath="url(#AivaHello_svg__a)">
      <path
        fill="#6366F1"
        fillRule="evenodd"
        d="M69.08 45.68a2.652 2.652 0 0 1 1.165 1.826c.337 2.35.343 4.75-.002 7.202a2.626 2.626 0 0 1-2.443 2.26c-.245.014-.49.018-.734.012a48.548 48.548 0 0 1-.237 1.75c-.39 2.537-2.364 4.56-4.924 5.042-10.316 1.942-20.69 1.856-31.118-.044-2.558-.466-4.537-2.475-4.95-5.004a49.21 49.21 0 0 1-.251-1.742 9.036 9.036 0 0 1-.442-.014 2.6 2.6 0 0 1-2.427-2.265 28.732 28.732 0 0 1 .014-7.162c.17-1.356 1.085-1.78 2.18-2.251 1.022-.439 1.187-1.715 1.33-2.81l.037-.283c.302-2.203.777-4.364 1.252-6.529.193-.876.386-1.753.567-2.633A1.296 1.296 0 0 1 29.367 32h2.788c.579 0 1.088.385 1.246.942l1.433 5.044c7.527-1.166 15.195-1.173 23.012.034l1.443-5.078A1.295 1.295 0 0 1 60.535 32h2.789c.615 0 1.145.432 1.27 1.035.203.995.416 1.987.63 2.978.568 2.644 1.135 5.283 1.528 7.969.044.307.092.625.262.885.251.385.689.456 1.136.528.328.053.66.106.93.284Zm-8.982 15.922a5.222 5.222 0 0 0 4.224-4.317h.001a39.148 39.148 0 0 0-.004-12.373 5.24 5.24 0 0 0-4.152-4.298 74.019 74.019 0 0 0-5.11-.841l-2.517 3.741a2.057 2.057 0 0 1-1.71.888h-8.988c-.7 0-1.352-.343-1.721-.906l-2.466-3.756c-1.75.222-3.49.512-5.224.87a5.23 5.23 0 0 0-4.095 4.224c-.707 4.074-.708 8.224.002 12.45a5.23 5.23 0 0 0 4.25 4.282c9.219 1.622 18.39 1.695 27.51.036ZM35.904 46.25a7.06 7.06 0 0 0-1.96.002 1.677 1.677 0 0 0-1.426 1.427 23.99 23.99 0 0 0-.01 6.811 1.673 1.673 0 0 0 1.437 1.422 7.53 7.53 0 0 0 1.938 0 1.676 1.676 0 0 0 1.433-1.4c.377-2.347.37-4.637.002-6.873a1.675 1.675 0 0 0-1.415-1.388Zm20.792.002a7.06 7.06 0 0 1 1.959-.002l-.001.001a1.675 1.675 0 0 1 1.415 1.388 21.247 21.247 0 0 1-.002 6.873 1.676 1.676 0 0 1-1.433 1.4 7.53 7.53 0 0 1-1.938 0 1.673 1.673 0 0 1-1.437-1.422 23.99 23.99 0 0 1 .01-6.81 1.677 1.677 0 0 1 1.427-1.428ZM43.38 55.299h6.436a.88.88 0 0 1 .824 1.188 4.31 4.31 0 0 1-8.084 0 .88.88 0 0 1 .824-1.188Z"
        clipRule="evenodd"
      />
    </g>
    <path fill="#6366F1" d="M64 4h62v24H64z" />
    <path fill="#6366F1" d="M72 17h18L72 35V17Z" />
    <path
      fill="#fff"
      d="M72.885 21V10.818h2.153v4.201h4.37v-4.2h2.147V21h-2.147v-4.206h-4.37V21h-2.153Zm10.445 0V10.818h6.861v1.775h-4.708v2.426h4.355v1.775h-4.355v2.431h4.728V21h-6.88Zm8.573 0V10.818h2.152v8.407h4.365V21h-6.517Zm7.943 0V10.818h2.153v8.407h4.365V21h-6.518Zm16.903-5.09c0 1.11-.21 2.054-.631 2.833-.418.779-.988 1.374-1.71 1.785-.72.407-1.528.611-2.426.611-.905 0-1.717-.205-2.437-.616-.719-.411-1.287-1.006-1.705-1.785-.417-.779-.626-1.722-.626-2.829 0-1.11.209-2.055.626-2.834.418-.779.986-1.372 1.705-1.78.72-.41 1.532-.616 2.437-.616.898 0 1.706.205 2.426.617.722.407 1.292 1 1.71 1.78.421.778.631 1.723.631 2.833Zm-2.182 0c0-.72-.108-1.327-.323-1.82-.213-.494-.512-.869-.9-1.124-.388-.255-.842-.383-1.362-.383-.521 0-.975.128-1.363.383-.387.255-.689.63-.905 1.123-.212.494-.318 1.1-.318 1.82s.106 1.326.318 1.82c.216.494.518.868.905 1.123.388.256.842.383 1.363.383.52 0 .974-.127 1.362-.383.388-.255.687-.63.9-1.123.215-.494.323-1.1.323-1.82Z"
    />
    <defs>
      <clipPath id="AivaHello_svg__a">
        <path fill="#fff" d="M22.5 24h48v48h-48z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAivaHello);
export default Memo;
