import PropType from 'prop-types'
import { useCallback } from 'react'

import CheckboxDetailSection from '../../../components/detail-sections/CheckboxDetailSection'
import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import bundleShape from '../../../prop-types/shapes/bundle'

const propTypes = {
  bundle: PropType.shape(bundleShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['ShareOptionsDetail'] })

const ShareOptionsDetail = ({ bundle, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [bundle._id] })

  const transformData = useCallback(({ overwrite }) => ({
    shareOptions: {
      overwriteKeywords: overwrite,
      overwriteTemplates: overwrite
    }
  }), [])

  return (
    <>
      <DetailSection
        className='mb-0'
        description='By default, all bundled items will be added upon import (though duplicate keywords cannot be added if they
                     already exist). However, import behavior for certain items can be modified as desired below:'
        title='Share Options'
        hideContent
      />
      <CheckboxDetailSection
        checked={bundle.shareOptions.overwriteKeywords || bundle.shareOptions.overwriteTemplates}
        description="When enabled, the user's existing matching keywords and templates (if any) will be overridden by bundle import."
        name='overwrite'
        objectId={bundle._id}
        service={service}
        title='Override Keywords & Templates'
        transformData={transformData}
        onChange={onChange}
      />
    </>
  )
}

ShareOptionsDetail.displayName = 'ShareOptionsDetail'
ShareOptionsDetail.propTypes = propTypes
ShareOptionsDetail.defaultProps = defaultProps

export default ShareOptionsDetail
