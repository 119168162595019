import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/contact-tag'
import TagList from '../tags/TagList'

const propTypes = {
  contactId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContactTagList'] })

const ContactTagList = forwardRef(({ contactId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contactId] })

  return (
    <TagList
      ref={ref}
      queryOverrides={{ contactId }}
      searchService={searchService}
      {...rest}
    />
  )
})

ContactTagList.displayName = 'ContactTagList'
ContactTagList.propTypes = propTypes
ContactTagList.defaultProps = defaultProps

export default ContactTagList
