import { pluralize } from '../lib/formatters'

import proxy from './proxy'

export default function goalMilestoneFromJSON (json) {
  if (!json) { return null }

  const overrides = {

    get introDelayDescription () {
      const { delayHours } = json.intro
      if (delayHours === 0) {
        return 'immediately'
      }

      return pluralize({
        count: delayHours,
        singular: 'hour',
        includeCount: true
      })
    },

    get nudgeDelayDescription () {
      const { delayDays, maxRepeat, repeatBackOff } = json.nudge

      const pluralizedDayCount = pluralize({
        count: delayDays,
        singular: 'day',
        includeCount: true
      })

      if (maxRepeat === 0) {
        return `after ${pluralizedDayCount} elapse`
      } else {
        if (repeatBackOff === 'exponential') {
          return `repeats after ${pluralizedDayCount} w/ exponential backoff`
        } else {
          return `repeats every ${pluralizedDayCount}`
        }
      }
    },

    get nudgeBehaviorDescription () {
      const { delayDays, maxRepeat } = json.nudge

      if (maxRepeat === -1) {
        return 'blocks until user completes '
      } else if (maxRepeat === 0) {
        const pluralizedDayCount = pluralize({
          count: delayDays,
          singular: 'day',
          includeCount: true
        })
        return `${pluralizedDayCount} elapse or user completes`
      } else {
        const pluralizedNudgeCount = pluralize({
          count: maxRepeat,
          singular: 'nudge',
          includeCount: true
        })
        return `after ${pluralizedNudgeCount} or user completes`
      }
    }
  }

  return proxy(json, overrides)
}
