import * as React from 'react'
import { memo } from 'react'
const SvgTimeLapse = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#D0FAE4" rx={4} />
        <path
            fill="#05976A"
            fillRule="evenodd"
            d="M6.545 12a5.455 5.455 0 1 1 10.91 0 5.455 5.455 0 0 1-10.91 0ZM12 5.455a6.545 6.545 0 1 0 0 13.09 6.545 6.545 0 0 0 0-13.09Zm.546 3.818a.545.545 0 0 0-1.091 0V12H9.818a.546.546 0 0 0 0 1.091H12a.546.546 0 0 0 .546-.545V9.273Z"
            clipRule="evenodd"
        />
    </svg>
)
const Memo = memo(SvgTimeLapse)
export default Memo
