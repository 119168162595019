import { pick } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import { masks } from '../../../components/forms/controls/TextInput'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

import validationSuite from './ProfileDetail.validations'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: false, tags: ['ProfileDetail'] })

const ProfileDetail = ({ contact, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const details = useMemo(() => {
    const parts = []
    if (contact.firstName && contact.lastName) {
      parts.push(contact.formattedName)
    } else {
      if (contact.firstName) { parts.push(contact.firstName) }
      if (contact.lastName) { parts.push(contact.lastName) }
    }
    if (contact.company) { parts.push(contact.company) }
    parts.push(contact.formattedPhoneNumber)
    if (contact.email) { parts.push(contact.email) }
    return parts.map((part) => <p key={part} className='select-text'>{part}</p>)
  }, [contact])

  const defaultValues = pick(contact, ['company', 'email', 'firstName', 'lastName'])
  defaultValues.phoneNumber = masks.tel(contact.phoneNumber)
  const formControls = [
    { label: 'First Name', name: 'firstName', type: 'text' },
    { label: 'Last Name', name: 'lastName', type: 'text' },
    { label: 'Company Name', name: 'company', type: 'text' },
    { label: 'Phone Number', name: 'phoneNumber', type: 'tel' },
    { label: 'Email', name: 'email', type: 'email' }
  ]

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Contacts are used in Project Broadcast as recipients to messages. First name, last name, company, and email can be used as dynamic fields in messages.'
      formControls={formControls}
      formDescription={`Updating profile for ${contact.formattedName}`}
      formTitle={contact.formattedName}
      objectId={contact._id}
      service={service}
      title='Profile'
      validationSuite={validationSuite}
      isPanelDialog
      showEditIcon
      onChange={onChange}
    >
      {details}
    </FormDetailSection>
  )
}

ProfileDetail.displayName = 'ProfileDetail'
ProfileDetail.propTypes = propTypes
ProfileDetail.defaultProps = defaultProps

export default ProfileDetail
