import { keyBy, map } from 'lodash'

const strengthOptions = [
  {
    label: 'Strong',
    value: 'strong',
    badgeColor: 'bg-green-500',
    textBgColor: 'bg-green-100',
    textFgColor: 'text-green-900'
  },
  {
    label: 'Good',
    value: 'good',
    badgeColor: 'bg-yellow-300',
    textBgColor: 'bg-yellow-100',
    textFgColor: 'text-yellow-900'
  },
  {
    label: 'Average',
    value: 'average',
    badgeColor: 'bg-orange-500',
    textBgColor: 'bg-orange-100',
    textFgColor: 'text-orange-900'
  },
  {
    label: 'Weak',
    value: 'weak',
    badgeColor: 'bg-red-500',
    textBgColor: 'bg-red-100',
    textFgColor: 'text-red-900'
  }
]

const strengthOptionsLUT = keyBy(strengthOptions, 'value')

export const messageStrengthValues = map(strengthOptions, ({ value }) => value)

export function messageStrengthBadgeColor (strengthValue) {
  return strengthOptionsLUT[strengthValue]?.badgeColor
}

export function messageStrengthTextBgColor (strengthValue) {
  return strengthOptionsLUT[strengthValue]?.textBgColor
}

export function messageStrengthTextFgColor (strengthValue) {
  return strengthOptionsLUT[strengthValue]?.textFgColor
}

export function messageStrengthLabel (strengthValue) {
  return strengthOptionsLUT[strengthValue]?.label
}

export function messageQualityLabel (isAivaVariant) {
  return isAivaVariant ? 'Message Strength' : 'Message Insights'
}
