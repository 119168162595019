import * as React from "react";
import { memo } from "react";
const SvgSpaces = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 180"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v180H0z" />
    <circle
      cx={187}
      cy={95}
      r={73.5}
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
    />
    <circle cx={92.5} cy={143.5} r={6.5} fill="#E1E7FF" />
    <circle cx={110.5} cy={41.5} r={4.5} fill="#E1E7FF" />
    <circle cx={267} cy={45} r={6} fill="#E1E7FF" />
    <circle cx={285} cy={15} r={4} fill="#E1E7FF" />
    <path
      fill="#FDDCAB"
      fillRule="evenodd"
      d="M187.688 164.605c7.51-3.606 12.712-11.45 12.712-20.543s-5.207-16.937-12.712-20.542c-7.509 3.605-12.711 11.449-12.711 20.542 0 9.093 5.206 16.94 12.711 20.543Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#Spaces_svg__a)"
      fillRule="evenodd"
      d="M164.839 68.94c0 .513-.004 1.026-.004 1.54 0 22.936 3.831 44.14 10.316 61.324a44.992 44.992 0 0 0 24.758.254c6.548-17.229 10.415-38.526 10.415-61.578 0-.514 0-1.027-.005-1.54 0-.304.005-.612.005-.916 0-20.712-8.849-39.227-22.747-51.517-13.898 12.285-22.747 30.805-22.747 51.517 0 .304 0 .612.005.915h.004Z"
      clipRule="evenodd"
    />
    <path
      fill="#818CF8"
      fillRule="evenodd"
      d="M224.806 122.162c0-11.727-5.433-22.101-13.764-28.429a.931.931 0 0 0-1.481.626 187.228 187.228 0 0 1-3.992 20.788.917.917 0 0 0 .393.995c7.649 5.096 12.653 13.536 12.653 23.079v.041c0 .969 1.28 1.317 1.744.468 2.828-5.157 4.451-11.164 4.451-17.568h-.004ZM154.451 139.73c.469.849 1.749.505 1.744-.468v-.041c0-9.543 5-17.983 12.654-23.079a.926.926 0 0 0 .392-.995 186.312 186.312 0 0 1-3.992-20.788.931.931 0 0 0-1.481-.626C155.432 100.061 150 110.435 150 122.162c0 6.404 1.623 12.406 4.451 17.568Z"
      clipRule="evenodd"
    />
    <path
      fill="#6366F1"
      fillRule="evenodd"
      d="M153.568 137.376c.312.662.643 1.313.992 1.951.469.848 1.749.504 1.744-.469v-.04c0-9.544 5-17.984 12.654-23.079a.928.928 0 0 0 .392-.996 179.587 179.587 0 0 1-.899-3.727c-8.751 5.931-14.539 15.503-14.883 26.36ZM221.561 137.376a36.416 36.416 0 0 1-.992 1.951c-.469.848-1.749.504-1.744-.469v-.04c0-9.544-5-17.984-12.654-23.079a.928.928 0 0 1-.392-.996c.311-1.232.611-2.474.899-3.727 8.751 5.931 14.539 15.503 14.883 26.36Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill="#fff"
      d="M187.581 84.163c6.774 0 12.266-5.498 12.266-12.28 0-6.783-5.492-12.281-12.266-12.281s-12.265 5.498-12.265 12.28c0 6.783 5.491 12.281 12.265 12.281Z"
    />
    <path
      fill="#818CF8"
      fillRule="evenodd"
      d="M187.581 56.102c-8.711 0-15.765 7.069-15.765 15.78 0 8.712 7.054 15.781 15.765 15.781s15.766-7.07 15.766-15.78c0-8.712-7.055-15.781-15.766-15.781Zm12.266 15.78c0 6.783-5.492 12.281-12.266 12.281s-12.265-5.498-12.265-12.28c0-6.783 5.491-12.281 12.265-12.281 6.774 0 12.266 5.498 12.266 12.28Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#Spaces_svg__b)"
      d="M212.112 68.024c0-16.617-5.562-31.872-14.826-43.773h-4.607c9.853 11.561 15.869 26.91 15.869 43.773 0 .3 0 .594-.004.893l1.793.022h-.014l-1.784-.022v.036c0 .509.005 1.018.005 1.527 0 22.48-3.72 43.229-9.986 60.082a42.944 42.944 0 0 1-22.043-.236c-6.212-16.809-9.897-37.468-9.897-59.846 0-.509 0-1.018.005-1.527v-.036l-1.784.022h-.014l1.793-.022c0-.295-.004-.594-.004-.893 0-16.863 6.017-32.212 15.869-43.773h-4.607c-9.268 11.9-14.826 27.156-14.826 43.773 0 .304 0 .612.005.915 0 .514-.005 1.028-.005 1.541 0 23.128 3.863 44.546 10.433 61.953l1.668-.629-1.668.629.312.831.847.25a46.754 46.754 0 0 0 25.74.268l.874-.241.321-.849-1.668-.634 1.668.634c6.632-17.456 10.531-38.972 10.531-62.212 0-.513 0-1.023-.005-1.532 0-.308.005-.616.005-.924h.004Z"
    />
    <path
      fill="url(#Spaces_svg__c)"
      fillRule="evenodd"
      d="M171.654 125.11c4.019 3.988 9.844 6.497 16.324 6.497 5.955 0 11.356-2.116 15.312-5.555a212.023 212.023 0 0 1-2.935 11.28 2.975 2.975 0 0 1-1.998 2.019 35.797 35.797 0 0 1-10.383 1.545c-3.889 0-7.681-.639-11.333-1.849a2.953 2.953 0 0 1-1.918-1.991 215.198 215.198 0 0 1-3.069-11.951v.005Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#Spaces_svg__d)"
      fillRule="evenodd"
      d="M207.928 43.735c-6.065 1.72-12.992 2.693-20.347 2.693s-14.281-.974-20.347-2.693c3.849-10.633 9.959-20.074 17.707-27.657a3.766 3.766 0 0 1 5.276 0c7.747 7.583 13.858 17.024 17.711 27.657Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="Spaces_svg__a"
        x1={168.733}
        x2={210.212}
        y1={80.425}
        y2={80.425}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5EBFF" />
        <stop offset={0.271} stopColor="#F5F7FF" />
        <stop offset={1} stopColor="#BFC7EA" />
      </linearGradient>
      <linearGradient
        id="Spaces_svg__b"
        x1={164.272}
        x2={214.003}
        y1={61.446}
        y2={60.11}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8588FF" />
        <stop offset={1} stopColor="#6366F1" />
      </linearGradient>
      <linearGradient
        id="Spaces_svg__c"
        x1={172.97}
        x2={203.076}
        y1={134.684}
        y2={132.902}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8588FF" />
        <stop offset={1} stopColor="#676AF3" />
      </linearGradient>
      <linearGradient
        id="Spaces_svg__d"
        x1={178.545}
        x2={206.437}
        y1={24.827}
        y2={40.204}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8587FF" />
        <stop offset={1} stopColor="#6366F1" />
      </linearGradient>
    </defs>
  </svg>
);
const Memo = memo(SvgSpaces);
export default Memo;
