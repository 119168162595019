const brokenMedia = 'https://projectbroadcast.s3.amazonaws.com/media/noimage.png'

export function firstMediaUrl (message) {
  const firstMediaInfo = getMediaInfo(message).find(({ isImage, isVcard, isVoice }) => {
    return isImage || isVcard || isVoice
  })
  if (firstMediaInfo) {
    const { url, isImage, isOnS3 } = firstMediaInfo
    if (isImage) {
      return isOnS3 ? url : brokenMedia
    } else {
      return url
    }
  }
}

export function firstLink (message) {
  const firstLinkInfo = getMediaInfo(message).find(({ isLink }) => isLink)
  if (firstLinkInfo) {
    const { url, isOnS3 } = firstLinkInfo
    return isOnS3 ? url : brokenMedia
  }
}

export function firstImageUrl (message) {
  const firstImageInfo = getMediaInfo(message).find(({ isImage }) => isImage)
  if (firstImageInfo) {
    const { url, isOnS3 } = firstImageInfo
    return isOnS3 ? url : brokenMedia
  }
}

export function firstNonS3ImageUrl (message) {
  return getMediaInfo(message).find(({ isImage, isOnS3 }) => isImage && !isOnS3)?.url
}

export function firstVcardUrl (message) {
  return getMediaInfo(message).find(({ isVcard }) => isVcard)?.url
}

export function firstVoiceUrl (message) {
  return getMediaInfo(message).find(({ isVoice }) => isVoice)?.url
}

export function getMediaInfo (message) {
  if (!message?.media || !message?.type) { return [] }

  const { type, media } = message
  return media.map((url) => {
    const resourceName = extractNameFromUrl(url)

    return {
      url,
      resourceName,
      isImage: type !== 'rvm' && hopefullyIsAnImage(resourceName),
      isLink: type !== 'rvm' && hopefullyIsALink(resourceName),
      isVcard: type !== 'rvm' && resourceName.endsWith('.vcf'),
      isVoice: type === 'rvm',
      isOnS3: isAmazonS3Url(url)
    }
  })
}

function extractNameFromUrl (url) {
  return url && url.substr(url.lastIndexOf('/') + 1)
}

const isAmazonS3Url = function (url) {
  return /.+s3\.amazonaws\.com.+/.test(url)
}

const hopefullyIsALink = function (name) {
  if (!/\./.test(name)) { // has no extension, is probably a link
    return true
  }
  return !/\.(smil|txt)$/i.test(name) // not a smil or txt file
}

const hopefullyIsAnImage = function (name) {
  if (!/\./.test(name)) { // has no extension, is probably an image
    return true
  }
  return /\.(jpeg|jpg|gif|png)$/i.test(name) // has an image extension
}
