import useStore from '..'

export function receiveSharedImage (imageData, mimeType, size) {
  useStore.setState((state) => {
    state.nativeSharedImage = {
      imageData,
      mimeType,
      size,
      sharedAt: new Date()
    }
  }, false, 'nativeSharedImage/receiveSharedImage')
}
