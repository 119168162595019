import * as React from "react";
import { memo } from "react";
const SvgAttach = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.5 23.5c4.137 0 7.5-3.363 7.5-7.5V6.625A5.617 5.617 0 0 0 14.375 1 5.617 5.617 0 0 0 8.75 6.625V16a3.745 3.745 0 0 0 3.75 3.75A3.745 3.745 0 0 0 16.25 16V7.562a.928.928 0 0 0-.938-.937.936.936 0 0 0-.937.938V16a1.872 1.872 0 0 1-1.875 1.875A1.872 1.872 0 0 1 10.625 16V6.625a3.745 3.745 0 0 1 3.75-3.75 3.745 3.745 0 0 1 3.75 3.75V16a5.617 5.617 0 0 1-5.625 5.625A5.617 5.617 0 0 1 6.875 16V8.812a.928.928 0 0 0-.938-.937.936.936 0 0 0-.937.938V16c0 4.137 3.363 7.5 7.5 7.5Z"
    />
  </svg>
);
const Memo = memo(SvgAttach);
export default Memo;
