import { omit } from 'lodash'
import { forwardRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import TextInput from './TextInput'

const propTypes = {}
const defaultProps = {}

const log = logger({ enabled: false, tags: ['HiddenInput'] })

const HiddenInput = forwardRef(({ ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const textInputProps = omit(rest, 'type')

  return (
    <TextInput
      {...textInputProps}
      ref={ref}
      type='hidden'
    />
  )
})

HiddenInput.displayName = 'HiddenInput'
HiddenInput.propTypes = propTypes
HiddenInput.defaultProps = defaultProps

export default HiddenInput
