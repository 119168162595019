import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'
import {
  createDraft as createDraftService,
  del as deleteDraftService,
  scheduleDraft as scheduleDraftService
} from '../../../services/scheduledBroadcast'
import { setCurrentInstance } from '../../../store/actions/currentInstance'

import ScheduledBroadcastDraftDialog from './create/ScheduledBroadcastEditDraftDialog'

const propTypes = {
  draft: PropType.shape(scheduledBroadcastShape),
  message: PropType.shape(message),
  onSuccess: PropType.func
}

const defaultProps = {
  draft: null,
  message: null,
  onSuccess: null
}

const log = logger({ enabled: true, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ draft: initialDraft, message: initialMessage, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const editDraftDialogRef = useRef()
  const navigate = useNavigate()
  const [draft, setDraft] = useState(initialDraft)

  const { call: createDraftCall } = useService(createDraftService, {
    onReplyOk: useCallback((reply) => {
      const model = reply.model
      if (initialMessage) {
        model.media = initialMessage.media
        model.text = initialMessage.text
        model.type = initialMessage.type
        model.template = initialMessage.template
      }
      setDraft(reply.model)
    }, [initialMessage])
  })

  useLayoutEffect(() => {
    if (draft) {
      editDraftDialogRef.current.open()
    }
  }, [draft])

  const { call: scheduleDraftCall } = useService(scheduleDraftService, {
    onReplyOk: useCallback((reply) => {
      const scheduledBroadcast = reply.model
      setCurrentInstance('scheduledBroadcast', scheduledBroadcast)
      bus.emit('scheduledBroadcastCreated', scheduledBroadcast)
      navigate(`/scheduled-broadcasts/${scheduledBroadcast._id}`)
      onSuccess?.()
    }, [navigate, onSuccess])
  })

  const { call: deleteDraftCall } = useService(deleteDraftService)

  useImperativeHandle(ref, () => ({
    activate () {
      if (!draft) {
        createDraftCall()
      }
    }
  }), [createDraftCall, draft])

  const onChange = useCallback((updatedScheduledBroadcast) => {
    setDraft(updatedScheduledBroadcast)
  }, [])

  const onCancel = useCallback(() => {
    deleteDraftCall(draft?._id)
  }, [draft, deleteDraftCall])

  const onClose = useCallback(() => {
    setDraft(null)
  }, [])

  const onSave = useCallback(() => {
    scheduleDraftCall(draft?._id)
  }, [draft, scheduleDraftCall])

  return (draft
    ? <ScheduledBroadcastDraftDialog
        ref={editDraftDialogRef}
        scheduledBroadcast={draft}
        onCancel={onCancel}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
      />
    : null
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
