import { Clipboard } from '@capacitor/clipboard/dist/esm'
import { useCallback, useRef, useState } from 'react'

import ConfirmDialog from '../../components/dialog/ConfirmDialog'
import Message from '../../components/forms/controls/Message'
import Panel from '../../components/panels/Panel'
import PanelBottomSafeSpacer from '../../components/panels/PanelBottomSafeSpacer'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Aiva } from '../../svg/icons'
import { AivaHello, TopEdgeBackground } from '../../svg/misc/aiva-panels'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TryAivaScreen'] })

const TryAivaScreen = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const sendDialogRef = useRef()
  const [messageText, setMessageText] = useState()

  const renderInstruction = useCallback((number, instruction) => {
    return (
      <div key={`instruction-${number}`} className='flex flex-row items-center gap-2 max-w-[294px]'>
        <div className='w-8 h-8 border border-neutral-200 rounded-full label-xs-strong p-2 bg-white'>
          {number}
        </div>
        <div className='label-xs-medium text-left'>
          {instruction}
        </div>
      </div>
    )
  }, [])

  const handleMessageSend = useCallback(({ message }) => {
    sendDialogRef.current.open()
    setMessageText(message.text)
  }, [])

  const handleCopyToClipboard = useCallback(async () => {
    Clipboard.write({ string: messageText })
  }, [messageText])

  return (
    <Panel hideAivaChatButton>
      <PanelHeader subtitle='Artificial intelligence Virtual Assistant' title='Give AIVA a try' />
      <PanelContent disableAutoSafeSpacer>
        <div className='flex flex-col h-full'>
          <div className='bg-neutral-50 h-full relative overflow-y-auto'>
            <TopEdgeBackground className='absolute t-0 l-0 r-0' />
            <div className='flex flex-col pt-12 pb-8 gap-y-3 items-center text-center z-10'>
              <div>
                <AivaHello className='w-32 h-32 inline' />
                <h1 className='label-md-strong text-black'>
                  Wave goodbye to writer's block using AIVA
                </h1>
                <p className='label-sm text-neutral-600 max-w-[600px]'>
                  Searching for the perfect words? Let AIVA transform your thoughts into captivating content. Ready to enhance your SMS campaigns?
                </p>
              </div>
              <h2 className='mt-3 label-xs-strong text-black'>
                Follow these four simple steps:
              </h2>
              <div className='flex flex-col gap-y-2 text-neutral-600'>
                {renderInstruction(1, <>Start to type out a message below.</>)}
                {renderInstruction(2, (
                  <>
                    Click on the
                    {' '}
                    <Aiva className='inline h-4 mx-1 text-indigo-500' />
                    {' '}
                    icon in the toolbar.
                  </>
                ))}
                {renderInstruction(3, <>Select an option to modify your text.</>)}
                {renderInstruction(4, <>Choose to insert AIVA’s suggestion into your message.</>)}
              </div>
            </div>
          </div>
          <Message
            draftKey='try-aiva'
            name='tryAiva'
            placeholder='Sample Message'
            theme='aiva'
            onSend={handleMessageSend}
          />
          <PanelBottomSafeSpacer className='bg-neutral-50' />
        </div>
        <ConfirmDialog
          ref={sendDialogRef}
          confirmTitle='Copy to Clipboard'
          description='Select below if you would like to copy this message to the clipboard for later use.'
          title='Message Ready'
          onConfirm={handleCopyToClipboard}
        />
      </PanelContent>
    </Panel>
  )
}

TryAivaScreen.displayName = 'TryAivaScreen'
TryAivaScreen.propTypes = propTypes
TryAivaScreen.defaultProps = defaultProps

export default TryAivaScreen
