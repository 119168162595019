import * as React from "react";
import { memo } from "react";
const SvgBell = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0a3.006 3.006 0 0 0-2.93 3.642A7.006 7.006 0 0 0 5 10c0 1.815-.424 3.612-1.235 5.233l-1.66 3.32A1 1 0 0 0 3 20h5c0 2.212 1.788 4 4 4 2.212 0 4-1.788 4-4h5a1 1 0 0 0 .894-1.447l-1.66-3.32A11.718 11.718 0 0 1 19 10a7.006 7.006 0 0 0-4.07-6.358A3.006 3.006 0 0 0 12 0Zm1.201 5.146c.076.03.154.052.233.063A5.008 5.008 0 0 1 17 10c0 2.125.496 4.228 1.446 6.127L19.382 18H4.618l.936-1.873C6.504 14.228 7 12.125 7 10a5.008 5.008 0 0 1 3.566-4.79.995.995 0 0 0 .232-.064 4.997 4.997 0 0 1 2.403 0Zm-.204-2.075A1.006 1.006 0 0 0 12 2a1.006 1.006 0 0 0-.997 1.07 7.041 7.041 0 0 1 1.994 0ZM12 22c-1.108 0-2-.892-2-2h4c0 1.108-.892 2-2 2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBell);
export default Memo;
