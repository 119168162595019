import PropType from 'prop-types'
import { useLayoutEffect } from 'react'

import { usePanel } from '../../contexts/PanelContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PanelBottomSafeSpacer from '../panels/PanelBottomSafeSpacer'

import ListLoading from './ListLoading'

const propTypes = {
  context: PropType.shape({
    loading: PropType.bool.isRequired
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListFooter'] })

const ListFooter = ({ context: { loading } }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { setContainsList } = usePanel()

  useLayoutEffect(() => {
    setContainsList(true)
    return () => setContainsList(false)
  }, [setContainsList])

  return (
    loading
      ? <ListLoading />
      : <PanelBottomSafeSpacer />
  )
}

ListFooter.displayName = 'ListFooter'
ListFooter.propTypes = propTypes
ListFooter.defaultProps = defaultProps

export default ListFooter
