import * as React from "react";
import { memo } from "react";
const SvgEdit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Edit_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.803 1.065a3.625 3.625 0 0 1 5.134 0 3.625 3.625 0 0 1 0 5.135l-10.05 10.05a1 1 0 0 1-.436.255l-5.18 1.46a1 1 0 0 1-1.233-1.234l1.46-5.18a1 1 0 0 1 .255-.436l7.835-7.835.005-.005.005-.005 2.205-2.205Zm-8.451 11.28L16.3 5.397l2.306 2.305-6.949 6.949-3.21.905.905-3.211Zm12.17-7.56L20.02 6.288l-2.306-2.306 1.503-1.502-.707-.708.707.708c.64-.64 1.666-.64 2.306 0 .64.64.64 1.666 0 2.305ZM3 2.002c-1.652 0-3 1.348-3 3v16c0 1.653 1.348 3 3 3h13c1.652 0 3-1.347 3-3v-6a1 1 0 0 0-2 0v6c0 .548-.452 1-1 1H3c-.548 0-1-.452-1-1v-16c0-.547.452-1 1-1h9a1 1 0 1 0 0-2H3Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Edit_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgEdit);
export default Memo;
