import { useCallback, useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { get } from '../../services/template'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['TemplateRedirect'] })

const routePartsByContentType = {
  MessageTemplate: 'message-templates',
  VoiceDrop: 'voice-drops'
}

const TemplateRedirect = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [newPath, setNewPath] = useState(null)

  const location = useLocation()
  const params = useParams()

  const handleReplyOk = useCallback((reply) => {
    const { contentType } = reply.json
    const updatedPath = location.pathname.replace('/templates/', `/${routePartsByContentType[contentType]}/`)
    setNewPath(updatedPath)
  }, [location])
  const handleReplyNotOk = useCallback(() => {
    setNewPath('/message-templates')
  }, [])
  const { call } = useService(get, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })

  useEffect(() => {
    call(params.id)
  }, [call, params])

  if (!newPath) { return null }

  return <Navigate to={newPath} replace />
}

TemplateRedirect.displayName = 'TemplateRedirect'
TemplateRedirect.propTypes = propTypes
TemplateRedirect.defaultProps = defaultProps

export default TemplateRedirect
