import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import { archive, del } from '../../../services/message'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ message }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Message was deleted')
    bus.emit('messageDeleted', reply.model)
  }, [])
  const { call: deleteCall } = useService(del, { onReplyOk: handleReplyOk })
  const { call: archiveCall } = useService(archive, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    message.isScheduled ? deleteCall(message._id) : archiveCall(message._id)
  }, [archiveCall, deleteCall, message])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Deleting this message cannot be undone.'
      title='Are you sure you want to delete this message?'
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
