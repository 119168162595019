import PropType from 'prop-types'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import dates from '../../../data-sources/dates'
import months from '../../../data-sources/months'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['BirthdayDetail'] })

const BirthdayDetail = ({ contact, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const value = contact?.formattedBirthday
  const defaultValues = {
    birthday: {
      date: contact?.birthday?.date,
      month: contact?.birthday?.month
    }
  }
  const formControls = [
    { label: 'Month', name: 'birthday[month]', type: 'select', options: months },
    { label: 'Day', name: 'birthday[date]', type: 'select', options: dates }
  ]

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Set the birthday on your contact to enable them for Birthday Campaigns'
      formControls={formControls}
      formDescription={`Updating birthday for ${contact.formattedName}`}
      formTitle={contact.formattedName}
      initialEditButtonText='Set Birthday'
      objectId={contact._id}
      service={service}
      showClearIcon={!!value}
      showEditIcon={!!value}
      showInitialEditButton={!value}
      title='Birthday'
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

BirthdayDetail.displayName = 'BirthdayDetail'
BirthdayDetail.propTypes = propTypes
BirthdayDetail.defaultProps = defaultProps

export default BirthdayDetail
