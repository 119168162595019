import PropType from 'prop-types'
import { forwardRef } from 'react'

import stateProvinceData from '../../../data-sources/state-province'

import Select from './Select'

const propTypes = {
  label: PropType.string,
  placeholder: PropType.string
}
const defaultProps = {
  label: 'State/Province',
  placeholder: 'Choose State/Province'
}

const StateProvinceSelect = forwardRef(({ ...formControlArgs }, ref) => {
  delete formControlArgs.type

  return (
    <Select
      ref={ref}
      options={stateProvinceData}
      type='select'
      {...formControlArgs}
    />
  )
})

StateProvinceSelect.displayName = 'StateProvinceSelect'
StateProvinceSelect.propTypes = propTypes
StateProvinceSelect.defaultProps = defaultProps

export default StateProvinceSelect
