import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/keyword-join-space'
import SpaceList from '../spaces/SpaceList'

const propTypes = {
  keywordId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['KeywordJoinSpaceList'] })

const KeywordJoinSpaceList = forwardRef(({ keywordId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [keywordId] })

  return (
    <SpaceList
      ref={ref}
      itemSharedContext={{ keywordId }}
      queryOverrides={{ keywordId }}
      searchService={searchService}
      {...rest}
    />
  )
})

KeywordJoinSpaceList.displayName = 'KeywordJoinSpaceList'
KeywordJoinSpaceList.propTypes = propTypes
KeywordJoinSpaceList.defaultProps = defaultProps

export default KeywordJoinSpaceList
