import * as React from "react";
import { memo } from "react";
const SvgPerson = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm0 14c-2.622 0-5.052.623-6.86 1.677C3.358 16.717 2 18.3 2 20.25V23a1 1 0 1 0 2 0v-2.75c0-.94.667-1.982 2.147-2.845C7.603 16.557 9.672 16 12 16s4.398.557 5.852 1.405C19.334 18.268 20 19.311 20 20.25V23a1 1 0 1 0 2 0v-2.75c0-1.95-1.358-3.534-3.14-4.573C17.052 14.623 14.622 14 12 14Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPerson);
export default Memo;
