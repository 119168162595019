import PropType from 'prop-types'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

const propTypes = {
  children: PropType.node.isRequired,
  external: PropType.bool.isRequired,
  download: PropType.string,
  href: PropType.string
}

const defaultProps = {
  download: null,
  href: null
}

const ButtonWrapper = forwardRef(({ children, download, href, external, ...rest }, ref) => {
  if (!href) { return <button ref={ref} {...rest}>{children}</button> }
  if (external || download) {
    return (
      <a
        ref={ref}
        download={download}
        href={href}
        {...rest}
      >
        {children}
      </a>
    )
  }
  return <Link ref={ref} to={href} {...rest}>{children}</Link>
})

ButtonWrapper.displayName = 'ButtonWrapper'
ButtonWrapper.propTypes = propTypes
ButtonWrapper.defaultProps = defaultProps

export default ButtonWrapper
