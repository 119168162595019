import PropType from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import useDefaultId from '../../../hooks/useDefaultId'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatExtendedDateAndTime } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

import DateTimeDialog from './date-time/DateTimeDialog'

const propTypes = {
  name: PropType.string.isRequired,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
  onChange: PropType.func
}

const defaultProps = {
  error: null,
  hint: null,
  id: null,
  label: null,
  onChange: undefined,
  placeholder: ''
}

const log = logger({ enabled: true, tags: ['DateTimeSelect'] })

const DateTimeSelect = ({
  error,
  hint,
  id,
  label,
  name,
  placeholder,
  onChange
}) => {
  id = useDefaultId(id)
  const dateTimeDialogRef = useRef()
  const { userTimeZone } = useTimeZone()

  useLogger({ log, lifecycle: false, tags: [name, id] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  useEffect(() => { if (useFormMethods?.register) { useFormMethods?.register(name) } }, [name, useFormMethods])

  const initialDateTime = useFormMethods?.getValues(name)
  const [selectedDateTime, setSelectedDateTime] = useState(initialDateTime || null)

  const className = 'flex flex-row flex-no-wrap items-center overflow-hidden border border-neutral-300 rounded-md w-full bg-white'
  const buttonClassName = 'h-[40px] text-left w-full py-[5px] px-3 cursor-pointer'

  const trigger = useMemo(() => {
    const placeholderNode = <span className='inline-block text-neutral-500'>{placeholder}</span>
    return (
      <button className={buttonClassName} type='button'>
        {selectedDateTime ? formatExtendedDateAndTime(selectedDateTime, userTimeZone) : placeholderNode}
      </button>
    )
  }, [placeholder, selectedDateTime, userTimeZone])

  const handleSubmit = useCallback(({ date, time }) => {
    dateTimeDialogRef.current.close()
    const newDateTime = dateAtDayAndTimeInTimeZone(date, time, userTimeZone)
    setSelectedDateTime(newDateTime)
    useFormMethods?.setValue?.(name, newDateTime)
    onChange?.(newDateTime)
  }, [name, onChange, useFormMethods, userTimeZone])

  return (
    <>
      <InputLabel id={id} text={label} />
      <div className={className}>
        <DateTimeDialog
          ref={dateTimeDialogRef}
          description='Please select a date and time.'
          title={label}
          trigger={trigger}
          onSubmit={handleSubmit}
        />
      </div>
      <InputError message={error?.message || error} />
      <InputHint message={hint} />
    </>
  )
}

DateTimeSelect.displayName = 'DateTime'
DateTimeSelect.propTypes = propTypes
DateTimeSelect.defaultProps = defaultProps

export default DateTimeSelect
