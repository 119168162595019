import useStore, { reset } from '../'
import rollbar from '../../lib/rollbar'

const setRollbarPerson = function (currentUser) {
  rollbar.configure({
    payload: {
      person: {
        id: currentUser?._id,
        username: currentUser?.auth?.username
      }
    }
  })
}

export function setRequestedLocation (location) {
  useStore.setState((state) => {
    state.requestedLocation = location
  })
}

export async function login (serviceReply) {
  useStore.setState((state) => {
    const currentUser = serviceReply?.ok && serviceReply?.model
    setRollbarPerson(currentUser)
    state.currentUser = currentUser || null
  }, false, 'currentUser/login')
  return serviceReply
}

export function resumeSession (serviceReply) {
  useStore.setState((state) => {
    const currentUser = serviceReply?.ok && serviceReply?.model
    setRollbarPerson(currentUser)
    state.currentUser = currentUser || null
  }, false, 'currentUser/resumeSession')
}

export async function logout (serviceReply) {
  const managingUser = serviceReply?.ok && serviceReply?.model
  if (managingUser) {
    setRollbarPerson(managingUser)
    reset('currentUser/manageUserEnd', {
      currentUser: managingUser
    })
  } else {
    setRollbarPerson(null)
    reset('currentUser/logout')
  }
  return serviceReply
}

export async function manageUser (serviceReply) {
  const managedUser = serviceReply?.ok && serviceReply?.model
  if (managedUser) {
    setRollbarPerson(managedUser)
    reset('currentUser/manageUserStart', {
      currentUser: managedUser
    })
  }
  return serviceReply
}

export async function update (serviceReply) {
  if (serviceReply?.ok) {
    useStore.setState((state) => {
      const currentUser = serviceReply.model
      setRollbarPerson(currentUser)
      state.currentUser = currentUser
    }, false, 'currentUser/update')
  }
  return serviceReply
}
