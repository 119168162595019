import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useCurrentStackKey from '../../../hooks/useCurrentStackKey'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chat from '../../../prop-types/shapes/chat'
import { update } from '../../../services/contact'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  chat: PropType.shape(chat).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MarkAsDoNotMessage'] })

const MarkAsDoNotMessage = forwardRef(({ chat }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const currentStackKey = useCurrentStackKey()
  const confirmDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () { confirmDialogRef.current.open() }
  }), [])

  const handleReplyOk = useCallback(() => {
    confirmDialogRef.current.close()
    success(`${chat.formattedName} marked as do not message`)
    bus.emit('contactOptOutRelatedInfoUpdated', chat)
    navigate(currentStackKey === 'chats' ? '/chats' : `/contacts/${chat._id}`)
  }, [chat, currentStackKey, navigate])
  const { call } = useService(update, { onReplyOk: handleReplyOk })

  const handleConfirm = useCallback(() => {
    call(chat._id, { doNotMessage: true })
  }, [call, chat._id])

  return (
    <ConfirmDialog
      ref={confirmDialogRef}
      description='When confirmed, messages will not be sent to this contact. You can also manually toggle again when needed.'
      title='Are you sure you want to mark this contact as do not message?'
      onConfirm={handleConfirm}
    />
  )
})

MarkAsDoNotMessage.displayName = 'MarkAsDoNotMessage'
MarkAsDoNotMessage.propTypes = propTypes
MarkAsDoNotMessage.defaultProps = defaultProps

export default MarkAsDoNotMessage
