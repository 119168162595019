import * as React from "react";
import { memo } from "react";
const SvgGif = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#GIF_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2ZM0 4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm8.227 6.75a1.073 1.073 0 0 0-.16-.363 1.122 1.122 0 0 0-.648-.437 1.734 1.734 0 0 0-.466-.06c-.364 0-.676.088-.933.262-.256.175-.451.426-.586.754-.133.328-.2.724-.2 1.188 0 .469.064.87.192 1.203.127.333.317.588.57.765.253.178.566.266.941.266.331 0 .606-.048.825-.144.221-.097.386-.233.496-.41a1.15 1.15 0 0 0 .163-.586H6.97V11.64h3.547v1.109c0 .73-.155 1.353-.465 1.871a3.079 3.079 0 0 1-1.274 1.188c-.539.273-1.157.41-1.855.41-.779 0-1.462-.166-2.05-.496a3.485 3.485 0 0 1-1.38-1.414C3.164 13.697 3 12.969 3 12.125c0-.662.1-1.247.3-1.758a3.69 3.69 0 0 1 .845-1.293c.359-.354.774-.62 1.246-.8A4.165 4.165 0 0 1 6.906 8c.474 0 .914.068 1.32.203.41.133.77.323 1.083.57.315.245.569.536.761.872.193.335.31.704.352 1.105H8.227ZM13.7 8.11v8h-2.172v-8H13.7Zm1.03 0v8H16.9v-3.126h3.11v-1.75H16.9V9.86h3.454V8.11h-5.626Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="GIF_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgGif);
export default Memo;
