import { get as getCampaignService } from '../services/campaign'

import useCurrentInstance from './useCurrentInstance'

const useCurrentCampaign = () => useCurrentInstance({
  resourceName: 'campaign',
  getService: getCampaignService,
  replyAttrPath: 'model',
  notFoundRedirect: '/campaigns'
})

export default useCurrentCampaign
