import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Check, LightBlub } from '../../../svg/icons'

const propTypes = {
  message: PropType.string.isRequired,
  type: PropType.oneOf(['con', 'pro']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MessageQualityListItem'] })

const MessageQualityListItem = ({ message, type }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const conIcon = (
    <div className='flex flex-col shrink-0 items-center justify-center bg-indigo-300 w-[32px] h-[32px] rounded-full mr-3'>
      <LightBlub className='text-white w-7/12 h-7/12' />
    </div>
  )

  const proIcon = (
    <div className='flex flex-col shrink-0 items-center justify-center bg-emerald-300 w-[32px] h-[32px] rounded-full mr-3'>
      <Check className='text-white w-7/12 h-7/12' />
    </div>
  )

  return (
    <li className='flex flex-row items-center mb-4'>
      {type === 'pro' ? proIcon : conIcon}
      <div className='text-neutral-700 label-sm-medium'>
        {message}
      </div>
    </li>
  )
}

MessageQualityListItem.displayName = 'MessageQualityListItem'
MessageQualityListItem.propTypes = propTypes
MessageQualityListItem.defaultProps = defaultProps

export default MessageQualityListItem
