import { useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { create, enforce, only, test } from 'vest'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useService from '../../hooks/useService'
import { changePassword as changePasswordService } from '../../services/user'

const ChangePassword = () => {
  const formRef = useRef(null)

  const handleReplyOkay = useCallback(() => {
    formRef.current.reset()
    notify('Password updated successfully.')
  }, [])
  const { call: changePassword } = useService(changePasswordService, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='passwordForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Change Password'
      />
      <PanelContent className='p-5'>
        <div className='pb-4'>
          Use the form below to change your password. If you need to invalidate all existing sessions, please make sure to use the
          {' '}
          <Link className='link' to='/account/sign-out-all'>
            Sign Out From All Devices
          </Link>
          {' '}
          feature.
        </div>
        <Form
          ref={formRef}
          defaultValues={{}}
          formControls={[
            {
              type: 'password',
              name: 'currentPassword',
              label: 'Current Password',
              required: true
            },
            {
              hint: 'Password must be 8 or more characters with at least 1 letter and 1 number.',
              type: 'password',
              name: 'newPassword',
              label: 'New Password',
              required: true
            }
          ]}
          id='passwordForm'
          validationSuite={create((data = {}, currentField) => {
            only(currentField)

            test('newPassword', 'Password is too short', () => {
              enforce(data.newPassword).longerThan(7)
            })
            test('newPassword', 'Password must have at least one number', () => {
              enforce(data.newPassword).matches(/[0-9]/)
            })
            test('newPassword', 'Password must have at least one lowercase letter', () => {
              enforce(data.newPassword).matches(/[a-z]/)
            })
          })}
          onSubmit={changePassword}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='passwordForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ChangePassword.displayName = 'ChangePassword'

export default ChangePassword
