import React from 'react'

import PanelNotFound from '../components/panels/PanelNotFound'
import RequireStripeAccount from '../components/RequireStripeAccount'
import RequireWeb from '../components/RequireWeb'
import AffiliateProgram from '../screens/account/AffiliateProgram'
import ApiKey from '../screens/account/ApiKey'
import CanSpamCompliance from '../screens/account/CanSpamCompliance'
import ChangePassword from '../screens/account/ChangePassword'
import ContactCard from '../screens/account/ContactCard'
import CreditLedger from '../screens/account/CredtLedger'
import IntegrationsList from '../screens/account/IntegrationsList'
import ManagersList from '../screens/account/ManagersList'
import MultiFactorAuthenticationList from '../screens/account/mfa/List'
import MultiFactorAuthentication from '../screens/account/MultiFactorAuthentication'
import Profile from '../screens/account/Profile'
import ReferralProgram from '../screens/account/ReferralProgram'
import SignOutAll from '../screens/account/SignOutAll'
import Stats from '../screens/account/Stats'
import TheCampaignRegistry from '../screens/account/TheCampaignRegistry'
import UserCustomFields from '../screens/account/UserCustomFields'

const routes = [
  {
    path: 'profile',
    element: <Profile />
  },
  {
    path: 'tcr',
    element: <TheCampaignRegistry />
  },
  {
    path: 'integrations',
    element: (
      <RequireWeb><IntegrationsList /></RequireWeb>
    ),
    children: [{
      path: ':partnerId/connect',
      element: <IntegrationsList />
    }]
  },
  {
    path: 'api-key',
    element: (
      <RequireWeb><ApiKey /></RequireWeb>
    )
  },
  {
    path: 'managers',
    element: <ManagersList />
  },
  {
    path: 'credit-usage',
    element: <CreditLedger />
  },
  {
    path: 'stats',
    element: <Stats />
  },
  {
    path: 'sign-out-all',
    element: <SignOutAll />
  },
  {
    path: 'user-custom-fields',
    element: <UserCustomFields />
  },
  {
    path: 'affiliate-program',
    element: (
      <RequireWeb><AffiliateProgram /></RequireWeb>
    )
  },
  {
    path: 'change-password',
    element: <ChangePassword />
  },
  {
    path: 'multi-factor-authentication',
    element: <MultiFactorAuthentication />
  },
  {
    path: 'multi-factor-authentication/list',
    element: <MultiFactorAuthenticationList />
  },
  {
    path: 'referral-program',
    element: (
      <RequireStripeAccount>
        <ReferralProgram />
      </RequireStripeAccount>
    )
  },
  {
    path: 'contact-card',
    element: <ContactCard />
  },
  {
    path: 'can-spam-compliance',
    element: <CanSpamCompliance />
  },
  {
    path: '*',
    element: <PanelNotFound />
  }
]

export default routes
