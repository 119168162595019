import { useParams } from 'react-router-dom'

import { get as getGoalMilestoneService } from '../services/admin/goal-milestone'

import useCurrentInstance from './useCurrentInstance'

const useCurrentGoalMilestone = () => {
  const { goalId } = useParams()
  return useCurrentInstance({
    resourceName: 'goalMilestone',
    getService: (goalMilestoneId, background) => getGoalMilestoneService(goalId, goalMilestoneId, background),
    replyAttrPath: 'model',
    notFoundRedirect: `/goals/${goalId}`
  })
}

export default useCurrentGoalMilestone
