import { useRef } from 'react'

import ChatList from '../../components/chats/ChatList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

import ListHeaderMore from './list/ListHeaderMore'

const propTypes = {}

const defaultProps = {}

const ChatsList = () => {
  const listRef = useRef()
  const actionMenuRef = useRef()

  const moreButton = <ListHeaderMore ref={actionMenuRef} listRef={listRef} />

  return (
    <Panel>
      <PanelHeader end={moreButton} title='Chats' />
      <PanelContent>
        <ChatList ref={listRef} savedStateKey='chats' type='nav' />
      </PanelContent>
    </Panel>
  )
}

ChatsList.displayName = 'ChatsList'
ChatsList.propTypes = propTypes
ChatsList.defaultProps = defaultProps

export default ChatsList
