import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useTimeZone from '../../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../../lib/formatters'
import logger from '../../../../lib/logger'
import FormDialog from '../../../dialog/FormDialog'

const propTypes = {
  dateTime: PropType.instanceOf(Date)
}

const defaultProps = {
  dateTime: null
}

const log = logger({ enabled: false, tags: ['DateTimeDialog'] })

const DateTimeDialog = forwardRef(({ dateTime, ...formDialogProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { userTimeZone } = useTimeZone()

  const currentDate = formatDateForInput(dateTime || new Date(), userTimeZone)
  const currentTime = formatTimeForInput(dateTime || new Date(), userTimeZone)

  const defaultValues = {
    date: currentDate,
    time: currentTime
  }

  const formControls = [
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      required: true
    },
    {
      label: 'Time',
      name: 'time',
      type: 'time',
      required: true
    }
  ]

  return (
    <FormDialog
      ref={ref}
      defaultValues={defaultValues}
      formControls={formControls}
      {...formDialogProps}
    />
  )
})

DateTimeDialog.displayName = 'DateTimeDialog'
DateTimeDialog.propTypes = propTypes
DateTimeDialog.defaultProps = defaultProps

export default DateTimeDialog
