import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import Banner from '../../../components/banners/Banner'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import useCurrentGoal from '../../../hooks/useCurrentGoal'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ArrowDown } from '../../../svg/icons'

import GoalDetailHeaderMore from './detail/DetailHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['GoalDetail'] })

const GoalDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { goal } = useCurrentGoal()

  const renderedMilestones = useMemo(() => goal?.milestones.map((milestone, idx) => {
    const nudgeBehaviorColor = milestone.nudge.maxRepeat === -1
      ? 'text-orange-700'
      : 'text-black'

    return (
      <div key={milestone._id} className='flex flex-col items-center'>
        <NavLink className='border border-neutral-300 rounded w-fit p-3 hover:bg-neutral-100' to={`${milestone._id}`}>
          <h1 className='label-md-medium'>{milestone.name}</h1>
        </NavLink>
        <h4 className={`mt-1 label-2xs ${nudgeBehaviorColor}`}>
          {milestone.nudgeBehaviorDescription}
        </h4>
        {idx < goal.milestones.length - 1 ? <ArrowDown className='h-10' /> : null}
      </div>
    )
  }), [goal])

  if (!goal) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader end={<GoalDetailHeaderMore goal={goal} />} start={<PanelHeaderBackButton />} title={goal.displayName} />
      <PanelContent className='p-5'>
        {!goal.enabled && (<Banner className='mb-4' title='Currently Disabled' type='warning' />)}
        <p>
          The
          {' '}
          <pre className='paragraph-sm inline bg-neutral-200 p-0.5 rounded'>{goal.name}</pre>
          {' goal '}
          {goal.openEnded
            ? 'is an open-ended goal. Milestones can be added at any time since the goal does not complete.'
            : 'is a fixed-step goal. Milestones are the fixed set of steps towards completion of the goal.'}
        </p>
        <div className='mt-4 flex flex-col items-center'>
          {renderedMilestones}
        </div>
      </PanelContent>
    </Panel>
  )
}

GoalDetail.displayName = 'Detail'
GoalDetail.propTypes = propTypes
GoalDetail.defaultProps = defaultProps

export default GoalDetail
