import PropType from 'prop-types'

export const customField = {
  code: PropType.string,
  description: PropType.string,
  name: PropType.string,
  order: PropType.number,
  path: PropType.string
}

export const userCustomField = {
  ...customField,
  value: PropType.string
}

export default {
  __v: PropType.number,
  _id: PropType.string,
  createdAt: PropType.string, // 2023-01-20T23:26:11.694Z
  updatedAt: PropType.string, // 2023-01-20T23:26:11.694Z
  firstName: PropType.string,
  lastName: PropType.string,
  contactNumber: PropType.string,
  address: PropType.shape({
    addressLine1: PropType.string,
    addressLine2: PropType.string,
    city: PropType.string,
    country: PropType.string,
    postal: PropType.string,
    state: PropType.string,
    stateProvince: PropType.string
  }),
  phoneNumber: PropType.string,
  phoneNumberType: PropType.oneOf([
    'managed-voip',
    'verified-cell',
    'deactivated',
    'lost'
  ]),
  callForwardNumber: PropType.string,
  callForwardEnabled: PropType.bool,
  callFromAnyoneEnabled: PropType.bool,
  stripeId: PropType.string,
  subscriptionPlan: PropType.string,
  addOns: PropType.arrayOf(PropType.oneOf([
    'outboundcall',
    'unlimited_chat',
    'voice'
  ])),
  refId: PropType.string,
  referredByCode: PropType.string,
  referredBy: PropType.string, // User ObjectId
  referredByAffiliateId: PropType.number,
  smsOnly: PropType.bool,
  signupSource: PropType.oneOf([
    'facebook'
  ]),
  sync: PropType.shape({
    receivedTime: PropType.string, // 2023-01-20T23:26:11.694Z
    sentTime: PropType.string // 2023-01-20T23:26:11.694Z
  }),
  messageCredits: PropType.number,
  messageCreditData: PropType.shape({
    monthlyPlanNonRollover: PropType.number,
    usedThisPeriod: PropType.number
  }),
  messageCreditsAtCancel: PropType.number,
  messagesSentAtCancel: PropType.number,
  card: PropType.shape({
    brand: PropType.string,
    exp_month: PropType.number,
    exp_year: PropType.number,
    id: PropType.string,
    last4: PropType.number
  }),
  cancelled: PropType.bool,
  cancelledDate: PropType.string, // 2023-01-20T23:26:11.694Z
  subscriptionEndDate: PropType.string, // 2023-01-20T23:26:11.694Z
  phoneNumberPendingCancellation: PropType.string,
  phoneNumberPendingCancellationAt: PropType.string, // 2023-01-20T23:26:11.694Z
  cancelledPhone: PropType.bool,
  disabled: PropType.bool, // permanently disabled (ie identified as spammer)
  onHold: PropType.bool,
  superuser: PropType.bool,
  lowCreditWarningSent: PropType.bool,
  customFields: PropType.arrayOf(PropType.shape(customField)),
  userCustomFields: PropType.arrayOf(PropType.shape(userCustomField)),
  relaxedDynamicFieldValidation: PropType.bool,
  assistantPassword: PropType.string,
  apiKey: PropType.string,
  integratorApiKeys: PropType.arrayOf(PropType.shape({
    apiKey: PropType.string,
    integrator: PropType.string // Integrator ObjectId
  })),
  timezone: PropType.string,
  outOfOffice: PropType.bool,
  extra: PropType.shape({
    appVersion: PropType.number,
    webVersion: PropType.number
  }),
  outOfOfficeUntil: PropType.string, // 2023-01-20T23:26:11.694Z
  officeHours: PropType.arrayOf(PropType.shape({
    dayOfWeek: PropType.number,
    endHour: PropType.number,
    endMinute: PropType.number,
    startHour: PropType.number,
    startMinute: PropType.number
  })),
  voicemailGreetingMediaUrl: PropType.string,
  managers: PropType.arrayOf(PropType.shape({
    hidden: PropType.bool,
    user: PropType.string // User ObjectId
  })),
  isV2: PropType.bool,
  isTwilio: PropType.bool,
  isSinch: PropType.bool,
  twilio: PropType.shape({
    accountSid: PropType.string,
    brandRegistration: PropType.shape({
      failedReason: PropType.string,
      sid: PropType.string,
      status: PropType.oneOf(['not-created', 'creating', 'created', 'creation-failed'])
    }),
    customerProfile: PropType.shape({
      addressSid: PropType.string,
      endUserSid: PropType.string,
      failedReason: PropType.string,
      sid: PropType.string,
      status: PropType.oneOf(['not-created', 'creating', 'created', 'creation-failed']),
      supportingDocumentSid: PropType.string
    }),
    failedReason: PropType.string,
    messagingServiceSid: PropType.string,
    phoneNumberSid: PropType.string,
    previousAccountSid: PropType.string, // only used when moving to/from cancelled account that is still registered - when Starter brand registration was shut down
    status: PropType.oneOf(['not-registered', 'registering', 'registered', 'registration-failed', 'unregistering', 'unregistered', 'unregistration-failed']),
    trustProduct: PropType.shape({
      failedReason: PropType.string,
      sid: PropType.string,
      status: PropType.oneOf(['not-created', 'creating', 'created', 'creation-failed'])
    }),
    usAppToPerson: PropType.shape({
      failedReason: PropType.string,
      sid: PropType.string,
      status: PropType.oneOf(['not-created', 'creating', 'created', 'creation-failed', 'removing', 'removed', 'remove-failed'])
    })
  }),
  autoRecharge: PropType.shape({
    enabled: PropType.bool,
    locked: PropType.bool,
    lowCreditThreshold: PropType.number,
    renewalCreditAmount: PropType.number
  }),
  optOutText: PropType.string,
  canvaUserId: PropType.string,
  pamperedChefUserId: PropType.string,
  trinitySoftwareUserId: PropType.string,
  trinitySoftwareLillaRoseUserId: PropType.string,
  exampleIntegratorUserId: PropType.string,
  forceMMS: PropType.bool,
  cancellations: PropType.arrayOf(PropType.shape({
    date: PropType.string, // 2023-01-20T23:26:11.694Z
    reason: PropType.string,
    reasonCode: PropType.oneOf([
      'stopped-business',
      'switching-to-competitor',
      'hard-to-use',
      'too-expensive',
      'other'
    ]),
    type: PropType.oneOf([
      'payment-failure',
      'user-initiated',
      'other'
    ])
  })),
  signupDates: PropType.arrayOf(PropType.string), // 2023-01-20T23:26:11.694Z
  cancelledDates: PropType.arrayOf(PropType.string), // 2023-01-20T23:26:11.694Z
  allowMessagesFromShortCodes: PropType.bool,
  incomingMessageEmailNotifications: PropType.bool,
  tcrSoleProp: PropType.shape({
    bandwidth: PropType.shape({
      failedReason: PropType.string,
      status: PropType.oneOf(['not-connected', 'connecting', 'connected', 'connection-failed']),
      tnOrderOptionId: PropType.string
    }),
    brandId: PropType.string,
    campaignId: PropType.string,
    failedReason: PropType.string,
    referenceId: PropType.string,
    status: PropType.oneOf(['not-registered', 'registering', 'registered', 'unregistering', 'unregistered', 'registration-failed', 'unregistration-failed'])
  }),
  reactivationBonus: PropType.shape({
    code: PropType.string,
    expiration: PropType.string, // 2023-01-20T23:26:11.694Z
    redeemed: PropType.string // 2023-01-20T23:26:11.694Z
  }),
  requireDoubleOptIn: PropType.bool,
  enableAutoDoubleOptInMessage: PropType.bool,
  spaces: PropType.arrayOf(PropType.shape({
    joinDate: PropType.string, // 2023-01-20T23:26:11.694Z
    lastMessageRead: PropType.shape({ time: PropType.string }), // 2023-01-20T23:26:11.694Z
    notificationSettings: PropType.shape({
      disabled: PropType.bool
    }),
    space: PropType.string // Space ObjectId
  })),
  aivaSettings: PropType.shape({
    assistantChat: PropType.bool,
    enableNotifications: PropType.bool,
    instantAssistant: PropType.bool,
    messageHelper: PropType.bool,
    messageStrength: PropType.bool,
    stopDetect: PropType.oneOf(['off', 'flag-only', 'auto-opt-out'])
  }),
  isCanSpamCompliant: PropType.bool,
  canSpamCompliance: PropType.shape({
    agreesToCanSpamAct: PropType.bool,
    physicalMailingAddress: PropType.shape({
      addressLine1: PropType.string,
      addressLine2: PropType.string,
      city: PropType.string,
      postal: PropType.string,
      stateProvince: PropType.string
    })
  })
}
