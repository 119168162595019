import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { SearchNoResults } from '../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmptySearchResultsListContent'] })

const EmptySearchResultsListContent = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <div className='flex flex-col mx-auto mt-12 mb-6 max-w-[375px]'>
        <SearchNoResults />
      </div>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <h1 className='label-lg-strong text-center'>No results found</h1>
      </div>
    </>
  )
}

EmptySearchResultsListContent.displayName = 'EmptySearchResultsListContent'
EmptySearchResultsListContent.propTypes = propTypes
EmptySearchResultsListContent.defaultProps = defaultProps

export default EmptySearchResultsListContent
