import * as React from "react";
import { memo } from "react";
const SvgCampaigns = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 244 183"
    {...props}
  >
    <g filter="url(#Campaigns_svg__a)">
      <path
        fill="#fff"
        d="M187.786 5.143H47.214c-6.47 0-11.714 5.244-11.714 11.714v140.571c0 6.47 5.245 11.715 11.714 11.715h140.572c6.469 0 11.714-5.245 11.714-11.715V16.857c0-6.47-5.245-11.714-11.714-11.714Z"
      />
      <path
        stroke="#E6E7EB"
        d="M47.214 5.643h140.572c6.193 0 11.214 5.02 11.214 11.214v140.571c0 6.194-5.021 11.215-11.214 11.215H47.214c-6.193 0-11.214-5.021-11.214-11.215V16.857c0-6.194 5.02-11.214 11.214-11.214Z"
      />
    </g>
    <path
      fill="#ECFEFF"
      d="M177.5 17.143h-120c-5.523 0-10 4.477-10 10v120c0 5.522 4.477 10 10 10h120c5.523 0 10-4.478 10-10v-120c0-5.523-4.477-10-10-10Z"
    />
    <g filter="url(#Campaigns_svg__b)">
      <rect
        width={178}
        height={40}
        x={54.5}
        y={85.643}
        fill="#fff"
        rx={4}
        shapeRendering="crispEdges"
      />
      <rect width={28} height={28} x={62.5} y={91.643} fill="#CFFBFE" rx={4} />
      <path
        fill="#0891B3"
        fillRule="evenodd"
        d="M85.834 98.006a.848.848 0 0 0-1.092-.813l-15.878 4.764v-.141a.849.849 0 1 0-1.697 0v1.262a.76.76 0 0 0 0 .037V107.755a.849.849 0 1 0 1.697 0v-.068l2.195.768-.374 1.064a2.552 2.552 0 0 0 1.658 3.22l4.047 1.273a2.553 2.553 0 0 0 3.193-1.662l.265-.819 4.857 1.7a.848.848 0 0 0 1.129-.801V98.006Zm-7.588 12.965-5.585-1.955-.37 1.05c-.131.44.12.916.56 1.054l4.048 1.273a.855.855 0 0 0 1.066-.556l.002-.008.28-.858Zm-9.382-5.082 15.273 5.346V99.147l-15.273 4.581v2.161Z"
        clipRule="evenodd"
      />
      <path
        fill="#CFFBFE"
        d="M187.897 96.614h-87.859c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h87.859c1.954 0 3.537-1.574 3.537-3.515 0-1.94-1.583-3.514-3.537-3.514ZM151.928 107.643h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
      />
      <rect
        width={177}
        height={39}
        x={55}
        y={86.143}
        stroke="#E6E7EB"
        rx={3.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#Campaigns_svg__c)">
      <rect
        width={178}
        height={40}
        x={7}
        y={34.143}
        fill="#fff"
        rx={4}
        shapeRendering="crispEdges"
      />
      <rect width={28} height={28} x={15} y={40.143} fill="#CFFBFE" rx={4} />
      <path
        fill="#0891B3"
        fillRule="evenodd"
        d="M38.334 46.506a.848.848 0 0 0-1.092-.813l-15.878 4.764v-.141a.849.849 0 0 0-1.697 0V56.255a.849.849 0 0 0 1.697 0v-.068l2.195.768-.374 1.064a2.553 2.553 0 0 0 1.658 3.22l4.047 1.273a2.553 2.553 0 0 0 3.193-1.662l.265-.819 4.857 1.7a.849.849 0 0 0 1.129-.8V46.505Zm-7.588 12.965-5.585-1.955-.37 1.05c-.131.44.12.916.56 1.054l4.048 1.273a.856.856 0 0 0 1.066-.556l.002-.008.28-.858Zm-9.382-5.082 15.273 5.345V47.647l-15.273 4.581v2.161Z"
        clipRule="evenodd"
      />
      <path
        fill="#CFFBFE"
        d="M140.397 45.114h-87.86c-1.953 0-3.537 1.573-3.537 3.514 0 1.941 1.584 3.515 3.538 3.515h87.859c1.954 0 3.537-1.574 3.537-3.515 0-1.94-1.583-3.514-3.537-3.514ZM104.428 56.143h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.94 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
      />
      <rect
        width={177}
        height={39}
        x={7.5}
        y={34.643}
        stroke="#E6E7EB"
        rx={3.5}
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="Campaigns_svg__a"
        width={182}
        height={182}
        x={28.5}
        y={0.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_815_69538"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_815_69538"
          result="shape"
        />
      </filter>
      <filter
        id="Campaigns_svg__b"
        width={196}
        height={58}
        x={47.5}
        y={80.643}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_815_69538"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_815_69538"
          result="shape"
        />
      </filter>
      <filter
        id="Campaigns_svg__c"
        width={196}
        height={58}
        x={0}
        y={29.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_815_69538"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_815_69538"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(SvgCampaigns);
export default Memo;
