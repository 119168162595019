import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import configurableFormShape from '../../../prop-types/shapes/configurableForm'
import { clone as cloneService } from '../../../services/configurableForm'
import { success } from '../../banners/Banner'

const propTypes = {
  configurableForm: PropType.shape(configurableFormShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const CloneAction = forwardRef(({ configurableForm, onSuccess }, ref) => {
  const handleReplyOk = useCallback(() => {
    success(`${configurableForm.name} was cloned`)
    bus.emit('configurableFormCloned', configurableForm)
    onSuccess?.()
  }, [configurableForm, onSuccess])
  const { call: cloneConfigurableFormCall } = useService(cloneService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { cloneConfigurableFormCall(configurableForm._id) }
  }), [configurableForm, cloneConfigurableFormCall])
})

CloneAction.displayName = 'CloneAction'
CloneAction.propTypes = propTypes
CloneAction.defaultProps = defaultProps

export default CloneAction
