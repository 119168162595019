import * as React from "react";
import { memo } from "react";
const SvgText = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.966 18.09H1L5.52 5h3.566l4.513 13.09h-2.966L7.353 7.992h-.102l-3.285 10.1Zm-.185-5.145h7.005v2.16H3.781v-2.16ZM17.923 18.276c-.626 0-1.185-.108-1.675-.326a2.696 2.696 0 0 1-1.163-.978c-.281-.434-.422-.976-.422-1.623 0-.546.1-1.004.3-1.375.2-.37.473-.669.819-.895a3.969 3.969 0 0 1 1.176-.51 8.964 8.964 0 0 1 1.393-.244c.571-.06 1.032-.115 1.38-.166.35-.055.604-.136.761-.243a.534.534 0 0 0 .237-.473v-.038c0-.405-.128-.718-.383-.94-.252-.221-.61-.332-1.074-.332-.49 0-.88.109-1.17.326a1.5 1.5 0 0 0-.575.805l-2.519-.204c.128-.597.38-1.113.754-1.547a3.62 3.62 0 0 1 1.451-1.01c.597-.239 1.287-.358 2.071-.358.546 0 1.068.064 1.567.192.502.127.948.326 1.335.594.392.269.701.614.927 1.036.226.417.34.918.34 1.502v6.622H20.87v-1.362h-.077a2.772 2.772 0 0 1-.633.812c-.264.23-.582.411-.952.543-.371.128-.8.192-1.285.192Zm.78-1.879c.4 0 .754-.079 1.06-.236a1.88 1.88 0 0 0 .723-.653c.175-.272.262-.581.262-.926V13.54a1.46 1.46 0 0 1-.351.153 6.403 6.403 0 0 1-.492.122c-.184.034-.367.066-.55.096l-.499.07c-.32.047-.599.121-.837.223a1.34 1.34 0 0 0-.556.416c-.132.17-.198.384-.198.64 0 .37.134.653.402.85.273.191.618.287 1.036.287Z"
    />
  </svg>
);
const Memo = memo(SvgText);
export default Memo;
