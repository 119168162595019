import * as React from "react";
import { memo } from "react";
const SvgEyeOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.414 4.414A1 1 0 0 0 20 3L3.03 19.97a1 1 0 0 0 1.414 1.415l1.834-1.835C7.978 20.475 9.928 21 12 21c5.294 0 9.785-3.428 11.38-8.182a1 1 0 0 0 0-.636 12.026 12.026 0 0 0-4.05-5.684l2.084-2.084Zm-3.512 3.512-2.488 2.488a3.981 3.981 0 0 1 .505 2.892 4.001 4.001 0 0 1-6.005 2.608l-2.148 2.148A10.004 10.004 0 0 0 21.371 12.5a10.027 10.027 0 0 0-3.469-4.574ZM12 14.5c-.204 0-.4-.03-.585-.087l2.498-2.498a1.999 1.999 0 0 1 .046.988A2 2 0 0 1 12 14.5Zm.472-5.972a4 4 0 0 0-4.445 4.445l4.445-4.445ZM12 4c1.49 0 2.916.271 4.232.768l-1.586 1.586A10.004 10.004 0 0 0 2.63 12.5a10.006 10.006 0 0 0 2.3 3.571l-1.414 1.414A12.003 12.003 0 0 1 .62 12.818a1 1 0 0 1 0-.636C2.215 7.428 6.706 4 12 4Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgEyeOff);
export default Memo;
