import { useCallback, useEffect, useState } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentUser from '../../hooks/useCurrentUser'
import useService from '../../hooks/useService'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { optOutText as optOutTextService } from '../../services/config'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const OptOutMessage = () => {
  const [configOptOutText, setConfigOptOutText] = useState()
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('Opt Out Message updated successfully.')
  }, [])
  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateUserAction, { onReplyOk: handleReplyOkay })

  const { call: fetchConfigOptOutText } = useService(optOutTextService, {
    onReplyOk: (result) => {
      setConfigOptOutText(result.json.optOutText)
    }
  })

  useEffect(() => {
    if (!currentUser.optOutText) {
      fetchConfigOptOutText()
    }
  }, [configOptOutText, currentUser.optOutText, fetchConfigOptOutText])

  const title = 'Opt Out Message'

  if (!currentUser.optOutText && !configOptOutText) { return <PanelLoading title={title} /> }

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='optOutMessageForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent className='p-5'>
        <div className='pb-4'>Use the field below to update your opt out message. Your opt out message can be inserted into any message using the optOut Dynamic Field.</div>
        <Form
          defaultValues={{
            optOutText: currentUser.optOutText || configOptOutText
          }}
          formControls={[
            {
              type: 'textarea',
              name: 'optOutText',
              label: 'Opt Out Message'
            }
          ]}
          id='optOutMessageForm'
          onSubmit={updateProfile}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='optOutMessageForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

OptOutMessage.displayName = 'OptOutMessage'

export default OptOutMessage
