import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('message', 'Message is required', () => {
    enforce(data.message).isNotEmpty()
  })
})

export default validationSuite
