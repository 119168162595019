import { keyBy } from 'lodash'

import { formatDateForInput } from './formatters'

export const pbHqTimeZone = 'America/Chicago'

export const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function timeZoneName (refDate, timeZone) {
  const tzParts = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short'
  }).formatToParts(refDate)
  const { timeZoneName } = keyBy(tzParts, 'type')
  return timeZoneName?.value || ''
}

export function timeZoneOffsetDiff (refDate, timeZone1, timeZone2) {
  const options = {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  }
  const tz1Parts = new Intl.DateTimeFormat('en-US', { timeZone: timeZone1, ...options }).formatToParts(refDate)
  const tz2Parts = new Intl.DateTimeFormat('en-US', { timeZone: timeZone2, ...options }).formatToParts(refDate)

  const toDateParams = (parts) => {
    const { year, month, day, hour, minute } = keyBy(parts, 'type')
    return [year.value, month.value - 1, day.value, hour.value % 24, minute.value]
  }
  return Date.UTC(...toDateParams(tz1Parts)) - Date.UTC(...toDateParams(tz2Parts))
}

export function dateAtStartOfDayInTimeZone (day, timeZone) {
  // day expected format 'yyyy-mm-dd' (eg 2023-02-07)
  if (/^\d{4}-\d{2}-\d{2}$/.test(day) === false) { return null }
  const [year, month, dayOfMonth] = day.split('-')
  const dateTimeLocal = new Date(year, month - 1, dayOfMonth)
  const timeZoneMinusLocalOffset = timeZoneOffsetDiff(dateTimeLocal, timeZone, localTimeZone)
  return new Date(dateTimeLocal.getTime() - timeZoneMinusLocalOffset)
}

export function dateAtTimeOfDayInTimeZone (dateTime, timeOfDay, timeZone) {
  // timeOfDay expected format 'hh:mm' (eg 19:00)
  if (!dateTime || /^\d?\d:\d\d$/.test(timeOfDay) === false) { return null }
  const day = formatDateForInput(dateTime, timeZone) // return 'YYYY-MM-DD'
  const [year, month, dayOfMonth] = day.split('-')
  const [hours, minutes] = timeOfDay.split(':')
  const dateTimeLocal = new Date(year, month - 1, dayOfMonth, hours, minutes)
  const timeZoneMinusLocalOffset = timeZoneOffsetDiff(dateTimeLocal, timeZone, localTimeZone)
  return new Date(dateTimeLocal.getTime() - timeZoneMinusLocalOffset)
}

export function dateAtDayAndTimeInTimeZone (day, timeOfDay, timeZone) {
  // day expected format 'yyyy-mm-dd' (eg 2023-02-07)
  // timeOfDay expected format 'hh:mm' (eg 19:00)
  const dateAtStartOfDay = dateAtStartOfDayInTimeZone(day, timeZone)
  return dateAtTimeOfDayInTimeZone(dateAtStartOfDay, timeOfDay, timeZone)
}
