import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import FormDialog from '../../components/dialog/FormDialog'
import SubscriptionSummary from '../../components/plans/SubscriptionSummary'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import useStackBackButton from '../../hooks/useStackBackButton'
import { formatPhone } from '../../lib/formatters'
import logger from '../../lib/logger'
import { changePlan as changePlanService } from '../../services/subscription'
import { update } from '../../store/actions/currentUser'

import validationSuite from './VerifyMobileNumber.validations'

const propTypes = {
  cellPhoneNumber: PropType.string,
  plan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  reanchorImmediately: PropType.bool
}

const defaultProps = {
  cellPhoneNumber: null,
  plan: null,
  reanchorImmediately: false
}

const log = logger({ enabled: false, tags: ['VerifyMobileNumber'] })

const VerifyMobileNumber = forwardRef(({ plan, reanchorImmediately, cellPhoneNumber }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { handleBackButtonClick } = useStackBackButton()

  const handlePlanChangeOk = useCallback(() => {
    ref.current.close()
    notify('Your plan change request has been submitted.')
    handleBackButtonClick()
  }, [handleBackButtonClick, ref])
  const { call: changePlan } = useServiceAndAction(changePlanService, update, { onReplyOk: handlePlanChangeOk })

  const onSubmit = useCallback(({ verificationCode }) => {
    const planId = plan?.id
    changePlan({ planId, reanchorImmediately, cellPhoneNumber, verificationCode })
  }, [plan?.id, changePlan, reanchorImmediately, cellPhoneNumber])

  return (
    <FormDialog
      ref={ref}
      defaultValues={{
        verificationCode: ''
      }}
      description={`A verification code has been sent to ${formatPhone(cellPhoneNumber)}.`}
      formControls={[
        {
          name: 'verificationCode',
          placeholder: 'Enter verification code',
          hint: 'Enter your verification code to complete your reactivation.'
        },
        {
          name: 'summary',
          type: 'passthrough',
          element: <SubscriptionSummary plan={plan} reanchorImmediately={reanchorImmediately} />
        }
      ]}
      title='Mobile Number Verification'
      validationSuite={validationSuite}
      onSubmit={onSubmit}
    />
  )
})

VerifyMobileNumber.displayName = 'VerifyMobileNumber'
VerifyMobileNumber.propTypes = propTypes
VerifyMobileNumber.defaultProps = defaultProps

export default VerifyMobileNumber
