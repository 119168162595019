import { find, map, pick } from 'lodash'

import { Calendar, Campaign, Countdown, Drip, TimeLapse } from '../svg/types'

export const campaignTypeOptions = [
  {
    label: 'Drip',
    value: 'drip',
    description: 'Multiple messages sent to contacts based on a day delay and time from when they join the campaign.',
    contactsAddDescription: 'Adding contacts will schedule future broadcasts based on your drip messages.  Removing contacts will remove them from the scheduled broadcasts.',
    supportsMultipleMessages: true,
    typeSvg: Drip
  },
  {
    label: 'Time-lapsed',
    value: 'delay',
    description: 'Multiple messages sent to contacts based on a hour and minute delay from when they join the campaign.',
    contactsAddDescription: 'Adding contacts will schedule future broadcasts based on your time-lapsed messages.  Removing contacts will remove them from the scheduled broadcasts.',
    supportsMultipleMessages: true,
    typeSvg: TimeLapse
  },
  {
    label: 'Event',
    value: 'event',
    description: 'Multiple messages sent to contacts based on a number of days and time before their event date and time.',
    contactsAddDescription: 'Adding contacts will schedule future broadcasts based on your event messages. Removing contacts will remove them from the scheduled broadcasts.',
    supportsMultipleMessages: true,
    typeSvg: Countdown
  },
  {
    label: 'Countdown',
    value: 'countdown',
    description: 'Multiple messages sent to contacts based on a number of days and time before the campaign end date and time.',
    contactsAddDescription: 'Adding contacts will schedule future broadcasts based on your countdown messages. Removing contacts will remove them from the scheduled broadcasts.',
    supportsMultipleMessages: true,
    typeSvg: Countdown
  },
  {
    label: 'Calendar',
    value: 'schedule',
    description: 'Multiple messages sent to contacts based on a specific date and time.',
    contactsAddDescription: 'Adding contacts will schedule future broadcasts based on your calendar messages.  Removing contacts will remove them from the scheduled broadcasts.',
    supportsMultipleMessages: true,
    typeSvg: Calendar
  },
  {
    label: 'Repeating',
    value: 'repeat',
    description: 'One message sent to contacts repeatedly based on a date and frequency.',
    contactsAddDescription: 'Adding contacts will schedule a future broadcast based on your repeating message.  Removing contacts will remove them from the scheduled broadcast.',
    supportsMultipleMessages: false,
    typeSvg: Campaign
  },
  {
    label: 'Birthday',
    value: 'birthday',
    description: 'One message sent to contacts every year on their birthday.',
    contactsAddDescription: 'Adding contacts with a birthday will schedule a future broadcast based on your birthday message.  Removing contacts will remove them from the scheduled broadcast.',
    supportsMultipleMessages: false,
    typeSvg: Campaign
  },
  {
    label: 'Anniversary',
    value: 'anniversary',
    description: 'One message sent to contacts every year on their anniversary.',
    contactsAddDescription: 'Adding contacts with an anniversary will schedule a future broadcast based on your anniversary message.  Removing contacts will remove them from the scheduled broadcast.',
    supportsMultipleMessages: false,
    typeSvg: Campaign
  }
]

export const campaignTypeOptionsForSearch = map(campaignTypeOptions, (cto) => pick(cto, ['label', 'value']))

export const campaignTypes = map(campaignTypeOptions, 'value')

export function campaignTypeLabel (type) {
  return campaignTypeInfo(type)?.label || ''
}

export function campaignTypeInfo (type) {
  return find(campaignTypeOptions, ({ value }) => (value === type))
}

export const repeatFrequencyOptions = [
  {
    label: 'Every Day',
    value: 'day'
  },
  {
    label: 'Every Week',
    value: 'week'
  },
  {
    label: 'Every Month',
    value: 'month'
  },
  {
    label: 'Every Year',
    value: 'year'
  }
]

export const repeatFrequencies = map(repeatFrequencyOptions, 'value')

export function repeatFrequencyLabel (type) {
  return find(repeatFrequencyOptions, ({ value }) => (value === type))?.label || ''
}
