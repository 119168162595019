import background from '../../../assets/images/bg-lg.png'
import Button from '../../../components/buttons/Button'
import Panel from '../../../components/panels/Panel'
import { School } from '../../../svg/icons'

const propTypes = {}
const defaultProps = {}

const GettingStarted = () => {
  const bgStyle = {
    backgroundImage: `url(${background})`
  }

  return (
    <Panel hideMenuToggleButton>
      <div className='flex h-full text-center p-4 bg-cover bg-center' style={{ ...bgStyle }}>
        <div className='flex flex-col justify-center items-center m-auto px-[25px] w-[380px] h-[380px] lg:px-[50px] lg:w-[480px] lg:h-[480px] bg-white rounded-full drop-shadow'>
          <div className='mx-auto p-[20px] w-[76px] h-[76px] bg-violet-50 text-indigo-600 rounded-full'>
            <School />
          </div>
          <h1 className='headline-xs my-4'>Did you know?</h1>
          <p className='paragraph-sm mb-4'>Project Broadcast has launched our Web School! Check out our Quick Start course to get you up and running in no time!</p>
          <Button href='https://training.projectbroadcast.com/courses/project-broadcast-quick-start' size='md' theme='neutral'>Start Learning</Button>
        </div>
      </div>
    </Panel>
  )
}

GettingStarted.displayName = 'LoginGettingStarted'
GettingStarted.propTypes = propTypes
GettingStarted.defaultProps = defaultProps

export default GettingStarted
