import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import Button from '../buttons/Button'

const propTypes = {
  category: PropType.string.isRequired,
  selected: PropType.bool
}

const defaultProps = {
  selected: false
}

const CategoryButton = forwardRef(({ category, selected, ...rest }, ref) => {
  const className = classNames('grow pt-2 pb-1 border-b-4', {
    'border-primary': selected,
    'border-white': !selected
  })

  return (
    <Button
      ref={ref}
      className={className}
      data-category={category}
      size='none'
      theme='none'
      variant='none'
      {...rest}
    >
      {category}
    </Button>
  )
})

CategoryButton.displayName = 'CategoryButton'
CategoryButton.propTypes = propTypes
CategoryButton.defaultProps = defaultProps

export default CategoryButton
