import * as React from "react";
import { memo } from "react";
const SvgMore = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.423 20.462a2.538 2.538 0 1 1 5.077 0 2.538 2.538 0 0 1-5.077 0Zm0-8.462a2.538 2.538 0 1 1 5.077 0 2.538 2.538 0 0 1-5.077 0Zm2.538-11a2.538 2.538 0 1 0 0 5.077 2.538 2.538 0 0 0 0-5.077Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMore);
export default Memo;
