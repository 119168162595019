import PropType from 'prop-types'

import configurableFormFromJSON from '../models/configurableForm'

import { searchPropTypes } from './configurableForm.prop-types'
import pb from './pb'

export function get (configurableFormId, background = false) {
  const options = {
    background,
    model: { conversion: configurableFormFromJSON }
  }
  return pb.setupGet(`configurableForms/${configurableFormId}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: configurableFormFromJSON,
      key: 'configurableForms',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'configurableForm#search')
  return pb.setupPost('configurableForms/search', options)
}

export function create (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: configurableFormFromJSON
    }
  }
  return pb.setupPost('configurableForms', options)
}

export function update (configurableFormId, body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: configurableFormFromJSON
    }
  }
  return pb.setupPut(`configurableForms/${configurableFormId}`, options)
}

export function bulkDelete (body, background = false) {
  const options = {
    body, background
  }
  return pb.setupDelete('configurableForms/bulk/delete', options)
}

export function del (configurableFormId, background = false) {
  return pb.setupDelete(`configurableForms/${configurableFormId}`, { background })
}

export function clone (configurableFormId, background = false) {
  return pb.setupPost(`configurableForms/${configurableFormId}/clone`, { background })
}
