import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import Button from '../buttons/Button'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AivaLearnMoreButton'] })

const AivaLearnMoreButton = ({ ...buttonParams }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Button
      href='https://www.projectbroadcast.com/2023/09/06/greetings-i-am-aiva/'
      size='md'
      {...buttonParams}
    >
      Learn more
    </Button>
  )
}

AivaLearnMoreButton.displayName = 'AivaLearnMoreButton'
AivaLearnMoreButton.propTypes = propTypes
AivaLearnMoreButton.defaultProps = defaultProps

export default AivaLearnMoreButton
