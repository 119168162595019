import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { useChatThreadPolling } from '../../hooks/useThreadPolling'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { markAsRead, messages } from '../../services/chat'
import { updateUnreadChats } from '../../store/actions/unread'
import ThreadList from '../list/thread/ThreadList'
import MessageThreadItemContent from '../message/MessageThreadItemContent'

const propTypes = {
  contactId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ChatThread'] })

const ChatThread = forwardRef(({ contactId, ...rest }, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [contactId] })

  const handleMarkAsReadReplyOk = useCallback((reply) => { updateUnreadChats(reply?.json?.unreadCount) }, [])
  const { call: markAsReadCall } = useService(markAsRead, { onReplyOk: handleMarkAsReadReplyOk })

  const handleMessageDeleted = useCallback((message) => {
    ref.current.updateMessage(message)
  }, [ref])
  useBus('messageDeleted', handleMessageDeleted)

  const handleUpdatesFromPoller = useCallback((updates) => {
    ref.current.newMessages(updates.messages, updates.scheduledMessagesCount)
  }, [ref])
  const { handleLastItemChange: pollerHandleLastItemChanged } = useChatThreadPolling(contactId, handleUpdatesFromPoller)

  const handleLastItemChange = useCallback((lastItem) => {
    pollerHandleLastItemChanged(lastItem)
    if (!lastItem) { return }
    markAsReadCall({ contactId, messageId: lastItem._id }, true)
    bus.emit('newLastChatMessage', lastItem)
  }, [contactId, markAsReadCall, pollerHandleLastItemChanged])

  return (
    <ThreadList
      ref={ref}
      queryOverrides={{ contactId }}
      service={messages}
      ThreadItemContent={MessageThreadItemContent}
      onLastItemChange={handleLastItemChange}
      {...rest}
    />
  )
})

ChatThread.displayName = 'ChatThread'
ChatThread.propTypes = propTypes
ChatThread.defaultProps = defaultProps

export default ChatThread
