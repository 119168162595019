import PropType from 'prop-types'

import FormattedDate from '../../../../components/FormattedDate'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'

const propTypes = {
  item: PropType.shape({
    createdAt: PropType.string,
    message: PropType.string,
    meta: PropType.string,
    objType: PropType.string
  })
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['AuditHistoryItemContent'] })

const AuditHistoryItemContent = ({ item: audit }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col flex-grow py-[4px] max-w-full overflow-auto'>
      <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
        {audit.objType}
        {' '}
        {audit.message}
      </div>
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <div className='line-clamp-1'>
          <strong><FormattedDate date={audit.createdAt} format='extendedDate' /></strong>
        </div>
      </div>
      <div className='flex flex-col label-xs text-neutral-500 mt-[3px] whitespace-pre-wrap break-words'>
        {audit.meta}
      </div>
    </div>
  )
}

AuditHistoryItemContent.displayName = 'AuditHistoryItemContent'
AuditHistoryItemContent.propTypes = propTypes
AuditHistoryItemContent.defaultProps = defaultProps

export default AuditHistoryItemContent
