import classNames from 'classnames'
import { merge } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { mergeRefs } from 'react-merge-refs'

import useDefaultId from '../../../hooks/useDefaultId'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import logger from '../../../lib/logger'
import { localTimeZone, timeZoneName } from '../../../lib/timeZones'
import { Calendar, Clock } from '../../../svg/icons'
import Banner from '../../banners/Banner'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'
import TextInputIcon from '../TextInputIcon'
import './TextInput.css'

const propTypes = { // passes along extra props to the input
  name: PropType.string.isRequired,
  autoComplete: PropType.string,
  autoFocus: PropType.bool,
  className: PropType.string,
  clearable: PropType.bool,
  end: PropType.node,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
  start: PropType.node,
  type: PropType.oneOf([
    'date',
    'email',
    'hidden',
    'number',
    'password',
    'tel',
    'text',
    'time',
    'time-message-send',
    'url'
  ]),
  onChange: PropType.func
}

const defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  className: '',
  clearable: false,
  end: null,
  error: null,
  hint: null,
  id: null,
  label: null,
  onChange: null,
  placeholder: null,
  start: null,
  type: 'text'
}

const log = logger({ enabled: false, tags: ['TextInput'] })

const patterns = {
  tel: '^(\\+?\\d{1,2}?)?.*(\\d{3}).*(\\d{3}).*(\\d{4})$',
  url: '(https?://)?.+\\..+'
}

export const masks = {
  tel (val) {
    const onlyNumbers = (val || '').replace(/\D/g, '')
    const parts = onlyNumbers.match(/^(1)?(\d{0,3})?(\d{0,3})?(\d{0,4})?/)
    if (parts[4]) { return `(${parts[2]}) ${parts[3] || ''} ${parts[4] || ''}` }
    if (parts[3]) { return `(${parts[2]}) ${parts[3]}` }
    return `${parts[2] || ''}`
  }
}

const TextInput = forwardRef(({
  autoComplete,
  autoFocus,
  className,
  clearable,
  end,
  error,
  hint,
  id,
  label,
  onChange,
  name,
  placeholder,
  start,
  type,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  id = useDefaultId(id)

  useLogger({ log, lifecycle: true, tags: [name, id] })

  const { userTimeZone } = useTimeZone()

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  const [focused, setFocused] = useState(false)
  const [showMessageTimeWarning, setShowMessageTimeWarning] = useState(false)

  const handleShowPickerClick = useCallback(() => {
    ref.current.showPicker?.()
    ref.current.click()
    ref.current.focus()
  }, [ref])

  if (['date', 'time', 'time-message-send'].includes(type)) {
    if (end) { throw new Error('end prop not supported for date and time inputs') }

    const icon = type === 'date' ? <Calendar /> : <Clock />

    end = (
      <>
        {(['time', 'time-message-send'].includes(type)) && (userTimeZone !== localTimeZone)
          ? <span className='flex justify-center items-center'>{timeZoneName(new Date(), userTimeZone)}</span>
          : null}
        <TextInputIcon icon={icon} onClick={handleShowPickerClick} />
      </>
    )
  }

  const clear = (clearable ? <TextInputIcon icon='close' onClick={() => useFormMethods?.setValue?.(name, '')} /> : null)

  const wrapperClassNames = classNames(
    'flex flex-row flex-nowrap',
    {
      'ring-1': focused
    },
    'border border-neutral-300 rounded-md w-full bg-white',
    className
  )
  const inputClassNames = classNames(
    'px-0 py-2 border-none w-full rounded-md flex-grow',
    'focus:!ring-0',
    {
      'ml-[12px]': !start,
      'ml-[2px]': start,
      'mr-[12px]': !end && !clear,
      'mr-[2px]': end || clear
    }
  )

  const handleFocus = useCallback(() => setFocused(true), [])
  const handleBlur = useCallback(() => setFocused(false), [])

  const handleChange = useCallback((event) => {
    if (masks[type]) {
      event.target.value = masks[type](event.target.value)
    }
    if (type === 'time-message-send') {
      const [hours] = event.target.value.split(':')
      setShowMessageTimeWarning(hours < 8 || hours >= 20)
    }
    onChange?.(event)
  }, [onChange, type])

  const registerProps = useFormMethods?.register
    ? useFormMethods.register(name, merge({
      onBlur: handleBlur,
      onChange: handleChange
    }, type === 'number' && {
      valueAsNumber: true
    }))
    : { onBlur: handleBlur, onChange: handleChange }

  const mergedRef = mergeRefs([ref, registerProps.ref])
  delete registerProps.ref

  return (
    <>
      <InputLabel id={id} text={label} />
      <div className={wrapperClassNames}>
        {start}
        <input
          ref={mergedRef}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={inputClassNames}
          id={id}
          name={name}
          pattern={patterns[type]}
          placeholder={placeholder}
          type={{
            'time-message-send': 'time',
            url: 'text'
          }[type] || type}
          onFocus={handleFocus}
          {...rest}
          {...registerProps}
        />
        {end}
        {clear}
      </div>
      <InputError message={error} />
      <InputHint message={hint} />
      {!!showMessageTimeWarning && (
        <Banner className='my-5' type='warning'>
          Heads up! Your selection may cause recipients to receive your message at undesired times.
        </Banner>
      )}
    </>
  )
})

TextInput.displayName = 'TextInput'
TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps

export default TextInput
