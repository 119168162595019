import { formatName, formatPhone } from '../lib/formatters'

import proxy from './proxy'

export default function managerFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get _id () {
      return json._id
    },

    get formattedName () {
      return formatName(json.user)
    },

    get formattedPhoneNumber () {
      return formatPhone(json.user.phoneNumber)
    }
  }

  return proxy(json.user, overrides)
}
