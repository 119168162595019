import PropType from 'prop-types'

import pb from './pb'
import { searchPropTypes } from './qrCode.prop-types'

export function create (body, background = false) {
  const options = {
    body,
    background
  }
  return pb.setupPost('qrCodes', options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('qrCodes/bulk/delete', { body, background })
}

export function del (qrCodeId) {
  return pb.setupDelete(`qrCodes/${qrCodeId}`)
}

export function get (qrCodeId, background = false) {
  return pb.setupGet(`qrCodes/${qrCodeId}`, { background })
}

export function search (body, background = false) {
  const options = { body, background }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'qrCodes#search')
  return pb.setupPost('qrCodes/search', options)
}

export function update (qrCodeId, body, background = false) {
  const options = { body, background }
  return pb.setupPut(`qrCodes/${qrCodeId}`, options)
}
