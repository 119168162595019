import { Link } from 'react-router-dom'

import { MarkColor } from '../../svg/branding'

import SideMenuLockToggle from './SideMenuLockToggle'

const propTypes = {}

const defaultProps = {}

const SideMenuHeader = () => {
  return (
    <header className='flex flex-row grow-0 shrink-0 w-full content-between mt-safe pl-[8px] h-[53px] border-b border-neutral-200'>
      <Link to='/'><MarkColor height='48' width='48' /></Link>
      <SideMenuLockToggle
        className='ml-auto hidden not-native:lg:block cursor-pointer'
        height='52'
        width='52'
      />
    </header>
  )
}

SideMenuHeader.displayName = 'SideMenuHeader'
SideMenuHeader.propTypes = propTypes
SideMenuHeader.defaultProps = defaultProps

export default SideMenuHeader
