import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import BulkEditDialog from '../../../components/bulk-edit/BulkEditDialog'
import BulkEditActions from '../../../components/chats/actions/BulkEditActions'
import MarkAllAsReadAction from '../../../components/chats/actions/MarkAllAsReadAction'
import NewChatAction from '../../../components/chats/actions/NewChatAction'
import OpenChatAction from '../../../components/chats/actions/OpenChatAction'
import ChatList from '../../../components/chats/ChatList'
import ChatListItemContentWithoutFavorite from '../../../components/chats/ChatListItemContentWithoutFavorite'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import CreateAction from '../../../components/scheduled-broadcast/actions/CreateAction'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListHeaderMore'] })

const ListHeaderMore = forwardRef(({ listRef }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const bulkEditRef = useRef()
  const openBulkEdit = useCallback(() => { bulkEditRef.current.open(listRef.current.query()) }, [listRef])

  const markAllAsReadActionRef = useRef()
  const activateMarkAllAsRead = useCallback(() => markAllAsReadActionRef.current.activate(), [])
  const handleMarkAllAsReadSuccess = useCallback(() => listRef.current.reload(), [listRef])

  const newChatActionRef = useRef()
  const activateNewChatAction = useCallback(() => newChatActionRef.current.activate(), [])

  const openChatActionRef = useRef()
  const activateOpenChat = useCallback(() => openChatActionRef.current.activate(), [])

  const scheduleBroadcastActionRef = useRef()
  const activateScheduleBroadcastAction = useCallback(() => scheduleBroadcastActionRef.current.activate(), [])

  const handleReloadList = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadList} />
      <PanelHeaderButton icon={<Plus />} title='New Chat' onClick={activateNewChatAction} />
      <ActionMenu ref={ref} placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Edit Chats' onClick={openBulkEdit} />
        <ActionMenuItem label='Open Chat' onClick={activateOpenChat} />
        <ActionMenuItem label='Schedule Broadcast' onClick={activateScheduleBroadcastAction} />
        <ActionMenuItem label='Mark All As Read' onClick={activateMarkAllAsRead} />
      </ActionMenu>
      <BulkEditDialog
        ref={bulkEditRef}
        actions={BulkEditActions}
        list={ChatList}
        ListItemContent={ChatListItemContentWithoutFavorite}
      />
      <CreateAction ref={scheduleBroadcastActionRef} />
      <MarkAllAsReadAction ref={markAllAsReadActionRef} onSuccess={handleMarkAllAsReadSuccess} />
      <NewChatAction ref={newChatActionRef} />
      <OpenChatAction ref={openChatActionRef} />
    </>
  )
})

ListHeaderMore.displayName = 'ListHeaderMore'
ListHeaderMore.propTypes = propTypes
ListHeaderMore.defaultProps = defaultProps

export default ListHeaderMore
