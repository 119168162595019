import classNames from 'classnames'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import { firstImageUrl, firstLink, firstVcardUrl, firstVoiceUrl } from '../../helpers/messageMedia'
import useHover from '../../hooks/useHover'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import messageShape from '../../prop-types/shapes/message'

import MessageThreadItemActionMenuAiva from './message-thread-item-content/MessageThreadItemActionMenuAiva'
import MessageThreadItemActionMenuDefault from './message-thread-item-content/MessageThreadItemActionMenuDefault'
import MessageThreadItemContentAudio from './message-thread-item-content/MessageThreadItemContentAudio'
import MessageThreadItemContentEmail from './message-thread-item-content/MessageThreadItemContentEmail'
import MessageThreadItemContentError from './message-thread-item-content/MessageThreadItemContentError'
import MessageThreadItemContentImage from './message-thread-item-content/MessageThreadItemContentImage'
import MessageThreadItemContentMediaLink from './message-thread-item-content/MessageThreadItemContentMediaLink'
import MessageThreadItemContentName from './message-thread-item-content/MessageThreadItemContentName'
import MessageThreadItemContentText from './message-thread-item-content/MessageThreadItemContentText'
import MessageThreadItemContentVcard from './message-thread-item-content/MessageThreadItemContentVcard'

const propTypes = {
  item: PropType.shape(messageShape).isRequired,
  listRef: PropType.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  listRef: null
}

const log = logger({ enabled: true, tags: ['MessageThreadItemContent'] })

const MessageThreadItemContent = ({ item: message, listRef }) => {
  const messageThreadItemActionMenuRef = useRef()

  useLogger({ log, lifecycle: false, tags: [message?._id] })

  // NOTE: this originally tried to be based on message type but we have messages typed as 'sms' that have media associated with them.
  const hopefullyImageUrl = firstImageUrl(message)
  const hopefullyVcardLink = firstVcardUrl(message)
  const hopefullyMediaLink = !hopefullyImageUrl && !hopefullyVcardLink ? firstLink(message) : null // old ui would prefer treating a media url entry as image and then fallback to treating it as a link
  const hopefullyVoiceUrl = firstVoiceUrl(message)

  const className = useMemo(() => classNames(
    'relative flex flex-col mx-4 shrink',
    message.direction === 'in' ? 'justify-self-start mr-auto' : 'justify-self-end ml-auto',
    (message.direction === 'in' && message.isAivaChatMessage) ? 'max-w-[85%] sm:max-w-[65%]' : 'max-w-[65%]'
  ), [message?.direction, message?.isAivaChatMessage])

  const actionMenuDisabled = useMemo(() => {
    return (message.archived || listRef === null)
  }, [listRef, message?.archived])

  const handleShowActionMenuButtonVisibility = useCallback(() => {
    if (actionMenuDisabled) { return false }
    messageThreadItemActionMenuRef.current.showButton()
  }, [actionMenuDisabled])
  const handleHideActionMenuButtonVisibility = useCallback(() => {
    if (actionMenuDisabled) { return false }
    messageThreadItemActionMenuRef.current.hideButton()
  }, [actionMenuDisabled])
  const { ref: hoverRef } = useHover({
    onHoverIn: handleShowActionMenuButtonVisibility,
    onHoverOut: handleHideActionMenuButtonVisibility
  })

  const handleClick = useCallback((event) => {
    if (actionMenuDisabled) { return false }
    // Show ... action menu trigger. This is helpful for touch environments.
    messageThreadItemActionMenuRef.current.toggleButton()
  }, [actionMenuDisabled])

  const handleImageClick = useCallback(() => {
    listRef.current.showLightBoxForMessageId(message._id)
  }, [listRef, message._id])

  return (
    <div
      ref={hoverRef}
      className='flex focus:select-text w-full focus:outline-none'
      tabIndex='-1'
      onClick={handleClick}
    >
      <div className={className}>
        <MessageThreadItemContentName direction={message.direction} fromFormattedName={message.fromFormattedName} space={!!message.space} />
        <MessageThreadItemContentImage
          direction={message.direction}
          mediaUrl={hopefullyImageUrl}
          onClick={listRef ? handleImageClick : null}
        />
        <MessageThreadItemContentEmail direction={message.direction} subject={message.emailMessage?.subject} type={message.type} />
        <MessageThreadItemContentAudio direction={message.direction} mediaUrl={hopefullyVoiceUrl} template={message.template} />
        <MessageThreadItemContentMediaLink direction={message.direction} mediaUrl={hopefullyMediaLink} />
        <MessageThreadItemContentVcard direction={message.direction} mediaUrl={hopefullyVcardLink} />
        <MessageThreadItemContentText
          archived={message.archived}
          direction={message.direction}
          isPotentialStop={message.isPotentialStop}
          keywordAppliedByOtherDisplayText={message.keywordAppliedByOtherDisplayText}
          keywordId={message.keyword}
          renderTextAsMarkdown={message.isAivaChatMessage ? message.direction === 'in' : false}
          text={message.text}
          type={message.type}
        />
        <MessageThreadItemContentError direction={message.direction} error={message.error} />
        {actionMenuDisabled
          ? null
          : message.isAivaChatMessage
            ? <MessageThreadItemActionMenuAiva ref={messageThreadItemActionMenuRef} message={message} />
            : <MessageThreadItemActionMenuDefault ref={messageThreadItemActionMenuRef} message={message} />}
      </div>
    </div>
  )
}

MessageThreadItemContent.displayName = 'MessageThreadItemContent'
MessageThreadItemContent.propTypes = propTypes
MessageThreadItemContent.defaultProps = defaultProps

export default MessageThreadItemContent
