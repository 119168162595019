import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import Banner from '../banners/Banner'
import SupportLink from '../links/SupportLink'

const propTypes = {
  error: PropType.object, // eslint-disable-line react/forbid-prop-types
  resetErrorBoundary: PropType.func
}

const defaultProps = {
  error: null,
  resetErrorBoundary: undefined
}

const log = logger({ enabled: true, tags: ['GenericErrorBoundaryFallback'] })

// eslint-disable-next-line n/handle-callback-err, no-unused-vars
const GenericErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='p-5'>
      <Banner title='Something went wrong' type='error'>
        We'll try to get it resolved as soon as we can.
        {' '}
        <SupportLink label='Contact support' />
      </Banner>
    </div>
  )
}

GenericErrorBoundaryFallback.displayName = 'GenericErrorBoundaryFallback'
GenericErrorBoundaryFallback.propTypes = propTypes
GenericErrorBoundaryFallback.defaultProps = defaultProps

export default GenericErrorBoundaryFallback
