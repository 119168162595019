import * as React from "react";
import { memo } from "react";
const SvgAndroid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.33 17c0 .458.375.833.833.833h.832v2.917a1.248 1.248 0 1 0 2.498 0v-2.917h1.665v2.917a1.248 1.248 0 1 0 2.498 0v-2.917h.832a.835.835 0 0 0 .833-.833V8.667H7.33V17ZM5.25 8.667C4.558 8.667 4 9.225 4 9.917v5.833a1.248 1.248 0 1 0 2.498 0V9.917c0-.692-.558-1.25-1.25-1.25Zm14.153 0c-.69 0-1.248.558-1.248 1.25v5.833a1.248 1.248 0 1 0 2.497 0V9.917c0-.692-.558-1.25-1.249-1.25ZM15.264 3.8l1.083-1.083a.413.413 0 0 0 0-.592.412.412 0 0 0-.591 0l-1.232 1.233a4.858 4.858 0 0 0-2.198-.525c-.8 0-1.549.192-2.215.525l-1.24-1.233a.412.412 0 0 0-.592 0 .413.413 0 0 0 0 .592L9.37 3.808a4.988 4.988 0 0 0-2.04 4.025h9.99A4.973 4.973 0 0 0 15.265 3.8Zm-4.603 2.367h-.833v-.834h.833v.834Zm4.162 0h-.832v-.834h.832v.834Z"
    />
  </svg>
);
const Memo = memo(SvgAndroid);
export default Memo;
