import PropType from 'prop-types'
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react'

import TextInput from '../../../components/forms/controls/TextInput'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { customField } from '../../../prop-types/shapes/user'

const propTypes = {
  customFields: PropType.arrayOf(PropType.shape(customField)).isRequired,
  description: PropType.string.isRequired,
  title: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CustomFieldNamesTab'] })

const CustomFieldNamesTab = forwardRef(({ customFields, description, title }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    setVisible
  }), [])

  const fieldInputs = useMemo(() => customFields.map((field) => (
    <div key={field.path} className='mb-3'>
      <TextInput
        label='Name'
        name={field.path}
      />
      <p className='label-sm text-neutral-400'>
        {field.description}
        {' '}
        When using this custom field you will use the dynamic field
        {' '}
        <strong className='select-text'>{field.code}</strong>
        <span>.</span>
      </p>
    </div>
  )), [customFields])

  return (!!visible && (
    <>
      <h1 className='mb-1 label-md-strong'>{title}</h1>
      <p className='paragraph-sm mb-3'>{description}</p>
      {fieldInputs}
    </>
  ))
})

CustomFieldNamesTab.displayName = 'CustomFieldNamesTab'
CustomFieldNamesTab.propTypes = propTypes
CustomFieldNamesTab.defaultProps = defaultProps

export default CustomFieldNamesTab
