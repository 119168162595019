import * as React from "react";
import { memo } from "react";
const SvgEmojiAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.667 8.667c.506 0 1-.427 1-1V5.333H23c.507 0 1-.426 1-1 0-.56-.44-1-1-1h-2.333V1c0-.56-.44-1-1-1s-1 .44-1 1v2.333h-2.334c-.56 0-1 .44-1 1 0 .547.44 1 1 1h2.334v2.334c0 .546.44 1 1 1Zm-9 15.333c5.893 0 10.666-4.773 10.666-10.667 0-.6-.053-1.226-.16-1.853a1.015 1.015 0 0 0-.986-.813c-.547 0-1 .453-1 1 0 .226.146.826.146 1.666A8.666 8.666 0 0 1 10.667 22 8.666 8.666 0 0 1 2 13.333a8.666 8.666 0 0 1 8.667-8.666c.68 0 1.506.146 1.666.146.547 0 1-.453 1-1 0-.493-.373-.866-.826-.986-.32-.08-.947-.16-1.84-.16C4.773 2.667 0 7.44 0 13.333 0 19.227 4.773 24 10.667 24ZM7.333 12.667c1.107 0 2-.894 2-2 0-1.107-.893-2-2-2-1.106 0-2 .893-2 2 0 1.106.894 2 2 2Zm6.667 0c1.107 0 2-.894 2-2 0-1.107-.893-2-2-2s-2 .893-2 2c0 1.106.893 2 2 2Z"
    />
    <path
      fill="currentColor"
      d="M16.385 16.13a5.77 5.77 0 0 1-2.156 3.047 5.878 5.878 0 0 1-3.562 1.156 5.877 5.877 0 0 1-3.562-1.156 5.771 5.771 0 0 1-2.156-3.047.962.962 0 0 1 .062-.758.952.952 0 0 1 .594-.492.962.962 0 0 1 .758.062c.245.124.409.322.492.594.26.833.742 1.508 1.446 2.024a3.907 3.907 0 0 0 2.367.773c.874 0 1.663-.258 2.367-.773a3.882 3.882 0 0 0 1.445-2.024.951.951 0 0 1 .5-.594.978.978 0 0 1 .765-.063c.261.085.454.249.579.493a.961.961 0 0 1 .061.758Z"
    />
  </svg>
);
const Memo = memo(SvgEmojiAdd);
export default Memo;
