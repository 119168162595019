import { includes } from 'lodash'
import { create, enforce, only, test } from 'vest'

import { repeatFrequencies } from '../../../helpers/campaign'

const generateValidationSuite = (campaignType) => create((data = {}, currentField) => {
  only(currentField)

  test('name', 'Name is required', () => {
    enforce(data.name).isNotEmpty()
  })

  if (includes(['drip'], campaignType)) {
    test('scheduledDelayDays', '0 or more Day Delay required', () => {
      enforce(data.scheduledDelayDays).greaterThanOrEquals(0)
    })
  }

  if (includes(['countdown', 'event'], campaignType)) {
    test('scheduledBeforeDays', '0 or more Days Before required', () => {
      enforce(data.scheduledBeforeDays).greaterThanOrEquals(0)
    })
  }

  if (includes(['schedule', 'repeat'], campaignType)) {
    test('scheduledAtDay', 'Date required', () => {
      enforce(data.scheduledAtDay).isNotEmpty()
    })
  }

  if (includes(['drip', 'birthday', 'anniversary', 'countdown', 'event', 'schedule', 'repeat'], campaignType)) {
    test('scheduledAtTime', 'Time required', () => {
      enforce(data.scheduledAtTime).isNotEmpty()
    })
  }

  if (includes(['delay'], campaignType)) {
    test('scheduledDelayHours', 'Hour Delay is required', () => {
      enforce(data.scheduledDelayHours).greaterThanOrEquals(0)
    })

    test('scheduledDelayMinutes', 'Minute Delay is required', () => {
      enforce(data.scheduledDelayMinutes).greaterThanOrEquals(0)
    })
  }

  if (includes(['repeat'], campaignType)) {
    test('scheduledRepeat', 'Selection required', () => {
      enforce(data.scheduledRepeat).isValueOf(repeatFrequencies)
    })
  }
})

export default {
  drip: generateValidationSuite('drip'),
  delay: generateValidationSuite('delay'),
  countdown: generateValidationSuite('countdown'),
  event: generateValidationSuite('event'),
  schedule: generateValidationSuite('schedule'),
  repeat: generateValidationSuite('repeat'),
  birthday: generateValidationSuite('birthday'),
  anniversary: generateValidationSuite('anniversary')
}
