import PropType from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'

import AppFlowTemplateList from '../../../components/app-flows/AppFlowTemplateList'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import LoadingIndicator from '../../../components/LoadingIndicator'
import Pill from '../../../components/pill/Pill'
import { useMinBreakpoint } from '../../../hooks/useBreakpoint'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import appFlowShape from '../../../prop-types/shapes/appFlow'
import { createDesign as createDesignService } from '../../../services/appFlow'
import { Dynamic } from '../../../svg/icons'

import EditorDialog from './EditorDialog'

const propTypes = {
  appFlow: PropType.shape(appFlowShape).isRequired,
  onChange: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['DesignDetail'] })

const DesignDetail = ({ appFlow, onChange }) => {
  const editorDialogRef = useRef()
  const triggerEditNewDesignRef = useRef(false)
  const selectTemplateActionRef = useRef()

  const roomForEdit = useMinBreakpoint('roomToEditDesign')
  const fullyLoaded = !!appFlow.appmixer

  useEffect(() => {
    if (triggerEditNewDesignRef.current && appFlow.hasDesign) {
      triggerEditNewDesignRef.current = false
      editorDialogRef.current.open()
    }
  }, [appFlow.hasDesign])

  const { call: createDesign } = useService(createDesignService, {
    onReplyOk: useCallback((reply) => {
      onChange(reply)
      triggerEditNewDesignRef.current = true
    }, [onChange])
  })

  const handleTemplateSelected = useCallback((template) => {
    createDesign(appFlow._id, template._id)
  }, [appFlow._id, createDesign])

  const handleEditDesign = useCallback(() => {
    editorDialogRef.current.open()
  }, [])

  const handleSelectTemplate = useCallback(() => {
    selectTemplateActionRef.current.open()
  }, [])

  const handleEditorClose = useCallback(({ hasSavedEdits }) => {
    if (hasSavedEdits) {
      onChange()
    }
  }, [onChange])

  return (
    <>
      <DetailSection
        description={`Design and control your custom flow.${roomForEdit ? '' : ' Only available on larger screens.'}`}
        initialEditButtonText='Start Design'
        showEditIcon={appFlow.hasDesign ? roomForEdit : null}
        showInitialEditButton={!appFlow.hasDesign}
        title='Flow'
        onEditClick={handleEditDesign}
        onInitialEditClick={handleSelectTemplate}
      >
        {appFlow.hasDesign
          ? (
            <div className='h-[300px] flex flex-col justify-center items-center relative'>
              {!fullyLoaded
                ? <LoadingIndicator className='mx-auto text-neutral-400' message='Loading Flow' />
                : appFlow.appmixer.thumbnail
                  ? (
                    <>
                      <div className='h-full'>
                        <img className='h-full' src={appFlow.appmixer.thumbnail} />
                      </div>
                      <div className='flex flex-row gap-2 absolute -top-1 -right-1'>
                        <Pill
                          start={appFlow.state === 'running' ? <Dynamic /> : null}
                          theme={appFlow.state === 'running' ? 'primary' : 'danger'}
                          value={appFlow.state.toUpperCase()}
                        />
                      </div>
                    </>
                    )
                  : <br />}
            </div>
            )
          : null}
      </DetailSection>
      <EditorDialog
        ref={editorDialogRef}
        appFlow={appFlow}
        onChange={onChange}
        onClose={handleEditorClose}
      />
      <ListDialog
        ref={selectTemplateActionRef}
        list={AppFlowTemplateList}
        multiple={false}
        title='Select App Flow Template'
        type='select'
        onSubmitSelected={handleTemplateSelected}
      />
    </>
  )
}

DesignDetail.displayName = 'DesignDetail'
DesignDetail.propTypes = propTypes
DesignDetail.defaultProps = defaultProps

export default DesignDetail
