import * as React from "react";
import { memo } from "react";
const SvgProjectBroadcast = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#Project_Broadcast_svg__a)">
      <path d="M17.925 5.858a4.944 4.944 0 0 0-1.14-1.638A5.398 5.398 0 0 0 15.1 3.128a5.322 5.322 0 0 0-2.06-.406H8.028c-.732 0-1.45.14-2.056.406A5.17 5.17 0 0 0 3.148 5.85a4.884 4.884 0 0 0-.426 2.012v4.788c0 .714.148 1.414.422 2.002.264.62.639 1.153 1.139 1.638a5.399 5.399 0 0 0 1.685 1.092c.649.27 1.343.406 2.061.406h10.299V7.87a4.835 4.835 0 0 0-.403-2.012Zm-1.922 2.003v7.644H8.03c-1.644 0-2.982-1.283-2.982-2.856V7.86c0-1.578 1.338-2.857 2.982-2.857h4.969c1.658 0 3.005 1.284 3.005 2.857Z" />
      <path d="M19.717 8.71v10.482H8.826c.583 1.186 1.773 1.997 3.14 1.997h9.224v-9.553c0-1.2-.584-2.263-1.473-2.93v.004Z" />
    </g>
    <defs>
      <clipPath id="Project_Broadcast_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgProjectBroadcast);
export default Memo;
