import { useCallback } from 'react'

import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentAttachment from '../../hooks/useCurrentAttachment'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import {
  updateFile as updateAttachmentFileService,
  update as updateAttachmentService
} from '../../services/attachment'

import AttachmentDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import DownloadsDetail from './detail/DownloadsDetail'
import FileDetail from './detail/FileDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AttachmentsDetail'] })

const AttachmentsDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { attachment, setAttachment, loading } = useCurrentAttachment()

  const handleDetailsUpdate = useCallback((updateReply) => {
    const updatedAttachment = updateReply.model
    setAttachment(updatedAttachment)
    bus.emit('attachmentUpdated', updatedAttachment)
  }, [setAttachment])

  if (loading || !attachment) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<AttachmentDetailHeaderMoreMenu attachment={attachment} />}
        start={<PanelHeaderBackButton />}
        title={attachment.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          object={attachment}
          objectName='attachment'
          service={updateAttachmentService}
          onChange={handleDetailsUpdate}
        />
        <FileDetail attachment={attachment} service={updateAttachmentFileService} onChange={handleDetailsUpdate} />
        <DownloadsDetail attachment={attachment} />
      </PanelContent>
    </Panel>
  )
}

AttachmentsDetail.displayName = 'AttachmentsDetail'
AttachmentsDetail.propTypes = propTypes
AttachmentsDetail.defaultProps = defaultProps

export default AttachmentsDetail
