const propTypes = {}

const defaultProps = {}

const IntegrationListEmptyContent = () => {
  return (<p className='flex flex-col justify-center min-h-[60px] text-center'>(no integrations)</p>)
}

IntegrationListEmptyContent.displayName = 'IntegrationListEmptyContent'
IntegrationListEmptyContent.propTypes = propTypes
IntegrationListEmptyContent.defaultProps = defaultProps

export default IntegrationListEmptyContent
