import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { success } from '../../../components/banners/Banner'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import ListDialog from '../../../components/dialog/ListDialog'
import KeywordList from '../../../components/keywords/KeywordList'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import configurableFormShape from '../../../prop-types/shapes/configurableForm'

const propTypes = {
  configurableForm: PropType.shape(configurableFormShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: true, contacts: ['KeywordDetail'] })

const KeywordDetail = ({ configurableForm, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [configurableForm?._id] })

  const setKeywordDialogRef = useRef()
  const confirmRemoveKeywordDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success(`Keyword ${reply.json.keyword ? 'updated' : 'removed'}`)
    onChange?.(reply)
  }, [onChange])
  const { call: updateCall } = useService(service, { onReplyOk: handleReplyOk })

  const handlePromptForSetKeyword = useCallback(() => { setKeywordDialogRef.current.open() }, [])
  const handlePromptForRemoveKeyword = useCallback(() => { confirmRemoveKeywordDialogRef.current.open() }, [])

  const handleSetKeyword = useCallback((keyword) => {
    updateCall(configurableForm._id, { keyword: keyword._id })
  }, [configurableForm._id, updateCall])

  const handleRemoveKeyword = useCallback(() => {
    updateCall(configurableForm._id, { keyword: null })
  }, [configurableForm._id, updateCall])

  return (
    <>
      <DetailSection
        description='The below keyword will be applied to your contact after this form has been submitted.'
        initialEditButtonText='Set Keyword'
        showClearIcon={!!configurableForm.keyword}
        showEditIcon={!!configurableForm.keyword}
        showInitialEditButton={!configurableForm.keyword}
        title='Apply Keyword to Contact on Form Submission'
        onClearClick={handlePromptForRemoveKeyword}
        onEditClick={handlePromptForSetKeyword}
        onInitialEditClick={handlePromptForSetKeyword}
      >
        {configurableForm.keyword?.word}
      </DetailSection>
      <ListDialog
        ref={setKeywordDialogRef}
        list={KeywordList}
        multiple={false}
        title='Set Keyword'
        type='select'
        search
        onSubmitSelected={handleSetKeyword}
      />
      <ConfirmDialog
        ref={confirmRemoveKeywordDialogRef}
        description='Are you sure you want to remove the keyword from this configurable form?'
        title='Remove Keyword?'
        onConfirm={handleRemoveKeyword}
      />
    </>
  )
}

KeywordDetail.displayName = 'KeywordDetail'
KeywordDetail.propTypes = propTypes
KeywordDetail.defaultProps = defaultProps

export default KeywordDetail
