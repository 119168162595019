import { useCallback, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import poller from '../lib/poller'
import useStore from '../store'
import { updateIsBroadcastingPaused } from '../store/actions/isBroadcastingPaused'
import { updateUnreadChats, updateUnreadSpaces } from '../store/actions/unread'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Poller'] })

const Poller = () => {
  useLogger({ log, lifecycle: true, tags: [] })

  const { currentUserId } = useStore(useShallow((state) => ({ currentUserId: state.currentUser?._id })))
  const appIsActive = useStore((state) => state.appIsActive)

  // start: handling broadcasting updates
  const handlePollerBroadcastingUpdate = useCallback((isBroadcastingPaused) => {
    updateIsBroadcastingPaused(isBroadcastingPaused)
  }, [])

  useEffect(() => {
    poller.on('broadcastingUpdate', handlePollerBroadcastingUpdate)
    return () => { poller.off(handlePollerBroadcastingUpdate) }
  }, [handlePollerBroadcastingUpdate])
  // end: handling broadcasting updates

  // start: handling unreadChats
  const handlePollerChatsUpdate = useCallback((updates) => {
    updateUnreadChats(updates?.unreadCount)
  }, [])

  useEffect(() => {
    poller.on('chatsUpdate', handlePollerChatsUpdate)
    return () => { poller.off(handlePollerChatsUpdate) }
  })
  // end: handling unreadChats

  // start: handling unreadSpaces
  const handlePollerSpacesUpdate = useCallback((updates) => {
    updateUnreadSpaces(updates?.unreadCount)
  }, [])

  useEffect(() => {
    poller.on('spacesUpdate', handlePollerSpacesUpdate)
    return () => { poller.off(handlePollerSpacesUpdate) }
  })
  // end: handling unreadSpaces

  // start: poller start/stopping based on app state/current user id
  const handlePause = useCallback(() => {
    log.debug('pausing poller')
    poller.pause()
  }, [])

  const handleResume = useCallback(() => {
    if (!currentUserId) {
      log.debug('no currentUser ... ensuring poller is stopped')
      poller.stop()
    } else {
      log.debug('resuming poller')
      poller.start()
    }
  }, [currentUserId])

  useEffect(() => {
    if (appIsActive) { handleResume() }
    // pause on any changes
    return () => { handlePause() }
  }, [appIsActive, handlePause, handleResume])
  // end: poller start/stopping based on app state/current user id

  return null
}

Poller.displayName = 'Poller'
Poller.propTypes = propTypes
Poller.defaultProps = defaultProps

export default Poller
