import { map } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import ContactList from '../../components/contacts/ContactList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentContest from '../../hooks/useCurrentContest'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/contest-contact'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContestContactsList'] })

const ContestContactsList = () => {
  const { contestId, loading, contest, setContest } = useCurrentContest()
  useLogger({ log, lifecycle: false, tags: [contestId] })

  const contestContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Contacts updated')
    contestContactListRef.current.reload()
    const updatedContest = reply.model
    setContest(updatedContest)
    bus.emit('contestUpdated', updatedContest)
  }, [setContest])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ contestId, ...query })
  }, [addAllInQueryCall, contestId])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ contestId, contactIds: map(selected, '_id') })
  }, [addSelectedCall, contestId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ contestId, ...query })
  }, [contestId, removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ contestId, contactIds: map(selected, '_id') })
  }, [contestId, removeSelectedCall])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !contest) { return <PanelLoading /> }

  const end = (
    <>
      {contest.contacts.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={contest.name}
        title='Participants'
      />
      <PanelContent>
        <ContactList
          ref={contestContactListRef}
          placeholder='Search Participants'
          queryOverrides={{ contestId }}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={ContactList}
        queryOverrides={{ contestId }}
        subtitle={contest.name}
        title='Remove Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        contestId={contestId}
        list={ContactList}
        subtitle={contest.name}
        title='Add Contact(s)'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

ContestContactsList.displayName = 'ContestContactsList'
ContestContactsList.propTypes = propTypes
ContestContactsList.defaultProps = defaultProps

export default ContestContactsList
