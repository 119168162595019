import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmptyListContent'] })

const EmptyListContent = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='m-5'>
      <p className='mb-5'>
        Multi-Factor Authentication (MFA) is used to help keep your account secure.
      </p>
      <p>
        Add a calling or texting number to enable MFA.
      </p>
    </div>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
