import classNames from 'classnames'
import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import { useSideMenu } from '../../../contexts/SideMenuContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ChevronDown, ChevronUp } from '../../../svg/icons'

import IconLabelChevron from './IconLabelChevron'
import SubMenu from './SubMenu'

const propTypes = {
  icon: PropType.element.isRequired,
  label: PropType.string.isRequired,
  subConfig: PropType.arrayOf(PropType.shape({
    label: PropType.string,
    path: PropType.string,
    stack: PropType.string
  })).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MenuItemWithSub'] })

const MenuItemWithSub = ({ icon, label, subConfig }) => {
  useLogger({ log, lifecycle: false, tags: [label] })

  const { expanded } = useSideMenu()
  const [open, setOpen] = useState(false)

  const chevron = open ? (<ChevronUp height='20' width='20' />) : (<ChevronDown height='20' width='20' />)

  // NOTE: we're assuming the root path will match for sections
  const hasMatch = !!subConfig.find(({ path }) => {
    return window.location.pathname.startsWith(path)
  })

  const className = hasMatch ? 'cursor-pointer text-primary' : 'cursor-pointer text-black'

  const handleOpenEvent = useCallback((event) => {
    if (event.type === 'click' || (event.type === 'keyup' && event.key === 'Enter')) {
      event.stopPropagation()
      setOpen(!open)
    }
  }, [open])

  const containerClassName = classNames(
    'flex flex-col grow-0 shrink-0 w-[278px] select-none',
    {
      'active:bg-blue-50 hover:bg-blue-50 focus:bg-blue-50': !open
    }
  )

  return (
    <li className={containerClassName}>
      <div
        className={className}
        tabIndex={0}
        onClick={handleOpenEvent}
        onKeyUp={handleOpenEvent}
      >
        <IconLabelChevron
          chevron={chevron}
          icon={icon}
          label={label}
        />
      </div>
      <SubMenu open={!!(expanded && open)} subConfig={subConfig} />
    </li>
  )
}

MenuItemWithSub.displayName = 'MenuItemWithSub'
MenuItemWithSub.propTypes = propTypes
MenuItemWithSub.defaultProps = defaultProps

export default MenuItemWithSub
