import PropType from 'prop-types'

import scheduledBroadcastFromJSON from '../models/scheduledBroadcast'

import messageFieldsNormalizer from './messageFieldsNormalizer'
import pb from './pb'
import { searchPropTypes, updatePropTypes } from './scheduledBroadcast.prop-types'

export function search (body, background = false) {
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'scheduledBroadcasts#search')
  return pb.setupPost('schedule/jobs/search', {
    background,
    body,
    model: {
      conversion: scheduledBroadcastFromJSON,
      key: 'jobs',
      multiple: true
    }

  })
}

export function get (scheduledBroadcastId, background = false) {
  return pb.setupGet(`schedule/jobs/${scheduledBroadcastId}`, {
    background,
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function createDraft () {
  return pb.setupPost('schedule/jobs/draft', {
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function scheduleDraft (scheduledBroadcastId) {
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/scheduled`, {
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function createDraftForUnsent (scheduledBroadcastId) {
  return pb.setupPost(`schedule/jobs/${scheduledBroadcastId}/draft/unsent`, {
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function update (scheduledBroadcastId, updateData) {
  updateData = messageFieldsNormalizer(updateData)
  PropType.checkPropTypes(updatePropTypes, updateData, 'prop', 'scheduledBroadcasts#update')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}`, {
    body: updateData,
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function eBrake (scheduledBroadcastId) {
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/ebrake`, {
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function del (scheduledBroadcastId) {
  return pb.setupDelete(`schedule/jobs/${scheduledBroadcastId}`)
}
