import { useRef, useState } from 'react'
import { create, enforce, only, test } from 'vest'

import Button from '../../../components/buttons/Button'
import FormDialog from '../../../components/dialog/FormDialog'
import FormPanelDialog from '../../../components/dialog/FormPanelDialog'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const log = logger({ enabled: true, tags: ['Style Guide', 'FormDialogs'] })

function FormDialogs () {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmDialogRef1 = useRef(null)
  const [confirmDialog1Values, setConfirmDialog1Values] = useState({ newItem: '' })

  const confirmDialogRef2 = useRef(null)
  const [confirmDialog2Values, setConfirmDialog2Values] = useState({ newItem: '' })

  const validationSuite = create((data = {}, currentField) => {
    only(currentField)

    test('newItem', 'New Item is required', () => {
      enforce(data.newItem).isNotEmpty()
    })
  })

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title='Form Dialogs' />
      <PanelContent className='mt-4'>
        <p className='mx-4'>Form Dialog</p>
        <div className='p-4 flex flex-row gap-4'>
          <FormDialog
            ref={confirmDialogRef1}
            confirm={{
              title: 'That\'s a long name!',
              description: 'The name you provided is a bit long, are you sure you want to use this value?',
              when: ({ newItem }) => (newItem.length > 5)
            }}
            confirmCancel='Are you sure you want to cancel?'
            defaultValues={confirmDialog1Values}
            description='Please name your new item'
            formControls={[
              {
                name: 'newItem',
                placeholder: 'Item Name'
              }
            ]}
            title='New Item'
            trigger={<Button size='sm'>Primary Trigger</Button>}
            validationSuite={validationSuite}
            onSubmit={(data, event, close) => {
              log.info(data, event)
              setConfirmDialog1Values(data)
              // close || if you have a ref... confirmDialogRef1.current.close()
              close()
            }}
          />
          <Button size='sm' onClick={() => confirmDialogRef1.current.open()}>Trigger By Ref</Button>
        </div>
        <p className='mx-4'>Form Panel Dialog</p>
        <div className='p-4 flex flex-row gap-4'>
          <FormPanelDialog
            ref={confirmDialogRef2}
            confirm={{
              title: 'That\'s a long name!',
              description: 'The name you provided is a bit long, are you sure you want to use this value?',
              when: ({ newItem }) => (newItem.length > 5)
            }}
            confirmCancel='Are you sure you want to cancel?'
            defaultValues={confirmDialog2Values}
            description='Please name your new item'
            formControls={[
              {
                name: 'newItem',
                placeholder: 'Item Name'
              }
            ]}
            title='New Item'
            trigger={<Button size='sm'>Primary Trigger</Button>}
            validationSuite={validationSuite}
            onSubmit={(data, event, close) => {
              log.info(data, event)
              setConfirmDialog2Values(data)
              // close || if you have a ref... confirmDialogRef1.current.close()
              close()
            }}
          />
          <Button size='sm' onClick={() => confirmDialogRef2.current.open()}>Trigger By Ref</Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

export default FormDialogs
