import * as React from "react";
import { memo } from "react";

const SvgMarkColor = (props) => (
  <svg
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.584 13.504H20.89c-4.09 0-7.386 3.314-7.386 7.405v10.68c0 4.1 3.306 7.405 7.386 7.405h18.04V20.91c.04-4.1-3.306-7.405-7.347-7.405Z"
      fill="#fff"
    />
    <path
      d="M38.97 39.004V20.83H28.666c-4.299 0-7.823 3.534-7.823 7.844v10.331H38.97Z"
      fill="#E1E7FF"
    />
    <path
      d="M42.008 20.87s0 .039 0 0v21.18H20.891c.387 3.962 3.743 7.057 7.784 7.057h20.432V28.623c0-4.011-3.127-7.366-7.089-7.754h-.01Z"
      fill="#A6B4FD"
    />
    <path
      d="M41.651 16.669a10.564 10.564 0 0 0-2.323-3.474 10.963 10.963 0 0 0-3.455-2.329 10.558 10.558 0 0 0-4.24-.866H20.882c-1.509 0-2.978.299-4.23.866a10.532 10.532 0 0 0-3.464 2.33c-1.023 1.024-1.757 2.149-2.323 3.463a10.63 10.63 0 0 0-.864 4.25v10.68c0 1.513.298 2.986.864 4.24a10.56 10.56 0 0 0 2.323 3.474 10.964 10.964 0 0 0 3.455 2.33c1.33.576 2.75.865 4.24.865h21.583v-21.57c.04-1.383-.238-2.856-.824-4.26h.01Zm-3.177 4.24v17.598H20.881c-3.802 0-6.89-3.096-6.89-6.908v-10.69c0-3.812 3.088-6.908 6.89-6.908h10.653c3.823 0 6.94 3.096 6.94 6.908Z"
      fill="#384152"
    />
  </svg>
);

const Memo = memo(SvgMarkColor);
export default Memo;
