import * as React from "react";
import { memo } from "react";
const SvgQa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.436 12.633H6.75l.475.596.804.914 1.047 1.266H7.58l-.743-.864-.499-.719-.901-1.193Zm3.63-.804c0 .694-.136 1.28-.406 1.757-.27.475-.634.835-1.093 1.08a3.22 3.22 0 0 1-1.534.366 3.212 3.212 0 0 1-1.541-.368 2.743 2.743 0 0 1-1.09-1.084C3.134 13.103 3 12.519 3 11.829c0-.694.134-1.279.402-1.754.27-.477.633-.837 1.09-1.08a3.212 3.212 0 0 1 1.54-.37 3.2 3.2 0 0 1 1.535.37c.459.243.823.603 1.093 1.08.27.475.405 1.06.405 1.754Zm-1.742 0c0-.374-.05-.688-.15-.944a1.194 1.194 0 0 0-.435-.585 1.2 1.2 0 0 0-.706-.2c-.28 0-.517.066-.71.2-.19.132-.336.327-.435.585-.098.256-.146.57-.146.944 0 .373.048.689.146.947.1.255.244.45.435.584.193.132.43.198.71.198.28 0 .515-.066.706-.198.193-.134.338-.329.435-.584.1-.258.15-.574.15-.947ZM11.376 15.044c-.446 0-.83-.079-1.154-.237a1.809 1.809 0 0 1-.743-.637 1.584 1.584 0 0 1-.258-.88c0-.233.044-.44.134-.618.089-.18.214-.346.374-.496.16-.15.348-.299.563-.445l1.413-.877a.793.793 0 0 0 .29-.277.574.574 0 0 0 .076-.258c0-.1-.037-.188-.11-.265-.073-.077-.187-.115-.341-.113a.52.52 0 0 0-.247.055.39.39 0 0 0-.161.146.441.441 0 0 0-.055.225c0 .12.035.243.104.369a2.7 2.7 0 0 0 .283.402l.405.484 3.02 3.325h-1.68l-2.534-2.68a7.46 7.46 0 0 1-.41-.484c-.151-.193-.281-.41-.39-.651a1.917 1.917 0 0 1-.162-.789 1.695 1.695 0 0 1 .868-1.495c.274-.156.594-.234.959-.234.35 0 .65.072.901.216.254.144.449.336.585.575.138.24.207.504.207.792 0 .29-.072.561-.216.813a2.047 2.047 0 0 1-.612.66l-1.291.914c-.1.069-.186.148-.26.237a.54.54 0 0 0-.106.348c0 .117.028.218.083.301a.528.528 0 0 0 .23.192c.102.044.221.067.357.067.207 0 .414-.05.621-.15.21-.1.4-.242.573-.423a2.122 2.122 0 0 0 .572-1.461h1.328c0 .361-.038.71-.113 1.044a3.098 3.098 0 0 1-.362.92 2.288 2.288 0 0 1-.658.703 14.31 14.31 0 0 1-.25.122l-.25.122c-.249.16-.51.274-.782.34a3.437 3.437 0 0 1-.8.098Z"
    />
    <path
      fill="currentColor"
      d="M16.426 14.947H14.6l2.059-6.236h2.314l2.058 6.236h-1.827l-1.364-4.519h-.049l-1.364 4.519Zm-.34-2.46h3.434v1.266h-3.435v-1.267Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3ZM0 3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgQa);
export default Memo;
