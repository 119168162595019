import useStore from '../'

export function resumeAppActiveState () {
  useStore.setState((state) => {
    state.appIsActive = true
  }, false, 'appActiveState/resume')
}

export function pauseAppActiveState () {
  useStore.setState((state) => {
    state.appIsActive = false
  }, false, 'appActiveState/pause')
}
