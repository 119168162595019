import ListActionLink from '../../../components/links/ListActionLink'
import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DialogsList'] })

const DialogsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Panel>
      <PanelHeader title='Dialogs' />
      <PanelContent>
        <ListActionLink title='Blocking' to='blocking' />
        <ListActionLink title='Confirm' to='confirm' />
        <ListActionLink title='Form' to='form' />
        <ListActionLink title='Panel' to='panel' />
      </PanelContent>
    </Panel>
  )
}

DialogsList.displayName = 'DialogsList'
DialogsList.propTypes = propTypes
DialogsList.defaultProps = defaultProps

export default DialogsList
