import PropType from 'prop-types'
import { forwardRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import UnlayerEditor from '../unlayer/UnlayerEditor'

import DisplayConditionsDialog from './DisplayConditionsDialog'

const propTypes = {
  onReady: PropType.func
}

const defaultProps = {
  onReady: undefined
}

const log = logger({ enabled: true, tags: ['EmailEditor'] })

const EmailEditor = forwardRef(({ onReady }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <UnlayerEditor
      ref={ref}
      DisplayConditionsDialog={DisplayConditionsDialog}
      displayMode='email'
      onReady={onReady}
    />
  )
})

EmailEditor.displayName = 'EmailEditor'
EmailEditor.propTypes = propTypes
EmailEditor.defaultProps = defaultProps

export default EmailEditor
