import * as React from "react";
import { memo } from "react";
const SvgDynamic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.089 23.087c.522 0 1.144-.209 1.8-.884l9.989-10.43C19.61 11 20 10.227 20 9.577c0-1.068-.922-1.828-2.544-1.828H15.11L19.078 1H9.033L4.29 11.356c-.2.43-.289.86-.289 1.264 0 1.362.978 2.49 2.4 2.49h1.767l-2.311 4.7c-.256.516-.3 1.031-.3 1.448 0 1.142.655 1.829 1.533 1.829Zm.267-1.976c-.156 0-.09-.307.033-.577l3.278-7.264H6.4c-.456 0-.722-.27-.722-.65a.98.98 0 0 1 .1-.442l4.289-9.337h5.91l-3.8 6.748h5.279c.377 0 .566.135.566.332 0 .135-.089.294-.266.49l-9.978 10.43c-.178.197-.334.27-.422.27Z"
    />
  </svg>
);
const Memo = memo(SvgDynamic);
export default Memo;
