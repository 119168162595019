import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import { Close, Search } from '../../svg/icons'

const propTypes = {
  className: PropType.string,
  disabled: PropType.bool,
  icon: PropType.oneOfType([
    PropType.oneOf([
      'close',
      'search'
    ]),
    PropType.element
  ]),
  title: PropType.string,
  onClick: PropType.func
}

const defaultProps = {
  className: null,
  disabled: false,
  icon: null,
  onClick: undefined,
  title: null
}

const InputIcon = forwardRef(({ className, disabled, icon, title, onClick }, ref) => {
  if (!icon && !title) { return null }

  if (typeof icon === 'string') {
    ({ icon, title } = iconAndTitleMappings[icon])
  }

  const computedClassName = classNames(
    'inline-block flex-initial p-[6px] flex items-center',
    {
      'cursor-pointer': !disabled && onClick
    },
    className
  )

  return (
    <button
      ref={ref}
      className={computedClassName}
      disabled={disabled}
      onClick={onClick}
    >
      {icon
        ? (
          <span className='inline-block h-[20px] w-[20px]'>
            {icon}
            <span className='sr-only'>{title}</span>
          </span>
          )
        : <span className='inline-block h-[20px] label-md'>{title}</span>}
    </button>
  )
})

InputIcon.displayName = 'InputIcon'
InputIcon.propTypes = propTypes
InputIcon.defaultProps = defaultProps

const iconAndTitleMappings = {
  close: { icon: <Close />, title: 'close' },
  search: { icon: <Search />, title: 'search' }
}

export default InputIcon
