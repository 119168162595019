import { getDynamicFieldsByCategory } from '../lib/dynamicFields'

export const excludeContactPaths = ['doubleOptInLink', 'doubleOptIn']
export const excludeOtherPaths = []
export const excludeUserPaths = ['optOut']
const generateMergeTags = (currentUser) => {
  const dataByCategory = getDynamicFieldsByCategory(currentUser, excludeContactPaths, excludeOtherPaths, excludeUserPaths)

  const contactMergeTags = dataByCategory.Contact.reduce((mergeTags, { code: value, name, path }) => {
    mergeTags[path] = { name, value }
    return mergeTags
  }, {})
  const otherMergeTags = dataByCategory.Other.reduce((mergeTags, { code: value, name, path }) => {
    mergeTags[path] = { name, value }
    return mergeTags
  }, {})
  const userMergeTags = dataByCategory.User.reduce((mergeTags, { code: value, name, path }) => {
    mergeTags[path] = { name, value }
    return mergeTags
  }, {})

  const mergeTags = {
    contact: {
      name: 'Contact',
      mergeTags: contactMergeTags
    },
    user: {
      name: 'User',
      mergeTags: userMergeTags
    },
    other: {
      name: 'Other',
      mergeTags: otherMergeTags
    }
  }
  return mergeTags
}

const generateSocialIconUrls = (folder) => {
  const socialIconVariants = ['circle', 'circle-black', 'circle-white', 'rounded', 'rounded-black', 'squared', 'squared-black']
  return socialIconVariants.reduce((icons, variant) => {
    icons[variant] = `${process.env.REACT_APP_IMAGE_URL || process.env.REACT_APP_URL}/images/landing-page-social-icons/${folder}/${variant}.png`
    return icons
  }, {})
}

export const editorConfigDefaults = (currentUser, displayMode) => {
  return {
    displayMode,
    version: 'latest',
    appearance: {
      theme: 'modern_light'
    },
    mergeTags: generateMergeTags(currentUser),
    mergeTagsConfig: {
      sort: false
    },
    translations: {
      'en-US': {
        'labels.merge_tags': 'Dynamic Fields'
      }
    },
    tools: {
      menu: { enabled: false },
      form: { enabled: false },
      social: {
        properties: {
          icons: {
            value: {
              editor: {
                data: {
                  customIcons: [
                    {
                      name: 'SMS',
                      url: `sms:${currentUser.phoneNumber}`,
                      icons: generateSocialIconUrls('sms')
                    },
                    {
                      name: 'Call',
                      url: `tel:${currentUser.phoneNumber}`,
                      icons: generateSocialIconUrls('phone')
                    }
                  ]
                }
              }
            }
          }
        }
      }
    },
    features: {
      pageAnchors: true,
      smartMergeTags: false,
      stockImages: {
        enabled: true,
        safeSearch: true
      },
      textEditor: {
        spellChecker: true,
        tables: true
      }
    }
  }
}
