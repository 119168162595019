import { useCallback, useRef, useState } from 'react'

import ContactList from '../../components/contacts/ContactList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

import ContactListHeaderMoreMenu from './list/ListHeaderMore'

function ContactsList () {
  const listRef = useRef() // listRef.current.[reload]
  const actionMenuRef = useRef() // (TODO: probably don't need) actionMenuRef.current.[toggle | open | close]

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  const moreButton = <ContactListHeaderMoreMenu ref={actionMenuRef} listRef={listRef} />

  return (
    <Panel>
      <PanelHeader count={total} end={moreButton} title='Contacts' />
      <PanelContent>
        <ContactList
          ref={listRef}
          savedStateKey='contacts'
          type='nav'
          sortable
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

export default ContactsList
