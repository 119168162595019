import { useCallback, useMemo, useRef } from 'react'

import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import PotentialStopMessageBanner from '../../components/message/potential-stop/PotentialStopMessageBanner'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentContact from '../../hooks/useCurrentContact'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateContactService } from '../../services/contact'

import AnniversaryDetail from './detail/AnniversaryDetail'
import BirthdayDetail from './detail/BirthdayDetail'
import CampaignsDetail from './detail/CampaignsDetail'
import CustomFieldsDetail from './detail/CustomFieldsDetail'
import ContactDetailHeaderMoreMenu from './detail/DetailHeaderMore'
import DoubleOptInDetail from './detail/DoubleOptInDetail'
import NotesDetail from './detail/NotesDetail'
import ProfileDetail from './detail/ProfileDetail'
import TagsDetail from './detail/TagsDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ContactsDetail'] })

const ContactsDetail = () => {
  const { contactId, loading, contact, setContact } = useCurrentContact()
  const actionMenuRef = useRef() // (TODO: probably don't need) actionMenuRef.current.[toggle | open | close]

  useLogger({ log, lifecycle: true, tags: [contactId] })

  const moreButton = useMemo(() => {
    return (<ContactDetailHeaderMoreMenu ref={actionMenuRef} contact={contact} />)
  }, [contact])

  const handleDetailsUpdate = useCallback((updateReply) => {
    setContact(updateReply.model)
    bus.emit('contactUpdated', updateReply.model)
  }, [setContact])

  const handleOptOutRelatedInfoUpdate = useCallback((updateReply) => {
    setContact(updateReply.model)
    bus.emit('contactOptOutRelatedInfoUpdated')
  }, [setContact])

  if (loading || !contact) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={moreButton}
        start={<PanelHeaderBackButton />}
        title={contact?.formattedName}
      />
      <PanelContent>
        {contact?.potentialStopMessage
          ? <PotentialStopMessageBanner messageId={contact.potentialStopMessage} onContactUpdate={handleOptOutRelatedInfoUpdate} />
          : null}
        <div className='p-5'>
          <ProfileDetail contact={contact} service={updateContactService} onChange={handleDetailsUpdate} />
          <BirthdayDetail contact={contact} service={updateContactService} onChange={handleDetailsUpdate} />
          <AnniversaryDetail contact={contact} service={updateContactService} onChange={handleDetailsUpdate} />
          <CustomFieldsDetail contact={contact} service={updateContactService} onChange={handleDetailsUpdate} />
          <TagsDetail contact={contact} onChange={handleDetailsUpdate} />
          <NotesDetail contact={contact} service={updateContactService} onChange={handleDetailsUpdate} />
          <CheckboxDetailSection
            checked={contact.doNotMessage}
            description='When checked, messages will not be sent to this contact.'
            name='doNotMessage'
            objectId={contact._id}
            service={updateContactService}
            title='Do Not Message'
            onChange={handleOptOutRelatedInfoUpdate}
          />
          <CheckboxDetailSection
            checked={contact.doNotEmail}
            description='When checked, emails will not be sent to this contact.'
            name='doNotEmail'
            objectId={contact._id}
            service={updateContactService}
            title='Do Not Email'
            onChange={handleOptOutRelatedInfoUpdate}
          />
          <CheckboxDetailSection
            checked={contact.doNotMessageMe}
            description='When checked, messages received from this contact will be ignored and unrecoverable.'
            name='doNotMessageMe'
            objectId={contact._id}
            service={updateContactService}
            title='Do Not Message Me'
            onChange={handleDetailsUpdate}
          />
          <CheckboxDetailSection
            checked={contact.importLock}
            description="When checked, future imports will not change the values of the contact's profile (First Name, Last Name, Email, and Company)."
            name='importLock'
            objectId={contact._id}
            service={updateContactService}
            title='Import Lock'
            onChange={handleDetailsUpdate}
          />
          <CheckboxDetailSection
            checked={contact.hideChat}
            description='When checked, the chat with this contact will be hidden. It will auto unhide if you receive an incoming message from the contact.'
            name='hideChat'
            objectId={contact._id}
            service={updateContactService}
            title='Hide Chat'
            onChange={handleDetailsUpdate}
          />
          <CampaignsDetail contact={contact} onChange={handleDetailsUpdate} />
          <DoubleOptInDetail contact={contact} />
        </div>
      </PanelContent>
    </Panel>
  )
}

ContactsDetail.displayName = 'ContactsDetail'
ContactsDetail.propTypes = propTypes
ContactsDetail.defaultProps = defaultProps

export default ContactsDetail
