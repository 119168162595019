import { useCallback, useRef, useState } from 'react'

import List from '../../../components/list/List'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import logger from '../../../lib/logger'
import { unsentMessages as searchUnsentService } from '../../../services/admin'
import { Reload } from '../../../svg/icons'
import EmptyListContent from '../../unsent-messages/list/EmptyListContent'
import UnsentMessageItemContent from '../../unsent-messages/list/UnsentMessageItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UnsentMessages'] })

const UnsentMessages = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()
  const { userId, loading, user } = useSelectedUser()

  const handleRefreshSelected = useCallback(() => {
    listRef.current.reload()
  }, [])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !user) { return <PanelLoading subtitle=' ' title='Unsent Messages' /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<PanelHeaderButton icon={<Reload />} onClick={handleRefreshSelected} />}
        start={<PanelHeaderBackButton />}
        subtitle={`${user.formattedName}: ${user.carrierName} ${user.formattedPhoneNumber}`}
        title='Unsent Messages'
      />
      <PanelContent>
        <List
          ref={listRef}
          EmptyListContent={EmptyListContent}
          itemSharedContext={{ showAdminContent: true }}
          ListItemContent={UnsentMessageItemContent}
          placeholder='Search Unsent Messages'
          queryOverrides={{ userId }}
          resultsKey='messages'
          searchService={searchUnsentService}
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

UnsentMessages.displayName = 'UnsentMessages'
UnsentMessages.propTypes = propTypes
UnsentMessages.defaultProps = defaultProps

export default UnsentMessages
