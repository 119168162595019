import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import attachmentShape from '../../prop-types/shapes/attachment'
import { Attachment } from '../../svg/types'

const propTypes = {
  item: PropType.shape(attachmentShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['AttachmentListItemContent'] })

const AttachmentListItemContent = ({ item: attachment }) => {
  useLogger({ log, lifecycle: true, tags: [attachment?._id] })

  return (
    <>
      <Attachment className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {attachment.name}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px] line-clamp-1 break-words'>
          {attachment.fileNameAndSize}
        </div>
      </div>
    </>
  )
}

AttachmentListItemContent.displayName = 'AttachmentListItemContent'
AttachmentListItemContent.propTypes = propTypes
AttachmentListItemContent.defaultProps = defaultProps

export default AttachmentListItemContent
