import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import ContactTagList from '../../components/contacts/ContactTagList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import TagList from '../../components/tags/TagList'
import TagListEmptySearchContent from '../../components/tags/TagListEmptySearchResultsContent'
import useCurrentContact from '../../hooks/useCurrentContact'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import {
  addAll as addAllTagsService,
  add as addTagsService,
  removeAll as removeAllTagsService,
  remove as removeTagsService
} from '../../services/contact-tag'

import EmptyContactTagsList from './contacts-tags/EmptyContactTagsList'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ContactsTags'] })

// NOTE: this screen is also used in chats/:contactId/tags
const ContactsTags = () => {
  const { contactId, loading, contact, setContact } = useCurrentContact()

  useLogger({ log, lifecycle: false, tags: [contactId] })

  const contactTagListRef = useRef()
  const removeListDialogRef = useRef()
  const addListDialogRef = useRef()

  const handleReplyOk = useCallback((currentReply) => {
    success('Tags updated')
    contactTagListRef.current.reload()
    setContact(currentReply.model)
    bus.emit('contactUpdated', currentReply.model)
  }, [setContact])
  const { call: addTagsCall } = useService(addTagsService, { onReplyOk: handleReplyOk })
  const { call: addAllTagsCall } = useService(addAllTagsService, { onReplyOk: handleReplyOk })
  const { call: removeTagsCall } = useService(removeTagsService, { onReplyOk: handleReplyOk })
  const { call: removeAllTagsCall } = useService(removeAllTagsService, { onReplyOk: handleReplyOk })

  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])
  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])

  const handleAddSelected = useCallback((selected) => {
    const tags = selected.map((tagSearchResult) => tagSearchResult.name)
    addTagsCall({ contactId, tags })
  }, [addTagsCall, contactId])
  const handleAddAll = useCallback((query) => {
    addAllTagsCall({ contactId, ...query })
  }, [addAllTagsCall, contactId])
  const handleRemoveSelected = useCallback((selected) => {
    const tags = selected.map((tagSearchResult) => tagSearchResult.name)
    removeTagsCall({ contactId, tags })
  }, [contactId, removeTagsCall])
  const handleRemoveAll = useCallback((query) => {
    removeAllTagsCall({ contactId, ...query })
  }, [contactId, removeAllTagsCall])

  const addMissingTag = useCallback((newTagName) => {
    addTagsCall({ contactId, tags: [newTagName] })
    addListDialogRef.current.close()
  }, [addTagsCall, contactId])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  const end = (
    <>
      {contact?.tags?.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  if (loading || !contact) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={contact?.formattedName}
        title='Tags'
      />
      <PanelContent>
        <ContactTagList
          ref={contactTagListRef}
          contactId={contact?._id}
          EmptyListContent={EmptyContactTagsList}
          type='list'
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        contactId={contact?._id}
        list={ContactTagList}
        subtitle={contact?.formattedName}
        title='Remove Tags'
        type='remove'
        search
        onSubmitAllInQuery={handleRemoveAll}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        additionalContext={{ addMissingTag }}
        contactId={contact?._id}
        EmptySearchResultsListContent={TagListEmptySearchContent}
        list={TagList}
        subtitle={contact?.formattedName}
        title='Add Tags'
        type='add'
        search
        onSubmitAllInQuery={handleAddAll}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

ContactsTags.displayName = 'ContactsTags'
ContactsTags.propTypes = propTypes
ContactsTags.defaultProps = defaultProps

export default ContactsTags
