import { useCallback } from 'react'

import useService from './useService'

const useServiceAndAction = (serviceMethod, actionMethod, options) => {
  const originalHandleReply = options?.onReply
  const handleReply = useCallback((reply) => {
    actionMethod(reply)
    originalHandleReply?.(reply)
  }, [actionMethod, originalHandleReply])
  const { call, abort, loading } = useService(serviceMethod, { ...options, onReply: handleReply })

  return { call, abort, loading }
}

export default useServiceAndAction
