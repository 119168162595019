import * as React from "react";
import { memo } from "react";
const SvgPicture = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 2a1 1 0 0 0-1 1v11.085l5.21-6.699a1 1 0 0 1 1.58 0L15.49 16H22V3a1 1 0 0 0-1-1H3Zm11.991 16a.62.62 0 0 0 .02 0H22v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3h12.991Zm-2.036-2L8 9.629 3.045 16h9.91ZM0 17v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v14ZM16 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPicture);
export default Memo;
