import { TextZoom as TextZoomPlugin } from '@capacitor/text-zoom'
import { clamp } from 'lodash'
import { useEffect } from 'react'

import { useApp } from '../../contexts/AppContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import PbNativeUtil from '../../plugins/pb-native-util'
import useStore from '../../store'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['AppEvents', 'TextZoom'] })

const minLevel = 1 // 100%
const maxLevel = 1.35 // 135% ... we need to address spacing/heights if we want to allow above 135%

const TextZoom = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const appIsActive = useStore((state) => state.appIsActive)
  const { isAndroid, isNative } = useApp()

  useEffect(() => {
    if (!isNative) { return }
    if (!appIsActive) { return }
    const checkTextZoom = async () => {
      const { value: currentValue } = await TextZoomPlugin.get()
      const { value: preferredValue } = await TextZoomPlugin.getPreferred()
      let clampedValue = clamp(preferredValue, minLevel, maxLevel)

      if (isAndroid) {
        // On android the user's display zoom setting > 1 can throw us over the 135% in some cases
        //    Adjust max level to accommodate for this (User issue: https://trello.com/c/v6mhotUL)
        const { value: systemDisplayZoom } = await PbNativeUtil.getSystemDisplayZoom()
        log.debug('system display-zoom value', systemDisplayZoom)
        if (systemDisplayZoom > 1) {
          const adjustedMaxLevel = Math.max(minLevel, maxLevel / systemDisplayZoom)
          log.debug('adjusted max text-zoom level', adjustedMaxLevel)
          clampedValue = clamp(preferredValue, minLevel, adjustedMaxLevel)
        }
      }

      log.debug('current text-zoom level', currentValue)
      log.debug('preferred text-zoom level', preferredValue)
      log.debug('clamped text-zoom value', clampedValue)
      if (currentValue !== preferredValue) {
        await TextZoomPlugin.set({ value: clampedValue })
      }
    }
    checkTextZoom()
  }, [appIsActive, isAndroid, isNative])

  return null
}

TextZoom.displayName = 'TextZoom'
TextZoom.propTypes = propTypes
TextZoom.defaultProps = defaultProps

export default TextZoom
