import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import campaignShape from '../../../prop-types/shapes/campaign'
import { bulkDelete as bulkDeleteService } from '../../../services/campaign'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  campaigns: PropType.arrayOf(PropType.shape(campaignShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ campaigns }, ref) => {
  const confirmRef = useRef()
  const navigate = useNavigate()
  const params = useParams()

  const handleReplyOk = useCallback(() => {
    success('Campaigns have been deleted')
    bus.emit('campaignsDeleted', campaigns)
    bus.emit('closeBulkEditDialog')
    const campaignIds = new Set(pluck(campaigns, '_id'))
    if (campaignIds.has(params.campaignId)) { navigate('/campaigns') }
  }, [navigate, campaigns, params.campaignId])
  const { call: bulkDeleteCampaigns } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!campaigns || !campaigns.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [campaigns])

  const handleConfirm = useCallback(() => {
    bulkDeleteCampaigns({ campaignIds: pluck(campaigns, '_id') })
  }, [bulkDeleteCampaigns, campaigns])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
