import * as React from "react";
import { memo } from "react";
const SvgPeopleCouple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.044 1.1c.3-.06.617-.1.956-.1 3.032 0 5.5 2.468 5.5 5.5S19.032 12 16 12c-.34 0-.657-.05-.893-.089l-.025-.004a1 1 0 0 1 .316-1.975c.262.042.434.068.602.068 1.928 0 3.5-1.572 3.5-3.5S17.928 3 16 3c-.182 0-.365.02-.564.06a1 1 0 0 1-.392-1.96ZM8 14c-2.132 0-4.1.664-5.557 1.783C.986 16.903 0 18.52 0 20.38V22a1 1 0 1 0 2 0v-1.62c0-1.11.584-2.183 1.662-3.01C4.74 16.54 6.272 16 8 16c1.727 0 3.259.543 4.338 1.373 1.079.83 1.662 1.903 1.662 3.007V22a1 1 0 1 0 2 0v-1.62c0-1.856-.986-3.473-2.443-4.593C12.101 14.667 10.133 14 8 14Zm9.178.077a1 1 0 1 0-.236 1.986C20.026 16.43 22 18.397 22 20.38V22a1 1 0 1 0 2 0v-1.62c0-3.397-3.187-5.87-6.822-6.303ZM4.5 6.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM8 1a5.5 5.5 0 1 0 0 11A5.5 5.5 0 0 0 8 1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPeopleCouple);
export default Memo;
