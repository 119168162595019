import { Link } from 'react-router-dom'

import { needsTcrInfo, pendingSmsVerification, tcrApplicable, tcrComplete, tcrFailed } from '../../../helpers/user-tcr'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { ChevronRight, Info } from '../../../svg/icons'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MissingTcrRegistration'] })

const MissingTcrRegistration = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()

  if (currentUser.billingAccount || !tcrApplicable(currentUser) || tcrComplete(currentUser)) {
    return null
  }

  const failed = tcrFailed(currentUser)
  const needsInfo = needsTcrInfo(currentUser)
  const needsSmsVerification = pendingSmsVerification(currentUser)
  const title = failed ? 'Registration Failed' : (needsInfo ? 'Registration Required' : (needsSmsVerification ? 'SMS Verification Required' : 'Registration Pending'))
  const description = failed ? 'Please contact support' : (needsInfo ? 'by Mobile Carriers immediately' : (needsSmsVerification ? 'Resend sms verification' : 'Check for updates'))

  return (
    <Link className='block w-[278px]' to='/account/tcr'>
      <div className='bg-indigo-50 flex flex-row flex-nowrap items-center py-2 px-[12px]'>
        <div className='bg-indigo-400 rounded-lg shrink-0 w-[40px] h-[40px] flex flex-col items-center justify-center mr-3'>
          <div className='bg-indigo-50 rounded-full w-[32px] h-[32px] flex flex-col items-center justify-center'>
            <Info className='text-indigo-700' height='20' width='20' />
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='label-sm-strong text-indigo-700 line-clamp-1 break-all shrink-0'>{title}</span>
          <span className='label-xs-medium text-indigo-400 line-clamp-2 break-word' title={description}>{description}</span>
        </div>
        <ChevronRight className='ml-auto text-indigo-700' height='16' width='16' />
      </div>
    </Link>
  )
}

MissingTcrRegistration.displayName = 'MissingTcrRegistration'
MissingTcrRegistration.propTypes = propTypes
MissingTcrRegistration.defaultProps = defaultProps

export default MissingTcrRegistration
