import PropType from 'prop-types'

import BuyCreditsLink from './BuyCreditsLink'
import SubscriptionDetails from './SubscriptionDetails'

const propTypes = {
  currentUser: PropType.shape({
    formattedMessageCredits: PropType.string,
    subscriptionDetails: PropType.shape({
      cancelsAt: PropType.string,
      currentPlanCreditPrice: PropType.number,
      currentPlanEndDate: PropType.string,
      currentPlanFrequency: PropType.string,
      currentPlanName: PropType.string,
      scheduledPlanFrequency: PropType.string,
      scheduledPlanName: PropType.string
    }),
    timezone: PropType.string
  }).isRequired
}

const defaultProps = {}

const PlanDetails = ({ currentUser }) => {
  return (
    <div className='flex flex-col bg-blue-700 text-white rounded-md p-[12px] mt-[10px] mb-[12px]'>
      <div>
        <div>
          <span className='label-lg-strong pr-[0.25em]'>{currentUser.formattedMessageCredits}</span>
          <span className='label-xs-strong'>Credits Remaining</span>
        </div>
        <BuyCreditsLink currentUser={currentUser} />
        <SubscriptionDetails currentUser={currentUser} />
      </div>
    </div>
  )
}

PlanDetails.displayName = 'PlanDetails'
PlanDetails.propTypes = propTypes
PlanDetails.defaultProps = defaultProps

export default PlanDetails
