import * as React from "react";
import { memo } from "react";
const SvgDocument = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 2c-.548 0-1 .452-1 1v18c0 .548.452 1 1 1h14c.548 0 1-.452 1-1V9.414L12.586 2H5ZM2 3c0-1.652 1.348-3 3-3h8a1 1 0 0 1 .707.293l8 8A1 1 0 0 1 22 9v12c0 1.652-1.348 3-3 3H5c-1.652 0-3-1.348-3-3V3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgDocument);
export default Memo;
