import * as React from "react";
import { memo } from "react";
const SvgBookmarkAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 1C4.348 1 3 2.348 3 4v18a1 1 0 0 0 1.447.894L12 19.118l7.553 3.776A1 1 0 0 0 21 22V4c0-1.652-1.348-3-3-3H6ZM5 4c0-.548.452-1 1-1h12c.548 0 1 .452 1 1v16.382l-6.553-3.276a1 1 0 0 0-.894 0L5 20.382V4Zm7 1a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBookmarkAdd);
export default Memo;
