import { useCallback, useEffect, useRef, useState } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import {
  getMfaSettings as getUserMfaSettings,
  updateMfaSettings as updateUserMfaSettings
} from '../../../services/admin'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['MultiFactorAuthentication'] })

const MultiFactorAuthentication = () => {
  const formRef = useRef()
  const { userId, loading, user } = useSelectedUser()
  const [mfaSettings, setMfaSettings] = useState(null)
  useLogger({ log, lifecycle: false, tags: [userId] })

  const { call: getMfaSettings } = useService(getUserMfaSettings, {
    onReplyOk: useCallback((reply) => {
      setMfaSettings(reply.json)
    }, [])
  })

  const { call: updateMfaSettings } = useService(updateUserMfaSettings, {
    onReplyOk: useCallback((reply) => {
      setMfaSettings(reply.json)
      notify('MFA Settings Updated')
    }, [])
  })

  const handleSubmit = useCallback(({ disabled }) => {
    updateMfaSettings({ disabled }, userId)
  }, [updateMfaSettings, userId])

  useEffect(() => {
    if (!mfaSettings) {
      getMfaSettings(userId)
    }
  }, [getMfaSettings, mfaSettings, userId])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }
  if (!mfaSettings) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='mfaForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Multi-Factor Authentication'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <Form
          ref={formRef}
          defaultValues={mfaSettings}
          formControls={[
            {
              type: 'checkbox',
              name: 'disabled',
              label: 'Disable MFA',
              hint: `Disable/enable the user's current MFA setting. MFA enforcement is disabled for the user when checked, otherwise MFA
                     is enforced via the method(s) they currently have setup. If no methods exist they will be prompted to create one.`
            }
          ]}
          id='mfaForm'
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='mfaForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

MultiFactorAuthentication.displayName = 'MultiFactorAuthentication'
MultiFactorAuthentication.propTypes = propTypes
MultiFactorAuthentication.defaultProps = defaultProps

export default MultiFactorAuthentication
