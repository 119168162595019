import useStackBackButton from '../../../hooks/useStackBackButton'

import PanelHeaderButton from './PanelHeaderButton'

const PanelHeaderBackButton = () => {
  const { handleBackButtonClick } = useStackBackButton()
  return <PanelHeaderButton icon='back' onClick={handleBackButtonClick} />
}

PanelHeaderBackButton.displayName = 'PanelHeaderBackButton'

export default PanelHeaderBackButton
