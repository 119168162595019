import { forwardRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { Plus } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['InsertContentButton'] })

const InsertContentButton = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Button
      ref={ref}
      className='!text-black'
      icon={<Plus />}
      size='md'
      theme='neutral'
      variant='filled'
      circle
      {...props}
    >
      Insert Content
    </Button>
  )
})

InsertContentButton.displayName = 'InsertContentButton'
InsertContentButton.propTypes = propTypes
InsertContentButton.defaultProps = defaultProps

export default InsertContentButton
