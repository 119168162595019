import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import logger from '../../../../lib/logger'
import messageQualityShape from '../../../../prop-types/shapes/messageQuality'
import { applyMessageRevision } from '../../../../services/aiva'

import RevisionResultsPanel from './RevisionResultsPanel'
import RevisionSelectPanel from './RevisionSelectPanel'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  messageText: PropType.string.isRequired,
  onClose: PropType.func.isRequired,
  onInsertRevision: PropType.func.isRequired,
  onSelectTab: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['ReviseMessageTab'] })

const ReviseMessageTab = ({ messageQuality, messageText, onClose, onInsertRevision, onSelectTab }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [currentStep, setCurrentStep] = useState('revision-select')
  const [suggestionText, setSuggestionText] = useState()

  const { call: applyMessageRevisionCall } = useService(applyMessageRevision, {
    onReplyOk: (reply) => {
      setSuggestionText(reply.json[0])
      setCurrentStep('revision-results')
    }
  })

  const handleRevisionSelected = useCallback((promptId) => {
    applyMessageRevisionCall(promptId, messageText)
  }, [applyMessageRevisionCall, messageText])

  return (
    <>
      {currentStep === 'revision-select' && (
        <RevisionSelectPanel
          messageQuality={messageQuality}
          messageText={messageText}
          onClose={onClose}
          onPromptSelected={handleRevisionSelected}
          onSelectTab={onSelectTab}
        />
      )}
      {currentStep === 'revision-results' && (
        <RevisionResultsPanel
          suggestionText={suggestionText}
          onBack={() => setCurrentStep('revision-select')}
          onInsertSelected={onInsertRevision}
        />
      )}
    </>
  )
}

ReviseMessageTab.displayName = 'ReviseMessageTab'
ReviseMessageTab.propTypes = propTypes
ReviseMessageTab.defaultProps = defaultProps

export default ReviseMessageTab
