import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import PanelErrorBanner from '../../panels/PanelErrorBanner'

import PotentialStopMessageReviewDialog from './PotentialStopMessageReviewDialog'

const propTypes = {
  messageId: PropType.string.isRequired,
  onContactUpdate: PropType.func,
  onResolved: PropType.func
}

const defaultProps = {
  onContactUpdate: null,
  onResolved: null
}

const log = logger({ enabled: false, tags: ['PotentialStopMessageBanner'] })

const PotentialStopMessageBanner = ({ messageId, onContactUpdate, onResolved }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const reviewDialogRef = useRef()
  const { aivaSettings } = useCurrentUser()

  const handleViewMessage = useCallback(() => {
    reviewDialogRef.current.open()
  }, [])

  return aivaSettings.stopDetect !== 'off'
    ? (
      <>
        <PanelErrorBanner
          message='Potential opt-out detected'
          resolutionOnClick={handleViewMessage}
          resolutionTitle='View message'
        />
        <PotentialStopMessageReviewDialog
          ref={reviewDialogRef}
          messageId={messageId}
          onContactUpdate={onContactUpdate}
          onResolved={onResolved}
        />
      </>
      )
    : null
}

PotentialStopMessageBanner.displayName = 'PotentialStopMessageBanner'
PotentialStopMessageBanner.propTypes = propTypes
PotentialStopMessageBanner.defaultProps = defaultProps

export default PotentialStopMessageBanner
