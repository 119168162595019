import { get as getContactService } from '../services/contact'

import useCurrentInstance from './useCurrentInstance'

const useCurrentContact = () => useCurrentInstance({
  resourceName: 'contact',
  getService: getContactService,
  replyAttrPath: 'model',
  notFoundRedirect: '/contacts'
})

export default useCurrentContact
