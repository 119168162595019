import { useCallback, useRef, useState } from 'react'

import EmailList from '../../components/emails/EmailList'
import FolderEmptyListContent from '../../components/folders/FolderEmptyListContent'
import FolderList from '../../components/folders/FolderList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import { useAdditionalContentHandlers } from '../../hooks/useAdditionalContentHandlers'
import useCurrentListFolderMode from '../../hooks/useCurrentListFolderMode'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'

import EmailEmptyListContent from './list/EmptyListContent'
import EmailListHeaderMoreMenu from './list/ListHeaderMoreContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmailsList'] })

const EmailsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()

  const currentListFolderMode = useCurrentListFolderMode()
  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  const List = currentListFolderMode ? FolderList : EmailList
  const EmptyListContent = currentListFolderMode ? FolderEmptyListContent : EmailEmptyListContent
  const queryOverrides = currentListFolderMode ? { contentType: CONTENT_TYPE.email } : null
  const savedStateKey = currentListFolderMode ? 'emailsFolders' : 'emails'

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<EmailListHeaderMoreMenu currentListFolderMode={currentListFolderMode} listRef={listRef} />}
        title='Emails'
      />
      <PanelContent>
        <List
          ref={listRef}
          additionalHandlers={useAdditionalContentHandlers}
          EmptyListContent={EmptyListContent}
          queryOverrides={queryOverrides}
          savedStateKey={savedStateKey}
          type='nav'
          showFolderToggle
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

EmailsList.displayName = 'EmailsList'
EmailsList.propTypes = propTypes
EmailsList.defaultProps = defaultProps

export default EmailsList
