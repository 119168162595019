import PropType from 'prop-types'

const socialBase = {
  cellPhoneNumber: PropType.string.isRequired,
  email: PropType.string.isRequired,
  firstName: PropType.string.isRequired,
  lastName: PropType.string.isRequired,
  password: PropType.string.isRequired,
  plan: PropType.oneOf(['free']).isRequired
}

const socialSignUpPreFlight = PropType.shape(socialBase)

const socialSignUp = PropType.shape({
  ...socialBase,
  verificationCode: PropType.string.isRequired
})

const fullBase = {
  email: PropType.string.isRequired,
  firstName: PropType.string.isRequired,
  lastName: PropType.string.isRequired,
  password: PropType.string.isRequired,
  plan: PropType.string.isRequired,
  stateProvince: PropType.string.isRequired,
  timezone: PropType.string.isRequired,
  referralCode: PropType.string
}

const fullSignUpPreFlight = PropType.shape(fullBase)

const fullSignUp = PropType.shape({
  ...fullBase,
  captcha: PropType.string.isRequired,
  cellPhoneNumber: PropType.string.isRequired,
  phoneNumber: PropType.string.isRequired,
  stripeResult: PropType.shape({
    id: PropType.string.isRequired,
    card: PropType.shape({
      brand: PropType.string.isRequired,
      exp_month: PropType.number.isRequired,
      exp_year: PropType.number.isRequired,
      id: PropType.string.isRequired,
      last4: PropType.string.isRequired
    })
  }).isRequired
})

export const signUpPreFlightPropTypes = PropType.oneOfType([
  socialSignUpPreFlight,
  fullSignUpPreFlight
]).isRequired

export const signUpPropTypes = PropType.oneOfType([
  socialSignUp,
  fullSignUp
]).isRequired
