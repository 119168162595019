import { forwardRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { CalendarAdd } from '../../../../svg/icons'
import Button from '../../../buttons/Button'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduleButton'] })

const ScheduleButton = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <Button
      ref={ref}
      className='min-w-[32px]'
      icon={<CalendarAdd />}
      size='sm'
      theme='none'
      variant='none'
      {...props}
    >
      Schedule Send
    </Button>
  )
})

ScheduleButton.displayName = 'ScheduleButton'
ScheduleButton.propTypes = propTypes
ScheduleButton.defaultProps = defaultProps

export default ScheduleButton
