import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Block, Clock } from '../../svg/icons'
import { Email, LandingPage } from '../../svg/types'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape()
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['UnlayerTemplateListItemContent'] })

const UnlayerTemplateListItemContent = ({ item: unlayerTemplate }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const Icon = unlayerTemplate.type === 'email' ? Email : LandingPage

  return (
    <>
      <Icon className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {unlayerTemplate.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-neutral-500 shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            modified
            {' '}
            <FormattedDate date={unlayerTemplate.updatedAt} format='extendedDate2' />
          </div>
        </div>
        {!unlayerTemplate.enabled
          ? (
            <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
              <Block className='text-black-neutral-500 shrink-0 grow-0 h-[1rem] w-[1rem]' />
              <div className='line-clamp-1 break-words'>Disabled</div>
            </div>
            )
          : null}
      </div>
    </>
  )
}

UnlayerTemplateListItemContent.displayName = 'UnlayerTemplateListItemContent'
UnlayerTemplateListItemContent.propTypes = propTypes
UnlayerTemplateListItemContent.defaultProps = defaultProps

export default UnlayerTemplateListItemContent
