import * as React from "react";
import { memo } from "react";
const SvgAppFlow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width={24} height={24} fill="#EAD6E6" rx={4} />
    <path
      fill="#973080"
      fillRule="evenodd"
      d="M5.25 5.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM7.5 8.155a3.001 3.001 0 0 0-.75-5.905A3 3 0 0 0 6 8.155v6.19a3.001 3.001 0 0 0 .75 5.905 3 3 0 0 0 .84-5.881 2.25 2.25 0 0 1 2.16-1.619h3A3.75 3.75 0 0 0 16.5 9v-.845a3.001 3.001 0 0 0-.75-5.905A3 3 0 0 0 15 8.155V9a2.25 2.25 0 0 1-2.25 2.25h-3c-.844 0-1.623.279-2.25.75V8.154Zm8.25-1.405a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-10.5 10.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAppFlow);
export default Memo;
