import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'

import { tcrStatus } from '../../helpers/user-tcr'
import useLogger from '../../hooks/useLogger'
import { formatTimeDistance } from '../../lib/formatters'
import logger from '../../lib/logger'
import userShape from '../../prop-types/shapes/user'
import { MarkColor } from '../../svg/branding'
import Avatar from '../avatar/Avatar'
import Button from '../buttons/Button'
import ManageAccountAction from '../managed-accounts/actions/ManageAction'
import Pluralize from '../Pluralize'

const propTypes = {
  item: PropType.shape(userShape),
  itemSharedContext: PropType.shape({
    hideActions: PropType.bool
  })
}

const defaultProps = {
  item: null,
  itemSharedContext: {
    hideActions: true
  }
}

const log = logger({ enabled: false, tags: ['ListItemContent'] })

const ListItemContent = ({ item: user, itemSharedContext: { hideActions } }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const manageAccountActionRef = useRef()

  const initiateManageAccount = useCallback(() => {
    manageAccountActionRef.current.activate()
  }, [])

  const extraInfo = useMemo(() => {
    const status = tcrStatus(user)
    const pendingDate = (status === 'registering' && user.tcr?.campaign?.sharingStatusPendingDate)

    return `${user.carrierName}
    ${(user.isTwilio && user.twilio.accountSid) || ''}
    ${user.registration?.brand?.entityType || ''}
    ${status}
    ${pendingDate ? formatTimeDistance(pendingDate) : ''}
    ${user.tcr?.brand?.brandId || ''}
    ${user.tcr?.campaign?.campaignId || ''}`
  }, [user])

  return (
    <>
      <Avatar contactOrUser={user} size='sm' />
      <div className='flex flex-col flex-grow ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
          {user.isPBUser ? <MarkColor height='16' width='16' /> : null}
          {user.formattedName}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {user.formattedPhoneNumber}
        </div>
        <div className='line-clamp-1 break-words label-xs text-neutral-500 mt-[3px]'>
          {user.email}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          <Pluralize count={user.messageCredits} singular='credit' includeCount />
          {' on '}
          {user.subscriptionPlan}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {extraInfo}
        </div>
      </div>
      {!hideActions && (
        <>
          <div className='self-center'>
            <Button
              size='md'
              variant='text'
              onClick={initiateManageAccount}
            >
              Manage
            </Button>
          </div>
          <ManageAccountAction ref={manageAccountActionRef} userId={user._id} />
        </>
      )}
    </>
  )
}

ListItemContent.displayName = 'ListItemContent'
ListItemContent.propTypes = propTypes
ListItemContent.defaultProps = defaultProps

export default ListItemContent
