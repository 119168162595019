import { useParams } from 'react-router-dom'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import SpaceScheduledThread from '../../components/spaces/SpaceScheduledThread'
import useCurrentSpace from '../../hooks/useCurrentSpace'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpacesScheduledThread'] })

const SpacesScheduledThread = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { spaceId } = useParams()
  const { space, loading } = useCurrentSpace()

  if (!space || loading) { return <PanelLoading hideMenuToggleButton /> }

  return (
    <Panel hideMenuToggleButton>
      <PanelHeader start={<PanelHeaderBackButton />} subtitle={space.name} title='Scheduled Messages' />
      <PanelContent>
        <SpaceScheduledThread spaceId={spaceId} />
      </PanelContent>
    </Panel>
  )
}

SpacesScheduledThread.displayName = 'SpacesScheduledThread'
SpacesScheduledThread.propTypes = propTypes
SpacesScheduledThread.defaultProps = defaultProps

export default SpacesScheduledThread
