import { pick } from 'lodash'
import PropType from 'prop-types'
import { create, enforce, only, test } from 'vest'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import tagShape from '../../../prop-types/shapes/tag'

const propTypes = {
  service: PropType.func.isRequired,
  tag: PropType.shape(tagShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['NameDetail'] })

const NameDetail = ({ tag, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [tag?.name] })

  const defaultValues = pick(tag, 'name')
  const formControls = [
    { autoComplete: 'off', label: 'Name', name: 'name', type: 'text' }
  ]

  const validationSuite = create((data = {}, currentField) => {
    only(currentField)

    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty()
    })
  })

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Tags are for grouping multiple contacts together. The name you use will be the label you see throughout Project Broadcast.'
      formControls={formControls}
      formDescription='Updating tag name'
      formTitle='Tag Name'
      objectId={tag.name}
      service={service}
      title='Name'
      validationSuite={validationSuite}
      showEditIcon
      onChange={onChange}
    >
      {tag.name}
    </FormDetailSection>
  )
}

NameDetail.displayName = 'NameDetail'
NameDetail.propTypes = propTypes
NameDetail.defaultProps = defaultProps

export default NameDetail
