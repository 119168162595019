import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createAttachmentService } from '../../../services/attachment'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormDialog from '../../dialog/FormDialog'

import validationSuite from './CreateAction.validations'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    const attachment = reply.model
    setCurrentInstance('attachment', attachment)
    bus.emit('attachmentCreated', attachment)
    if (!createdFromFolder) { navigate(`/attachments/${attachment._id}`) }
    onSuccess?.(attachment)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createAttachment } = useService(createAttachmentService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const createAttachmentWithName = useCallback(({ attachmentName }) => {
    dialogRef.current.close()
    createAttachment(attachmentName)
  }, [createAttachment])

  return (
    <FormDialog
      ref={dialogRef}
      defaultValues={{ attachmentName: '' }}
      description='Please name your new attachment'
      formControls={[
        {
          name: 'attachmentName',
          placeholder: 'Attachment Name'
        }
      ]}
      title='New Attachment'
      validationSuite={validationSuite}
      onSubmit={createAttachmentWithName}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
