import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

import AppStateChange from './app-events/AppStateChange'
import AppUrlOpen from './app-events/AppUrlOpen'
import BackButton from './app-events/BackButton'
import Keyboard from './app-events/Keyboard'
import SharedImage from './app-events/SharedImage'
import TextZoom from './app-events/TextZoom'
import Unauthorized from './app-events/Unauthorized'

const log = logger({ enabled: true, tags: ['AppEvents'] })

function AppEvents () {
  useLogger({ log, lifecycle: true })

  return (
    <>
      <Unauthorized />
      <AppUrlOpen />
      <AppStateChange />
      <BackButton />
      <Keyboard />
      <SharedImage />
      <TextZoom />
    </>
  )
}

export default AppEvents
