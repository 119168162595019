import { get as getSpaceService } from '../services/space'

import useCurrentInstance from './useCurrentInstance'

const useCurrentSpace = () => useCurrentInstance({
  resourceName: 'space',
  getService: getSpaceService,
  replyAttrPath: 'model',
  notFoundRedirect: '/spaces'
})

export default useCurrentSpace
