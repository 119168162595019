import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import useMessageDraft from '../../../hooks/useMessageDraft'
import useSegmentedMessage from '../../../hooks/useSegmentedMessage'
import logger from '../../../lib/logger'
import { error as notifyError } from '../../banners/Banner'

import CharacterCounter from './message/CharacterCounter'
import MessageTextInput from './message/MessageTextInput'
import SendButton from './message/SendButton'

const propTypes = {
  name: PropType.string.isRequired,
  className: PropType.string,
  disabled: PropType.bool,
  draftKey: PropType.string,
  maxCharacters: PropType.number,
  placeholder: PropType.string,
  onSend: PropType.func
}

const defaultProps = {
  className: null,
  disabled: false,
  draftKey: null,
  maxCharacters: 1024,
  onSend: undefined,
  placeholder: null
}

const log = logger({ enabled: true, tags: ['AivaMessage'] })

const AivaMessage = forwardRef(({
  draftKey,
  name,
  className,
  disabled,
  maxCharacters,
  placeholder,
  onSend
}, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [] })

  const textareaRef = useRef()
  const [message, setMessage] = useState({ text: '', type: 'sms', media: [], upload: null })
  const { characterCount, segmentsCount, updateSegmentedMessage } = useSegmentedMessage(message)
  const { saveDraft } = useMessageDraft({ draftKey, onRestoreDraft: setMessage })

  const setMessageAndSavePolitely = useCallback((message) => {
    setMessage(message)
    saveDraft(message)
  }, [saveDraft])

  const validateMessage = useCallback(async ({ characterCount, message }) => {
    let errorMessage = null

    const text = (message.text || '').trim()
    if (!text) {
      errorMessage = 'Message is required.'
    }

    if (maxCharacters && characterCount > maxCharacters) {
      errorMessage = `Message text cannot exceed ${maxCharacters} characters.`
    }

    return errorMessage
  }, [maxCharacters])

  useImperativeHandle(ref, () => ({
    get message () { return message },

    async hasError () {
      return await validateMessage({ characterCount, message })
    },

    reset (messageOverrideValue = null) {
      const resetMessage = {
        text: '',
        type: 'sms',
        media: [],
        upload: null,
        call: null,
        ...messageOverrideValue
      }
      updateSegmentedMessage(resetMessage)
      setMessageAndSavePolitely(resetMessage)
    }
  }), [characterCount, message, setMessageAndSavePolitely, updateSegmentedMessage, validateMessage])

  useEffect(() => {
    validateMessage({ characterCount, message })
  }, [characterCount, message, name, validateMessage])

  const handleTextareaChange = useCallback(() => {
    const updatedMessage = { ...message, text: textareaRef.current.value }
    updateSegmentedMessage(updatedMessage)
    setMessageAndSavePolitely(updatedMessage)
  }, [message, setMessageAndSavePolitely, updateSegmentedMessage])

  const handleSendClick = useCallback(async () => {
    if (!onSend) { return }
    const errors = await ref.current.hasError()
    if (errors) { return notifyError(errors) }
    const data = { message: { ...message } }
    onSend?.(data)
  }, [message, onSend, ref])

  const computedClassName = classNames(
    'flex flex-col w-full rounded-md',
    className
  )

  return (
    <div className={computedClassName}>
      <MessageTextInput
        ref={textareaRef}
        className='border border-none w-full h-full bg-white pr-6 h-36 min-w-72 resize-none'
        maxRows={8}
        messageType={message.type}
        minRows={3}
        placeholder={placeholder}
        value={message.text}
        onChange={handleTextareaChange}
      />
      <div className='flex flex-row-reverse flex-nowrap gap-x-4 items-center text-black p-2'>
        <SendButton disabled={disabled} onClick={handleSendClick} />
        <CharacterCounter
          count={characterCount}
          max={maxCharacters}
          messageType={message.type}
          segmentsCount={segmentsCount}
          showSegmentsCount={false}
        />
      </div>
    </div>
  )
})

AivaMessage.displayName = 'AivaMessage'
AivaMessage.propTypes = propTypes
AivaMessage.defaultProps = defaultProps

export default AivaMessage
