import PropType from 'prop-types'

import landingPageFromJSON from '../models/landingPage'

import { searchPropTypes } from './landingPage.prop-types'
import pb from './pb'

export function get (landingPageId, background = false) {
  const options = {
    background,
    model: {
      conversion: landingPageFromJSON
    }
  }
  return pb.setupGet(`landingPages/${landingPageId}`, options)
}

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: landingPageFromJSON,
      key: 'landingPages',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'landingPages#search')
  return pb.setupPost('landingPages/search', options)
}

export function create (body) {
  const options = {
    body,
    model: {
      conversion: landingPageFromJSON
    }
  }
  return pb.setupPost('landingPages', options)
}

export function update (landingPageId, body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: landingPageFromJSON
    }
  }
  return pb.setupPut(`landingPages/${landingPageId}`, options)
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('landingPages/bulk/delete', { body, background })
}

export function del (landingPageId) {
  return pb.setupDelete(`landingPages/${landingPageId}`)
}

export function clone (landingPageId) {
  const options = {
    model: {
      conversion: landingPageFromJSON
    }
  }
  return pb.setupPost(`landingPages/${landingPageId}/clone`, options)
}

export function updateSocialSharingImage (landingPageId, body) {
  const options = {
    body,
    model: {
      conversion: landingPageFromJSON
    }
  }
  return pb.setupPut(`landingPages/${landingPageId}/updateSocialSharingImage`, options)
}

export function viewsSearch (landingPageId, body, background = false) {
  const options = { body, background }
  return pb.setupPost(`landingPages/${landingPageId}/landingPageViews/search`, options)
}
