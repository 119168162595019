export function trackPurchase ({ selectedPlan, eventId }) {
  window.fbq('track', 'Purchase', {
    currency: 'USD',
    value: selectedPlan.value
  }, { eventID: eventId })
  window.gtag('event', 'purchase', {
    currency: 'USD',
    value: selectedPlan.value
  })
  window.gtag('event', 'conversion', {
    send_to: 'AW-10840714179/3C_JCL6w_7wDEMPXoLEo',
    value: selectedPlan.value,
    currency: 'USD',
    transaction_id: ''
  })
}
