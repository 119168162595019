import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { warning } from '../banners/Banner'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import PanelDialog from './PanelDialog'

const propTypes = {
  list: PropType.elementType.isRequired,
  onSubmitSelected: PropType.func.isRequired,
  multiple: PropType.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropType.array,
  subtitle: PropType.string,
  title: PropType.string,
  trigger: PropType.element,
  type: PropType.oneOf(['add', 'list', 'move', 'nav', 'remove', 'select']),
  onSubmitAllInQuery: PropType.func
}

const defaultProps = {
  multiple: true,
  onSubmitAllInQuery: undefined,
  selectedItems: [],
  subtitle: null,
  title: 'Select',
  trigger: null,
  type: 'list'
}

const log = logger({ enabled: false, tags: ['ListDialog'] })

const ListDialog = forwardRef(({
  list: PassedInList,
  multiple,
  selectedItems: initialSelectedItems,
  subtitle,
  title,
  trigger,
  type,
  onSubmitSelected,
  onSubmitAllInQuery,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  const listRef = useRef()

  useLogger({ log, lifecycle: false, tags: [] })

  const triggerListSubmit = useCallback(() => listRef.current.submitSelected(), [])
  const handleCancel = useCallback(() => ref.current.close(), [ref])

  const handleListSubmitSelected = useCallback((selectedItems) => {
    if (!selectedItems || !selectedItems.length) {
      warning('No selection made')
    } else {
      ref.current.close()
      onSubmitSelected(multiple ? selectedItems : selectedItems[0])
    }
  }, [multiple, onSubmitSelected, ref])
  const handleListSubmitAllInQuery = useCallback((query, count) => {
    ref.current.close()
    onSubmitAllInQuery?.(query, count)
  }, [onSubmitAllInQuery, ref])

  return (
    <PanelDialog
      ref={ref}
      ariaLabel={title}
      trigger={trigger}
    >
      <PanelHeader
        end={<PanelHeaderButton icon='save' onClick={triggerListSubmit} />}
        start={<PanelHeaderButton icon='cancel' onClick={handleCancel} />}
        subtitle={subtitle}
        title={title}
      />
      <PanelContent>
        <PassedInList
          ref={listRef}
          multiple={multiple}
          selectedItems={initialSelectedItems}
          type={type}
          onSubmitAllInQuery={handleListSubmitAllInQuery}
          onSubmitSelected={handleListSubmitSelected}
          {...rest}
        />
      </PanelContent>
    </PanelDialog>
  )
})

ListDialog.displayName = 'ListDialog'
ListDialog.propTypes = propTypes
ListDialog.defaultProps = defaultProps

export default ListDialog
