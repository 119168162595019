import { create, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('scheduledAtFrom', 'From is required', () => {
    if (data.scheduledAtType === 'range' && !data.scheduledAtFrom) {
      return false
    }
  })

  test('scheduledAtTo', 'To is required', () => {
    if (data.scheduledAtType === 'range' && !data.scheduledAtTo) {
      return false
    }
  })

  test('scheduledAtTo', 'To must be ahead of From', () => {
    if (data.scheduledAtType === 'range' && data.scheduledAtFrom && data.scheduledAtTo) {
      if (data.scheduledAtFrom > data.scheduledAtTo) {
        return false
      }
    }
  })
})

export default validationSuite
