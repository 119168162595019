import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../../components/banners/Banner'
import CampaignList from '../../../components/campaigns/CampaignList'
import DetailSection from '../../../components/detail-sections/DetailSection'
import ListDialog from '../../../components/dialog/ListDialog'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'
import { addOne as addOneCampaignService } from '../../../services/contact-campaign'
import EventDateDialog from '../../campaigns/detail/EventDateDialog'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined
}

const log = logger({ enabled: true, tags: ['CampaignsDetail'] })

const CampaignsDetail = ({ contact, onChange }) => {
  const [selectedCampaign, setSelectedCampaign] = useState()

  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const listDialogRef = useRef()
  const eventDateDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Campaign added')
    onChange?.(reply)
  }, [onChange])
  const { call: addOneCampaign } = useService(addOneCampaignService, { onReplyOk: handleReplyOk })

  const handleInitialEdit = useCallback(() => { listDialogRef.current.open() }, [])

  const handleInitialCampaignAdd = useCallback((selected) => {
    if (selected.type === 'event') {
      setSelectedCampaign(selected)
      eventDateDialogRef.current.open()
    } else {
      const campaignId = selected._id
      addOneCampaign({ contactId: contact._id, campaignId })
    }
  }, [contact, addOneCampaign, setSelectedCampaign])

  const handleContinueAdd = useCallback((data) => {
    const campaignId = selectedCampaign._id
    const eventDate = data.eventDate
    eventDateDialogRef.current.close()
    addOneCampaign({ contactId: contact._id, campaignId, eventDate })
  }, [contact, addOneCampaign, selectedCampaign])

  const value = contact?.campaigns?.subset.map((campaign) => campaign.name).join(', ')
  const count = contact?.campaigns?.length || 0
  const title = `Campaigns ${count > 0 ? `(${count})` : ''}`

  return (
    <>
      <DetailSection
        description='The campaigns this contact is currently in.'
        editHref='campaigns'
        editIcon='chevron'
        initialEditButtonText='Add Campaign'
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title={title}
        onInitialEditClick={handleInitialEdit}
      >
        {value}
      </DetailSection>
      <ListDialog
        ref={listDialogRef}
        list={CampaignList}
        multiple={false}
        subtitle={contact?.formattedName}
        title='Add Campaign'
        type='add'
        search
        onSubmitSelected={handleInitialCampaignAdd}
      />
      <EventDateDialog ref={eventDateDialogRef} onSubmit={handleContinueAdd} />
    </>
  )
}

CampaignsDetail.displayName = 'CampaignsDetail'
CampaignsDetail.propTypes = propTypes
CampaignsDetail.defaultProps = defaultProps

export default CampaignsDetail
