import PropType from 'prop-types'

export default {
  _id: PropType.string,
  auth: PropType.shape({
    username: PropType.string
  }),
  firstName: PropType.string,
  lastName: PropType.string,
  messageCredits: PropType.number,
  messageCreditData: PropType.shape({
    monthlyPlanNonRollover: PropType.number,
    usedThisPeriod: PropType.number
  }),
  phoneNumber: PropType.string
}
