import PropType from 'prop-types'

import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import { formatName } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import attachmentShape from '../../../prop-types/shapes/attachment'

const propTypes = {
  attachment: PropType.shape(attachmentShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DownloadsDetail'] })

const DownloadsDetail = ({ attachment }) => {
  useLogger({ log, lifecycle: false, tags: [attachment?._id] })

  const downloadSubsetValues = attachment?.attachmentDownloads.subset.map((download) => formatName(download.contact))
  const value = downloadSubsetValues.join(', ')
  const count = attachment?.attachmentDownloads.length

  return (
    <DetailSection
      count={count}
      description='The contacts below are the recent tracked downloads for your link.'
      editHref='downloads'
      editIcon='chevron'
      title='Downloads'
      showEditIcon
    >
      <div className='line-clamp-2'>{value}</div>
    </DetailSection>
  )
}

DownloadsDetail.displayName = 'DownloadsDetail'
DownloadsDetail.propTypes = propTypes
DownloadsDetail.defaultProps = defaultProps

export default DownloadsDetail
