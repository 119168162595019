import Button from '../../components/buttons/Button'
import ExternalLink from '../../components/links/ExternalLink'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

const AffiliateProgram = () => {
  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='Affiliate Program'
      />
      <PanelContent>
        <div className='p-5 flex flex-col gap-3 text-center md:items-center'>
          <div className='label-xl-strong'>Project Broadcast now offers a paid affiliate program!</div>
          <div>This program is in addition to our existing referral program. Click the Sign Up button below to learn more and create your paid affiliate account.</div>
          <div className='flex flex-row'>
            <Button
              className='flex-grow md:flex-none'
              href='https://affiliate.projectbroadcast.com/'
              size='sm'
            >
              Sign Up
            </Button>
          </div>
          <div>
            Already signed up?
            {' '}
            <ExternalLink className='text-action' href='https://affiliate.projectbroadcast.com/' label='Login' />
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

AffiliateProgram.displayName = 'AffiliateProgram'
export default AffiliateProgram
