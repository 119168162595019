import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { useSpaceThreadPolling } from '../../hooks/useThreadPolling'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { markAsRead, messages } from '../../services/space'
import { updateUnreadSpaces } from '../../store/actions/unread'
import ThreadList from '../list/thread/ThreadList'
import MessageThreadItemContent from '../message/MessageThreadItemContent'

const propTypes = {
  spaceId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpaceThread'] })

const SpaceThread = forwardRef(({ spaceId, ...rest }, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [spaceId] })

  const handleMarkAsReadReplyOk = useCallback((reply) => { updateUnreadSpaces(reply?.json?.unreadCount) }, [])
  const { call: markAsReadCall } = useService(markAsRead, { onReplyOk: handleMarkAsReadReplyOk })

  const handleMessageDeleted = useCallback((message) => {
    ref.current.updateMessage(message)
  }, [ref])
  useBus('messageDeleted', handleMessageDeleted)

  const handleUpdatesFromPoller = useCallback((updates) => {
    ref.current.newMessages(updates.messages, updates.scheduledMessagesCount)
  }, [ref])
  const { handleLastItemChange: pollerHandleLastItemChanged } = useSpaceThreadPolling(spaceId, handleUpdatesFromPoller)

  const handleLastItemChange = useCallback((lastItem) => {
    pollerHandleLastItemChanged(lastItem)
    if (!lastItem) { return }
    markAsReadCall({ spaceId, messageId: lastItem._id }, true)
    bus.emit('newLastChatMessage', lastItem)
  }, [markAsReadCall, pollerHandleLastItemChanged, spaceId])

  return (
    <ThreadList
      ref={ref}
      queryOverrides={{ spaceId }}
      service={messages}
      ThreadItemContent={MessageThreadItemContent}
      onLastItemChange={handleLastItemChange}
      {...rest}
    />
  )
})

SpaceThread.displayName = 'SpaceThread'
SpaceThread.propTypes = propTypes
SpaceThread.defaultProps = defaultProps

export default SpaceThread
