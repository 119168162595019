// This is a helper component for <Button />
import classNames from 'classnames'
import PropType from 'prop-types'

const propTypes = {
  icon: PropType.element.isRequired,
  size: PropType.oneOf(['lg', 'md', 'sm', 'none']).isRequired,
  position: PropType.oneOf(['start', 'center', 'end'])
}
const defaultProps = {
  position: 'center'
}

const classNamesBySize = {
  lg: 'w-[21px] h-[21px]',
  md: 'w-[18px] h-[18px]',
  sm: 'w-[15px] h-[15px]'
}

const classNamesByPositionAndSize = {
  start: {
    lg: 'mr-[15.5px]',
    md: 'mr-[11px]',
    sm: 'mr-[6.5px]'
  },
  center: { lg: '', md: '', sm: '' },
  end: {
    lg: 'ml-[15.5px]',
    md: 'ml-[11px]',
    sm: 'ml-[6.5px]'
  }
}

const ButtonIcon = ({ icon, position, size }) => {
  if (!icon) { return null }

  const className = classNames(
    'shrink-0',
    classNamesBySize[size],
    classNamesByPositionAndSize[position][size]
  )

  return (
    <span className={className}>
      {icon}
    </span>
  )
}

ButtonIcon.displayName = 'ButtonIcon'
ButtonIcon.propTypes = propTypes
ButtonIcon.defaultProps = defaultProps

export default ButtonIcon
