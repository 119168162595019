import proxy from './proxy'

export default function attachmentFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get attachmentsSummary () {
      return json.attachments.map((x) => x.name).join(', ')
    },
    get campaignsSummary () {
      return json.campaigns.map((x) => x.name).join(', ')
    },
    get emailsSummary () {
      return json.emails.map((x) => x.name).join(', ')
    },
    get keywordsSummary () {
      return json.keywords.map((x) => x.word).join(', ')
    },
    get landingPagesSummary () {
      return json.landingPages.map((x) => x.name).join(', ')
    },
    get messageTemplatesSummary () {
      return json.messageTemplates.map((x) => x.name).join(', ')
    },
    get voiceDropsSummary () {
      return json.voiceDrops.map((x) => x.name).join(', ')
    }
  }

  return proxy(json, overrides)
}
