import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import Avatar from '../../../components/avatar/Avatar'
import FormattedDate from '../../../components/FormattedDate'
import MessageFragment from '../../../components/message/MessageFragment'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import { MarkColor } from '../../../svg/branding'
import { Block, Clock } from '../../../svg/icons'

const propTypes = {
  item: PropType.shape(messageShape).isRequired,
  itemSharedContext: PropType.shape({
    showAdminContent: PropType.bool
  })
}

const defaultProps = {
  itemSharedContext: {
    showAdminContent: false
  }
}

const log = logger({ enabled: false, tags: ['UnsentMessageItemContent'] })

const UnsentMessageItemContent = ({ item: message, itemSharedContext: { showAdminContent } }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const stateDescription = useMemo(() => {
    const { error, skipMessage, deliveryDescription, state } = message
    return error || skipMessage || deliveryDescription || upperFirst(state)
  }, [message])

  const carrierAndMessageId = useMemo(() => {
    return (
      <div className='label-xs break-words max-w-full overflow-auto'>
        {message.messageId}
        {' '}
        {new Date(message.time).toISOString()}
      </div>
    )
  }, [message])

  return (
    <>
      <Avatar contactOrUser={message.contact} size='sm' />
      <div className='ml-[12px] max-w-full overflow-hidden'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {message.contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {message.contact.formattedName}
          </div>
        </div>
        <div className='flex flex-row max-w-full overflow-hidden'>
          <div className='flex flex-col flex-grow py-[4px] max-w-full overflow-auto'>
            <div className='label-xs text-neutral-500 mt-[3px]'>{message.contact.formattedPhoneNumber}</div>
            <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
              <Clock className='text-black shrink-0 grow-0 h-3 w-3' />
              <div className='line-clamp-1'>
                <FormattedDate date={new Date(message.time)} format='shortDateAndTime' />
              </div>
            </div>
            {!message.billable && (
              <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
                <Block className='text-black shrink-0 grow-0 h-3 w-3' />
                <div className='line-clamp-1'>
                  (no credits charged)
                </div>
              </div>
            )}
            <MessageFragment message={message} size='sm' />
            {showAdminContent ? carrierAndMessageId : null}
          </div>
          <div className='flex flex-none basis-1/3 self-center justify-end'>
            <div className='label-xs text-neutral-500 mt-[3px] text-red-400 p-2 bg-neutral-100 rounded'>
              {stateDescription}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

UnsentMessageItemContent.displayName = 'UnsentMessageItemContent'
UnsentMessageItemContent.propTypes = propTypes
UnsentMessageItemContent.defaultProps = defaultProps

export default UnsentMessageItemContent
