import * as React from "react";
import { memo } from "react";
const SvgExternal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.353 4c.07 0 .236.035.371.153.1.087.276.299.276.847v14c0 .208-.058.518-.19.74-.06.1-.12.16-.175.194a.508.508 0 0 1-.282.066H7.647a.631.631 0 0 1-.371-.153C7.176 19.76 7 19.548 7 19v-3a1 1 0 1 0-2 0v3c0 1.052.373 1.84.96 2.353A2.621 2.621 0 0 0 7.646 22h10.706c1.074 0 1.784-.577 2.178-1.24.362-.612.469-1.301.469-1.76V5c0-1.052-.373-1.84-.96-2.353A2.622 2.622 0 0 0 18.354 2H7.647c-1.074 0-1.785.577-2.178 1.24C5.107 3.852 5 4.541 5 5v3a1 1 0 1 0 2 0V5c0-.208.058-.518.19-.74.06-.1.12-.16.175-.194A.507.507 0 0 1 7.647 4h10.706Zm-1.637 7.302a.999.999 0 0 1-.002 1.399l-.008.007-3.999 4a1 1 0 0 1-1.414-1.415L13.586 13H4a1 1 0 1 1 0-2h9.586l-2.293-2.293a1 1 0 0 1 1.414-1.414l4 3.999.009.01Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgExternal);
export default Memo;
