import * as React from "react";
import { memo } from "react";
const SvgCelebration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.998 11v.01M4.998 2v.01M23.998 8.99V9M22.998 20.99V21M10.998 1v1.88c0 1.36-.54 2.66-1.5 3.62M23.998 14h-1.88c-1.36 0-2.66.54-3.62 1.5M23.998 1c-1.29 1.17-2.56-.34-3.85.83s-.02 2.67-1.31 3.84-2.56-.34-3.85.83M2.108 20.33 5.888 9l10.11 10.11-11.33 3.78c-1.58.53-3.09-.98-2.56-2.56v0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.739.328a1 1 0 0 1-.07 1.413c-.99.898-2.042.757-2.679.672l-.049-.007c-.648-.086-.847-.084-1.121.165-.29.263-.322.502-.327 1.187v.055c-.005.656-.011 1.716-.983 2.598-.99.898-2.043.757-2.68.672l-.049-.007c-.648-.086-.847-.084-1.12.165a1 1 0 1 1-1.345-1.482c.991-.898 2.043-.757 2.68-.672l.049.007c.648.086.847.084 1.121-.165.29-.263.322-.502.327-1.187v-.055c.004-.656.011-1.716.983-2.598.99-.898 2.043-.757 2.68-.672l.049.007c.648.086.848.084 1.121-.165a1 1 0 0 1 1.413.07ZM10.998 0a1 1 0 0 1 1 1v1.88c0 1.626-.646 3.18-1.793 4.327a1 1 0 0 1-1.414-1.414A4.116 4.116 0 0 0 9.998 2.88V1a1 1 0 0 1 1-1Zm-5 2a1 1 0 0 0-2 0v.01a1 1 0 1 0 2 0V2Zm10 9a1 1 0 1 0-2 0v.01a1 1 0 0 0 2 0V11Zm8-3.01a1 1 0 0 1 1 1V9a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm0 13a1 1 0 1 0-2 0V21a1 1 0 0 0 2 0v-.01ZM22.118 13c-1.625 0-3.18.646-4.327 1.793a1 1 0 0 0 1.414 1.414A4.117 4.117 0 0 1 22.118 15h1.88a1 1 0 1 0 0-2h-1.88ZM6.595 8.293a1 1 0 0 0-1.655.39l-3.78 11.33c-.792 2.362 1.464 4.617 3.826 3.825l11.329-3.78a1 1 0 0 0 .39-1.655L6.595 8.293ZM3.056 20.648l-.948-.318.949.317 3.268-9.796 7.822 7.822-9.797 3.269c-.797.267-1.561-.497-1.294-1.294Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCelebration);
export default Memo;
