import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/campaign-event'
import List from '../list/List'

import CampaignEventListItemContent from './CampaignEventListItemContent'

const propTypes = {
  campaignId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignEventList'] })

const CampaignEventList = forwardRef(({
  campaignId,
  ...rest
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [campaignId] })

  if (rest.search) { throw new Error('CampaignEventList does not support search') }

  return (
    <List
      ref={ref}
      ListItemContent={CampaignEventListItemContent}
      queryOverrides={{ campaignId }}
      resultsKey='events'
      searchService={searchService}
      {...rest}
    />
  )
})

CampaignEventList.displayName = 'CampaignEventList'
CampaignEventList.propTypes = propTypes
CampaignEventList.defaultProps = defaultProps

export default CampaignEventList
