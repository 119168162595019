import classNames from 'classnames'
import { omit } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { mergeRefs } from 'react-merge-refs'
import TextareaAutosize from 'react-textarea-autosize'

import useDefaultId from '../../../hooks/useDefaultId'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import addToTextArea from '../../../lib/addToTextArea'
import logger from '../../../lib/logger'
import EmojiPicker from '../../emoji-picker/EmojiPicker'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

const propTypes = {
  name: PropType.string.isRequired,
  className: PropType.string,
  emojiPicker: PropType.bool,
  error: PropType.string,
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  maxRows: PropType.number,
  minRows: PropType.number,
  onChange: PropType.func,
  onHeightChange: PropType.func
}

const defaultProps = {
  className: null,
  emojiPicker: true,
  error: null,
  hint: null,
  id: null,
  label: null,
  maxRows: 6,
  minRows: 3,
  onChange: undefined,
  onHeightChange: undefined
}

const log = logger({ enabled: false, tags: ['TextArea'] })

const TextArea = forwardRef(({
  className,
  emojiPicker,
  error,
  hint,
  id,
  label,
  maxRows,
  minRows,
  name,
  onChange,
  onHeightChange,
  ...rest
}, ref) => {
  ref = useDefaultRef(ref)
  id = useDefaultId(id)

  useLogger({ log, lifecycle: false, tags: [name, id] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  const computedClassName = classNames(
    'border border-neutral-300 rounded-md w-full h-full bg-white',
    {
      'pr-6': emojiPicker
    },
    className
  )

  const registerProps = useFormMethods?.register
    ? useFormMethods.register(name, { onChange })
    : { onChange }

  const mergedRef = mergeRefs([ref, registerProps.ref])
  delete registerProps.ref

  const textareProps = omit(rest, 'type')

  const handleEmojiClick = useCallback((emojiData) => {
    addToTextArea(emojiData.emoji, ref.current)
  }, [ref])

  return (
    <>
      <InputLabel id={id} text={label} />
      <div className='relative w-full'>
        <TextareaAutosize
          ref={mergedRef}
          className={computedClassName}
          id={id}
          maxRows={maxRows}
          minRows={minRows}
          name={name}
          onHeightChange={onHeightChange}
          {...textareProps}
          {...registerProps}
        />
        {emojiPicker ? <EmojiPicker className='absolute top-0 right-0' onEmojiClick={handleEmojiClick} /> : null}
      </div>
      <InputError message={error} />
      <InputHint message={hint} />
    </>
  )
})

TextArea.displayName = 'TextArea'
TextArea.propTypes = propTypes
TextArea.defaultProps = defaultProps

export default TextArea
