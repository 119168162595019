import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as configurableFormSearch } from '../../services/configurableForm'
import List from '../list/List'

import ConfigurableFomListItemContent from './ConfigurableFomListItemContent'

const propTypes = {
  additionalHandlers: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {}
}

const configurableFormResultsFields = [
  '_id',
  'slug', // utilized by the message composer
  'keyword',
  'name'
]

const log = logger({ enabled: false, tags: ['ConfigurableFormList'] })

const ConfigurableFormList = forwardRef(({ additionalHandlers, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('configurableFormCreated', handleBusEvent)
  useBus('configurableFormCloned', handleBusEvent)
  useBus('configurableFormUpdated', handleBusEvent)
  useBus('configurableFormDeleted', handleBusEvent)
  useBus('configurableFormsDeleted', handleBusEvent)

  additionalHandlers?.('configurableForm', ref)

  return (
    <List
      ref={ref}
      ListItemContent={ConfigurableFomListItemContent}
      placeholder='Search Configurable Forms'
      resultsFields={configurableFormResultsFields}
      resultsKey='configurableForms'
      searchService={configurableFormSearch}
      search
      {...listProps}
    />
  )
})

ConfigurableFormList.displayName = 'ConfigurableFormList'
ConfigurableFormList.propTypes = propTypes
ConfigurableFormList.defaultProps = defaultProps

export default ConfigurableFormList
