import PropType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { success } from '../../components/banners/Banner'
import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import ContactsWithAddDetailSection from '../../components/detail-sections/ContactsWithAddDetailSection'
import MessageDetailSection from '../../components/detail-sections/MessageDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import WarningDetailSection from '../../components/detail-sections/WarningDetailSection'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  popup: PropType.string
}

const defaultProps = {
  popup: null
}

const log = logger({ enabled: true, tags: ['DetailSections'] })

const DetailSections = ({ popup }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const [testObject1, setTestObject1] = useState({
    _id: '6005fe0e038ce666c101cc9d',
    name: 'L4 Green Curry Chicken',
    text: 'hello 🫡',
    media: ['https://picsum.photos/seed/picsum.s3.amazonaws.com/370/260'],
    type: 'mms',
    triggerContact: true,
    contacts: {
      length: 33,
      subset: [
        { _id: 'dummy-contact-id-1', firstName: 'Tester', lastName: '1' },
        { _id: 'dummy-contact-id-2', firstName: 'Tester', lastName: '2' },
        { _id: 'dummy-contact-id-3', firstName: 'Tester', lastName: '3' }
      ]
    }
  })
  const testObject2 = useMemo(() => ({
    _id: '6005fe0e038ce666c101cc9c',
    name: '',
    text: '',
    media: [],
    type: 'mms',
    triggerContact: false,
    contacts: {
      length: 0,
      subset: []
    }
  }), [])

  const createDummyService = (testObject, message) => {
    return (param1, param2) => ({
      call: () => {
        if (testObject === testObject1) {
          setTestObject1({ ...testObject, ...param2 })
        }
        log.info(`Would ${message}`, { param1, param2 })
        return new Promise((resolve) => resolve({
          ok: true,
          json: testObject
        }))
      }
    })
  }

  useEffect(() => {
    if (popup) {
      success(popup)
      navigate('/style-guide/components/detail-sections')
    }
  }, [navigate, popup])

  return (
    <Panel>
      <PanelHeader title='Detail Sections' />
      <PanelContent className='p-5'>
        <WarningDetailSection text='This is a warning detail section.' />
        <NameDetailSection
          object={testObject1}
          objectName='object'
          service={createDummyService('update name')}
        />
        <MessageDetailSection
          message={testObject1}
          service={createDummyService('update message')}
        />
        <CheckboxDetailSection
          checked={testObject1.triggerContact}
          description='After enabling, any new incoming message from a contact will trigger.'
          name='triggerContact'
          objectId={testObject1._id}
          service={createDummyService('update checkbox')}
          title='Checkbox'
          confirmOnSet
        />
        <ContactsWithAddDetailSection
          addAllInQueryService={createDummyService('add all contacts in query')}
          addSelectedService={createDummyService('add all selected contacts')}
          description='The participants in your campaign are listed below.'
          object={testObject1}
          objectName='object'
        />
        <ContactsWithAddDetailSection
          addAllInQueryService={createDummyService(testObject2, 'add all contacts in query')}
          addSelectedService={createDummyService(testObject2, 'add all selected contacts')}
          description='The participants in your campaign are listed below.'
          nomenclature='Participants'
          object={testObject2}
          objectName='object'
        />
      </PanelContent>
    </Panel>
  )
}

DetailSections.displayName = 'DetailSections'
DetailSections.propTypes = propTypes
DetailSections.defaultProps = defaultProps

export default DetailSections
