import { extend, get, isEqual } from 'lodash'
import mitt from 'mitt'

import messageFromJSON from '../models/message'
import userFromJSON from '../models/user'
import { poller as pollerService } from '../services/user'
import useStore from '../store'
import { update as updateCurrentUser } from '../store/actions/currentUser'

import logger from './logger'

const log = logger({ enabled: true, tags: ['lib:poller'] })

const poller = mitt()

/*
  events:
    broadcastingUpdate: true or false
    chatsUpdate: { lastMessageUnread: {_id: '640a248932f38d3f39928a54', time: '2023-03-09T18:25:13.328Z'}, unreadCount: 3 }
    spacesUpdate: { lastMessageUnread: {_id: '640a248932f38d3f39928a54', time: '2023-03-09T18:25:13.328Z'}, unreadCount: 3 }
    newMessagesInSpaceThread: { messages: [], scheduledMessagesCount: 0 }
    newMessagesInChatThread: { messages: [], scheduledMessagesCount: 0 }

  methods:
    runImmediately():
      forces an immediate run (does nothing if stopped)
      it aborts the fetch if in progress
    start():
      starts the checking
    pause():
      stops checking but keeps the `data` for a full restart
    stop():
      stops checking and clears the `data`
    startMonitoringChatThread(contactId, lastMessageId):
      starts monitoring a chat thread. can only monitor a single thread.
      will adjust the delay between calls
    stopMonitoringChatThread():
      stops monitoring the chat thread. potentially adjusts the delay between calls
    startMonitoringSpaceThread(spaceId, lastMessageId):
      starts monitoring a space thread. can only monitor a single thread.
      will adjust the delay between calls
    stopMonitoringSpaceThread():
      stops monitoring the space thread. potentially adjusts the delay between calls
*/

// broadcast hours check is currently 60,000
// chat/space unread count check is currently 10,000
// chat/space thread check is currently 5,000
const baselineDelay = 10000
const threadCheckDelay = 5000
let delay = baselineDelay
let timeout
let stopped = false
let data = {}
let previousResults
let currentAbort = false
const disabled = process.env.REACT_APP_DEV_DISABLE_POLLER === 'true'

extend(poller, {
  runImmediately () {
    run()
  },

  start () {
    stopped = false
    run()
    poller.emit('resumed')
  },

  pause () {
    if (timeout) { clearTimeout(timeout) }
    if (currentAbort) { currentAbort() }
    stopped = true
    poller.emit('paused')
  },

  stop () {
    if (timeout) { clearTimeout(timeout) }
    if (currentAbort) { currentAbort() }
    stopped = true
    data = {}
    poller.emit('stopped')
  },

  startMonitoringChatThread (contactId, lastMessageId) {
    startMonitoringThread('contact', contactId, lastMessageId)
  },

  stopMonitoringChatThread () {
    stopMonitoringThread('contact')
  },

  startMonitoringSpaceThread (spaceId, lastMessageId) {
    startMonitoringThread('space', spaceId, lastMessageId)
  },

  stopMonitoringSpaceThread () {
    stopMonitoringThread('space')
  }
})

function checkResults (results) {
  // check results... and emit necessary events
  // check if broadcasting changed
  checkBroadcastingPausedResults(results)
  checkChatsUpdates(results.chatsUpdates)
  checkSpacesUpdates(results.spacesUpdates)
  checkChatThreadUpdates(results.contactThreadUpdates)
  checkSpaceThreadUpdates(results.spaceThreadUpdates)
  checkCurrentUser(results)
}

function checkCurrentUser (results) {
  // this inentially does not emit an update event
  // the updates should come from the store
  if (results.currentUser) {
    // faking a serviceReply here in order to make it easier to
    // reuse the actions/currentUser.update method
    const currentUserModel = userFromJSON(results.currentUser)
    const fakeServiceReply = { ok: true, model: currentUserModel }
    updateCurrentUser(fakeServiceReply)
  }
}

function checkBroadcastingPausedResults (results) {
  const previousResult = get(previousResults, 'isBroadcastingPaused')
  const currentResult = get(results, 'isBroadcastingPaused')

  if (currentResult !== previousResult) {
    log.debug('changes detected in broadcastingUpdate... emitting "broadcastingUpdate"')
    poller.emit('broadcastingUpdate', results.isBroadcastingPaused)
  }
}

function checkChatsUpdates (chatsUpdates) {
  if (!chatsUpdates) { return }

  const previousChatsUpdates = get(previousResults, 'chatsUpdates')

  if (!isEqual(chatsUpdates, previousChatsUpdates)) {
    log.debug('changes detected in chats... emitting "chatsUpdate"')
    const event = { ...chatsUpdates }
    poller.emit('chatsUpdate', event)
  }
}

function checkSpacesUpdates (spacesUpdates) {
  if (!spacesUpdates) { return }

  const previousSpacesUpdates = get(previousResults, 'spacesUpdates')

  if (!isEqual(spacesUpdates, previousSpacesUpdates)) {
    log.debug('changes detected in spaces... emitting "spacesUpdate"')
    const event = { ...spacesUpdates }
    poller.emit('spacesUpdate', event)
  }
}

function checkChatThreadUpdates (chatThreadUpdates) {
  if (!chatThreadUpdates) { return }

  const previousChatThreadUpdates = get(previousResults, 'contactThreadUpdates')

  if (!isEqual(chatThreadUpdates, previousChatThreadUpdates)) {
    log.debug('changes detected in chat thread updates... emitting "newMessagesInChatThread"')
    const event = { ...chatThreadUpdates }
    event.messages = event?.messages?.map((message) => messageFromJSON(message))
    poller.emit('newMessagesInChatThread', event)
  }
}

function checkSpaceThreadUpdates (spaceThreadUpdates) {
  if (!spaceThreadUpdates) { return }

  const previousSpaceThreadUpdates = get(previousResults, 'spaceThreadUpdates')

  if (!isEqual(spaceThreadUpdates, previousSpaceThreadUpdates)) {
    log.debug('changes detected in space thread updates... emitting "newMessagesInSpaceThread"')
    const event = { ...spaceThreadUpdates }
    event.messages = event?.messages?.map((message) => messageFromJSON(message))
    poller.emit('newMessagesInSpaceThread', event)
  }
}

async function run () {
  if (currentAbort) { currentAbort() }
  if (timeout) { clearTimeout(timeout) }
  if (stopped) { return }
  data.currentUserUpdatedAt = useStore.getState().currentUser?.updatedAt
  const { call, abort } = pollerService(data)
  currentAbort = abort
  try {
    const reply = await call()
    if (reply?.aborted) { return }
    if (!reply?.ok) {
      log.error('Error while polling...', reply)
      return
    }
    if (!reply?.json) {
      log.error('Reply was missing json...', reply)
      return
    }
    checkResults(reply.json)
    previousResults = reply.json
  } catch (err) {
    log.error('Error while polling...', err)
  } finally {
    currentAbort = false
    if (!disabled) { timeout = setTimeout(run, delay) }
  }
}

function adjustDelay () {
  let updatedDelay
  if (data.spaceThread || data.contactThread) {
    updatedDelay = threadCheckDelay
  } else {
    updatedDelay = baselineDelay
  }
  if (delay !== updatedDelay) { log.debug(`updated delay from ${delay} to ${updatedDelay}`) }
  delay = updatedDelay
}

const startMonitoringThread = function (keyPrefix, typeId, messageId) {
  if (!typeId || !messageId) { return }
  data[`${keyPrefix}Thread`] = {
    [`${keyPrefix}Id`]: typeId,
    messageId
  }
  poller.runImmediately()
  adjustDelay()
}

const stopMonitoringThread = function (keyPrefix) {
  if (currentAbort) { currentAbort() }
  const key = `${keyPrefix}Thread`
  if (data[key]) { delete data[key] }
  adjustDelay()
}

export default poller
