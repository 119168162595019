export function needsTcrInfo (user) {
  const status = tcrStatus(user)
  return tcrApplicable(user) && (!status || status === 'not-registered')
}

export function tcrApplicable (user) {
  return user?.phoneNumberType === 'managed-voip'
}

export function tcrStatus (user) {
  return user?.registration?.isTCR ? user?.tcr?.status : user?.twilio?.status
}

export function pendingSmsVerification (user) {
  const identityStatus = user?.registration?.isTCR ? user.tcr?.brand?.identityStatus : user?.twilio?.brandRegistration?.identityStatus
  const brandStatus = user?.registration?.isTCR ? 'approved' : user?.twilio?.brandRegistration?.brandStatus
  const status = user?.registration?.isTCR ? user?.tcr?.status : user?.twilio?.status
  return tcrApplicable(user) &&
    user.registration?.brand?.entityType === 'SOLE_PROPRIETOR' && status === 'registering' && brandStatus === 'approved' &&
    identityStatus === 'unverified'
}

export function tcrRegistering (user) {
  const status = tcrStatus(user)
  return status === 'registering' || status === 'registration-queued'
}

export function tcrComplete (user) {
  const status = tcrStatus(user)
  return status === 'registered'
}

export function tcrFailed (user) {
  const status = tcrStatus(user)
  return status === 'registration-failed'
}

export function userRegistrationIsSoleProp (user) {
  return user?.registration?.brand?.entityType === 'SOLE_PROPRIETOR'
}

export function needsSolePropUpgradeInfo (user) {
  return tcrApplicable(user) && !user?.registration?.isTCR && userRegistrationIsSoleProp(user) && !user?.isSinch && !user?.isStayingWithBandwidth && user?.twilio?.canUpdateToSoleProp2 && user?.twilio?.status === 'not-registered'
}

export function tcrAdminEditable (user) {
  return user?.registration?.isTwilio &&
    user?.twilio?.status === 'registration-failed' &&
    user?.twilio?.brandRegistration?.status === 'created' &&
    user?.twilio?.usAppToPerson?.status === 'created' &&
    user?.twilio?.usAppToPerson?.campaignStatus === 'failed'
}

export function tcrAdminCanResubmit (user) {
  return user?.registration?.isTwilio &&
    user?.twilio?.status === 'registration-failed' &&
    user?.twilio?.brandRegistration?.status === 'created' &&
    user?.twilio?.usAppToPerson?.status === 'created' &&
    user?.twilio?.usAppToPerson?.campaignStatus === 'failed'
}
