import PropType from 'prop-types'

import contactFromJSON from '../models/contact'

import { addOnePropTypes, removeOnePropTypes, searchPropTypes } from './contact-campaign.prop-types'
import pb from './pb'

export function search (body, background = false) {
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'contact-campaign#search')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background
  }

  return pb.setupPost(`contacts/${contactId}/campaigns/search`, options)
}

export function addOne (body, background = false) {
  PropType.checkPropTypes(addOnePropTypes, body, 'prop', 'contact-campaign#addOne')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/campaigns/addOne`, options)
}

export function removeOne (body, background = false) {
  PropType.checkPropTypes(removeOnePropTypes, body, 'prop', 'contact-campaign#removeOne')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/campaigns/removeOne`, options)
}
