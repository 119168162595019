import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import { CONTENT_TYPE } from '../../../lib/folder'
import logger from '../../../lib/logger'
import templateShape from '../../../prop-types/shapes/template'
import TemplateBulkEditActions from '../../templates/actions/BulkEditActions'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(templateShape)).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageTemplatesBulkEditActions'] })

const BulkEditActions = ({ selectedItems }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <TemplateBulkEditActions
      deleteActionLabel='Delete Message Templates'
      folderContentType={CONTENT_TYPE.messageTemplate}
      selectedItems={selectedItems}
    />
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
