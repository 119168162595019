import * as React from "react";
import { memo } from "react";
const SvgActivities = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Activities_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM8.5 3.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM7.168 8.445A1 1 0 0 1 8 8h8a1 1 0 0 1 .832.445l3.576 5.365 2.276-.759a1 1 0 0 1 .632 1.898l-3 1a1 1 0 0 1-1.148-.394L16 10.803V18h2a3 3 0 1 1 0 6H6a3 3 0 1 1 0-6h2v-7.197l-3.168 4.752a1 1 0 0 1-1.148.394l-3-1a1 1 0 0 1 .632-1.898l2.276.759 3.576-5.365ZM10 18h4v-8h-4v8Zm-5 3a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Activities_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgActivities);
export default Memo;
