import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'

function Typography () {
  return (
    <Panel>
      <PanelHeader title='Typography' />
      <PanelContent>
        <div className='p-5'>
          <p className='headline-2xl mb-[36px]'>
            Heading XXLage (.headline-2xl) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-xl mb-[36px]'>
            Heading XLage (.headline-xl) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-lg mb-[36px]'>
            Heading Lage (.headline-lg) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-md mb-[36px]'>
            Heading Medium (.headline-md) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-sm mb-[36px]'>
            Heading Small (.headline-sm) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-xs mb-[36px]'>
            Heading XSmall (.headline-xs) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='headline-2xs mb-[36px]'>
            Heading XXSmall (.headline-2xs) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='heading-3xs mb-[36px]'>
            Heading XXXSmall (.headline-3xs) - The quick brown fox jumps over the lazy dog
          </p>
          <p className='paragraph-xl mb-[36px]'>Paragraph XLarge (.paragraph-xl) - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non nulla vel diam tristique tincidunt. Mauris convallis vel risus quis pretium. Maecenas nisl augue, congue sed viverra cursus, auctor ut est. Cras nec dignissim ante.</p>
          <p className='paragraph-lg mb-[36px]'>Paragraph Large (.paragraph-lg) - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non nulla vel diam tristique tincidunt. Mauris convallis vel risus quis pretium. Maecenas nisl augue, congue sed viverra cursus, auctor ut est. Cras nec dignissim ante.</p>
          <p className='paragraph-md mb-[36px]'>Paragraph Medium (.paragraph-md) - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non nulla vel diam tristique tincidunt. Mauris convallis vel risus quis pretium. Maecenas nisl augue, congue sed viverra cursus, auctor ut est. Cras nec dignissim ante.</p>
          <p className='paragraph-sm mb-[36px]'>Paragraph Small (.paragraph-sm) - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non nulla vel diam tristique tincidunt. Mauris convallis vel risus quis pretium. Maecenas nisl augue, congue sed viverra cursus, auctor ut est. Cras nec dignissim ante.</p>
          <p className='paragraph-xs mb-[36px]'>Paragraph XSmall (.paragraph-xs) - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non nulla vel diam tristique tincidunt. Mauris convallis vel risus quis pretium. Maecenas nisl augue, congue sed viverra cursus, auctor ut est. Cras nec dignissim ante.</p>
          <p className='label-xl-strong mb-[36px]'>Label XLarge Strong (.label-xl-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-xl-medium mb-[36px]'>Label XLarge Medium (.label-xl-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-xl mb-[36px]'>Label XLarge (.label-xl) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-lg-strong mb-[36px]'>Label Large Strong (.label-lg-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-lg-medium mb-[36px]'>Label Large Medium (.label-lg-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-lg mb-[36px]'>Label Large (.label-lg) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-md-strong mb-[36px]'>Label Medium Strong (.label-md-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-md-medium mb-[36px]'>Label Medium Medium (.label-md-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-md mb-[36px]'>Label Medium (.label-md) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-sm-strong mb-[36px]'>Label Small Strong (.label-sm-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-sm-medium mb-[36px]'>Label Small Medium (.label-sm-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-sm mb-[36px]'>Label Small (.label-sm) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-xs-strong mb-[36px]'>Label XSmall Strong (.label-xs-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-xs-medium mb-[36px]'>Label XSmall Medium (.label-xs-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-xs mb-[36px]'>Label XSmall (.label-xs) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-2xs-strong mb-[36px]'>Label XXSmall Strong (.label-2xs-strong) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-2xs-medium mb-[36px]'>Label XXSmall Medium (.label-2xs-medium) - The quick brown fox jumps over the lazy dog</p>
          <p className='label-2xs mb-[36px]'>Label XXSmall (.label-2xs) - The quick brown fox jumps over the lazy dog</p>

        </div>
      </PanelContent>
    </Panel>
  )
}

export default Typography
