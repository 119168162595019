import * as React from "react";
import { memo } from "react";
const SvgChatError = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 9.5C0 4.258 4.258 0 9.5 0h5C19.742 0 24 4.258 24 9.5S19.742 19 14.5 19h-.197l-5.748 3.832A1 1 0 0 1 7 22v-3.34C2.966 17.56 0 13.88 0 9.5ZM9.5 2A7.506 7.506 0 0 0 2 9.5c0 3.686 2.663 6.749 6.176 7.375A1 1 0 0 1 9 17.86v2.271l4.445-2.963A1 1 0 0 1 14 17h.5c4.138 0 7.5-3.362 7.5-7.5S18.638 2 14.5 2h-5ZM12 5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Zm1 7.99a1 1 0 1 0-2 0V13a1 1 0 1 0 2 0v-.01Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChatError);
export default Memo;
