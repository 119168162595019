import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import { useApp } from '../../../contexts/AppContext'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Aiva as AivaEmptyState } from '../../../svg/empty-states'
import PanelDialog from '../../dialog/PanelDialog'
import SupportLink from '../../links/SupportLink'
import PanelHeaderButton from '../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../panels/PanelContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AivaSupportAction'] })

const AivaSupportAction = forwardRef((params, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { isNative } = useApp()

  const panelDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () {
      panelDialogRef.current.open()
    }
  }), [])

  const handleCloseClick = useCallback(() => {
    panelDialogRef.current.close()
  }, [])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel='AIVA Support'>
      <PanelContent className='relative'>
        <PanelHeaderButton className='absolute top-2 right-2' icon='close' onClick={handleCloseClick} />
        <div className='flex flex-col items-center gap-6 my-12 w-full'>
          <div className='w-full bg-indigo-50'>
            <AivaEmptyState className='max-w-[600px] mx-auto' />
          </div>
          <div className='text-center'>
            <h1 className='label-lg-strong'>Need Support?</h1>
            <p className='max-w-[320px]'>
              While AIVA is impressive, she's still learning and may not have all the answers yet.
              If you need extra support, please don't hesitate to contact us.
            </p>
          </div>
          <SupportLink label='support@projectbroadcast.com' />
          <p className='label-xs'>
            {`${isNative ? 'Tap' : 'Hover over'} AIVA responses to report or rate them.`}
          </p>
        </div>
      </PanelContent>
    </PanelDialog>
  )
})

AivaSupportAction.displayName = 'AivaSupportAction'
AivaSupportAction.propTypes = propTypes
AivaSupportAction.defaultProps = defaultProps

export default AivaSupportAction
