import PropType from 'prop-types'
import { forwardRef, useCallback, useMemo, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Eye, EyeOff } from '../../../svg/icons'
import TextInputIcon from '../TextInputIcon'

import TextInput from './TextInput'

const propTypes = { // all other props are passed to TextInput
  showPassword: PropType.bool,
  showPasswordToggle: PropType.bool
}

const defaultProps = {
  showPassword: false,
  showPasswordToggle: true
}

const log = logger({ enabled: false, tags: ['PasswordInput'] })

const PasswordInput = forwardRef(({ showPassword: initialShowPasswordState, showPasswordToggle, ...rest }, ref) => {
  if (showPasswordToggle && rest.end) { throw new Error('PasswordInput does not support the `end` prop when using the built-in password toggle') }

  useLogger({ log, lifecycle: true, tags: [rest.name, rest.id] })

  const [showPassword, setShowPassword] = useState(initialShowPasswordState)
  const handlePasswordToggle = useCallback(() => setShowPassword(!showPassword), [showPassword])

  delete rest.type
  const type = showPassword ? 'text' : 'password'

  const end = useMemo(() => (showPasswordToggle
    ? <TextInputIcon
      icon={showPassword ? <EyeOff /> : <Eye />}
      title='Toggle password visibility'
      onClick={handlePasswordToggle}
      />
    : null
  ), [showPasswordToggle, showPassword, handlePasswordToggle])

  return (
    <TextInput
      ref={ref}
      end={end}
      type={type}
      {...rest}
    />
  )
})

PasswordInput.displayName = 'PasswordInput'
PasswordInput.propTypes = propTypes
PasswordInput.defaultProps = defaultProps

export default PasswordInput
