import { formatDateMonth, formatName, formatPhone } from '../lib/formatters'

import messageFromJSON from './message'
import proxy from './proxy'
import userFromJSON from './user'

export default function contactFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get formattedAnniversary () {
      return formatDateMonth(json.anniversary)
    },

    get formattedBirthday () {
      return formatDateMonth(json.birthday)
    },

    get formattedCustomFields () {
      return json.customFields.map((field) => field?.value).join(', ')
    },

    get formattedName () {
      return formatName(json)
    },

    get formattedPhoneNumber () {
      return formatPhone(json.phoneNumber)
    },

    get isPBUser () {
      return !!json.contactUser
    }
  }

  const model = proxy(json, overrides)
  model.lastMessage = messageFromJSON(json.lastMessage)
  if (json.user?._id) {
    model.user = userFromJSON(json.user)
  }
  return model
}
