import * as React from "react";
import { memo } from "react";
const SvgBlogPromoCircleWithLogoMark = (props) => (
  <svg
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#BlogPromoCircleWithLogoMark_svg__a)">
      <rect width={90} height={90} rx={45} fill="#FEF2F2" />
      <path
        d="M103.189 29.94S74.161 22.256 72.196 44.64C70.231 67.025 60.82 76.156 47.952 75.903c-12.868-.253-39.216.412-29.327 31.125s48.754 60.99 67.311 24.283C104.494 94.604 103.19 29.939 103.19 29.939Z"
        fill="#FEE2E1"
      />
      <path
        d="M72.266-23.39S69.733 2.167 62.47 9.506c-7.264 7.338-17.922.855-23.044-4.46C34.303-.269 24.43-.48 27.164 8.938c2.733 9.42 4.521 15.645-2.495 18.938-7.017 3.293-15.269 1.624-11.47 10.73C17 47.712 17.698 65.141 6.196 68.222c-11.503 3.08-34.746-8.926-29.79-46.667 4.959-37.742.158-59.395 25.51-57.502C27.268-34.055 72.266-23.39 72.266-23.39Z"
        fill="#DDD6FF"
      />
      <path
        d="M46.32 30.716h-9.228c-3.53 0-6.375 2.86-6.375 6.39v9.218a6.376 6.376 0 0 0 6.375 6.39H52.66V37.108c.034-3.54-2.853-6.391-6.34-6.391Z"
        fill="#fff"
      />
      <path
        d="M52.694 52.723V37.038H43.8c-3.71 0-6.751 3.05-6.751 6.769v8.916h15.645Z"
        fill="#E1E7FF"
      />
      <path
        d="M55.316 37.072s0 .035 0 0v18.28H37.092c.334 3.419 3.23 6.09 6.717 6.09h17.633V43.764c0-3.462-2.699-6.357-6.117-6.692h-.009Z"
        fill="#A6B4FD"
      />
      <path
        d="M55.008 33.448a9.116 9.116 0 0 0-2.005-2.998 9.464 9.464 0 0 0-2.982-2.01 9.112 9.112 0 0 0-3.659-.748h-9.279c-1.302 0-2.57.258-3.65.748a9.091 9.091 0 0 0-2.99 2.01 9.353 9.353 0 0 0-2.005 2.989 9.174 9.174 0 0 0-.746 3.668v9.217c0 1.306.257 2.577.746 3.66a9.116 9.116 0 0 0 2.005 2.997 9.464 9.464 0 0 0 2.981 2.01 9.111 9.111 0 0 0 3.659.748H55.71V37.124c.034-1.194-.206-2.465-.711-3.676h.009Zm-2.742 3.659v15.187H37.083c-3.282 0-5.946-2.671-5.946-5.961v-9.226c0-3.29 2.664-5.962 5.946-5.962h9.194c3.298 0 5.989 2.672 5.989 5.962Z"
        fill="#384152"
      />
    </g>
    <rect
      x={3.5}
      y={3.5}
      width={83}
      height={83}
      rx={41.5}
      stroke="#fff"
      strokeWidth={7}
    />
    <defs>
      <clipPath id="BlogPromoCircleWithLogoMark_svg__a">
        <rect width={90} height={90} rx={45} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBlogPromoCircleWithLogoMark);
export default Memo;
