import * as React from 'react'
import { memo } from 'react'
const SvgBundles = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#FEF9C3" rx={4} />
        <g clipPath="url(#Bundles_svg__a)">
            <path
                fill="#CA8A04"
                fillRule="evenodd"
                d="m12.09 5.635 3.023 4.64H9.069l3.022-4.64Zm.763-1.496 3.996 6.135h2.878a.727.727 0 0 1 .693.948l-2.545 8a.727.727 0 0 1-.693.507H7a.727.727 0 0 1-.693-.507l-2.545-8a.727.727 0 0 1 .693-.947h2.878l4-6.142a.91.91 0 0 1 1.516 0l.004.006Zm3.61 7.59H5.45l2.083 6.546h9.118l2.083-6.546h-2.27Zm-3.645 2.182a.727.727 0 1 0-1.454 0v2.182a.727.727 0 0 0 1.454 0V13.91Zm-3.636-.727c.401 0 .727.325.727.727v2.182a.727.727 0 0 1-1.454 0V13.91c0-.402.325-.727.727-.727Zm6.545.727a.727.727 0 1 0-1.454 0v2.182a.727.727 0 0 0 1.454 0V13.91Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="Bundles_svg__a">
                <path fill="#fff" d="M3 3h17.454v17.454H3z" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgBundles)
export default Memo
