import * as React from "react";
import { memo } from "react";
const SvgBrowser = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Browser_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 2c-.548 0-1 .452-1 1v5h20V3c0-.548-.452-1-1-1H3ZM0 3v20a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V3c0-1.652-1.348-3-3-3H3C1.348 0 0 1.348 0 3Zm22 19V10H2v12h20ZM6 4.99a1 1 0 0 0-2 0V5a1 1 0 0 0 2 0v-.01Zm3-1a1 1 0 0 1 1 1V5a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm5 1a1 1 0 1 0-2 0V5a1 1 0 1 0 2 0v-.01Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Browser_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgBrowser);
export default Memo;
