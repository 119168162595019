import { omit, pick } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useMemo, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import useTimeZone from '../../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../../lib/formatters'
import logger from '../../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../../lib/timeZones'
import scheduledBroadcastShape from '../../../../prop-types/shapes/scheduledBroadcast'
import { update as updateDraftService } from '../../../../services/scheduledBroadcast'
import FormPanelDialog from '../../../dialog/FormPanelDialog'

import DraftContactsDetailSection from './DraftContactsDetailSection'
import validationSuite from './ScheduledBroadcastEditDraftDialog.validations'

const propTypes = {
  onCancel: PropType.func.isRequired,
  onSave: PropType.func.isRequired,
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape),
  onChange: PropType.func,
  onClose: PropType.func
}

const defaultProps = {
  onChange: null,
  onClose: null,
  scheduledBroadcast: null
}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastEditDraftDialog'] })

const ScheduledBroadcastEditDraftDialog = forwardRef(({ scheduledBroadcast, onCancel, onSave, onChange, onClose }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { userTimeZone } = useTimeZone()
  const contactsDetailRef = useRef()

  const { call: updateDraftCall } = useService(updateDraftService, {
    onReplyOk: useCallback(() => {
      ref.current.close()
      onSave()
    }, [onSave, ref])
  })

  const handleUpdate = useCallback((updatedScheduledBroadcast) => {
    onChange?.(updatedScheduledBroadcast)
  }, [onChange])

  const handleSubmit = useCallback((data) => {
    if (scheduledBroadcast.contacts.length > 0) {
      updateDraftCall(scheduledBroadcast._id, data)
    } else {
      contactsDetailRef.current.setError('Send To is required.')
    }
  }, [updateDraftCall, scheduledBroadcast])

  const { confirm, defaultValues, formControls, transformData } = useMemo(() => {
    return {
      confirm: {
        description: `Are you sure you want to schedule this broadcast to send immediately?
                      Clicking OK will cause your broadcast to begin sending to your recipients now.`,
        when: ({ scheduledAt }) => !!scheduledAt && (new Date(scheduledAt) < new Date())
      },
      defaultValues: {
        message: pick(scheduledBroadcast, 'text', 'type', 'media', 'template'),
        scheduledAtDay: formatDateForInput(new Date(), userTimeZone),
        scheduledAtTime: formatTimeForInput(new Date(), userTimeZone)
      },
      formControls: [
        {
          label: 'Message',
          type: 'message',
          name: 'message'
        },
        {
          type: 'passthrough',
          name: 'passthrough1',
          element: <DraftContactsDetailSection ref={contactsDetailRef} scheduledBroadcast={scheduledBroadcast} onChange={handleUpdate} />
        },
        {
          label: 'Date',
          name: 'scheduledAtDay',
          hint: 'Specify the date to send this broadcast.',
          type: 'date'
        },
        {
          label: 'Time',
          name: 'scheduledAtTime',
          hint: 'Specify the time of day to start sending this broadcast.',
          type: 'time-message-send'
        }
      ],
      transformData: (data) => {
        const { scheduledAtDay, scheduledAtTime } = data
        data.scheduledAt = dateAtDayAndTimeInTimeZone(scheduledAtDay, scheduledAtTime, userTimeZone)?.toISOString()
        return omit(data, 'scheduledAtDay', 'scheduledAtTime')
      }
    }
  }, [handleUpdate, scheduledBroadcast, userTimeZone])

  return (
    <FormPanelDialog
      ref={ref}
      confirm={confirm}
      confirmCancel='Are you sure you want to close? Closing will result in the loss of your work.'
      defaultValues={defaultValues}
      description='You have something to say? Make it personal. Make it awesome!'
      formControls={formControls}
      title='Schedule Broadcast'
      transformData={transformData}
      validationSuite={validationSuite}
      onCancel={onCancel}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
})

ScheduledBroadcastEditDraftDialog.displayName = 'ScheduledBroadcastEditDraftDialog'
ScheduledBroadcastEditDraftDialog.propTypes = propTypes
ScheduledBroadcastEditDraftDialog.defaultProps = defaultProps

export default ScheduledBroadcastEditDraftDialog
