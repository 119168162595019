import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'

import { calculatePanelOneAndTwoClassNames } from '../../helpers/panels'
import { Single } from '../../svg/components/appbar'

import PanelOne from './PanelOne'
import PanelTwo from './PanelTwo'

const propTypes = {
  includeHeaderBackground: PropType.bool.isRequired,
  isNative: PropType.bool.isRequired,
  panel1: PropType.node.isRequired,
  roomForTwoPanels: PropType.bool.isRequired,
  className: PropType.string,
  panel2: PropType.node,
  panelPriority: PropType.oneOf([1, 2])
}

const defaultProps = {
  className: null,
  panel2: null,
  panelPriority: 1
}

const PanelLayoutPanels = ({
  className,
  includeHeaderBackground,
  isNative,
  panel1,
  panel2,
  panelPriority,
  roomForTwoPanels
}) => {
  const panelHeaderBackground = (
    <div className='absolute left-0 top-0 w-full z-0' style={{ height: 'calc(env(safe-area-inset-top) + 52px)' }}>
      <Single className='w-full h-full' preserveAspectRatio='xMinYMax slice' />
    </div>
  )

  const computedClassName = classNames(
    'relative h-full w-full max-h-full max-w-full',
    'flex grow flex-nowrap overflow-hidden',
    className
  )

  const [panel1ClassName, panel2ClassName] = useMemo(() => {
    return calculatePanelOneAndTwoClassNames(!!panel2, panelPriority, roomForTwoPanels)
  }, [panel2, panelPriority, roomForTwoPanels])

  return (
    <main className={computedClassName}>
      <PanelOne className={panel1ClassName} hasTwoPanels={!!panel2} roomForTwoPanels={roomForTwoPanels}>{panel1}</PanelOne>
      {panel2 ? <PanelTwo className={panel2ClassName} roomForTwoPanels={roomForTwoPanels}>{panel2}</PanelTwo> : null}
      {isNative && includeHeaderBackground ? panelHeaderBackground : null}
    </main>
  )
}

PanelLayoutPanels.displayName = 'PanelLayoutPanels'
PanelLayoutPanels.propTypes = propTypes
PanelLayoutPanels.defaultProps = defaultProps

export default PanelLayoutPanels
