import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createAppFlowService } from '../../../services/appFlow'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import FormPanelDialog from '../../dialog/FormPanelDialog'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: undefined
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    dialogRef.current.close()
    const appFlow = reply.json
    setCurrentInstance('appFlow', appFlow)
    success('App Flow created.')
    bus.emit('appFlowCreated', appFlow)
    navigate(`/app-flows/${appFlow._id}`)
    onSuccess?.()
  }, [navigate, onSuccess])
  const { call: createAppFlow } = useService(createAppFlowService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((createData) => { createAppFlow(createData) }, [createAppFlow])

  return (
    <FormPanelDialog
      ref={dialogRef}
      defaultValues={{ name: '' }}
      description='Please name your new app flow'
      formControls={[
        {
          autoComplete: 'off',
          label: 'Name',
          name: 'name',
          type: 'text',
          required: true
        }
      ]}
      title='New App Flow'
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
