import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import { success } from '../../../components/banners/Banner'
import ConfirmDialog from '../../../components/dialog/ConfirmDialog'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { remove as removeService } from '../../../services/user-managedAccount'
import ListDialog from '../../dialog/ListDialog'
import ManagedAccountList from '../ManagedAccountList'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['RemoveAction'] })

const RemoveAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const confirmRef = useRef()
  const listRef = useRef()
  const [selectedManagedAccount, setSelectedManagedAccount] = useState()
  const { call: removeCall } = useService(removeService, {
    onReplyOk: useCallback(() => {
      success(`${selectedManagedAccount.formattedName} was removed`)
      bus.emit('managedAccountRemoved', selectedManagedAccount)
      onSuccess?.()
    }, [selectedManagedAccount, onSuccess])
  })

  useImperativeHandle(ref, () => ({
    activate () { listRef.current.open() }
  }), [])

  const handleManagedAccountSelected = useCallback((data) => {
    setSelectedManagedAccount(data)
    confirmRef.current.open()
  }, [])

  const handleConfirm = useCallback(() => {
    removeCall(selectedManagedAccount._id)
  }, [removeCall, selectedManagedAccount])

  return (
    <>
      <ListDialog
        ref={listRef}
        list={ManagedAccountList}
        multiple={false}
        title='Remove Managed Account'
        type='select'
        onSubmitSelected={handleManagedAccountSelected}
      />
      <ConfirmDialog
        ref={confirmRef}
        description={`You will no longer be able to manage ${selectedManagedAccount?.formattedName}'s account. Are you sure?`}
        title='Remove Managed Account?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

RemoveAction.displayName = 'RemoveAction'
RemoveAction.propTypes = propTypes
RemoveAction.defaultProps = defaultProps

export default RemoveAction
