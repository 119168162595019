import PropType from 'prop-types'

import Banner from '../../components/banners/Banner'
import useLogger from '../../hooks/useLogger'
import { formatExtendedDate } from '../../lib/formatters'
import logger from '../../lib/logger'

const propTypes = {
  timezone: PropType.string.isRequired,
  subscriptionDetails: PropType.shape({
    currentPlanEndDate: PropType.string.isRequired,
    currentPlanFrequency: PropType.string.isRequired,
    currentPlanId: PropType.string.isRequired,
    currentPlanName: PropType.string.isRequired,
    cancelsAt: PropType.string,
    scheduledPlanFrequency: PropType.string,
    scheduledPlanId: PropType.string,
    scheduledPlanName: PropType.string
  })
}

const defaultProps = {
  subscriptionDetails: {
    cancelsAt: null,
    scheduledPlanFrequency: null,
    scheduledPlanName: null
  }
}

const log = logger({ enabled: false, tags: ['ChangeSubscriptionScheduledBanner'] })

const ChangeSubscriptionScheduledBanner = ({ subscriptionDetails, timezone }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const planCancelling = !!subscriptionDetails?.cancelsAt
  if (planCancelling) {
    return (
      <Banner className='mb-5' title='Upcoming Account Termination' type='warning'>
        Your subscription expires
        {' '}
        <strong>{formatExtendedDate(subscriptionDetails.currentPlanEndDate, timezone)}</strong>
        {'. '}
      </Banner>
    )
  }

  const planChanging = subscriptionDetails?.scheduledPlanId && subscriptionDetails.scheduledPlanId !== subscriptionDetails.currentPlanId
  if (planChanging) {
    return (
      <Banner className='mb-5'>
        At your next renewal on
        {' '}
        <strong>{formatExtendedDate(subscriptionDetails.currentPlanEndDate, timezone)}</strong>
        {' '}
        your plan is scheduled to change from
        {' '}
        <strong className='whitespace-nowrap'>
          {subscriptionDetails.currentPlanName}
          {' '}
          {subscriptionDetails.currentPlanFrequency}
        </strong>
        {' to '}
        <strong className='whitespace-nowrap'>
          {subscriptionDetails.scheduledPlanName}
          {' '}
          {subscriptionDetails.scheduledPlanFrequency}
        </strong>
        {'. '}
      </Banner>
    )
  }
}

ChangeSubscriptionScheduledBanner.displayName = 'ChangeSubscriptionScheduledBanner'
ChangeSubscriptionScheduledBanner.propTypes = propTypes
ChangeSubscriptionScheduledBanner.defaultProps = defaultProps

export default ChangeSubscriptionScheduledBanner
