import { omit } from 'lodash'
import PropType from 'prop-types'

import attachmentDownloadFromJSON from '../models/attachmentDownload'

import { searchPropTypes } from './attachment-download.prop-types'
import pb from './pb'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'attachment-download#search')

  const { attachmentId } = data
  const body = omit(data, 'attachmentId')
  const model = {
    conversion: attachmentDownloadFromJSON,
    key: 'attachmentDownloads',
    multiple: true
  }
  return pb.setupPost(`attachments/${attachmentId}/attachmentDownloads/search`, { body, background, model })
}
