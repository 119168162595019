import { formatName } from '../lib/formatters'

import proxy from './proxy'
import userFromJSON from './user'

export default function trackableLinkFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get clickCount () {
      return json.trackableLinkClicks?.length || 0
    },

    get clickSubsetNames () {
      const clickNames = json.trackableLinkClicks?.subset?.map((trackableLinkClick) => formatName(trackableLinkClick.contact))?.join(', ')
      return clickNames || 'No Clicks Yet'
    }
  }

  const model = proxy(json, overrides)
  if (json.user?._id) {
    model.user = userFromJSON(json.user)
  }
  return model
}
