import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import bundleShape from '../../prop-types/shapes/bundle'
import {
  Attach,
  Browser,
  Campaign,
  ChatText,
  Email,
  Key,
  Microphone
} from '../../svg/icons'
import { Bundles } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(bundleShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['BundleListItemContent'] })

const BundleListItemContent = ({ item: bundle }) => {
  useLogger({ log, lifecycle: true, tags: [bundle?._id] })

  const attachments = useMemo(() => {
    if (!bundle?.attachments?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Attach className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.attachments.length} />
        <div className='line-clamp-1 break-words'>{bundle.attachmentsSummary}</div>
      </div>
    )
  }, [bundle])

  const campaigns = useMemo(() => {
    if (!bundle?.campaigns?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Campaign className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.campaigns.length} />
        <div className='line-clamp-1 break-words'>{bundle.campaignsSummary}</div>
      </div>
    )
  }, [bundle])

  const keywords = useMemo(() => {
    if (!bundle?.keywords?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Key className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.keywords.length} />
        <div className='line-clamp-1 break-words'>{bundle.keywordsSummary}</div>
      </div>
    )
  }, [bundle])

  const landingPages = useMemo(() => {
    if (!bundle?.landingPages?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Browser className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.landingPages.length} />
        <div className='line-clamp-1 break-words'>{bundle.landingPagesSummary}</div>
      </div>
    )
  }, [bundle])

  const messageTemplates = useMemo(() => {
    if (!bundle?.messageTemplates?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <ChatText className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.messageTemplates.length} />
        <div className='line-clamp-1 break-words'>{bundle.messageTemplatesSummary}</div>
      </div>
    )
  }, [bundle])

  const voiceDrops = useMemo(() => {
    if (!bundle?.voiceDrops?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Microphone className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.voiceDrops.length} />
        <div className='line-clamp-1 break-words'>{bundle.voiceDropsSummary}</div>
      </div>
    )
  }, [bundle])

  const emails = useMemo(() => {
    if (!bundle?.emails?.length) { return null }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Email className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
        <Pill className='mx-1' value={bundle.emails.length} />
        <div className='line-clamp-1 break-words'>{bundle.emailsSummary}</div>
      </div>
    )
  }, [bundle])

  const emptyBundle = useMemo(() => {
    if (attachments || campaigns || emails || keywords || landingPages || messageTemplates || voiceDrops) {
      return null
    }

    return (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <div className='line-clamp-1 break-words'>Empty Bundle</div>
      </div>
    )
  }, [attachments, campaigns, emails, keywords, landingPages, messageTemplates, voiceDrops])

  return (
    <>
      <Bundles className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {bundle?.name}
        </div>
        {emptyBundle}
        {attachments}
        {campaigns}
        {emails}
        {keywords}
        {landingPages}
        {messageTemplates}
        {voiceDrops}
      </div>
    </>
  )
}

BundleListItemContent.displayName = 'BundleListItemContent'
BundleListItemContent.propTypes = propTypes
BundleListItemContent.defaultProps = defaultProps

export default BundleListItemContent
