async function request (context, { method, path, data }) {
  return context._request({
    url: `${context.get('baseUrl')}${path}`,
    method,
    data
  })
}

export async function appmixerGet (context, path) {
  return request(context, { method: 'GET', path })
}

export async function appmixerPut (context, path, data = {}) {
  return request(context, { method: 'PUT', path, data })
}

export async function appmixerPost (context, path, data = {}) {
  return request(context, { method: 'POST', path, data })
}
