import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as searchService } from '../../services/trackableLink-contact'
import ContactList from '../contacts/ContactList'

import TrackableLinkContactListItemContent from './TrackableLinkContactListItemContent'

const propTypes = {
  trackableLinkId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TrackableLinkContactList'] })

const TrackableLinkContactList = forwardRef(({ trackableLinkId, ...rest }, ref) => {
  useLogger({ log, lifecycle: false, tags: [trackableLinkId] })

  return (
    <ContactList
      ref={ref}
      ListItemContent={TrackableLinkContactListItemContent}
      queryOverrides={{ trackableLinkId }}
      resultsKey='trackableLinkClicks'
      searchService={searchService}
      type='list'
      {...rest}
    />
  )
})

TrackableLinkContactList.displayName = 'TrackableLinkContactList'
TrackableLinkContactList.propTypes = propTypes
TrackableLinkContactList.defaultProps = defaultProps

export default TrackableLinkContactList
