import classNames from 'classnames'
import PropType from 'prop-types'

import { firstImageUrl, firstVcardUrl, firstVoiceUrl } from '../../helpers/messageMedia'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { baseMessageFields } from '../../prop-types/shapes/message'

import EmailMessageFragment from './message-fragment/EmailMessageFragment'
import ImageMessageFragment from './message-fragment/ImageMessageFragment'
import VcardMessageFragment from './message-fragment/VcardMessageFragment'
import VoiceMessageFragment from './message-fragment/VoiceMessageFragment'

const propTypes = {
  message: PropType.shape(baseMessageFields).isRequired, // could be a message, a template, a broadcast, or other models that have some message like fields
  size: PropType.oneOf(['sm', 'md', 'lg']),
  onRefreshForVoice: PropType.func
}

const defaultProps = {
  onRefreshForVoice: null,
  size: 'md'
}

const log = logger({ enabled: true, tags: ['MessageFragment'] })

const MessageFragment = ({ message, size, onRefreshForVoice }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const imageUrl = firstImageUrl(message)
  const vcardUrl = firstVcardUrl(message)
  const voiceUrl = firstVoiceUrl(message)
  const textClassName = classNames(
    `whitespace-pre-wrap break-words line-clamp-${{ sm: imageUrl ? 1 : 2, md: 2, lg: 3 }[size]} select-text`,
    {
      'leading-tight': size === 'sm'
    }
  )

  if (message.type === 'email') { return <EmailMessageFragment message={message} size={size} /> }
  if (message.type === 'rvm' || voiceUrl) {
    return (
      <VoiceMessageFragment
        mediaUrl={voiceUrl}
        message={message}
        size={size}
        onRefreshForVoice={onRefreshForVoice}
      />
    )
  }

  return (
    <>
      <ImageMessageFragment mediaUrl={imageUrl} size={size} />
      <VcardMessageFragment mediaUrl={vcardUrl} size={size} />
      <div className={textClassName}>
        {message.text}
      </div>
    </>
  )
}

MessageFragment.displayName = 'MessageFragment'
MessageFragment.propTypes = propTypes
MessageFragment.defaultProps = defaultProps

export default MessageFragment
