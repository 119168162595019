export const bundleContentTypes = [
  'attachments',
  'campaigns',
  'emails',
  'keywords',
  'landingPages',
  'messageTemplates',
  'voiceDrops'
]

export function bundleContentTypeLabel (contentType) {
  switch (contentType) {
    case 'attachments':
      return 'Attachment'
    case 'campaigns':
      return 'Campaign'
    case 'emails':
      return 'Email'
    case 'keywords':
      return 'Keyword'
    case 'landingPages':
      return 'Landing Page'
    case 'messageTemplates':
      return 'Message Template'
    case 'voiceDrops':
      return 'Voice Drop'
  }
}
