import PropType from 'prop-types'

import DetailSection from '../../../components/detail-sections/DetailSection'
import useLogger from '../../../hooks/useLogger'
import { formatName } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import tagShape from '../../../prop-types/shapes/tag'

const propTypes = {
  tag: PropType.shape(tagShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContactsDetail'] })

const ContactsDetail = ({ tag }) => {
  useLogger({ log, lifecycle: false, tags: [tag?.name] })

  const downloadSubsetValues = tag?.contacts.subset.map(formatName)
  const value = downloadSubsetValues.join(', ')
  const count = tag?.contacts.length

  return (
    <DetailSection
      count={count}
      description='The contacts below have the above tag associated with them. This helps you to quickly add large groups of contacts to your messages and campaigns.'
      editHref='contacts'
      editIcon='chevron'
      title='Contacts'
      showEditIcon
    >
      <div className='line-clamp-2'>{value}</div>
    </DetailSection>
  )
}

ContactsDetail.displayName = 'ContactsDetail'
ContactsDetail.propTypes = propTypes
ContactsDetail.defaultProps = defaultProps

export default ContactsDetail
