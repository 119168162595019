import { upperFirst } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import logger from '../lib/logger'
import useStore from '../store'
import { setCurrentInstance, setCurrentInstanceFromReply } from '../store/actions/currentInstance'

import useLogger from './useLogger'
import useServiceAndAction from './useServiceAndAction'

const log = logger({ enabled: true, tags: ['useCurrentInstance'] })

const useCurrentInstance = ({ resourceName, getService, replyAttrPath, notFoundRedirect, idPath, instanceIdAttr }) => {
  const currentInstanceIdRef = useRef(false)
  const currentInstance = useStore((state) => state.current[resourceName])
  const serviceReplyAction = (serviceReply) => setCurrentInstanceFromReply(resourceName, replyAttrPath, serviceReply)
  instanceIdAttr = instanceIdAttr || `${resourceName}Id`
  idPath = idPath || '_id'
  const instanceId = useParams()[instanceIdAttr]
  const navigate = useNavigate()
  const location = useLocation()

  useLogger({ log, lifecycle: false, tags: [resourceName, instanceId] })

  const handleReplyNotOk = useCallback((reply) => {
    if (notFoundRedirect && reply?.status === 404) {
      log.debug('Got a 404... redirecting', notFoundRedirect)
      navigate(notFoundRedirect, { replace: true })
    }
  }, [navigate, notFoundRedirect])
  const handleReplyOk = useCallback((reply) => {
    currentInstanceIdRef.current = reply.json[idPath]
  }, [idPath])
  const { loading, call: getInstance } = useServiceAndAction(getService, serviceReplyAction, { onReplyOk: handleReplyOk, onReplyNotOk: handleReplyNotOk })

  useEffect(() => {
    if (!instanceId) { return }
    const currentInstanceId = currentInstanceIdRef.current
    const invalidateCurrentInstance = location.state?.invalidateCurrentInstance
    if (invalidateCurrentInstance) {
      log.debug('Invalidating current instance...')
      setCurrentInstance(resourceName, null)
      currentInstanceIdRef.current = false
    }
    if (invalidateCurrentInstance || !currentInstanceId || currentInstanceId !== instanceId) {
      log.debug('Fetching current instance...', instanceId, idPath, invalidateCurrentInstance)
      getInstance(instanceId, true)
    }
  }, [getInstance, idPath, instanceId, location.state?.invalidateCurrentInstance, resourceName])

  const refresh = useCallback(() => { getInstance(instanceId, true) }, [getInstance, instanceId])
  const set = useCallback((instance) => { setCurrentInstance(resourceName, instance) }, [resourceName])

  return {
    loading: loading || (currentInstance?.[instanceIdAttr] && (instanceId !== currentInstance[instanceIdAttr])),
    [`${instanceIdAttr}`]: instanceId,
    [`${resourceName}`]: currentInstance,
    refresh,
    [`set${upperFirst(resourceName)}`]: set
  }
}

export default useCurrentInstance
