import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import chat from '../../../prop-types/shapes/chat'
import { update } from '../../../services/contact'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'

const propTypes = {
  chat: PropType.shape(chat).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['TurnOffDoNotMessage'] })

const TurnOffDoNotMessage = forwardRef(({ chat }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleReplyOk = useCallback((reply) => {
    const newChat = reply.model
    setCurrentInstance('chat', newChat)
    success(`Do Not Message Turned Off to ${chat.formattedName}`)
    bus.emit('contactOptOutRelatedInfoUpdated')
  }, [chat])
  const { call } = useService(update, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { call(chat._id, { doNotMessage: false }) }
  }), [call, chat._id])

  return null
})

TurnOffDoNotMessage.displayName = 'TurnOffDoNotMessage'
TurnOffDoNotMessage.propTypes = propTypes
TurnOffDoNotMessage.defaultProps = defaultProps

export default TurnOffDoNotMessage
