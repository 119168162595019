import * as React from "react";
import { memo } from "react";
const SvgKeywords = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 235 183"
    {...props}
  >
    <g filter="url(#Keywords_svg__a)">
      <path
        fill="#fff"
        d="M182.786 5.143H42.214c-6.47 0-11.714 5.244-11.714 11.714v140.571c0 6.47 5.245 11.715 11.714 11.715h140.572c6.469 0 11.714-5.245 11.714-11.715V16.857c0-6.47-5.245-11.714-11.714-11.714Z"
      />
    </g>
    <path
      fill="#ECFDF5"
      d="M172.5 17.143h-120c-5.523 0-10 4.477-10 10v120c0 5.522 4.477 10 10 10h120c5.523 0 10-4.478 10-10v-120c0-5.523-4.477-10-10-10Z"
    />
    <g filter="url(#Keywords_svg__b)">
      <g clipPath="url(#Keywords_svg__c)">
        <rect width={171} height={41} x={7} y={34.143} fill="#fff" rx={5} />
        <path
          fill="#fff"
          d="M172.103 34.143H12.897C9.64 34.143 7 36.765 7 40v29.285c0 3.235 2.64 5.858 5.897 5.858h159.206c3.257 0 5.897-2.623 5.897-5.858V40c0-3.235-2.64-5.857-5.897-5.857Z"
        />
        <path
          fill="#A7F3CF"
          d="M139.672 44.685H51.813c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.515-3.538-3.515ZM103.703 56.4h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.94 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.94-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={28.303}
          height={28.114}
          x={12.896}
          y={41.171}
          fill="#D0FAE4"
          rx={4}
        />
        <g clipPath="url(#Keywords_svg__d)">
          <path
            fill="#05976A"
            fillRule="evenodd"
            d="M29.503 48.718a1.802 1.802 0 0 0-2.526-.003l-2.39 2.373c-.644.64-.689 1.65-.145 2.347l-4.28 4.252a.636.636 0 0 0-.19.452v3.485c0 .353.289.639.644.639h3.509a.641.641 0 0 0 .643-.64V59.94h1.696a.641.641 0 0 0 .643-.639v-1.103h2.866a.647.647 0 0 0 .11-.01 1.776 1.776 0 0 0 1.134-.514l2.386-2.37a1.774 1.774 0 0 0 .003-2.51l-4.103-4.075Zm.47 8.195a.66.66 0 0 0-.088.006h-3.421a.641.641 0 0 0-.643.639v1.103h-1.696a.641.641 0 0 0-.644.64v1.684H21.26v-2.582l4.537-4.506a.637.637 0 0 0 0-.904l-.297-.294a.502.502 0 0 1-.001-.707l2.384-2.369a.51.51 0 0 1 .712-.001l4.096 4.069h.001c.198.2.193.518.002.708l-2.386 2.37a.481.481 0 0 1-.334.144ZM29.24 51.9a.646.646 0 0 0-.91 0 .636.636 0 0 0 0 .904l1.17 1.162c.251.249.659.249.91 0a.636.636 0 0 0 0-.904L29.24 51.9Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </g>
    <g filter="url(#Keywords_svg__e)">
      <g clipPath="url(#Keywords_svg__f)">
        <rect width={171} height={41} x={53} y={85.143} fill="#fff" rx={5} />
        <path
          fill="#fff"
          d="M218.103 85.143H58.897C55.64 85.143 53 87.765 53 91v29.285c0 3.235 2.64 5.858 5.897 5.858h159.206c3.257 0 5.897-2.623 5.897-5.858V91c0-3.235-2.64-5.857-5.897-5.857Z"
        />
        <path
          fill="#A7F3CF"
          d="M185.672 95.686H97.813c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h87.859c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514ZM149.703 107.4h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={28.303}
          height={28.114}
          x={58.897}
          y={92.171}
          fill="#D0FAE4"
          rx={4}
        />
        <g clipPath="url(#Keywords_svg__g)">
          <path
            fill="#05976A"
            fillRule="evenodd"
            d="M75.503 99.718a1.802 1.802 0 0 0-2.526-.003l-2.39 2.373a1.773 1.773 0 0 0-.145 2.347l-4.28 4.252a.634.634 0 0 0-.19.451v3.486c0 .353.289.639.644.639h3.509a.641.641 0 0 0 .643-.639v-1.685h1.696a.641.641 0 0 0 .643-.639v-1.103h2.865a.668.668 0 0 0 .11-.01 1.778 1.778 0 0 0 1.135-.514l2.386-2.37a1.774 1.774 0 0 0 .003-2.51l-4.103-4.075Zm.47 8.195a.627.627 0 0 0-.088.006h-3.421a.641.641 0 0 0-.644.639v1.103h-1.695a.641.641 0 0 0-.644.639v1.685H67.26v-2.582l4.537-4.506a.638.638 0 0 0 0-.904l-.297-.294a.501.501 0 0 1-.001-.707l2.384-2.369a.51.51 0 0 1 .712-.001l4.096 4.069.001.001c.198.2.193.517.002.707l-2.386 2.37a.483.483 0 0 1-.335.144Zm-.733-5.012a.647.647 0 0 0-.91 0 .636.636 0 0 0 0 .904l1.17 1.162a.647.647 0 0 0 .91 0 .636.636 0 0 0 0-.904l-1.17-1.162Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="Keywords_svg__c">
        <rect width={171} height={41} x={7} y={34.143} fill="#fff" rx={5} />
      </clipPath>
      <clipPath id="Keywords_svg__d">
        <path fill="#fff" d="M19.33 47.56h15.438v15.336H19.33z" />
      </clipPath>
      <clipPath id="Keywords_svg__f">
        <rect width={171} height={41} x={53} y={85.143} fill="#fff" rx={5} />
      </clipPath>
      <clipPath id="Keywords_svg__g">
        <path fill="#fff" d="M65.33 98.56h15.438v15.336H65.33z" />
      </clipPath>
      <filter
        id="Keywords_svg__a"
        width={182}
        height={182}
        x={23.5}
        y={0.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_126146"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_126146"
          result="shape"
        />
      </filter>
      <filter
        id="Keywords_svg__b"
        width={189}
        height={59}
        x={0}
        y={29.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_126146"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_126146"
          result="shape"
        />
      </filter>
      <filter
        id="Keywords_svg__e"
        width={189}
        height={59}
        x={46}
        y={80.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_126146"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_126146"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(SvgKeywords);
export default Memo;
