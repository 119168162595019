import PropType from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

import { useApp } from './AppContext'

export const PanelContext = createContext()
PanelContext.displayName = 'PanelContext'

const providerPropTypes = {
  children: PropType.node.isRequired,
  slot: PropType.oneOf(['first', 'second', 'dialog']).isRequired,
  hasTwoPanels: PropType.bool,
  isNative: PropType.bool,
  roomForTwoPanels: PropType.bool
}
const providerDefaultProps = {
  hasTwoPanels: false,
  isNative: null,
  roomForTwoPanels: null
}

export const PanelProvider = ({ children, slot, isNative, hasTwoPanels, roomForTwoPanels }) => {
  const appContext = useApp()
  const [containsList, setContainsList] = useState(false)
  const [containsAivaChatButton, setContainsAivaChatButton] = useState(false)
  const [containsSideMenuToggleButton, setContainsSideMenuToggleButton] = useState(false)
  isNative = isNative ?? appContext.isNative

  const value = useMemo(() => ({
    isNative,
    slot,
    inFirst: slot === 'first',
    inSecond: slot === 'second',
    inDialog: slot === 'dialog',
    containsList,
    setContainsList,
    containsAivaChatButton,
    setContainsAivaChatButton,
    containsSideMenuToggleButton,
    setContainsSideMenuToggleButton,
    hasTwoPanels,
    roomForTwoPanels
  }), [isNative, slot, containsList, containsAivaChatButton, containsSideMenuToggleButton, hasTwoPanels, roomForTwoPanels])

  return (
    <PanelContext.Provider value={value}>{children}</PanelContext.Provider>
  )
}
PanelProvider.displayName = 'PanelProvider'
PanelProvider.propTypes = providerPropTypes
PanelProvider.defaultProps = providerDefaultProps

export function usePanel () {
  const context = useContext(PanelContext)
  if (context === undefined) {
    throw new Error('usePanel must be used within a PanelProvider')
  }
  return context
}
