import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createLandingPageService } from '../../../services/landingPage'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import FormPanelDialog from '../../dialog/FormPanelDialog'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: undefined
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    dialogRef.current.close()
    const landingPage = reply.model
    setCurrentInstance('landingPage', landingPage)
    success('Landing Page created.')
    bus.emit('landingPageCreated', landingPage)
    if (!createdFromFolder) { navigate(`/landing-pages/${landingPage._id}`) }
    onSuccess?.(landingPage)
  }, [createdFromFolder, navigate, onSuccess])
  const { call: createLandingPage } = useService(createLandingPageService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((createData) => { createLandingPage(createData) }, [createLandingPage])

  return (
    <FormPanelDialog
      ref={dialogRef}
      defaultValues={{ name: '' }}
      description='Please name your new landing page'
      formControls={[
        {
          autoComplete: 'off',
          label: 'Name',
          name: 'name',
          type: 'text',
          required: true
        },
        {
          hint: 'This is what shows up as the title in the browser tab and when the link is shared.',
          label: 'Title',
          name: 'title',
          type: 'text',
          required: true
        },
        {
          hint: 'This shows up when the link is shared. About 155 characters is best.',
          label: 'Description',
          name: 'description',
          type: 'text',
          required: true
        }
      ]}
      title='New Landing Page'
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
