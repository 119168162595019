import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { stackKeyFromContentType } from '../../../lib/folder'
import logger from '../../../lib/logger'
import { contentType as contentTypeShape } from '../../../prop-types/shapes/folder'
import { create as createFolderService } from '../../../services/folder'
import { notify } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuite from './CreateFolderAction.validations'

const propTypes = {
  contentType: PropType.oneOf(contentTypeShape).isRequired,
  selectedItems: PropType.array // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  selectedItems: []
}

const log = logger({ enabled: false, tags: ['CreateFolderAction'] })

const CreateFolderAction = forwardRef(({ contentType, selectedItems }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()
  const dialogRef = useRef()
  const confirmRef = useRef()
  const nameRef = useRef()
  const contentIds = useMemo(() => selectedItems.map((item) => item._id), [selectedItems])

  const handleReplyOk = useCallback((response) => {
    if (response.json.showConfirmation) {
      return confirmRef.current.open()
    }
    const folder = response.json
    notify('Success!', 'Your folder has been created.')
    const stackKey = stackKeyFromContentType(contentType)
    bus.emit('closeBulkEditDialog')
    bus.emit('folderCreated')
    navigate(`/${stackKey}/folder/${folder._id}`)
  }, [contentType, navigate])
  const { call: createFolder } = useService(createFolderService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      dialogRef.current.open()
    }
  }), [])

  const createFolderWithName = useCallback(({ name }) => {
    dialogRef.current.close()
    nameRef.current = name
    createFolder({ name, contentType, contentIds })
  },
  [createFolder, contentType, contentIds])

  const handleConfirm = useCallback(() => {
    createFolder({ name: nameRef.current, contentType, contentIds, movingItemsConfirmed: true })
  },
  [createFolder, contentType, contentIds])

  return (
    <>
      <FormPanelDialog
        ref={dialogRef}
        defaultValues={{ name: '' }}
        description='Please name your new folder'
        formControls={[
          {
            autoComplete: 'off',
            name: 'name',
            label: 'Folder Name',
            type: 'text',
            hint: 'Please name your folder.'
          },
          {
            name: 'contentType',
            value: contentType,
            type: 'hidden'
          }
        ]}
        title='Create New Folder'
        validationSuite={validationSuite}
        onSubmit={createFolderWithName}
      />
      <ConfirmDialog
        ref={confirmRef}
        description='Selected items already exist in other folders. Would you like to move them to a new folder?'
        title='Move Items?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

CreateFolderAction.displayName = 'CreateFolderAction'
CreateFolderAction.propTypes = propTypes
CreateFolderAction.defaultProps = defaultProps

export default CreateFolderAction
