import * as React from "react";
import { memo } from "react";
const SvgEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 376 175"
    {...props}
  >
    <path fill="#fff" d="M.5 0h375v175H.5z" />
    <path
      fill="#F0F9FF"
      stroke="#E0F2FE"
      strokeWidth={3}
      d="M273 87.014c0 45.04-37.568 81.625-84 81.625s-84-36.585-84-81.625c0-45.041 37.568-81.625 84-81.625s84 36.584 84 81.625Z"
    />
    <path
      fill="#DBEAFF"
      d="M127.529 61.886a2.18 2.18 0 0 1 1.109-1.889l59.441-34.124a2.312 2.312 0 0 1 2.294 0l59.44 34.124a2.18 2.18 0 0 1 1.109 1.889v77.043c0 1.212-1.01 2.194-2.256 2.194H129.785c-1.246 0-2.256-.982-2.256-2.194V61.886Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M248.026 60.433 189 25.825l-59.027 34.608v78.083h118.053V60.433Zm-119.421-2.05a2.23 2.23 0 0 0-1.105 1.923v78.433a2.241 2.241 0 0 0 2.249 2.233h118.502c1.242 0 2.249-1 2.249-2.233V60.306c0-.79-.42-1.521-1.105-1.923l-59.252-34.74a2.26 2.26 0 0 0-2.286 0l-59.252 34.74Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M215.367 25.522a10.002 10.002 0 0 0-6.971-2.83h-52.821c-5.522 0-10 4.477-10 10v66.41c0 5.523 4.478 10 10 10h68.203c5.523 0 10-4.477 10-10V47.647c0-2.701-1.093-5.287-3.029-7.17l-15.382-14.955Z"
      clipRule="evenodd"
    />
    <path
      fill="#DBEAFF"
      fillRule="evenodd"
      d="M212.799 21.389v18.19c0 1.212 1.01 2.194 2.256 2.194h18.71l-20.966-20.384Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M213.237 21.815h-59.992c-4.859 0-8.797 3.83-8.797 8.553v76.103c0 4.724 3.938 8.553 8.797 8.553h72.412c4.859 0 8.798-3.829 8.798-8.553V42.443l-2.256-2.193v66.221c0 3.513-2.929 6.36-6.542 6.36h-72.412c-3.613 0-6.542-2.847-6.542-6.36V30.368c0-3.512 2.929-6.36 6.542-6.36h62.248l-2.256-2.193Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M211.671 22.451v16.004a6.768 6.768 0 0 0 6.768 6.767h16.003l-2.256-2.256h-13.747a4.512 4.512 0 0 1-4.512-4.511V24.707l-2.256-2.256Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m213.388 21.73 21.067 20.481-1.755 1.706-21.067-20.482 1.755-1.706Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M127.529 102.084c0-1.211 1.01-2.193 2.256-2.193h118.881c1.246 0 2.256.982 2.256 2.193v36.845c0 1.212-1.01 2.194-2.256 2.194H129.785c-1.246 0-2.256-.982-2.256-2.194v-36.845Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M130.01 102.304v36.406h118.431v-36.406H130.01Zm-.225-2.413c-1.246 0-2.256.982-2.256 2.193v36.845c0 1.212 1.01 2.194 2.256 2.194h118.881c1.246 0 2.256-.982 2.256-2.194v-36.845c0-1.211-1.01-2.193-2.256-2.193H129.785Z"
      clipRule="evenodd"
    />
    <path
      fill="#EFF6FF"
      d="M248.985 59.246 202.75 100.11a1.291 1.291 0 0 0 .015 1.96l44.8 38.473c1.308 1.123 3.368.224 3.374-1.472l.31-78.847c.004-1.144-1.393-1.748-2.264-.978Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m205.013 101.074 43.672 37.505.3-76.37-43.972 38.865Zm-2.263-.964 46.235-40.864c.871-.77 2.268-.166 2.264.978l-.31 78.847c-.006 1.696-2.066 2.595-3.374 1.472l-44.8-38.473a1.291 1.291 0 0 1-.015-1.96Z"
      clipRule="evenodd"
    />
    <path
      fill="#EFF6FF"
      d="m129.805 59.246 46.234 40.864c.597.527.59 1.441-.014 1.96l-44.801 38.473c-1.307 1.123-3.367.224-3.374-1.472l-.309-78.847c-.005-1.144 1.392-1.748 2.264-.978Z"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="m173.777 101.074-43.673 37.505-.3-76.37 43.973 38.865Zm2.262-.964-46.234-40.864c-.872-.77-2.269-.166-2.264.978l.309 78.847c.007 1.696 2.067 2.595 3.374 1.472l44.801-38.473a1.291 1.291 0 0 0 .014-1.96Z"
      clipRule="evenodd"
    />
    <path
      fill="#D2D5DC"
      fillRule="evenodd"
      d="M117.039 139.821c0-.666.556-1.206 1.241-1.206h141.44c.685 0 1.241.54 1.241 1.206 0 .667-.556 1.207-1.241 1.207H118.28c-.685 0-1.241-.54-1.241-1.207Z"
      clipRule="evenodd"
    />
    <circle cx={73} cy={142.5} r={6.5} fill="#E1E7FF" />
    <circle cx={91} cy={40.5} r={4.5} fill="#E1E7FF" />
    <circle cx={287.5} cy={44} r={6} fill="#E1E7FF" />
    <circle cx={305.5} cy={14} r={4} fill="#E1E7FF" />
  </svg>
);
const Memo = memo(SvgEmail);
export default Memo;
