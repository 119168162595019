import { filter } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { generateAdvancedSearchFromSearchData, generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/chatListSearchBar'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ActionMenu from '../action-menu/ActionMenu'
import ActionMenuItem from '../action-menu/ActionMenuItem'
import Label from '../forms/InputLabel'
import AdvancedSearchDialog from '../search-bar/AdvancedSearchDialog'
import SearchBar from '../search-bar/SearchBar'
import SearchBarButton from '../search-bar/SearchBarButton'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  // NOTE: search only works for data that does not get transformed
  search: PropType.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  placeholder: undefined,
  search: null
}

const log = logger({ enabled: false, tags: ['ChatListSearchBar'] })

const ChatListSearchBar = ({
  search: initialSearch,
  placeholder,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { aivaSettings } = useCurrentUser()

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()

  const [advancedSearchData, setAdvancedSearchData] = useState(generateAdvancedSearchFromSearchData(initialSearch))

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])
  const handleSearchBarReset = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData)
  }, [advancedSearchData])

  const formControls = filter([
    { label: 'Term', name: 'term' },
    {
      type: 'radiogroup',
      name: 'filter',
      label: 'Filter',
      options: [
        { label: 'Sent', value: 'sent' },
        { label: 'Unread', value: 'unread' },
        { label: 'Starred', value: 'starred' },
        { label: 'Scheduled', value: 'scheduled' }
      ]
    },
    aivaSettings.stopDetect !== 'off'
      ? {
          type: 'passthrough',
          name: 'passthrough',
          element: (
            <div className='-mb-4'>
              <Label text='Smart Opt-out' />
            </div>
          )
        }
      : null,
    aivaSettings.stopDetect !== 'off'
      ? {
          label: 'Potential Opt-out',
          name: 'potentialStopMessage',
          type: 'checkbox'
        }
      : null,
    { label: 'Tags', name: 'tags', model: 'tags', type: 'modelselect' }
  ])

  const handleFilterClick = useCallback((filter) => {
    const newAdvancedSearchData = { ...advancedSearchData, filter }
    setAdvancedSearchData(newAdvancedSearchData)
    const search = generateSearchFromAdvancedSearchFormData(newAdvancedSearchData)
    searchBarRef.current.update(search)
  }, [advancedSearchData])

  const handleSentFilterClick = useCallback(() => { handleFilterClick('sent') }, [handleFilterClick])
  const handleUnreadFilterClick = useCallback(() => { handleFilterClick('unread') }, [handleFilterClick])
  const handleStarredFilterClick = useCallback(() => { handleFilterClick('starred') }, [handleFilterClick])
  const handleScheduledFilterClick = useCallback(() => { handleFilterClick('scheduled') }, [handleFilterClick])

  const end = (
    <ActionMenu placement='bottom-start' trigger={<SearchBarButton icon='filter' />}>
      <ActionMenuItem label='Sent' onClick={handleSentFilterClick} />
      <ActionMenuItem label='Unread' onClick={handleUnreadFilterClick} />
      <ActionMenuItem label='Starred' onClick={handleStarredFilterClick} />
      <ActionMenuItem label='Scheduled' onClick={handleScheduledFilterClick} />
    </ActionMenu>
  )

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        end={end}
        generateSummary={generateSummary}
        placeholder={placeholder}
        search={advancedSearchData}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onReset={handleSearchBarReset}
        onTermChange={handleSearchBarTermChange}
      />
      <AdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        formControls={formControls}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>
  )
}

ChatListSearchBar.displayName = 'ChatListSearchBar'
ChatListSearchBar.propTypes = propTypes
ChatListSearchBar.defaultProps = defaultProps

export default ChatListSearchBar
