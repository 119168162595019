import PropType from 'prop-types'

import messageTypes from '../../helpers/messageTypes'
import { CONTENT_TYPE } from '../../lib/folder'

import userShape from './user'

export default {
  __v: PropType.number,
  _id: PropType.string,
  bundles: PropType.array, // TODO: array of what?
  createdAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  media: PropType.arrayOf(PropType.string),
  name: PropType.string,
  search: PropType.string,
  text: PropType.string,
  type: PropType.oneOf(messageTypes),
  updatedAt: PropType.string, // "2021-06-04T12:28:43.881Z"
  contentType: PropType.oneOf([
    CONTENT_TYPE.email,
    CONTENT_TYPE.messageTemplate,
    CONTENT_TYPE.voiceDrop
  ]),
  user: PropType.oneOfType([
    PropType.string,
    PropType.shape(userShape)
  ]),
  subject: PropType.string, // email
  preheader: PropType.string, // email
  unlayerDesign: PropType.object, // email
  transactional: PropType.bool // email
}
