import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/message-templates/actions/CreateAction'
import { MessageTemplates as TemplatesEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const MessageTemplateEmptyListContent = () => {
  const createActionRef = useRef()

  const onAddTemplate = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[280px]'>
        <TemplatesEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong text-center mb-3'>Create New Message Template</h1>
        <p className='label-sm text-center max-w-[240px]'>
          Create text based messages templates that you can use over and over again.
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={onAddTemplate}>
          Add Message Template
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

MessageTemplateEmptyListContent.displayName = 'MessageTemplateEmptyListContent'
MessageTemplateEmptyListContent.propTypes = propTypes
MessageTemplateEmptyListContent.defaultProps = defaultProps

export default MessageTemplateEmptyListContent
