import * as React from "react";
import { memo } from "react";
const SvgCollapse = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.756 2.244a.833.833 0 0 1 0 1.179L15.678 9.5H19.5a.833.833 0 1 1 0 1.667h-5.833a.833.833 0 0 1-.834-.834V4.5a.833.833 0 1 1 1.667 0v3.821l6.077-6.077a.833.833 0 0 1 1.179 0ZM2.244 21.756a.833.833 0 0 1 0-1.179L8.322 14.5H4.5a.833.833 0 1 1 0-1.667h5.833c.46 0 .834.373.834.834V19.5a.833.833 0 0 1-1.667 0v-3.822l-6.077 6.078a.833.833 0 0 1-1.179 0Z"
    />
  </svg>
);
const Memo = memo(SvgCollapse);
export default Memo;
