import * as React from "react";
import { memo } from "react";
const SvgPhoneOutboundCall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.709.885 2.91 2.91a3.01 3.01 0 0 1 0 4.265L8.697 9.98a11.571 11.571 0 0 0 5.317 5.316l1.92-1.92a3.01 3.01 0 0 1 4.264 0l2.91 2.91a3.01 3.01 0 0 1 0 4.264l-1.38 1.38a7.107 7.107 0 0 1-6.398 1.934C7.67 22.376 1.628 16.322.13 8.664L.129 8.66c-.44-2.313.253-4.71 1.935-6.394l1.38-1.38a3.01 3.01 0 0 1 4.265 0ZM6.294 2.3c-.4-.4-1.036-.4-1.435 0l-1.38 1.38C2.282 4.876 1.774 6.597 2.093 8.283c1.343 6.86 6.761 12.286 13.62 13.618a5.109 5.109 0 0 0 4.601-1.386l1.38-1.38c.4-.4.4-1.036 0-1.435l-2.91-2.91c-.4-.4-1.036-.4-1.435 0l-2.41 2.41a1 1 0 0 1-1.094.215 13.565 13.565 0 0 1-7.266-7.267 1 1 0 0 1 .215-1.093l2.41-2.41c.4-.4.4-1.036 0-1.435L6.294 2.3Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPhoneOutboundCall);
export default Memo;
