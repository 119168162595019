import loadFromCacheOrService from '../lib/loadFromCacheOrService'
import { messageTagUrlInfo } from '../services/message'

import { firstMediaUrl } from './messageMedia'

export const messageDirections = ['in', 'out']

export const messageTags = {
  attachment: { start: '{<', end: '>}' },
  dynamicField: { start: '{{', end: '}}' },
  configurableForm: { start: '<<', end: '>>' },
  landingPage: { start: '{[', end: ']}' },
  trackableLink: { start: '[[', end: ']]' }
}

export function isMessageEmpty (message) {
  if (!message) { return true }
  const media = firstMediaUrl(message)
  if (!message.text && !media) { return true }
}

// returns false or an error message
// message is one of:
//   { text: 'message body', ... }
//   'message body'
export async function hasHardcodedDynamicLinks (message) {
  const text = message?.text || message
  if (!text) { return false }
  const tagUrlInfo = await loadFromCacheOrService('messageTagUrlInfo', messageTagUrlInfo)
  if (!tagUrlInfo?.tagUrls.length) { return false } // just assume it is ok if we ran into an issue here
  const hardCodedUrl = tagUrlInfo.tagUrls.find(({ finder }) => {
    const regex = new RegExp(finder, 'i')
    return regex.test(text)
  })
  if (hardCodedUrl) {
    return `We've detected a ${hardCodedUrl.name} URL. Please use the insert button to add your link.`
  }
  return false
}
