import { formatDistanceToNow, isValid } from 'date-fns'
import { isNumber, keyBy } from 'lodash'

import { localTimeZone } from './timeZones'

export function formatDateMonth (dateMonth) {
  // 'January 1st'
  if (!isNumber(dateMonth?.date) || !isNumber(dateMonth?.month)) { return '' }
  const { date, month } = dateMonth

  const ordinalMap = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
  }
  const pluralRule = new Intl.PluralRules('en-us', { type: 'ordinal' })
  const ordinal = ordinalMap[pluralRule.select(date)]

  const newDate = new Date(2020, month, date) // 2020 is a leap year
  const monthName = Intl.DateTimeFormat('en-us', { month: 'long' }).format(newDate)
  return `${monthName} ${date}${ordinal}`
}

export function formatExtendedDateAndTime (dateTime, timeZone) {
  // 'Sunday, January 1, 2023, 3:23 AM'
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    weekday: 'long',
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: timeZone !== localTimeZone ? 'short' : undefined
  }).format(date)
}

export function formatExtendedDate (dateTime, timeZone) {
  // 'Sunday, January 1, 2023'
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    weekday: 'long',
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    timeZone
  }).format(date)
}

export function formatExtendedDate2 (dateTime, timeZone) {
  // 'Sun, Jan 1, 2023'
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    weekday: 'short',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    timeZone
  }).format(date)
}

export function formatAbbreviatedDate (dateTime, timeZone) {
  // 'Jan 1, 2023'
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    timeZone
  }).format(date)
}

export function formatShortDateAndTime (dateTime, timeZone) {
  // 1/1/2023 3:23 AM
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: timeZone !== localTimeZone ? 'short' : undefined
  }).format(date)
}

export function formatDateForInput (dateTime, timeZone) {
  // 2023-01-30 (required for input type='date')
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  const tzParts = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone
  }).formatToParts(date)
  const { day, month, year } = keyBy(tzParts, 'type')
  return `${year.value}-${month.value}-${day.value}`
}

export function formatDateMonthDayYear (dateTime) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const date = new Date(dateTime)
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month}. ${day}, ${year}`
}

export function formatTimeForInput (dateTime, timeZone) {
  // 19:00 (required for input type='time')
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
    timeZone
  }).format(date)
}

export function formatTimeOfDay (dateTime, timeZone) {
  // 3:23 AM
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  return new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: timeZone !== localTimeZone ? 'short' : undefined
  }).format(date)
}

export function formatMonthDayOrdinal (dateTime, timeZone) {
  // February 29th, September 2nd, November 1st, October 3rd
  if (!timeZone) { throw new Error('timeZone required') }
  const date = dateOrThrowError(dateTime)
  const getOrdinal = (day) => {
    if (day > 3 && day < 21) { return 'th' }
    switch (day % 10) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  }
  const month = new Intl.DateTimeFormat('default', { month: 'long', timeZone }).format(date)
  const day = new Intl.DateTimeFormat('default', { day: 'numeric', timeZone }).format(date)
  return `${month} ${day}${getOrdinal(day)}`
}

export function formatTimeDistance (dateTime/*, timeZone */) {
  // 2 minutes ago
  return formatDistanceToNow(new Date(dateTime))
}

export function formatName (contactOrUser) {
  if (!contactOrUser) { return '' }
  let from = [contactOrUser.firstName, contactOrUser.lastName].join(' ').trim()
  if (!from) { from = contactOrUser.company }
  if (!from) { from = formatPhone(contactOrUser.phoneNumber) }
  return from
}

export function formatPhone (phoneNumber) {
  if (!phoneNumber) { return '' }

  const digits = phoneNumber.replace(/\D+/g, '')

  if (digits.length === 11 && digits[0] === '1') {
    return digits.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3-$4')
  } else if (digits.length === 10) {
    return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  } else {
    // pb doesn't currently handle non us/ca numbers.
    return ''
  }
}

export function smsToUri (phoneNumber, message, countryCode = '+1') {
  return `smsto:${countryCode}${phoneNumber}:${message}`
}

export function formatAvatarInitials (contactOrUser = {}) {
  function firstAlphaNumeric (str) {
    str = str || ''
    const match = str.toString().match(/[a-z0-9]/i)
    return match ? match[0] : ''
  }

  const { firstName, lastName } = contactOrUser
  return `${firstAlphaNumeric(firstName)}${firstAlphaNumeric(lastName)}`.toLocaleUpperCase()
}

export function mfaChannelVerb (channel) {
  switch (channel) {
    case 'sms':
      return 'Text'
    case 'call':
      return 'Call'
    default:
      return ''
  }
}

export function mfaViaFormatted (mfaMethod) {
  if (!mfaMethod?.channel) { return '' }

  const { channel, redactedTo, to } = mfaMethod
  switch (channel) {
    case 'sms':
    case 'call':
      return redactedTo || (to && formatPhone(to))
    default:
      return ''
  }
}

export function formatNumber (number, opts = {}) {
  if (!isNumber(number)) { return '' }

  return Intl.NumberFormat('en-US', opts).format(number)
}

export function cardLastFour (number) {
  if (!isNumber(number) && !number?.toString().match(/\d{1,4}$/)) { return '' }

  const str = number.toString()
  return str.length > 4 ? str.slice(-4) : str.padStart(4, '0')
}

export function centsToDollars (cents) {
  if (!isNumber(cents)) { return '' }

  return (cents / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const pluralize = ({ count, singular, plural, zero, includeCount }) => {
  if (!singular || !isNumber(count)) { return '' }

  plural ||= `${singular}s`
  zero ||= plural
  const phrase = count === 1
    ? singular
    : count === 0
      ? zero
      : plural
  return includeCount === true ? `${formatNumber(count)} ${phrase}` : phrase
}

function dateOrThrowError (dateOrString) {
  let date = dateOrString
  if (typeof dateOrString === 'string') {
    try {
      date = new Date(dateOrString)
    } catch (e) {
      // isValid will throw the error.
    }
  }
  if (!isValid(date)) { throw new Error(`invalid Date: ${JSON.stringify(dateOrString)}`) }
  return date
}

// NOTE: duplicated on the server in lib.tagHelper
// NOTE: formatTag does not lowercase the tag but the tag model plugin will lowercase it... leaving this as-is to match server
export const formatTag = (text) => {
  return (text || '').trim().replace(/[^a-zA-Z0-9-_ ]+/g, '')
}
