import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import { formatName } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import contact from '../../../prop-types/shapes/contact'
import { callContact } from '../../../services/voice'
import { success } from '../../banners/Banner'

const propTypes = {
  contact: PropType.shape(contact).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CallAction'] })

// NOTE: also used by chat threads and passes a chat for the contact
const CallAction = forwardRef(({ contact }, ref) => {
  useLogger({ log, lifecycle: false, tags: [contact._id] })

  const { call: callContactCall } = useService(callContact, {
    onReplyOk: useCallback(() => {
      success(`Calling to connect you with ${formatName(contact)}`)
    }, [contact])
  })

  useImperativeHandle(ref, () => ({
    activate () { callContactCall(contact._id) }
  }), [callContactCall, contact])

  return null
})

CallAction.displayName = 'CallAction'
CallAction.propTypes = propTypes
CallAction.defaultProps = defaultProps

export default CallAction
