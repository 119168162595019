import PropType from 'prop-types'
import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import DetailSection from '../../components/detail-sections/DetailSection'
import useLogger from '../../hooks/useLogger'
import { copyToClipboard } from '../../lib/copyToClipboard'
import logger from '../../lib/logger'

const propTypes = {
  url: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CopyOptInLink'] })

const CopyOptInLink = ({ url }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleCopyClick = useCallback(() => {
    copyToClipboard(url)
    notify('Link copied to clipboard')
  }, [url])

  return (
    <DetailSection
      title='Copy and share your link'
      showCopyIcon
      onCopyClick={handleCopyClick}
    >
      <div className='select-text'>{url}</div>
    </DetailSection>
  )
}

CopyOptInLink.displayName = 'CopyOptInLink'
CopyOptInLink.propTypes = propTypes
CopyOptInLink.defaultProps = defaultProps

export default CopyOptInLink
