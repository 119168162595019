import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import ContactList from '../../contacts/ContactList'
import ListDialog from '../../dialog/ListDialog'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['OpenChatAction'] })

const OpenChatAction = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listDialogRef = useRef()

  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    activate () { listDialogRef.current.open() }
  }), [])

  const handleSubmitSelected = useCallback((selected) => {
    navigate(`/chats/${selected._id}`)
  }, [navigate])

  return (
    <ListDialog
      ref={listDialogRef}
      list={ContactList}
      multiple={false}
      title='Open Chat'
      type='select'
      onSubmitSelected={handleSubmitSelected}
    />
  )
})

OpenChatAction.displayName = 'OpenChatAction'
OpenChatAction.propTypes = propTypes
OpenChatAction.defaultProps = defaultProps

export default OpenChatAction
