import PropType from 'prop-types'
import { forwardRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { searchTemplates as appFlowTemplateSearch } from '../../services/appFlow'
import List from '../list/List'

import AppFlowTemplateListItemContent from './AppFlowTemplateListItemContent'

const propTypes = {
  additionalHandlers: PropType.func,
  queryOverrides: PropType.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  additionalHandlers: () => {},
  queryOverrides: {}
}

const resultsFields = [
  '_id',
  'name',
  'description'
]

const log = logger({ enabled: true, tags: ['AppFlowTemplateList'] })

const AppFlowTemplateList = forwardRef(({ additionalHandlers, queryOverrides, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tag: [listProps.type] })

  additionalHandlers?.('appFlowTemplate', ref)

  return (
    <List
      ref={ref}
      ListItemContent={AppFlowTemplateListItemContent}
      placeholder='Search'
      queryOverrides={queryOverrides}
      resultsFields={resultsFields}
      resultsKey='appFlowTemplates'
      searchService={appFlowTemplateSearch}
      search
      {...listProps}
    />
  )
})

AppFlowTemplateList.displayName = 'AppFlowTemplateList'
AppFlowTemplateList.propTypes = propTypes
AppFlowTemplateList.defaultProps = defaultProps

export default AppFlowTemplateList
