import { omit } from 'lodash'
import PropType from 'prop-types'

import contactFromJSON from '../models/contact'
import scheduledBroadcastFromJSON from '../models/scheduledBroadcast'

import pb from './pb'
import { addRemoveAllInQueryPropTypes, addRemoveSelectedPropTypes, searchPropTypes, tagContactsPropTypes } from './scheduledBroadcast-contact.prop-types'

export function search (data, background = false) {
  PropType.checkPropTypes(searchPropTypes, data, 'prop', 'scheduledBroadcast-contact#search')

  const { scheduledBroadcastId } = data
  const body = omit(data, 'scheduledBroadcastId')
  const model = {
    conversion: contactFromJSON,
    key: 'contacts',
    multiple: true
  }
  return pb.setupPost(`schedule/jobs/${scheduledBroadcastId}/contacts/search`, { body, background, model })
}

export function addSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'scheduledBroadcast-contact#addSelected')
  const { scheduledBroadcastId } = data
  const body = omit(data, 'scheduledBroadcastId')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/contacts/add`, {
    body,
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}

export function addAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'scheduledBroadcast-contact#addAllInQuery')
  const { scheduledBroadcastId } = data
  const body = omit(data, 'scheduledBroadcastId')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/contacts/add/all`, {
    body,
    model: {
      conversion: scheduledBroadcastFromJSON
    }

  })
}

export function removeSelected (data) {
  PropType.checkPropTypes(addRemoveSelectedPropTypes, data, 'prop', 'scheduledBroadcast-contact#removeSelected')
  const { scheduledBroadcastId } = data
  const body = omit(data, 'scheduledBroadcastId')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/contacts/remove`, {
    body,
    model: {
      conversion: scheduledBroadcastFromJSON
    }

  })
}

export function removeAllInQuery (data) {
  PropType.checkPropTypes(addRemoveAllInQueryPropTypes, data, 'prop', 'scheduledBroadcast-contact#removeAllInQuery')
  const { scheduledBroadcastId } = data
  const body = omit(data, 'scheduledBroadcastId')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/contacts/remove/all`, {
    body,
    model: {
      conversion: scheduledBroadcastFromJSON
    }

  })
}

export function searchByState (data, background) {
  PropType.checkPropTypes(this.searchByStatePropTypes, data, 'prop', 'scheduledBroadcast-contact#searchByState')
  const { scheduledBroadcastId } = data
  return pb.setupPost(`schedule/jobs/${scheduledBroadcastId}/contacts/results/search`, {
    body: omit(data, 'scheduledBroadcastId'),
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contacts',
      multiple: true
    }
  })
}

export function tagContacts (scheduledBroadcastId, data) {
  PropType.checkPropTypes(tagContactsPropTypes, data, 'prop', 'scheduledBroadcast-contact#tagContacts')
  return pb.setupPut(`schedule/jobs/${scheduledBroadcastId}/contacts/tag`, {
    body: data,
    model: {
      conversion: scheduledBroadcastFromJSON
    }
  })
}
