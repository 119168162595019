import * as React from "react";
import { memo } from "react";
const SvgTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.5 6.726a7.046 7.046 0 0 1-2.062.58 3.677 3.677 0 0 0 1.578-2.037 7.086 7.086 0 0 1-2.28.894A3.546 3.546 0 0 0 15.116 5c-1.983 0-3.59 1.648-3.59 3.68 0 .29.031.57.093.84-2.983-.154-5.629-1.619-7.4-3.846a3.738 3.738 0 0 0-.486 1.85c0 1.278.634 2.404 1.597 3.064a3.511 3.511 0 0 1-1.627-.46v.047c0 1.783 1.238 3.27 2.88 3.609a3.504 3.504 0 0 1-1.621.063c.456 1.462 1.783 2.526 3.353 2.556A7.089 7.089 0 0 1 3 17.93a9.983 9.983 0 0 0 5.503 1.654c6.603 0 10.215-5.61 10.215-10.474 0-.16-.004-.319-.01-.477a7.4 7.4 0 0 0 1.79-1.905h.002Z"
    />
  </svg>
);
const Memo = memo(SvgTwitter);
export default Memo;
