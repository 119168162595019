import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'
import TemplateList from '../templates/TemplateList'

const propTypes = {
  additionalHandlers: PropType.func,
  placeholder: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object
}

const defaultProps = {
  additionalHandlers: () => {},
  placeholder: 'Search Voice Drops',
  queryOverrides: {}
}

const log = logger({ enabled: false, tags: ['VoiceDropList'] })

const VoiceDropList = forwardRef(({
  additionalHandlers,
  placeholder,
  queryOverrides,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })
  const combinedQueryOverrides = { contentType: [CONTENT_TYPE.voiceDrop], ...queryOverrides }

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('voiceDropCreated', handleBusEvent)
  useBus('voiceDropCloned', handleBusEvent)
  useBus('voiceDropUpdated', handleBusEvent)
  useBus('voiceDropDeleted', handleBusEvent)
  useBus('voiceDropsDeleted', handleBusEvent)

  additionalHandlers?.('voiceDrop', ref)

  return (
    <TemplateList
      ref={ref}
      placeholder={placeholder}
      {...listProps}
      queryOverrides={combinedQueryOverrides}
    />
  )
})

VoiceDropList.displayName = 'VoiceDropList'
VoiceDropList.propTypes = propTypes
VoiceDropList.defaultProps = defaultProps

export default VoiceDropList
