import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import FormattedDate from '../../../components/FormattedDate'
import { canEditBroadcast } from '../../../helpers/scheduledBroadcast'
import useLogger from '../../../hooks/useLogger'
import useTimeZone from '../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { dateAtDayAndTimeInTimeZone } from '../../../lib/timeZones'
import scheduledBroadcastShape from '../../../prop-types/shapes/scheduledBroadcast'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['ScheduledAtDetail'] })

const ScheduledAtDetail = ({ scheduledBroadcast, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [scheduledBroadcast._id] })

  const { userTimeZone } = useTimeZone()

  const { confirm, formControls, defaultValues, transformData, value } = useMemo(() => ({
    confirm: {
      description: `Are you sure you want to schedule this broadcast to send immediately?
                    Clicking OK will cause your broadcast to begin sending to your recipients now.`,
      when: ({ scheduledAt }) => !!scheduledAt && (new Date(scheduledAt) < new Date())
    },
    formControls: [
      { label: '', name: 'date', type: 'date' },
      { label: '', name: 'time', type: 'time-message-send' }
    ],
    defaultValues: scheduledBroadcast.scheduledAt && {
      date: formatDateForInput(scheduledBroadcast.scheduledAt, userTimeZone),
      time: formatTimeForInput(scheduledBroadcast.scheduledAt, userTimeZone)
    },
    transformData: ({ date, time }) => ({
      scheduledAt: dateAtDayAndTimeInTimeZone(date, time, userTimeZone)?.toISOString()
    }),
    value: <FormattedDate date={scheduledBroadcast.scheduledAt} format='extendedDateAndTime' />
  }), [scheduledBroadcast, userTimeZone])

  return (
    <FormDetailSection
      confirm={confirm}
      defaultValues={defaultValues}
      description='The date and time to start sending this broadcast.'
      formControls={formControls}
      formDescription='Update the date and time for this Broadcast'
      formTitle='Select Date and Time'
      initialEditButtonText='Set Date and Time'
      objectId={scheduledBroadcast._id}
      service={service}
      showEditIcon={scheduledBroadcast.state === 'scheduled' && canEditBroadcast(scheduledBroadcast)}
      showInitialEditButton={!scheduledBroadcast.scheduledAt}
      title='Schedule'
      transformData={transformData}
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

ScheduledAtDetail.displayName = 'ScheduledAtDetail'
ScheduledAtDetail.propTypes = propTypes
ScheduledAtDetail.defaultProps = defaultProps

export default ScheduledAtDetail
