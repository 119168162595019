import PropType from 'prop-types'
import { forwardRef, useCallback, useId, useImperativeHandle, useRef, useState } from 'react'

import { editorConfigDefaults, excludeContactPaths, excludeOtherPaths, excludeUserPaths } from '../../helpers/unlayer-editor'
import useCurrentUser from '../../hooks/useCurrentUser'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import useScript from '../../hooks/useScript'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { allWordsWithPublicLinkForUser } from '../../services/keyword'
import { editorConfig as editorConfigService, editorPreview } from '../../services/unlayer'
import DynamicFieldsDialog from '../dynamic-fields-dialog/DynamicFieldsDialog'

const propTypes = {
  DisplayConditionsDialog: PropType.elementType.isRequired,
  displayMode: PropType.oneOf(['web', 'email']).isRequired,
  onReady: PropType.func
}

const defaultProps = {
  onReady: undefined
}

const log = logger({ enabled: true, tags: ['UnlayerEditor'] })

const UnlayerEditor = forwardRef(({
  DisplayConditionsDialog,
  displayMode,
  onReady
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })
  const id = useId()
  const fieldsDialogRef = useRef(null)
  const displayConditionsDialogRef = useRef(null)
  const [error, setError] = useState(false)
  const [editor, setEditor] = useState()
  const mergeTagDoneFnRef = useRef(null)
  const displayConditionDoneFnRef = useRef(null)
  const previewFnRef = useRef(null)

  const currentUser = useCurrentUser()

  const handleMergeTagSelect = useCallback((selection) => {
    mergeTagDoneFnRef.current({ name: selection.name, value: selection.code })
  }, [])
  const handleConditionsSelect = useCallback((condition) => {
    displayConditionsDialogRef.current.close()
    displayConditionDoneFnRef.current(condition)
  }, [])

  const handlePreviewReplyOk = useCallback((reply) => {
    previewFnRef.current({ html: reply.json.html })
  }, [])
  const { call: loadEditorPreview } = useService(editorPreview, { onReplyOk: handlePreviewReplyOk })

  const handleFetchKeywordsReplyOk = useCallback((reply) => {
    const options = reply.json.map(({ word, publicTextKeywordLink }) => ({ value: publicTextKeywordLink, label: word }))
    editor.setLinkTypes([
      { name: 'sms', enabled: true },
      {
        name: 'sms_keyword',
        label: 'SMS Keyword',
        attrs: {
          href: '{{publicTextKeywordLink}}',
          target: '_blank'
        },
        fields: [
          {
            name: 'publicTextKeywordLink',
            label: 'Keyword',
            inputType: null,
            placeholderText: null,
            options
          }
        ]
      }
    ])
  }, [editor])
  const { call: fetchKeywords } = useService(allWordsWithPublicLinkForUser, { onReplyOk: handleFetchKeywordsReplyOk })

  const handleEditorReady = useCallback(() => {
    if (onReady) { onReady() }
    fetchKeywords()
  }, [fetchKeywords, onReady])

  const handleEditorConfigReplyOk = useCallback((reply) => {
    const editor = unlayer.createEditor({ // eslint-disable-line no-undef
      ...editorConfigDefaults(currentUser, displayMode),
      ...reply.json,
      id
    })
    editor.setDisplayConditions([])
    editor.registerCallback('mergeTag', function (data, done) {
      mergeTagDoneFnRef.current = done
      fieldsDialogRef.current.open()
    })
    editor.registerCallback('displayCondition', function (data, done) {
      displayConditionDoneFnRef.current = done
      displayConditionsDialogRef.current.open()
    })
    editor.registerCallback('previewHtml', function (data, done) {
      previewFnRef.current = done
      loadEditorPreview(data.html)
    })
    editor.addEventListener('editor:ready', handleEditorReady)
    setEditor(editor)
  }, [currentUser, displayMode, handleEditorReady, id, loadEditorPreview])
  const handleEditorConfigReplyNotOk = useCallback(() => {
    setError('Failed to load configuration for editor.')
  }, [])
  const { call: fetchEditorConfig } = useService(editorConfigService, { onReplyOk: handleEditorConfigReplyOk, onReplyNotOk: handleEditorConfigReplyNotOk })

  const handleScriptLoad = useCallback(() => {
    fetchEditorConfig()
  }, [fetchEditorConfig])

  const handleScriptLoadError = useCallback(() => { setError('Failed to load editor.') }, [])

  useScript({
    src: 'https://editor.unlayer.com/embed.js',
    onLoad: handleScriptLoad,
    onError: handleScriptLoadError
  })

  useImperativeHandle(ref, () => {
    const publicApi = {}
    const editorMethodsToExpose = [
      'addEventListener',
      'removeEventListener',
      'exportHtml',
      'loadDesign'
    ]
    editorMethodsToExpose.forEach((methodName) => {
      publicApi[methodName] = function (...args) {
        editor[methodName](...args)
      }
    })
    return publicApi
  }, [editor])

  return (
    <div className='flex flex-1 min-height[500px]'>
      <div className='flex flex-1' id={id} />
      {error ? <p className='text-error'>{error}</p> : null}
      <DynamicFieldsDialog
        ref={fieldsDialogRef}
        excludeContactPaths={excludeContactPaths}
        excludeOtherPaths={excludeOtherPaths}
        excludeUserPaths={excludeUserPaths}
        forceOverlay
        onSelect={handleMergeTagSelect}
      />
      <DisplayConditionsDialog
        ref={displayConditionsDialogRef}
        forceOverlay
        onSelect={handleConditionsSelect}
      />
    </div>
  )
})

UnlayerEditor.displayName = 'UnlayerEditor'
UnlayerEditor.propTypes = propTypes
UnlayerEditor.defaultProps = defaultProps

export default UnlayerEditor
