import { Contacts } from '@capacitor-community/contacts'
import PropType from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'

import Banner, { error, warning } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import FormControl from '../../../components/forms/FormControl'
import Panel from '../../../components/panels/Panel'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import { getContactsAsCSVFromDevice } from '../../../helpers/contactCsv'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import rollbar from '../../../lib/rollbar'

import { ImportContext } from './ImportContext'

const propTypes = {
  onImportAllContacts: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['FromDevice'] })

const FromDevice = ({
  onImportAllContacts
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [hasPermission, setHasPermission] = useState(null)
  const [additionalTags, setAdditionalTags] = useState('')

  useEffect(() => {
    async function checkPermissions () {
      let permission = await Contacts.checkPermissions()
      if (permission?.contacts === 'granted') {
        setHasPermission(true)
      } else {
        permission = await Contacts.requestPermissions()
        setHasPermission(permission?.contacts === 'granted')
      }
    }
    checkPermissions()
  }, [])

  const { handleBackButtonClick } = useContext(ImportContext)

  const handleImportContacts = useCallback(async () => {
    try {
      const csvFileLikeObj = await getContactsAsCSVFromDevice()

      if (!csvFileLikeObj) {
        warning('No Contacts', 'Did not find any contacts to import.')
        return
      }

      const mappings = {
        firstName: 'first',
        lastName: 'last',
        phoneNumber: 'phone',
        email: 'email',
        birthday: 'birthday',
        additionalTags
      }

      onImportAllContacts(csvFileLikeObj, mappings)
    } catch (e) {
      error('Could not import contacts from device')
      rollbar.error('Could not import contacts from device', { hasPermission })
      log.error(e)
    }
  }, [additionalTags, hasPermission, onImportAllContacts])

  const handleAdditionalTagsChange = useCallback((event) => {
    setAdditionalTags(event.target.value)
  }, [])

  const title = 'Import Your Contacts'

  if (hasPermission === null) {
    return <PanelLoading title={title} />
  }

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderButton icon='back' onClick={handleBackButtonClick} />} title={title} />
      <PanelContent className='p-4'>
        {!hasPermission ? <Banner className='mb-4' title='Permissions Required' type='warning'>Please allow access to your contacts in order to import them.</Banner> : null}
        <p className='mb-4'>This tool will attempt to import all the contacts on your device that have a phone number. If a contact has more than one phone number, it will prefer the phone number with the "Mobile" label. Additionally it will include the contacts name, company name, email, and birthday if present.</p>
        <FormControl
          autoCapitalize='none'
          disabled={!hasPermission}
          hint='Enter additional comma separated tags above that you want associated with all the contacts in your import. Tags may only contain letters, numbers, spaces, dashes, and underscores.'
          label='Additional Tags'
          name='additionalTags'
          placeholder='Ex: vip, customers'
          type='text'
          onChange={handleAdditionalTagsChange}
        />
        <Button
          className='w-auto mx-auto'
          disabled={!hasPermission}
          size='md'
          onClick={handleImportContacts}
        >
          Import Contacts
        </Button>
      </PanelContent>
    </Panel>
  )
}

FromDevice.displayName = 'FromDevice'
FromDevice.propTypes = propTypes
FromDevice.defaultProps = defaultProps

export default FromDevice
