import { map } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import ContactList from '../../components/contacts/ContactList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import ScheduledBroadcastContactList from '../../components/scheduled-broadcast/ScheduledBroadcastContactList'
import { canEditBroadcast } from '../../helpers/scheduledBroadcast'
import useCurrentScheduledBroadcast from '../../hooks/useCurrentScheduledBroadcast'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../services/scheduledBroadcast-contact'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastContactsList'] })

const ScheduledBroadcastContactsList = () => {
  const { scheduledBroadcastId, loading, scheduledBroadcast, setScheduledBroadcast } = useCurrentScheduledBroadcast()

  useLogger({ log, lifecycle: false, tags: [scheduledBroadcastId] })

  const scheduledBroadcastContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    success('Contacts updated')
    scheduledBroadcastContactListRef.current.reload()
    const updatedScheduledBroadcast = reply.model
    setScheduledBroadcast(updatedScheduledBroadcast)
    bus.emit('scheduledBroadcastUpdated', updatedScheduledBroadcast)
  }, [setScheduledBroadcast])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ scheduledBroadcastId, ...query })
  }, [addAllInQueryCall, scheduledBroadcastId])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ scheduledBroadcastId, contactIds: map(selected, '_id') })
  }, [addSelectedCall, scheduledBroadcastId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ scheduledBroadcastId, ...query })
  }, [scheduledBroadcastId, removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ scheduledBroadcastId, contactIds: map(selected, '_id') })
  }, [scheduledBroadcastId, removeSelectedCall])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !scheduledBroadcast) { return <PanelLoading /> }

  const end = (
    <>
      {scheduledBroadcast.contacts.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={canEditBroadcast(scheduledBroadcast) ? end : null}
        start={<PanelHeaderBackButton />}
        subtitle={scheduledBroadcast.name}
        title='Contacts'
      />
      <PanelContent>
        <ScheduledBroadcastContactList
          ref={scheduledBroadcastContactListRef}
          scheduledBroadcastId={scheduledBroadcast._id}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={ScheduledBroadcastContactList}
        scheduledBroadcastId={scheduledBroadcast._id}
        subtitle={scheduledBroadcast.name}
        title='Remove Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        list={ContactList}
        scheduledBroadcastId={scheduledBroadcast._id}
        subtitle={scheduledBroadcast.name}
        title='Add Contact(s)'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

ScheduledBroadcastContactsList.displayName = 'ScheduledBroadcastContactsList'
ScheduledBroadcastContactsList.propTypes = propTypes
ScheduledBroadcastContactsList.defaultProps = defaultProps

export default ScheduledBroadcastContactsList
