import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as campaignSearch } from '../../services/campaign'
import List from '../list/List'

import CampaignListItemContent from './CampaignListItemContent'
import CampaignListSearchBar from './CampaignListSearchBar'

const propTypes = {
  additionalHandlers: PropType.func,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func
}

const defaultProps = {
  additionalHandlers: () => {},
  queryOverrides: {},
  searchService: campaignSearch
}

const log = logger({ enabled: false, tags: ['CampaignList'] })

const CampaignList = forwardRef(({ additionalHandlers, queryOverrides, searchService, ...listProps }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('campaignCreated', handleBusEvent)
  useBus('campaignCloned', handleBusEvent)
  useBus('campaignUpdated', handleBusEvent)
  useBus('campaignDeleted', handleBusEvent)
  useBus('campaignsDeleted', handleBusEvent)

  additionalHandlers?.('campaign', ref)

  const handleCampaignEventEvent = useCallback(() => {
    ref.current.indicateUpdatesDetected()
  }, [ref])
  useBus('campaignEventCreated', handleCampaignEventEvent)
  useBus('campaignEventUpdated', handleCampaignEventEvent)
  useBus('campaignEventDeleted', handleCampaignEventEvent)

  return (
    <List
      ref={ref}
      ListItemContent={CampaignListItemContent}
      ListSearchBar={CampaignListSearchBar}
      placeholder='Search Campaigns'
      queryOverrides={queryOverrides}
      resultsKey='campaigns'
      searchService={searchService}
      search
      {...listProps}
    />
  )
})

CampaignList.displayName = 'CampaignList'
CampaignList.propTypes = propTypes
CampaignList.defaultProps = defaultProps

export default CampaignList
