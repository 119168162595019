import { forwardRef } from 'react'

import List from '../../../components/list/List'
import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { search as goalSearch } from '../../../services/admin/goal'

import GoalListItemContent from './GoalListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['GoalList'] })

const GoalList = forwardRef(({ ...listProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [listProps.type] })
  ref = useDefaultRef(ref)

  return (
    <List
      ref={ref}
      ListItemContent={GoalListItemContent}
      multiple={false}
      placeholder='Goals'
      resultsFields={[
        '_id',
        'name',
        'enabled',
        'openEnded'
      ]}
      resultsKey='goals'
      searchService={goalSearch}
      type='nav'
      search
      {...listProps}
    />
  )
})

GoalList.displayName = 'GoalList'
GoalList.propTypes = propTypes
GoalList.defaultProps = defaultProps

export default GoalList
