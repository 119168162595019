import * as React from "react";
import { memo } from "react";
const SvgTrash = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Trash_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 2c-.548 0-1 .452-1 1v1h8V3c0-.548-.452-1-1-1H9Zm9 2V3c0-1.652-1.348-3-3-3H9C7.348 0 6 1.348 6 3v1H3a1 1 0 0 0 0 2h.064l.838 15.166A3.005 3.005 0 0 0 6.9 24h10.21a3.005 3.005 0 0 0 2.998-2.834L20.946 6H21a1 1 0 1 0 0-2h-3Zm-1 2H5.067l.831 15.053c.03.53.472.947 1.002.947h10.21c.53 0 .971-.416 1.002-.946L18.942 6H17Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Trash_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgTrash);
export default Memo;
