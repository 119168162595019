const { useEffect, useState } = require('react')

const { stateKeyFromStackKey } = require('../lib/folder')
const { default: useStore } = require('../store')

const { default: useCurrentStackKey } = require('./useCurrentStackKey')

function useCurrentListFolderMode () {
  const listFolderModes = useStore((state) => state.listFolderModes)
  const stackKey = useCurrentStackKey()
  const [listFolderMode, setListFolderMode] = useState(false)
  useEffect(() => {
    const stateKey = stateKeyFromStackKey(stackKey)
    const mode = listFolderModes[stateKey]
    setListFolderMode(mode)
  }, [listFolderModes, stackKey])
  return listFolderMode
}

export default useCurrentListFolderMode
