import PropType from 'prop-types'
import { useMemo } from 'react'

import { durationSummary } from '../../helpers/scheduledBroadcast'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import scheduledBroadcastShape from '../../prop-types/shapes/scheduledBroadcast'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape)
}

const defaultProps = {
  scheduledBroadcast: null
}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastSendToInfo'] })

const ScheduledBroadcastSendToInfo = ({ scheduledBroadcast }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser()

  const estimatedBroadcastTimeMins = scheduledBroadcast?.contacts.estimatedBroadcastTimeMins
  const estimatedDuration = useMemo(() => durationSummary(estimatedBroadcastTimeMins), [estimatedBroadcastTimeMins])

  const creditsRequired = scheduledBroadcast?.contacts.length - scheduledBroadcast?.contacts.contactUsersCount
  const showCreditWarning = creditsRequired > currentUser.messageCredits

  const doNotPhrase = scheduledBroadcast?.type === 'email' ? 'Do Not Email' : 'Do Not Message'
  const contactsWithAddDetailSectionHint = `You may have ${doNotPhrase} contacts in the Send To list. Project Broadcast will ignore them at the time of sending if they are still marked as ${doNotPhrase}.`

  return scheduledBroadcast?.contacts.length > 0 && (
    <div className='-mt-1'>
      {!!showCreditWarning && (
        <div className='mb-2 p-2 bg-danger text-white'>
          {
            currentUser.phoneNumberType === 'managed-voip'
              ? 'You will need more credits to send this broadcast to the currently selected contacts.'
              : 'You will need to upgrade your account to send this broadcast to the currently selected contacts.'
          }
        </div>
      )}
      <div className={`mb-2 paragraph-sm ${estimatedBroadcastTimeMins > 5 ? 'text-orange-500' : ''}`}>
        {estimatedDuration}
      </div>
      <div className='mb-3 paragraph-sm'>
        {contactsWithAddDetailSectionHint}
      </div>
    </div>
  )
}

ScheduledBroadcastSendToInfo.displayName = 'ScheduledBroadcastSendToInfo'
ScheduledBroadcastSendToInfo.propTypes = propTypes
ScheduledBroadcastSendToInfo.defaultProps = defaultProps

export default ScheduledBroadcastSendToInfo
