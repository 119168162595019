import PropType from 'prop-types'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import TabButton from '../../../buttons/TabButton'

import CallTab from './CallTab'
import UploadTab from './UploadTab'

const propTypes = {
  selected: PropType.oneOf(['call', 'upload']).isRequired,
  onCallTabClick: PropType.func.isRequired,
  onUpload: PropType.func.isRequired,
  onUploadTabClick: PropType.func.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Tabs'] })

const Tabs = ({
  selected,
  onCallTabClick,
  onUpload,
  onUploadTabClick
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <div className='flex flex-row border-b border-neutral-200 mb-4'>
        <TabButton selected={selected === 'upload'} onClick={onUploadTabClick}>Upload</TabButton>
        <TabButton selected={selected === 'call'} onClick={onCallTabClick}>Call to Record</TabButton>
      </div>
      <div className='px-4'>
        {selected === 'upload' ? <UploadTab onUpload={onUpload} /> : <CallTab />}
      </div>
    </>
  )
}

Tabs.displayName = 'Tabs'
Tabs.propTypes = propTypes
Tabs.defaultProps = defaultProps

export default Tabs
