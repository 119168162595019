import { omit } from 'lodash'

import pb from './pb'

export function addSelected (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/contacts/add`, { body })
}

export function addAllInQuery (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/contacts/add/all`, { body })
}
