import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { formatNumber } from '../../lib/formatters'
import logger from '../../lib/logger'
import { defaultPaygPlanId, isPaygPlan, isPayingPlan, isSocialPlan, onHoldPlanIds } from '../../lib/plans'
import { plans as plansService } from '../../services/user'

import ChangeSubscriptionScheduledBanner from './ChangeSubscriptionScheduledBanner'
import ConfirmCancelSubscriptionDialog from './ConfirmCancelSubscriptionDialog'
import ConfirmOnHoldDialog from './ConfirmOnHoldDialog'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CancelSubscription'] })

const CancelSubscription = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const currentUser = useCurrentUser({ alsoReload: true })
  const navigate = useNavigate()
  const confirmCancelRef = useRef()
  const confirmHoldRef = useRef()
  const [currentPlan, setCurrentPlan] = useState(null)
  const handlePaygClick = useCallback(() => navigate(`/subscription/change-subscription?plan=${defaultPaygPlanId}`), [navigate])
  const handleOnHoldClick = useCallback(() => confirmHoldRef.current.open(), [confirmHoldRef])
  const handleCancelClick = useCallback(() => confirmCancelRef.current.open(), [confirmCancelRef])

  const loadPlans = useCallback((data) => {
    setCurrentPlan(data?.json?.currentPlan)
  }, [])
  const { call: getPlans } = useService(plansService, { onReplyOk: loadPlans })

  useEffect(() => {
    getPlans()
  }, [getPlans])

  const title = 'Cancel Subscription'

  if (!currentPlan) { return <PanelLoading title={title} /> }

  const subscriptionDetails = currentUser.subscriptionDetails
  const planCancelling = !!subscriptionDetails?.cancelsAt
  const planChanging = subscriptionDetails?.scheduledPlanId && subscriptionDetails.scheduledPlanId !== subscriptionDetails.currentPlanId

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title={title} />
      <PanelContent className='p-5'>
        <p className='mb-5'>
          You are currently subscribed to the
          {' '}
          <strong>
            {currentPlan.name}
            {' '}
            {currentPlan.frequency}
          </strong>
          {' '}
          plan and have
          {' '}
          <strong>
            {formatNumber(currentUser.messageCredits)}
            {' '}
            available credits
          </strong>
          {' '}
          on your account. Please review your options below.
        </p>
        {planCancelling || planChanging
          ? <ChangeSubscriptionScheduledBanner subscriptionDetails={subscriptionDetails} timezone={currentUser.timezone} />
          : null}
        {isPayingPlan(currentPlan) && !isPaygPlan(currentPlan) && !planChanging
          ? (
            <div className='mb-5'>
              <label className='block label-md-strong mb-1'>Change To The Pay As You Go Plan:</label>
              <p className='mb-5'>You want to continue using Project Broadcast but don't need as many credits per month. You will purchase credits when you need them.</p>
              <Button
                className='mx-auto'
                size='md'
                theme='primary'
                onClick={handlePaygClick}
              >
                Explore Pay As You Go
              </Button>
            </div>
            )
          : null}
        {!isSocialPlan(currentPlan) && !onHoldPlanIds.includes(currentUser.subscriptionDetails?.scheduledPlanId) && !planChanging
          ? (
            <div className='mb-5'>
              <label className='block label-md-strong mb-1'>Put Your Account On Hold:</label>
              <p className='mb-5'>You plan on using Project Broadcast in the future but don't have a current need. You want to keep your Project Broadcast phone number and accumulated message credits.</p>
              <Button
                className='mx-auto'
                size='md'
                theme='primary'
                onClick={handleOnHoldClick}
              >
                Explore On Hold
              </Button>
              <ConfirmOnHoldDialog ref={confirmHoldRef} />
            </div>)
          : null}
        {!planCancelling
          ? (
            <div>
              <label className='block label-md-strong mb-1'>Cancel Your Account:</label>
              <p className='mb-5'>You no longer have a need for Project Broadcast.</p>
              <Button
                className='mx-auto'
                size='md'
                theme='danger'
                onClick={handleCancelClick}
              >
                Cancel Anyway
              </Button>
              <ConfirmCancelSubscriptionDialog ref={confirmCancelRef} />
            </div>
            )
          : null}
      </PanelContent>
    </Panel>
  )
}

CancelSubscription.displayName = 'CancelSubscription'
CancelSubscription.propTypes = propTypes
CancelSubscription.defaultProps = defaultProps

export default CancelSubscription
