import { PushNotifications as NativePush } from '@capacitor/push-notifications'
import { useEffect } from 'react'

import { useApp } from '../contexts/AppContext'
import logger from '../lib/logger'
import resolveLaunchUrl from '../lib/resolveLaunchUrl'
import { subscribeToken } from '../services/push'
import useStore from '../store'

import { externalNavigate } from './ExternalNavigate'

const log = logger(['Push'])

async function initNativePush () {
  await addListeners()
  await registerNotifications()
}

async function addListeners () {
  await NativePush.addListener('registration', async (token) => {
    log.info('Registration complete. Token:', token.value)
    subscribeToken(token.value).call()
  })

  await NativePush.addListener('registrationError', (err) => {
    log.error('Registration error:', err.error)
  })

  await NativePush.addListener('pushNotificationReceived', (notification) => {
    log.debug('Notification received/ignored:', JSON.stringify(notification))
  })

  await NativePush.addListener('pushNotificationActionPerformed', ({ notification }) => {
    log.debug('Notification action performed', JSON.stringify(notification))
    if (notification.data.launchUrl) {
      const launchUri = resolveLaunchUrl(notification.data.launchUrl)
      log.info('Dispatching Launch Uri:', launchUri)
      externalNavigate(launchUri)
    }
  })
}

async function registerNotifications () {
  let permStatus = await NativePush.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await NativePush.requestPermissions()
  }

  if (permStatus.receive === 'granted') {
    await NativePush.register()
  }
}

function PushNotifications () {
  const currentUserId = useStore((state) => state.currentUser?._id)
  const { isNative } = useApp()

  useEffect(() => {
    if (currentUserId && isNative) {
      initNativePush()
      return () => NativePush.removeAllListeners()
    }
  }, [currentUserId, isNative])

  return undefined
}

export default PushNotifications
