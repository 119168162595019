import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import AttachmentList from '../../components/attachments/AttachmentList'
import { success } from '../../components/banners/Banner'
import CampaignList from '../../components/campaigns/CampaignList'
import ListDialog from '../../components/dialog/ListDialog'
import EmailList from '../../components/emails/EmailList'
import KeywordList from '../../components/keywords/KeywordList'
import LandingPageList from '../../components/landing-pages/LandingPageList'
import MessageTemplateList from '../../components/message-templates/MessageTemplateList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import VoiceDropList from '../../components/voice-drops/VoiceDropList'
import { bundleContentTypeLabel, bundleContentTypes } from '../../helpers/bundle'
import useCurrentBundle from '../../hooks/useCurrentBundle'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import {
  addAllInQuery as addAllInQueryService,
  addSelected as addSelectedService,
  removeAllInQuery as removeAllInQueryService,
  removeSelected as removeSelectedService,
  search as searchService
} from '../../services/bundle-content '

const bundleContentListMap = {
  attachments: AttachmentList,
  campaigns: CampaignList,
  emails: EmailList,
  keywords: KeywordList,
  landingPages: LandingPageList,
  messageTemplates: MessageTemplateList,
  voiceDrops: VoiceDropList
}

const propTypes = {
  contentType: PropType.oneOf(bundleContentTypes).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['BundlesContentList'] })

const BundlesContentList = ({ contentType }) => {
  const { bundleId, loading, bundle, setBundle } = useCurrentBundle()

  useLogger({ log, lifecycle: false, tags: [bundleId] })

  const bundleContentListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const BundleContentList = useMemo(() => bundleContentListMap[contentType], [contentType])

  const { contentName, title } = useMemo(() => {
    const contentName = bundleContentTypeLabel(contentType)
    return {
      contentName,
      title: `${contentName}s`
    }
  }, [contentType])

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleReplyOk = useCallback((reply) => {
    success(`${contentName}s updated`)
    bundleContentListRef.current.reload()
    setBundle(reply.model)
    bus.emit('bundleUpdated', reply.model)
  }, [contentName, setBundle])
  const { call: addSelected } = useService(addSelectedService, { onReplyOk: handleReplyOk })
  const { call: addAllInQuery } = useService(addAllInQueryService, { onReplyOk: handleReplyOk })
  const { call: removeSelected } = useService(removeSelectedService, { onReplyOk: handleReplyOk })
  const { call: removeAllInQuery } = useService(removeAllInQueryService, { onReplyOk: handleReplyOk })

  const handleAddSelected = useCallback((selected) => {
    addSelected({
      bundleId: bundle._id,
      contentType,
      [`${contentType}`]: selected.map((x) => x._id)
    })
  }, [addSelected, bundle, contentType])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQuery({
      bundleId: bundle._id,
      contentType,
      ...query
    })
  }, [addAllInQuery, bundle, contentType])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelected({
      bundleId: bundle._id,
      contentType,
      [`${contentType}`]: selected.map((x) => x._id)
    })
  }, [removeSelected, bundle, contentType])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQuery({
      bundleId: bundle._id,
      contentType,
      ...query
    })
  }, [removeAllInQuery, bundle, contentType])

  const end = (
    <>
      {bundle?.[contentType]?.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !bundle) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={bundle?.name}
        title={title}
      />
      <PanelContent>
        <BundleContentList
          ref={bundleContentListRef}
          queryOverrides={{
            bundleId: bundle?._id,
            contentType
          }}
          searchService={searchService}
          type='list'
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        bundleId={bundle?._id}
        list={BundleContentList}
        queryOverrides={{
          bundleId: bundle?._id,
          contentType
        }}
        searchService={searchService}
        subtitle={bundle?.name}
        title={`Remove ${contentName}`}
        type='remove'
        multiple
        search
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        bundleId={bundle?._id}
        list={BundleContentList}
        subtitle={bundle?.name}
        title={`Add ${contentName}`}
        type='add'
        multiple
        search
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

BundlesContentList.displayName = 'BundlesContentList'
BundlesContentList.propTypes = propTypes
BundlesContentList.defaultProps = defaultProps

export default BundlesContentList
