import * as React from "react";
import { memo } from "react";
const SvgNewWindow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 7c0-.548.452-1 1-1h1a1 1 0 0 0 0-2H5C3.348 4 2 5.348 2 7v12c0 1.652 1.348 3 3 3h12c1.652 0 3-1.348 3-3v-1a1 1 0 1 0-2 0v1c0 .548-.452 1-1 1H5c-.548 0-1-.452-1-1V7Zm8-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7.414l-9.293 9.293a1 1 0 0 1-1.414-1.414L16.586 6H13a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgNewWindow);
export default Memo;
