import React from 'react'

import ChatsThread from '../screens/chats/ChatsThread'
import ScheduledChatsThread from '../screens/chats/ScheduledChatsThread'
import ArchivedContacts from '../screens/contacts/ArchivedContacts'
import ContactsCampaigns from '../screens/contacts/ContactsCampaigns'
import ContactsDetail from '../screens/contacts/ContactsDetail'
import ContactsTags from '../screens/contacts/ContactsTags'
import ImportContacts from '../screens/contacts/ImportContacts'

const routes = [
  {
    path: 'archived',
    element: <ArchivedContacts />
  },
  {
    path: 'import',
    element: <ImportContacts />
  },
  // NOTE: this screen is also used in /chats/:contactId/contact
  {
    path: ':contactId',
    element: <ContactsDetail />
  },
  // NOTE: this screen is also used in /chats/:contactId/contact/tags
  {
    path: ':contactId/tags',
    element: <ContactsTags />
  },
  // NOTE: this screen is also used in /chats/:contactId/contact/campaigns
  {
    path: ':contactId/campaigns',
    element: <ContactsCampaigns />
  },
  // NOTE: this screen is also used in /chats/:contactId/scheduled
  {
    path: ':chatId/chat/scheduled',
    element: <ScheduledChatsThread />
  },
  {
    path: ':chatId/chat',
    element: <ChatsThread />
  }
]

export default routes
