import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import dynamicField from '../../prop-types/shapes/dynamicField'
import { DynamicField } from '../../svg/types'

const propTypes = {
  item: PropType.shape(dynamicField).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DynamicFieldListItemContent'] })

const DynamicFieldListItemContent = ({ item: dynamicField }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <DynamicField className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px]'>
        <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
          {dynamicField.name}
        </div>
        <div className='flex flex-col items-start label-xs text-neutral-500 mt-[3px] gap-1'>
          <div>{dynamicField.description}</div>
          <div>{dynamicField.code}</div>
        </div>
      </div>
    </>
  )
}

DynamicFieldListItemContent.displayName = 'DynamicFieldListItemContent'
DynamicFieldListItemContent.propTypes = propTypes
DynamicFieldListItemContent.defaultProps = defaultProps

export default DynamicFieldListItemContent
