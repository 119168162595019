import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  mediaUrl: PropType.string,
  size: PropType.oneOf(['sm', 'md', 'lg'])
}

const defaultProps = {
  mediaUrl: null,
  size: 'md'
}

const log = logger({ enabled: false, tags: ['ImageMessageFragment'] })

const ImageMessageFragment = ({ mediaUrl, size }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (!mediaUrl) { return }

  if (size === 'sm') {
    return (<div>(image)</div>)
  }

  return (
    <img className='h-12 mt-0.5' src={mediaUrl} />
  )
}

ImageMessageFragment.displayName = 'ImageMessageFragment'
ImageMessageFragment.propTypes = propTypes
ImageMessageFragment.defaultProps = defaultProps

export default ImageMessageFragment
