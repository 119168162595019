import * as React from "react";
import { memo } from "react";
const SvgChevronUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11.069 7.403-6.68 6.679a1.296 1.296 0 0 0 0 1.853c.52.52 1.347.52 1.854 0l5.759-5.759 5.758 5.759c.52.52 1.347.52 1.853 0 .254-.254.387-.6.387-.933 0-.334-.133-.68-.387-.934L12.935 7.39a1.296 1.296 0 0 0-1.853 0l-.014.013Z"
    />
  </svg>
);
const Memo = memo(SvgChevronUp);
export default Memo;
