import ExternalLink from '../../../components/links/ExternalLink'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Tags as TagsEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['EmptyContactTagsList'] })

const EmptyContactTagsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col items-center justify-center mx-auto h-full max-w-[240px]'>
      <TagsEmptyStateSvg className='my-6' />
      <p className='label-sm text-center'>
        Learn how to use
        {' '}
        <ExternalLink
          className='link'
          href='https://training.projectbroadcast.com/courses/tags'
          label='Tags'
        />
        {' '}
        to become a Project Broadcast ninja!
      </p>
    </div>
  )
}

EmptyContactTagsList.displayName = 'EmptyContactTagsList'
EmptyContactTagsList.propTypes = propTypes
EmptyContactTagsList.defaultProps = defaultProps

export default EmptyContactTagsList
