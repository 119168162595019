import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import message from '../../../prop-types/shapes/message'
import { More } from '../../../svg/icons'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import RateChatMessageAction from '../../aiva/actions/RateChatMessageAction'
import ResendChatMessageAction from '../../aiva/actions/ResendChatMessageAction'
import Button from '../../buttons/Button'

const propTypes = {
  message: PropType.shape(message).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageThreadItemActionMenuAiva'] })

const MessageThreadItemActionMenuAiva = forwardRef(({ message }, ref) => {
  const actionMenuRef = useRef()
  const [showButton, setShowButton] = useState(false)

  useLogger({ log, lifecycle: false, tags: [] })

  useImperativeHandle(ref, () => ({
    showButton () { setShowButton(true) },
    hideButton () { setShowButton(false) },
    toggleButton () { setShowButton(!showButton) },
    open () { actionMenuRef.current.open() },
    close () { actionMenuRef.current.close() }
  }), [showButton])

  const triggerClassName = classNames(
    'absolute top-[50%] mt-[-23px] select-none',
    message.direction === 'in' ? 'right-[-40px]' : 'left-[-40px]',
    !showButton ? 'hidden' : null
  )

  const triggerTrapClick = useCallback((event) => event.stopPropagation(), [])
  const trigger = (
    <Button
      className={triggerClassName}
      icon={<More />}
      size='sm'
      theme='none'
      variant='none'
      onClick={triggerTrapClick}
    >
      Manage Message
    </Button>
  )
  const placement = message.direction === 'in' ? 'right' : 'left'

  const rateHelpfulActionRef = useRef()
  const handleRateHelpfulAction = useCallback(() => { rateHelpfulActionRef.current.activate() }, [])

  const rateUnhelpfulActionRef = useRef()
  const handleRateUnhelpfulAction = useCallback(() => { rateUnhelpfulActionRef.current.activate() }, [])

  const rateIncorrectActionRef = useRef()
  const handleRateIncorrectAction = useCallback(() => { rateIncorrectActionRef.current.activate() }, [])

  const resendChatMessageActionRef = useRef()
  const handleResendChatMessageAction = useCallback(() => { resendChatMessageActionRef.current.activate() }, [])

  return message.direction === 'in'
    ? (
      <>
        <ActionMenu ref={actionMenuRef} placement={placement} trigger={trigger}>
          <ActionMenuHeader label='Rate Answer' />
          <ActionMenuItem label='Helpful' onClick={handleRateHelpfulAction} />
          <ActionMenuItem label='Unhelpful' onClick={handleRateUnhelpfulAction} />
          <ActionMenuItem label='Wrong' onClick={handleRateIncorrectAction} />
        </ActionMenu>
        <RateChatMessageAction ref={rateHelpfulActionRef} message={message} rating='helpful' />
        <RateChatMessageAction ref={rateUnhelpfulActionRef} message={message} rating='unhelpful' />
        <RateChatMessageAction ref={rateIncorrectActionRef} message={message} rating='incorrect' />
      </>
      )
    : (message.error
        ? (
          <>
            <ActionMenu ref={actionMenuRef} placement={placement} trigger={trigger}>
              <ActionMenuItem label='Resend' onClick={handleResendChatMessageAction} />
            </ActionMenu>
            <ResendChatMessageAction ref={resendChatMessageActionRef} message={message} />
          </>
          )
        : null)
})

MessageThreadItemActionMenuAiva.displayName = 'MessageThreadItemActionMenuAiva'
MessageThreadItemActionMenuAiva.propTypes = propTypes
MessageThreadItemActionMenuAiva.defaultProps = defaultProps

export default MessageThreadItemActionMenuAiva
