import classNames from 'classnames'
import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string
}

const defaultProps = {
  className: ''
}

const log = logger({ enabled: false, tags: ['Well'] })

const Well = ({ children, className }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = classNames(
    'bg-indigo-100 rounded-lg p-4',
    className
  )

  return (
    <div className={computedClassName}>
      {children}
    </div>
  )
}

Well.displayName = 'Well'
Well.propTypes = propTypes
Well.defaultProps = defaultProps

export default Well
