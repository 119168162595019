import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import ApplyKeyword from '../../../components/keywords/actions/ApplyKeywordAction'
import CloneAction from '../../../components/keywords/actions/CloneAction'
import DeleteAction from '../../../components/keywords/actions/DeleteAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['KeywordDetailHeaderMoreMenu'] })

const DetailHeaderMoreMenu = ({ keyword }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()
  const cloneActionRef = useRef()

  const applyKeywordActionRef = useRef()
  const activateApplyKeywordAction = useCallback(() => applyKeywordActionRef.current.activate(), [applyKeywordActionRef])

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/keywords', { replace: true }), [navigateInStack])

  const onCloneSelected = useCallback(() => cloneActionRef.current.activate(), [])

  return !!keyword && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Apply Keyword' onClick={activateApplyKeywordAction} />
        <ActionMenuItem label='Clone Keyword' onClick={onCloneSelected} />
        <ActionMenuItem label='Delete Keyword' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} keyword={keyword} onSuccess={onDeleteSuccess} />
      <ApplyKeyword ref={applyKeywordActionRef} keyword={keyword} />
      <CloneAction ref={cloneActionRef} keyword={keyword} />
    </>
  )
}

DetailHeaderMoreMenu.displayName = 'DetailHeaderMoreMenu'
DetailHeaderMoreMenu.propTypes = propTypes
DetailHeaderMoreMenu.defaultProps = defaultProps

export default DetailHeaderMoreMenu
