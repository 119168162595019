import { omit } from 'lodash'

import pb from './pb'

export function search (data, background = false) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPost(`keywords/${keywordId}/campaigns/search`, { body, background })
}

export function addSelected (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/campaigns/add`, { body })
}

export function addAllInQuery (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/campaigns/add/all`, { body })
}

export function removeSelected (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/campaigns/remove`, { body })
}

export function removeAllInQuery (data) {
  const { keywordId } = data
  const body = omit(data, 'keywordId')
  return pb.setupPut(`keywords/${keywordId}/campaigns/remove/all`, { body })
}
