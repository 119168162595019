import PropType from 'prop-types'

export default {
  __v: PropType.number,
  _id: PropType.string,
  link: PropType.string, // id of trackable link
  contact: PropType.shape({
    _id: PropType.string,
    firstName: PropType.string,
    lastName: PropType.string,
    phoneNumber: PropType.string
  }),
  user: PropType.string, // id of user
  createdAt: PropType.string, // "2021-02-12T15:47:33.143Z"
  updatedAt: PropType.string // "2021-02-12T15:47:33.143Z"
}
