// stateKey is camelCase (the key often used in state maps)
// contentType is PascalCase (the model name)
// stackKey is all lowercase with dashes (the route part)

const contentTypeByStateKey = {
  attachment: 'Attachment',
  bundle: 'Bundle',
  campaign: 'Campaign',
  configurableForm: 'ConfigurableForm',
  contact: 'Contact',
  contest: 'Contest',
  email: 'Email', // Template.contentType === 'email'
  keyword: 'Keyword',
  landingPage: 'LandingPage',
  messageTemplate: 'MessageTemplate', // Template.contentType === 'message'
  qrCode: 'QrCode',
  space: 'Space',
  template: 'Template', // TODO: remove
  trackableLink: 'TrackableLink',
  voiceDrop: 'VoiceDrop' // Template.contentType === 'voice-drop'
}
export const CONTENT_TYPE = Object.freeze({ ...contentTypeByStateKey })

const stateKeyByContentType = Object.entries(contentTypeByStateKey).reduce((obj, [stateKey, contentType]) => {
  obj[contentType] = stateKey
  return obj
}, {})

const stackKeyByContentType = {
  [contentTypeByStateKey.attachment]: 'attachments',
  [contentTypeByStateKey.bundle]: 'bundles',
  [contentTypeByStateKey.campaign]: 'campaigns',
  [contentTypeByStateKey.configurableForm]: 'configurable-forms',
  [contentTypeByStateKey.contact]: 'contacts',
  [contentTypeByStateKey.contest]: 'contests',
  [contentTypeByStateKey.email]: 'emails',
  [contentTypeByStateKey.keyword]: 'keywords',
  [contentTypeByStateKey.landingPage]: 'landing-pages',
  [contentTypeByStateKey.messageTemplate]: 'message-templates',
  [contentTypeByStateKey.qrCode]: 'qr-codes',
  [contentTypeByStateKey.space]: 'spaces',
  [contentTypeByStateKey.template]: 'templates', // TODO: remove
  [contentTypeByStateKey.trackableLink]: 'trackable-links',
  [contentTypeByStateKey.voiceDrop]: 'voice-drops'
}

const contentTypeByStackKey = Object.entries(stackKeyByContentType).reduce((obj, [contentType, stackKey]) => {
  obj[stackKey] = contentType
  return obj
}, {})

export function contentTypeFromStateKey (stateKey) {
  return contentTypeByStateKey[stateKey]
}
export function stateKeyFromContentType (contentType) {
  return stateKeyByContentType[contentType]
}
export function stackKeyFromContentType (contentType) {
  return stackKeyByContentType[contentType]
}
export function contentTypeFromStackKey (stackKey) {
  return contentTypeByStackKey[stackKey]
}
export function stateKeyFromStackKey (stackKey) {
  const contentType = contentTypeByStackKey[stackKey]
  return stateKeyByContentType[contentType]
}
