import classNames from 'classnames'
import PropType from 'prop-types'

import FormControl from './FormControl'

const propTypes = {
  formControls: PropType.arrayOf(PropType.shape({
    name: PropType.string.isRequired
  })).isRequired,
  className: PropType.string
}

const defaultProps = {
  className: null
}

const FormControlGroup = ({ className, formControls }) => {
  const computedClassNames = classNames('flex flex-row justify-between gap-x-3', className)
  const controls = formControls.map((controlProps) => {
    return (<FormControl key={controlProps.name} {...controlProps} />)
  })
  return (
    <div className={computedClassNames}>
      {controls}
    </div>
  )
}

FormControlGroup.displayName = 'FormControlGroup'
FormControlGroup.propTypes = propTypes
FormControlGroup.defaultProps = defaultProps

export default FormControlGroup
