import PropType from 'prop-types'

import user from './user'

export const contentType =
  [
    'Attachment',
    'Bundle',
    'Campaign',
    'ConfigurableForm',
    'Contact',
    'Contest',
    'Email',
    'Keyword',
    'LandingPage',
    'MessageTemplate',
    'QrCode',
    'Space',
    'Template', // TODO: remove
    'TrackableLink',
    'VoiceDrop'
  ]

export default {
  __v: PropType.number,
  _id: PropType.string,
  name: PropType.string,
  url: PropType.string,
  user: PropType.oneOfType([PropType.string, PropType.shape(user)]),
  search: PropType.string,
  contentType: PropType.string,
  createdAt: PropType.string,
  updatedAt: PropType.string
}
