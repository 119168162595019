import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { defaultsDeep } from 'lodash'
import mitt from 'mitt'

import { critical, error, success } from '../components/banners/Banner'
import logger from '../lib/logger'
import rollbar from '../lib/rollbar'
import { decrementApiLoading, incrementApiLoading } from '../store/actions/apiLoading'

import pbAppSessionCookieJar from './pbAppSessionCookieJar'

const log = logger({ enabled: true, tags: ['Services'] })

const events = mitt()
let appInfo

export let baseUrl = process.env.REACT_APP_PB_URL
if (process.env.REACT_APP_PROXY_URL) {
  baseUrl = (new URL(baseUrl, window.location)).toString()
}

const isCurrentlyInStyleGuide = /^\/style-guide\//.test(window.location.pathname)

const retryableStatuses = [502, 503, 504]
const retryIntervals = [500, 1000, 2000, 4000]
const waitFor = (millseconds) => new Promise((resolve) => setTimeout(resolve, millseconds))

let _defaultOptions = null
const defaultOptions = async () => {
  if (_defaultOptions) { return _defaultOptions }

  if (!appInfo && Capacitor.isNativePlatform()) { appInfo = await App.getInfo() }
  _defaultOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'X-OS-Name': Capacitor.getPlatform(),
      'X-React-App-Version': process.env.REACT_APP_PB_VERSION
    }
  }
  if (appInfo?.version) {
    _defaultOptions.headers['X-App-Store-Version'] = appInfo.version
  }

  return _defaultOptions
}

const convertBodyToJSONIfNecessary = (options) => {
  if (!options.body) { return }

  let sendAsJSON = true
  if (options.body instanceof FormData) {
    if (Capacitor.isNativePlatform()) {
      // FormData multipart w/ possible binary not supported from native platforms rn, send as json
      const formData = options.body
      for (const [key, value] of formData.entries()) {
        options.body[key] = value
      }
    } else {
      sendAsJSON = false
    }
  }

  if (sendAsJSON) {
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(options.body)
  }
}

const notifyForSomeNotOkResponses = (response, reply) => {
  if (response.ok) { return }

  if (response.status === 426) {
    const { message, upgradeAction, upgradeUrl } = reply?.json || {}
    critical('Upgrade Required', message, upgradeAction, upgradeUrl)
  } else if (response.status === 400) {
    error('Oops! An Error Occurred', reply?.json?.error || reply.statusText)
  } else if (response.status === 401) {
    events.emit('unauthorized')
  } else if (response.status === 403 && reply?.json?.error === 'Account Cancelled') {
    // user logs in but their account is cancelled. this is handled in LoginForm.
  } else if (response.status === 428) {
    // TMP (old app requires 428) reserved for MFA flow/requirement. this is handled in LoginForm. at some point, we'll probably refactor this to be a 2xx response.
  } else if (response.status === 429) {
    error('Oh no!', reply?.json?.error || reply.statusText)
    rollbar.info('Internal API: Rate limit', reply)
  } else if (response.status >= 400 && response.status < 500) {
    error(reply?.json?.error || reply.statusText)
  } else if (response.status === 500) { // Internal Service Error
    rollbar.critical('Internal API: Received 500')
  } else {
    rollbar.critical('Internal API: Unexpected Error', reply)
    error('Unexpected Error', reply?.json?.error || reply.statusText)
  }
}

const pb = {
  async init () {
    await pbAppSessionCookieJar.restore(baseUrl)
  },
  events,
  setupRequestWithAbort (path, options) {
    const abortController = new AbortController()
    options.signal = abortController.signal
    const call = async () => pb.request(path, options)
    const abort = () => abortController.abort()
    return { abort, call }
  },
  async request (path, options) {
    if (isCurrentlyInStyleGuide) {
      const { method } = options
      const allowed = (method === 'GET') || (method === 'POST' && /\/search$|\/poller$|\/users\/session$/.test(path))
      if (!allowed) { return success(`Would ${method} ${path}`) }
    }

    const background = options?.background ?? false
    if (options.background) { delete options.background }
    const model = options?.model ?? false
    if (options.model) { delete options.model }

    if (!background) { incrementApiLoading(options.apiLoadingMessageOverride) }

    path = new URL(path, `${baseUrl}/`).toString()

    const defaults = await defaultOptions()
    defaultsDeep(options, defaults)

    convertBodyToJSONIfNecessary(options) // mutates options

    let nextDelayInterval = 0
    const tryRequest = async () => {
      const reply = {}
      try {
        const response = await fetch(path, options)
        // Capacitor HTTP bridge is ignoring the abort and still letting the request progress
        // So we're capturing it here and forcing an abort
        if (options?.signal?.aborted) {
          throw new DOMException('The user aborted a request.', 'AbortError')
        }
        reply.ok = response.ok
        reply.headers = response.headers
        reply.status = response.status
        reply.statusText = response.statusText
        reply.aborted = false

        if (reply.ok && options.appSessionCapture) {
          await pbAppSessionCookieJar.capture(baseUrl)
        }

        try {
          reply.json = await response.json()
          if (model) {
            if (model.multiple) {
              const jsonItems = model.key ? reply.json[model.key] : reply.json
              reply.models = jsonItems.map((json) => model.conversion(json))
            } else {
              reply.model = model.conversion(model.key ? reply.json[model.key] : reply.json)
            }
          }
        } catch (err) {}
        try {
          reply.text = await response.text()
        } catch (err) {}

        if (reply.ok && options.appSessionReset?.(reply.json)) {
          pbAppSessionCookieJar.reset(baseUrl)
        }

        if (retryableStatuses.includes(response.status)) {
          const delay = retryIntervals[nextDelayInterval++]
          if (!delay) {
            log.debug('Exhausted retries...')
            rollbar.error(`Internal API: Exhausted retries for ${response.status}`)
            error('Unexpected Error', 'Please try again in a few minutes.')
            return reply
          }
          log.debug(`Encountered retryable response ${response.status}. Will retry (attempt ${nextDelayInterval}) after waiting ${delay}.`)
          rollbar.debug(`Internal API: Received ${response.status}`, { retryAttemps: nextDelayInterval })
          await waitFor(delay)
          return await tryRequest()
        } else {
          notifyForSomeNotOkResponses(response, reply)
          return reply
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          reply.ok = false
          reply.aborted = true
          return reply
        } else {
          rollbar.critical('Internal API: Very Unexpected Error', err, reply)
        }
      }
    }

    const reply = await tryRequest()
    if (!background) { decrementApiLoading() }
    return reply
  },
  setupGet (path, options) {
    return pb.setupRequestWithAbort(path, defaultsDeep(options, { method: 'GET' }))
  },
  setupPost (path, options) {
    return pb.setupRequestWithAbort(path, defaultsDeep(options, { method: 'POST' }))
  },
  setupPut (path, options) {
    return pb.setupRequestWithAbort(path, defaultsDeep(options, { method: 'PUT' }))
  },
  setupDelete (path, options) {
    return pb.setupRequestWithAbort(path, defaultsDeep(options, { method: 'DELETE' }))
  }
}

export default pb
