import PropType from 'prop-types'

import MarkColor from '../../../svg/branding/MarkColor'

import Wrapper from './Wrapper'

const propTypes = {
  title: PropType.string.isRequired,
  goBack: PropType.func,
  heading: PropType.string,
  subtitle: PropType.oneOfType([PropType.string, PropType.node])
}

const defaultProps = {
  goBack: null,
  heading: null,
  subtitle: null
}

const Header = ({ title, subtitle, heading, goBack }) => {
  return (
    <>
      <Wrapper goBack={goBack}>
        <div className='w-[75px] h-[75px] mx-auto'>
          <MarkColor />
        </div>
      </Wrapper>
      <h1 className='text-center'>
        {heading ? <span className='text-indigo-500 headline-3xs uppercase'>{heading}</span> : null}
        <strong className='m-4 block headline-sm'>{title}</strong>
      </h1>
      {subtitle ? <p className='text-center label-lg'>{subtitle}</p> : null}
    </>
  )
}

Header.displayName = 'Header'
Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
