import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import tagShape from '../../../prop-types/shapes/tag'
import { del as deleteService } from '../../../services/tag'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  tag: PropType.shape(tagShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ tag, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [tag?.name] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${tag.name} was deleted`)
    bus.emit('tagDeleted', tag)
    onSuccess?.()
  }, [onSuccess, tag])
  const { call: deleteTag } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteTag([tag.name], false)
  }, [deleteTag, tag.name])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to delete this tag? This can not be undone.'
      title={`Delete ${tag.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
