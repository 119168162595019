import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWizard } from 'react-use-wizard'

import { error, notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import SubscriptionSummary from '../../../components/plans/SubscriptionSummary'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import useTimeZone from '../../../hooks/useTimeZone'
import { getCookie } from '../../../lib/cookies'
import { centsToDollars, pluralize } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import { defaultPlanId, includesTrial, isPayingPlan, optionsData, requiresVerifiedMobile } from '../../../lib/plans'
import { generate } from '../../../lib/shortid'
import {
  plansForNewSubscriber as plansForNewSubscriberService,
  signUpPreFlight as signUpPreFlightService
} from '../../../services/subscription'
import {
  addToCart as trackAddToCartService,
  lead as trackLeadService,
  search as trackSearchService
} from '../../../services/tracking'
import Header from '../shared/Header'

import AffiliatePromo from './AffiliatePromo'
import PartnerPromo from './PartnerPromo'
import SecureCheckout from './SecureCheckout'
import validationSuite from './SubscriptionForm.validations'

const propTypes = {
  onSuccess: PropType.func.isRequired,
  affiliate: PropType.shape({
    name: PropType.string.isRequired
  }),
  integration: PropType.shape({
    defaultPlan: PropType.string.isRequired,
    description: PropType.string.isRequired,
    key: PropType.string.isRequired,
    logo: PropType.string.isRequired,
    name: PropType.string.isRequired,
    newUserAccountBalanceCreditCents: PropType.number.isRequired,
    request: PropType.string.isRequired,
    terms: PropType.string.isRequired,
    url: PropType.string.isRequired
  }),
  signInUrl: PropType.string
}

const defaultProps = {
  affiliate: null,
  integration: null,
  signInUrl: '/auth'
}

const formSettings = {
  id: 'signUpForm',
  validationSuite
}

function creditMessage (integration) {
  if (integration?.newUserAccountBalanceCreditCents === 0) {
    return null
  }

  return (
    <p>
      As a bonus, new sign-ups connected to
      {' '}
      <strong>{integration.name}</strong>
      {' '}
      are receiving a
      {' '}
      <strong>
        $
        {centsToDollars(integration.newUserAccountBalanceCreditCents)}
      </strong>
      {' '}
      account credit!
    </p>
  )
}

const trackLead = () => {
  const eventId = generate()
  window.fbq('track', 'Lead', {}, { eventID: eventId })
  window.gtag('event', 'lead')

  const trackingData = {
    eventId,
    eventSourceUrl: window.location.href,
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp')
  }

  trackLeadService(trackingData).call()
}

const trackSearch = () => {
  const eventId = generate()
  window.fbq('track', 'Search', {}, { eventID: eventId })

  const trackingData = {
    eventId,
    eventSourceUrl: window.location.href,
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp')
  }

  trackSearchService(trackingData).call()
}

const log = logger({ enabled: false, tags: ['SubscriptionForm'] })

const SubscriptionForm = ({ onSuccess, integration, affiliate, signInUrl, ...data }) => {
  useLogger({ log, lifecycle: true, tags: [] })

  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [formData, setFormData] = useState({})
  const [plans, setPlans] = useState([])
  const { goToStep } = useWizard()
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const { dataForSelect: timeZoneOptions, loading: timeZoneInfoLoading, userTimeZone } = useTimeZone()
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId)
  const partnerId = integration?.key

  const handlePlansReply = useCallback((reply) => {
    const plans = reply.json
    const planParam = params.get('plan')
    const verifiedPlanId = (
      plans.find((plan) => plan.id === data.planId) ||
      plans.find((plan) => plan.id === planParam) ||
      plans.find((plan) => plan.id === integration?.defaultPlan) ||
      plans.find((plan) => plan.id === defaultPlanId)
    )?.id
    setPlans(plans)
    setSelectedPlanId(verifiedPlanId)
  }, [data.planId, integration?.defaultPlan, params])
  const { call: plansData } = useService(plansForNewSubscriberService, { onReplyOk: handlePlansReply })

  const trackAddToCart = useCallback(({ firstName, lastName, email, stateProvince }) => {
    const eventId = generate()
    const trackingData = {
      firstName,
      lastName,
      email,
      stateProvince,
      eventId,
      eventSourceUrl: window.location.href,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp')
    }

    window.fbq('track', 'AddToCart', {}, { eventID: eventId })
    window.gtag('event', 'add_to_cart')

    trackAddToCartService(trackingData).call()
  }, [])

  const handlePreFlightReplyOk = useCallback((reply) => {
    const mergedData = { ...formData, selectedPlan }
    trackAddToCart(mergedData)
    if (reply.json.verificationCodeSent === true) {
      onSuccess(mergedData)
      goToStep(1)
    } else if (reply.json.existingUser === true) {
      notify('Welcome Back!', 'You already have an account with Project Broadcast. Please login to reactivate your account.')
      navigate(signInUrl)
    } else {
      const respNumbers = reply.json.numbers
      const numbers = {}
      let numbersAvailable = false

      Object.keys(respNumbers).forEach((key) => {
        if (respNumbers[key].length > 0) {
          numbers[key] = respNumbers[key]
          numbersAvailable = true
        }
      })

      if (numbersAvailable) {
        const referral = reply.json.referral
        onSuccess({ ...formData, numbers, selectedPlan, referral })
        goToStep(2)
      } else {
        error('Oh no!', 'It seems we have no numbers available in that state/province. Please select another.')
      }
    }
  }, [formData, goToStep, navigate, onSuccess, selectedPlan, signInUrl, trackAddToCart])
  const { call: signUpPreFlight } = useService(signUpPreFlightService, { onReplyOk: handlePreFlightReplyOk })

  useEffect(() => {
    plansData({ partnerId }, { background: false })
  }, [partnerId, plansData])

  useEffect(() => {
    trackLead()
    trackSearch()
  }, [])

  const handlePlanChange = useCallback((event) => {
    log.debug('handlePlanChange', event.target.value)
    setSelectedPlanId(event.target.value)
  }, [])

  formSettings.defaultValues = {
    referralCode: params.get('referral'), // if data has referral, let it override.
    timezone: userTimeZone,
    ...data,
    planId: selectedPlanId
  }

  formSettings.onSubmit = (data) => {
    if (typeof data.referralCode === 'string') {
      data.referralCode = data.referralCode.toLowerCase()
    }
    data.affiliate = affiliate
    signUpPreFlight(data)
    setFormData(data)
  }

  const formControls = [{
    autoFocus: true,
    type: 'select',
    name: 'planId',
    label: 'Subscription Plan',
    required: true,
    hint: selectedPlanHintMessage(),
    onChange: handlePlanChange,
    options: optionsData(plans, true),
    placeholder: 'Choose a subscription plan'
  },
  !integration && !affiliate && isPayingPlan(selectedPlan)
    ? {
        name: 'referralCode',
        type: 'text',
        label: 'Referral Code',
        hint: 'If you were referred to Project Broadcast by an existing user, make sure to ask them for their referral code!',
        placeholder: 'Referral Code'
      }
    : null,
  integration?.newUserAccountBalanceCreditCents > 0
    ? {
        type: 'passthrough',
        element: creditMessage(integration),
        name: 'creditMessage'
      }
    : null,
  {
    autoComplete: 'first-name',
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
    required: true
  },
  {
    autoComplete: 'last-name',
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true
  },
  {
    autoComplete: 'email',
    type: 'email',
    name: 'email',
    label: 'Email Address',
    placeholder: 'Email Address',
    required: true
  },
  {
    autoComplete: 'new-password',
    type: 'password',
    name: 'password',
    label: 'Password',
    hint: 'Password must be 8 or more characters with at least 1 letter and 1 number.',
    placeholder: 'Password',
    required: true
  },
  requiresVerifiedMobile(selectedPlan)
    ? {
        autoComplete: 'phone-number',
        type: 'tel',
        name: 'phoneNumber',
        label: 'Phone Number',
        hint: 'A verification code will be sent via text message to this number. This code will be required to complete signup in the next step.',
        placeholder: 'Enter your mobile number',
        required: true
      }
    : {
        type: 'stateprovince',
        name: 'stateProvince',
        label: 'State/Province',
        hint: 'The State/Province is used to locate you a Project Broadcast number. If the State/Province you provide does not return any available numbers, please use a different State/Province.',
        placeholder: 'Choose State/Province',
        required: true
      },
  {
    type: 'select',
    name: 'timezone',
    required: true,
    label: 'Time Zone',
    onChange: null,
    options: timeZoneOptions,
    placeholder: 'Choose Time Zone'
  },
  integration
    ? {
        type: 'checkbox',
        name: 'partnerTerms',
        labelAlignItems: 'items-start',
        label: integration.terms,
        labelAlignment: 'right',
        required: true
      }
    : null,
  integration
    ? {
        type: 'hidden',
        name: 'partnerId',
        value: partnerId
      }
    : null,
  {
    type: 'checkbox',
    name: 'euDisclaimer',
    label: 'Select here if you are a member of the European Union.',
    labelAlignment: 'right'
  }]
  formSettings.formControls = formControls.filter((o) => o)

  function selectedPlanHintMessage () {
    if (!includesTrial(selectedPlan)) { return }
    const pluralizedText = pluralize({ count: selectedPlan.newUserTrialInDays, singular: 'day', includeCount: true })
    return `You have selected a plan with a free trial. Your initial charge will occur ${pluralizedText} after signup`
  }

  // form defaultValues are cached after first render,
  // so block Form rendering until we have the data we need.
  if (!selectedPlanId || !plans.length || timeZoneInfoLoading) {
    return null
  }

  return (
    <>
      <Header
        goBack={integration ? null : () => navigate(signInUrl)}
        heading='Create Account'
        title='Account Details'
      />
      {integration
        ? <PartnerPromo
            integration={integration}
            signInUrl={signInUrl}
          />
        : (affiliate ? <AffiliatePromo referrerName={affiliate.name} /> : null)}
      <Form {...formSettings} />
      <div className='paragraph-sm mb-4'>
        <SubscriptionSummary plan={selectedPlan} />
      </div>
      <Button
        className='mb-4 w-full'
        form='signUpForm'
        size='md'
        type='submit'
      >
        Continue
      </Button>
      <SecureCheckout />
    </>
  )
}

SubscriptionForm.displayName = 'SubscriptionForm'
SubscriptionForm.propTypes = propTypes
SubscriptionForm.defaultProps = defaultProps

export default SubscriptionForm
