import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import { search as appFlowSearch } from '../../services/appFlow'
import List from '../list/List'

import AppFlowListItemContent from './AppFlowListItemContent'

const appFlowResultsFields = [
  '_id',
  'name',
  'description',
  'state',
  'updatedAt'
]

const sortList = [
  { key: 'modified', label: 'Most Recently Updated', default: true },
  { key: 'newest', label: 'Most Recently Created' },
  { key: 'name', label: 'Name' }
]

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func,
  sortable: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  queryOverrides: {},
  searchService: appFlowSearch,
  sortable: false,
  sortList
}

const log = logger({ enabled: false, tags: ['AppFlowList'] })

const AppFlowList = forwardRef(({
  queryOverrides,
  searchService,
  sortable,
  sortList,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [listProps.type] })

  const handleAppFlowCreated = useCallback(() => {
    ref.current.indicateUpdatesDetected()
  }, [ref])
  const handleAppFlowUpdated = useCallback((updatedAppFlow) => {
    ref.current.indicateUpdatesDetected()
    ref.current.updateItem(updatedAppFlow)
  }, [ref])
  const handleAppFlowRemoved = useCallback((removedAppFlow) => {
    ref.current.removeItem(removedAppFlow._id)
    ref.current.indicateUpdatesDetected()
  }, [ref])
  const handleAppFlowsRemoved = useCallback((removedAppFlows) => {
    const removedIds = removedAppFlows.map((item) => item._id)
    ref.current.removeItems(removedIds)
    ref.current.indicateUpdatesDetected()
  }, [ref])
  useBus('appFlowCreated', handleAppFlowCreated)
  useBus('appFlowCloned', handleAppFlowCreated)
  useBus('appFlowUpdated', handleAppFlowUpdated)
  useBus('appFlowDeleted', handleAppFlowRemoved)
  useBus('appFlowsDeleted', handleAppFlowsRemoved)

  return (
    <List
      ref={ref}
      ListItemContent={AppFlowListItemContent}
      placeholder='Search App Flows'
      queryOverrides={queryOverrides}
      resultsFields={appFlowResultsFields}
      resultsKey='appFlows'
      searchService={searchService}
      sortable={sortable}
      sortList={sortList}
      search
      {...listProps}
    />
  )
})

AppFlowList.displayName = 'AppFlowList'
AppFlowList.propTypes = propTypes
AppFlowList.defaultProps = defaultProps

export default AppFlowList
