import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import goalShape from '../../../prop-types/shapes/goal'
import { Warning } from '../../../svg/icons'

const propTypes = {
  item: PropType.shape(goalShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['ListItemContent'] })

const ListItemContent = ({ item: goal }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col flex-grow ml-[12px] py-[4px] max-w-full overflow-auto'>
      <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
        {` ${goal.displayName}`}
      </div>
      <div className='label-xs text-neutral-500 mt-[3px]'>
        Name:
        {' '}
        {goal.name}
      </div>
      <div className='label-xs text-neutral-500 mt-[3px]'>
        Type:
        {' '}
        {` ${goal.openEnded ? 'Open-ended' : 'Fixed steps for completion'}`}
      </div>
      {!goal.enabled && (
        <div className='label-xs text-danger mt-[3px] flex flex-row gap-2'>
          <Warning className='h-4' />
          Currently disabled
        </div>
      )}
    </div>
  )
}

ListItemContent.displayName = 'ListItemContent'
ListItemContent.propTypes = propTypes
ListItemContent.defaultProps = defaultProps

export default ListItemContent
