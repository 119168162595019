import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../../components/action-menu/ActionMenuItem'
import ExportGoalAction from '../../../../components/admin/goals/actions/ExportGoalAction'
import ImportGoalAction from '../../../../components/admin/goals/actions/ImportGoalAction'
import PanelHeaderButton from '../../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import goalShape from '../../../../prop-types/shapes/goal'

const propTypes = {
  goal: PropType.shape(goalShape)
}

const defaultProps = {
  goal: null
}

const log = logger({ enabled: true, tags: ['GoalDetailHeaderMore'] })

const GoalDetailHeaderMore = ({ goal }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const exportGoalActionRef = useRef()
  const importGoalActionRef = useRef()

  const onExportSelected = useCallback(() => {
    exportGoalActionRef.current.activate()
  }, [])

  const onImportSelected = useCallback(() => {
    importGoalActionRef.current.activate()
  }, [])

  return !!goal && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Export Goal' onClick={onExportSelected} />
        <ActionMenuItem label='Import Goal' onClick={onImportSelected} />
      </ActionMenu>
      <ExportGoalAction ref={exportGoalActionRef} goal={goal} />
      <ImportGoalAction ref={importGoalActionRef} goal={goal} />
    </>
  )
}

GoalDetailHeaderMore.displayName = 'GoalDetailHeaderMore'
GoalDetailHeaderMore.propTypes = propTypes
GoalDetailHeaderMore.defaultProps = defaultProps

export default GoalDetailHeaderMore
