import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { notify } from '../../components/banners/Banner'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import rollbar from '../../lib/rollbar'
import { doubleOptIn, doubleOptInLanding, doubleOptInUpdate } from '../../services/shortLinks'

import StepOne from './double-opt-in/StepOne'
import StepThree from './double-opt-in/StepThree'
import StepTwo from './double-opt-in/StepTwo'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['DoubleOptIn'] })

const DoubleOptIn = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [contact, setContact] = useState(null)
  const [error, setError] = useState(null)
  const [step, setStep] = useState(1) // or 2 (update), 3 (thank you)

  const { contactSlug } = useParams()

  const handleGetReplyOk = useCallback((reply) => {
    log.debug('Got contact', reply.json)
    setContact(reply.model)
    if (reply.model.isDoubleOptedIn) {
      log.debug('Contact already double opted in, moving to step 2')
      setStep(2)
    }
  }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/paste the link from your message to your browser.')
  }, [])
  const { call: getContactCall, loading: contactLoading } = useService(doubleOptInLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  const handleClickReply = useCallback((reply) => {
    log.debug('Moving to step 2', reply.ok)
    if (!reply.ok) {
      rollbar.error('Double Opt In failed on accept opt in call.', reply)
      setError('Sorry! Unable to double opt in. Please try again later.')
      return
    }
    if (reply.model) { setContact(reply.model) }
    setStep(2)
  }, [])
  const { call: doubleOptInClickCall } = useService(doubleOptIn, { onReply: handleClickReply })

  const handleUpdateReply = useCallback((reply) => {
    log.debug('Moving to step 3', reply.ok)
    if (!reply.ok) {
      rollbar.error('Double Opt In failed to update the contact profile.', reply)
      setError('Sorry! Unable to update your profile. Please try again later.')
      return
    }
    if (reply.model) { setContact(reply.model) }
    setStep(3)
  }, [])
  const { call: doubleOptInUpdateCall } = useService(doubleOptInUpdate, { onReply: handleUpdateReply })

  useEffect(() => {
    getContactCall(contactSlug, true)
  }, [contactSlug, getContactCall])

  const handleDoubleOptInClick = useCallback(() => {
    log.debug('Clicked to double opt in')
    doubleOptInClickCall(contactSlug)
  }, [contactSlug, doubleOptInClickCall])

  const handleUpdateSubmit = useCallback((data) => {
    const dataToSend = {}
    const keysToSendIfFilledIn = ['firstName', 'lastName', 'company', 'email']
    keysToSendIfFilledIn.forEach((key) => {
      const val = data[key]
      if (val) { dataToSend[key] = val }
    })
    if (data?.birthday?.month && data?.birthday?.date) {
      dataToSend.birthday = {
        month: parseInt(data.birthday.month, 10),
        date: parseInt(data.birthday.date, 10)
      }
    }
    log.debug('Submitted update', data, dataToSend)
    if (isEmpty(dataToSend)) {
      notify('Please fill in your information first.')
    } else {
      doubleOptInUpdateCall(contactSlug, dataToSend)
    }
  }, [contactSlug, doubleOptInUpdateCall])

  const loading = (contactLoading || !contact) && !error

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      {step === 1 && <StepOne contact={contact} onDoubleOptInClick={handleDoubleOptInClick} />}
      {step === 2 && <StepTwo contact={contact} onUpdateSubmit={handleUpdateSubmit} />}
      {step === 3 && <StepThree contact={contact} />}
    </ShortLinkContent>
  )
}

DoubleOptIn.displayName = 'DoubleOptIn'
DoubleOptIn.propTypes = propTypes
DoubleOptIn.defaultProps = defaultProps

export default DoubleOptIn
