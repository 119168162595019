import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

import ReferralPromoTextFragment from './ReferralPromoTextFragment'

const propTypes = {
  frequency: PropType.oneOf(['Monthly', 'Yearly']).isRequired,
  referrerName: PropType.string.isRequired,
  credits: PropType.number,
  percentOff: PropType.number
}

const defaultProps = {
  credits: 0,
  percentOff: 0
}

const log = logger({ enabled: false, tags: ['ReferralPromo'] })

const ReferralPromo = ({ frequency, percentOff, credits, referrerName }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const props = {
    credits,
    frequency,
    percentOffMonth: frequency === 'Monthly' ? percentOff : undefined,
    percentOffYear: frequency === 'Yearly' ? percentOff : undefined,
    referrerName
  }

  return <ReferralPromoTextFragment {...props} />
}

ReferralPromo.displayName = 'ReferralPromo'
ReferralPromo.propTypes = propTypes
ReferralPromo.defaultProps = defaultProps

export default ReferralPromo
