import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import tagShape from '../../../prop-types/shapes/tag'
import { bulkDelete as bulkDeleteService } from '../../../services/tag'
import { success } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  tags: PropType.arrayOf(PropType.shape(tagShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ tags }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success('Tags have been deleted')
    bus.emit('tagsDeleted', tags)
    bus.emit('closeBulkEditDialog')
  }, [tags])
  const { call: bulkDelete } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    bulkDelete({ tags: pluck(tags, 'name') })
  }, [bulkDelete, tags])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
