import PropType from 'prop-types'

export default {
  _id: PropType.string,
  channel: PropType.oneOf([
    'call',
    'sms'
  ]),
  to: PropType.string
}
