import * as React from "react";
import { memo } from "react";
const SvgPin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Pin_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.365 9.157 1.471 2.554c-.85 2.17-1.088 5.95.449 6.837.46.265.997.292 1.604-.06l4.243-2.455 4.05 7.019c.101.162.205.267.362.358.516.298 1.111.043 1.37-.405.15-.258.162-.565-.049-.925l-4.05-7.019 4.26-2.459c.27-.158.557-.396.719-.676.699-1.21-.534-2.312-1.98-3.147-1.156-.667-2.599-1.17-3.57-1.328l-1.47-2.553.306-2.395c.11-.864-.264-1.692-.915-2.068-.55-.317-1.286-.309-2.008.112L3.031 4.655a2.714 2.714 0 0 0-.985.956c-.764 1.323.27 2.458.929 2.69l2.39.856Zm11.968 2.215-8.605 4.96c-.162.1-.244.113-.305.063-.072-.057-.107-.152-.129-.27-.298-1.606.089-2.639.623-4.29.057-.176.064-.291-.025-.447L6.794 7.77c-.073-.132-.151-.178-.299-.233l-2.084-.844c-.3-.128-.278-.295-.213-.407.032-.056.105-.104.195-.157l6.244-3.616c.132-.073.219-.068.297-.023.101.058.157.195.141.35l-.295 2.222a.616.616 0 0 0 .085.423l2.047 3.574c.117.187.224.234.409.251a8.189 8.189 0 0 1 3.098.952c.522.286 1.197.75 1.067.974a.385.385 0 0 1-.153.136Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Pin_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgPin);
export default Memo;
