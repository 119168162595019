import classNames from 'classnames'
import PropType from 'prop-types'

import { formatAvatarInitials } from '../../lib/formatters'
import './Avatar.css'

const sizes = ['xs', 'sm', 'md', 'lg']
Avatar.propTypes = {
  contactOrUser: PropType.shape({
    firstName: PropType.string,
    lastName: PropType.string
  }).isRequired,
  size: PropType.oneOf(sizes).isRequired
}

function Avatar ({ contactOrUser, size }) {
  const avatarInitials = formatAvatarInitials(contactOrUser)
  const className = classNames('avatar', size)
  return (
    <div className={className}>
      {avatarInitials}
    </div>
  )
}

export default Avatar
