function calculatePrefix (tags) {
  return tags.reduce((str, tag) => {
    if (tag) { str += `[${tag}]` }
    return str
  }, '')
}

function logger (options) {
  const instance = {
    enabled: options.enabled || false,
    originalTags: options.tags || [],
    additionalTags: [],
    prefix: calculatePrefix(options.tags || []),

    disable () { instance.enabled = false },
    enable () { instance.enabled = true },

    updateAdditionalTags (additionalTags) {
      instance.additionalTags = additionalTags || []
      instance.prefix = calculatePrefix([...instance.originalTags, ...instance.additionalTags])
    },

    debug (...rest) { instance._logWithConsole('debug', ...rest) },
    error (...rest) { instance._logWithConsole('error', ...rest) },
    info (...rest) { instance._logWithConsole('info', ...rest) },
    log (...rest) { instance._logWithConsole('log', ...rest) },
    warn (...rest) { instance._logWithConsole('warn', ...rest) },

    _logWithConsole (method, ...rest) {
      if (!this.enabled) { return }
      // eslint-disable-next-line no-console
      console[method](instance.prefix, ...rest)
    }
  }

  return instance
}

export default logger
