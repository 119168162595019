import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import { Current, Filter, Folder, List, Past, Sort, Tag } from '../../svg/icons'

const propTypes = {
  className: PropType.string,
  icon: PropType.oneOfType([
    PropType.oneOf([
      'current',
      'filter',
      'past',
      'tags',
      'sort',
      'folder',
      'list'
    ]),
    PropType.element
  ]),
  start: PropType.bool,
  title: PropType.string,
  onClick: PropType.func
}

const defaultProps = {
  className: null,
  icon: null,
  onClick: null,
  start: false,
  title: null
}

const SearchBarButton = forwardRef(({ icon, title, start, onClick, className }, ref) => {
  if (!icon && !title) { return null }

  if (typeof icon === 'string') {
    ({ icon, title } = iconAndTitleMappings[icon])
  }

  const computedClassName = classNames(
    'inline-block flex-initial w-[42px] h-[42px] flex items-center justify-center shrink-0',
    'bg-white border border-neutral-300 rounded-md bg-white',
    {
      'cursor-pointer': onClick,
      [start ? 'mr-2' : 'ml-2']: true
    },
    className
  )

  return (
    <button
      ref={ref}
      className={computedClassName}
      onClick={onClick}
    >
      {icon
        ? (
          <span className='inline-block h-[20px] w-[20px]'>
            {icon}
            <span className='sr-only'>{title}</span>
          </span>
          )
        : <span className='inline-block h-[20px] label-md'>{title}</span>}
    </button>
  )
})

SearchBarButton.displayName = 'SearchBarButton'
SearchBarButton.propTypes = propTypes
SearchBarButton.defaultProps = defaultProps

const iconAndTitleMappings = {
  current: { icon: <Current />, title: 'current' },
  filter: { icon: <Filter />, title: 'filter' },
  past: { icon: <Past />, title: 'past' },
  tags: { icon: <Tag />, title: 'tags' },
  sort: { icon: <Sort />, title: 'sort' },
  folder: { icon: <Folder />, title: 'folder' },
  list: { icon: <List />, title: 'list' }
}

export default SearchBarButton
