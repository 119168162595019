import { Navigate, useLocation, useOutlet } from 'react-router-dom'

import PanelLayoutPanels from '../../components/panels/PanelLayoutPanels'
import StyleGuideMenu from '../../components/style-guide/StyleGuideMenu'
import { useApp } from '../../contexts/AppContext'
import { useMinBreakpoint } from '../../hooks/useBreakpoint'

import './StyleGuideLayout.css'

function StyleGuideLayout () {
  const { isNative } = useApp()
  const location = useLocation()
  const roomForTwoPanels = useMinBreakpoint('lg')
  const outlet = useOutlet()

  if (location.pathname === '/style-guide') {
    return <Navigate to='/style-guide/branding' replace />
  }

  return (
    <>
      <StyleGuideMenu />
      <PanelLayoutPanels
        className='style-guide-layout'
        isNative={isNative}
        panel1={outlet}
        roomForTwoPanels={roomForTwoPanels}
        includeHeaderBackground
      />
    </>
  )
}

export default StyleGuideLayout
