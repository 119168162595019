import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import integration from '../../../prop-types/shapes/integration'
import { disconnect as disconnectService } from '../../../services/integration'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  integration: PropType.shape(integration).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['DisconnectAction'] })

const DisconnectAction = forwardRef(({ integration }, ref) => {
  useLogger({ log, lifecycle: false, tags: [integration.key] })
  const confirmRef = useRef()
  const { call: disconnectCall } = useService(disconnectService, {
    onReplyOk: useCallback(() => {
      bus.emit('integrationDisconnected', integration)
    }, [integration])
  })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    disconnectCall(integration.key)
  }, [disconnectCall, integration])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description={`Are you sure you want to disconnect your ${integration.name} integration?`}
      title={`Disconnect ${integration.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DisconnectAction.displayName = 'DisconnectAction'
DisconnectAction.propTypes = propTypes
DisconnectAction.defaultProps = defaultProps

export default DisconnectAction
