import { formatName, formatNumber, formatPhone } from '../lib/formatters'

import proxy from './proxy'

export default function managedAccountFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get formattedMessageCredits () {
      return formatNumber(json.messageCredits)
    },

    get email () {
      return json?.auth?.username
    },

    get formattedName () {
      return formatName(json)
    },

    get formattedPhoneNumber () {
      return formatPhone(json.phoneNumber)
    }
  }

  return proxy(json, overrides)
}
