import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import trackableLink from '../../prop-types/shapes/trackableLink'
import { PeopleGroup, Slug } from '../../svg/icons'
import { TrackableLink } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(trackableLink).isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['TrackableLinkListItemContent'] })

const TrackableLinkListItemContent = ({ item: trackableLink }) => {
  useLogger({ log, lifecycle: true, tags: [trackableLink?._id] })

  return (
    <>
      <TrackableLink className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {trackableLink.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <div className='line-clamp-1 break-all'>{trackableLink.url}</div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Slug className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            {trackableLink.slug}
          </div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <PeopleGroup className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={trackableLink.clickCount} />
          <div className='line-clamp-1 break-words'>{trackableLink.clickSubsetNames}</div>
        </div>
      </div>
    </>
  )
}

TrackableLinkListItemContent.displayName = 'TrackableLinkListItemContent'
TrackableLinkListItemContent.propTypes = propTypes
TrackableLinkListItemContent.defaultProps = defaultProps

export default TrackableLinkListItemContent
