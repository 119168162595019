import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useState } from 'react'

import useDefaultRef from '../../../hooks/useDefaultRef'
import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { update as updateService } from '../../../services/contact'
import { get as getMessageService } from '../../../services/message'
import Button from '../../buttons/Button'
import PanelDialog from '../../dialog/PanelDialog'
import ThreadItemDate from '../../list/thread/ThreadItemDate'
import PanelHeaderButton from '../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../panels/PanelContent'
import PanelHeader from '../../panels/PanelHeader'
import MessageThreadItemContentText from '../message-thread-item-content/MessageThreadItemContentText'

const propTypes = {
  messageId: PropType.string.isRequired,
  onContactUpdate: PropType.func,
  onResolved: PropType.func
}

const defaultProps = {
  onContactUpdate: null,
  onResolved: null
}

const log = logger({ enabled: true, tags: ['PotentialStopMessageReviewDialog'] })

const PotentialStopMessageReviewDialog = forwardRef(({ messageId, onContactUpdate, onResolved }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [message, setMessage] = useState(null)
  ref = useDefaultRef(ref)

  const { call: getMessage } = useService(getMessageService, {
    onReplyOk: (reply) => setMessage(reply.model)
  })

  const { call: updateContact } = useService(updateService, {
    onReplyOk: (reply) => {
      bus.emit('contactOptOutRelatedInfoUpdated')
      onContactUpdate?.(reply)
      onResolved?.()
      ref.current.close()
    }
  })

  useEffect(() => {
    getMessage(messageId)
  }, [getMessage, messageId])

  const handleCloseClick = useCallback(() => {
    ref.current.close()
  }, [ref])

  const handleDoNotMessageClick = useCallback(() => {
    updateContact(message.contact._id, {
      doNotMessage: true
    })
  }, [message, updateContact])

  const handleNotAStopMessageClick = useCallback(() => {
    updateContact(message.contact._id, {
      potentialStopMessage: null
    })
  }, [message, updateContact])

  return (
    <PanelDialog ref={ref} ariaLabel='Potential Opt-out'>
      <PanelHeader start={<PanelHeaderButton icon='close' onClick={handleCloseClick} />} title='Potential Opt-out' />
      <PanelContent className='flex flex-col'>
        <div className='min-h-[300px] max-w-[65%] mx-auto flex flex-col justify-center items-center'>
          {message
            ? (
              <>
                <ThreadItemDate date={new Date(message.time)} />
                <MessageThreadItemContentText
                  direction='in'
                  text={message.text}
                  type={message.type}
                />
              </>
              )
            : null}
        </div>
        <div className='px-5 py-3 border-t border-neutral-300 flex flex-row gap-2'>
          <Button
            className='basis-1/2'
            size='md'
            theme='danger'
            onClick={handleDoNotMessageClick}
          >
            Mark Do Not Message
          </Button>
          <Button
            className='basis-1/2'
            size='md'
            theme='neutral'
            variant='outline'
            onClick={handleNotAStopMessageClick}
          >
            Not an Opt-out
          </Button>
        </div>
      </PanelContent>
    </PanelDialog>
  )
})

PotentialStopMessageReviewDialog.displayName = 'PotentialStopMessageReviewDialog'
PotentialStopMessageReviewDialog.propTypes = propTypes
PotentialStopMessageReviewDialog.defaultProps = defaultProps

export default PotentialStopMessageReviewDialog
