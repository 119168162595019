import * as React from "react";
import { memo } from "react";
const SvgAivaWithShadow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 43"
    {...props}
  >
    <ellipse cx={21.149} cy={40.067} fill="#E6E7EB" rx={16.596} ry={2.556} />
    <g clipPath="url(#AivaWithShadow_svg__a)">
      <path
        fill="#6366F1"
        fillRule="evenodd"
        d="M40.366 18.92c.534.353.9.92.991 1.556a21.686 21.686 0 0 1-.002 6.136 2.236 2.236 0 0 1-2.079 1.926 7.85 7.85 0 0 1-.625.01 41.37 41.37 0 0 1-.201 1.49c-.332 2.162-2.012 3.887-4.19 4.297-8.78 1.654-17.61 1.58-26.484-.038a5.199 5.199 0 0 1-4.212-4.263c-.08-.496-.152-.99-.214-1.484a7.723 7.723 0 0 1-.376-.012 2.214 2.214 0 0 1-2.066-1.93 24.505 24.505 0 0 1 .012-6.103c.144-1.155.923-1.516 1.856-1.917.869-.374 1.01-1.462 1.13-2.394l.032-.241c.257-1.877.662-3.719 1.066-5.563.164-.747.328-1.494.483-2.244a1.103 1.103 0 0 1 1.08-.881H8.94c.493 0 .926.327 1.06.802l1.22 4.298c6.406-.994 12.933-1 19.585.028l1.228-4.326a1.102 1.102 0 0 1 1.06-.802h2.374c.523 0 .975.368 1.08.881.174.848.356 1.693.537 2.538.484 2.252.966 4.501 1.3 6.79.038.261.079.532.223.754.214.328.587.388.968.45.278.044.562.09.79.242Zm-7.644 13.566a4.447 4.447 0 0 0 3.595-3.678 33.386 33.386 0 0 0-.003-10.542 4.462 4.462 0 0 0-3.533-3.662 62.86 62.86 0 0 0-4.35-.717l-2.142 3.188a1.75 1.75 0 0 1-1.455.756h-7.65c-.596 0-1.15-.291-1.464-.771l-2.099-3.201c-1.488.19-2.97.437-4.446.742A4.454 4.454 0 0 0 5.69 18.2c-.601 3.472-.602 7.008.001 10.607a4.453 4.453 0 0 0 3.619 3.649c7.845 1.382 15.65 1.445 23.412.03ZM12.13 19.406a6 6 0 0 0-1.668.001 1.428 1.428 0 0 0-1.214 1.217 20.459 20.459 0 0 0-.009 5.803c.092.632.591 1.13 1.223 1.21.551.072 1.101.072 1.65 0a1.427 1.427 0 0 0 1.22-1.192c.321-2 .315-3.95.001-5.855a1.426 1.426 0 0 0-1.204-1.183Zm17.695.001a6 6 0 0 1 1.667 0 1.426 1.426 0 0 1 1.204 1.183c.313 1.904.32 3.855-.001 5.855-.1.623-.595 1.111-1.22 1.193a6.396 6.396 0 0 1-1.65 0 1.424 1.424 0 0 1-1.223-1.211 20.458 20.458 0 0 1 .009-5.803c.089-.63.584-1.13 1.214-1.217Zm-11.333 7.709h5.478a.75.75 0 0 1 .701 1.012 3.669 3.669 0 0 1-6.88 0 .75.75 0 0 1 .701-1.012Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="AivaWithShadow_svg__a">
        <path fill="#fff" d="M.723.448h40.851v40.897H.724z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgAivaWithShadow);
export default Memo;
