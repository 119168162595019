import * as React from "react";
import { memo } from "react";
const SvgTag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.81 1c-.75 0-1.453.299-1.978.81L1.83 11.668l-.005.005a2.821 2.821 0 0 0 0 3.987l6.516 6.516c1.1 1.1 2.887 1.1 3.987 0l9.852-9.997.003-.003A2.816 2.816 0 0 0 23 10.189V3.818A2.823 2.823 0 0 0 20.182 1h-6.371ZM3.238 13.089l9.992-9.847A.832.832 0 0 1 13.81 3h6.371c.448 0 .818.37.818.818v6.371c0 .22-.088.428-.234.574l-9.852 9.998-.003.002a.821.821 0 0 1-1.156-.002L3.24 14.245a.821.821 0 0 1-.002-1.156Zm15.219-6.545a1 1 0 1 0-2 0v.01a1 1 0 0 0 2 0v-.01Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTag);
export default Memo;
