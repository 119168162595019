import * as React from "react";
import { memo } from "react";
const SvgAdmin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.486.126a1 1 0 0 0-.972 0l-9 5A1 1 0 0 0 2 6v6.027c.063 2.29.486 4.653 1.95 6.776 1.47 2.128 3.9 3.89 7.739 5.147.202.067.42.067.622 0 3.84-1.257 6.27-3.019 7.738-5.147 1.465-2.123 1.888-4.487 1.95-6.776v-.011L22 12V6a1 1 0 0 0-.514-.874l-9-5ZM4 11.986V6.588l8-4.444 8 4.444v5.398c-.059 2.086-.442 4.007-1.597 5.681-1.12 1.622-3.045 3.128-6.403 4.278-3.358-1.15-5.284-2.656-6.403-4.278-1.155-1.674-1.538-3.595-1.597-5.68Zm12.332-2.431a1 1 0 0 0-1.664-1.11l-3.445 5.168-2.168-1.445a1 1 0 0 0-1.11 1.664l3 2a1 1 0 0 0 1.387-.277l4-6Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgAdmin);
export default Memo;
