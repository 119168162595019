import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import space from '../../../prop-types/shapes/space'
import { del } from '../../../services/space'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'
import Pluralize from '../../Pluralize'

const propTypes = {
  space: PropType.shape(space).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: [space?._id] })

  const confirmDialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback(() => {
    confirmDialogRef.current.close()
    bus.emit('spaceDeleted', space)
    navigate('/spaces')
  }, [navigate, space])
  const { call } = useService(del, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmDialogRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    call(space._id)
  }, [call, space._id])

  return (
    <DangerousConfirmDialog
      ref={confirmDialogRef}
      confirmButtonText='Delete'
      confirmValue='DELETE'
      title={`Are you sure you want to delete the space named: ${space.name}`}
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This space currently has
        {' '}
        <Pluralize count={space.userCount} singular='user' includeCount />
        .
      </p>
      <p className='mb-4'>
        This
        {' '}
        <strong>cannot</strong>
        {' '}
        be undone.
      </p>
    </DangerousConfirmDialog>
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
