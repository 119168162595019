import * as React from "react";
import { memo } from "react";
const SvgLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.917 20.5H3.29V8.816h3.628V20.5ZM5.101 7.222C3.941 7.222 3 6.262 3 5.102a2.101 2.101 0 1 1 4.202 0c0 1.16-.941 2.12-2.1 2.12ZM20.496 20.5h-3.62v-5.688c0-1.355-.028-3.094-1.887-3.094-1.886 0-2.175 1.473-2.175 2.996V20.5H9.19V8.816h3.48v1.594h.05c.485-.918 1.668-1.887 3.433-1.887 3.672 0 4.347 2.418 4.347 5.559V20.5h-.004Z"
    />
  </svg>
);
const Memo = memo(SvgLinkedin);
export default Memo;
