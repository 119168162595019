import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)
  test('newNumber', 'Phone number is required', () => {
    enforce(data.newNumber).isNotEmpty()
  })
})

export default validationSuite
