import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'
import { MarkColor } from '../../svg/branding'
import { Celebration, Clock } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import FormattedDate from '../FormattedDate'

const propTypes = {
  item: PropType.shape(contactShape).isRequired,
  itemSharedContext: PropType.shape({
    campaignId: PropType.string
  }).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['CampaignContactListItemContent'] })

const CampaignContactListItemContent = ({ item: contact, itemSharedContext: { campaignId } }) => {
  useLogger({ log, lifecycle: true, tags: [contact._id] })

  const campaignJoinDate = contact.campaignJoinDates.reduce((memo, { campaign: joinDateCampaignId, joinDate }) => {
    return joinDateCampaignId === campaignId ? joinDate : memo
  }, undefined)

  const eventDate = contact.campaignJoinDates.reduce((memo, { campaign: joinDateCampaignId, eventDate }) => {
    return joinDateCampaignId === campaignId ? eventDate : memo
  }, undefined)

  const eventDateSection = eventDate
    ? (
      <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
        <Celebration className='text-black shrink-0 grow-0 h-3 w-3' />
        <div className='line-clamp-1'>
          <FormattedDate date={eventDate} format='extendedDateAndTime' />
        </div>
      </div>
      )
    : null

  return (
    <>
      <Avatar contactOrUser={contact} size='sm' />
      <div className='flex flex-col ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {contact.formattedName}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{contact.formattedPhoneNumber}</div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Clock className='text-black shrink-0 grow-0 h-3 w-3' />
          <div className='line-clamp-1'>
            <FormattedDate date={campaignJoinDate} format='extendedDateAndTime' />
          </div>
        </div>
        {eventDateSection}
      </div>
    </>
  )
}

CampaignContactListItemContent.displayName = 'CampaignContactListItem'
CampaignContactListItemContent.propTypes = propTypes
CampaignContactListItemContent.defaultProps = defaultProps

export default CampaignContactListItemContent
