import proxy from './proxy'
import userFromJSON from './user'

export default function attachmentFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get fileNameAndSize () {
      return json.key ? `${json.fileName} (${json.fileSize})` : '(no file uploaded)'
    }
  }

  const model = proxy(json, overrides)
  if (json.user?._id) {
    model.user = userFromJSON(json.user)
  }
  return model
}
