import { RedirectFromFolder } from '../components/folders/RedirectFromFolder'
import OutletOrComponent from '../components/OutletOrComponent'
import QrCodesDetail from '../screens/qr-codes/QrCodesDetail'
import QrCodesFolderDetail from '../screens/qr-codes/QrCodesFolderDetail'

const qrCodeRoutes = [
  {
    path: ':qrCodeId',
    element: <QrCodesDetail />
  }
]

const routes = [
  ...qrCodeRoutes,
  {
    path: 'folder',
    element: <RedirectFromFolder />
  },
  {
    path: 'folder/:folderId',
    element: <OutletOrComponent component={<QrCodesFolderDetail />} />,
    children: [...qrCodeRoutes]
  }
]

export default routes
