import Rollbar from 'rollbar'

const rollbarConfig = {
  enabled: process.env.REACT_APP_ROLLBAR_ENABLED === 'true',
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.NODE_ENV,
  server: {
    root: process.env.REACT_APP_URL
  },
  codeVersion: process.env.REACT_APP_PB_COMMIT || process.env.REACT_APP_PB_VERSION
}

const rollbar = new Rollbar(rollbarConfig)

export default rollbar
