import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import space from '../../../prop-types/shapes/space'
import { leave } from '../../../services/space'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  space: PropType.shape(space).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['LeaveAction'] })

const LeaveAction = forwardRef(({ space }, ref) => {
  useLogger({ log, lifecycle: false, tags: [space?._id] })
  const confirmDialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback(() => {
    bus.emit('spaceLeft', space)
    navigate('/spaces')
  }, [navigate, space])
  const { call } = useService(leave, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmDialogRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    call(space._id)
  }, [call, space._id])

  return (
    <ConfirmDialog
      ref={confirmDialogRef}
      description={`You will no longer be a member of the space named: ${space.name}`}
      title='Are you sure?'
      onConfirm={handleConfirm}
    />
  )
})

LeaveAction.displayName = 'LeaveAction'
LeaveAction.propTypes = propTypes
LeaveAction.defaultProps = defaultProps

export default LeaveAction
