import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { create, enforce, only, test } from 'vest'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import {
  applyTagToContacts as addAllContactsService,
  addSelectedContacts as addSelectedContactsService
} from '../../../services/tag'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { warning } from '../../banners/Banner'
import ContactList from '../../contacts/ContactList'
import FormDialog from '../../dialog/FormDialog'
import ListDialog from '../../dialog/ListDialog'

const propTypes = {
  mode: PropType.oneOf([
    'default',
    'tag-name-only'
  ]),
  onSuccess: PropType.func
}

const defaultProps = {
  mode: 'default',
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ mode, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const navigate = useNavigate()

  const collectTagNameDialogRef = useRef()
  const addContactsDialogRef = useRef()

  const [newTagName, setNewTagName] = useState('')

  const handleReplyOk = useCallback((reply) => {
    const newTag = reply.json
    if (newTag) {
      setCurrentInstance('tag', newTag)
      bus.emit('tagCreated', newTag)
      navigate(`/tags/${newTag.name}`)
      onSuccess?.(newTag)
    }
  }, [navigate, onSuccess])
  const { call: addSelectedContacts } = useService(addSelectedContactsService, { onReplyOk: handleReplyOk })
  const { call: addAllContacts } = useService(addAllContactsService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      setNewTagName('')
      collectTagNameDialogRef.current.open()
    }
  }), [])

  const handleCreateStart = useCallback(({ tagName }) => {
    collectTagNameDialogRef.current.close()
    tagName = tagName.toLowerCase()
    if (mode === 'tag-name-only') {
      onSuccess?.({ name: tagName })
    } else {
      setNewTagName(tagName)
      addContactsDialogRef.current.open()
    }
  }, [mode, onSuccess])

  const handleContactsSelected = useCallback((data) => {
    log.info('handleContactsSelected data:', data)
    if (data?.length > 0) {
      addSelectedContacts(newTagName, map(data, '_id'))
    } else {
      warning('You must add at least one contact when creating a new tag.')
      collectTagNameDialogRef.current.open()
    }
  }, [addSelectedContacts, newTagName])

  const handleAllContactsSelected = useCallback((data, count) => {
    log.info('handleAllContactsSelected data:', data, count)
    if (count > 0) {
      addAllContacts({ tagName: newTagName, ...data })
    } else {
      warning('You must add at least one contact when creating a new tag.')
      collectTagNameDialogRef.current.open()
    }
  }, [addAllContacts, newTagName])

  return (
    <>
      <FormDialog
        ref={collectTagNameDialogRef}
        defaultValues={{ tagName: newTagName }}
        description='Used for grouping multiple contacts together under this label'
        formControls={[
          {
            name: 'tagName',
            placeholder: 'Tag Name'
          }
        ]}
        submitTitle='Next'
        title='New Tag'
        validationSuite={
          create((data = {}, currentField) => {
            only(currentField)
            test('tagName', 'Tag name is required', () => {
              enforce(data.tagName).isNotEmpty()
            })
          })
        }
        onSubmit={handleCreateStart}
      />
      <ListDialog
        ref={addContactsDialogRef}
        list={ContactList}
        subtitle={newTagName}
        title='Add Contacts'
        type='add'
        multiple
        onSubmitAllInQuery={handleAllContactsSelected}
        onSubmitSelected={handleContactsSelected}
      />
    </>
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
