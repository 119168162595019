import { useParams } from 'react-router-dom'

import { get as getCampaignEventService } from '../services/campaign-event'

import useCurrentInstance from './useCurrentInstance'

const useCurrentCampaignEvent = () => {
  const { campaignId } = useParams()
  return useCurrentInstance({
    resourceName: 'campaignEvent',
    getService: (campaignEventId, background) => getCampaignEventService(campaignId, campaignEventId, background),
    replyAttrPath: 'model',
    notFoundRedirect: `/campaigns/${campaignId}`
  })
}

export default useCurrentCampaignEvent
