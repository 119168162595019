/* IMPORTANT NOTE: This is a stand alone set of routes used by ShortLinkApp */
import React from 'react'
import { matchRoutes, useRoutes } from 'react-router-dom'

import ShortLinkLayout from '../components/short-links/ShortLinkLayout'
import Attachment from '../screens/short-links/Attachment'
import ConfigurableForm from '../screens/short-links/ConfigurableForm'
import ContactLink from '../screens/short-links/ContactLink'
import DoubleOptIn from '../screens/short-links/DoubleOptIn'
import SingleOptIn from '../screens/short-links/SingleOptIn'
import TextKeywordLink from '../screens/short-links/TextKeywordLink'
import TrackableLink from '../screens/short-links/TrackableLink'
import Unsubscribe from '../screens/short-links/Unsubscribe'

const routes = [
  {
    path: '/',
    element: <ShortLinkLayout />,
    children: [
      {
        path: 'a/:attachmentSlug/:contactSlug',
        element: <Attachment />
      },
      {
        path: 'c/:contactSlugOrId/k/:keywordSlugOrId/:random?',
        element: <ContactLink />
      },
      {
        path: 'c/:contactSlugOrId/:random?',
        element: <ContactLink />
      },
      {
        path: 'd/:contactSlug',
        element: <DoubleOptIn />
      },
      {
        path: 'f/:configurableFormSlug/:contactSlug',
        element: <ConfigurableForm />
      },
      {
        path: 'l/:trackableLinkSlug/:contactSlug',
        element: <TrackableLink />
      },
      {
        path: 't/:keywordSlug',
        element: <TextKeywordLink />
      },
      {
        path: 'unsubscribe/:contactSlug',
        element: <Unsubscribe />
      }
    ]
  }, {
    path: 'u/:slug',
    element: <ShortLinkLayout excludeFooter />,
    children: [
      {
        path: 'subscribe',
        element: <SingleOptIn />
      }
    ]
  }
]

export function isShortLinkRoute (url) {
  if (url.pathname === '/') { return false }
  return matchRoutes(routes, url)?.length
}

export function ShortLinkRoutes () {
  const routesAsElements = useRoutes(routes)
  return routesAsElements
}

export default routes
