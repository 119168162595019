import * as React from "react";
import { memo } from "react";
const SvgPersonAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 6.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM8 1a5.5 5.5 0 1 0 0 11A5.5 5.5 0 0 0 8 1Zm0 13c-2.132 0-4.1.664-5.557 1.783C.986 16.903 0 18.52 0 20.38V22a1 1 0 1 0 2 0v-1.62c0-1.11.584-2.183 1.662-3.01C4.74 16.54 6.272 16 8 16c1.727 0 3.259.543 4.338 1.373 1.079.83 1.662 1.903 1.662 3.007V22a1 1 0 1 0 2 0v-1.62c0-1.856-.986-3.473-2.443-4.593C12.101 14.667 10.133 14 8 14Zm12-6a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2V9a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPersonAdd);
export default Memo;
