import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as tagSearch } from '../../services/tag'
import List from '../list/List'

import TagListItemContent from './TagListItemContent'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func
}

const defaultProps = {
  queryOverrides: {},
  searchService: tagSearch
}

const log = logger({ enabled: false, tags: ['TagList'] })

const TagList = forwardRef(({ queryOverrides, searchService, ...rest }, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tags: [] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('tagCreated', handleBusEvent)
  useBus('tagUpdated', handleBusEvent)
  useBus('tagDeleted', handleBusEvent)
  useBus('tagsDeleted', handleBusEvent)

  return (
    <List
      ref={ref}
      ListItemContent={TagListItemContent}
      placeholder='Search Tags'
      queryOverrides={queryOverrides}
      resultsKey='tags'
      searchService={searchService}
      {...rest}
    />
  )
})

TagList.displayName = 'TagList'
TagList.propTypes = propTypes
TagList.defaultProps = defaultProps

export default TagList
