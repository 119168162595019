import classNames from 'classnames'
import { omit, pick } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { firstVoiceUrl } from '../../../helpers/messageMedia'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

import MessageVoiceAttachment from './voice-drop/MessageVoiceAttachment'
import Tabs from './voice-drop/Tabs'

const propTypes = {
  name: PropType.string.isRequired,
  className: PropType.string,
  error: PropType.string, // only outputs when theme === 'input
  hint: PropType.string, // only outputs when theme === 'input'
  label: PropType.string, // only outputs when theme === 'input'
  required: PropType.bool, // for standlone usage (like chat threads)
  onChange: PropType.func
}

const defaultProps = {
  className: null,
  error: null,
  hint: null,
  label: null,
  onChange: undefined,
  required: true
}

const log = logger({ enabled: true, tags: ['VoiceDrop'] })

const defaultMessage = {
  text: '',
  type: 'rvm',
  media: [],
  upload: null,
  call: null
}

const VoiceDrop = forwardRef(({
  name,
  className,
  error,
  hint,
  label,
  required,
  onChange
}, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const currentUser = useCurrentUser()

  const [selectedTab, setSelectedTab] = useState('upload') // upload or call

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form

  const initialMessageValue = pick(useFormMethods?.getValues(name), 'text', 'type', 'media')
  const [message, setMessage] = useState({ ...defaultMessage, ...initialMessageValue })

  const handleVoiceSelect = useCallback(({ upload, call }) => {
    useFormMethods?.clearErrors(name)
    const updatedMessage = { ...omit(message, 'call', 'upload') }
    updatedMessage.media = []
    if (upload) { updatedMessage.upload = upload }
    if (call) { updatedMessage.call = call }
    updatedMessage.type = 'rvm'
    setMessage(updatedMessage)
  }, [message, name, useFormMethods])

  error = error || useFormMethods?.formState?.errors?.[name]?.message

  useFormMethods?.register(name)

  const validateMessage = useCallback(async ({ message }) => {
    let errorMessage = null
    const { upload, call } = message
    const voiceUrl = firstVoiceUrl(message)
    if (call) {
      const unsupportedPlan = currentUser.phoneNumberType !== 'managed-voip'
      const missingCallForwardingNumber = !currentUser.callForwardNumber
      const callForwardingNotEnabled = !currentUser.callForwardEnabled
      if (unsupportedPlan) {
        errorMessage = 'Your current plan does not support the Call to Record feature.'
      }
      if (missingCallForwardingNumber) {
        errorMessage = 'A Call Forwarding number is required to use the Call to Record feature.'
      }
      if (callForwardingNotEnabled) {
        errorMessage = 'Call Forwarding must be enabled to use the Call to Record feature.'
      }
    } else if (required && !voiceUrl && !upload) {
      errorMessage = 'A recording is required.'
    }

    useFormMethods?.setCustomError(name, { message: errorMessage })

    return errorMessage
  }, [currentUser.callForwardEnabled, currentUser.callForwardNumber, currentUser.phoneNumberType, name, required, useFormMethods])

  useImperativeHandle(ref, () => ({
    get message () { return message },

    async hasError () {
      return await validateMessage({ message })
    },

    reset (messageOverrideValue = null) {
      setMessage({ ...defaultMessage, ...initialMessageValue, ...messageOverrideValue })
    }
  }), [validateMessage, initialMessageValue, message])

  const selectUpload = useCallback(() => {
    setSelectedTab('upload')
    handleVoiceSelect({ call: false })
  }, [handleVoiceSelect])

  const selectCall = useCallback(() => {
    setSelectedTab('call')
    handleVoiceSelect({ call: true })
  }, [handleVoiceSelect])

  const handleUpload = useCallback((upload) => {
    handleVoiceSelect({ upload })
  }, [handleVoiceSelect])

  const clearMedia = useCallback(() => {
    const updatedMessage = { ...omit(message, 'call', 'upload') }
    updatedMessage.media = []
    updatedMessage.type = 'rvm'
    setMessage(updatedMessage)
  }, [message])

  useEffect(() => {
    useFormMethods?.setValue(name, message)
    onChange?.(message)
    validateMessage({ message })
  }, [name, message, onChange, useFormMethods, validateMessage])

  const uploadWebPathOrMediaUrl = useMemo(() => { return message.upload?.webPath || firstVoiceUrl(message) }, [message])

  const computedClassName = classNames('border border-neutral-200 pb-4 mb-2', className)

  return (
    <>
      <InputLabel text={label} />
      <div className={computedClassName}>
        {uploadWebPathOrMediaUrl
          ? <MessageVoiceAttachment mediaUrl={uploadWebPathOrMediaUrl} onRemove={clearMedia} />
          : <Tabs
              selected={selectedTab}
              onCallTabClick={selectCall}
              onUpload={handleUpload}
              onUploadTabClick={selectUpload}
            />}
      </div>
      <InputError message={error} />
      <InputHint message={hint} />
    </>
  )
})

VoiceDrop.displayName = 'VoiceDrop'
VoiceDrop.propTypes = propTypes
VoiceDrop.defaultProps = defaultProps

export default VoiceDrop
