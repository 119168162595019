import BlogPromoBanner from '../components/BlogPromoBanner'
import Button from '../components/buttons/Button'
import SupportLink from '../components/links/SupportLink'
import Panel from '../components/panels/Panel'
import PanelContent from '../components/panels/PanelContent'
import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { Android, Apple, NewWindow } from '../svg/icons'
import { Community, Ninja, Training } from '../svg/misc/empty-panel'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['GettingStarted'] })

const GettingStarted = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const promoLinkClassName = 'relative flex flex-col mb-4 p-5 min-w-[200px] w-[30%] items-center border border-neutral-200 rounded-lg drop-shadow bg-white text-center'

  return (
    <Panel>
      <PanelContent className='w-full h-full flex flex-col items-center justify-between overflow-auto bg-white'>
        <BlogPromoBanner />
        <div>
          <h2 className='label-lg-strong text-black mb-6 text-center'>Project Broadcast Success Plan</h2>
          <div className='flex flex-row flex-wrap justify-evenly mb-2'>
            <a
              className={promoLinkClassName}
              href='http://training.projectbroadcast.com'
              rel='noopener noreferrer'
              target='_blank'
            >
              <NewWindow className='absolute top-4 right-4 text-neutral-400' height='16' width='16' />
              <Ninja width='50%' />
              <h3 className='mb-2 label-sm-strong text-black'>Web School Ninja</h3>
              <p className='label-sm text-neutral-600'>
                Start your journey with our
                {' '}
                <span className='text-primary'>Project Broadcast Web School</span>
                {' '}
                where you can get training and earn credits going from noob to ninja!
              </p>
            </a>
            <a
              className={promoLinkClassName}
              href='https://www.projectbroadcast.com/video/'
              rel='noopener noreferrer'
              target='_blank'
            >
              <NewWindow className='absolute top-4 right-4 text-neutral-400' height='16' width='16' />
              <Training width='50%' />
              <h3 className='mb-2 label-sm-strong text-black'>Training Videos</h3>
              <p className='label-sm text-neutral-600'>
                Love video training? We got you! Check out our
                {' '}
                <span className='text-primary'>video tutorials</span>
                {' '}
                for strategy!
              </p>
            </a>
            <a
              className={promoLinkClassName}
              href='https://www.facebook.com/groups/projectbroadcast/'
              rel='noopener noreferrer'
              target='_blank'
            >
              <NewWindow className='absolute top-4 right-4 text-neutral-400' height='16' width='16' />
              <Community width='50%' />
              <h3 className='mb-2 label-sm-strong text-black'>Join the Community</h3>
              <p className='label-sm text-neutral-600'>
                You can ask questions and learn from other users having success with Project Broadcast. Our
                {' '}
                <span className='text-primary'>Facebook group</span>
                {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
              </p>
            </a>
          </div>
        </div>
        <p className='label-sm text-neutral-600 mb-4'>
          Still stuck? Drop us an email at
          {' '}
          <SupportLink />
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <div>
          <h3 className='label-sm-strong text-black mb-1 text-center'>Don’t Forget To Download Our App!</h3>
          <div className='w-full flex flex-row flex-wrap justify-center gap-4 p-4 mb-2'>
            <Button
              className='!text-black shrink-0'
              href='https://itunes.apple.com/us/app/project-broadcast/id1289777524'
              size='md'
              start={<Apple />}
              theme='neutral'
              variant='outline'
            >
              Download for iOS
            </Button>
            <Button
              className='!text-black shrink-0'
              href='https://play.google.com/store/apps/details?id=com.starterstep.broadcast'
              size='md'
              start={<Android />}
              theme='neutral'
              variant='outline'
            >
              Download for Android
            </Button>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

GettingStarted.displayName = 'GettingStarted'
GettingStarted.propTypes = propTypes
GettingStarted.defaultProps = defaultProps

export default GettingStarted
