import PropType from 'prop-types'

import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import colors from '../../tailwindcss/colors'

ColorChip.propTypes = {
  hex: PropType.string.isRequired,
  name: PropType.string.isRequired
}

function ColorChip ({ name, hex }) {
  return (
    <div className='m-1 w-20'>
      <div className='w-20 h-20' style={{ backgroundColor: hex }} />
      <p className='text-xs'>{hex}</p>
      <p className='text-xs'>{name}</p>
    </div>
  )
}

function Colors () {
  const chips = Object.entries(colors).reduce((arr, [key, value]) => {
    if (typeof value === 'string') {
      arr.push(<ColorChip key={`${key}`} hex={value} name={key} />)
    } else {
      const collection = []
      Object.entries(value).forEach(([subKey, subValue]) => {
        if (typeof subValue === 'string') {
          collection.push(<ColorChip key={`${key}-${subKey}`} hex={subValue} name={`${key}-${subKey}`} />)
        } else {
          Object.entries(subValue).forEach(([subSubKey, subSubValue]) => {
            collection.push(<ColorChip key={`${key}-${subKey}-${subSubKey}`} hex={subSubValue} name={`${key}-${subKey}-${subSubKey}`} />)
          })
        }
      })
      arr.push(
        <div key={`${key}-header`} className='flex flex-col w-full my-5'>
          <div className='flex flex-row flex-wrap'>{collection}</div>
        </div>
      )
    }
    return arr
  }, [])
  return (
    <Panel>
      <PanelHeader title='Colors' />
      <PanelContent>
        <div className='flex flex-row w-full flex-wrap p-5'>
          {chips}
        </div>
      </PanelContent>
    </Panel>
  )
}

export default Colors
