import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import landingPageShape from '../../../prop-types/shapes/landingPage'
import { del as deleteService } from '../../../services/landingPage'
import { success } from '../../banners/Banner'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  landingPage: PropType.shape(landingPageShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const DeleteAction = forwardRef(({ landingPage, onSuccess }, ref) => {
  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${landingPage.name} was deleted`)
    bus.emit('landingPageDeleted', landingPage)
    if (landingPage.folderId) {
      bus.emit('landingPageRemovedFromFolder', landingPage)
      bus.emit('foldersUpdated')
    }
    onSuccess?.()
  }, [landingPage, onSuccess])
  const { call: deleteLandingPage } = useService(deleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    deleteLandingPage([landingPage._id], false)
  }, [deleteLandingPage, landingPage._id])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description='Are you sure you want to delete this landing page? This can not be undone.'
      title={`Delete ${landingPage.name}?`}
      onConfirm={handleConfirm}
    />
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
