import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'
import messageShape from '../../prop-types/shapes/message'
import { MarkColor } from '../../svg/branding'
import { Clock } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import FormattedDate from '../FormattedDate'

const itemShape = {
  ...contactShape,
  message: PropType.shape(messageShape)
}

const propTypes = {
  item: PropType.shape(itemShape).isRequired,
  itemSharedContext: PropType.shape({
    listState: PropType.oneOf(['sending', 'sent', 'unsent'])
  }).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: true, tags: ['ScheduledBroadcastContactListItemContent'] })

const ScheduledBroadcastContactListItemContent = ({ item: contact, itemSharedContext }) => {
  useLogger({ log, lifecycle: true, tags: [contact._id] })
  const { listState } = itemSharedContext

  const stateDescription = useMemo(() => {
    const { error, skipMessage, deliveryDescription, state: messageState } = contact.message
    return error || skipMessage || deliveryDescription || upperFirst(listState) || upperFirst(messageState)
  }, [contact, listState])

  return (
    <>
      <Avatar contactOrUser={contact} size='sm' />
      <div className='flex flex-col flex-grow ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {contact.formattedName}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{contact.formattedPhoneNumber}</div>
        {contact.message.time
          ? (
            <div className='label-xs text-neutral-500 mt-[3px] flex flex-row gap-1 items-center'>
              <Clock className='text-black shrink-0 grow-0 h-3 w-3' />
              <FormattedDate date={contact.message.time} format='extendedDateAndTime' />
            </div>
            )
          : null}
      </div>
      <div className='flex flex-none basis-1/3 self-center'>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {stateDescription}
        </div>
      </div>
    </>
  )
}

ScheduledBroadcastContactListItemContent.displayName = 'ScheduledBroadcastContactListItem'
ScheduledBroadcastContactListItemContent.propTypes = propTypes
ScheduledBroadcastContactListItemContent.defaultProps = defaultProps

export default ScheduledBroadcastContactListItemContent
