import * as React from "react";
import { memo } from "react";
const SvgGroupChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 0A6.497 6.497 0 0 0 0 6.5c0 2.307 1.2 4.317 3 5.468V15.5a1 1 0 0 0 1.573.82L9.315 13H11.5c3.592 0 6.5-2.908 6.5-6.5S15.092 0 11.5 0h-5ZM2 6.5C2 4.012 4.012 2 6.5 2h5C13.988 2 16 4.012 16 6.5S13.988 11 11.5 11H9a1 1 0 0 0-.573.18L5 13.58v-2.19a1 1 0 0 0-.545-.89A4.482 4.482 0 0 1 2 6.5Zm19.896 3.223a1 1 0 0 0-1.352 1.474A4.473 4.473 0 0 1 22 14.5a4.494 4.494 0 0 1-2.455 4 1 1 0 0 0-.545.89v1.934l-4.085-2.204a1 1 0 0 0-.475-.12H12.5a4.47 4.47 0 0 1-3.337-1.49 1 1 0 0 0-1.486 1.34A6.47 6.47 0 0 0 12.5 21h1.687l5.338 2.88A1 1 0 0 0 21 23v-3.032a6.487 6.487 0 0 0 3-5.468 6.472 6.472 0 0 0-2.104-4.777Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgGroupChat);
export default Memo;
