import { useElements, useStripe } from '@stripe/react-stripe-js'
import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import { error, notify } from '../../components/banners/Banner'
import FormDialog from '../../components/dialog/FormDialog'
import SubscriptionSummary from '../../components/plans/SubscriptionSummary'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import useStackBackButton from '../../hooks/useStackBackButton'
import logger from '../../lib/logger'
import { changePlan as changePlanService } from '../../services/subscription'
import { update } from '../../store/actions/currentUser'

const propTypes = {
  numbers: PropType.objectOf(
    PropType.arrayOf(
      PropType.shape({
        nationalNumber: PropType.string.isRequired,
        number: PropType.string.isRequired
      })
    ).isRequired
  ),
  plan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  reanchorImmediately: PropType.bool
}

const defaultProps = {
  numbers: null,
  plan: null,
  reanchorImmediately: false
}

const log = logger({ enabled: false, tags: ['ChooseNumber'] })

const ChooseNumber = forwardRef(({ numbers, plan, reanchorImmediately }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const stripe = useStripe()
  const elements = useElements()
  const { handleBackButtonClick } = useStackBackButton()

  const optionsData = []
  if (numbers) {
    for (const key in numbers) {
      optionsData.push({
        label: key,
        value: numbers[key].map((info) => {
          return {
            label: info.nationalNumber,
            value: info.number
          }
        })
      })
    }
  }

  const handlePlanChangeOk = useCallback(() => {
    ref.current.close()
    notify('Your plan change request has been submitted.')
    handleBackButtonClick()
  }, [handleBackButtonClick, ref])
  const { call: changePlan } = useServiceAndAction(changePlanService, update, { onReplyOk: handlePlanChangeOk })

  const handleSubmit = useCallback(async ({ phone }) => {
    const card = elements.getElement('card')
    const { error: stripeError, token } = await stripe.createToken(card)

    if (stripeError) {
      const isCustomerFriendlyStripeErrorType = stripeError.type === 'card_error'
      error(isCustomerFriendlyStripeErrorType ? stripeError.message : 'Something went wrong. Please try again later.')
      return
    }

    const data = {
      phone,
      planId: plan.id,
      reanchorImmediately,
      stripeResult: token
    }

    log.info('ChooseNumber', 'handleSubmit', { data })

    changePlan(data)
  }, [changePlan, elements, plan.id, reanchorImmediately, stripe])

  return (
    <FormDialog
      ref={ref}
      defaultValues={{}}
      description='Choose a phone number to use for your Project Broadcast account.'
      formControls={[
        {
          autoFocus: true,
          name: 'phone',
          type: 'select',
          label: 'Project Broadcast Phone Number',
          placeholder: 'Choose a phone number',
          hint: 'This will be the phone number all of your Project Broadcast messages originate from.',
          required: true,
          options: optionsData
        },
        {
          name: 'card',
          type: 'stripecardelement',
          label: 'Payment Info',
          hint: `Your card will be charged ${plan.frequency.toLowerCase()}.`
        },
        {
          type: 'passthrough',
          name: 'subscription-summary',
          element: <SubscriptionSummary plan={plan} reanchorImmediately={reanchorImmediately} />
        }
      ]}
      submitTitle='Change Subscription'
      title='Change Subscription'
      onSubmit={handleSubmit}
    />
  )
})

ChooseNumber.displayName = 'ChooseNumber'
ChooseNumber.propTypes = propTypes
ChooseNumber.defaultProps = defaultProps

export default ChooseNumber
