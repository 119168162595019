import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import MailLink from './MailLink'

const propTypes = {
  className: PropType.string,
  label: PropType.string
}

const defaultProps = {
  className: 'link',
  label: null
}

const log = logger({ enabled: false, tags: ['SupportLink'] })

const SupportLink = ({ className, label, ...rest }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const to = 'support@projectbroadcast.com'

  return (
    <MailLink
      className={className}
      label={label || to}
      to={to}
      {...rest}
    />

  )
}

SupportLink.displayName = 'SupportLink'
SupportLink.propTypes = propTypes
SupportLink.defaultProps = defaultProps

export default SupportLink
