import * as React from "react";
import { memo } from "react";
const SvgPortriats = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 0a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H5ZM2 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm1 5c-.548 0-1 .452-1 1v10c0 .548.452 1 1 1a1 1 0 1 1 0 2c-1.652 0-3-1.348-3-3V11c0-1.652 1.348-3 3-3h18c1.652 0 3 1.348 3 3v10c0 1.652-1.348 3-3 3a1 1 0 1 1 0-2c.548 0 1-.452 1-1V11c0-.548-.452-1-1-1H3Zm9 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3.5 1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM12 19c-1.505 0-2.92.32-3.996.884C6.98 20.422 6 21.34 6 22.62V23a1 1 0 1 0 2 0v-.38c0-.159.146-.552.933-.964C9.67 21.269 10.755 21 12 21s2.33.27 3.067.656c.787.412.933.805.933.964V23a1 1 0 1 0 2 0v-.38c0-1.28-.98-2.198-2.005-2.736C14.92 19.321 13.505 19 12 19Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPortriats);
export default Memo;
