import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useTimeZone from '../../../../hooks/useTimeZone'
import { formatDateForInput, formatTimeForInput } from '../../../../lib/formatters'
import logger from '../../../../lib/logger'
import FormDialog from '../../../dialog/FormDialog'

const propTypes = {
  onSubmit: PropType.func.isRequired,
  currentDateTime: PropType.instanceOf(Date)
}

const defaultProps = {
  currentDateTime: null
}

const log = logger({ enabled: true, tags: ['ScheduleMessageDialog'] })

const ScheduleMessageDialog = forwardRef(({ currentDateTime, onSubmit }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { userTimeZone } = useTimeZone()

  const minDate = formatDateForInput(new Date(), userTimeZone)
  const minTime = formatTimeForInput(new Date(), userTimeZone)
  const currentDate = formatDateForInput(currentDateTime || new Date(), userTimeZone)
  const currentTime = formatTimeForInput(currentDateTime || new Date(), userTimeZone)

  const defaultValues = {
    date: currentDate,
    time: currentTime
  }

  const formControls = [
    {
      name: 'date',
      type: 'date',
      required: true
    },
    {
      name: 'time',
      type: 'time-message-send',
      required: true
    }
  ]

  const handleSubmit = useCallback((data) => {
    onSubmit?.(data)
  }, [onSubmit])

  return (
    <FormDialog
      ref={ref}
      confirm={{
        description: 'Clicking "Confirm" will cause cause the scheduled chat to begin sending to your recipients now.',
        title: 'Are you sure you want to schedule this message to send immediately?',
        when: ({ date, time }) => (date <= minDate && time <= minTime)
      }}
      defaultValues={defaultValues}
      description='Pick a date and time to send the message.'
      formControls={formControls}
      submitTitle='Schedule Chat'
      title='Schedule Chat'
      onSubmit={handleSubmit}
    />
  )
})

ScheduleMessageDialog.displayName = 'ScheduleMessageDialog'
ScheduleMessageDialog.propTypes = propTypes
ScheduleMessageDialog.defaultProps = defaultProps

export default ScheduleMessageDialog
