import PropType from 'prop-types'
import { useCallback, useEffect, useId, useRef } from 'react'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import useService from '../../../hooks/useService'
import { mfaViaFormatted } from '../../../lib/formatters'
import {
  mfaInitiate as mfaInitiateService,
  mfaLogin as mfaLoginService
} from '../../../services/user'
import { login as loginAction } from '../../../store/actions/currentUser'
import Header from '../shared/Header'

import validationSuite from './Form.validations'

const propTypes = {
  onAccountCancelled: PropType.func.isRequired,
  onLoggedIn: PropType.func.isRequired,
  backStepIndex: PropType.number,
  method: PropType.shape({
    _id: PropType.string,
    channel: PropType.string,
    redactedTo: PropType.string,
    to: PropType.string
  }),
  nonce: PropType.string,
  username: PropType.string
}

const defaultProps = {
  backStepIndex: 0,
  method: null,
  nonce: null,
  username: null
}

const MFAForm = ({ backStepIndex, nonce, method, username, onAccountCancelled, onLoggedIn }) => {
  const formRef = useRef()
  const formId = useId()
  const { goToStep } = useWizard()

  const handleMFALoginReplyOk = useCallback((reply) => {
    formRef.current.reset()
    loginAction(reply) // assigns current user if ok
    onLoggedIn()
  }, [onLoggedIn])

  const handleMFALoginError = useCallback((reply) => {
    formRef.current.reset()
    if (reply.status === 403) {
      onAccountCancelled()
    }
  }, [onAccountCancelled])

  const { call: mfaInitiate } = useService(mfaInitiateService)
  const { call: mfaLogin } = useService(mfaLoginService, { onReplyOk: handleMFALoginReplyOk, onReplyNotOk: handleMFALoginError })

  useEffect(() => {
    if (nonce && username && method?._id) {
      // the AddMethod component triggers mfaInitiate, the LoginForm component does not, so we need to.
      mfaInitiate(username, nonce, method)
    }
  }, [method, mfaInitiate, nonce, username])

  const handleGoBack = useCallback(() => goToStep(backStepIndex), [backStepIndex, goToStep])
  const handleSubmit = useCallback(({ verificationCode }) => mfaLogin(username, nonce, method, verificationCode), [method, mfaLogin, nonce, username])

  const subtitle = (
    <>
      {'Enter the verification code received via '}
      <span className='whitespace-nowrap'>
        {mfaViaFormatted(method)}
      </span>
    </>
  )

  return (
    <>
      <Header
        goBack={handleGoBack}
        heading='Sign In'
        subtitle={subtitle}
        title='Verification Code'
      />
      <Form
        ref={formRef}
        className='mt-5'
        defaultValues={{ verificationCode: '' }}
        formControls={[
          {
            autoComplete: 'one-time-code',
            name: 'verificationCode',
            placeholder: 'Enter your verification code',
            autoFocus: true
          }
        ]}
        id={formId}
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <Button
        className='mt-5 w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Continue
      </Button>
    </>
  )
}

MFAForm.displayName = 'MultiFactorAuthenticationForm'
MFAForm.propTypes = propTypes
MFAForm.defaultProps = defaultProps

export default MFAForm
