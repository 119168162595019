import { registerPlugin } from '@capacitor/core'

const PbNativeUtil = registerPlugin('PbNativeUtil')

/*
  export interface PbNativeUtilPlugin {
    // [ANDROID] Launch the Android Home Screen (puts app in the background)
    launchAndroidHomeScreen()
  }
*/

export default PbNativeUtil
