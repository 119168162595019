import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import Button from '../buttons/Button'

const propTypes = { // will also pass through any other props to Button
  label: PropType.node.isRequired,
  className: PropType.string,
  end: PropType.element,
  start: PropType.element
}

const defaultProps = {
  className: null,
  end: null,
  start: null
}

const log = logger({ enabled: false, tags: ['ActionMenuItem'] })

const ActionMenuItem = forwardRef(({ className, end, label, start, ...rest }, ref) => {
  useLogger({ log, lifecycle: true, tags: [label] })

  const computedClassName = classNames('text-left label-sm-md px-[6px] py-[3px] my-[3px] min-w-[40px] min-h-[40px] flex items-center', className)

  return (
    <Button
      ref={ref}
      className={computedClassName}
      role='menuitem'
      size='none'
      theme='none'
      variant='none'
      {...rest}
    >
      {start ? <span className='block mr-[13px] h-[18px] w-[18px]'>{start}</span> : null}
      <span className='mr-auto'>{label}</span>
      {end ? <span className='block ml-[13px] h-[18px] w-[18px] content-end'>{end}</span> : null}
    </Button>
  )
})

ActionMenuItem.displayName = 'ActionMenuItem'
ActionMenuItem.propTypes = propTypes
ActionMenuItem.defaultProps = defaultProps

export default ActionMenuItem
