import PropType from 'prop-types'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { baseMessageFields } from '../../../prop-types/shapes/message'
import { Email } from '../../../svg/types'

const propTypes = {
  message: PropType.shape(baseMessageFields).isRequired, // sometimes this is template (like in a template list view)
  size: PropType.oneOf(['sm', 'md', 'lg'])
}

const defaultProps = {
  size: 'md'
}

const log = logger({ enabled: false, tags: ['EmailMessageFragment'] })

const EmailMessageFragment = ({ message, size }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (size !== 'lg') {
    return (<div>{message.template?.subject || message.subject || '(email message)'}</div>)
  }

  if (message.template?.name) {
    return (
      <div className='flex items-center px-3 pt-3 pb-3 flex '>
        <Email className='w-6 h-6 mr-2 shrink-0 self-start' />
        <div className='flex flex-col'>
          <div>
            <strong>
              {message.template?.name || 'Email'}
            </strong>
          </div>
          {size === 'lg'
            ? (
              <div>
                {message.template?.subject || message.subject || ''}
              </div>
              )
            : null}
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center px-3 pt-3 pb-3 flex '>
      (email message)
    </div>
  )
}

EmailMessageFragment.displayName = 'EmailMessageFragment'
EmailMessageFragment.propTypes = propTypes
EmailMessageFragment.defaultProps = defaultProps

export default EmailMessageFragment
