import * as React from "react";
import { memo } from "react";
const SvgSlug = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.83 8.67c-.05-.66-.26-1.31-.61-1.87A4.148 4.148 0 0 0 6.5 5.28V1.79a.79.79 0 0 0-.39-.68.795.795 0 0 0-.79 0 .79.79 0 0 0-.39.68v3.16c-.34 0-.68.04-1.01.13L2.49 2.22a.784.784 0 0 0-.66-.43.777.777 0 0 0-.7.35c-.15.23-.17.53-.05.78l1.43 2.86c-.07.06-.16.1-.22.16A3.94 3.94 0 0 0 1 8.85c0 1.09.45 2.13 1.26 2.87.19.16.3.39.31.63v3.57c0 1.87.75 3.67 2.07 5a7.052 7.052 0 0 0 5 2.07h10.92c.31 0 .62-.08.88-.25.27-.16.48-.4.62-.67.14-.28.2-.6.18-.91-.03-.31-.14-.62-.33-.87a14.06 14.06 0 0 0-1.58-1.78l-.03-.03-.07-.07c-.99-1.05-2.47-1.7-3.68-2.21-1.35-.58-2.77-1-4.21-1.25-.29-.05-.61-.08-.94-.12-.73-.08-1.56-.16-1.99-.48-.22-.16-.39-.46-.47-.84-.11-.53-.1-1.15-.09-1.76v-.5c0-.43.01-.86.02-1.29m11.7 11.46H9.65c-1.46 0-2.86-.58-3.89-1.61a5.503 5.503 0 0 1-1.61-3.89v-3.57c0-.68-.31-1.33-.82-1.78a2.342 2.342 0 0 1 .02-3.47c.49-.45 1.15-.67 1.82-.6.6.08 1.14.38 1.53.84.34.39.53.88.57 1.4 0 .4 0 .79-.01 1.19 0 .44-.01.88-.01 1.31v.48c-.01.69-.02 1.4.12 2.1.16.77.54 1.41 1.08 1.81.79.58 1.85.69 2.78.79.29.03.58.06.83.1 1.32.23 2.62.62 3.86 1.15 1.1.47 2.47 1.05 3.22 1.9l.02-.02c.54.51 1.05 1.06 1.49 1.66.03.04.04.1.01.14-.02.04-.06.07-.1.07h.01Z"
    />
  </svg>
);
const Memo = memo(SvgSlug);
export default Memo;
