import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { formatName, formatPhone } from '../../lib/formatters'
import logger from '../../lib/logger'
import { get as getMessageService } from '../../services/message'
import PanelDialog from '../dialog/PanelDialog'
import ThreadItemDate from '../list/thread/ThreadItemDate'
import LoadingIndicator from '../LoadingIndicator'
import PanelHeaderButton from '../panels/panel-header/PanelHeaderButton'
import PanelContent from '../panels/PanelContent'
import PanelHeader from '../panels/PanelHeader'

import MessageThreadItemContent from './MessageThreadItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MessageViewerDialog'] })

const MessageViewerDialog = forwardRef((params, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [message, setMessage] = useState(null)
  const dialogRef = useRef()

  const { call: getMessage } = useService(getMessageService, {
    onReplyOk: (reply) => setMessage(reply.model)
  })

  const handleCloseClick = useCallback(() => {
    dialogRef.current.close()
  }, [dialogRef])

  useImperativeHandle(ref, () => ({
    open (messageId) {
      dialogRef.current.open()
      getMessage(messageId)
    }
  }), [getMessage])

  const title = useMemo(() => {
    return `To: ${message?.contact ? formatName(message.contact) : '(unknown)'}`
  }, [message])

  const subtitle = useMemo(() => {
    if (message?.type === 'email' && message?.contact.email) {
      return message?.contact.email
    } else if (message?.contact.phoneNumber) {
      return formatPhone(message.contact.phoneNumber)
    }
  }, [message])

  return (
    <PanelDialog ref={dialogRef} ariaLabel='View Message'>
      <PanelHeader start={<PanelHeaderButton icon='close' onClick={handleCloseClick} />} subtitle={subtitle} title={title} />
      <PanelContent className='flex flex-col'>
        <div className='min-h-[300px] max-w-[65%] mx-auto flex flex-col justify-center items-center'>
          {message
            ? (
              <>
                <ThreadItemDate date={new Date(message.time)} />
                <MessageThreadItemContent item={message} />
              </>
              )
            : <LoadingIndicator />}
        </div>
      </PanelContent>
    </PanelDialog>
  )
})

MessageViewerDialog.displayName = 'MessageViewerDialog'
MessageViewerDialog.propTypes = propTypes
MessageViewerDialog.defaultProps = defaultProps

export default MessageViewerDialog
