import classNames from 'classnames'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useFormContext } from 'react-hook-form'

import useDefaultId from '../../../hooks/useDefaultId'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

const propTypes = {
  name: PropType.string.isRequired,
  defaultValue: PropType.string,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  hintTop: PropType.bool,
  id: PropType.string,
  label: PropType.string,
  options: PropType.arrayOf(PropType.shape({
    label: PropType.string.isRequired,
    value: PropType.string.isRequired,
    disabled: PropType.bool
  })),
  onChange: PropType.func
}

const defaultProps = {
  defaultValue: null,
  error: null,
  hint: null,
  hintTop: false,
  id: null,
  label: null,
  onChange: null,
  options: []
}

const log = logger({ enabled: true, tags: ['RadioGroup'] })

const RadioGroup = forwardRef(({
  defaultValue,
  error,
  hint,
  hintTop,
  id,
  label,
  name,
  options,
  onChange
}, ref) => {
  id = useDefaultId(id)

  useLogger({ log, lifecycle: false, tags: [] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  useImperativeHandle(ref, () => ({
    // TODO: do we need to expose anything here?
  }), [])

  // NOTE: this pattern doesn't seem friendly for keyboard users...
  //       maybe we should provide an 'Any' option if no value is ok
  const handleToggleRadio = useCallback((event) => {
    return onChange?.(event)
  }, [onChange])

  const inputs = options.map(({ label, value, description, disabled }) => {
    const registerProps = useFormMethods?.register
      ? useFormMethods.register(name, { onChange })
      : { onChange }
    const defaultChecked = defaultValue === value
    const className = classNames(
      'ml-2 label-sm-medium',
      {
        'cursor-not-allowed': disabled,
        'opacity-50': disabled
      }
    )
    return (
      <label key={`${name}-${value}`} className='flex items-center m-2'>
        <input
          defaultChecked={defaultChecked}
          disabled={disabled}
          name={name}
          type='radio'
          value={value}
          onClick={handleToggleRadio}
          {...registerProps}
        />
        <span className={className}>{label}</span>
        {description ? <p className='pl-[28px] label-sm text-neutral-400'>{description}</p> : null}
      </label>
    )
  })

  return (
    <>
      {hintTop ? <InputHint message={hint} /> : null}
      <InputLabel id={id} text={label} />
      {inputs}
      <InputError message={error?.message || error} />
      {!hintTop ? <InputHint message={hint} /> : null}
    </>
  )
})

RadioGroup.displayName = 'RadioGroup'
RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps

export default RadioGroup
