import PropType from 'prop-types'
import { useWizard } from 'react-use-wizard'

import Button from '../../../components/buttons/Button'
import { mfaChannelVerb } from '../../../lib/formatters'
import Header from '../shared/Header'

const propTypes = {
  onMethodSelected: PropType.func.isRequired,
  availableMethods: PropType.arrayOf(PropType.shape({
    _id: PropType.string.isRequired,
    channel: PropType.string.isRequired,
    redactedTo: PropType.string.isRequired
  })),
  backStepIndex: PropType.number
}

const defaultProps = {
  availableMethods: [],
  backStepIndex: 0
}

const Choices = ({ availableMethods, backStepIndex, onMethodSelected }) => {
  const { goToStep } = useWizard()

  const handleSelection = (method) => {
    onMethodSelected(method)
    goToStep(4)
  }

  return (
    <>
      <Header
        goBack={() => goToStep(backStepIndex)}
        heading='Sign In'
        subtitle='Choose a method of verification.'
        title='Multi-Factor Authentication'
      />
      {availableMethods.map((method) => (
        <Button
          key={method._id}
          className='mt-5 w-full'
          size='md'
          onClick={() => handleSelection(method)}
        >
          {`${mfaChannelVerb(method.channel)} ${method.redactedTo}`}
        </Button>
      ))}
    </>
  )
}

Choices.displayName = 'MultiFactorAuthenticationChoices'
Choices.propTypes = propTypes
Choices.defaultProps = defaultProps

export default Choices
