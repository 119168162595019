import { useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import List from '../../components/list/List'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import ScheduledBroadcastListSearchBar from '../../components/scheduled-broadcast/ScheduledBroadcastListSearchBar'
import useBus from '../../hooks/useBus'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as scheduledBroadcastsSearch } from '../../services/scheduledBroadcast'

import ScheduledBroadcastListEmptyContent from './list/ListEmptyContent'
import ScheduledBroadcastListHeaderMoreMenu from './list/ListHeaderMoreMenu'
import ScheduledBroadcastListItemContent from './list/ListItemContent'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastsList'] })

const ScheduledBroadcastsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const location = useLocation()
  const ref = useRef()

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('scheduledBroadcastCreated', handleBusEvent)
  useBus('scheduledBroadcastUpdated', handleBusEvent)
  useBus('scheduledBroadcastDeleted', handleBusEvent)

  return (
    <Panel>
      <PanelHeader
        end={<ScheduledBroadcastListHeaderMoreMenu listRef={ref} />}
        title='Schedule'
      />
      <PanelContent>
        <List
          ref={ref}
          defaultSearch={location.state?.search}
          EmptyListContent={ScheduledBroadcastListEmptyContent}
          ListItemContent={ScheduledBroadcastListItemContent}
          ListSearchBar={ScheduledBroadcastListSearchBar}
          placeholder='Search Schedule'
          savedStateKey='scheduled-broadcasts'
          searchService={scheduledBroadcastsSearch}
          type='nav'
          search
          stripPadding
        />
      </PanelContent>
    </Panel>
  )
}

ScheduledBroadcastsList.displayName = 'ScheduledBroadcasts'
ScheduledBroadcastsList.propTypes = propTypes
ScheduledBroadcastsList.defaultProps = defaultProps

export default ScheduledBroadcastsList
