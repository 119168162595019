import { get as getTemplateService } from '../services/template'

import useCurrentInstance from './useCurrentInstance'

const useCurrentMessageTemplate = () => useCurrentInstance({
  resourceName: 'messageTemplate',
  getService: getTemplateService,
  replyAttrPath: 'json',
  notFoundRedirect: '/message-templates'
})

export default useCurrentMessageTemplate
