import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contestShape from '../../prop-types/shapes/contest'
import { PeopleGroup } from '../../svg/icons'
import { Contest } from '../../svg/types'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(contestShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['ContestListItemContent'] })

const ContestListItemContent = ({ item: contest }) => {
  useLogger({ log, lifecycle: true, tags: [contest?._id] })

  return (
    <>
      <Contest className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {contest.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <PeopleGroup className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={contest?.contacts.length} />
          <div className='line-clamp-1 break-words'>{contest?.contactsSubsetNames}</div>
        </div>
      </div>
    </>
  )
}

ContestListItemContent.displayName = 'ContestListItemContent'
ContestListItemContent.propTypes = propTypes
ContestListItemContent.defaultProps = defaultProps

export default ContestListItemContent
