import { useCallback, useRef, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import TagList from '../../components/tags/TagList'

import TagEmptyListContent from './list/EmptyListContent'
import TagListHeaderMoreMenu from './list/ListHeaderMoreMenu'

function TagsList () {
  const listRef = useRef() // listRef.current.[reload]

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<TagListHeaderMoreMenu listRef={listRef} />}
        title='Tags'
      />
      <PanelContent>
        <TagList
          ref={listRef}
          EmptyListContent={TagEmptyListContent}
          savedStateKey='tags'
          type='nav'
          search
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

export default TagsList
