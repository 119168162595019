import { add } from 'date-fns'

import { formatExtendedDate, formatTimeOfDay } from '../lib/formatters'

export function contactSummary (event) {
  return `${event.contactsScheduled.length} Queued, ${event.contactsSent.length} Processed`
}

export function scheduleSummary (event, timeZone) {
  let description
  let timeOfDay

  switch (event.campaignType) {
    case 'drip':
      description = `${event.scheduledDelayDays} Day Delay`
      timeOfDay = formatTimeOfDay(event.scheduledAt, timeZone)
      break
    case 'event':
    case 'countdown':
      description = `${event.scheduledBeforeDays} Day${event.scheduledBeforeDays === 1 ? '' : 's'} Before`
      timeOfDay = formatTimeOfDay(event.scheduledAt, timeZone)
      break
    case 'delay': {
      description = `${event.scheduledDelayHours} Hour ${event.scheduledDelayMinutes} Minute Delay`
      const delayScheduledAt = add(new Date(), {
        hours: event.scheduledDelayHours,
        minutes: event.scheduledDelayMinutes
      })
      timeOfDay = formatTimeOfDay(delayScheduledAt, timeZone)
      break
    }
    case 'schedule':
    case 'repeat':
      description = formatExtendedDate(event.scheduledAt, timeZone)
      timeOfDay = formatTimeOfDay(event.scheduledAt, timeZone)
      break
    case 'birthday':
    case 'anniversary':
      description = `On Contact's ${event.campaignType === 'birthday' ? 'Birthday' : 'Anniversary'}`
      timeOfDay = formatTimeOfDay(event.scheduledAt, timeZone)
      break
  }

  return `${description}, Sends at ${timeOfDay}`
}
