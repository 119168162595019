import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { useMinBreakpoint } from '../../hooks/useBreakpoint'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { success } from '../banners/Banner'
import DetailSection from '../detail-sections/DetailSection'

import UnlayerDesignPreview from './UnlayerDesignPreview'
import UnlayerEditorDialog from './UnlayerEditorDialog'
import UnlayerTemplateDialog from './UnlayerTemplateDialog'

const propTypes = {
  Editor: PropType.elementType.isRequired,
  record: PropType.object.isRequired, // eslint-disable-line react/forbid-prop-types
  service: PropType.func.isRequired,
  which: PropType.oneOf(['landingPage', 'email', 'unlayerTemplate']).isRequired,
  whichTemplateType: PropType.oneOf(['landingPage', 'email']),
  onChange: PropType.func
}

const defaultProps = {
  onChange: undefined,
  whichTemplateType: undefined
}

const log = logger({ enabled: true, tags: ['UnlayerDesignDetailSection'] })

const UnlayerDesignDetailSection = ({
  Editor,
  record,
  service,
  which,
  whichTemplateType,
  onChange
}) => {
  const templateDialogRef = useRef()
  const editorDialogRef = useRef()

  const [closeEditor, setCloseEditor] = useState(false)

  const roomForEdit = useMinBreakpoint('roomToEditDesign')
  const hasDesign = !!record.unlayerDesign
  const showEditIcon = roomForEdit && hasDesign
  const showInitialEditButton = roomForEdit && !hasDesign
  const noRoomForEditDescription = 'Designs can only be edited on larger screens.'
  const hasDesignDescription = 'This is live preview of the design.'
  const description = [hasDesign ? hasDesignDescription : '', !roomForEdit ? noRoomForEditDescription : ''].join(' ')
  whichTemplateType = whichTemplateType || which === 'unlayerTemplate' ? record.type : which

  const handleUpdateReplyOk = useCallback((reply) => {
    success('Design updated')
    if (closeEditor) { editorDialogRef.current.close() }
    onChange(reply)
  }, [closeEditor, onChange])
  const { call: updateDesign } = useService(service, { onReplyOk: handleUpdateReplyOk })

  const handleEditDesign = useCallback(() => {
    editorDialogRef.current.open(record.unlayerDesign)
  }, [record.unlayerDesign])

  const saveDesign = useCallback((details, close) => {
    setCloseEditor(close)
    updateDesign(record._id, {
      unlayerDesign: details.design,
      unlayerHtmlChunks: details.chunks
    })
  }, [record._id, updateDesign])

  const handleSaveDesign = useCallback((details) => { saveDesign(details, false) }, [saveDesign])
  const handleSaveDesignAndClose = useCallback((details) => { saveDesign(details, true) }, [saveDesign])

  const handleTemplateSelected = useCallback((template) => {
    templateDialogRef.current.close()
    editorDialogRef.current.open(template.unlayerDesign)
  }, [])

  return (
    <>
      <DetailSection
        description={description}
        initialEditButtonText='Start Designing'
        showEditIcon={showEditIcon}
        showInitialEditButton={showInitialEditButton}
        title='Design'
        onEditClick={handleEditDesign}
        onInitialEditClick={() => templateDialogRef.current.open()}
      >
        <UnlayerDesignPreview
          key={record.updatedAt}
          recordId={record._id}
          showPreview={hasDesign}
          which={which}
        />
      </DetailSection>
      <UnlayerTemplateDialog
        ref={templateDialogRef}
        subtitle={record.name}
        which={whichTemplateType}
        onTemplateSelected={handleTemplateSelected}
      />
      <UnlayerEditorDialog
        ref={editorDialogRef}
        ariaLabel='Edit Design'
        Editor={Editor}
        subtitle={record.name}
        title='Edit Design'
        onSave={handleSaveDesign}
        onSaveAndClose={handleSaveDesignAndClose}
      />
    </>
  )
}

UnlayerDesignDetailSection.displayName = 'UnlayerDesignDetailSection'
UnlayerDesignDetailSection.propTypes = propTypes
UnlayerDesignDetailSection.defaultProps = defaultProps

export default UnlayerDesignDetailSection
