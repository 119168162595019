import PropType from 'prop-types'
import { forwardRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { chatMessages } from '../../services/aiva'
import ThreadList from '../list/thread/ThreadList'
import MessageThreadItemContent from '../message/MessageThreadItemContent'

import AivaChatEmptyThreadContent from './AivaChatEmptyThreadContent'

const propTypes = {
  recipientTyping: PropType.bool
}

const defaultProps = {
  recipientTyping: false
}

const log = logger({ enabled: false, tags: ['AivaChatThread'] })

const AivaChatThread = forwardRef(({ recipientTyping, ...rest }, ref) => {
  ref = useDefaultRef(ref)

  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <ThreadList
      ref={ref}
      EmptyThreadContent={AivaChatEmptyThreadContent}
      recipientTyping={recipientTyping}
      service={chatMessages}
      ThreadItemContent={MessageThreadItemContent}
      {...rest}
    />
  )
})

AivaChatThread.displayName = 'AivaChatThread'
AivaChatThread.propTypes = propTypes
AivaChatThread.defaultProps = defaultProps

export default AivaChatThread
