import { upperFirst } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { success } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { bundleContentTypeLabel, bundleContentTypes } from '../../helpers/bundle'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import {
  importInfoByShareId as importInfoByShareIdService,
  importByShareId as importService
} from '../../services/bundle'
import { setCurrentInstance } from '../../store/actions/currentInstance'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ImportBundle'] })

const ImportBundle = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { shareId } = useParams()
  const [bundle, setBundle] = useState(null)
  const navigate = useNavigate()

  const { call: importInfoByShareIdCall, loading } = useService(importInfoByShareIdService, {
    onReplyOk: useCallback((reply) => {
      setBundle(reply.model)
    }, [setBundle]),
    onReplyNotOk: useCallback(() => {
      navigate('/bundles')
    }, [navigate])
  })

  const { call: importCall } = useService(importService, {
    onReplyOk: useCallback((reply) => {
      const newBundle = reply.model
      setCurrentInstance('bundle', newBundle)
      bus.emit('bundleImported', newBundle)
      navigate(`/bundles/${newBundle._id}`)
      success('Bundle Imported!')
    }, [navigate])
  })

  useEffect(() => {
    if (!loading && !bundle) {
      importInfoByShareIdCall({ shareId })
    }
  }, [bundle, importInfoByShareIdCall, loading, shareId])

  const handleImport = useCallback(() => {
    importCall({ shareId: bundle.shareId })
  }, [bundle, importCall])

  const bundleContentsRows = useMemo(() => {
    return bundle && bundleContentTypes.map((contentType) => {
      const contentCount = bundle[contentType].length
      const overwriteContent = !!bundle.shareOptions[`overwrite${upperFirst(contentType)}`]
      return (
        <div key={contentType} className='flex flex-row gap-1 mt-2'>
          <div className='basis-2/6'>{`${bundleContentTypeLabel(contentType)}s`}</div>
          <div className='basis-1/6'>{contentCount}</div>
          <div className='basis-3/6'>
            {overwriteContent
              ? (<span className='p-1 text-white bg-orange-500'>Adds/Overwrites</span>)
              : (<span className='p-1 text-white bg-green-500'>Adds</span>)}
          </div>
        </div>
      )
    })
  }, [bundle])

  if (loading || !bundle) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton title='Import' onClick={handleImport} />}
        start={<PanelHeaderButton href='/bundles' icon='back' />}
        subtitle={bundle.name}
        title='Shared Bundle'
      />
      <PanelContent className='p-5'>
        <div className='mb-4'>
          Please review the information below and tap import if you would like to import this bundle.
        </div>
        <div className='bundle-import-action mb-5'>
          <div className='label-md-strong'>Bundle Name</div>
          <p className='label-sm text-neutral-500'>{bundle.name}</p>
          <div className='mt-3 label-md-strong'>Bundle Usage</div>
          <p className='label-sm text-neutral-500'>{bundle.usage || '(not provided)'}</p>
          <div className='mt-3 label-md-strong'>Bundle Contents</div>
          <div className='mt-1 flex flex-row'>
            <div className='basis-2/6 label-sm-strong text-left bg-neutral-200 p-1'>Items</div>
            <div className='basis-1/6 label-sm-strong text-left bg-neutral-200 p-1'>Count</div>
            <div className='basis-3/6 label-sm-strong text-left bg-neutral-200 p-1'>Import Action</div>
          </div>
          {bundleContentsRows}
        </div>
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='customFieldNamesForm'
            size='sm'
            type='button'
            onClick={handleImport}
          >
            Import
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

ImportBundle.displayName = 'ImportBundle'
ImportBundle.propTypes = propTypes
ImportBundle.defaultProps = defaultProps

export default ImportBundle
