import PropType from 'prop-types'

import ExternalLink from '../../links/ExternalLink'
import PrivacyLink from '../../links/PrivacyLink'
import SupportLink from '../../links/SupportLink'
import TermsLink from '../../links/TermsLink'
import VersionText from '../../VersionText'

import Nav from './Nav'
import PlanDetails from './PlanDetails'
import ReferralProgramBanner from './ReferralProgramBanner'

const propTypes = {
  currentUser: PropType.shape({
    formattedMessageCredits: PropType.string,
    superuser: PropType.bool
  }).isRequired
}

const defaultProps = {}

const Raised = ({ currentUser }) => {
  return (
    <div className='w-full max-h-full overflow-y-auto px-[12px] pb-[12px]'>
      <PlanDetails currentUser={currentUser} />
      <Nav currentUser={currentUser} />
      <ReferralProgramBanner />
      <div className='flex flex-col px-[10px] mt-[10px]'>
        <span className='label-xs'>
          <TermsLink className='link text-neutral-600 mr-2' label='Terms' />
          <PrivacyLink className='link text-neutral-600 mr-2' label='Privacy' />
          <ExternalLink className='link text-neutral-600 mr-2' href='http://training.projectbroadcast.com/' label='Training' />
          <SupportLink className='link text-neutral-600' label='Help' />
        </span>
        <span className='label-xs mt-[12px]'>
          <VersionText />
        </span>
      </div>
    </div>
  )
}

Raised.displayName = 'Raised'
Raised.propTypes = propTypes
Raised.defaultProps = defaultProps

export default Raised
