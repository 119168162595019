import * as React from 'react'
import { memo } from 'react'
const SvgTrackableLink = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <rect width={24} height={24} fill="#E1E7FF" rx={4} />
        <path
            fill="#5046E5"
            d="M12.645 15a3.683 3.683 0 0 0 2.573-1.073l2.991-2.99a3.62 3.62 0 0 0 1.064-2.573 3.635 3.635 0 0 0-6.21-2.573l-1 1c-.281.3-.281.7 0 .964.319.281.691.272.964 0l1-1a2.302 2.302 0 0 1 1.61-.664 2.264 2.264 0 0 1 1.608.664c.437.445.673 1.027.664 1.609 0 .581-.227 1.163-.664 1.609l-3 2.99a2.228 2.228 0 0 1-1.6.664c-.972.018-1.4-.518-1.927-1.045a.686.686 0 0 0-.963 0 .69.69 0 0 0-.073.882c.745.963 1.69 1.527 2.963 1.536Zm-4.281 4.273a3.62 3.62 0 0 0 2.572-1.064l1-1c.282-.3.282-.7 0-.963-.318-.282-.69-.273-.963 0l-1 1a2.303 2.303 0 0 1-1.61.663 2.303 2.303 0 0 1-1.608-.663 2.302 2.302 0 0 1-.664-1.61c0-.581.227-1.163.664-1.609l3-2.99a2.228 2.228 0 0 1 1.6-.664c1.154 0 1.5.618 1.927 1.045.263.264.7.264.963 0 .373-.372.137-.89-.318-1.345A3.667 3.667 0 0 0 11.355 9a3.683 3.683 0 0 0-2.573 1.073l-2.991 2.99a3.62 3.62 0 0 0-1.064 2.573 3.635 3.635 0 0 0 3.637 3.637Z"
        />
    </svg>
)
const Memo = memo(SvgTrackableLink)
export default Memo
