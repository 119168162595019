import { Capacitor } from '@capacitor/core'
import PropType from 'prop-types'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { useMinBreakpoint } from '../hooks/useBreakpoint'
import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { setPreference } from '../lib/preferences'

export const AppContext = createContext()
AppContext.displayName = 'AppContext'

const providerPropTypes = {
  children: PropType.node.isRequired,
  preferences: PropType.shape({
    emojiSkinTone: PropType.oneOf([
      'neutral',
      '1f3fb',
      '1f3fc',
      '1f3fd',
      '1f3fe',
      '1f3ff'
    ]),
    sideMenuLocked: PropType.bool
  }),
  shortLinkApp: PropType.bool
}
const providerDefaultProps = {
  preferences: {
    emojiSkinTone: 'neutral',
    sideMenuLocked: false
  },
  shortLinkApp: false
}

const log = logger({ enabled: false, tags: ['AppProvider'] })

export const AppProvider = ({
  children,
  preferences: initialPreferences,
  shortLinkApp
}) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const isRoomForTwoPanels = useMinBreakpoint('lg')
  const [emojiSkinTone, setEmojiSkinTone] = useState(initialPreferences.emojiSkinTone)

  const saveEmojiSkinTone = useCallback((value) => {
    setEmojiSkinTone(value)
    if (!shortLinkApp) { setPreference('emojiSkinTone', value) }
  }, [shortLinkApp])

  const value = useMemo(() => ({
    emojiSkinTone,
    isAndroid: Capacitor.getPlatform() === 'android' || /android/i.test(navigator.userAgent),
    isIOS: Capacitor.getPlatform() === 'ios' || /ipad|iphone|ipod/i.test(navigator.userAgent),
    isRoomForTwoPanels,
    isNative: Capacitor.getPlatform() !== 'web',
    isWeb: Capacitor.getPlatform() === 'web',
    prefersSideMenuLocked: initialPreferences.sideMenuLocked,
    saveEmojiSkinTone
  }), [emojiSkinTone, initialPreferences, isRoomForTwoPanels, saveEmojiSkinTone])

  return (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
  )
}
AppProvider.displayName = 'AppProvider'
AppProvider.propTypes = providerPropTypes
AppProvider.defaultProps = providerDefaultProps

export function useApp () {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider')
  }
  return context
}
