import * as React from "react";
import { memo } from "react";
const SvgDefault = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 375 180"
    {...props}
  >
    <path fill="#fff" d="M0 0h375v180H0z" />
    <path
      fill="#fff"
      d="M255.286 0H114.714C108.245 0 103 5.245 103 11.714v140.572c0 6.469 5.245 11.714 11.714 11.714h140.572c6.469 0 11.714-5.245 11.714-11.714V11.714C267 5.244 261.755 0 255.286 0Z"
    />
    <path
      stroke="#E6E7EB"
      d="M114.714.5h140.572c6.193 0 11.214 5.02 11.214 11.214v140.572c0 6.193-5.021 11.214-11.214 11.214H114.714c-6.193 0-11.214-5.021-11.214-11.214V11.714C103.5 5.521 108.521.5 114.714.5Z"
    />
    <path
      fill="#ECFEFF"
      d="M245 12H125c-5.523 0-10 4.477-10 10v120c0 5.523 4.477 10 10 10h120c5.523 0 10-4.477 10-10V22c0-5.523-4.477-10-10-10Z"
    />
    <rect
      width={178}
      height={40}
      x={122}
      y={80.5}
      fill="#fff"
      rx={4}
      shapeRendering="crispEdges"
    />
    <rect width={28} height={28} x={130} y={86.5} fill="#CFFBFE" rx={4} />
    <path
      fill="#CFFBFE"
      d="M255.397 91.471h-87.859c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.537-1.573 3.537-3.514 0-1.941-1.583-3.515-3.537-3.515ZM219.428 102.5h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h51.89c1.954 0 3.538-1.574 3.538-3.515s-1.584-3.514-3.538-3.514Z"
    />
    <rect
      width={177}
      height={39}
      x={122.5}
      y={81}
      stroke="#E6E7EB"
      rx={3.5}
      shapeRendering="crispEdges"
    />
    <rect
      width={178}
      height={40}
      x={74.5}
      y={29}
      fill="#fff"
      rx={4}
      shapeRendering="crispEdges"
    />
    <rect width={28} height={28} x={82.5} y={35} fill="#CFFBFE" rx={4} />
    <path
      fill="#CFFBFE"
      d="M207.897 39.971h-87.859c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.537-1.573 3.537-3.514 0-1.941-1.583-3.515-3.537-3.515ZM171.928 51h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.515 3.538 3.515h51.89c1.954 0 3.538-1.574 3.538-3.515 0-1.94-1.584-3.514-3.538-3.514Z"
    />
    <rect
      width={177}
      height={39}
      x={75}
      y={29.5}
      stroke="#E6E7EB"
      rx={3.5}
      shapeRendering="crispEdges"
    />
  </svg>
);
const Memo = memo(SvgDefault);
export default Memo;
