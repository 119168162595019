import useStore from '../'
import { externalNavigate } from '../../components/ExternalNavigate'
import logger from '../../lib/logger'
import stackKeyFromLocation from '../../lib/stackKeyFromLocation'

const log = logger({ enabled: true, tags: ['Stacks'] })

export function popStackEntry () {
  useStore.setState((state) => {
    const location = state.stackBackButtonLocation
    const stackKey = stackKeyFromLocation(location)
    const stack = state.stacks[stackKey] || []
    stack.pop() // currentLocation
  }, false, {
    type: 'stacks/popStackEntry',
    location
  })
}

export function addStackEntryFromLocation (location) {
  useStore.setState((state) => {
    const stackKey = stackKeyFromLocation(location)
    const parts = location.pathname.slice(1).split('/')
    state.stacks[stackKey] = state.stacks[stackKey] || []
    const stack = state.stacks[stackKey]

    // When the route is /:stackKey we want to have a clear stack
    if (parts.length === 1) {
      state.stacks[stackKey] = []
      return
    }

    // When the route is 2 parts we're assuming it is a details page and that should be the start of our stack history
    // /:stackKey/:itemId
    if (parts.length === 2) {
      state.stacks[stackKey] = [location]
      return
    }

    // When the route is 3 parts and inclusive of a particular value in the 2nd part
    // that is also the start of a stack history
    // /:stackKey/folder/:folderId
    const resettingSubResources = ['folder']
    if (parts.length === 3 && resettingSubResources.includes(parts[1])) {
      state.stacks[stackKey] = [location]
      return
    }

    // If we have a stack, only add the new location if it is different from the previous location
    if (stack.length) {
      const prevLocation = stack.at(-1)
      if (location.pathname === prevLocation.pathname) {
        return
      }
    }

    state.stacks[stackKey].push(location)
  }, false, {
    type: 'stacks/addStackEntryFromLocation',
    location
  })
}

export function backInStack (location, fallback) {
  useStore.setState((state) => {
    const stackKey = stackKeyFromLocation(location)
    const parts = location.pathname.split('/')
    const stack = state.stacks[stackKey] || []
    parts.pop() // lastPath
    stack.pop() // currentLocation
    if (!stack.length) {
      externalNavigate(fallback || parts.join('/'))
      return
    }
    externalNavigate(stack.pop())
  }, false, {
    type: 'stacks/backInStack',
    location
  })
}

export function setStackBackButtonLocation (location) {
  useStore.setState((state) => {
    state.stackBackButtonLocation = location
  }, false, {
    type: 'stacks/setStackBackButtonLocation',
    location
  })
}

export function setBackButtonHandlerOverride (handler) {
  useStore.setState((state) => {
    state.backButtonHandlerOverride = handler
  }, false, {
    type: 'stacks/setBackButtonHandlerOverride',
    location
  })
}

export function handleAndroidBackButton () {
  const { backButtonHandlerOverride, stackBackButtonLocation } = useStore.getState()
  if (backButtonHandlerOverride) {
    backButtonHandlerOverride()
    return true
  } else if (stackBackButtonLocation) {
    backInStack(stackBackButtonLocation)
    return true
  }
}
