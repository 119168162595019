import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { logoutAll as logoutAllService } from '../../services/user'
import { logout as logoutAction } from '../../store/actions/currentUser'

const SignOutAll = () => {
  const navigate = useNavigate()

  const { call: logoutAll } = useServiceAndAction(logoutAllService, logoutAction, {
    onReplyOk: useCallback(() => {
      navigate('/', { replace: true })
    }, [navigate])
  })
  const handleOnClick = () => {
    logoutAll()
  }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title='Sign Out From All Devices'
      />
      <PanelContent>
        <div className='p-5 flex flex-col gap-3 text-center md:items-center'>
          <div>Using this feature will force all previous devices to re-login to access this Project Broadcast account. It is useful if you need to reset your password and want to invalidate previous devices that may still be logged in.</div>
          <div className='flex flex-row'>
            <Button
              className='flex-grow md:flex-none'
              form='profileForm'
              size='sm'
              type='button'
              onClick={handleOnClick}
            >
              Sign Out From All Devices
            </Button>
          </div>
        </div>
      </PanelContent>
    </Panel>
  )
}

SignOutAll.displayName = 'SignOutAll'
export default SignOutAll
