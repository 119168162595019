import PropType from 'prop-types'
import { Link } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  integration: PropType.shape({
    name: PropType.string.isRequired,
    newUserAccountBalanceCreditCents: PropType.number.isRequired
  }).isRequired,
  signInUrl: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['PartnerPromo'] })

const PartnerPromo = ({ integration, signInUrl }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <>
      <p className='my-5'>
        You are signing up and connecting your account to
        {' '}
        <strong>{integration.name}</strong>
        {'. '}
      </p>
      <p className='my-5'>
        Already have an account with Project Broadcast?
        {' '}
        <Link className='link' to={signInUrl}>
          Sign in
        </Link>
        {' '}
        to connect.
      </p>
    </>
  )
}

PartnerPromo.displayName = 'PartnerPromo'
PartnerPromo.propTypes = propTypes
PartnerPromo.defaultProps = defaultProps

export default PartnerPromo
