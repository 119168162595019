import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create as createQrCodeService } from '../../../services/qrCode'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import { success } from '../../banners/Banner'
import FormPanelDialog from '../../dialog/FormPanelDialog'

const propTypes = {
  createdFromFolder: PropType.bool,
  onSuccess: PropType.func
}

const defaultProps = {
  createdFromFolder: false,
  onSuccess: undefined
}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef(({ createdFromFolder, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const dialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    dialogRef.current.close()
    const qrCode = reply.json
    setCurrentInstance('qrCode', qrCode)
    success(`${qrCode.name} created`)
    bus.emit('qrCodeCreated', qrCode)
    if (!createdFromFolder) { navigate(`/qr-codes/${qrCode._id}`) }
    onSuccess?.(qrCode)
  }, [createdFromFolder, navigate, onSuccess])

  const { call: createQrCode } = useService(createQrCodeService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { dialogRef.current.open() }
  }), [])

  const handleCreate = useCallback((createData) => {
    createQrCode(createData)
  }, [createQrCode])

  return (
    <FormPanelDialog
      ref={dialogRef}
      defaultValues={{ name: '', url: '' }}
      description='Please name your new QR code.'
      formControls={[
        {
          autoComplete: 'off',
          label: 'Name',
          name: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'URL',
          name: 'url',
          type: 'url',
          required: true
        }
      ]}
      title='New QR Code'
      onSubmit={handleCreate}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
