import pb from './pb'

const background = true

export function addToCart (body) {
  return pb.setupPost('tr/addToCart', { body, background })
}

export function initiateCheckout (body) {
  return pb.setupPost('tr/initiateCheckout', { body, background })
}

export function lead (body) {
  return pb.setupPost('tr/lead', { body, background })
}

export function search (body) {
  return pb.setupPost('tr/search', { body, background })
}

export function view (body) {
  return pb.setupPost('tr/view', { body, background })
}
