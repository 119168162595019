import { map, upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import { formatName } from '../../lib/formatters'
import logger from '../../lib/logger'
import { applyTagToContacts } from '../../services/tag'
import ActionMenu from '../action-menu/ActionMenu'
import ActionMenuItem from '../action-menu/ActionMenuItem'
import { success } from '../banners/Banner'
import AddContactsDialog from '../campaigns/AddContactsDialog'
import ConfirmDialog from '../dialog/ConfirmDialog'
import ListDialog from '../dialog/ListDialog'
import TagCreateAction from '../tags/actions/CreateAction'
import TagList from '../tags/TagList'
import TagListEmptySearchContent from '../tags/TagListEmptySearchResultsContent'

import DetailSection from './DetailSection'
import DetailSectionMoreButton from './DetailSectionMoreButton'

const propTypes = {
  addAllInQueryService: PropType.func.isRequired,
  addSelectedService: PropType.func.isRequired,
  object: PropType.shape({
    _id: PropType.string.isRequired,
    contacts: PropType.shape({
      length: PropType.number,
      subset: PropType.arrayOf(PropType.shape({
        _id: PropType.string,
        firstName: PropType.string,
        lastName: PropType.string
      }))
    }).isRequired,
    name: PropType.string.isRequired
  }).isRequired,
  objectName: PropType.string.isRequired,
  addContactsSubtitle: PropType.string,
  nomenclature: PropType.string,
  tagAllService: PropType.func,
  onChange: PropType.func
}

const defaultProps = {
  addContactsSubtitle: undefined,
  nomenclature: 'Contacts',
  onChange: undefined,
  tagAllService: undefined
}

const log = logger({ enabled: true, tags: ['ContactsWithAddDetailSection'] })

const ContactsWithAddDetailSection = ({
  object,
  objectName,
  nomenclature,
  addContactsSubtitle,
  addAllInQueryService,
  addSelectedService,
  tagAllService,
  onChange,
  ...rest
}) => {
  useLogger({ log, lifecycle: false, tags: [objectName, object?._id] })

  const [selectedTag, setSelectedTag] = useState(null)
  const addContactsDialogRef = useRef()
  const applyTagDialogRef = useRef()
  const confirmApplyTagDialogRef = useRef()
  const tagCreateActionRef = useRef()

  const handleAddReplyOk = useCallback((reply) => {
    success('Contacts added')
    onChange?.(reply)
  }, [onChange])
  const { call: addSelectedCall } = useService(addSelectedService, { onReplyOk: handleAddReplyOk })
  const { call: addAllInQueryCall } = useService(addAllInQueryService, { onReplyOk: handleAddReplyOk })

  const handleApplyTagToContactsReplyOk = useCallback(() => { success('Tag applied') }, [])
  const { call: applyTagToContactsCall } = useService(tagAllService || applyTagToContacts, { onReplyOk: handleApplyTagToContactsReplyOk })

  const handleAddFirst = useCallback(() => { addContactsDialogRef.current.open() }, [])

  const handleAddSelected = useCallback((selected, data = {}) => {
    addSelectedCall({ ...data, [`${objectName}Id`]: object._id, contactIds: map(selected, '_id') })
  }, [addSelectedCall, object._id, objectName])

  const handleAddAllInQuery = useCallback((query, data = {}) => {
    addAllInQueryCall({ ...data, [`${objectName}Id`]: object._id, ...query })
  }, [addAllInQueryCall, object._id, objectName])

  const handleApplyExistingTag = useCallback(() => { applyTagDialogRef.current.open() }, [])
  const handleApplyNewTag = useCallback(() => { tagCreateActionRef.current.activate() }, [])

  const handleSetSelectedTag = useCallback((tag) => {
    setSelectedTag(tag)
    confirmApplyTagDialogRef.current.open()
  }, [])

  const handleApplySelectedTag = useCallback(() => {
    applyTagToContactsCall({ tagName: selectedTag?.name, [`${objectName}Id`]: object._id })
  }, [applyTagToContactsCall, selectedTag?.name, objectName, object._id])

  const addMissingTag = useCallback((newTagName) => {
    handleSetSelectedTag({ name: newTagName })
  }, [handleSetSelectedTag])

  const count = object?.contacts.length
  return (
    <>
      <DetailSection
        count={count}
        editHref='contacts'
        editIcon='chevron'
        initialEditButtonText={`Add ${nomenclature}`}
        showEditIcon={count > 0}
        showInitialEditButton={count === 0}
        title={nomenclature}
        onInitialEditClick={handleAddFirst}
        {...rest}
      >
        {object?.contacts.subset.map(formatName).join(', ')}
        <div className='grid place-content-center'>
          <ActionMenu placement='bottom-start' trigger={<DetailSectionMoreButton ariaSubject={`${upperFirst(objectName)} Contact`} />}>
            <ActionMenuItem label='Apply Existing Tag' onClick={handleApplyExistingTag} />
            <ActionMenuItem label='Apply New Tag' onClick={handleApplyNewTag} />
          </ActionMenu>
          <TagCreateAction ref={tagCreateActionRef} mode='tag-name-only' onSuccess={handleSetSelectedTag} />
        </div>
      </DetailSection>
      <AddContactsDialog
        ref={addContactsDialogRef}
        campaign={objectName === 'campaign' ? object : null}
        subtitle={addContactsSubtitle || object?.name}
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
      <ListDialog
        ref={applyTagDialogRef}
        additionalContext={{ addMissingTag, contactCount: object?.contacts.length }}
        EmptySearchResultsListContent={TagListEmptySearchContent}
        list={TagList}
        multiple={false}
        subtitle={object?.name}
        title='Apply Tag'
        type='add'
        search
        onSubmitSelected={handleSetSelectedTag}
      />
      <ConfirmDialog
        ref={confirmApplyTagDialogRef}
        description={`Are you sure you want to apply the tag ${selectedTag?.name} to ${object?.contacts.length} contact(s)?`}
        title='Apply Tag?'
        onConfirm={handleApplySelectedTag}
      />
    </>
  )
}

ContactsWithAddDetailSection.displayName = 'ContactsWithAddDetailSection'
ContactsWithAddDetailSection.propTypes = propTypes
ContactsWithAddDetailSection.defaultProps = defaultProps

export default ContactsWithAddDetailSection
