import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import unlayerTemplate from '../../../prop-types/shapes/unlayerTemplate'
import { cloneAsOppositeType } from '../../../services/admin/unlayer-template'
import { success } from '../../banners/Banner'

const propTypes = {
  unlayerTemplate: PropType.shape(unlayerTemplate).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['CloneAsOppositeTypeAction'] })

const CloneAsOppositeTypeAction = forwardRef(({ unlayerTemplate, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const handleReplyOk = useCallback(() => {
    success(`${unlayerTemplate.name} was cloned`)
    bus.emit('unlayerTemplateCloned', unlayerTemplate)
    onSuccess?.()
  }, [onSuccess, unlayerTemplate])
  const { call } = useService(cloneAsOppositeType, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { call(unlayerTemplate._id) }
  }), [call, unlayerTemplate])
})

CloneAsOppositeTypeAction.displayName = 'CloneAsOppositeTypeAction'
CloneAsOppositeTypeAction.propTypes = propTypes
CloneAsOppositeTypeAction.defaultProps = defaultProps

export default CloneAsOppositeTypeAction
