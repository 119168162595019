import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import unlayerTemplate from '../../../prop-types/shapes/unlayerTemplate'
import { del } from '../../../services/admin/unlayer-template'
import Banner, { success } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  unlayerTemplate: PropType.shape(unlayerTemplate).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['DeleteAction'] })

const DeleteAction = forwardRef(({ unlayerTemplate, onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const confirmRef = useRef()

  const handleReplyOk = useCallback(() => {
    success(`${unlayerTemplate.name} was deleted`)
    bus.emit('unlayerTemplateDeleted', unlayerTemplate)
    onSuccess?.()
  }, [onSuccess, unlayerTemplate])
  const { call } = useService(del, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    call(unlayerTemplate._id, false)
  }, [call, unlayerTemplate])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='Delete'
      confirmValue={unlayerTemplate.name}
      title={`Delete ${unlayerTemplate.name}?`}
      onConfirm={handleConfirm}
    >
      {unlayerTemplate.enabled ? <Banner className='mb-4' title='This template is currently enabled' type='warning' /> : null}
      <p>Are you sure you want to delete this Unlayer Template? This can not be undone.</p>
    </DangerousConfirmDialog>
  )
})

DeleteAction.displayName = 'DeleteAction'
DeleteAction.propTypes = propTypes
DeleteAction.defaultProps = defaultProps

export default DeleteAction
