import { useCallback, useEffect, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { settings } from '../../services/multi-factor-authentication'
import { isManager } from '../../services/user-managedAccount'

import DisableDetail from './mfa/detail/DisableDetail'
import DisableDetailDisabled from './mfa/detail/DisableDetailDisabled'
import MfaDetail from './mfa/detail/MfaDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['MultiFactorAuthentication'] })

const MultiFactorAuthentication = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [canDisableMFA, setCanDisableMFA] = useState(true)
  const [mfaDisabled, setMFADisabled] = useState(false)

  const handleIsManagerReplyOk = useCallback((reply) => {
    setCanDisableMFA(reply?.json?.isManager === false)
  }, [])
  const { call: isManagerCall, loadingIsManager } = useService(isManager, { onReplyOk: handleIsManagerReplyOk })

  const handleMFASettingsReplyOk = useCallback((reply) => {
    setMFADisabled(reply.json.disabled)
  }, [])
  const { call: mfaSettingsCall, loadingMFASettings } = useService(settings, { onReplyOk: handleMFASettingsReplyOk })

  useEffect(() => { isManagerCall() }, [isManagerCall])
  useEffect(() => { mfaSettingsCall() }, [mfaSettingsCall])

  const handleDisabledUpdate = useCallback((updateReply) => {
    setMFADisabled(updateReply?.json?.disabled)
  }, [])

  const title = 'Multi-Factor Authentication'

  if (loadingIsManager || loadingMFASettings) { return <PanelLoading title={title} /> }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title={title}
      />
      <PanelContent className='p-5'>
        <MfaDetail />
        {canDisableMFA
          ? <DisableDetail mfaDisabled={mfaDisabled} onChange={handleDisabledUpdate} />
          : <DisableDetailDisabled />}
      </PanelContent>
    </Panel>
  )
}

MultiFactorAuthentication.displayName = 'MultiFactorAuthentication'
MultiFactorAuthentication.propTypes = propTypes
MultiFactorAuthentication.defaultProps = defaultProps

export default MultiFactorAuthentication
