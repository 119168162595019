import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { currentProgram } from '../../../services/referral'

import ReferralPromoTextFragment from './ReferralPromoTextFragment'

const propTypes = {
  referrerName: PropType.string.isRequired,
  plan: PropType.shape({
    frequency: PropType.string.isRequired
  })
}

const defaultProps = {
  plan: undefined
}

const log = logger({ enabled: false, tags: ['AffiliatePromo'] })

const AffiliatePromo = ({ referrerName, plan }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [program, setProgram] = useState(null)
  const handleCurrentProgramReplyOk = useCallback((reply) => {
    setProgram(reply.json)
  }, [])
  const { call: loadCurrentProgram } = useService(currentProgram, { onReplyOk: handleCurrentProgramReplyOk })

  useEffect(() => {
    loadCurrentProgram()
  }, [loadCurrentProgram])

  if (!program) {
    return null
  }

  const frequency = plan?.frequency
  const percentOffMonth = program?.percentOff
  const percentOffYear = program?.percentOffYear

  const props = {
    frequency,
    percentOffMonth,
    percentOffYear,
    referrerName
  }

  return <ReferralPromoTextFragment {...props} />
}

AffiliatePromo.displayName = 'AffiliatePromo'
AffiliatePromo.propTypes = propTypes
AffiliatePromo.defaultProps = defaultProps

export default AffiliatePromo
