import { FloatingFocusManager, FloatingNode, FloatingPortal, useClick, useDismiss, useFloating, useFloatingNodeId, useFloatingParentNodeId, useFloatingTree, useInteractions, useRole } from '@floating-ui/react'
import classNames from 'classnames'
import PropType from 'prop-types'
import { cloneElement, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { setBackButtonHandlerOverride } from '../../store/actions/stacks'

import Overlay from './Overlay'

export const propTypes = {
  children: PropType.node.isRequired,
  ariaDescribedBy: PropType.string,
  ariaDescription: PropType.string,
  ariaLabel: PropType.string,
  ariaLabelledBy: PropType.string,
  ariaRole: PropType.oneOf(['alertdialog', 'dialog']),
  dismissable: PropType.bool,
  // `forceOverlay` should really only be used in conjunction with a `keepInBackground` parent dialog
  forceOverlay: PropType.bool,
  // `fullscreen` is sometimes true (see PanelDialog) because we're on a smallscreen
  fullscreen: PropType.bool,
  // parent dialogs that use `keepInBackground` should have immediate child dialogs that use `forceOverlay`
  keepInBackground: PropType.bool,
  open: PropType.bool,
  trigger: PropType.element,
  onClose: PropType.func,
  onOpen: PropType.func
}

export const defaultProps = {
  ariaDescribedBy: null,
  ariaDescription: null,
  ariaLabel: null,
  ariaLabelledBy: null,
  ariaRole: 'dialog',
  dismissable: true,
  forceOverlay: false,
  fullscreen: false,
  keepInBackground: false,
  onClose: null,
  onOpen: null,
  open: false,
  trigger: null
}

const log = logger({ enabled: true, tags: ['BaseDialogNode'] })

const BaseDialogNode = forwardRef(({
  ariaLabel,
  ariaLabelledBy,
  ariaDescription,
  ariaDescribedBy,
  ariaRole,
  children,
  dismissable,
  forceOverlay,
  fullscreen,
  keepInBackground,
  open: initialOpen,
  trigger,
  onClose,
  onOpen
}, ref) => {
  ref = useDefaultRef(ref)

  const [open, setOpen] = useState(initialOpen)
  const [foreground, setForeground] = useState(false)

  const nodeId = useFloatingNodeId()
  const parentNodeId = useFloatingParentNodeId()
  const floatingTree = useFloatingTree()

  useLogger({ log, lifecycle: false, tags: [parentNodeId, nodeId, (open ? 'open' : 'closed'), (foreground ? 'foreground' : 'background')] })

  const { reference, floating, context } = useFloating({
    nodeId,
    open,
    onOpenChange (newOpenValue) {
      changeOpen(newOpenValue)
    }
  })

  const handleBackButtonOverride = useCallback(() => {
    if (!dismissable) { return }
    ref.current.close()
  }, [dismissable, ref])

  const changeForeground = useCallback((newForegroundValue) => {
    setForeground(newForegroundValue)
    if (newForegroundValue) {
      log.debug('Moved to the foreground')
      setBackButtonHandlerOverride(handleBackButtonOverride)
    } else {
      log.debug('Moved to the background')
      setBackButtonHandlerOverride(null)
    }
  }, [handleBackButtonOverride])

  const changeOpen = useCallback((newOpenValue) => {
    log.debug(`changing open from ${open} to ${newOpenValue}`)
    setOpen(newOpenValue)
    if (!newOpenValue) {
      onClose?.()
    } else {
      onOpen?.()
    }
    floatingTree.events.emit('openChanged', { foreground, open: newOpenValue, nodeId, parentNodeId })
  }, [floatingTree.events, foreground, nodeId, onClose, onOpen, open, parentNodeId])
  const closeDialog = useCallback(() => { changeOpen(false) }, [changeOpen])
  const openDialog = useCallback(() => { changeOpen(true) }, [changeOpen])

  const handleCloseTree = useCallback(() => closeDialog(), [closeDialog])
  const handleOpenChanged = useCallback((event) => {
    if (nodeId === event.nodeId) { // current dialog node
      changeForeground(event.open)
    }
    if (nodeId === event.parentNodeId) { // parent dialog node
      changeForeground(!event.open)
    }
  }, [changeForeground, nodeId])

  useEffect(() => {
    floatingTree.events.on('closeTree', handleCloseTree)
    floatingTree.events.on('openChanged', handleOpenChanged)
    return () => {
      floatingTree.events.off('closeTree', handleCloseTree)
      floatingTree.events.off('openChanged', handleOpenChanged)
    }
  }, [floatingTree.events, handleCloseTree, handleOpenChanged])

  const useClickInteraction = useClick(context)
  const useDismissInteraction = useDismiss(context, { enabled: dismissable, bubbles: false, outsidePressEvent: 'click' })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    trigger ? useClickInteraction : undefined,
    useRole(context, {
      role: ariaRole
    }),
    dismissable ? useDismissInteraction : null
  ])

  // Public facing API via ref.current
  useImperativeHandle(ref, () => ({
    close () {
      closeDialog()
    },
    closeTree () {
      floatingTree.events.emit('closeTree')
    },
    open () {
      // using setTimeout because we use `outsidePressEvent: 'click'`
      // opening by using the ref.current.open() from a 'click'
      // would result in immediately closing the dialog
      setTimeout(() => openDialog(), 1)
    }
  }), [closeDialog, floatingTree.events, openDialog])

  const className = classNames(
    'w-full h-full px-1 grid grid-cols-1 place-items-center place-content-center',
    {
      hidden: keepInBackground ? false : !foreground,
      'drop-shadow': fullscreen ? false : foreground && !!parentNodeId
    }
  )

  const floatingProps = getFloatingProps({
    id: nodeId,
    ref: floating,
    className,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-description': ariaDescription,
    'aria-describedby': ariaDescribedBy
  })

  const hasBackground = fullscreen ? false : forceOverlay === true || !parentNodeId

  return (
    <FloatingNode id={nodeId}>
      {trigger ? cloneElement(trigger, getReferenceProps({ ref: reference, ...trigger.props })) : null}
      <FloatingPortal id='base-dialog-portal'>
        {open
          ? (
            <Overlay hasBackground={hasBackground}>
              <FloatingFocusManager context={context} modal>
                <div {...floatingProps}>{children}</div>
              </FloatingFocusManager>
            </Overlay>
            )
          : null}
      </FloatingPortal>
    </FloatingNode>
  )
})

BaseDialogNode.displayName = 'BaseDialogNode'
BaseDialogNode.propTypes = propTypes
BaseDialogNode.defaultProps = defaultProps

export default BaseDialogNode
