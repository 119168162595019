import proxy from './proxy'

export default function campaignEventFromJSON (json) {
  if (!json) { return null }

  const overrides = {
    get campaignId () {
      return json.campaign._id || json.campaign
    }
  }

  return proxy(json, overrides)
}
