import AivaLearnMoreButton from '../../components/aiva/AivaLearnMoreButton'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { AivaHello, CustomerSupport, EmpowersUsers, GrowingYourBusiness } from '../../svg/misc/aiva-panels'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['WhatIsAivaScreen'] })

const WhatIsAivaScreen = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const boxContainerClassName = `flex flex-col mb-4 p-5 w-[235px] items-center text-center
                                 border border-neutral-200 rounded-lg drop-shadow bg-white`

  return (
    <Panel hideAivaChatButton>
      <PanelHeader subtitle='Artificial intelligence Virtual Assistant' title='What is AIVA?' />
      <PanelContent className='flex flex-col gap-y-6 py-8 px-4 items-center bg-neutral-50'>
        <div className='text-center max-w-[600px]'>
          <AivaHello className='w-32 h-32 inline' />
          <h1 className='label-lg-strong text-black'>
            Introducing a new AI virtual assistant named AIVA
          </h1>
          <p className='label-lg text-neutral-600'>
            AIVA's goal is to enhance the Project Broadcast experience and to offer insightful, considerate, and clever assistance
          </p>
        </div>
        <div className='flex flex-row flex-wrap justify-evenly gap-x-5'>
          <div className={boxContainerClassName}>
            <EmpowersUsers className='w-[120px] h-[100px]' />
            <h2 className='label-sm-strong'>
              Empowers Users
            </h2>
            <p className='label-xs text-neutral-600'>
              Navigate Project Broadcast comprehensively. AIVA acts as a reliable companion throughout users' journey.
            </p>
          </div>
          <div className={boxContainerClassName}>
            <CustomerSupport className='w-[120px] h-[100px]' />
            <h2 className='label-sm-strong'>
              Customer Support
            </h2>
            <p className='label-xs text-neutral-600'>
              Provides help, guidance, and tailored assistance for all users covering both basic and advanced platform features..
            </p>
          </div>
          <div className={boxContainerClassName}>
            <GrowingYourBusiness className='w-[120px] h-[100px]' />
            <h2 className='label-sm-strong'>
              Growing Your Business
            </h2>
            <p className='label-xs text-neutral-600'>
              AIVA offers insightful, considerate, and clever assistance to help you succeed and grow.
            </p>
          </div>
        </div>
        <AivaLearnMoreButton />
      </PanelContent>
    </Panel>
  )
}

WhatIsAivaScreen.displayName = 'WhatIsAivaScreen'
WhatIsAivaScreen.propTypes = propTypes
WhatIsAivaScreen.defaultProps = defaultProps

export default WhatIsAivaScreen
