import { pick } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import { repeatFrequencyLabel, repeatFrequencyOptions } from '../../../helpers/campaign'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import campaignEvent from '../../../prop-types/shapes/campaignEvent'

const propTypes = {
  campaignEvent: PropType.shape(campaignEvent).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['EventRepeatDetail'] })

const EventRepeatDetail = ({ campaignEvent, service, onChange }) => {
  useLogger({ log, lifecycle: false, tags: [campaignEvent.name] })

  const { defaultValues, formControls, value } = useMemo(() => ({
    defaultValues: pick(campaignEvent, 'scheduledRepeat'),
    formControls: [
      {
        label: 'Repeat',
        name: 'scheduledRepeat',
        type: 'select',
        options: repeatFrequencyOptions
      }
    ],
    value: repeatFrequencyLabel(campaignEvent.scheduledRepeat)
  }), [campaignEvent])

  return (
    <FormDetailSection
      defaultValues={defaultValues}
      description='Specify how often to resend this message.'
      formControls={formControls}
      formDescription='Updating message repeat frequency'
      formTitle='Message Repeat'
      objectId={campaignEvent._id}
      service={service}
      title='Repeat'
      showEditIcon
      onChange={onChange}
    >
      {value}
    </FormDetailSection>
  )
}

EventRepeatDetail.displayName = 'EventRepeatDetail'
EventRepeatDetail.propTypes = propTypes
EventRepeatDetail.defaultProps = defaultProps

export default EventRepeatDetail
