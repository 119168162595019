import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { campaignTypeOptionsForSearch } from '../../helpers/campaign'
import { generateAdvancedSearchFromSearchData, generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/campaignListSearchBar'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import ActionMenu from '../action-menu/ActionMenu'
import ActionMenuItem from '../action-menu/ActionMenuItem'
import AdvancedSearchDialog from '../search-bar/AdvancedSearchDialog'
import SearchBar from '../search-bar/SearchBar'
import SearchBarButton from '../search-bar/SearchBarButton'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  // NOTE: search only works for data that does not get transformed
  search: PropType.object, // eslint-disable-line react/forbid-prop-types
  showFolderToggle: PropType.bool
}

const defaultProps = {
  placeholder: undefined,
  search: null,
  showFolderToggle: false
}

const log = logger({ enabled: true, tags: ['CampaignListSearchBar'] })

const CampaignListSearchBar = ({
  search: initialSearch,
  placeholder,
  showFolderToggle,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()

  const [advancedSearchData, setAdvancedSearchData] = useState(generateAdvancedSearchFromSearchData(initialSearch))

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])
  const handleSearchBarReset = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData)
  }, [advancedSearchData])

  const formControls = [
    { label: 'Term', name: 'term' },
    {
      type: 'radiogroup',
      name: 'type',
      label: 'Campaign Type',
      options: [...campaignTypeOptionsForSearch]
    }
  ]

  const handleTypeFilterClick = useCallback((type) => {
    const newAdvancedSearchData = { ...advancedSearchData, type }
    setAdvancedSearchData(newAdvancedSearchData)
    const search = generateSearchFromAdvancedSearchFormData(newAdvancedSearchData)
    searchBarRef.current.update(search)
  }, [advancedSearchData])

  const actionMenuItems = campaignTypeOptionsForSearch.map(({ label, value }) => {
    return <ActionMenuItem key={value} label={label} onClick={() => handleTypeFilterClick(value)} />
  })
  const end = (
    <ActionMenu placement='bottom-start' trigger={<SearchBarButton icon='filter' />}>
      {actionMenuItems}
    </ActionMenu>
  )

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        end={end}
        generateSummary={generateSummary}
        placeholder={placeholder}
        search={advancedSearchData}
        showFolderToggle={showFolderToggle}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onReset={handleSearchBarReset}
        onTermChange={handleSearchBarTermChange}
      />
      <AdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        formControls={formControls}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>
  )
}

CampaignListSearchBar.displayName = 'CampaignListSearchBar'
CampaignListSearchBar.propTypes = propTypes
CampaignListSearchBar.defaultProps = defaultProps

export default CampaignListSearchBar
