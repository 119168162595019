import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'

import FormDetailSection from '../../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import milestoneShape from '../../../../prop-types/shapes/goalMilestone'

import validationSuite from './MilestoneMessageDetail.validations'

const propTypes = {
  description: PropType.string.isRequired,
  messageType: PropType.oneOf(['intro', 'nudge', 'reward']).isRequired,
  milestone: PropType.shape(milestoneShape).isRequired,
  service: PropType.func.isRequired,
  showEditIcon: PropType.bool.isRequired,
  title: PropType.string.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['MilestoneMessageDetailSection'] })

const MilestoneMessageDetailSection = ({
  description,
  milestone,
  messageType,
  service,
  showEditIcon,
  title,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [milestone?._id] })

  const { defaultValues, formControls } = useMemo(() => {
    return {
      defaultValues: {
        message: milestone?.[messageType].message
      },
      formControls: [
        { label: 'Message', name: 'message', type: 'textarea', minRows: 6, maxRows: 12, emojiPicker: false }
      ]
    }
  }, [messageType, milestone])

  const transformData = useCallback(({ message }) => ({
    [messageType]: { message }
  }), [messageType])

  return (
    <FormDetailSection
      className='whitespace-pre-line'
      defaultValues={defaultValues}
      description={description}
      formControls={formControls}
      formDescription={`Updating milestone's ${messageType} message.`}
      formTitle={`${upperFirst(messageType)} Message`}
      objectId={milestone._id}
      service={service}
      showEditIcon={showEditIcon}
      title={title}
      transformData={transformData}
      validationSuite={validationSuite}
      onChange={onChange}
    >
      {milestone?.[messageType].message || 'n/a'}
    </FormDetailSection>
  )
}

MilestoneMessageDetailSection.displayUsage = 'MilestoneMessageDetailSection'
MilestoneMessageDetailSection.propTypes = propTypes
MilestoneMessageDetailSection.defaultProps = defaultProps

export default MilestoneMessageDetailSection
