import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import messageQualityShape from '../../../prop-types/shapes/messageQuality'
import PanelDialog from '../../dialog/PanelDialog'

import MessageStrengthTab from './message-helper/MessageStrengthTab'
import ReviseMessageTab from './message-helper/ReviseMessageTab'

const propTypes = {
  messageQuality: PropType.shape(messageQualityShape).isRequired,
  messageText: PropType.string.isRequired,
  onInsertRevision: PropType.func
}

const defaultProps = {
  onInsertRevision: undefined
}

const log = logger({ enabled: true, tags: ['MessageHelperAction'] })

const MessageHelperAction = forwardRef(({ messageQuality, messageText, onInsertRevision }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const [currentTab, setCurrentTab] = useState('revise-message')

  const panelDialogRef = useRef()

  useImperativeHandle(ref, () => ({
    activate () {
      panelDialogRef.current.open()
    }
  }), [])

  const handleClose = useCallback(() => {
    panelDialogRef.current.close()
  }, [])

  const handleInsertRevision = useCallback((suggestionText) => {
    onInsertRevision?.(suggestionText)
    panelDialogRef.current.close()
  }, [onInsertRevision])

  return (
    <PanelDialog ref={panelDialogRef} ariaLabel='AIVA Message Helper' maxHeight>
      {currentTab === 'revise-message' && (
        <ReviseMessageTab
          messageQuality={messageQuality}
          messageText={messageText}
          onClose={handleClose}
          onInsertRevision={handleInsertRevision}
          onSelectTab={setCurrentTab}
        />
      )}
      {currentTab === 'message-strength' && (
        <MessageStrengthTab
          messageQuality={messageQuality}
          onClose={handleClose}
          onSelectTab={setCurrentTab}
        />
      )}
    </PanelDialog>
  )
})

MessageHelperAction.displayName = 'MessageHelperAction'
MessageHelperAction.propTypes = propTypes
MessageHelperAction.defaultProps = defaultProps

export default MessageHelperAction
