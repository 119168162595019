import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty()
  })
  test('password', 'Password is too short', () => {
    enforce(data.password).longerThan(7)
  })
  test('password', 'Password must have at least one number', () => {
    enforce(data.password).matches(/[0-9]/)
  })
  test('password', 'Password must have at least one lowercase letter', () => {
    enforce(data.password).matches(/[a-z]/)
  })
})

export default validationSuite
