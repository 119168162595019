import { useCallback, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { updateNumber as updateUserNumber } from '../../../services/admin'

import validationSuite from './UpdateNumber.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['UpdateNumber'] })

const UpdateNumber = () => {
  const formRef = useRef()
  const { userId, loading, user, setUser } = useSelectedUser()
  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback((updateReply) => {
    setUser(updateReply.model)
    bus.emit('userUpdated', updateReply.model)
    notify('Number Updated')
  }, [setUser])
  const { call: updateNumber } = useService(updateUserNumber, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    updateNumber({ disconnect: data.disconnect, newNumber: data.newNumber }, user._id)
    formRef.current.reset()
  }, [user, updateNumber])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='updateNumberForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Update Number'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <Form
          ref={formRef}
          defaultValues={{
            newNumber: ''
          }}
          formControls={[
            {
              type: 'checkbox',
              name: 'disconnect',
              label: 'Disconnect Current Number',
              hint: 'This will disconnect the current number.'
            },
            {
              type: 'text',
              name: 'newNumber',
              label: 'New Number'
            }
          ]}
          id='updateNumberForm'
          validationSuite={validationSuite}
          onSubmit={handleSubmit}
        />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='updateNumberForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

UpdateNumber.displayName = 'UpdateNumber'
UpdateNumber.propTypes = propTypes
UpdateNumber.defaultProps = defaultProps

export default UpdateNumber
