import { Badge as CapacitorBadge } from '@capawesome/capacitor-badge'
import { useEffect } from 'react'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import useStore from '../store'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Badge'] })

const Badge = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const appIsActive = useStore((state) => state.appIsActive)
  const totalUnreadCount = useStore((state) => state.unreadCounts.total)

  useEffect(() => {
    async function updateBadgeCount () {
      const { display: badgePermissionStatus } = await CapacitorBadge.checkPermissions()

      if (badgePermissionStatus === 'granted') {
        log.info('updating count', totalUnreadCount)
        CapacitorBadge.set({ count: totalUnreadCount })
      }
    }

    updateBadgeCount()
  }, [appIsActive, totalUnreadCount])

  return null
}

Badge.displayName = 'Badge'
Badge.propTypes = propTypes
Badge.defaultProps = defaultProps

export default Badge
