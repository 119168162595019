import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import tagShape from '../../../prop-types/shapes/tag'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import { warning } from '../../banners/Banner'
import Button from '../../buttons/Button'

import BulkDeleteAction from './BulkDeleteAction'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(tagShape)).isRequired
}

const defaultProps = {
}

const handleAction = (selectedItems, action) => {
  if (!selectedItems || !selectedItems.length) {
    warning('No selection made')
  } else {
    action?.()
  }
}

const BulkEditActions = ({ selectedItems }) => {
  const deleteActionRef = useRef()

  const handleDeleteAction = useCallback(() => handleAction(selectedItems, deleteActionRef.current.activate), [selectedItems])

  const actionsTrigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={actionsTrigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Delete Tags' onClick={handleDeleteAction} />
      </ActionMenu>
      <BulkDeleteAction ref={deleteActionRef} tags={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
