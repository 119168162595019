import React from 'react'

import PanelLayout from '../components/panels/PanelLayout'
import ForgotPasswordForm from '../screens/auth/forgot-password/Form'
import LoginGettingStarted from '../screens/auth/login/GettingStarted'
import LoginWizard from '../screens/auth/login/Wizard'
import ReactivateWizard from '../screens/auth/reactivate/Wizard'
import SignUpWizard from '../screens/auth/signup/Wizard'

const routes = [
  {
    path: 'integrations/:partnerId/connect',
    element: <PanelLayout includeHeaderBackground={false} panel1={<LoginWizard />} panel2={<LoginGettingStarted />} />
  },
  {
    path: 'integrations/:partnerId/forgot-password',
    element: <PanelLayout includeHeaderBackground={false} panel1={<ForgotPasswordForm />} panel2={<LoginGettingStarted />} />
  },
  {
    path: 'integrations/:partnerId/reactivate',
    element: <PanelLayout includeHeaderBackground={false} panel1={<ReactivateWizard />} />
  },
  {
    path: 'integrations/:partnerId/signup',
    element: <PanelLayout includeHeaderBackground={false} panel1={<SignUpWizard />} />
  }
]

export default routes
