import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import AddAction from '../../../components/multi-factor-authentication/actions/AddAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Plus, Reload } from '../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({
      reload: PropType.func.isRequired
    })
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListHeaderMoreMenu'] })

const ListHeaderMoreMenu = ({ listRef }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const addActionRef = useRef()

  const handleAddClick = useCallback(() => addActionRef.current.activate(), [])
  const handleReloadClick = useCallback(() => listRef.current.reload(), [listRef])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadClick} />
      <PanelHeaderButton icon={<Plus />} title='Add New Method' onClick={handleAddClick} />
      <AddAction ref={addActionRef} />
    </>
  )
}

ListHeaderMoreMenu.displayName = 'ListHeaderMoreMenu'
ListHeaderMoreMenu.propTypes = propTypes
ListHeaderMoreMenu.defaultProps = defaultProps

export default ListHeaderMoreMenu
