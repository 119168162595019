import PropType from 'prop-types'

import attachmentFromJSON from '../models/attachment'

import { searchPropTypes } from './attachment.prop-types'
import pb from './pb'

export function search (body, background = false) {
  const options = {
    body,
    background,
    model: {
      conversion: attachmentFromJSON,
      key: 'attachments',
      multiple: true
    }
  }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'attachments#search')
  return pb.setupPost('attachments/search', options)
}

export function create (name) {
  const body = { name }
  return pb.setupPost('attachments', {
    body,
    model: { conversion: attachmentFromJSON }
  })
}

export function get (attachmentId, background = false) {
  return pb.setupGet(`attachments/${attachmentId}`, {
    background,
    model: { conversion: attachmentFromJSON }
  })
}

export function update (attachmentId, formData) {
  return pb.setupPut(`attachments/${attachmentId}`, {
    body: formData,
    model: { conversion: attachmentFromJSON }
  })
}

export function updateFile (attachmentId, formData) {
  return pb.setupPut(`attachments/${attachmentId}/updateFile`, {
    body: formData,
    model: { conversion: attachmentFromJSON }
  })
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('attachments/bulk/delete', { body, background })
}

export function del (attachmentId) {
  return pb.setupDelete(`attachments/${attachmentId}`)
}
