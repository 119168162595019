import * as React from "react";
import { memo } from "react";
const SvgPicturePortrait = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 6c0-1.658 1.342-3 3-3h12c1.658 0 3 1.342 3 3v12a2.997 2.997 0 0 1-1.352 2.508C18.62 17.78 15.428 16 12 16s-6.62 1.78-7.648 4.508A2.985 2.985 0 0 1 3.1 18.756 3.09 3.09 0 0 1 3 18V6Zm3.322 15h11.356c-.766-1.64-2.89-3-5.678-3s-4.912 1.36-5.678 3ZM6 1C3.238 1 1 3.238 1 6v12c0 .447.064.86.16 1.242v.005A4.992 4.992 0 0 0 6 23h12c2.762 0 5-2.238 5-5V6c0-2.762-2.238-5-5-5H6Zm6 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPicturePortrait);
export default Memo;
