import * as React from "react";
import { memo } from "react";
const SvgEmails = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 53 48"
    {...props}
  >
    <mask id="Emails_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6 4a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h36a5 5 0 0 0 5-5V11.79a5 5 0 0 0-5-5H21.1l-.6-.796A5 5 0 0 0 16.505 4H6Z"
      clipRule="evenodd"
    />
    <path
      fill="#6B7380"
      d="m21.1 6.79-1.998 1.504.75.997H21.1v-2.5Zm-.6-.796-1.997 1.503L20.5 5.994ZM3.5 9A2.5 2.5 0 0 1 6 6.5v-5A7.5 7.5 0 0 0-1.5 9h5Zm0 2.79V9h-5v2.79h5Zm0 24.42V11.79h-5v24.42h5Zm0 2.79v-2.79h-5V39h5ZM6 41.5A2.5 2.5 0 0 1 3.5 39h-5A7.5 7.5 0 0 0 6 46.5v-5Zm36 0H6v5h36v-5Zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5v5a7.5 7.5 0 0 0 7.5-7.5h-5Zm0-27.21V39h5V11.79h-5ZM42 9.29a2.5 2.5 0 0 1 2.5 2.5h5a7.5 7.5 0 0 0-7.5-7.5v5Zm-20.9 0H42v-5H21.1v5Zm-2.597-1.793.6.797 3.995-3.007-.6-.797-3.995 3.007ZM16.505 6.5a2.5 2.5 0 0 1 1.998.997l3.995-3.007a7.5 7.5 0 0 0-5.993-2.99v5ZM6 6.5h10.505v-5H6v5Z"
      mask="url(#Emails_svg__a)"
    />
    <rect
      width={43.5}
      height={29.5}
      x={2.25}
      y={13.25}
      stroke="#6B7380"
      strokeWidth={2.5}
      rx={3.75}
    />
    <g clipPath="url(#Emails_svg__b)">
      <rect width={16} height={16} x={35} y={30} fill="#E1E7FF" rx={4} />
      <rect width={16} height={16} x={35} y={30} fill="#CFFBFE" rx={4} />
      <path
        fill="#0891B3"
        fillRule="evenodd"
        d="M38.926 35.222c0-.225.182-.407.407-.407h7.334c.225 0 .407.182.407.407v.927l-3.565 1.645c-.323.15-.7.15-1.023 0l-3.56-1.643v-.929Zm-.815 1.199v4.505c0 .675.547 1.222 1.222 1.222h7.334c.675 0 1.222-.547 1.222-1.222v-5.704c0-.675-.547-1.222-1.222-1.222h-7.334c-.675 0-1.222.547-1.222 1.222v1.18a.59.59 0 0 0 0 .019Zm.815.627 3.219 1.486c.54.25 1.166.249 1.706 0l3.223-1.487v3.879a.407.407 0 0 1-.407.407h-7.334a.407.407 0 0 1-.407-.407v-3.878Z"
        clipRule="evenodd"
      />
    </g>
    <rect
      width={18}
      height={18}
      x={34}
      y={29}
      stroke="#6B7380"
      strokeWidth={2}
      rx={5}
    />
    <defs>
      <clipPath id="Emails_svg__b">
        <rect width={16} height={16} x={35} y={30} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgEmails);
export default Memo;
