import AutolinkerLib from 'autolinker'
import classNames from 'classnames'
import PropType from 'prop-types'
import { useMemo } from 'react'
import MarkdownLib from 'react-markdown'
import { Link } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {
  content: PropType.string.isRequired,
  className: PropType.string
}

const defaultProps = {
  className: null
}

const log = logger({ enabled: false, tags: ['Markdown'] })

const markdownComponentRewrites = {
  // Rewrite anchor tags w/ `link` class & target in new tab
  a (props) {
    const isExternalHref = /^[a-zA-Z]+:/.test(props.href)
    return isExternalHref
      ? (
        <a
          className='link'
          rel='noopener noreferrer'
          target='_blank'
          {...props}
        />
        )
      : <Link {...props} className='link' to={props.href} />
  }
}

const Markdown = ({ content, className }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const computedClassName = useMemo(() => classNames(
    'prose prose-ol:pl-5 prose-ul:pl-4 prose-li:pl-0',
    'whitespace-normal leading-normal',
    className
  ), [className])

  const autoLinkedContent = useMemo(() => {
    // Use autolinker to render links as markdown [link text](link)
    return AutolinkerLib.link(content, {
      replaceFn: (match) => `[${match.getAnchorText()}](${match.getAnchorHref()})`
    })
  }, [content])

  return (
    <MarkdownLib className={computedClassName} components={markdownComponentRewrites}>
      {autoLinkedContent}
    </MarkdownLib>
  )
}

Markdown.displayName = 'Markdown'
Markdown.propTypes = propTypes
Markdown.defaultProps = defaultProps

export default Markdown
