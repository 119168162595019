import * as React from "react";
import { memo } from "react";
const SvgTemplate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 0a1 1 0 0 0-1 1v5H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-5h8a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H9Zm4 18v4H2V8h6v3H5a1 1 0 1 0 0 2h3v3H5a1 1 0 1 0 0 2h8Zm-3-6v4h12V2H10v10Zm2-6a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgTemplate);
export default Memo;
