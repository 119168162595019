import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import bus from '../../../../lib/bus'
import logger from '../../../../lib/logger'
import { markAsSpammer as markAsSpammerService } from '../../../../services/admin'
import { success } from '../../../banners/Banner'
import ConfirmDialog from '../../../dialog/ConfirmDialog'
import ListDialog from '../../../dialog/ListDialog'
import UserList from '../../../users/UserList'

const propTypes = {
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const log = logger({ enabled: false, tags: ['AddAction'] })

const AddAction = forwardRef(({ onSuccess }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()
  const confirmRef = useRef()
  const [selectedUser, setSelectedUser] = useState()
  const { call: markAsSpammerCall } = useService(markAsSpammerService, {
    onReplyOk: useCallback(() => {
      success(`${selectedUser.formattedName} was added to spammer list.`)
      bus.emit('userAdded', selectedUser)
      onSuccess?.()
    }, [selectedUser, onSuccess])
  })

  useImperativeHandle(ref, () => ({
    activate () { listRef.current.open() }
  }), [])

  const handleUserSelected = useCallback((data) => {
    setSelectedUser(data)
    confirmRef.current.open()
  }, [])

  const handleConfirm = useCallback(() => {
    markAsSpammerCall(selectedUser?._id)
  }, [markAsSpammerCall, selectedUser?._id])

  return (
    <>
      <ListDialog
        ref={listRef}
        list={UserList}
        multiple={false}
        title='Add Spammer'
        type='add'
        onSubmitSelected={handleUserSelected}
      />
      <ConfirmDialog
        ref={confirmRef}
        description={`Add ${selectedUser?.formattedName} to spammer list. Are you sure?`}
        title='Add spammer?'
        onConfirm={handleConfirm}
      />
    </>
  )
})

AddAction.displayName = 'AddAction'
AddAction.propTypes = propTypes
AddAction.defaultProps = defaultProps

export default AddAction
