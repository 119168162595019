import pb from './pb'

export function editorConfig (background = false) {
  return pb.setupGet('unlayer/editorConfig', { background })
}

export function editorPreview (html, background = false) {
  const options = {
    background,
    body: { html }
  }
  return pb.setupPost('unlayer/editorPreview', options)
}

export function previewEmail (emailId, contactId, background = true) {
  const options = { background }
  return pb.setupGet(`unlayer/preview/email/${emailId}/${contactId || ''}`, options)
}

export function previewLandingPage (landingPageId, contactId, background = true) {
  const options = { background }
  return pb.setupGet(`unlayer/preview/landingPage/${landingPageId}/${contactId || ''}`, options)
}

export function previewUnlayerTemplate (unlayerTemplateId, contactId, background = true) {
  const options = { background }
  return pb.setupGet(`unlayer/preview/unlayerTemplate/${unlayerTemplateId}/${contactId || ''}`, options)
}
