import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import PanelHeaderButton from '../../../../components/panels/panel-header/PanelHeaderButton'
import CreateAction from '../../../../components/unlayer-templates/actions/CreateAction'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { Reload } from '../../../../svg/icons'

const propTypes = {
  listRef: PropType.shape({
    current: PropType.shape({ query: PropType.func.isRequired, reload: PropType.func.isRequired })
  }).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ListHeaderMoreMenu'] })

const ListHeaderMoreMenu = ({ listRef }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const createActionRef = useRef()

  const handleRefreshSelected = useCallback(() => listRef.current.reload(), [listRef])
  const handleCreateSelected = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <PanelHeaderButton icon={<Reload />} onClick={handleRefreshSelected} />
      <PanelHeaderButton icon='add' title='Create' onClick={handleCreateSelected} />
      <CreateAction ref={createActionRef} />
    </>
  )
}

ListHeaderMoreMenu.displayName = 'ListHeaderMoreMenu'
ListHeaderMoreMenu.propTypes = propTypes
ListHeaderMoreMenu.defaultProps = defaultProps

export default ListHeaderMoreMenu
