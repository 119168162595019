import PropType from 'prop-types'
import { useCallback, useState } from 'react'

import GoalList from '../../../components/admin/goals/GoalList'
import Panel from '../../../components/panels/Panel'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'

const propTypes = {
  title: PropType.string
}

const defaultProps = {
  title: 'Goals'
}

const log = logger({ enabled: false, tags: ['GoalsList'] })

const GoalsList = ({ title }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const [total, setTotal] = useState(0)

  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader count={total} title={title} />
      <PanelContent>
        <GoalList savedStateKey='admin-goals' onTotalChange={handleTotalChange} />
      </PanelContent>
    </Panel>
  )
}

GoalsList.displayName = 'GoalsList'
GoalsList.propTypes = propTypes
GoalsList.defaultProps = defaultProps

export default GoalsList
