import PropType from 'prop-types'

import messageFieldsNormalizer from './messageFieldsNormalizer'
import pb from './pb'
import { searchPropTypes, updatePropTypes } from './template.prop-types'

export function search (body, background = false) {
  const options = { body, background }
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'template#search')
  return pb.setupPost('templates/search', options)
}

export function get (templateId, background = false) {
  return pb.setupGet(`templates/${templateId}`, { background })
}

export function create (body, background = false) {
  body = messageFieldsNormalizer(body)
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'template#create')
  return pb.setupPost('templates', { body, background })
}

export function update (templateId, body, background = false) {
  body = messageFieldsNormalizer(body)
  PropType.checkPropTypes(updatePropTypes, body, 'prop', 'template#update')
  return pb.setupPut(`templates/${templateId}`, { body, background })
}

export function bulkDelete (body, background = false) {
  return pb.setupDelete('templates/bulk/delete', { body, background })
}

export function del (templateId) {
  return pb.setupDelete(`templates/${templateId}`)
}

export function clone (templateId) {
  return pb.setupPost(`templates/${templateId}/clone`)
}
