import { useCallback, useRef } from 'react'

import Button from '../../../components/buttons/Button'
import CreateAction from '../../../components/keywords/actions/CreateAction'
import ExternalLink from '../../../components/links/ExternalLink'
import { Keywords as KeywordsEmptyStateSvg } from '../../../svg/empty-states'

const propTypes = {}

const defaultProps = {}

const EmptyListContent = () => {
  const createActionRef = useRef()

  const handleAddKeyword = useCallback(() => createActionRef.current.activate(), [])

  return (
    <>
      <div className='flex flex-col items-center my-6 mx-auto max-w-[250px]'>
        <KeywordsEmptyStateSvg className='my-6' />
        <h1 className='label-lg-strong text-center'>Get Started with Keywords</h1>
        <p className='label-sm text-center'>
          Learn how to use
          {' '}
          <ExternalLink
            className='link'
            href='https://training.projectbroadcast.com/courses/keywords'
            label='Keywords'
          />
          {' '}
          to become a Project Broadcast ninja!
        </p>
        <Button className='my-6 place-content-center' size='md' onClick={handleAddKeyword}>
          Add Keyword
        </Button>
      </div>
      <CreateAction ref={createActionRef} />
    </>
  )
}

EmptyListContent.displayName = 'EmptyListContent'
EmptyListContent.propTypes = propTypes
EmptyListContent.defaultProps = defaultProps

export default EmptyListContent
