import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import SearchBar from '../search-bar/SearchBar'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  search: PropType.object, // eslint-disable-line react/forbid-prop-types
  showFolderToggle: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape))
}

const defaultProps = {
  placeholder: undefined,
  search: null,
  showFolderToggle: false,
  sortList: null
}

const log = logger({ enabled: true, tags: ['ListSearchBar'] })

const ListSearchBar = ({
  placeholder,
  search: initialSearch,
  showFolderToggle,
  sortList,
  onChange
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <SearchBar
      placeholder={placeholder}
      search={initialSearch}
      showFolderToggle={showFolderToggle}
      sortList={sortList}
      onChange={onChange}
    />
  )
}

ListSearchBar.displayName = 'ListSearchBar'
ListSearchBar.propTypes = propTypes
ListSearchBar.defaultProps = defaultProps

export default ListSearchBar
