import { useCallback } from 'react'

import CheckboxDetailSection from '../../components/detail-sections/CheckboxDetailSection'
import ContactsWithAddDetailSection from '../../components/detail-sections/ContactsWithAddDetailSection'
import NameDetailSection from '../../components/detail-sections/NameDetailSection'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import { campaignTypeInfo } from '../../helpers/campaign'
import useCurrentCampaign from '../../hooks/useCurrentCampaign'
import useLogger from '../../hooks/useLogger'
import bus from '../../lib/bus'
import logger from '../../lib/logger'
import { update as updateCampaignService } from '../../services/campaign'
import { addAllInQuery, addSelected } from '../../services/campaign-contact'

import CountdownDateDetail from './detail/CountdownDateDetail'
import CampaignDetailHeaderMoreMenu from './detail/DetailHeaderMoreMenu'
import EventsDetail from './detail/EventsDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['CampaignsDetail'] })

const CampaignsDetail = () => {
  const { campaignId, campaign, setCampaign, loading } = useCurrentCampaign()

  useLogger({ log, lifecycle: true, tags: [campaignId] })

  const handleCampaignUpdated = useCallback((updateReply) => {
    const updatedCampaign = updateReply.model
    setCampaign(updatedCampaign)
    bus.emit('campaignUpdated', updatedCampaign)
  }, [setCampaign])

  const handleAddSelected = useCallback((data) => { return addSelected(data) }, [])
  const handleAddAllInQuery = useCallback((data) => { return addAllInQuery(data) }, [])

  if (loading || !campaign) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        end={<CampaignDetailHeaderMoreMenu campaign={campaign} onUpdatedCampaign={handleCampaignUpdated} />}
        start={<PanelHeaderBackButton />}
        title={campaign.name}
      />
      <PanelContent className='p-5'>
        <NameDetailSection
          object={campaign}
          objectName='campaign'
          service={updateCampaignService}
          onChange={handleCampaignUpdated}
        />
        {(campaign.type === 'countdown') && (
          <CountdownDateDetail campaign={campaign} service={updateCampaignService} onChange={handleCampaignUpdated} />
        )}
        <EventsDetail campaign={campaign} onChange={handleCampaignUpdated} />
        <ContactsWithAddDetailSection
          addAllInQueryService={handleAddAllInQuery}
          addSelectedService={handleAddSelected}
          description={campaignTypeInfo(campaign.type).contactsAddDescription}
          object={campaign}
          objectName='campaign'
          onChange={handleCampaignUpdated}
        />
        <CheckboxDetailSection
          checked={campaign.removeOnReply}
          description='After enabling, any new incoming message from a contact will trigger the contact to be removed from this campaign if they have been sent a message from this campaign.'
          name='removeOnReply'
          objectId={campaign._id}
          service={updateCampaignService}
          title='Remove Contact On Reply'
          confirmOnSet
          onChange={handleCampaignUpdated}
        />
      </PanelContent>
    </Panel>
  )
}

CampaignsDetail.displayName = 'CampaignsDetail'
CampaignsDetail.propTypes = propTypes
CampaignsDetail.defaultProps = defaultProps

export default CampaignsDetail
