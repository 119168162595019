import PropType from 'prop-types'

import DetailSection from '../../../components/detail-sections/DetailSection'
import FormattedDate from '../../../components/FormattedDate'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import contactShape from '../../../prop-types/shapes/contact'

const propTypes = {
  contact: PropType.shape(contactShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['DoubleOptInDetail'] })

const DoubleOptInDetail = ({ contact }) => {
  useLogger({ log, lifecycle: false, tags: [contact?._id] })

  const value = contact.isDoubleOptedIn
    ? <FormattedDate date={contact.doubleOptedInAt} format='extendedDateAndTime' />
    : 'Not double opted in'

  return (
    <DetailSection
      description='The date the contact double opted in.'
      title='Double Opted In'
    >
      {value}
    </DetailSection>
  )
}

DoubleOptInDetail.displayName = 'DoubleOptInDetail'
DoubleOptInDetail.propTypes = propTypes
DoubleOptInDetail.defaultProps = defaultProps

export default DoubleOptInDetail
