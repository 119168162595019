import PropType from 'prop-types'

import { usePanel } from '../../contexts/PanelContext'

const propTypes = {
  className: PropType.string
}
const defaultProps = {
  className: null
}

const PanelBottomSafeSpacer = ({ className }) => {
  const { containsAivaChatButton, containsSideMenuToggleButton } = usePanel()

  // SPACER COMPONENTS NOTES:
  // Spacer height resolves to the taller of the two:
  // pb-safe
  //    is to prevent obscuring from any bottom device notch, etc
  // side menu toggle button (when showing)
  //    is to prevent obscuring from the side nav toggle menu button
  // aiva chat toggle button (when showing)
  //    is to prevent obscuring from the aiva chat button
  return (
    <div className={`flex flex-row ${className}`}>
      <div className='pb-safe basis-1/2 ' />
      <div className='basis-1/4'>
        {containsSideMenuToggleButton
          ? <div className='lg:hidden min-h-[85px]' />
          : null}
      </div>
      <div className='basis-1/4'>
        {containsAivaChatButton
          ? <div className='min-h-[85px]' />
          : null}
      </div>
    </div>
  )
}

PanelBottomSafeSpacer.displayName = 'PanelBottomSafeSpacer'
PanelBottomSafeSpacer.propTypes = propTypes
PanelBottomSafeSpacer.defaultProps = defaultProps

export default PanelBottomSafeSpacer
