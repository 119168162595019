import PropType from 'prop-types'
import { useMemo, useRef } from 'react'

import { firstImageUrl } from '../../helpers/messageMedia'
import { messageQualityLabel } from '../../helpers/messageQuality'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import message from '../../prop-types/shapes/message'
import ActionMenu from '../action-menu/ActionMenu'
import ActionMenuItem from '../action-menu/ActionMenuItem'
import MessageQualityAction from '../message/actions/MessageQuality'
import NewBroadcastAction from '../message/actions/NewBroadcast'
import ViewImageAction from '../message/actions/ViewImage'
import MessageFragment from '../message/MessageFragment'
import NewMessageTemplateAction from '../message-templates/actions/CreateAction'

import DetailSectionMoreButton from './DetailSectionMoreButton'
import FormDetailSection from './FormDetailSection'

const propTypes = {
  message: PropType.shape(message).isRequired,
  service: PropType.func.isRequired,
  contentOptions: PropType.shape({
    emails: PropType.bool,
    messageTemplates: PropType.bool,
    voiceDrops: PropType.bool
  }),
  readOnly: PropType.bool,
  required: PropType.bool,
  onChange: PropType.func,
  onRefreshForVoice: PropType.func
}

const defaultProps = {
  contentOptions: {
    emails: true,
    messageTemplates: true,
    voiceDrops: true
  },
  onChange: null,
  onRefreshForVoice: null,
  readOnly: false,
  required: true
}

const log = logger({ enabled: true, tags: ['MessageDetailSection'] })

const MessageDetailSection = ({ message, service, readOnly, required, contentOptions, onChange, onRefreshForVoice, ...rest }) => {
  useLogger({ log, lifecycle: false, tags: [message.name || message._id] })
  const { aivaSettings: { messageHelper: aivaMessageHelperEnabled } } = useCurrentUser()

  const viewImageRef = useRef()
  const messageInsightsRef = useRef()
  const newMessageTemplateRef = useRef()
  const newBroadcastRef = useRef()

  const formControls = useMemo(() => {
    return [
      {
        label: '',
        name: 'message',
        type: message.type === 'rvm' ? 'voicedrop' : 'message',
        required,
        contentOptions
      }
    ]
  }, [contentOptions, message.type, required])

  return (
    <FormDetailSection
      defaultValues={{ message }}
      formControls={formControls}
      formDescription={`Update the ${message.type === 'rvm' ? 'Voice Drop' : 'Message'} below`}
      formTitle={message.type === 'rvm' ? 'Edit Voice Drop' : 'Edit Message'}
      objectId={message._id}
      service={service}
      showEditIcon={!readOnly}
      title={message.type === 'rvm' ? 'Voice Drop' : 'Message'}
      isPanelDialog
      onChange={onChange}
      {...rest}
    >
      <MessageFragment message={message} size='lg' onRefreshForVoice={onRefreshForVoice} />
      <div className='grid place-content-center'>
        <ActionMenu placement='bottom-start' trigger={<DetailSectionMoreButton ariaSubject='Campaign Contact' />}>
          {message.type !== 'rvm' && message.type !== 'email' && firstImageUrl(message) && (
            <ActionMenuItem label='View Image' onClick={() => viewImageRef.current.activate()} />
          )}
          {message.type !== 'rvm' && message.type !== 'email' && (
            <ActionMenuItem label={messageQualityLabel(aivaMessageHelperEnabled)} onClick={() => messageInsightsRef.current.activate()} />
          )}
          {message.type !== 'rvm' && message.type !== 'email' && (
            <ActionMenuItem label='New Message Template' onClick={() => newMessageTemplateRef.current.activate()} />
          )}
          <ActionMenuItem label='New Broadcast' onClick={() => newBroadcastRef.current.activate()} />
        </ActionMenu>
        <ViewImageAction ref={viewImageRef} message={message} />
        <MessageQualityAction ref={messageInsightsRef} message={message} variant={aivaMessageHelperEnabled ? 'aiva' : 'insights'} />
        <NewMessageTemplateAction ref={newMessageTemplateRef} message={message} />
        <NewBroadcastAction ref={newBroadcastRef} message={message} />
      </div>
    </FormDetailSection>
  )
}

MessageDetailSection.displayName = 'MessageDetailSection'
MessageDetailSection.propTypes = propTypes
MessageDetailSection.defaultProps = defaultProps

export default MessageDetailSection
