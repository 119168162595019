import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { formatPhone, mfaChannelVerb } from '../../../lib/formatters'
import logger from '../../../lib/logger'
import mfaMethod from '../../../prop-types/shapes/mfaMethod'
import { remove as removeService } from '../../../services/multi-factor-authentication'
import ConfirmDialog from '../../dialog/ConfirmDialog'

const propTypes = {
  mfaMethod: PropType.shape(mfaMethod).isRequired
}

const defaultProps = {
}

const log = logger({ enabled: false, tags: ['RemoveAction'] })

const RemoveAction = forwardRef(({ mfaMethod }, ref) => {
  useLogger({ log, lifecycle: false, tags: [mfaMethod._id] })
  const confirmRef = useRef()
  const { call: removeCall } = useService(removeService, {
    onReplyOk: useCallback(() => {
      bus.emit('mfaMethodRemoved')
    }, [])
  })

  useImperativeHandle(ref, () => ({
    activate () { confirmRef.current.open() }
  }), [])

  const handleConfirm = useCallback(() => {
    removeCall(mfaMethod._id)
  }, [removeCall, mfaMethod])

  return (
    <ConfirmDialog
      ref={confirmRef}
      description={`Are you sure you want to remove ${mfaChannelVerb(mfaMethod.channel)} ${formatPhone(mfaMethod.to)} from your account?`}
      title='Remove MFA Method'
      onConfirm={handleConfirm}
    />
  )
})

RemoveAction.displayName = 'RemoveAction'
RemoveAction.propTypes = propTypes
RemoveAction.defaultProps = defaultProps

export default RemoveAction
