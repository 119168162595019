import { map } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import useService from '../../../../hooks/useService'
import logger from '../../../../lib/logger'
import scheduledBroadcastShape from '../../../../prop-types/shapes/scheduledBroadcast'
import { addAllInQuery, addSelected, removeAllInQuery, removeSelected } from '../../../../services/scheduledBroadcast-contact'
import ContactList from '../../../contacts/ContactList'
import ListDialog from '../../../dialog/ListDialog'
import PanelDialog from '../../../dialog/PanelDialog'
import PanelHeaderButton from '../../../panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../panels/PanelContent'
import PanelHeader from '../../../panels/PanelHeader'
import ScheduledBroadcastContactList from '../../ScheduledBroadcastContactList'

const propTypes = {
  scheduledBroadcast: PropType.shape(scheduledBroadcastShape).isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, tags: ['ScheduledBroadcastContactsDialog'] })

const ScheduledBroadcastContactsDialog = forwardRef(({ scheduledBroadcast, onChange }, ref) => {
  const scheduledBroadcastId = scheduledBroadcast._id

  useLogger({ log, lifecycle: false, tags: [scheduledBroadcastId] })

  const scheduledBroadcastContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()

  const handleReplyOk = useCallback((reply) => {
    scheduledBroadcastContactListRef.current.reload()
    const updatedScheduledBroadcast = reply.model
    onChange?.(updatedScheduledBroadcast)
  }, [onChange])
  const { call: addAllInQueryCall } = useService(addAllInQuery, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(addSelected, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(removeAllInQuery, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(removeSelected, { onReplyOk: handleReplyOk })

  const handleBackClick = useCallback(() => ref.current.close(), [ref])

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall({ scheduledBroadcastId, ...query })
  }, [addAllInQueryCall, scheduledBroadcastId])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall({ scheduledBroadcastId, contactIds: map(selected, '_id') })
  }, [addSelectedCall, scheduledBroadcastId])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall({ scheduledBroadcastId, ...query })
  }, [scheduledBroadcastId, removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall({ scheduledBroadcastId, contactIds: map(selected, '_id') })
  }, [scheduledBroadcastId, removeSelectedCall])

  const end = (
    <>
      {scheduledBroadcast.contacts.length ? <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} /> : null}
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <PanelDialog ref={ref} ariaLabel='Broadcast Contacts'>
      <PanelHeader
        end={end}
        start={<PanelHeaderButton icon='back' onClick={handleBackClick} />}
        subtitle={scheduledBroadcast.name}
        title={`Contacts (${scheduledBroadcast.contacts?.length})`}
      />
      <PanelContent>
        <ScheduledBroadcastContactList
          ref={scheduledBroadcastContactListRef}
          scheduledBroadcastId={scheduledBroadcast._id}
          type='list'
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={ScheduledBroadcastContactList}
        scheduledBroadcastId={scheduledBroadcast._id}
        subtitle={scheduledBroadcast.name}
        title='Remove Contact(s)'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        list={ContactList}
        scheduledBroadcastId={scheduledBroadcast._id}
        subtitle={scheduledBroadcast.name}
        title='Add Contact(s)'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </PanelDialog>
  )
})

ScheduledBroadcastContactsDialog.displayName = 'ScheduledBroadcastContactsDialog'
ScheduledBroadcastContactsDialog.propTypes = propTypes
ScheduledBroadcastContactsDialog.defaultProps = defaultProps

export default ScheduledBroadcastContactsDialog
