import { create, enforce, only, test } from 'vest'

const validationSuite = create((data = {}, currentField) => {
  only(currentField)

  test('firstName', 'First Name is required', () => {
    enforce(data.firstName).isNotEmpty()
  })

  test('lastName', 'Last Name is required', () => {
    enforce(data.lastName).isNotEmpty()
  })

  test('email', 'Email is required', () => {
    enforce(data.email).isNotEmpty()
  })

  test('phoneNumber', 'Phone Number is required', () => {
    enforce(data.phoneNumber).isNotEmpty()
  })
})

export default validationSuite
