import defaultIntegratorImage from '../assets/images/integrations/example-integrator.png'

import proxy from './proxy'

export default function integrationFromJSON (json) {
  if (!json) { return null }

  const overrides = {

    get icon () {
      let icon = defaultIntegratorImage
      try {
        // NOTE: webpack converts this asset path to the fingerprinted asset path
        icon = require(`../assets/images/integrations/${json.key}.png`)
      } catch (ex) {}
      return icon
    }
  }

  return proxy(json, overrides)
}
