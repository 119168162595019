import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import DeleteAction from '../../../components/qr-codes/actions/DeleteAction'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import qrCodeShape from '../../../prop-types/shapes/qrCode'

const propTypes = {
  qrCode: PropType.shape(qrCodeShape).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['QrCodeDetailHeaderMoreMenu'] })

const QrCodeDetailHeaderMoreMenu = ({ qrCode }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const deleteActionRef = useRef()

  const onDeleteSelected = useCallback(() => deleteActionRef.current.activate(), [])
  const onDeleteSuccess = useCallback(() => navigateInStack('/qr-codes', { replace: true }), [navigateInStack])

  return !!qrCode && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Delete QR Code' onClick={onDeleteSelected} />
      </ActionMenu>
      <DeleteAction ref={deleteActionRef} qrCode={qrCode} onSuccess={onDeleteSuccess} />
    </>
  )
}

QrCodeDetailHeaderMoreMenu.displayName = 'QrCodeDetailHeaderMoreMenu'
QrCodeDetailHeaderMoreMenu.propTypes = propTypes
QrCodeDetailHeaderMoreMenu.defaultProps = defaultProps

export default QrCodeDetailHeaderMoreMenu
