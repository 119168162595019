import { useCallback, useRef } from 'react'

import { notify } from '../../../components/banners/Banner'
import Button from '../../../components/buttons/Button'
import Form from '../../../components/forms/Form'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import { restoreCancelledCredits as restoreCreditsToUser } from '../../../services/admin'

import validationSuite from './AddCredits.validation'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['RestoreCancelledCredits'] })

const RestoreCancelledCredits = () => {
  const formRef = useRef()
  const { userId, loading, user, setUser } = useSelectedUser()

  useLogger({ log, lifecycle: false, tags: [userId] })

  const handleReplyOk = useCallback((updateReply) => {
    setUser(updateReply.model)
    notify('Credits Restored')
  }, [setUser])
  const { call: restoreCredits } = useService(restoreCreditsToUser, { onReplyOk: handleReplyOk })

  const handleSubmit = useCallback((data) => {
    restoreCredits({ amount: data.amount }, user._id)
    formRef.current.reset()
  }, [user, restoreCredits])

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  const form = (
    <>
      <Form
        ref={formRef}
        confirm={{
          description: `Are you sure you want to restore credits to ${user.formattedName} at ${user.formattedPhoneNumber}?`,
          title: 'Confirmation'
        }}
        defaultValues={{
          amount: ''
        }}
        formControls={[
          {
            type: 'number',
            name: 'amount',
            label: 'Number of Credits',
            step: 1,
            max: user.messageCreditsAtCancel
          }
        ]}
        id='cancelledCreditsForm'
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <div className='flex flex-row gap-4 items-center mt-3'>
        <Button
          className='flex-grow md:flex-none'
          form='cancelledCreditsForm'
          size='sm'
          type='submit'
        >
          Save
        </Button>
      </div>
    </>
  )

  return (
    <Panel>
      <PanelHeader
        end={
          <PanelHeaderButton
            form='cancelledCreditsForm'
            title='Save'
            type='submit'
          />
        }
        start={<PanelHeaderBackButton />}
        subtitle={user.formattedName}
        title='Restore Cancelled Credits'
      />
      <PanelContent className='p-5 space-y-2'>
        <div className='flex flex-row '>
          <ListItemContent item={user} />
        </div>
        <p className='headline-2xs mb-[7px]'> Credits available to restore</p>
        <span>{user.messageCreditsAtCancel}</span>
        {user.messageCreditsAtCancel > 0 ? form : null}
      </PanelContent>
    </Panel>
  )
}

RestoreCancelledCredits.displayName = 'RestoreCancelledCredits'
RestoreCancelledCredits.propTypes = propTypes
RestoreCancelledCredits.defaultProps = defaultProps

export default RestoreCancelledCredits
