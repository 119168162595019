import PropType from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

import { useApp } from '../contexts/AppContext'
import useLogger from '../hooks/useLogger'
import bus from '../lib/bus'
import logger from '../lib/logger'

const propTypes = {
  children: PropType.node.isRequired
}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['RequireWeb'] })

const RequireWeb = ({ children }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const location = useLocation()
  const { isWeb } = useApp()

  if (isWeb) {
    return children
  } else {
    bus.emit('nativeUnsupportedAppUrl', `${process.env.REACT_APP_URL}${location.pathname}`)
    return <Navigate to='/' />
  }
}

RequireWeb.displayName = 'RequireWeb'
RequireWeb.propTypes = propTypes
RequireWeb.defaultProps = defaultProps

export default RequireWeb
