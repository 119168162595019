import PropType from 'prop-types'
import { useCallback } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Star, StarFilled } from '../../../svg/icons'
import Button from '../../buttons/Button'

const propTypes = {
  starred: PropType.bool,
  onClick: PropType.func
}

const defaultProps = {
  onClick: undefined,
  starred: false
}

const log = logger({ enabled: false, tags: ['StarContact'] })

const StarContact = ({ starred, onClick }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const text = starred ? 'Star Contact' : 'Unstar Contact'
  const icon = starred ? <StarFilled /> : <Star />

  const handleStarClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    onClick?.()
  }, [onClick])

  return (
    <Button
      icon={icon}
      size='sm'
      tabIndex={-1}
      variant='text'
      onClickCapture={handleStarClick}
    >
      {text}
    </Button>
  )
}

StarContact.displayName = 'StarContact'
StarContact.propTypes = propTypes
StarContact.defaultProps = defaultProps

export default StarContact
