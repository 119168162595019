import './ThreadRecipientTyping.css'

const propTypes = {}

const defaultProps = {}

const ThreadRecipientTyping = () => {
  return (
    <div className='flex flex-row gap-1 mx-4 mb-4 p-3 rounded-lg self-start bg-neutral-100'>
      <span className='message-thread-typing-dot h-2 w-2 rounded-full' />
      <span className='message-thread-typing-dot h-2 w-2 rounded-full' />
      <span className='message-thread-typing-dot h-2 w-2 rounded-full' />
    </div>
  )
}

ThreadRecipientTyping.displayName = 'ThreadRecipientTyping'
ThreadRecipientTyping.propTypes = propTypes
ThreadRecipientTyping.defaultProps = defaultProps

export default ThreadRecipientTyping
