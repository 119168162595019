import PropType from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'
import TemplateList from '../templates/TemplateList'

const propTypes = {
  additionalHandlers: PropType.func,
  placeholder: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object
}

const defaultProps = {
  additionalHandlers: () => {},
  placeholder: 'Search Emails',
  queryOverrides: {}
}

const log = logger({ enabled: false, tags: ['EmailList'] })

const EmailList = forwardRef(({
  additionalHandlers,
  placeholder,
  queryOverrides,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })
  const combinedQueryOverrides = { contentType: [CONTENT_TYPE.email], ...queryOverrides }

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('emailCreated', handleBusEvent)
  useBus('emailCloned', handleBusEvent)
  useBus('emailUpdated', handleBusEvent)
  useBus('emailDeleted', handleBusEvent)
  useBus('emailsDeleted', handleBusEvent)

  additionalHandlers?.('email', ref)

  return (
    <TemplateList
      ref={ref}
      placeholder={placeholder}
      {...listProps}
      queryOverrides={combinedQueryOverrides}
    />
  )
})

EmailList.displayName = 'EmailList'
EmailList.propTypes = propTypes
EmailList.defaultProps = defaultProps

export default EmailList
