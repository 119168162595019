import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import ActionMenu from '../../../components/action-menu/ActionMenu'
import ActionMenuItem from '../../../components/action-menu/ActionMenuItem'
import CloneAction from '../../../components/campaigns/actions/CloneAction'
import DeleteAction from '../../../components/campaigns/actions/DeleteAction'
import FastForwardAction from '../../../components/campaigns/actions/FastForwardAction'
import ViewScheduleAction from '../../../components/campaigns/actions/ViewScheduleAction'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import useLogger from '../../../hooks/useLogger'
import useNavigateInStack from '../../../hooks/useNavigateInStack'
import logger from '../../../lib/logger'
import campaignShape from '../../../prop-types/shapes/campaign'

const propTypes = {
  campaign: PropType.shape(campaignShape),
  onUpdatedCampaign: PropType.func
}

const defaultProps = {
  campaign: null,
  onUpdatedCampaign: null
}

const log = logger({ enabled: false, tags: ['CampaignDetailHeaderMoreMenu'] })

const CampaignDetailHeaderMoreMenu = ({ campaign, onUpdatedCampaign }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigateInStack = useNavigateInStack()
  const fastForwardActionRef = useRef()
  const viewScheduleActionRef = useRef()
  const cloneActionRef = useRef()
  const deleteActionRef = useRef()

  const onDeleteSuccess = useCallback(() => navigateInStack('/campaigns', { replace: true }), [navigateInStack])

  return !!campaign && (
    <>
      <ActionMenu placement='bottom-end' trigger={<PanelHeaderButton icon='more' />}>
        <ActionMenuItem label='Fast Forward' onClick={() => fastForwardActionRef.current.activate()} />
        <ActionMenuItem label='View Schedule' onClick={() => viewScheduleActionRef.current.activate()} />
        <ActionMenuItem label='Clone Campaign' onClick={() => cloneActionRef.current.activate()} />
        <ActionMenuItem label='Delete Campaign' onClick={() => deleteActionRef.current.activate()} />
      </ActionMenu>
      <FastForwardAction ref={fastForwardActionRef} campaign={campaign} onSuccess={onUpdatedCampaign} />
      <ViewScheduleAction ref={viewScheduleActionRef} campaign={campaign} />
      <CloneAction ref={cloneActionRef} campaign={campaign} />
      <DeleteAction ref={deleteActionRef} campaign={campaign} onSuccess={onDeleteSuccess} />
    </>
  )
}

CampaignDetailHeaderMoreMenu.displayName = 'CampaignDetailHeaderMoreMenu'
CampaignDetailHeaderMoreMenu.propTypes = propTypes
CampaignDetailHeaderMoreMenu.defaultProps = defaultProps

export default CampaignDetailHeaderMoreMenu
