import PropType from 'prop-types'

const propTypes = {
  icon: PropType.element.isRequired,
  label: PropType.string.isRequired,
  chevron: PropType.element,
  count: PropType.number
}

const defaultProps = {
  chevron: undefined,
  count: undefined
}

const IconLabelChevron = ({ chevron, count, icon, label }) => {
  return (
    <div className='flex flex-row w-full pl-[18px] pr-[12px] py-[10px] items-center'>
      <div className='w-[26px] h-[26px] grow-0 shrink-0'>{icon}</div>
      <div className='flex flex-row label-md-strong flex-grow ml-[20px] items-center'>
        {label}
        {count ? <span className='bg-primary text-white rounded-full label-2xs-strong ml-[10px] py-[2px] px-[8px]'>{count}</span> : null}
      </div>
      {chevron ? <div className='ml-auto relative top-[-2px] self-end'>{chevron}</div> : null}
    </div>
  )
}

IconLabelChevron.displayName = 'IconLabelChevron'
IconLabelChevron.propTypes = propTypes
IconLabelChevron.defaultProps = defaultProps

export default IconLabelChevron
