import * as React from "react";
import { memo } from "react";
const SvgShare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Share_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20 21.353c0 .07-.035.236-.153.371-.087.1-.299.276-.847.276H5a1.59 1.59 0 0 1-.74-.19.552.552 0 0 1-.194-.175.507.507 0 0 1-.066-.282V10.647c0-.07.035-.236.153-.371.087-.1.299-.276.847-.276h3a1 1 0 0 0 0-2H5c-1.052 0-1.84.373-2.353.96A2.621 2.621 0 0 0 2 10.646v10.706c0 1.074.577 1.784 1.24 2.178.612.362 1.301.469 1.76.469h14c1.052 0 1.84-.373 2.353-.96A2.621 2.621 0 0 0 22 21.354V10.647c0-1.074-.577-1.785-1.24-2.178C20.148 8.107 19.459 8 19 8h-3a1 1 0 1 0 0 2h3c.208 0 .518.058.74.19.1.06.16.12.194.175.03.048.066.13.066.282v10.706ZM11.3.286a.996.996 0 0 1 1.086-.209.996.996 0 0 1 .315.21l.007.007 4 3.999a1 1 0 0 1-1.415 1.414L13 3.414V13a1 1 0 1 1-2 0V3.414L8.707 5.707a1 1 0 0 1-1.414-1.414l3.999-4L11.3.287Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Share_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgShare);
export default Memo;
