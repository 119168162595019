import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import configurableForm from '../../prop-types/shapes/configurableForm'
import { Key, Slug } from '../../svg/icons'
import { ConfigurableForm } from '../../svg/types'

const propTypes = {
  item: PropType.shape(configurableForm)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['ConfigurableFomListItemContent'] })

const ConfigurableFomListItemContent = ({ item: configurableForm }) => {
  useLogger({ log, lifecycle: false, tags: [configurableForm?._id] })

  return (
    <>
      <ConfigurableForm className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full overflow-auto'>
        <div className='label-sm-strong text-black break-words'>
          {configurableForm.name}
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Slug className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            {configurableForm.slug}
          </div>
        </div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Key className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <div className='line-clamp-1 break-words'>
            {configurableForm.keywordName}
          </div>
        </div>
      </div>
    </>
  )
}

ConfigurableFomListItemContent.displayName = 'ConfigurableFomListItemContent'
ConfigurableFomListItemContent.propTypes = propTypes
ConfigurableFomListItemContent.defaultProps = defaultProps

export default ConfigurableFomListItemContent
