import { pick } from 'lodash'
import PropType from 'prop-types'

import FormDetailSection from '../../../components/detail-sections/FormDetailSection'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import keywordShape from '../../../prop-types/shapes/keyword'

const propTypes = {
  keyword: PropType.shape(keywordShape).isRequired,
  service: PropType.func.isRequired,
  onChange: PropType.func
}

const defaultProps = {
  onChange: null
}

const log = logger({ enabled: false, keyword: ['NameDetail'] })

const NameDetail = ({ keyword, service, onChange }) => {
  useLogger({ log, lifecycle: false, keyword: [keyword?.word] })

  return (
    <FormDetailSection
      defaultValues={pick(keyword, ['word'])}
      description='Keywords act as mini inboxes. You can have Project Broadcast perform tasks on your behalf when this keyword is sent to your Project Broadcast Number.'
      formControls={[{ label: 'Name', name: 'word', type: 'text', required: true }]}
      formDescription={`Updating keyword for ${keyword.word}`}
      formTitle={keyword.word}
      objectId={keyword._id}
      service={service}
      title='Name'
      showEditIcon
      onChange={onChange}
    >
      {keyword.word}
    </FormDetailSection>
  )
}

NameDetail.displayName = 'NameDetail'
NameDetail.propTypes = propTypes
NameDetail.defaultProps = defaultProps

export default NameDetail
