import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pluck } from 'underscore'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import spaceShape from '../../../prop-types/shapes/space'
import { bulkDelete as bulkDeleteService } from '../../../services/space'
import { success, warning } from '../../banners/Banner'
import DangerousConfirmDialog from '../../dialog/DangerousConfirmDialog'

const propTypes = {
  spaces: PropType.arrayOf(PropType.shape(spaceShape)).isRequired
}

const BulkDeleteAction = forwardRef(({ spaces }, ref) => {
  const confirmRef = useRef()
  const navigate = useNavigate()
  const params = useParams()

  const handleReplyOk = useCallback(() => {
    success('Spaces have been deleted')
    bus.emit('spacesDeleted', spaces)
    bus.emit('closeBulkEditDialog')
    const spaceIds = new Set(pluck(spaces, '_id'))
    if (spaceIds.has(params.spaceId)) { navigate('/spaces') }
  }, [navigate, spaces, params.spaceId])
  const { call: bulkDeleteSpaces } = useService(bulkDeleteService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () {
      if (!spaces || !spaces.length) {
        warning('No selection made')
      } else {
        confirmRef.current.open()
      }
    }
  }), [spaces])

  const handleConfirm = useCallback(() => {
    bulkDeleteSpaces({ spaceIds: pluck(spaces, '_id') })
  }, [bulkDeleteSpaces, spaces])

  return (
    <DangerousConfirmDialog
      ref={confirmRef}
      confirmButtonText='I understand, delete permanently'
      confirmValue='DELETE'
      title='Are you absolutely sure?'
      onConfirm={handleConfirm}
    >
      <p className='mb-4'>
        This action
        {' '}
        <strong>CANNOT</strong>
        {' '}
        be undone. This will permanently delete the items and everything associated with them.
      </p>
    </DangerousConfirmDialog>
  )
})

BulkDeleteAction.displayName = 'BulkDeleteAction'
BulkDeleteAction.propTypes = propTypes

export default BulkDeleteAction
