import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { get as getAttachmentService } from '../../../../services/attachment'
import { get as getConfigurableFormService } from '../../../../services/configurableForm'
import { get as getLandingPageService } from '../../../../services/landingPage'
import { get as getTemplateService } from '../../../../services/template'
import { get as getTrackableLinkService } from '../../../../services/trackableLink'
import {
  Attach,
  Browser,
  ConfigurableForm,
  Dynamic,
  Email,
  Link,
  Microphone,
  PictureFrame
} from '../../../../svg/icons'
import ActionMenu from '../../../action-menu/ActionMenu'
import ActionMenuDivider from '../../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../../action-menu/ActionMenuItem'
import AttachmentList from '../../../attachments/AttachmentList'
import ConfigurableFormList from '../../../configurable-forms/ConfigurableFormList'
import ListSelectAction from '../../../dialog/ListSelectAction'
import DynamicFieldsDialog from '../../../dynamic-fields-dialog/DynamicFieldsDialog'
import EmailList from '../../../emails/EmailList'
import LandingPageList from '../../../landing-pages/LandingPageList'
import TrackableLinkList from '../../../trackable-links/TrackableLinkList'
import VoiceDropList from '../../../voice-drops/VoiceDropList'

import InsertContentButton from './InsertContentButton'

const propTypes = {
  contentOptions: PropType.shape({
    emails: PropType.bool,
    messageTemplates: PropType.bool,
    voiceDrops: PropType.bool
  }),
  theme: PropType.oneOf(['chat', 'input', 'space', 'aiva']),
  onAttachmentSelect: PropType.func,
  onConfigurableFormSelect: PropType.func,
  onDynamicFieldSelect: PropType.func,
  onEmailSelect: PropType.func,
  onLandingPageSelect: PropType.func,
  onTrackableLinkSelect: PropType.func,
  onVcardSelect: PropType.func,
  onVoiceDropSelect: PropType.func
}

const defaultProps = {
  contentOptions: {
    emails: true,
    messageTemplates: true,
    voiceDrops: true
  },
  onAttachmentSelect: undefined,
  onConfigurableFormSelect: undefined,
  onDynamicFieldSelect: undefined,
  onEmailSelect: undefined,
  onLandingPageSelect: undefined,
  onTrackableLinkSelect: undefined,
  onVcardSelect: undefined,
  onVoiceDropSelect: undefined,
  theme: 'input'
}

const log = logger({ enabled: false, tags: ['ActionMenu'] })

const MessageBarActionMenu = forwardRef(({
  contentOptions,
  theme,
  onAttachmentSelect,
  onConfigurableFormSelect,
  onDynamicFieldSelect,
  onEmailSelect,
  onLandingPageSelect,
  onTrackableLinkSelect,
  onVcardSelect,
  onVoiceDropSelect
}, ref) => {
  const dynamicFieldsDialogRef = useRef()
  const selectAttachmentActionRef = useRef()
  const selectConfigurableFormActionRef = useRef()
  const selectEmailActionRef = useRef()
  const selectLandingPageActionRef = useRef()
  const selectTrackableLinkActionRef = useRef()
  const selectVoiceDropLinkActionRef = useRef()

  const showEmailItem = contentOptions.emails

  useLogger({ log, lifecycle: false, tags: [] })

  const handleAttachmentsClick = useCallback(() => selectAttachmentActionRef.current.activate(), [])
  const handleConfigurableFormsClick = useCallback(() => selectConfigurableFormActionRef.current.activate(), [])
  const handleDynamicFieldsClick = useCallback(() => dynamicFieldsDialogRef.current.open(), [])
  const handleEmailFieldsClick = useCallback(() => selectEmailActionRef.current.activate(), [])
  const handleLandingPageClick = useCallback(() => selectLandingPageActionRef.current.activate(), [])
  const handleTrackableLinksClick = useCallback(() => selectTrackableLinkActionRef.current.activate(), [])
  const handleVoiceDropsClick = useCallback(() => selectVoiceDropLinkActionRef.current.activate(), [])

  if (theme === 'space') { return null }

  return (
    <>
      <ActionMenu
        ref={ref}
        offset={{ crossAxis: 6, mainAxis: 6 }}
        placement='bottom-start'
        trigger={<InsertContentButton disabled={theme === 'aiva'} />}
      >
        <ActionMenuHeader label='Insert Content' />
        <ActionMenuDivider />
        <ActionMenuItem label='Attachments' start={<Attach />} onClick={handleAttachmentsClick} />
        <ActionMenuItem label='Configurable Forms' start={<ConfigurableForm />} onClick={handleConfigurableFormsClick} />
        <ActionMenuItem label='Dynamic Fields' start={<Dynamic />} onClick={handleDynamicFieldsClick} />
        {showEmailItem ? <ActionMenuItem label='Emails' start={<Email />} onClick={handleEmailFieldsClick} /> : null}
        <ActionMenuItem label='Landing Pages' start={<Browser />} onClick={handleLandingPageClick} />
        <ActionMenuItem label='Trackable Links' start={<Link />} onClick={handleTrackableLinksClick} />
        {contentOptions.voiceDrops ? <ActionMenuItem label='Voice Drops' start={<Microphone />} onClick={handleVoiceDropsClick} /> : null}
        <ActionMenuItem label='Your Contact Card' start={<PictureFrame />} onClick={onVcardSelect} />
      </ActionMenu>
      <DynamicFieldsDialog ref={dynamicFieldsDialogRef} onSelect={onDynamicFieldSelect} />
      <ListSelectAction
        ref={selectAttachmentActionRef}
        list={AttachmentList}
        queryOverrides={{ requireFile: true }}
        service={getAttachmentService}
        title='Select Attachment'
        onSelect={onAttachmentSelect}
      />
      <ListSelectAction
        ref={selectConfigurableFormActionRef}
        list={ConfigurableFormList}
        service={getConfigurableFormService}
        title='Select Configurable Form'
        onSelect={onConfigurableFormSelect}
      />
      <ListSelectAction
        ref={selectEmailActionRef}
        list={EmailList}
        queryOverrides={{ requireUnlayerDesign: true }}
        service={getTemplateService}
        title='Select Email'
        onSelect={onEmailSelect}
      />
      <ListSelectAction
        ref={selectLandingPageActionRef}
        list={LandingPageList}
        queryOverrides={{ requireUnlayerDesign: true }}
        service={getLandingPageService}
        title='Select Landing Page'
        onSelect={onLandingPageSelect}
      />
      <ListSelectAction
        ref={selectTrackableLinkActionRef}
        list={TrackableLinkList}
        service={getTrackableLinkService}
        title='Select Trackable Link'
        onSelect={onTrackableLinkSelect}
      />
      <ListSelectAction
        ref={selectVoiceDropLinkActionRef}
        list={VoiceDropList}
        queryOverrides={{ requireMedia: true }}
        service={getTemplateService}
        title='Select Voice Drop'
        onSelect={onVoiceDropSelect}
      />
    </>
  )
})

MessageBarActionMenu.displayName = 'MessageBarActionMenu'
MessageBarActionMenu.propTypes = propTypes
MessageBarActionMenu.defaultProps = defaultProps

export default MessageBarActionMenu
