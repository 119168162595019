import PropType from 'prop-types'

import { NewWindow } from '../../svg/icons'

ExternalLink.propTypes = {
  href: PropType.string.isRequired,
  label: PropType.oneOfType([
    PropType.string,
    PropType.node
  ]).isRequired,
  className: PropType.string,
  showIcon: PropType.bool
}

ExternalLink.defaultProps = {
  className: null,
  showIcon: false
}

function ExternalLink ({ className, href, label, showIcon }) {
  return (
    <a
      className={className}
      href={href}
      rel='noopener noreferrer'
      target='_blank'
    >
      {label}
      {!!showIcon && (
        <>
          {' '}
          <NewWindow className='h-3 w-3 inline' />
        </>
      )}
    </a>
  )
}

export default ExternalLink
