import PropType from 'prop-types'

// Space Members are added via contacts but then referenced via user ids
// Their first/last name will be the value in their contacts versus what the user has as their first/last name

export default {
  _id: PropType.string, // this is a user id
  firstName: PropType.string,
  lastName: PropType.string,
  phoneNumber: PropType.string,
  search: PropType.string
}
