import PropType from 'prop-types'
import { forwardRef, useImperativeHandle } from 'react'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import logger from '../../../lib/logger'
import messageShape from '../../../prop-types/shapes/message'
import { rateChatMessage } from '../../../services/aiva'
import { success } from '../../banners/Banner'

const propTypes = {
  message: PropType.shape(messageShape).isRequired,
  rating: PropType.oneOf(['helpful', 'unhelpful', 'incorrect']).isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['RateChatMessageAction'] })

const RateChatMessageAction = forwardRef(({ message, rating }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { call: rateChatMessageCall } = useService(rateChatMessage, {
    onReplyOk: () => success('Thanks for your feedback!')
  })

  useImperativeHandle(ref, () => ({
    activate () {
      rateChatMessageCall(message._id, rating)
    }
  }), [message?._id, rateChatMessageCall, rating])

  return null
})

RateChatMessageAction.displayName = 'RateChatMessageAction'
RateChatMessageAction.propTypes = propTypes
RateChatMessageAction.defaultProps = defaultProps

export default RateChatMessageAction
