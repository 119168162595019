import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import { CONTENT_TYPE } from '../../../lib/folder'
import campaignShape from '../../../prop-types/shapes/campaign'
import ActionMenu from '../../action-menu/ActionMenu'
import ActionMenuDivider from '../../action-menu/ActionMenuDivider'
import ActionMenuHeader from '../../action-menu/ActionMenuHeader'
import ActionMenuItem from '../../action-menu/ActionMenuItem'
import DangerousActionMenuItem from '../../action-menu/DangerousActionMenuItem'
import Button from '../../buttons/Button'
import AddToExistingFolderAction from '../../folders/actions/AddToExistingFolderAction'
import CreateFolderAction from '../../folders/actions/CreateFolderAction'

import BulkDeleteAction from './BulkDeleteAction'

const propTypes = {
  selectedItems: PropType.arrayOf(PropType.shape(campaignShape)).isRequired
}

const defaultProps = {
}

const BulkEditActions = ({ selectedItems }) => {
  const deleteActionRef = useRef()
  const addToFolderActionRef = useRef()
  const createFolderActionRef = useRef()

  const handleDeleteAction = useCallback(() => deleteActionRef.current.activate(), [])
  const handleAddToFolderAction = useCallback(() => addToFolderActionRef.current.activate(), [])
  const handleCreateFolderAction = useCallback(() => createFolderActionRef.current.activate(), [])

  const trigger = (
    <Button
      className='text-primary label-sm'
      size='none'
      theme='none'
      variant='none'
    >
      Actions
    </Button>
  )

  return (
    <>
      <ActionMenu
        placement='bottom-end'
        trigger={trigger}
      >
        <ActionMenuHeader label='Actions' />
        <ActionMenuDivider />
        <ActionMenuItem label='Create new folder' onClick={handleCreateFolderAction} />
        <ActionMenuItem label='Move to existing folder' onClick={handleAddToFolderAction} />
        <ActionMenuDivider />
        <DangerousActionMenuItem label='Delete Campaigns' onClick={handleDeleteAction} />
      </ActionMenu>
      <AddToExistingFolderAction ref={addToFolderActionRef} contentType={CONTENT_TYPE.campaign} selectedItems={selectedItems} />
      <CreateFolderAction ref={createFolderActionRef} contentType={CONTENT_TYPE.campaign} selectedItems={selectedItems} />
      <BulkDeleteAction ref={deleteActionRef} campaigns={selectedItems} />
    </>
  )
}

BulkEditActions.displayName = 'BulkEditActions'
BulkEditActions.propTypes = propTypes
BulkEditActions.defaultProps = defaultProps

export default BulkEditActions
