import { useCallback, useRef, useState } from 'react'

import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import SpaceList from '../../components/spaces/SpaceList'

import ListHeaderMore from './list/ListHeaderMore'

const propTypes = {}

const defaultProps = {}

const SpacesList = () => {
  const listRef = useRef()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<ListHeaderMore listRef={listRef} />}
        title='Spaces'
      />
      <PanelContent>
        <SpaceList
          ref={listRef}
          savedStateKey='spaces'
          type='nav'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

SpacesList.displayName = 'SpacesList'
SpacesList.propTypes = propTypes
SpacesList.defaultProps = defaultProps

export default SpacesList
