import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Banner, { warning } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import BlockingDialog from '../../components/dialog/BlockingDialog'
import Form from '../../components/forms/Form'
import TermsLink from '../../components/links/TermsLink'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import Well from '../../components/wells/Well'
import { toCreditOptions } from '../../helpers/credits'
import useCurrentUser from '../../hooks/useCurrentUser'
import useNavigateInStack from '../../hooks/useNavigateInStack'
import useService from '../../hooks/useService'
import { planCanBuyCredits } from '../../lib/plans'
import { create as createCreditService, credit as creditService } from '../../services/credit'

import validationSuite from './BuyCredits.validation'

const propTypes = {}

const defaultProps = {}

const BuyCredits = () => {
  const navigateInStack = useNavigateInStack()
  const currentUser = useCurrentUser()
  const [credits, setCredits] = useState([])
  const formRef = useRef()
  const blockingDialogRef1 = useRef()
  const handleReplyOk = useCallback((result) => { setCredits(toCreditOptions(result?.json)) }, [])
  const handleCreditOk = useCallback(() => {
    formRef.current.reset()
    blockingDialogRef1.current.open()
  }, [])
  const handleCreditReply = useCallback(() => {
    formRef.current.updateSubmitting(false)
  }, [])
  const { call: fetchCredit } = useService(creditService, { onReplyOk: handleReplyOk })
  const { call: createCredit } = useService(createCreditService, { onReplyOk: handleCreditOk, onReply: handleCreditReply })

  useEffect(() => {
    fetchCredit()
  }, [fetchCredit])

  if (!planCanBuyCredits({ id: currentUser.subscriptionPlan })) {
    warning('Your current subscription plan does not allow credit purchases. Consider upgrading.')
    navigateInStack('/subscription/change-subscription', { replace: true })
  }

  const onSubmitHandler = (data) => createCredit(data)
  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='buyCreditsForm' title='Purchase' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Buy Credits'
      />
      <PanelContent className='p-5'>
        <Well className='mb-5'>
          <p className='mb-2'>
            <strong className='block'>Never Run Low Again!</strong>
            {' '}
            Tired of credit shortages? Upgrade for more credits at each renewal, securing a lower per-credit price!
          </p>
          <Link className='text-primary' to='/subscription/change-subscription'>
            Upgrade Now
          </Link>
        </Well>
        <p className='mb-4'>
          Project Broadcast allows you to purchase extra credits when you need a bit more. Be aware, when you run out of credits your messages are not sent.
        </p>
        <Form
          ref={formRef}
          defaultValues={{}}
          formControls={[
            {
              label: 'Available Credit Bundles',
              name: 'credits',
              type: 'select',
              options: [{ label: '', value: '' }].concat(credits)
            }
          ]}
          id='buyCreditsForm'
          validationSuite={validationSuite}
          preventMultipleSubmits
          onSubmit={onSubmitHandler}
        />
        <p className='mb-2'>
          By continuing, you understand that Project Broadcast will charge your card on file for the above selection
          and the corresponding credit quantity will be applied to your account. Your credits will be available immediately.
          {' '}
          This does not impact your current subscription.
          {' '}
          <strong>Refunds are not provided.</strong>
          {' '}
          Complete terms can be found
          {' '}
          <TermsLink label='here' />
          {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
        </p>
        <Banner className='mb-2'>
          You may have outstanding unsent messages that were skipped while your credit balance was 0.
          You may want to reschedule some/all of those messages.
        </Banner>
        <Banner className='mb-2'>
          You may have also had App Flows that were automatically stopped due to insufficient credits.
          You'll need to manually re-enable each App Flow you wish to activate.
        </Banner>
        <p className='mb-2' />
        <div className='flex flex-row gap-4 items-center mt-3'>
          <Button
            className='flex-grow md:flex-none'
            form='buyCreditsForm'
            size='sm'
            type='submit'
          >
            Purchase
          </Button>
        </div>
        <div className='p-4 flex flex-row gap-4'>
          <BlockingDialog
            ref={blockingDialogRef1}
            ariaDescription='Notification of existing account'
            buttonVerb='Done'
            title='Success'
            dismissable
            onClick={() => blockingDialogRef1.current.close()}
          >
            <p className='paragraph-sm text-neutral-500 text-center mb-[20px]'>
              Your purchased credits may take a moment to show on your account.  You can view your available credits on the stats
              feature found in the more tab.  From there you can also tap the refresh button so you can see when the credits are
              available.  In most cases, it takes just a few seconds for them to be available.  If you don't see them, try to logout
              of Project Broadcast and login again.
            </p>

          </BlockingDialog>
        </div>
      </PanelContent>
    </Panel>
  )
}

BuyCredits.displayName = 'BuyCredits'
BuyCredits.propTypes = propTypes
BuyCredits.defaultProps = defaultProps

export default BuyCredits
