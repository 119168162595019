import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create } from '../../../services/contact'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormPanelDialog from '../../dialog/FormPanelDialog'

import validationSuite from './CreateAction.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formDialogRef = useRef()
  const navigate = useNavigate()

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    setCurrentInstance('contact', reply.model)
    bus.emit('contactCreated', reply.model)
    navigate(`/contacts/${reply.model._id}`)
  }, [navigate])
  const { call } = useService(create, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleSubmit = useCallback((data) => {
    call(data)
  }, [call])

  const defaultValues = {
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: '',
    email: ''
  }
  const formControls = [
    { label: 'First Name', name: 'firstName', type: 'text' },
    { label: 'Last Name', name: 'lastName', type: 'text' },
    { label: 'Company Name', name: 'company', type: 'text' },
    { label: 'Phone Number', name: 'phoneNumber', type: 'tel' },
    { label: 'Email', name: 'email', type: 'email' }
  ]

  return (
    <FormPanelDialog
      ref={formDialogRef}
      defaultValues={defaultValues}
      description='Create a new contact'
      formControls={formControls}
      title='New Contact'
      validationSuite={validationSuite}
      onSubmit={handleSubmit}
    />
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
