import { useCallback, useState } from 'react'

import ContactList from '../../components/contacts/ContactList'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import PanelLoading from '../../components/panels/PanelLoading'
import useCurrentContest from '../../hooks/useCurrentContest'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['ContestWinnersContacts'] })

const ContestWinnersContacts = () => {
  const { contestId, loading, contest } = useCurrentContest()

  useLogger({ log, lifecycle: false, tags: [contestId] })

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  if (loading || !contest) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader
        count={total}
        start={<PanelHeaderBackButton />}
        subtitle={contest?.name}
        title='Winners'
      />
      <PanelContent>
        <ContactList
          placeholder='Search Winners'
          queryOverrides={{ wonContestId: contestId }}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

ContestWinnersContacts.displayName = 'ContestWinnersContacts'
ContestWinnersContacts.propTypes = propTypes
ContestWinnersContacts.defaultProps = defaultProps

export default ContestWinnersContacts
