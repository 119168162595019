import { compact, values } from 'lodash'
import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import useCurrentListFolderMode from '../../hooks/useCurrentListFolderMode'
import useCurrentStackKey from '../../hooks/useCurrentStackKey'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import sortListItemShape from '../../prop-types/shapes/sortListItem'
import { toggleListFolderModeByStackKey } from '../../store/actions/list'
import InputIcon from '../forms/InputIcon'

import SortButton from './buttons/SortButton'
import SearchBarButton from './SearchBarButton'
import SummaryInput from './SummaryInput'
import TermInput from './TermInput'

const propTypes = {
  end: PropType.node,
  generateSummary: PropType.func,
  // func should return an array of strings
  hasAdvancedSearchDataCheck: PropType.func,
  placeholder: PropType.string,
  search: PropType.object, // eslint-disable-line react/forbid-prop-types
  showFolderToggle: PropType.bool,
  sortList: PropType.arrayOf(PropType.shape(sortListItemShape)),
  onAdvancedSearchClick: PropType.func,
  onChange: PropType.func,
  onClear: PropType.func,
  onTermChange: PropType.func
}

const defaultProps = {
  end: null,
  generateSummary: null,
  hasAdvancedSearchDataCheck: null,
  onAdvancedSearchClick: undefined,
  onChange: undefined,
  onClear: undefined,
  onTermChange: undefined,
  placeholder: undefined,
  search: {},
  showFolderToggle: false,
  sortList: undefined
}

const log = logger({ enabled: true, tags: ['SearchBar'] })

const SearchBar = forwardRef(({
  end,
  generateSummary,
  hasAdvancedSearchDataCheck,
  search: initialSearch,
  showFolderToggle,
  sortList,
  placeholder,
  onAdvancedSearchClick,
  onChange,
  onClear,
  onTermChange
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [] })

  const stackKey = useCurrentStackKey()
  const currentListFolderMode = useCurrentListFolderMode()

  // initial/default sort can come from 2 places:
  // 1) the initialSearch (ex: defaultSearch={{ sortKey: 'firstName' }} passed down to a `List` or a list state being restored)
  // 2) a `default` key on a `sortList` item
  const initialSort = useMemo(() => {
    if (initialSearch?.sortKey) { return initialSearch.sortKey }
    const defaultSortListItem = sortList?.find((sortListItem) => sortListItem.default)
    if (defaultSortListItem?.key) { return defaultSortListItem.key }
    return sortList?.[0]?.key // just fallback to the first item in the list
  }, [initialSearch, sortList])
  const [search, setSearch] = useState({ term: '', ...initialSearch, sortKey: initialSort })

  const termInputRef = useRef()
  const updateTermInput = useCallback((term) => termInputRef.current?.setTerm(term), [])

  const publicApi = useMemo(() => ({
    clear () {
      const newSearch = { term: '', sortKey: search.sortKey }
      setSearch(newSearch)
      updateTermInput('')
      onChange?.({ ...newSearch })
      onClear?.({ ...newSearch })
    },

    sortBy (newSort) {
      const newSearch = { ...search, sortKey: newSort }
      setSearch(newSearch)
      onChange?.({ ...newSearch })
    },

    update (updatedSearch = {}) {
      const newSearch = { ...updatedSearch, sortKey: search.sortKey }
      setSearch(newSearch)
      if (newSearch.term) {
        updateTermInput(newSearch.term)
      }
      onChange?.({ ...newSearch })
    }
  }), [onChange, onClear, search, updateTermInput])
  useImperativeHandle(ref, () => publicApi, [publicApi])

  const handleTermInputChange = useCallback((term) => {
    onTermChange?.(term)
    publicApi.update({ term })
  }, [onTermChange, publicApi])

  const summaries = useMemo(() => {
    if (generateSummary) { return generateSummary(search) }

    const searchExcludingSort = { ...search, sortKey: undefined } // exclude sort from summary
    const filters = compact(values(searchExcludingSort))
    return [`${filters.length}`, ...filters]
  }, [generateSummary, search])

  const hasAdvancedSearchData = useMemo(() => {
    if (hasAdvancedSearchDataCheck) { return hasAdvancedSearchDataCheck(search) }

    const data = { ...search, sortKey: undefined } // exclude sort from filter calculations
    delete data.term
    const filters = compact(values(data)).flat()
    return filters.length > 0
  }, [hasAdvancedSearchDataCheck, search])

  const handleClearSearch = useCallback(() => publicApi.clear(), [publicApi])
  const handleSortChange = useCallback((newSortKey) => publicApi.sortBy(newSortKey), [publicApi])

  const searchIcon = useMemo(() => {
    return (
      <InputIcon
        className={onAdvancedSearchClick ? 'text-primary' : ''}
        disabled={!onAdvancedSearchClick}
        icon='search'
        onClick={onAdvancedSearchClick}
      />
    )
  }, [onAdvancedSearchClick])

  const handleFolderToggleClick = useCallback(() => { toggleListFolderModeByStackKey(stackKey) }, [stackKey])

  const folderToggle = useMemo(() => {
    if (!showFolderToggle) { return }
    const icon = currentListFolderMode ? 'list' : 'folder'
    return (<SearchBarButton icon={icon} start onClick={handleFolderToggleClick} />)
  }, [currentListFolderMode, handleFolderToggleClick, showFolderToggle])

  return (
    <div className='flex flex-row flex-nowrap bg-neutral-50 border-neutral-200 border-b p-[8px]'>
      {folderToggle}
      {hasAdvancedSearchData
        ? <SummaryInput
            start={searchIcon}
            summaries={summaries}
            onClear={handleClearSearch}
            onClick={onAdvancedSearchClick}
          />
        : (
          <TermInput
            ref={termInputRef}
            placeholder={placeholder}
            start={searchIcon}
            term={search.term}
            onChange={handleTermInputChange}
            onClear={handleClearSearch}
          />
          )}
      {end}
      {sortList ? <SortButton currentSortKey={search.sortKey} sortList={sortList} onChange={handleSortChange} /> : null}
    </div>
  )
})

SearchBar.displayName = 'SearchBar'
SearchBar.propTypes = propTypes
SearchBar.defaultProps = defaultProps

export default SearchBar
