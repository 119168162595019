import { map } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'

import { success } from '../../components/banners/Banner'
import ArchivedContactList from '../../components/contacts/ArchivedContactList'
import ContactList from '../../components/contacts/ContactList'
import ListDialog from '../../components/dialog/ListDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useService from '../../hooks/useService'
import bus from '../../lib/bus'
import { pluralize } from '../../lib/formatters'
import { archive, archiveAll, unarchive, unarchiveAll } from '../../services/contact'

const ArchivedContacts = () => {
  const archivedContactListRef = useRef()
  const addListDialogRef = useRef()
  const removeListDialogRef = useRef()
  //
  const handleReplyOk = useCallback(() => {
    success('Archived Contacts updated')
    bus.emit('archivedContactsUpdated')
  }, [])
  const { call: addAllInQueryCall } = useService(archiveAll, { onReplyOk: handleReplyOk })
  const { call: addSelectedCall } = useService(archive, { onReplyOk: handleReplyOk })
  const { call: removeAllInQueryCall } = useService(unarchiveAll, { onReplyOk: handleReplyOk })
  const { call: removeSelectedCall } = useService(unarchive, { onReplyOk: handleReplyOk })

  const handleAddButtonClick = useCallback(() => { addListDialogRef.current.open() }, [])
  const handleRemoveButtonClick = useCallback(() => { removeListDialogRef.current.open() }, [])

  const handleAddAllInQuery = useCallback((query) => {
    addAllInQueryCall(query)
  }, [addAllInQueryCall])

  const handleAddSelected = useCallback((selected) => {
    addSelectedCall(map(selected, '_id'))
  }, [addSelectedCall])

  const handleRemoveAllInQuery = useCallback((query) => {
    removeAllInQueryCall(query)
  }, [removeAllInQueryCall])

  const handleRemoveSelected = useCallback((selected) => {
    removeSelectedCall(map(selected, '_id'))
  }, [removeSelectedCall])

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])
  const subtitle = useMemo(() => { return pluralize({ count: total, singular: 'Archived Contact', includeCount: true }) }, [total])

  const end = (
    <>
      <PanelHeaderButton icon='remove' onClick={handleRemoveButtonClick} />
      <PanelHeaderButton icon='add' onClick={handleAddButtonClick} />
    </>
  )

  return (
    <Panel>
      <PanelHeader
        end={end}
        start={<PanelHeaderBackButton />}
        subtitle={subtitle}
        title='Archived Contacts'
      />
      <PanelContent>
        <ArchivedContactList
          ref={archivedContactListRef}
          type='list'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
      <ListDialog
        ref={removeListDialogRef}
        list={ArchivedContactList}
        subtitle='Archived'
        title='Remove Contacts'
        type='remove'
        onSubmitAllInQuery={handleRemoveAllInQuery}
        onSubmitSelected={handleRemoveSelected}
      />
      <ListDialog
        ref={addListDialogRef}
        list={ContactList}
        subtitle='Archived'
        title='Add Contacts'
        type='add'
        onSubmitAllInQuery={handleAddAllInQuery}
        onSubmitSelected={handleAddSelected}
      />
    </Panel>
  )
}

ArchivedContacts.displayName = 'ArchivedContacts'

export default ArchivedContacts
