import PropType from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'

import { generateAdvancedSearchFromSearchData, generateFormControls, generateSearchFromAdvancedSearchFormData, generateSummaryFromAdvancedSearchFormData } from '../../helpers/userListSearchBar'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import AdvancedSearchDialog from '../search-bar/AdvancedSearchDialog'
import SearchBar from '../search-bar/SearchBar'

const propTypes = {
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  // NOTE: search only works for data that does not get transformed
  search: PropType.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  placeholder: null,
  search: null
}

const log = logger({ enabled: true, tags: ['UsersSearchBar'] })

const UsersSearchBar = ({
  search: initialSearch,
  onChange,
  placeholder
}) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const searchBarRef = useRef()
  const advancedSearchDialogRef = useRef()

  const [advancedSearchData, setAdvancedSearchData] = useState(generateAdvancedSearchFromSearchData(initialSearch))

  const handleSearchBarChange = useCallback((newSearch) => { onChange(newSearch) }, [onChange])
  const handleSearchBarTermChange = useCallback((term) => { setAdvancedSearchData({ ...advancedSearchData, term }) }, [advancedSearchData])
  const handleSearchBarClear = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])
  const handleSearchBarReset = useCallback((/* newSearch */) => { setAdvancedSearchData({}) }, [])

  const handleAdvancedSearchClick = useCallback(() => advancedSearchDialogRef.current.open(), [])
  const handleAdvancedSearchDialogSubmit = useCallback((data) => {
    setAdvancedSearchData(data)
    const search = generateSearchFromAdvancedSearchFormData(data)
    searchBarRef.current.update(search)
  }, [])

  const generateSummary = useCallback(() => {
    return generateSummaryFromAdvancedSearchFormData(advancedSearchData)
  }, [advancedSearchData])

  const formControls = useMemo(() => { return generateFormControls() }, [])

  return (
    <>
      <SearchBar
        ref={searchBarRef}
        generateSummary={generateSummary}
        placeholder={placeholder}
        search={advancedSearchData}
        onAdvancedSearchClick={handleAdvancedSearchClick}
        onChange={handleSearchBarChange}
        onClear={handleSearchBarClear}
        onReset={handleSearchBarReset}
        onTermChange={handleSearchBarTermChange}
      />
      <AdvancedSearchDialog
        ref={advancedSearchDialogRef}
        defaultValues={advancedSearchData}
        formControls={formControls}
        onSubmit={handleAdvancedSearchDialogSubmit}
      />
    </>

  )
}

UsersSearchBar.displayName = 'UsersSearchBar'
UsersSearchBar.propTypes = propTypes
UsersSearchBar.defaultProps = defaultProps

export default UsersSearchBar
