import { useCallback } from 'react'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import { updateIncomingMessageEmailNotifications as updateIncomingMessageEmailNotificationsService } from '../../services/user'
import { update as updateUserAction } from '../../store/actions/currentUser'

const IncomingMessageEmailNotifications = () => {
  const currentUser = useCurrentUser()

  const handleReplyOkay = useCallback(() => {
    notify('Incoming Message Email Notifications updated successfully.')
  }, [])
  const { call: updateIncomingMessageEmailNotifications } = useServiceAndAction(updateIncomingMessageEmailNotificationsService, updateUserAction, { onReplyOk: handleReplyOkay })

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='incomingMessageEmailNotificationsForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Incoming Message Email Notifications'
      />
      <PanelContent className='p-5'>
        <Form
          defaultValues={{
            incomingMessageEmailNotifications: currentUser.incomingMessageEmailNotifications
          }}
          formControls={[
            {
              type: 'checkbox',
              name: 'incomingMessageEmailNotifications',
              label: 'When enabled, you will receive an email notification for each incoming message.',
              title: 'Enable Incoming Message Email Notification'
            }
          ]}
          id='incomingMessageEmailNotificationsForm'
          onSubmit={updateIncomingMessageEmailNotifications}
        />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='incomingMessageEmailNotificationsForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

IncomingMessageEmailNotifications.displayName = 'IncomingMessageEmailNotifications'

export default IncomingMessageEmailNotifications
