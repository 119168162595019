import PropType from 'prop-types'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import contactShape from '../../prop-types/shapes/contact'
import { MarkColor } from '../../svg/branding'
import { Block, Tag } from '../../svg/icons'
import Avatar from '../avatar/Avatar'
import PotentialStopMessageBadge from '../chats/chat-list-item-content/PotentialStopMessageBadge'
import Pill from '../pill/Pill'

const propTypes = {
  item: PropType.shape(contactShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: true, tags: ['ContactListItemContent'] })

const ContactListItemContent = ({ item: contact }) => {
  useLogger({ log, lifecycle: true, tags: [contact._id] })

  return (
    <>
      <Avatar contactOrUser={contact} size='sm' />
      <div className='flex flex-col ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap text-black'>
          {contact.isPBUser ? <MarkColor className='shrink-0 mt-[2px]' height='16' width='16' /> : null}
          <div className='label-sm-strong break-words max-w-full overflow-auto'>
            {contact.formattedName}
          </div>
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>{contact.formattedPhoneNumber}</div>
        <div className='flex flex-row items-center label-xs text-neutral-500 mt-[3px] gap-1'>
          <Tag className='text-black shrink-0 grow-0 h-[1rem] w-[1rem]' />
          <Pill className='mx-1' value={contact?.tags?.length} />
          <div className='line-clamp-1 break-words'>{contact?.tags?.join(', ')}</div>
        </div>
        {contact?.doNotMessage
          ? (
            <div className='flex flex-row flex-nowrap items-center pt-1'>
              <Block className='text-neutral-500' height='16' width='16' />
              <span className='pl-1 label-xs text-neutral-500'>
                Do Not Message
              </span>
            </div>
            )
          : contact?.potentialStopMessage
            ? <PotentialStopMessageBadge className='mt-1' />
            : null}
        {contact?.doNotEmail
          ? (
            <div className='flex flex-row flex-nowrap items-center pt-1'>
              <Block className='text-neutral-500' height='16' width='16' />
              <span className='pl-1 label-xs text-neutral-500'>
                Do Not Email
              </span>
            </div>
            )
          : null}
      </div>
    </>
  )
}

ContactListItemContent.displayName = 'ContactListItemContent'
ContactListItemContent.propTypes = propTypes
ContactListItemContent.defaultProps = defaultProps

export default ContactListItemContent
