import PropType from 'prop-types'

import { campaignTypes } from '../helpers/campaign'
import paging from '../prop-types/shapes/paging'

export const searchPropTypes = {
  paging: PropType.shape(paging).isRequired,
  term: PropType.string
}

export const createPropTypes = {
  name: PropType.string,
  type: PropType.oneOf(campaignTypes)
}

export const updatePropTypes = {
  name: PropType.string,
  removeOnReply: PropType.bool
}
