import * as React from "react";
import { memo } from "react";
const SvgDownloads = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Downloads_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 21h13.188C21.84 21 24 18.768 24 16.032a5.005 5.005 0 0 0-2.04-4.044c.132-.456.204-.936.204-1.416 0-2.736-2.16-4.956-4.8-4.956-.192 0-.384.012-.576.024-1.416-2.352-3.924-3.84-6.684-3.84-4.332 0-7.86 3.66-7.872 8.148C.828 11.112 0 12.876 0 14.796 0 18.216 2.688 21 6 21Zm13.188-1.8H6c-2.316 0-4.2-1.98-4.2-4.404 0-1.62.84-3.048 2.076-3.816.3-.168.156-.348.156-1.008 0-3.516 2.724-6.372 6.072-6.372 2.532 0 4.704 1.632 5.616 3.948.072.192.204.24.384.144.384-.18.804-.276 1.26-.276 1.656 0 3 1.404 3 3.156 0 .78-.264 1.488-.708 2.04-.144.18-.108.276.12.324 1.38.276 2.424 1.56 2.424 3.096 0 1.752-1.344 3.168-3.012 3.168ZM11.7 16.8a.927.927 0 0 0 .636-.276l3.6-3.588a.892.892 0 0 0 .264-.624.89.89 0 0 0-.264-.636.93.93 0 0 0-.648-.276.892.892 0 0 0-.624.264L12.6 13.716V8.7a.898.898 0 0 0-.252-.636.906.906 0 0 0-.648-.264.93.93 0 0 0-.636.264.859.859 0 0 0-.264.636v5.016l-2.076-2.052A.892.892 0 0 0 8.1 11.4c-.24 0-.468.12-.66.3a.814.814 0 0 0-.24.6.87.87 0 0 0 .264.636l3.6 3.588c.18.168.408.276.636.276Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Downloads_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgDownloads);
export default Memo;
