import PropType from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import { stateKeyFromContentType } from '../../../lib/folder'
import templateShape from '../../../prop-types/shapes/template'
import { clone as cloneService } from '../../../services/template'
import { success } from '../../banners/Banner'

const propTypes = {
  template: PropType.shape(templateShape).isRequired,
  onSuccess: PropType.func
}

const defaultProps = {
  onSuccess: null
}

const CloneAction = forwardRef(({ template, onSuccess }, ref) => {
  const handleReplyOk = useCallback(() => {
    const stateKey = stateKeyFromContentType(template.contentType)
    success(`${template.name} was cloned`)
    bus.emit(`${stateKey}Cloned`, template)
    onSuccess?.()
  }, [template, onSuccess])
  const { call: cloneTemplateCall } = useService(cloneService, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { cloneTemplateCall(template._id) }
  }), [template, cloneTemplateCall])
})

CloneAction.displayName = 'CloneAction'
CloneAction.propTypes = propTypes
CloneAction.defaultProps = defaultProps

export default CloneAction
