import PropType from 'prop-types'
import { useOutlet } from 'react-router-dom'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'

const propTypes = {
  component: PropType.node.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['OutletOrComponent'] })

const OutletOrComponent = ({ component }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const outlet = useOutlet()

  return (outlet || component)
}

OutletOrComponent.displayName = 'OutletOrComponent'
OutletOrComponent.propTypes = propTypes
OutletOrComponent.defaultProps = defaultProps

export default OutletOrComponent
