import { CardElement, useElements } from '@stripe/react-stripe-js'
import PropType from 'prop-types'
import { useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import {
  error as colorsError,
  neutral as colorsNeutral
} from '../../../tailwindcss/colors'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputLabel from '../InputLabel'

const propTypes = {
  name: PropType.string.isRequired,
  error: PropType.string,
  hint: PropType.string,
  label: PropType.string
}

const defaultProps = {
  error: null,
  hint: null,
  label: null
}

const log = logger({ enabled: false, tags: ['StripeCardElement'] })

const cardElementOptions = {
  style: {
    base: {
      iconColor: colorsNeutral[600],
      color: colorsNeutral[600],
      fontWeight: '400',
      fontFamily: process.env.REACT_APP_FONT_FAMILY_SANS,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: colorsNeutral[600]
      },
      '::placeholder': {
        color: colorsNeutral[400]
      }
    },
    invalid: {
      iconColor: colorsError,
      color: colorsError
    }
  }
}

const StripeCardElement = ({ name, hint, label, error }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  const lastStripeChangeError = useRef(null)
  const elements = useElements()

  error = error || useFormMethods?.formState?.errors?.[name]?.message
  useFormMethods?.register(name)

  const validateStripeInput = useCallback(() => {
    let errorMessage = null
    if (lastStripeChangeError.current) {
      errorMessage = lastStripeChangeError.current
    } else if (!elements?.getElement('card')._complete) {
      errorMessage = 'Your card details are incomplete.'
    }
    useFormMethods?.setCustomError(name, { message: errorMessage })
  }, [elements, name, useFormMethods])

  const handleStripeChange = useCallback(({ error: stripeError }) => {
    lastStripeChangeError.current = stripeError?.message
    validateStripeInput()
  }, [validateStripeInput])

  return (
    <>
      <InputLabel text={label} />
      <div className='p-3 border border-neutral-300 rounded-md w-full bg-white'>
        <CardElement
          options={cardElementOptions}
          onBlur={validateStripeInput}
          onChange={handleStripeChange}
        />
      </div>
      <InputError message={error} />
      <InputHint message={hint} />
    </>
  )
}

StripeCardElement.displayName = 'StripeCardElement'
StripeCardElement.propTypes = propTypes
StripeCardElement.defaultProps = defaultProps

export default StripeCardElement
