import { capitalize } from 'lodash'

// converts server-ready object back to an advanced search object :(
export function generateAdvancedSearchFromSearchData (searchData) {
  const search = {}

  if (!searchData) { return search }

  const fieldsToCopyAsIs = ['term', 'filter', 'potentialStopMessage']
  fieldsToCopyAsIs.forEach((field) => { search[field] = searchData[field] })

  search.tags = (searchData.tags || []).map((name) => ({ name }))

  return search
}

// converts advanced search form data to a server-ready object for the search api
export function generateSearchFromAdvancedSearchFormData (advancedSearchFormData) {
  const search = {}

  if (!advancedSearchFormData) { return search }

  const fieldsToCopyAsIs = ['term', 'filter']
  fieldsToCopyAsIs.forEach((field) => { search[field] = advancedSearchFormData[field] })

  // some fields are expected to be excluded when not checked (instead of passing `false`)
  const fieldsToIncludeIfTrue = ['potentialStopMessage']
  fieldsToIncludeIfTrue.forEach((name) => {
    if (advancedSearchFormData[name] === true) {
      search[name] = true
    }
  })

  search.tags = (advancedSearchFormData.tags || []).filter((tag) => !!tag?.name).map(({ name }) => name)

  return search
}

export function generateSummaryFromAdvancedSearchFormData (advancedSearchFormData) {
  let filterCount = 0
  const summaries = []

  if (!advancedSearchFormData) { return summaries }

  if (advancedSearchFormData.term) {
    filterCount++
    summaries.push(advancedSearchFormData.term)
  }

  if (advancedSearchFormData.filter) {
    filterCount++
    summaries.push(capitalize(advancedSearchFormData.filter))
  }

  if (advancedSearchFormData.tags?.length) {
    filterCount++
    summaries.push(advancedSearchFormData.tags.filter((tag) => !!tag?.name).map(({ name }) => name).join(' or '))
  }

  const checkboxFields = [
    ['potentialStopMessage', 'Potential Opt-out']
  ]
  checkboxFields.forEach(([field, summary]) => {
    if (advancedSearchFormData[field] === true) {
      filterCount++
      summaries.push(summary)
    }
  })

  if (filterCount) { summaries.unshift(`${filterCount}`) }
  return summaries
}
