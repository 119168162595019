import PropType from 'prop-types'
import { forwardRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search } from '../../services/space-member'
import List from '../list/List'

import SpaceMemberListItemContent from './SpaceMemberListItemContent'

const propTypes = {
  spaceId: PropType.string.isRequired
}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['SpaceMemberList'] })

const SpaceMemberList = forwardRef(({ spaceId, ...listProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <List
      ref={ref}
      ListItemContent={SpaceMemberListItemContent}
      queryOverrides={{ spaceId }}
      resultsKey='members'
      searchService={search}
      {...listProps}
    />
  )
})

SpaceMemberList.displayName = 'SpaceContactList'
SpaceMemberList.propTypes = propTypes
SpaceMemberList.defaultProps = defaultProps

export default SpaceMemberList
