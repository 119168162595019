import { omit, pick } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { notify } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelHeaderButton from '../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLogger from '../../hooks/useLogger'
import useServiceAndAction from '../../hooks/useServiceAndAction'
import logger from '../../lib/logger'
import { updateProfile as updateProfileService } from '../../services/user'
import { update as updateAction } from '../../store/actions/currentUser'

import validationSuite from './Profile.validations'

const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'stateProvince', 'postal']

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: true, tags: ['Profile'] })

const Profile = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const navigate = useNavigate()

  const handleReplyOk = useCallback(() => {
    notify('Profile updated successfully.')
    if (doLogout.current) {
      navigate('/logout', { replace: true })
    }
  }, [navigate])
  const { call: updateProfile } = useServiceAndAction(updateProfileService, updateAction, { onReplyOk: handleReplyOk })

  const currentUser = useCurrentUser()
  const formRef = useRef()
  const doLogout = useRef(false)

  const formProps = useMemo(() => ({
    id: 'profileForm',
    defaultValues: {
      email: currentUser.auth.username,
      ...pick(currentUser, 'firstName', 'lastName', 'contactNumber'),
      ...pick(currentUser.address, ADDRESS_FIELDS)
    },
    formControls: [
      {
        autoFocus: true,
        type: 'text',
        name: 'firstName',
        label: 'First Name'
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'Last Name'
      },
      {
        type: 'email',
        name: 'email',
        label: 'Email Address',
        hint: 'After updating your email above, you will need to login again using your updated email.'
      }
    ],
    onSubmit: function (data) {
      const updateData = {
        ...omit(data, ADDRESS_FIELDS),
        address: pick(data, ADDRESS_FIELDS)
      }
      doLogout.current = (updateData.email !== currentUser.auth.username)
      updateProfile(updateData)
    },
    validationSuite
  }), [currentUser, updateProfile])

  return (
    <Panel>
      <PanelHeader
        end={<PanelHeaderButton form='profileForm' title='Save' type='submit' />}
        start={<PanelHeaderBackButton />}
        title='Profile'
      />
      <PanelContent className='p-4'>
        <Form ref={formRef} {...formProps} />
        <div className='flex flex-row gap-4 items-center'>
          <Button
            className='flex-grow md:flex-none'
            form='profileForm'
            size='sm'
            type='submit'
          >
            Save
          </Button>
        </div>
      </PanelContent>
    </Panel>
  )
}

Profile.displayName = 'Profile'
Profile.propTypes = propTypes
Profile.defaultProps = defaultProps

export default Profile
