import * as React from "react";
import { memo } from "react";
const SvgCalendarAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 0a1 1 0 0 1 1 1v1h10V1a1 1 0 1 1 2 0v1h2a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h2V1a1 1 0 0 1 1-1Zm11 6a1 1 0 1 0 2 0V4h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h2v2a1 1 0 0 0 2 0V4h10v2Zm-5 3a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgCalendarAdd);
export default Memo;
