import PropType from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

const propTypes = {
  onLoad: PropType.func
}

const defaultProps = {
  onLoad: undefined
}

const log = logger({ enabled: true, tags: ['UnlayerDesignPreviewIframe'] })

const UnlayerDesignPreviewIframe = forwardRef(({ onLoad }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const iframeRef = useRef()

  const [loaded, setLoaded] = useState(false)

  useImperativeHandle(ref, () => ({
    update (body) {
      iframeRef.current.contentDocument.body.innerHTML = body
      iframeRef.current.style.height = `${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`
      setLoaded(true)
    }
  }))

  useEffect(() => {
    let interval
    if (loaded) {
      interval = setInterval(() => {
        iframeRef.current.style.height = `${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`
      }, 250)
    }
    return () => { clearInterval(interval) }
  }, [loaded])

  // async setting src so react can trigger onLoad
  useEffect(() => { iframeRef.current.src = 'about:blank' }, [])

  const handleIframeLoad = useCallback(() => { onLoad?.() }, [onLoad])

  return (
    // eslint-disable-next-line react/iframe-missing-sandbox
    <iframe
      ref={iframeRef}
      className='w-full'
      scrolling='no'
      style={{ pointerEvents: 'none' }}
      onLoad={handleIframeLoad}
    />
  )
})

UnlayerDesignPreviewIframe.displayName = 'UnlayerDesignPreviewIframe'
UnlayerDesignPreviewIframe.propTypes = propTypes
UnlayerDesignPreviewIframe.defaultProps = defaultProps

export default UnlayerDesignPreviewIframe
