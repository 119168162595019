import { useCallback } from 'react'

import DetailSection from '../../../components/detail-sections/DetailSection'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import useCurrentGoalMilestone from '../../../hooks/useCurrentGoalMilestone'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { update as updateMilestoneService } from '../../../services/admin/goal-milestone'

import MilestoneMessageDetailSection from './detail/MilestoneMessageDetail'
import RewardCreditsDetail from './detail/RewardCreditsDetail'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['GoalMilestoneDetail'] })

const GoalMilestoneDetail = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const { goalMilestone: milestone, loading, setGoalMilestone: setMilestone } = useCurrentGoalMilestone()

  const updateMilestoneServiceWrapper = (_ignored, updateData) => {
    return updateMilestoneService(milestone.goal, milestone._id, updateData)
  }

  const handleMilestoneUpdated = useCallback((updateReply) => {
    setMilestone(updateReply.model)
  }, [setMilestone])

  if (!milestone || loading) { return <PanelLoading /> }

  return (
    <Panel>
      <PanelHeader start={<PanelHeaderBackButton />} title={milestone.name} />
      <PanelContent className='p-5'>
        <MilestoneMessageDetailSection
          description={`This message is sent to the user ${milestone.introDelayDescription} after the milestone comes "in-scope".`}
          messageType='intro'
          milestone={milestone}
          service={updateMilestoneServiceWrapper}
          title='Intro Message'
          showEditIcon
          onChange={handleMilestoneUpdated}
        />
        <DetailSection
          description='Nudges propel the user through the milestone while in-scope. This milestone remains in-scope until:'
          title='Nudge Behavior'
        >
          <div className='select-text'>
            {milestone.nudgeBehaviorDescription}
            {milestone.nudge.maxRepeat !== 0 ? ` (nudge ${milestone.nudgeDelayDescription})` : null}
          </div>
        </DetailSection>
        <MilestoneMessageDetailSection
          description='For nudge behavior that blocks or repeats, a message will be sent to the user to help prompt them to action.'
          messageType='nudge'
          milestone={milestone}
          service={updateMilestoneServiceWrapper}
          showEditIcon={milestone.nudge.maxRepeat !== 0}
          title='Nudge Message'
          onChange={handleMilestoneUpdated}
        />
        <DetailSection
          description={`The coded-trigger that signals completion of this milestone. See events/milestone-triggers/${milestone.trigger}.js`}
          title='Trigger'
        >
          <div className='select-text'>
            {milestone.trigger}
          </div>
        </DetailSection>
        <MilestoneMessageDetailSection
          description='This message is sent to user upon completion.'
          messageType='reward'
          milestone={milestone}
          service={updateMilestoneServiceWrapper}
          title='Reward Message'
          showEditIcon
          onChange={handleMilestoneUpdated}
        />
        <RewardCreditsDetail
          milestone={milestone}
          service={updateMilestoneServiceWrapper}
          onChange={handleMilestoneUpdated}
        />
      </PanelContent>
    </Panel>
  )
}

GoalMilestoneDetail.displayName = 'GoalMilestoneDetail'
GoalMilestoneDetail.propTypes = propTypes
GoalMilestoneDetail.defaultProps = defaultProps

export default GoalMilestoneDetail
