import { omit } from 'lodash'
import PropType from 'prop-types'

import { searchPropTypes as contactSearchPropTypes } from './contact.prop-types'

export const searchPropTypes = {
  tagName: PropType.string.isRequired,
  ...contactSearchPropTypes
}

export const addRemoveSelectedPropTypes = {
  tagName: PropType.string.isRequired,
  contactIds: PropType.arrayOf(PropType.string).isRequired
}

export const addRemoveAllInQueryPropTypes = {
  tagName: PropType.string.isRequired,
  ...omit(contactSearchPropTypes, 'paging')
}
