import * as React from "react";
import { memo } from "react";
const SvgChevronRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.921 11.069-6.678-6.68a1.296 1.296 0 0 0-1.853 0c-.52.52-.52 1.347 0 1.854l5.759 5.759-5.76 5.758c-.519.52-.519 1.347 0 1.853.254.254.6.387.934.387.333 0 .68-.133.933-.387l6.679-6.678c.52-.52.52-1.347 0-1.853l-.014-.014Z"
    />
  </svg>
);
const Memo = memo(SvgChevronRight);
export default Memo;
