import PropType from 'prop-types'

export const createPropTypes = {
  name: PropType.string.isRequired
}

export const updatePropTypes = {
  name: PropType.string.isRequired
}

export const updateNotificationSettingsPropTypes = {
  disabled: PropType.bool.isRequired
}
