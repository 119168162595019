import PropType from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'

import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import { get as getTemplateService } from '../../../../services/template'
import { ChatText } from '../../../../svg/icons'
import Button from '../../../buttons/Button'
import ListSelectAction from '../../../dialog/ListSelectAction'
import MessageTemplateList from '../../../message-templates/MessageTemplateList'

const propTypes = {
  onTemplateSelect: PropType.func
}

const defaultProps = {
  onTemplateSelect: undefined
}

const log = logger({ enabled: false, tags: ['InsertTemplateButton'] })

const InsertTemplateButton = forwardRef(({ onTemplateSelect, ...buttonProps }, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const selectTemplatesActionRef = useRef()

  const handleClick = useCallback(() => selectTemplatesActionRef.current.activate(), [])

  return (
    <>
      <Button
        ref={ref}
        className='min-w-[32px]'
        icon={<ChatText />}
        size='sm'
        theme='none'
        variant='none'
        onClick={handleClick}
        {...buttonProps}
      >
        Insert Template
      </Button>
      <ListSelectAction
        ref={selectTemplatesActionRef}
        list={MessageTemplateList}
        service={getTemplateService}
        title='Select Message Template'
        onSelect={onTemplateSelect}
      />
    </>
  )
})

InsertTemplateButton.displayName = 'InsertTemplateButton'
InsertTemplateButton.propTypes = propTypes
InsertTemplateButton.defaultProps = defaultProps

export default InsertTemplateButton
