import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'
import template from '../../prop-types/shapes/template'
import { Warning } from '../../svg/icons'
import {
  Email,
  MessageTemplate,
  // TODO: Can we drop the fallback to a generic Template icon?
  //       Maybe after initial deploy and running the migration script
  Template,
  VoiceDrop
} from '../../svg/types'
import MessageFragment from '../message/MessageFragment'

const propTypes = {
  item: PropType.shape(template)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['TemplateListItemContent'] })

const contentTypeIconMap = {
  [CONTENT_TYPE.email]: Email,
  [CONTENT_TYPE.messageTemplate]: MessageTemplate,
  [CONTENT_TYPE.voiceDrop]: VoiceDrop
}

const TemplateListItemContent = ({ item: template }) => {
  useLogger({ log, lifecycle: false, tags: [template?._id] })

  const Icon = useMemo(() => (contentTypeIconMap[template.contentType] || Template), [template.contentType])

  return (
    <>
      <Icon className='shrink-0 grow-0 h-[1.5rem] w-[1.5rem]' />
      <div className='flex flex-col ml-[12px] pt-[1px] max-w-full flex-grow overflow-auto'>
        <div className='label-sm-strong items-center text-black break-words'>
          {template.name}
        </div>
        <div className='label-sm text-neutral-500'>
          <MessageFragment message={template} />
        </div>
        {template?.transactional
          ? (
            <div className='flex flex-row flex-nowrap items-center pt-1'>
              <Warning className='text-neutral-500' height='16' width='16' />
              <span className='pl-1 label-xs text-neutral-500'>
                Transactional Email
              </span>
            </div>
            )
          : null}
      </div>
    </>
  )
}

TemplateListItemContent.displayName = 'TemplateListItemContent'
TemplateListItemContent.propTypes = propTypes
TemplateListItemContent.defaultProps = defaultProps

export default TemplateListItemContent
