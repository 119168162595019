import PropType from 'prop-types'

import { usePanel } from '../../contexts/PanelContext'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import Panel from './Panel'
import PanelHeaderBackButton from './panel-header/PanelHeaderBackButton'
import PanelContent from './PanelContent'
import PanelHeader from './PanelHeader'

const propTypes = {
  start: PropType.element,
  title: PropType.string
}

const defaultProps = {
  start: null,
  title: 'Coming Soon'
}

const log = logger({ enabled: false, tags: ['PanelUnderConstruction'] })

const PanelUnderConstruction = ({ start, title }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { inSecond } = usePanel()

  start = start || (inSecond ? <PanelHeaderBackButton /> : null)

  return (
    <Panel>
      <PanelHeader start={start} title={title} />
      <PanelContent className='p-5'>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          <h1 className='label-xl text-neutral-400'>Coming Soon</h1>
        </div>
      </PanelContent>
    </Panel>
  )
}

PanelUnderConstruction.displayName = 'PanelUnderConstruction'
PanelUnderConstruction.propTypes = propTypes
PanelUnderConstruction.defaultProps = defaultProps

export default PanelUnderConstruction
