import PropType from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import Panel from '../../../components/panels/Panel'
import PanelHeaderButton from '../../../components/panels/panel-header/PanelHeaderButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import UserList from '../../../components/users/UserList'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Reload } from '../../../svg/icons'

const propTypes = {
  title: PropType.string
}

const defaultProps = {
  title: 'Users'
}

const log = logger({ enabled: false, tags: ['UsersList'] })

const UsersList = ({ title }) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const listRef = useRef()

  const [total, setTotal] = useState(0)

  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])
  const handleReloadList = useCallback(() => listRef.current.reload(), [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<PanelHeaderButton icon={<Reload />} title='Reload List' onClick={handleReloadList} />}
        title={title}
      />
      <PanelContent>
        <UserList
          ref={listRef}
          itemSharedContext={{ hideActions: true }}
          savedStateKey='admin-users'
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

UsersList.displayName = 'UsersList'
UsersList.propTypes = propTypes
UsersList.defaultProps = defaultProps

export default UsersList
