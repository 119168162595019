import PropType from 'prop-types'

import useLogger from '../hooks/useLogger'
import logger from '../lib/logger'
import { ArrowRight } from '../svg/icons'
import { BlogPromoBackground, BlogPromoCircleWithLogoMark } from '../svg/misc/empty-panel'

const propTypes = {
  className: PropType.string
}

const defaultProps = {
  className: 'mb-3 p-4 w-full'
}

const log = logger({ enabled: false, tags: ['BlogPromoBanner'] })

const BlogPromoBanner = ({ className }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className={className}>
      <a href='https://www.projectbroadcast.com/blog/' rel='noopener noreferrer' target='_blank'>
        <div className='relative bg-neutral-50 w-full p-4 border border-neutral-200 rounded-md'>
          <div className='relative flex flex-row z-10'>
            <BlogPromoCircleWithLogoMark className='shrink-0 mr-4' height='90' width='90' />
            <div className='flex flex-col justify-center'>
              <p className='label-lg-strong mb-1 text-black'>Tips, Tricks & strategies to help you grow your business.</p>
              <p className='label-sm mb-3 text-neutral-500'>Keep up-to-date on all the latest news</p>
              <p className='label-sm-strong text-primary'>
                Explore the blog
                {' '}
                <ArrowRight className='inline' height='1em' />
              </p>
            </div>
          </div>
          <div className='absolute left-0 top-0 w-full h-full z-0'>
            <BlogPromoBackground className='w-full h-full' preserveAspectRatio='xMinYMax slice' />
          </div>
        </div>
      </a>
    </div>
  )
}

BlogPromoBanner.displayName = 'BlogPromoBanner'
BlogPromoBanner.propTypes = propTypes
BlogPromoBanner.defaultProps = defaultProps

export default BlogPromoBanner
