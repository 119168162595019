import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'

import MessageViewerDialog from '../../components/message/MessageViewerDialog'
import Panel from '../../components/panels/Panel'
import PanelHeaderBackButton from '../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentUser from '../../hooks/useCurrentUser'
import useService from '../../hooks/useService'
import useTimeZone from '../../hooks/useTimeZone'
import { formatNumber, formatShortDateAndTime } from '../../lib/formatters'
import { entriesAfter as entriesAfterService } from '../../services/credit-ledger'
import { External } from '../../svg/icons'

const CreditLedger = () => {
  const currentUser = useCurrentUser()
  const { userTimeZone } = useTimeZone()

  const [entries, setEntries] = useState([])
  const lastFetchedEntryId = useRef(null)
  const messageViewerDialogRef = useRef()

  const { call: fetchLedgerEntriesInitial } = useService(entriesAfterService, {
    onReplyOk: useCallback((result) => setEntries(result.json), [])
  })
  useEffect(() => {
    fetchLedgerEntriesInitial()
  }, [fetchLedgerEntriesInitial])

  const { call: fetchLedgerEntriesAfter } = useService(entriesAfterService, {
    onReplyOk: useCallback((result) => setEntries(entries.concat(result.json)), [entries])
  })
  const fetchMoreData = useCallback(() => {
    const lastEntry = entries[entries.length - 1]
    if (lastEntry?._id && (lastEntry._id !== lastFetchedEntryId.current)) {
      lastFetchedEntryId.current = lastFetchedEntryId
      fetchLedgerEntriesAfter(lastEntry._id)
    }
  }, [entries, fetchLedgerEntriesAfter])

  const categoryToText = useCallback((category) => {
    // Feature Usage
    switch (category) {
      case 'appFlowTasks':
        return 'App Flow Tasks Executed'
      case 'email':
        return 'Sent Email'
      case 'message':
        return 'Sent Message'
      case 'rvm':
        return 'Sent Voice Drop'

        // Common Inflows
      case 'subscriptionCredits':
        return 'Subscription Credits'
      case 'purchasedCredits':
        return 'Purchased Credits'
      case 'rewardedCredits':
        return 'Rewarded Credits'
      case 'giftedCredits':
        return 'Gifted Credits'

        // Other
      case 'adminEntry':
        return 'Admin Entry'
      case 'systemEntry':
        return 'System Entry'
      case 'startingBalance' :
        return 'Starting Balance'

      default:
        return category
    }
  }, [])

  const currentBalance = useMemo(() => {
    const balance = formatNumber(currentUser.messageCredits, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    return (
      <div className='flex flex-row items-center gap-2'>
        <span className='label-sm-strong'>Balance:</span>
        <span className='bg-indigo-100 px-1.5 py-0.5 rounded-md'>
          {balance}
        </span>
      </div>
    )
  }, [currentUser.messageCredits])

  const renderTable = useCallback((props) => {
    return <table {...props} className='min-w-full table-auto text-sm text-gray-500' />
  }, [])

  const renderHeader = useCallback(() => {
    return (
      <tr className='border-b border-gray-200 bg-neutral-100 text-xs font-semibold text-gray-600 whitespace-nowrap'>
        <th className='py-2 px-3 text-left'>Date</th>
        <th className='py-2 px-3 text-left'>Category</th>
        <th className='py-2 px-3 text-left'>Memo</th>
        <th className='py-2 px-3 text-right'>Credits In</th>
        <th className='py-2 px-3 text-right'>Credits Out</th>
        <th className='py-2 px-3 text-right'>Balance</th>
      </tr>
    )
  }, [])

  const renderRow = useCallback((index, entry) => {
    const { entryDate, category, memo, creditsIn, creditsOut, balance, refObjType, refObjId } = entry
    return (
      <>
        <td className='py-3 px-3 text-left whitespace-pre-line'>
          {entryDate ? formatShortDateAndTime(entryDate, userTimeZone).replace(', ', '\n') : ''}
        </td>
        <td className='py-3 px-3 text-left'>{category ? categoryToText(category) : ''}</td>
        <td className='py-3 px-3 text-left'>
          {memo || ''}
          {refObjType === 'Message'
            ? (
              <a
                className='flex flex-row items-center gap-1 px-0 cursor-pointer'
                onClick={() => messageViewerDialogRef.current.open(refObjId)}
              >
                view message
                <External className='w-4 h-4' />
              </a>
              )
            : null}
        </td>
        <td className='py-3 px-3 text-right'>{creditsIn || ''}</td>
        <td className='py-3 px-3 text-right'>{creditsOut || ''}</td>
        <td className='py-3 px-3 text-right'>{balance || ''}</td>
      </>
    )
  }, [categoryToText, userTimeZone])

  return (
    <Panel hideAivaChatButton>
      <PanelHeader
        end={currentBalance}
        start={<PanelHeaderBackButton />}
        title='Credit Usage'
      />
      <PanelContent>
        <TableVirtuoso
          components={{
            Table: renderTable,
            TableRow: 'tr',
            TableCell: 'td'
          }}
          data={entries}
          endReached={fetchMoreData}
          fixedHeaderContent={renderHeader}
          itemContent={renderRow}
          style={{ width: '100%' }}
        />
        <MessageViewerDialog ref={messageViewerDialogRef} />
      </PanelContent>
    </Panel>
  )
}

CreditLedger.displayName = 'CreditLedger'
export default CreditLedger
