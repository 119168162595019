import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getCookie } from '../lib/cookies'
import { generate } from '../lib/shortid'
import { view as trackViewService } from '../services/tracking'

const trackPageView = () => {
  const eventId = generate()
  window.fbq('track', 'PageView', {}, { eventID: eventId })

  const trackingData = {
    eventId,
    eventSourceUrl: window.location.href,
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp')
  }

  trackViewService(trackingData).call()
}

function PageViewTracker () {
  const location = useLocation()

  useEffect(() => {
    trackPageView()
  }, [location])

  return undefined
}

export default PageViewTracker
