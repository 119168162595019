import { forwardRef, useCallback } from 'react'

import useBus from '../../hooks/useBus'
import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search } from '../../services/admin/unlayer-template'
import List from '../list/List'

import UnlayerTemplateListItemContent from './UnlayerTemplateListItemContent'
import UnlayerTemplateListSearchBar from './UnlayerTemplateListSearchBar'

const propTypes = {}

const defaultProps = {}

const unlayerTemplateResultsFields = [
  '_id',
  'name',
  'enabled',
  'type',
  'updatedAt'
]

const sortList = [
  { key: 'modified', label: 'Most Recently Updated' },
  { key: 'newest', label: 'Most Recently Created' },
  { key: 'name', label: 'Name', default: true }
]

const log = logger({ enabled: false, tags: ['UnlayerTemplateList'] })

const UnlayerTemplateList = forwardRef((listProps, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: true, tag: [listProps.type] })

  const handleBusEvent = useCallback(() => { ref.current.indicateUpdatesDetected() }, [ref])
  useBus('unlayerTemplateCreated', handleBusEvent)
  useBus('unlayerTemplateUpdated', handleBusEvent)
  useBus('unlayerTemplateDeleted', handleBusEvent)

  return (
    <List
      ref={ref}
      ListItemContent={UnlayerTemplateListItemContent}
      ListSearchBar={UnlayerTemplateListSearchBar}
      placeholder='Search Unlayer Templates'
      resultsFields={unlayerTemplateResultsFields}
      resultsKey='unlayerTemplates'
      searchService={search}
      sortList={sortList}
      search
      sortable
      {...listProps}
    />
  )
})

UnlayerTemplateList.displayName = 'UnlayerTemplateList'
UnlayerTemplateList.propTypes = propTypes
UnlayerTemplateList.defaultProps = defaultProps

export default UnlayerTemplateList
