import PropType from 'prop-types'

import messageTypes from '../../../../helpers/messageTypes'
import useLogger from '../../../../hooks/useLogger'
import logger from '../../../../lib/logger'
import templateShape from '../../../../prop-types/shapes/template'
import { Close } from '../../../../svg/icons'
import { Email } from '../../../../svg/types'
import Button from '../../../buttons/Button'

const propTypes = {
  messageType: PropType.oneOf(messageTypes).isRequired,
  template: PropType.shape(templateShape),
  onRemove: PropType.func
}

const defaultProps = {
  onRemove: undefined,
  template: undefined
}

const log = logger({ enabled: false, tags: ['MessageEmailAttachment'] })

const MessageEmailAttachment = ({ messageType, template, onRemove }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  if (messageType !== 'email') { return null }

  return (
    <div className='flex items-center px-3 pt-3 pb-8 flex '>
      <Email className='w-6 h-6 mr-2 shrink-0 self-start' />
      <div className='flex flex-col'>
        <div>
          <strong>
            {template?.name}
          </strong>
        </div>
        <div>
          {template?.subject}
        </div>
      </div>
      <Button
        className='ml-3'
        icon={<Close />}
        size='sm'
        theme='primary'
        variant='outline'
        circle
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  )
}

MessageEmailAttachment.displayName = 'MessageEmailAttachment'
MessageEmailAttachment.propTypes = propTypes
MessageEmailAttachment.defaultProps = defaultProps

export default MessageEmailAttachment
