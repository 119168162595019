import { formatName } from '../lib/formatters'

import proxy from './proxy'

export default function contestFromJSON (json) {
  if (!json) { return null }

  const overrides = {

    get contactsSubsetNames () {
      return json.contacts?.subset?.map(formatName)?.join(', ') || 'No Participants Yet'
    },

    get winnersSubsetNames () {
      return json.winners?.subset?.map(formatName)?.join(', ') || ''
    }
  }

  return proxy(json, overrides)
}
