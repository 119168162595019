import { get as getTrackableLinkService } from '../services/trackableLink'

import useCurrentInstance from './useCurrentInstance'

const useCurrentTrackableLink = () => useCurrentInstance({
  resourceName: 'trackableLink',
  getService: getTrackableLinkService,
  replyAttrPath: 'model',
  notFoundRedirect: '/trackable-links'
})

export default useCurrentTrackableLink
