import { useCallback, useRef, useState } from 'react'

import AppFlowList from '../../components/app-flows/AppFlowList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'

import EmptyListContent from './list/EmptyListContent'
import ListHeaderMoreMenu from './list/ListHeaderMoreMenu'

const log = logger({ enabled: false, tags: ['AppFlowsList'] })

const AppFlowsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()

  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={total}
        end={<ListHeaderMoreMenu listRef={listRef} />}
        title='App Flows'
      />
      <PanelContent>
        <AppFlowList
          ref={listRef}
          EmptyListContent={EmptyListContent}
          savedStateKey='app-flows'
          type='nav'
          sortable
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

AppFlowsList.displayName = 'AppFlowsList'

export default AppFlowsList
