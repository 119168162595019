import PropType from 'prop-types'

import contactFromJSON from '../models/contact'

import { addAllPropTypes, addPropTypes, removeAllPropTypes, removePropTypes, searchPropTypes } from './contact-tag.prop-types'
import pb from './pb'

export function search (body, background = false) {
  PropType.checkPropTypes(searchPropTypes, body, 'prop', 'contact-tag#search')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background
  }
  return pb.setupPost(`contacts/${contactId}/tags/search`, options)
}

export function addAll (body, background = false) {
  PropType.checkPropTypes(addAllPropTypes, body, 'prop', 'contact-tag#addAll')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/tags/add/all`, options)
}

export function add (body, background = false) {
  PropType.checkPropTypes(addPropTypes, body, 'prop', 'contact-tag#add')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/tags/add`, options)
}

export function removeAll (body, background = false) {
  PropType.checkPropTypes(removeAllPropTypes, body, 'prop', 'contact-tag#removeAll')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/tags/remove/all`, options)
}

export function remove (body, background = false) {
  PropType.checkPropTypes(removePropTypes, body, 'prop', 'contact-tag#remove')

  const { contactId } = body
  delete body.contactId

  const options = {
    body,
    background,
    model: {
      conversion: contactFromJSON,
      key: 'contact'
    }
  }
  return pb.setupPut(`contacts/${contactId}/tags/remove`, options)
}
