import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { Aiva as AivaEmptyState } from '../../svg/empty-states'

import AivaLearnMoreButton from './AivaLearnMoreButton'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['AivaChatEmptyThreadContent'] })

const AivaChatEmptyThreadContent = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  return (
    <div className='flex flex-col items-center gap-6 w-full'>
      <div className='w-full bg-indigo-50'>
        <AivaEmptyState className='max-w-[600px] mx-auto' />
      </div>
      <div className='pb-2'>
        <h1 className='label-lg-strong text-center'>Meet AIVA</h1>
        <p className='label-medium text-center max-w-[240px] mb-6'>
          AIVA serves as your dedicated virtual assistant, readily
          available to answer questions regarding Project Broadcast.
        </p>
        <AivaLearnMoreButton />
      </div>
    </div>
  )
}

AivaChatEmptyThreadContent.displayName = 'AivaChatEmptyThreadContent'
AivaChatEmptyThreadContent.propTypes = propTypes
AivaChatEmptyThreadContent.defaultProps = defaultProps

export default AivaChatEmptyThreadContent
