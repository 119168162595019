import { useCallback, useRef, useState } from 'react'

import CampaignList from '../../components/campaigns/CampaignList'
import FolderEmptyListContent from '../../components/folders/FolderEmptyListContent'
import FolderList from '../../components/folders/FolderList'
import Panel from '../../components/panels/Panel'
import PanelContent from '../../components/panels/PanelContent'
import PanelHeader from '../../components/panels/PanelHeader'
import useCurrentListFolderMode from '../../hooks/useCurrentListFolderMode'
import useLogger from '../../hooks/useLogger'
import { CONTENT_TYPE } from '../../lib/folder'
import logger from '../../lib/logger'

import CampaignEmptyListContent from './list/EmptyListContent'
import CampaignListHeaderMoreMenu from './list/ListHeaderMoreMenu'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CampaignsList'] })

const CampaignsList = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const listRef = useRef()
  const folderListRef = useRef()

  const currentListFolderMode = useCurrentListFolderMode()
  const [total, setTotal] = useState(0)
  const handleTotalChange = useCallback((newTotal) => { setTotal(newTotal) }, [])
  const [folderTotal, setFolderTotal] = useState(0)
  const handleFolderTotalChange = useCallback((newTotal) => { setFolderTotal(newTotal) }, [])

  return (
    <Panel>
      <PanelHeader
        count={currentListFolderMode ? folderTotal : total}
        end={<CampaignListHeaderMoreMenu currentListFolderMode={currentListFolderMode} listRef={listRef} />}
        title='Campaigns'
      />
      <PanelContent>
        <FolderList
          ref={folderListRef}
          EmptyListContent={FolderEmptyListContent}
          queryOverrides={{ contentType: CONTENT_TYPE.campaign }}
          savedStateKey='campaignsFolders'
          type='nav'
          visible={currentListFolderMode}
          showFolderToggle
          onTotalChange={handleFolderTotalChange}
        />
        <CampaignList
          ref={listRef}
          EmptyListContent={CampaignEmptyListContent}
          savedStateKey='campaigns'
          type='nav'
          visible={!currentListFolderMode}
          showFolderToggle
          onTotalChange={handleTotalChange}
        />
      </PanelContent>
    </Panel>
  )
}

CampaignsList.displayName = 'CampaignsList'
CampaignsList.propTypes = propTypes
CampaignsList.defaultProps = defaultProps

export default CampaignsList
