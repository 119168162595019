import PropType from 'prop-types'

import TermsLink from '../links/TermsLink'

import SummaryTextFragment from './SummaryTextFragment'

const propTypes = {
  plan: PropType.shape({
    credits: PropType.number.isRequired,
    frequency: PropType.string.isRequired,
    name: PropType.string.isRequired,
    price: PropType.string.isRequired,
    priceInCents: PropType.number.isRequired,
    actualCredits: PropType.number,
    newUserTrialInDays: PropType.number
  }),
  reanchorImmediately: PropType.bool
}

const defaultProps = {
  plan: null,
  reanchorImmediately: false
}

const SubscriptionSummary = ({ plan, reanchorImmediately }) => {
  if (!plan) { return null }

  return (
    <>
      <p className='mb-5'>
        <SummaryTextFragment plan={plan} reanchorImmediately={reanchorImmediately} />
      </p>
      <p>
        By continuing, you understand and agree to the
        {' '}
        <TermsLink label='Terms and Conditions' />
        {' '}
        of Project Broadcast.
      </p>
    </>
  )
}

SubscriptionSummary.displayName = 'SubscriptionSummary'
SubscriptionSummary.propTypes = propTypes
SubscriptionSummary.defaultProps = defaultProps

export default SubscriptionSummary
