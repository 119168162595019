import { has } from 'lodash'
import PropType from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import useDefaultId from '../../../hooks/useDefaultId'
import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import AttachmentList from '../../attachments/AttachmentList'
import CampaignEventList from '../../campaigns/CampaignEventList'
import CampaignList from '../../campaigns/CampaignList'
import ContactList from '../../contacts/ContactList'
import ListDialog from '../../dialog/ListDialog'
import KeywordList from '../../keywords/KeywordList'
import TagList from '../../tags/TagList'
import InputError from '../InputError'
import InputHint from '../InputHint'
import InputIcon from '../InputIcon'
import InputLabel from '../InputLabel'

const propTypes = {
  model: PropType.oneOf([
    'attachments',
    'campaigns',
    'campaignEvents',
    'contacts',
    'keywords',
    'tags']).isRequired,
  name: PropType.string.isRequired,
  error: PropType.oneOfType([
    PropType.string,
    PropType.shape({
      message: PropType.string,
      ref: PropType.node,
      type: PropType.string
    })
  ]),
  hint: PropType.string,
  id: PropType.string,
  label: PropType.string,
  listProps: PropType.object, // eslint-disable-line react/forbid-prop-types
  multiple: PropType.bool,
  placeholder: PropType.string, // eslint-disable-line react/forbid-prop-types
  onChange: PropType.func
}

const defaultProps = {
  error: null,
  hint: null,
  id: null,
  label: null,
  listProps: {},
  multiple: true,
  onChange: null,
  placeholder: null
}

const log = logger({ enabled: true, tags: ['ModelSelect'] })

// TODO: ability to pass in default value?
const ModelSelect = ({
  error,
  hint,
  id,
  label,
  listProps,
  model,
  multiple,
  name,
  placeholder,
  onChange
}) => {
  id = useDefaultId(id)
  const dialogRef = useRef()

  useLogger({ log, lifecycle: true, tags: [model, name, id] })

  const useFormMethods = useFormContext() // This may be null if we're using a form control outside a form
  error = error || useFormMethods?.formState?.errors?.[name]?.message

  const rawInitialValue = useFormMethods?.getValues(name)
  const initialValueArray = rawInitialValue && (multiple ? rawInitialValue : [rawInitialValue])
  const [selectedItems, setSelectedItems] = useState(initialValueArray || [])

  useEffect(() => { if (useFormMethods?.register) { useFormMethods?.register(name) } }, [name, useFormMethods])

  const className = 'flex flex-row flex-no-wrap items-center overflow-hidden border border-neutral-300 rounded-md w-full bg-white'
  const buttonClassName = 'h-[40px] text-left w-full py-[5px] cursor-pointer'
  const summaryNodeClassName = 'inline-block ml-[4px] px-[8px] py-[2px] bg-neutral-50 text-primary rounded-md border border-neutral-200'
  const placeholderClassName = 'inline-block ml-3 text-neutral-500'

  const updateValue = useCallback((newValue) => {
    setSelectedItems(newValue)
    const selectedValue = multiple ? newValue : newValue[0]
    if (useFormMethods?.setValue) { useFormMethods?.setValue(name, selectedValue) }
    if (onChange) { onChange(selectedValue) }
  }, [multiple, name, onChange, useFormMethods])

  const DelegatedList = useMemo(() => {
    switch (model) {
      case 'attachments':
        return AttachmentList
      case 'campaigns':
        return CampaignList
      case 'campaignEvents':
        return CampaignEventList
      case 'contacts':
        return ContactList
      case 'keywords':
        return KeywordList
      case 'tags':
        return TagList
      default:
        throw new Error(`Unknown model (${model}) for ModelSelect`)
    }
  }, [model])

  const summaryNodes = useMemo(() => {
    if (!selectedItems.length) { return <span className={placeholderClassName}>{placeholder}</span> }
    return selectedItems.map((summary) => {
      let summaryValue
      switch (model) {
        case 'contacts':
          summaryValue = summary?.firstName || summary?.phoneNumber
          break
        case 'keywords':
          summaryValue = summary?.word
          break
        case 'attachments':
        case 'campaigns':
        case 'tags':
        default:
          summaryValue = summary?.name
          break
      }
      return <span key={summaryValue} className={summaryNodeClassName}>{summaryValue}</span>
    })
  }, [model, placeholder, selectedItems])

  const end = useMemo(() => {
    return selectedItems.length ? <InputIcon icon='close' onClick={() => { updateValue([]) }} /> : null
  }, [selectedItems, updateValue])

  const trigger = useMemo(() => {
    return (
      <button className={buttonClassName} type='button'>
        {summaryNodes}
      </button>
    )
  }, [buttonClassName, summaryNodes])

  const handleSubmitSelected = useCallback((selectedItems) => updateValue(multiple ? selectedItems : [selectedItems]), [multiple, updateValue])

  if (!listProps) { listProps = {} }
  if (!has(listProps, 'search')) { listProps.search = true }

  return (
    <>
      <InputLabel id={id} text={label} />
      <div className={className}>
        <ListDialog
          ref={dialogRef}
          list={DelegatedList}
          multiple={multiple}
          selectedItems={selectedItems}
          trigger={trigger}
          type='select'
          {...listProps}
          onSubmitSelected={handleSubmitSelected}
        />
        {end}
      </div>
      <InputError message={error?.message || error} />
      <InputHint message={hint} />
    </>
  )
}

ModelSelect.displayName = 'ModelSelect'
ModelSelect.propTypes = propTypes
ModelSelect.defaultProps = defaultProps

export default ModelSelect
