import { entries } from 'lodash'
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { success } from '../../components/banners/Banner'
import Button from '../../components/buttons/Button'
import Form from '../../components/forms/Form'
import ShortLinkContent from '../../components/short-links/ShortLinkContent'
import dates from '../../data-sources/dates.json'
import months from '../../data-sources/months.json'
import useLogger from '../../hooks/useLogger'
import useService from '../../hooks/useService'
import logger from '../../lib/logger'
import { configurableFormLanding, configurableFormUpdate } from '../../services/shortLinks'

const propTypes = {}

const defaultProps = {}

const controlSettingsByName = function (name, required) {
  return {
    firstName: [{ type: 'text', name: 'firstName', label: `First Name${required ? ' *' : ''}`, required }],
    lastName: [{ type: 'text', name: 'lastName', label: `Last Name${required ? ' *' : ''}`, required }],
    company: [{ type: 'text', name: 'company', label: `Company${required ? ' *' : ''}`, required }],
    email: [{ type: 'email', name: 'email', label: `Email${required ? ' *' : ''}`, required }],
    birthDay: [
      {
        type: 'controlgroup',
        name: 'birthday',
        formControls: [
          { containerClassName: 'w-full', label: `Birthday Month${required ? ' *' : ''}`, name: 'birthday[month]', type: 'select', options: [{ label: 'Select Month', value: '' }, ...months, required] },
          { containerClassName: 'w-full', label: `Birthday Day${required ? ' *' : ''}`, name: 'birthday[date]', type: 'select', options: [{ label: 'Select Day', value: '' }, ...dates, required] }
        ]
      }
    ],
    anniversary: [
      {
        type: 'controlgroup',
        name: 'anniversary',
        formControls: [
          { containerClassName: 'w-full', label: `Anniversary Month${required ? ' *' : ''}`, name: 'anniversary[month]', type: 'select', options: [{ label: 'Select Month', value: '' }, ...months, required] },
          { containerClassName: 'w-full', label: `Anniversary Day${required ? ' *' : ''}`, name: 'anniversary[date]', type: 'select', options: [{ label: 'Select Day', value: '' }, ...dates, required] }
        ]
      }
    ]
  }[name]
}

const log = logger({ enabled: true, tags: ['ConfigurableForm'] })

const ConfigurableForm = () => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formId = useId()
  const formRef = useRef()

  const { configurableFormSlug, contactSlug } = useParams()

  const [configurableForm, setConfigurableForm] = useState(null)
  const [error, setError] = useState(null)

  const handleGetReplyOk = useCallback((reply) => { setConfigurableForm(reply.model) }, [])
  const handleGetReplyNotOk = useCallback((/* reply */) => {
    setError('Invalid link. Please try to copy/paste the link from your message to your browser.')
  }, [])
  const { call: getConfigurableForm, loading: loadingConfigurableForm } = useService(configurableFormLanding, { onReplyOk: handleGetReplyOk, onReplyNotOk: handleGetReplyNotOk })

  useEffect(() => {
    getConfigurableForm(configurableFormSlug, contactSlug, true)
  }, [configurableFormSlug, contactSlug, getConfigurableForm])

  const handleUpdateReplyOk = useCallback((/* reply */) => {
    window.scrollTo(0, 0)
    formRef.current.reset()
    success('Your contact info has been updated!')
    if (configurableForm.redirectUrl) {
      setTimeout(() => { window.location.replace(configurableForm.redirectUrl) }, 2000)
    }
  }, [configurableForm])
  const { call: configurableFormUpdateCall } = useService(configurableFormUpdate, { onReplyOk: handleUpdateReplyOk })

  const loading = (loadingConfigurableForm || !configurableForm) && !error

  const { formControls, defaultValues } = useMemo(() => {
    if (!configurableForm) { return { controls: [], defaultValues: {} } }

    const fields = { ...configurableForm.fields }
    const customFields = fields.customFields
    delete fields.customFields

    let controls = []
    const defaultValues = {}

    entries(fields).forEach(([name, { enabled, required }]) => {
      if (!enabled) { return }
      controls = controls.concat(controlSettingsByName(name, required))
      defaultValues[name] = ''
    })

    customFields.forEach(({ enabled, required, name: label, _id: id }) => {
      if (!enabled) { return }
      const name = `customFields[${id}]`
      controls = controls.concat([{ type: 'text', name, label: `${label}${required ? ' *' : ''}`, required }])
      defaultValues[name] = ''
    })

    return { formControls: controls, defaultValues }
  }, [configurableForm])

  const handleSubmit = useCallback((data) => {
    configurableFormUpdateCall(configurableFormSlug, contactSlug, data)
  }, [configurableFormSlug, configurableFormUpdateCall, contactSlug])

  if (loading || error) { return <ShortLinkContent error={error} loading={loading} /> }

  return (
    <ShortLinkContent>
      <p className='mb-10 text-center'>
        You have clicked on a form sent from
        {' '}
        <strong>
          {configurableForm.user.formattedName}
          {' - '}
          {configurableForm.user.formattedPhoneNumber}
        </strong>
        {`${'.'}`/* this is a weird workaround to avoid a react/jsx-child-element-spacing linter rule error */}
      </p>
      <p className='mb-4 text-center'>{configurableForm.description}</p>
      <Form
        ref={formRef}
        defaultValues={defaultValues}
        formControls={formControls}
        id={formId}
        onSubmit={handleSubmit}
      />
      <p className='mt-4 mb-8 text-center'>
        ** By submitting this form you agree to receive text messages from the person who sent you this link. Message and data rates may apply. At any time you can reply STOP to the sender to stop receiving messages **
      </p>
      <Button
        className='w-full'
        form={formId}
        size='md'
        type='submit'
      >
        Submit
      </Button>
    </ShortLinkContent>
  )
}

ConfigurableForm.displayName = 'ConfigurableForm'
ConfigurableForm.propTypes = propTypes
ConfigurableForm.defaultProps = defaultProps

export default ConfigurableForm
