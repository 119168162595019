import * as React from "react";
import { memo } from "react";
const SvgBellDisabled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0a3.006 3.006 0 0 0-2.93 3.642A7.006 7.006 0 0 0 5 10c0 1.815-.424 3.612-1.235 5.233l-1.66 3.32A1 1 0 0 0 3 20h1.478l-1.841 2.229a1 1 0 0 0 1.542 1.274L21.905 2.045A1 1 0 0 0 20.363.77l-3.482 4.215a7.023 7.023 0 0 0-1.951-1.344A3.006 3.006 0 0 0 12 0Zm3.602 6.535a5.005 5.005 0 0 0-2.168-1.326.995.995 0 0 1-.232-.063 4.997 4.997 0 0 0-2.403 0 .995.995 0 0 1-.233.063A5.008 5.008 0 0 0 7 10c0 2.125-.496 4.228-1.446 6.127L4.618 18H6.13l9.472-11.465ZM12 24a3.997 3.997 0 0 1-3.887-3.05L10.551 18h8.831l-.936-1.873A13.72 13.72 0 0 1 17 10.191l1.735-2.1A6.99 6.99 0 0 1 19 10c0 1.815.424 3.612 1.235 5.233l1.66 3.32A1 1 0 0 1 21 20h-5c0 2.212-1.788 4-4 4Zm.997-20.93A1.006 1.006 0 0 0 12 2a1.006 1.006 0 0 0-.997 1.07 7.041 7.041 0 0 1 1.994 0ZM12 22c-1.108 0-2-.892-2-2h4c0 1.108-.892 2-2 2Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBellDisabled);
export default Memo;
