import PropType from 'prop-types'

import Button from '../../../components/buttons/Button'

const propTypes = {
  canDeferSetup: PropType.bool.isRequired,
  onClick: PropType.func.isRequired
}

const defaultProps = {}

const DeferSetupButton = ({ canDeferSetup, onClick }) => {
  if (canDeferSetup) {
    return (
      <Button
        className='mx-auto my-5 font-normal'
        size='sm'
        variant='text'
        onClick={onClick}
      >
        Setup Later
      </Button>
    )
  }
}

DeferSetupButton.displayName = 'DeferSetupButton'
DeferSetupButton.propTypes = propTypes
DeferSetupButton.defaultProps = defaultProps

export default DeferSetupButton
