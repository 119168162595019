import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useLogger from '../../../hooks/useLogger'
import useService from '../../../hooks/useService'
import bus from '../../../lib/bus'
import logger from '../../../lib/logger'
import { create } from '../../../services/space'
import { setCurrentInstance } from '../../../store/actions/currentInstance'
import FormDialog from '../../dialog/FormDialog'

import AddMembersAction from './AddMembersAction'
import validationSuite from './CreateAction.validations'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['CreateAction'] })

const CreateAction = forwardRef((props, ref) => {
  useLogger({ log, lifecycle: false, tags: [] })
  const formDialogRef = useRef()
  const addMembersActionRef = useRef()
  const navigate = useNavigate()
  const [newSpace, setNewSpace] = useState()

  const handleReplyOk = useCallback((reply) => {
    formDialogRef.current.close()
    const newSpace = reply.model
    setCurrentInstance('space', newSpace)
    bus.emit('spaceCreated', newSpace)
    navigate(`/spaces/${newSpace._id}`)
    setNewSpace(newSpace)
    addMembersActionRef.current.activate()
  }, [navigate])
  const { call } = useService(create, { onReplyOk: handleReplyOk })

  useImperativeHandle(ref, () => ({
    activate () { formDialogRef.current.open() }
  }), [])

  const handleSubmit = useCallback((data) => {
    call(data)
  }, [call])

  const defaultValues = {
    name: ''
  }

  const formControls = [
    { autoComplete: 'off', label: 'Name', name: 'name', type: 'text' }
  ]

  return (
    <>
      <FormDialog
        ref={formDialogRef}
        defaultValues={defaultValues}
        description='Please name your new space'
        formControls={formControls}
        title='New Space'
        validationSuite={validationSuite}
        onSubmit={handleSubmit}
      />
      <AddMembersAction ref={addMembersActionRef} space={newSpace} />
    </>
  )
})

CreateAction.displayName = 'CreateAction'
CreateAction.propTypes = propTypes
CreateAction.defaultProps = defaultProps

export default CreateAction
