import * as React from "react";
import { memo } from "react";
const SvgFood = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Food_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.45 0c-1.713 0-3.179.903-4.094 2.224C7.916 2.083 7.446 2 6.95 2 4.14 2 1.988 4.365 1.838 7.187.73 7.624 0 8.772 0 10c0 1.333.859 2.57 2.129 2.91l1.867 10.269A1 1 0 0 0 4.98 24H18.98a1 1 0 0 0 .984-.821l1.866-10.262c1.287-.326 2.16-1.573 2.16-2.917 0-1.232-.734-2.383-1.849-2.817C21.99 4.362 19.828 2 17.03 2c-.21 0-.414.017-.61.045C15.5.818 14.08 0 12.45 0Zm8.548 11H2.83c-.375 0-.83-.366-.83-1s.455-1 .83-1h.05a1 1 0 0 0 .993-1.117l-.027-.214A1.1 1.1 0 0 1 3.83 7.5C3.83 5.477 5.304 4 6.95 4c.465 0 .922.134 1.351.368a1 1 0 0 0 1.387-.458C10.23 2.736 11.289 2 12.45 2c1.08 0 2.067.63 2.635 1.655a1 1 0 0 0 1.157.474c.28-.082.542-.129.788-.129 1.638 0 3.12 1.479 3.12 3.5 0 .056-.005.092-.016.17l-.027.213A1 1 0 0 0 21.1 9h.06c.375 0 .83.366.83 1s-.455 1-.83 1h-.162Zm-16.82 2 1.637 9H7.36l-1.023-9H4.178Zm4.172 0 1.023 9h1.607v-9H8.35Zm4.63 0v9h1.607l1.023-9h-2.63Zm4.643 0L16.6 22h1.545l1.637-9h-2.16ZM12.98 3.99a1 1 0 0 1 1 1V5a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm-5 3a1 1 0 1 0-2 0V7a1 1 0 1 0 2 0v-.01Zm10 0a1 1 0 1 0-2 0V7a1 1 0 1 0 2 0v-.01Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Food_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgFood);
export default Memo;
