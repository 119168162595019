import * as React from "react";
import { memo } from "react";
const SvgPanels = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 3a1 1 0 0 1 1-1h3v20H3a1 1 0 0 1-1-1V3Zm5 21H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H7ZM22 8v13a1 1 0 0 1-1 1H8V8h14Zm0-2V3a1 1 0 0 0-1-1H8v4h14Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPanels);
export default Memo;
