import { unlayerTemplateTypeLabel } from './unlayerTemplate'

// converts server-ready object back to an advanced search object :(
export function generateAdvancedSearchFromSearchData (searchData) {
  const search = {}

  if (!searchData) { return search }

  const fieldsToCopyAsIs = ['term', 'type']
  fieldsToCopyAsIs.forEach((field) => { search[field] = searchData[field] })

  return search
}

// converts advanced search form data to a server-ready object for the search api
export function generateSearchFromAdvancedSearchFormData (advancedSearchFormData) {
  const search = {}

  if (!advancedSearchFormData) { return search }

  const fieldsToCopyAsIs = ['term', 'type']
  fieldsToCopyAsIs.forEach((field) => { search[field] = advancedSearchFormData[field] })

  return search
}

export function generateSummaryFromAdvancedSearchFormData (advancedSearchFormData) {
  let filterCount = 0
  const summaries = []

  if (!advancedSearchFormData) { return summaries }

  if (advancedSearchFormData.term) {
    filterCount++
    summaries.push(advancedSearchFormData.term)
  }

  if (advancedSearchFormData.type) {
    filterCount++
    summaries.push(unlayerTemplateTypeLabel(advancedSearchFormData.type))
  }

  if (filterCount) { summaries.unshift(`${filterCount}`) }
  return summaries
}
