import ListActionLink from '../../../components/links/ListActionLink'
import Panel from '../../../components/panels/Panel'
import PanelHeaderBackButton from '../../../components/panels/panel-header/PanelHeaderBackButton'
import PanelContent from '../../../components/panels/PanelContent'
import PanelHeader from '../../../components/panels/PanelHeader'
import PanelLoading from '../../../components/panels/PanelLoading'
import PanelNotFound from '../../../components/panels/PanelNotFound'
import ListItemContent from '../../../components/users/ListItemContent'
import useLogger from '../../../hooks/useLogger'
import useSelectedUser from '../../../hooks/useSelectedUser'
import logger from '../../../lib/logger'

const propTypes = {}

const defaultProps = {}

const log = logger({ enabled: false, tags: ['Detail'] })

const Detail = () => {
  useLogger({ log, lifecycle: false, tags: [] })

  const { loading, user } = useSelectedUser()

  if (loading) { return <PanelLoading /> }
  if (!user) { return <PanelNotFound /> }

  return (
    <Panel>
      <PanelHeader
        start={<PanelHeaderBackButton />}
        title={user.formattedName}
      />
      <PanelContent>
        <div className='flex flex-row p-5'>
          <ListItemContent item={user} itemSharedContext={{ hideActions: false }} />
        </div>
        <div className='border-t border-neutral-200'>
          <ListActionLink title='Add Credits' to='add-credits' />
          {user.stripeId ? <ListActionLink title='Apply Referral Code' to='apply-referral-code' /> : null}
          <ListActionLink title='Audit History' to='audit-history' />
          <ListActionLink title='Campaign Registration' to='campaign-registration' />
          {user.stripeId ? <ListActionLink title='Export Payment History' to='export-payment-history' /> : null}
          <ListActionLink title='Multi-Factor Authentication' to='multi-factor-authentication' />
          <ListActionLink title='Restore Cancelled Credits' to='restore-cancelled-credits' />
          <ListActionLink title='Set Password' to='set-password' />
          <ListActionLink title='Usent Messages' to='unsent-messages' />
          <ListActionLink title='Update Number' to='update-number' />
        </div>
      </PanelContent>
    </Panel>
  )
}

Detail.displayName = 'Detail'
Detail.propTypes = propTypes
Detail.defaultProps = defaultProps

export default Detail
