import * as React from "react";
import { memo } from "react";
const SvgScheduleBroadcast = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 235 183"
    {...props}
  >
    <g filter="url(#ScheduleBroadcast_svg__a)">
      <path
        fill="#fff"
        d="M182.786 5.143H42.214c-6.47 0-11.714 5.244-11.714 11.714v140.571c0 6.47 5.245 11.715 11.714 11.715h140.572c6.469 0 11.714-5.245 11.714-11.715V16.857c0-6.47-5.245-11.714-11.714-11.714Z"
      />
    </g>
    <path
      fill="#FEFCE8"
      d="M172.5 17.143h-120c-5.523 0-10 4.477-10 10v120c0 5.522 4.477 10 10 10h120c5.523 0 10-4.478 10-10v-120c0-5.523-4.477-10-10-10Z"
    />
    <g filter="url(#ScheduleBroadcast_svg__b)">
      <g clipPath="url(#ScheduleBroadcast_svg__c)">
        <rect width={171} height={41} x={7} y={34.143} fill="#fff" rx={5} />
        <path
          fill="#fff"
          d="M172.103 34.143H12.897C9.64 34.143 7 36.765 7 40v29.285c0 3.235 2.64 5.858 5.897 5.858h159.206c3.257 0 5.897-2.623 5.897-5.858V40c0-3.235-2.64-5.857-5.897-5.857Z"
        />
        <path
          fill="#FEF08A"
          d="M139.672 44.685H51.813c-1.954 0-3.538 1.574-3.538 3.515 0 1.94 1.584 3.514 3.538 3.514h87.859c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.515-3.538-3.515Z"
        />
        <path
          fill="#FEFCE8"
          d="M103.703 56.4h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.94 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.94-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={28.303}
          height={28.114}
          x={12.896}
          y={41.171}
          fill="#FEF9C3"
          rx={4}
        />
        <g clipPath="url(#ScheduleBroadcast_svg__d)">
          <path
            fill="#CA8A04"
            fillRule="evenodd"
            d="M22.96 61.486a7.7 7.7 0 0 1-1.692-1.276 7.627 7.627 0 0 1 0-10.844 7.75 7.75 0 0 1 10.917 0 7.627 7.627 0 0 1 0 10.844c-.52.515-1.09.944-1.691 1.276a.849.849 0 0 0-.332 1.16.86.86 0 0 0 1.167.329 9.405 9.405 0 0 0 2.069-1.56 9.322 9.322 0 0 0 0-13.254c-3.687-3.662-9.656-3.662-13.343 0a9.322 9.322 0 0 0 0 13.253 9.411 9.411 0 0 0 2.07 1.56.86.86 0 0 0 1.166-.329.849.849 0 0 0-.332-1.159Zm1.575-2.679a4.57 4.57 0 0 1-1.061-.788 4.544 4.544 0 0 1 0-6.462 4.618 4.618 0 0 1 6.506 0 4.544 4.544 0 0 1 0 6.462 4.526 4.526 0 0 1-1.062.788.849.849 0 0 0-.35 1.154.86.86 0 0 0 1.16.348 6.24 6.24 0 0 0 1.464-1.085 6.24 6.24 0 0 0 0-8.872 6.342 6.342 0 0 0-8.931 0 6.24 6.24 0 0 0 0 8.872 6.28 6.28 0 0 0 1.463 1.085.86.86 0 0 0 1.162-.348.849.849 0 0 0-.35-1.154Zm1.144-2.979a1.488 1.488 0 0 0 2.095 0 1.465 1.465 0 0 0 0-2.08 1.488 1.488 0 0 0-2.095 0 1.465 1.465 0 0 0 0 2.08Zm.19 2.02a3.186 3.186 0 0 1-1.403-.815 3.16 3.16 0 0 1 0-4.49 3.212 3.212 0 0 1 4.521 0 3.16 3.16 0 0 1 0 4.49 3.186 3.186 0 0 1-1.403.814v6.433c0 .47-.384.852-.857.852a.855.855 0 0 1-.858-.852v-6.433Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </g>
    <g filter="url(#ScheduleBroadcast_svg__e)">
      <g clipPath="url(#ScheduleBroadcast_svg__f)">
        <rect width={171} height={41} x={53} y={85.143} fill="#fff" rx={5} />
        <path
          fill="#fff"
          d="M218.103 85.143H58.897C55.64 85.143 53 87.765 53 91v29.285c0 3.235 2.64 5.858 5.897 5.858h159.206c3.257 0 5.897-2.623 5.897-5.858V91c0-3.235-2.64-5.857-5.897-5.857Z"
        />
        <path
          fill="#FEF08A"
          d="M185.672 95.686H97.813c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h87.859c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
        />
        <path
          fill="#FEFCE8"
          d="M149.703 107.4h-51.89c-1.954 0-3.538 1.573-3.538 3.514 0 1.941 1.584 3.514 3.538 3.514h51.89c1.954 0 3.538-1.573 3.538-3.514 0-1.941-1.584-3.514-3.538-3.514Z"
        />
        <rect
          width={28.303}
          height={28.114}
          x={58.897}
          y={92.171}
          fill="#FEF9C3"
          rx={4}
        />
        <g clipPath="url(#ScheduleBroadcast_svg__g)">
          <path
            fill="#CA8A04"
            fillRule="evenodd"
            d="M68.96 112.486a7.717 7.717 0 0 1-1.692-1.276 7.627 7.627 0 0 1 0-10.844 7.751 7.751 0 0 1 10.917 0 7.627 7.627 0 0 1 0 10.844c-.52.515-1.09.943-1.691 1.276a.849.849 0 0 0-.332 1.159.861.861 0 0 0 1.167.33 9.397 9.397 0 0 0 2.069-1.561 9.322 9.322 0 0 0 0-13.253c-3.687-3.662-9.655-3.662-13.343 0a9.322 9.322 0 0 0 0 13.253 9.4 9.4 0 0 0 2.07 1.561.861.861 0 0 0 1.166-.33.849.849 0 0 0-.331-1.159Zm1.575-2.679a4.538 4.538 0 0 1-1.061-.788 4.544 4.544 0 0 1 0-6.462 4.618 4.618 0 0 1 6.505 0 4.544 4.544 0 0 1 0 6.462 4.52 4.52 0 0 1-1.061.788.85.85 0 0 0-.35 1.154.862.862 0 0 0 1.16.348 6.271 6.271 0 0 0 1.464-1.085 6.24 6.24 0 0 0 0-8.872 6.341 6.341 0 0 0-8.931 0 6.24 6.24 0 0 0 0 8.872c.438.435.935.803 1.463 1.085a.862.862 0 0 0 1.161-.348.85.85 0 0 0-.35-1.154Zm1.144-2.979a1.488 1.488 0 0 0 2.095 0 1.465 1.465 0 0 0 0-2.081 1.49 1.49 0 0 0-2.095 0 1.465 1.465 0 0 0 0 2.081Zm.19 2.019a3.183 3.183 0 0 1-1.403-.814 3.16 3.16 0 0 1 0-4.491 3.212 3.212 0 0 1 4.521 0 3.16 3.16 0 0 1 0 4.491 3.183 3.183 0 0 1-1.403.814v6.433a.855.855 0 0 1-.857.852.855.855 0 0 1-.858-.852v-6.433Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="ScheduleBroadcast_svg__c">
        <rect width={171} height={41} x={7} y={34.143} fill="#fff" rx={5} />
      </clipPath>
      <clipPath id="ScheduleBroadcast_svg__d">
        <path fill="#fff" d="M16.434 44.685h20.584v20.447H16.434z" />
      </clipPath>
      <clipPath id="ScheduleBroadcast_svg__f">
        <rect width={171} height={41} x={53} y={85.143} fill="#fff" rx={5} />
      </clipPath>
      <clipPath id="ScheduleBroadcast_svg__g">
        <path fill="#fff" d="M62.434 95.686h20.584v20.447H62.434z" />
      </clipPath>
      <filter
        id="ScheduleBroadcast_svg__a"
        width={182}
        height={182}
        x={23.5}
        y={0.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_112500"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_112500"
          result="shape"
        />
      </filter>
      <filter
        id="ScheduleBroadcast_svg__b"
        width={189}
        height={59}
        x={0}
        y={29.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_112500"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_112500"
          result="shape"
        />
      </filter>
      <filter
        id="ScheduleBroadcast_svg__e"
        width={189}
        height={59}
        x={46}
        y={80.143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={4} />
        <feGaussianBlur stdDeviation={4.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_890_112500"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_890_112500"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(SvgScheduleBroadcast);
export default Memo;
