import { debounce } from 'lodash'
import { useDebugValue, useLayoutEffect, useState } from 'react'

import screens from '../tailwindcss/screens'

const keys = Object.keys(screens)
export let breakpoints = []
let nextMax
for (const [breakpoint, size] of Object.entries(screens).reverse()) {
  const min = parseInt(size)
  breakpoints.push({
    min,
    max: nextMax || null,
    name: breakpoint
  })
  nextMax = min - 1
}
breakpoints[0].max = undefined // no max for the last breakpoint
breakpoints = breakpoints.reverse()

const getBreakpointForWidth = (width) => {
  let current = null

  for (const { min, max, name } of breakpoints) {
    if ((min === 0 || width >= min) && (!max || width <= max)) {
      current = name
      break
    }
  }

  return current
}

export const useBreakpointValue = () => {
  const [breakpoint, setBreakpoint] = useState(
    getBreakpointForWidth(window.innerWidth)
  )

  useDebugValue(`Current window width ${window.innerWidth}`)
  useDebugValue(`Current breakpoint ${breakpoint}`)

  useLayoutEffect(() => {
    const resizeHandler = debounce(
      () => {
        const breakpoint = getBreakpointForWidth(window.innerWidth)
        setBreakpoint(breakpoint)
      },
      150,
      { maxWait: 300 }
    )
    window.addEventListener('resize', resizeHandler, { passive: true })
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return breakpoint
}

export const useMinBreakpoint = (minBreakpoint = 'sm') => {
  const currentBreakpoint = useBreakpointValue()

  useDebugValue(`Minimum Requested Breakpoint ${minBreakpoint}`)
  useDebugValue(`Current Breakpoint ${currentBreakpoint}`)

  const indexOfCurrentBreakpoint = keys.findIndex(
    (key) => key === currentBreakpoint
  )
  const indexOfMinBreakpoint = keys.findIndex((key) => key === minBreakpoint)
  return indexOfMinBreakpoint <= indexOfCurrentBreakpoint
}
