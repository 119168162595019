import PropType from 'prop-types'
import { forwardRef } from 'react'

import useDefaultRef from '../../hooks/useDefaultRef'
import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import { search as templateSearch } from '../../services/template'
import List from '../list/List'

import TemplateListItemContent from './TemplateListItemContent'

const propTypes = {
  placeholder: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  queryOverrides: PropType.object,
  searchService: PropType.func
}

const defaultProps = {
  placeholder: 'Search Templates',
  queryOverrides: {},
  searchService: templateSearch
}

const templateResultsFields = [
  '_id',
  'contentType',
  'media',
  'name',
  'text',
  'type',
  'subject',
  'transactional'
]

const log = logger({ enabled: false, tags: ['TemplateList'] })

const TemplateList = forwardRef(({
  placeholder,
  queryOverrides,
  searchService,
  ...listProps
}, ref) => {
  ref = useDefaultRef(ref)
  useLogger({ log, lifecycle: false, tags: [listProps.type] })

  return (
    <List
      ref={ref}
      ListItemContent={TemplateListItemContent}
      placeholder={placeholder}
      queryOverrides={queryOverrides}
      resultsFields={templateResultsFields}
      resultsKey='templates'
      searchService={searchService}
      search
      {...listProps}
    />
  )
})

TemplateList.displayName = 'TemplateList'
TemplateList.propTypes = propTypes
TemplateList.defaultProps = defaultProps

export default TemplateList
