import { upperFirst } from 'lodash'
import PropType from 'prop-types'
import { useMemo } from 'react'

import useLogger from '../../../hooks/useLogger'
import logger from '../../../lib/logger'
import { Key } from '../../../svg/icons'

const propTypes = {
  hasKeyword: PropType.bool.isRequired,
  keywordAppliedByOther: PropType.string
}

const defaultProps = {
  keywordAppliedByOther: null
}

const log = logger({ enabled: false, tags: ['KeywordBadge'] })

const KeywordBadge = ({ hasKeyword, keywordAppliedByOther }) => {
  useLogger({ log, lifecycle: false, tags: [] })

  const appliedByTag = useMemo(() => {
    if (!keywordAppliedByOther) { return null }
    if (keywordAppliedByOther === 'user') { return 'You' }
    return upperFirst(keywordAppliedByOther)
  }, [keywordAppliedByOther])

  if (!hasKeyword) { return null }

  return (
    <div className='flex flex-row flex-nowrap items-center'>
      <Key height='16' width='16' />
      <span className='pt-1 pl-1 label-xs text-neutral-500 uppercase'>
        {appliedByTag}
      </span>
    </div>
  )
}

KeywordBadge.displayName = 'KeywordBadge'
KeywordBadge.propTypes = propTypes
KeywordBadge.defaultProps = defaultProps

export default KeywordBadge
