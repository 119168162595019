import PropType from 'prop-types'
import { useCallback, useRef } from 'react'

import useLogger from '../../hooks/useLogger'
import logger from '../../lib/logger'
import managedAccountShape from '../../prop-types/shapes/managedAccount'
import Avatar from '../avatar/Avatar'
import Button from '../buttons/Button'

import ManageAccountAction from './actions/ManageAction'

const propTypes = {
  itemSharedContext: PropType.shape({
    hideActions: PropType.bool
  }).isRequired,
  item: PropType.shape(managedAccountShape)
}

const defaultProps = {
  item: null
}

const log = logger({ enabled: false, tags: ['ManagedAccountListItemContent'] })

const ManagedAccountListItemContent = ({ item: managedAccount, itemSharedContext: { hideActions } }) => {
  useLogger({ log, lifecycle: false, tags: [managedAccount._id] })
  const manageAccountActionRef = useRef()

  const initiateManageAccount = useCallback(() => {
    manageAccountActionRef.current.activate()
  }, [])

  return (
    <>
      <Avatar contactOrUser={managedAccount} size='sm' />
      <div className='flex flex-col flex-grow ml-[12px] py-[4px] max-w-full overflow-auto'>
        <div className='label-sm-strong flex flex-row flex-nowrap items-center text-black'>
          {managedAccount.formattedName}
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {managedAccount.formattedMessageCredits}
          {' '}
          available credits
        </div>
        <div className='label-xs text-neutral-500 mt-[3px]'>
          {managedAccount.formattedPhoneNumber}
        </div>
        <div className='line-clamp-1 break-words label-xs text-neutral-500 mt-[3px]'>
          {managedAccount.email}
        </div>
      </div>
      {!hideActions && (
        <>
          <div className='self-center'>
            <Button
              size='md'
              variant='text'
              onClick={initiateManageAccount}
            >
              Manage
            </Button>
          </div>
          <ManageAccountAction ref={manageAccountActionRef} userId={managedAccount._id} />
        </>
      )}
    </>
  )
}

ManagedAccountListItemContent.displayName = 'ManagedAccountListItemContent'
ManagedAccountListItemContent.propTypes = propTypes
ManagedAccountListItemContent.defaultProps = defaultProps

export default ManagedAccountListItemContent
